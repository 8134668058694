<div [ngClass]="class">
    <em class="iconb-close close-toast" (click)="closeToaster(data.id)"></em>
   <div class="icon-box">
     <span class="toast-typeIcon"></span>
   </div>
   <div class="toast-content">
     <div class="toast-title">{{data.title}}</div>
     <div class="toast-message" [innerHtml]="data.message"></div>
   </div>
</div>
