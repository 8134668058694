<div class="projectSetting-wrapper">
  <div class="topInfo-section">
    <div class="topTitle">Settings</div>
    <div class="integrationSection-top">
      <div class="left">
        <div class="maintitle">Integrations ({{integration_kinds?.length}})</div>
      </div>
      <div class="right"></div>
    </div>
  </div>
  <div class="integrationList-table">
    <div class="integrationList-wrqapper">
      <div class="integrationList-topHead">
        <div class="nameBlock">Integration Name</div>
        <div class="statusBlock">Status</div>
        <div class="urlBlock">URL/ID</div>
        <div class="actBlock">Action</div>
      </div>
      <div class="integrationList-content">
        <div class="integrationList-listing" *ngFor="let item of integration_kinds">
          <div class="nameBlock">
            <div class="blockContent">
              <div class="integration-icon"><img src="../../assets/images/{{item.kind}}.png" alt="loader"> </div>
              <div class="nameWithInitials">{{ item.kind ? item.kind.charAt(0).toUpperCase() + item.kind.slice(1) : ''}}</div>
            </div>
          </div>
          <div class="statusBlock">
            <div class="blockContent">{{ item.integrations[0].created_at ? 'Enabled' : 'Not Enabled' }}</div>
          </div>
          <div class="urlAndActionBlock">
            <div class="urlAndActionListing" *ngFor="let integration of item.integrations; index as i;">
              <div class="urlBlock">
                <div class="blockContent" *ngIf="!integration.insertURL && integration.resource_url">{{integration.resource_url}}</div>
                <span class="ifNotEnabled" *ngIf="!integration.insertURL && !integration.resource_url" (click)="insertIntegrationURL(integration)">Insert project URL to enable {{integration.kind ? integration.kind.charAt(0).toUpperCase() + integration.kind.slice(1) : ''}}</span>
                <div class="inputIntegration-link" *ngIf="integration.insertURL">
                  <input #focusURLInput type="url" class="insertURL" [(ngModel)]="integration.inputURL"  [disabled]="integration.isSaveDisable" placeholder="Insert the {{integration.kind ? integration.kind + ' ': ''}}project link">
                  <button *ngIf="!integration.resource_url" type="button" class="saveButton" [disabled]="integration.isSaveDisable || integration.inputURL.trim().length === 0" (click)="addIntegration(integration)">Save</button>
                  <button *ngIf="integration.resource_url" type="button" class="saveButton" [disabled]="integration.isSaveDisable || integration.inputURL.trim().length === 0 || integration.resource_url === integration.inputURL.trim()" (click)="editIntegration(integration)">Save</button>
                </div>
              </div>
              <div class="actBlock">
                <div class="blockContent" *ngIf="i===0 && integration.resource_url">
                  <button type="button" class="addMultipleUrl" *ngIf="checkForAddUrl(item.kind)" (click)="addIntegrationLocally(item.kind)"><span class="iconb-plus"></span> add URL</button>
                  <em class="iconb-edit" (click)="editURL(integration)"></em>
                  <em class="iconb-bin" (click)="openDeletePopup(integration)"></em>
                </div>
                <div class="blockContent" *ngIf="i>0 && integration.resource_url">
                  <em class="iconb-edit" (click)="editURL(integration)"></em>
                  <em class="iconb-bin" (click)="openDeletePopup(integration)"></em>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="openConfirmPopUp" class="confirmPopUp">
  <div class="confirmPopUp-backdrop" (click)="closeConfirmPopUp()"></div>
  <div class="confirmPopUp-wrapper">
    <div class="bulkAction-Loader" *ngIf="waitForLoader">
      <img src="../../assets/images/storyBoardLoader.gif" alt="loader"/>
    </div>
    <div class="commonPopUp-title">Confirm</div>
    <div class="commonPopUp-text">Are you sure you want to delete this URL ?</div>
    <div class="commonPopUp-actionBtn">
      <span class="commonPopUp-submit" (click)="deleteIntegration()">Yes</span>
      <span class="commonPopUp-cancel" (click)="closeConfirmPopUp()">No</span>
    </div>
  </div>
</div>
