import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'search',
    pure: false
})
export class SearchPipe implements PipeTransform {
    transform(items: any[], searchText: string, key?: string): any[] {
        if (!items) return [];
        if (!searchText) return items;
        searchText = searchText.toLowerCase();
        return items.filter(it => {
          if (key) {
            const itKey = it[key].toLowerCase();
            return (itKey.includes(searchText));
          } else {
            const itKey = it.toLowerCase();
            return itKey.includes(searchText);
          }
        });
    }
}
