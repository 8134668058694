import { forwardRef, Inject, Injectable } from '@angular/core';
import { DataService } from './data.service';
import { HttpRequests } from './http-requests.service';
import { Router } from '@angular/router';
import {map} from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API_ROUTES } from '../apiRoutes';
import { environment } from '../../../environments/environment';
import { storyApprovalFilter } from '../dataTypes';

@Injectable()
export class StoryApprovalService {
    filters?: storyApprovalFilter = {
        epics: [],
        requestors: [],
        reviewers: [],
        request_type: [],
        requested_on: [],
        reviewed_on: [],
        status: []
    };

    paramKeyConfig = {
        epics: 'epic_ids',
        requestors: 'requestor_ids',
        reviewers: 'reviewer_ids',
        request_type: 'request_type',
        requested_on: 'created_on_date_range',
        reviewed_on: 'reviewed_on_date_range',
        status: 'status'
    };

    constructor(
        private dataService: DataService,
        private httpRequest: HttpRequests,
        private router: Router,
        public http: HttpClient
    ) {}

    // below function is used to add or remove filter
    addRemoveFilter(event, key, value) {
        if (this.filters[key]) {
            const index = this.filters[key].indexOf(value);
            if (key === 'requested_on' || key === 'reviewed_on') {
                this.filters[key] = [];
                if(event !== 'remove') {
                  this.filters[key].push(value);
                }
                return;
            }
            if (index === -1 && event.checked) {
                this.filters[key].push(value);
            } else if (index !== -1 && !event.checked) {
                this.filters[key].splice(index, 1);
            }
        }
    }

    // below function is used to reset filter
    resetFilter() {
        this.filters = {
            epics: [],
            requestors: [],
            reviewers: [],
            request_type: [],
            requested_on: [],
            reviewed_on: [],
            status: []
        };
    }

    // below function is used to return total number of filters applied
    totalfiltersApplied() {
        let totalLength = 0;
        for (const key in this.filters) {
          if (this.filters.hasOwnProperty(key) && Array.isArray(this.filters[key])) {
            totalLength += this.filters[key].length;
          }
        }
        return totalLength;
    }

    // function for return the number of filter applied in single key
    filtersApplied(key) {
        return (this.filters[key] && this.filters[key].length > 0) ? this.filters[key].length : 0;
    }
      
    buildFiltersData() {
        const data = {};
        const paramKey = {
          epic_ids: [],
          requestor_ids: [],
          reviewer_ids: [],
          request_type: [],
          created_on_date_range: [],
          reviewed_on_date_range: [],
          status: []
        };
      
        for (const key in this.filters) {
          if (this.filters.hasOwnProperty(key) && this.filters[key].length > 0) {
            const mappedKey = this.paramKeyConfig[key];
            if (mappedKey) {
              paramKey[mappedKey] = this.filters[key];
            }
          }
        }
      
        // Copy the properties from paramKey to data object
        for (const prop in paramKey) {
          if (paramKey.hasOwnProperty(prop) && paramKey[prop].length > 0) {
            data[prop + '[]'] = paramKey[prop];
          }
        }
        return data;
    }
      

    // below function is used to hit the api and parse response
    storyApprovalList(pageno) {
        const data = this.buildFiltersData();
        const API = this.dataService.apiUrl + API_ROUTES.STORY_APPROVAL() + '?page=' + pageno;
        return this
            .httpRequest
            .get(API, {params : data}, this.dataService.getRequestOptionArgs()).pipe(
                map(res => this.parseResponse(res)),
              );
    }

    // below function is used to get filter data and parse response
    getFilterData(scope, pageno, queryString) {
        const API = this.dataService.apiUrl + API_ROUTES.STORY_APPROVAL_FILTER_DATA(scope) + '?page=' + pageno + '&q=' + queryString;
        return this
            .httpRequest
            .get(API, this.dataService.getRequestOptionArgs()).pipe(
                map(res => this.parseResponse(res)),
            );
    }

    storyCompare(storyData) {
        const API = this.dataService.apiUrl + API_ROUTES.STORY_COMPARE(storyData.project_id, storyData.id);
        return this
          .httpRequest
          .get(API, this.dataService.getRequestOptionArgs());
    }
    
    actionOnStory(projectId, listId, status, reasonForRejection, statusKey) {
        let data:any = {
            story_approval: {
                [statusKey]: status
            }
        };
        data = reasonForRejection ? {...data,note:{note:reasonForRejection}} : data
        const API = this.dataService.apiUrl +  API_ROUTES.ACTION_ON_COMPARE_REQUEST(projectId, listId);
        return this.httpRequest
        .put(API, data, this.dataService.getRequestOptionArgs()).pipe(
        map(response => this.parseResponse(response)));
      }

      public parseResponse(res: any): any {
        return res;
      }

      stripTags(html: string): string {
        if (html === null) {
          return '';
        }
        return html.replace(/<[^>]*>/g, '');
      }

      calculateWordDiff(original: string, modified: string) {
        if ((original === null && modified === null)) {
          return { originalHtml: '', modifiedHtml: '' };
        }
      
        const originalWords = original ? this.stripTags(original).split(' ') : [];
        const modifiedWords = modified ? this.stripTags(modified).split(' ') : [];
      
        const dp: number[][] = [];
      
        for (let i = 0; i <= originalWords.length; i++) {
          dp[i] = [];
          for (let j = 0; j <= modifiedWords.length; j++) {
            dp[i][j] = 0;
          }
        }
        
        for (let i = 1; i <= originalWords.length; i++) {
          for (let j = 1; j <= modifiedWords.length; j++) {
            if (originalWords[i - 1] === modifiedWords[j - 1]) {
              dp[i][j] = dp[i - 1][j - 1] + 1;
            } else {
              dp[i][j] = Math.max(dp[i][j - 1], dp[i - 1][j]);
            }
          }
        }
      
        let i = originalWords.length;
        let j = modifiedWords.length;
        let originalHtml = '';
        let modifiedHtml = '';
        let addedText = '';
        let deletedText = '';
        let additions = 0;
        let deletions = 0;
    
        while (i > 0 || j > 0) {
          if (i > 0 && j > 0 && originalWords[i - 1] === modifiedWords[j - 1]) {
            if (addedText !== '') {
              modifiedHtml = '<span class="diffAdded">' + addedText + '</span> ' + modifiedHtml;
              additions++;
              addedText = '';
            }
            if (deletedText !== '') {
              originalHtml = '<span class="diffRemoved">' + deletedText + '</span> ' + originalHtml;
              deletions++;
              deletedText = '';
            }
            originalHtml = originalWords[i - 1] + ' ' + originalHtml;
            modifiedHtml = modifiedWords[j - 1] + ' ' + modifiedHtml;
            i--;
            j--;
          } else if (j > 0 && (i === 0 || dp[i][j - 1] >= dp[i - 1][j])) {
            addedText = modifiedWords[j - 1] + ' ' + addedText;
            j--;
          } else {
            deletedText = originalWords[i - 1] + ' ' + deletedText;
            i--;
          }
        }
      
        if (addedText !== '') {
          modifiedHtml = '<span class="diffAdded">' + addedText + '</span> ' + modifiedHtml;
          additions++;
        }
        if (deletedText !== '') {
          originalHtml = '<span class="diffRemoved">' + deletedText + '</span> ' + originalHtml;
          deletions++;
        }
      
        return {
          originalHtml: original === '' ? '' : originalHtml.trim(),
          modifiedHtml: modified === '' ? '' : modifiedHtml.trim(),
          additions: additions ? additions : 0,
          deletions: deletions ? deletions : 0
        };
      }
         
}