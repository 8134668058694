import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BaseComponent } from '../base.component';
import { TimingProjectsService, IProjectsList } from './../../services/projects.service';
import { TimingUsersService, IUsersList } from './../../services/users.service';
import moment from 'moment';
import { DateRange } from '../../models/date-range';
import { Project } from '../../models/project';
import { User } from '../../models/user';

@Component({
  selector: 'app-admin-timing-projects',
  templateUrl: 'projects.component.html',
  styleUrls: [
    '../../../admin.common.scss',
    'projects.component.scss'
  ]
})
export class TimingProjectsComponent extends BaseComponent implements OnInit {
  public projects: Project[] = [];
  public project: Project;
  public users: User[] = [];
  public dateRange: DateRange;
  public totalUsers: number;
  public totalTime: string;
  public isPending = true;
  public startLoader:boolean = false;

  constructor(private projectsService: TimingProjectsService,
              private usersService: TimingUsersService,
              private route: ActivatedRoute,
              private router: Router) {
    super();
  }

  ngOnInit() {
    this.startLoader = true;
    const id = +this.route.snapshot.paramMap.get('id');
    const from = moment().startOf('isoWeek');
    const to = moment(from).day(+7);
    this.dateRange = new DateRange(from, to);

    this.projectsService
      .getProjects()
      .subscribe(
        data => {
          this.projects = data.projects;
          if (id) {
            this.project = this.projects.find(x => x.id === id) || this.projects[0];
          } else {
            this.project = this.projects[0];
          }
          this.getUsers();
        });
  }

  onDateRangeChanged(dateRange: DateRange): void {
    this.dateRange = dateRange;
    this.getUsers();
  }

  onUsersPageChanged(page: number): void {
    this.currentUsersPage = page;
    this.getUsers();
  }

  getUsers(): void {
    this.isPending = true;
    const filter = {
      project: this.project.id,
      page: this.currentUsersPage,
      from: this.dateRange.formatedFrom,
      to: this.dateRange.formatedTo,
      hours: true
    };
    this.usersService
      .getUsers(filter)
      .subscribe(
        data => {
          this.users = data.users;
          this.totalUsers = data.totalUsers;
          this.totalTime = data.totalTime;
          this.totalUsersPages = data.pages;
          this.startLoader = false;
        });
  }

  goToUser(userId: number): void {
    const queryParams = { project: this.project.id };
    this.router.navigate(['admin', 'timing', 'users', userId], { queryParams: queryParams });
  }
}
