import { Component, OnInit, Input, EventEmitter } from '@angular/core';
import { UserService } from '../../../shared/services/user.service';
import { CommonForm } from '../../common/classes';
import moment from 'moment-timezone';
import { CustomToastService } from '../../../shared/services/custom-toast.service';
import { Router } from '@angular/router';

@Component({
  selector: 'admin-users-form',
  templateUrl: 'form.component.html',
  styleUrls: [
    '../../admin.common.scss',
    'form.component.scss'
  ],
  host: {
    '(document:click)': 'onClick($event)'
  }
})
export class AdminUsersFormComponent extends CommonForm implements OnInit {
  @Input('model') model: any;
  @Input('errors') outputErr: any;
  @Input('isPending') isPending: boolean = false;
  roleData = [];
  localeData = [];
  offsetTmz = [];
  selectDomainDD:boolean = false;

  constructor(public userService: UserService) {
    super();
  }

  ngOnInit() {
    this.getallRoleLocale('roles', 'locales');
    for (const i in moment.tz.names()) {
      if (i) {
        this.offsetTmz.push({'id': moment.tz.names()[i], 'value': moment.tz.names()[i] + ' (GMT' + moment.tz(moment.tz.names()[i]).format('Z') + ')'});

      }
      this.model.time_zone = (this.model.time_zone ? this.model.time_zone : 'US/Pacific');
    }

    this.model.selected_user_domain = this.model.selected_user_domain ? this.model.selected_user_domain : this.userService.partner.allowed_user_domain[0];
  }


  removeError(type) {
    if(this.outputErr && this.outputErr[type]) {
      delete this.outputErr[type];
    }
  }

  getallRoleLocale(roles, locales) {
    // For get all Roles

      ['admin', 'user'].forEach(role => {
        let obj = {id: '', value: ''};
        obj.value = role.charAt(0).toUpperCase() + role.slice(1);
        obj.id = role;
        this.roleData.push(obj);
      });
      if(!this.model.id) {
        this.model.role = 'admin';
      }
     

    // For get all Locale
    this.userService.getallLocale(locales).subscribe((res: any) => {
      this.localeData = res;
    });
  }

  checkEmail(email) {
    let re = /^(?=.{1,256})([a-zA-Z0-9][a-zA-Z0-9._%+-]*@[a-zA-Z0-9][a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$)/;
    return re.test(email);
  }
  openDomainSelection() {
    this.selectDomainDD = !this.selectDomainDD;
  }
  closeDomainSelection(user_domain?) {
    this.selectDomainDD = false;
    if (user_domain) {
      this.model.selected_user_domain = user_domain;
    }
  }

  onClick(event) {
    const element = event.target;
    if (!(element.classList.contains('iconb-dd-down') || element.classList.contains('selectedDomain'))) {
      this.closeDomainSelection();
    }
  }
}
