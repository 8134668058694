<div class="sprint-wrapper">
  <div class="topInfo-section">
    <div class="topActionButtons">
      <div class="tableTitle" *ngIf="projectService.sprintDetailPageTitle">Backlog ( {{storyListMeta?.total_count}} )</div>
      <span type="button" *ngIf="sprintId" class="collapse-expand-button" [ngClass]="{'toggleShape' : projectService.show_hide_backlog}" (click)="showHideBacklog()"><em class="iconb-left-arrow"></em></span>
      <div class="integrationSection-search">
        <input type="text" class="integrationSection-searchInput" (input)="checkifSearchInputEmpty()" (keyup.enter)="searchStory()" placeholder="Search story" [(ngModel)]= "searchStoryData"/>
        <button type="button" class="integrationSection-searchButton">
          <em class="iconb-search"></em>
        </button>
      </div>
      <div class="fliterButton-wapper" >
        <div class="filterButton" [class.buttonSelected]="showfilterList" (click)="toggelefilter()">
          <em class="iconb-filter"></em>
          <span class="filterbutton-title"><strong style="font-weight: 500" class="filterbutton-title">{{project ? project.filterCount : 0}}</strong> Filter</span>
        </div>
        <div class="filterBackdrop" *ngIf="showfilterList" (click)="closeFilter()"></div>
        <div class="filterDD" [@slideOut] *ngIf="showfilterList" [ngClass]="[showfilterList ? 'filterDD-open':'']">
          <app-story-filter [project]="project" [currentUser]="currentUser"></app-story-filter>
        </div>
      </div>
      <div class="sprint-sorting">
        <span class="selectedFilter" [ngClass]="{'buttActive':showSort}" (click)="openSort()"><em class="iconb-sorting"></em>  Sort </span>
        <div class="sprint-sorting-options" [@fadeInOut] *ngIf="showSort">
          <div class="sprint-sorting-option" [ngClass]="{'active': sort_by === 'updated_at'}" (click)="sortStoryList('updated_at')"><em class="iconb-calendar"></em> Date Updated</div>
          <div class="sprint-sorting-option" [ngClass]="{'active': sort_by === 'created_at'}" (click)="sortStoryList('created_at')"><em class="iconb-calendar"></em> Date Created</div>
          <div class="sprint-sorting-option" [ngClass]="{'active': sort_by === 'priority'}" (click)="sortStoryList('priority')"><em class="iconb-three-arrow-nav"></em> Priority</div>
          <div class="sprint-sorting-option" [ngClass]="{'active': sort_by === 'status'}" (click)="sortStoryList('status')"><em class="iconb-three-arrow-nav"></em> Status</div>
          <div class="sprint-sorting-option" [ngClass]="{'active': sort_by === 'story_type'}" (click)="sortStoryList('story_type')"><em class="iconb-three-arrow-nav"></em> Type</div>
        </div>
      </div>
      <div class="bulk-action-block" *ngIf="selected_stories.length > 0">
        <button [disabled]="storyListLoader" class="bulk-add-stories" type="button" (click)="addBulkStories()">Add Stories</button>
        <button [disabled]="storyListLoader" class="bulk-add-stories" type="button" (click)="resetStorySelection()">Deselect Stories</button>
      </div>
    </div>
  </div>

  <div class="releaseVersion-table"
    #scrollableDiv
    infiniteScroll
    [scrollWindow]="false"
    [infiniteScrollDisabled]="!isScrollFetchAvailable()"
    (scrolled)="getMoreSprintStories()">
    <div class="hscroll">
      <div class="is-collapse" *ngIf="projectService.show_hide_backlog">Backlog</div>
      <div class="releaseVersion-tableHead">
        <div class="tableHead-col1" [ngClass]="{'noCheck': !sprintId}">
          <div class="rowCheckBox" *ngIf="sprintId">
            <label class="customCheckForRowSelection">
              <input type="checkbox" [checked]="select_all_stories" (change)="allStorySelection($event)" class="story-selected-checkbox" hidden/>
              <span class="check-toggle"></span>
            </label>
          </div>
        </div>
        <div class="tableHead-col2">Status</div>
        <div class="tableHead-col2">
          <div class="topHead-label">P
            <span class="elementHover">Priority</span>
          </div>
        </div>
        <div class="tableHead-col2">
          <div class="topHead-label">T
            <span class="elementHover">Ticket Type</span>
          </div>
        </div>
        <div class="tableHead-col8">Story ID</div>
        <div class="tableHead-col9 sm">Title</div>
        <div class="tableHead-col5">Sprint</div>
        <div class="tableHead-col6">Story Type</div>
        <div class="tableHead-col8">Dev Release</div>
        <div class="tableHead-col10">Owner</div>
        <div class="tableHead-col10">Reviewer</div>
        <div class="tableHead-col3"></div>
      </div>
      <div class="releaseVersion-tableContent"
          [id]="'doneList'"
          cdkDropList
          #doneList="cdkDropList"
          [cdkDropListData]="sprintStories"
          [cdkDropListConnectedTo]="'todoList'"
          (cdkDropListDropped)="drop($event)" >
        <div class="ifNoSprint-exits" *ngIf="!storyListLoader && sprintStories.length === 0">
          <span class="ifNoSprint-text">No Story available.</span>
        </div>
        <div class="releaseVersion-tableRow" [ngClass]="{'bulk-selection-on': selected_stories.length > 0,'sub-task': story?.parent_id}" cdkDrag *ngFor="let story of sprintStories" [cdkDragDisabled]="selected_stories.length > 0" (click)="commonStoryService.toggleEditMode($event, story, project)">
          <div class="tableHead-col1" [ngClass]="{'noCheck': !sprintId}">
            <div class="rowCheckBox" *ngIf="sprintId">
              <label class="customCheckForRowSelection">
                <input type="checkbox" [checked]="story.isSelected" (change)="toggleStorySelection($event,story)" class="story-selected-checkbox" hidden/>
                <span class="check-toggle"></span>
              </label>
            </div>
          </div>
          <div class="tableHead-col2">
            <div class="tableCell-block">
              <div class="story-statusWrapper">
                <span class="story-statusIcon" [ngClass]="[story?.state]"></span>
                <span class="elementHover">{{story?.state}}</span>
              </div>
            </div>
          </div>
          <div class="tableHead-col2">
            <div class="story-priority" (click)="commonStoryService.openDDOptions('priority',story)">
              <em [class]="'priority-icon ' + story.storyPriorityType_icon(story.priority)"></em>
              <span class="elementHover" *ngIf="!story?.showPriority">{{story?.priority}}</span>
            </div>
            <div class="storyPriority-wrapper" [@fadeInOut2] *ngIf="story?.showPriority && commonStoryService.dd_type === 'priority'">
              <div class="storyPriority-option" *ngFor="let priority of storyPriorityOptions" (click)='commonStoryService.changeStoryPriority(priority.type, story)'>
                <em [class]="priority.icon"></em> {{ priority.type }}
              </div>
            </div>
          </div>
          <div class="tableHead-col2">
            <div class="story-type">
              <em [class]="story?.story_type_icon"></em>
              <span class="elementHover">{{story?.story_type}}</span>
              <span class="elementHover">{{(story && !story.parent_id) ? story?.story_type : 'Sub Task'}}</span>
            </div>
          </div>
          <div class="tableHead-col8">
            <div class="story-id" (click)="commonStoryService.copyStoryID(story)" [clip]="story?.title + ' - ' + getStoryURL(story?.id)">
              {{story?.id}} <span class="iconb-duplicate copy-bt"></span>
              <div *ngIf="story?.show_copy_tooltip" class="elementHover">Copied</div>
            </div>
          </div>
          <div class="tableHead-col9 sm">
            <div class="story-summary" *ngIf="!story?.showStoryTitleInput"><span class="story-title">{{story?.title}}</span><em class="iconb-edit-2 editTitle" (click)="showEditStoryTitleInput(story)"></em> </div>
            <div class="story-summary" *ngIf="story?.showStoryTitleInput">
              <input #makeFocusTitle id="focusTitle" type="text" maxlength="200" class="editName" (focusout)="commonStoryService.updateStoryTitle(story)" [(ngModel)]="story.title">
            </div>
          </div>
          <div class="tableHead-col5">
            <div class="story-summary"><span class="story-title">{{story.sprint && story.sprint.name ? story.sprint.name : '-'}}</span></div>
          </div>
          <div class="tableHead-col6">
            <div class="story-summary">{{story?.core? 'Core' : 'Custom'}}</div>
          </div>
          <div class="tableHead-col8"> {{(story && story.dev_release_at_date) ? commonStoryService.getDateFormat(story.dev_release_at_date) : '-'}} </div>
          <div class="tableHead-col10">
            <div class="nameBlock">
              <div class="userInitials noMember" *ngIf="!story?.owned_by_initials" (click)="commonStoryService.openDDOptions('owner',story)"><em class="userInitials-icon iconb-add-member"></em></div>
              <div class="userInitials" (click)="commonStoryService.openDDOptions('owner',story)"*ngIf="story?.owned_by_initials">{{story?.owned_by_initials?.substr(0,1)}}</div>
              <span class="elementHover" *ngIf="!story?.showOwnerList && story?.owned_by_name">{{story?.owned_by_name}}</span>
            </div>
            <div class="storyUserList-wrapper" [@fadeInOut2] *ngIf="story?.showOwnerList && commonStoryService.dd_type === 'owner'">
              <div class="storyUser-search">
                <input type="text" class="storyUser-searchInput" [(ngModel)]="searchOwner" placeholder="Search"/>
                <button type="button" class="storyUser-searchButton">
                  <em class="storyUser-searchButtonIcon iconb-search"></em>
                </button>
              </div>
              <div class="list-optionWrapper">
                <div class="storyUserList-option" *ngFor = "let user of project.users  | search: searchOwner: 'name' | squadMemberSearch: story; index as i;">
                  <div class="ifClickOutside nameBlock" [ngClass]="{'userNotActive': !project.isUserActive(user) || commonStoryService.checkSameUser(user,'owner',story, project)}" (click)="commonStoryService.setUser(user, 'Owner', story, project)">
                    <div class="ifClickOutside userInitials">{{user.initials.substr(0,1)}}</div>
                    <div class="ifClickOutside nameWithInitials">
                      <span class="ifClickOutside ifUserDeallocated" *ngIf="!project.isUserActive(user)">{{ project.userInactiveText(user) }}</span>
                      <span class="ifClickOutside ifUserDeallocated sameUser" *ngIf="commonStoryService.checkSameUser(user,'owner',story, project)">Owner and Reviewer cannot be the same.</span>
                      <span class="ifClickOutside selectUser-name">{{user?.name}}</span>
                      <span class="ifClickOutside selectUser-email">{{user?.email}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tableHead-col10">
            <div class="nameBlock">
              <div class="userInitials noMember" *ngIf="!story?.reviewer_initials" (click)="commonStoryService.openDDOptions('reviewer',story)"><em class="userInitials-icon iconb-add-member"></em></div>
              <div class="userInitials" *ngIf="story?.reviewer_initials" (click)="commonStoryService.openDDOptions('reviewer',story)">{{story?.reviewer_initials?.substr(0,1)}}</div>
              <span class="elementHover" *ngIf="!story?.showReviewerList && story?.reviewer_name">{{story?.reviewer_name}}</span>
            </div>
            <div class="storyUserList-wrapper" [@fadeInOut2] *ngIf="story?.showReviewerList && commonStoryService.dd_type === 'reviewer'">
              <div class="storyUser-search">
                <input type="text" class="storyUser-searchInput" [(ngModel)]="searchReviewer" placeholder="Search"/>
                <button type="button" class="storyUser-searchButton">
                  <em class="storyUser-searchButtonIcon iconb-search"></em>
                </button>
              </div>
              <div class="list-optionWrapper">
                <div class="storyUserList-option" *ngFor = "let user of project.users  | search: searchReviewer: 'name' | squadMemberSearch: story; index as i;">
                  <div class="ifClickOutside nameBlock" [ngClass]="{'userNotActive': !project.isUserActive(user) || commonStoryService.checkSameUser(user,'reviewer',story, project)}"  (click)="commonStoryService.setUser(user, 'Reviewer', story, project)">
                    <div class="ifClickOutside userInitials">{{user.initials.substr(0,1)}}</div>
                    <div class="ifClickOutside nameWithInitials">
                      <span class="ifClickOutside ifUserDeallocated" *ngIf="!project.isUserActive(user)">{{ project.userInactiveText(user) }}</span>
                      <span class="ifClickOutside ifUserDeallocated sameUser" *ngIf="commonStoryService.checkSameUser(user,'reviewer',story, project)">Owner and Reviewer cannot be the same.</span>
                      <span class="ifClickOutside selectUser-name">{{user?.name}}</span>
                      <span class="ifClickOutside selectUser-email">{{user?.email}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tableHead-col3">
            <div class="moreActions">
              <em class="iconb-more moreAction-bt" [ngClass]="{'makeActive':(story?.showMoreStoryAction && commonStoryService.dd_type==='more_action')}"  (click)="commonStoryService.openDDOptions('more_action',story,project)"></em>
              <div class="storyaction-wrapper" [@fadeInOut2] *ngIf="story?.showMoreStoryAction && commonStoryService.dd_type==='more_action'">
                <!--<div class="storyaction-option" (click)="toggleEditMode(story.id)"><em class="iconb-check-circle"></em> Create Subtask</div>-->
                <div class="storyaction-option" (click)="commonStoryService.storyEditMode(story.id, project)"><em class="iconb-edit-2"></em> Edit Story</div>
                <div class="storyaction-option" (click)="makeClone(story, true)"><em class="iconb-duplicate"></em> Duplicate</div>
                <div class="storyaction-option" *ngIf="!story.isSelected" (click)="commonStoryService.openArchivePopup(story, 'backlog_story')"><em class="iconb-archive"></em> Archive</div>
              </div>
            </div>
          </div>
        </div>
        <app-story-clone-popup *ngIf="projectService.cloneConfirmPopup" [project]="project" [fromBacklog]= true></app-story-clone-popup>
        <div class="mainProjectList-Loader" *ngIf="isPending">
          <img src="../../assets/images/storyBoardLoader.gif" alt=""/>
        </div>
        <!--<div class="no-notifications" *ngIf = "!moreAvailable">No more stories.</div>-->
      </div>
      <div class="sprintInitial-Loader" *ngIf="storyListLoader || projectService.storyAddedListLoader">
        <img src="../../assets/images/storyBoardLoader.gif" alt=""/>
      </div>
    </div>
  </div>
</div>


<div *ngIf="commonStoryService.OpenConfirmPopUp  && commonStoryService.story_belongs_to === 'backlog_story'" class="commonPopUp makeLayerUp">
  <div class="commonPopUp-backdrop" (click)="commonStoryService.closeArchivePopup()"></div>
  <div class="commonPopUp-wrapper">
    <div class="common-Loader commonPopup-Loader" *ngIf="commonStoryService.waitForLoader">
      <img src="../../assets/images/storyBoardLoader.gif" alt=""/>
    </div>
    <div class="commonPopUp-title">Archive</div>
    <div class="commonPopUp-text">The status of the story is {{ commonStoryService.archiveStory?.state }} and time tracked is {{ sharedService.totalTimeTracked(commonStoryService.archiveStory) }}, are you sure you want to archive it?</div>
    <div class="commonPopUp-actionBtn">
      <span class="commonPopUp-submit-s" (click)="commonStoryService.archive('backlog_story')">Archive</span>
      <span class="commonPopUp-cancel-s" (click)="commonStoryService.closeArchivePopup()">Cancel</span>
    </div>
  </div>
</div>

<app-accept-reject-popup *ngIf="projectService.acceptRejectPopup" [popupType]="projectService.acceptRejectPopupType" [project]="project"></app-accept-reject-popup>
