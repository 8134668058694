 <!--Accept Stories popup Starts-->
 <div class="bulkaction-popUp" *ngIf="projectService.acceptRejectPopup && SelectedStories().length > 0">
    <div class="bulkaction-popUpBackdrop" (click)="closeAcceptRejectConfirmation()"></div>
    <div class="bulkaction-popUp-wrapper deleteStories">
      <div class="bulkAction-Loader" *ngIf="waitForLoader">
        <img src="../../assets/images/storyBoardLoader.gif" alt="loader"/>
      </div>
      <div class="bulkaction-popUp-title" *ngIf="popupType === 'reject'">Reject Multiple Stories</div>
      <div class="bulkaction-popUp-title" *ngIf="popupType === 'accept'">Accept Multiple Stories</div>
      <span class="bulkaction-popUp-Text1" *ngIf="popupType === 'accept'">
        You have selected {{SelectedStories().length}} {{SelectedStories().length > 1 ? 'Stories': 'Story'}} for acceptance. <span *ngIf="projectService.storiesCannotBeAccepted.length > 0">{{projectService.storiesCannotBeAccepted.length}} {{projectService.storiesCannotBeAccepted.length > 1 ? 'Stories': 'Story'}} will not 
          be accepted due to below reasons:
          <ul>
            <li *ngIf="this.project.external()">- Missing commit ID</li>
            <li>- Owner has not delivered the story</li>
            <li>- Reviewer missing</li>
            <li>- Missing feature tagging</li>
            <li *ngIf="!projectService.subtaskSelected">- Pending subtasks</li>
            <li *ngIf="this.project.external()">- In BB development flow</li>
            <li>- You are not the reviewer</li>
            <li *ngIf="this.project.external()">- Story is in Draft</li>
          </ul></span>
      </span>
      <span class="bulkaction-popUp-Text1" *ngIf="popupType === 'reject'">
        You have selected {{SelectedStories().length}} {{SelectedStories().length > 1 ? 'Stories': 'Story'}} for rejection. <span *ngIf="projectService.storiesCannotBeRejected.length > 0">{{projectService.storiesCannotBeRejected.length}} {{projectService.storiesCannotBeRejected.length > 1 ? 'Stories': 'Story'}} will not 
          be rejected due to below reasons:
          <ul>
            <li *ngIf="this.project.external()">- Missing commit ID</li>
            <li>- Owner has not delivered the story</li>
            <li>- Reviewer missing</li>
            <li>- Missing feature tagging</li>
            <li *ngIf="this.project.external()">- In BB development flow</li>
            <li>- You are not the reviewer</li>
            <li *ngIf="this.project.external()">- Story is in Draft</li>
          </ul></span>
      </span>
      <div class="selectedStoriesList-wrapper"  *ngIf="projectService.storiesCanBeAccepted && projectService.storiesCanBeAccepted.length > 0 && popupType === 'accept'">
        <span class="bulkaction-popUp-Text1">Below {{projectService.storiesCanBeAccepted.length > 1 ? 'stories': 'story'}} can be accepted:</span>
        <div class="selectedStoriesList" *ngFor = "let story of projectService.storiesCanBeAccepted">
          <div class="selectedStoriesList-left">
            <div class="story-typeIcon">
              <em class="iconb-task featureIcon" *ngIf = "story.story_type === 'task'"></em>
              <em class="iconb-bug bugIcon" *ngIf = "story.story_type == 'bug'"></em>
            </div>
            <div class="story-NameBox">
              <span class="selected-storyName">{{story.title}}</span>
              <span class="selected-storyEpicName" *ngIf = "getFullEpic(story) != ''">{{getFullEpic(story)}}</span>
            </div>
          </div>
          <div class="selectedStoriesList-right">
            <div class="deleteSelection-check">
              <label class="customCheckFor-deleteSelection">
                <input type="checkbox" checked class="delete-selected-checkbox" (change) = "toggleAcceptRejectSelection($event, story)" hidden/>
                <span class="check-toggleDeleteSelection"></span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="selectedStoriesList-wrapper"  *ngIf="projectService.storiesCanBeRejected && projectService.storiesCanBeRejected.length > 0 && popupType === 'reject'">
        <span class="bulkaction-popUp-Text1">Below {{projectService.storiesCanBeRejected.length > 1 ? 'stories': 'story'}} can be rejected:</span>
        <div class="selectedStoriesList" *ngFor = "let story of projectService.storiesCanBeRejected">
          <div class="selectedStoriesList-left">
            <div class="story-typeIcon">
              <em class="iconb-task featureIcon" *ngIf = "story.story_type === 'task'"></em>
              <em class="iconb-bug bugIcon" *ngIf = "story.story_type == 'bug'"></em>
            </div>
            <div class="story-NameBox">
              <span class="selected-storyName">{{story.title}}</span>
              <span class="selected-storyEpicName" *ngIf = "getFullEpic(story) != ''">{{getFullEpic(story)}}</span>
            </div>
          </div>
          <div class="selectedStoriesList-right">
            <div class="deleteSelection-check">
              <label class="customCheckFor-deleteSelection">
                <input type="checkbox" checked class="delete-selected-checkbox" (change) = "toggleAcceptRejectSelection($event, story)" hidden/>
                <span class="check-toggleDeleteSelection"></span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="bulkaction-popUp-actionBtn">
        <button class="bulkaction-popUp-submit" *ngIf="popupType === 'accept'" (click) = "bulkAcceptReject('accepted')" type="button" [disabled] = "projectService.togglestoryAccept.length === 0">Accept</button>
        <button class="bulkaction-popUp-submit" *ngIf="popupType === 'reject'" (click) = "bulkAcceptReject('rejected')" type="button" [disabled] = "projectService.togglestoryReject.length === 0">Reject</button>
        <button class="bulkaction-popUp-cancel" type="button" (click)="closeAcceptRejectConfirmation()">Cancel</button>
      </div>
    </div>
  </div>
  <!--Accept Stories popup Ends-->