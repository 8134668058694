import { Injectable, Injector } from '@angular/core';
import {HttpHeaders} from '@angular/common/http';
import 'rxjs/add/operator/map';
import 'rxjs/Rx';
import {environment} from '../../../environments/environment';
import { UserService } from './user.service';

@Injectable()
export class DataService {
  public authToken: string;
  private userService: any;
  public user_cookie = null;
  public cookie_domain = null;

  constructor(private injector: Injector) {
  }

  public getRequestOptionArgs(contentType?: string, token?: boolean, body?: any, type?: any, resp?, text?, params?: any, d_text?) {
    this.userService = this.injector.get(UserService);
    this.userService.signOutDeveloperAfterSetTime();
    let httpOptions: any;
    if (resp) {
       httpOptions = {  headers:  new HttpHeaders(), observe: 'response'};
    } else {
       httpOptions = {  headers:  new HttpHeaders()};
    }
    if (contentType) {
      httpOptions.headers = httpOptions.headers.set('Content-Type', contentType);
    }
    if (text) {
      httpOptions = {  headers:  new HttpHeaders(), responseType: 'text'};
    }
    if (d_text) {
      httpOptions = {  headers:  new HttpHeaders(), responseType: 'arraybuffer'};
    }
    if (params) {
      httpOptions.params = params;
    }

    let user = this.userService.getUser();
    if(!user) {
      if(this.userService.getUserFromLocalStorage()) {
        const userCookies = {user: this.userService.getUserFromLocalStorage(), designerLogin: false, 'access-token': this.userService.getauthTokenFromLocalStorage(), uid: this.userService.getUidFromLocalStorage(), client: this.userService.getClientFromLocalStorage()}
        document.cookie = `${this.user_cookie}=${JSON.stringify(userCookies)}; path=/; domain=${this.cookie_domain};`;
        user = this.userService.getUser();
      }
    }
    if (user) {
      const authToken = this.userService.getAuthToken();
      const uid = this.userService.getUid();
      const client = this.userService.getClient();
      if (authToken) {
        httpOptions.headers = httpOptions.headers.set('access-token', authToken);
        httpOptions.headers = httpOptions.headers.set('uid', uid);
        httpOptions.headers = httpOptions.headers.set('client', client);
      }
    }
    if (type) {
      httpOptions.headers = httpOptions.headers.set(Object.keys(type)[0], type[Object.keys(type)[0]]);
    }
    if (token) {
      httpOptions.headers = httpOptions.headers.set('Authorization', 'Basic ' + btoa(environment.hive_username + ':' + environment.hive_password));
    }
    return httpOptions;
  }

  get apiUrl() {
    return environment.apiBase + environment.apiPath;
  }

  parseQueryParams(url, filter) {
    let apiUrl = '';
    for (let key in filter) {
      let i = Object.keys(filter).indexOf(key);
      if (i === 0) {
        apiUrl = apiUrl + '?';
        apiUrl = apiUrl + key + '=' + filter[key];
      } else {
        apiUrl = apiUrl + '&' + key + '=' + filter[key];
      }
    }
    return url + apiUrl;
  }

  public setDomain(){
    const hostfromurl = window.location.hostname;
    this.user_cookie = environment.USER_COOKIE;
    if(hostfromurl === "localhost"){this.cookie_domain = "localhost";}
    else {
      const separate = hostfromurl.split(".");
      separate.shift();
      const currentDomain = separate.join('.');
      this.cookie_domain = "."+currentDomain;
    }
  }
}
