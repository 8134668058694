import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { API_ROUTES } from '../../../shared/apiRoutes';
import { HttpRequests } from '../../../shared/services/http-requests.service';
import { DataService } from '../../../shared/services/data.service';
import { HttpClient } from '@angular/common/http';

const ROUTES = {
  MEMBERSHIPS: 'admin/memberships.json',
  MEMBERSHIP: (id) => `admin/memberships/${id}`,
  AVAILABLE_PROJECTS: (user_id) => `admin/memberships/available_projects/${user_id}`,
  AVAILABLE_USERS: (project_id) => `admin/memberships/available_users/${project_id}`,
  EXPORT_USERS: (project_id) =>  `admin/memberships.csv?project_id=${project_id}`
};

@Injectable()
export class CommonMembershipsService {
  constructor(
    private httpRequest: HttpRequests,
    private http: HttpClient,
    private dataService: DataService) {}

  getMemberships(queryParams) {
    const API = this.dataService.apiUrl + ROUTES.MEMBERSHIPS;
    const url = this.dataService.parseQueryParams(API, queryParams);

    return this
      .httpRequest
      .get(url, this.dataService.getRequestOptionArgs());
  }

  getMembership(id: number) {
    const API = this.dataService.apiUrl + ROUTES.MEMBERSHIP(id);
    return this
      .httpRequest
      .get(API, this.dataService.getRequestOptionArgs());
  }

  createMembership(membership) {
    const API = this.dataService.apiUrl + ROUTES.MEMBERSHIPS;
    return this
      .httpRequest
      .post(API, membership, this.dataService.getRequestOptionArgs());
  }

  updateMembership(id: number, membership) {
    const API = this.dataService.apiUrl + ROUTES.MEMBERSHIP(id);
    return this
      .httpRequest
      .put(API, membership, this.dataService.getRequestOptionArgs());
  }

  deleteMembership(id: number) {
    const API = this.dataService.apiUrl + ROUTES.MEMBERSHIP(id);
    return this
      .httpRequest
      .delete(API, this.dataService.getRequestOptionArgs());
  }

  availableProjects(user_id: number) {
    const API = this.dataService.apiUrl + ROUTES.AVAILABLE_PROJECTS(user_id);
    return this
      .httpRequest
      .get(API, this.dataService.getRequestOptionArgs());
  }

  availableUsers(project_id: number) {
    const API = this.dataService.apiUrl + ROUTES.AVAILABLE_USERS(project_id);
    return this
      .httpRequest
      .get(API, this.dataService.getRequestOptionArgs());
  }
  exportFileMember(project_id: number) {
    const API = this.dataService.apiUrl + ROUTES.EXPORT_USERS(project_id);
    return this.http.get(API, this.dataService.getRequestOptionArgs('', false, '', '', '', true));
  }

  resendInviteToUser(membership, project_id: number) {
    const data = {
      membership: {
        user_id: membership.user_id
      }
    };

    return this.httpRequest
      .post(API_ROUTES.RESEND_INVITE(project_id), data, this.dataService.getRequestOptionArgs()).pipe(
      map(res => this.parseResponse(res)));
  }

  public parseResponse(res: any): any {
    return res;
  }
}
