import { BaseModel } from './baseModel';
import {SprintData, SprintStoriesStatusData, SprintUpdateUserData} from "../dataTypes";
import moment from 'moment';

export class Sprint extends BaseModel implements SprintData {
  description?: string;
  clone_description?: string;
  id?: number;
  name?: string;
  clone_name?: string;
  number?: number;
  project_id?: number;
  started_by?: SprintUpdateUserData;
  status?: string;
  started_at?: Date;
  updated_at?: Date;
  updated_by?: SprintUpdateUserData;
  stories_status?: SprintStoriesStatusData;
  showMoreAction?: boolean;
  stories_count?: number;
  showInput?: boolean;
  showDescInput?:boolean;
  projected_end_date?: any;
  projected_start_date?: any;
  internal_demo_scheduled_at?: any;
  external_demo_scheduled_at?: any;
  clone_projected_end_date?: any;
  clone_projected_start_date?: any;
  todayDate?: Date = new Date();
  WEEKEND_DAYS = [0, 6];
  numberOfWorkdays?: number;
  no_of_states?: number;

  constructor(data: SprintData) {
    super(data);
    this.description = data.description;
    this.clone_description = data.description;
    this.id = data.id;
    this.name = data.name;
    this.clone_name = data.name;
    this.number = data.number;
    this.project_id = data.project_id;
    this.started_by = data.started_by;
    this.status = data.status;
    this.updated_at = data.updated_at;
    this.started_at = data.started_at;
    this.stories_count = data.stories_count;
    this.showMoreAction = false;
    this.showInput = false;
    this.showDescInput = false;
    this.projected_end_date = data.projected_end_date;
    this.projected_start_date = data.projected_start_date;
    this.clone_projected_end_date = data.projected_end_date;
    this.clone_projected_start_date = data.projected_start_date;
    this.internal_demo_scheduled_at = data.internal_demo_scheduled_at;
    this.external_demo_scheduled_at = data.external_demo_scheduled_at;
    data.stories_status && (this.stories_status = data.stories_status);

    // lets find out the number of keys has values in stories_status object
    if(this.stories_status) {
      this.no_of_states = 0;
      let count = 0;
      for (let key in this.stories_status) {
        if (this.stories_status.hasOwnProperty(key)) {
          if(this.stories_status[key] > 0) {
            count++;
          }
        }
      }
      this.no_of_states = count;
    }

    data.updated_by && (this.updated_by = data.updated_by);
    this.setWeekDays(this.projected_start_date, this.projected_end_date);
  }
  
  sprintStatus() {
    switch (this.status) {
      case 'running':
        return 'Sprint is running';
      case 'draft':
        return 'Sprint is in draft state';
      case 'paused':
        return 'Sprint is paused';
      case 'completed':
        return 'Sprint is completed';
      default:
        return '';
    }
  }

  getDateFormat(date_generic) {
    return moment(date_generic).format('DD/MM/YYYY');
  }

  getClassName() {
    return "Sprint";
  }

  checkSprintStartingDate(){
    if(this.getDateFormat(this.projected_start_date) !== this.getDateFormat(this.todayDate)) {
      return 'You are starting sprint on ' + this.getDateFormat(this.todayDate) + ' but it was projected to be started on ' + this.getDateFormat(this.projected_start_date) + '. Are you sure you want to start the sprint?'
    } else{
      return 'Are you sure you want to start the sprint?'
    }
  }

  getNumberOfWorkdays(startDate: Date, endDate: Date): number {
    let count = 0;
    let currentDate = startDate;
    while (currentDate <= endDate) {
      if (this.WEEKEND_DAYS.indexOf(currentDate.getDay()) === -1) {
        count++;
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return count;
  }

  setWeekDays(start_date, end_date) {
    const startDate = new Date(start_date);
    const endDate = new Date(end_date);
    this.numberOfWorkdays = this.getNumberOfWorkdays(startDate, endDate);
  }

  futureEndDate(endDate){
    const todayDate = new Date;
    const formattedEndDate = new Date(endDate);
    return formattedEndDate > todayDate;
  }
}
