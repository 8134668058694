import { NotificationService } from './services/notification.service';
import {User } from './models/user';
import {Note} from './models/note';
import { Story } from './models/story';
import { Epic } from './models/epic';
import { Project } from './models/project';
import { Membership } from './models/membership';
import { ProjectColumn } from './models/column';
import {Integration} from './models/integration';
import { AcceptanceCriteria } from './models/acceptanceCriteria';

export interface PolicyData {
  role: String;
  project_admin: boolean;
  skill: String;
  policies: { [key: string]: any };
}

export interface EpicData {
  id?: number;
  title: String;
  description?: String;
  project?: Project;
  project_id?: number;
  story_ids?: Array<number>;
  notes?: Array<any>;
  updated_at?: Date;
  created_at?: Date;
  isSelected?: boolean;
  internal?: boolean;
  builder_feature_id?: number;
  min_estimate?: number;
  mid_estimate?: number;
  max_estimate?: number;
  estimate?: number;
  tracked_time?: number;
  accepted_stories_count?: number;
  stories_count?: number;
  studio_feature_count?: number;
  trends?: any;
  total_estimate?: number;
  featureDetailsData?: any;
  bb_status?: String;
  bb_design_infos?: Array<any>;
  default_bb_design_infos?: Array<any>;
  progress?: any;
  status?: string;
  story_completion?: boolean;
  task_done_count?: number;
  draft_stories_count?: number;
  certification_type?: string;
  group_no?: number;
}

export interface ActivityDetail {
  action: string;
  field_name: string;
  field_value: string;
  content_type: string;
  attachment_url: string;
}

export interface IntegrationKindData {
  kind?: string;
  integrations?: Array<Integration>;
}

export interface IntegrationData {
  id: number;
  kind?: string;
  created_at?: string;
  resource_url?: string;
  insertURL?: boolean;
  inputURL?: string;
  isSaveDisable?: boolean;
}

export interface ReplyData {
  id?: number;
  parent_id?: number;
  show_mention?: boolean;
  created_at?: string;
  maintain_mentions?: any;
  note?: string;
  user_id?: number;
  user_name?: string;
  user_initials?: string;
  user_email?: string;
  openEditReply?: boolean;
  display_reply?: string;
  display_reply_html?: string;
  prevReplyText: string;
  disableEditReplyButton?: boolean;
}

export interface DocumentData {
  id?: number;
  name?: string;
  object_id?: number;
  object_type?: string;
  remote_url?: string;
  size?: number;
  content_type?: string;
  object?: any;
  progress?: any;
}

export interface ResetPasswordData {
  email: string;
}

export interface UpdatePasswordData {
  password: string;
  passwordConfirmation: string;
  passwordCurrent?: string;
  userType?: string;
  resetPasswordToken?: string;
}

export interface RegisterData {
  login: string;
  email: string;
  initials?: string;
  id?: string;
  password: string;
  password_confirmation: string;
  name?: string;
  userType?: string;
  confirm_success_url?: string;
}

export interface UserData {
  id: number;
  provider?: string;
  uid?: string;
  skill?: string;
  name: string;
  nickname?: string;
  username?: string;
  initials?: string;
  image?: any;
  email: string;
  role?: string;
  name_or_email?: string;
  most_recent_activities?: Array<any>;
  last_time_active?: String;
  tracked_times?: Array<any>;
  is_confirmed?: boolean;
  reviewer?: boolean;
  deleted_at?: Date;
  pod_role?: string;
}

export interface PartnerData {
  id: number;
  company_name: string;
  name: string;
  website_link: string;
  frontend_url: string;
  code: string;
  logo_url;
  allowed_user_domain: Array<string>;
}

export interface MembershipData {
  id?: number;
  role: string;
  user?: any;
  user_id?: number;
  project_id?: number;
  project?: Project;
  editable?: boolean;
  accepted_at?: any;
  created_at: any;
  added_on?: String;
  last_time_active?: String;
  member_card?: any;
  recent_activities?: Array<any>;
  invoice_activity?: any;
  active?: boolean;
  developer_working_schedules?:Array<any>;
  pod_role?: string;
}

export interface ActivityData {
  story_id?: number;
  commit_id?: string;
  commit_url?: string;
  story_name?: string;
  added_on?: string;
  user_name?: string;
  user_initials?: string;
  action_name?: string;
  action_type?: string;
  content_type?: string;
  attachment_url?: string;
  created_on?: string;
  details?: ActivityDetail[];
  toggleHistory?: Boolean;
  moved_subtask?: {
    from_name?: string,
    from_url?: string,
    to_name?: string,
    to_url?: string
  };
}

export interface TimelineActivityData {
  title?: string;
  created_on?: string;
  details?: Array<any>;
}

export interface StoryData {
  title?: string;
  list_id?: number;
  epic_id?:any;
  test_case_id?:number;
  is_epic_added?:boolean;
  testCases?:Array<any>;
  test_cases?:Array<any>;
  // match_found?:boolean;
  st_platform_names?: string;
  matching_story?: {id: number, title: string, catalog_story_id: number, description: string, difference: string, similarity: string};
  column_name?: string;
  attachments?: Array<any>;
  showQuickAct?: boolean;
  showDropDown?: boolean;
  isQuickActionPerformed?: boolean;
  active_blockers?: any;
  progress?: number;
  commits?: Array<any>;
  commits_count?: number;
  state_events?: any;
  reviewer_estimate_changed?:boolean;
  // severity_icon?: string;
  story_type_icon?: string;
  total_estimate?: number;
  parent_id?: number;
  accepted_at?: Date;
  created_at?: Date;
  updated_at?: Date;
  owned_by_change?: boolean;
  state_change?: boolean;
  type_change?: boolean;
  description?: string;
  project_id?: number;
  story_type?: string;
  priority?: string;
  states?: string;
  // severity?: string;
  requested_by_id?: number;
  current_reviewer_timer_activity?: TimerActivityData;
  owned_by_id?: number;
  owned_by_name?: string;
  owner_email?: string;
  owned_by_initials?: string;
  reviewer_id?: number;
  reviewer_name?: string;
  reviewer_email?: string;
  reviewer_initials?: string;
  reviewer_estimate?: number;
  reviewer_elapsed_time_total?: number;
  phase?: string;
  task?: string;
  task_execution?: string;
  requested_by_name?: string;
  requested_by_email?: string;
  requested_by_initials?: string;
  estimate?: number;
  state?: string;
  prev_state?: string;
  position?: number;
  id?: number;
  done_tasks_count?: number;
  total_tasks_count?: number;
  timer_elapsed_total?: number;
  current_timer_activity?: TimerActivityData;
  labels?: string | Array<any>;
  epics?: Array<any>;
  project?: Project;
  notes?: Array<any>;
  notes_count?: number;
  blockers?: Array<any>;
  merge_requests?: Array<any>;
  blockers_count?: number;
  blocked_stories?: Array<any>;
  isSelected?: boolean;
  aha_reference_num?: number;
  aha_url?: string;
  sub_tasks?: Array<any>;
  task_format?: any;
  sketch_status?: string;
  design_status?: string;
  story_acceptable?: boolean;
  deleted_at?: any;
  platform_ids?: Array<number>;
  formatted_platforms?: Array<any>;
  selectedDevicePlatform?: string;
  core?: boolean;
  parent_core?: boolean;
  dev_type?: string;
  isQACore?: boolean;
  hasStoryPlatforms?: boolean;
  inactive?: boolean;
  storyDevicePlatformsData?: Array<DevicePlatformData>;
  wireframe?: boolean;
  client_name?: string;
  created_from?: string;
  studio_role_id?: number;
  bug_reason_id?: number;
  bug_reason_detail_id?: number;
  previous?: {id?: number, title?: string};
  next?: {id?: number, title?: string};
  request_for_review_count?: number;
  reviewed_enabled_at?: boolean;
  sync_available?: boolean;
  catalog_story_id?: number;
  bug_related_to_story?: {id?: number, title?: string};
  related_to_story_id?: number;
  reason_for_creation?: string;
  show_reason_dd?: boolean;
  story_create_reason_for_bulk_duplicate?: string;
  show_other_reason_input?: boolean;
  bulk_story_selected_reason?: string;
  other_reason_input?: string;
  confirmed_by_id?: number;
  confirmed?: boolean;
  ready_for_certification?:boolean;
  requested_deleted_at?: Date;
  reason_for_update?: string;
  pre_conditions?: string;
  post_conditions?: string;
  test_steps?: string;
  test_data?: string;
  test_cases_count?: number;
  expected_results?: Array<any>;
  acceptance_criteria?:  Array<any>;
  show_create_criteria?: boolean;
  description_as_a?: string;
  description_i_want?: string;
  description_so_that?: string;
  status?: string;
  // acceptance_criteria_temp?:  Array<CriteriaData>;
  related_stories?: Array<any>;
  dev_release_at?: any;
  sprint_id?: number;
  role_name?: string;
  enable_ready_for_certification?: boolean;
  done_test_cases_count?: number;
  sprint?:any;
  show_ready_for_certification_cta?: boolean;
  detached_platform_ids?: Array<any>;
  url?: string;
  demo_video_url?: string;
  accepted_demo_video_url?: string;
  // duplicate_sub_tasks?:boolean;
  // duplicate_parent_only?:boolean;
  release_id?: number;
}

export interface CriteriaData {
  id?: number;
  story_id?: number;
  title?: string;
  cmt_acceptance_criteria_id?: number;
  created_at?: Date;
  updated_at?: Date;
  description_for_given?: string;
  description_for_then?: string;
  description_for_when?: string;
  criteria_loader?:boolean;
  reason_for_update?: string;
  old_title?: string;
  old_description_for_given?: string;
  old_description_for_then?: string;
  old_description_for_when?: string;
  criteria_description_edited?: boolean;
}

// export interface 

export interface DevicePlatformData {
  device?: string;
  platforms?: Array<PlatformData>;
}

export interface PlatformData {
  display_name?: string;
  id?: number;
}

export interface TimerActivityData {
  id: number;
  started_at: string;
  description: string;
}

export interface ProjectData {
  graph_options?: any;
  chore_click?: boolean;
  bug_click?: boolean;
  sideeffect_click?: boolean;
  favorite?: boolean;
  graph_details?: any;
  recorded_time?: string;
  stories_count?: number;
  story_type?: any;
  stories_status?: any;
  supports_bb?: boolean;
  is_dragg?: boolean;
  is_bulk?: boolean;
  id?: number;
  name?: string;
  last_changeset_id?: number;
  point_values?: Array<number>;
  start_date?: string;
  memberships_count?: number;
  membership_data?: Array<any>;
  countSelectedStories?: number;
  project_brief?: String;
  project_brief_attachments?: Array<any>;
  landing_url?: String;
  internal_mattermost_channel_url?: String;
  builder_now_instant_URL?: String;
  builder_now_custom_URL?: String;
  branding_id?: number;
  branding_now_pid?: number;
  branding_project_id?: number;
  branding_created_at?: String;
  branding_updated_at?: String;
  branding_logo_attachments?: Array<any>;
  branding_font_attachments?: Array<any>;
  branding_colour_attachments?: Array<any>;
  branding_icon_attachments?: Array<any>;
  branding_illustration_attachments?: Array<any>;
  branding_other_attachments?: Array<any>;
  phases?: Array<String>;
  tracker_phases?: Array<any>;
  internal?: boolean;
  dashboard_project_id?: number;
  squad_name?: string;
  build_card_id?: number;
  platform_names?: String;
  cpe_name?: string;
  cpe_initials?: string;
  cte_name?: string;
  cte_initials?: string;
  total_tracked_time?: string;
  total_approved_time?: string;
  invested_hours?: string;
  creator_tracked_time_data?: any;
  formatted_platforms?: Array<any>;
  status?: string;
  projectDevicePlatformsData?: Array<DevicePlatformData>;
  progress?: any;
  progress_available?: boolean;
  studio_roles?: Array<StudioRole>;
  primary_managers?: Array<PrimaryManagers>;
  config?: any;
  bug_reasons?:Array<BugReasons> ;
  alias_name?:string;
  swimlane_count?: number;
  swimlanes?: Array<any>;
}

export interface BugReasons{
  id?: number;
  title?: string;
  parent_id?: number;
}

export interface ProjectBriefDocument {
  created_at?: Date;
  folder_files?: any;
  folderable_id?: number;
  folderable_type?:String;
  id?: number;
  name?: String;
  owner_id?: number;
  owner_type?: String;
  visibility_type?: String;
  owner?: { email: String, name: String};
}

export interface SameFeaturePhaseStories {
  id: number,
  owned_by_initials: String,
  owned_by_name: String,
  requested_by_initials: String,
  requested_by_name: String,
  title: String,
  state: String
}

export interface PrimaryManagers{
  email?: string;
  initials?: string;
  name?: string;
  pod_role?: string;
}

export interface StudioRole {
  studio_role: {
    created_at?: Date;
    id?: number;
    name?: string;
    pm_dashboard_id?: number;
    project_id?: number;
    studio_id?: number;
    updated_at?: Date;
  };
}

export interface PlatformData {
  id?: number;
  display_name?: string;
}

export interface NoteData {
  created_at?: Date;
  maintain_mentions?: any;
  show_mention?: boolean
  open_edit?: boolean;
  display_note_html?: string;
  display_note?: string;
  formatted_replies?: ReplyData[];
  errors?: { [key: string]: any };
  id?: number;
  metadata?: { [key: string]: any };
  note?: string;
  note_type?: string;
  story_id?: number;
  updated_at?: Date;
  user_id?: number;
  user_name?: string;
  user_email?: string;
  user_initials?: string;
  story?: Story;
  project?: Project;
  documents?: Array<any>;
  isReadOnly?: boolean;

  // adding variables for local use (done while optimizing note component)
  editCommentuploadProcessStarted?: boolean;
  uploaderArr?: Array<any>;
  attachmentPreviewUrls?: Array<any>;
  removeDocuments?: Array<any>;
  prevNoteText?: string;
  prevNoteDocuments?: Array<any>;
  replyNote?: string;
  currentReply?: ReplyData;
  showMentionReply?: boolean;
  replyMaintainMentions?: any;
  showReplyInput?: boolean;
  openCommentReply?: boolean;
  prevOpenCommentReplyStatus?: boolean;
  disableReplyButton?: boolean;
  user_type?: string;
  release_id?: number;
}

export interface SprintData {
  description?: string;
  id?: number;
  name?: string;
  number?: number;
  project_id?: number;
  started_at?: Date;
  started_by?: SprintUpdateUserData;
  status?: string;
  updated_at?: Date;
  updated_by?: SprintUpdateUserData;
  stories_status?: SprintStoriesStatusData;
  stories_count?: number;
  projected_end_date?: Date;
  projected_start_date?: Date;
  internal_demo_scheduled_at?: Date;
  external_demo_scheduled_at?: Date;
}
export interface SprintUpdateUserData {
  email?: string;
  guid?: any;
  id?: number;
  initials?: string;
  internal?: boolean;
  name?: string;
  role?: string;
  skill?: string;
  time_zone?: string;
  username?: string;
}
 export interface  SprintStoriesStatusData {
   accepted?: number;
   delivered?: number;
   finished?: number;
   rejected?: number;
   started?: number;
   unscheduled?: number;
   unstarted?: number;
 }

export type EventType = string;
export type NotificationSuccessCallback = { (data: any): void };
export type NotificationErrorCallback = { (data: any): void };
export type NotificationCompletedCallback = { (): void };

export const EVENT_TYPES = {
  RELEASE: {
    RELEASE_FILTER: 'release_filter',
  },
  RELEASE_COMMENT: {
    COMMENT_ACTION: 'comment_action',
  },
  release_detail: {
    release_detail_UPDATE: 'release_detail_update',
  },
  TEST_SUITE: {
    TEST_SUITE_STORY__FILTER:'story_filter'
  },
  SPRINT: {
    SPRINT_CREATED:'sprint_created',
    SPRINT_UPDATED: 'sprint_updated',
    SPRINT_DELETED: 'sprint_deleted',
    SPRINT_FILTER: 'sprint_filter',
    SPRINT_STORY_ADDED: 'story_added',
    SPRINT_STORY_REMOVED: 'story_removed',
    SPRINT_STORY_UPDATED: 'sprint_story_updated',
    BACKLOG_STORY_ADDED: 'backlog_story_added',
    BACKLOG_STORY_REMOVED: 'backlog_story_removed',
  },
  ACCEPTANCE_CRITERIA: {
    CREATED: 'acceptance-criteria-created',
    UPDATED: 'acceptance-criteria-updated',
    REMOVED: 'acceptance-criteria-removed',
  },
  CURRENT_USER: {
    UPDATED: 'current-user-update',
    REMOVED: 'current-user-removed'
  },
  SESSION: {
    LOGGED_IN: 'logged-in',
    LOGGED_IN_NEW: 'logged-in-new',
    TIMER_TOTAL_PARENT_START: 'timer-toal-parent-start',
    TIMER_TOTAL_START: 'timer-total-start',
    TIMER_START: 'timer-start',
    LOGGED_OUT: 'logged-out',
    LOGIN_FAILED: 'login-failed'
  },
  USER: {
    REGISTERED: 'user-registered',
    NEW_USER_ADDED: 'new-user-added',
    ON_EDIT_SUCCESS: 'on-edit-success'
  },
  POLICIES: {
    UPDATED: 'policies-updated'
  },
  FILTER_BROADCAST: {
    FILTER_APPLIED: 'filter_applied'
  },
  STORY: {
    CREATE_NEW: 'story-create-new',
    FOCUS_NEW: 'story-focus-new',
    REMOVED: 'story-removed',
    SHOW_DETAILS: 'show-details',
    ASSiGNED_TO_ME: 'assigned_to_me',
    PROGRESS: 'progress',
    BLOCKERS_UPDATED: 'blockers-updated',
    STORY_HISTORY: 'story-history',
    ADDED: 'story-added',
    UPDATED: 'story-updated',
    SPRINT_STORY_CLONED: 'sprint-story-cloned',
    SPRINT_STORY_UPDATED: 'sprint-story-updated',
    SPRINT_STORY_REMOVED: 'sprint-story-removed',
    TITLE_UPDATED: 'story-title-updated',
    BUG_RELATED_TO_UPDATED: 'bug-related-to-updated',
    ESTIMATE_UPDATED: 'story-estimate-updated',
    SHOW_QUICK_ACTIONS: 'story-show-quick-actions',
    HIDE_QUICK_ACTIONS: 'story-hide-quick-actions',
    UNSELECT_AND_HIDE_QUICK_ACTIONS : 'unselect-hide-quick-actions',
    SET_OWNER: 'story-set-owner',
    SET_REVIEWER: 'story-set-reviewer',
    SET_ESTIMATE: 'story-set-estimate',
    EDIT_FEATURES_LABELS: 'story-edit-features-labels',
    MOVE: 'story-move',
    DATA_UPDATED: 'story-data-updated',
    UPDATE_OPENED_SUBTASK: 'subtask-data-updated',
    CLOSE_EXPAND: 'close-expand',
    ACTION_EXPAND: 'action-expand',
    DUPLICATE_AFTER_CONFIRM: 'story-duplicate',
    SPRINT_STORY_DUPLICATE:'sprint-story-duplicate',
    ARCHIVE: 'story-archive',
    EXPECTED_RESULT: 'story-expected-result'
  },
  TESTCASE: {
    CREATE_NEW: 'test-case-create-new',
    DATA_UPDATED: 'test-suite-data-updated',
    EPIC_UPDATED: 'test-suite-data-updated',
    CREATE_NEW_BUG : 'test-case-bug-created'
  },
  BLOCKER: {
    REMOVED: 'blocker-removed',
    CREATED: 'blocker-created',
    UPDATED: 'blocker-updated'
  },
  NOTE: {
    REMOVED: 'note-removed',
    CREATED: 'note-created',
    UPDATED: 'note-updated'
  },
  PROJECT: {
    NEW_CURRENT: 'project-new-current',
    NEW: 'project-new',
    PROJECT_LOAD: 'project-load',
    CLOSE_SEARCH: 'close-search',
    REFRESH_SEARCH: 'refresh-search',
    BULK_CLONE: 'bulk-clone',
    PROJECT_HISTORY: 'project-history',
    UNASSIGNED: 'unassigned',
    STORY_HISTORY_FOCUS: 'story-history-focus',
    STORIES: 'stories',
    SHOW: 'show',
    EDIT: 'edit',
    NEW_MEMBER_ADDED: 'new-member-added',
    APPLY_FILTER: 'apply-filter'
  },
  NAVBAR: {
    COLLAPSED: 'navbar-collapsed',
    EXPANDED: 'navbar-expanded'
  },
  SEARCH: {
    SHOW_RESULTS: 'search-show-results'
  },
  EPIC: {
    UPDATED: 'updated',
    FOCUS_NEW: 'epic-focus-new',
    CREATE_NEW_STORY: 'create-new-story'
  },
  PROJECT_PERCENTAGE: {
    PROGRESS_UPDATED: 'progress_updated'
  },
  EPIC_PROGRESS_UPDATE: {
    EPIC_PROGRESS_UPDATED: 'epic_progress_updated'
  },
  STORY_CONFIRMATION: {
    USER_CONFIRMED_STORY : 'user_confirmed_story'
  },
  BULK_ARCHIVE_PROCESSED: {
    JOB_PROCESSED: 'job_processed'
  }
};

export interface NotificationPayload {
  data?: { [key: string]: any };
}

export interface NotificationEvent {
  event: EventType;
  message?: string;
  payload: NotificationPayload;
}

export const DEV_TYPE_OPTIONS = [
  {
    type: 'FE',
    title: 'Frontend'
  },
  {
    type: 'BE',
    title: 'Backend'
  },
  {
    type: 'E2E',
    title: 'E2E'
  },
  {
    type: 'QA',
    title: 'QA'
  }
];

export const STORY_TYPE_OPTIONS = [
  {
    type: 'task',
    title: 'Story',
    icon: 'iconb-subtask taskIcons'
  },
  {
    type: 'bug',
    title: 'Bug',
    icon: 'iconb-bug bugIcons'
  }
];

export const STORY_SUB_TYPE_OPTIONS = [
  {
    type: 'core',
    title: 'Core',
    icon: 'iconb-task taskIcons'
  },
  {
    type: 'custom',
    title: 'Custom',
    icon: 'iconb-bug bugIcons'
  }
];


export const STORY_SEVERITY_OPTIONS = [
  {
    type: 'S1',
    icon: 'iconbs-s1'
  },
  {
    type: 'S2',
    icon: 'iconbs-s2'
  },
  {
    type: 'S3',
    icon: 'iconbs-s3'
  }
];

export const STORY_PRIORITY_OPTIONS = [
  {
    type: 'P1 - Blocker',
    icon: 'iconb-restrict'
  },
  {
    type: 'P2 - Critical',
    icon: 'iconb-thick-arrow-up'
  },
  {
    type: 'P3 - Major',
    icon: 'iconb-doublechevron-up'
  },
  {
    type: 'P4 - Minor',
    icon: 'iconb-doublechevron-down'
  },
  {
    type: 'P5 - Trivial',
    icon: 'iconb-circle-withdot'
  }
];

export const TEST_STORY_STATE_OPTIONS = [
  {
    type: 'unstarted',
    title: 'Todo',
    icon: 'stateSelection-icon unstarted'
  },
  {
    type: 'started',
    title: 'In Progress',
    icon: 'stateSelection-icon started'
  },
  {
    type: 'accepted',
    title: 'Pass',
    icon: 'stateSelection-icon accepted'
  },
  {
    type: 'rejected',
    title: 'Fail',
    icon: 'stateSelection-icon rejected'
  },

];

export const STORY_STATE_OPTIONS = [
  {
    type: 'unscheduled',
    title: 'Unscheduled',
    icon: 'stateSelection-icon unscheduled'
  },
  {
    type: 'unstarted',
    title: 'Unstarted',
    icon: 'stateSelection-icon unstarted'
  },
  {
    type: 'started',
    title: 'Started',
    icon: 'stateSelection-icon started'
  },
  {
    type: 'finished',
    title: 'Finished',
    icon: 'stateSelection-icon finished'
  },
  {
    type: 'delivered',
    title: 'Delivered',
    icon: 'stateSelection-icon delivered'
  },
  {
    type: 'accepted',
    title: 'Accepted',
    icon: 'stateSelection-icon accepted'
  },
  {
    type: 'rejected',
    title: 'Rejected',
    icon: 'stateSelection-icon rejected'
  }
];

export const BB_STATUS_OPTIONS = [
  {
    type: 'unassigned',
    title: 'Unassigned',
  },
  {
    type: 'assigned',
    title: 'Assigned',
  },
  {
    type: 'bb_under_development',
    title: 'BB under development',
    icon: 'stateSelection-icon started'
  },
  {
    type: 'ready_for_certification',
    title: 'Ready for Certification',
  },
  {
    type: 'waiting',
    title: 'Waiting',
  },
  {
    type: 'ready_for_customisation',
    title: 'Ready for Customisation',
  },
  {
    type: 'ongoing_customisation',
    title: 'Ongoing Customisation',
  },
  {
    type: 'finished_customisation',
    title: 'Finished Customisation',
  },
  {
    type: 'finished_using_bb',
    title: 'Finished using BB',
  },
  {
    type: 'skipped',
    title: 'Skipped',
  }
];

export const EXPORT_STATE_OPTIONS = [
  {
    type: 'all',
    title: 'All',
    checked: true
  },
  {
    type: 'unscheduled',
    title: 'Unscheduled',
    checked: true
  },
  {
    type: 'unstarted',
    title: 'Unstarted',
    checked: true
  },
  {
    type: 'started',
    title: 'Started',
    checked: true
  },
  {
    type: 'finished',
    title: 'Finished',
    checked: true
  },
  {
    type: 'delivered',
    title: 'Delivered',
    checked: true
  },
  {
    type: 'accepted',
    title: 'Accepted',
    checked: true
  },
  {
    type: 'rejected',
    title: 'Rejected',
    checked: true
  }
];

export const EXPORT_PHASE_OPTIONS = [
  {
    type: 'all',
    title: 'All',
    checked: true
  },
  {
    type: 'Product Roadmap',
    title: 'Product Roadmap',
    checked: true
  },
  {
    type: 'Design',
    title: 'Design',
    checked: true
  },
  {
    type: 'Prototype',
    title: 'Prototype',
    checked: true
  },
  {
    type: 'MVP',
    title: 'MVP',
    checked: true
  },
  {
    type: 'Full Build',
    title: 'Full Build',
    checked: true
  }
];

export const EXPORT_FIELD_OPTIONS = [
  {
    type: 'all',
    title: 'All',
    checked: true
  },
  {
    type: 'id',
    title: 'Id',
    checked: true
  },
  {
    type: 'title',
    title: 'Story Title',
    checked: true
  },
  {
    type: 'description_as_a',
    title: 'As a',
    checked: true
  },
  {
    type: 'description_i_want',
    title: 'I want',
    checked: true
  },
  {
    type: 'description_so_that',
    title: 'So that',
    checked: true
  },
  {
    type: 'description',
    title: 'Task Description/Additional Notes',
    checked: true
  },
  {
    type: 'labels',
    title: 'Labels',
    checked: true
  },
  {
    type: 'features',
    title: 'Features',
    checked: true
  },
  {
    type: 'phase',
    title: 'Phase',
    checked: true
  },
  {
    type: 'story_type',
    title: 'Story Type',
    checked: true
  },
  {
    type: 'priority',
    title: 'Priority',
    checked: true
  },
  {
    type: 'studio_role',
    title: 'Roles',
    checked: true
  },
  {
    type: 'project_name',
    title: 'Project Name',
    checked: true
  },
  {
    type: 'dashboard_project_id',
    title: '360 Project ID',
    checked: true
  },
  {
    type: 'platform_names',
    title: 'Platforms',
    checked: true
  },
  {
    type: 'estimate',
    title: 'Owner Estimate',
    checked: true
  },
  {
    type: 'reviewer_estimate',
    title: 'Reviewer Estimate',
    checked: true
  },
  {
    type: 'total_estimate',
    title: 'Total Estimate',
    checked: true
  },
  {
    type: 'rate_card',
    title: 'Rate Card',
    checked: true
  },
  {
    type: 'owner_bt_recorded',
    title: 'Owner BT Recorded',
    checked: true
  },
  {
    type: 'reviewer_bt_recorded',
    title: 'Reviewer BT Recorded',
    checked: true
  },
  {
    type: 'total_bt_recorded',
    title: 'Total BT Recorded',
    checked: true
  },
  {
    type: 'state',
    title: 'State',
    checked: true
  },
  {
    type: 'progress',
    title: 'Progress (%)',
    checked: true
  },
  {
    type: 'started_at',
    title: 'Started At',
    checked: true
  },
  {
    type: 'created_at',
    title: 'Created at',
    checked: true
  },
  {
    type: 'accepted_at',
    title: 'Accepted at',
    checked: true
  },
  {
    type: 'bt_blockers',
    title: 'Blockers',
    checked: true
  },
  {
    type: 'requested_by_name',
    title: 'Requested By',
    checked: true
  },
  {
    type: 'owned_by_name',
    title: 'Owned By',
    checked: true
  },
  {
    type: 'reviewer_name',
    title: 'Reviewed By',
    checked: true
  },
  {
    type: 'rejection_count',
    title: 'Rejection Count',
    checked: true
  },
  {
    type: 'execution',
    title: 'Task Execution',
    checked: true
  },  
  {
    type: 'acceptance_criteria',
    title: 'Acceptance Criteria',
    checked: true
  },
  {
    type: 'parent_id',
    title: 'Parent ID',
    checked: true
  },
  {
    type: 'url',
    title: 'URL',
    checked: true
  },
  {
    type: 'code',
    title: 'Code',
    checked: true
  },
  {
    type: 'pre_conditions',
    title: 'Pre Conditions',
    checked: true
  },
  {
    type: 'post_conditions',
    title: 'Post Conditions',
    checked: true
  },
  {
    type: 'test_data',
    title: 'Test Data',
    checked: true
  }
  // ,{
  //   type: 'comment',
  //   title: 'Comment',
  //   checked: true
  // }
];

export interface DataManipulationOptions {
  currentUser: any;
  user?: User;
  notificationService?: NotificationService;
}

export interface RelatedStoryData {
  id?: number;
  title?: string;
}

export const PROJECT_COLUMN = {
  features: 'features',
  to_do: 'to_do',
  in_progress: 'in_progress',
  qa: 'qa',
  rejected: 'rejected',
  accepted: 'accepted',
  archived: 'archived'
};

export const PROJECT_COLUMN_NAME = {
  features: 'features',
  to_do: 'To Do',
  in_progress: 'In Progress',
  qa: 'QA',
  rejected: 'Rejected',
  accepted: 'Accepted',
  archived: 'Archived'
};

export const NOT_APPLIED_REVIEWER = ['Product Roadmap', 'Design', 'Prototype'];

export const PROJECT_COLUMN_IDENTIFIER = {
  features: '#features',
  to_do: '#to-do',
  in_progress: '#in-progress',
  qa: '#qa',
  rejected: '#rejected',
  accepted: '#accepted',
  archived: '#archived'
};

export const PROJECT_COLUMN_CODE = {
  features: 'features',
  to_do: 'to-do',
  in_progress: 'in-progress',
  qa: 'qa',
  rejected: 'rejected',
  accepted: 'accepted',
  archived: 'archived'
};

export const BB_STATUS = {
  unassigned: 'unassigned',
  ready_for_customisation: 'ready_for_customisation',
  ongoing_customisation: 'ongoing_customisation',
  finished_customisation: 'finished_customisation',
  finished_using_bb: 'finished_using_bb',
  assigned: 'assigned',
  bb_under_development: 'bb_under_development',
  ready_for_certification: 'ready_for_certification',
  waiting: 'waiting',
  skipped: 'skipped'
};

export interface IFilter {
  phase: Array<string>;
  feature: Array<number>;
  label: Array<string>;
  member: Array<number>;
  'story_type': Array<string>;
  sub_type:  Array<string>;
  bb_status:  Array<string>;
  bb_design:  Array<string>;
  states: Array<string>;
  priority: Array<string>;
  created_from: Array<string>;
  platform: Array<number>;
  code_commit: Array<string>;
  created_on_date_range: Array<string>;
  state_changed_at_date_range: Array<string>;
  studio_roles: Array<string>;
  owner: Array<number>;
  reviewer: Array<number>;
  requester: Array<number>;
  dev_type: Array<string>;
  sprints: Array<string>;
  statuses: Array<string>;
  ship_release_date_range: Array<string>;
  rating: Array<string>;
  swimlane: Array<string>;
}

export interface ProjectColumnData {
  list_name: string;
  column_name?: string;
  list_position?: number;
  list_code?: string;
  list_id: number;
  list_type: String;
  column_type?: string;
  // position: number;
  visible?: boolean;
  canDrag?: boolean;
  columnIdentifier?: string
  canCreate?: boolean;
  isDroppableContainer?: boolean;
  allSelected?: boolean;
  isDependsOnCurrentUser?: boolean;
  selectedStoryCount?: number;
  stories?: Array<Story>;
  current_page?: number;
  total_pages?: number;
  total_count?: number;
  per_page_count?: number;
  expandable?: boolean;
  meta?: {current_page: number, total_pages: number, total_count: number, per_page_count: number};
}


export const FIRST_VISIBLE_COLUMN = [0, 1, 2, 3, 4, 5];

export interface SignInData {
  email: string;
  password: string;
}

export const PROJECT_COLUMN_DATA: Array<ProjectColumn> = [];

export interface HeaderComponentData {
  leftMenu: any;
  rightMenu: any;
  navBar: any;
  storyTimer: any;
  context: any;
}

export interface DraggableData {
  columnIdentifier: string;
  elementStory?: Story;
  siblingStory?: Story;
}

export interface GUIDData {
  guid: string;
  type: string;
  object?: any;
}

export const GUIDType = {
  Story: 'Story',
  Project: 'Project',
  Note: 'Note',
  Task: 'Task',
  User: 'User',
  Epic: 'Epic',
  Membership: 'Membership',
  Partner: 'Partner'
};

export const STORY_STATE = {
  unscheduled: 'unscheduled',
  unstarted: 'unstarted',
  started: 'started',
  finished: 'finished',
  delivered: 'delivered',
  accepted: 'accepted',
  rejected: 'rejected'
};

export const STORY_TYPE_FILTER: any = [
  {
    type: 'parent_stories',
    title: 'Parent Task Only',
  },
  {
    type: 'sub_tasks',
    title: 'Subtask Only',
  },
  {
    type: 'both',
    title: 'Both (Parent Task and Subtask)',
  }
];


export const STORY_TYPE = {
  task: 'task',
  chore: 'chore',
  bug: 'bug',
  release: 'release',
  side_effect: 'side_effect',
  rework: 'rework'
};
export const STORY_PRIORITY_LEVEL = {
  blocker: 'P1 - Blocker',
  major: 'P3 - Major',
  minor: 'P4 - Minor',
  critical: 'P2 - Critical',
  trivial: 'P5 - Trivial'
};

export const STORY_STATE_ACTIONS = [
  {
    action: 'start',
    state: STORY_STATE.started
  },
  {
    action: 'finish',
    state: STORY_STATE.finished
  },
  {
    action: 'deliver',
    state: STORY_STATE.delivered
  },
  {
    action: 'accept',
    state: STORY_STATE.accepted
  },
  {
    action: 'reject',
    state: STORY_STATE.rejected
  },
  {
    action: 'restart',
    state: STORY_STATE.started
  }
];

export type HeaderContextType = String;
export const HEADER_CONTEXT_TYPES: any = {
  project: 'project',
  projectBrief: 'projectBrief',
  projectDashboard: 'projectDashboard',
  projectEdit: 'projectEdit',
  projectImport: 'projectImport',
  timing: 'timing'
};

export type ProjectRole = string;
export const PROJECT_ROLES: any = {
  admin: 'admin',
  user: 'user',
  read_only: 'read_only'
};

export const TEST_CASE_STATUS: any = [
  {
    type: 'unstarted',
    title: 'Todo',
  },
  {
    type: 'started',
    title: 'In Progress',
  },
  {
    type: 'accepted',
    title: 'Pass',
  },
  {
    type: 'rejected',
    title: 'Fail',
  }
];

export const NEW_STORY_WRITTING_REASONS = [
  {
    reason: 'Customer changed the scope'
  },
  {
    reason: 'Missed adding use case(s)'
  },
  {
    reason: 'Need to log bug(s)'
  },
  {
    reason: 'Other(s)'
  }
]; 



export const UPDATE_STORY_REASONS = [
  {
    reason: 'Wrong judgement/prediction of efforts'
  },
  {
    reason: 'Lack of resource experience'
  },
  {
    reason: 'Did not meet the expectations (rework required)'
  },
  {
    reason: 'Requirements/user stories are not completed '
  },
  {
    reason: 'Expert forgot to pause tracker'
  }
]; 
export const UPDATE_STORY_DESCRIPTION_REASONS = [
  {
    reason: 'Missed Use Case'
  },
  {
    reason: 'Change Request'
  },
  {
    reason: 'Clarifying'
  }
];

export const ADMIN_ROLES: Array<String> = ['admin', 'super_admin'];
export const PROJECT_UPDATE_ROLES: Array<String> = [
  PROJECT_ROLES.admin,
  PROJECT_ROLES.user,
  PROJECT_ROLES.read_only
];

export interface UpdatedStoryData{
  catalog_story_id?: number,
  created_at?: Date,
  epic?: {builder_feature_id?: number, title?: string},
  estimate?: number,
  id?: number,
  parent_id?: number,
  project_id?: number,
  request_type?: string,
  requester?: {id?: number, name?: string, initials?: string, email?: string},
  reviewer?: {id?: number, name?: string, initials?: string, email?: string},
  status?: string,
  tech_stack?: string,
  title?: string,
  updated_at?: Date,
  reviewed_on?: Date,
  description?: string,
  description_as_a?: string,
  description_i_want?: string,
  description_so_that?: string,
  acceptance_criteria_approvals?: Array<AcceptanceCriteriaApprovalsData>
  designer?: {id?: number, name?: string, initials?: string, email?: string};
  designer_status?: string
  feedbacks?: Array<any>
  figma_image_url?: string
}

export interface AcceptanceCriteriaApprovalsData{
  id?: number,
  title?: string,
  description_for_given: string,
  description_for_when: string,
  description_for_then: string,
  created_at: Date,
  updated_at: Date,
  cmt_acceptance_criteria_id: number,
  story_approval_id: number
}

export interface StoryReviewData{
  catalog_story_id?: number,
  created_at?: Date,
  description?: string,
  epic?: {builder_feature_id?: number, title?: string},
  estimate?: number,
  id?: number,
  project_id?: number,
  request_type?: string,
  requester?: {id?: number, name?: string, initials?: string, email?: string},
  reviewer?: {id?: number, name?: string, initials?: string, email?: string},
  status?: string,
  tech_stack?: string,
  title?: string,
  updated_at?: Date,
  reviewed_on?: Date,
  designer_status?: string
}

export interface CMTStoryReviewData{
  id?: number,
  title?: string,
  phase?: string,
  description?: string,
  story_type?: string,
  assignee?: string,
  dev_type?: string,
  priority?: string,
  role?: string,
  goal?: string,
  reason?: string,
  acceptance_criteria?: Array<CMTAcceptanceCriteriaApprovalsData>,
  estimate_hrs?: number,
  feature?: {
      name?: string,
      complexity?: string,
      version?: string,
      obsolete?: boolean,
      assemblerize_name?: string,
      bb_exists?: boolean,
      certification_type?: string
  },
  subtasks?: Array<any>,
  as_a?: string,
  i_want?: string,
  so_that?: string,
}

export interface CMTAcceptanceCriteriaApprovalsData{
  id?: number,
  title?: string,
  given: string,
  when: string,
  then: string,
  created_at: Date,
  updated_at: Date,
  cmt_acceptance_criteria_id: number,
  story_approval_id: number
}

export interface ReviewStoryEpicsData{
  id?: number,
  title?: string,
  isSelected?: boolean
}

export interface StoryReviewUsersData{
  id?: number,
  name?: string,
  initials?: string,
  email?: string
}


export const APPROVE_HOUR_REASONS = [
  {
    reason: 'Shared expert with another Buildcard'
  },
  {
    reason: 'Task or story not created'
  },
  {
    reason: 'System Issue'
  },
  {
    reason: 'The expert forgot to start the story on the tracker'
  },
  {
    reason: 'Working outside of stories (i.e. training, meetings, knowledge transfer)'
  },
  {
    reason: 'Other(s)'
  },
];  

export const MEMBER_DASHBOARD_FILTER_DATA = [
  {
    name: 'Role',
    term: 'role',
    options: [
      { 
        title: 'Manager',
        value: 'admin'
      }, 
      { 
        title: 'Expert',
        value: 'user'
      }
    ]
  },
  {
    name: 'State',
    term: 'active',
    options: [
      { 
        title: 'Active',
        value: true
      }, 
      { 
        title: 'Inactive',
        value: false
      }
    ]
  },
  {
    name: 'Skill',
    term: 'skill',
    options: []
  }
]; 

export const START_STORY_CONFIRM_OPTIONS = {
  task_and_qa: [
    {option : 'You have read and understood the use cases to be covered in the user story.'}
  ],
  task_and_not_qa: [
    {option : 'You have read and understood the use cases to be covered in the user story.'},
    {option : 'You have reviewed the existing code and are ready to extend it.'} 
  ],
  bug_and_qa: [
    {option : 'You have read and understood the use cases to be covered in the user story.'},
    {option : 'You have read and understood the use cases and steps to reproduce in this bug.'}
  ],
  bug_and_not_qa: [
    {option : 'You have read and understood the use cases and steps to reproduce in this bug.'}
  ]
}

export const READY_FOR_CERTIFICATION_BE_CHECKLIST = [
  {
    list: '<a href="https://docs.google.com/document/d/e/2PACX-1vQX14iFwGnwP1E5k0dRNIOK-517wKcmuqrRQVZPldrnwrVzCt50mLWTBmDLGC6VeJez_onXL0-vjgU8/pub?embedded=true&rm=minimal&readonly=true" target="_blank" rel="noopener">BB checklist for Ruby Block Certification.</a>',
    isChecked: false
  }
];

export const READY_FOR_CERTIFICATION_FE_CHECKLIST =[
  {
    list: '<a href="https://docs.google.com/document/d/e/2PACX-1vSNs8gHz_Hmmci7SUQihDyk-juwwCHjRUUh0Grt9wpyTOtoQ9Wn7AmQG8GHq4gx4Cn9_2bk5h9qv5FC/pub?embedded=true&rm=minimal&readonly=true" target="_blank" rel="noopener">BB checklist for Frontend Block Certification.</a>',
    isChecked: false
  },
];

export const READY_FOR_CERTIFICATION_SENSEI_CHECKLIST=[
  {
    list: 'MR passes the pre-certification checks.',
    isChecked: false
  },
  {
    list: 'A detailed feature description is populated in the Tracker story which explains the scope of the block.',
    isChecked: false
  },
  {
    list: 'Ensure that for FE, both Web and Mobile components are available & built in React native.',
    isChecked: false
  },
  {
    list: 'Block to be contained in 1 feature branch. \r\na) Evidence of pre-cert tool passing for feature branch at CI level.',
    isChecked: false
  },
  {
    list: 'For any block submitted for certification where there is a pre-cert failure, there should be a comment added in the story as to why the block has been submitted for certification despite a failing check e.g. known bug in pre-cert tool or sign-off given separately from BX team.',
    isChecked: false
  },
  {
    list: 'Screenshot of coverage summary added to the Tracker story - This must be the output of running yarn test inside the block.',
    isChecked: false
  },
  {
    list: 'Screenshot of coverage summary added to the Tracker story - This must be the output of running yarn test inside the block.',
    isChecked: false
  }
];

export interface IMonthList {
  label: string,
  value: number,
  year: number,
  isActive: boolean
}

export interface IDays {
  dayNumber: string,
  initial: string
  date: string
}

export interface ICurrentYearAndMonth {
  currentYear: number,
  currentMonth: number,
  currentDate: string,
  yearRange: number
}

export type currentlyAllocatedDataType = {} | { [key: string]: any }

export interface IMessageSource {
  year: number,
  month: number,
  project_id: number,
  type: string,
  search: string,
  sort_by: string,
  per_page: number,
  page_number: number,
  skill_filter: Array<number>,
  leave_filter: any
}

export interface ILeaveType {
  label: string,
  key: string,
  value: boolean,
  isChecked: boolean
}

export interface ISortTypes {
  key: string,
  label: string,
  value: string,
  sortOrder: string,
  isChecked: boolean
}

export interface IResponse {
  success: boolean,
  message: string,
  data: Array<any>,
  meta: any,
  errors: Array<any>
}
export interface storyApprovalFilter {
  epics: Array<number>;
  requestors: Array<number>;
  reviewers: Array<string>;
  request_type:  Array<string>;
  requested_on: Array<string>;
  reviewed_on: Array<string>;
  status: Array<string>;
}

export interface ReleasePlannerPlatform {
  id?: number,
  name?: string,
  display_name?: string,
  device?: string,
  created_at?: string,
  updated_at?: string,
  selected?: boolean,
}

export interface ReleasePlannerData {
  id?: number,
  name?: string,
  version?: string,
  estimated_end_date?: any,
  created_at?: Date,
  environment?: string,
  attachments?: Array<any>,
  release_detail_text?: string,
  platforms?: Array<ReleasePlannerPlatform>,
  phase_deliverables?: string,
  phase?: string,
  platform_associations?: Array<any>,
  build_url?: string,
  status?: string,
  sprints?: Array<number>,
  rating?: any,
  start_date?: Date,
  started_by?: string,
  started_by_id?: number,
  updated_at?: any,
  updated_by?: string,
  updated_by_id?: number,
  comments?: Array<any>,
  stories_count?: number,
  stories_count_by_status?: any,
  features?: Array<any>,
  total_feature_count?: number,
  deleted_at?: any,
  parent_id?: number,
}

export interface ReleaseDetailsData {
  id?: number,
  release_id?: number,
  user_id?: number,
  body?: string,
  known_issues?: string,
  credentials?: string,
  coming_soon?: string,
  documents_attributes?: Array<any>,
  attachments?: [{
    id?: number,
    name?: string,
    remote_url?: string,
    content_type?: string,
  }],
  feature_notes?: Array<ReleaseFeatureNotesData>,
}

export interface ReleaseFeatureNotesData {
  id?: number,
  feature_name?: string,
  release_detail_id?: number,
  note_type?: string,
  body?: string,
  attachments?: [{
    id?: number,
    name?: string,
    remote_url?: string,
    content_type?: string,
  }],
  documents_attributes?: Array<any>,
  for_removal?: Array<any>,
}

export const APPROVAL_STATUS_CHECKLIST=[
  {
    value: 'waiting',
    name: 'Waiting',
    isSelected: false
  },
  {
    value: 'accepted',
    name: 'Accepted',
    isSelected: false
  },
  {
    value: 'rejected',
    name: 'Rejected',
    isSelected: false
  }
];

export const LIST_OF_STORYAPPROVAL_FILTERS = [
  {
    filter_name: 'Status',
    filter_key:'status'
  },
  {
    filter_name: 'Feature',
    filter_key:'epics'
  },
  {
    filter_name: 'Requested by',
    filter_key:'requestors'
  },
  {
    filter_name: 'Reviewed by',
    filter_key:'reviewers'
  },
  {
    filter_name: 'Requested on',
    filter_key:'requested_on'
  },
  {
    filter_name: 'Reviewed on',
    filter_key:'reviewed_on'
  }
];

export interface INudgeParams {
  developer_email: string
  project_id: number
  nudged_by_user_id: number
  nudged_by_user_email: string
  nudged_by_username: string
  nudged_from: "Tracker"
  urgent?: boolean
}

export const UPDATE_STORY_EVENT_KEYS = {
  archive: ['deleted_at'],
  unarchive: ['deleted_at', 'parent_id'],
  priority: ['priority'],
  phase: ['phase'],
  task: ['phase', 'task'],
  role: ['studio_role_id'],
  reviewer: ['reviewer_id', 'reviewer_name', 'reviewer_initials'],
  owner: ['owned_by_id', 'owned_by_name', 'owned_by_initials'],
  owner_estimate: ['estimate', 'reason_for_update'],
  reviewer_estimate: ['reviewer_estimate', 'reason_for_update']
}


export const RELEASE_STATUS_LIST = {
  staging: [
    { name: 'Draft', value: 'draft', iconColor:'draft', canChangeStatus: true },
    { name: 'Deployed to Staging', value: 'deployed_to_staging', iconColor:'deployed_to_staging',canChangeStatus: true },
    { name: 'Rolled Back', value: 'rolled_back', iconColor:'rolled_back',canChangeStatus: true }
  ],
  pre_prod: [
    { name: 'Deployed to Pre-Prod', value: 'deployed_to_pre_prod', iconColor:'deployed_to_pre_prod',canChangeStatus: true},
    { name: 'Ready to Ship', value: 'ready_to_ship', iconColor:'ready_to_ship' ,canChangeStatus: true },
    { name: 'Shipped to Customer', value: 'shipped_to_customer', iconColor:'shipped_to_customer' ,canChangeStatus: true },
    { name: 'Accepted', value: 'accepted', iconColor:'accepted',canChangeStatus: true },
    { name: 'Rejected', value: 'rejected', iconColor:'rejected' ,canChangeStatus: true },
    { name: 'Rolled Back', value: 'rolled_back', iconColor:'rolled_back' ,canChangeStatus: true }
  ],
  production: [
    { name: 'Deployed to Production', value: 'deployed_to_production', iconColor:'deployed_to_production' ,canChangeStatus: true },
    { name: 'Ready to Ship', value: 'ready_to_ship', iconColor:'ready_to_ship' ,canChangeStatus: true },
    { name: 'Shipped to Customer', value: 'shipped_to_customer', iconColor:'shipped_to_customer' ,canChangeStatus: true },
    { name: 'Accepted', value: 'accepted', iconColor:'accepted' ,canChangeStatus: true },
    { name: 'Rejected', value: 'rejected', iconColor:'rejected' ,canChangeStatus: true }
  ]
}

export const RELEASE_RATE_LIST = [
  {
    name: '1/5',
    value:'1'
  },
  {
    name: '2/5',
    value:'2'
  },
  {
    name: '3/5',
    value:'3'
  },
  {
    name: '4/5',
    value:'4'
  },
  {
    name: '5/5',
    value:'5'
  }
];

export const ALLOWED_CLASS_IN_DD = [
  'selected-name', 
  'selectedDevicePlatform-name', 
  'iconb-dd-down', 
  'forDevicePlatform', 
  'iconb-devices-platefrom', 
  'iconb-Smartphone', 
  'iconb-Desktop', 
  'iconb-Watch', 
  'selectDeviceList-wrapper', 
  'deviceType', 
  'storyReqSelection-list', 
  'moveSelection-check', 
  'customCheckFor-moveSelection', 
  'move-selected-checkbox', 
  'check-toggleMoveSelection', 
  'selectedType-name', 
  'selected-type', 
  'storySelection-DD', 
  'storySelection-list'
];


export const PHASES_AND_DELIVERABLES = [
  {
    name: 'Under Planning',
    value: 'under_planning',
    options: [
      { 
        title: 'Spec Document',
        value: 'spec_document'
      }, 
      { 
        title: 'End to End Workflow',
        value: 'end_to_end_workflow'
      },
      {
        title: 'Database Design',
        value: 'database_design'
      },
      {
        title: 'High Level System Design',
        value: 'high_level_system_design'
      },
      {
        title: 'Spec Doc - Partial',
        value: 'spec_doc_partial'
      }
    ]
  },
  {
    name: 'Under Designing',
    value: 'under_designing',
    options: [
      {
        title: 'Wireframes - Partial',
        value: 'wireframes_partial'
      },
      {
        title: 'Wireframes - Complete',
        value: 'wireframes_complete'
      },
      {
        title: 'UI/UX Design - Partial',
        value: 'ui_ux_design_partial'
      },
      {
        title: 'UI/UX Design - Complete',
        value: 'ui_ux_design_complete'
      },
      {
        title: 'Database Design',
        value: 'database_design'
      },
      {
        title: 'High Level System Design',
        value: 'high_level_system_design'
      }
    ]
  },
  {
    name: 'Under Development/QA',
    value: 'under_development_qa',
    options: [
      {
        title: 'MVP Build - Partial',
        value: 'mvp_build_partial'
      },
      {
        title: 'MVP Build',
        value: 'mvp_build'
      },
      {
        title: 'Full Build - Partial',
        value: 'full_build_partial'
      },
      {
        title: 'Full Build',
        value: 'full_build'
      }
    ]
  },
  {
    name: 'Delivered - Under Customer Review',
    value: 'delivered_under_customer_review',
    options: [
      {
        title: 'MVP Build',
        value: 'mvp_build'
      },
      {
        title: 'Full Build',
        value: 'full_build'
      }
    ]
  }
];
