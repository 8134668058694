import { Story } from './story';

export class Project {
  id: number;
  name: string;
  totalTime: string;
  stories?: Story[];
  hours?: string[];

  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    this.stories = (data.stories || []).map(story => new Story(story));
    this.hours = this.prepareHours(data.hours || []);
    this.totalTime = data.total_time;
  }

  private prepareHours(hours: string[]): string[] {
    hours.length = 7;
    for (let i = 0; i < hours.length; i++) {
      hours[i] = hours[i] || '-';
    }
    return hours;
  }
}
