import {map} from 'rxjs/operators';
import { Injectable, Inject, forwardRef } from '@angular/core';
import { API_ROUTES } from '../apiRoutes';
import { NotificationService } from './notification.service';
import { StoryService } from './story.service';
import { ErrorService } from '../errorFunction';
import { HttpRequests } from './http-requests.service';
import { DataService } from './data.service';
import { Story } from '../models/story';
import { Note } from '../models/note';

@Injectable()
export class NoteService {

  constructor(
    private httpRequest: HttpRequests,
    private dataService: DataService,
    @Inject(forwardRef(() => StoryService)) private storyService: StoryService,
    @Inject(forwardRef(() => ErrorService)) private errorService: ErrorService,
    @Inject(forwardRef(() => NotificationService)) private notificationService: NotificationService) {
  }

  addStoryAttachment(story: Story, note: Note, onCreate) {
    const forAttachment = 'attachment';
    const formData: FormData = new FormData();
    formData.append('note[note]', note.note);
    formData.append('note[note_type]', forAttachment);
    formData.append('note[on_create]', JSON.stringify (onCreate));
    note.documents.forEach(file => {
      formData.append('note[documents_attributes][][name]', file.name);
      formData.append('note[documents_attributes][][attachment]', file);
      formData.append('note[documents_attributes][][content_type]', file.type);
      formData.append('note[documents_attributes][][size]', file.size);
    });

    const API = this.dataService.apiUrl + API_ROUTES.STORY_NOTES(story.project.id, story.id);
    return this.httpRequest.post(API, formData, this.dataService.getRequestOptionArgs()).pipe(
             map(res => this.parseResponse(res)),
             map(res => {
                return new Note(res.note, story);
              }));
  }

  createNote(story: Story, note: Note, onCreate) {
    const formData: FormData = new FormData();
    formData.append('note[note]', note.note);
    formData.append('note[on_create]', JSON.stringify (onCreate));
    note.documents.forEach(file => {
      formData.append('note[documents_attributes][][name]', file.name);
      formData.append('note[documents_attributes][][attachment]', file);
      formData.append('note[documents_attributes][][content_type]', file.type);
      formData.append('note[documents_attributes][][size]', file.size);
    });

    const API = this.dataService.apiUrl + API_ROUTES.STORY_NOTES(story.project.id, story.id);
    return this.httpRequest.post(API, formData, this.dataService.getRequestOptionArgs()).pipe(
             map(res => this.parseResponse(res)),
             map(res => {
                return new Note(res.note, story);
              }));
  }

  updateNote(story: Story, note: Note, document, noteObj?) {
    const data = {
      note: {
        'documents_attributes': [{
          id: document['id'],
          '_destroy': true
        }]
      }
    };
    const API_ENDPOINT = noteObj && noteObj.subject_type === 'story' ? API_ROUTES.STORY_NOTE(noteObj.project_id, noteObj.subject_id, note.id) : API_ROUTES.UPDATE_COMMENTS_FOR_RELEASE(noteObj.project_id, noteObj.subject_id, note.id);
    const API = this.dataService.apiUrl + API_ENDPOINT;
    return this.httpRequest.patch(API, data, this.dataService.getRequestOptionArgs()).pipe(
         map(res => this.parseResponse(res)),
         map(res => {
            return noteObj.subject_type === 'story' ? new Note(res.note, story) : new Note(res.comment, null);
          }));
  }

  deleteAttachednote(story: Story, note: Note, document) {
    const data = {
      note: {
        'documents_attributes': [{
          id: document['id'],
          '_destroy': true
        }]
      }
    };

    const API = this.dataService.apiUrl + API_ROUTES.STORY_NOTE(story.project.id, story.id, note.id);
    return this.httpRequest.patch(API, data, this.dataService.getRequestOptionArgs()).pipe(
         map(res => this.parseResponse(res)),
         map(res => {
            return new Note(res.note, story);
          }));
  }

  deleteNote(noteObj, note: Note) {
    const API_ENDPOINT = noteObj.subject_type === 'story' ? API_ROUTES.STORY_NOTE(noteObj.project_id, noteObj.subject_id, note.id) : API_ROUTES.DELETE_COMMENTS_FOR_RELEASE(noteObj.project_id, noteObj.subject_id, note.id);
    const API = this.dataService.apiUrl + API_ENDPOINT;
    return this.httpRequest.delete(API, this.dataService.getRequestOptionArgs()).pipe(
             map(res => note));
  }

  editUpdateNote(story: Story, note: Note, addDocuments, removeDocuments, noteObj?) {
    const formData: FormData = new FormData();
    formData.append('note[note]', note.note);
    addDocuments.forEach(file => {
      formData.append('note[documents_attributes][][name]', file.name);
      formData.append('note[documents_attributes][][attachment]', file);
      formData.append('note[documents_attributes][][content_type]', file.type);
      formData.append('note[documents_attributes][][size]', file.size);
    });
    removeDocuments.forEach(doc => {
      formData.append('note[documents_attributes][][name]', doc.name);
      formData.append('note[documents_attributes][][size]', doc.size);
      formData.append('note[documents_attributes][][content_type]', doc.content_type);
      formData.append('note[documents_attributes][][id]', doc.id);
      formData.append('note[documents_attributes][][_destroy]', doc._destroy);
    });
    const API_ENDPOINT = noteObj.subject_type === 'story' ? API_ROUTES.STORY_NOTE(noteObj.project_id, noteObj.subject_id, note.id) : API_ROUTES.UPDATE_COMMENTS_FOR_RELEASE(noteObj.project_id, noteObj.subject_id, note.id);
    const API = this.dataService.apiUrl + API_ENDPOINT;
    return this.httpRequest.patch(API, formData, this.dataService.getRequestOptionArgs()).pipe(
      map(res => this.parseResponse(res)),
      map(res => {
        return noteObj.subject_type === 'story' ? new Note(res.note, story) : new Note(res.comment, null);
      }));
  }
  // API_ROUTES.COMMENTS_FOR_RELEASE(project.id, release_id);
  createNoteReply(story: Story, replyNote, noteObj) {
    const data = {
      note: {
        note: replyNote.note,
        parent_id: replyNote.parent_id,
      }
    };
    const API_ENDPOINT = noteObj.subject_type === 'story' ? API_ROUTES.STORY_NOTES(noteObj.project_id, noteObj.subject_id) : API_ROUTES.COMMENTS_FOR_RELEASE(noteObj.project_id, noteObj.subject_id);
    const API = this.dataService.apiUrl + API_ENDPOINT;
    return this.httpRequest.post(API, data, this.dataService.getRequestOptionArgs()).pipe(
      map(res => this.parseResponse(res)),
      map(res => {
        return noteObj.subject_type === 'story' ? new Note(res.note, story) : new Note(res.data, null);
      }));
  }

  editNoteReply(story: Story, replyNote, noteObj) {
    const data = {
      note: {
        note: replyNote.note
      }
    };
    const API_ENDPOINT = noteObj.subject_type === 'story' ? API_ROUTES.STORY_NOTE(noteObj.project_id, noteObj.subject_id, replyNote.id) : API_ROUTES.UPDATE_COMMENTS_FOR_RELEASE(noteObj.project_id, noteObj.subject_id, replyNote.id);
    const API = this.dataService.apiUrl + API_ENDPOINT;
    return this.httpRequest.patch(API, data, this.dataService.getRequestOptionArgs()).pipe(
      map(res => this.parseResponse(res)),
      map(res => {
        return noteObj.subject_type === 'story' ? new Note(res.note, story) : new Note(res.comment, null);
      }));
  }

  deleteNoteReply(noteObj, replyNoteId) {
    const API_ENDPOINT = noteObj.subject_type === 'story' ? API_ROUTES.STORY_NOTE(noteObj.project_id, noteObj.subject_id, replyNoteId) : API_ROUTES.DELETE_COMMENTS_FOR_RELEASE(noteObj.project_id, noteObj.subject_id, replyNoteId);
    const API = this.dataService.apiUrl + API_ENDPOINT;
    return this.httpRequest.delete(API, this.dataService.getRequestOptionArgs());
  }

  public parseResponse(res: any): any {
    return res;
  }
}
