import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { SharedModule } from './../shared/shared.module';

import { AccountRoutingModule } from './account-routing.module';

import { AccountIntegrationComponent } from './components/integration/integration.component';

import { AccountViewComponent } from './pages/view/view.component';
import { ClipModule } from 'ng2-clip';
import { AccountEditComponent } from './pages/edit/edit.component';
// import { PopoverModule} from 'ngx-bootstrap';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    SharedModule,
    AccountRoutingModule,
    ClipModule
  ],
  declarations: [
    AccountIntegrationComponent,
    AccountViewComponent,
    AccountEditComponent
  ],
  exports: [

  ]
})
export class AccountModule { }
