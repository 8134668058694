import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { find, pull, some, sortBy } from 'lodash';
import { ProjectComponent } from '../../project/project.component';
import { EVENT_TYPES, PROJECT_COLUMN_DATA, PROJECT_COLUMN } from '../dataTypes';
import { GUID } from '../models/guid';
import { Epic } from '../models/epic';
import { Story } from '../models/story';
import moment from 'moment';
import { NotificationService } from './notification.service';
import { SocketsService } from './sockets.service';
import { StoryService } from './story.service';
import { UserService } from './user.service';
import { EpicService } from './epic.service';
import { ProjectService } from './project.service';
import { ActivityService } from './activity.service';
import {map} from 'lodash';
import { ProjectColumn } from '../models/column';
import { SharedService } from './shared.service';
import {SprintComponent} from "../../project/sprint/sprint.component";
import { ProjectSyncService } from './projectSync.service';
import { ErrorService } from '../errorFunction';
import { CustomToastService } from './custom-toast.service';
import { PolicyService } from './policy.service';

@Injectable()
export class CommonStoryService {
  archiveStory: Story;
  OpenConfirmPopUp:boolean = false;
  waitForLoader:boolean = false;
  story_belongs_to = '';
  story_clicked: Story;
  dd_type = '';

  constructor(private notificationService: NotificationService,
    private socketsService: SocketsService,
    private storyService: StoryService,
    private sharedService: SharedService,
    private userService: UserService,
    private epicService: EpicService,
    private activityService: ActivityService,
    private projectService: ProjectService,
    private projectSyncService: ProjectSyncService,
    private errorService: ErrorService,
    private customToast: CustomToastService,
    private policyService: PolicyService) {}

  toggleEditMode(event, story_data, project) {
    const element = event.target;
    if (story_data.showStoryTitleInput 
       || story_data.showPriority 
       || story_data.showReviewerList
       || story_data.showOwnerList
       || story_data.showMoreStoryAction 
       || story_data.dev_release_at_cal
       || this.isUserDDActive(element)
       || this.isMoreActionDDActive(element)
       || this.isPriorityDDActive(element) 
       || this.isCopyIdActive(element) 
       || this.isStorySelected(element) 
    ) {
      event.stopPropagation();
      this.checkOnClick(element);
    } else {
      const initiated_at =  this.projectService.sprintId ? 'from_sprint_Detail': 'from_sprint';
      const sprint_id = this.projectService.sprintId ? this.projectService.sprintId : null;
      this.projectService.show_StoryDetails(project, story_data, story_data.id, false, initiated_at, sprint_id);
    }
  }

  isStorySelected(element) {
    // return story_data.isSelected;
    return element.classList.contains('check-toggle') || element.classList.contains('customCheckForRowSelection') || element.classList.contains('story-selected-checkbox');
  }

  isCopyIdActive(element){
    return element.classList.contains('story-id') || element.classList.contains('copy-bt');
  }

  isPriorityDDActive(element){
    return element.classList.contains('priority-icon') || element.classList.contains('story-priority') || element.classList.contains('storyPriority-wrapper') || element.classList.contains('storyPriority-option')
  }

  isMoreActionDDActive(element) {
    return  element.classList.contains('moreAction-bt') || element.classList.contains('storyaction-wrapper') || element.classList.contains('storyaction-option')
  }

  isUserDDActive(element) {
    return element.classList.contains("userInitials") || element.classList.contains("storyUserList-wrapper") || element.classList.contains("storyUser-searchInput") ||
    element.classList.contains("storyUser-searchButton") || element.classList.contains("storyUser-searchButtonIcon") || element.classList.contains("list-optionWrapper") ||
    element.classList.contains("storyUserList-option") || element.classList.contains("userInitials-icon") || element.classList.contains("ifClickOutside") ||
    element.classList.contains("storyUser-search")
  }

  storyEditMode(story_id, project) {
    const initiated_at =  this.projectService.sprintId ? 'from_sprint_Detail': 'from_sprint';
    this.projectService.show_StoryDetails(project, '', story_id, false, initiated_at, this.projectService.sprintId);
  }

  checkOnClick(element){
    if (!(this.isPriorityDDActive(element))) {
      if (this.story_clicked) {
        this.story_clicked.showPriority = false;
      }
    }
    if (!(this.isMoreActionDDActive(element))) {
      if (this.story_clicked) {
        this.story_clicked.showMoreStoryAction = false;
      }
    }
    if (!(this.isUserDDActive(element))) {
      if (this.story_clicked) {
        this.story_clicked.showOwnerList = false;
        this.story_clicked.showReviewerList = false;
      }
    }
  }

  openDDOptions(dd_type,story,project?) {
    if(!this.policyService.isUserProjectAdmin && !(project && this.policyService.isQAExpert(project) && story.story_type === 'bug')) {
      this.policyService.readOnlyWarning();
      return;
    }
    if(this.story_clicked && this.story_clicked.id !== story.id){
      this.story_clicked.showPriority = false;
      this.story_clicked.showOwnerList = false;
      this.story_clicked.showReviewerList = false;
      this.story_clicked.showMoreStoryAction = false;
    } 
    this.story_clicked = story;
    if(dd_type === 'priority') {
      this.dd_type = dd_type;
      story.showPriority = !story.showPriority;
    } else if(dd_type === 'owner') {
      this.dd_type = dd_type;
      story.showOwnerList = !story.showOwnerList;
    } else if(dd_type === 'reviewer') {
      this.dd_type = dd_type;
      story.showReviewerList = !story.showReviewerList;
    } else if(dd_type === 'more_action') {
      this.dd_type = dd_type;
      story.showMoreStoryAction = !story.showMoreStoryAction;
    }
  }

  openArchivePopup(story, story_in) {
    this.story_belongs_to = story_in;
    this.archiveStory = story;
    this.OpenConfirmPopUp = true;
  }

  closeArchivePopup() {
    this.OpenConfirmPopUp = false;
  }

  archive(story_in) {
    this.archiveStory.deleted_at = moment();
    this.waitForLoader = true;
    this.removeStory(this.archiveStory, story_in);
  }

  removeStory(story, story_in) {
    this.storyService.updateSprintStory(story).subscribe((st: Story) => {
      this.waitForLoader = false;
      const payload = {
        data: {
          story: st,
          story_in: story_in
        }
      };
      this.notificationService.broadcast(EVENT_TYPES.STORY.ARCHIVE, payload);
      this.OpenConfirmPopUp = false;
      this.customToast.messages.push({
        id: 'story_deleted',
        type: 'success',
        class: 'story_delete',
        title: 'Story Archived',
        message: story && story.parent_id ? 'You have archived 1 task successfully' : 'You have archived 1 story successfully'
      });
    }, (error) => {
      this.errorService.errorFunction(error);
    });
  }

  setUser(user, type, story, project) {
    if(!project.isUserActive(user) || this.checkSameUser(user,'reviewer',story, project)) {return}
    if (type === 'Owner') {
      this.setOwnedByID(user, story);
    } else if (type === 'Reviewer') {
      this.setReviewerByID(user, story);
    }
  }

  setReviewerByID(user, story) {
    story.reviewer_id = user.id;
    story.reviewer_name = user.name;
    story.reviewer_initials = user.initials;
    story.showReviewerList = false;
    this.updateStory(story, 'reviewer updated', 'reviewer', 'reviewer_email');
  }

  setOwnedByID(user, story) {
    story.owned_by_id = user.id;
    story.owned_by_name = user.name;
    story.owned_by_initials = user.initials;
    story.showOwnerList = false;
    this.updateStory(story, 'owner updated', 'owner', 'owner_email');
  }

  trackStoryUpdateSegmentEvent(story, event_Name, propName, propKey?) {
    this.userService.trackSegmentEvent(story.storyOrTask() + ' ' + event_Name,
      this.projectService.getPropsOnStoryTaskUpdation(story.project, story, propName, propKey));
  } 

  trackStoryTaskCreateSegmentEvent(story, isSubTask?, parentStory?, project?) {
    this.userService.trackSegmentEvent(story.storyOrTask(story) + ' created',
      this.projectService.getPropsOnStoryTaskCreation(project, story, isSubTask, parentStory));
  }
  
  updateStoryTitle(story) {
    story.showStoryTitleInput = false;
    if ((story.title.trim() === story.title_copy) || !story.title.trim()) {
      story.title = story.title_copy;
      return;
    }
    story.title = story.title.trim();
    this.updateStory(story, 'title updated', 'title');
  }

  changeStoryPriority(priority, story) {
    story.priority = priority;
    this.updateStory(story, 'priority updated', 'priority');
    story.showPriority = false;
  }

  updateStory(story, event_Name, propName, propKey?, startTimer?) {
    this.storyService.updateSprintStory(story).subscribe((st: Story) => {
      if (event_Name && propName) {
        this.trackStoryUpdateSegmentEvent(story, event_Name, propName, propKey);
      }
      story.title_copy = story.title;
      this.projectSyncService.updateAndShiftStory(story, st);
    }, (error) => {
      story.title = story.title_copy;
      this.errorService.errorFunction(error);
    });
  }

  copyStoryID(story) {
    story.show_copy_tooltip = true;
    setTimeout(() => {
      story.show_copy_tooltip = false;
    }, 3000);
  }

  makeClone(story_data, project) {
    this.storyService.cloneSprintStory(story_data, this.projectService.apply_on_sub_tasks).subscribe(res => {
      const story = res.story.story;
      this.projectService.clonePopupLoader = false;
      story.isSimulatedObject = true;
      const clonedStory = new Story(story);
      this.trackStoryTaskCreateSegmentEvent(clonedStory,null,null, project);
      if (res && res.message) {
        this.customToast.messages.push({
          id: 'story_new',
          type: 'success',
          class: 'story_new',
          title: 'Story Duplicate',
          message: res.message
        });
      }
      const payload = {};
      this.notificationService.broadcast(EVENT_TYPES.STORY.SPRINT_STORY_DUPLICATE, payload);
      this.projectService.clonePopupLoader = false;
      this.projectService.closeCloneConfirmationPopup();
    }, error => {
      this.errorService.errorFunction(error)
      this.projectService.clonePopupLoader = false;
      this.projectService.closeCloneConfirmationPopup();
    });
  }

  checkSameUser(user,type, story, project){
    if (type === 'owner') {
      if (project.dashboard_project_id && story.phase !==  'Product Roadmap' && story.reviewer_id && story.reviewer_id === user.id) {
        return true;
      }else return false;
    }else{
      if (project.dashboard_project_id && story.phase !==  'Product Roadmap' && story.owned_by_id && story.owned_by_id === user.id) {
        return true;
      }else return false;
    }
  }
  getDateFormat(date_generic) {
    return moment(date_generic).format('DD/MM/YYYY');
  }

  addStories(stories, story) {
    const index = stories.findIndex(st => st.id === story.id);
    (index === -1) ? stories.unshift(story) : stories.splice(index, 1, story);
  }

  removeMultipleStories(stories, payload) {
    payload.bulk_changes.forEach(s => {
      this.updateStories(stories, s);
    })
  }

  updateStories(stories, payload) {
    const ind = stories.findIndex(as => as.id === payload.id);
    
    // Allowed keys only these keys for now
    const alloweKeys = ['state', 'priority', 'story_type', 'title', 'dev_release_at', 'owned_by_id', 'owned_by_initials', 
    'owned_by_name', 'requested_by_id', 'requested_by_initials', 'requested_by_name', 'reviewer_email', 'reviewer_id', 
    'reviewer_name', 'reviewer_initials', 'project_id', 'timer_elapsed_total', 'reviewer_elapsed_time_total','sprint'];

    if (ind !== -1) {
      if ('deleted_at' in payload || Object.keys(payload.changes).indexOf('deleted_at') !== -1) {
        stories.splice(ind, 1);
      } else {
        const story = stories[ind];
        for (let key in payload.changes) {
          if (alloweKeys.indexOf(key) !== -1) {
            // If dev release at get updated, please format the value to date
            if (key === 'dev_release_at') {
              story.setDevReleaseAt(payload.changes);
            } else {
              story[key] = payload.changes[key];
            }
          }
        }
      }      
    }
  }
}
