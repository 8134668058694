import {Component, OnInit, OnChanges, Input,Renderer2, ViewChild, ElementRef, HostListener} from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { ProjectService } from '../../shared/services/project.service';
import { NotificationService } from '../../shared/services/notification.service';
import { ErrorService } from '../../shared/errorFunction';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {CustomToastService} from '../../shared/services/custom-toast.service';
import {Project} from "../../shared/models/project";
import {EVENT_TYPES, STORY_PRIORITY_OPTIONS, STORY_TYPE_OPTIONS, STORY_STATE, STORY_TYPE, NOT_APPLIED_REVIEWER} from '../../shared/dataTypes';
import {Story} from "../../shared/models/story";
import {User} from "../../shared/models/user";
import {UserService} from "../../shared/services/user.service";
import {StoryService} from "../../shared/services/story.service";
import {SharedService} from "../../shared/services/shared.service";
import { CdkDragDrop, moveItemInArray,transferArrayItem} from '@angular/cdk/drag-drop';
import { CommonStoryService } from '../../shared/services/common-story.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-backlog-stories',
  templateUrl: './backlog-stories.component.html',
  styleUrls: ['./backlog-stories.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({
          opacity:'0',
          top: '50px'
        }),
        animate(100, style({
          opacity:'1',
          top: '40px'
        }))
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate(200, style({
          opacity:'0',
          top: '50px'
        }))
      ])
    ]),
    trigger('slideOut', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({
          opacity:'0',
          top: '0',
          right: '-190px'
        }),
        animate(100, style({
          opacity:'1',
          top: '0',
          right: '0'
        }))
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate(100, style({
          opacity:'0',
          top: '0',
          right: '-190px'
        }))
      ])
    ]),
    trigger('fadeInOut2', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({
          opacity:'0',
          top: '30px'
        }),
        animate(100, style({
          opacity:'1',
          top: '21px'
        }))
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate(100, style({
          opacity:'0',
          top: '30px'
        }))
      ])
    ]),
  ],
  host: {
    '(document:click)': 'onClick($event)'
  }
})
export class BacklogStoriesComponent implements OnInit {
  @Input() currentUser: User;
  @ViewChild('makeFocusName') makeFocusName: ElementRef;
  @ViewChild('scrollableDiv') scrollableDiv: ElementRef;
  sprintStories: Array<Story> = [];
  @Input() project: Project;
  projectId?: number;
  sprintId?: number;
  story: Story;
  showSort:boolean = false;
  openFeatureListPopUp: boolean = false;
  showfilterList:boolean = false;
  searchOwner = '';
  searchReviewer = '';
  searchUser = '';
  storyPriorityOptions: Array<any> = STORY_PRIORITY_OPTIONS;
  storyTypeOptions: Array<any> = STORY_TYPE_OPTIONS;
  waitForLoader:boolean = false;
  startApplyFilterLoader: boolean = false;
  storyListLoader: boolean = false;
  sort_by = 'updated_at';
  searchStoryData = '';
  isPending: boolean = false;
  storyListMeta: {
    current_page: number;
    next_page: number;
    per_page: number;
    total_count: number;
  };
  nxt_page = 1;
  frombacklog:boolean = false;
  dd_type = '';
  story_clicked: Story;
  selected_stories = [];
  select_all_stories: boolean = false;
  private notificationSubscriptions: Subscription[] = [];

  constructor(public projectService: ProjectService,
              private errorService: ErrorService,
              private notificationService: NotificationService,
              private customToast: CustomToastService,
              private router: Router,
              private userService: UserService,
              private storyService: StoryService,
              private sharedService: SharedService,
              private activatedRoute: ActivatedRoute,
              private renderer: Renderer2,
              public commonStoryService: CommonStoryService) { }

  drop(event) {
    if (event.previousContainer === event.container) {
      return;
    } else {
      let story_InDrop: any;
      story_InDrop = {
        story_ids: event.previousContainer.data[event.previousIndex].id,
      };
      transferArrayItem(event.previousContainer.data,
            event.container.data,
            event.previousIndex,
            event.currentIndex);
      this.projectService.removeStoryFromSprint(this.projectId, this.sprintId, story_InDrop).subscribe(res => {
        if (res ) {
          this.customToast.messages.push({
            id: 'story_removed',
            type: 'success',
            class: 'new_member_added',
            title: 'Sprint Stories',
            message: 'Story removed from sprint successfully'
          });
        }
      }, err => {
        transferArrayItem(event.container.data,
           event.previousContainer.data,
           event.currentIndex,
          event.previousIndex);
        this.errorService.errorFunction(err);
      });
    }
  }

  onClick(event) {
    const element = event.target;
    this.commonStoryService.checkOnClick(element);
    if (!(element.classList.contains('sprint-sorting-options') || element.classList.contains('selectedFilter') || element.classList.contains('iconb-sorting'))) {
      this.showSort = false;
    }
  }

  resetStorySelection() {
    this.select_all_stories = false;
    this.selected_stories = [];
    this.sprintStories.forEach(story => {
      story.isSelected = false;
    });
  }

  allStorySelection($event) {
    if($event.target.checked) {
      this.select_all_stories = true;
      this.selected_stories = [];
      this.sprintStories.forEach(story => {
        story.isSelected = true;
        this.selected_stories.push(story.id);
      });
    } else {
      this.select_all_stories = false;
      this.sprintStories.forEach(story => {
        story.isSelected = false;
      });
      this.selected_stories = [];
    }
  }

  toggleStorySelection(event, story: Story) {
    if (event && event.target.checked) {
      story.isSelected = true;
      this.selected_stories.push(story.id); 
      this.checkAllSelection();
    } else {
      story.isSelected = false;
      // find the index of story in selected_story array and remove it
      const index = this.selected_stories.findIndex(st => st === story.id);
      if (index !== -1) {
        this.selected_stories.splice(index, 1);
      }
      this.checkAllSelection();
    }
  }

  checkAllSelection() {
    if ((this.selected_stories.length === this.sprintStories.length) && this.sprintStories.length > 0) {
      this.select_all_stories = true;
    } else {
      this.select_all_stories = false;
    }
  }

  addBulkStories() {
    this.storyListLoader = true;
    const payload = {
      story_ids: this.selected_stories,
    };
    this.projectService.addStoryIntoSprint(this.projectId, this.sprintId, payload).subscribe(res => {
      if (res) {
        this.selected_stories = [];
        this.customToast.messages.push({
          id: 'story_added',
          type: 'success',
          class: 'new_member_added',
          title: 'Sprint Stories',
          message: 'Stories added into sprint successfully'
        });
      }
      this.resetStorySelection();
      this.storyListLoader = false;
    }, err => {
      this.errorService.errorFunction(err)
    });
  }

  

  ngOnInit() {
    this.isPending = false;
    this.activatedRoute.params.subscribe((params: Params) => {
      this.projectId = +params['id'];
      this.sprintId = +params['sprint_id'];
    });
    this.loadBacklogStories(this.projectId, this.sort_by, this.searchStoryData);
    
    const sprintStoryClonedEvent = this.notificationService.subscribeEvent(EVENT_TYPES.STORY.SPRINT_STORY_CLONED, res => {
      if (res && res.payload.data && !res.payload.data.story.sprint_id) {
        this.commonStoryService.addStories(this.sprintStories, res.payload.data.story);
      }
    });
    this.notificationSubscriptions.push(sprintStoryClonedEvent);

    const sprintStoryUpdatedEvent = this.notificationService.subscribeEvent(EVENT_TYPES.STORY.SPRINT_STORY_UPDATED, res => {
      if(res && res.payload.changes) {
        this.commonStoryService.updateStories(this.sprintStories, res.payload);
      }
    });
    this.notificationSubscriptions.push(sprintStoryUpdatedEvent);

    const backlogStoryRemovedEvent = this.notificationService.subscribeEvent(EVENT_TYPES.SPRINT.BACKLOG_STORY_REMOVED, res => {
      const element = this.scrollableDiv.nativeElement;
      const atBottom = element.scrollHeight - element.scrollTop === element.clientHeight;
      if (this.sprintId) {
        if (atBottom && this.storyListMeta) {
          this.storyListLoader = true;
          const perPage = this.storyListMeta.current_page * this.storyListMeta.per_page;
          this.projectService.getSprintsDetailsBacklog(this.projectId, this.sprintId, this.sort_by, this.searchStoryData, 1, this.project, perPage).subscribe(res => {
            this.sprintStories = [];
            this.setSprintStoriesData(res, true);
          }, err => {
            this.sprintHasError(err);
          });
        } else {
          this.storyListLoader = false
        }
      }
    });
    this.notificationSubscriptions.push(backlogStoryRemovedEvent);

    const sprintUpdatedEvent = this.notificationService.subscribeEvent(EVENT_TYPES.SPRINT.SPRINT_UPDATED, res => {
      this.sprintStories.forEach(story => {
        if (story.sprint && story.sprint.id === res.payload.sprint.id) {
          story.sprint.name = res.payload.sprint.name;
        }
      });
    });
    this.notificationSubscriptions.push(sprintUpdatedEvent);

    const backlogStoryAddedEvent = this.notificationService.subscribeEvent(EVENT_TYPES.SPRINT.BACKLOG_STORY_ADDED, res => {
      if (this.sprintId && this.storyListMeta) {
        const perPage = this.storyListMeta.current_page * this.storyListMeta.per_page;
        this.projectService.getSprintsDetailsBacklog(this.projectId, this.sprintId, this.sort_by, this.searchStoryData, 1, this.project, perPage).subscribe(res => {
          this.sprintStories = [];
          this.setSprintStoriesData(res, true);
        }, err => {
          this.sprintHasError(err);
        });
      }
    });
    this.notificationSubscriptions.push(backlogStoryAddedEvent);

    const storyRemovedEvent = this.notificationService.subscribeEvent(EVENT_TYPES.STORY.SPRINT_STORY_REMOVED, res => {
      if (res.payload.action === 'bulk_archive') {
        this.commonStoryService.removeMultipleStories(this.sprintStories, res.payload);
      }
    });
    this.notificationSubscriptions.push(storyRemovedEvent);

    const storyArchivedEvent = this.notificationService.subscribeEvent(EVENT_TYPES.STORY.ARCHIVE, res => {
      if (res && res.payload.data.story_in === 'backlog_story' && res.payload.data.story.deleted_at) {
        this.removeStoryFromList(res.payload.data.story);
      }
    });
    this.notificationSubscriptions.push(storyArchivedEvent);

    const sprintFilterEvent = this.notificationService.subscribeEvent(EVENT_TYPES.SPRINT.SPRINT_FILTER, res => {
      if (res && res.payload.data.filter_at === 'sprint_dashboard') {
        this.nxt_page = 1;
        this.projectService.sprintFilter = true;
        this.loadBacklogStories(this.projectId, this.sort_by, this.searchStoryData);
      } else {
        this.nxt_page = 1;
        this.projectService.sprintDetailFilter = true;
        this.loadBacklogStories(this.projectId, this.sort_by, this.searchStoryData);
      }
    });
    this.notificationSubscriptions.push(sprintFilterEvent);

    const sprintStoryAddedEvent = this.notificationService.subscribeEvent(EVENT_TYPES.SPRINT.SPRINT_STORY_ADDED, res => {
      if(res && (this.sprintId === res.payload.sprint.id) && (res.payload.stories.length > 0)) {
        res.payload.stories.forEach(as => {
          this.removeStoryFromList(as);
        });
      }else if (res && (this.sprintId !== res.payload.sprint.id) && (res.payload.stories.length > 0)) {
        this.addStoryInBacklog(res);
      }
    });
    this.notificationSubscriptions.push(sprintStoryAddedEvent);

    const sprintStoryRemovedEvent = this.notificationService.subscribeEvent(EVENT_TYPES.SPRINT.SPRINT_STORY_REMOVED, res => {
      if(res && (this.sprintId === res.payload.sprint.id) && (res.payload.stories.length > 0)) {
        this.resetStorySelection();
        this.addStoryInBacklog(res);
      }
    });
    this.notificationSubscriptions.push(sprintStoryRemovedEvent);
  }

  removeStoryFromList(data) {
    const index = this.sprintStories.findIndex(st => st.id === data.id);
    // need to decreament this.storyListMeta total_count
    this.storyListMeta.total_count = this.storyListMeta.total_count - 1;
    if (index !== -1) {
      this.sprintStories.splice(index, 1);
    }
  }

  getEpics() {
    return Object.assign([], this.project.epics);
  }

  sortStoryList(value) {
    this.resetStorySelection();
    this.storyListLoader = true;
    this.sort_by = value;
    this.sprintStories = [];
    this.nxt_page = 1;
    this.storyService.searchStoriesVal = this.searchStoryData;
    this.loadBacklogStories(this.projectId, this.sort_by, this.searchStoryData);
  }

  isScrollFetchAvailable(): boolean {
    return !this.isPending && this.storyListMeta && this.storyListMeta.next_page != null;
  }

  getMoreSprintStories() {
    if (this.storyListMeta && this.storyListMeta.next_page) {
      this.nxt_page = this.storyListMeta.current_page + 1;
      this.loadBacklogStories(this.projectId, this.sort_by, this.searchStoryData);
    } else {
      this.nxt_page = 2;
      this.loadBacklogStories(this.projectId, this.sort_by, this.searchStoryData);
    }
  }

  loadBacklogStories(projectId, sort_by, s_data) {
    this.isPending = true;
    this.storyListLoader = true;
    this.storyService.searchStoriesVal = this.searchStoryData;
    if (this.nxt_page === 1) {
      this.sprintStories = [];
      this.storyListLoader = true;
    } else {
      this.storyListLoader = false;
    }
    if (this.sprintId) {
      this.projectService.getSprintsDetailsBacklog(projectId, this.sprintId, sort_by, s_data, this.nxt_page, this.project).subscribe(res => {
        this.setSprintStoriesData(res, true);
      }, err => {
        this.sprintHasError(err);
      });
    } else {
      this.projectService.getProjectSprintsBacklog(projectId, sort_by, s_data, this.nxt_page, this.project).subscribe(res => {
        this.setSprintStoriesData(res, true);
      }, err => {
        this.sprintHasError(err);
      });
    }
  }

  sprintHasError(err) {
    this.isPending = false;
    this.storyListLoader = false;
    this.startApplyFilterLoader = false;
    this.projectService.startApplyFilterLoader = false;
    this.errorService.errorFunction(err);
  }

  setSprintStoriesData(res, listMeta = true) {
    if (this.nxt_page === 1) {
      this.sprintStories = [];
    }
    if (res ) {
      res.data.forEach((storyData) => {
        const story = new Story(storyData);
        this.sprintStories.push(story);
      });
      this.storyListLoader = false;
      this.startApplyFilterLoader = false;
      if(listMeta) {
        this.storyListMeta = res.meta;
      }
    }
    this.isPending = false;
    this.projectService.startApplyFilterLoader = false;
    this.checkAllSelection();
  }

  ngOnDestroy() {
    this.notificationSubscriptions.forEach(subscription => subscription.unsubscribe());
    this.storyService.searchStoriesVal = '';
  }

  searchStory() {
    this.resetStorySelection();
    this.storyService.searchStoriesVal = this.searchStoryData;
    this.nxt_page = 1;
    this.loadBacklogStories(this.projectId, this.sort_by, this.searchStoryData);
  }

  checkifSearchInputEmpty() {
    if (!this.searchStoryData) {
      this.nxt_page = 1;
      this.loadBacklogStories(this.projectId, this.sort_by, this.searchStoryData);
    }
  }

  openSort() {
    this.showSort = !this.showSort;
  }

  openFeaturelist() {
    this.openFeatureListPopUp = true;
  }

  toggelefilter() {
    this.showfilterList = !this.showfilterList;
    if (this.showfilterList) {
      this.userService.trackSegmentEvent('Filter Open', this.projectService.getCommonPropsForStoryBoardEvents(this.project));
    }
    if(this.selected_stories.length > 0) {
      this.resetStorySelection();
    }
  }

  closeFilter() {
    this.showfilterList = false;
  }

  showEditStoryTitleInput(story) {
    story.showStoryTitleInput = true;
    setTimeout(() => {
      document.getElementById('focusTitle').focus();
    }, 0);
  }

  makeClone(story, cloneFromWhere) {
    this.frombacklog = cloneFromWhere;
    story.showMoreStoryAction = false; 
    this.projectService.selectedStory = story;
    this.commonStoryService.dd_type = '';
    this.projectService.cloneConfirmPopup = true;
    this.renderer.addClass(document.body, 'duplicateStoryPopup-open');
    // this.commonStoryService.makeClone(story, this.project);
  }

  hide_StoryDetails() {
    this.projectService.hide_StoryDetails();
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.projectService.hide_StoryDetails();
  }

  clearAllFilters() {
    this.storyListLoader = true;
    this.nxt_page = 1;
    if (!this.startApplyFilterLoader) {
      this.startApplyFilterLoader = true;
      this.project.clearFilter();
      this.sprintStories = [];
      this.loadBacklogStories(this.projectId, this.sort_by, this.searchStoryData);
    }
  }

  getStoryURL(story_id) {
    return `${window.location.origin}/#/projects/${this.project.id}` + `?storyId=${story_id}`;
  }

  showHideBacklog() {
    this.projectService.show_hide_backlog = !this.projectService.show_hide_backlog;
  }
  
  addStoryInBacklog(res){
    res.payload.stories.forEach(as => {
      let ind = this.sprintStories.findIndex(sprint_story => sprint_story.id === as.id);
      if(ind === -1) {
        let story;
        if (this.projectService.show_Story && as.id === this.projectService.show_Story.id) {
          story = this.projectService.show_Story;
        }else{
          story = new Story(as);
        }  
        this.sprintStories.unshift(story);
      }
    });
  }

}
