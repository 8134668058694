import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'sign-in-oauth',
    templateUrl: 'sign-in-oauth.component.html'
})
export class SignInOAuthComponent {

    public output: any;

    constructor() { 
        // this is intentional
    }

    // Submit Data to Backend
    onSubmit() {

        this.output = null;
    }
}
