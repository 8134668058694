import { ErrorHandler, Injectable } from "@angular/core";
import * as Sentry from "@sentry/browser";
import { environment } from '../../../environments/environment';

Sentry.init({
    dsn: "https://c70ae24e59214d77897a3cb24254ccc4@sentry.engineer.ai/9"
  });

  @Injectable()
  export class SentryErrorHandler implements ErrorHandler {
    constructor() {
      // This is intentional
    }
    handleError(error) {
      if (environment.enableSentry) {
        const eventId = error && error.originalError ? Sentry.captureException( error && error.originalError) :
                                                        Sentry.captureException( error);
        //   Sentry.showReportDialog({ eventId });
      }
    }
  }
