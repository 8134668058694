import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { Angulartics2Module } from 'angulartics2';
import { Angulartics2Segment } from 'angulartics2/segment';
import { FileUploadModule } from '../lib';
// import { ChartsModule } from 'ng2-charts';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { ExampleModule } from './example';
import { AuthenticationModule } from './authentication';
import { RestrictedModule } from './restricted';
import { SharedModule } from './shared/shared.module';
import { HeaderModule } from './header/header.module';
import { NotificationsModule } from './notifications';
import { DashboardModule } from './dashboard';
import { ProjectDashboardModule } from './projectDashboard';
import { ProjectModule } from './project';
import { ProjectImportModule } from './projectImport';
import { AccountModule } from './account';
import { AdminModule } from './admin/admin.module';
import { CopiedComponent } from './project/story/copied.component';
import { routes } from './app.routes';
import { MemberModule } from './member/member.module';
import {ProjectBriefModule} from './project-brief';
import { ProjectSettingModule } from './project-setting/project-setting.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { HelpSupportComponent } from './help-support/help-support.component';
import { ProjectExportModule } from './project-export/project-export.module';
import {WhiteBoardModule} from './white-board/white-board.module';
import {ProjectReleasesModule} from './project-releases/project-releases.module';
import { FeedbackloopModule } from './feedbackloop-dashboard/feedbackloop.module';
import { TestSuiteModule } from './test-suite/test-suite.module';
import { LeavePlannerModule } from './leave-planner/leave-planner.module';


// @ts-ignore
@NgModule({
  imports: [
    routes,
    BrowserModule,
    BrowserAnimationsModule, // required animations module
    FormsModule,
    // ChartsModule,
    DragDropModule,
    HttpClientModule,
    ExampleModule,
    AuthenticationModule,
    SharedModule,
    HeaderModule,
    NotificationsModule,
    DashboardModule,
    ProjectModule,
    RestrictedModule,
    FileUploadModule,
    ProjectDashboardModule,
    WhiteBoardModule,
    ProjectBriefModule,
    ProjectImportModule,
    AccountModule,
    AdminModule,
    MemberModule,
    Angulartics2Module.forRoot([Angulartics2Segment]),  // added for user event tracking
    ProjectSettingModule, ServiceWorkerModule.register('ngsw-worker.js', { enabled: true }),
    ProjectReleasesModule,
    ProjectExportModule,
    FeedbackloopModule,
		TestSuiteModule,
    LeavePlannerModule,
  ],
  providers: [
  ],
  declarations: [
    AppComponent,
    HelpSupportComponent,
    CopiedComponent
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {
}
