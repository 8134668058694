<div class="authMainContainer">

  <div class="rightSection">
    <div class="logo"><img src="../../../assets/images/trackerLogo2.png" alt="eTracker" class="head-logo"></div>
    <div class="sign-box-center">
        <ng-container *ngIf="State.Initial === state">
          <div class="auth-title2">Forgot your password?</div>
          <div class="notifyText2">Please enter your registered email address to retrieve password.</div>
          <div class="authForm">
            <form (ngSubmit)="onSubmit()">
              <div class="authForm-block" [class.has-error]="errors">
                <span class="inputLabel">Email address</span>
                <input type="text" class="authInput" name="email" placeholder="Your email" [(ngModel)]="resetPasswordData.email">
                <p class="help-block">{{ errors }}</p>
              </div>
              <button type="submit" class="submitAuth" [disabled]="!canSubmit()">Send</button>
              <div class="fgtBlock2">Back to <span class="forgotPassword-link" routerLink="/login">Login</span></div>
            </form>
          </div>
        </ng-container>
        <ng-container *ngIf="State.EmailSent === state">
          <div class="auth-title2">Email Sent</div>
          <div class="notifyText2">{{ message }}</div>
          <div class="fgtBlock2">Back to <span class="forgotPassword-link" routerLink="/login">Login</span></div>
        </ng-container>
    </div>
  </div>
  <div class="leftSection">
    <!--<img src="../../../assets/images/builderTracker_W_T.png" alt="eTracker" class="head-logo">-->
  </div>
</div>
