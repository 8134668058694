<ng-container *ngIf="!memberships && indexIsPending">
  <span class="fa fa-spin fa-spinner"></span>
  <ng-container *ngIf="isUser">Loading projects</ng-container>
  <ng-container *ngIf="isProject">
    <div class="st-Loader projectDetailLoader-member">
      <div class="in-Loader"><img src="../../assets/images/builder_black.png" alt="loader"/></div>
      <div class="loadingOverlay"></div>
    </div>
  </ng-container>
</ng-container>

<ng-container *ngIf="memberships">
  <div class="tabletopSection">
    <ng-container *ngIf="isUser">
      <div class="topHeading">Projects</div>
    </ng-container>
    <ng-container *ngIf="isProject">
      <div class="topHeading">Members ({{meta.total_count}})</div>
    </ng-container>

    <div class="searchMember" *ngIf="isProject">
      <input type="search"
             class="searchMember-input"
             placeholder="Search"
             name="search"
             autocomplete="off"
             [(ngModel)]="params.q"
             (input)="getSearchRecords()">
      <button class="searchMember-button"><em class="iconb-search"></em></button>
    </div>

    <span class="exportIcon" title="Export" (click)="!is_exporting && exportUser()" *ngIf="isProject">
      <img src="../../../../assets/images/export-file.svg" alt="loader"/>
    </span>

    <div class="dateFilter" *ngIf="isProject">
      <span class="datefilterIcon" title="Filter Member Progress" (click)="dpStart.openCalendar(); dpEnd.openCalendar(); openFilterDate()"><img src="../../../../assets/images/dateFilter.png" alt="date filter"/></span>
      <div class="dateFilter-popup" [ngClass]="{'dateFilter-popupOpen': openDateFilter}" [class.dateFilter-popupOpenW]="memberships.length < 5">
        <div class="dateFilter-backdrop" (click)="closeFilterDate()"></div>
        <div class="dateFilter-wrapper">
          <div class="dateFilter-top">Member progress</div>
          <span class="dateFilter-reset" (click)="resetCalender(dpStart, dpEnd)">Reset</span>
          <div class="contentWrapper">
            <div class="startDateCalendar">
              <div class="dateInfo">
                <div class="calendarBlock">
                  <span class="dateInfo-title">Start Date: </span>
                  <input
                    class="dateInfo-date"
                    placeholder="Select"
                    angular-mydatepicker
                    name="startdate"
                    [(ngModel)]="startDate"
                    [options]="myOptionsStartDate"
                    #dpStart="angular-mydatepicker"
                    (dateChanged)="onDateChanged($event, 'start', dpEnd)"/>
                </div>
              </div>
            </div>
            <div class="EndDateCalendar">
              <div class="dateInfo">
                <div class="calendarBlock">
                  <span class="dateInfo-title">End Date: </span>
                  <input
                    class="dateInfo-date"
                    placeholder="Select"
                    angular-mydatepicker
                    name="enddate"
                    [(ngModel)]="endDate"
                    [options]="myOptionsEndDate"
                    #dpEnd="angular-mydatepicker"
                    (dateChanged)="onDateChanged($event, 'end', dpEnd)"/>
                </div>
              </div>
            </div>
            <div class="preFilter">
              <span class="preFilter-list" (click) = "setStartEndDate(1)">This Month</span>
              <span class="preFilter-list" (click) = "setStartEndDate(3)">Last 3 Months</span>
              <span class="preFilter-list" (click) = "setStartEndDate(6)">Last 6 Months</span>
              <span class="preFilter-list" (click) = "setStartEndDate(9)">Last 9 Months</span>
              <span class="preFilter-list" (click) = "setStartEndDate(12)">Last Year</span>
            </div>
          </div>
          <div class="dateFilter-actionWrapper">
            <button type="button" class="dateFilter-cancel" (click)="closeFilterDate()">Cancel</button>
            <button type="button" class="dateFilter-apply" (click) = "filterMemberShipData(dpEnd)">Apply</button>
          </div>
        </div>
      </div>
    </div>

    <button class="createNewMember" *ngIf = "(isEditable && isUser) || (isProject)"
      container="body"
      placement="bottom"
      outsideClick="true"
      (onShown)="onPopoverShown()"
      (onHidden)="onPopoverHidden()">
      <ng-container *ngIf="isUser">+ Add to Project</ng-container>
      <ng-container *ngIf="isProject">+ Add Member</ng-container>
    </button>
  </div>
  <div class="allMemberList-wrqapper" *ngIf="isProject">

    <div class="noMemberFound-message" *ngIf="memberships.length == 0">
      <img src="../../../../assets/images/no-member-found.png" alt="no member">
      <span class="notFoundText"><strong> No member found </strong></span>
    </div>
    <div class="allMemberList-wrqapper2" *ngIf="this.memberships?.length">
      <div class="allMemberList-topHead">
        <div class="nameBlock">Name</div>
        <div class="roleBlock">Role</div>
        <div class="createdBlock">Added on</div>
        <div class="projectBlock">Status</div>
        <div class="hrsBlock">Tracker usage (hrs)</div>
        <div class="requesterBlock">Requestor count</div>
        <div class="ownerBlock">Owner count</div>
        <div class="reviewerBlock">Reviewer count</div>
        <div class="reviewerBlock">Rejection count</div>
        <div class="removeMemberBlock">Remove</div>
      </div>
      <div class="allMemberList-content">
        <div class="allMemberList-listing" *ngFor="let membership of memberships">
          <div class="nameBlock">
            <div class="nameWithInitials">{{ membership.user.name}} ({{membership.user.initials}})</div>
            <div class="memEmail">{{membership.user.email}}</div>
          </div>
          <div class="roleBlock" *ngIf="!membership.editable">
            <span class="selectedRole">{{membership.role}}</span>
          </div>
          <div class="roleBlock" *ngIf="membership.editable">
            <span class="selectedRole" (click)="openSelectRoleDD(membership)">{{membership.role}} <em class="iconb-dd-down"></em></span>
            <div class="user-role-DD" [ngClass]="{'openDDSelection': membership.openSelectDD}">
              <span class="selectRole-list" *ngFor="let role of roles" (click)="updateMembership(role, membership)">
                {{role}}
              </span>
            </div>
          </div>
          <div class="createdBlock">{{membership.added_on}}</div>
          <div class="projectBlock">
            <span class="ifInvitationIsAccepted" *ngIf="membership.accepted_at">Member</span>
            <span class="ifInvitationIsNotAccepted" *ngIf='!membership.accepted_at'>Invitation Unaccepted
             <button type="button" class="reinviteButton" (click)='resendInvite(membership)'>Resend</button>
           </span>
          </div>
          <div class="hrsBlock">{{membership.usage}}</div>
          <div class="requesterBlock">
            <div class="count-box">{{membership.requestor_count}}
              <div class="storyCounter-info">
                <span class="typeLabel">Request Type</span>
                <div class="storyTypeBlock">
                  <span class="ss-type">
                    <em class="iconb-task taskIcons"></em>
                    <span class="typeCounter">{{ membership.requestor_type?.task?membership.requestor_type?.task:'0' }}</span>
                  </span>
                  <span class="ss-type">
                    <em class="iconb-bug bugIcons"></em>
                    <span class="typeCounter">{{ membership.requestor_type?.bug?membership.requestor_type?.bug:'0' }}</span>
                  </span>
                  <span class="ss-type">
                    <em class="iconb-side-effect sideeffectIcons"></em>
                    <span class="typeCounter">{{ membership.requestor_type?.side_effect?membership.requestor_type?.side_effect:'0' }}</span>
                  </span>
                  <span class="ss-type">
                    <em class="iconb-chore choreIcons"></em>
                    <span class="typeCounter">{{ membership.requestor_type?.chore?membership.requestor_type?.chore:'0' }}</span>
                  </span>
                  <span class="ss-type">
                    <em class="iconb-release releaseIcons"></em>
                    <span class="typeCounter">{{ membership.requestor_type?.release?membership.requestor_type?.release:'0' }}</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="ownerBlock">
            <div class="count-box">
              {{membership.owner_count}}
              <div class="storyCounter-info">
                <span class="typeLabel">Unstarted</span>
                <div class="storyTypeBlock">
                  <span class="ss-type">
                    <em class="iconb-task taskIcons"></em>
                    <span class="typeCounter">{{ membership.owner_unstarted?.task?membership.owner_unstarted?.task:'0' }}</span>
                  </span>
                  <span class="ss-type">
                    <em class="iconb-bug bugIcons"></em>
                    <span class="typeCounter">{{ membership.owner_unstarted?.bug?membership.owner_unstarted?.bug:'0' }}</span>
                  </span>
                  <span class="ss-type">
                    <em class="iconb-side-effect sideeffectIcons"></em>
                    <span class="typeCounter">{{ membership.owner_unstarted?.side_effect?membership.owner_unstarted?.side_effect:'0' }}</span>
                  </span>
                  <span class="ss-type">
                    <em class="iconb-chore choreIcons"></em>
                    <span class="typeCounter">{{ membership.owner_unstarted?.chore?membership.owner_unstarted?.chore:'0' }}</span>
                  </span>
                  <span class="ss-type">
                    <em class="iconb-release releaseIcons"></em>
                    <span class="typeCounter">{{ membership.owner_unstarted?.release?membership.owner_unstarted?.release:'0' }}</span>
                  </span>
                </div>
                <span class="typeLabel">Ongoing</span>
                <div class="storyTypeBlock">
                <span class="ss-type">
                  <em class="iconb-task taskIcons"></em>
                  <span class="typeCounter">{{ membership.owner_ongoing?.task ? membership.owner_ongoing?.task : '0' }}</span>
                </span>
                  <span class="ss-type">
                  <em class="iconb-bug bugIcons"></em>
                  <span class="typeCounter">{{ membership.owner_ongoing?.bug ? membership.owner_ongoing?.bug : '0' }}</span>
                </span>
                  <span class="ss-type">
                  <em class="iconb-side-effect sideeffectIcons"></em>
                  <span class="typeCounter">{{ membership.owner_ongoing?.side_effect ? membership.owner_ongoing?.side_effect : '0' }}</span>
                </span>
                  <span class="ss-type">
                  <em class="iconb-chore choreIcons"></em>
                  <span class="typeCounter">{{ membership.owner_ongoing?.chore ? membership.owner_ongoing?.chore : '0' }}</span>
                </span>
                  <span class="ss-type">
                  <em class="iconb-release releaseIcons"></em>
                  <span class="typeCounter">{{ membership.owner_ongoing?.release ? membership.owner_ongoing?.release : '0' }}</span>
                </span>
                </div>
                <span class="typeLabel">Completed</span>
                <div class="storyTypeBlock">
                <span class="ss-type">
                  <em class="iconb-task taskIcons"></em>
                  <span class="typeCounter">{{ membership.owner_completed?.task ? membership.owner_completed?.task : '0' }}</span>
                </span>
                  <span class="ss-type">
                  <em class="iconb-bug bugIcons"></em>
                  <span class="typeCounter">{{ membership.owner_completed?.bug ? membership.owner_completed?.bug : '0' }}</span>
                </span>
                  <span class="ss-type">
                  <em class="iconb-side-effect sideeffectIcons"></em>
                  <span class="typeCounter">{{ membership.owner_completed?.side_effect ? membership.owner_completed?.side_effect : '0' }}</span>
                </span>
                  <span class="ss-type">
                  <em class="iconb-chore choreIcons"></em>
                  <span class="typeCounter">{{ membership.owner_completed?.chore ? membership.owner_completed?.chore : '0' }}</span>
                </span>
                  <span class="ss-type">
                  <em class="iconb-release releaseIcons"></em>
                  <span class="typeCounter">{{ membership.owner_completed?.release ? membership.owner_completed?.release : '0' }}</span>
                </span>
                </div>
              </div>
            </div>
          </div>
          <div class="reviewerBlock">
            <div class="count-box">
              {{membership.reviewer_count}}
              <div class="storyCounter-info">
                <span class="typeLabel">Unstarted</span>
                <div class="storyTypeBlock">
                  <span class="ss-type">
                    <em class="iconb-task taskIcons"></em>
                    <span class="typeCounter">{{ membership.reviewer_unstarted?.task?membership.reviewer_unstarted?.task:'0' }}</span>
                  </span>
                  <span class="ss-type">
                    <em class="iconb-bug bugIcons"></em>
                    <span class="typeCounter">{{ membership.reviewer_unstarted?.bug?membership.reviewer_unstarted?.bug:'0' }}</span>
                  </span>
                  <span class="ss-type">
                    <em class="iconb-side-effect sideeffectIcons"></em>
                    <span class="typeCounter">{{ membership.reviewer_unstarted?.side_effect?membership.reviewer_unstarted?.side_effect:'0' }}</span>
                  </span>
                  <span class="ss-type">
                    <em class="iconb-chore choreIcons"></em>
                    <span class="typeCounter">{{ membership.reviewer_unstarted?.chore?membership.reviewer_unstarted?.chore:'0' }}</span>
                  </span>
                  <span class="ss-type">
                    <em class="iconb-release releaseIcons"></em>
                    <span class="typeCounter">{{ membership.reviewer_unstarted?.release?membership.reviewer_unstarted?.release:'0' }}</span>
                  </span>
                </div>
                <span class="typeLabel">Ongoing</span>
                <div class="storyTypeBlock">
                <span class="ss-type">
                  <em class="iconb-task taskIcons"></em>
                  <span class="typeCounter">{{ membership.reviewer_ongoing?.task?membership.reviewer_ongoing?.task:'0' }}</span>
                </span>
                  <span class="ss-type">
                  <em class="iconb-bug bugIcons"></em>
                  <span class="typeCounter">{{ membership.reviewer_ongoing?.bug?membership.reviewer_ongoing?.bug:'0' }}</span>
                </span>
                  <span class="ss-type">
                  <em class="iconb-side-effect sideeffectIcons"></em>
                  <span class="typeCounter">{{ membership.reviewer_ongoing?.side_effect?membership.reviewer_ongoing?.side_effect:'0' }}</span>
                </span>
                  <span class="ss-type">
                  <em class="iconb-chore choreIcons"></em>
                  <span class="typeCounter">{{ membership.reviewer_ongoing?.chore?membership.reviewer_ongoing?.chore:'0' }}</span>
                </span>
                  <span class="ss-type">
                  <em class="iconb-release releaseIcons"></em>
                  <span class="typeCounter">{{ membership.reviewer_ongoing?.release?membership.reviewer_ongoing?.release:'0' }}</span>
                </span>
                </div>
                <span class="typeLabel">Completed</span>
                <div class="storyTypeBlock">
                <span class="ss-type">
                  <em class="iconb-task taskIcons"></em>
                  <span class="typeCounter">{{ membership.reviewer_completed?.task?membership.reviewer_completed?.task:'0' }}</span>
                </span>
                  <span class="ss-type">
                  <em class="iconb-bug bugIcons"></em>
                  <span class="typeCounter">{{ membership.reviewer_completed?.bug?membership.reviewer_completed?.bug:'0' }}</span>
                </span>
                  <span class="ss-type">
                  <em class="iconb-side-effect sideeffectIcons"></em>
                  <span class="typeCounter">{{ membership.reviewer_completed?.side_effect?membership.reviewer_completed?.side_effect:'0' }}</span>
                </span>
                  <span class="ss-type">
                  <em class="iconb-chore choreIcons"></em>
                  <span class="typeCounter">{{ membership.reviewer_completed?.chore?membership.reviewer_completed?.chore:'0' }}</span>
                </span>
                  <span class="ss-type">
                  <em class="iconb-release releaseIcons"></em>
                  <span class="typeCounter">{{ membership.reviewer_completed?.release?membership.reviewer_completed?.release:'0' }}</span>
                </span>
                </div>
              </div>
            </div>
            </div>
          <div class="rejectionCountBlock">{{membership.rejection_count}}</div>
          <div class="removeMemberBlock">
            <span *ngIf="!membership.editable">N/A</span>
            <button class="removeMemberButton" *ngIf="membership.editable" (click)="openTaskPopup(membership)" [disabled]="itemPendingId || (!membership.editable)">
              <em class="iconb-delete-icon" *ngIf="itemPendingId != membership.id"></em>
              <span *ngIf="itemPendingId == membership.id" class="fa fa-spin fa-spinner"></span>
            </button>
          </div>
        </div>
        <div class="paginationWrapper" *ngIf="meta">
          <ul class="pagination">
          <li [class.disabled]="!previousPageAvailable()">
          <span (click)="previousPage()">&laquo;</span>
          </li>
          <li *ngFor="let page of pages" [class.active]="page == params.page">
          <span (click)="goToPage(page)">{{page}}</span>
          </li>
          <li [class.disabled]="!nextPageAvailable()">
          <span (click)="nextPage()">&raquo;</span>
          </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <table role="presentation" class="table table-bordered table-condensed table-hover table-memberships"
         [class.half-opacity]="indexIsPending" *ngIf="isUser">
    <thead>
      <tr>
        <td align="center">
          ID
        </td>
        <td *ngIf="isUser">
          Project Name
        </td>
        <td *ngIf="isProject">
          Name
        </td>
        <td colspan="2">
          Role
        </td>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let membership of memberships"
          [class.pending]="itemPendingId == membership.id">
        <td width="10%" *ngIf="isUser">{{ membership.project_id }}</td>
        <td width="10%" *ngIf="isProject">{{ membership.user_id }}</td>
        <td width="70%" *ngIf="isUser">
          {{ membership.project.name }}
        </td>
        <td width="70%" *ngIf="isProject">
          {{ membership.user.name_or_email  }}
        </td>
        <td width="20%" class="no-padding">
          <select [disabled] = "!membership.editable" [(ngModel)]="membership.role"
                  (ngModelChange)="updateMembership(membership)">
            <option *ngFor="let role of roles"
                    [ngValue]="role">
              {{ role }}
            </option>
          </select>
        </td>
        <td class="no-padding">
          <button (click)="openTaskPopup(membership)"
                  [disabled]="itemPendingId || (!membership.editable)">
            <span *ngIf="itemPendingId != membership.id"
                  class="glyphicon glyphicon-remove">
            </span>
            <span *ngIf="itemPendingId == membership.id"
                  class="fa fa-spin fa-spinner"></span>
          </button>
        </td>
      </tr>
      <tr *ngIf="!isPending && !memberships.length">
        <td colspan="3" class="no-memberships">
          <ng-container *ngIf="isUser">
            User doesn't belong to any project yet.
          </ng-container>
          <ng-container *ngIf="isProject">
            Project doesn't have any members yet.
          </ng-container>
        </td>
      </tr>
    </tbody>
  </table>

  <div class="clearfix" *ngIf="meta && isUser">
    <div class="btn-group pull-right">
      <button class="btn btn-link btn-xs"
              [disabled]="!previousPageAvailable()"
              (click)="previousPage()">
          &laquo;
      </button>
      <button class="btn btn-link btn-xs"
              *ngFor="let page of pages"
              (click)="goToPage(page)"
              [class.active]="params.page == page">
        {{page}}
      </button>
      <button class="btn btn-link btn-xs"
              [disabled]="!nextPageAvailable()"
              (click)="nextPage()">
        &raquo;
      </button>
    </div>
  </div>

</ng-container>

<ng-template #createMembershipTemplate>
  <form autocomplete="off" (ngSubmit)="createMembership()">
      <div class="form-group" *ngIf="isUser">
        <label class="control-label">Project</label>
        <select [(ngModel)]="createForm.project_id"
                name="project_id"
                class="form-control input-sm">
          <option disabled value="0">
            <ng-container *ngIf="projectsIsPending">
              Loading projects...
            </ng-container>
            <ng-container *ngIf="!projectsIsPending">
              Select project
            </ng-container>
          </option>
          <option *ngFor="let project of projects" [ngValue]="project.id">
            {{ project.name }}
          </option>
        </select>
      </div>
      <div class="form-group" *ngIf="isProject">
        <label class="control-label">User</label>
        <select [(ngModel)]="createForm.user_id"
                name="user_id"
                class="form-control input-sm">
          <option disabled value="0">
            <ng-container *ngIf="usersIsPending">
              Loading users...
            </ng-container>
            <ng-container *ngIf="!usersIsPending">
              Select user
            </ng-container>
          </option>
          <option *ngFor="let user of users" [ngValue]="user.id">
            {{ user.name_or_email }}
          </option>
        </select>
      </div>
      <div class="form-group">
        <label class="control-label">Role</label>
        <select [(ngModel)]="createForm.role"
                class="form-control input-sm"
                name="role">
          <option disabled value="0">Select role</option>
          <option *ngFor="let role of roles" [ngValue]="role">
            {{role.charAt(0).toUpperCase() + role.slice(1)}}
          </option>
        </select>
      </div>
      <button type="submit"
              class="btn btn-primary btn-sm btn-block"
              [disabled]="!canSubmit() || createIsPending">
        <span *ngIf="!createIsPending" class="fa fa-user-plus"></span>
        <span *ngIf="createIsPending" class="fa fa-spin fa-spinner"></span>
        Add user to project
      </button>
  </form>
</ng-template>


<app-custom-popup   [texts] = "{ptitle: 'Delete', ptext: 'Are you sure you want to delete?', psuccess: 'Delete', pcancel: 'Cancel'}" (closeCustomPopUp) = "closeTaskPopUp()" (deleteElement) = "deleteMembership($event)" [delement] = "elem" [OpenPopUp] = "OpenPopUp" [waitForLoader] = "waitForLoader"></app-custom-popup>
