import { Injectable } from '@angular/core';
import { ProjectService } from './project.service';
import { CustomToastService } from './custom-toast.service';
import { ErrorService } from '../errorFunction';

@Injectable()
export class SprintService {
editSprint:boolean = false;
startLoader:boolean = false;
enterDateAlertPopup: boolean;

  constructor(
    private customToast: CustomToastService,
    private errorService: ErrorService,
    private projectService: ProjectService
  ) {}

  updateSprintDetails(sprint_Details, projectId) {
    this.startLoader = true;
    this.projectService.updateSprint(sprint_Details, projectId).subscribe((res: any) => {
      this.customToast.messages.push({
        id: 'sprintDetailUpdated', type: 'success',
        class: 'new_member_added',
        title: 'Sprint Updated',
        message: 'Sprint updated successfully'
      });
      this.startLoader = false;
      this.editSprint = false;
      this.enterDateAlertPopup = false;
    }, (error) => {
      this.startLoader = false;
      this.enterDateAlertPopup = false;
      this.errorService.errorFunction(error);
    });
  }

  addSprint(createSprint, projectId) {
    this.startLoader = true;
    this.projectService.createSprint(createSprint, projectId).subscribe((res: any) => {
      this.customToast.messages.push({
        id: 'sprintAdded', type: 'success',
        class: 'new_member_added',
        title: 'Sprint Created',
        message: 'New sprint created successfully'
      });
      this.startLoader = false;
      this.editSprint = false;
      this.enterDateAlertPopup = false;
    }, (error) => {
      this.startLoader = false;
      this.enterDateAlertPopup = false;
      this.errorService.errorFunction(error);
    });
  }

  closeDateAlertPopup(){
    this.enterDateAlertPopup = false;
  }
 
}
