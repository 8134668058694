import { Component } from '@angular/core';

@Component({
  selector: 'app-admin-timing-sidebar',
  templateUrl: 'sidebar.component.html',
  styleUrls: ['sidebar.component.scss']
})
export class TimingSidebarComponent {

  constructor() {
    // This is intentional
   }

}
