import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { SharedModule } from './../../shared/shared.module';

import { RegisterComponent } from './register.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        SharedModule
    ],
    declarations: [
        // RegisterComponent
    ],
    exports: [
        // RegisterComponent
    ]
})
export class RegisterModule { }
