<div class="st-Loader adminTimingLoader" *ngIf="startLoader">
  <div class="in-Loader"><img src="../../assets/images/builder_black.png" alt="loader"/></div>
  <div class="loadingOverlay"></div>
</div>

<div [class.pending]="isPending">
  <div class="row">
    <div class="col-sm-12">
      <h3>Users</h3>
    </div>
  </div>

  <div class="rpw">
    <div class="col-sm-12">
      <div class="table-wrapper table-timing">
        <table role="presentation" class="table table-hover table-striped">
          <thead>
            <tr>
              <td width="67%">Name</td>
              <td width="33%">Position</td>
            </tr>
          </thead>
          <tbody>
            <tr *ngIf="!users.length" class="nothing-yet">
              <td colspan="3"><em>There is no data yet</em></td>
            </tr>
            <tr *ngFor="let user of users" (click)="goToUser(user.id)">
              <td>{{user.name_or_email}}</td>
              <td>{{user.role}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div *ngIf="users.length" class="row">
    <div class="col-sm-12">
      <app-admin-timing-pagination
        [isPending]="isPending"
        [currentPage]="currentUsersPage"
        [totalPages]="totalUsersPages"
        (onNewPageSelected)="onUsersPageChanged($event)">
      </app-admin-timing-pagination>
    </div>
  </div>
</div>
