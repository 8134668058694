<span class="add-toDo add-storyBtn" *ngIf="projectColumn.canCreate && project && !project.dashboard_project_id && policyService.expertAsQA(project)" (click)="addNewtask()">+ Add Story</span>
<div class="column_header" [attr.data-column-name]="projectColumn.columnIdentifier">
  <!-- <span class="toggle-title">{{((projectColumn.list_name != 'Search') ? projectColumn.list_name : searchQuery)}}
  </span> -->
  <span class="story-header-right selectWithinCol" *ngIf="columnData.length > 0">
    <label class="customCheckForStorySelection" *ngIf="!this.policyService.isProjectReadonly(project)" >
      <input type="checkbox" (change)="toggleStorySelection($event)" [disabled]="checkColSelected(projectColumn.list_name)" [checked]="projectColumn.allSelected"
             class="story-selected-checkbox" hidden/>
      <span class="check-toggle"></span>
      <span class="elementHover-checkbox">{{projectService.archived_story_selected ? 'These stories cannot be selected as you have selected archived stories.' : 'These stories cannot be selected as you  have selected unarchived stories.'}}</span>
    </label>
    
    <label class="customCheckForStorySelection" *ngIf="this.policyService.isProjectReadonly(project)" (click)="this.policyService.readOnlyWarning()">
      <span class="check-toggle"></span>
    </label>
    <span class="elementHover" *ngIf="!checkColSelected(projectColumn.list_name)">Only loaded stories will be selected<br/> except for Bulk Archive.</span>
  </span>
  <!--<span class="add-toDo" *ngIf="(projectColumn.selectedStoryCount == 0) && projectColumn.canCreate" (click)="addNewStory(project)">+ Add Story</span>-->

  <div class="columnAction" *ngIf="projectColumn.canCreate && projectColumn.list_type == 'custom'">
    <em class="iconb-more columnActionIcon" (click)="openColumnActionDD()"></em>
    <div class="columnName-actionDD" [@fadeInOut] *ngIf="columnActionToggle">
      <div class="actionList" (click)="openEditColumnName()"><em class="iconb-edit"></em> <span class="actionList-title">Rename</span></div>
      <div class="actionList" (click)="deleteColumn()"><em class="iconb-bin"></em> <span class="actionList-title">Delete</span></div>
    </div>
  </div>
</div>
<div class="coloumnLoader" *ngIf="(projectService.showListLoader && (projectColumn?.list_name === projectService.story_col_name)) || (projectService.forDragDropLoader && ((projectColumn?.list_name === 'In Progress') || (projectColumn?.list_name === 'To Do')))">
  <img src="../../assets/images/storyBoardLoader.gif" alt=""/>
</div>
<div #storycolumn class="storycolumn"
[id]="projectColumn.columnIdentifier"
     infiniteScroll
     [scrollWindow]="false"
     [infiniteScrollDisabled]="!isScrollFetchAvailable()"
     (scrolled)="getMoreStories()">

  <div class="newStory-wrapper" *ngIf = "editColumnName && !projectColumn.expandable">
    <textarea class="newStory-title" [(ngModel)] = "columnName" placeholder="List name... " maxlength="120"></textarea>
    <div class="newStory-actions">
      <button type="button" [disabled] = "(!columnName.trim() )|| (columnName.trim() == projectColumn?.list_name) || (disbaleRenameList)" (click) = "renameColumnName()" class="addStory">Update</button>
      <button type="button" (click) = "canceleditColumn()" class="newStoryForm-close">Cancel</button>
    </div>
  </div>

  <div class="newStory-wrapper" *ngIf="createNewTask && !projectColumn.expandable">
    <textarea #focusNewStoryTitle autosize class="newStory-title" placeholder="Enter a title for this story... " maxlength="200"
              [disabled]="isCreateNewStoryDisable" (keyup.enter)="!isCreateNewStoryDisable && addNewStory()" [(ngModel)]="newStory_title"></textarea>
    <div class="newStory-actions">
      <button type="button" class="addStory" [disabled]="isCreateNewStoryDisable || newStory_title.length == 0" (click)="!isCreateNewStoryDisable && addNewStory()">{{projectService.createBug === 'bug' || policyService.isQAExpert(project) ? 'Add Bug' : 'Add Story'}}</button>
      <button type="button" class="newStoryForm-close" (click)="closeAddNewtask()">Cancel</button>
    </div>
  </div>

    <ng-container  *ngFor="let item of columnData; index as i;">
      <project-story class="projectStory" [@storyLoad]
        [ngClass]="['column-' + projectColumn.list_code]"
        [attr.storyid]="['story-' + item?.id]"
        [attr.column]="[projectColumn.name]"
        (applyFilterLabel) = "applyFilterLabel($event)"
        id="{{item?.id}}"
        project-story
        [story]="item"
        [project]="project"
        [column]="projectColumn">
      </project-story>
    </ng-container>
    <div class="storyLoader" *ngIf="isStoryLoading">
      <img src="../../../../assets/images/dot-loader.gif" alt="loader" />
    </div>
    <div *ngIf="((!searchStory && columnData && columnData.length === 0) || (searchStory && showHtml && columnData && columnData.length === 0 && epics.length == 0)) && is_search_view_open"
      class="not-found">No results found
    </div>

  <div>
    <project-epic [@storyLoad] class="projectEpic" [showEpicArrow]="false" [epic]="epic" [project]="project" *ngFor="let epic of epics">
    </project-epic>
  </div>
</div>

<app-custom-popup
  [texts]="{ptitle: 'Confirm', ptext: 'Are you sure you want to delete ' + projectColumn?.list_name + ' ? All stories in this list will move to the default position.', psuccess: 'Confirm', pcancel: 'Cancel'}"
  (closeCustomPopUp)="closedeleteColumn()" (deleteElement)="stateActionChanged()"
  [OpenPopUp]="OpencolumnNamePopup" [waitForLoader]="waitForLoader"></app-custom-popup>
