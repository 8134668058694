<div class="projectBrief-DD" *ngIf="project">
  <div class="common-Loader" *ngIf="briefLoader">
    <img src="../../assets/images/storyBoardLoader.gif" alt="loader"/>
  </div>
  <!-- <div class="topTitle">{{ briefHeading }}</div> -->
  <div class="topTitle"> Brief & Documents</div>
  <div class="contentWrapper">
    <div class="ifContentExit" *ngIf="project.project_brief || project.project_brief_attachments?.length > 0">
      <ng-container *ngIf="project.project_brief">
        <!--<span class="breifTitle">'Project Brief'</span>-->
        <span class="breifContent" *ngIf="project.project_brief" [innerHtml]="domSanitizer.bypassSecurityTrustHtml(project.project_brief)"></span>
      </ng-container>
      <span class="breifTitle2" *ngIf="project.project_brief_attachments?.length > 0">Attachments ({{project.project_brief_attachments.length}})</span>
      <div class="breifAttachment-wrapper" *ngIf="project.project_brief_attachments?.length > 0">
        <ngx-slick-carousel class="carousel"
                            #slickModal="slick-carousel"
                            [config]="slideConfig">
          <div ngxSlickItem *ngFor="let attachment of project.project_brief_attachments" class="slide breifAttachment-list">
            <div class="assetsContainer">
              <div class="att-type">{{sharedService.checkType(attachment?.url, true) == 'image' ? 'Asset' : 'Document'}}</div>
              <span class="asst-container" (click)="openAuthorPopup(attachment?.url)">
                <img *ngIf="sharedService.checkType(attachment?.url, true) == 'image'" src="{{ attachment?.url }}" alt="loader"/>
                <a *ngIf = "sharedService.checkType(attachment?.url, true) == 'doc' || sharedService.checkType(attachment?.url, true) == ''" class="gotoImage" href="{{(attachment?.url)}}" target="_blank" rel="noopener">
                    <img class="default-attachment" src="/assets/images/defaullt-download.png" alt="loader"/>
                </a>
                <div *ngIf = "sharedService.checkType(attachment?.url, true) == 'video'" class="videoIcon" (click)="openVideoPopup(attachment)">
                    <div class="att-type">Video</div>
                    <em class="iconb-play-circle"></em>
                </div>
            </span>
            </div>
            <div class="assetsInfo">
              <span class="assetsName">{{attachment.name}}</span>
              <a  href="{{ attachment.url }}" class="gotoImage" target="_blank" rel="noopener"> <em class="iconb-download"></em></a>
            </div>
          </div>
        </ngx-slick-carousel>
      </div>
      <button type="button" class="showBrandingAssets" *ngIf="project.branding_id" (click)="showBrandGuidline()">Show Branding Assets</button>
    </div>
    <div class="ifNoContent" *ngIf="!project.project_brief && project.project_brief_attachments?.length == 0">
      <span class="breifTitle" *ngIf="isProjectAdmin">Project Brief is not updated <a [href]="project.landing_url" target="_blank" rel="noopener" class="linkto360">Update Now</a> </span>
      <button type="button" class="showBrandingAssets" [ngClass]="{'removeMargin': !isProjectAdmin}" *ngIf="project.branding_id" (click)="showBrandGuidline()">Show Branding Assets</button>
    </div>
    <div class="projectDocument-wrapper">


      <div class="document-block">
        <div class="document-blockTop">
          <div class="docTitle">
            <span class="d-title">Documents</span>
          </div>
          <div class="folderNameInfo-block">
            <div class="folderNameInfo-title">{{selectedFolderName}}  </div>
          </div>
        </div>
        <!--For Developers:- if no Folder is Existed: starts-->
        <div class="ifNoFile" *ngIf="projectDocuments && projectDocuments.length === 0">
          <em class="iconb-folder nofileIcon"></em>
          <span class="noFileText" >No Folder available</span>
        </div>
        <!--For Developers:- if no Folder is Existed: Ends-->
      
      
        <div class="docInfo-section" *ngIf="projectDocuments && projectDocuments.length > 0">
          
          <div class="doc-leftSection">
            <div class="folderInfo-card" [ngClass]="{selected : pdoc.folder_files === projectDocumentFile}" (click)="selectFolder(pdoc)" *ngFor="let pdoc of projectDocuments">
              <div class="folderName">{{pdoc.name}} <span class="folderType" [ngStyle]="{'background-color' : (pdoc.visibility_type === 'external') ? '#0091EA' : '#FF6D00'}">{{pdoc.visibility_type}}</span></div>
              <div class="folderCreatedOn">Created on: <span class="title">{{formatDate(pdoc.created_at)}}</span> </div>
              <div class="folderCreatedBy">Created by: <span class="title">{{pdoc.owner.name}}</span></div>
            </div>
          </div>
          <div class="doc-rightSection">
            
      
            <!--For Developers:- if no file is existed: starts-->
            <div class="ifNoFile" *ngIf="projectDocumentFile && projectDocumentFile.length === 0">
              <!-- <em class="icon-file-text nofileIcon"></em> -->
              <span class="noFileText">No file in this folder</span>
            </div>
            <!--For Developers:- if no file is Existed: Ends-->
      
            <div class="fileListing-block" *ngIf="projectDocumentFile && projectDocumentFile.length > 0">
              <div class="fileListing-head">
                <div class="fileName">Files</div>
                <div class="fileCreatedAt">Date</div>
                <div class="fileAction">Action</div>
              </div>
              <div class="fileListing-list" *ngFor="let folderFiles of projectDocumentFile">
                <div class="fileName">{{folderFiles.data_identifier}}</div>
                <div class="fileCreatedAt">{{formatDate(folderFiles.created_at)}}</div>
                <div class="fileAction">
                  <em class="iconb-download" (click)="openDocument(folderFiles.data_url)"></em>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="overlay-board" *ngIf = "project && openBrandGuidlines" (click)="closeBrandGuidline()"></div>
<div class="brandingGuildlines" [ngClass]="{'openBrandingGuidlines': openBrandGuidlines}" *ngIf="project">
  <div class="branding-heading filterPanelHeader">
    <em class="iconb-close" (click)="closeBrandGuidline()"></em>
    <div class="topTitle">Brand Guidelines</div>
  </div>
  <div class="filterPanelContent branding-content">
    <div class="common-guide-box brand-logo-box"
         *ngIf="project.branding_logo_attachments?.files?.length > 0 || project.branding_logo_attachments?.reference?.length > 0">
      <div class="common-guide-head">Brand logo</div>
      <div class="common-guide-item" *ngIf="project.branding_logo_attachments?.files?.length > 0">
        <ul class="logo-item">
          <li *ngFor='let icons of project.branding_logo_attachments?.files'>
            <div class="guide-logo">
              <span (click)="openAuthorPopup(icons?.file_url)">
                <!-- <img *ngIf="sharedService.checkType(icons?.file_url, true) == 'image'" src="{{icons?.file_url}}" alt="" title="" />
                <img *ngIf="sharedService.checkType(icons?.file_url, true) == 'image'" src="assets/images/screenshot-upload.jpg" alt="" title=""/> -->
                <img *ngIf="sharedService.checkType(icons?.file_url, true) == 'image'" src="{{ icons?.file_url }}" alt="loader"/>
                <a *ngIf = "sharedService.checkType(icons?.file_url, true) == 'doc' || sharedService.checkType(icons?.file_url, true) == ''" class="gotoImage" href="{{(icons?.file_url)}}" target="_blank" rel="noopener">
                  <img class="default-attachment" src="/assets/images/defaullt-download.png" alt="loader"/>
                </a>
                <div *ngIf = "sharedService.checkType(icons?.file_url, true) == 'video'" class="videoContainer" (click)="openVideoPopup(icons)">
                  <div class="att-type">Video</div>
                  <em class="iconb-play-circle"></em>
               </div>
              </span>
            </div>
            <div class="info-logo-box">
              <div class="tag-box">{{icons?.name}}</div>
              <button class="btn guideline-btn">
                <a [href]="icons?.file_url" target="_blank" rel="noopener" class="linkto360">Download <em class="iconb-download"></em></a>
              </button>
            </div>
          </li>
        </ul>
      </div>
      <div class="common-guide-item" *ngIf='project.branding_logo_attachments.reference?.length > 0'>
        <div class="common-desc-view">
          <p>{{project.branding_logo_attachments.reference}}</p>
        </div>
      </div>
    </div>
    <div class="common-guide-box font-family-box" *ngIf="project.branding_font_attachments?.custom_attribute?.length > 0">
      <div class="common-guide-head">Font family</div>
      <div class="common-guide-item">
        <div class="font-names">{{project.branding_font_attachments.custom_attribute?.join(', ')}}</div>
      </div>
      <div class="common-guide-item" *ngIf="project.branding_font_attachments?.files?.length > 0">
        <ul class="font-item">
          <li *ngFor='let fontName of project.branding_font_attachments?.files'>
            <div class="tag-box"  title="{{fontName?.name}}">
              <a [href]="fontName?.file_url" target="_blank" rel="noopener" class="linkto360">{{ fontName.name }}</a>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="common-guide-box color-box"
         *ngIf="project.branding_colour_attachments?.custom_attribute?.length > 0 || project.branding_colour_attachments?.reference?.length > 0">
      <div class="common-guide-head">Color</div>
      <div class="common-guide-item" *ngIf="project.branding_colour_attachments.custom_attribute?.length > 0">
        <ul class="color-items">
          <li *ngFor='let colour of project.branding_colour_attachments.custom_attribute'>
            <div class="color-circle" [ngStyle]='{"background" : colour }'></div>
            <div class="color-code">{{colour}}</div>
          </li>
        </ul>
      </div>
      <div class="common-guide-item m10" *ngIf='project.branding_colour_attachments?.reference'>
        <div class="common-desc-view">
          <p>{{project.branding_colour_attachments.reference}}</p>
        </div>
      </div>
    </div>
    <div class="common-guide-box icon-box"
         *ngIf="project.branding_icon_attachments?.custom_attribute?.length > 0 || project.branding_icon_attachments?.reference?.length > 0">
      <div class="common-guide-head">Icon style</div>
      <div class="common-guide-item" *ngIf="project.branding_icon_attachments?.custom_attribute?.length > 0">
        <ul class="color-items icon-items">
          <li>
            <!-- <img *ngIf="sharedService.checkType(project?.branding_icon_attachments?.custom_attribute, true) == 'image'" src="{{project.branding_icon_attachments.custom_attribute}}" alt="" title="" />
            <img *ngIf="sharedService.checkType(project?.branding_icon_attachments?.custom_attribute, true) != 'image'" src="assets/images/screenshot-upload.jpg" alt="" title=""/> -->
            <span (click)="openAuthorPopup(project?.branding_icon_attachments?.custom_attribute)">
                <img *ngIf="sharedService.checkType(project?.branding_icon_attachments?.custom_attribute, true) == 'image'" src="{{ project?.branding_icon_attachments?.custom_attribute }}" alt="loader"/>
                <a *ngIf = "sharedService.checkType(project?.branding_icon_attachments?.custom_attribute, true) == 'doc' || sharedService.checkType(project?.branding_icon_attachments?.custom_attribute, true) == ''" class="gotoImage" href="{{(project?.branding_icon_attachments?.custom_attribute)}}" rel="noopener" target="_blank">
                  <img class="default-attachment" src="/assets/images/defaullt-download.png" alt="loader"/>
                </a>
                <div *ngIf = "sharedService.checkType(project?.branding_icon_attachments?.custom_attribute, true) == 'video'" class="videoContainer" (click)="openVideoPopup(project?.branding_icon_attachments?.custom_attribute, true)">
                  <div class="att-type">Video</div>
                  <em class="iconb-play-circle"></em>
               </div>
              </span>
          </li>
        </ul>
      </div>
      <div class="common-guide-item m0" *ngIf='project.branding_icon_attachments?.reference?.length > 0'>
        <div class="common-desc-view">
          <p>{{project.branding_icon_attachments.reference}}</p>
        </div>
      </div>
    </div>
    <div class="common-guide-box illustration-box" *ngIf='project.branding_illustration_attachments?.reference'>
      <div class="common-guide-head">Illustration style</div>
      <div class="common-guide-item">
        <div class="common-desc-view">
          <p>{{project.branding_illustration_attachments.reference}}</p>
        </div>
      </div>
    </div>
    <div class="common-guide-box other-doc-box"
         *ngIf="project.branding_other_attachments?.files?.length > 0 || project.branding_other_attachments?.reference?.length > 0">
      <div class="common-guide-head">Other docs / Info</div>
      <div class="common-guide-item" *ngIf="project.branding_other_attachments?.files?.length > 0">
        <ul class="font-item">
          <li *ngFor='let file of project.branding_other_attachments.files'>
            <div class="tag-box" title="{{file?.name}}"><a [href]="file?.file_url" target="_blank" rel="noopener" class="linkto360">{{ file?.name }}</a></div>
          </li>
        </ul>
      </div>
      <div class="common-guide-item m10" *ngIf='project.branding_other_attachments?.reference?.length > 0'>
        <div class="common-desc-view">
          <p>{{project.branding_other_attachments.reference}}</p>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="videoPopup-wrapper" *ngIf="showVideo">
    <div class="videoPopup-backdrop"></div>
    <em class="iconb-close closeVideoPopup" (click)="closeVideoPopup()"></em>
    <div class="videoPopup">
      <video width="640px" controls autoplay>
        <source src="{{vURL}}" type='video/mp4' />
      </video>
    </div>
  </div>

<div *ngIf="openAuthor" class="authorBioWrapper" [ngClass]="{'openAuthorBio' : openAuthor}">
    <div class="backdropClose" (click)="closeAuthorPopup()"></div>
    <em class="closeFun iconb-close" (click)="closeAuthorPopup()">close</em>
    <div class="authorContent">
      <img src="{{openItemDoc}}" alt="loader"/>
    </div>
  </div>
