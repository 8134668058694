<div class="comparisonPopup">
  <div class="backdrop" (click)="closeStoryCompare()"></div>
  <div class="storyApprovalPanel-Loader" *ngIf="comparePopupStartLoader">
    <img src="../../assets/images/storyBoardLoader.gif" alt="" />
  </div>
  <div class="comparisonBlock" *ngIf="storyCompare">
    <span class="iconb-close closeComparePopup" (click)="closeStoryCompare()"></span>
    <div class="topBlock">
      <div class="leftSection">
        <span class="featureName">{{storyCompare?.epic?.title}}</span>
        <ng-container *ngIf="has_story_data">
          <div class="actionWrapper syncButton">
            <div class="actionButton" (click)="syncActionConfirmation = true">Sync</div>
          </div>
        </ng-container>
        <div class="status-dropdown-wrapper" [ngClass]="{'expanded': statusDropdown}" *ngIf="!has_story_data">
          <div [ngClass]="['status-trigger',(storyCompare?.request_type === 'request_to_promote') && (storyCompare?.status === 'accepted') ? storyCompare?.designer_status : storyCompare?.status]" 
               (click)="statusDropdownToggle(storyCompare?.status,storyCompare?.designer_status)">{{(storyCompare?.request_type === 'request_to_promote') && (storyCompare?.status === 'accepted') ? storyCompare?.designer_status : storyCompare?.status}} <em *ngIf="storyCompare?.status === 'waiting' || (storyCompare?.request_type === 'request_to_promote' && storyCompare?.status === 'accepted' && storyCompare?.designer_status === 'waiting')" class="iconb-dd-down"></em></div>
          <div class="status-dropdown" [@fadeInOut] *ngIf="statusDropdown && (storyCompare?.status === 'waiting' || (storyCompare?.request_type === 'request_to_promote' && storyCompare?.status === 'accepted' && storyCompare?.designer_status === 'waiting'))">
            <div class="status" (click)="reviewAction(storyCompare, 'accepted')">Accepted</div>
            <div class="status is-rejected" (click)="reviewAction(storyCompare, 'rejected')">Rejected</div>
          </div>
        </div>
        <div class="feedback-status">
          <div class="status is-blue" *ngIf="storyCompare?.status === 'waiting'"><em class="iconb-spinner"></em>Userstory Review</div>
          <div class="status" *ngIf="storyCompare?.status === 'accepted'"><em class="iconb-done"></em>Userstory Approved</div>
          <div class="status is-red" *ngIf="storyCompare?.status === 'rejected'"><em class="iconb-close"></em>Userstory Rejected</div>
          <div class="status is-blue"*ngIf="storyCompare?.request_type === 'request_to_promote' && storyCompare?.status === 'accepted' && storyCompare?.designer_status === 'waiting'"><em class="iconb-spinner"></em>Design Review</div>
          <div class="status" *ngIf="storyCompare?.request_type === 'request_to_promote' && storyCompare?.status === 'accepted' && storyCompare?.designer_status === 'accepted'"><em class="iconb-done"></em>Design Approved</div>
          <div class="status is-red" *ngIf="storyCompare?.request_type === 'request_to_promote' && storyCompare?.status === 'accepted' && storyCompare?.designer_status === 'rejected'"><em class="iconb-close"></em>Design Rejected</div>
          
        </div>
      </div>
      
      <div class="rightSection">
        <ng-container *ngIf="!has_story_data">
          <div class="requestor-info">
            <span class="requesterName">{{storyCompare?.requester?.initials ? storyCompare?.requester?.initials.slice(0,1) :
              storyCompare.requester?.name.slice(0,1)}}</span>
            <div class="info">
              <span class="info-label">Requestor</span>
              <span class="fullname">{{storyCompare.requester?.name}}</span>
            </div>
            <div class="elementHover">
              <div class="tooltip-wrapper">
                <span class="requesterName">{{storyCompare?.requester?.initials ? storyCompare?.requester?.initials.slice(0,1) :
                  storyCompare.requester?.name.slice(0,1)}}</span>
                <div class="tooltip-content">
                  <span class="name">{{storyCompare.requester?.name}}</span>
                  <span class="email">{{storyCompare.requester?.email}} 
                    <span class="iconb-copyid" (click)="copyStoryID()" [clip]="storyCompare?.requester?.email"></span>
                    <span>{{showCopiedID ? ' (copied)' : ''}}</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="requestor-info">
            <em class="iconb-calendar"></em>
            <div class="info">
              <span class="info-label">Requested on</span>
              <span class="fullname">{{sharedService.timeFormat(storyCompare?.created_at)}}</span>
            </div>
          </div>
        </ng-container>
        
      </div>
    </div>
    <div class="contentSection" [ngClass]="{'add_pad_bottom' : (storyCompare?.status === 'accepted'), 'flip':has_story_data, 'device-preview': devicePreviewVisible }">
      <div class="back-to-review" *ngIf="devicePreviewVisible">
        <span class="inner-link" (click)="hideDevicePreview()"><em class="iconb-left-2"></em>Back to Review</span>
        <div class="devices-list">
          <div class="device-link active"><em class="iconb-mobile-new"></em></div>
          <div class="device-link"><em class="iconb-desktop"></em></div>
        </div>
      </div>
      <div class="previousContent">
        <div class="normalTitle" *ngIf="!devicePreviewVisible">
          <span class="name">{{has_story_data ? 'New' : 'Previous'}} Content</span>
          <span class="add-removal-tag" *ngIf="text_removed > 0"><em class="iconb-remove-circle"></em>{{has_story_data ? 'Addition' : 'Removal'}}<em class="count">{{text_removed}}</em></span>
        </div>

        <!-- device preview title -->
        <div class="normalTitle secondary" *ngIf="devicePreviewVisible">
          <span class="name">Previous Design Preview</span>
          <span class="download-link"><em class="iconb-download-new"></em>Download</span>
        </div>

        <div class="common-Loader story-compare-Loader" *ngIf="!cmtStory">
          <img src="../../assets/images/storyBoardLoader.gif" alt="" />
        </div>

        <div class="design-preview-wrapper" *ngIf="devicePreviewVisible">
          <div class="preview-outer">
            <div class="zooming-btns">
              <div class="zoom-btn"><em class="iconb-plus"></em></div>
              <div class="zoom-btn"><em class="iconb-minus"></em></div>
            </div>
            <div class="preview-img">
              <img src="../../../../assets/images/mob-screen-full.png" alt="">
            </div>
            <div class="img-name">Mobilescreen.png</div>
          </div>
        </div>

        <ng-container *ngIf="cmtStory && !devicePreviewVisible">
          <div class="sectionBlock">
            <div class="sectionLabel">Title</div>
            <div class="storyTitle" [innerHtml]="cmtStory.title"></div>
          </div>

          <!-- <div class="sectionBlock">
            <div class="sectionLabel">DLS Design File</div>
            <span class="compare-design-link" (click)="showDevicePreview()">Compare Design</span>
            <div class="dls-wrapper">
              <div class="dls-box">
                <div class="dls-img-box">
                  <img src="storyCompare?.figma_image_url" alt="">
                  <div class="action-wrapper">
                    <div class="action-trigger">
                        <em class="iconb-download-new"><a [href]="storyCompare?.figma_image_url" target="_blank" rel="noopener" ></a></em>
                    </div>
                  </div>
                </div>
                <div class="img-title">Mobilescreen.png</div>
              </div>
              <div class="dls-box">
                <div class="dls-img-box">
                  <img  src="storyCompare?.figma_image_url"  alt="">
                  <div class="action-wrapper">
                    <div class="action-trigger" (click)="deviceActions()">
                      <em class="iconb-more-vert"></em>
                    </div>
                    <div class="action-dropdown" [ngClass]="{'open': deviceActionsVisible}">
                      <div class="action-link"><em class="iconb-download-new"></em>Download image</div>
                      <div class="action-link purple-bg"><em class="iconb-hyperlink"></em>Visit Figma file</div>
                    </div>
                  </div>
                </div>
                <div class="img-title">Desktopscreen.png</div>
              </div>
            </div>
          </div> -->

          <div class="contentWrapper sectionBlock" *ngIf="!storyCompare.parent_id && !currentUserDesignSquad">
            <div class="sectionLabel">Description</div>
            <div class="description-format-wrapper">
              <div class="format-type-list">
                <div class="format-type-title">As a</div>
                <div class="format-type-description">
                  <div class="format-inout if-no-as-a" [innerHtml]="cmtStory.as_a"></div>
                </div>
              </div>
              <div class="format-type-list">
                <div class="format-type-title">I want</div>
                <div class="format-type-description">
                  <div class="format-inout if-no-i-want" [innerHtml]="cmtStory.i_want"></div>
                </div>
              </div>
              <div class="format-type-list">
                <div class="format-type-title">So that</div>
                <div class="format-type-description">
                  <div class="format-inout if-no-so-that" [innerHtml]="cmtStory.so_that"></div>
                </div>
              </div>
            </div>
          </div>
          
          <div class="sectionBlock" *ngIf="!currentUserDesignSquad">
            <div class="sectionLabel">Task Description</div>
            <div class="storyDescription" [innerHtml]="cmtStory.description"></div>
          </div>
          <div class="contentWrapper sectionBlock" *ngIf="cmtStory.acceptance_criteria.length > 0 && !currentUserDesignSquad">
            <div class="sectionLabel">Acceptance Criteria</div>
            <div class="criteria-list-wrapper">
              <div class="criteria-list" *ngFor="let criteria of cmtStory.acceptance_criteria">
                <div class="criteria-title">
                  <div class="criteria-title-input" [innerHtml]="criteria.title"></div>
                </div>
                <div class="description-format-wrapper">
                  <div class="format-type-list">
                    <div class="format-type-title">Given</div>
                    <div class="format-type-description">
                      <div class="format-inout if-no-given" [innerHtml]="criteria.given"></div>
                    </div>
                  </div>
                  <div class="format-type-list">
                    <div class="format-type-title">When</div>
                    <div class="format-type-description">
                      <div class="format-inout if-no-when" [innerHtml]="criteria.when"></div>
                    </div>
                  </div>
                  <div class="format-type-list">
                    <div class="format-type-title">Then</div>
                    <div class="format-type-description">
                      <div class="format-inout if-no-then" [innerHtml]="criteria.then"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="sectionBlock" *ngIf="!currentUserDesignSquad">
            <div class="sectionLabel">Estimates</div>
            <div class="storyEstimates">
              <span class="iconb-timer"></span>
              <span class="storyEstimate">{{cmtStory.estimate_hrs}}{{(cmtStory.estimate_hrs > 1) ? 'hrs':'hr'}}</span>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="newContent">
        <div class="normalTitle" *ngIf="!devicePreviewVisible">
          <span class="name">{{has_story_data ? 'Previous' : 'New'}} Content</span>
          <span class="add-removal-tag is-addition" *ngIf="text_added > 0"><em class="iconb-add-circle"></em>{{has_story_data ? 'Removal' : 'Addition'}}<em class="count">{{text_added}}</em></span>
        </div>

        <!-- device preview title -->
        <!-- <div class="normalTitle secondary" *ngIf="devicePreviewVisible">
          <span class="name">Updated Design Preview</span>
          <span class="download-link"><em class="iconb-download-new"></em>Download</span>
        </div> -->

        <!-- <div class="common-Loader story-compare-Loader" *ngIf="!storyCompare">
          <img src="storyCompare?.figma_image_url" alt="" />
        </div> -->

        <!-- <div class="design-preview-wrapper" *ngIf="devicePreviewVisible">
          <div class="preview-outer">
            <div class="zooming-btns">
              <div class="zoom-btn"><em class="iconb-plus"></em></div>
              <div class="zoom-btn"><em class="iconb-minus"></em></div>
            </div>
            <div class="preview-img">
              <img src="storyCompare?.figma_image_url" alt="">
            </div>
            <div class="img-name">Mobilescreen.png</div>
          </div>
        </div> -->

        <ng-container *ngIf="storyCompare && !devicePreviewVisible">
          <div class="sectionBlock">
            <div class="sectionLabel">Title</div>
            <div class="storyTitle" [innerHtml]="storyCompare.title"></div>
          </div>

          <!-- <div class="sectionBlock">
            <div class="sectionLabel">Updated Design File</div>
            <span class="compare-design-link" (click)="showDevicePreview()">Compare Design</span>
            <div class="dls-wrapper">
              <div class="dls-box">
                <div class="dls-img-box">
                  <img src="../../../../assets/images/mob-screen.png" alt="">
                  <div class="action-wrapper">
                    <div class="action-trigger">
                      <em class="iconb-download-new"></em>
                    </div>
                  </div>
                </div>
                <div class="img-title">Mobilescreen.png</div>
              </div>
              <div class="dls-box">
                <div class="dls-img-box">
                  <img src="../../../../assets/images/desktop-screen.png" alt="">
                  <div class="action-wrapper">
                    <div class="action-trigger" (click)="deviceActions()">
                      <em class="iconb-more-vert"></em>
                    </div>
                    <div class="action-dropdown" [ngClass]="{'open': deviceActionsVisible}">
                      <div class="action-link"><em class="iconb-download-new"></em>Download image</div>
                      <div class="action-link purple-bg"><em class="iconb-hyperlink"></em>Visit Figma file</div>
                    </div>
                  </div>
                </div>
                <div class="img-title">Desktopscreen.png</div>
              </div>
            </div>
          </div> -->

          <div class="contentWrapper sectionBlock" *ngIf="!storyCompare.parent_id && !currentUserDesignSquad">
            <div class="sectionLabel">Description</div>
            <div class="description-format-wrapper">
              <div class="format-type-list">
                <div class="format-type-title">As a</div>
                <div class="format-type-description">
                  <div class="format-inout if-no-as-a" [innerHtml]="storyCompare.description_as_a"></div>
                </div>
              </div>
              <div class="format-type-list">
                <div class="format-type-title">I want</div>
                <div class="format-type-description">
                  <div class="format-inout if-no-i-want" [innerHtml]="storyCompare.description_i_want"></div>
                </div>
              </div>
              <div class="format-type-list">
                <div class="format-type-title">So that</div>
                <div class="format-type-description">
                  <div class="format-inout if-no-so-that" [innerHtml]="storyCompare.description_so_that"></div>
                </div>
              </div>
            </div>
          </div>

          <div class="sectionBlock" *ngIf="!currentUserDesignSquad">
            <div class="sectionLabel">Task Description</div>
            <div class="storyDescription" [innerHtml]="storyCompare.description">
              <!-- <ckeditor [(ngModel)]="storyCompare.description" [editor]="viewDescriptionEditor"></ckeditor> -->
            </div>
          </div>

          <div class="contentWrapper sectionBlock" *ngIf="storyCompare.acceptance_criteria_approvals?.length && !storyCompare.parent_id && !currentUserDesignSquad">
            <div class="sectionLabel">Acceptance Criteria</div>
            <div class="criteria-list-wrapper">
              <div class="criteria-list" *ngFor="let criteria of storyCompare.acceptance_criteria_approvals">
                <div class="criteria-title">
                  <div class="criteria-title-input" [innerHtml]="criteria.title"></div>
                </div>
                <div class="description-format-wrapper">
                  <div class="format-type-list">
                    <div class="format-type-title">Given</div>
                    <div class="format-type-description">
                      <div class="format-inout if-no-given" [innerHtml]="criteria.description_for_given"></div>
                    </div>
                  </div>
                  <div class="format-type-list">
                    <div class="format-type-title">When</div>
                    <div class="format-type-description">
                      <div class="format-inout if-no-when" [innerHtml]="criteria.description_for_when"></div>
                    </div>
                  </div>
                  <div class="format-type-list">
                    <div class="format-type-title">Then</div>
                    <div class="format-type-description">
                      <div class="format-inout if-no-then" [innerHtml]="criteria.description_for_then"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div class="sectionBlock" *ngIf="!currentUserDesignSquad">
            <div class="sectionLabel">Estimates</div>
            <div class="storyEstimates">
              <em class="iconb-timer"></em>
              <span class="storyEstimate">{{storyCompare.estimate}}{{(storyCompare.estimate > 1) ? 'hrs':'hr'}}</span>
            </div>
          </div>
        </ng-container>
      </div>

      <div class="ifActionDone" *ngIf="!has_story_data && storyCompare.status !== 'waiting'">
        <div class="ifActionDone-left">
          <div class="story-acception-rejection" *ngIf="storyCompare?.reviewer">
            <div class="story-status-outer" *ngIf="storyCompare?.status === 'accepted'">
              <em class="iconb-done icon"></em>
              <div class="info-outer">
                <div class="title">Story Accepted By</div>
                <div class="value">{{storyCompare?.reviewer?.name}}</div>
              </div>
            </div>
            <div class="story-status-outer" *ngIf="storyCompare?.status === 'rejected'">
              <em class="iconb-close icon is-red"></em>
              <div class="info-outer">
                <div class="title">Story Rejected By</div>
                <div class="value">{{storyCompare?.reviewer?.name}}</div>
              </div>
            </div>
          </div>
          <div class="story-acception-rejection" *ngIf="storyCompare?.designer">
            <div class="story-status-outer" *ngIf="storyCompare?.designer_status === 'accepted'">
              <em class="iconb-done icon"></em>
              <div class="info-outer">
                <div class="title">Design Accepted By</div>
                <div class="value">{{storyCompare?.designer?.name}}</div>
              </div>
            </div>
            <div class="story-status-outer" *ngIf="storyCompare?.designer_status === 'rejected'">
              <em class="iconb-close icon is-red"></em>
              <div class="info-outer">
                <div class="title">Design Rejected By</div>
                <div class="value">{{storyCompare?.designer?.name}}</div>
              </div>
            </div>
          </div>
          <!-- <div class="left-actions">
            <div class="requestor-info">
              <span class="requesterName">{{storyCompare?.reviewer?.initials ? storyCompare?.reviewer?.initials.slice(0,1) :
                storyCompare.reviewer?.name.slice(0,1)}}</span>
              <div class="info">
                <span class="info-label">Reviewer</span>
                <span class="fullname">{{storyCompare.reviewer?.name}}</span>
              </div>
            </div>
            <div class="requestor-info" *ngIf="storyCompare.reviewed_on">
              <em class="iconb-calendar"></em>
              <div class="info">
                <span class="info-label">Reviewed on</span>
                <span class="fullname">{{sharedService.timeFormat(storyCompare.reviewed_on)}}</span>
              </div>
            </div>
          </div> -->
        </div>
        <div class="right-btns" *ngIf="storyCompare?.status === 'rejected' || storyCompare?.designer_status === 'rejected'">
          <span class="border-btn" (click)="openRejectionPopup()">Reason for Rejection</span>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- Rejection reason popup -->
<div class="warningPopUp" *ngIf="openRejectionReasonPopup">
  <div class="warningPopUp-backdrop"></div>
  <div class="warningPopUp-wrapper storyUpdate-reason">
    <div class="warningPopUp-title">Rejection Feedback</div>
    <div class="bugreason-textarea">
      <label>Reason</label>
      <textarea placeholder="Please enter your reason for rejection" [(ngModel)]="reasonForRejection" [disabled]="(storyCompare?.status === 'rejected' || storyCompare?.designer_status === 'rejected')"></textarea>
    </div>
    <div class="logReason-actionBt">
      <button type="button" class="warningPopUp-submit" *ngIf="!(storyCompare?.status === 'rejected' || storyCompare?.designer_status === 'rejected')" (click)="storyReviewAction(storyCompare, action_on_story.requestIs,reasonForRejection)">Reject</button>
      <span class="warningPopUp-cancel" (click)="closeRejectionPopup()">Back</span>
    </div>
  </div>
</div>

<app-custom-popup 
*ngIf="reviewActionConfirmation" 
[texts] = "{ptitle: action_on_story.message.title, ptext: action_on_story.message.text, psuccess: 'Confirm', pcancel: 'Cancel'}" 
(closeCustomPopUp) = "reviewActionConfirmation = false" 
(deleteElement) = "storyReviewAction(storyCompare, action_on_story.requestIs)" 
[OpenPopUp] = "reviewActionConfirmation" 
[waitForLoader] = "waitForLoader"></app-custom-popup>

<app-custom-popup 
*ngIf="syncActionConfirmation" 
[texts] = "{ptitle: 'Update Story Data', ptext: 'You are trying to update this story to a updated CMT story, due to this you will override the data in your story. Are you sure you want to update story?', psuccess: 'Confirm', pcancel: 'Cancel'}" 
(closeCustomPopUp) = "syncActionConfirmation = false" 
(deleteElement) = "syncAction()" 
[OpenPopUp] = "syncActionConfirmation" 
[waitForLoader] = "waitForLoader"></app-custom-popup>