import { Pipe, PipeTransform } from '@angular/core';
import { Story } from '../models/story';
@Pipe({
    name: 'devOptions',
    pure: false
})

export class DevTypeOptionsPipe implements PipeTransform {
    transform(items: any[], story: Story): any[] {
        if (!items) return [];
        // if (!story || !story.parent_id) return items;
        return items.filter(it => {
            return it.type != 'E2E';
        });
    }
}
