import { Component, OnInit } from '@angular/core';
import { Notification } from '../shared/models/notification';
import {  ProjectService } from '../shared/services/project.service';
import { SocketsService } from '../shared/services/sockets.service';
import { UserService } from '../shared/services/user.service';
import { NotificationsService } from '../shared/services/notifications.service';
import { map, min, max } from 'lodash';
import moment from 'moment';
import {Router} from '@angular/router';
import { SharedService } from '../shared/services/shared.service';

@Component({
  selector: 'notifications-dropdown',
  templateUrl: 'notifications.component.html',
  styleUrls: ['notifications.component.scss'],
  host: {
    '(document:click)': 'onClick($event)',
  }
})
export class NotificationsComponent implements OnInit {
  unreadCount: number;
  totalCount: number;
  notifications: Array<Notification> = [];
  isPending: boolean = false;
  moreAvailable: boolean = true;
  openNotificationDD: boolean = false;
  doNothing: boolean = false;
  filterOption: boolean = false;
  filter_key: String = 'all';
  filter_value: String = 'All';
  objectKeys = Object.keys;
  private _subscription: any;

  constructor(private notificationsService: NotificationsService,
              public sharedService: SharedService,
              public projectService: ProjectService,
              private userService: UserService,
              private socketsService: SocketsService,
              private router: Router) {}

  ngOnInit() {
    this.getNotifications(this.filter_key);
    this.subscribeForUpdates();
  }

  openOptionFilter() {
    this.filterOption = true;
  }

  closeOptionFilter() {
    this.filterOption = false;
  }

  openNotiDD() {
    this.openNotificationDD = !this.openNotificationDD;
  }
  closeNotiDD() {
    this.openNotificationDD = false;
  }

  onClick(event) {
    const element = event.target;

    if (this.doNothing && !(element.classList.contains('story-name'))) {
      this.doNothing = false;
      return true;
    }

    if (!(element.classList.contains('notif-icon') ||
          element.classList.contains('glyphicon-bell'))) {
      this.openNotificationDD = false;
    }
  }

  getNotifications(key, value?) {
    this.isPending = true;

    if (value) {
      this.filterOption = false;
      this.notifications = [];
      this.filter_key = key;
      this.filter_value = value;
    }

    this.notificationsService.getNotifications({filter: this.filter_key}).subscribe(data => {
      this.moreAvailable = data.more_available;
      this.unreadCount = data.unread_count;
      this.totalCount = data.total_count;
      this.notifications = data.notifications;
      this.getChangeState();
      this.isPending = false;
    });
  }

  getChangeState() {
    this.notifications.forEach((notification) => {
      if(notification.subject && notification.subject.subject_changes) {
       notification.changes = this.activityToChanges(notification.subject);
      }
    })
  }

  getNewerNotifications() {
    const newestId = this.notifications.length > 0 ? max(map(this.notifications, 'id')) : 0;
    this.notificationsService.getNotifications({newer_than_id: newestId , filter: this.filter_key}).subscribe(data => {
      this.unreadCount = data.unread_count;
      this.totalCount = data.total_count;
      this.notifications = this.arrayUnique(data.notifications.concat(this.notifications));
      this.getChangeState();
    });
  }

  arrayUnique(array) {
    let a = array.concat();
    for(let i=0; i<a.length; ++i) {
        for(let j=i+1; j<a.length; ++j) {
            if(a[i] === a[j]){
              let spliceIndex = j - 1;
              a.splice(spliceIndex, 1);
            } 
        }
    }

    return a;
  }

  getOlderNotifications() {
    this.isPending = true;
    const oldestId = min(map(this.notifications, 'id'));
    this.notificationsService.getNotifications({older_than_id: oldestId, filter: this.filter_key}).subscribe(data => {
      this.moreAvailable = data.more_available;
      this.notifications = this.notifications.concat(data.notifications);
      this.getChangeState();
      this.isPending = false;
    });
  }

  isScrollFetchAvailable(): boolean {
    return !this.isPending && this.moreAvailable;
  }

  markAsRead(notification) {
    if (!notification.read_at) {
      this.notificationsService.markAsRead({notification_id: notification.id}).subscribe((data) => {
        this.unreadCount = data.unread_count;
        notification.read_at = moment;
        if (this.filter_key === 'unread') {
          const index = this.notifications.findIndex(noti => noti.id === notification.id);
          this.notifications.splice(index, 1);
          this.totalCount--;
        }
      });
    }
  }

  markAllAsRead() {
    this.notificationsService.markAsRead().subscribe((data) => {
      this.unreadCount = data.unread_count;
      this.notifications.forEach(noti => {
        if (!noti.read_at) {
          noti.read_at = moment;
        }
      });
      if (this.filter_key === 'unread' && this.unreadCount === 0) {
        this.totalCount = 0;
        this.notifications = [];
      }
    });
  }

  subscribeForUpdates() {
    const channelName = this.userService.getUser() ? `user-${this.userService.getUser().id}` : '';
    this.socketsService.centrifuge.subscribe(channelName, (eventContext) => {
      const event = eventContext.data;
      if (event.action == 'new-notification') {
        this.getNewerNotifications();
      }
    });

    this._subscription = this.socketsService.centrifuge.subscribe('tracker-maintenance', (eventContext) => {
      if (eventContext.data && eventContext.data.data) {
        this.userService.saveMaintenanceAndAction(eventContext.data.data);
      } else {
        const randomSeconds = this.randomIntFromInterval(8000, 80000);
        setTimeout(() => {
          this.userService.checkMaintenance();
        }, randomSeconds);
      }
    });
  }

  randomIntFromInterval(min, max) { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min)
  }

  shortDate(time: string): string {
    return moment(time).format('MMM D');
  }

  shortDateTime(time: string): string {
    return moment(time).format('MMM D, hh:mma');
  }

  storyPath(story) {
    return `/projects/${story.project_id}?storyId=${story.id}`;
  }

  goToStory(notification) {
    this.markAsRead(notification);
    if(this.projectService.currentProject.id != notification.story.project_id) {
      const URL = this.storyPath(notification.story);
      this.router.navigateByUrl(URL);
    } else {
       this.projectService.show_StoryDetails(this.projectService.currentProject, '', notification.story.id)
    }
  }

  epicPath(epic) {
    if(epic) {
      return `/#/projects/${epic.project_id}?epicId=${epic.id}`;
    }
  }

  activityToChanges(activity) {
    if(activity) {
      let a =  map(activity.subject_changes, (changes, field: string) => {
        const change = {
          field: this.fieldName(field),
          valueWas: changes[0],
          valueNow: changes[1],
          type: 'change'
        };

        if (!change.valueWas && change.valueNow) {
          change.type = 'set';
        }

        if (change.valueWas && !change.valueNow) {
          change.type = 'unset';
        }
        return change;
      });
      return a;
    }
  }

  fieldName(field: string): string {
    return field.replace(/_/g, ' ');
  }

  notificationIconClass(notification: Notification): string {
    const classes = {
      new_note: 'glyphicon-comment',
      mention: 'glyphicon-comment',
      story_assign: 'glyphicon-hand-right',
      story_change: 'glyphicon-edit',
      add_to_epic: 'glyphicon-th-list',
      remove_from_epic: 'glyphicon-th-list'
    };
    return `glyphicon ${classes[notification.kind]}`;
  }

  closeUserEditFormInAdmin() {
    this.sharedService.showUserEditFormInAdmin = false;
    this.sharedService.isGetUserSuccessForEditInAdmin = false;
  }

  onDestroy() {
    if (this._subscription) {
      this._subscription.unsubscribe();
      this._subscription = undefined;
    }
  }
}
