import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, ViewChildren, HostListener, Renderer2, QueryList } from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import moment from 'moment';
import { map, startCase } from 'lodash';
import {
  EVENT_TYPES,
  STORY_TYPE_OPTIONS,
  DEV_TYPE_OPTIONS,
  STORY_STATE_OPTIONS,
  STORY_SEVERITY_OPTIONS,
  DataManipulationOptions,
  STORY_TYPE, STORY_STATE, 
  STORY_PRIORITY_OPTIONS, 
  BB_STATUS, 
  UPDATE_STORY_REASONS, 
  UPDATE_STORY_DESCRIPTION_REASONS,
  READY_FOR_CERTIFICATION_FE_CHECKLIST,
  READY_FOR_CERTIFICATION_BE_CHECKLIST,
  TEST_STORY_STATE_OPTIONS,
  CriteriaData,
  READY_FOR_CERTIFICATION_SENSEI_CHECKLIST
} from '../../shared/dataTypes';

import {User } from '../../shared/models/user';
import {Note} from '../../shared/models/note';
import { Story } from '../../shared/models/story';
import { Epic } from '../../shared/models/epic';
import { Project } from '../../shared/models/project';
import { NoteService } from '../../shared/services/note.service';
import { QueryParamsService } from '../../shared/services/queryParams.service';
import { DocumentService } from '../../shared/services/document.service';
import { StoryService } from '../../shared/services/story.service';
import { EpicService } from '../../shared/services/epic.service';
import { UserService } from '../../shared/services/user.service';
import { ErrorService } from '../../shared/errorFunction';
import { NotificationService } from '../../shared/services/notification.service';
import { ProjectService } from '../../shared/services/project.service';
import {Sprint} from "../../shared/models/sprint";

import {pull} from 'lodash';
import * as _ from 'lodash';
import {CopiedComponent} from './copied.component';
// import {BsModalService} from 'ngx-bootstrap/modal';
import {CustomToastService} from '../../shared/services/custom-toast.service';
import {SharedService} from '../../shared/services/shared.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import * as DecoupledEditor from '../../../assets/js/ck-editor/ckeditor.js';
import {HttpClient} from '@angular/common/http';
import { DataService } from '../../shared/services/data.service';
import { UploadAdapter } from '../../shared/utils/ck-editor-upload.adapter';
import {FileHandle} from '../../shared/directives/dragdropfile.directive';
import { trigger, transition, style, animate } from '@angular/animations';
import {PolicyService} from "../../shared/services/policy.service";
import {SlickCarouselComponent} from 'ngx-slick-carousel';
import { ProjectSyncService } from '../../shared/services/projectSync.service';
import { Samefeaturephase } from '../../shared/models/samefeaturephase';
import { Subscription } from 'rxjs';
import { RelatedStory } from '../../shared/models/related_story';
import { CommonStoryService } from '../../shared/services/common-story.service';

@Component({
  selector: 'story-detail',
  templateUrl: 'storyDetail.component.html',
  styleUrls: ['storyDetails.component.scss'],
  animations: [
    trigger('tooltipAni', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({
          opacity: '0',
          transform: 'translateX(-50%)'
        }),
        animate(200, style({
          opacity: '1',
          transform: 'translateX(-50%)'
        }))
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate(200, style({
          opacity: '0',
          transform: 'translateX(-50%)'
        }))
      ])
    ]),
    trigger('DDpAni', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({
          top: '0',
          opacity: '0'
        }),
        animate(200, style({
          top: '47px',
          opacity: '1'
        }))
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate(200, style({
          top: '0',
          opacity: '0'
        }))
      ])
    ]),
    trigger('DDowner', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({
          top: '46px',
          opacity: '0'
        }),
        animate(200, style({
          top: '36px',
          opacity: '1'
        }))
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate(200, style({
          top: '46px',
          opacity: '0'
        }))
      ])
    ]),
    trigger('docUploader', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({
          bottom: '0',
          opacity: '0'
        }),
        animate(400, style({
          bottom: '54px',
        }))
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate(400, style({
          bottom: '0',
        }))
      ])
    ]),
    trigger('DDSort', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({
          top: '0',
          opacity: '0'
        }),
        animate(200, style({
          top: '25px',
        }))
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate(200, style({
          top: '0',
          opacity: '0'
        }))
      ])
    ]),
    trigger('DDExtra', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({
          top: '45px',
          opacity: '0'
        }),
        animate(200, style({
          opacity: '1',
          top: '35px',
        }))
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate(200, style({
          top: '45px',
          opacity: '0'
        }))
      ])
    ]),
    trigger('fadeInOut', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({
          opacity: '0',
          transform: 'translateY(-20px)'
        }),
        animate(100, style({
          opacity: '1',
          transform: 'translateY(0)'
        }))
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate(100, style({
          opacity: '0',
          transform: 'translateY(-20px)'
        }))
      ])
    ])
  ],
  host: {
    '(document:click)': 'onClick($event)',
  }
})

export class StoryDetailComponent implements OnInit {
  FE_certification_url = 'https://docs.google.com/document/d/1VHm1dOU1IXkti3xrGkMOeK2D-McRXPSgwgkhngoLf3k/edit#';
  BE_certification_url =  'https://docs.google.com/document/d/1FG9wAmvzROXRlEr5qv2XgIUddeI8PWJuCShWkyrz5lw/edit#heading=h.wi76pz9pjhzg';
  project: Project;
  story: Story;
  enterStoryProgress = false;
  storyProgress = {title: '', completedProgress: 0};
  progress: number;
  elem: any;
  unchangesOwnerStoryState = ['started', 'finished', 'delivered', 'accepted', 'rejected'];
  disable_story_board = false;
  disableStoryChatGPT = false
  mentionUsers = [];
  public sortedUsers = [];
  public commentNoteUploading: boolean = false;
  public searchUser = '';
  uploaderArr: Array<any> = [];
  attachOnlyArr: Array<any> = [];
  attachmentPreviewUrls: Array<any> = [];
  descriptionInEditMode: boolean = false;
  sprintSubscription:  Subscription;
  copied: any;
  showBlokerlist: Array<any> = [];
  blocker_count : number = 0;
  params: object = {};
  openAuthor: boolean = false;
  enableCreateExpResult:boolean = false;
  expectedResultText: string;
  openItemDoc: string = '';
  storyDescriptionLoader: boolean = false;
  OpencommonPopUp: boolean = false;
  waitForLoader: boolean = false;
  OpenBlockerPopUp: boolean = false;
  dropDownType: String = '';
  showDropDown: boolean = false;
  searchEpic: String = '';
  canAddFeatureLabel: boolean = false;
  canAddFeature: boolean = false;
  commit_sort_order: string = "New to Old";
  selected_story_state: string = '';
  OpenAcceptAlertPopup: boolean = false;
  OpenAcceptRejectPopup: boolean = false;
  openAddFeaturePopup: boolean = false;
  showCommitPopupOnStoryStarted: boolean = false;
  storyUrl: string = '';
  storyTypeOptions: Array<any> = STORY_TYPE_OPTIONS;
  devTypeOptions: Array<any> = DEV_TYPE_OPTIONS;
  storyStateOptions: Array<any> = STORY_STATE_OPTIONS;
  storySeverityOptions: Array<any> = STORY_SEVERITY_OPTIONS;
  storyPriorityOptions: Array<any> = STORY_PRIORITY_OPTIONS;
  projectUsernames = [];
  type = '';
  openCommitPopup: boolean = false;
  openBugReasonsPopup: boolean = false;
  create_SubTask: boolean = false;
  focus_create_SubTask: boolean = false;
  new_subTask: Story;
  parent_story: Story;
  input_estimate: number = 0;
  input_reviewer_estimate: number = 0;
  old_description: string;
  old_story_title: string;
  subTaskTitle:string;
  insertOwnerTime:boolean = false;
  // showSubtaskTimer = '';
  insertReviewerTime:boolean = false;
  trackedStory: Story;
  searchText = '';
  noNoteText = '';
  atTheIndex;
  showMention = false;
  charCount = 0;
  @ViewChild('titleDim') titleDim: ElementRef;
  @ViewChildren('subTaskInputTitle') subTaskInputTitle: QueryList<ElementRef>;
  @ViewChild('checkScroll') checkScroll: ElementRef;
  @ViewChild('makeFocus') makeFocus: ElementRef;
  @ViewChild('maintainHeight') maintainHeight: ElementRef;
  @ViewChildren('ownersList') ownersList: any;
  @ViewChildren('reviewersList') reviewersList: any;
  @ViewChildren('mentionUsersList') mentionUsersList: any;
  @ViewChild('slickModal') slickModal: SlickCarouselComponent;

  membersListElements: any;
  selectedMemberIndex = 0;
  maintainMentions = [];
  oldText = '';
  addEpic: Epic;
  filtered_tracker_phases: Array<any> = [];
  subtaskSortOrder = 'title';
  bugReasons: any;
  openSubTaskBugReasonsPopup: boolean = false;

  slideConfig = {
    'slidesToShow': 3,
    'slidesToScroll': 1,
    'infinite': false,
    'variableWidth': true
  };

  showMatchingPopup = false;
  phases = [];
  showVideo:boolean = false;
  vURL = '';
  public viewDescriptionEditor = DecoupledEditor;
  public editDescriptionEditor = DecoupledEditor;
  expResultInputField: boolean = false;
  expResultEditMode: boolean = false;
  actualResultInputField: boolean = false;
  addSprintDropdown: boolean = false;
  sprintsMeta: {
    current_page: number,
    next_page: number,
    per_page: number,
    total_count: number
  };
  sprints_in_story: Array<Sprint> = [];  


  // Editor option for edit mode.
  editor_options = {
    toolbar: ['heading', '|', 'bold', 'italic', 'underline', 'strikeThrough', '|', 'alignment', 'numberedList', 'bulletedList',
      '|', 'link', 'blockquote', 'imageupload', 'insertTable', 'codeblock', '|', 'undo', 'redo'],
    image: {
      // You need to configure the image toolbar, too, so it uses the new style buttons.
      toolbar: ['imageTextAlternative', '|', 'imageStyle:alignLeft', 'imageStyle:full', 'imageStyle:alignRight'],

      styles: [
        // This option is equal to a situation where no style is applied.
        'full',

        // This represents an image aligned to the left.
        'alignLeft',

        // This represents an image aligned to the right.
        'alignRight'
      ]
    },
    extraPlugins: [this.theUploadAdapterPlugin],
    uploadUrl: null,
    http: null,
    placeholder: 'Enter Additional Description',
  };

  // Editor option for view mode.
  view_editor_options = {
    image: {
      styles: [
        // This option is equal to a situation where no style is applied.
        'full',

        // This represents an image aligned to the left.
        'alignLeft',

        // This represents an image aligned to the right.
        'alignRight'
      ]
    },
    placeholder: 'Enter Additional Description',
  };

  isUrlClickedInDesc: boolean = false;
  showCopiedID:boolean = false;
  showCommitWarningPopup: boolean = false;
  statusTooltip: boolean = false;
  ifStoryArchived: boolean = true;
  showSubtaskFilter: boolean = false;
  addScroll: boolean = false;
  getTitleHeight:number;
  loggedInUser: any;
  showCopiedLinked:boolean = false;
  selectedBugReason = '';
  selectedSubtasks = [];
  openMoveSubtask:boolean = false;
  criteriaLinkCopied:boolean = false;
  parentTasksList:Array<Samefeaturephase> = [];
  loaderForTaskList:boolean = false;
  loaderForTaskListSearch:boolean = false;
  moveToStory = null;
  updatingSubtaskList:boolean = false;
  meta: any;
  moreAvailable: boolean = true;
  isPending:boolean = false;
  searchParentStories = '';
  parentStorySub: Subscription;
  ifNoParentTask:boolean = false;
  reasonSelected:boolean = false;
  subTaskID:number;
  idForTimerPopup:number;
  showConvertSubtaskPopup:boolean = false;
  showExtraOptions:boolean = false;
  showReasonList:boolean = false;
  showDetailReasonList:boolean = false;
  selectedBugReasonInPopup = '';
  selectedDetailBugReasonInPopup = '';
  bug_reason_id: number;
  bug_reason_detail_id: number;
  bug_related_to_story: {id?: number, title?: string};
  currentStoryTypeChange: boolean = false;
  enterRelatedStory:boolean = false;
  searchRelatedStory = '';
  relateStorySub: Subscription;
  relateStoryList: Array<RelatedStory> = [];
  loaderForRelateStoryList:boolean = false;
  // story_start_confirmation:boolean = false;

  openUnarchivePopUp:boolean = false;
  archived_story_detail: any;
  archived_story_detail_loader:boolean = false;

  openTimerReasonsPopup:boolean = false;
  story_update_reason = UPDATE_STORY_REASONS;
  showStoryupdateReasonList:boolean = false;
  input_for = '';
  subtask_input_time_id = null;
  story_update_reason_option = '';
  @ViewChild('ownerTimeInput') ownerTimeInput: ElementRef;
  @ViewChild('reviewerTimeInput') reviewerTimeInput: ElementRef;
  temp_description = '';
  @ViewChildren('subTaskOwnerTimeInputList') subTaskOwnerTimeInputList: QueryList<ElementRef>;
  checklist_certification_options = [];
  // checklist_BE = READY_FOR_CERTIFICATION_BE_CHECKLIST;
  // checklist_FE = READY_FOR_CERTIFICATION_FE_CHECKLIST;

  add_criteria_title = '';
  add_criteria_given = '';
  add_criteria_when = '';
  add_criteria_then = '';
  show_add_criteria: boolean = false;
  create_criteria_loader = false;
  openCriteriaDeleteConfirmation: boolean = false;
  selected_criteria:any;
  criteria_updated_for = '';
  // showExtraOptions: boolean = false;
  editing_time;
  isPreConditionFieldActive:boolean = false;
  isPostConditionFieldActive:boolean = false;
  isTestStepsFieldActive:boolean = false;
  isTestDataFieldActive:boolean = false;
  failedTestCasePopup:boolean = false;
  openCreateBugPopup: boolean = false;
  testCaseBugTitle = '';
  testCaseRejected:boolean = false;
  subTaskMenuVisible:boolean = false;
  read_only_checklist:boolean = false;
  private notificationSubscriptions: Subscription[] = [];
  detachment_options:boolean = false;
  storyReviewConfirmation:boolean = false;
  showCompare:boolean = false;
  selectedStory:any;
  uploadVideoLoader: boolean = false
  docLinkUrl:SafeResourceUrl 
  aiInputBlock: boolean = false;
  aiInput: string = '';

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dataService: DataService,
    public sharedService: SharedService,
    private sanitizer: DomSanitizer,
    public storyService: StoryService,
    private notificationService: NotificationService,
    private noteService: NoteService,
    private errorService: ErrorService,
    private documentService: DocumentService,
    private userService: UserService,
    private epicService: EpicService,
    private element: ElementRef,
    private queryParamsService: QueryParamsService,
    // private modalService: BsModalService,
    private cd: ChangeDetectorRef,
    private customToast: CustomToastService,
    public projectService: ProjectService,
    private renderer: Renderer2,
    private renderer2: Renderer2,
    private http: HttpClient,
    private policyService: PolicyService,
    private projectSyncService: ProjectSyncService,
    public commonStoryService: CommonStoryService,
    private domSanitizer: DomSanitizer
  ) {
    this.copied = CopiedComponent;
  }

  updateStoryFormat(updated_data, old_data) {
    if (updated_data === old_data || !this.storyService.allowStoryUpdateFlag) { return }
    
    if (this.validateStoryPermission()) {
      this.policyService.expertReadOnlyWarning();
    } else {
      this.selected_criteria = {};
      if(this.checkforTimeInput(this.story) && !this.story.description_format_edited && !this.story_update_reason_option ) {
        this.story.description_format_edited = true;
         this.getReasonForEdit();
      } else{
        this.story.reason_for_update = this.story_update_reason_option;
        this.disable_story_board = true;
        this.updateStory(this.story, 'description updated', 'description');
      }
    }
  }

  keepEditingAlive(continue_editing_alive, check_editing, criteria_data?) {
    if (check_editing) {
      clearTimeout(this.editing_time);
      continue_editing_alive === 'criteria_editing' ? criteria_data.criteria_description_edited = true : this.story.description_format_edited = true;
    }
  }

  // Do not ask editing reason before 60 seconds once asked.
  keepEditingAliveAfterUpdate(keep_editing_alive) {
    clearTimeout(this.editing_time);
    this.editing_time = setTimeout(() => {
      keep_editing_alive === 'criteria_editing' ? this.selected_criteria.criteria_description_edited = false : this.story.description_format_edited = false;
      if (keep_editing_alive === 'criteria_editing') {
        this.resetCriteriaDescriptionEdited();
        this.selected_criteria = {};
      }
    }, 60000);
  }

  resetCriteriaDescriptionEdited() {
    const criteria = this.story.acceptance_criteria.find(item => item.id === this.selected_criteria.id);
    if (criteria) {
      criteria.criteria_description_edited = false;
    }
  }

  checkReasonAndSave(){
    if(this.checkforTimeInput(this.story) && !this.story.description_format_edited && !this.story_update_reason_option ) {
      this.story.description_format_edited = true;
       this.getReasonForEdit();
    } else{
      this.saveDescriptionAndCriteria()
    }
  }
  
  saveDescriptionAndCriteria(){
    this.storyService.canDisableResetFlag = true
    this.storyService.canDisableSaveFlag = true
    this.storyService.disableStoryChatGPT = true
    this.storyService.handleAcceptanceCriteriaDeleteForGPT = false
    this.openTimerReasonsPopup = false;
    this.storyService.disableStoryUpdateFlag = true
    if(this.story.description_format_edited) {
      this.story.reason_for_update = this.story_update_reason_option;
    }
    this.updateStory(this.story, 'description updated', 'description');
    this.projectSyncService.acceptanceCriteriaArray = this.projectSyncService.acceptanceCriteriaArray.map((val:any,index:number)=>{
    if(!val.title){
      return {...val,title:`Acceptance Criteria ${index+1}`}
    }    
    return val  
    })
    this.storyService.createAcceptanceCriteriaBulk(this.project.id,this.story.id, this.projectSyncService.acceptanceCriteriaArray).subscribe((res) => {
      this.projectSyncService.acceptanceCriteriaArray = []
      this.aiInput = ''
    }, (error) => {
      this.create_criteria_loader = false;
      this.projectSyncService.acceptanceCriteriaArray = []
      this.aiInput = ''
      this.errorService.errorFunction(error);
    });
    
  }

  checkCriteriaTitle(name_count?) {
    let criteria_count = name_count ? name_count : this.story.acceptance_criteria.length;
    let criteria_name = name_count ? 'Acceptance Criteria ' + name_count : 'Acceptance Criteria ' + (criteria_count + 1);
    const criteria_index = this.story.acceptance_criteria.findIndex(item => item.title === criteria_name);
    if(criteria_index === -1){
      this.add_criteria_title = criteria_name;
    } else{
      criteria_count++;
      this.checkCriteriaTitle(criteria_count);
    }
  }

  checkCreateCriteria(added_data) {
    if (added_data === '') { return; }

    this.story.criteria_creation_inprogress = true;
    if (this.checkforTimeInput(this.story) && this.project.external() && !this.story_update_reason_option ) {
      this.getReasonForEdit();
    } else{
      this.createCriteria();
    }
  }

  createCriteria() {
    this.create_criteria_loader = true;
    if (!this.add_criteria_title) {
      this.checkCriteriaTitle();
    }
    const data = {
      title: this.add_criteria_title,
      description_for_given: this.add_criteria_given,
      description_for_when: this.add_criteria_when,
      description_for_then: this.add_criteria_then
    }
    if(this.story_update_reason_option) {
      data['reason_for_update'] = this.story_update_reason_option;
    }
    this.storyService.createAcceptanceCriteria(this.story.id, data).subscribe((res) => {
      this.story.criteria_creation_inprogress = false;
      this.create_criteria_loader = false;
      this.story.show_create_criteria = false;
      
      // Save critria locally
      this.story.addCriteria(res);

      // Do not ask editing reason again while updating the criteria
      const criteria = this.story.acceptance_criteria.find(item => item.id === res.id);
      criteria.criteria_description_edited = true;
      this.selected_criteria = {...criteria};
      this.selected_criteria.criteria_description_edited = true;
      this.keepEditingAliveAfterUpdate('criteria_editing');
      
      this.resetCreateCriteria();
    }, (error) => {
      this.create_criteria_loader = false;
      this.errorService.errorFunction(error);
    });
  }

  checkAcceptanceCriteriaEdited(criteria, update_for) {
    if (!this.storyService.allowStoryUpdateFlag) {
      let currentCriteriaChange = this.projectSyncService.acceptanceCriteriaArray.find((val) => val[update_for] === criteria[`old_${update_for}`])
      if (currentCriteriaChange) {
        currentCriteriaChange[update_for] = criteria[update_for]
        criteria[`old_${update_for}`] = criteria[update_for]
      }
      return;
    }   
    if (this.checkRestriction() || this.validateStoryPermission()) {
      return;
    } else {
      // Return if same changes available.
      if (criteria[update_for] === criteria['old_' + update_for]) {
        return;
      }

      // check if update action is performed previously to any other Acceptance Criteria 
      if (this.selected_criteria && this.selected_criteria.id !== criteria.id) {
        this.resetCriteriaDescriptionEdited();
        clearTimeout(this.editing_time);
      }

      // Store selected criteria for later usages.
      this.selected_criteria = {...criteria};
      this.criteria_updated_for = update_for;

      if (criteria['title'] === '') {
        this.restoreCriteriadata(update_for);
        this.customToast.messages.push({
          id: 'criteria_reset',
          type: 'alert',
          class: 'acceptance_alert',
          title: 'Alert',
          message: 'Acceptance Criteria fields cannot be left blank.'
        });
        return;
      } else{
        if(this.checkforTimeInput(this.story) && !criteria.criteria_description_edited && this.project.external() && !this.story_update_reason_option ) {
          criteria.criteria_description_edited = true;
          this.selected_criteria.criteria_description_edited = true;
          this.getReasonForEdit();
        } else{
          this.updateCriteria();
        }
      }
    }
  }

  updateCriteria() {
    const ind = this.story.acceptance_criteria.findIndex(as => as.id === this.selected_criteria.id);
    const data = {
      [this.criteria_updated_for] : this.selected_criteria[this.criteria_updated_for]
    }
    this.selected_criteria.reason_for_update = this.story_update_reason_option;
    if(this.selected_criteria.reason_for_update) {
      data['reason_for_update'] = this.selected_criteria.reason_for_update;
    }
    // this.story.acceptance_criteria[ind]['criteria_loader'] = true;
    this.openTimerReasonsPopup = false;
    this.storyService.updateAcceptanceCriteria(this.story.id, data, this.selected_criteria.id).subscribe(res => {
      // Just update the old values to validate the new values while updating the record.
      // Otherwise acting user face the fluctuation while writing the acceptane criteria frequetly
      this.story.updateCriteria({ id: res.id, changes: { ['old_' + this.criteria_updated_for]:  res[this.criteria_updated_for] }});

      if (this.selected_criteria.criteria_description_edited) {
        this.keepEditingAliveAfterUpdate('criteria_editing');
      }
      this.criteria_updated_for = '';
    }, (error) => {
      this.criteria_updated_for = '';
      this.story.acceptance_criteria[ind]['criteria_loader'] = false;
      this.errorService.errorFunction(error);
    });
  }

  openDelteAcceptanceCriteria(criteria, open?: boolean) {
    if(open) {
      this.selected_criteria = {...criteria};
      this.openCriteriaDeleteConfirmation = true;
    } else{
      this.selected_criteria = {};
      this.openCriteriaDeleteConfirmation = false;
    }
  }

  deleteAcceptanceCriteria(criteria) {
    if (this.story.acceptance_criteria.length > 1) {
      if(!this.storyService.allowStoryUpdateFlag){
        this.deleteAcceptanceCriteriaOfChatGPT(criteria)
        this.openCriteriaDeleteConfirmation = false;
        return
      }
      criteria.criteria_loader = true;
      this.openCriteriaDeleteConfirmation = false;
      this.storyService.deleteAcceptanceCriteria(this.story.id, criteria.id).subscribe(res => {
        this.story.removeCriteria(criteria);
        criteria.criteria_loader = false;
        this.selected_criteria = {};
      }, (error) => {
        criteria.criteria_loader = false;
        this.errorService.errorFunction(error);
      });
    } else {
      this.customToast.messages.push({
        id: 'serverfail',
        type: 'fail',
        class: 'generic_alert',
        title: 'Alert',
        message: 'Atleast 1 acceptance criterion should always be there.'
      });
    }
  }
  
  deleteAcceptanceCriteriaOfChatGPT(criteria) {
    const index = this.projectSyncService.acceptanceCriteriaArray.findIndex(ac => ac.title === criteria.title);
    if (index !== -1) {
      this.projectSyncService.acceptanceCriteriaArray.splice(index, 1);
    }
    this.story.removeCriteriaWithoutId(criteria)
  }

  restoreCriteriadata(update_for) {
    if(this.selected_criteria && this.selected_criteria.criteria_description_edited) {
      const criteria = this.story.acceptance_criteria.find(item => item.id === this.selected_criteria.id);
      if (criteria) {
        criteria[update_for] = this.selected_criteria['old_' + update_for];
        criteria.criteria_description_edited = false;
      }
      this.criteria_updated_for = '';
      this.selected_criteria = {};
    }
  }
  

  resetEditedCriteria() {
    this.restoreCriteriadata(this.criteria_updated_for);
    this.showStoryupdateReasonList = false;
    this.openTimerReasonsPopup = false;
    this.story_update_reason_option = '';
  }

  openAddCriteria() {
    if (this.story.show_create_criteria) { return }

    this.story.show_create_criteria = true;
  }
  
  closeAddCriteria() {
    this.story.show_create_criteria = false;
    this.resetCreateCriteria();
  }

  checkAddCriteriaOptions() {
    return this.add_criteria_title && this.add_criteria_given && this.add_criteria_when && this.add_criteria_then;
  }

  resetCreateCriteria() {
    this.add_criteria_title = '';
    this.add_criteria_given = '';
    this.add_criteria_when = '';
    this.add_criteria_then = '';
    this.story.criteria_creation_inprogress = false;
    this.showStoryupdateReasonList = false;
    this.openTimerReasonsPopup = false;
    // this.story_update_reason_option = '';
  }
  
  checkDescriptionFormatHasData() {
    return (this.story.acceptance_criteria.length > 0 || this.story.description_as_a || this.story.description_i_want || this.story.description_so_that)
  }

  get isProjectReadonly(): boolean {
    return ((this.policyService.isProjectReadonly(this.project)) );
  }

  // called on view mode
  public onView( editor ) {
    editor.ui.getEditableElement().parentElement.insertBefore(
      editor.ui.getEditableElement(),
      null
    );
  }

  changeStoryPhase(phase) {
    if(phase == this.story.phase) {
      return this.blockSameDropDownRequest();
    }
    if (this.project.external() && this.story.reviewer_id && this.story.owned_by_id && this.story.reviewer_id === this.story.owned_by_id && phase !== 'Product Roadmap') {
      this.customToast.messages.push({
        id: 'sameOwnerReviewer',
        type: 'fail',
        class: 'generic_alert',
        title: 'Alert',
        message: 'Owner and Reviewer cannot be the same.'
      });
      return this.blockSameDropDownRequest();
    }
    this.story.phase = phase;
    this.updateStory(this.story, 'phase updated', 'phase');
    this.showDropDown = false;
  }

  changeStoryTracker_Phase(phase) {
    if (this.story.task === phase.tracker_phase_name) {
      return this.blockSameDropDownRequest();
    }
     if (this.project.external() && this.story.reviewer_id && this.story.owned_by_id && this.story.reviewer_id === this.story.owned_by_id && phase !== 'Product Roadmap') {
      this.customToast.messages.push({
        id: 'sameOwnerReviewer',
        type: 'fail',
        class: 'generic_alert',
        title: 'Alert',
        message: 'Owner and Reviewer cannot be the same.'
      });
      return this.blockSameDropDownRequest();
    }
    this.story.phase = phase.phase_name;
    this.story.task = phase.tracker_phase_name;
    this.updateStory(this.story, 'sub phase updated', 'task');
    this.showDropDown = false;
  }

  addStoryClass(storyState) {
    // For test case handle story state changes
    if (this.story.testCase()) {
      return this.loggedInUser.id !== this.story.owned_by_id;
    }
    const isStoryStateAcceptedOrRejected = storyState === 'accepted' || storyState === 'rejected';
    const storyEpic: Epic = this.story.getEpicInStory();
    if(isStoryStateAcceptedOrRejected && this.story.created_from !== 'delivery' && (this.story.dev_type === 'FE' || this.story.dev_type === 'BE') && (storyEpic.bb_status === BB_STATUS.ready_for_certification || storyEpic.bb_status === BB_STATUS.bb_under_development)) {
      return true;
    }
    if (storyState === 'accepted' && !this.checkCoreStoryAcceptance()) {
      return true;
    }
    if (this.story && (((!this.story.estimate) || this.story.estimate == 0 || !this.story.owned_by_id)  && _.includes(this.unchangesOwnerStoryState, storyState))) {
      return true;
    }
    if (storyState === 'started' || storyState === 'finished' || storyState === 'delivered') {
      return this.loggedInUser.id !== this.story.owned_by_id;
    }
    if (isStoryStateAcceptedOrRejected && this.story.state !== 'delivered' && this.story.state !== 'accepted' && this.story.state !== 'rejected') {
      return true;
    } 
    if(storyState === 'unstarted' && !(this.story.state === 'unstarted' || this.story.state === 'unscheduled') && !this.policyService.isProjectAdmin(this.project) && (this.story.timer_elapsed_total > 0) ){
      return true
    }

    const isReviewerNotLoggedInUser = this.loggedInUser.id !== this.story.reviewer_id && this.story.dev_type !== 'QA';
    const isNotQADeveloperAndOwnerIsLoggedInUser = this.loggedInUser.id !== this.story.owned_by_id && !this.story.reviewer_id;
    
    if (isStoryStateAcceptedOrRejected && isReviewerNotLoggedInUser) {
      return true;
    }

    if (isStoryStateAcceptedOrRejected && this.story.dev_type === 'QA') {
      if(isNotQADeveloperAndOwnerIsLoggedInUser || (this.loggedInUser.id !== this.story.owned_by_id && this.story.reviewer_id && this.loggedInUser.id !== this.story.reviewer_id)) {
        return true;
      }
    }

    if (this.project && this.project.external() && this.story && !this.story.story_acceptable
          && (storyState === 'finished' || storyState === 'delivered' || storyState === 'accepted')) {
      return true;
    }
    if (this.story.checkForMR() && (storyState === 'finished' || storyState === 'delivered' || storyState === 'accepted')) {
      return true;
    }
    if (this.story.state != 'accepted' && this.story.state != 'rejected' && isStoryStateAcceptedOrRejected && (this.story.dev_type !== 'QA')) {
      return this.story.acceptRejectCondition();
    }
    return false;
  }

  closeStoryStartConfirmation() {
    this.storyService.story_start_confirmation = false;
    this.storyService.storyState = '';
  }

  updateTestCaseStatus(status,test){
    if (status === 'rejected') {
      this.failedTestCasePopup = true;
      return;
    }
    this.disable_story_board = true;
    let testCaseData = {}
    testCaseData['state'] = status;
    this.storyService.updateTestCase(testCaseData,test.id,this.project).subscribe(res=>{
      this.disable_story_board = false;
    }, (error) => {
      this.disable_story_board = false;
      this.errorService.errorFunction(error);
    });
  }

  closeFailedTestCasePopup(){
    this.failedTestCasePopup = false;
  }

  createBug(){
    this.failedTestCasePopup = false;
    this.showCreateBugPopup();
    this.testCaseRejected = true;
  }

  closeDocValidationPopup() {
    this.story.openDocValidationPopup = false;
    this.story.certification_doc_checked = false;
  }

  checklistDocLink() {
    const checklist = this.story.dev_type === 'FE' ? READY_FOR_CERTIFICATION_FE_CHECKLIST : READY_FOR_CERTIFICATION_BE_CHECKLIST;
    return checklist[0].list.split('"')[1];
  }
  
  getSafeUrl(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  toggleCertificationDocRead(event) {
    this.story.certification_doc_checked = event.target.checked;
  }

  resetStory(){
  this.projectSyncService.acceptanceCriteriaArray = []
  this.storyService.allowStoryUpdateFlag = true
  this.storyService.disableStoryUpdateFlag = true
  this.storyService.disableStoryChatGPT = false
  this.aiInput = ''
  this.storyService.chatGPTLoader = false
  this.projectService.recallStory(this.project,this.story,this.story.id)
  }

  changeStoryState(storyState) {
    if (this.story.state === storyState) {
      return;
    }
    if (this.story.testCase()) {
      if (this.loggedInUser.id === this.story.owned_by_id) {
        this.updateTestCaseStatus(storyState, this.story);
      }
      return;
    } else if (storyState === this.story.state) {
      return this.blockSameDropDownRequest();
    } else if (this.addStoryClass(storyState)) {
      return false;
    }

    if (this.project.external() && this.story.dev_type !== 'QA' && this.story.dev_type !== 'E2E' && (storyState === 'delivered' || storyState === 'accepted') && this.story &&
        (this.story.phase === 'Full Build' || this.story.phase === 'MVP') && this.story.commits && this.story.commits.length < 1) {
      this.openCommitWarningPopup();
      return;
    }

    if (this.projectService.commit_format_confirmed === false && this.story.dev_type !== 'QA' && this.story.dev_type !== 'E2E' && storyState === 'started' && (this.story.phase === 'Full Build' || this.story.phase === 'MVP')) {
      this.openCommitPopupOnStoryStarted();
      return;
    }

    if ((this.loggedInUser.id === this.story.owned_by_id) && this.story.isUserConfirmedDocValidation()) {
      this.story.openDocValidationPopup = true;
      return;
    }
    
    if((storyState === 'started' || storyState === 'finished' || storyState === 'delivered') && !this.story.confirmed && (this.story.confirmed_by_id !== this.story.owned_by_id) ) {
      this.storyService.confirmOptions( this.story.story_type, storyState, this.policyService.expertAsQA(this.project, false));
      return;
    }

    if (storyState === 'accepted' || storyState === 'rejected') {
      this.selected_story_state = storyState;

      if (storyState === 'accepted' &&  !this.story.parent_id && ((this.story.total_tasks_count - this.story.done_tasks_count > 0) || (this.story.test_cases && this.story.checkTestCasesStatus()))) {
        this.OpenAcceptAlertPopup = true;
      } else {
        this.OpenAcceptRejectPopup = true;
      }
    } else {
      if(storyState === 'started') {
        let trackedStory: Story;
        const owned_user_id = this.story.owned_by_id;
        if(this.story.confirmed){
          this.storyService.story_start_confirmation = false;
        }
        if(owned_user_id) {
          trackedStory = this.sharedService.getActiveStory(owned_user_id, this.story.id);
        }
        if(!trackedStory) {
          this.stateChange(storyState);
        } else {
          this.trackedStory = trackedStory;
          this.updateProgressOnStateChange();
        }
      } else {
        if(storyState === 'finished' || storyState == 'delivered') {
          this.story.progress = 100;
          this.progress = 100;
        }
        this.stateChange(storyState);
      }
    }
    this.showDropDown = false;
  }

  openCommitWarningPopup() {
    this.showCommitWarningPopup = true;
  }

  closeCommitWarningPopup() {
    this.showCommitWarningPopup = false;
  }

  clickedOnIUnderstand() {
    this.projectService.updateCommitFormatConfirmation(this.story.project.id).subscribe(res => {
      this.projectService.commit_format_confirmed = true;
      this.closeCommitPopupOnStoryStarted();
      this.changeStoryState('started');
    }, error => {
      this.errorService.errorFunction(error);
      this.closeCommitPopupOnStoryStarted();
    });
  }

  openCommitPopupOnStoryStarted() {
    this.showCommitPopupOnStoryStarted = true;
  }

  closeCommitPopupOnStoryStarted() {
    this.showCommitPopupOnStoryStarted = false;
  }

  stateChange(storyState: string) {
    this.story.state = storyState;
    this.updateStory(this.story, 'state updated', 'state', '', storyState === 'started');
    this.projectService.isRefresh = true;
  }

  finalStoryState() {
    this.OpenAcceptRejectPopup = false;
    this.story.state = this.selected_story_state;
    this.story.progress = 100;
    this.progress = 100;
    this.updateStory(this.story,  'state updated', 'state');
  }

  closeAcceptRejectPopUp() {
    this.OpenAcceptRejectPopup = false;
  }

  closeAcceptAlertPopUp() {
    this.OpenAcceptAlertPopup = false;
  }

  storyStatusIconSelectedClass(story_state) {
    switch (story_state) {
      case STORY_STATE.unscheduled:
        return 'stateSelected-icon unscheduled';
      case STORY_STATE.unstarted:
        return 'stateSelected-icon unstarted';
      case STORY_STATE.started:
        return 'stateSelected-icon started';
      case STORY_STATE.finished:
        return 'stateSelected-icon finished';
      case STORY_STATE.delivered:
        return 'stateSelected-icon delivered';
      case STORY_STATE.accepted:
        return 'stateSelected-icon accepted';
      case STORY_STATE.rejected:
        return 'stateSelected-icon rejected';
      default:
        return null;
    }
  }

  removeNoteDocument(file) {
    const index = this.uploaderArr.findIndex(item => item === file);
    if (index !== -1) {
      this.uploaderArr.splice(index, 1);
      this.attachmentPreviewUrls.splice(index, 1);
    }
  }
  removeStoryAttachment(file) {
    const index = this.attachOnlyArr.findIndex(item => item === file);
    if (index !== -1) {
      this.attachOnlyArr.splice(index, 1);
      this.attachmentPreviewUrls.splice(index, 1);
    }
  }

  storyTypeIcon(story_type,subtask = false) {
    switch (story_type) {
      case STORY_TYPE.task:
        return subtask ? 'iconb-subtask taskIcons' : 'iconb-task taskIcons';
      case STORY_TYPE.bug:
        return 'iconb-bug bugIcons';
      default:
        return null;
    }
  }

  blockSameDropDownRequest() {
    this.dropDownType = '';
    this.showDropDown = false;
    return ;
  }

  openReasonsLists() {
    this.showDetailReasonList = false;
    this.showReasonList = !this.showReasonList;
  } 

  openDetailReasonsLists() {
    this.showReasonList = false;
    this.showDetailReasonList = !this.showDetailReasonList;
  }

  bugReason() {
    if (this.bug_reason_id) {
      return this.project.bug_reasons.filter(b => b.id === this.bug_reason_id)[0].title;
    } else {
      return 'Select a Reason';
    }
  }

  bugDetailReason() {
    if (this.bug_reason_detail_id) {
      return this.project.bug_reasons.filter(b => b.id === this.bug_reason_detail_id)[0].title;
    } else {
      return 'Select Reason Detail';
    }
  }

  selectBugReason(bugReason, type?) {
    if (type === 'detailedReason') {
      this.bug_reason_detail_id = Number(bugReason.id);
      this.showDetailReasonList = false;
    } else {
      this.bug_reason_id = Number(bugReason.id);
      this.bug_reason_detail_id = null;
      this.showReasonList = false;
    }
  }

  submitBugReasonPopup(submitFor: string) {
    if (this.bug_reason_id && this.bug_reason_detail_id) {
      if (!this.currentStoryTypeChange) {
        this.dropDownType = '';
        this.new_subTask.story_type = 'bug';
        this.showDropDown = false;
        this.new_subTask.bug_reason_detail_id = this.bug_reason_detail_id;
        this.new_subTask.bug_reason_id = this.bug_reason_id;
        this.new_subTask.related_to_story_id = this.bug_related_to_story.id;
      } else {
        this.story.bug_reason_id = this.bug_reason_id;
        this.story.bug_reason_detail_id = this.bug_reason_detail_id;
        this.story.related_to_story_id = this.bug_related_to_story.id;
        this.updateStoryType('bug');
      }
      this.openBugReasonsPopup = false;
      this.showReasonList = false;
      this.showDetailReasonList = false;
    }
  }
  
  closeBugReasonPopup() {
    this.currentStoryTypeChange = false;
    this.reasonSelected = false;
    this.openBugReasonsPopup = false;
    this.showReasonList = false;
    this.showDetailReasonList = false;
    this.bug_reason_id = null;
    this.bug_reason_detail_id = null;
    this.bug_related_to_story = {};
  }

  updateStoryType(storyType) {
    this.dropDownType = '';
    this.story.story_type = storyType;
    this.updateStory(this.story, 'Bug Reason updated', 'bugReason');
    this.showDropDown = false;
    this.currentStoryTypeChange = false;
    this.story.acceptance_criteria = [];
    if(storyType === 'bug') {
      this.story.show_create_criteria = true;
    }
  }

  changeStoryType(storyType: string) {
    if (this.story.story_type === storyType) {
      return this.blockSameDropDownRequest();
    }
    if (storyType !== 'bug') {
      this.story.bug_reason_id = null;
      this.story.bug_reason_detail_id = null;
      this.selectedBugReason = '';
      this.create_SubTask = false;
      this.updateStoryType(storyType);
    }  else {
      if (this.story.story_type !== 'bug') {
        this.currentStoryTypeChange = true;
        this.selectedBugReason = '';
        this.openBugReasonPopup();
      }
    }
  }

  changeDevType(devType : string){
    if (this.story.dev_type === devType) {
      return this.blockSameDropDownRequest();
    }else{
      this.dropDownType = '';
      this.story.dev_type = devType;
      this.updateStory(this.story, 'Dev Type updated', 'devType');
      this.showDropDown = false;
    }
  }
  
  changeNewSubTaskType(storyType) {
    this.reasonSelected = true;
    if (storyType !== 'bug') {
      this.dropDownType = '';
      this.new_subTask.story_type = storyType;
      this.showDropDown = false;
    } else {
      this.bug_related_to_story = {
        id : this.story.id,
        title : this.story.title
      }
      this.openBugReasonPopup();
    }
  }

  changeBugReason(bugReasonId, type) {
    if (type === 'bug_reason') {
      if (this.story.bug_reason_id === bugReasonId) {
        return this.blockSameDropDownRequest();
      }
      this.currentStoryTypeChange = true;
      this.bug_reason_id = bugReasonId;
      this.bug_related_to_story = this.story.bug_related_to_story;
      this.openBugReasonPopup();
    } else {
      this.story.bug_reason_detail_id = bugReasonId;
      this.updateStory(this.story, 'Bug Reason updated', 'bugReason');
    }
    this.dropDownType = '';
    this.showDropDown = false;
  }

  openBugReasonPopup() {
    this.closeCommitWarningPopup();
    this.searchRelatedStory = '';
    this.relateStoryList = [];
    if(this.story.parent_id) {
      this.bug_related_to_story = {
        id : this.story.parent_id,
        title : this.parent_story.title
      }
    }
    this.openBugReasonsPopup = true;
  }

  resetRelatedTo() {
    this.bug_related_to_story = {};
  }

  changeStoryPriority(priority) {
    if (this.story.priority === priority) {
      return this.blockSameDropDownRequest();
    }
    this.dropDownType = '';
    this.story.priority = priority;
    this.updateStory(this.story, 'priority updated', 'priority');
    this.showDropDown = false;
  }
  
  changeStoryStudioRole(st_role_id) {
    if (this.story.studio_role_id === st_role_id) {
      return this.blockSameDropDownRequest();
    }
    this.dropDownType = '';
    this.story.studio_role_id = st_role_id;
    this.updateStory(this.story, 'studio role updated', 'role');
    this.showDropDown = false;
  }

  setSubTaskUser(user, subtask) {
    this.disable_story_board = true;
    if(this.story.owned_by_id == user.id) {
      this.searchUser = '';
      this.subTaskID = null;
    }
    this.subTaskID = null;
    const updateSubtaskListData = {
      owned_by_id : user.id,
      owned_by_name : user.name,
      owned_by_initials : user.initials
    };
    this.storyService.updateStorySubtaskList(this.project.id, updateSubtaskListData, subtask.id, true).subscribe((st: Story) => {
      const f_Index = this.story.sub_tasks.findIndex(item => item.id === subtask.id);
      if (f_Index != -1){
        this.story.sub_tasks[f_Index].owned_by_id = updateSubtaskListData.owned_by_id;
        this.story.sub_tasks[f_Index].owned_by_name = updateSubtaskListData.owned_by_name;
        this.story.sub_tasks[f_Index].owned_by_initials = updateSubtaskListData.owned_by_initials;
      }
      this.disable_story_board = false;
    }, (error) => {
      this.disable_story_board = false;
      this.subTaskID = null;
      this.errorService.errorFunction(error);
    });
  }

  setSubTaskOwnerTime(subtask) {
    if (subtask.estimate === null || subtask.estimate === 0) {
      subtask.estimate = 0;
    } else {
      const updateSubtaskListData = {
        estimate : subtask.estimate || 0,
        reason_for_update: this.story_update_reason_option
      };
      this.updateSubTaskOwnerTime(subtask, updateSubtaskListData);
    }
  }

  updateSubTaskOwnerTime(subtask, updateSubtaskListData) {
    this.disable_story_board = true;
    if (subtask.estimate > 120 ) {
      subtask.estimate = 120;
      this.inputEstimateWarnning();
    }
    this.storyService.updateStorySubtaskList(this.project.id, updateSubtaskListData, subtask.id, true).subscribe((st: Story) => {
      this.disable_story_board = false;
    }, (error) => {
      this.disable_story_board = false;
      this.errorService.errorFunction(error);
    });
  }

  inputEstimateWarnning() {
    this.customToast.messages.push({
      id: 'serverfail',
      type: 'fail',
      class: 'generic_alert',
      title: 'Alert',
      message: 'Maximum estimate allowed is 120 hours, else break this story into parts.'
    });
  }

  increaseSubTaskOwnerEstimate(hour :number, subtask :any) {
    if (!this.policyService.projectAdmin(this.project)) {
      return this.policyService.expertReadOnlyWarning();
    }

    subtask.estimate = Number(subtask.estimate || 0) + Number(hour);
    if (subtask.estimate > 120 ) {
      subtask.estimate = 120;
      this.inputEstimateWarnning();
    }
    const updateSubtaskListData = {
      estimate : subtask.estimate,
      reason_for_update: this.story_update_reason_option
    };
    this.updateSubTaskOwnerTime(subtask, updateSubtaskListData);
  }

  setUser(user, type) {
    if((type == 'Owner' && this.story.owned_by_id == user.id) || (type == 'Reviewer' && this.story.reviewer_id == user.id)) {
      this.searchUser = '';
      return this.blockSameDropDownRequest();
    }
    this.searchUser = '';
    this.dropDownType = '';
    this.showDropDown = false;

    if (type == 'Owner') {
      this.setOwnedByID(user);
    } else if (type == 'Reviewer') {
      this.setReviewerByID(user);
    }
  }

  setReviewerByID(user) {
    this.story.reviewer_id = user.id;
    this.story.reviewer_name = user.name;
    this.story.reviewer_initials = user.initials;
    this.updateStory(this.story, 'reviewer updated', 'reviewer', 'reviewer_email');
  }

  setOwnedByID(user) {
    this.story.owned_by_id = user.id;
    this.story.owned_by_name = user.name;
    this.story.owned_by_initials = user.initials;
    this.updateStory(this.story, 'owner updated', 'owner', 'owner_email');
  }

  resetData_OnInit() {
    this.storyService.canDisableResetFlag = true
    this.storyService.handleAcceptanceCriteriaDeleteForGPT = true
    this.storyService.canDisableSaveFlag = true
    this.storyService.neglectOnClosure = false
    this.aiInput = ''
    this.aiInputBlock = false
    this.storyService.allowStoryUpdateFlag = true
    this.storyService.disableStoryUpdateFlag = true
    this.storyService.disableStoryChatGPT = false
    this.storyService.chatGPTLoader = false
    this.addSprintDropdown = false;
    this.story.description_format_edited = false;
    this.resetCreateCriteria();
    this.story_update_reason_option = '';
    this.openTimerReasonsPopup = false;
    this.story_update_reason = UPDATE_STORY_REASONS;
    this.showStoryupdateReasonList = false;
    this.input_for = '';
    this.subtask_input_time_id = null;
    this.storyService.attachmentActionLoader = false; 
    this.storyService.attachmentActionLoader2 = false;
    this.subTaskID = null;
    this.idForTimerPopup = null;
    this.new_subTask = null;
    this.selectedSubtasks = [];
    this.projectService.acceptRejectSubtasks = [];
    this.openMoveSubtask = false;
    this.parentTasksList = [];
    this.getTitleHeight = 48;
    this.parent_story = undefined;
    this.showMention = false;
    this.membersListElements = [];
    this.selectedMemberIndex = 0;
    this.uploaderArr = [];
    this.attachOnlyArr = [];
    this.searchEpic = '';
    this.input_estimate = this.story && this.story.estimate ? this.story.estimate : 0;
    this.input_reviewer_estimate = this.story.reviewer_estimate ?  this.story.reviewer_estimate : 0;
    this.old_description = this.story.description;
    this.old_story_title = this.story.title;
    this.create_SubTask = false;
    this.phases = this.project.phases;
    this.focus_create_SubTask = false;
    this.currentStoryTypeChange = false;
    this.reasonSelected = false;
    this.openCreateBugPopup = false;
    this.openBugReasonsPopup = false;
    this.bug_reason_id = null;
    this.bug_reason_detail_id = null;
    if (this.maintainHeight) {
      this.renderer2.setStyle(this.maintainHeight.nativeElement, 'height', '');
    }
    if(this.story.testCase()){
      this.view_editor_options.placeholder = 'Enter Description'
      this.editor_options.placeholder = 'Enter Description'
    }else if (this.story.story_type === 'bug') {
      this.view_editor_options.placeholder = 'Add steps to reproduce'
      this.editor_options.placeholder = 'Add steps to reproduce'
    }
    if (!this.story.pre_conditions) {
      this.isPreConditionFieldActive = true;
    }else{
      this.isPreConditionFieldActive = false;
    }
    if (!this.story.post_conditions) {
      this.isPostConditionFieldActive = true;
    }else{
      this.isPostConditionFieldActive = false;
    }
    if (!this.story.test_steps) {
      this.isTestStepsFieldActive = true;
    }else{
      this.isTestStepsFieldActive = false
    }
    if (!this.story.test_data) {
      this.isTestDataFieldActive = true;
    }else{
      this.isTestDataFieldActive = false;
    }
    this.closeReadyForCertificationPopUp()
    if (this.story && this.story.parent_id) {
      const index = this.project.stories.findIndex(st => st.id === this.story.parent_id);
      if (index !== -1) {
        this.parent_story = this.project.stories[index];
      } else {
        this.storyService.getStoryDetail(this.project.id, this.story.parent_id).subscribe(parent => this.parent_story = parent);
      }

      this.filtered_tracker_phases = this.project.tracker_phases;
      if (this.parent_story && this.parent_story.phase && this.parent_story.phase.trim().toLowerCase() !== 'design') {
        this.filtered_tracker_phases = this.project.tracker_phases.filter(item => item.phase_name.trim().toLowerCase() !== 'design');
      }
    }
    if (this.story && this.story.isTestCaseOrBug()) {
      this.storyService.getexpectedResult(this.story.id,this.project).subscribe(res => {
        this.disable_story_board = false
        this.story.expected_results = res.data;
      }, error => {
        this.errorService.errorFunction(error);
        this.disable_story_board = false;
      }); 
    } 
    this.progress = this.story.progress;
    this.showBlokerlist = [];
    this.blocker_count = 0;
    if (this.story.blockers) {
      for (let i = 0; i < this.story.blockers.length; i++) {
        this.story.blockers[i].blocker['isText'] = false;
        this.story.blockers[i].blocker['isShow'] = false;
        this.showBlokerlist.push(this.story.blockers[i].blocker);
      }
    }
    this.project.users = this.project.users.filter((user) => {
      return user.email;
    });
    this.sortedUsers = this.project.users.sort((a: User, b: User) => {
      const nameA = (a.name != null ? a.name.toLowerCase() : a.email.toLowerCase());
      const nameB = (b.name != null ? b.name.toLowerCase() : b.email.toLowerCase());
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    this.sortMembers();
    this.sortDeallocatedMembers();
    this.getSprints();
    if (this.story.id) {
      this.storyUrl = `${window.location.origin}/#/projects/${this.project.id}` + `?storyId=${this.story.id}`;
    }

    this.projectUsernames = this.story.project.users.map(user => `${user.email}`);
    for (let i = 0; i < this.projectUsernames.length; i++) {
      this.projectUsernames[i] = this.projectUsernames[i] + ' ';
    }
    this.relateStoryList = [];
    this.searchRelatedStory = '';
  }

  startTheTimer() {
    this.storyService.startTimer(this.project,this.story).subscribe(story => {
      this.storyService.story_start_confirmation = false;
    }, error => {
      this.storyService.story_start_confirmation = false;
      this.errorService.errorFunction(error);
    });
  }

  sortDeallocatedMembers(){
    if(this.project.memberships.length > 0){
    this.sortedUsers = this.sortedUsers.sort((a, b) => {
      let valueA, valueB;
      valueA = a,
      valueB = b;
      const ind1 = this.project.memberships.findIndex(member => member.user.id === valueA.id)
      const ind2 = this.project.memberships.findIndex(member => member.user.id === valueB.id);
      if ((valueA.deleted_at || !this.project.memberships[ind1].active) && (!valueB.deleted_at && this.project.memberships[ind2].active)) {
        return 1;
      } else if ((!valueA.deleted_at && this.project.memberships[ind1].active) && (valueB.deleted_at || !this.project.memberships[ind2].active)) {
        return -1;
      } else {
        return 0;
      }
    });
  }
  }

  sortMembers(){
    this.sortedUsers = this.sortedUsers.sort((a, b) => {
      let valueA, valueB;
      valueA = a.name ? a.name : '',
      valueB = b.name ? b.name : '';
      valueA = valueA.toLowerCase();
      valueB = valueB.toLowerCase();
      if (valueA > valueB) {
        return 1;
      } else if (valueA < valueB) {
        return -1;
      } else {
        return 0;
      }
    });
  }

  ngOnInit() {
    this.loggedInUser = this.userService.getUser();
    if (this.story) {
      this.resetData_OnInit();
    }

    // Listen for sprint created
    const sprintAddedEvent = this.notificationService.subscribeEvent(EVENT_TYPES.SPRINT.SPRINT_CREATED, res => {
      this.sprints_in_story.unshift(new Sprint(res.payload.sprint));
    });
    this.notificationSubscriptions.push(sprintAddedEvent);

    // Listen for sprint deleted
    const sprintDeletedEvent = this.notificationService.subscribeEvent(EVENT_TYPES.SPRINT.SPRINT_DELETED, res => {
      const sprint_Index = this.sprints_in_story.findIndex(sp => sp.id === res.payload.sprint.id);
      if (sprint_Index !== -1) {
        this.sprints_in_story.splice(sprint_Index, 1);
      }

      // story sprint deleted through websocket
      if (this.story?.sprint && this.story.sprint.id === res.payload.sprint.id) {
        this.story.sprint = {}; 
      }
    });
    this.notificationSubscriptions.push(sprintDeletedEvent);

    // Listen for sprint updation
    const sprintUpdatedEvent = this.notificationService.subscribeEvent(EVENT_TYPES.SPRINT.SPRINT_UPDATED, res => {
      const sprint_Index = this.sprints_in_story.findIndex(sp => sp.id === res.payload.sprint.id);
      if (sprint_Index !== -1) {
        this.sprints_in_story[sprint_Index] = new Sprint(res.payload.sprint); 
      }
      if (sprint_Index !== -1 && res.payload.sprint.status === "completed") {
        this.sprints_in_story.splice(sprint_Index, 1);
      }
   
      // story sprint updated through websocket
      if (this.story?.sprint && this.story.sprint.id === res.payload.sprint.id) {
        this.story.sprint['name'] = res.payload.sprint.name;
        this.story.sprint['status'] = res.payload.sprint.status;
      }
    }); 
    this.notificationSubscriptions.push(sprintUpdatedEvent);

    // Listen for user confirmation event
    const userConfirmedStoryEvent = this.notificationService.subscribeEvent(EVENT_TYPES.STORY_CONFIRMATION.USER_CONFIRMED_STORY, res => {
      this.story.confirmed = true;
      if (this.story.state === 'started') {
        this.updateStory(this.story, 'confirmedby_updated_with_timer', 'story_update_timer_start', '', 'started');
        this.projectService.isRefresh = true;
      } else{
        this.changeStoryState(res.payload.data.story_state);
      }
    });
    this.notificationSubscriptions.push(userConfirmedStoryEvent);

    // Listen for comment created
    const noteCreatedEvent = this.notificationService.subscribeEvent(EVENT_TYPES.NOTE.CREATED, res => {
      this.commentNoteUploading = false;
    }, res => { this.commentNoteUploading = false; });
    this.notificationSubscriptions.push(noteCreatedEvent);

    // Listen for story show details
    const showDetailEvent = this.notificationService.subscribeEvent(EVENT_TYPES.STORY.SHOW_DETAILS, res => {
      this.story = res.payload.data.story;
      this.activatedRoute.params.subscribe((params: Params) => {
        const r_id = +params['rid'];
        this.story.selected = this.story.release_id === r_id;
      });
      this.docLinkUrl = this.getSafeUrl(this.checklistDocLink())
      this.project = this.story.project;
      this.view_editor_options.placeholder = this.story.parent_id ? 'Enter Task Description' : 'Enter Additional Description';
      this.resetData_OnInit();
      this.removeMentionsTag();
      this.projectSyncService.shiftStory(this.story, res.payload.data.prev_col);
      setTimeout(() => {
        this.getDimension();
      }, 400);
    });
    this.notificationSubscriptions.push(showDetailEvent);

    // Listen for updated bug related
    const bugRelatedToUpdatedEvent = this.notificationService.subscribeEvent(EVENT_TYPES.STORY.BUG_RELATED_TO_UPDATED, res => {
      if (this.story?.id === res.payload.data.story_id) {
        this.story.bug_related_to_story = res.payload.data.bug_related_to_story;
      }
    });
    this.notificationSubscriptions.push(bugRelatedToUpdatedEvent);

    // Listen for updated expected result
    const expectedResultEvent = this.notificationService.subscribeEvent(EVENT_TYPES.STORY.EXPECTED_RESULT, event => {
      this.story.updateExpectedResult(event.payload);
    });
    this.notificationSubscriptions.push(expectedResultEvent);

    const updateOpenedSubtaskEvent = this.notificationService.subscribeEvent(EVENT_TYPES.STORY.UPDATE_OPENED_SUBTASK, res => {
      if(this.story?.parent_id === res.payload.story_id || this.story?.id === res.payload.story_id) {
        if ('description_as_a' in res.payload.changes) {
          this.story['description_as_a'] = res.payload.changes.description_as_a;
        };
        if ('description_i_want' in res.payload.changes) {
          this.story['description_i_want'] = res.payload.changes.description_i_want;
        };
        if ('description_so_that' in res.payload.changes) {
          this.story['description_so_that'] = res.payload.changes.description_so_that;
        };
      }
    });
    this.notificationSubscriptions.push(updateOpenedSubtaskEvent);

    // Listen for cloned story
    const duplicateAfterConfimationEvent = this.notificationService.subscribeEvent(EVENT_TYPES.STORY.DUPLICATE_AFTER_CONFIRM, res => {
      if (this.story?.id == res.payload.data.story.id && this.projectService.show_storyDetails) {
        this.duplicate();
        this.projectService.duplicate_with_filter = false;
      }
    });
    this.notificationSubscriptions.push(duplicateAfterConfimationEvent);

    const titleUpdatedEvent = this.notificationService.subscribeEvent(EVENT_TYPES.STORY.TITLE_UPDATED, res => {
      setTimeout(() => {
        this.getDimension(true);
      }, 400);
    });
    this.notificationSubscriptions.push(titleUpdatedEvent);

    const estimateUdpatedEvent = this.notificationService.subscribeEvent(EVENT_TYPES.STORY.ESTIMATE_UPDATED, res => {
      if (this.story) {
        this.input_estimate = this.story && this.story.estimate ? this.story.estimate : 0;
        this.input_reviewer_estimate = this.story.reviewer_estimate ? this.story.reviewer_estimate : 0;
      }
    });
    this.notificationSubscriptions.push(estimateUdpatedEvent);

    const storyProgressEvent = this.notificationService.subscribeEvent(EVENT_TYPES.STORY.PROGRESS, res => {
      const story = res.payload.data.story;
      if (this.story?.id == story.id) {
        this.progress = story.progress;
      }
    });
    this.notificationSubscriptions.push(storyProgressEvent);

    const blockerUpdatedEvent = this.notificationService.subscribeEvent(EVENT_TYPES.STORY.BLOCKERS_UPDATED, res => {
      if (res && res.payload && res.payload.data && res.payload.data.story && this.story &&
            this.story.id === res.payload.data.story.id) {
        this.showBlokerlist = [];
        if (this.story.blockers) {
          for (let i = 0; i < this.story.blockers.length; i++) {
            this.story.blockers[i].blocker['isText'] = false;
            this.story.blockers[i].blocker['isShow'] = false;
            this.showBlokerlist.push(this.story.blockers[i].blocker);
          }
        }
      }
    });
    this.notificationSubscriptions.push(blockerUpdatedEvent);

    const dataUpdatedEvent = this.notificationService.subscribeEvent(EVENT_TYPES.STORY.DATA_UPDATED, res => {
      if (this.story && res && res.payload && res.payload.class === 'Note') {
        const event = res.payload;
        const subTask = this.story.findSubStoryByID(event.story_id);
        if (subTask) {
          if ('notes_count' in event) {
            subTask.comment_count = event.notes_count;
          }
        }
      } else if (this.story && res && res.payload && res.payload.class === 'Story') {
        const event = res.payload;
        if (event.changes && Object.keys(event.changes).length !== 0) {
          const subTask = this.story.findSubStoryByID(event.story_id);
          const testCaseStory = this.story.test_cases.find(as => as.test_case.id === event.story_id);
          this.upadteThroughWebSocket(event, subTask, testCaseStory);
        }
      }
    });
    this.notificationSubscriptions.push(dataUpdatedEvent);
  }

  ngOnDestroy() {
    this.notificationSubscriptions.forEach(subscription => subscription.unsubscribe());
    this.clearSprintSubscription();
  }

  upadteThroughWebSocket(event, subTask, testCaseStory) {
    delete event.changes['last_updated_at'];
    for (let key in event.changes) {
      subTask ? subTask[key] = event.changes[key] : testCaseStory.test_case[key] = event.changes[key];
    }
  }

  getBlockerCount() {
    return this.blocker_count = this.showBlokerlist.length + this.story.related_stories.length;
  }

  getInternalEpic() {
    if (this.project) {
      return this.project.epics.find((epic) => {
        return epic.title == 'Internal Project Management';
      })
    }
  }

  
  createSubTask(val) {
    if (this.isProjectReadonly || (this.story && this.story.deleted_at) || this.story.state === 'accepted') {
      this.policyService.readOnlyWarning();
    } else {
      this.create_SubTask = val;
      this.focus_create_SubTask = val;

      if (this.create_SubTask) {
        this.new_subTask = Story.createDefaultStory(this.project, this.userService.getUser());
        if (this.policyService.projectAdmin(this.project)) {
          this.new_subTask.story_type = this.story.story_type;
        } else if (this.policyService.expertAsQA(this.project, false)) {
          this.new_subTask.story_type = 'bug';
          this.bug_related_to_story = {
            id : this.story.id,
            title : this.story.title
          }
        }
        if (this.new_subTask.story_type === 'bug') {
          this.openBugReasonPopup();
        }
      } else {
        this.new_subTask = null;
      }
    }
  }

  addSubTask() {
    if (!this.disable_story_board) {
      this.disable_story_board = true;
      this.new_subTask.title = this.new_subTask.title.trim();
      this.new_subTask.parent_id = this.story.id;
      this.new_subTask.owner_email = this.story.owner_email;
      this.new_subTask.owned_by_id = this.story.owned_by_id;
      this.new_subTask.owned_by_name = this.story.owned_by_name;
      this.new_subTask.owned_by_initials = this.story.owned_by_initials;
      this.new_subTask.list_id = this.story.list_id;
      if(this.story.bug_reason_id && (this.new_subTask.story_type === 'bug') && !this.new_subTask.bug_reason_id) {
        this.new_subTask.bug_reason_id = this.story.bug_reason_id;
        this.new_subTask.bug_reason_detail_id = this.story.bug_reason_detail_id;
      }
      if (this.story.phase) {
        if (this.project && !this.project.external()) {
          this.new_subTask.phase = this.story.phase;
          this.new_subTask.task = this.story.phase;
        } else {
          const tracker_phase = this.project.tracker_phases.filter(item => item.phase_name === this.story.phase)[0];
          if (tracker_phase) {
            this.new_subTask.phase = tracker_phase.phase_name;
            this.new_subTask.task = tracker_phase.tracker_phase_name;
          } else {
            this.new_subTask.phase = this.story.phase;
            this.new_subTask.task = this.story.phase;
          }
        }
      }

      if (this.story && this.new_subTask && this.story.epics.length > 0) {
        this.new_subTask.epics = this.story.epics;
        this.new_subTask.epic_id = this.new_subTask.epics[0].id
      }
      if (this.story.labels.length > 0) {
        this.new_subTask.labels = this.story.labels;
      }

      this.storyService.createStory(this.new_subTask).subscribe((subTask: Story) => {
        if(this.story) {
          this.story.sub_tasks.splice(0, 0, subTask.task_format);
          this.story.total_tasks_count++;
        }
        if(subTask && this.new_subTask) {
          subTask.epics = this.new_subTask.epics;
          subTask.project = this.project;
        }
        this.trackStoryTaskCreateSegmentEvent(subTask, true, this.story);
        if(subTask.story_type === 'bug') {
          this.createSubTask(false);
        } else{
          this.createSubTask(true);
        }
        this.disable_story_board = false;
        this.projectService.addStoryOrSubTaskOnStoryBoard(this.project, subTask);
        this.closeBugReasonPopup();
      }, (error) => {
        this.disable_story_board = false;
        this.closeBugReasonPopup();
        this.errorService.errorFunction(error);
      });
    }
  }

  gotoStory(story_id) {
    if (story_id) {
      this.projectService.storySizeExpand = false;
      this.projectService.show_StoryDetails(this.project, '', story_id);
    }
  }

  goToSubTask(subTask) {
    if (this.story && this.story.deleted_at) {
      this.policyService.readOnlyWarning();
    }else if (this.router.url.indexOf('test-suite') > 0) {
      this.projectService.show_StoryDetails(this.project, '', subTask.id,false,true);
    }else {
      this.projectService.storySizeExpand = false;
      this.projectService.show_StoryDetails(this.project, '', subTask.id);
    }
  }

  goToTestCase(test) {
    if (this.story && this.story.deleted_at) {
      this.policyService.readOnlyWarning();
    } else if (this.router.url.indexOf('test-suite') > 0) {
      this.projectService.show_StoryDetails(this.project, '', test.test_case.id,false,true);
    }else {
      this.projectService.show_StoryDetails(this.project, '', test.test_case.id);
    }
  }

  goToParent() {
    if (this.story) {
      this.projectService.storySizeExpand = false;
      if (this.router.url.indexOf('test-suite') > 0) {
        this.projectService.show_StoryDetails(this.project, '', this.story.parent_id,false,true);
      }else {
        this.projectService.show_StoryDetails(this.project, '', this.story.parent_id);
      }
    }
  }
  
   // calling at a time of edit mode ready
   public onEdit(editor) {
    //setting http and upload url dynamically in edity config
    editor.config.set('http', this.http);
    editor.config.set('uploadUrl', this.dataService.apiUrl + 'stories/' + this.story.id + '/upload_file');
    editor.ui.getEditableElement().parentElement.insertBefore(
      editor.ui.view.toolbar.element,
      editor.ui.getEditableElement(),
    );

    // moving focus on end of description
    editor.model.change(writer => {
      writer.setSelection(editor.model.document.getRoot(), 'end');
      editor.ui.getEditableElement().focus();
    });
    // editor focusout (calling for save description)
    editor.ui.focusTracker.on('change:isFocused', (evt, name, isFocused) => {
      // hack to avoid focus out when uploading images
      let imageUploadClicked = (window.document.activeElement === editor.ui.view.editable.element);
      if (!isFocused && !imageUploadClicked) {
        this.addDescription(editor.getData())
      }
    });
  }

  addDescription(event) {
    if (this.story.description.trim() === this.old_description) {
      this.story.description = this.old_description;
      this.descriptionInEditMode = false;
      return false;
    } else{
      if(this.checkforTimeInput(this.story) && !this.story.testCase() && !this.story_update_reason_option) {
        this.getReasonForEdit();
      } else{
        this.story.description = this.story.description.trim();
        this.old_description = this.story.description;
        if (this.story.description) {
          this.descriptionInEditMode = false;
          this.storyDescriptionLoader = false;
          this.updateStory(this.story, 'description updated', 'description');
        }
      }
      
    }
  }

  // created custom upload adapter plugin
  theUploadAdapterPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
      return new UploadAdapter(loader, editor.config.get('uploadUrl'), editor.config.get('http'));
    };
  }
  
  getReasonForEdit() {
    if (!this.story.testCase()) {
      this.openTimerReasonsPopup = true;  
      this.story_update_reason = UPDATE_STORY_DESCRIPTION_REASONS;
    }
  }

  updateReason(action_type) {
    if (this.story.criteria_creation_inprogress) {
      action_type === 'proceed' ? this.createCriteria() : this.resetCreateCriteria();
    } else if (this.selected_criteria && this.selected_criteria.criteria_description_edited) {
      action_type === 'proceed' ? this.updateCriteria() : this.resetEditedCriteria();
    } else if (this.descriptionInEditMode || this.story.description_format_edited) {
      if (this.descriptionInEditMode) {
        this.story.description_format_edited = false;
      }
      action_type === 'proceed' ?!this.storyService.allowStoryUpdateFlag ?this.saveDescriptionAndCriteria() :this.submitStoryEditupdateReason() : this.closeStoryEditupdateReason();
    } else {
      action_type === 'proceed' ? this.submitStoryTimeUpdateReason() : this.closeTimerReason();
    }
  }

  submitStoryEditupdateReason() {
    this.openTimerReasonsPopup = false;
    if(this.story.description_format_edited) {
      this.story.reason_for_update = this.story_update_reason_option;
      this.updateStory(this.story, 'description updated', 'description');
    } else{
      this.story.description = this.story.description.trim();
      this.old_description = this.story.description;
      this.story.reason_for_update = this.story_update_reason_option;
      if (this.story.description) {
        this.descriptionInEditMode = false;
        this.storyDescriptionLoader = false;
        this.updateStory(this.story, 'description updated', 'description');
      }
    }
  }

  closeStoryEditupdateReason() {
    if(this.story.description_format_edited) {
      this.story.resetOldDescription();
      this.story.description_format_edited = false;
    } else{
      this.story.description = this.old_description;
    }
    this.showStoryupdateReasonList = false;
    this.openTimerReasonsPopup = false;
    this.story_update_reason_option = '';
    this.descriptionInEditMode = false;
  }
  
  updateStory(story, event_Name, propName, propKey?, startTimer?, archive?, unarchive?) {
    this.showMatchingPopup = false;
    this.project.is_dragg = false;
    this.story.openDocValidationPopup = false;
    this.story.certification_doc_checked = false;
    this.disable_story_board = propName !== 'description' && propName !== 'title'
    this.storyService.updateStory(story, true, propName).subscribe((st: Story) => {
      if(propName === 'description' && this.story.description_format_edited) {
        this.keepEditingAliveAfterUpdate('description_format_editing');
      }
      if (event_Name && propName) {
        this.trackStoryUpdateSegmentEvent(event_Name, propName, propKey);
      }
      if (propName === 'devicePlatformDetach') {
        this.story.platform_detach_story_option = '';
      }
      this.disable_story_board = false;
      this.old_story_title = this.story.title;
      this.projectSyncService.updateAndShiftStory(this.story, st);
      
      if (propName == 'bugReason') {
        this.closeBugReasonPopup();
      } else if(propName == 'description' && st.matching_story) {
        this.story.matching_story = st.matching_story;
        this.story.st_platform_names = st.st_platform_names
        this.showMatchingPopup = true;
      } else if (propName === 'story_update_timer_start') {
        this.storyService.story_start_confirmation = false;
        this.startTheTimer();
      }
      if (archive) {
        this.closecommonPopUp();
        this.waitForLoader = false;
        this.customToast.messages.push({
          id: 'story_deleted',
          type: 'success',
          class: 'story_delete',
          title: this.story.testCase() ? 'Test Case Deleted' : 'Story Archived',
          message: story && story.parent_id ? this.story.testCase() ? 'You have deleted test case successfully' : 'You have archived 1 task successfully' : 'You have archived 1 story successfully'
        });
      } else if (unarchive) {
        this.customToast.messages.push({
          id: 'story_new',
          type: 'success',
          class: 'story_new',
          title: 'Story Unarchive',
          message: story && story.parent_id ? 'You have unarchived 1 task successfully' : 'You have unarchived 1 story successfully'
        });
      }
    }, (error) => {
      this.story_update_reason_option = '';
      if(this.story.description_format_edited) {
        this.story.resetOldDescription();
        this.story.description_format_edited = false;
      }
      this.closeBugReasonPopup();
      this.disable_story_board = false;
      this.projectService.show_StoryDetails(this.project, '', this.story.id, true);
      this.errorService.errorFunction(error);
      this.closecommonPopUp();
    });
  }

  getDimension(adjustHeight = false) {
    if (this.titleDim) {
      if (adjustHeight) {
        let basedOnNewLine = this.story.title.split(/\r\n|\r|\n/).length * 48;
        let basedOnLength = Math.ceil(this.story.title.length / 55) * 48;
        let bestHeight = basedOnLength >= basedOnNewLine ? basedOnLength : basedOnNewLine;
        bestHeight = bestHeight >= 144 ? 144 : bestHeight;
        this.renderer.setStyle(this.titleDim.nativeElement, 'height', bestHeight + 'px');
      }

      this.getTitleHeight = this.titleDim.nativeElement.offsetHeight;
      this.addScroll = this.getTitleHeight >= 110;
    }
  }

  updateTitle() {
    if (this.validateStoryPermission(null, 'title')) {
      this.policyService.expertReadOnlyWarning();
    } else {
      if ((this.story.title.trim() === this.old_story_title) || !this.story.title.trim()) {
        this.story.title = this.old_story_title;
        return;
      }
      this.disable_story_board = true;
      this.story.title = this.story.title.trim();
      this.updateStory(this.story, 'title updated', 'title');
    }
  }
  validateEditPermission(subTask){
    if(subTask.story_type==='bug'){
      return  this.policyService.expertAsQA(this.project)
    }else{
      return this.policyService.projectAdmin(this.project)
    }   
  }

  updateSubtaskTitle(subTask) {
    subTask.subTaskTitleEdit = false
    let tempTitle:string = subTask.title
    if(!this.subTaskTitle || this.subTaskTitle.trim() === subTask.title){
      this.subTaskTitle = tempTitle
      return;
    }
    subTask.title = this.subTaskTitle
    this.storyService.updateStorySubtaskList(this.project.id, {title:this.subTaskTitle}, subTask.id, true).subscribe((st: Story) => {
     }, (error) => {
      this.disable_story_board = false;
      subTask.title = tempTitle     
    });
  }

  unarchive() {
    this.openUnarchivePopUp = false;
    this.story.deleted_at = null;
    if (this.story.parent_id && this.archived_story_detail.parent_deleted_at) {
      this.story.parent_id = null;
    }
    this.updateStory(this.story, null, null, null, null, false, true);
  }

  showUnarchivePopup() {
    this.archived_story_detail_loader = true;
    this.openUnarchivePopUp = true;
    this.storyService.getStoryDetail(this.project.id, this.story.id).subscribe(res => {
      this.archived_story_detail = res;
      this.archived_story_detail_loader = false;
    }, (error) => {
      this.archived_story_detail_loader = false;
      this.errorService.errorFunction(error);
    });
  }

  checkParentArchiveDetail() {
    if (this.archived_story_detail && !this.story.parent_id) {
      return !this.archived_story_detail.active_subtask_count && !this.archived_story_detail.archived_subtask_count;
    }
  }

  closeUnarchivePopUp() {
    this.openUnarchivePopUp = false;
  }

  archive() {
    this.story.deleted_at = moment();
    this.waitForLoader = true;
    this.updateStory(this.story, null, null, null, null, true);
    if (this.story.testCase()) {
      this.projectService.hide_StoryDetails(); 
    }
  }

  duplicate() {
    const copy = Object.assign({}, this.story);
    this.project.is_dragg = false;
    copy.reason_for_creation = this.projectService.creationReasonsSelected ? this.projectService.creationReasonsSelected : '';
    this.storyService.copyStory(copy, this.projectService.apply_on_sub_tasks).subscribe(res => {
      const storyData = res.story.story;
      this.projectService.clonePopupLoader = false;
      this.projectService.closeCloneConfirmationPopup();
      this.renderer2.removeClass(document.body, 'duplicateStoryPopup-open'); 
      this.projectService.creationReasonsSelected = '';
      storyData.isSimulatedObject = true;
      storyData.project = this.story.project;
      const clonedStory = new Story(storyData);
      this.trackStoryTaskCreateSegmentEvent(clonedStory);
      this.projectService.addStoryOrSubTaskOnStoryBoard(this.project, clonedStory);
      if (res && res.message) {
        this.customToast.messages.push({
          id: 'story_new',
          type: 'success',
          class: 'story_new',
          title: 'Story Duplicate',
          message: res.message
        });
      }
    }, error => {
      this.errorService.errorFunction(error)
      this.projectService.clonePopupLoader = false;
      this.projectService.closeCloneConfirmationPopup();
      this.renderer2.removeClass(document.body, 'duplicateStoryPopup-open');
    });
  }

  addStoryToEpic(epic: Epic, story: Story) {
    if (!epic.hasStory(story)) {
      this.waitForLoader = true;
      const param = {
        'on_create': false
      };
      this.epicService.addStory(epic, story, param).subscribe(response => {
        this.story.epics = [];
        this.story.epics.push(response.epic);
        this.closeAddFeaturePopUp();
        this.searchEpic = '';
        this.trackStoryUpdateSegmentEvent('features updated', 'features');
        if (this.story && this.story.phase && this.story.phase.toLowerCase() === 'design') {
          this.projectService.show_StoryDetails(this.project, '', this.story.id, true);
        }
      },
    (error) => {
      this.closeAddFeaturePopUp();
      this.errorService.errorFunction(error);
    });
    }
  }

  removeStoryToEpic(epic: Epic, story: Story) {
    this.epicService.removeStory(epic, story)
      .subscribe(response => {
        this.trackStoryUpdateSegmentEvent('features updated', 'features');
      });
  }

  destroy() {
    if(this.isProjectReadonly || (this.story && this.story.deleted_at) || (!this.policyService.isUserProjectAdmin && this.story.story_type !== 'bug' && this.policyService.isQAExpert(this.project))) {
      this.policyService.readOnlyWarning();
    } else{
      this.OpencommonPopUp = true;
    }
  }

  closecommonPopUp() {
    this.waitForLoader = false;
    this.OpencommonPopUp = false;
  }

  cancel() {
    this.projectService.storySizeExpand = false;
    if (this.project) {
      this.project.is_dragg = false;
    }
    // this.searchText = '';
    this.projectService.hide_StoryDetails(); 
  }

  startDescriptionEditing(event) {
    if (this.validateStoryPermission(null, 'description')) { return; }
    
    this.temp_description = this.story.description;
    if (this.isProjectReadonly || (this.story && this.story.deleted_at) || (this.story.wireframe && this.story.parent_id === null)) {
      this.policyService.readOnlyWarning(); 
    } else{
      if (event && event.target && event.target.href) {
        this.isUrlClickedInDesc = true;
        let target_url = ("http://" + event.target.attributes.href.value).replace("http://http", "http");
        window.open(target_url, '_blank');
        setTimeout(() => { this.isUrlClickedInDesc = false; }, 0);
        event.preventDefault();
      } else {
        this.descriptionInEditMode = true;
      }
    }
  }

  get dataManipulationOptions(): DataManipulationOptions {
    return {
      notificationService: this.notificationService,
      currentUser: this.userService.getUser()
    };
  }

  addNewLabel(labelName: String) {
    if (!labelName.trim()) {
      return;
    }
    const label = {
      display: labelName,
      value: labelName
    };

    if (this.story.labels.findIndex(selectedLabel => selectedLabel.value === label.value) === -1) {
      this.story.labels.push(label);
    }
    this.searchEpic = '';
    this.updateLabels();
  }

  onLabelRemove(removeLabel) {
    if(this.isProjectReadonly || (this.story && this.story.deleted_at)) {
      this.policyService.readOnlyWarning();
    } else {
      this.story.labels = this.story.labels.filter((label) => {
        return removeLabel.value != label.value;
      });
      this.updateLabels();
    }
  }

  updateLabels() {
    this.storyService.updateLabels(this.story).subscribe((story: Story) => {
      this.trackStoryUpdateSegmentEvent('labels updated', 'labels');
      this.projectService.project_labels(this.project).subscribe(data => { this.project.labels = data; });
    }, (error) => {
     this.errorService.errorFunction(error);
   });
  }

  noteEdit(data) {
    const index = this.story.notes.findIndex(candidate => candidate === data.note);
    if (index !== -1) {
      this.story.notes.splice(index, 1);
    }
    const note = new Note({
      note: data.note.note,
      story_id: this.story.id,
      story: this.story,
      documents: data.uploaderArr
    });
    this.story.addNote(note);
  }

  openStoryUpdateReasonsLists() {
    this.showStoryupdateReasonList = !this.showStoryupdateReasonList;
  } 

  selectStoryupdateReason(update_reason) {
    this.story_update_reason_option = update_reason.reason;
    this.showStoryupdateReasonList = false;
  }

  submitStoryTimeUpdateReason() {
    this.story.reason_for_update = this.story_update_reason_option;
    this.showStoryupdateReasonList = false;
    this.openTimerReasonsPopup = false;
    if(this.input_for === 'owner_time') {
      this.openOwnerQuickTimeEstimate();
    } else if(this.input_for === 'subtask_owner_time'){
      this.openSubtaskOwnerQuickTimeEstimate(this.subtask_input_time_id, true);
    } else{
      this.openReviewerQuickTimeEstimate();
    }
  }
  
  askReasonForTimeInput(input_for, subtask_id?: number) {
    this.story_update_reason = UPDATE_STORY_REASONS;
    this.input_for = input_for;
    this.subtask_input_time_id = subtask_id ? subtask_id : null;
    this.openTimerReasonsPopup = true;
  }
  
  openOwnerQuickTimeEstimate() {
    this.insertOwnerTime = true;
    setTimeout(() => {
      this.ownerTimeInput.nativeElement.focus();
    }, 400);
  }

  checkforTimeInput(story,type = 'owner') {
    if (type === 'reviewer') {
      return story.reviewer_estimate_changed || (story.reviewer_elapsed_time_total || story.current_reviewer_timer_activity);
    }
    return story.timer_elapsed_total || !(['unscheduled','unstarted'].indexOf(story.state) !== -1);
  }

  closeTimerReason() {
    this.showStoryupdateReasonList = false;
    this.openTimerReasonsPopup = false;
    this.story_update_reason_option = '';
  }

  openSubtaskOwnerQuickTimeEstimate(openTimerPopup, withReason) {
    this.idForTimerPopup = openTimerPopup;
    if(withReason) {
      const selected_tasks = this.story.sub_tasks.findIndex(item => openTimerPopup === item.id);
      setTimeout(() => {
        // this.subTaskOwnerTimeInputList['_results'][selected_tasks].nativeElement.getElementsByTagName('input')[1].focus();
        document.getElementById('subtaskownerTime_' + openTimerPopup).focus();
      }, 0);
    }
  }
  closeSubtaskOwnerQuickTimeEstimate() {
    this.idForTimerPopup = null;
    this.story_update_reason_option = '';
  }

  closeOwnerQuickTimeEstimate() {
    this.insertOwnerTime = false;
    if (this.story && (this.input_estimate != this.story.estimate)) {
      this.updateEstimate('estimate');
    } else{
      this.story_update_reason_option = '';
    }
  }

  openReviewerQuickTimeEstimate() {
    this.insertReviewerTime = true;
    setTimeout(() => {
      this.reviewerTimeInput.nativeElement.focus();
    }, 400);
  }

  closeReviewerQuickTimeEstimate() {
    this.insertReviewerTime = false;
    if (this.input_reviewer_estimate != this.story.reviewer_estimate) {
      this.updateEstimate('reviewer_estimate');
    } else{
      this.story_update_reason_option = '';
    }
  }

  increaseOwnerEstimate(hour) {
    if (!this.policyService.projectAdmin(this.project)) {
      return this.policyService.expertReadOnlyWarning();
    }

    this.input_estimate = Number(this.input_estimate) + Number(hour);
    if (this.input_estimate) {
      this.updateEstimate('estimate');
    }
  }

  increaseReviewerEstimate(hour) {
    if (!this.policyService.projectAdmin(this.project)) {
      return this.policyService.expertReadOnlyWarning();
    }

    this.input_reviewer_estimate = Number(this.input_reviewer_estimate) + Number(hour);
    if (this.input_reviewer_estimate) {
      this.updateEstimate('reviewer_estimate');
    }
  }

  updateEstimate(key) {
    if (this.projectService.current_user_role === 'user') { return; }

    if (key === 'estimate') {
      this.input_estimate = parseInt(String(this.input_estimate), 10);
      if (isNaN(this.input_estimate) || this.input_estimate == 0) {
        this.input_estimate = this.story && this.story.estimate ? this.story.estimate : 0;
        return false;
      } else if (this.story && this.story.estimate && this.story.estimate == this.input_estimate) {
        this.story_update_reason_option = '';
        return false;
      }
    } else if (key === 'reviewer_estimate') {
      this.input_reviewer_estimate = parseInt(String(this.input_reviewer_estimate), 10);
      if (isNaN(this.input_reviewer_estimate) || this.input_reviewer_estimate == 0) {
        this.input_reviewer_estimate = this.story.reviewer_estimate ? this.story.reviewer_estimate : 0;
        return false;
      } else if (this.story.reviewer_estimate && this.story.reviewer_estimate == this.input_reviewer_estimate) {
        this.story_update_reason_option = '';
        return false;
      }
    }

    this.showEstimatedWarn(key);
    if (key === 'estimate') {
      this.story.estimate = this.input_estimate;
      this.updateStory(this.story, 'owner estimate updated', 'owner_estimate', 'estimate');
    } else if (key === 'reviewer_estimate') {
      this.story.reviewer_estimate = this.input_reviewer_estimate;
      this.updateStory(this.story, 'reviewer estimate updated', 'reviewer_estimate', 'reviewer_estimate');
    }
  }

  updateProgress(event, story) {
    if (story.progress == event.target.value) {
      return false;
    }
    this.progress = event.target.value;
    if (this.progress > 100) {
      this.progress = 100;
      this.customToast.messages.push({
        id: 'serverfail',
        type: 'fail',
        class: 'generic_alert',
        title: 'Alert',
        message: 'Story progress can not be more than 100%'
      });
    }
    this.story.progress = this.progress;
    this.updateStory(story, 'story progress updated', 'progress');
  }

  showEstimatedWarn(key) {
    if (this.input_estimate > 120 || this.input_reviewer_estimate > 120) {
      if (key == 'estimate') {
        this.input_estimate = 120;
      } else if (key == 'reviewer_estimate') {
        this.input_reviewer_estimate = 120;
      }
      this.customToast.messages.push({
        id: 'serverfail',
        type: 'fail',
        class: 'generic_alert',
        title: 'Alert',
        message: 'Maximum estimate allowed is 120 hours, else break this story into parts.'
      });
    }
  }

  addNewNote(model) {
    if (!this.commentNoteUploading) {
      if ((model && model.value && model.value.trim()) || this.uploaderArr.length > 0) {
        this.commentNoteUploading = true;
        if (this.maintainMentions.length > 0) {
          this.searchText = this.sharedService.saveParseString(this.searchText, this.maintainMentions);
        }
        if (!this.searchText) {
          this.searchText = '';
        }
        const note = new Note({
          note: this.searchText,
          story_id: this.story.id,
          story: this.story,
          documents: this.uploaderArr
        });
        this.noteService.createNote(this.story, note, false).subscribe((nt: Note) => {
          const getDiv = document.getElementById('activityDIV');
          if (getDiv) {
            getDiv.scrollIntoView({behavior: 'smooth', block: 'nearest'});
          }
          this.story.addNote(nt);
          model.reset();
          this.trackStoryUpdateSegmentEvent('comment added', 'comment_added', nt);
          this.renderer.setStyle(this.maintainHeight.nativeElement, 'height', '');
          this.commentNoteUploading = false;
          this.uploaderArr = [];
          this.attachmentPreviewUrls = [];
        }, (error) => {
          this.errorService.errorFunction(error);
          this.commentNoteUploading = false;
        });
        this.removeMentionsTag();
      }
    }
  }

  removeMentionsTag() {
    this.maintainMentions = [];
    this.searchText = '';
    this.oldText = '';
  }

  openAddFeaturePopUp(epic: Epic) {
    this.openAddFeaturePopup = true;
    this.addEpic = epic;
  }

  closeAddFeaturePopUp() {
    this.openAddFeaturePopup = false;
    this.addEpic = null;
    this.waitForLoader = false;
  }

  onEpicRemoved(epic: Epic) {
    if(this.isProjectReadonly || (this.story && this.story.deleted_at) || this.story.core || this.story.wireframe) {
      this.policyService.readOnlyWarning();
    } else {
      if (this.story) {
        pull(this.story.epics, epic);
        this.removeStoryToEpic(epic, this.story);
      }
    }
  }


  updateTestCase(type,data){
    this.disable_story_board = true;
    let testCaseData = {}
    testCaseData[type] = data;
    this.storyService.updateTestCase(testCaseData,this.story.id,this.project).subscribe(res=>{
      if (type === 'pre_conditions') {
        this.isPreConditionFieldActive = false;  
      }
      if (type === 'post_conditions') {
        this.isPostConditionFieldActive = false;
      }
      if (type === 'test_steps') {
        this.isTestStepsFieldActive = false;
      }
      if (type === 'test_data') {
        this.isTestDataFieldActive = false;
      }
      this.disable_story_board = false;
    }, (error) => {
      this.disable_story_board = false;
      this.errorService.errorFunction(error);
    });
  }

  addBlocker(data, update?, index?) {
    this.disable_story_board = true;
    if (data && ((data.value && !data.value.trim()) || (data.name && !data.name.trim()))) {
      this.disable_story_board = false;
       return false;
    }
    let changeUrlToID: Boolean = false;
    let replaced_data: String = '';
    if (data && data.value && data.value.length > 0) {
      const split_data = data.value.split('=');
      if (split_data && split_data.length === 2 &&
        split_data[0] === `${window.location.origin}/#/projects/${this.project.id}?storyId`) {
        if (Number(split_data[1])) {
          changeUrlToID = true;
          replaced_data = '#' + split_data[1];
        }
      }
    } else if (data.name && data.name.length > 0) {
      const split_data = data.name.split('=');
      if (split_data && split_data.length === 2 &&
        split_data[0] === `${window.location.origin}/#/projects/${this.project.id}?storyId`) {
        if (Number(split_data[1])) {
          changeUrlToID = true;
          replaced_data = '#' + split_data[1];
        }
      }
    }

    if (data && data.value && data.value.length > 0 && !update) {
      if (!changeUrlToID) {
        this.params = {
          'blocker': {
            'name': data.value,
            'on_create': false
          }
        };
      } else {
        this.params = {
          'blocker': {
            'name': replaced_data,
            'on_create': false
          }
        };
      }
      this.storyService.createBlocker(this.story.id, this.params).subscribe((blocker: any) => {
        this.disable_story_board = false;
        const block = {
          blocker_story: blocker.blocker.blocker_story,
          done: blocker.blocker.done,
          id: blocker.blocker.id,
          name: blocker.blocker.name,
          text: blocker.blocker.text,
          type: blocker.blocker.type,
          isText: false,
          isShow: false
        };
        this.showBlokerlist.push(block);
        this.story.blockers.push({blocker: block});
        data.reset();
        this.trackStoryUpdateSegmentEvent('blockers updated', 'blockers', this.getBlockersId());
      }, (error) => {

        this.disable_story_board = false;
        this.errorService.errorFunction(error);
      });
    } else {
      if (update) {
        this.params = {
          'blocker': {
            'done': true
          }
        };
      } else {
        if (!changeUrlToID) {
          this.params = {
            'blocker': {
              'name': data.name,
              'text': data.text,
              'done': false
            }
          };
        } else {
          this.params = {
            'blocker': {
              'name': replaced_data,
              'text': data.text,
              'done': false
            }
          };
        }
      }

      this.storyService.updateBlocker(this.story.id, data.id, this.params).subscribe((blocker: any) => {
        this.disable_story_board = false;
        this.showBlokerlist.forEach((bloc, ind) => {
          if (blocker.blocker.id == bloc.id) {
            this.showBlokerlist[ind]['done'] = blocker.blocker.done;
            this.showBlokerlist[ind]['name'] = blocker.blocker.name;
            this.showBlokerlist[ind]['text'] = blocker.blocker.text;
            if (!update) {
              this.canclelistBlocker(data);
            }
          }
        });
        this.trackStoryUpdateSegmentEvent('blockers updated', 'blockers', this.getBlockersId());
      }, (error) => {
        this.disable_story_board = false;
        this.errorService.errorFunction(error);
      });
    }
  }

  listBlocker(blocker) {
    if(this.isProjectReadonly || (this.story && this.story.deleted_at) || this.story.inactive) {
      this.policyService.readOnlyWarning();
    } else {
      blocker.name = blocker.text;
      if (!blocker.done) {
        blocker.isShow = !blocker.isShow;
        blocker.isText = !blocker.isText;
      }
    }
  }

  canclelistBlocker(blocker) {
    blocker.isShow = !blocker.isShow;
    blocker.isText = !blocker.isText;
  }

  openBlockerStoryPopup(data, type) {
    if(this.isProjectReadonly || (this.story && this.story.deleted_at) || this.story.inactive) {
      this.policyService.readOnlyWarning();
    } else {
      this.OpenBlockerPopUp = true;
      this.elem = data;
      this.type = type;
    }
  }

  closePopUp() {
    this.OpenBlockerPopUp = false;
  }

  deleteElement(data) {
    if (this.type === 'deleteBlocker') {
      this.deleteBlocker(data);
    }
  }

  deleteBlocker(data) {
    this.OpenBlockerPopUp = true;
    this.waitForLoader = true;
    this.storyService.getblocker.next(data);
    this.storyService.deleteBlocker(this.story.id, data.id).subscribe((blocker: any) => {
      for (let i = 0; i < this.showBlokerlist.length; i++) {
        if (this.showBlokerlist[i]['id'] === data.id) {
          this.showBlokerlist.splice(this.showBlokerlist.indexOf(this.showBlokerlist[i]), 1);
        }
      }
      this.waitForLoader = false;
      this.OpenBlockerPopUp = false;
      this.trackStoryUpdateSegmentEvent('blockers updated', 'blockers', this.getBlockersId());
    }, (error) => {
      this.errorService.errorFunction(error);
    });
  }

  addMergeRequest(mergeReq){
    this.disable_story_board = true;
    this.storyService.addMergeRequest(this.project.id,this.story.id,mergeReq.value).subscribe((res: any) => {
      const index  = this.story.merge_requests.findIndex(mr => mr.commit.id === res.commit.id)
      if (index === -1) {
        this.story.merge_requests.push(res); 
      }
      mergeReq.reset();
      this.disable_story_board = false;
    }, (error) => {
      this.disable_story_board = false;
    this.errorService.errorFunction(error);
    });
  }

  deleteMergeRequest(mergeReq){
    this.disable_story_board = true;
    this.storyService.deleteMergeRequest(this.project.id,this.story.id,mergeReq.commit.id).subscribe((req: any) => {
      const index = this.story.merge_requests.findIndex(mr => mr === mergeReq);
      if (index !== -1) {
        this.story.merge_requests.splice(index, 1);
      }
      this.disable_story_board = false;
    }, (error) => {
      this.disable_story_board = false;
      this.errorService.errorFunction(error);
    });
  }

  showEpics() {
    if(this.project && this.story) {
      const epics: Array<Epic> = this.project.epics.filter(epic => this.story.epics.findIndex(ep => ep.id === epic.id) === -1);
      const searchText = this.searchEpic.toLowerCase();

      return epics.filter(epic => epic.title.toLowerCase().includes(searchText));
    }
  }

  showLabels() {
    if (this.project && this.story) {
      const storyLables: Array<any> = [];
      for (let a = 0; a < this.story.labels.length; a++) {
        storyLables.push(this.story.labels[a].value);
      }
      const lables: Array<any> = this.project.labels.filter(label => storyLables.indexOf(label) === -1);
      const searchText = this.searchEpic.toLowerCase();

      return lables.filter(label => label.toLowerCase().includes(searchText));
    }
  }
  
  showRestrictionError() {
    this.policyService.readOnlyWarning();
  }

  selectSubTaskOwner(subTaskId) {
    if (this.subTaskID === subTaskId) {
      this.subTaskID = null;
    } else {
      this.subTaskID = subTaskId;
    }
  }

  validateStoryPermission(story_type?, column = null) {
    // story_type will be available for sub tasks otherwise pick for story
    if (!story_type) {
      story_type = this.story.story_type;
    }

    if (story_type === 'test_case') {

      // if user is not active member in project
      if(!this.project.isUserActive(this.loggedInUser)) { return true;} 

      if (column === 'title' || column === 'description') { return false; }
      
      return !this.policyService.expertAsQA(this.project);
    } else {
      return this.policyService.isProjectUser(this.project) && (story_type == 'task' || (story_type == 'bug' && !this.policyService.isQAExpert(this.project)));
    }
  }

  testSuiteFocusDropdown(dropDownType : string){
    if (dropDownType == 'parent_state') {
      this.showDropDown = true;
      this.dropDownType = dropDownType;
    } else if (dropDownType === 'owned_by') {
      this.dropDownType = dropDownType;
      this.showDropDown = true;
      this.setMembersList();
    }
  }

  focusDropDown(dropDownType: string) {
    // Warning to user role if they  want to change below values
    if (['story_type_task', 'bug_reason_id', 'detail_bug_reason_id'].indexOf(dropDownType) >= 0 && this.validateStoryPermission()) {
      this.policyService.expertReadOnlyWarning();
      return
    }
    if (this.isProjectReadonly || (dropDownType === 'dev_type' && this.story.dev_type === 'E2E') || (dropDownType === 'deviceAndPlatform' && this.story.checkDevicePlatformRestriction()) ||
      (this.story && this.story.deleted_at) || 
      (dropDownType == 'parent_state' && this.checkForBBStatus()) || 
      this.story.inactive || 
      (this.story.inDraft() && dropDownType === 'parent_state') || 
      (this.disableStoryStatusChange() && (this.story.owner_email === 'tracker_systemadmin@builder.ai') && this.story.state === 'accepted')) {
      this.policyService.readOnlyWarning();
    } else {
      this.searchUser = '';
      if (!this.story.phase && (dropDownType === 'reviewer_by' || dropDownType === 'owned_by')) {
        this.policyService.readOnlyWarning();
        return;
      }
      if (dropDownType === 'deviceAndPlatform' && this.story
            && this.story.parent_id && this.parent_story && this.parent_story.phase === 'Design') {
        this.showDropDown = false;
        this.dropDownType = '';
      } else if ((dropDownType === 'parent_state' && this.checkBugRelatedAction()) || (dropDownType === 'story_type' && this.policyService.isProjectUser(this.project))) {
        this.policyService.readOnlyWarning();
      } else if (this.showDropDown === false) {
        this.dropDownType = dropDownType;
        this.showDropDown = true;
      } else if (this.dropDownType === dropDownType) {
        this.showDropDown = false;
        this.dropDownType = '';
      } else {
        this.dropDownType = dropDownType;
      }

      if(dropDownType !== 'story_type_task'){
        this.create_SubTask = false;
      }

      if (this.showDropDown && (this.dropDownType === 'owned_by' || this.dropDownType === 'reviewer_by')) {
        this.setMembersList();
      }
      if (dropDownType === 'story_type_task' && this.policyService.isProjectUser(this.project)) {
        this.dropDownType = '';
        this.showDropDown = false;
      }
    }
  }

  setMembersList() {
    setTimeout(() => {
      this.selectedMemberIndex = 0;
      this.membersListElements = [];

      if (this.dropDownType === 'owned_by') {
        this.membersListElements = this.ownersList.map(member => {
          this.renderer2.setStyle(member.nativeElement, 'backgroundColor', 'white');
          return member.nativeElement;
        });
      } else if (this.dropDownType === 'reviewer_by') {
        this.membersListElements = this.reviewersList.map(member => {
          this.renderer2.setStyle(member.nativeElement, 'backgroundColor', 'white');
          return member.nativeElement;
        });
      } else if (this.showMention === true) {
        this.membersListElements = this.mentionUsersList.map(member => {
          this.renderer2.setStyle(member.nativeElement, 'backgroundColor', 'white');
          return member.nativeElement;
        });
      }

      if (this.membersListElements && this.membersListElements[this.selectedMemberIndex]) {
        this.renderer2.setStyle(this.membersListElements[this.selectedMemberIndex], 'backgroundColor', '#F2F4F4');
        this.membersListElements[this.selectedMemberIndex].scrollIntoViewIfNeeded();
      }
    }, 0);
  }

  setSelectedIndex(index) {
    if (this.membersListElements && this.membersListElements[this.selectedMemberIndex]) {
      this.renderer2.setStyle(this.membersListElements[this.selectedMemberIndex], 'backgroundColor', 'white');
      this.selectedMemberIndex = index;
      this.renderer2.setStyle(this.membersListElements[this.selectedMemberIndex], 'backgroundColor', '#F2F4F4');
      this.membersListElements[this.selectedMemberIndex].scrollIntoViewIfNeeded();
    }
  }

  setSelectedMember() {
    if (this.showDropDown && (this.dropDownType === 'owned_by' || this.dropDownType === 'reviewer_by')) {
      const index = this.sortedUsers.findIndex(user =>
        user.email === this.membersListElements[this.selectedMemberIndex].getAttribute('email'));
      if (index >= 0 && this.project.isUserActive(this.sortedUsers[index])) {
        if (this.dropDownType === 'owned_by') {
          this.setUser(this.sortedUsers[index], 'Owner');
        } else if (this.dropDownType === 'reviewer_by') {
          this.setUser(this.sortedUsers[index], 'Reviewer');
        }
      }
    } else if (this.showMention) {
      this.selectMention(this.mentionUsers[this.selectedMemberIndex]);
    }
  }

  onScroll(event): void {
    const checkHeight = this.checkScroll.nativeElement.scrollTop;
    if (checkHeight > 82) {
      this.renderer2.addClass(this.checkScroll.nativeElement, 'makeSticky');
    }
    if (checkHeight < 82) {
      this.renderer2.removeClass(this.checkScroll.nativeElement, 'makeSticky');
    }
  }

  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (this.showMention || (this.showDropDown && (this.dropDownType === 'owned_by' || this.dropDownType === 'reviewer_by'))) {
      if (event.keyCode === 38) {
        if (this.selectedMemberIndex > 0) {
          this.setSelectedIndex(this.selectedMemberIndex - 1);
        }
        event.preventDefault();
      } else if (event.keyCode === 40) {
        if (this.membersListElements && (this.selectedMemberIndex < (this.membersListElements.length - 1))) {
          this.setSelectedIndex(this.selectedMemberIndex + 1);
        }
        event.preventDefault();
      }else if (event.keyCode === 9 || event.keyCode === 13) {
        this.setSelectedMember();
      }
    }
    if (event.keyCode === 27) {
      if (this.openAuthor) {
        this.openAuthor = false;
      }
    }
  }

  onClick(event) {
      const element = event.target;
      if (!(element.classList.contains('selectedType-name') ||
        element.classList.contains('selected-type') ||
        element.classList.contains('iconbs-s2') ||
        element.classList.contains('iconb-rework') ||
        element.classList.contains('iconb-design') ||
        element.classList.contains('iconb-mvp') ||
        element.classList.contains('iconb-full-build') ||
        element.classList.contains('iconbs-s3') ||
        element.classList.contains('iconbs-s1') ||
        element.classList.contains('iconb-prototype') ||
        element.classList.contains('iconb-product-roadmap') ||
        element.classList.contains('selected-tonClickype') ||
        element.classList.contains('selected-name') ||
        element.classList.contains('userSearchFilter') ||
        element.classList.contains('iconb-dd-down') ||
        element.classList.contains('iconb-task') ||
        element.classList.contains('iconb-chore') ||
        element.classList.contains('iconb-bug') ||
        element.classList.contains('iconb-release') ||
        element.classList.contains('iconb-side-effect') ||
        element.classList.contains('iconb-owner') ||
        element.classList.contains('iconb-review') ||
        element.classList.contains('iconb-s1') ||
        element.classList.contains('iconb-s2') ||
        element.classList.contains('iconb-s3') ||
        element.classList.contains('stateSelected-icon') ||
        element.classList.contains('selected-state') ||
        element.classList.contains('stateSelected-state') ||
        element.classList.contains('commentFilter-selectedOption') ||
        element.classList.contains('selectedUser-name') ||
        element.classList.contains('selectedDevicePlatform-name') ||
        element.classList.contains('selectedReq-name') ||
        element.classList.contains('iconb-Smartphone') ||
        element.classList.contains('iconb-Desktop') ||
        element.classList.contains('iconb-Watch') ||
        element.classList.contains('selectDeviceList-wrapper') ||
        element.classList.contains('deviceType') ||
        element.classList.contains('storyReqSelection-list') ||
        element.classList.contains('moveSelection-check') ||
        element.classList.contains('customCheckFor-moveSelection') ||
        element.classList.contains('move-selected-checkbox') ||
        element.classList.contains('check-toggleMoveSelection') ||
        element.classList.contains('selectedType-name'))) {
        this.showDropDown = false;
        this.dropDownType = '';
      }

      if (element.classList.contains('linkify-2')) {
        this.descriptionInEditMode = false;
      }
      if (!element.classList.contains('addFeature')) {
        this.canAddFeature = false;
      }
      if (!element.classList.contains('toOpen') ) {
        this.showExtraOptions = false;
      }
      if (!(element.classList.contains('subtaskSorting-wrapper') || element.classList.contains('makeFilterClick'))) {
        this.showSubtaskFilter = false;
      }
      if (!element.classList.contains('forCreate-subTask') ||
          !element.classList.contains('subtask-inputbox')) {
        this.focus_create_SubTask = false;
      }
      if (!(element.classList.contains('sprint-trigger') || element.classList.contains('iconb-dd-down') || element.classList.contains('search-sprint-input'))) {
        this.addSprintDropdown = false;
      }
      if (!element.classList.contains('resultsInput') && !element.classList.contains('iconb-edit')) {
        if (this.story) {
          this.story.expected_results.forEach(res => {
            res.editMode = false;
          });            
        }
      }
      if (!(element.classList.contains('owner-wrapper') || 
        element.classList.contains('ifOwner-exist') || 
        element.classList.contains('iconb-add-member') || 
        element.classList.contains('userSelection-DD') ||
        element.classList.contains('userSearchFilter') ||
        element.classList.contains('clickContainer') ||
        element.classList.contains('listingWrapper')
        )) {
        this.subTaskID = null;
      }
      if (!(element.classList.contains('addRelated-input') || element.classList.contains('relatedto-storyList'))) {
        this.enterRelatedStory = false;
        this.searchRelatedStory = '';
        this.relateStoryList = [];
      }
      if (!element.classList.contains('iconb-more-vert') && !element.classList.contains('subtak-action-wrapper')) {
        this.subTaskMenuVisible = false;
      }
  }

  commitOption(commit) {
    if(this.isProjectReadonly || (this.story && this.story.deleted_at)) {
      this.policyService.readOnlyWarning();
    } else {
      commit.show_copy_commit = !commit.show_copy_commit;
    }
  }

  copyCommitLink(commit) {
    if(this.isProjectReadonly || (this.story && this.story.deleted_at)) {
      this.policyService.readOnlyWarning();
    } else {
      const selBox = document.createElement('textarea');
      selBox.style.position = 'fixed';
      selBox.style.left = '0';
      selBox.style.top = '0';
      selBox.style.opacity = '0';
      selBox.value = commit.url;
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand('copy');
      document.body.removeChild(selBox);
      commit.show_copy_commit = false;
    }
  }

  openComPopup() {
    this.closeCommitWarningPopup();
    this.openCommitPopup = true;
  }

  closeCommitPopup() {
    this.openCommitPopup = false;
  }

  getCommitCreatedAt(commited_on) {
    return moment(commited_on).format('lll');
  }

  trackStoryTaskCreateSegmentEvent(story, isSubTask?, parentStory?) {
    this.userService.trackSegmentEvent(story.storyOrTask(story) + ' created',
                                            this.projectService.getPropsOnStoryTaskCreation(this.project, story, isSubTask, parentStory));
  }

  trackStoryUpdateSegmentEvent(event_Name, propName, propKey?) {
    this.userService.trackSegmentEvent(this.story.storyOrTask() + ' ' + event_Name,
    this.projectService.getPropsOnStoryTaskUpdation(this.project, this.story, propName, propKey));
  }

  getBlockersId() {
    const blockerIds: Array<any> = [];
    this.showBlokerlist.forEach(blocker => blockerIds.push(blocker.name));
    return blockerIds;
  }

  toggle_comment_history(showList) {
    const mainDiv = document.getElementById('activityDIV');
    if (showList === 'comments') {
      this.projectService.show_Story_history = false;
    } else if (showList === 'history' && !this.projectService.show_Story_history) {
      this.projectService.show_Story_history = true;
      if (mainDiv) {
        mainDiv.scrollIntoView({ behavior: 'smooth', block: 'start'});
      }
    } else if (showList === 'history' && this.projectService.show_Story_history) {
      // const mainDiv = document.getElementById('activityDIV');
      if (mainDiv) {
        mainDiv.scrollIntoView({ behavior: 'smooth', block: 'start'});
      }
    }
  }

  updateProgressOnStateChange(storyProgress?) {
    this.storyService.updateStory(this.trackedStory, true).subscribe(() => {
      this.stateChange('started');
      const payload = {
        data: {
          story: this.trackedStory
        }
      };
      this.notificationService.broadcast(EVENT_TYPES.STORY.PROGRESS, payload);
      this.trackedStory = null;
    });
  }

  titleCase(str) {
    if(str) {
      const arr = str.split('_');
      let res = '';
      arr.forEach(s => res = res + s.substr(0, 1).toUpperCase() + s.slice(1).toLowerCase() + ' ');
      res = res.substr(0, res.length - 1);
      return res;
    }
  }

  selectMention(user) {
    this.mentionUsers = [];
    const mentionObj =   this.sharedService.prepareMentionStr(user, this.searchText, this.atTheIndex);
    this.searchText = mentionObj.searchText;
    const obj = {start: this.atTheIndex - 1, value: mentionObj.userObj, replace: '@' +  mentionObj.userObj.name, end: this.atTheIndex + mentionObj.userObj.name.length};
    this.maintainMentions.push(obj);
    this.showMention = false;
    this.atTheIndex = null;
    this.searchText = this.searchText.substring(0, mentionObj.commentStr.length) + this.searchText.substring(mentionObj.commentStr.length + this.charCount);
    setTimeout(() => {
      this.maintainHeight.nativeElement.focus();
      this.maintainHeight.nativeElement.selectionEnd = mentionObj.commentStr.length;
      this.charCount = 0;
    }, 0);
  }

  searchMentionUser(event) {
    if (event && (event.keyCode === 38 || event.keyCode === 40 || event.keyCode === 9 || event.keyCode === 16 || event.keyCode === 13)) {
      event.preventDefault();
      return false;
    }
    if (this.searchText[event.target.selectionStart - 1] == '@') {
      this.atTheIndex = event.target.selectionStart - 1;
      this.mentionUsers = this.sharedService.searchMentionUser(this.searchText, this.atTheIndex, this.project.users);
      this.charCount = 0;
      this.showMention = true;
      if (this.searchText[event.target.selectionStart - 1] == '@' || event.keyCode === 39) {
        this.setMembersList();
      }
    } else if (this.showMention && this.searchText[this.atTheIndex] == '@' && (this.atTheIndex < (event.target.selectionStart - 1))) {
      this.charCount++;
      this.mentionUsers = this.sharedService.searchMentionUser(this.searchText, this.atTheIndex, this.project.users, this.charCount);
      this.setMembersList();
    } else {
      this.showMention = false;
      this.mentionUsers = [];
      this.charCount = 0;
      this.atTheIndex = null;
    }
    if (this.oldText && (this.oldText.length > this.searchText.length)) {
      let decrementCount = this.oldText.length - this.searchText.length;
      this.maintainMentions = this.sharedService.decrementMentionIndex(this.maintainMentions, event, decrementCount);
    }
    if ( this.oldText  &&  (this.oldText.length < this.searchText.length)) {
      let incrementCount = this.searchText.length - this.oldText.length;
      this.maintainMentions = this.sharedService.incrementMentionIndex(this.maintainMentions, event, incrementCount);
    }
    this.oldText = this.searchText;
    if (this.searchText == '') {
      this.showMention = false;
      this.removeMentionsTag();
    }
  }

  attachFilesInComment(event) {
    Array.from(event.target.files).forEach(file => {
      this.uploaderArr.push(file);
      this.preview(file);
    });
  }

  attachFilesOnly(event) {
    Array.from(event.target.files).forEach(file => {
      this.attachOnlyArr.push(file);
      this.preview(file);
    });
  }

  attachDemoVideoOnly(event) {
    const formData: FormData = new FormData();
    formData.append('demo_video[builder_feature_id]', String(this.story.epics[0].builder_feature_id))
    formData.append('demo_video[device]', this.story.storyDevicePlatformsData[0].device.toLowerCase())
    formData.append('demo_video[dev_type]', this.story.dev_type)
    formData.append('demo_video[file]', event.target.files[0])
    this.uploadVideoLoader = true
    this.storyService.addDemoVideo(this.project, this.story, formData).subscribe((res) => {
      this.customToast.messages.push({id:"demoVideo", type:"success", class: "story_new",  title: 'Demo Video', message: res.message});
      this.uploadVideoLoader = false
    }, (error) => {
      this.uploadVideoLoader = false
      this.errorService.errorFunction(error)
    })
  }

  addAttachmentToStory() {
    this.storyService.attachmentActionLoader = true;
    this.storyService.attachmentActionLoader2 = true;
    if (this.attachOnlyArr.length > 0) {
      const note = new Note({
        note: this.noNoteText,
        story_id: this.story.id,
        story: this.story,
        documents: this.attachOnlyArr
      });

      this.noteService.addStoryAttachment(this.story, note, false).subscribe((nt: Note) => {
        this.story.addNote(nt);
        this.attachOnlyArr = [];
        this.attachmentPreviewUrls = [];
        this.storyService.attachmentActionLoader  = false;
        this.storyService.attachmentActionLoader2 = false;
      }, (error) => {
        this.errorService.errorFunction(error);
        this.storyService.attachmentActionLoader  = false;
        this.storyService.attachmentActionLoader2 = false;
      });
    }
  }
  removeAttachmentDocument(item) {
    this.storyService.attachmentActionLoader  = true;
    const note = new Note({
      note: this.noNoteText,
      id: item.note_id,
      story_id: this.story.id,
      story: this.story,
      documents: this.attachOnlyArr
    });
    
    this.noteService.deleteAttachednote(this.story, note, item)
      .subscribe((nt: Note) => {
        this.story.updateNote(nt);
        this.storyService.attachmentActionLoader  = false;
      }, error => {
        this.errorService.errorFunction(error);
        this.storyService.attachmentActionLoader  = false;
      });
  }

  attachDroppedFilesInComment(files: FileHandle[]) {
    Array.from(files).forEach(item => {
      this.uploaderArr.push(item.file);
      this.preview(item.file);
    });
  }

  preview(file) {
    if (this.sharedService.checkType(file.name, true) === 'image') {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (_event) => {
        this.attachmentPreviewUrls.push(reader.result);
      };
    } else {
      this.attachmentPreviewUrls.push('/assets/images/defaullt-download.png');
    }
  }

  openAuthorPopup(item, key, index?) {
    if (item && key === 'name') {
      if (this.sharedService.checkType(item.name, true) === 'image') {
        this.openItemDoc = this.attachmentPreviewUrls[index];
        this.openAuthor = true;
      }
    } else if (item && key === 'remote_url') {
      if (this.sharedService.checkType(item.remote_url, true) === 'image') {
        this.openItemDoc = item.remote_url;
        this.openAuthor = true;
      }
    }
  }

  closeAuthorPopup() {
    this.openAuthor = false;
  }

  openVideoPopup(item, isUrl?) {
    this.showVideo = true;
    this.vURL = item;
  }

  closeVideoPopup() {
    this.showVideo = false;
    this.vURL = '';
  }

  copyStoryID() {
    if (this.story) {
      this.showCopiedID = true;
      setTimeout(() => {
        this.showCopiedID = false;
      }, 10000);
    }
  }
  
  copyStoryLink() {
    if (this.story) {
      this.showCopiedLinked = true;
      setTimeout(() => {
        this.showCopiedLinked = false;
      }, 10000);
    }
  }

  copyAcceptanceCriteriaId() {
    if (this.story) {
      this.criteriaLinkCopied = true;
      setTimeout(() => {
        this.criteriaLinkCopied = false;
      }, 10000);
    }
  }

  getSketchStatus() {
    if (this.story && this.story.sketch_status) {
      const ind = this.story.sketch_status.indexOf('_');
      const subStr = this.story.sketch_status.substring(ind + 1);
      return subStr.length > 0 ? subStr.charAt(0).toUpperCase() + subStr.slice(1) : '';
    }
    return 'Awaited';
  }

  /* Disable dropdown and change in story status depending on BB(building block) status of epic in story */
  disableStoryStatusChange(): boolean {
    const storyEpic: Epic = this.story.getEpicInStory();
    if (!this.story.isDesignStory() && !this.story.isQACore && this.project && this.project.external() && storyEpic
      && storyEpic.builder_feature_id && storyEpic.bb_status && storyEpic.bb_status !== BB_STATUS.skipped) {
      if (storyEpic.bb_status === BB_STATUS.unassigned || storyEpic.bb_status === BB_STATUS.waiting) {
        return true;
      }

      if ((this.story.core || this.story.parent_core) && (storyEpic.bb_status === BB_STATUS.ready_for_customisation ||
        storyEpic.bb_status === BB_STATUS.ongoing_customisation || storyEpic.bb_status === BB_STATUS.finished_customisation ||
        storyEpic.bb_status === BB_STATUS.finished_using_bb || storyEpic.bb_status === BB_STATUS.ready_for_certification)) {
        return true;
      }
    }
    return false;
  }

  /* Is accepting core non-design story depending on BB(building block) status of epic in story possible. */
  checkCoreStoryAcceptance(): boolean {
    const storyEpic: Epic = this.story.getEpicInStory();
    if (!this.story.isDesignStory() && !this.story.isQACore && this.project && this.project.external() && storyEpic
          && storyEpic.builder_feature_id && storyEpic.bb_status && storyEpic.bb_status !== BB_STATUS.skipped
          && (this.story.core || this.story.parent_core) && (this.story.created_from === 'tracker' || this.story.created_from === 'catalog')) {
      if (storyEpic.bb_status === BB_STATUS.assigned || storyEpic.bb_status === BB_STATUS.bb_under_development) {
        return false;
      }
    }
    return true;
  }

  // check if story_type is bug and no bug reason is selected and no related to story is selected 
  checkBugRelatedAction() {
    return (this.story.story_type === 'bug' && (this.story.bug_reason_id === null || this.story.bug_related_to_story === null));
  }

  /* depending on feature's BB status, checking creating subtask is possible or not for non-design core story. */
  canCreateSubtask(): boolean {
    if (this.story) {
      const storyEpic: Epic = this.story.getEpicInStory();
      if (!this.story.isDesignStory() && !this.story.isQACore && this.project && this.project.external() && storyEpic
        && storyEpic.builder_feature_id && storyEpic.bb_status && storyEpic.bb_status !== BB_STATUS.skipped
        && this.story.core && (storyEpic.bb_status === BB_STATUS.ready_for_customisation ||
          storyEpic.bb_status === BB_STATUS.ongoing_customisation || storyEpic.bb_status === BB_STATUS.finished_customisation ||
          storyEpic.bb_status === BB_STATUS.finished_using_bb || storyEpic.bb_status === BB_STATUS.ready_for_certification)) {
        return false;
      }
      return true;
    }
    return false;
  }

  isPlatformChecked(platformId) {
    return this.story && this.story.platform_ids && this.story.platform_ids.length > 0
              && _.includes(this.story.platform_ids, platformId);
  }

  toggleDeviceSelection(event, deviceData) {
    if (event.target.checked) {
      this.story.platform_ids = [];
      deviceData.platforms.forEach(element => {
        this.story.platform_ids.push(element.id);
      });
      this.updateStory(this.story, '', '', 'devicePlatform');
    }
  }

  toggleDevicePlatform(event, platformId, deviceData) {
    const hasValidInputs = event && event.target && platformId;
    if (!hasValidInputs) {
      return;
    }
    if (event.target.checked) {
      if (this.story.storyDevicePlatformsData.length > 0 && this.story.storyDevicePlatformsData[0].device !== deviceData.device) {
        this.story.platform_ids = [];
      }
      if (!_.includes(this.story.platform_ids, platformId)) {
        this.story.platform_ids.push(platformId);
        this.updateStory(this.story, '', '', 'devicePlatform');
      }
    } else {
      const ind = this.story.platform_ids.findIndex(item => item === platformId);
      if (ind >= 0 && !this.story.checkPlatfromCanDetach()) {
        this.story.platform_ids.splice(ind, 1);
        this.updateStory(this.story, '', '', 'devicePlatform');
      } else {
        this.openDevicePlateformpopup(platformId);
      }
    }
  }

  openDevicePlateformpopup(platformId) {
    this.detachment_options = true;
    this.story.detached_platform_id = platformId;
  }

  checkStoryCreationForPlatform() {
    this.story.detached_platform_ids.push(this.story.detached_platform_id);
    const ind = this.story.platform_ids.findIndex(item => item === this.story.detached_platform_id);
    this.story.platform_ids.splice(ind, 1);
    this.detachment_options = false;
    this.updateStory(this.story, '', 'devicePlatformDetach');
  }

  closeDevicePlateformpopup() {
    this.detachment_options = false;
    this.story.platform_detach_story_option = '';
    const ind = this.story.detached_platform_ids.findIndex(item => item.id === this.story.detached_platform_id);
    this.story.detached_platform_ids.splice(ind, 1);
    this.story.detached_platform_id = null;
  }

  selectedOptionForPlatformDetach(event, detach_story_option) {
    this.story.platform_detach_story_option = detach_story_option;
    this.story.duplicate_parent_only = detach_story_option === 'duplicate_parent_only';
    this.story.duplicate_sub_tasks = detach_story_option === 'duplicate_sub_tasks';
  }

  openConvertSubtaskPopup() {
    this.showConvertSubtaskPopup = true;
  }
  closeConvertSubtaskPopup() {
    this.showConvertSubtaskPopup = false;
  }

  // Select sub task from sub tasks list to perform promote or move
  selectSubtask(event, subTask) {
    // subtask checkbox selected
    if (event.target.checked) {
      this.selectedSubtasks.push(subTask);
    } else {
      const selectedSub = this.selectedSubtasks.findIndex(sub => sub.id === subTask.id);
      if (selectedSub >= 0) {
        this.selectedSubtasks.splice(selectedSub, 1);
      }
    }
  }

  // if selected sub task in selected, return true
  isSelectedSubtask(subTask) {
   return this.selectedSubtasks.findIndex(sub => sub.id === subTask.id) !== -1;
  }

  // In case of promote, only return valid sub tasks which does not have design phase for external project
  // In case of move, return all selected sub tasks
  // In case of internal project, all selected sub tasks are valid
  validSelectedSubtasks(valid = true) {
    if (this.project.external() && this.moveToStory === null) {
      if (valid) {
        return this.selectedSubtasks.filter(sub => sub.phase !== 'Design' && sub.created_from === 'delivery' );
      } else {
        return this.selectedSubtasks.filter(sub => sub.phase === 'Design' || sub.created_from !== 'delivery');
      }
    } else {
      return valid ? this.project.external() ? this.validateSubtasksForMove() : this.selectedSubtasks : [];
    }
  }

  validateSubtasksForMove(valid = true){
    if (this.ifNoParentTask) {
      return this.selectedSubtasks;
    }
    return valid ? this.selectedSubtasks.filter(sub => sub.created_from === 'delivery') : this.selectedSubtasks.filter(sub => sub.created_from !== 'delivery');
  }

  promotePopupMessage(){
   return this.validSelectedSubtasks(false).length === 0 ? 'Are you certain about promoting this subtask as the parent? Please bear in mind that if this subtask has any of its own subtasks, they will also be detached from the main story.' : 'You have selected ' + this.selectedSubtasks.length +' subtask(s) for promoting.';
  }

  isScrollFetchAvailable(): boolean {
    return !this.isPending && this.moreAvailable;
  }

  getMoreParentStories() {
    const nextPage = this.meta.current_page + 1;
    if (this.meta.total_count  === this.parentTasksList.length) {
      this.moreAvailable = false;
    }
    if (this.moreAvailable) {
      this.loadParentStories(nextPage);
    }
  }

  showMoveSubtaskPopup() {
    this.isPending = true;
    this.parentTasksList = [];
    this.loaderForTaskList = true;
    this.openMoveSubtask = true;
    this.loadParentStories();
  }

  loadParentStories(nextPage?, search?) {
    this.ifNoParentTask = false;
    let pageNo = 1;
    if (nextPage) {
      pageNo = nextPage;
    }
    if (this.parentStorySub) {
      this.parentStorySub.unsubscribe();
    }
    this.parentStorySub = this.storyService.sameFeaturePhaseStories(this.project.id, this.story.id, this.story.phase, pageNo, this.searchParentStories).subscribe(res => {
      this.meta = res.meta;
      res.stories.forEach(item => {
        this.parentTasksList.push(item);
      });
      if(this.parentTasksList.length === 0) {
        this.ifNoParentTask = true;
      }
      this.loaderForTaskList = false;
      this.isPending = false;
      this.loaderForTaskListSearch = false;
    }, err => {
      this.parentTasksList = [];
      this.loaderForTaskList = false;
      this.loaderForTaskListSearch = false;
      this.errorService.errorFunction(err);
    });
  }

  getSearchParentStories() {
    this.moveToStory = null;
    this.loaderForTaskListSearch = true;
    this.parentTasksList = [];
    this.loadParentStories(1, true);
    this.moreAvailable = true;
  }

  closeMoveSubtaskPopup() {
    this.moveToStory = null;
    this.searchParentStories = '';
    this.openMoveSubtask = false;
    this.parentTasksList = [];
    this.moreAvailable = true;
  }

  selectStoryForMove(event, task) {
    if (event.target.checked) {
      this.moveToStory = task.id;
    }
  }

  // Promote or Move sub task
  // In case of promote, this.moveToStory will be null
  moveSubtasksTotask(moveFor:string) {
    this.updatingSubtaskList = true;

    // check if request is for converting subtask into parent story
    if (moveFor === 'convertSubtask') {
      this.showConvertSubtaskPopup = false;
      this.moveToStory = null;
    }

    const moveStoryData = {
      story: {
        current_parent_id: this.story.id,
        new_parent_id: this.moveToStory,
        sub_task_ids: this.validSelectedSubtasks().map(s => s.id)
      }
    };
    
    this.storyService.moveSubtaskToAnotherTask(this.project.id, moveStoryData).subscribe(res => {
      // Find new parent story
      const st = this.project.findStoryByID(this.moveToStory);

      // Remove the subtask from list instantly
      this.validSelectedSubtasks().forEach(sub => {
        const removesubtaskIndex = this.story.sub_tasks.findIndex(subTask => subTask.id === sub.id);        
        const subtask = this.story.sub_tasks.splice(removesubtaskIndex, 1)[0];
        if (st && subtask) {
          st.total_tasks_count++;
          if (subtask.state === 'accepted') {
            st.done_tasks_count++;
          }
        }
        // if converting subtask into parent task, change the subtask from list instantly
        if (moveFor === 'convertSubtask') {
          const changedTotask = this.project.findStoryByID(sub.id);
          if (changedTotask) {
            changedTotask.parent_id = null;
            changedTotask.task = null;
          }
        }
      });

      // Reset the subtask list count
      this.story.refreshTaskList(this.story);
      this.updatingSubtaskList = false;
      this.selectedSubtasks = [];

      // Close the moveToStory popup
      this.closeMoveSubtaskPopup();
    }, err => {
      this.updatingSubtaskList = false;
      this.showConvertSubtaskPopup = false;
      this.closeMoveSubtaskPopup();
      this.errorService.errorFunction(err);
    });
  }

  closeArchivePopUp() {
    this.showMatchingPopup = false;
  }

  showMatching(type) {
    const segmentData = {
      '360_project_id': this.project ? this.project.external() : null,
      build_card_id: this.project ? this.project.build_card_id : null,
      phase: this.story ? this.story.phase : null,
      platform_name: this.story ? this.story.selectedDevicePlatform : null,
      project_name: this.project ? this.project.name : null,
      story_id: this.story ? this.story.id : null,
      studio_feature_id: this.story && this.story.epics.length > 0 ? this.story.epics[0]['id'] : null,
      tracker_id: this.story ? this.story.project_id : null,
      updated_by: this.userService.currentUser ? this.userService.currentUser.email : null,
      user_email: this.userService.currentUser ? this.userService.currentUser.email : null,
      user_type: this.userService.getIsUserInternalOrCp(),
      title: this.story ? this.story.title : null,
      description: this.story ? this.story.description : null,
      recommended_title: this.story.matching_story ? this.story.matching_story.title: null ,
      recommended_description: this.story.matching_story ? this.story.matching_story.description: null ,
      recommended_catalog_story_id: this.story.matching_story ? this.story.matching_story.catalog_story_id: null,
      similarity_score:  this.story.matching_story ? this.story.matching_story.similarity: null,
      similarity_difference: this.story.matching_story ? this.story.matching_story.difference: null 
    }
    if(type == 'Yes') {
      segmentData['recommendation_accepted'] = false;
    } else if(type == 'No') {
      segmentData['recommendation_accepted'] = true;
      this.archive();
    }
    this.closeArchivePopUp();
    this.userService.trackSegmentEvent('Story similarity recommendations', segmentData);
  }

  goToStory(storyId) {
    window.open(`${window.location.origin}/#/projects/${this.project.id}` + `?storyId=${storyId}`, '_blank');
  }

  getTimeformat(created_on) {
    return moment(created_on).format('MMM-Do-h:mm:ss');
  }

  downloadSketchDoc() {
    const timeStamp = moment();
    const downloadTimeStamp = this.getTimeformat(timeStamp);
    if (this.story.epics.length > 0) {
      this.storyService.getSketchFiles(this.project.id, this.story.epics[0].id).subscribe((res: any) => {
        const data = res;
        const parsedResponse = data;
        const blob = new Blob([parsedResponse], {type: 'application/zip'});
        const url = window.URL.createObjectURL(blob);

        if (navigator.msSaveOrOpenBlob) {
          navigator.msSaveBlob(blob, this.project.name + downloadTimeStamp + '.zip');
        } else {
          const a = document.createElement('a');
          a.href = url;
          a.download = this.project.name + downloadTimeStamp + '.zip';
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
        window.URL.revokeObjectURL(url);
      }, async (error) => {
        const parsedMessage = await (new Response(error)).text();
        this.errorService.errorFunctionForBlob(parsedMessage);
      });
    }
  }

  openSubtaskFilter() {
    this.showSubtaskFilter = !this.showSubtaskFilter;
  }

  // below function is used for opening extra options in three dotted dropdown
  openMoreAction() {
    this.showExtraOptions = !this.showExtraOptions;
  }


  getrelatedStories() {
    if (this.searchRelatedStory.length >= 3) {
      this.loadStoriesToRealtebug();
    } else {
      this.relateStoryList = [];
    }
  }

  loadStoriesToRealtebug() {
    this.loaderForRelateStoryList = true;
    if (this.relateStorySub) {
      this.relateStorySub.unsubscribe();
    }
    this.relateStoryList = [];
    this.relateStorySub = this.storyService.relateStories(this.project.id, this.story.id, this.searchRelatedStory).subscribe(res => {
      res.forEach(item => {
        this.relateStoryList.push(item);
      });
      this.loaderForRelateStoryList = false;
    }, err => {
      this.relateStoryList = [];
      this.loaderForRelateStoryList = false;
      this.errorService.errorFunction(err);
    });
  }

  selectRelatedStory(relateStory, selection_origin?) {
    this.enterRelatedStory = false;
    this.searchRelatedStory = '';
    this.relateStoryList = [];
    if (!this.story.testCase()) {
      if (!selection_origin) {
        this.story.related_to_story_id = relateStory.id;
        this.updateStory(this.story, '', '');
      } else {
        this.bug_related_to_story = { id: relateStory.id, title: relateStory.title };
      }
    }else{
      this.bug_related_to_story = { id: relateStory.id, title: relateStory.title };
    }
  }

  toggleCheckCertificationList(event, list_index) {
    event.target.checked ? (this.checklist_certification_options[list_index].isChecked = true) : (this.checklist_certification_options[list_index].isChecked = false);
  }
  
  getTimeFormat(created_on) {
    return moment(created_on).format('ll');
  }

  checkAvailailtyOfBBReadyCheck() {
    return this.story && this.story.show_ready_for_certification_cta;
  }

  readyForCertificationPopUp = false;

  setCertificationChecklistData(checklist_data) {
    checklist_data.forEach( data => {
      this.checklist_certification_options.push(data);
    })
  }
  
  checkBBReady(event, read_only) {
     if((!event && read_only) || (event.target && event.target.checked)) {
       this.read_only_checklist = read_only;
       this.checklist_certification_options = [];
       this.readyForCertificationPopUp = true;
       this.story.dev_type === 'FE' ? (this.setCertificationChecklistData(READY_FOR_CERTIFICATION_FE_CHECKLIST)) : (this.setCertificationChecklistData(READY_FOR_CERTIFICATION_BE_CHECKLIST)); 
       this.setCertificationChecklistData(READY_FOR_CERTIFICATION_SENSEI_CHECKLIST);
    }
  }

  checkCertificationOptionsSelected(){
    const is_all_selected = this.checklist_certification_options.findIndex(item => item.isChecked === false);
    return (is_all_selected === -1) ? true : false;
  }

  closeReadyForCertificationPopUp() {
    this.readyForCertificationPopUp = false;
    this.checklist_certification_options.forEach(item => {
      item.isChecked = false;
    });
  }

  closereadyForCertificationAction() {
    this.read_only_checklist = false;
    this.readyForCertificationPopUp = false;
  }
  
  readyForCertificationAction(type) {
     if(type === 'No') {
       this.story.ready_for_certification = false;
     } else if(type === 'Yes' && this.checkCertificationOptionsSelected()) {
      this.story.ready_for_certification = true;
      this.updateStory(this.story, 'Ready for certification updated', 'ready_for_certification');
     }
     this.closeReadyForCertificationPopUp();
  }

  checkDisaleForBBReady() {
    return !(this.story.enable_ready_for_certification && (this.projectService.current_user_pod_role === 'CTE' || this.projectService.current_user_pod_role === 'SCTE'))
  }

  checkForBBStatus(){
    // if story is Test case type
    if (this.story.testCase()) { return false; }

    const storyEpic: Epic = this.story.getEpicInStory();
    if (storyEpic && !(this.story.isDesignStory() || this.story.core || this.story.parent_core || this.story.dev_type === 'QA' || !this.project.external())) {
      if (storyEpic.bb_status === BB_STATUS.assigned || storyEpic.bb_status === BB_STATUS.ready_for_certification || storyEpic.bb_status === BB_STATUS.bb_under_development){
        return true;
      }
    }
    return false;
  }

  checkRestriction() {
    return (this.disable_story_board || this.isProjectReadonly || this.story.inactive || this.story.deleted_at )
  }

  checkAllSubtaskDelivered():boolean{
    const is_all_Delivered = this.story.sub_tasks.filter(item => item.state === 'delivered' || item.state === 'accepted').length;
    return (is_all_Delivered === this.story.sub_tasks.length) ? false : true; 
  }

  createExpectedResult(expectedResult,actual?){
    this.disable_story_board = true;
    const param = {
      "note" : expectedResult,
      "status": actual ? 'actual' : 'draft',
      "story_id":this.story.id
    };
    this.storyService.setexpectedResult(this.story.id,this.project,param).subscribe(res => {
      this.expectedResultText = '';
      this.actualResultInputField = false;
      this.expResultInputField = false;
      this.disable_story_board = false;
    }, error => {
      this.errorService.errorFunction(error);
      this.disable_story_board = false;
    }); 
  }

  deleteExpectedResult(result){
    this.disable_story_board = true;
    this.storyService.deleteExpectedResult(this.story.id,this.project,result.id).subscribe(res => {
      this.disable_story_board = false;
    }, error => {
      this.errorService.errorFunction(error);
      this.disable_story_board = false;
    });
  }

  UpdateExpectedResult(result, event){
    if(!this.story.checkTestCaseMemberPermission(this.loggedInUser)) {
      this.customToast.messages.push({
        id: 'read_only_warning',
        type: 'warning',
        class: 'generic_alert',
        title: 'Alert',
        message: 'Not authorized to perform this action.'
      });
      return;
    }
    this.disable_story_board = true;
    const param = {
      "note" : result.note,
      "status": result.status === 'pass' ? 'draft' : 'pass',
    };
    this.storyService.updateExpectedResult(this.story.id,this.project,param,result.id).subscribe(res => {
      this.disable_story_board = false;
    }, error => {
      this.errorService.errorFunction(error);
      this.disable_story_board = false;
    });  
  }

  ToggleExpResultEditMode(result,actual?) {
    this.actualResultInputField = false;
    this.expResultInputField = false;
    this.expectedResultText = result.note;
    this.story.expected_results.forEach(res => {
      res.editMode = false;
    });
    result.editMode = !result.editMode;
  }

  updateExpResultTitle(result,expectedResultText){
    if (result.note === expectedResultText) {
      return;
    }
    this.disable_story_board = true;
    const param = {
      "note" : expectedResultText
    };
    this.storyService.updateExpectedResult(this.story.id,this.project,param,result.id).subscribe(res => {
      this.story.expected_results.forEach(result => {
        result.editMode = false;
      });
      this.disable_story_board = false;
    }, error => {
      this.errorService.errorFunction(error);
      this.disable_story_board = false;
    });  
  }

  toggleExpResultInputField(actual?){
    if(!this.story.checkTestCaseMemberPermission(this.loggedInUser)) {
      this.customToast.messages.push({
        id: 'read_only_warning',
        type: 'warning',
        class: 'generic_alert',
        title: 'Alert',
        message: 'Not authorized to perform this action.'
      });
      return;
    }
    this.expectedResultText = '';
    this.story.expected_results.forEach(res => {
      res.editMode = false;
  });
  if (actual) {
    this.expResultInputField = false;
    this.actualResultInputField = !this.actualResultInputField; 
  }else{
    this.actualResultInputField = false;
    this.expResultInputField = !this.expResultInputField;
  }
  }

  enableCreateExpResultButton(expectedResult){
    if(this.disable_story_board || expectedResult.length > 0 ){
      this.enableCreateExpResult = true
    }else this.enableCreateExpResult = false;
  }

  getStoryStatesOptions(story){
    if (this.story.testCase()) {
      return TEST_STORY_STATE_OPTIONS;
    }else return STORY_STATE_OPTIONS;
  }

  getState(story){
    if (this.story.testCase()) {
    const selectedState = TEST_STORY_STATE_OPTIONS.find(state => state.type === story.state)
    return selectedState.title;
    }
    else{
      return story && story.state ? story.state.charAt(0).toUpperCase() + story.state.slice(1) : '' ;
    }
  }

  emptyExpectedResult(story){
    return story.expected_results.findIndex(result => result.status === 'pass' || result.status === 'draft') === -1;
  }

  isActualResultEmpty(story){
    return story.expected_results.findIndex(result => result.status === 'pass' || result.status === 'actual') === -1;
  }

  toggleTestCaseTextFields(fieldName){
    if(!this.project.isUserActive(this.loggedInUser)) {
      this.customToast.messages.push({
        id: 'read_only_warning',
        type: 'warning',
        class: 'generic_alert',
        title: 'Alert',
        message: 'Not authorized to perform this action.'
      });
      return;
    }
    if (fieldName === 'Pre-conditions') {
      this.isPreConditionFieldActive = true;
    }else if (fieldName === 'post-conditions') {
      this.isPostConditionFieldActive = true;
    }else if (fieldName === 'testSteps') {
      this.isTestStepsFieldActive = true;
    }else if (fieldName === 'testData') {
      this.isTestDataFieldActive = true;
    }
  }

  showCreateBugPopup(){
    this.openCreateBugPopup = true;
    this.testCaseBugTitle = '';
    this.searchRelatedStory = '';
    this.relateStoryList = [];
    if(this.story.parent_id) {
      this.bug_related_to_story = {
        id : this.story.parent_id,
        title : this.parent_story.title
      }
    }
  }

  closeCreateBugPopup(){
    this.openCreateBugPopup = false;
    this.showReasonList = false;
    this.showDetailReasonList = false;
    this.bug_reason_id = null;
    this.bug_reason_detail_id = null;
    this.bug_related_to_story = {};
    this.testCaseBugTitle = '';
    this.searchRelatedStory = '';
    this.relateStoryList = [];
    this.testCaseRejected = false;
  }

  createTestCaseBug(){
    this.waitForLoader = true;
    this.openCreateBugPopup = false;
    const testCaseData = {
    "test_case_id": this.story.id,
    "title": this.testCaseBugTitle,
    "story_type": "bug",
    "bug_reason_id": this.bug_reason_id,
    "bug_reason_detail_id": this.bug_reason_detail_id,
    "related_to_story_id" : this.bug_related_to_story.id,
    "parent_id": this.story.parent_id
  };
    this.storyService.createTestCase(testCaseData,this.project).subscribe(res=>{
    if (this.testCaseRejected) {
      let testCaseStatusData = {}
      testCaseStatusData['state'] = 'rejected';
      this.storyService.updateTestCase(testCaseStatusData,this.story.id,this.project).subscribe(res=>{
        this.testCaseRejected = false;
      }, (error) => {
        this.testCaseRejected = false;
        this.errorService.errorFunction(error);
      });
    }
      this.testCaseBugTitle = '';
      this.closeCreateBugPopup();
      this.waitForLoader = false;
    }, error => {
      this.waitForLoader = false;
      this.errorService.errorFunction(error);
    });
  }

  allTestCasePassed(){
    const index = this.story.test_cases.indexOf(test => test.test_case.state === 'rejected')
    if (index === -1) {
      return true;
    }else  return false;
  }
  checkAddedFeature(){
    if (((this.story.story_type === 'bug' && this.policyService.isQAExpert(this.project)) || this.story.testCase()) && this.story.epics.length > 0) {
      return false;
    }else return true;
  }  

  requestForReview(storyReviewId, requestType) {
    this.waitForLoader = true;
    this.storyService.requestTOReview(this.project.id, storyReviewId, requestType).subscribe(res => {
      this.storyReviewConfirmation = false
      this.customToast.messages.push({
        id: 'story_new',
        type: 'success',
        class: 'story_new',
        title: 'Request for review',
        message: res.message
      });
      this.waitForLoader = false;;
    }, err => {
      this.errorService.errorFunction(err);
    });
  }

  // below function is used for Updating CMT core story if any update avaliable at CMT
  syncCMTstory(syncStory: boolean) {
    if(this.story.catalog_story_id) {
      this.disable_story_board = true;
      this.storyService.syncCMTStory(this.story).subscribe(res => {
        this.disable_story_board = false;
        this.customToast.messages.push({
          id: 'story_new',
          type: 'success',
          class: 'story_new',
          title: 'Sync Story',
          message: 'Story synced successfully.'
        });
      }, error => {
        this.disable_story_board = false;
        this.errorService.errorFunction(error)
      });
    }
  }
  
  getTestCaseState(testCase){
    if (testCase.test_case.state === 'unstarted') {
      return 'Todo';
    }else if (testCase.test_case.state === 'started') {
      return 'In Progress';
    }else if (testCase.test_case.state === 'accepted') {
      return 'Pass';
    }else if (testCase.test_case.state === 'rejected') {
      return 'Fail';
    }
  }

  toggleSubTaskMenu() {
    this.subTaskMenuVisible = !this.subTaskMenuVisible;
  }
  openCloneConfirmation() {
    if( !this.policyService.isUserProjectAdmin && this.story.story_type !== 'bug' && this.policyService.isQAExpert(this.project)) {
      this.policyService.readOnlyWarning();
      return;
    }
    this.projectService.cloneConfirmPopup = false;
    this.renderer2.removeClass(document.body, 'duplicateStoryPopup-open');
    this.projectService.selectedStory = this.story;
    this.projectService.cloneConfirmPopup = true;
    this.renderer2.addClass(document.body, 'duplicateStoryPopup-open');
  }
  
  getSprints(replace?:boolean){
    this.clearSprintSubscription();
    this.sprintSubscription = this.projectService.getProjectSprints(this.project.id, this.projectService.sprintQueryParams).subscribe(res => {
     if (replace) {
       this.sprints_in_story = [];
       this.projectService.sprintQueryParams.page = 1;
     }
     res.data.forEach((sprintData) => {
     if (!this.sprints_in_story.find(sprint => sprint.id === sprintData.id)) {
          this.sprints_in_story.push(sprintData);
        }
     });
     this.sprintsMeta = res.meta;
    }, err => this.errorService.errorFunction(err));
  }

  getOlder() {
    if (this.sprintsMeta && this.sprintsMeta.next_page) {
      this.projectService.sprintQueryParams.page = this.sprintsMeta.next_page;
      this.getSprints();
    }
  }

  clearSprintSubscription(){
    if (this.sprintSubscription) {
      this.sprintSubscription.unsubscribe();      
    }
  }

  addSprint(id){
    this.disable_story_board = true;
    this.addSprintDropdown = false;
    this.story.sprint_id = id;
    this.updateStory(this.story, '','');

  }

  removeSprint(){
    this.disable_story_board = true;
    this.story.sprint_id = null;
    this.updateStory(this.story, '','');
  }

  isSprintScrollFetchAvailable(): boolean {
    return this.sprintsMeta && this.sprintsMeta.next_page != null;
  }

  addSprintToggle() {
    this.addSprintDropdown = !this.addSprintDropdown;
  }

  isSprintEmpty(){
    return (this.story.sprint && (Object.keys(this.story.sprint).length === 0));
  }

  showSprint(){
    return this.story && !this.story.testCase();
  }

  changeStorySprint(){
    return (this.policyService.projectAdmin(this.project) || (this.policyService.isQAExpert(this.project) && this.story.story_type === 'bug')) && !this.story.deleted_at && this.story.state !== 'accepted' && !this.story.inactive && this.story.sprint.status !== 'completed' && !(this.disable_story_board || this.isProjectReadonly);
  }

  bypassSanitization(html: string) {
    return this.domSanitizer.bypassSecurityTrustHtml(html);
  }

  getSearchSprintStories(){
    if (this.projectService.sprintQueryParams.q === '') {
      this.getSprints(true);
    }
  }

  // Open the Bulk Accept or Reject task popup
  openAcceptRejectConfirmation(type) {
    // Build the data from selected sub task to process for accept or reject
    this.projectService.acceptRejectSubtasks = [];
    this.selectedSubtasks.forEach(subTask => {
      const story = new Story (subTask);
      story.parent_id = this.story.id;
      story.project = this.project;
      story.epics[0] = this.project.epics.find(epic => epic.id === subTask.epic_ids[0]);
      this.projectService.acceptRejectSubtasks.push(story);
    });
    
    this.renderer2.addClass(document.body, 'duplicateStoryPopup-open');
    this.projectService.subtaskSelected = true;
    this.projectService.acceptRejectPopupType = type;
    this.projectService.acceptRejectPopup = true;
    if (this.projectService.acceptRejectSubtasks.length > 0) {
      if (type === 'accept') {
        this.projectService.acceptRejectSubtasks.forEach(item => {
          if(item.storyAcceptableRejectable(this.userService.currentUser,true)) {
            this.projectService.storiesCanBeAccepted.push(item);
          } else {
              this.projectService.storiesCannotBeAccepted.push(item);
            }
        });
        this.projectService.togglestoryAccept = map(this.projectService.storiesCanBeAccepted, 'id');
    } else{
        this.projectService.acceptRejectSubtasks.forEach(item => {
          if(item.storyAcceptableRejectable(this.userService.currentUser,false)) {
            this.projectService.storiesCanBeRejected.push(item);
          } else {
            this.projectService.storiesCannotBeRejected.push(item);
            }
        });
        this.projectService.togglestoryReject = map(this.projectService.storiesCanBeRejected, 'id');
      }
    } 
  }
  
  toggleSubTaskEditMode(subTask) {
    subTask.subTaskTitleEdit = !subTask.subTaskTitleEdit;
    this.subTaskTitle=subTask.title
    if(subTask.subTaskTitleEdit){
    setTimeout(() => {
      let eleRef:ElementRef=this.subTaskInputTitle.find(res=>res.nativeElement.value===subTask.title)
      eleRef.nativeElement.focus()});
    }
  }
  

  openStoryCompare() {
    this.showCompare = true;
  }

  // close story approval detail popup
  onShowCompareChange(showCompare: boolean) {
    this.showCompare = showCompare;
  }
  
  storyReviewConfirmationPopup() {
    if(this.story.reviewed_enabled_at && this.policyService.projectAdmin(this.project)) {
      this.storyReviewConfirmation = true;
    }
  }

  getFileName(url: string, extension: boolean = false) {
    if (!url) return ''
    let name = extension ? url.substring(url.lastIndexOf('/') + 1, url.length) : startCase(url.substring(url.lastIndexOf('/') + 1, url.lastIndexOf('.')))
    return name
  }

  getFeatureDescription(epicId){
    const StoryEpic =  this.project.epics.find(epic => epic.id === epicId);
      if (StoryEpic) {
        return StoryEpic.featureDetailsData.description;
      }
  }

  getChatGptResponse(){
    if (this.aiInput.trim().length > 0) {
      this.storyService.neglectOnClosure = true
      this.storyService.chatGPTLoader = true
      this.projectSyncService.acceptanceCriteriaArray = []
      const desc = (this.story.epics.length) ? this.getFeatureDescription(this.story.epics[0]?.id) : '';
      this.storyService.generateChatGptResponse(this.project, this.story, desc,this.aiInput).subscribe((res) => {
        }, (error) => {
          this.errorService.errorFunction(error)
          this.storyService.neglectOnClosure = false
          this.storyService.chatGPTLoader = false
        })
      } 
    }

  triggerDesignThreshold() {
    this.storyService.triggerUIWDesignThresholdAPI(this.project, this.story).subscribe((res) => {
      if (res.success) {
        this.customToast.messages.push({
          id: 'story_new',
          type: 'success',
          class: 'hours_approved',
          title: 'Code Processing',
          message: 'The code for the design files you uploaded is now being processed.'
        })
      } else {
        this.customToast.messages.push({ id: "serverfail", type: "fail", class: "generic_alert", title: 'Alert', message: res.message })
      }
    }, err => this.errorService.errorFunction(err))
  }
  }
