<div class="storyBoardPanel-Loader" *ngIf="startLoader">
  <img src="../../assets/images/storyBoardLoader.gif" alt=""/>
</div>
<div class="dashboard-heading">
  <span class="topTitle" *ngIf = "meta">My Buildcards ({{(meta?.total_count ? meta?.total_count: 0 )}})</span>
  <div class="searchDashBoardProject">
    <input type="search"
           class="searchDashBoardProject-input"
           placeholder="Search"
           name="search"
           autocomplete="off"
           [(ngModel)]="params.q"
           (input)="getSearchProjects()">
    <button class="searchDashBoardProject-button"><em class="iconb-search"></em></button>
  </div>
  <button type="button" class="addNewProject-button" *ngIf="isAdmin" (click)="sharedService.showprojectAdminForm = true">+ Add New Buildcard</button>
</div>
<div class="dashboardWrapper">
  <div class="projectCard-wrapper">
    <div class="noMemberFound-message" *ngIf="!this.projects?.length">
      <img src="../../../../assets/images/no-such-projects.png" alt="no project">
      <span class="notFoundText">No Buildcard found.</span>
    </div>
    <div class="scrollClass"
    infiniteScroll 
    [scrollWindow]="false"  
    [infiniteScrollDisabled]="!isScrollFetchAvailable()" 
    (scrolled)="showMore()">
      <div class="scrollClass-inner">
        <div class="projectCard" *ngFor="let project of projects;trackBy: userTrackBy, let i = index">
          <div class="projectCard-top" [routerLink]="['/projects', project.id]">
            <div class="projectLogo-wrapper" style="cursor: pointer;">
              <div class="projectLogo">
                <img src="../../assets/images/projectIcon-default.png" alt=""/>
              </div>
            </div>
            <!--<div class="minCard-top">-->
              <!--<div class="storyStatus started"></div>-->
              <!--<span class="storyState-name">Started</span>-->
            <!--</div>-->
            <div class="cpe-initials">
              {{project.primary_cpe?.user.initials | slice: 0:1}}
              <span *ngIf="project.primary_cpe?.user.name" class="elementHover">{{project.primary_cpe?.user.name}}</span>
            </div>
          </div>
          <div class="projectCard-content">
            <div class="projectCard-name" [routerLink]="['/projects', project.id]">
              <div class="projectCard-innerName">
                <span class="name">{{project.name}}</span>
                <div class="iconb-info-extra infoIcon" (mouseenter)="fetchStatsData(project,i)">
                  <div class="storyCounter-info">
                    <div class="dashboardCard-Loader" *ngIf="!project?.hasProjectStats">
                      <img src="../../assets/images/storyBoardLoader.gif" alt="" />
                    </div>
                    <ng-container  *ngIf="project?.hasProjectStats">
                    <div class="storyCounter-info-top">
                      <div class="typeLabel">Total Stories ({{project.stories_count}})</div>
                      <div class="projectCard-timer" [routerLink]="isProjectAdmin(project) && ['/projects', project.id, 'project-timing']">
                        <em class="iconb-timer"></em>
                        <span class="counterBox">{{project.recorded_time}}</span>
                      </div>
                    </div>
                    <div class="typeLabel">Story Type</div>
                    <div class="storyTypeBlock">
                      <span class="ss-type">
                        <em class="iconb-task taskIcons"></em>
                        <span class="typeCounter">{{project && project.story_type.task ? project.story_type.task:'0'}}</span>
                      </span>
                      <span class="ss-type" (click) = "openColumn('bug', project)">
                        <em class="iconb-bug bugIcons"></em>
                        <span class="typeCounter">{{project && project.story_type.bug ? project.story_type.bug : '0'}}</span>
                      </span>
                      <span class="ss-type">
                        <em class="iconb-rework reworkIcons"></em>
                        <span class="typeCounter">{{project && project.story_type.rework ? project.story_type.rework : '0'}}</span>
                      </span>
                    </div>
                    <div class="typeLabel">Story Status</div>
                    <div class="storyTypeBlock">
                        <span class="ss-type">
                          <span class="stateSelection-icon unscheduled"></span>
                          <span class="typeCounter">{{project.stories_status.unscheduled ? project.stories_status.unscheduled :'0'}}</span>
                        </span>
                      <span class="ss-type">
                          <span class="stateSelection-icon unstarted"></span>
                          <span class="typeCounter">{{project.stories_status.unstarted ? project.stories_status.unstarted : '0'}}</span>
                        </span>
                      <span class="ss-type">
                          <span class="stateSelection-icon started"></span>
                          <span class="typeCounter">{{project.stories_status.started ? project.stories_status.started:'0'}}</span>
                        </span>
                      <span class="ss-type">
                          <span class="stateSelection-icon finished"></span>
                          <span class="typeCounter">{{project.stories_status.finished ? project.stories_status.finished : '0'}}</span>
                        </span>
                      <span class="ss-type">
                          <span class="stateSelection-icon delivered"></span>
                          <span class="typeCounter">{{project.stories_status.delivered ?project.stories_status.delivered : '0'}}</span>
                        </span>
                      <span class="ss-type">
                            <span class="stateSelection-icon accepted"></span>
                            <span class="typeCounter">{{project.stories_status.accepted ? project.stories_status.accepted : '0'}}</span>
                          </span>
                      <span class="ss-type">
                            <span class="stateSelection-icon rejected"></span>
                            <span class="typeCounter">{{project.stories_status.rejected ? project.stories_status.rejected : '0'}}</span>
                        </span>
                    </div>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
            <div class="project-alias-info-wrapper" *ngIf="project?.alias_name">
              <span class="alias-tag">Alias</span>
              <p class="alias-text">{{project?.alias_name}}</p>
            </div>

            <div class="bc-action-wrapper">
              <span *ngIf="!project.favorite" class="makeFavorite iconb-star-outline" (click)="toggle_favourite(project)">
                <span class="elementHover">Add to favorites</span>
              </span>
              <span *ngIf="project.favorite" class="removeFavorite iconb-star-fill" (click)="toggle_favourite(project)">
                <span class="elementHover">Remove from favorites</span>
              </span>

              <span class="projectQuickAction-button">
                <em class="iconb-more" (click)="projectQuickActionOpen(i)"></em>
                <div class="quickAction-wrapper" [ngClass]="{'quickAction-show':showQuickAct && quickIndex == i}">
                  <div class="quickAction-listBlock">
                    <div class="quickAction-list" *ngIf="project && project.dashboard_project_id">
                      <em class="iconb-brief"></em>
                      <span class="storyStatus-quickActionsText" [routerLink]="['/projects', project.id, 'brief']">Buildcard Brief</span>
                    </div>
                    <div class="quickAction-list" *ngIf="isProjectAdmin(project)">
                      <em class="iconb-project-dashboard"></em>
                      <span class="storyStatus-quickActionsText" [routerLink]="['/projects', project.id, 'dashboard']">Buildcard Dashboard</span>
                    </div>
                    <div class="quickAction-list">
                      <em class="iconb-nstory"></em>
                      <span class="storyStatus-quickActionsText" [routerLink]="['/projects', project.id]">Stories Board</span>
                    </div>
                  </div>
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>

<div class="addNewProject-popupWrapper" *ngIf="sharedService.showprojectAdminForm">
  <div class="addNewProject-popUpBackdrop" (click)="sharedService.showprojectAdminForm = false"></div>
  <div class="addNewProject-content">
    <div class="addNewProject-top">Add New Buildcard</div>
      <admin-projects-new></admin-projects-new>
  </div>
</div>
