import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import moment from 'moment';
import { Moment } from 'moment';
import { DateRange } from '../../models/date-range';

@Component({
  selector: 'app-admin-timing-weeks-switcher',
  templateUrl: 'weeks-switcher.component.html',
  styleUrls: ['weeks-switcher.component.scss']
})
export class TimingWeeksSwitcherComponent implements OnInit {

  private dateFrom: Moment;
  private dateTo: Moment;
  private weeksDifference: number;
  public weeksSwitcherLabel: string;
  public dateRangePickerLabel: string;

  @Input()
  set dateRange(range: DateRange) {
    this.dateFrom = range.from;
    this.dateTo = range.to;
  };
  @Input() isPending: boolean;
  @Output() onDateRangeChanged: EventEmitter<DateRange> = new EventEmitter();

  constructor() {
    // This is intentional
  }

  ngOnInit(): void {
    this.updateWeeksDifference();
    this.updateWeeksSwitcherLabel();
    this.updateDateRangePickerLabel();
  }

  onWeekPickerChanged(direction: string) {
    direction === 'forward' ? this.dateFrom.add(7, 'days')
                            : this.dateFrom.subtract(7, 'days');
    this.dateTo = moment(this.dateFrom).day(+7);
    this.updateDates();
  }

  onDateRangePickerChanged(event): void {
    this.dateFrom = moment(event.jsdate).startOf('isoWeek');
    this.dateTo = moment(this.dateFrom).day(+7);
    this.updateDates();
  }

  private updateDates(): void {
    this.updateWeeksDifference();
    this.updateWeeksSwitcherLabel();
    this.updateDateRangePickerLabel();
    this.onDateRangeChanged.emit(new DateRange(this.dateFrom, this.dateTo));
  }

  private updateWeeksDifference(): void {
    const currentMonday = moment().startOf('isoWeek');
    const newMonday = this.dateFrom;
    this.weeksDifference = newMonday.diff(currentMonday, 'weeks');
  }

  private updateWeeksSwitcherLabel(): void {
    switch (this.weeksDifference) {
      case -1:
        this.weeksSwitcherLabel = 'Previous week';
        break;
      case 0:
        this.weeksSwitcherLabel = 'This week';
        break;
      case 1:
        this.weeksSwitcherLabel = 'Next week';
        break;
      default:
        const count = Math.abs(this.weeksDifference);
        const direction = this.weeksDifference < 0 ? 'ago' : 'ahead';
        this.weeksSwitcherLabel = `${count} weeks ${direction}`;
    }
  }

  private updateDateRangePickerLabel(): void {
    const from = this.dateFrom.format('DD/MM/YYYY');
    const to = this.dateTo.format('DD/MM/YYYY');
    this.dateRangePickerLabel = `${from} - ${to}`;
  }
}
