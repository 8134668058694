<div class="leave-planner-page">
  <div class="page-title">
    <span class="title">Expert Schedule</span>
    <div class="status-wrapper">
      <ul>
        <li>Status :</li>
        <li><span class="status-dot"></span>{{response.meta?.pending_leaves}} Pending</li>
        <li><span class="status-dot accepted"></span>{{response.meta?.approved_leaves}} Accepted</li>
      </ul>
    </div>
  </div>

  <div class="planner-wrapper">

    <div class="planner-title-block">
      <div class="member-title">Members ({{response.meta?.total_count}})</div>
      <div class="calendar-title">
        <ngx-slick-carousel class="carousel" #calendarMonths="slick-carousel" [config]="slideConfig">
          <div ngxSlickItem class="slide" *ngFor="let month of monthsList">
            <div class="month-year" [ngClass]="{'active': month.isActive}" (click)="updateMonthAndYear(month)">
              {{month.label}}<span class="year">{{month.year}}</span>
            </div>
          </div>
        </ngx-slick-carousel>

        <span class="slider-nav-btn prev iconb-left-arrow" (click)="calendarMonths.slickPrev()"></span>
        <span class="slider-nav-btn next iconb-left-arrow" (click)="calendarMonths.slickNext()"></span>
      </div>
    </div>

    <div class="planner-middle-block">
      <div class="member-filters">
        <div class="filters-wrapper">
          <div class="search-box">
            <em class="iconb-search"></em>
            <input type="text" class="search-input" placeholder="Search Members" [(ngModel)]="queryParams.search"
              (ngModelChange)="onSearchInputChange($event)">
          </div>
          <div class="filters-outer">
            <div class="filter-trigger" (click)="filterVisible=true">
              <em class="iconb-filter"></em>Filters
            </div>
            <div class="filter-dropdown" [@fadeInOut] *ngIf="filterVisible" clickOutside
              (clickOutside)="filterToggleClose()">
              <div class="dropdown-title">
                <div class="left-title">Filter<span class="count">({{selectedFilterCounter}})</span></div>
                <div class="right-title">
                  <span class="clear-link" (click)="resetFilter()">Clear all</span>
                  <span class="close-filter iconb-close" (click)="filterToggleClose()"></span>
                </div>
              </div>
              <div class="dropdown-body">
                <div class="accordion-wrapper">
                  <div class="accordion-outer" [ngClass]="{'expanded': accordionExpanded}">
                    <div class="accordion-title">Sort</div>
                    <div class="accordion-content">
                      <div class="content-inner">
                        <div class="checkbox-wrapper">
                          <div class="custom-checkbox" *ngFor="let type of sortTypes"
                            (click)="toggleCheck('sort_by',type)">
                            <input type="checkbox" class="hidden-input" [checked]="type.isChecked">
                            <label class="checkbox-label">{{type.label}}</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-outer" [ngClass]="{'expanded': accordionExpanded}">
                    <div class="accordion-title">Leave Type</div>
                    <div class="accordion-content">
                      <div class="content-inner">
                        <div class="checkbox-wrapper">
                          <div class="custom-checkbox" *ngFor="let type of leaveTypes"
                            (click)="toggleCheck('leave_filter',type)">
                            <input type="checkbox" class="hidden-input" [checked]="type.isChecked">
                            <label class="checkbox-label">{{type.label}}</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-outer" [ngClass]="{'expanded': accordionExpanded}">
                    <div class="accordion-title">Skill Type</div>
                    <div class="accordion-content">
                      <div class="content-inner">
                        <div class="checkbox-wrapper">
                          <div class="custom-checkbox" *ngFor="let skill of skills"
                            (click)="toggleCheck('skill_filter',skill)">
                            <input type="checkbox" class="hidden-input" [checked]="skillType.indexOf(skill) >= 0">
                            <label class="checkbox-label">{{response.meta.skills[skill]}}</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="calendar-dates">
        <div class="date-wrapper">
          <div class="date-col" *ngFor="let date of days; let i = index">
            <div class="date-inner"
              [ngClass]="{'is-weekend':date.initial === 'Sa' || date.initial === 'Su', 'active': date.date === currentYearAndMonth.currentDate}">
              <span class="day">{{date.initial}}</span>{{date.dayNumber}}
              <div class="current-date-line" [ngStyle]="{'height':120*response.data.length+8+'px'}"></div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="planner-body" infiniteScroll [scrollWindow]="false" [infiniteScrollDisabled]="false"
      (scrolled)="getMoreData()" [ngClass]="{'no-scroll': !response.data.length}">
      <div class="sprintInitial-Loader" *ngIf="isLoading">
        <img src="../../assets/images/storyBoardLoader.gif" alt="">
      </div>
      <div class="no-experts" *ngIf="!response.data.length">
        <div class="inner-content">
          <em class="icon"><img src="../../assets/images/alert-circle.svg" alt=""></em>
          <span>No Experts Available</span>
        </div>
      </div>

      <div class="planner-row" *ngFor="let user of response.data;let i = index">
        <div class="member-card" >
          <!-- Add class "has-ping" to below div if ping button is available -->
          <div class="card-outer has-ping">
            <img *ngIf="user.profile_pic.thumb" class="initial" src="{{ user.profile_pic.thumb }}"
              alt="user.name[0].toUpperCase()" />
            <div *ngIf="!user.profile_pic.thumb" class="initial">{{user.name[0].toUpperCase()}}</div>
            <button class="ping-tag" *ngIf="pingAccess()" [ngClass]="{'is-grey': (nudgeResponseIsLoading && user.id === currentPingSelected)}" [disabled]="nudgeResponseIsLoading" (click)="pingAPICall(user)"><em class="iconb-alarm-bell"></em>Ping
              <span class="elementHover">Ping Partner SPOC</span>
            </button>
            <div class="card-body">
              <div class="name">{{startCase(user.name)}}</div>
              <div class="designation"><em class="iconb-user-group"></em><span
                  class="value">{{startCase(user.skill)}}</span></div>
              <div class="email"><em class="iconb-mail-new"></em><span class="value">{{user.email}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="member-calendar">
          <div class="cal-grid-row">
            <div *ngFor="let timelineParent of user.timelines;let i=index">
              <div *ngFor="let timeline of timelineParent">
                <div class="leave-status-strip" [ngClass]="getAllocationClasses(timeline,user.timelines.length,i)"
                  *ngIf="timeline.status !== 'empty' && timeline.allocation_status"
                  [ngStyle]="{'left': (100/days.length)*startDateCal(timeline)+'%',width:getWidth(timeline)}"
                  (click)="openModal(timeline)">
                  <ng-container *ngIf="endDateCal(timeline) > 2;else templateMinor">
                    <div class="status">{{timeline.allocation_status !== 'pending'? (timeline.allocation_status === "deallocated"? "Allocation Ended":"Allocation Period"):"Pending Allocation"}}</div>
                    <div class="date">{{getDurationDate(timeline.actual_range.from,timeline.actual_range.to)}}</div>
                  </ng-container>
                  <ng-template #templateMinor>
                    <div class="status">A</div>
                    <div class="secondary-tooltip" [ngClass]="{'reverse':startDateCal(timeline)>=23}">
                      <p>{{timeline.allocation_status !== 'pending'?timeline.allocation_status === "deallocated"? 'Allocation Ended':"Allocation Period":"Pending Allocation"}}</p>
                      <p>{{getDurationDate(timeline.actual_range.from,timeline.actual_range.to)}}</p>
                    </div>
                  </ng-template>

                </div>
                <div class="leave-status-strip"
                  [ngClass]="{'mini-strip': endDateCal(timeline) <= 3,'top-pos':user.timelines.length>1 && i===0,'approved':timeline.approved}"
                  *ngIf="timeline.status !== 'empty' && !timeline.allocation_status"
                  [ngStyle]="{'left': (100/days.length)*startDateCal(timeline)+'%',width:getWidth(timeline)}">
                  <ng-container *ngIf="endDateCal(timeline) > 3;else templateEM">
                    <div class="status">{{timeline.approved?'Approved Leave':'Pending Leave'}}
                    </div>
                    <span class="date">{{getDurationDate(timeline.actual_range.from,timeline.actual_range.to)}}</span>
                  </ng-container>
                  <ng-template #templateEM>
                    <em class="iconb-check-circle"></em>
                  </ng-template>
                  <div class="detail-wrapper" [ngClass]="{'reverse': startDateCal(timeline)>=20}">
                    <div class="top-info">
                      <div class="name-status">
                        <div class="name-block">
                          <img *ngIf="user.profile_pic.thumb" class="initial" src="{{ user.profile_pic.thumb }}"
                            alt="loader" />
                          <span *ngIf="!user.profile_pic.thumb" class="initial">{{user.name[0].toUpperCase()}}</span>
                          <span class="name">{{startCase(user.name)}}</span>
                        </div>
                        <div class="status-block">
                          <span class="status-dot" [ngClass]="{'approved':timeline.approved}"></span>
                          <span class="status-label">{{timeline.approved?'Approved':'Pending'}}</span>
                        </div>
                      </div>
                      <div class="date-info">
                        <em class="iconb-calendar"></em>
                        <span class="dates">{{getDurationDate(timeline.actual_range.from,timeline.actual_range.to)}}</span>
                      </div>
                    </div>
                    <div class="desc-block">
                      <div class="desc-inner">
                        <em class="iconb-notes"></em>
                        <p [innerHTML]="bypassSanitization(timeline.comment)"></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="cal-grid-col" *ngFor="let in of loopRows(days.length) ;let i = index">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- Allocation detail sidebar -->
<ng-container *ngIf="allocationDetailVisible">
  <button type="button" class="close-allocationPopup" (click)="toggleAllocationDetail()">
    <em class="iconb-close"></em>
  </button>
  <div class="allocation-detail-sidebar">
    <h2>{{currentlyAllocatedData.allocation_status==='pending'?'Pending Allocation':currentlyAllocatedData.allocation_status==='deallocated'?"Allocation Ended":"Allocation"}}</h2>
    <div class="allocation-outer">
      <em class="icon"><img src="../../assets/images/projectIcon-default.png" alt=""></em>
      <div class="title">
        <h3>Buildcard Name</h3>
      </div>
      <div class="content">{{startCase(currentlyAllocatedData.project.name)}}</div>
    </div>
    <!-- <div class="allocation-outer">
      <em class="icon initial">D</em>
      <img src="https://s3.ap-south-1.amazonaws.com/staging.e.ai/uploads/developer/profile_photo/65491/thumb_Man_Image.png" class="icon initial" alt="">
      <div class="title">
        <h3>Expert</h3>
      </div>
      <div class="content">
        <div class="expert-details">
            <div class="name">Dev 82</div>
            <div class="designation"><em class="iconb-user-group"></em><span class="value">Rails/ROR</span></div>
            <div class="email"><span class="value">dev8282@yopmail.com</span>
          </div>
        </div>
      </div>
    </div> -->
    <div class="allocation-outer">
      <em class="icon iconb-calendar"></em>
      <div class="title">
        <h3>Duration</h3>
      </div>
      <div class="content">
        {{getDurationDate(currentlyAllocatedData.actual_range.from,currentlyAllocatedData.actual_range.to)}}</div>
    </div>
    <div class="allocation-outer">
      <em class="icon iconb-file-folder"></em>
      <div class="title">
        <h3>Buildcard Brief</h3>
      </div>
      <div class="content" [innerHTML]="bypassSanitization(currentlyAllocatedData.project.description)"></div>
    </div>
    <div class="allocation-outer">
      <em class="icon iconb-activity"></em>
      <div class="title">
        <h3>Feature list</h3>
        <span class="btn btn-bordered" (click)="toggleFeatureDescPopup()">More Info</span>
      </div>
      <div class="content">
        <ul>
          <li *ngFor="let feature of currentlyAllocatedData.features">{{startCase(feature.name)}}</li>
        </ul>
      </div>
    </div>
  </div>
</ng-container>

<div class="epicStoryDes-popup leavePlanner-feature-popup" *ngIf="featureDescPopupVisible">
  <div class="epicStoryDes-backdrop"></div>
  <div class="epicStoryDes-popupWrapper">
    <em class="iconb-close closeEpicStoryPopup" (click)="toggleFeatureDescPopupClose()"></em>
    <div class="epicTitle">Features Description</div>
    <div class="feature-wrapper">
      <div class="feature-outer" *ngFor="let feature of currentlyAllocatedData.features">
        <h3>{{startCase(feature.name)}}</h3>
        <div class="content" [innerHTML]="bypassSanitization(feature.note)">

        </div>
      </div>
    </div>
  </div>
</div>