import { Injectable } from '@angular/core';
import { Project } from '../models/project';
import { Observable, Observer } from 'rxjs';
import { SharedService } from '../../../shared/services/shared.service';
import { HttpRequests } from '../../../shared/services/http-requests.service';
import { DataService } from '../../../shared/services/data.service';

export interface IProjectsList {
  projects: Project[];
  currentPage?: number;
  pages?: number;
  inProcess?: number;
  totalTime?: string;
}

@Injectable()
export class TimingProjectsService {

  private apiUrl = 'admin/timing/projects';

  constructor(
    private sharedService: SharedService,
    private httpRequest: HttpRequests,
    private dataService: DataService) { }

  getProjects(filter?: Object): Observable<IProjectsList> {
    let url = this.apiUrl;
    if (filter) {
      url = this.sharedService.parseQueryParams(this.apiUrl, filter);
    }

    const API = this.dataService.apiUrl + url;
    return Observable.create((observer: Observer<IProjectsList>) => {
      this.httpRequest
      .get(API, this.dataService.getRequestOptionArgs())
        .subscribe(
          response => {
            const projectsList: IProjectsList = {
              projects: this.parseResponse(response).projects.map(x => new Project(x)),
              currentPage: this.parseResponse(response).current_page,
              pages: this.parseResponse(response).pages,
              inProcess: this.parseResponse(response).in_process,
              totalTime: this.parseResponse(response).total_time
            };
            observer.next(projectsList);
            observer.complete();
          }
        );
      }
    );
  }

  public parseResponse(res: any): any {
    return res;
  }
}
