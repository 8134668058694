<div class="storyBoardPanel-Loader" *ngIf="startLoader">
  <img src="../../assets/images/storyBoardLoader.gif" alt=""/>
</div>
<div class="membersDashboard-wrapper">
  <div class="topSection">
    <div class="topHeading">Members ({{meta?.total_count}})</div>
    <div class="searchMember">
      <input type="search"
             class="searchMember-input"
             placeholder="Search"
             name="search"
             autocomplete="off"
             [(ngModel)]="params.q"
             (input)="getSearchRecords()">
      <button class="searchMember-button"><em class="iconb-search"></em></button>
    </div>
    <span class="createNewMember" (click)="sharedService.showUserForm = true && usersService.isNewUserAdded = true">+ Add Member</span>
  </div>

  <div class="noMemberFound-message" *ngIf="this.params.q && !this.users.length">
    <img src="../../../../assets/images/no-member-found.png" alt="no member">
    <span class="notFoundText"><strong> No member found </strong></span>
  </div>

  <div class="allMemberList-wrqapper" *ngIf="this.users?.length">
    <div class="allMemberList-topHead">
      <div class="nameBlock">Name</div>
      <div class="projectBlock">Buildcards</div>
      <div class="timeLogBlock">Time Logged</div>
      <div class="lastTimeActiveBlock">Last Time Active</div>
      <div class="actBlock"></div>
    </div>
    <div class="allMemberList-content">
      <div class="allMemberList-listing" *ngFor="let user of users">
        <div class="nameBlock">
          <div class="userInitials">{{ user.initials ? (user.initials | slice: 0:1) : '' }}</div>
          <div class="nameWithInitials" [routerLink]="user.id">{{user.name ? (user.name | titlecase) : user.email}}</div>
        </div>
        <div class="projectBlock">
          {{user.project_count}} Total Buildcards
          <div   class="iconb-info-extra" *ngIf="user.project_count > 0">
            <div class="showProjectList-wrapper">
              <div class="projectListing" *ngFor="let projectName of user.project_names">
                <span class="projectIcon"><img src="../../../assets/images/projectIcon-default.png" alt=""></span>
                <span class="projectName">{{projectName}}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="timeLogBlock"><em class="iconb-clock"></em> {{user.usage}}</div>
        <div class="lastTimeActiveBlock"><em class="iconb-clock" *ngIf="user.last_time_active"></em> {{user.last_time_active ? sharedService.formattTime(user.last_time_active) : '-'}}</div>
        <div class="actBlock" *ngIf="user.editable">
          <em class="iconb-more" [ngClass]="{'selected': user.openMemberAction}" (click)="toggleMemberAction(user)"></em>
          <div class="actBlock-DD" *ngIf="user.openMemberAction" [ngClass]="{'actBlock-DDopen': user.openMemberAction}">
            <div class="actBlock-list" (click)="editMember(user)"><em class="iconb-edit" (click)="editMember(user)"></em> Edit Member</div>
            <div class="actBlock-list" (click)="openUserDeletePopup(user)"><em class="iconb-bin" (click)="openUserDeletePopup(user)"></em> Delete Member</div>
          </div>
        </div>
      </div>
      <div class="paginationWrapper">
        <ul class="pagination">
          <li [class.disabled]="!previousPageAvailable()">
            <span (click)="previousPage()">&laquo;</span>
          </li>
          <li *ngFor="let page of pages" [class.active]="page == params.page">
            <span (click)="goToPage(page)">{{page}}</span>
          </li>
          <li [class.disabled]="!nextPageAvailable()">
            <span (click)="nextPage()">&raquo;</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

<app-custom-popup   [texts] = "{ptitle: 'Delete', ptext: 'Are you sure you want to delete?', psuccess: 'Delete', pcancel: 'Cancel'}" (closeCustomPopUp) = "closeUserDeletePopUp()" (deleteElement) = "deleteUser()" [delement] = "elem" [OpenPopUp] = "OpenPopUp" [waitForLoader] = "waitForLoader"></app-custom-popup>

  <!--<div class="allMemberList-wrqapper" *ngIf="this.users?.length">-->
    <!--<div class="allMemberList-topHead">-->
      <!--<div class="nameBlock">Name</div>-->
      <!--<div class="roleBlock">Role</div>-->
      <!--<div class="createdBlock">Created On</div>-->
      <!--<div class="projectBlock">No of Projects</div>-->
      <!--<div class="hrsBlock">Total Time Recorded</div>-->
      <!--<div class="totalEstimationTimeBlock">Total Time Estimation </div>-->
      <!--<div class="requesterBlock">Requestor count</div>-->
      <!--<div class="ownerBlock">Owner count</div>-->
      <!--<div class="reviewerBlock">Reviewer count</div>-->
      <!--<div class="commitCountBlock">Commit Count</div>-->
    <!--</div>-->
    <!--<div class="allMemberList-content">-->
      <!--<div class="allMemberList-listing" *ngFor="let user of users">-->
        <!--<div class="nameBlock">-->
          <!--<div class="nameWithInitials" [routerLink]="user.id">{{user.name}} {{user.initials?'('+user.initials+')':'' }}</div>-->
          <!--<div class="memEmail">{{user.email}}</div>-->
        <!--</div>-->
        <!--<div class="roleBlock">{{user.role.charAt(0).toUpperCase() + user.role.slice(1)}}</div>-->
        <!--<div class="createdBlock">{{user.created_on}}</div>-->
        <!--<div class="projectBlock">{{user.project_count}}</div>-->
        <!--<div class="hrsBlock">{{user.usage}}</div>-->
        <!--<div class="totalEstimationTimeBlock">{{user.total_time_estimate}}</div>-->
        <!--<div class="requesterBlock">{{user.requestor_count}}</div>-->
        <!--<div class="ownerBlock">{{user.owner_count}}</div>-->
        <!--<div class="reviewerBlock">{{user.reviewer_count}}</div>-->
        <!--<div class="commitCountBlock">{{user.commit_count}}</div>-->
      <!--</div>-->
      <!--<div class="paginationWrapper">-->
        <!--<ul class="pagination">-->
          <!--<li [class.disabled]="!previousPageAvailable()">-->
            <!--<span (click)="previousPage()">&laquo;</span>-->
          <!--</li>-->
          <!--<li *ngFor="let page of pages" [class.active]="page == params.page">-->
            <!--<span (click)="goToPage(page)">{{page}}</span>-->
          <!--</li>-->
          <!--<li [class.disabled]="!nextPageAvailable()">-->
            <!--<span (click)="nextPage()">&raquo;</span>-->
          <!--</li>-->
        <!--</ul>-->
      <!--</div>-->
    <!--</div>-->
  <!--</div>-->
<!--</div>-->
