<div class="session-modal-wrapper">
  <div class="row session-modal-header">
    <div class="col-sm-9 name">
      <h3>{{user.name}}</h3>
    </div>
    <div class="col-sm-3 date">
      <h5>{{options.date}}</h5>
    </div>
  </div>

  <div class="row session-modal-controllers">
    <div class="col-sm-6">
      <div class="form-group">
        <label>Name of project:</label>
        <select class="form-control"
                [(ngModel)]="selectedProject"
                (change)="onChangeProject()">
          <option *ngFor="let project of projects"
                  [selected]="project.id === selectedProject.id"
                  [ngValue]="project">
            {{project.name}}
          </option>
        </select>
      </div>
    </div>

    <div class="col-sm-6">
      <div class="form-group">
        <label>Story:</label>
        <select class="form-control"
          [(ngModel)]="selectedStory"
          (change)="onChangeStory()">
          <option *ngFor="let story of selectedProject.stories"
                  [selected]="story.id === selectedStory.id"
                  [ngValue]="story">
            {{story.name}}
          </option>
        </select>
      </div>
    </div>
  </div>

  <div class="row session-modal-content">
    <div class="col-sm-12">
      <div class="table-wrapper table-timing">
        <div class="loader" *ngIf="isPending">
          <span class="fa fa-spin fa-refresh"></span>
        </div>
        <table role="presentation">
          <thead>
            <tr>
              <td class="name">Session</td>
              <td class="description">Description</td>
              <td class="time">Time</td>
            </tr>
          </thead>
        </table>
        <div class="sessions-list-wrapper">
          <table role="presentation">
            <tbody>
              <tr *ngFor="let session of sessions">
                <td class="name">{{session.name}}</td>
                <td class="description">
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div class="row session-modal-footer">
    <div class="col-sm-12">
      <button class="btn btn-primary" (click)="updateSessions()">Update</button>
      <button class="btn btn-default" (click)="closeDialog('canceled')">Cancel</button>
    </div>
  </div>
</div>
