import { Pipe, PipeTransform } from '@angular/core';
import {Note} from '../models/note';
@Pipe({
  name: 'notesSort',
  pure: false
})

export class NotesSortPipe implements PipeTransform {

  transform(items: any[], sortText: string, type?: string): any[] {
    if (!items) return [];
    if (!sortText) return items;

    if (sortText === 'New to Old') {
      return items.sort((a: any, b: any) => {
        if (a[type] > b[type]) {
          return -1;
        }
        if (a[type] < b[type]) {
          return 1;
        }
        return 0;
      });
    } else if (sortText === 'Old to New') {
      return items.sort((a: any, b: any) => {
        if (a[type] < b[type]) {
          return -1;
        }
        if (a[type] > b[type]) {
          return 1;
        }
        return 0;
      });
    }
  }
}
