<div class="projectsDashboard-wrapper" *ngIf="project">
  <div class="storyBoardPanel-Loader" *ngIf="dashboardLoader">
    <img src="../../assets/images/storyBoardLoader.gif" alt="loader"/>
  </div>
  <div class="projectDetail-wrapper">
    <div class="topInfo-section">
      <div class="aboutProject">
        <div class="aboutProjec-name">
          <span class="aboutProject-title" (click)="openProjectList()"><span class="projTitle">{{ project.name }}</span><em class="iconb-dd-down"></em></span>
          <div class="projectList-dd" [ngClass]="{'openList': showProjectList}">
            <div class="makeSearch">
              <input type="text" (input)="getSearchProjects()" [(ngModel)]="searchProject" class="makesearch-project" placeholder="Search Buildcard by name" />
            </div>
            <div class="common-Loader projectList-Loader" *ngIf="startLoader && moreAvailable">
              <img src="../../assets/images/storyBoardLoader.gif" alt="loader"/>
            </div>
            <div class="projectList-block"
                  infiniteScroll
                  [scrollWindow]="false"
                  [infiniteScrollDisabled]="!isScrollFetchAvailable()"
                  (scrolled)="getOlderProject()">
              <div class="projectSelection-list"  *ngFor="let pr of projects">
                <a class="projectListing" [routerLink]="['/projects', pr.id, 'dashboard']" (click)="closeProjectList()">
                  <span class="projectIcon"><img src="../../assets/images/projectIcon-default.png" alt="loader"> </span>
                  <span class="projectName">{{pr.name}}</span>
                </a>
              </div>
              <div class="noSuchProject" *ngIf = "projects.length == 0">
                <div class="noSuchProjectInner">
                  <img src="../../assets/images/no_Project_icon.png" alt="loader"/>
                  <p>No such Buildcard</p>
                </div>
              </div>
              <div class="no-notifications" *ngIf = "!moreAvailable">No more Buildcard.</div>
            </div>
          </div>
        </div>
        <span class="aboutProject-edit" *ngIf="project.internal" (click)="sharedService.showprojectAdminForm = true">
          <em class="iconb-edit"></em>
          <span class="elementHover">Edit Buildcard</span>
        </span>

        <span class="expandCollapse-topBlock" [ngClass]="{'makeup':collapse_expand_project_info}" (click)="shrinkProjectInfo()">
          <em class="iconb-dd-down"></em>
          <span class="elementHover-extra">{{ collapse_expand_project_info ? 'Collapse' : 'Expand' }}</span>
        </span>
      </div>
      <ng-container *ngIf="collapse_expand_project_info">
        <div class="cpeDetails-wrapper" *ngIf="project.checkIFProjectExternal">
          <div class="cpeDetails" *ngIf="project.cpe_initials || project.cpe_name">
            <span class="cpe-initials" *ngIf="project.cpe_initials">{{ project.cpe_initials.substr(0,1) }}</span>
            <span class="cpe-Name" *ngIf="project.cpe_name">{{project.cpe_name | titlecase}} (Productologist)</span>
          </div>
          <div class="squadDetails" *ngIf="project && project.dashboard_project_id && project.squad_name">
           {{project.squad_name}}
          </div>
          <div class="viewAll-listWrapper">
            <div class="usersInitals-wrapper" *ngIf="project?.primary_managers?.length > 0">
              <div class="userInitials-block" (click)="showPrimaryManagerList()">
                <div class="usersInitials" *ngFor="let member of project.primary_managers">
                  {{ member?.initials?.substr(0,1) }}
                  <span class="elementHover">{{member.name ? (member.name | titlecase) : member.email}}</span>
                </div>
                <div class="usersInitials" *ngIf="project.primary_managers.length > 3">+{{project.primary_managers.length - 3}}</div>
              </div>
            </div>
            <div class="primaryUserListDD" [@fadeInOut] *ngIf="primaryMemberList">
              <div class="userList-infoWrapper" *ngFor="let member of project.primary_managers">
                <span class="userList-initials">{{ member?.initials?.substr(0,1) }}</span>
                <div class="userList-nameWrapper">
                  <span class="userList-name">{{member?.name ? (member?.name | titlecase) : member?.email}}</span>
                  <span class="userList-pod">{{member?.pod_role}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="invoiceCycle-wrapper">
          <div class="invoiceSelection" (click)="openInvoiceCycles()">Invoice Cycle [{{selected_invoice_cycle}}]<em class="iconb-calendar iconCal"></em> </div>
          <div class="invoiceSelection-wrapper" [@fadeInOut] *ngIf="show_invoice_cycle">
            <div class="search-cycle">
              <input type="text"  class="search-cycle-searchInput" [(ngModel)]= "searchCycle" placeholder="Search Invoice Cycle"/>
            </div>
            <div class="invoice-list-wrapper">
              <div class="noCyle_found" *ngIf="(invoice_cycles | search: searchCycle).length === 0">No Invoice Cycle </div>
              <div class="invoice-list" [ngClass]="{'selected' : (invoice === selected_invoice_cycle)}" *ngFor="let invoice of invoice_cycles | search: searchCycle" (click)="previousInvoiceActivity(invoice)">{{invoice}}</div>
            </div>
            <!-- <div class="actionBt-wrapper">
              <button type="button" class="actionButton">Save</button>
              <button type="button" class="actionButton clear-cycle">Clear</button>
            </div> -->
          </div>
        </div>
        <div class="stories_summary">
          <div class="stories_summary_title" (click)="openProjectStoriesSummary()">Summary 
            <em class="stories_summary_arrow iconb-dd-down" [ngClass]="{'makeup':showProjectStoriesSummary}"></em> 
          </div>
          <div class="stories_summary_wrapper" [@fadeInOut] *ngIf="showProjectStoriesSummary">
            <div class="top_block">
              <span class="top_block_title">Status</span>
              <span class="top_block_title">Stage</span>
            </div>
            <div class="detail_block">
              <div class="detail_block_left">
                <div class="totalStories">
                  <div class="totalStories-types">
                    <div class="typeList-block">
                      <span class="typeList-name">Unscheduled</span>
                      <span class="makeCount unscheduled">{{project.stories_status.unscheduled ? project.stories_status.unscheduled :'0'}}</span>
                    </div>
                    <div class="typeList-block">
                      <span class="typeList-name">Unstarted</span>
                      <span class="makeCount unstarted">{{project.stories_status.unstarted ? project.stories_status.unstarted : '0'}}</span>
                    </div>
                    <div class="typeList-block">
                      <span class="typeList-name">Started</span>
                      <span class="makeCount started">{{project.stories_status.started ? project.stories_status.started:'0'}}</span>
                    </div>
                    <div class="typeList-block">
                      <span class="typeList-name">Finished</span>
                      <span class="makeCount finished">{{project.stories_status.finished ? project.stories_status.finished : '0'}}</span>
                    </div>
                    <div class="typeList-block">
                      <span class="typeList-name">Delivered</span>
                      <span class="makeCount delivered">{{project.stories_status.delivered ?project.stories_status.delivered : '0'}}</span>
                    </div>
                    <div class="typeList-block">
                      <span class="typeList-name">Accepted</span>
                      <span class="makeCount accepted">{{project.stories_status.accepted ? project.stories_status.accepted : '0'}}</span>
                    </div>
                    <div class="typeList-block">
                      <span class="typeList-name">Rejected</span>
                      <span class="makeCount rejected">{{project.stories_status.rejected ? project.stories_status.rejected : '0'}}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="detail_block_right">
                <div class="totalStories">
                  <div class="totalStories-types">
                    <div class="typeList-block">
                      <em class="iconb-task"></em>
                      <span class="typeList-name">Task</span>
                      <span class="makeCount2">{{project.story_type.task ? project.story_type.task:'0'}}</span>
                    </div>
                    <div class="typeList-block">
                      <em class="iconb-bug"></em>
                      <span class="typeList-name">Bug</span>
                      <span class="makeCount2">{{project.story_type.bug ? project.story_type.bug : '0'}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>  
    </div>
    <div class="totalTimeInfo-wrapper" *ngIf="collapse_expand_project_info">
      <div class="totalTimeInfo-block">
        <span class="totalTimeInfo-title">Builder Tracker Recorded Time</span>
        <div class="timerCounter">{{project.total_tracked_time}}</div>
      </div>
      <div class="totalTimeInfo-block">
        <span class="totalTimeInfo-title">Total Manager Approved Hours </span>
        <div class="timerCounter">{{project.total_approved_time}}</div>
      </div>
    </div>
  </div>

  <div class="addProjectMember-top">
    <div class="left">
      <div class="maintitle">
        <span class="title-section">Members ({{(project.memberships | membersearch: searchStr).length}})</span> 
        <div class="member_filter_wrapper">
          <div class="filter_selection" (click)="openMemberFilter()">
            <em class="iconb-filter filter-icon"></em> {{ filtersCount }} {{ filtersCount >= 2 ? 'Filters' : 'Filter' }}
          </div>
          <button type="button" class="resetFilter-button" (click)="clearAllFilters(false)" [disabled]="filtersCount == 0">Clear all</button>
          <div class="membership_filter_block" [@fadeInOut] *ngIf="show_member_filter_options">
            <div class="membership_filter_list" *ngFor="let member_option of memberFilterOptions">{{ member_option.name }} <em class="iconb-right"></em>
              <span class="memberFilterOptions">
                <span class="member_filter_option_list" *ngFor="let option of member_option.options">
                  <label class="customCheck_default">
                    <input type="checkbox" (change)="changeOptionState($event, member_option.term, option.value)" [checked]="isOptionChecked(member_option.term, option.value)" class="selected-checkbox" hidden/>
                    <span class="check-toggle"></span>
                  </label>
                  {{ option.title }}
                </span> 
              </span> 
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="right">
      <span *ngIf="canCreate" class="addNewMember-link" (click)="clickAddMember()">+ Add Member</span>
      <div class="addNewMember">
        <input type="text" (input)="searchUser()" class="addNewMember-searchInput" placeholder="Search by Name or Email" [(ngModel)]= "searchStr"/>
        <button type="button" class="addNewMember-searchButton">
          <em class="iconb-search"></em>
        </button>
      </div>
    </div>
  </div>
  <div class="addProjectMember-table">
    <div class="noSearchResult-member" *ngIf = "!searched_user_loader && (project.memberships | membersearch: searchStr).length == 0 && searchUsers.length == 0">
      <img src="../../assets/images/no-member-found.png" alt="no member found"/>
      No members found
    </div>
    <div class="tableBlocks-top" *ngIf = "showHtml && (project.memberships | membersearch: searchStr).length > 0">
      <div class="tableBlocks-name">Name</div>
      <div class="tableBlocks-duration">Duration</div>
      <div class="tableBlocks-totalTrackedHours">
        Total Tracked Hrs
        <div class="iconb-info-extra">
          <div class="timerHover">
            <span class="timer_type"><em class="iconb-task"></em> Task Hrs</span>
            <span class="timer_type"><em class="iconb-bug"></em> Bug Hrs</span>
            <span class="timer_type"><em class="iconb-hrs"></em> Total Hrs</span>
          </div>
        </div>
      </div>
      <div class="tableBlocks-IDETrackedHours">IDE Tracked Hrs</div>
      <div class="tableBlocks-workingdHours">Working Hrs
        <em class="iconb-info-extra">
          <span class="elementHover">Working hours are the approximate hours that<br/> this expert must have worked for.</span>
        </em>
      </div>
      <div class="tableBlocks-ApprovedHours">Manager Approved Hrs <span class="approved-by">Approved By</span></div>
    </div>
    <div class="makeFullLoader invoice_cycle" *ngIf="invoice_cycle_loader && !dashboardLoader">
      <img src="../../assets/images/storyBoardLoader.gif" alt="loader"/>
    </div>
    <div class="tableBlocks-listingWrapper" [ngClass]="{'re_adjust_height': !collapse_expand_project_info}" >
      <div class="tableBlocks-listing"  
        [ngClass]="{'ifUserDeactivated': (membership.user?.deleted_at || !membership.active)}" 
        [class.ifRowSelected]="membership.open_more_options" 
        *ngFor="let membership of project.memberships | membersearch: searchStr">
        <div class="tableBlocks-container" [ngClass]="{'userHovered':membership.userListHovered}">
          <div class="tableBlocks-name">
            <div class="expand-user-block" [ngClass]="{'makeup' : membership.open_more_options}" (click)="showMoreOptions(membership)"><em class="iconb-dd-down-fill"></em></div>
            <div class="userInfo-wrapper">
              <span class="userName">
                <span class="userName-content">
                  <span class="userName-title">{{membership.user?.name}}</span>
                  <span class="userName-skill" *ngIf="membership.user.skill && !membership.pod_role">[{{membership.user?.skill}}]</span>
                  <span class="userName-pod-role" *ngIf="membership.pod_role">[{{checkMemberPodRole(membership)}}]</span>
                </span>
                <span class="elementHover" *ngIf="membership.last_time_active">Last time active: <span class="nextLine">{{ membership.last_time_active }}</span></span>
                
                <span class="userName-content2">
                  <span class="userName-email">{{membership.user?.email}} </span> 
                  <em class="iconb-copyid" (click)="copyUserEmail()" [clip]="membership.user?.email" (mouseover)="showCopiedEmail=false">
                    <span class="elementHover">{{showCopiedEmail ? 'Copied' : 'Copy Email'}}</span>
                  </em>
                </span>
              </span>
              <div class="user-role" *ngIf="!canUpdate || (canUpdate && !membership?.editable || membership?.user?.deleted_at || !membership?.active)">
                <span class="selectedRole-cannotEdit">{{ membership?.role?.replace('_', ' ') }}</span>
              </div>
              <div class="user-role-DD" *ngIf="canUpdate && membership?.editable && !membership?.user?.deleted_at && membership?.active" >
                <span class="selectedRole" (click)="openSelectRoleDD(membership)">{{ membership?.role?.replace('_', ' ') }} <em class="iconb-dd-down"></em></span>
                <div class="selectRole-dd" *ngIf="membership.openSelectDD">
                <span class="selectRole-list" *ngFor="let role of roleList" (click)="updateRole(role, membership)">
                  {{role.replace('_', ' ')}}
                  </span>
                </div>
              </div>
              <div class="user-status" *ngIf='(membership?.user?.deleted_at || !membership?.active)'>
                <span class="ifInvitationIsNotAccepted"><em class="iconb-blocker"></em> User Account {{membership.user.deleted_at ? 'Deactivated' : 'Deallocated'}} </span>
              </div>
              <div class="user-status" *ngIf='(!membership?.user?.deleted_at && membership?.active)'>
                <span class="ifInvitationIsNotAccepted" *ngIf='!membership?.user?.is_confirmed'><em class="iconb-side-effect"></em> Invitation Unaccepted</span>
                <button type="button" class="reinviteButton" *ngIf = "!membership.user?.is_confirmed" (click)='resendInvite(membership)'>Resend</button>
              </div>
            </div>
          </div>
          <div class="tableBlocks-duration">
            <!-- Feb 15, 2020 - Mar 14, 2020 -->
            {{membership.invoice_activity?.invoice_activity?.start_date}} - {{membership.invoice_activity?.invoice_activity?.end_date}}
          </div>
          <div class="tableBlocks-totalTrackedHours">
            <span class="totalTrackedHours-breakup"><span class="iconb-task iconForType"></span> {{taskhours(membership.invoice_activity?.invoice_activity?.total_elapsed_time, membership.invoice_activity?.invoice_activity?.bug_elapsed_time)}}</span>
            <span class="totalTrackedHours-breakup"><span class="iconb-bug iconForType"></span> {{secsToDuration(membership.invoice_activity?.invoice_activity?.bug_elapsed_time)}}</span>
            <span class="totalTrackedHours-breakup"><span class="iconb-hrs iconForType"></span>  {{secsToDuration(membership.invoice_activity?.invoice_activity?.total_elapsed_time)}}</span> 
          </div>
          <div class="tableBlocks-IDETrackedHours">{{membership.invoice_activity?.invoice_activity?.ide_elapsed_time ? secsToDuration(membership.invoice_activity.invoice_activity.ide_elapsed_time) : '00:00:00'}}</div>
          <div class="tableBlocks-workingdHours">
            {{membership.working_hour ? membership.working_hour : '-'}}
          </div>
          <!--Approve hours block starts here-->
          <div class="tableBlocks-ApprovedHours" *ngIf="membership.invoice_activity">
            <div class="cpe-approved">
              <!--Normal Scenario for showing Working Hours Starts-->
              <div class="ifTimeEntered">
                <div class="enterHours"  *ngIf="membership.invoice_activity?.invoice_activity?.approved_at === null && membership.invoice_activity?.invoice_activity?.approved_time == null && partner?.code === 'e-ai'">
                  <input type="number" (keypress)="sharedService.restrictNumeric($event)" [disabled] = "(membership.invoice_activity?.invoice_activity?.approved_at || membership.invoice_activity?.invoice_activity?.builder_hive_id == null || raise_concern_allowed)" (input) = "changeApprovedHours(membership.invoice_activity.invoice_activity)" [(ngModel)] = "hours[membership.invoice_activity?.invoice_activity?.id]" class="inputForHour" placeholder="00">
                  <span class="spacer">:</span>
                  <input type="number" (keypress)="sharedService.restrictNumeric($event)" [disabled] = "(membership.invoice_activity?.invoice_activity?.approved_at || membership.invoice_activity?.invoice_activity?.builder_hive_id == null || raise_concern_allowed)" (input) = "changeApprovedHours(membership.invoice_activity.invoice_activity)" maxlength="2" min = "0" max="60" [(ngModel)] = "minutes[membership.invoice_activity?.invoice_activity?.id]" class="inputForMinute" placeholder="00">
                  <span class="spacer">:</span>
                  <input type="number" (keypress)="sharedService.restrictNumeric($event)" [disabled] = "(membership.invoice_activity?.invoice_activity?.approved_at || membership.invoice_activity?.invoice_activity?.builder_hive_id == null || raise_concern_allowed)" (input) = "changeApprovedHours(membership.invoice_activity.invoice_activity)" maxlength="2" min = "0" max = "60" [(ngModel)] = "secs[membership.invoice_activity?.invoice_activity?.id]" class="inputForSec" placeholder="00">
                  <span class="elementHover" *ngIf="!previous_invoice_active && membership.invoice_activity?.invoice_activity?.start_date">Hours can be approved after {{raise_concern_till_this_date}}</span>
                  <span class="elementHover" *ngIf="!membership.invoice_activity?.invoice_activity?.start_date">Not applicable for invoice</span>
                </div>
                
                <div class="notificationForHours" *ngIf = "minsSecsError[membership.invoice_activity.invoice_activity.id]">{{minsSecsError[membership.invoice_activity.invoice_activity.id]}}</div>


                <div class="newBox" *ngIf = "(membership.invoice_activity?.invoice_activity?.approved_at !== null || membership.invoice_activity?.invoice_activity?.approved_time !== null) && partner?.code === 'e-ai'">
                  <!-- If Hours are Approved :Starts  -->
                  <span *ngIf = "membership.invoice_activity?.invoice_activity?.approved_time === null"><input type="number" disabled value = "" class="timeInput"  placeholder=""></span>
                  <span *ngIf = "membership.invoice_activity?.invoice_activity?.approved_time != null">
                  <input type="text" disabled value = "{{secsToDuration(membership.invoice_activity?.invoice_activity?.approved_time)}}" class="timeInput"  placeholder="">
                </span>
                  <!-- If Hours are Approved :Ends  -->
                </div>

                <!-- If their is partner concern in working hours:starts -->
                <div class="newBox2" *ngIf = "membership.invoice_activity?.invoice_activity?.approved_at == null && membership.invoice_activity?.invoice_activity?.partner_concern != null && partner?.code === 'e-ai'">
                  <em class="iconb-info ifConflictInHours"></em>
                  <div class="ifConflictInHours-info">{{membership.invoice_activity?.invoice_activity?.partner_concern}}</div>
                </div>
                <!-- If their is partner concern in working hours:Ends -->
              </div>
            </div>

            <!--Normal Scenario for showing Approve Button Starts-->
            <div class="approval-action" *ngIf = "!previous_invoice_active && membership.invoice_activity?.invoice_activity?.approved_at === null && (membership.invoice_activity?.invoice_activity?.approved_time == null || membership.invoice_activity?.invoice_activity?.cpe_reason == null) && partner?.code === 'e-ai'">
              <button type="button" class="approvalButton" *ngIf="showApproveButton(membership.invoice_activity.invoice_activity)" (click)="openPopupRating(membership, 'approve')"
                      [disabled] = "minsSecsError[membership.invoice_activity.invoice_activity.id] || membership.invoice_activity?.invoice_activity?.approved_at || qtd[membership.invoice_activity.invoice_activity.id] == null || raise_concern_allowed">
                Approve
                <span class="elementHover">Please fill Manager hours</span>
              </button>
              <button type="button" class="raiseRequestButton" *ngIf="showRaiseRequestButton(membership.invoice_activity.invoice_activity)" (click)="openPopupRating(membership, 'raise_request')"
                      [disabled] = "minsSecsError[membership.invoice_activity.invoice_activity.id] || membership.invoice_activity?.invoice_activity?.approved_at || raise_concern_allowed">
                Raise Request
                <span class="elementHover">Approval Limit : {{secsToDuration(approvalLimit(membership.invoice_activity?.invoice_activity?.total_elapsed_time, membership.invoice_activity?.invoice_activity?.variance))}} hours</span>
              </button>
            </div>
            <!--Normal Scenario for showing Approve Button Ends-->

            <!--Normal Scenario for showing Approved Button Starts-->
            <!-- <div class="approval-action" *ngIf = "membership.invoice_activity?.invoice_activity?.approved_at && partner?.code === 'e-ai'">
              <button type="button" disabled class="approvalButton approved">Approved</button>
            </div> -->
            <div class="approvalDetail" *ngIf="membership.invoice_activity?.invoice_activity?.approved_at">
              <span class="userInitial">{{membership.invoice_activity?.invoice_activity?.approver_name | slice: 0:1}}</span>
              <div class="elementHover-extra">
                <span class="approver-title">{{membership.invoice_activity?.invoice_activity?.approver_name}}</span>
                <span class="approver-pod-role" *ngIf="membership.invoice_activity?.invoice_activity?.formatted_pod_role">[{{membership.invoice_activity?.invoice_activity?.formatted_pod_role}}]</span>
                <span class="approver-email">{{membership.invoice_activity?.invoice_activity?.approver_email}} </span> 
              </div>
              <span class="copyEmail-id" (click)="copyApproverEmail(membership)" [clip]="membership.invoice_activity?.invoice_activity?.approver_email">{{membership.approver_email_copy ? 'Copied' : 'Copy Email Id'}}</span>
            </div>
            <!--Normal Scenario for showing Approved Button Ends-->

            <!--Normal Scenario for showing Request Sent Button Starts-->
            <div class="approval-action" *ngIf = "!previous_invoice_active && membership.invoice_activity?.invoice_activity?.approved_at == null && membership.invoice_activity?.invoice_activity?.approved_time != null && membership.invoice_activity?.invoice_activity?.cpe_reason != null && partner?.code === 'e-ai'">
              <button type="button" disabled class="raiseRequestButton requestSent">Request Sent
                <span class="elementHover">Request has been sent to Ops team for approval with the reason: {{membership.invoice_activity?.invoice_activity?.cpe_reason}}</span>
              </button>
            </div>
            <!--Normal Scenario for showing Request Sent Button Ends-->
          </div>
          <!--Approve hours block ends here-->

          <div class="userAdditionalInfo-wrapper" *ngIf="membership.open_more_options">
            <div class="additionalInfo-tabName">
              <span class="tabName" [ngClass]="{'selectedtab': (openTabWithId === membership.id)}" (click)="showMemberIDE(membership)">Buildcard IDE</span>
              <span class="tabName" [ngClass]="{'selectedtab': openMemberCard === membership.id}" (click)="showMemberCard(membership.id)">Member Card</span>
              <span class="tabName" [ngClass]="{'selectedtab': openMemberActivity === membership.id}" (click)="showMemberActivity(membership.id)">View Activity</span>
              <span class="tabName" *ngIf="canRemove && membership?.editable && !membership?.user?.deleted_at" (click)="removeMembership(membership)">Delete Member</span>
            </div>
            <div class="additionalInfo-tabContent" *ngIf="openMemberCard === membership.id">
              <div class="userCard-Loader" *ngIf="cardListLoader">
                <img src="../../assets/images/storyBoardLoader.gif" alt="loader"/>
              </div>
              <div class="tabContent-boxContainer">
                <em class="iconb-total-project boxContentIcon"></em>
                <div class="boxContent">
                  <span class="topTitle">Hourly Rate: {{userCardDetail?.hourly_rate}}</span>
                  <span class="projectInfo">On Buildcard <strong>{{project.name}}</strong></span>
                </div>
              </div>
              <div class="tabContent-boxContainer">
                <em class="iconb-check-circle boxContentIcon"></em>
                <div class="boxContent">
                  <span class="topTitle">Total Approved Hours: {{userCardDetail?.total_approved_time}}</span>
                  <span class="projectInfo">On Buildcard <strong>{{project.name}}</strong></span>
                </div>
              </div>
              <div class="tabContent-boxContainer">
                <em class="iconb-check-circle boxContentIcon"></em>
                <div class="boxContent">
                  <span class="topTitle">Total Tracked Hours: {{userCardDetail?.total_tracked_time}}</span>
                  <span class="projectInfo">On Buildcard <strong>{{project.name}}</strong></span>
                </div>
              </div>
              <div class="tabContent-boxContainer">
                <em class="iconb-check-circle boxContentIcon"></em>
                <div class="boxContent">
                  <div class="topTitle">Total invested hours: {{userCardDetail?.invested_hours}}
                    <div class="iconb-info-extra infoIcon">
                      <div class="estimated-info">
                        <span class="typeLabel">Total invested hours is the sum of total approved hours for this member and estimated approved hours (allocated days* 8hrs)</span>
                      </div>
                    </div>
                  </div>
                  <span class="projectInfo">On Buildcard <strong>{{project.name}}</strong></span>
                </div>
              </div>
              <div class="usersFeedback-wrapper">
                <div class="tabContent-boxContainer2">
                  <span class="userRateType-title">Delivery Ownership</span>
                  <div class="ratingType-buttons">
                    <span class="iconb-thumb-down thumbUp thumbUpTop" *ngIf = "userCardDetail?.ratings" [style.height.%]="userCardDetail?.ratings.delivery_ownership"></span>
                    <span class="iconb-thumb-down thumbDown thumbDownTop" *ngIf = "userCardDetail?.ratings" [style.height.%]="100 - userCardDetail?.ratings.delivery_ownership"></span>
                    <span class="iconb-thumb-up thumbUp defaultRate"></span>
                    <span class="iconb-thumb-down thumbDown defaultRate"></span>
                  </div>
                </div>
                <div class="tabContent-boxContainer2">
                  <span class="userRateType-title">Responsiveness</span>
                  <div class="ratingType-buttons">
                    <span class="iconb-thumb-down thumbUp thumbUpTop" *ngIf = "userCardDetail?.ratings" [style.height.%]="userCardDetail?.responsiveness"></span>
                    <span class="iconb-thumb-down thumbDown thumbDownTop" *ngIf = "userCardDetail?.ratings" [style.height.%]="100 - userCardDetail?.ratings.responsiveness"></span>
                    <span class="iconb-thumb-up thumbUp defaultRate"></span>
                    <span class="iconb-thumb-down thumbDown defaultRate"></span>
                  </div>
                </div>
                <div class="tabContent-boxContainer2" style="margin-right: 0;">
                  <span class="userRateType-title">Communication</span>
                  <div class="ratingType-buttons">
                    <span class="iconb-thumb-down thumbUp thumbUpTop" *ngIf = "userCardDetail?.ratings" [style.height.%]="userCardDetail?.ratings.communication"></span>
                    <span class="iconb-thumb-down thumbDown thumbDownTop" *ngIf = "userCardDetail?.ratings" [style.height.%]="100 - userCardDetail?.ratings.communication"></span>
                    <span class="iconb-thumb-up thumbUp defaultRate"></span>
                    <span class="iconb-thumb-down thumbDown defaultRate"></span>
                  </div>
                </div>
              </div>
            </div>

            <div class="additionalInfo-tabContent" *ngIf="openMemberActivity === membership.id">
              <div class="userActivityList-Loader" *ngIf="activityListLoader">
                <img src="../../assets/images/storyBoardLoader.gif" alt="loader"/>
              </div>
              <div class="ifNoActivity" *ngIf="userActivities.length === 0">
                No activity available.
              </div>
              <div class="hs_story history_story" *ngIf="userActivities.length > 0">
                <div class="hs_storyListing" [ngClass]="{commentHistory: userActivities?.details?.length == 0}" *ngFor="let userActivity of userActivities">
                  <div class="hs_story-header">
                    <div class="hs_storyIconWrapper" *ngIf="userActivity?.details.length > 0">
                      <em class="iconb-dd-down hs_story-expandIcon" [ngClass]="{'expanded':userActivity?.toggleHistory}" (click)="toggleViewmode(userActivity)"></em>
                    </div>
                    <div class="hs_storyTitleWrapper">
                      <div class="hs_requestor">
                        <span class="hs_requesterInfo">{{ userActivity?.user_name }}</span>
                        <span class="hs_eventType">{{ userActivity?.action_name }}
                          <span *ngIf="userActivity?.commit_id"><a [href]="userActivity?.commit_url" target="_blank" class="commitLink">{{userActivity?.commit_id}}</a></span>
                          {{ userActivity?.created_on ? sharedService.formattTimeWithSeconds(userActivity?.created_on) : ''}}
                        </span>
                        {{ userActivity?.action_type }} {{userActivity?.details.length > 0 ? '' : ' on story '}} <span class="sp-em">{{userActivity?.story_name}}</span>
                        <span class="hs_attachments" *ngIf="userActivity?.attachment_url">
                          <span class="uploading-listing">
                            <div class="uploading-listing-thumbnail not_maximized">
                              <img *ngIf="checkType(userActivity?.attachment_url)" src="{{ userActivity?.attachment_url }}" alt="loader"/>
                              <img *ngIf="!checkType(userActivity?.attachment_url)" class="default-attachment" src="/assets/images/defaullt-download.png" alt="loader"/>
                            </div>
                            <div class="uploading-file-description">
                              <span class="docuName">{{ userActivity?.action_type.substr(1, userActivity?.action_type.length - 2) }}</span>
                            </div>
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="hs_historyInfo" *ngIf="userActivity?.toggleHistory &&  userActivity?.details.length > 0">
                    <div class="hs_eventTypeList" *ngFor="let detail of userActivity?.details">
                      <span class="hs_eventTypeName" *ngIf="detail.field_name == 'description to'"><strong>{{ detail.action }}</strong>{{ ' ' + detail.field_name + ' '}}<span [innerHtml]="bypassSanitization(detail.field_value)"></span></span>
                      <span class="hs_eventTypeName" *ngIf="detail.field_name != 'description to'"><strong>{{ detail.action }}</strong>{{ ' ' + detail.field_name + ' "'}}<span [innerHtml]="bypassSanitization(detail.field_value)"></span>"</span>
                      <span class="hs_attachments" *ngIf="detail.attachment_url">
                        <span class="uploading-listing">
                          <div class="uploading-listing-thumbnail not_maximized">
                            <img *ngIf="checkType(detail.attachment_url)" src="{{ detail.attachment_url }}" alt="loader"/>
                            <img *ngIf="!checkType(detail.attachment_url)" class="default-attachment" src="/assets/images/defaullt-download.png" alt="loader"/>
                          </div>
                          <div class="uploading-file-description">
                            <span class="docuName">{{ detail.field_value }}</span>
                          </div>
                        </span>
                      </span>
                    </div>
                  </div>
                  <div class="hs_date_projectDash"><span>{{ userActivity?.added_on }} - on Buildcard <strong style="font-weight: 500">{{project.name}}</strong></span></div>
                </div>
              </div>
            </div>
            <div class="additionalInfo-tabContent" *ngIf="openTabWithId === membership.id">
              <div class="ifnoIDE-available" *ngIf="membership?.docker_ides.length === 0">No Buildcard IDE available</div>
              <div class="ide-list-wrapper" *ngIf="membership?.docker_ides.length > 0">
                
                <div class="ide-list" *ngFor="let ide of membership?.docker_ides">
                  <div class="ide-name"><span class="reponame">{{ide.repo_name}}</span> - {{ide.url}}</div>
                   <em class="iconb-copyid" (click)="copyStoryID()" [clip]="ide.url">
                    <span class="elementHover">{{showCopiedID ? 'URL Copied' : 'Copy URL'}}</span>
                  </em>
                </div>
                <div class="ide-list">
                  <button type="button" class="ideButton" [disabled]="resendLoader" (click)="resenIDEinvite(membership.id)">
                    <em class="iconb-stories"></em>
                    Resend 
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="addProjectMember-recommended" [ngClass]="{'noList':(project.memberships | membersearch: searchStr).length === 0}" *ngIf = "(searchStr.length > 0) || (searchUsers.length > 0)">
        <div class="makeFullLoader searched-user" *ngIf="searched_user_loader">
          <img src="../../assets/images/storyBoardLoader.gif" alt="loader"/>
        </div>
        <div class="maintitle" *ngIf = 'searchUsers.length > 0'>Instead you can invite People to this Buildcard.</div>
        <div class="addProjectMember-innertable" *ngIf = "(searchStr.length > 0) && (searchUsers.length > 0)">
          <div class="tableBlocks-listingWrapper">
            <div class="tableBlocks-listing" *ngFor="let user of searchUsers">
              <div class="tableBlocks-container">
                <div class="tableBlocks-name">
                  <span class="userInitial">{{user?.initials | slice: 0:1}}</span>
                  <div class="userInfo-wrapper">
                    <span class="userName">
                      <span class="userName-content">
                        <span class="userName-title">{{user.name}}</span>
                      </span>
                      <span class="userName-content2">
                        <span class="userName-email">{{user.email}} </span> 
                      </span>
                    </span>
                    <div class="user-status user-status2" *ngIf='user.deleted_at'>
                      <span class="ifInvitationIsNotAccepted"><em class="iconb-blocker"></em> User Account Deactivated </span>
                    </div>
                  </div>
                  <div class="user-status">
                    <span *ngIf="canUpdate && !user.deleted_at" class="inviteButton" (click)="inviteNewMember(user)">Add Member</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    
  </div>

</div>

<div class="userRatingPopup" [ngClass]="{'userRatingPopup-open': openRatingPopup}" *ngIf="openRatingPopup">
  <div class="userRatingPopup-backdrop" *ngIf="openRatingPopup" (click)="closePopupRating(selectedMembership)"></div>
  <div class="userRatingPopup-wrapper">
    <em class="iconb-closeSmall closePopup" (click)="closePopupRating()"></em>
    <div class="contentBlock">
      <div class="headingText">Rate your working experience with {{selectedMembership.user.name_or_email}} this month.</div>
      <div class="ratingBlock">
        <div class="rating-box">
          <span class="rating-title">Delivery Ownership</span>
          <div class="ratingType-buttons">
            <span class="iconb-thumb-up thumbUp" (click)="updateRating('delivery_ownership',1)"
                  [ngClass]="{'selected': delivery_ownership[selectedMembership?.invoice_activity?.invoice_activity?.user_email] == 1}"></span>
            <span class="iconb-thumb-down thumbDown" (click)="updateRating('delivery_ownership',0)"
                  [ngClass]="{'selected': delivery_ownership[selectedMembership?.invoice_activity?.invoice_activity?.user_email] == 0}"></span>
          </div>
        </div>
        <div class="rating-box">
          <span class="rating-title">Responsiveness</span>
          <div class="ratingType-buttons">
            <span class="iconb-thumb-up thumbUp" (click)="updateRating('responsiveness',1)"
                  [ngClass]="{'selected': responsiveness[selectedMembership?.invoice_activity?.invoice_activity?.user_email] == 1}"></span>
            <span class="iconb-thumb-down thumbDown" (click)="updateRating('responsiveness',0)"
                  [ngClass]="{'selected': responsiveness[selectedMembership?.invoice_activity?.invoice_activity?.user_email] == 0}"></span>
          </div>
        </div>
        <div class="rating-box">
          <span class="rating-title">Communication</span>
          <div class="ratingType-buttons">
            <span class="iconb-thumb-up thumbUp" (click)="updateRating('communication',1)"
                  [ngClass]="{'selected': communication[selectedMembership?.invoice_activity?.invoice_activity?.user_email] == 1}"></span>
            <span class="iconb-thumb-down thumbDown" (click)="updateRating('communication',0)"
                  [ngClass]="{'selected': communication[selectedMembership?.invoice_activity?.invoice_activity?.user_email] == 0}"></span>
          </div>
        </div>
      </div>
      <div class="raiseRequest-block" *ngIf="!isRaiseRequest && checkInputTime(selectedMembership)">
        <span class="raiseRequest-title">Reason for the increment in Total Tracked time.<sup>*</sup></span>
        <div class="approve-reasons-wrapper">
          <div class="selected-reason" (click)="showReasons()">{{selected_reason_option ? selected_reason_option : 'Select Reason'}} <em class="iconb-dd-down"></em></div>
          <div class="reasons-list-wrapper" *ngIf="show_reason">
            <div class="reasons-list" *ngFor="let approveReason of approveReasons" (click)="approvalReason(selectedMembership, approveReason.reason)">{{approveReason.reason}}</div>
          </div>
        </div>
        <textarea *ngIf="other_reason_option" class="raiseRequest-input marginAtTop" placeholder="Please provide additional information" [(ngModel)]="request_reason[selectedMembership?.invoice_activity?.invoice_activity?.id]"></textarea>
      </div>
      <div class="raiseRequest-block" *ngIf="isRaiseRequest">
        <span class="raiseRequest-title">Reason for raising request<sup>*</sup></span>
        <textarea class="raiseRequest-input" placeholder="Raise request here ... " [(ngModel)]="request_reason[selectedMembership?.invoice_activity?.invoice_activity?.id]"></textarea>
      </div>
      <div class="actionBlock">
        <button type="button" class="ok-button"  *ngIf="!isRaiseRequest" (click)="approveApprovedHours(selectedMembership)"
                [disabled]="!checkRating(selectedMembership?.invoice_activity?.invoice_activity) || (checkInputTime(selectedMembership) && !request_reason[selectedMembership?.invoice_activity?.invoice_activity?.id])">Apply</button>
        <button type="button" class="cancel-button"  *ngIf="isRaiseRequest" (click)="closePopupRating(selectedMembership)">Cancel</button>
        <button type="button" class="ok-button"  *ngIf="isRaiseRequest" (click)="approveApprovedHours(selectedMembership)"
                [disabled]="!checkRating(selectedMembership?.invoice_activity?.invoice_activity) || !(request_reason[selectedMembership?.invoice_activity?.invoice_activity?.id].trim().length > 0)">Submit & Raise Request</button>
      </div>
    </div>
  </div>
</div>

<div class="addNewProject-popupWrapper" *ngIf="sharedService.showprojectAdminForm">
  <div class="addNewProject-popUpBackdrop" (click)="sharedService.showprojectAdminForm = false"></div>
  <div class="addNewProject-content">
    <div class="addNewProject-top">Edit Buildcard</div>
    <admin-project-edit></admin-project-edit>
  </div>
</div>


<app-custom-popup  [texts] = "{ptitle: 'Delete', ptext: 'Are you sure you want to delete?', psuccess: 'Delete', pcancel: 'Cancel'}"
                   (closeCustomPopUp) = "closeMembershipDeletePopUp()"
                   (deleteElement) = "deleteMembership($event)"
                   [delement] = "delmembership"
                   [OpenPopUp] = "openMembershipDeletePopup"
                   [waitForLoader] = "waitForLoader">
</app-custom-popup>

<app-custom-popup  [texts] = "{ptitle: 'Delete', ptext: 'Are you sure you want to delete?', psuccess: 'Delete', pcancel: 'Cancel'}"
                   (closeCustomPopUp) = "closeProjectDeletePopUp()"
                   (deleteElement) = "deleteProject()"
                   [delement] = "delProject"
                   [OpenPopUp] = "openProjectDeletePopup"
                   [waitForLoader] = "waitForLoader">
</app-custom-popup>
