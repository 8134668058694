import { Injectable, Inject, forwardRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NotificationService } from './notification.service';
import { UserData, NotificationPayload, EVENT_TYPES, PartnerData } from '../dataTypes';
import {  User} from '../models/user';
import { Membership } from '../models/membership';
import { Partner } from '../models/partner';
import { Story } from '../models/story';
import { Project } from '../models/project';
import { API_ROUTES } from '../apiRoutes';
import { map } from 'rxjs/operators';
import {Angulartics2Segment} from 'angulartics2/segment';
import {environment} from '../../../environments/environment';
import { HttpRequests } from './http-requests.service';
import { DataService } from './data.service';
import { CustomToastService } from './custom-toast.service';

@Injectable()
export class UserService {
  private _currentUser: User;
  private _partnerCode: Partner;
  loginUrl = '';
  public currentUser: User;
  uid = '';
  client = '';
  developerLogin = '';
  isUserLoggedIn: boolean;
  isDesignerLogin: boolean;
  accessToken = '';
  isLoginWithDeveloper = false;
  isShowResendButton = false;
  isAdminLogin: boolean;
  redirectToStoryBoard: boolean = false;
  pro_id_for_reg:number;
  public maintenance = false;
  maintenanceData: any = {};
  showDowntimeNotification = true;

  constructor(
              private httpRequest: HttpRequests,
              private dataService: DataService,
              private customToast: CustomToastService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private angulartics2Segment: Angulartics2Segment,
              @Inject(forwardRef(() => NotificationService)) private notificationService: NotificationService) {
  }

  getIsLoginWithDeveloper() {
    return localStorage.getItem('isLoginWithDeveloper')  ? JSON.parse(localStorage.getItem('isLoginWithDeveloper')) : null;

  }

  getIsUserInternalOrCp() {
    return localStorage.getItem('isUserInternalOrCp') ? localStorage.getItem('isUserInternalOrCp') : '';
  }

  setCurrentUser(data: UserData, headers?, designerLogin?, adminLogin?) {
    if (headers) {
      const isDeveloperLogin = localStorage.getItem('isLoginWithDeveloper') ? JSON.parse(localStorage.getItem('isLoginWithDeveloper')) : null;
      if(isDeveloperLogin) {
        const currentTimeStamp =  new Date().getTime();
        localStorage.setItem('developerlogin', JSON.stringify(currentTimeStamp));
      }
      this.dataService.authToken = headers.get('access-token');
      this.setAuthToken(headers.get('access-token'));
      this.setUid(headers.get('uid'));
      this.setClient(headers.get('client'));
    }
    this.storeCurrentUser(new User(data), headers, designerLogin, adminLogin);
    this.currentUser = new User(data);
  }

  setAuthToken(token) {
    localStorage.setItem('access_token', token);
  }

  getLoggedInUser() {
    return this.currentUser;
  }

  getLoginTimeForDeveloper() {
    return localStorage.getItem('developerlogin') ? JSON.parse(localStorage.getItem('developerlogin')) : null;
  }

  setUid(uid) {
    localStorage.setItem('uid', uid);
  }

  setClient(client) {
    localStorage.setItem('client', client);
  }

  getAuthToken() {
    // return localStorage.getItem('access_token') ? localStorage.getItem('access_token') : null;
    if(this.accessToken) {
      return this.accessToken;
    } else {
      return this.accessToken = this.getCookie(this.dataService.user_cookie) ? JSON.parse(this.getCookie(this.dataService.user_cookie))['access-token'] : null;
    }
  }

  getAdminLogin() {
    if(this.isAdminLogin) {
      return this.isAdminLogin;
    }
    return this.isAdminLogin = this.getCookie(this.dataService.user_cookie) ? JSON.parse(this.getCookie(this.dataService.user_cookie)).adminLogin : null;
  }

  getDesignerLogin() {
    if(this.isDesignerLogin) {
      return this. isDesignerLogin;
    }
    return this. isDesignerLogin = this.getCookie(this.dataService.user_cookie) ? JSON.parse(this.getCookie(this.dataService.user_cookie)).designerLogin : null;
  }

  getUid() {
    // return localStorage.getItem('uid') ? localStorage.getItem('uid') : null;
    if(this.uid) {
      return this.uid;
    }
    return this.uid =  this.getCookie(this.dataService.user_cookie) ? JSON.parse(this.getCookie(this.dataService.user_cookie)).uid : null;
  }

  getClient() {
    // return localStorage.getItem('client') ? localStorage.getItem('client') : null;
    if(this.client) {
      return this.client
    }
    return this.client = this.getCookie(this.dataService.user_cookie) ? JSON.parse(this.getCookie(this.dataService.user_cookie)).client : null;
  }


  setPartner(data: PartnerData) {
    this.partner = new Partner(data);
  }

  storeCurrentUser(user: User, headers?, designerLogin?, adminLogin?) {
    this.clearCurrentUser();
    this._currentUser = user;
    localStorage.setItem('currentUser', JSON.stringify(this._currentUser.toJSON()));

    const payload: NotificationPayload = {
      data: {
        currentUser: this.currentUser
      }
    };
    if(headers) {
      this.setCookie(headers, user, designerLogin, adminLogin);
    }
    /*this.notificationService.broadcast(EVENT_TYPES.CURRENT_USER.UPDATED, payload);*/
  }

  setCookie(headers, user, designerLogin?, adminLogin?) {
    designerLogin = !!designerLogin;
    adminLogin = !!adminLogin;
    const userCookies = {user: user, designerLogin: designerLogin, adminLogin: adminLogin, 'access-token': headers.get('access-token'), uid: headers.get('uid'), client: headers.get('client')}
    document.cookie = `${this.dataService.user_cookie}=${JSON.stringify(userCookies)}; path=/; domain=${this.dataService.cookie_domain};`;
  }

  public getCookie(cname) {
      const name = cname + '=';
      const decodedCookie = decodeURIComponent(document.cookie);
      const ca = decodedCookie.split(';');
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return '';
  }

  getCurrentUser(): User {
    if (this._currentUser) {
      return this._currentUser;
    } else {
      return this.loadCurrentUser();
    }
  }

  set partner(partner: Partner) {
    this.clearPartner();
    this._partnerCode = partner;
    localStorage.setItem('partner', JSON.stringify(this._partnerCode.toJSON()));

    // const payload: NotificationPayload = {
    //   data: {
    //     currentUser: this.currentUser
    //   }
    // };
    // this.notificationService.broadcast(EVENT_TYPES.CURRENT_USER.UPDATED, payload);
  }

  get partner(): Partner {
    if (this._partnerCode) {
      return this._partnerCode;
    } else {
      return this.loadPartner();
    }
  }

  getUser() {
    if (this.currentUser) {
      return this.currentUser
    }
    return this.currentUser = this.getCookie(this.dataService.user_cookie) ? JSON.parse(this.getCookie(this.dataService.user_cookie)).user : null;
      // return this.currentUser = localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser')): null;
  }

  isAdmin() {
    return this.getUser().role === 'admin';
  }

  isUser() {
    return this.getUser().role === 'user';
  }

  userSignedIn(): boolean {
    //return localStorage.getItem('currentUser') ? true : false;
    if(this.isUserLoggedIn) {
      return this.isUserLoggedIn;
    }
    return this.getCookie(this.dataService.user_cookie) ? true : false;
  }

  loadCurrentUser() {
    const data: UserData = localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser')): null;
    //const data: UserData = this.getCookie(this.dataService.user_cookie) ? JSON.parse(this.getCookie(this.dataService.user_cookie)).user : null;

    if (data) {
      this.validateToken().subscribe(
        res => {},
        error => {
          this.clearCurrentUser(false);
          this.router.navigate(['/noSession'])
        }
      );
      return this._currentUser = new User(data);
    }
  }

  validateToken() {
    const API = this.dataService.apiUrl + API_ROUTES.VALIDATE_TOKEN;
    return this.httpRequest
    .get(API, this.dataService.getRequestOptionArgs()).pipe(
    map(res => this.parseResponse(res)));
  }

  loadPartner() {
    const data: PartnerData = JSON.parse(localStorage.getItem('partner'));

    if (data) {
      return this._partnerCode = new Partner(data);
    }
  }

  clearCurrentUser(notify = true) {
    localStorage.removeItem('currentUser');
    // this.unSetCookie();
    delete this._currentUser;
  }

  clearPartner(notify = true) {
    localStorage.removeItem('partner');
    delete this._partnerCode;
  }

  signOut(partner?) {
    if (this.getUser()) {
      this.signOutUser(partner).subscribe(res => {
        this.removeSignOutLocalStorage();
        this.unSetCookie();
        if(partner) {
          this.customToast.messages.push({id:"serverfail", type:"fail", class:"generic_alert", time: 5000,  title: 'Alert', message: 'Session expired, you need to sign in before continuing. '});
        }
        this.router.navigate(['/noSession']);
      }, (error) => {
        this.clearCurrentUser();
        this.removeSignOutLocalStorage();
        this.unSetCookie();
        this.router.navigate(['/noSession']);
      });
    }
  }

  signOutUser(partner?) {
    const API = this.dataService.apiUrl + API_ROUTES.SIGN_OUT;
    return this.httpRequest
    .delete(API, this.dataService.getRequestOptionArgs('application/json')).pipe(
    map(res => this.parseResponse(res)),
    map(res => {
      return res.partner;
    }));
  }

  membershipsForProject(project: Project) {
    const API = this.dataService.apiUrl + API_ROUTES.PROJECT_USERS(project.id);
    return this.httpRequest
             .get(API, this.dataService.getRequestOptionArgs()).pipe(
             map(res => this.parseResponse(res)),
             map(res => {
               return res.map(data => {
                 return Membership.getMembership(data.membership)
               });
             }),);
  }

  getPartnerCode() {
    const API = this.dataService.apiUrl + API_ROUTES.GET_PARTNER;
    return this.httpRequest
    .get(API, this.dataService.getRequestOptionArgs()).pipe(
    map(res => this.parseResponse(res)),
    map(res => {
      return res.partner;
    }),);
  }

  public updatePassword(userdetails) {
    const API = this.dataService.apiUrl + API_ROUTES.SIGN_IN;
    return this.httpRequest.post(API, JSON.stringify(userdetails), this.dataService.getRequestOptionArgs('application/json'))
    .pipe(
      map(res => this.parseResponse(res)));
  }

  resend_invitation(userEMail){
    const data = {
      email : userEMail
    };
    const API = this.dataService.apiUrl + API_ROUTES.RESEND_INVITATION;
    return this.httpRequest
      .post(API, data, this.dataService.getRequestOptionArgs()).pipe(
      map(res => this.parseResponse(res)));
  }


  public login_signup(userdetails, option, type?) {
    let API;
    let headers;
    let data;
    let login = false;
    if (option === 'login') {
      login = true;
      API = this.dataService.apiUrl + API_ROUTES.SIGN_IN;
      headers = this.dataService.getRequestOptionArgs('application/json', false, '', type, login);
      data = JSON.stringify(userdetails)
    } else if (option === 'register') {
      login = true;
      headers = this.dataService.getRequestOptionArgs('application/json', false, '', '', login);
      API = this.dataService.apiUrl +  API_ROUTES.SIGN_UP;
      data = userdetails
    } else if (option === 'forgot') {
      API = this.dataService.apiUrl + API_ROUTES.FORGOT_PASSWORD;
      headers = this.dataService.getRequestOptionArgs()
      data = userdetails;
    } else if (option === 'reset') {
      API = this.dataService.apiUrl + API_ROUTES.GET_USER;
    }
    return this.httpRequest.post(API, data , headers)
    .pipe(map(res => this.parseResponse(res)));
  }

  checkUserExistence(userEMail) {
    const data = {
      email : userEMail
    };
    const url = this.dataService.apiUrl + API_ROUTES.GET_USER;
    return this.httpRequest.get(url, {params : data}).pipe(
      map(res => this.parseResponse(res)));
  }

  matchImageForInitialFace(image, user) {
    const API = environment.apiHiveBase + API_ROUTES.FACE_AUTHENTICATION;
    const requestData = new FormData();
    requestData.append('target_image', image);
    requestData.append('email', user);
    return this.httpRequest.post(API, requestData, this.dataService.getRequestOptionArgs('', true)).pipe(
      map(res => this.parseResponse(res)));
  }

  queryUser(project: Project, query: string) {
    const API = this.dataService.apiUrl + API_ROUTES.QUERY_USERS(project.id, query);
    return this.httpRequest
             .get(API, this.dataService.getRequestOptionArgs()).pipe(
             map(res => this.parseResponse(res)));
  }

  createMember(member: any, email?, project_id?) {
    let addMember = Object.assign({}, member);
    if (project_id) {
      addMember['project_id'] = project_id;
    }
    if (email) {
      addMember.email = email;
    }
    const API = this.dataService.apiUrl + API_ROUTES.MEMBER;
    return this
      .httpRequest.post(API, addMember, this.dataService.getRequestOptionArgs());
  }

  getallRoles(roles: string) {
    const API = this.dataService.apiUrl + API_ROUTES.MEMBER_ROLE(roles);
    return this.httpRequest.get(API, this.dataService.getRequestOptionArgs()).pipe(map(response => this.parseResponse(response)));
  }

  getallLocale(locales: string) {
    const API = this.dataService.apiUrl + API_ROUTES.MEMBER_LOCALE(locales);
    return this.httpRequest.get(API, this.dataService.getRequestOptionArgs()).pipe(map(response => this.parseResponse(response)));
  }

  getConfirmationTokenFromRegister(token: string) {
    const API = this.dataService.apiUrl + API_ROUTES.REGISTER + '?confirmation_token=' + token;
    return this.httpRequest
             .get(API, this.dataService.getRequestOptionArgs()).pipe(
             map(res => this.parseResponse(res)));
  }

  getScheduleMaintenance(platform?: string) {
    const API = this.dataService.apiUrl.replace('api/v1/', '') + API_ROUTES.SCHEDULE_MAINTENANCE(platform)
    return this.httpRequest
             .get(API, this.dataService.getRequestOptionArgs()).pipe(
             map(res => this.parseResponse(res)));
  }

  trackSegmentEvent(event, prop) {
    this.angulartics2Segment.eventTrack(event, prop);
  }

  public parseResponse(res: any): any {
    return res;
  }

  signOutDeveloperAfterSetTime() {
    if (this.getUser() && this.getIsLoginWithDeveloper() && this.getLoginTimeForDeveloper()) {
      let totalTimeAfterLogin: number = ((new Date().getTime()) - this.getLoginTimeForDeveloper());
      if(totalTimeAfterLogin/3600000 >= environment.developerLogoutTime) {
        this.signOutDeveloper(true);
      }
    }
  }

  signOutDeveloper(partner?) {
    this.isLoginWithDeveloper = false;
    localStorage.setItem('developerlogin', '');
    localStorage.setItem('isLoginWithDeveloper', '');
    localStorage.setItem('isUserInternalOrCp', '');
    this.signOut(partner);
  }

  getUserFromLocalStorage() {
    return localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser')): null;
  }

  getClientFromLocalStorage() {
    return localStorage.getItem('client') ? localStorage.getItem('client') : null;
  }

  getauthTokenFromLocalStorage() {
    return localStorage.getItem('access_token') ? localStorage.getItem('access_token') : null;
  }

  getUidFromLocalStorage() {
    return localStorage.getItem('uid') ? localStorage.getItem('uid') : null;
  }

  removeSignOutLocalStorage() {
    localStorage.setItem('access_token', '');
    localStorage.setItem('client', '');
    localStorage.setItem('uid', '');
    this.currentUser = null;
    this.clearCurrentUser();
  }

  unSetCookie() {
    document.cookie = `${this.dataService.user_cookie}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${this.dataService.cookie_domain};`;
    this.currentUser = null;
    this.client = null;
    this.isAdminLogin = null;
    this.uid = null;
    this.accessToken = null;
    this.isDesignerLogin = null;
    this.isUserLoggedIn = null;
    localStorage.setItem('isLoginWithDeveloper', '');
    localStorage.setItem('isUserInternalOrCp', '');
    this.clearCurrentUser();
  }

  checkMaintenance() {
    this.checkLastMaintenance();
    this.getScheduleMaintenance('tracker').subscribe(res => {
      this.saveMaintenanceAndAction(res.data);
    }, error => {
      if (!error.status || error.status === 500 || error.status === 502 || error.status === 503) {
        this.checkLastMaintenance();
      }
    });
  }

  saveMaintenanceAndAction(data) {
    this.maintenanceData = data || {};
    localStorage.setItem('maintenanceOption', JSON.stringify(this.maintenanceData));

    if (data) {
      if (this.maintenance && !data.under_maintenance) {
        window.location.reload();
      } else {
        this.maintenance = data.under_maintenance;
      }
    } else if (this.maintenance) {
      window.location.reload();
    }
  }

  checkLastMaintenance() {
    const maintenanceOption = localStorage.getItem('maintenanceOption')
    if (maintenanceOption) {
      const data = JSON.parse(maintenanceOption);
      if (data) {
        this.maintenanceData = data;
        this.maintenance = data.under_maintenance;
      }
    }
  }
}
