<div class="storyBoard-wrapper ts-wrapper">
  <div class="storyBoardPanel-Loader" *ngIf="testSuiteLoader">
    <img src="../../assets/images/storyBoardLoader.gif" alt=""/>
  </div>
  <div class="storyBoard-topBlock">
    <div class="test-suite-left-title">
      <div>
        <div class="storyBoard-title">Test Suite</div>
      </div>
        <!-- sorting wrapper ends -->
      <div class="testcase-sorting">
        <span class="selectedFilter" [ngClass]="{'buttActive':showSort}" (click)="openSort()"><em class="iconb-sorting"></em>  Sort </span>
        <div class="testcase-sorting-options" [@fadeInOut] *ngIf="showSort">
          <div class="testcase-sorting-option" [ngClass]="{'active': sort_by === 'created_at'}" (click)="sortTestCaseList('created_at')"><em class="iconb-calendar"></em> Date Created</div>
          <div class="testcase-sorting-option" [ngClass]="{'active': sort_by === 'updated_at'}" (click)="sortTestCaseList('updated_at')"><em class="iconb-calendar"></em> Date Updated</div>
       </div>
      </div>
        <!-- sorting wrapper ends -->
    </div>
    <!-- Search wrapper starts -->
    <div class="storyBoardSearch-wrapper">
      <div class="searchArea-block" (click)="doSearch()"><em class="iconb-search"></em></div>
      <div class="searchWrapper-backdrop" *ngIf="openSearchBlock" (click)="closeSearch()"></div>
      <div class="searchWrapper" [@fadeInOut] *ngIf="openSearchBlock"
        [ngClass]="{'searchWrapper-open': openSearchBlock}">
        <div class="searchInput-wrapper">
          <input #focusSearchStory type="text" class="searchinput" placeholder="Search Test Suite"
          (input)="clearSearchStoriesData()" [(ngModel)]="searchStr" (keyup.enter)="searchStories();searchInEpic()"/>{{ openSearchBlock ? focusSearchStory.focus() : '' }}
          <span class="searchInput-info">Enter to search <em class="iconb-search"></em></span>
        </div>

        <div class="resultToggle-wrapper">
          <button type="button" class="resultStatus-title" [class.activeToggle]="resultStoriesStatus"
            (click)="showRelatedStories()">Related Stories ({{searchResult.stories && searchResult.stories.length > 0 ? searchResult.stories.length : 0}})</button>
          <div class="resultStatus" [class.resultStoriesActive]="resultStoriesStatus"
            [class.resultLabelsActive]="resultLabelsStatus">
            <span class="toggleCircle"></span>
          </div>
          <button type="button" class="resultStatus-title" [class.activeToggle]="resultLabelsStatus"
            (click)="showRelatedLabels()">Related Testcases ({{totalCountSearch}})</button>
        </div>

        <div class="searhResultWrapper"
        infiniteScroll
        [scrollWindow]="false"
        [infiniteScrollDisabled]="!moreSearchStoryAvailale"
        (scrolled)="searchStories(true)">
          <div class="blank_search" *ngIf="!searchStr">
            <div class="noSuchProjectInner">
              <div class="blank_searchLoader" *ngIf="searchGoingLoader"><img
                  src="../../assets/images/small-spinner.gif">
              </div>
              <img src="../../assets/images/no_Project_icon.png" alt="" />
              <p>please enter keywords to search!</p>
            </div>
          </div>
          <div class="resultBlock" *ngIf="resultStoriesStatus && searchStr">
            <div class="blank_search" *ngIf="searchResult.stories && searchResult.stories.length === 0">
              <div class="noSuchProjectInner">
                <div class="blank_searchLoader" *ngIf="searchGoingLoader"><img src="../../assets/images/small-spinner.gif"></div>
                <img src="../../assets/images/no_Project_icon.png" alt=""/>
                <p>No result!!</p>
              </div>
            </div>
            <ng-container *ngIf="searchResult.stories && searchResult.stories.length > 0">
              <div
                 class="resultListing" *ngFor="let searchStory of searchResult.stories" (click)="showStoryDetail(searchStory?.id)">
                <div class="storyName">{{searchStory.title}}</div>
                <span class="storyState-icon" [ngClass]="[searchStory.state]"></span>
                <em [class]="storyTypeIcon(searchStory.story_type, searchStory?.parent_id !== null)"></em>
                <div class="storyDescription" *ngIf="!isSearchById && !searchStory.title.toLowerCase().includes(oldSearchStr.trim().toLowerCase())">
                  ... <b>{{this.oldSearchStr?.trim()}}</b> ...
                </div>
              </div>
            </ng-container>
            <div class="mainProjectList-Loader" *ngIf="startSearchLoader">
              <img src="../../assets/images/storyBoardLoader.gif" alt=""/>
            </div>
          </div>
          <div class="resultBlock" *ngIf="resultLabelsStatus && searchStr">
            <div class="blank_search" *ngIf="totalCountSearch === 0">
              <div class="noSuchProjectInner">
                <div class="blank_searchLoader" *ngIf="searchGoingLoader"><img src="../../assets/images/small-spinner.gif"></div>
                <img src="../../assets/images/no_Project_icon.png" alt=""/>
                <p>No result!!</p>
              </div>
            </div>
            
            <ng-container *ngIf="searchResult.testCase && searchResult.testCase.length > 0">
              <div
                 class="resultListing" *ngFor="let searchTestCase of searchResult.testCase" (click)="showStoryDetail(searchTestCase?.id)">
                <div class="storyName">{{searchTestCase.title}}</div>
                <span class="storyState-icon" [ngClass]="[searchTestCase.state]"></span>
                <div class="storyDescription" *ngIf="!isSearchById && !searchTestCase.title.toLowerCase().includes(oldSearchStr.trim().toLowerCase())">
                  ... <b>{{this.oldSearchStr?.trim()}}</b> ...
                </div>
              </div>
            </ng-container>
            
          </div>
        </div>
      </div>
    </div>
    <!-- Search wrapper ends -->

    <!-- Filters related code starts -->
    <div class="fliterButton-wapper" >
      <div class="filterButton" [class.buttonSelected]="showfilterList" (click)="toggelefilter()">
        <em class="iconb-filter"></em>
        <span class="filterbutton-title"><strong style="font-weight: 500" class="filterbutton-title">{{project ? project.filterCount : 0}}</strong> Filter</span>
      </div>
      <div class="filterBackdrop" *ngIf="showfilterList" (click)="closeFilter()"></div>
      <div class="filterDD" [@slideOut] *ngIf="project && showfilterList" [ngClass]="[showfilterList ? 'filterDD-open':'']">
        <app-story-filter [project]="project" [currentUser]="currentUser"></app-story-filter>
      </div>
    </div>
    <!-- Filters related code ends -->

  </div>

  <div class="ts-inner-wrapper custom-slim-scroll">
    <div class="ts-story-wrapper" [ngClass]="{'no-story': isEmptyStoriesVisible}">
      <div class="test-suite-story-loader" *ngIf="projectService.startApplyFilterLoader">
        <img src="../../assets/images/storyBoardLoader.gif" alt=""/>
      </div>
      <div class="ts-story-col-title">
        <h4>Stories</h4>
      </div>
      <div class="ts-story-wrapper-body"
        infiniteScroll
        [scrollWindow]="false"
        [infiniteScrollDisabled]="!isStoryScrollFetchAvailable()"
        (scrolled)="getMoreParentStories()">
        <!-- When no story available - starts -->
        <div class="no-data-wrapper" *ngIf="isEmptyStoriesVisible">
          <em class="icon"><img src="assets/images/ic-alert-circle.svg" alt=""></em>
          <p>No story is available at the moment for test case development.</p>
          <p>Please connect with your manager.</p>
        </div>
        <!-- When no story available - ends -->

        <!-- story card - starts -->
        <!-- add "active" class when selected -->
        <div class="ts-story-card"  *ngFor="let story of testSuiteStories; let i = index;" [ngClass]="{active : (story.id === selectedStory?.id)}">
          <div class="ts-story-info-body" (click)="showTestCases(story)">
            <div class="ts-story-top">
              <a href="javascript:void(0)" class="copy-id" (click)="copyStoryID($event,'story')" [clip]="story?.id" >{{story.id}}
                <em class="icon"><img src="assets/images/ic-duplicate.svg" alt=""></em>
                <span *ngIf="showCopiedID" [@tooltipAni] class="elementHover">Story ID Copied</span>
              </a>
              <div class="ts-right-top">
                <p  class="isCustomOrCore"> {{ story.core ? 'Core' : 'Custom' }}</p>
                <div class="countText">
                  <span class="no elementHoverLink">{{story.test_cases_count}}
                    <span class="elementHover">Test case count</span>
                  </span>
                </div>
              </div>
              <!--  -->
            </div>
            <h6 class="ts-story-title">{{story.title}}</h6>
            <div class="features">
              <p class="feature-name">{{story.feature_title}}</p>
            </div>
          </div>
          <ul class="ts-story-btns">
            <li>
              <a href="javascript:void(0)" class="btn-link" (click)="showStoryDetail(story?.id,story)">
                <em class="iconb-eye"></em> View story
              </a>
            </li>
            <li>
              <a href="javascript:void(0)" class="btn-link" (click)="toggleCreateTestCasePopup(story)">
                <em class="iconb-plus"></em> Add testcase
              </a>
            </li>
          </ul>
        </div>
        <!-- story card - ends -->
      </div>
    </div>

    <div class="ts-story-details-wrapper" *ngIf="!isEmptyStoriesVisible">
      <div class="ts-story-table-wrapper">
        <div class="ts-detail-table table-responsive">
          <div class="table-data-row table-head">
            <div class="test-id-col">Test Case ID</div>
            <div class="title-col">Test Case Title</div>
            <div class="status-col">Status</div>
            <div class="tags-col">Feature Tags</div>
            <div class="bug-id-col">Bug ID</div>
            <div class="actions-col">Actions</div>
          </div>

          <div class="table-body" *ngIf="!selectedStory">
            <div class="no-data-wrapper">
              <em class="icon"><img src="assets/images/ic-alert-circle.svg" alt=""></em>
              <p>Please select a story to view the relevant test cases here.</p>
            </div>
          </div>

          <div class="table-body" *ngIf="selectedStory && selectedStory.test_cases_count < 1">
            <div class="no-data-wrapper">
              <em class="icon"><img src="assets/images/ic-alert-circle.svg" alt=""></em>
              <p>The selected story has no test cases yet.</p>
              <a href="javascript:void(0)" class="skyblue-btn" (click)="toggleCreateTestCasePopup()">Create test case</a>
            </div>
          </div>

          <div class="table-body" *ngIf="initial_loader">
            <div class="sprintInitial-Loader" >
              <img src="../../assets/images/storyBoardLoader.gif" alt=""/>
            </div>
          </div>

          <!-- test case data starts -->
          <div class="table-body" *ngIf="selectedStory && testCases.length > 0"
            infiniteScroll
            [scrollWindow]="false"
            [infiniteScrollDisabled]="!istestCaseScrollFetchAvailable()"
            (scrolled)="getMoreTestCases()">
            <!-- test suite row starts -->
            <div class="table-data-row" *ngFor="let test of testCases"  (click)="showStoryDetail(test?.id)">
              <div class="test-id-col">
                <a href="javascript:void(0)" class="copy-id" (click)="copyStoryID($event,'test')" [clip]="test?.id">{{test.id}}
                  <em class="icon"><img src="assets/images/ic-duplicate-purple.svg" alt=""></em>
                  <span *ngIf="showTestCopiedID" [@tooltipAni] class="elementHover">Test Case ID Copied</span>
                </a>
              </div>
              <div class="title-col">
                <a href="javascript:void(0)" class="elementHoverLink">
                  <span class="testcase-text">{{test.title}}</span>
                  <span class="elementHover">{{test.title}}</span>
                </a>
              </div>
              <div class="status-col">
                <div class="action-wrapper status-menu-wrapper">
                  <span class="action-trigger" [ngClass]="[getStatus(test), checkUser(test)]" [class.active]="test.openStatusDD"
                    (click)="statusMenuToggle(test);$event.stopPropagation()">{{getStatusText(test)}} <em class="iconb-dd-down"></em></span>
                  <ul class="action-items" [@fadeInOut] *ngIf="test.openStatusDD">
                    <li class="detailStory-Loader" *ngIf="statusChangeLoader">
                      <img src="../../assets/images/storyBoardLoader.gif" alt="loader"/>
                    </li>
                    <li *ngFor="let status of testCaseStatus" (click)="updateTestCase(status.type,test);$event.stopPropagation()">
                      <a href="javascript:void(0)">{{status.title}}</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="tags-col">
                <span class="feature-tag">{{test.feature_tags}}</span>
              </div>
              <div class="bug-id-col">
                <span *ngIf="test.bug_ids?.length <= 0">NA</span>   
                <div class="action-wrapper secondary" *ngIf="test.bug_ids?.length > 0">
                  <a href="javascript:void(0)" (click)="showStoryDetail(test.bug_ids[0]);$event.stopPropagation()">{{test.bug_ids[0]}}</a>
                  <em class="action-trigger" *ngIf="test.bug_ids?.length > 1" [class.active]="test.openBugListMenu" (click)="bugListToggle(test);$event.stopPropagation()">{{test.bug_ids?.length - 1}}+</em>
                  <ul class="action-items" [@fadeInOut] *ngIf="test.openBugListMenu">
                    <li *ngFor="let bugId of getBugIds(test)"><a href="javascript:void(0)" (click)="showStoryDetail(bugId);$event.stopPropagation()">{{bugId}}</a></li>
                  </ul>
                </div>
              </div>
              <div class="actions-col" *ngIf="project.isUserActive(loggedInUser)">
                <div class="action-wrapper">
                  <em class="iconb-more-vert action-trigger"[class.active]="test.openActionDD"
                    (click)="actionMenuToggle(test);$event.stopPropagation()"></em>
                  <ul class="action-items" [@fadeInOut] *ngIf="test.openActionDD">
                    <li *ngIf="policyService.expertAsQA(project)">
                      <a href="javascript:void(0)" (click)="showCreateBugPopup(test);$event.stopPropagation()">
                        <em class="icon"><img src="assets/images/ic-create-bug.svg" alt=""></em> Create Bug
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0)" (click)="archiveTestCase(test);$event.stopPropagation()">
                        <em class="icon"><img src="assets/images/ic-archive.svg"  alt=""></em> Delete
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <!-- test suite row ends -->
          </div>
          <!-- test case data starts -->
        </div>
      </div>
    </div>
  </div>
</div>

<div id="storyDIV" class="revStoryDes-popup" #expand [hidden] ="!projectService.show_storyDetails && !projectService.start_storyDetailLoader">
  <div class="revStoryDes-popupWrapper">
    <story-detail [hidden] ="!projectService.show_storyDetails && !projectService.start_storyDetailLoader">
    </story-detail>
  </div>
</div>

<div *ngIf = "openCreateTestCasePopup" class="commonPopUp">
  <div class="commonPopUp-backdrop" (click)="toggleCreateTestCasePopup()"></div>
  <div class="commonPopUp-wrapper">
    <div class="common-Loader commonPopup-Loader" *ngIf="waitForLoader">
      <img src="../../assets/images/storyBoardLoader.gif" alt=""/>
    </div>
    <div class="commonPopUp-title">Create Test Case</div>
    <div class="commonPopUp-form-group">
      <input #testcaseTitle type="text" class="form-control"  [(ngModel)]="testCaseTitle" (keyup.enter)="createTestCase()" placeholder="Add Test Case Title">
    </div>
    <div class="commonPopUp-actionBtn flex-btns">
      <span class="commonPopUp-submit-s"  (click)="createTestCase()">Create</span>
      <span class="commonPopUp-cancel-s" (click)="toggleCreateTestCasePopup()">Cancel</span>
    </div>
  </div>
</div>

<div *ngIf="OpencommonPopUp" class="commonPopUp">
  <div class="commonPopUp-backdrop" (click)="closecommonPopUp()"></div>
  <div class="commonPopUp-wrapper">
    <div class="common-Loader commonPopup-Loader" *ngIf="waitForLoader">
      <img src="../../assets/images/storyBoardLoader.gif" alt=""/>
    </div>
    <div class="commonPopUp-title">Delete</div>
    <div class="commonPopUp-text" >Are you sure you want to delete this test case. Please confirm as it cannot be retrieved.</div>
    <div class="commonPopUp-actionBtn flex-btns">
      <span class="commonPopUp-submit-s" (click)="archive()">Confirm</span>
      <span class="commonPopUp-cancel-s" (click)="closecommonPopUp()">Cancel</span>
    </div>
  </div>
</div>

<div class="warningPopUp" *ngIf = "openCreateBugPopup">
  <div class="warningPopUp-backdrop" (click)="closeCreateBugPopup()"></div>
  <div class="warningPopUp-wrapper bugSelection-wrapper">
    <em class="closeFun iconb-close" (click)="closeCreateBugPopup()"></em>
    <div class="warningPopUp-title">Create a Bug</div>
    <div class="bugReason-listWrapper">
      <div class="relatedToWrapper form-group">
        <input #testcaseBugTitle type="text" class="addRelated-input"  [(ngModel)]="testCaseBugTitle" placeholder="Add Bug title">
      </div>
      <div class="bugreason-selectBlock form-group">
        <div class="selected-bugreason" (click)="openReasonsLists()">{{ bugReason() }}<em class="iconb-dd-down" [ngClass]="{'makeDown' : showReasonList }"></em></div>
        <div class="dd-wrapper" *ngIf="showReasonList">
          <div class="bugReason-list" *ngFor="let bugReason of project?.mainBugReasons" (click)="selectBugReason(bugReason)">{{bugReason?.title}}</div>
        </div>
      </div>
      <div class="bugreason-selectBlock form-group">
        <div class="ifReadOnly" *ngIf="!bug_reason_id"></div>
        <div class="selected-bugreason" (click)="openDetailReasonsLists()">{{ bugDetailReason() }}<em class="iconb-dd-down" [ngClass]="{'makeDown' : showDetailReasonList }"></em></div>
        <div class="dd-wrapper" *ngIf="showDetailReasonList">
          <div class="bugReason-list" *ngFor="let detailbugReason of project?.detailedBugReason(bug_reason_id)" (click)="selectBugReason(detailbugReason, 'detailedReason')">{{detailbugReason?.title}}</div>
        </div>
      </div>
      <div class="relatedToWrapper" *ngIf = "(currentStoryTypeChange && !story.bug_related_to_story?.id) || !currentStoryTypeChange">
        <div class="relatedTo-list" *ngIf = "bug_related_to_story?.id">
          <span class="relatedTo-storyId">[#{{bug_related_to_story?.id}}]</span> {{bug_related_to_story?.title}}
        </div>
      </div>
    <div class="logReason-actionBt">
      <button type="button" class="warningPopUp-submit" [disabled]="!bug_reason_detail_id || testCaseBugTitle.length < 1 || !bug_related_to_story?.id" (click)="createTestCaseBug()">Submit</button>
      <span class="warningPopUp-cancel" (click) = "closeCreateBugPopup()" >Cancel</span>
    </div>
  </div>
</div>
</div>


<div *ngIf="failedTestCasePopup" class="commonPopUp">
  <div class="commonPopUp-backdrop" (click)="closeFailedTestCasePopup()"></div>
  <div class="commonPopUp-wrapper">
    <div class="commonPopUp-text" >This test case is marked as failed, please create bug for the same to log the issue.</div>
    <div class="commonPopUp-actionBtn flex-btns">
      <span class="commonPopUp-submit-s" (click)="createBug()">Create Bug</span>
      <span class="commonPopUp-cancel-s" (click)="closeFailedTestCasePopup()">Cancel</span>
    </div>
  </div>
</div>

<app-story-clone-popup *ngIf="projectService.cloneConfirmPopup" [project]="project"></app-story-clone-popup>

<app-accept-reject-popup *ngIf="projectService.acceptRejectPopup" [popupType]="projectService.acceptRejectPopupType" [project]="project"></app-accept-reject-popup>