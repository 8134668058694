import { Component, OnInit } from '@angular/core';
import { EVENT_TYPES} from '../shared/dataTypes';
import { Project} from '../shared/models/project';
import { ErrorService } from '../shared/errorFunction';
import { NotificationService } from '../shared/services/notification.service';
import { PolicyService } from '../shared/services/policy.service';
import { ProjectService } from '../shared/services/project.service';
import { UserService } from '../shared/services/user.service';

import moment from 'moment-timezone';

import { Router } from '@angular/router';
import { CustomToastService } from '../shared/services/custom-toast.service';
import { SharedService } from '../shared/services/shared.service';
import {AdminUsersService} from '../admin/users';

@Component({
  selector: 'app-member',
  templateUrl: './member.component.html',
  styleUrls: ['./member.component.scss'],
  host: {
    '(window:click)': 'onClick($event)'
  }
})
export class MemberComponent implements OnInit {
  model: any = {};
  offsetTmz = [];
  roleData = [{id: 'admin', value: 'Admin'}, {id: 'user', value: 'User'}];
  localeData = [];
  projectId: any;
  outputErr:any;
  selectDomainDD:boolean = false;
  constructor(private router: Router,
              private errorService: ErrorService,
              public projectService: ProjectService,
              private policyService: PolicyService,
              public sharedService: SharedService,
              public userService: UserService,
              private usersService: AdminUsersService,
              private notificationService: NotificationService,
              private customToast: CustomToastService) { }

  get isAdmin(): boolean {
    return this.policyService.isAdmin;
  }

  ngOnInit() {
    if (localStorage.getItem('projectId')) {
      this.projectId =  JSON.parse(localStorage.getItem('projectId'));
    }
    this.getallRoleLocale('roles', 'locales');
    for (const i in moment.tz.names()) {
      if (i) {
        this.offsetTmz.push({'id': moment.tz.names()[i], 'value': moment.tz.names()[i] + ' (GMT' + moment.tz(moment.tz.names()[i]).format('Z') + ')'});

      }
    }
    this.model.time_zone = (this.model.time_zone ? this.model.time_zone : 'US/Pacific');
    this.model.selected_user_domain = this.userService.partner.allowed_user_domain.length > 0 ?
                                        this.userService.partner.allowed_user_domain[0] : '';
  }

  onSubmit(isAdd?) {
    if (this.usersService.isNewUserAdded) {
      this.addMemberFromAdminMembers(isAdd);
    } else if (this.projectService.isNewMemberAdded) {
      this.addMemberFromProjectDashboard(isAdd);
    }
  }

  addMemberFromAdminMembers(isAdd?) {
    if (!this.model.role) {
      this.model.role = 'user';
    }
    this.usersService.createUser(this.model, this.fullEmail).subscribe((member: any) => {
      this.notificationService.broadcast(EVENT_TYPES.USER.NEW_USER_ADDED, {});
      this.customToast.messages.push({
        id: 'UserAdded', type: 'success',
        class: 'new_member_added',
        title: 'User Created',
        message: 'New user created successfully'
      });
      if (isAdd) {
        this.sharedService.showUserForm = true;
      } else {
        this.sharedService.showUserForm = false;
        this.usersService.isNewUserAdded = false;
      }
      delete this.model.email;
      delete this.model.name;
      delete this.model.initials;
      this.model.role = 'user';
      this.model.time_zone = 'US/Pacific';
    }, (error) => {
      this.errorService.errorFunction(error);
      const err = JSON.parse(error._body);
      this.outputErr = err;
    });
  }

  addMemberFromProjectDashboard(isAdd?) {
    if (!this.model.role) {
      this.model.role = 'user';
    }
    this.userService.createMember(this.model, this.fullEmail, this.projectId).subscribe((member: any) => {
      this.notificationService.broadcast(EVENT_TYPES.PROJECT.NEW_MEMBER_ADDED, {});
      this.customToast.messages.push({
        id: 'MemberAdded', type: 'success',
        class: 'new_member_added',
        title: 'Membership Info',
        message: 'New member added successfully'
      });
      if (isAdd) {
        this.sharedService.showUserForm = true;
      } else {
        this.sharedService.showUserForm = false;
        this.projectService.isNewMemberAdded = false;
      }
      delete this.model.name;
      delete this.model.email;
      delete this.model.initials;
      this.model.role = 'user';
      this.model.time_zone = 'US/Pacific';
    }, (error) => {
      this.errorService.errorFunction(error);
      const err = JSON.parse(error._body);
      this.outputErr = err;
    });
  }

  removeError(type) {
    if (this.outputErr && this.outputErr[type]) {
      delete this.outputErr[type];
    }
  }

  getallRoleLocale(roles, locales) {
    // For get all Roles
    // this.userService.getallRoles(roles).subscribe((data: any) => {
    //   data.forEach(role => {
    //     let obj = {id: '', value: ''};
    //     obj.value = role.charAt(0).toUpperCase() + role.slice(1);
    //     obj.id = role;
    //     this.roleData.push(obj);
    //   });
    //   this.model.role = 'admin';
    // });

    this.model.role = 'admin';
    // For get all Locale
    this.userService.getallLocale(locales).subscribe((res: any) => {
      this.localeData = res;
    });
  }

  checkEmail(email) {
    let re = /^(?=.{1,256})([a-zA-Z0-9][a-zA-Z0-9._%+-]*@[a-zA-Z0-9][a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$)/;
    return re.test(email);
  }

  get fullEmail() {
    return this.model.email + '@' + this.model.selected_user_domain;
  }
  openDomainSelection() {
    this.selectDomainDD = !this.selectDomainDD;
  }
  closeDomainSelection(user_domain?) {
    this.selectDomainDD = false;
    if (user_domain) {
      this.model.selected_user_domain = user_domain;
    }
  }

  onClick(event) {
    const element = event.target;
    if (!(element.classList.contains('iconb-dd-down') || element.classList.contains('selectedDomain'))) {
      this.closeDomainSelection();
    }
  }
}
