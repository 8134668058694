<div class="storyBoard-navigation">
  <div class="navigation-list">
    <!-- <div class="navList-item" role="presentation" routerLink="/admin/dashboard" routerLinkActive="active">
      <em class="iconb-total-activeuser"></em>
      <span class="navigation-listName">Dashboard</span>
    </div> -->
    <!--<div class="navList-item" (click)="projectService.closeProjectDD()" role="presentation" routerLink="/admin/users" routerLinkActive="active">
      <em class="iconb-members"></em>
      <span class="navigation-listName">Members</span>
      <span class="menuListHover">Members</span>
    </div>-->
    <!--<div class="navList-item" (click)="projectService.closeProjectDD()" role="presentation" routerLink="/admin/projects" routerLinkActive="active">
      <em class="iconb-total-project"></em>
      <span class="navigation-listName">Projects</span>
      <span class="menuListHover">Projects</span>
    </div>-->
    <!--<div class="navList-item" (click)="projectService.closeProjectDD()" role="presentation" routerLink="/admin/timing" routerLinkActive="active">
      <em class="iconb-timings"></em>
      <span class="navigation-listName">Timings</span>
      <span class="menuListHover">Timings</span>
    </div>-->
  </div>
</div>
