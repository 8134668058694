import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { StoryApprovalService } from '../../shared/services/story-approval.service';
import { StoryReviewEpic } from '../../shared/models/storyReviewEpic';
import { StoryReviewUsers } from '../../shared/models/storyReviewUsers';
import { ErrorService } from '../../shared/errorFunction';
import { APPROVAL_STATUS_CHECKLIST,  LIST_OF_STORYAPPROVAL_FILTERS } from '../../shared/dataTypes';
import moment from 'moment';
import * as _ from 'lodash';
import { StoryRequestUsers } from '../../shared/models/storyRquestUsers';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-approvallist-filter',
  templateUrl: './approvallist-filter.component.html',
  styleUrls: ['./approvallist-filter.component.scss']
})
export class ApprovallistFilterComponent implements OnInit {
  showSelectedFilterType: String = 'status';
  meta_for_scope = {
    epics_meta: null,
    requestors_meta: null,
    reviewers_meta: null
  }
  ranges: any = {
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'Last 3 Month': [moment().subtract(3, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'Last 6 Month': [moment().subtract(6, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'Last 12 Month': [moment().subtract(12, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  };
  requested_on_date: { startDate: moment.Moment, endDate: moment.Moment };
  reviewed_on_date: { startDate: moment.Moment, endDate: moment.Moment };
  filterList = LIST_OF_STORYAPPROVAL_FILTERS;
  statusList = APPROVAL_STATUS_CHECKLIST;
  featureList: Array<StoryReviewEpic> = [];
  requestorList: Array<StoryRequestUsers> = [];
  reviewerList: Array<StoryReviewUsers> = [];
  scopeConfig = {
    epics: {
      listName: 'featureList',
      constructor: StoryReviewEpic
    },
    requestors: {
      listName: 'requestorList',
      constructor: StoryRequestUsers
    },
    reviewers: {
      listName: 'reviewerList',
      constructor: StoryReviewUsers
    }
  };
  @Output() filterApplied = new EventEmitter<{event:any, key: any, value: any }>();
  @Output() resetFilter = new EventEmitter<{event:any, key: any}>();
  searchRequestor = '';
  searchReviewer = '';
  searchFeature = '';
  filterDataSubscription:  Subscription;
  filter_loader:boolean = false;

  constructor(
    public storyApprovalService: StoryApprovalService,
    private errorService: ErrorService,
  ) { }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.clearfilterDataSubscription();
  }

  clearfilterDataSubscription(){
    if (this.filterDataSubscription) {
      this.filterDataSubscription.unsubscribe();      
    }
  }

  showFilterOptions(filter_key: string){
    this.showSelectedFilterType = filter_key;
    if (_.includes(Object.keys(this.scopeConfig), filter_key)) {
      this.filter_loader = this[this.scopeConfig[filter_key].listName].length === 0;
      this.filterData(filter_key);
    } else if(filter_key === 'requested_on' || filter_key === 'reviewed_on') {
      this.initialseDateRange(filter_key);
    };
  }
  
  filterOption(event ,selected_key ,key) {
    selected_key.isSelected = event.target.checked;
    const selected_option = key === 'status' ? selected_key.value : selected_key.id;
    this.filterApplied.emit({ event: event.target, key: key, value: selected_option });
  }

  applyDateFilter(event: any, key, value) {
    this.filterApplied.emit({ event: event, key: key, value: value });
  }

  updateList(list, newData, comparator, constructor) {
    newData.forEach(item => {
      if (!list.find(comparator.bind(null, item))) {
        list.push(new constructor(item));
      }
    });
  }
  
  getSearchQuery(scope) {
    const searchQueries = {
      epics: this.searchFeature,
      requestors: this.searchRequestor,
      reviewers: this.searchReviewer
    };
    return searchQueries[scope] || '';
  }

  filterData(scope) {
    const config = this.scopeConfig[scope];
    const Constructor = config.constructor;
    const meta = this.meta_for_scope[`${scope}_meta`];
    const pageNo = meta ? meta.current_page : 1;
    this.showSelectedFilterType = scope;
    const queryString = this.getSearchQuery(scope);
    this.clearfilterDataSubscription();
    this.filterDataSubscription = this.storyApprovalService.getFilterData(scope, pageNo, queryString).subscribe(res => {
      this.filter_loader = false;
      this.meta_for_scope[`${scope}_meta`] = res.meta;
      this[config.listName] = meta && meta.current_page === 1 && (this[config.listName].length === 0 || queryString) ? [] : this[config.listName];
      this.updateList(this[config.listName], res.data, (a, b) => a.id === b.id, Constructor);
    }, error => {
      this.filter_loader = false;
      this.errorService.errorFunction(error)
    });
  }

  datesFilter(event,key) {
    if (event.startDate && event.endDate) {
      const start_date = moment(event.startDate).format('DD/MM/YYYY');
      const end_date = moment(event.endDate).format('DD/MM/YYYY');
      this.applyDateFilter(event, key, start_date + ',' + end_date);
    }
  }

  clearDate(event,key) {
    this.applyDateFilter(event, key, null);
    this.initialseDateRange(key)
  }

  clearAllFilters(event, key) {
    for (let list in this.scopeConfig) {
      if (this[this.scopeConfig[list].listName].length > 0) {
        this[this.scopeConfig[list].listName].forEach(item => {
          item.isSelected = false;
        });
      }
    }
    this.statusList.forEach(item => {
      item.isSelected = false;
    });
    this.requested_on_date = null;
    this.reviewed_on_date = null;
    this.searchRequestor = '';
    this.searchReviewer = '';
    this.searchFeature = '';
    const resetDynamicData =  _.includes(('epics,requestors,reviewers').split(','), this.showSelectedFilterType);
    if(resetDynamicData){
      this.filterData(this.showSelectedFilterType);
    }
    this.resetFilter.emit({ event: event.target, key: key });
  }

  initialseDateRange(key) {
    if (this.storyApprovalService.filters[key].length === 0) {
      this[key + '_date'] = null;
    }
  }

}