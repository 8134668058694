import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'example',
    templateUrl: 'example.component.html',
    styleUrls: ['example.component.scss']
})
export class ExampleComponent {

    private showImprint: boolean = false;

    constructor() {
        // This is intentional      
    }

}