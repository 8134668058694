<div class="row">
  <div class="col-sm-4">
    <label>Selected week</label>
    <div class="week-switcher">
      <button type="button"
              class="btn btn-default"
              [disabled]="isPending"
              (click)="onWeekPickerChanged('backward')">
        <span class="glyphicon glyphicon-chevron-left"></span>
      </button>
      <button type="button"
              class="btn btn-default"
              (click)="onWeekPickerChanged('forward')"
              [disabled]="isPending">
        <span class="glyphicon glyphicon-chevron-right"></span>
      </button>
      <div class="selected-week">
        {{weeksSwitcherLabel}}
      </div>
    </div>
  </div>

  <div class="col-sm-4 col-sm-offset-4">
    <label>Date range</label>
    <div class="input-group date-panel">
      <input hidden
             angular-mydatepicker
             #dp="angular-mydatepicker"
             (dateChanged)="onDateRangePickerChanged($event)">
      <input type="text"
             readonly
             class="form-control week-calendar"
             name="dateRange"
             [ngModel]="dateRangePickerLabel"
             (click)="dp.openCalendar()">
      <div class="input-group-btn">
        <button class="btn btn-link"
                [disabled]="isPending"
                (click)="dp.toggleCalendar()">
          <em class="iconb-calendar"></em>
        </button>
      </div>
    </div>
  </div>
</div>
