<div class="container">

    <div class="row">
        <div class="col-md-12">
            <div class="card card-block card-success card-inverse">
                <h4 class="card-title">Current User Status</h4>

                <!-- <div class="row current-user">
                    <div class="col-md-4">
                        <p><b>Signed In?</b> {{_tokenService.currentUserData != null}} </p>
                    </div>
                    <div class="col-md-4">
                        <p><b>Email:</b> {{_tokenService.currentUserData?.email}} </p>
                    </div>
                    <div class="col-md-4">
                        <p><b>UserType:</b> {{_tokenService.currentUser}} </p>
                    </div>
                </div> -->

            </div>
        </div>
    </div>

<!--     <div class="row">
        <register></register>
    </div>
 -->
    <div class="row">
        <sign-in></sign-in>
    </div>

    <div class="row">
        <sign-in-oauth></sign-in-oauth>
    </div>

    <div class="row">
        <access-resource></access-resource>
    </div>

    <div class="row">
        <div class="col-md-6">
            <div class="card card-block">
                <h4 class="card-title">Can Activate</h4>
                <a routerLink="/restricted" routerLinkActive="active">
                    <button type="submit" class="btn btn-primary">
                        Go To Restricted Site
                    </button>
                </a>
            </div>
        </div>
    </div>

<!--
    <div class="row">
        <change-password></change-password>
    </div>
 -->

    <div class="row">
        <validate-token></validate-token>
    </div>

    <div class="row">
        <sign-out></sign-out>
    </div>
</div>
