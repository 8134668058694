<div class="bulkaction-popUp" *ngIf="projectService.cloneConfirmPopup">
    <div class="bulkaction-popUpBackdrop" (click)="closeCloneConfirmationPopup()"></div>
    <div class="bulkaction-popUp-wrapper duplicateStories">
      <div class="bulkAction-Loader" *ngIf="projectService.clonePopupLoader">
        <img src="../../assets/images/storyBoardLoader.gif" alt="loader"/>
      </div>
      <div class="bulkaction-popUp-title">Duplicate Story</div>
      <div class="bulkaction-popUp-text">Are you sure you want to duplicate story in <strong>{{project.name}}</strong>?</div>
      <div class="bulkaction-indicateInfo">
        <span class="indicateInfo-icon-box">
          <span class="indicateInfo-typeIcon"></span>
        </span>
        <span class="bulkaction-indicateInfoText">The new stories will be created at the top of the To Do column.</span>
      </div>
      <div class="selectedStoriesList-wrapper">
        <div class="selectedStoriesList">
          <div class="selectedStoriesList-left">
            <div class="story-typeIcon">
              <em class="iconb-task featureIcon" *ngIf = "story.story_type == 'task'"></em>
              <em class="iconb-bug bugIcon" *ngIf = "story.story_type == 'bug'"></em>
            </div>
            <div class="story-NameBox">
              <span class="selected-storyName">{{story.title}}</span>
            </div>
            <div class="story-creation-reason-wrapper forBulk_duplicate" *ngIf="(story?.epics[0]?.task_done_count > 0) && story.story_type === 'task' && !story.parent_id">
              <div class="selected-reason" (click)="showReasonList()">{{projectService.selected_reason_option ? projectService.selected_reason_option : 'Select Reason'}}<em class="iconb-dd-down" [ngClass]="{'makeDown':projectService.story_creation_reason}"></em></div>
              <div class="selected-reason-list-wrapper" *ngIf="projectService.story_creation_reason">
                <div class="select-reason" (click)="projectService.selectCreationReason(creationReason.reason)" *ngFor="let creationReason of projectService.creationReasons">{{creationReason.reason}}</div>
              </div>
              <textarea *ngIf="projectService.other_reason" class="otherReason-input" (input)="projectService.getOtherReasonData()" placeholder="Please provide additional information" [(ngModel)]="projectService.otherReason"></textarea>
             </div>
          </div>
          <div class="selectedStoriesList-right" *ngIf="story?.epics.length > 0">
            <span class="selected-storyEpicName">{{story?.epics[0]?.title}}</span>
          </div>
        </div>
      </div>
      <div class="bulkaction-popUp-actionBtn">
        <div class="conditionForChild" *ngIf="hasParentSubtasks()">
          <div class="forChildSelection-check">
            <label class="customCheckFor-deleteSelection">
              <input type="checkbox" checked class="delete-selected-checkbox" (change)="toggleApplyOnSubTasks()" [checked]="projectService.apply_on_sub_tasks" hidden/>
              <span class="check-toggleDeleteSelection"></span>
            </label>
          </div>
          <div class="conditionForChild-title">Duplicate story with the sub tasks</div>
        </div>
        <button type="button" class="bulkaction-popUp-submit" [disabled]="checkStoriesReason()" (click) = "cloneStory()">Confirm</button>
        <button type="button" class="bulkaction-popUp-cancel" (click)="closeCloneConfirmationPopup()">Cancel</button>
      </div>
    </div>
  </div>