import { Component, OnInit, OnDestroy } from '@angular/core';
@Component({
  selector: 'app-admin',
  templateUrl: 'admin.component.html'
})
export class AdminComponent implements OnInit, OnDestroy {
  ngOnInit() {
    // this.renderer.addClass(document.body, 'admin-panel');
  }

  ngOnDestroy() {
    // this.renderer.removeClass(document.body, 'admin-panel');
  }

}
