import { Component, OnInit, ViewChild, ElementRef, HostListener, Renderer2 } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { PolicyService } from '../../shared/services/policy.service';
import { ProjectService } from '../../shared/services/project.service';
import { NotificationService } from '../../shared/services/notification.service';
import { ErrorService } from '../../shared/errorFunction';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { CustomToastService } from '../../shared/services/custom-toast.service';
import { Project } from "../../shared/models/project";
import { DevicePlatformData, EVENT_TYPES, STORY_PRIORITY_OPTIONS, STORY_TYPE_OPTIONS } from '../../shared/dataTypes';
import { Sprint } from "../../shared/models/sprint";
import { ProjectSyncService } from "../../shared/services/projectSync.service";
import { Story } from "../../shared/models/story";
import { User } from "../../shared/models/user";
import { Membership } from "../../shared/models/membership";
import { Epic } from "../../shared/models/epic";
import { transferArrayItem } from '@angular/cdk/drag-drop';
import { CommonStoryService } from '../../shared/services/common-story.service';
import { SprintService } from '../../shared/services/sprint.service';
import { EpicService } from '../../shared/services/epic.service';
import { UserService } from "../../shared/services/user.service";
import { StoryService } from "../../shared/services/story.service";
import { SharedService } from "../../shared/services/shared.service";
import { Subscription } from 'rxjs';
import moment from 'moment-timezone';

@Component({
  selector: 'app-sprint-detail',
  templateUrl: './sprint-detail.component.html',
  styleUrls: ['./sprint-detail.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({
          opacity: '0',
          top: '30px'
        }),
        animate(200, style({
          opacity: '1',
          top: '21px'
        }))
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate(200, style({
          opacity: '0',
          top: '30px'
        }))
      ])
    ]),
    trigger('fadeInOut2', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({
          opacity: '0',
          top: '30px'
        }),
        animate(100, style({
          opacity: '1',
          top: '21px'
        }))
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate(100, style({
          opacity: '0',
          top: '30px'
        }))
      ])
    ]),
    trigger('fadeInOut3', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({
          opacity:'0',
          top: '50px'
        }),
        animate(100, style({
          opacity:'1',
          top: '40px'
        }))
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate(200, style({
          opacity:'0',
          top: '50px'
        }))
      ])
    ])
  ],
  host: {
    '(document:click)': 'onClick($event)'
  }
})

export class SprintDetailComponent implements OnInit {
  @ViewChild('makeFocusName') makeFocusName: ElementRef;
  @ViewChild('sprintStoryscrollableDiv') sprintStoryscrollableDiv: ElementRef;
  editOptionDD: boolean = false;
  project: Project;
  projectId?: number;
  currentUser: User;
  sprintId?: number;
  sprint_Details: Sprint;
  sprintDetailinitialLoader: boolean = false;
  public todayDate: Date = new Date();
  currentYear: any = this.todayDate.getUTCFullYear();
  currentDate: any = this.todayDate.getUTCDate() - 1;
  currentMonth: any = this.todayDate.getUTCMonth() + 1;
  sprintDeletePopUp: boolean = false;
  sprintStories: Array<Story> = [];
  story: Story;
  waitForLoader: boolean = false;
  whereFrom = 'from_sprint_detail';
  memberships: Array<Membership> = [];
  epics: Array<Epic> = [];
  labels: Array<any> = [];
  formatted_platforms?: Array<any>;
  status?: string;
  projectDevicePlatformsData?: Array<DevicePlatformData>;
  searchMemberForFilter: String = '';
  storyPriorityOptions: Array<any> = STORY_PRIORITY_OPTIONS;
  storyTypeOptions: Array<any> = STORY_TYPE_OPTIONS;
  sort_by = 'updated_at';
  sprintStartActionPopUp: boolean = false;
  canStartSprint: boolean = false;
  canCompleteSprint: boolean = false;
  sprintCompletetActionPopUp: boolean = false;
  sprintActionType = '';
  sprintPausePopUp: boolean = false;
  nxt_page = 1;
  inSprintStoryMeta: {
    current_page: number;
    next_page: number;
    per_page: number;
    total_count: number;
  };
  inSprintStory_nxt_page = 1;
  fromAddedbacklog: boolean = false;
  isPending: boolean = false;
  loadmoreStories: boolean = false;
  demoDatePickerOptions = {
    dateFormat: 'dd/mm/yyyy',
    openSelectorTopOfInput: false,
    focusInputOnDateSelect: false,
    showSelectorArrow: false,
    alignSelectorRight: false,
    disableUntil: { year: this.currentYear, month: this.currentMonth, day: this.currentDate }
  };

  devReleaseDateOptions = {
    dateFormat: 'dd/mm/yyyy',
    openSelectorTopOfInput: false,
    focusInputOnDateSelect: false,
    showSelectorArrow: false,
    alignSelectorRight: false,
    appendSelectorToBody: true,
    disableUntil: { year: this.currentYear, month: this.currentMonth, day: this.currentDate },
    disableSince: this.setDisableSince(),
  };
  externalDemoDate: any;
  internalDemoDate: any;
  restrict_delete_socket: boolean = false;
  popupFor: string = '';
  activitySidebarOpen = false;
  showSort:boolean = false;
  filterLoader: boolean = false;
  private notificationSubscriptions: Subscription[] = [];
  sprintselectionDropdown = false;
  moveStoriesToSprint = false;
  selected_stories = [];
  select_all_stories: boolean = false;
  spillOverStoryCount: number;
  sprintMoveOptions = [];
  selectedSprintForMove = [];

  constructor(public projectService: ProjectService,
    private errorService: ErrorService,
    public policyService: PolicyService,
    private notificationService: NotificationService,
    private customToast: CustomToastService,
    private router: Router,
    private userService: UserService,
    private projectSyncService: ProjectSyncService,
    private storyService: StoryService,
    private sharedService: SharedService,
    private renderer: Renderer2,
    private epicService: EpicService,
    private activatedRoute: ActivatedRoute,
    public commonStoryService: CommonStoryService,
    public sprintService: SprintService) { }


  ngOnInit() {
    this.sprintDetailinitialLoader = true;
    this.loadmoreStories = true;
    const queryIndex = window.location.href.indexOf('?');
    const queryUrl = window.location.href.substr(queryIndex + 1);
    const registerQueryParam = queryUrl.substr(0, queryUrl.indexOf('='));
    const registerQueryParamValue = queryUrl.substr(queryUrl.indexOf('=') + 1);
    this.activatedRoute.params.subscribe((params: Params) => {
      this.projectId = +params['id'];
      this.sprintId = +params['sprint_id'];
    });
    this.projectService.sprintDetailPageTitle = true;
    this.projectService.initiate_from = 'from_sprint_detail';
    this.projectService.sprintId = this.sprintId;
    this.projectSyncService.subscribe(this);
    this.projectService.project(this.projectId, true).subscribe(project => {
      this.project = project;
      if (registerQueryParam === 'storyId') {
        setTimeout(() => {
          this.projectService.show_StoryDetails(this.project, '', registerQueryParamValue, false, this.whereFrom, this.sprintId);
        }, 500);
      }
      this.notificationService.broadcast(EVENT_TYPES.PROJECT.SHOW, {
        data: {
          project: this.project
        }
      });
      this.project.memberships = [];
      if (this.projectService.current_user_role === 'read_only') {
        this.router.navigate(['/projects/' + this.project.id]);
        return;
      }
      this.projectService.loadUsers(this.project);
      this.project.phases = [];
      this.project.tracker_phases = [];
      if (this.project && !this.project.dashboard_project_id) {
        this.project.phases = ['Product Roadmap', 'Design', 'Prototype', 'MVP', 'Full Build'];
        this.project.phases.forEach(item => {
          const tracker_phase = {
            'tracker_phase_name': item,
            'phase_name': item
          };
          this.project.tracker_phases.push(tracker_phase);
        });
      } else {
        this.projectService.projectPhasesNBrief(this.project).subscribe(res => {
          this.project.internal_mattermost_channel_url = res.data.internal_mattermost_channel_url;

          for (let i = 0; i < res.data.trackerphasesettings.length; i++) {
            this.project.phases.push(res.data.trackerphasesettings[i].phase_name);

            for (let j = 0; j < res.data.trackerphasesettings[i].tracker_phases.length; j++) {
              const tracker_phase = {
                'tracker_phase_name': res.data.trackerphasesettings[i].tracker_phases[j],
                'phase_name': res.data.trackerphasesettings[i].phase_name
              };
              this.project.tracker_phases.push(tracker_phase);
            }
          }
        }, (error) => {
          this.project.internal_mattermost_channel_url = null;
        });
      }
      this.loadEpics();
      this.projectService.project_labels(this.project).subscribe(data => {
        this.project.labels = data;
      }, (error) => {
        this.errorService.errorFunction(error);
      });

    }, err => {
      this.errorService.errorFunction(err);
    });
    this.loadSprintDetail(this.projectId, this.sprintId);
    const sprintUpdatedEvent = this.notificationService.subscribeEvent(EVENT_TYPES.SPRINT.SPRINT_UPDATED, res => {
      if (this.sprint_Details.id === res.payload.sprint.id) {
        this.sprint_Details = new Sprint(res.payload.sprint);
        this.sprintDurationAction();
      }
      if (res.payload.sprint.status === 'completed') {
        const index = this.sprintMoveOptions.findIndex(sprint => sprint.id === res.payload.sprint.id)
        if (index !== -1 ) {
          this.sprintMoveOptions.splice(index,1)
        }
      }
    });
    this.notificationSubscriptions.push(sprintUpdatedEvent);

    const duplicateAfterConfimationEvent = this.notificationService.subscribeEvent(EVENT_TYPES.STORY.SPRINT_STORY_DUPLICATE, res => {
      this.renderer.removeClass(document.body, 'duplicateStoryPopup-open'); 
    });
    this.notificationSubscriptions.push(duplicateAfterConfimationEvent);

    const sprintStoryCloned = this.notificationService.subscribeEvent(EVENT_TYPES.STORY.SPRINT_STORY_CLONED, res => {
      if (res && res.payload.data && res.payload.data.story.sprint_id === this.sprint_Details.id) {
        this.commonStoryService.addStories(this.sprintStories, res.payload.data.story);
      }
    });
    this.notificationSubscriptions.push(sprintStoryCloned);

    const storyArchive = this.notificationService.subscribeEvent(EVENT_TYPES.STORY.ARCHIVE, res => {
      if (res && res.payload.data.story_in === 'sprint_story' && res.payload.data.story.deleted_at) {
        this.removeStoryFromList(res.payload.data.story);
      }
    });
    this.notificationSubscriptions.push(storyArchive);

    const sprintStoryUpdated = this.notificationService.subscribeEvent(EVENT_TYPES.STORY.SPRINT_STORY_UPDATED, res => {
      if (res && res.payload.changes) {
        this.commonStoryService.updateStories(this.sprintStories, res.payload);
      }
    });
    this.notificationSubscriptions.push(sprintStoryUpdated);

    const sprintStoryBulkRemoved = this.notificationService.subscribeEvent(EVENT_TYPES.STORY.SPRINT_STORY_REMOVED, res => {
      if (res.payload.action === 'bulk_archive') {
        this.commonStoryService.removeMultipleStories(this.sprintStories, res.payload);
      }
    });
    this.notificationSubscriptions.push(sprintStoryBulkRemoved);

    const sprintDeletedEvent = this.notificationService.subscribeEvent(EVENT_TYPES.SPRINT.SPRINT_DELETED, res => {
      if (res && (this.sprintId === res.payload.sprint.id) && !this.restrict_delete_socket) {
        this.router.navigate(['/projects/' + this.projectId + '/sprint']);
      }
    });
    this.notificationSubscriptions.push(sprintDeletedEvent);

    const sprintStoryAdded = this.notificationService.subscribeEvent(EVENT_TYPES.SPRINT.SPRINT_STORY_ADDED, res => {
      if (res && (this.sprintId === res.payload.sprint.id) && (res.payload.stories.length > 0)) {
        this.resetStorySelection();
        res.payload.stories.forEach(as => {
          let ind = this.sprintStories.findIndex(sprint_story => sprint_story.id === as.id);
          if (ind === -1) {
            let story;
            if (this.projectService.show_Story && as.id === this.projectService.show_Story.id) {
              story = this.projectService.show_Story;
            }else{
              story = new Story(as);
            } 
            this.sprintStories.unshift(story);
          }
        });
      }
    });
    this.notificationSubscriptions.push(sprintStoryAdded);

    const sprintStoryRemoved = this.notificationService.subscribeEvent(EVENT_TYPES.SPRINT.SPRINT_STORY_REMOVED, res => {
      if (res && (this.sprintId === res.payload.sprint.id) && (res.payload.stories.length > 0)) {
        res.payload.stories.forEach(as => {
          this.removeStoryFromList(as);
        });
      }
    });
    this.notificationSubscriptions.push(sprintStoryRemoved);

    const storyAdded = this.notificationService.subscribeEvent(EVENT_TYPES.SPRINT.BACKLOG_STORY_REMOVED, res => {
      const perPage = this.inSprintStoryMeta.current_page * this.inSprintStoryMeta.per_page
      this.projectService.getSprintsDetailsAddedStories(this.projectId, this.sort_by, this.sprintId, 1, perPage ).subscribe(res => {
        if (res) {
          this.sprintStories = [];
          this.setDisableSince();
          res.data.forEach((storyData) => {
            const story = new Story(storyData);
            this.sprintStories.push(story);
          });
          this.inSprintStoryMeta = res.meta;
          this.isPending = false;
          this.filterLoader = false;
          this.projectService.storyAddedListLoader = false;
          this.loadmoreStories = false;
        }
      }, err => {
        this.errorService.errorFunction(err);
        this.filterLoader = false;
        this.projectService.storyAddedListLoader = false;
      });
    });
    this.notificationSubscriptions.push(storyAdded);

    const storyRemoved = this.notificationService.subscribeEvent(EVENT_TYPES.SPRINT.BACKLOG_STORY_ADDED, res => {
      const element = this.sprintStoryscrollableDiv.nativeElement;
      const atBottom = element.scrollHeight - element.scrollTop === element.clientHeight;
      if(atBottom) {
        this.filterLoader = true;
        this.nxt_page = 1;
        this.sprintStories = [];
        this.loadSprintAddedStories(this.projectId, this.sort_by, this.sprintId);
      }
    });
    this.notificationSubscriptions.push(storyRemoved);

  }

  resetStorySelection() {
    this.select_all_stories = false;
    this.selected_stories = [];
    this.sprintStories.forEach(story => {
      story.isSelected = false;
    });
  }

  allStorySelection($event) {
    if($event.target.checked) {
      this.select_all_stories = true;
      this.selected_stories = [];
      this.sprintStories.forEach(story => {
        story.isSelected = true;
        this.selected_stories.push(story.id);
      });
    } else {
      this.select_all_stories = false;
      this.sprintStories.forEach(story => {
        story.isSelected = false;
      });
      this.selected_stories = [];
    }
  }

  toggleStorySelection(event, story: Story) {
    if (event && event.target.checked) {
      story.isSelected = true;
      this.selected_stories.push(story.id); 
      this.checkAllSelection();
    } else {
      story.isSelected = false;
      // find the index of story in selected_story array and remove it
      const index = this.selected_stories.findIndex(st => st === story.id);
      if (index !== -1) {
        this.selected_stories.splice(index, 1);
      }
      this.checkAllSelection();
    }
  }

  checkAllSelection() {
    if ((this.selected_stories.length === this.sprintStories.length) && this.sprintStories.length > 0) {
      this.select_all_stories = true;
    } else {
      this.select_all_stories = false;
    }
  }

  bulkStoriesRemove() {
    this.loadmoreStories = true;
    const payload = {
      story_ids: this.selected_stories,
    };
    this.projectService.removeStoryFromSprint(this.projectId, this.sprintId, payload).subscribe(res => {
      if (res) {
        this.selected_stories = [];
        this.customToast.messages.push({
          id: 'story_removed',
          type: 'success',
          class: 'new_member_added',
          title: 'Sprint Stories',
          message: 'Stories removed from sprint successfully'
        });
        this.resetStorySelection();
        this.loadmoreStories = false;
      }
    }, err => {
      this.errorService.errorFunction(err)
      this.loadmoreStories = false;
    });
  }


  removeStoryFromList(data) {
    const index = this.sprintStories.findIndex(st => st.id === data.id);
    this.inSprintStoryMeta.total_count = this.inSprintStoryMeta.total_count - 1;
    if (index !== -1) {
      this.sprintStories.splice(index, 1);
    }
  }

  setDisableSince() {
    let getdates;
    if (this.sprint_Details && this.sprint_Details.projected_end_date) {
      getdates = new Date(this.sprint_Details.projected_end_date);
    }
    return { year: getdates ? getdates.getFullYear() : this.currentYear, month: getdates ? (getdates.getMonth() + 1) : this.currentMonth, day: getdates ? (getdates.getDate() + 1) : this.currentDate };
  }

  ngOnDestroy() {
    this.projectSyncService.unsubscribe();
    this.notificationSubscriptions.forEach(subscription => subscription.unsubscribe());
    this.projectService.sprintDetailPageTitle = false;
    this.projectService.initiate_from = '';
    this.projectService.sprintId = null;
    this.projectService.sprintDetailFilter = false;
    if (this.projectService.show_storyDetails) {
      this.projectService.hide_StoryDetails_if_it_is_open();
    }
    if (this.projectService.trackEventSubscription) {
      this.projectService.trackEventSubscription.unsubscribe();
    }
    this.projectService.show_hide_backlog = false;
  }

  onClick(event) {
    const element = event.target;
    this.commonStoryService.checkOnClick(element);
    if (!(element.classList.contains('sprint-story-sorting-options') || element.classList.contains('selectedSprintFilter') || element.classList.contains('iconb-sorting'))) {
      this.showSort = false;
    }
    if (!(element.classList.contains('sprintInfo-edit') || element.classList.contains('showOptions'))) {
      this.editOptionDD = false;
    }
    // if (!element.classList.contains('bulkaction-popUp')) {
    //     this.renderer.removeClass(document.body, 'duplicateStoryPopup-open');
    // }
  }

  updateDemoDate(event, demo_type) {
    this.sprint_Details[demo_type] = event.singleDate.formatted;
    this.sprintService.updateSprintDetails(this.sprint_Details, this.projectId);
  }

  showSprintDelete() {
    this.sprintDeletePopUp = true;
  }

  closeSprintDelete() {
    this.sprintDeletePopUp = false;
  }

  deleteSprint() {
    if (this.sprint_Details && this.sprint_Details.id) {
      const todayDate: Date = new Date();
      this.waitForLoader = true;
      this.restrict_delete_socket = false;
      this.sprint_Details['deleted_at'] = todayDate;
      this.projectService.updateSprint(this.sprint_Details, this.projectId).subscribe((st) => {
        this.waitForLoader = false;
        this.sprintDeletePopUp = false;
        this.customToast.messages.push({
          id: 'story_deleted',
          type: 'success',
          class: 'story_delete',
          title: 'Sprint Delete',
          message: 'You have deleted sprint successfully'
        });
        this.router.navigate(['/projects/' + this.projectId + '/sprint']);
      }, (error) => {
        this.errorService.errorFunction(error);
      });
    }
  }

  editSprintDetails(sprint_Details) {
    this.popupFor = 'edit_sprint';
    this.sprintService.editSprint = true;
  }

  drop(event) {
    if (event.previousContainer === event.container) {
      return;
    } else {
      this.filterLoader = true;
      let story_InDrop: any;
      story_InDrop = {
        story_ids: event.previousContainer.data[event.previousIndex].id,
      };
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
      this.projectService.addStoryIntoSprint(this.projectId, this.sprintId, story_InDrop).subscribe(res => {
        if (res) {
          this.customToast.messages.push({
            id: 'story_added',
            type: 'success',
            class: 'new_member_added',
            title: 'Sprint Stories',
            message: 'Story added into sprint successfully'
          });
        }
      }, err => {
        transferArrayItem(event.container.data,
          event.previousContainer.data,
          event.currentIndex,
          event.previousIndex
        );
        this.errorService.errorFunction(err)
      });
    }
  }

  checkStoryAndSprint(): boolean {
    return (this.sprintStories.length === 1 && ((this.sprint_Details.status === 'running') || (this.sprint_Details.status === 'paused') || (this.sprint_Details.status === 'completed')));
  }

  sprintDurationAction() {
    this.sprint_Details.internal_demo_scheduled_at ? this.internalDemoDate = {isRange: false, singleDate: {jsDate: new Date(this.sprint_Details.internal_demo_scheduled_at)}} : this.internalDemoDate = null;
    this.sprint_Details.external_demo_scheduled_at ? this.externalDemoDate = {isRange: false, singleDate: {jsDate: new Date(this.sprint_Details.external_demo_scheduled_at)}} : this.externalDemoDate = null;
    this.setCalOptions();
  }

  setCalOptions() {
    this.devReleaseDateOptions = {
      dateFormat: 'dd/mm/yyyy',
      openSelectorTopOfInput: false,
      focusInputOnDateSelect: false,
      showSelectorArrow: false,
      alignSelectorRight: false,
      appendSelectorToBody: true,
      disableUntil: { year: this.currentYear, month: this.currentMonth, day: this.currentDate },
      disableSince: this.setDisableSince(),
    };
  }

  setDevReleaseDate(event, story) {
    story.dev_release_at = event.singleDate.formatted;
    story.dev_release_at_cal = false;
    this.commonStoryService.updateStory(story, 'dev_release', 'dev_release_date');
  }

  loadSprintDetail(projectId, sprintId) {
    this.projectService.sprintDetail(projectId, sprintId).subscribe(res => {
      if (res) {
        this.sprint_Details = new Sprint(res.data);
        this.sprintDurationAction();
        this.loadSprintAddedStories(this.projectId, this.sort_by, this.sprintId);
      }
      this.sprintDetailinitialLoader = false;
    }, err => this.errorService.errorFunction(err));
  }

  loadSprintAddedStories(projectId, sort_by, sprintId) {
    this.isPending = true;
    this.projectService.getSprintsDetailsAddedStories(projectId, sort_by, sprintId, this.nxt_page).subscribe(res => {
      if (res) {
        this.setDisableSince();
        res.data.forEach((storyData) => {
          const story = new Story(storyData);
          this.sprintStories.push(story);
        });
        this.inSprintStoryMeta = res.meta;
        this.isPending = false;
        this.filterLoader = false;
        this.projectService.storyAddedListLoader = false;
        this.loadmoreStories = false;
        this.checkAllSelection();
      }
    }, err => {
      this.errorService.errorFunction(err);
      this.filterLoader = false;
      this.projectService.storyAddedListLoader = false;
    });
  }

  isScrollFetchAvailable(): boolean {
    return !this.isPending && this.inSprintStoryMeta && this.inSprintStoryMeta.next_page != null;
  }

  getMoreAddedStories() {
    if (this.inSprintStoryMeta && this.inSprintStoryMeta.next_page) {
      this.nxt_page = this.inSprintStoryMeta.current_page + 1;
      this.loadSprintAddedStories(this.projectId, this.sort_by, this.sprintId);
    }
  }

  showEditStoryTitleInput(story) {
    story.showStoryTitleInput = true;
    setTimeout(() => {
      document.getElementById('sprintStoryfocusTitle').focus();
    }, 0);
  }

  getStoryURL(story_id) {
    return `${window.location.origin}/#/projects/${this.project.id}` + `?storyId=${story_id}`;
  }

  makeClone(story, fromWhere) {
    this.fromAddedbacklog = fromWhere;
    story.showMoreStoryAction = false;
    this.commonStoryService.dd_type = '';
    this.projectService.selectedStory = story;
    this.projectService.cloneConfirmPopup = true;
    this.renderer.addClass(document.body, 'duplicateStoryPopup-open');
    // this.commonStoryService.makeClone(story, this.project);
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.projectService.hide_StoryDetails();
  }

  showEditOption() {
    this.editOptionDD = !this.editOptionDD;
  }

  openStartSprintConfirmation(actionType) {
    if (!this.sprint_Details.projected_start_date) {
      this.sprintService.enterDateAlertPopup = true;
      return;
    }
    this.sprintActionType = actionType;
    this.sprintStartActionPopUp = true;
    this.canStartSprint = (actionType === 'start_sprint' && this.sprintStories.length > 0);
  }

  closeStartSprintConfirmation() {
    this.sprintStartActionPopUp = false;
    this.canCompleteSprint = false;
    this.sprintActionType = '';
  }

  openCompleteSprintConfirmation(actionType) {
    this.sprintActionType = actionType;
    const  params = {
      sprint_id: this.sprintId
    };
    this.projectService.getProjectSprints(this.projectId,params).subscribe(res => {
      if (res ) {
        this.spillOverStoryCount = res.meta.remainig_story_count
        this.sprintMoveOptions = res.data;
        this.spillOverStoryCount > 0 && res.data.length ? this.moveStoriesToSprint = true : this.sprintCompletetActionPopUp = true;
        this.selectedSprintForMove = [];
        const filterIfAllAccepted = this.sprintStories.filter(data => data.state !== 'accepted');
        this.canCompleteSprint = (actionType === 'completed' && filterIfAllAccepted.length === 0);
      }
    }, err => this.errorService.errorFunction(err));
  }

  closeCompleteSprintConfirmation() {
    this.sprintCompletetActionPopUp = false;
    this.canCompleteSprint = false;
    this.sprintActionType = '';
  }

  openPauseSprintConfirmation(actionType) {
    this.sprintActionType = actionType;
    this.sprintPausePopUp = true;
  }

  closePauseSprintConfirmation() {
    this.sprintPausePopUp = false;
    this.sprintActionType = '';
  }

  actionSprint(actionType: String) {
    this.waitForLoader = true;
    this.sprint_Details.showMoreAction = false;
    if (actionType === 'start_sprint' && this.sprint_Details.status === 'draft') {
      const startDate: Date = new Date(this.sprint_Details.projected_start_date);
      const endDate: Date = new Date(this.sprint_Details.projected_end_date);
      const currentDate: Date = new Date;
      if (startDate > currentDate) {
        this.sprint_Details.projected_start_date = this.sprint_Details.getDateFormat(this.todayDate);
      }
      this.sprint_Details.status = 'running';
      this.sprintStartActionPopUp = false;
    } else if (actionType === 'start_sprint') {
      this.sprint_Details.status = 'running';
      this.sprintStartActionPopUp = false;
    } else if (actionType === 'paused') {
      this.sprint_Details.status = 'paused';
    } else if (actionType === 'completed') {
      if (this.sprint_Details.futureEndDate(this.sprint_Details.projected_end_date)) {
        this.sprint_Details.projected_end_date = this.sprint_Details.getDateFormat(moment());
      }
      this.sprint_Details.status = 'completed';
    }

    this.projectService.updateSprint(this.sprint_Details, this.projectId).subscribe((res: any) => {
      this.sprintCompletetActionPopUp = false;
      this.sprintPausePopUp = false;
      this.waitForLoader = false;
      this.sprintActionType = '';
      this.customToast.messages.push({
        id: 'sprintUpdate', type: 'success',
        class: 'new_member_added',
        title: 'Sprint Update',
        message: 'Sprint updated successfully'
      });
    }, (error) => {
      this.sprintStartActionPopUp = false;
      this.sprintCompletetActionPopUp = false;
      this.sprintPausePopUp = false;
      this.waitForLoader = false;
      this.sprintActionType = '';
      this.errorService.errorFunction(error);
      const err = JSON.parse(error._body);
    });
  }

  loadEpics() {
    this.epicService.projectEpics(this.project)
      .subscribe((epics: Array<Epic>) => {
        this.project.addEpics(epics);
        if (this.project && this.project.dashboard_project_id != null) {
          this.loadAllFeaturedetails();
        }
        const payload = {
          data: {
            epics: this.project.epics
          }
        };
        this.notificationService.broadcast(EVENT_TYPES.EPIC.UPDATED, payload);
      }, (error) => {
        this.errorService.errorFunction(error);
      });
  }

  loadAllFeaturedetails() {
    this.epicService.allFeatureDetails(this.project).subscribe(res => {
      if (res && res.data && res.data.length > 0) {
        res.data.forEach(item => {
          const ind = this.project.epics.findIndex(obj => obj.builder_feature_id === item.builder_feature_id);
          if (ind >= 0) {
            this.project.epics[ind].featureDetailsData = item;
          }
        });
      }
    });
  }

  checkForCpmplete() {
    return (this.sprintStories.length > 0) && this.policyService.projectAdmin(this.project) && this.sprint_Details.status !== 'draft' && this.sprint_Details.status !== 'completed';
  }

  toggleActivityLogSidebar() {
    this.activitySidebarOpen = !this.activitySidebarOpen;
    document.body.classList.toggle("activity-sidebar-open");
  }

  closeMoveStoriesToSprint() {
    this.moveStoriesToSprint = false;
    this.sprintselectionDropdown = false; 
  }

  sprintselection() {
    this.sprintselectionDropdown = !this.sprintselectionDropdown; 
  }
  openSort() {
    this.showSort = !this.showSort;
  }

  selectSprintForMove(sprint){
    this.selectedSprintForMove = sprint;
    this.sprintselectionDropdown = false;
  }

  moveStoriesAndCompleteSprint(sprint,projectID,nextSprintId){
    if (this.selectedSprintForMove.length === 0) {
      return;
    }
    if (this.sprint_Details.futureEndDate(this.sprint_Details.projected_end_date)) {
      this.sprint_Details.projected_end_date = this.sprint_Details.getDateFormat(moment());
    }
    sprint.status = 'completed';
    this.selectedSprintForMove = [];
    this.projectService.moveStoriesAndCompleteSprint(sprint,projectID,nextSprintId).subscribe(res => {
    this.closeMoveStoriesToSprint();
    this.customToast.messages.push({
      id: 'sprintUpdate',
      type: 'success',
      class: 'new_member_added',
      title: 'Sprint Update',
      message: res.message
    });
    }, err => {
      this.errorService.errorFunction(err)
      this.closeMoveStoriesToSprint();
    });
  }

  sortStoryList(value) {
    this.filterLoader = true;
    this.sort_by = value;
    this.sprintStories = [];
    this.nxt_page = 1;
    this.loadSprintAddedStories(this.projectId, this.sort_by, this.sprintId);
  }

  openSprintEdit(){
    this.sprintService.editSprint = true;
    this.sprintService.enterDateAlertPopup = false;
  }

  openDateAlertpopup(){
    this.sprintService.enterDateAlertPopup = true;
  }

}
