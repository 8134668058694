import { Component, OnInit } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { ProjectService } from '../shared/services/project.service';
import { ErrorService } from '../shared/errorFunction';
import { PolicyService } from '../shared/services/policy.service';
import { NotificationService } from '../shared/services/notification.service';
import { CustomToastService } from '../shared/services/custom-toast.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import { ProjectSyncService } from '../shared/services/projectSync.service';
import { SprintService } from '../shared/services/sprint.service';
import {Project} from "../shared/models/project";
import {User} from "../shared/models/user";
import {EVENT_TYPES, RELEASE_STATUS_LIST} from '../shared/dataTypes';
import { ReleasePlannerService } from '../shared/services/release-planner.service';
import { ReleasePlannerModal } from '../shared/models/releasePlannerModal';
import { Subscription } from 'rxjs';
import { EpicService } from '../shared/services/epic.service';
import { Epic } from '../shared/models/epic';
import moment from 'moment';

@Component({
  selector: 'app-project-releases',
  templateUrl: './project-releases.component.html',
  styleUrls: ['./project-releases.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({
          opacity: '0',
          top: '40px'
        }),
        animate(200, style({
          opacity: '1',
          top: '20px'
        }))
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate(200, style({
          opacity: '0',
          top: '40px'
        }))
      ])
    ]),
    trigger('slideOut', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({
          opacity:'0',
          top: '0',
          right: '-190px'
        }),
        animate(100, style({
          opacity:'1',
          top: '0',
          right: '0'
        }))
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate(100, style({
          opacity:'0',
          top: '0',
          right: '-190px'
        }))
      ])
    ])
  ]
})
export class ProjectReleasesComponent implements OnInit {
  project: Project;
  projectId?: number;
  releases_list: Array<ReleasePlannerModal> = [];
  selected_release:ReleasePlannerModal;
  currentUser: User;
  showFilter: boolean = false;
  openFeatureListPopUp: boolean = false;
  openReleaseDetailPopUp: boolean = false;
  openAddVersion: boolean = false;
  openAddStoriesPopUp: boolean = false;
  showActionMenu: boolean = false;
  viewActivityPopUp: boolean = false;
  close_delete_release_popup: boolean = false;
  waitForLoader: boolean = false;
  action_on_story: string = '';
  releaseListLoader:boolean = false;
  listReleaseSubscriptions: Subscription;
  releasesMeta: {
    current_page: number,
    next_page: number,
    per_page: number,
    total_count: number,
    total_pre_prod_count: number,
    total_production_count: number,
    total_staging_count: number
  };
  environment: string = '';
  search_release_text:string = '';
  showfilterList: boolean = false;
  showStatusDD: boolean = false;
  status_selection: any;
  statusOptionList = [];
  filterSubscriptions: Subscription;
  release_detail_for = '';
  bulk_selection = [];
  show_move_release_Popup: boolean = false;
  moveable_env = '';
  moveable_status = '';
  release_cannot_move = [];

  constructor(
    public projectService: ProjectService,
    private errorService: ErrorService,
    public policyService: PolicyService,
    private notificationService: NotificationService,
    private customToast: CustomToastService,
    public router: Router,
    private projectSyncService: ProjectSyncService,
    private activatedRoute: ActivatedRoute,
    public releasePlannerService: ReleasePlannerService,
    private epicService: EpicService,
    public sprintService: SprintService) { }

  ngOnInit() {
    this.releaseListLoader = true;
    this.environment = 'staging';
    this.status_selection = RELEASE_STATUS_LIST[this.environment];
    this.activatedRoute.params.subscribe((params: Params) => {
      this.projectId = +params['id'];
    });
    this.releasePlannerService.loadProjectDetails('from_release', this.projectId).subscribe(
      data => {
        this.project = data.project;
        this.currentUser = data.current_user;
        this.loadReleases();
        this.projectSyncService.subscribe(this);
      },
      err => {
        this.errorService.errorFunction(err)
      }
    );

    this.filterSubscriptions = this.notificationService.subscribeEvent(EVENT_TYPES.RELEASE.RELEASE_FILTER, res => {
      if (res && res.payload.data.filter_at === 'release_filter') {
        this.loadReleases(true);
      }
    });
  }

  toggelefilter() {
    this.showfilterList = !this.showfilterList;
  }

  ngOnDestroy() {
    this.clearCallSubscription();   
    this.filterSubscriptions.unsubscribe();   
  }

  clearCallSubscription() {
    if (this.listReleaseSubscriptions) {
      this.listReleaseSubscriptions.unsubscribe();      
    }
  }

  searchRelease() {
    this.releaseListLoader = true;
    this.releases_list = [];
    this.clearCallSubscription();
    this.loadReleases();
  }

  changeEnv(env) {
    const env_data = env.split(' ').join('');
    this.releaseListLoader = true;
    this.environment = env;
    this.releasesMeta.next_page = null;
    this.searchRelease();
    this.status_selection = RELEASE_STATUS_LIST[env_data];
  }

  loadReleases(actionedOnFilter: boolean = false) {
    const filters = this.projectService.getFiltersForAPICall(this.project);
    const pageno = this.releasesMeta && this.releasesMeta.next_page && !actionedOnFilter ? this.releasesMeta.next_page : 1;
    this.listReleaseSubscriptions = this.releasePlannerService.getReleases(this.project, pageno, this.search_release_text, this.environment, filters).subscribe(res => {
      if(res) {
        this.bulk_selection = [];
        if(actionedOnFilter) {
          this.releases_list = [];
        }
        this.releasesMeta = res.meta;
        if(this.releasesMeta.current_page === 1) {this.releases_list = [];}
        res.data.forEach(item => {
          this.releases_list.push(new ReleasePlannerModal(item));
        });
      }
      this.releaseListLoader = false;
      this.projectService.startApplyFilterLoader = false;
    }, (error) => {
      this.releaseListLoader = false;
      this.errorService.errorFunction(error);
    });
  } 

  versionAction(release?) {
    this.selected_release = release ? release : null;
    this.openAddVersion = !this.openAddVersion;
  }

  openFilter() {
    this.showFilter = !this.showFilter;
  }

  openFeaturelist(release) {
    this.selected_release = release;
    this.openFeatureListPopUp = true;
  }

  showReleaseDetail(release) {
    this.release_detail_for = release.release_detail_text === '' ? 'edit' : 'show';
    this.selected_release = release;
    this.openReleaseDetailPopUp = true;
  }
  
  closeFeaturelist() {
    this.openFeatureListPopUp = false;
  }

  closeReleaseDetail() {
    this.openReleaseDetailPopUp = false;
    this.release_detail_for = '';
  }

  showAddStoryPopup(release, action_type) {
    this.action_on_story = action_type;
    this.openAddStoriesPopUp = true;
    this.showActionMenu = false;
    this.selected_release = release;
  }

  closeAddStoryPopup() {
    this.openAddStoriesPopUp = false;
  }

  toggleStatusDropdown(event, release) {
    release.statusDropdownVisible = !release.statusDropdownVisible;
  }

  openActionMenu(event, release) {
    release.show_release_actions = !release.show_release_actions;
  }

  showActivityPopup(release, type) {
    release.show_comment = type === 'comment';
    release.show_activity = type !== 'comment';
    this.viewActivityPopUp = true;
    release.show_release_actions = false;
    this.selected_release = release;
  }

  closeCommentActivityPopup() {
    this.viewActivityPopUp = false;
  }

  closeDeleteReleasePopUp() {
    this.close_delete_release_popup = false
  }

  storyProgress(no_stories, total_count) {
    const individual_p = (no_stories * (100/total_count));
    return individual_p;
  }

  gotoReleaseDetail(event, release_data) {
    const element = event.target;
    if (element.classList.contains('actionTrigger') || 
    element.classList.contains('actionLink') || 
    element.classList.contains('action_icon') || 
    element.classList.contains('submenu-dropdown') || 
    element.classList.contains('submenu-link') || 
    element.classList.contains('selected-status') || 
    element.classList.contains('status-icon') || 
    element.classList.contains('status-list') || 
    element.classList.contains('status-list-item') || 
    element.classList.contains('d-icon') || 
    element.classList.contains('check-toggle') || 
    element.classList.contains('customCheckForRowSelection') || 
    element.classList.contains('story-selected-checkbox') || 
    this.openReleaseDetailPopUp || release_data.statusDropdownVisible || release_data.show_release_actions ||
    this.openFeatureListPopUp) {
      return;
    } else {
      this.router.navigate(['/projects/' + this.project.id + '/releases/' + release_data.id]);
    }
  }

  openDeleteReleaseConfirmation(release) {
    this.close_delete_release_popup = true
    this.selected_release = release;
  }
  // for soft deleting release call api from service file
  deleteRelease(event) {
    this.close_delete_release_popup = false;
    this.selected_release.deleted_at = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
    const deleted_at = this.selected_release.deleted_at;
    this.releasePlannerService.removeRelease(this.project.id, this.selected_release.id, deleted_at).subscribe(res => {
      this.customToast.messages.push({
        id: 'release_created',
        type: 'success',
        class: 'stories_moved',
        title: 'Release',
        message: 'Release removed successfully'
      });
      this.selected_release = null;
    }, (error) => {
      this.releaseListLoader = false;
      this.errorService.errorFunction(error);
    });
  }

  storySelection(event, release) {
    release.check_selection = !release.check_selection;
    if (event && event.target.checked) {
      release.check_selection = true;
      this.bulk_selection.push(release); 
    } else {
      release.check_selection = false;
      // find the index of story in selected_story array and remove it
      const index = this.bulk_selection.findIndex(st => st.id === release.id);
      if (index !== -1) {
        this.bulk_selection.splice(index, 1);
      }
    }
  }

  checkEnv() {
    if(this.environment === 'staging'){
      return 'UAT'
    } else if(this.environment === 'pre_prod') {
      return 'Production'
    }
  }

  SingleReleaseMove(release, env) {
    // this.releaseListLoader = true;
    const releases = [];
    const data = {
      id: release.id,
      environment: env,
      status: release.status
    }
    if(this.environment === 'staging' && env === 'pre_prod' && release.status === 'deployed_to_staging') {
      data.status = 'deployed_to_pre_prod';
      releases.push(data);
      this.releaseMove(releases, false);
    } else if(this.environment === 'pre_prod' && env === 'prod' && release.status === 'accepted') {
      data.status = 'deployed_to_production';
      releases.push(data.id);
      this.releaseMove(releases, true);
    } else if(this.environment === 'pre_prod' && env === 'staging' && (release.status === 'rolled_back' || release.status === 'rejected' || release.status === 'deployed_to_pre_prod')) {
      data.status = 'rolled_back';
      releases.push(data);
      this.releaseMove(releases, false);
    } else{
      this.customToast.messages.push({
        id: 'release_created',
        type: 'error',
        class: 'acceptance_alert',
        title: 'Release',
        message: 'Release cannot be moved as required status is not available.'
      });
    }    
  }

  closeMoveReleasePopup() {
    this.show_move_release_Popup = false;
    this.moveable_env = '';
    this.moveable_status = '';
    this.release_cannot_move = [];
  }

  openMoveReleasePopup(env, new_status) {
    this.show_move_release_Popup = true;
    this.moveable_env = env;
    this.moveable_status = new_status;
    if(this.environment === 'pre_prod' && this.moveable_env === 'staging') {
      this.bulk_selection.forEach(release => {
        if(release.status !== 'rolled_back' && release.status !== 'rejected' && release.status !== 'deployed_to_pre_prod') {
          this.release_cannot_move.push(release);
        }
      });
    } else if(this.environment === 'pre_prod' && env === 'production') {
      this.bulk_selection.forEach(release => {
        if(release.status !== 'accepted') {
          this.release_cannot_move.push(release);
        }
      });
    } else if(this.environment === 'staging' && this.moveable_env === 'pre_prod') {
      this.bulk_selection.forEach(release => {
        if(release.status !== 'deployed_to_staging') {
          this.release_cannot_move.push(release);
        }
      });
    }
  }

  bulkReleaseMove(env, new_status) {
    // this.releaseListLoader = true;
    let releases = [];
    if(this.environment === 'pre_prod' && this.moveable_env === 'staging') {
      // find the release from this.bulk_selection and this.release_cannot_move
      this.bulk_selection.forEach(release => {
        const index = this.release_cannot_move.findIndex(st => st.id === release.id);
        if (index === -1) {
          const data = {
            id: release.id,
            environment: env,
            status: 'rolled_back'
          }
          releases.push(data);
        }
      });
      this.releaseMove(releases, false);
    } else if(this.environment === 'pre_prod' && this.moveable_env === 'production') {
      this.bulk_selection.forEach(release => {
        const index = this.release_cannot_move.findIndex(st => st.id === release.id);
        if (index === -1) {
          releases.push(release.id);
        }
      });
      this.releaseMove(releases, true);
    } else{
      this.bulk_selection.forEach(release => {
        const data = {
          id: release.id,
          environment: env,
          status: new_status
        }
        releases.push(data);
      });
      this.releaseMove(releases, false);
    }
  }

  // Combined function
  releaseMove(releases, isProd: boolean = false) {
    const moveOperation = isProd 
      ? this.releasePlannerService.moveReleaseToProd(this.project.id, releases) 
      : this.releasePlannerService.moveRelease(this.project.id, releases);

    moveOperation.subscribe(
      res => this.handleSuccess(),
      error => this.handleError(error)
    );
  }

  // Helper function for success
  handleSuccess() {
    this.bulk_selection.forEach(release => {
      release.check_selection = false;
    });
    this.bulk_selection = [];
    this.customToast.messages.push({
      id: 'release_created',
      type: 'success',
      class: 'stories_moved',
      title: 'Release',
      message: 'Release moved successfully'
    });
    this.closeMoveReleasePopup();
    // this.loadReleases();
  }

  // Helper function for error
  handleError(error) {
    this.releaseListLoader = false;
    this.bulk_selection.forEach(release => {
      release.check_selection = false;
    });
    this.bulk_selection = [];
    this.closeMoveReleasePopup();
    this.errorService.errorFunction(error);
  }

}