<ng-container *ngIf="story">
  <div class="note-wrapperEmpty" *ngIf="story?.notes?.length === 0">
    <span class="wrapperEmpty-title">No comments available</span> 
  </div>
  <div class="note" *ngFor="let note of (story.notes | notesSort:comment_sort_order:'created_at')">
    <div class="note_meta">
      <span class="commentUser-initials">{{(note.user_type === 'client' && story.created_from === 'builder_live')?(story.client_name | slice:0:1):(note.user_initials?note.user_initials:currentUser.initials) | slice:0:1}}</span>
      <span class="commentUser-name">{{(note.user_type === 'client' && story.created_from === 'builder_live')? (story.client_name) : (note.user_name?note.user_name:currentUser.name)}} <span class="commentUser-time">{{note.createdAt}}</span></span>
    </div>

    <div class="commentDes" *ngIf="!note.open_edit" [innerHtml]="note.display_note_html && showNoteContent(note.display_note_html)"></div>
    <ng-container *ngIf="note.open_edit">
      <div class="note_form">
        <textarea #editComment class="forEditComment" name="note"  (keyup) = "searchMentionUser($event, 'edit', note)" autosize name="noteEdit" [(ngModel)]="note.display_note" [disabled] = "note.editCommentuploadProcessStarted"></textarea>
        <div class="mentionWrapper" *ngIf = "note && note.show_mention && mentionUsers.length > 0">
          <div class = "mentiondropdown">
              <div #editMentionsList class="mentionSelection-list" *ngFor = "let user of mentionUsers; index as i;"
                  (click) = "selectMention(user, 'edit', note)" (mouseenter)="setSelectedIndex(i)">
                <div class="mentionUser-initial">{{user.initials | slice:0:1}}</div>
                <div class="mentionUser-descriptionWrapper">
                  <span class="mentionUser-name">{{user.name}}</span>
                  <span class="mentionUser-email">{{user.email}}</span>
                </div>
              </div>
            </div>
        </div>
        <div class="commentAreaBtn">
          <ul class="attachments" *ngIf = "note.documents.length > 0 || note.uploaderArr.length > 0">
            <li class="attachment" *ngFor="let item of note.documents">
              <span class="uploading-listing" (click)="openAuthorPopup(note, item.document, 'remote_url')">
                <div class="uploading-listing-thumbnail not_maximized">
                  <img *ngIf = "item.document && item.document.remote_url && sharedService.checkType(item.document.remote_url, true) == 'image'" [src]="item.document.remote_url" alt="loader"/>
                  <a *ngIf = "item.document && item.document.remote_url &&  (sharedService.checkType(item.document.remote_url, true) == 'doc' || sharedService.checkType(item.document.remote_url, true) == '')" class="gotoImage" [href]="item.document.remote_url" rel="noopener" target="_blank">
                      <img class="default-attachment" src="/assets/images/defaullt-download.png" alt="loader"/>
                  </a>
                  <div *ngIf = "item.document && item.document.remote_url && sharedService.checkType(item.document.remote_url, true) == 'video'" class="videoContainer" (click)="openVideoPopup(item.document.remote_url)">
                      <em class="iconb-play-circle"></em>
                  </div>
                </div>
                <div class="uploading-file-description">
                  <span class="docuName">{{ (item.document ? item.document.name: item.name) }}</span>
                  <span class="docuSize">{{ (item.document ? sharedService.getFileSizeText(item.document.size) : sharedService.getFileSizeText(item.size))}}</span>
                </div>
              </span>
              <span class="removeDocument" (click)="removeEditDoc(note, item)">
                <em class="iconb-bin"></em>
              </span>
            </li>
            <li class="attachment" *ngFor="let item of note.uploaderArr; let i = index">
              <span class="uploading-listing" (click)="openAuthorPopup(note, item, 'name', i)">
                <div class="uploading-listing-thumbnail not_maximized">
                  <img *ngIf = "sharedService.checkType(item.name, true) == 'image'" [src]="note.attachmentPreviewUrls[i]" alt="loader"/>
                  <a *ngIf = "sharedService.checkType(item.name, true) == 'doc' || sharedService.checkType(item.name, true) == ''" class="gotoImage" [href]="note.attachmentPreviewUrls[i]" target="_blank" rel="noopener">
                      <img class="default-attachment" src="/assets/images/defaullt-download.png" alt="loader"/>
                  </a>
                  <div *ngIf = "sharedService.checkType(item.name, true) == 'video'" class="videoContainer" (click)="openVideoPopup(note.attachmentPreviewUrls[i], true)">
                      <em class="iconb-play-circle"></em>
                  </div>
                </div>
                <div class="uploading-file-description">
                  <span class="docuName">{{ item?.name }}</span>
                  <span class="docuSize">{{ sharedService.getFileSizeText(item?.size) }}</span>
                </div>
              </span>
              <span class="removeDocument" (click)="removeNoteDocument(note, item)">
                <em class="iconb-bin"></em>
              </span>
            </li>
          </ul>

          <div class="noteActionWrapper">
            <div class="attach-file">
              <label class="labelForFileType">
                <em class="iconb-attach"></em>
                <input type="file"
                      #noteAttachmentInput
                      id="file"
                      class="forFiletype"
                      (change)="attachFilesInComment($event, note)"
                      (click)="noteAttachmentInput.value = null" value=""
                      multiple />
              </label>
            </div>
            <div class="add-note">
              <button class="editButton-cancel" [disabled] = "note.editCommentuploadProcessStarted" (click)="canceleditNote(note)">Cancel</button>
              <button type="button" [disabled] = "note.editCommentuploadProcessStarted || (!note.note.trim().length && !note.documents.length && !note.uploaderArr.length)" class="editButton-save" (click)="editCurrentNote(note)">Save</button>
            </div>
          </div>
      </div>
      </div>
    </ng-container>

    <ul class="attachments" *ngIf = "note.documents.length > 0 && !note.open_edit">
      <li class="attachment" *ngFor="let item of note.documents">
        <span class="attachInfo" (click)="openAuthorPopup(note, item.document, 'remote_url')">
          <div class="attachment_thumbnail not_maximized">
            <img *ngIf = "item.document && item.document.remote_url && sharedService.checkType(item.document.remote_url, true) == 'image'" [src]="item.document.remote_url" alt="loader"/>
            <a *ngIf = "item.document && item.document.remote_url &&  (sharedService.checkType(item.document.remote_url, true) == 'doc' || sharedService.checkType(item.document.remote_url, true) == '')" class="gotoImage" [href]="item.document.remote_url" rel="noopener" target="_blank">
                <img class="default-attachment" src="/assets/images/defaullt-download.png" alt="loader"/>
            </a>
            <div *ngIf = "item.document && item.document.remote_url && sharedService.checkType(item.document.remote_url, true) == 'video'" class="videoContainer" (click)="openVideoPopup(item.document.remote_url)">
              <em class="iconb-play-circle"></em>
            </div>
          </div>
          <div class="attachment_description">{{ (item.document ? item.document.name: item.name) }}</div>
        </span>
        <span *ngIf="((currentUser.id === note.user_id) && story && (!story.deleted_at))" class="deleteDocument" (click)="openNotePopup(note, item.document ? item.document: item, 'removeDocument')">
          <em class="iconb-bin"></em>
          <span class="elementHover">Delete</span>
        </span>
        <a [href]="item.document?.remote_url" target="_blank" rel="noopener" class="downloadAttachment" *ngIf="story && !story.deleted_at">
          <em class="iconb-download"></em>
          <span class="elementHover">Download</span>
        </a>
      </li>
    </ul>

    <div class="comnentAction-wrapper"  *ngIf="note && !note.open_edit && note.user_type !== 'client' && !((story?.epics.length > 0) && (story?.epics[0]?.status === 'inactive'))">
      <span (click)="editNote(note)" class="editComment-button" *ngIf = "(((currentUser.id === note.user_id) || note.isNew) && story && (!story.deleted_at))">Edit</span>
      <span (click)="openNotePopup(note, null, 'deleteNote')" *ngIf = "(((currentUser.id === note.user_id) || note.isNew) && story && (!story.deleted_at))" class="deleteComment-button">Delete</span>
      <span class="replyComment-button" *ngIf="story && !story.deleted_at" (click)="openReplyField(note)">Reply</span>
      <div class="replyInputContainer" [ngClass]="{'openReply': note.showReplyInput}">
        <span class="replyUser-initials">{{currentUser.initials ? (currentUser.initials | slice: 0:1) : ''}}</span>
        <span class="replyInput">
          <textarea  #replyNoteEl class="forReply" [(ngModel)] = "note.replyNote" maxlength="300" autosize (keyup) = "searchMentionUser($event, 'reply', note)" name="replyOnComment" ></textarea>
          <div class="mentionWrapper" *ngIf = "note && note?.showMentionReply && (mentionUsers.length != -1) && (mentionUsers.length > 0)">
            <div class = "mentiondropdown">
              <div #replyMentionsList class="mentionSelection-list" *ngFor = "let user of mentionUsers; index as i;"
                  (click) = "selectMention(user, 'reply', note)" (mouseenter)="setSelectedIndex(i)">
                <div class="mentionUser-initial">{{user.initials | slice:0:1}}</div>
                <div class="mentionUser-descriptionWrapper">
                  <span class="mentionUser-name">{{user.name}}</span>
                  <span class="mentionUser-email">{{user.email}}</span>
                </div>
              </div>
            </div>
          </div>
        </span>
        <div class="relpyActionBlock">
          <button type="button" class="reply-submit" [disabled] = "!note.replyNote || note.disableReplyButton" (click) = "replyOnNote(note)">Reply</button>
          <button type="button" class="reply-cancel" (click)="closeReplyField(note)">Cancel</button>
        </div>
      </div>
    </div>

    <button  type="button" class="viewReply" *ngIf="note.formatted_replies && note.formatted_replies.length > 0" (click)="openReply(note)">
      <em class="iconb-dd-down-fill" [ngClass]="{'changeIcon': note.openCommentReply}"></em>
        {{note.openCommentReply ? 'Hide':'View'}} {{note.formatted_replies?.length > 1 ? (note.formatted_replies?.length + ' ') : ''}}{{note.formatted_replies?.length == 1 ? 'reply' : 'replies'}}
    </button>
    <div *ngIf="note.openCommentReply && note.formatted_replies && note.formatted_replies.length > 0">
      <div  class="replyWrapper" *ngFor="let reply of note.formatted_replies; let i = index">
        <div class="note_meta">
          <span class="commentUser-initials">{{ reply.user_initials ? (reply.user_initials  | slice: 0:1) : '' }}</span>
          <span class="commentUser-name">{{ reply.user_name ? reply.user_name : reply.user_email }} <span class="commentUser-time">{{formatTime(reply.created_at)}}</span></span>
        </div>
        <div class="commentDes" *ngIf="!reply.openEditReply" [innerHtml]="reply.display_reply_html && showNoteContent(reply.display_reply_html)"></div>
        <ng-container *ngIf="reply.openEditReply">
          <div class="note_form">
            <textarea class="forEditComment" #replyEditNote [(ngModel)]="reply.display_reply" maxlength="300" autosize name="replyEdit" (keyup) = "searchMentionUser($event, 'replyedit', note)"></textarea>
            <div class="mentionWrapper" *ngIf = "note.currentReply && note.currentReply.show_mention && mentionUsers.length > 0">
              <div class = "mentiondropdown">
                <div #editReplyMentionsList class="mentionSelection-list" *ngFor = "let user of mentionUsers; index as i;"
                    (click) = "selectMention(user, 'replyedit', note)" (mouseenter)="setSelectedIndex(i)">
                  <div class="mentionUser-initial">{{user.initials | slice:0:1}}</div>
                  <div class="mentionUser-descriptionWrapper">
                    <span class="mentionUser-name">{{user.name}}</span>
                    <span class="mentionUser-email">{{user.email}}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="commentAreaBtn">
              <div class="noteActionWrapper">
                <div class="add-note">
                  <button class="editButton-cancel" (click)="cancelEditReply(reply, note)">Cancel</button>
                  <button type="button" [disabled]="reply.disableEditReplyButton || reply.note.trim().length === 0" class="editButton-save" (click)="editReply(reply, note)">Save</button>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <div class="comnentAction-wrapper" *ngIf="(!reply.openEditReply && (currentUser.id == reply.user_id) && story && !story.deleted_at) && !((story?.epics.length > 0) && (story?.epics[0]?.status === 'inactive'))">
          <span (click)="openEditReply(reply, note)" class="editComment-button">Edit</span>
          <span class="deleteComment-button" (click)="openNotePopup(note, reply, 'deleteNoteReply')">Delete</span>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="release_comments">
  <div class="note-wrapperEmpty" *ngIf="release_comments.length === 0">
    <span class="wrapperEmpty-title">No comments available</span> 
  </div>
  <div class="note" *ngFor="let note of (release_comments)">
    <div class="note_meta">
      <span class="commentUser-initials">{{(note.user_type === 'client' && story.created_from === 'builder_live')?(story.client_name | slice:0:1) : (note.user_initials ? note.user_initials : note.user_name) | slice:0:1}}</span>
      <span class="commentUser-name">{{(note.user_type === 'client' && story.created_from === 'builder_live')? (story.client_name) : (note.user_name ? note.user_name : note.user_name.name)}} <span class="commentUser-time">{{note.createdAt}}</span></span>
    </div>

   <div class="commentDes" *ngIf="!note.open_edit" [innerHtml]="note.display_note_html && showNoteContent(note.display_note_html)"></div>
    <ng-container *ngIf="note.open_edit">
      <div class="note_form">
        <textarea #editComment class="forEditComment" name="note"  (keyup) = "searchMentionUser($event, 'edit', note)" autosize name="noteEdit" [(ngModel)]="note.display_note" [disabled] = "note.editCommentuploadProcessStarted"></textarea>
        <div class="mentionWrapper" *ngIf = "note && note.show_mention && mentionUsers.length > 0">
          <div class = "mentiondropdown">
              <div #editMentionsList class="mentionSelection-list" *ngFor = "let user of mentionUsers; index as i;"
                  (click) = "selectMention(user, 'edit', note)" (mouseenter)="setSelectedIndex(i)">
                <div class="mentionUser-initial">{{user.initials | slice:0:1}}</div>
                <div class="mentionUser-descriptionWrapper">
                  <span class="mentionUser-name">{{user.name}}</span>
                  <span class="mentionUser-email">{{user.email}}</span>
                </div>
              </div>
            </div>
        </div>
        <div class="commentAreaBtn">
          <ul class="attachments" *ngIf = "note.documents.length > 0 || note.uploaderArr.length > 0">
            <li class="attachment" *ngFor="let item of note.documents">
              <span class="uploading-listing" (click)="openAuthorPopup(note, item.document, 'remote_url')">
                <div class="uploading-listing-thumbnail not_maximized">
                  <img *ngIf = "item.document && item.document.remote_url && sharedService.checkType(item.document.remote_url, true) == 'image'" [src]="item.document.remote_url" alt="loader"/>
                  <a *ngIf = "item.document && item.document.remote_url &&  (sharedService.checkType(item.document.remote_url, true) == 'doc' || sharedService.checkType(item.document.remote_url, true) == '')" class="gotoImage" [href]="item.document.remote_url" rel="noopener" target="_blank">
                      <img class="default-attachment" src="/assets/images/defaullt-download.png" alt="loader"/>
                  </a>
                  <div *ngIf = "item.document && item.document.remote_url && sharedService.checkType(item.document.remote_url, true) == 'video'" class="videoContainer" (click)="openVideoPopup(item.document.remote_url)">
                      <em class="iconb-play-circle"></em>
                  </div>
                </div>
                <div class="uploading-file-description">
                  <span class="docuName">{{ (item.document ? item.document.name: item.name) }}</span>
                  <span class="docuSize">{{ (item.document ? sharedService.getFileSizeText(item.document.size) : sharedService.getFileSizeText(item.size))}}</span>
                </div>
              </span>
              <span class="removeDocument" (click)="removeEditDoc(note, item)">
                <em class="iconb-bin"></em>
              </span>
            </li>
            <li class="attachment" *ngFor="let item of note.uploaderArr; let i = index">
              <span class="uploading-listing" (click)="openAuthorPopup(note, item, 'name', i)">
                <div class="uploading-listing-thumbnail not_maximized">
                  <img *ngIf = "sharedService.checkType(item.name, true) == 'image'" [src]="note.attachmentPreviewUrls[i]" alt="loader"/>
                  <a *ngIf = "sharedService.checkType(item.name, true) == 'doc' || sharedService.checkType(item.name, true) == ''" class="gotoImage" [href]="note.attachmentPreviewUrls[i]" target="_blank" rel="noopener">
                      <img class="default-attachment" src="/assets/images/defaullt-download.png" alt="loader"/>
                  </a>
                  <div *ngIf = "sharedService.checkType(item.name, true) == 'video'" class="videoContainer" (click)="openVideoPopup(note.attachmentPreviewUrls[i], true)">
                      <em class="iconb-play-circle"></em>
                  </div>
                </div>
                <div class="uploading-file-description">
                  <span class="docuName">{{ item?.name }}</span>
                  <span class="docuSize">{{ sharedService.getFileSizeText(item?.size) }}</span>
                </div>
              </span>
              <span class="removeDocument" (click)="removeNoteDocument(note, item)">
                <em class="iconb-bin"></em>
              </span>
            </li>
          </ul>

          <div class="noteActionWrapper">
            <div class="attach-file">
              <label class="labelForFileType">
                <em class="iconb-attach"></em>
                <input type="file"
                      #noteAttachmentInput
                      id="file"
                      class="forFiletype"
                      (change)="attachFilesInComment($event, note)"
                      (click)="noteAttachmentInput.value = null" value=""
                      multiple />
              </label>
            </div>
            <div class="add-note">
              <button class="editButton-cancel" [disabled] = "note.editCommentuploadProcessStarted" (click)="canceleditNote(note)">Cancel</button>
              <button type="button" [disabled] = "note.editCommentuploadProcessStarted || (!note.note.trim().length && !note.documents.length && !note.uploaderArr.length)" class="editButton-save" (click)="editCurrentNote(note)">Save</button>
            </div>
          </div>
      </div>
      </div>
    </ng-container>

   <ul class="attachments" *ngIf = "note.documents?.length > 0 && !note.open_edit">
      <li class="attachment" *ngFor="let item of note.documents">
        <span class="attachInfo" (click)="openAuthorPopup(note, item.document, 'remote_url')">
          <div class="attachment_thumbnail not_maximized">
            <img *ngIf = "item.document && item.document.remote_url && sharedService.checkType(item.document.remote_url, true) == 'image'" [src]="item.document.remote_url" alt="loader"/>
            <a *ngIf = "item.document && item.document.remote_url &&  (sharedService.checkType(item.document.remote_url, true) == 'doc' || sharedService.checkType(item.document.remote_url, true) == '')" class="gotoImage" [href]="item.document.remote_url" rel="noopener" target="_blank">
                <img class="default-attachment" src="/assets/images/defaullt-download.png" alt="loader"/>
            </a>
            <div *ngIf = "item.document && item.document.remote_url && sharedService.checkType(item.document.remote_url, true) == 'video'" class="videoContainer" (click)="openVideoPopup(item.document.remote_url)">
              <em class="iconb-play-circle"></em>
            </div>
          </div>
          <div class="attachment_description">{{ (item.document ? item.document.name: item.name) }}</div>
        </span>
        <span *ngIf="(currentUser.id === note.user_id)" class="deleteDocument" (click)="openNotePopup(note, item.document ? item.document: item, 'removeDocument')">
          <em class="iconb-bin"></em>
          <span class="elementHover">Delete</span>
        </span>
        <a [href]="item.document?.remote_url" target="_blank" rel="noopener" class="downloadAttachment">
          <em class="iconb-download"></em>
          <span class="elementHover">Download</span>
        </a>
      </li>
    </ul>

    <div class="comnentAction-wrapper"  *ngIf="note && !note.open_edit && note.user_type !== 'client'">
      <span (click)="editNote(note)" class="editComment-button" *ngIf = "((currentUser.id === note.user_id) || note.isNew)">Edit</span>
      <span (click)="openNotePopup(note, null, 'deleteNote')" *ngIf = "((currentUser.id === note.user_id) || note.isNew)" class="deleteComment-button">Delete</span>
      <span class="replyComment-button" (click)="openReplyField(note)">Reply</span>
      <div class="replyInputContainer" [ngClass]="{'openReply': note.showReplyInput}">
        <span class="replyUser-initials">{{currentUser.initials ? (currentUser.initials | slice: 0:1) : ''}}</span>
        <span class="replyInput">
          <textarea  #replyNoteEl class="forReply" [(ngModel)] = "note.replyNote" maxlength="300" autosize (keyup) = "searchMentionUser($event, 'reply', note)" name="replyOnComment" ></textarea>
          <div class="mentionWrapper" *ngIf = "note && note?.showMentionReply && (mentionUsers.length != -1) && (mentionUsers.length > 0)">
            <div class = "mentiondropdown">
              <div #replyMentionsList class="mentionSelection-list" *ngFor = "let user of mentionUsers; index as i;"
                  (click) = "selectMention(user, 'reply', note)" (mouseenter)="setSelectedIndex(i)">
                <div class="mentionUser-initial">{{user.initials | slice:0:1}}</div>
                <div class="mentionUser-descriptionWrapper">
                  <span class="mentionUser-name">{{user.name}}</span>
                  <span class="mentionUser-email">{{user.email}}</span>
                </div>
              </div>
            </div>
          </div>
        </span>
        <div class="relpyActionBlock">
          <button type="button" class="reply-submit" [disabled] = "!note.replyNote || note.disableReplyButton" (click) = "replyOnNote(note)">Reply</button>
          <button type="button" class="reply-cancel" (click)="closeReplyField(note)">Cancel</button>
        </div>
      </div>
    </div>

    <button  type="button" class="viewReply" *ngIf="note.formatted_replies && note.formatted_replies.length > 0" (click)="openReply(note)">
      <em class="iconb-dd-down-fill" [ngClass]="{'changeIcon': note.openCommentReply}"></em>
        {{note.openCommentReply ? 'Hide':'View'}} {{note.formatted_replies?.length > 1 ? (note.formatted_replies?.length + ' ') : ''}}{{note.formatted_replies?.length == 1 ? 'reply' : 'replies'}}
    </button>
    <div *ngIf="note.openCommentReply && note.formatted_replies && note.formatted_replies.length > 0">
      <div  class="replyWrapper" *ngFor="let reply of note.formatted_replies; let i = index">
        <div class="note_meta">
          <span class="commentUser-initials">{{ reply.user_initials ? (reply.user_initials  | slice: 0:1) : '' }}</span>
          <span class="commentUser-name">{{ reply.user_name ? reply.user_name : reply.user_email }} <span class="commentUser-time">{{formatTime(reply.created_at)}}</span></span>
        </div>
        <div class="commentDes" *ngIf="!reply.openEditReply" [innerHtml]="reply.display_reply_html && showNoteContent(reply.display_reply_html)"></div>
        <ng-container *ngIf="reply.openEditReply">
          <div class="note_form">
            <textarea class="forEditComment" #replyEditNote [(ngModel)]="reply.display_reply" maxlength="300" autosize name="replyEdit" (keyup) = "searchMentionUser($event, 'replyedit', note)"></textarea>
            <div class="mentionWrapper" *ngIf = "note.currentReply && note.currentReply.show_mention && mentionUsers.length > 0">
              <div class = "mentiondropdown">
                <div #editReplyMentionsList class="mentionSelection-list" *ngFor = "let user of mentionUsers; index as i;"
                    (click) = "selectMention(user, 'replyedit', note)" (mouseenter)="setSelectedIndex(i)">
                  <div class="mentionUser-initial">{{user.initials | slice:0:1}}</div>
                  <div class="mentionUser-descriptionWrapper">
                    <span class="mentionUser-name">{{user.name}}</span>
                    <span class="mentionUser-email">{{user.email}}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="commentAreaBtn">
              <div class="noteActionWrapper">
                <div class="add-note">
                  <button class="editButton-cancel" (click)="cancelEditReply(reply, note)">Cancel</button>
                  <button type="button" [disabled]="reply.disableEditReplyButton || reply?.note?.trim().length === 0" class="editButton-save" (click)="editReply(reply, note)">Save</button>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <div class="comnentAction-wrapper" *ngIf="(!reply.openEditReply && (currentUser.id == reply.user_id))">
          <span (click)="openEditReply(reply, note)" class="editComment-button">Edit</span>
          <span class="deleteComment-button" (click)="openNotePopup(note, reply, 'deleteNoteReply')">Delete</span>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<div *ngIf="openAuthor" class="authorBioWrapper" [ngClass]="{'openAuthorBio' : openAuthor}">
  <div class="backdropClose" (click)="closeAuthorPopup()"></div>
  <em class="closeFun iconb-close" (click)="closeAuthorPopup()"></em>
  <div class="authorContent">
    <img src="{{openItemDoc}}" alt="loader"/>
  </div>
</div>

<app-custom-popup  [texts] = "{ptitle: 'Delete', ptext: 'Are you sure you want to delete the ' + (noteDocRemove? 'file' : 'comment') + '?', psuccess: 'Delete', pcancel: 'Cancel'}" (closeCustomPopUp) = "closeNotePopup()" (deleteElement) = "deleteElement($event)" [delement] = "delnote" [OpenPopUp] = "openNoteDeletePopup" [waitForLoader] = "waitForLoader"></app-custom-popup>

<div class="videoPopup-wrapper" *ngIf="showVideo">
  <div class="videoPopup-backdrop"></div>
  <em class="iconb-close closeVideoPopup" (click)="closeVideoPopup()"></em>
  <div class="videoPopup">
    <video width="640px" controls autoplay>
      <source src="{{vURL}}" type='video/mp4' />
    </video>
  </div>
</div>
