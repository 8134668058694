import { forwardRef, Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import {Epic} from '../models/epic';
import {Project} from '../models/project';
import {Story} from '../models/story';
import {User} from '../models/user';
import {EVENT_TYPES, ProjectRole, PROJECT_COLUMN_DATA, PROJECT_COLUMN, PROJECT_COLUMN_IDENTIFIER, NEW_STORY_WRITTING_REASONS,BB_STATUS} from '../dataTypes';
import { API_ROUTES } from '../apiRoutes';
import { NotificationService } from './notification.service';
import { StoryService } from './story.service';
import { ErrorService } from '../errorFunction';
import { Observable, Subject, Subscription } from 'rxjs';
import { UserService } from './user.service';
import { environment } from '../../../environments/environment';
import {map} from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DataService } from './data.service';
import { HttpRequests } from './http-requests.service';
import {EpicService} from './epic.service';
import { Router } from '@angular/router';
import { ProjectColumn } from '../models/column';
import { PolicyService } from './policy.service';
import { CustomToastService } from './custom-toast.service';

@Injectable()
export class ProjectService {
  projectColumns: Array<ProjectColumn> = PROJECT_COLUMN_DATA;
  existingExpandData = [];
  meta: any;
  currentProject?: Project;
  searchString = '';
  dropDownType = '';
  trackEventSubscription: Subscription;
  projectsListSubject: Subject<any>;
  clonePopupLoader: boolean;
  isFormMail = false;
  urlStoryId: number;
  isRefresh = false;
  all_projects: Array<Project>;
  allocation_end_date: any;
  allocated: boolean = true;
  commit_format_confirmed: boolean;
  docker_ides: Array<any> = [];
  current_user_role: String;
  current_user_pod_role: string;
  is_rebuild = false;
  openProDD: boolean = false;
  storyId;
  show_storyDetails: Boolean = false;         // true if any story is open in detailed view mode
  start_storyDetailLoader: Boolean = false;   // loader will be shown until the story has been loaded
  show_Story: Story;                          // holds story which needs to be displayed in detailed view mode
  show_Story_history: Boolean = false;        // true => view story history ; false => view comments in story detail
  render_storyDetails: Boolean = false;       // used for description/title input fields to re-render if switching among story & subTask
  focus_input: Boolean = false;               // used for setting the focus on input field
  show_users: boolean = false;                // once the users have been loaded then users icon will be displayed in DOM
  /* shows the new member has been added in the project as using same component for Add member (in members & project dashboard) */
  isNewMemberAdded: boolean = false;
  isDefaultFilterApplied: boolean = false;    // if true then 'Member' filter is applied on loading stories (in case role is 'User')
  from_sprint:string = '';
  initiate_from:string = '';
  initialLoader: boolean = false;
  sprintDetailPageTitle: boolean = false;
  sprintId:number;
  releaseId:number;
  storyAddedListLoader:boolean = false;
  storyMetaList: {
    current_page: number;
    per_page_count: number;
    total_count: number;
    total_pages: number;
  };
  forDragDropLoader: boolean = false;
  storySizeExpand:boolean = false;
  showCreationReasonDialog:boolean = false;
  story_creation_reason:boolean = false;
  creationReasons: Array<any> = NEW_STORY_WRITTING_REASONS;
  creationReasonsSelected = '';
  setFeatureFilter: boolean = true;
  story_create_for_epic: Epic;
  creationReason_origin = '';
  story_to_duplicate: Story;
  otherReason = '';
  selectedStory: Story;
  other_reason:boolean = false;
  selected_reason_option = '';
  duplicate_with_filter:boolean = false;
  showListLoader:boolean = false;
  storiesInvalidToAssignUser = [];
  story_col_name = '';
  archived_story_selected:boolean = false;
  normal_story_selected:boolean = false;
  showHtml: boolean = false;
  startApplyFilterLoader: boolean = false;
  openSearchBlock:boolean = false;
  sprintFilter:boolean = false;
  sprintDetailFilter:boolean = false;
  get_native_element: any;
  cloneConfirmPopup: boolean = false;
  apply_on_sub_tasks: boolean = true;
  acceptRejectPopup: boolean = false;
  storiesCanBeAccepted = [];
  storiesCannotBeAccepted = [];
  storiesCanBeRejected = [];
  storiesCannotBeRejected = [];
  togglestoryAccept = [];
  togglestoryReject = [];
  acceptRejectPopupType: string;
  acceptRejectSubtasks = [];
  subtaskSelected: boolean;
  sprintQueryParams = {
    q: '',
    page: 1,
    incomplete: true
  };
  createBug ='';
  meFilterLoader: boolean;
  activeStoryId : number = 0
  featureSearchText?:string;
  show_hide_backlog:boolean = false;

  constructor(
    private dataService: DataService,
    private httpRequest: HttpRequests,
    private router: Router,
    @Inject(forwardRef(() => StoryService)) private storyService: StoryService,
    @Inject(forwardRef(() => PolicyService)) private policyService: PolicyService,
    @Inject(forwardRef(() => EpicService)) private epicService: EpicService,
    @Inject(forwardRef(() => ErrorService)) private errorService: ErrorService,
    @Inject(forwardRef(() => UserService)) private userService: UserService,
    @Inject(forwardRef(() => NotificationService)) private notificationService: NotificationService,
    @Inject(forwardRef(() => CustomToastService)) private customToast: CustomToastService,
    public http: HttpClient) {
    this.projectsListSubject = new Subject();
  }

  doSearch() {
    this.openSearchBlock = true;
    this.focus_input = true;
  }

  closeSearch() {
    this.openSearchBlock = false;
    this.focus_input = false;
  }

  checkStoryarchived(story) {
    if(this.archived_story_selected && !story.deleted_at) {
      return true;
    } else if(this.normal_story_selected && story.deleted_at){
      return true;
    } else{
      return false;
    }
  }

  public notifyProjectsList(projectsList: any, meta: any) {
    this.projectsListSubject.next({ projects: projectsList, meta: meta });
  }

  public clearMessage() {
    this.projectsListSubject.next();
  }

  public getProjectsList(): Observable<any> {
    return this.projectsListSubject.asObservable();
  }

  get projects() {
    let queryParams = {timezone: ''};
       queryParams.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
       const API = this.dataService.apiUrl + API_ROUTES.PROJECTS;
       let url = this.dataService.parseQueryParams(API, queryParams)

    return this.httpRequest
      .get(url, this.dataService.getRequestOptionArgs()).pipe(
      map(res => this.parseResponse(res)),
      );
  }

  getProjects(queryParams) {
    queryParams.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let api = this.dataService.apiUrl + API_ROUTES.PROJECTS;
    let url = this.dataService.parseQueryParams(api, queryParams)
    return this.httpRequest
      .get(url, this.dataService.getRequestOptionArgs()).pipe(
      map(res => this.parseResponse(res)));
  }

  project(id: number, setCurrentProject: boolean = false, info: boolean = false) {
    let API = this.dataService.apiUrl + API_ROUTES.PROJECT(id);
    if (info) {
      API = API + '/info';
    }
    return this.httpRequest
      .get(API, this.dataService.getRequestOptionArgs()).pipe(
      map(res => this.parseResponse(res)),
      map(res => {
        let project = Project.getProject(res.project);

        if (info) {
          project = new Project(res.project);
        }
        if (setCurrentProject) {
          this.currentProject = new Project(res.project);
          project.selectedStories = [];
          const payload = {
            data: {
              project: this.currentProject
            }
          };
          this.notificationService.broadcast(EVENT_TYPES.PROJECT.NEW_CURRENT, payload);
        }
        this.allocation_end_date = res.allocation_end_date;
        this.allocated = res.allocated;
        this.commit_format_confirmed = res.commit_format_confirmed;
        this.docker_ides = res.docker_ides;
        this.current_user_role = res.current_user_role;
        this.current_user_pod_role = res.current_user_pod_role;

        // update the policy for current policy
        if (res.project_policy) {
          this.policyService.updatePolicies(res.project_policy);
        }
        return project;
      }));
  }

  get(projectId) {
    const API = this.dataService.apiUrl + API_ROUTES.PROJECT(projectId);
    return this
      .httpRequest
      .get(API, this.dataService.getRequestOptionArgs());
  }

  update(project) {
    const API = this.dataService.apiUrl + API_ROUTES.PROJECT(project.id);
    return this
      .httpRequest
      .put(API, project, this.dataService.getRequestOptionArgs());
  }  

  getProjectIntegrations(projectId) {
    const API = this.dataService.apiUrl + API_ROUTES.INTEGRATIONS_GET(projectId);
    return this.httpRequest.get(API, this.dataService.getRequestOptionArgs()).pipe(
                map(res => this.parseResponse(res)));
  }

  getProjectSprints(projectId, queryParams) {
    const API = this.dataService.apiUrl + API_ROUTES.PROJECT_SPRINTS(projectId);
    const url = this.dataService.parseQueryParams(API, queryParams)
    return this.httpRequest.get(url, this.dataService.getRequestOptionArgs()).pipe(
      map(res => this.parseResponse(res)));
  }

  createSprint(sprint, projectId) {
    const API = this.dataService.apiUrl + API_ROUTES.CREATE_SPRINT(projectId);
    return this
      .httpRequest
      .post(API, sprint, this.dataService.getRequestOptionArgs());
  }

  updateSprint(sprint, projectId) {
    let sprintId = sprint.id;
    const API = this.dataService.apiUrl + API_ROUTES.UPDATE_SPRINT(projectId, sprintId);
    return this
      .httpRequest
      .put(API, sprint, this.dataService.getRequestOptionArgs());
  }

  deleteSprint(projectId, sprintId) {
    const API = this.dataService.apiUrl + API_ROUTES.DELETE_SPRINT(projectId, sprintId);
    return this
      .httpRequest
      .delete(API, this.dataService.getRequestOptionArgs()).pipe(
        map(res => this.parseResponse(res)));
  }

  sprintDetail(projectId, sprintId) {
    const API = this.dataService.apiUrl + API_ROUTES.UPDATE_SPRINT(projectId, sprintId);
    return this
      .httpRequest
      .get(API, this.dataService.getRequestOptionArgs()).pipe(
        map(res => this.parseResponse(res)));
  }

  addProjectIntegration(projectId, url, kind) {
    const data = {
      integration: {
        'url': url,
        'kind': kind
      }
    };
    const API = this.dataService.apiUrl + API_ROUTES.INTEGRATION_ADD(projectId, kind);
    return this.httpRequest.post(API, data, this.dataService.getRequestOptionArgs()).pipe(
                map(res => this.parseResponse(res)));
  }

  updateProjectIntegration(projectId, integrationId, url, kind) {
    const data = {
      integration: {
        'url': url,
        'kind': kind
      }
    };
    const API = this.dataService.apiUrl + API_ROUTES.INTEGRATION_EDIT(projectId, integrationId, kind);
    return this.httpRequest.put(API, data, this.dataService.getRequestOptionArgs()).pipe(
                map(res => this.parseResponse(res)));
  }

  deleteProjectIntegration(projectId, integrationId, kind) {
    const API = this.dataService.apiUrl + API_ROUTES.INTEGRATION_DELETE(projectId, integrationId, kind);
    return this.httpRequest.delete(API, this.dataService.getRequestOptionArgs()).pipe(
                map(res => this.parseResponse(res)));
  }

  projectPhasesNBrief(project: Project) {
    const httpOptions = {  headers:  new HttpHeaders()};
    httpOptions.headers =  httpOptions.headers.set('secret-key', environment.secretKey);
    const API = environment.apiPMBase + API_ROUTES.STORY_PHASE(project.id);
    return this.http.get(API, httpOptions).pipe(
      map(res => this.parseResponse(res)));
  }
  
  projectBriefDocument(project: Project) {
    const httpOptions = {  headers:  new HttpHeaders()};
    httpOptions.headers =  httpOptions.headers.set('secret-key', environment.secretKey);
    const API = environment.apiPMBase + API_ROUTES.PROJECT_DOCUMENT(project.dashboard_project_id);
    return this.http.get(API, httpOptions).pipe(
      map(res => this.parseResponse(res)));
  }

  getProjectDetails(project: Project) {
    const httpOptions = {  headers:  new HttpHeaders()};
    httpOptions.headers =  httpOptions.headers.set('secret-key', environment.secretKey);
    const API = environment.apiPMBase + API_ROUTES.PROJECT_DETAILS(project.dashboard_project_id);
    return this.http.get(API, httpOptions).pipe(
      map(res => this.parseResponse(res)));
  }

  loadData(project: Project) {
    this.loadStories(project, false).subscribe(() => null);
    this.loadUsers(project);
  }

  getColumn(list_id) {
    return PROJECT_COLUMN_DATA.filter(col => col.list_id == list_id)[0];
  }

  addFeatureCol() {
    const featureCol = new ProjectColumn({
      list_name: 'Features',
      list_code: 'features',
      list_id: 0,
      list_type: 'fixed',
      column_name: PROJECT_COLUMN.features,
      column_type: 'is_feature_column',
      visible: true,
      canDrag: false,
      canCreate: false,
      isDroppableContainer: false,
      columnIdentifier: PROJECT_COLUMN_IDENTIFIER.features,
      allSelected: false,
      isDependsOnCurrentUser: false,
      selectedStoryCount: 0,
      list_position: -100,
      stories: []
    });
    PROJECT_COLUMN_DATA.push(featureCol);
  }

  loadUsers(project: Project) {
    this.userService.membershipsForProject(project).subscribe(memberships => {
        project.addMembers(memberships);
        this.show_users = true;
      }, err => this.errorService.errorFunction(err));
  }

  loadCreatorTrackedTime(projectId) {
    const API = this.dataService.apiUrl + API_ROUTES.USER_TRACKED_TIME(projectId);
    return this
      .httpRequest
      .get(API, this.dataService.getRequestOptionArgs()).pipe(
        map(res => this.parseResponse(res)));
  }

  getTestSuiteStories(project: Project, page = 1) {
    const data = [];
    const filter_data = project ? this.getFiltersForAPICall(project) : '';
    // Map the filters into data parameter
    if (filter_data && project) {
      this.storyService.updateParamsData(filter_data, data);
    }
    const API = this.dataService.apiUrl + API_ROUTES.TEST_SUITE_STORIES(project.id) + '?page=' + page;
    return this.httpRequest.get(API, {params : data}, this.dataService.getRequestOptionArgs()).pipe(
      map(res => this.parseResponse(res)));
  }

  getTestSuites(project: Project,story,sort_by,page = 1){
    const API = this.dataService.apiUrl + API_ROUTES.GET_TEST_SUITES(project.id,story.id)+ '&sort_by=' + sort_by  + '&page=' + page;
    return this.httpRequest.get(API, this.dataService.getRequestOptionArgs()).pipe(
      map(res => this.parseResponse(res)));
  }

  projectDashboard(projectId, previous_invoice?) {
    const data = {
      invoice_cycle: previous_invoice ? previous_invoice : ''
     };
    // data['invoice_cycle'] = previous_invoice;
    const API = this.dataService.apiUrl + API_ROUTES.PROJECT_DASHBOARD_DATA(projectId);
    return this
      .httpRequest
      .get(API, {params : data}, this.dataService.getRequestOptionArgs()).pipe(
        map(res => this.parseResponse(res)));
  }

  addUserToProject(user: User, project: Project, role: ProjectRole) {
    const data = {
      membership: {
        user_id: user.id,
        role: role
      }
    };
    const API = this.dataService.apiUrl + API_ROUTES.PROJECT_USERS(project.id);
    return this.httpRequest
      .post(API, data, this.dataService.getRequestOptionArgs());
  }

  resendInviteToUser(userId , project: Project) {
    const data = {
      membership: {
        user_id: userId
      }
    };
    const API = this.dataService.apiUrl + API_ROUTES.RESEND_INVITE(project.id);
    return this.httpRequest
      .post(API, data, this.dataService.getRequestOptionArgs()).pipe(
      map(res => this.parseResponse(res)));
  }

  updateApprovedHours(projectId, approvedId, data) {
    const API = this.dataService.apiUrl + API_ROUTES.PROJECT_TIMING_APPROVED_HOURS(projectId, approvedId);
    return this.httpRequest
      .post(API, data, this.dataService.getRequestOptionArgs()).pipe(
      map(res => this.parseResponse(res)));
  }

  addNewColumn(projectId, columnName) {
    const API = this.dataService.apiUrl + API_ROUTES.ADD_NEW_COLUMN(projectId);
    const data = {
      name: columnName
    }
    return this.httpRequest
      .post(API, data, this.dataService.getRequestOptionArgs()).pipe(
      map(res => this.parseResponse(res)));
  }

  renameColumn(projectId, list_id, reValue , type?) {
    let data;
    if(type == 'reorder') {
       data = {
        position: reValue
       };
    } else {
       data = {
        name: reValue
       };
    }
    const API = this.dataService.apiUrl + API_ROUTES.RENAME_LIST(projectId, list_id);
    return this.httpRequest
      .put(API, data, this.dataService.getRequestOptionArgs()).pipe(
      map(res => this.parseResponse(res)));
  }

  removeUserFromProject(userId, project: Project) {
    const API = this.dataService.apiUrl + API_ROUTES.PROJECT_USER(project.id, userId);
    return this.httpRequest
      .delete(API, this.dataService.getRequestOptionArgs()).pipe(
      map(res => this.parseResponse(res)),
      map(res => {
        const membership = project.membershipForUser(userId);
        project.removeMember(membership);
      }));
  }

  changeUserRole(userId, project: Project, role: ProjectRole) {
    const data = {
      membership: {
        role: role
      }
    };
    const API = this.dataService.apiUrl + API_ROUTES.PROJECT_USER(project.id, userId);
    return this.httpRequest
      .put(API, data, this.dataService.getRequestOptionArgs()).pipe(
      map(res => this.parseResponse(res)),
      map(res => {
        const membership = project.membershipForUser(userId);
        membership.role = role;
      }));
  }

   exportFile(projectId, status, phases, export_columns, start_date, end_date) {
     const data = [];
     if (status && status === 'all') {
       data['status'] = 'all';
     } else if (status) {
       data['status[]'] = status;
     }
     if (phases && phases === 'all') {
      data['phases'] = 'all';
     } else if (status) {
      data['phases[]'] = phases;
     }
     if (export_columns) {
       data['export_columns[]'] = export_columns;
     }
     if (start_date) {
       data['start_date'] = start_date;
     }
     if (end_date) {
       data['end_date'] = end_date;
     }
     const API = this.dataService.apiUrl + API_ROUTES.Export_STORIES(projectId);
     return this.http.get(API, this.dataService.getRequestOptionArgs('', false, '', '', '', true, data));
  }

  importUpload(uploadData, projectId, channelName?, import_pt?) {
    const API = this.dataService.apiUrl + API_ROUTES.Import_STORIES(projectId);
    const formData: FormData = new FormData();
    formData.append('id', projectId);
    formData.append('import_pt', import_pt);
    formData.append('project[channelKey]', channelName);
    formData.append('project[import][name]', uploadData.name);
    formData.append('project[import][attachment]', uploadData);
    formData.append('project[import][content_type]', uploadData.type);
    formData.append('project[import][size]', uploadData.size);
    return this.httpRequest
      .patch(API, formData, this.dataService.getRequestOptionArgs());
  }

  acceptProjectInvitation(qparams) {
    let url = this.dataService.apiUrl + API_ROUTES.CONFIRM_MEMBERSHIP;
    if (qparams && Object.keys(qparams).length > 0) {
      url = this.parseQueryParams(qparams, url);
    }
    return this.httpRequest
      .post(url, null, this.dataService.getRequestOptionArgs()).pipe(
      map(res => {
        return this.parseResponse(res);
      }));
  }

  parseQueryParams(qparams, url) {
    url = url + '?';
    for (const key in qparams) {
      let i = Object.keys(qparams).indexOf(key);
      if (i > 0) {
        url = url + '&';
      }
      url = url + key + '=' + qparams[key];
    }
    return url;
  }

  setLocalStorage(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  getLocalStorage(key) {
    return JSON.parse(localStorage.getItem(key));
  }

  project_labels(project: Project) {
    const API = this.dataService.apiUrl + API_ROUTES.PROJECT_LABELS(project.id);
    return this.httpRequest
      .get(API, this.dataService.getRequestOptionArgs()).pipe(
      map(res => this.parseResponse(res).labels));
  }

  addToFavorite(projectId: number) {
    const API = this.dataService.apiUrl + API_ROUTES.PROJECT_FAVORITE(projectId);
    return this.httpRequest.post(API, null, this.dataService.getRequestOptionArgs())
    .pipe(
      map(res => {
        return this.parseResponse(res);
      }));
  }

  deleteFromFavorite(projectId: number) {
    const API = this.dataService.apiUrl + API_ROUTES.PROJECT_FAVORITE(projectId);
    return this.httpRequest.delete(API, this.dataService.getRequestOptionArgs()).pipe(
      map(res => {
        return this.parseResponse(res);
      }));
  }

  deleteColumn(projectId: number, listId: number) {
    const API = this.dataService.apiUrl + API_ROUTES.RENAME_LIST(projectId, listId);
    return this.httpRequest.delete(API, this.dataService.getRequestOptionArgs()).pipe(
      map(res => {
        return this.parseResponse(res);
      }));
  }

  updateCommitFormatConfirmation(projectId) {
    const data = {
      'commit_format_confirmed': true
    };
    const API = this.dataService.apiUrl + API_ROUTES.UPDATE_COMMIT_FORMAT_CONFIRMATION(projectId);
    return this.httpRequest.put(API, data, this.dataService.getRequestOptionArgs()).pipe(
      map(res => this.parseResponse(res)));
  }

  scrollStoryDetailToTop() {
    const mainDiv = document.getElementById('storyDIVScroll');
    if (mainDiv) {
      mainDiv.scrollTop = 0;
    }
  }

  openProjectDD() {
    if(!this.openProDD && this.show_storyDetails) {
      this.hide_StoryDetails();
    }
    this.openProDD = !this.openProDD;
  }

  closeProjectDD(isFilterClear?) {
    if(isFilterClear && this.currentProject) {
      this.currentProject.clearFilter();
    }
    this.openProDD = false;
    return true;
  }

  show_user_activity(projectId: number, userId: number) {
    const API = this.dataService.apiUrl + API_ROUTES.USER_ACTIVITIES(projectId, userId);
    return this.httpRequest.get(API, this.dataService.getRequestOptionArgs()).pipe(
      map(res => {
        return this.parseResponse(res);
      }));
  }
  show_user_card(projectId: number, userId: number) {
    const API = this.dataService.apiUrl + API_ROUTES.USER_CARD(projectId, userId);
    return this.httpRequest.get(API, this.dataService.getRequestOptionArgs()).pipe(
      map(res => {
        return this.parseResponse(res);
      }));
  }
  resendIDEinvite(projectId, membershipId) {
    const API = this.dataService.apiUrl + API_ROUTES.RESEND_IDE_INVITE(projectId, membershipId);
    return this.httpRequest.post(API, null, this.dataService.getRequestOptionArgs())
    .pipe(
      map(res => {
        return this.parseResponse(res);
      }));
  }

  show_StoryDetails(project, story, id, restoreStoryData?, from?, sprintId?) {
    this.sprintQueryParams.q = '';
    this.scrollStoryDetailToTop();
    this.start_storyDetailLoader = true;
    this.render_storyDetails = false;
    let isStorySelected: boolean;
    this.storySizeExpand = localStorage.getItem('storySizeExpand') === 'collapsed';
    if (this.storySizeExpand) {
      document.getElementById('storyDIV').classList.add('expand');
    }
    let prev_col, prev_state;
    const st = this.currentProject.stories.filter(item => item.id === id)[0];
    if (st) {
      isStorySelected = (st && st.isSelected);
      prev_col = st.list_id;
      prev_state = st.state;
    }
    if(this.initiate_from === 'from_sprint_detail') {
      isStorySelected = story.isSelected;
    }
    this.storyService.story(project, story, id, true).subscribe(it => {
      this.storyId = '';
      this.show_Story_history = false;
      this.show_storyDetails = true;
      this.render_storyDetails = true;
      this.show_Story = it;
      if (this.show_Story && this.show_Story.project && this.initiate_from === 'from_sprint_detail') {
        const URL = `/projects/${this.show_Story.project.id}/sprint/${this.sprintId}?storyId=${this.show_Story.id}`;
        this.router.navigateByUrl(URL);
      } else if (this.show_Story && this.show_Story.project && this.initiate_from === 'from_sprint') {
        const URL = `/projects/${this.show_Story.project.id}/sprint?storyId=${this.show_Story.id}`;
        this.router.navigateByUrl(URL);
      } else if (this.show_Story && this.show_Story.project && this.initiate_from === 'from_test_suite') {
        const URL = `/projects/${this.show_Story.project.id}/test-suite?storyId=${this.show_Story.id}`;
        this.router.navigateByUrl(URL);
      } else if (this.show_Story && this.show_Story.project && this.initiate_from === 'from_release_detail') {
        const URL = `/projects/${this.show_Story.project.id}/releases/${this.releaseId}?storyId=${this.show_Story.id}`;
        this.router.navigateByUrl(URL);
      } else {
        const URL = `/projects/${this.show_Story.project.id}?storyId=${this.show_Story.id}`;
        this.router.navigateByUrl(URL);
      }
      if(this.initiate_from === 'from_sprint_detail') {
        it.isSelected = isStorySelected;
      }
      const payload = {
        data: {
          story: this.show_Story,
          prev_state: prev_state,
          prev_col: prev_col
        }
      };
      if (isStorySelected && project.selectedStories.length !== 0) {
        it.isSelected = true;
        const col = this.getColumn(it.list_id);
        col.selectedStoryCount = col.stories.filter(sto => sto.isSelected).length;
        col.allSelected = (col.stories.length === col.selectedStoryCount);
      }
      this.notificationService.broadcast(EVENT_TYPES.STORY.SHOW_DETAILS, payload);
      this.notificationService.broadcast(EVENT_TYPES.SESSION.TIMER_START, payload);
      this.start_storyDetailLoader = false;
      if (!restoreStoryData) {
        this.userService.trackSegmentEvent(this.show_Story.storyOrTask() + ' Clicked',
                                            this.getCommonPropsForStoryBoardEvents(this.show_Story.project, this.show_Story));
      }
    }, (error) => {
      this.errorService.errorFunction(error);
      this.start_storyDetailLoader = false;
    });
  }

  expandStoryDetailSize() {
    this.storySizeExpand = !this.storySizeExpand;
    if (document.getElementById('storyDIV')) {
      if (this.storySizeExpand) {
        localStorage.setItem('storySizeExpand', 'collapsed');
        document.getElementById('storyDIV').classList.add('expand');
      } else {
        localStorage.setItem('storySizeExpand', '');
        document.getElementById('storyDIV').classList.remove('expand');
      }
    }
  }
  
  recallStory(project,story,id){
    this.start_storyDetailLoader = true;
    this.storyService.story(project, story, id, true).subscribe(it => {
      this.storyId = '';
      this.show_Story_history = false;
      this.show_storyDetails = true;
      this.render_storyDetails = true;
      this.show_Story = it;
      const payload = {
        data: {
          story: this.show_Story,
        }
      };
      !this.storyService.canDisableResetFlag && this.customToast.messages.push({
        id: 'ResetSuccess',
        type: 'success',
        class: 'new_member_added',
        title: 'Story Reset Successfully',
        message: 'Story details have been reset successfully.'
      });
      this.notificationService.broadcast(EVENT_TYPES.STORY.SHOW_DETAILS, payload);
      this.start_storyDetailLoader = false;
    }, (error) => {
      this.errorService.errorFunction(error);
      this.start_storyDetailLoader = false;
    });
  }
   
  hide_StoryDetails() {
    const payload = {
      data: {
        story: this.show_Story,
        closeExpand: this.storySizeExpand
      }
    };
    this.notificationService.broadcast(EVENT_TYPES.STORY.CLOSE_EXPAND, payload);
     if (this.show_Story && this.show_Story.project) {
      if (this.initiate_from === 'from_sprint') {
        this.router.navigate(['/projects/' + this.show_Story.project.id + '/sprint']);
      } else if(this.initiate_from === 'from_sprint_detail') {
        this.router.navigate(['/projects/' + this.show_Story.project.id + '/sprint/' + this.sprintId]);
      } else if(this.initiate_from === 'from_test_suite') {
        this.router.navigate(['/projects/' + this.show_Story.project.id + '/test-suite']);
      } else if(this.initiate_from === 'from_release_detail') {
        this.router.navigate(['/projects/' + this.show_Story.project.id + '/releases/' +  this.releaseId]);
      } else{
        this.router.navigate(['/projects/' + this.show_Story.project.id]);
      }
      this.show_Story.notes.forEach(note => note.prevOpenCommentReplyStatus = false);
    } else if (this.currentProject && this.initiate_from !== 'from_sprint_detail') {
      this.router.navigate(['/projects/' + this.currentProject.id]);
    }
    this.start_storyDetailLoader = false;
    this.searchString = '';
    this.show_storyDetails = false;
    this.show_Story = null;
    this.storyId = null;
    this.show_Story_history = false;
    this.render_storyDetails = false;
    this.acceptRejectSubtasks = [];
    this.scrollStoryDetailToTop();
  }

  hide_StoryDetails_if_it_is_open() {
    const payload = {
      data: {
        story: this.show_Story,
        closeExpand: this.storySizeExpand
      }
    };
    this.notificationService.broadcast(EVENT_TYPES.STORY.CLOSE_EXPAND, payload);
    this.start_storyDetailLoader = false;
    this.searchString = '';
    this.show_storyDetails = false;
    this.show_Story = null;
    this.storyId = null;
    this.show_Story_history = false;
    this.render_storyDetails = false;
    this.scrollStoryDetailToTop();
  }

  getPropsOnStoryTaskCreation(project, story, isSubTask?, parentStory?) {
    if (project && story) {
      const props = {
        'build_card_id': project.build_card_id,
        '360_project_id': project.dashboard_project_id,
        'platform_name': project.platform_names,
        'tracker_id': project.id,
        'user_email': this.userService.getUser().email,
        'user_type': this.userService.getIsUserInternalOrCp(),
        'project_name': project.name,
        'project_type': project.dashboard_project_id ? 'client' : 'internal',
        'title': story.title,
        'requester' : this.userService.getUser().email,
        'labels' : this.getLabelsId(story),
        'description': story.description,
        'owner' : story.owner_email,
        'reviewer' : story.reviewer_email,
        'owner_estimate' : story.estimate,
        'reviewer_estimate' : story.reviewer_estimate,
        'phase': story.phase,
        // 'severity': story.severity,
        'comments': [],
        'blockers': []
      };

      if (isSubTask) {
        props['features'] = this.getFeaturesId(parentStory);
      } else {
        props['features'] = this.getFeaturesId(story);
      }

      if (story && story.parent_id) {
        props['story_id'] = story.parent_id;
        props['task_id'] = story.id;
        props['sub_phase'] = story.task;
        props['task_state'] = story.state;
        props['task_execution'] = story.task_execution;
        props['task_type'] = story.story_type;
      } else {
        props['story_id'] = story.id;
        props['story_state'] = story.state;
        props['story_type'] = story.story_type;
      }
      return props;
    }
  }

  getPropsOnStoryTaskUpdation(project, story, prop, propKey?) {
    if (project && story) {
      const props = {
        'build_card_id': project.build_card_id,
        '360_project_id': project.dashboard_project_id,
        'platform_name': project.platform_names,
        'tracker_id': project.id,
        'updated_by': this.userService.getUser().email,
        'phase': story.phase,
        'user_email': this.userService.getUser().email,
        'user_type': this.userService.getIsUserInternalOrCp(),
        'project_name': project.name,
        'project_type': project.dashboard_project_id ? 'client' : 'internal'
      };

      if (prop === 'phase') {
      } else if (prop === 'owner') {
        props[prop] = story.owner_email;
      } else if (prop === 'reviewer') {
        props[prop] = story.reviewer_email;
      } else if (prop === 'features') {
        props[prop] = this.getFeaturesId(story);
      } else if (prop === 'labels') {
        props[prop] = this.getLabelsId(story);
      } else if (prop === 'blockers') {
        props[prop] = propKey;
      } else if (prop === 'comment_added') {
        props[prop] = this.getCommentData(propKey);
      } else if (prop === 'task') {
        props['sub_phase'] = story.task;
      } else if (prop === 'owner estimate') {
        props['owner_estimate'] = story.estimate;
      } else if (prop === 'state') {
        if (story.state == 'finished' || story.state == 'delivered' || story.state == 'accepted' || story.state == 'rejected' ) {
          const time = (story.timer_elapsed_total / 3600).toFixed(2);
          props['owner_time_taken'] = time;
        }
        props[prop] = story[prop];
      } else if (prop === 'story_type') {
        // if (story.story_type == 'bug' || story.story_type == 'side_effect') {
        //   props['severity'] = story.severity;
        // }
        if (story && story.parent_id) {
          props['task_type'] = story[prop];
        } else {
          props[prop] = story[prop];
        }
      } else if (!propKey) {
        props[prop] = story[prop];
      } else {
        props[prop] = story[propKey];
      }

      if (story && story.parent_id) {
        props['story_id'] = story.parent_id;
        props['task_id'] = story.id;
      } else {
        props['story_id'] = story.id;
      }

      return props;
    }
  }

  getFeaturesId(story) {
    if(story) {
      const epicIds: Array<any> = [];
      story.epics.forEach(item => {
        if (item.epic && item.epic.builder_feature_id) {
          epicIds.push(item.epic.builder_feature_id);
        } else if (item.builder_feature_id) {
          epicIds.push(item.builder_feature_id);
        }
      });
      return epicIds;
    }
  }

  getLabelsId(story) {
    let labelIds: Array<any> = [];
    if (typeof (story.labels) === 'string') {
      labelIds = story.labels.split(',');
      return labelIds;
    } else if (story.labels?.length > 0) {
      story.labels.forEach(lab => labelIds.push(lab.display));
      return labelIds;
    }
    return labelIds;
  }

  getCommentData(note) {
    const urls: Array<any> = [];
    note.documents.forEach(doc => urls.push(doc.document.remote_url));

    const com = {
      'id': note.id,
      'comment': note.note,
      'attachments': urls
    };

    return com;
  }

  getFiltersForAPICall(project: Project) {
    const data = {
      'sub_type[]': project.filters ? project.filters.sub_type : [],
      'story_types[]': project.filters ? project.filters.story_type : [],
      'phases[]': project.filters ? project.filters.phase : [],
      'epic_ids[]': project.filters ? project.filters.feature : [],
      'labels[]': project.filters ? project.filters.label : [],
      'bb_status[]' : project.filters ? project.filters.bb_status : [],
      'states[]' : project.filters ? project.filters.states : [],
      'priorities[]': project.filters ? project.filters.priority : [],
      'created_from[]': project.filters ? project.filters.created_from : [],
      'platform_ids[]': project.filters ? project.filters.platform : [],
      'code_commit[]': project.filters ? project.filters.code_commit : [],
      'created_on_date_range[]': project.filters ? project.filters.created_on_date_range : [],
      'state_changed_at_date_range[]' : project.filters ? project.filters.state_changed_at_date_range : [],
      'studio_role_ids[]': project.filters ? project.filters.studio_roles : [],
      'owner_ids[]': project.filters ? project.filters.owner : [],
      'requestor_ids[]': project.filters ? project.filters.requester : [],
      'reviewer_ids[]': project.filters ? project.filters.reviewer : [],
      'dev_type[]': project.filters ? project.filters.dev_type : [],
      'sprint_ids[]': project.filters ? project.filters.sprints : [],
      'bb_design[]': project.filters ? project.filters.bb_design : [],
      'statuses[]': project.filters ? project.filters.statuses : [],
      'ship_release_date_range[]': project.filters ? project.filters.ship_release_date_range : [],
      'rating[]': project.filters ? project.filters.rating : [],
      'swimlane[]': project.filters ? project.filters.swimlane : []
    };
    return data;
  }

  getProjectSprintsBacklog(projectId, sort_by, s_data, s_page_no, project?) {
    const data = [];
    const filter_data = project ? this.getFiltersForAPICall(project) : '';
    // Map the filters into data parameter
    if (filter_data && project) {
      this.storyService.updateParamsData(filter_data, data);
    }
    const searchBy = this.storyService.searchStoriesVal;
    const API = this.dataService.apiUrl + API_ROUTES.SPRINTS_BACKLOG_STORIES(projectId) + '?q=' + s_data + '&sort_by=' + sort_by + '&p=' + searchBy + '&page=' + s_page_no;
      return this.httpRequest.get(API, {params : data}, this.dataService.getRequestOptionArgs()).pipe(
        map(res => this.parseResponse(res)));
  }

  getSprintsDetailsBacklog(projectId, sprintId ,sort_by, s_data, s_page_no, project?, per_page = 20) {
    const data = [];
    const filter_data = project ? this.getFiltersForAPICall(project) : '';
    // Map the filters into data parameter
    if (filter_data && project) {
      this.storyService.updateParamsData(filter_data, data);
    }
    const searchBy = this.storyService.searchStoriesVal;
    const API = this.dataService.apiUrl + API_ROUTES.SPRINTS_DETAIL_BACKLOG_STORIES(projectId,sprintId) + '&q=' + s_data + '&sort_by=' + sort_by + '&p=' + searchBy + '&page=' + s_page_no + '&per_page=' + per_page;
      return this.httpRequest.get(API, {params : data}, this.dataService.getRequestOptionArgs()).pipe(
        map(res => this.parseResponse(res)));
  }

  getSprintsDetailsAddedStories(projectId, sort_by, s_id, s_page_no,per_page = 20) {
    const API = this.dataService.apiUrl + API_ROUTES.SPRINTS_DETAIL_ADDED_BACKLOG_STORIES(projectId, s_id) + '&sort_by=' + sort_by + '&page=' + s_page_no +'&per_page=' + per_page;
    return this.httpRequest.get(API, this.dataService.getRequestOptionArgs()).pipe(
      map(res => this.parseResponse(res)));
  }

  projectStories(project: Project, apply_default_filter = false, sortBy?, page_no?) {
    return this.storyService.stories(project, null, null, this.getFiltersForAPICall(project), apply_default_filter, sortBy, page_no);
  }

  addStoryIntoSprint(projectId, sprintId, story_ids) {
    const API = this.dataService.apiUrl + API_ROUTES.ADD_STORY_TO_SPRINT(projectId, sprintId);
    return this
      .httpRequest
      .post(API, story_ids, this.dataService.getRequestOptionArgs());
  }
  
  removeStoryFromSprint(projectId, sprintId, story_ids) {
    const API = this.dataService.apiUrl + API_ROUTES.REMOVE_STORY_FROM_SPRINT(projectId, sprintId);
    return this
      .httpRequest
      .post(API, story_ids, this.dataService.getRequestOptionArgs());
  }

  loadStories(project: Project, apply_default_filter) {
    return this.projectStories(project, apply_default_filter).pipe(
      map((columns: ProjectColumn[]) => {
          while(PROJECT_COLUMN_DATA.length > 0) {
            PROJECT_COLUMN_DATA.pop();
          }
          this.addFeatureCol();
          if(this.currentProject.id != project.id) {
            project.clearFilter();
            this.currentProject.clearFilter();
            return 'project-change';
          }
          this.isDefaultFilterApplied = columns['default_filter_applied'];
          columns['lists'].map((col: ProjectColumn) => {
            let column = new ProjectColumn(col);
            column.setStories(project);
            PROJECT_COLUMN_DATA.push(column);
          })
          return project.stories;
        }
      ));
  }

  public parseResponse(res: any): any {
    return res;
  }

  addStoryOrSubTaskOnStoryBoard(project, story) {
    if (project.filterCount === 0) {
      this.addStoryInProject(project, story);
    } else {
      const story_ids = [];
      story_ids.push(story.id);
      this.storyService.stories(project, story_ids, null, this.getFiltersForAPICall(project))
        .subscribe(res => {
          if (res && res.length === 1) {
            res.forEach(item => {
              if (item.story.id === story.id) {
                this.addStoryInProject(project, story);
              }
            });
          }
        });
    }
  }

  addStoryInProject(project, story) {
    project.addStory(story);
    project.addEpic(new Epic(story.epics[0]));
    if (story) {
      story.refreshEpics();
    }
    this.notificationService.broadcast(EVENT_TYPES.STORY.ADDED, { data: { story: story } });
  }

  getCommonPropsForStoryBoardEvents(project, story?) {
    if (project) {
      const props = {
        'build_card_id': project.build_card_id,
        '360_project_id': project.dashboard_project_id,
        'platform_name': project.platform_names,
        'tracker_id': project.id,
        'user_name': this.userService.getUser().name ? this.userService.getUser().name : '',
        'user_email': this.userService.getUser().email,
        'user_type': this.userService.getIsUserInternalOrCp(),
        'project_name': project.name,
        'project_type': project.dashboard_project_id ? 'client' : 'internal'
      };

      if (story) {
        if (story.parent_id) {
          props['story_id'] = story.parent_id;
          props['task_id'] = story.id;
        } else {
          props['story_id'] = story.id;
        }
      }

      return props;
    }
  }
  checkStoryCreateNeedConfirmation(story, project) {
    if (this.policyService.isProjectReadonly(project) || (story && story.deleted_at)) {
      this.policyService.readOnlyWarning();
    } else {
      this.duplicate_with_filter = true;
        const payload = {
          data: {
            story: story,
            reason: this.creationReasonsSelected
          }
        };
        this.notificationService.broadcast(EVENT_TYPES.STORY.DUPLICATE_AFTER_CONFIRM, payload);
    }
  }

  checkCreateConfirmation(type, epic?: Epic, story?: Story) {
    this.creationReason_origin = type;
    if(type === 'new_story' || type === 'new_bug') {
      this.createBug = (type === 'new_bug') ? 'bug': '';
      this.setFeatureFilter = false;
      (epic.task_done_count && !(this.policyService.expertAsQA(epic.project, false)) && type === 'new_story') ? this.openCreationReasonDialog(epic) : this.addNewStory(epic);
    }
    if(type === 'duplicate_story') {
      this.story_to_duplicate = story;
      this.openCreationReasonDialog(epic);
    }
  }

  addNewStory(epic: Epic) {
    this.hide_StoryDetails();
    this.setFeatureFilter = false;
    if(this.policyService.isProjectReadonly(this.currentProject)) {
      this.policyService.readOnlyWarning();
    } else {
      const payload = {
        data: {
          epic: epic,
          reason: this.creationReasonsSelected
        }
      };
      this.notificationService.broadcast(EVENT_TYPES.EPIC.CREATE_NEW_STORY, payload);
    }
  }

  openCreationReasonDialog(epic?) {
    this.story_create_for_epic = epic;
    this.showCreationReasonDialog = true;
    this.creationReasonsSelected = '';
  }

  showReasonList() {
    this.story_creation_reason = !this.story_creation_reason;
  }

  getOtherReasonData(bulk_story?) {
    bulk_story ? bulk_story.story_create_reason_for_bulk_duplicate = bulk_story.other_reason_input : this.creationReasonsSelected = this.otherReason;
  }

  selectCreationReason(reason, bulk_story?) {
    bulk_story ? bulk_story.bulk_story_selected_reason = reason : this.selected_reason_option = reason;
    if(reason === "Other(s)") {
      if(bulk_story) {
        bulk_story.story_create_reason_for_bulk_duplicate = '';
        bulk_story.show_other_reason_input = true
      } else{
        this.other_reason = true;
        this.creationReasonsSelected = '';
      }
    } else {
      this.otherReason = '';
      if(bulk_story) {
        bulk_story.show_other_reason_input = false
        bulk_story.story_create_reason_for_bulk_duplicate = reason;
      } else{
        this.other_reason = false;
        this.creationReasonsSelected = reason;
      }
    }
    bulk_story ? (bulk_story.show_reason_dd = false) : (this.story_creation_reason = false);
  }

  confirmedToCreateStory() {
    if(this.creationReasonsSelected) {
      if(this.creationReason_origin === 'duplicate_story') {
        const payload = {
          data: {
            story: this.story_to_duplicate,
            reason: this.creationReasonsSelected
          }
        };
        this.notificationService.broadcast(EVENT_TYPES.STORY.DUPLICATE_AFTER_CONFIRM, payload);
      } else {
        this.addNewStory(this.story_create_for_epic);
      }
      this.closeCreationReasonDialog();
    }
  }

  closeCreationReasonDialog(reset_all_value?) {
    this.story_create_for_epic = null;
    this.showCreationReasonDialog = false;
    if(reset_all_value === 'reset_all_values') {
      this.creationReasonsSelected = '';
    }
    this.creationReason_origin = '';
    this.story_to_duplicate = null;
    this.other_reason = false;
    this.otherReason = '';
    this.selected_reason_option = '';
    this.story_creation_reason = false;
  }

  getWorkingHours(emailIds){
    const API = environment.apiHiveBase + API_ROUTES.WORKING_HOURS;
    let url = this.WorkingHoursQueryParams(API,emailIds)
    return this.httpRequest
    .get(url, '',this.dataService.getRequestOptionArgs('', true)).pipe(
      map(res => this.parseResponse(res)));
  }

  WorkingHoursQueryParams(API,emailIds){
    let apiUrl = '';
    let i = 0;
    emailIds.forEach(email =>{      
      if (i === 0) {
        apiUrl = apiUrl + '?' + 'emails[]';
        apiUrl = apiUrl + '=' + email;
        i++;
      } else {
        apiUrl = apiUrl + '&' + 'emails[]' + '=' + email;
        i++;
      }
  })
  return API + apiUrl;
}

closeCloneConfirmationPopup() {
  this.otherReason = '';
  this.other_reason = false;
  this.creationReasonsSelected = '';
  this.story_creation_reason = false;
  this.selected_reason_option = '';
  this.cloneConfirmPopup = false;
}

getlocalStorageFilterData(){
  const filterData = localStorage.getItem('parentOrSubtaskFilter');
  if (filterData) {
    const parsedData = JSON.parse(filterData);
    const currentProjectFilterData = parsedData.find(data => data.project_id === this.currentProject.id);
    this.storyService.parentOrSubtaskStories = currentProjectFilterData ? currentProjectFilterData.filter : 'both';
  }else {
    this.storyService.parentOrSubtaskStories = 'both';
  } 
}
  getExpandCollapse(project) {
    const expandableListOptions = localStorage.getItem('expandableListOptions')
    if (expandableListOptions) {
      const existingExpandData = JSON.parse(expandableListOptions); 
      existingExpandData.forEach(data => {
        if (this.projectColumns && data.project_id === project.id) {
          const expandableList = this.projectColumns.find(col => col.list_id === data.list_id)
          if (expandableList) {
            expandableList.expandable = data.expandable;
          }
        }
      })
    }
  }

  expandCollpaseColumn(column,project) {
    column.expandable = false;
    this.existingExpandData = [];
    const expandableListOptions = localStorage.getItem('expandableListOptions')
    if (expandableListOptions) {
      this.existingExpandData = JSON.parse(expandableListOptions); 
      const index = this.existingExpandData.findIndex(e => e.list_id === column.list_id);
      if (index !== -1) {
        this.existingExpandData.splice(index, 1);
      }
    }
    this.existingExpandData.push({ project_id: project.id, list_id: column.list_id, expandable: column.expandable })
    localStorage.setItem('expandableListOptions', JSON.stringify(this.existingExpandData));
  }

  updateFilteredList(project){
    this.loadStories(project, false).subscribe((stories) => {
      this.projectColumns = PROJECT_COLUMN_DATA;
      this.getExpandCollapse(project);
      if (typeof stories === 'string' && stories === 'project-change') {
        this.startApplyFilterLoader = false;
        if (project.stories.length > 0) {
          this.showHtml = true;
        } else {
          this.showHtml = false;
        }
      } else {
        this.showHtml = true;
        this.startApplyFilterLoader = false;
      }
    }, err => {
      this.errorService.errorFunction(err);
      this.startApplyFilterLoader = false;
    });
  }

  validateStoryPermission(story,project,story_type?, column = null) {
    // story_type will be available for sub tasks otherwise pick for story
    if (!story_type) {
      story_type = story.story_type;
    }

    if (story_type === 'test_case') {

      // if user is not active member in project
      if(!project.isUserActive(this.userService.getUser())) { return true;} 

      if (column === 'title' || column === 'description') { return false; }
      
      return !this.policyService.expertAsQA(project);
    } else {
      return this.policyService.isProjectUser(project) && (story_type == 'task' || (story_type == 'bug' && !this.policyService.isQAExpert(this.project)));
    }
  }

  checkAddedFeature(story){
    if (((story.story_type === 'bug' && this.policyService.isQAExpert(this.project)) || story.testCase()) && story.epics.length > 0) {
      return false;
    }else return true;
  }

  checkRestriction(story,project) {
    return this.policyService.isProjectReadonly(project) || story.inactive || story.deleted_at;
  }

  bbExists(story){
    const storyEpic: Epic = story.getEpicInStory();
    return story.core && story.phase !== 'Design' && story.phase !== 'Product Roadmap' && story.phase !== 'Prototype' && (storyEpic.bb_status === BB_STATUS.ready_for_certification || storyEpic.bb_status === BB_STATUS.ready_for_customisation || storyEpic.bb_status === BB_STATUS.finished_using_bb || storyEpic.bb_status === BB_STATUS.ongoing_customisation || storyEpic.bb_status === BB_STATUS.finished_customisation);
  }
  
  getProjectStatsData(projectId) {
    let queryParams = { timezone: '' };
    queryParams.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let api = this.dataService.apiUrl + API_ROUTES.PROJECT_STATS(projectId);
    let url = this.dataService.parseQueryParams(api, queryParams)
    return this.httpRequest
      .get(url, this.dataService.getRequestOptionArgs()).pipe(
        map(res => this.parseResponse(res)));
  }

  moveStoriesAndCompleteSprint(sprint, projectId,nextSprintId){
    let sprintId = sprint.id;
    const params = {
      next_sprint_id : nextSprintId
    };
    const API = this.dataService.apiUrl + API_ROUTES.UPDATE_SPRINT(projectId, sprintId);
    const url = this.dataService.parseQueryParams(API, params)
    return this
      .httpRequest
      .put(url, sprint, this.dataService.getRequestOptionArgs());
  }

  checkBBStatusSkipped(story){
    const storyEpic: Epic = story.getEpicInStory();
    return storyEpic.bb_status === 'skipped' || storyEpic.bb_status === null;
  }
}


