import { forwardRef, Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import {Epic} from '../models/epic';
import {Project} from '../models/project';
import {Story} from '../models/story';
import {User} from '../models/user';
import {EVENT_TYPES, ProjectRole, PROJECT_COLUMN_DATA, PROJECT_COLUMN, PROJECT_COLUMN_IDENTIFIER, NEW_STORY_WRITTING_REASONS} from '../dataTypes';
import { API_ROUTES } from '../apiRoutes';
import { NotificationService } from './notification.service';
import { StoryService } from './story.service';
import { ErrorService } from '../errorFunction';
import { Observable, Subject, Subscription } from 'rxjs';
import { UserService } from './user.service';
import { environment } from '../../../environments/environment';
import {map} from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DataService } from './data.service';
import { HttpRequests } from './http-requests.service';
import {EpicService} from './epic.service';
import { Router } from '@angular/router';
import { ProjectColumn } from '../models/column';
import { PolicyService } from './policy.service';
import { SharedService } from './shared.service';
import { ProjectService } from './project.service';
import { Sprint } from '../models/sprint';
import _ from 'lodash';

@Injectable()
export class FilterStoryService {
  projectColumns: Array<ProjectColumn> = PROJECT_COLUMN_DATA;
  meFilter: boolean;
  sprintSubscription: Subscription;
  sprintFilterList: any = []
  sprintSelected: boolean = false
  currentRunningSprintList: any = []
  deletedSprintData:any = null
  sprintsMetaData: {
    current_page: number,
    next_page: number,
    per_page: number,
    total_count: number
  };
  isFirst: boolean = true

  constructor(
    private dataService: DataService,
    private httpRequest: HttpRequests,
    private router: Router,
    @Inject(forwardRef(() => StoryService)) private storyService: StoryService,
    @Inject(forwardRef(() => ProjectService)) private projectService: ProjectService,
    @Inject(forwardRef(() => SharedService)) private sharedService: SharedService,
    @Inject(forwardRef(() => PolicyService)) private policyService: PolicyService,
    @Inject(forwardRef(() => EpicService)) private epicService: EpicService,
    @Inject(forwardRef(() => ErrorService)) private errorService: ErrorService,
    @Inject(forwardRef(() => UserService)) private userService: UserService,
    @Inject(forwardRef(() => NotificationService)) private notificationService: NotificationService) {
    
  }

  getFeaturesFromBBStatus(project, bbstatus){
    if (project.epics) {
      project.epics.forEach(item => {
        if (item.bb_status === bbstatus) {
          project.addFilterParam('feature', item.id)
        }
      });
    }
  }
  deselectBBStatusOnFeatureChange(project, value){
    if (project.epics && value) {
      const status = project.epics.find(ftr => ftr.id === value).bb_status;
      const filterIndex = project.findFilterIndex('bb_status', status);
      project.removeFilterParam('bb_status', filterIndex);
    }
  }

  deselectFeaturesOnBBStatusDeselect(project, value){
    if (project.epics) {
      project.epics.forEach(ftr =>{
        if (ftr.bb_status == value) {
          const filterIndex = project.findFilterIndex('feature', ftr.id);
          if (filterIndex !== -1) {
             project.removeFilterParam('feature', filterIndex);     
          }
        }
      });
    }
  }

  getFeaturesFromSwimlane(project, group){
    if (project.epics) {
      project.epics.forEach(item => {
        if (item.group_no === group) {
          project.addFilterParam('feature', item.id)
        }
      });
    }
  }
  deselectSwimlaneOnFeatureChange(project, value){
    if (project.epics && value) {
      const group = project.epics.find(ftr => ftr.id === value).group_no;
      const filterIndex = project.findFilterIndex('swimlane', group);
      project.removeFilterParam('swimlane', filterIndex);
    }
  }

  deselectFeaturesOnSwimlaneDeselect(project, group){
    if (project.epics) {
      project.epics.forEach(ftr =>{
        if (ftr.group_no == group) {
          const filterIndex = project.findFilterIndex('feature', ftr.id);
          if (filterIndex !== -1) {
             project.removeFilterParam('feature', filterIndex);     
          }
        }
      });
    }
  }

  isFilterChecked(project, key, value) {
    return project.findFilterIndex(key, value) >= 0 ? true : false;
  }

  clearDataForApplyFilter(project) {
    this.projectService.showHtml = false;
    if (project) {
      project.stories = [];
    }
    PROJECT_COLUMN_DATA.forEach(col => {
      col.stories = [];
      col.allSelected = false;
      col.selectedStoryCount = 0;
      col.current_page = 0;
      col.per_page_count = 0;
      col.total_count = 0;
      col.total_pages = 0;
    });
  }

  deSelectAll(project) {
    project.selectedStories.forEach((story) => {
      story.isSelected = false;
      story.storyQuickActionClose();
    })
    this.projectService.archived_story_selected = false;
    this.projectService.normal_story_selected = false;
    this.projectColumns.forEach(col => {
      col.allSelected = false;
      col.selectedStoryCount = 0;
    });
    project.selectedStories = [];
  }

  onlyVisibleColumns() {
    PROJECT_COLUMN_DATA.forEach((col) => {
      if (this.sharedService.visibleIds.indexOf(col.list_id) < 0) {
        col.visible = false;
      }
    })
  }

  selectFltersFromMembers(project,memberId){
    project.addFilterParam('owner', memberId);
    project.addFilterParam('requester', memberId);
    project.addFilterParam('reviewer', memberId);
  }

  deselectFltersFromMembers(project,memberId){
    const filterIndex = project.findFilterIndex('member', memberId);
    if (filterIndex !== -1) {
      project.removeFilterParam('member', filterIndex);    
    }  
  }

  deselectFieldsOnMemberChange(project,memberId){
    if (project.memberships) {
      const ownerFilterIndex = project.findFilterIndex('owner', memberId);
      if (ownerFilterIndex !== -1) {
        project.removeFilterParam('owner', ownerFilterIndex);    
      }
      const reviewerFilterIndex = project.findFilterIndex('reviewer', memberId);
      if (reviewerFilterIndex !== -1) {
        project.removeFilterParam('reviewer', reviewerFilterIndex);    
      }
      const requesterFilterIndex = project.findFilterIndex('requester', memberId);
      if (requesterFilterIndex !== -1) {
        project.removeFilterParam('requester', requesterFilterIndex);    
      }
    }
  }
  
  getFeaturesFromBBDesign(project, device){
    if (project.epics) {
      project.epics.forEach(item => {
        if (item.bb_design_infos.length > 0 && item.bb_design_infos.find(design => design.device === device)) {
          project.addFilterParam('feature', item.id)
        }
      });
    }
  }

  deselectFeaturesOnBBDesignDeselect(project,value){
    if (project.epics) {
      project.epics.forEach(ftr =>{
        if ((ftr.bb_design_infos.length === 1 && ftr.bb_design_infos.find(design => design.device === value)) || project.filters.bb_design.length === 1) {
          const filterIndex = project.findFilterIndex('feature', ftr.id);
          if (filterIndex !== -1) {
             project.removeFilterParam('feature', filterIndex);     
          }
        }
      });
    }
  }

  deselectBBDesignOnFeatureChange(project, value) {
    if (project.epics && value) {
      const selectedEpic = project.epics.find(ftr => ftr.id === value);
      if (selectedEpic && selectedEpic.bb_design_infos && selectedEpic.bb_design_infos.length > 0) {
        selectedEpic.bb_design_infos.forEach(design => {
          const deviceIndex = project.findFilterIndex('bb_design', design.device);
          if (deviceIndex !== -1) {
            project.removeFilterParam('bb_design', deviceIndex);
          }
        });
      }
    }
  }

  meFilterSelected(project,currentUser){
    if (project) {
      return  project.filters.member.length === 1 && project.filters.member.find(member => member === currentUser) &&
              project.filters.owner.length === 1 && project.filters.owner.find(owner => owner === currentUser) &&
              project.filters.reviewer.length === 1 && project.filters.reviewer.find(reviewer => reviewer === currentUser) &&
              project.filters.requester.length === 1 && project.filters.requester.find(requester => requester === currentUser);
    }
  }

  deletedSprintFilterOnUpdation(id) {
    if (this.deletedSprintData && this.deletedSprintData.id === id) {
      const sprint_Index = this.sprintFilterList.findIndex(st_sprint_index => st_sprint_index.id === this.deletedSprintData.id);
      if (sprint_Index !== -1) {
        this.sprintFilterList.splice(sprint_Index, 1);
        this.currentRunningSprintList = this.sprintFilterList.filter((sprint: any) => sprint.status === 'running')
      }
      this.deletedSprintData = null
    }
  }

  applyFilter(project, event, key, value) {
    this.deletedSprintFilterOnUpdation(value)
    if (key === 'bb_design') {
      if (event.target.checked) {
        this.getFeaturesFromBBDesign(project, value);  
      }else{
        this.deselectFeaturesOnBBDesignDeselect(project, value);
      }
    }
    if (key === 'bb_status') {
      if (project.epics) {
        const index = project.epics.findIndex(ftr => ftr.bb_status === value)
          if (index == -1) {
            return;
          }
      }
      if (event.target.checked) {
        this.getFeaturesFromBBStatus(project, value);  
      }else{
        this.deselectFeaturesOnBBStatusDeselect(project, value);
      }
    }
    if (key === 'swimlane') {
      if (project.epics) {
        const index = project.epics.findIndex(ftr => ftr.group_no === value)
          if (index == -1) {
            return;
          }
      }
      if (event.target.checked) {
        this.getFeaturesFromSwimlane(project, value);  
      }else{
        this.deselectFeaturesOnSwimlaneDeselect(project, value);
      }
    }
    if (key === 'owner' || key === 'reviewer' || key === 'requester') {
      if (!event || !event.target.checked) {
        this.deselectFltersFromMembers(project,value);  
      }
    }
    if (key === 'member') {
      if ((event && event.target.checked || this.meFilter)) {
        this.selectFltersFromMembers(project, value);
        if (this.isFirst && JSON.parse(localStorage.getItem('currentSprintSelection'))) {
          const currentListId = this.currentRunningSprintList.map((val: any) => val.id)
          currentListId.forEach((val: number) => { project.addFilterParam('sprints', val) })
        }
      }else{
        this.deselectFieldsOnMemberChange(project,value);
      }
    }
    if (key === 'feature') {
      this.deselectBBStatusOnFeatureChange(project, value);
      this.deselectBBDesignOnFeatureChange(project, value);
      this.deselectSwimlaneOnFeatureChange(project,value)
    }
    if (!this.projectService.startApplyFilterLoader) {
      this.projectService.startApplyFilterLoader = true;
      this.projectService.closeSearch();
      const filterIndex = project.findFilterIndex(key, value);
      let eventName = '';
      if (key === 'ship_release_date_range' || key === 'created_on_date_range' || key === 'state_changed_at_date_range') {
        if (value) {
          project.addFilterParam(key, value);
          eventName = 'Filter Applied';
        } else if (filterIndex !== -1) {
          project.removeFilterParam(key, filterIndex);
          eventName = 'Filter Removed';
        }
      } else if ((!event || event.target.checked) && !this.isFilterChecked(project, key, value)) {
        if (filterIndex === -1) {
          if (key === 'sub_type' && !this.isFilterChecked(project, 'story_type', 'task')) {
            project.addFilterParam('story_type', 'task');
          }
          if (Array.isArray(value)) {
            value.forEach((val: number) => { project.addFilterParam(key, val) })
          } else {
            project.addFilterParam(key, value);
          }
          eventName = 'Filter Applied';
        } else {
          this.projectService.startApplyFilterLoader = false;
          return;
        }
      } else {
        if (filterIndex !== -1) {
          if (key === 'story_type' && value === 'task') {
            project.filters.sub_type = [];
          }
          project.removeFilterParam(key, filterIndex);
          eventName = 'Filter Removed';
        }
        if (event && key === 'sprints' && !event.target.checked && Array.isArray(value)) {
          value.forEach((val: number) => {
            let indexNumber = project.findFilterIndex(key, val)
            if (indexNumber !== -1) {
              project.removeFilterParam(key, indexNumber);
            }
          })
        }
      }

      // if(key)
      // this.filtersCount = this.project.filterCount;
      this.clearDataForApplyFilter(project);
      if(this.projectService.initiate_from === 'from_sprint') {
        this.notificationService.broadcast(EVENT_TYPES.SPRINT.SPRINT_FILTER, { data: { filter_at: 'sprint_dashboard'} });
      } else if(this.projectService.initiate_from === 'from_sprint_detail') {
        this.notificationService.broadcast(EVENT_TYPES.SPRINT.SPRINT_FILTER, { data: { filter_at: 'sprint_detail'} });
      } else if(this.projectService.initiate_from === 'from_test_suite') {
        this.notificationService.broadcast(EVENT_TYPES.TEST_SUITE.TEST_SUITE_STORY__FILTER, { data: { filter_at: 'test_suit'} });
      } else if(this.projectService.initiate_from === 'from_release') {
        this.notificationService.broadcast(EVENT_TYPES.RELEASE.RELEASE_FILTER, { data: { filter_at: 'release_filter'} });
      } else{
        this.projectService.loadStories(project, false).subscribe((stories) => {
          if(project.selectedStories.length > 0) {
            this.deSelectAll(project);
          }
          this.projectColumns = PROJECT_COLUMN_DATA;
          this.projectService.getExpandCollapse(project);
          this.onlyVisibleColumns();
          if (typeof stories === 'string' && stories === 'project-change') {
            this.projectService.startApplyFilterLoader = false;
            this.projectService.meFilterLoader = false;
            if (project.stories.length > 0) {
              this.projectService.showHtml = true;
            } else {
              this.projectService.showHtml = false;
            }
          } else {
            this.projectService.showHtml = true;
            this.projectService.startApplyFilterLoader = false;
            this.projectService.meFilterLoader = false;
            this.userService.trackSegmentEvent(eventName, this.getPropsOnFilterAppliedOrRemoved(project, key, value));
          }
        }, err => {
          this.errorService.errorFunction(err);
          this.projectService.startApplyFilterLoader = false;
          this.projectService.meFilterLoader = false;
        });
      }
    }
  }

  getPropsOnFilterAppliedOrRemoved(project, key, value) {
    if (key && value && project) {
      const props = this.projectService.getCommonPropsForStoryBoardEvents(project);
      switch (key) {
        case 'phase':
          props['filter_type'] = 'Phase';
          props['filter_name'] = value;
          break;
        case 'story_type':
          props['filter_type'] = 'Story Type';
          props['filter_name'] = value;
          break;
        case 'label':
          props['filter_type'] = 'Label';
          props['filter_name'] = value;
          break;
        case 'feature':
          props['filter_type'] = 'Feature';
          props['filter_name'] = this.getFeatureNameById(project, value);
          break;
        case 'member':
          props['filter_type'] = 'Member';
          props['filter_name'] = this.getMemberNameById(project, value);
          break;
      }
      return props;
    }
  }

  getFeatureNameById(project, id) {
    const ind = project.epics.findIndex(item => item.id === id);
    return ind !== -1 ? project.epics[ind].title : '';
  }

  getMemberNameById(project, id) {
    const ind = project.memberships.findIndex(item => item.user.id === id);
    if (ind !== -1) {
      return project.memberships[ind].user.name ? project.memberships[ind].user.name : project.memberships[ind].user.email;
    }
    return '';
  }

  getSprints(project, projectId) {
    this.clearSprintSubscription();
    this.sprintSubscription = this.projectService.getProjectSprints(projectId, {
      q: '',
      page: 1,
      filter: true,
      per_page: 200
    }).subscribe(res => {
      res.data.forEach((sprintData) => {
        this.sprintFilterList.push(new Sprint(sprintData));
      });
      if (JSON.parse(localStorage.getItem('currentSprintSelection'))) {
        this.sprintSelected = true
        this.currentSprintSelection(project)
      } else {
        this.sprintSelected = false
        this.currentSprintSelection(project)
      }
      this.sprintsMetaData = res.meta;
    }, err => this.errorService.errorFunction(err));
  }

  currentSprintSelection(project) {
    const meFilter = JSON.parse(localStorage.getItem('meFilter'));
    const currentFilter = JSON.parse(localStorage.getItem('currentSprintSelection'));
    this.sprintSelected = this.isFirst ? this.sprintSelected : !this.sprintSelected
    this.currentRunningSprintList = this.sprintFilterList.filter((sprint: any) => sprint.status === 'running')
    const currentListId = this.currentRunningSprintList.map((val: any) => val.id)
    if (meFilter && this.isFirst) {
      this.meFilter = meFilter
      this.applyFilter(project, null, 'member', this.userService.currentUser.id)
    } else if (this.isFirst && !meFilter && currentFilter) {
      this.projectService.startApplyFilterLoader = false
      this.applyFilter(project, { target: { checked: true } }, 'sprints', currentListId)
    }
    if (this.sprintSelected && !_.isEmpty(this.currentRunningSprintList) && !this.isFirst) {
      localStorage.setItem('currentSprintSelection', JSON.stringify(true))
      this.projectService.startApplyFilterLoader = false
      this.applyFilter(project, { target: { checked: true } }, 'sprints', currentListId)
    } else if (!this.sprintSelected && !_.isEmpty(this.currentRunningSprintList) && !this.isFirst) {
      localStorage.setItem('currentSprintSelection', JSON.stringify(false))
      this.applyFilter(project, { target: { checked: false } }, 'sprints', currentListId)
    }
    this.isFirst = false
  }

  clearSprintSubscription() {
    if (this.sprintSubscription) {
      this.sprintSubscription.unsubscribe();
    }
  }
}
