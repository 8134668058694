import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'linkify'})
export class LinkifyPipe implements PipeTransform {

  transform(str: string): string {
    return this.linkify(str);
  }

  private linkify(plainText): string {
    let replacedText;
    let replacePattern1;
    let replacePattern2;

    /*URLs starting with http://, https://, or ftp:*/
    replacePattern1 = /(\b(https?):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
    replacedText = plainText.replace(replacePattern1, '<a class="linkify-2" href="$1" target="_blank">$1</a>');

    /*URLs starting with "www." (without // before it, or it'd re-link the ones done above).*/
    replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
    replacedText = replacedText.replace(replacePattern2, '$1<a class="linkify-2" href="http://$2" target="_blank">$2</a>');

    return replacedText;
  }
}
