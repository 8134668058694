import { ViewChild, Component, OnInit, ElementRef } from '@angular/core';
import {
  Router,
  ActivatedRoute,
  Params
} from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import moment from 'moment';

import {
  PROJECT_ROLES,
  EVENT_TYPES,
  APPROVE_HOUR_REASONS, MEMBER_DASHBOARD_FILTER_DATA
} from '../shared/dataTypes';

import { ProjectService } from '../shared/services/project.service';
import { PolicyService } from '../shared/services/policy.service';
import { NotificationService } from '../shared/services/notification.service';
import { UserService } from '../shared/services/user.service';
import { ErrorService } from '../shared/errorFunction';
import { CustomToastService } from '../shared/services/custom-toast.service';
import { SharedService } from '../shared/services/shared.service';
import {Subscription} from 'rxjs';
import {AdminProjectsService} from '../admin/projects';
import { Project } from '../shared/models/project';
import { User } from '../shared/models/user';
import { Partner } from '../shared/models/partner';
import { Membership } from '../shared/models/membership';
import { GUID } from '../shared/models/guid';
import {DomSanitizer} from '@angular/platform-browser';
import { trigger, transition, style, animate } from '@angular/animations';
import {Activity} from "../shared/models/activity";

@Component({
  selector: 'project-dashboard',
  templateUrl: 'projectDashboard.component.html',
  styleUrls: ['projectDashboard.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({
          opacity: '0',
          top: '50px'
        }),
        animate(100, style({
          opacity: '1',
          top: '33px'
        }))
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate(100, style({
          opacity: '0',
          top: '50px'
        }))
      ])
    ])
  ],
  host: {
    '(document:click)': 'onClick($event)',
  },
})
export class ProjectDashboardComponent implements OnInit {
  project: Project;
  projectId?: number;
  projectName:string = '';
  currentUser: User;
  roleList: Array<String> = Object.keys(PROJECT_ROLES);
  selectedRole: any;
  searchStr = '';
  searchUsers: Array<User> = [];
  openMembershipDeletePopup: boolean = false;
  openProjectDeletePopup: boolean = false;
  waitForLoader = false;
  delmembership: any;
  delProject: any;
  showHtml = false;
  projects: Array<Project> = [];
  searchProject = '';
  startLoader = false;
  projectSubscription: Subscription;
  usersSubscription: Subscription;
  searched_user_loader: boolean = false;
  isPending = false;
  params = {
    q: '',
    page: 1,
    timezone: '',
    min_search: true
  };
  meta: any;
  moreAvailable = true;
  selectedMembership: any;
  communication = {};
  responsiveness = {};
  delivery_ownership = {};
  request_reason = {};
  qtd = {};
  hours = {};
  minutes = {};
  secs = {};
  minsSecsError = {};
  // showRedIcon = {};
  partner: Partner;
  inputHours;
  isNewMemberInvited: boolean = false;
  project_invested_hours: string = '';
  raise_concern_allowed: boolean;
  raise_concern_till_this_date: string = '';

  @ViewChild('newMemberRoleSelect')
  private newMemberRoleSelect: NgSelectModule;
  @ViewChild('newMemberRoleInput')
  private newMemberRoleInput: any;

  showProjectList: boolean = false;
  openMemberCard: number;
  openMemberActivity: number;
  openRatingPopup: boolean = false;
  accessTypeOfattachment = ['png', 'jpg', 'jpeg', 'gif'];
  dashboardLoader: boolean = false;
  isRaiseRequest: Boolean = false;
  primaryMemberList:boolean = false;
  activityListLoader:boolean = false;
  cardListLoader:boolean = false;
  userActivities: Array<Activity> = [];
  userCardDetail: any;
  openIDEcard:boolean = false;
  openTabWithId:number;
  showCopiedID:boolean = false;
  resendLoader:boolean = false;
  showCopiedEmail:boolean = false;
  approveReasons: Array<any> = APPROVE_HOUR_REASONS;
  show_reason:boolean = false;
  other_reason_option:boolean = false;
  selected_reason_option = '';
  showProjectStoriesSummary: boolean = false;
  collapse_expand_project_info:boolean = true;
  show_invoice_cycle:boolean = false;
  invoice_cycles = [];
  previous_invoice_active: boolean = false;
  current_invoice_cycle = '';
  current_cycle_tracked_hour: any;
  selected_invoice_cycle = '';
  invoice_cycle_loader:boolean = false;
  searchCycle = '';
  show_member_filter_options:boolean = false;
  
  memberFilterOptions: Array<any> = MEMBER_DASHBOARD_FILTER_DATA;
  filtersCount = 0;
  all_memberships = [];
  memberFilters = {};
  emailIdsArray = [];

  constructor(
    private router: Router,
    public sharedService: SharedService,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private errorService: ErrorService,
    private projectService: ProjectService,
    private policyService: PolicyService,
    private customToast: CustomToastService,
    private notificationService: NotificationService,
    private projectsService: AdminProjectsService,
    private _eref: ElementRef,
    private domSanitizer: DomSanitizer
  ) {}

  searchUser() {
    this.getUserByQuery(this.searchStr);
  }

  ngOnInit() {
    this.dashboardLoader = true;
    this.policyService.initialPolicies();
    this.activatedRoute.params.subscribe((params: Params) => {
      this.clearData();
      this.projectId = +params['id'];
      localStorage.setItem('projectId', JSON.stringify(this.projectId));
      this.loadData();
    });

    this.notificationService.subscribeEvent(EVENT_TYPES.PROJECT.EDIT, data => {
      this.project.name = data.payload.data.project.name;
      this.projectName = this.project.name;
      this.notificationService.broadcast(EVENT_TYPES.PROJECT.SHOW, { data: { project: this.project } });
      const ind = this.projects.findIndex(pro => pro.id === data.payload.data.project.id);
      if (ind !== -1) {
        this.projects[ind].name = this.projectName;
      }
    });

    this.notificationService.subscribeEvent(EVENT_TYPES.PROJECT.NEW_MEMBER_ADDED, () => {
      this.getDashboardData();
    });
  }

  openProjectStoriesSummary() {
    this.showProjectStoriesSummary = !this.showProjectStoriesSummary;
  }

  clearData() {
    this.showHtml = false;
    this.project = undefined;
    this.projects = [];
    this.searchProject = '';
    this.projectName = '';
    this.params = {
      q: '',
      page: 1,
      timezone: '',
      min_search: true
    };
  }
  
   removeColon(time){
      time = time.replace(":", "");
      return parseInt(time);
  }
   
  convertToSeconds(time){
    let splitTime = time.split(':');
    let seconds = splitTime[0]*3600 + splitTime[1]*60;
    return seconds;
  }
   

  setWorkingHoursForMembers() {
    if (this.project.creator_tracked_time_data) {
      let start_date = moment(this.project.creator_tracked_time_data.start_date);
      let end_date = moment(this.project.creator_tracked_time_data.end_date);
      this.project.memberships.forEach(member => {
        if (member.invoice_activity && member.invoice_activity.invoice_activity) {
          let activity = member.invoice_activity.invoice_activity;
          if(activity.start_date && activity.end_date) {
            let member_start_date = new Date(activity.start_date);
            let member_end_date = new Date(activity.end_date);
            let workingHours = 0;
            if (member.developer_working_schedules) {
              while(member_start_date <= member_end_date) {
                let day = this.getDay(member_start_date);
                const index = member.developer_working_schedules.findIndex(schedule => schedule.day === day)
                if (index !== -1) {
                  workingHours = workingHours +  (this.convertToSeconds(member.developer_working_schedules[index].end_time) - this.convertToSeconds(member.developer_working_schedules[index].start_time));
                 
                }
                member_start_date = new Date(member_start_date.setDate(member_start_date.getDate()+1));
              }  
            }
            if(workingHours > 0) {
              member['working_hour'] = Math.round(workingHours/3600);
            }
          }
        }
      });
    }
  }
  
  getDay(date) {
    const weekdays = new Array(7);
    weekdays[0] = "sunday";
    weekdays[1] = "monday";
    weekdays[2] = "tuesday";
    weekdays[3] = "wednesday";
    weekdays[4] = "thursday";
    weekdays[5] = "friday";
    weekdays[6] = "saturday";
    const day = weekdays[date.getDay()];
   return day;
}

  showPrimaryManagerList() {
    this.primaryMemberList = !this.primaryMemberList;
  }

  loadData() {
    this.currentUser = this.userService.getUser();
    this.partner = this.userService.partner;
    this.projectService.project(this.projectId, true, true).subscribe(project => {
      this.project = project;

      // Load invoice cycle information with help of Hive
      this.projectService.loadCreatorTrackedTime(this.projectId).subscribe(res => {
        if (res && res.creator) {
          this.current_cycle_tracked_hour = res.creator.current_cycle_data;
          this.project.creator_tracked_time_data = res.creator.current_cycle_data;
        }
      }, error => this.errorService.errorFunction(error));

      this.projectName = this.project.name;
      this.notificationService.broadcast(EVENT_TYPES.PROJECT.SHOW, {
        data: {
          project: this.project
        }
      });
      if (this.projectService.current_user_role === 'read_only' || this.projectService.current_user_role === 'user') {
        this.router.navigate(['/projects/' + this.project.id]);
        return;
      }
      this.searchProject = '';
      this.getProjects();
      this.getDashboardData();
    }, error => {
      this.errorService.errorFunction(error);
      this.dashboardLoader = false;
    });
  }

  previousInvoiceActivity(previous_invoice) {
    this.previous_invoice_active = (previous_invoice !== this.current_invoice_cycle) ? true : false;
    this.show_invoice_cycle = false;
    this.getDashboardData(previous_invoice);
  }

  openMemberFilter() {
    this.show_member_filter_options = !this.show_member_filter_options;
  }

  clearAllFilters(clearSkill = true) {
    this.memberFilters = {};
    this.filtersCount = 0;
    this.show_member_filter_options = false;
    
    const index = this.memberFilterOptions.findIndex(item => item.name === 'Skill');
    if (clearSkill) {
      this.memberFilterOptions[index].options = [];
    } else {
      this.filterMembership();
    }
  }

  calculateFilterCount() {
    this.filtersCount = Object.keys(this.memberFilters).length;
  }

  filterMembership() {
    this.project.memberships = [];
    const filterKeys = Object.keys(this.memberFilters);
    this.project.memberships = this.all_memberships.filter(item => {
      return filterKeys.every(eachKey => {
        const term = this.memberFilters[eachKey];
        if (term && term.length === 0) {
          return true; 
        }
        
        let value = item[eachKey];
        if (eachKey === 'skill') {
          return term.includes(item.user.skill);
        } else if (eachKey === 'active') {
          return term.includes(value && !item.user.deleted_at);
        } else {
          return term.includes(value);
        }
      });
    });
  }

  // Based on select checkbox selection, create filters data
  changeOptionState(event, term, value) {
    if (event.target.checked) {
      if (!this.memberFilters[term]) {
        this.memberFilters[term] = [];
      }

      if (this.memberFilters[term].indexOf(value) === -1) {
        this.memberFilters[term].push(value);
      }
    } else {
      const index = this.memberFilters[term].indexOf(value);
      if (index !== -1) {
        this.memberFilters[term].splice(index, 1);
      }

      if (this.memberFilters[term].length === 0) {
        delete this.memberFilters[term];
      }
    }
    this.calculateFilterCount();
    this.filterMembership();
  }

  isOptionChecked(term, value) {
    return this.memberFilters[term] && this.memberFilters[term].indexOf(value) !== -1;
  }

  getDashboardData(previous_invoice?) {
    this.clearAllFilters();
    this.invoice_cycle_loader = true;
    if (this.project && this.project.dashboard_project_id) {
      this.projectService.getProjectDetails(this.project).subscribe(res=>{
        this.project.squad_name = res.data.projectdetail.squad.name;
      }); 
    }
    this.projectService.projectDashboard(this.projectId, previous_invoice).subscribe(res => {
      this.project.memberships = [];
      this.project_invested_hours = res.project_invested_hours;
      this.raise_concern_allowed = res.raise_concern_allowed;
      this.raise_concern_till_this_date = res.raise_concern_till_this_date;
      this.invoice_cycles = res.invoice_cycles;
      this.current_invoice_cycle = res.invoice_cycle;
      this.selected_invoice_cycle = previous_invoice ? previous_invoice : this.current_invoice_cycle;

      res.memberships.forEach(item => {
        // temp data for total time
        // item.membership.invoice_activity.invoice_activity.total_elapsed_time = 3600;
        // item.membership.invoice_activity.invoice_activity.ide_elapsed_time = 3600;
        if (previous_invoice) {
          this.project.creator_tracked_time_data = {
            end_date: item.membership.invoice_activity.invoice_activity.end_date,
            start_date: item.membership.invoice_activity.invoice_activity.start_date
          };
        } else {
          this.project.creator_tracked_time_data = this.current_cycle_tracked_hour;
        }
      
        if (item.membership && item.membership.user.skill) {
          const index = this.memberFilterOptions.findIndex(item => item.name === 'Skill');
          const options = this.memberFilterOptions[index].options;
          if (item.membership.user.skill && options.findIndex(i => i.title === item.membership.user.skill) === -1) {
            this.memberFilterOptions[index].options.push({
              title: item.membership.user.skill, 
              value: item.membership.user.skill,
              isChecked: false,
              key: 'skill'
            })
          }
        }
        item.membership.open_more_options = false;
        item.membership.approver_email_copy = false;
        item.membership.last_time_active = item.membership.last_time_active ? this.sharedService.formattTime(item.membership.last_time_active): null;
        this.project.addMember(item.membership);
        const invoice_activity = item.membership.invoice_activity ? item.membership.invoice_activity.invoice_activity : undefined;
        if (invoice_activity && invoice_activity.ratings) {
          this.communication[invoice_activity.user_email] = invoice_activity.ratings.communication;
          this.responsiveness[invoice_activity.user_email] = invoice_activity.ratings.responsiveness;
          this.delivery_ownership[invoice_activity.user_email] = invoice_activity.ratings.delivery_ownership;
          this.request_reason[invoice_activity.id] = '';
        } else if (invoice_activity) {
          this.communication[invoice_activity.user_email] = -1;
          this.responsiveness[invoice_activity.user_email] = -1;
          this.delivery_ownership[invoice_activity.user_email] = -1;
          this.request_reason[invoice_activity.id] = '';
        }
      });
      this.all_memberships = this.project.memberships;
      if (this.isNewMemberInvited) {
        this.searchUser();
        this.isNewMemberInvited = false;
      }
      this.showHtml = true;
      this.dashboardLoader = false;
      this.invoice_cycle_loader = false;
      this.project.memberships.forEach(membership => this.emailIdsArray.push(membership.user.email))
      this.projectService.getWorkingHours(this.emailIdsArray).subscribe(res =>{
        if (res) {
            this.project.memberships.forEach(membership => {
            const index = res.data.findIndex(user => membership.user.email === user.email)
            if (index !== -1) {
              membership.developer_working_schedules = res.data[index].developer_working_schedules;
            }
          })
        }
        this.setWorkingHoursForMembers();
      });
    }, error => {
      this.invoice_cycle_loader = false;
      this.errorService.errorFunction(error);
      this.dashboardLoader = false;
    });
  }

  getOlderProject() {
    this.startLoader = true;
    if (this.meta.total_count == this.projects.length) {
      this.moreAvailable = false;
    }
    if (this.moreAvailable) {
      this.params.page++;
      this.getProjects();
    }
  }

  isScrollFetchAvailable(): boolean {
    return !this.isPending && this.moreAvailable;
  }

  getProjects(search?) {
    this.params.q = this.searchProject;
    this.params.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.isPending = true;
    if (this.projectSubscription) {
      this.projectSubscription.unsubscribe();
    }
    this.projectSubscription = this.projectService.getProjects(this.params).
    subscribe(res => {
      this.isPending = false;
      this.meta = res.meta;
      if (search) {
        this.projects = [];
      }
      res.projects.forEach(data => this.projects.push(data.project));
      this.startLoader = false;
    },
  (error => {
      this.errorService.errorFunction(error);
  }));
  }

  getSearchProjects() {
    this.params.page = 1;
    this.getProjects(true);
  }

  openSelectRoleDD(membership) {
    if (this.project) {
      for (let i = 0; i < this.project.memberships.length; i++) {
        if (this.project.memberships[i] != membership) {
          this.project.memberships[i].openSelectDD = false;
        }
      }
    }
    membership.openSelectDD = !membership.openSelectDD;
  }

  updateRole(role, membership) {
    const lowercaseRole = role.charAt(0).toLowerCase() + role.slice(1);
    this.selectedRole = {id: lowercaseRole, text: lowercaseRole};

    if (!this.selectedRole) {
      return;
    }
    this.projectService
      .changeUserRole(membership.user_id, this.project, this.selectedRole.id)
      .subscribe(res => {
          this.customToast.messages.push({
            id: 'role_updated',
            type: 'success',
            class: 'new_member_added',
            title: 'Role Updated',
            message: 'Role updated successfully'
          });
          if (this.currentUser.id === membership.user_id && this.selectedRole.id === 'user' && this.policyService.isProjectAdmin(this.project)) {
            this.policyService.initialPolicies();
          }
          this.selectedRole = null;
          membership.openSelectDD = false;
        },
        (error) => {
          let errorIndex = 0;
          this.project.memberships.forEach((member, index) => {
            if (member.id == membership.id) {
              errorIndex = index;
            }
          });

          if (membership.role == 'admin') {
            this.project.memberships[errorIndex].role = 'admin';
            this.selectedRole = {id: 'admin', text: 'admin'};
          } else {
            this.project.memberships[errorIndex].role = 'user';
            this.selectedRole = {id: 'user', text: 'user'};
          }
          membership.openSelectDD = false;
          this.errorService.errorFunction(error);
        });
  }

  inviteNewMember(user) {
    this.projectService
      .addUserToProject(user, this.project, Membership.defaultRole)
      .subscribe(() => {
        const userMsg: string = user.name || user.email;
        this.customToast.messages.push({
          id: 'memberInvited',
          type: 'success',
          class: 'membership_added',
          title: 'Member added',
          message: 'New Member has been added to the project.'
        });
        this.getDashboardData();
        this.isNewMemberInvited = true;
      }, (error) => this.errorService.errorFunction(error));
  }

  resendInvite(membership) {
    this.projectService
      .resendInviteToUser(membership.user_id, this.project)
      .subscribe(res => {
        this.customToast.messages.push({
          id: 'memberReInvited',
          type: 'success',
          class: 'membership_added',
          title: 'Member Reinvited',
          message: res.message
        });
      }, (error) => this.errorService.errorFunction(error));
  }

  closeMembershipDeletePopUp() {
    this.openMembershipDeletePopup = false;
  }

  removeMembership(membership) {
    this.delmembership = membership;
    this.openMembershipDeletePopup = true;
  }

  deleteMembership(membership: Membership) {
    this.waitForLoader = true;
    this.projectService
      .removeUserFromProject(membership.user_id, this.project)
      .subscribe(res => {
        this.waitForLoader = false;
        this.closeMembershipDeletePopUp();
        this.searchUser();
        const userMsg: String = membership.user.name_or_email;
        this.customToast.messages.push({
          id: 'membershipRemoved',
          type: 'success',
          class: 'membership_removed',
          title: 'Member Removed',
          message: userMsg + ' is removed from this project.'
        });
      }, (error) => {
        this.waitForLoader = false;
        this.closeMembershipDeletePopUp();
        this.errorService.errorFunction(error);
      });
  }

  closeProjectDeletePopUp() {
    this.openProjectDeletePopup = false;
  }

  removeProject(project) {
    this.delProject = project;
    this.openProjectDeletePopup = true;
  }

  deleteProject() {
    this.waitForLoader = true;
    this.projectsService.destroyProject(this.projectId).subscribe(() => {
      GUID.instance.unsetProjectById(this.projectId);
      this.closeProjectDeletePopUp();
      this.waitForLoader = false;
      this.notificationService.broadcast(EVENT_TYPES.SESSION.LOGGED_IN_NEW, {});
      this.router.navigate(['/']);
      this.customToast.messages.push({
        id: 'project_deleted',
        type: 'success',
        class: 'user_delete_info',
        title: 'Project Deleted',
        message: this.projectName + ' Project is deleted successfully'
      });
    }, error => {
      this.errorService.errorFunction(error);
      this.closeProjectDeletePopUp();
      this.waitForLoader = false;
    });
  }

  getUserByQuery(query: any) {
    this.searched_user_loader = true;
    if (this.project) {
      this.searchUsers = [];
      if (this.usersSubscription) {
        this.usersSubscription.unsubscribe();
      }
      this.usersSubscription = this.userService.queryUser(this.project, query).subscribe(res => {
        if(res && this.searchStr) {
          res.forEach(item => {
            const ind = this.project.memberships.findIndex(member => member.user_id === item.user.id);
            if (ind === -1) {
              this.searchUsers.push(item.user);
            }
          });
        }
        this.searched_user_loader = false;
      });
    } else {
      return null;
    }
  }

  get canCreate(): boolean {
    return (
      this.project &&
      this.policyService.policiesActive &&
      this.policyService.canCreateMembership(this.project)
    );
  }

  get canUpdate(): boolean {
    return (
      this.project &&
      this.policyService.policiesActive &&
      this.policyService.canUpdateMembership(this.project)
    );
  }

  get canRemove(): boolean {
    return (
      this.project &&
      this.policyService.policiesActive &&
      this.policyService.canDeleteMembership(this.project)
    );
  }

  onClick(event) {
    const element = event.target;
    if (!(element.classList.contains('dd-arrow') || element.classList.contains('selectedRole'))) {
      this.closeSelectRoleDD();
    }
    if (!(element.classList.contains('aboutProject-title') || element.classList.contains('iconb-dd-down')
          || element.classList.contains('makesearch-project'))) {
      this.closeProjectList();
    }
    if (!(element.classList.contains('userInitials-block') || element.classList.contains('usersInitials'))) {
      this.primaryMemberList = false;
    }
    if (!(element.classList.contains('stories_summary_title') || element.classList.contains('stories_summary_arrow'))) {
      this.showProjectStoriesSummary = false;
    }
    if (!(element.classList.contains('search-cycle-searchInput') || 
    element.classList.contains('invoice-list') || 
    element.classList.contains('invoiceSelection') || 
    element.classList.contains('iconCal') ||
    element.classList.contains('invoiceCycle-wrapper')
    )) {
      this.show_invoice_cycle = false;
      this.searchCycle = '';
    }
    if (!(element.classList.contains('filter_selection') || 
    element.classList.contains('filter-icon') || 
    element.classList.contains('membership_filter_list') || 
    element.classList.contains('member_filter_option_list') || 
    element.classList.contains('selected-checkbox') || 
    element.classList.contains('check-toggle') ||
    element.classList.contains('customCheck_default')
    )) {
      this.show_member_filter_options = false;
    }
   }

  closeSelectRoleDD() {
    if (this.project) {
      for (let i = 0; i < this.project.memberships.length; i++) {
        this.project.memberships[i].openSelectDD = false;
      }
    }
  }

  openProjectList() {
    this.showProjectList = !this.showProjectList;
  }

  showMemberIDE(membershipD) {
    this.openTabWithId = membershipD.id;
    this.openMemberCard = null;
    this.openMemberActivity = null;
  }

  showMemberCard(userdetailId) {
    this.cardListLoader = true;
    this.openMemberCard = userdetailId;
    this.openMemberActivity = null;
    this.openTabWithId = null;
    this.projectService.show_user_card(this.projectId, userdetailId).subscribe(res => {
      this.userCardDetail = res;
      this.cardListLoader = false;
    }, error => {
      this.cardListLoader = false;
      this.errorService.errorFunction(error);
    });

  }

  showMemberActivity(userdetailId) {
    this.activityListLoader = true;
    this.userActivities = [];
    this.openMemberCard = null;
    this.openTabWithId = null;
    this.openMemberActivity = userdetailId;
    this.projectService.show_user_activity(this.projectId, userdetailId).subscribe(res => {
      res.forEach(act => {
        this.userActivities.push(new Activity(act));
      });
      this.activityListLoader = false;
    }, error => {
      this.activityListLoader = false;
      this.errorService.errorFunction(error);
    });
  }

  copyStoryID() {
    this.showCopiedID = true;
    setTimeout(() => {
      this.showCopiedID = false;
    }, 10000);
  }
  copyUserEmail() {
    this.showCopiedEmail = true;
    // setTimeout(() => {
    //   this.showCopiedEmail = false;
    // }, 1000);
  }
  copyApproverEmail(membership) {
    membership.approver_email_copy = true;
    setTimeout(() => {
      membership.approver_email_copy = false;
    }, 1500);
  }

  resenIDEinvite(membershipId) {
    this.resendLoader = true;
    this.projectService.resendIDEinvite(this.projectId, membershipId).subscribe(res => {
      this.customToast.messages.push({
        id: 'ide_invite_resend',
        type: 'success',
        class: 'membership_added',
        title: 'Buildcard IDE',
        message: 'Buildcard IDE invite resend successfully'
      });
      this.resendLoader = false;
    }, error => {
      this.resendLoader = false;
      this.errorService.errorFunction(error);
    });
  }

  closeProjectList() {
    this.showProjectList = false;
  }

  openPopupRating(membership, type) {
    if (type === 'approve') {
      this.isRaiseRequest = false;
    } else if (type === 'raise_request') {
      this.isRaiseRequest = true;
    }
    this.openRatingPopup = true;
    this.selectedMembership = membership;
  }

  closePopupRating(membership) {
    this.openRatingPopup = false;
    this.show_reason = false;
    this.request_reason[membership.invoice_activity.invoice_activity.id] = '';
    this.selected_reason_option = '';
  }

  toggleViewmode(activity) {
    activity.toggleHistory = !activity.toggleHistory;
  }

  checkType(doc) {
    if (doc && doc.length > 0) {
      const lastIndexofDot = doc.lastIndexOf('.');
      const type = doc.substr(lastIndexofDot + 1);
      if (this.accessTypeOfattachment.indexOf(type.toLowerCase()) >= 0) {
        return true;
      }
      return false;
    }
  }

  // Convert seconds to time duration
  secsToDuration(secs: number) {
    const duration = moment.duration(secs, 'seconds');
    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    const pad = (number) => { return number < 10 ? '0' + number : number };
    return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
  }

  updateRating(type, value) {
    if (this.selectedMembership) {
      if (type === 'delivery_ownership') {
        this.delivery_ownership[this.selectedMembership.invoice_activity.invoice_activity.user_email] = value;
      } else if (type === 'responsiveness') {
        this.responsiveness[this.selectedMembership.invoice_activity.invoice_activity.user_email] = value;
      } else if (type === 'communication') {
        this.communication[this.selectedMembership.invoice_activity.invoice_activity.user_email] = value;
      }
    }
  }

  checkRating(activity) {
    if (activity) {
      if (this.delivery_ownership[activity.user_email] !== -1 && this.responsiveness[activity.user_email] !== -1
        && this.communication[activity.user_email] !== -1) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  }

  approveApprovedHours(membership) {
    const data = {
      approved_time: this.qtd[membership.invoice_activity.invoice_activity.id],
      ratings: {
        delivery_ownership: this.delivery_ownership[membership.invoice_activity.invoice_activity.user_email],
        communication: this.communication[membership.invoice_activity.invoice_activity.user_email],
        responsiveness: this.responsiveness[membership.invoice_activity.invoice_activity.user_email]
      }
    };
    if (this.isRaiseRequest || (!this.isRaiseRequest && this.checkInputTime(membership))) {
      data['cpe_reason'] = this.request_reason[membership.invoice_activity.invoice_activity.id];
    } 
    data['raise_approve_request'] = this.isRaiseRequest;
    this.projectService.updateApprovedHours(this.projectId, membership.invoice_activity.invoice_activity.id, data).subscribe((response) => {
        membership.invoice_activity.invoice_activity = response.invoice_activity;
        this.closePopupRating(membership);
        this.selectedMembership = undefined;
        this.customToast.messages.push({
          id: 'hoursApproved',
          type: 'success',
          class: 'hours_approved',
          title: response.title,
          message: response.message
        });
        this.isRaiseRequest = false;
      },
      (error) => {
        this.errorService.errorFunction(error);
        this.qtd[membership.invoice_activity.invoice_activity.id] = null;
        this.hours[membership.invoice_activity.invoice_activity.id] = null;
        this.minutes[membership.invoice_activity.invoice_activity.id] = null;
        this.secs[membership.invoice_activity.invoice_activity.id] = null;
        this.closePopupRating(membership);
        this.selectedMembership = undefined;
      });
  }

  changeApprovedHours(invoiceActivity) {
    if ((this.hours[invoiceActivity.id] == null) && (this.minutes[invoiceActivity.id] == null) && (this.secs[invoiceActivity.id] == null)) {
      this.inputHours = null;
      this.hours[invoiceActivity.id] = null;
      this.minutes[invoiceActivity.id] = null;
      this.secs[invoiceActivity.id] = null;
      this.qtd[invoiceActivity.id] = null;
    } else {
      let totalTime = 0;
      if (this.hours[invoiceActivity.id] != null) {
        totalTime = this.hours[invoiceActivity.id] * 3600;
      }
      if (this.minutes[invoiceActivity.id] != null) {
        if (this.minutes[invoiceActivity.id] > 59) {
          this.minsSecsError[invoiceActivity.id] = 'Minutes can not be greater than 59';
        } else {
          delete this.minsSecsError[invoiceActivity.id];
        }
        totalTime = totalTime + this.minutes[invoiceActivity.id] * 60;
      }
      if (this.secs[invoiceActivity.id] != null) {
        if (this.secs[invoiceActivity.id] > 59) {
          this.minsSecsError[invoiceActivity.id] = 'Seconds can not be greater than 59';
        } else {
          delete this.minsSecsError[invoiceActivity.id];
        }
        totalTime = totalTime + this.secs[invoiceActivity.id];
      }
      this.inputHours = Number(totalTime);
      this.qtd[invoiceActivity.id] = this.inputHours;
    }
  }

  convertToNumber(value) {
    return Number(value);
  }

  clickAddMember() {
    this.sharedService.showUserForm = true;
    this.projectService.isNewMemberAdded = true;
  }

  bypassSanitization(html: string) {
    return this.domSanitizer.bypassSecurityTrustHtml(html);
  }

  showApproveButton(invoiceActivity) {
    if (invoiceActivity != null) {
      return this.qtd[invoiceActivity.id] == null || (this.qtd[invoiceActivity.id] != null &&
                                (this.qtd[invoiceActivity.id] <= this.approvalLimit(invoiceActivity.total_elapsed_time, invoiceActivity.variance)));
    }
    return false;
  }

  showRaiseRequestButton(invoiceActivity) {
    if (invoiceActivity != null) {
      return this.qtd[invoiceActivity.id] != null &&
          (this.qtd[invoiceActivity.id] > this.approvalLimit(invoiceActivity.total_elapsed_time, invoiceActivity.variance));
    }
    return false;
  }

  approvalLimit(total_elapsed_time, variance) {
    const max_limit = Math.floor(((variance/100) * this.convertToNumber(total_elapsed_time)) + this.convertToNumber(total_elapsed_time));
    return max_limit;
  }

  taskhours(totalHour, bugHour) {
    const calcTaskHours = totalHour - bugHour;
    return this.secsToDuration(calcTaskHours);
  }

  checkInputTime(selectedMembership) {
    const total_elapsed_time = selectedMembership.invoice_activity.invoice_activity.total_elapsed_time;
    const variance = selectedMembership.invoice_activity.invoice_activity.variance;
    return ((this.inputHours <= this.approvalLimit(total_elapsed_time, variance)) && (this.inputHours > total_elapsed_time) ); 
  }
  
  showReasons() {
    this.show_reason = !this.show_reason;
  }
  approvalReason(membership, approveReason) {
    this.selected_reason_option = approveReason;
    if (approveReason === 'Other(s)') {
      this.request_reason[membership.invoice_activity.invoice_activity.id] = '';
      this.show_reason = false;
      this.other_reason_option = true;
    } else {
      this.other_reason_option = false;
      this.request_reason[membership.invoice_activity.invoice_activity.id] = approveReason;
      this.show_reason = false;
    }
  }

  checkMemberPodRole(member){
    const member_data = {
      'CPE': 'Productologist',
      'SCPE': 'Sr. Productologist',
      'SCPM': 'Sr. Ninja',
      'CPM': 'Ninja',
      'SCTE': 'Sr Sensei',
      'CTE': 'Sensei'
    }
    return (member_data[member.pod_role] || member.pod_role);
  }

  showMoreOptions(membership) {
    membership.open_more_options = !membership.open_more_options;
    if(this.openMemberCard !== membership.id && this.openMemberActivity !== membership.id){
      this.showMemberIDE(membership);
    }
  }
  shrinkProjectInfo() {
    this.collapse_expand_project_info = !this.collapse_expand_project_info;
  }

  openInvoiceCycles() {
    this.show_invoice_cycle = !this.show_invoice_cycle;
  }
}
