import { Component } from '@angular/core';
import { AccountService } from '../../../shared/services/account.service';
import { ErrorService } from '../../../shared/errorFunction';
import { API_ROUTES } from '../../../shared/apiRoutes';
import { clone, isEqual, uniq, each } from 'lodash';
import { CustomToastService } from '../../../shared/services/custom-toast.service';
import { CopiedComponent } from '../../../project';

@Component({
    selector: 'app-account-view',
    templateUrl: 'view.component.html',
    styleUrls: ['view.component.scss']
})
export class AccountViewComponent {
  public account;
  private providers = ['slack'];
  copied: any;
  openChangePassword:boolean = false;
  form = {password: '', password_confirmation: '', id: ''};
  private errors: any;
  showLoginPassword = false;

  constructor(private accountService: AccountService,
              private customToast: CustomToastService,
              private errorService: ErrorService) {
                this.copied = CopiedComponent;
    this.getAccount();
  }

  getAccount() {
    this.accountService.get().subscribe(account => {
      this.account =this.parseResponse(account).account;
    },
    (error) => {
      this.errorService.errorFunction(error);
    });
  }

  openChangePasswordPopup() {
    this.openChangePassword = true;
  }
  closeChangePasswordPopup() {
    this.errors = null;
    this.form = {password: '', password_confirmation: '', id: ''};
    this.openChangePassword = false;
  }

  onPasswordChange(event) {
    let fieldName: string = event.srcElement.name;
    if (this.form[fieldName] &&
        this.form[fieldName].length == 0) {
      delete this.form[fieldName];
    }
  }


  onSubmit() {
    this.form.id = this.account.id;
    this
      .accountService
      .update(this.form)
      .subscribe(this.onSuccess.bind(this), this.onError.bind(this));
  }

  onSuccess(response) {
    this.closeChangePasswordPopup();
    this.customToast.messages.push({id:"passwordupdated", type:"success", class: "password-update-successful",  title: 'Password Status', message: 'Your password updated succesfully.'});
  }

  showPassword($event) {
    this.showLoginPassword = !this.showLoginPassword;
    $event.preventDefault();
    $event.stopPropagation();
  }


  onError(response) {
    this.errors = {};
    each(response, (value, key) => {
      this.errors[key] = uniq(value);
    });
    // this.isPending = false;
  }

  hasError(field) {
    return this.errors && this.errors.hasOwnProperty(field);
  }

  toIcon(value: boolean): string {
    return value ? 'checkIcon' : 'uncheckedIcon';
  }

  removeErrors() {
    this.errors = {}
  }

  public parseResponse(res: any): any {
    return res;
  }
}
