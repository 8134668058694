import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-story-progress',
  templateUrl: './story-progress.component.html',
  styleUrls: ['./story-progress.component.css']
})
export class StoryProgressComponent implements OnInit {

  @Input() storyProgress;
  @Input() story;
  @Output() progressEmit = new EventEmitter();

  constructor(public sharedService: SharedService) { }

  ngOnInit() {
    // This is intentional
  }

  progressStrip(progress, i) {
    this.storyProgress.completedProgress = progress;
  }

  checkIndex(index) {
    return ((this.storyProgress.completedProgress/10) >= index);
  }

  SaveProgress() {
    this.progressEmit.emit(this.storyProgress)
  }

}
