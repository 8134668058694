import { GUID } from './guid';
import { BaseModel } from './baseModel';
import { StoryReviewData, storyApprovalFilter } from '../dataTypes';
export class StoryReview extends BaseModel implements StoryReviewData {
  catalog_story_id?: number;
  created_at?: Date;
  epic: {builder_feature_id?: number, title?: string};
  id?: number;
  project_id?: number;
  estimate?: number;
  request_type?: string;
  requester?: {id?: number, name?: string, initials?: string, email?: string};
  reviewer?: {id?: number, name?: string, initials?: string, email?: string};
  status?: string;
  tech_stack?: string;
  title?: string;
  updated_at?: Date;
  reviewed_on?: Date;
  designer_status?: string

  constructor(data: StoryReviewData) {
    super(data);
    this.catalog_story_id = data.catalog_story_id;
    this.created_at = data.created_at;
    this.epic = data.epic ? data.epic : null;
    this.id = data.id;
    this.project_id = data.project_id;
    this.request_type = data.request_type;
    this.requester = data.requester ? data.requester : null;
    this.reviewer = data.reviewer ? data.reviewer : null;
    this.status = data.status;
    this.tech_stack = data.tech_stack;
    this.title = data.title;
    this.updated_at = data.updated_at;
    this.reviewed_on = data.reviewed_on;
    this.designer_status = data.designer_status
  }

  updateList(changes) {
    this.status = changes.status;
    this.reviewed_on = changes.reviewed_on;
    this.reviewer = changes.reviewer;
    this.updated_at = changes.updated_at;
  }

  getClassName() {
    return "StoryReview";
  }
} 