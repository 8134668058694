import { Pipe, PipeTransform } from '@angular/core';
import { orderBy } from 'lodash';
@Pipe({
  name: 'subTaskListSort',
  pure: false
})

export class SubTaskListSort implements PipeTransform {

  transform(items: any[], sortText: string, type?: string): any[] {
    if (!items) return [];
    if (!sortText) return items;

    if (sortText === 'title') {
      return items.sort((a: any, b: any) => {
        if (a[type] < b[type]) {
          return -1;
        }
        if (a[type] > b[type]) {
          return 1;
        }
        return 0;
      });
    } else if (sortText === 'created_at') {
      return items.sort((a: any, b: any) => {
        if (a.created_at > b.created_at) {
          return -1;
        }
        if (a.created_at < b.created_at) {
          return 1;
        }
        return 0;
      });
    } else if (sortText === 'updated_at') {
      return items.sort((a: any, b: any) => {
        if (a.updated_at > b.updated_at) {
          return -1;
        }
        if (a.updated_at < b.updated_at) {
          return 1;
        }
        return 0;
      });
    } else if (sortText === 'state') {
      const unscheduled_filter = items.filter(s => s.state === 'unscheduled');
      const unstarted_filter = items.filter(s => s.state === 'unstarted');
      const started_filter = items.filter(s => s.state === 'started');
      const finished_filter = items.filter(s => s.state === 'finished');
      const delivered_filter = items.filter(s => s.state === 'delivered');
      const accepted_filter = items.filter(s => s.state === 'accepted');
      const rejected_filter = items.filter(s => s.state === 'rejected');
      return items = [...unscheduled_filter, ...unstarted_filter, ...started_filter, ...finished_filter, ...delivered_filter, ...accepted_filter, ...rejected_filter];
    }
  }
}
