import { Component, Input, OnInit } from '@angular/core';
import { AccountService } from '../../../shared/services/account.service';
import { API_ROUTES } from '../../../shared/apiRoutes';
import { ErrorService } from '../../../shared/errorFunction';

@Component({
    selector: 'app-account-integration',
    templateUrl: 'integration.component.html',
    styleUrls: ['integration.component.scss']
})
export class AccountIntegrationComponent implements OnInit {

  private isPending = false;
  private integraionLink: string;
  private iconPath: string;

  @Input() accountId: number;
  @Input() state: boolean;
  @Input() provider: string;

  constructor (private accountService: AccountService,
  private errorService: ErrorService) {
  }

  ngOnInit(): void {
    this.integraionLink = API_ROUTES.OMNIAUTH(this.provider, this.accountId, window.location.href);
    this.iconPath = `assets/images/integrations/${this.provider}.png`;
  }

  onChange(event): void {
    this.isPending = true;
    event ? this.enable() : this.disable();
  }

  enable(): void {
    window.location.href = this.integraionLink;
  }

  disable(): void {
    this.isPending = true;
    const fieldName = `${this.provider}_notifications`;
    this.accountService
      .update({ fieldName: false })
      .subscribe(date => {
        this.state = this.parseResponse(date).account[fieldName];
        this.isPending = false;
      },
        (error) => {
          this.errorService.errorFunction(error);
        });
  }

  public parseResponse(res: any): any {
    return res;
  }
}
