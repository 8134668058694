import moment from 'moment';

export class Session {
  id: number;
  name: string;
  description: string;
  elapsed: number;
  buffer: number;
  status: string;

  constructor(data) {
    this.id = data.id;
    this.name = `Session ${this.id}`;
    this.description = data.description;
    this.elapsed = data.elapsed;
    this.buffer = this.elapsed;
    this.status = data.status;
  }

  get formatedTime(): string {
    const h = this.pad(Math.floor(this.elapsed / 3600));
    const m = this.pad(Math.floor(this.elapsed / 60 % 60));
    const s = this.pad(Math.floor(this.elapsed % 60));
    return `${h}:${m}:${s}`;
  }

  set formatedTime(value) {
    const time = this.fillTime(value);
    this.buffer = moment.duration(time).asSeconds();
  }

  public isActive(): boolean {
    return this.status === 'active';
  }

  public updateElapsed(): void {
    this.elapsed = this.buffer;
  }

  public asJson(): Object {
    return { id: this.id, elapsed: this.elapsed };
  }

  public fillTime(value: number | string): string {
    const n = value.toString().match(/\d/g) || [];
    n.length = 6;
    for (let i = 0; i < n.length; i++) {
      n[i] = n[i] || '0';
    }
    return `${n[0]}${n[1]}:${n[2]}${n[3]}:${n[4]}${n[5]}`;
  }

  private pad(value): string {
    return (value < 10) ? '0' + value : value;
  };
}
