
import { BaseModel } from './baseModel';
import {
  CriteriaData
} from '../dataTypes';

export class AcceptanceCriteria extends BaseModel implements CriteriaData {
  id?: number;
  story_id?: number;
  title?: string;
  cmt_acceptance_criteria_id?: number;
  created_at?: Date;
  updated_at?: Date;
  description_for_given?: string;
  description_for_then?: string;
  description_for_when?: string;
  criteria_loader?:boolean;
  reason_for_update?: string;
  old_title?: string;
  old_description_for_given?: string;
  old_description_for_then?: string;
  old_description_for_when?: string;
  criteria_description_edited?: boolean;
  confirm_delete?: boolean;

  constructor(data: CriteriaData) {
    super(data);
    this.id = data.id;
    this.story_id = data.story_id;
    this.title = data.title;
    this.cmt_acceptance_criteria_id = data.cmt_acceptance_criteria_id;
    this.created_at = data.created_at;
    this.updated_at = data.updated_at;
    this.description_for_given = data.description_for_given;
    this.description_for_then = data.description_for_then;
    this.description_for_when = data.description_for_when;
    this.criteria_loader = false;
    this.reason_for_update = data.reason_for_update;
    this.old_title = data.title;
    this.old_description_for_given = data.description_for_given;
    this.old_description_for_then = data.description_for_then;
    this.old_description_for_when = data.description_for_when;
    this.criteria_description_edited = false;
    this.confirm_delete = false;
  }
  
  getClassName() {
    return "AcceptanceCriteria";
  }
}