<div class="st-Loader adminTimingLoader" *ngIf="startLoader">
  <div class="in-Loader"><img src="../../assets/images/builder_black.png" alt="loader"/></div>
  <div class="loadingOverlay"></div>
</div>


<div [class.pending]="isPending">
  <div class="row">
    <div class="col-sm-12">
      <h3>Dashboard</h3>
    </div>
  </div>

  <div class="row">
    <div class="searhInputBox">
      <label>Project Name</label>
      <input type="text" class="searhInput" (input)="searchProject()" [(ngModel)]= "searchPro" placeholder="search">
    </div>
    <div class="cal-SelBox">
      <app-admin-timing-date-range-switcher
        [isPending]="false"
        [dateRange]="dateRange"
        (onDateRangeChanged)="onDateRangeChanged($event)"
        [isDashboard]="true">
      </app-admin-timing-date-range-switcher>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12">
      <hr>
    </div>
  </div>

  <div class="row pt5">
    <div class="col-sm-12">
      <div class="table-wrapper table-timing">
        <table role="presentation" class="table table-hover table-striped">
          <thead>
            <tr>
              <td>Project name</td>
              <td>BuilderTracker URL</td>
              <td>Total stories</td>
              <td>Productologist name</td>
              <td>Partner hours</td>
              <td>Productologist approval</td>
              <td>Total BT recorded time</td>
              <td>Total Productologist approved time</td>
              <td>Total Adjustment</td>
            </tr>
          </thead>
          <tbody>
            <tr *ngIf="!projects.length" class="nothing-yet">
              <td colspan="3"><em>There is no data yet</em></td>
            </tr>
            <tr *ngFor="let project of projects">
              <td>{{project.name}}</td>
              <td><a target="_blank" href="{{project.url}}" rel="noopener">{{project.url}}</a></td>
              <td>{{project.stories_count}}</td>
              <td>{{project.admin_names}}</td>
              <td>{{project.vendor_time}}</td>
              <td>{{project.cpe_approval}}</td>
              <td>{{secsToDuration(project.total_recorded_time)}}</td>
              <td>{{secsToDuration(project.total_approved_time)}}</td>
              <td>{{getTotalAdjustment(project.total_approved_time, project.total_recorded_time)}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div *ngIf="projects.length" class="row">
    <div class="col-sm-12">
      <app-admin-timing-pagination
        [isPending]="isPending"
        [currentPage]="currentProjectsPage"
        [totalPages]="totalProjectsPages"
        (onNewPageSelected)="onProjectsPageChanged($event)">
      </app-admin-timing-pagination>
    </div>
  </div>

  <div class="row pt5">
    <div class="col-sm-12">
      <div class="table-wrapper table-timing">
        <table role="presentation" class="table table-hover table-striped">
          <thead>
            <tr>
              <td>Name</td>
              <td>Position</td>
              <td>Total time</td>
            </tr>
          </thead>
          <tbody>
            <tr *ngIf="!users.length" class="nothing-yet">
              <td colspan="3"><em>There is no data yet</em></td>
            </tr>
            <tr *ngFor="let user of users" (click)="goToUser(user.id)">
              <td>{{user.name_or_email}}</td>
              <td>{{user.role}}</td>
              <td>{{user.totalTime || '00:00:00'}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div *ngIf="users.length" class="row">
    <div class="col-sm-12">
      <app-admin-timing-pagination
        [isPending]="isPending"
        [currentPage]="currentUsersPage"
        [totalPages]="totalUsersPages"
        (onNewPageSelected)="onUsersPageChanged($event)">
      </app-admin-timing-pagination>
    </div>
  </div>
</div>
