import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CustomToastService {
  messages = [];

  constructor() {
    // This is intentional
  }
}
