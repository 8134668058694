import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { SharedModule } from './../shared/shared.module';
import { ProjectImportComponent } from './projectImport.component';
import { FileUploadModule } from '../../lib';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    FileUploadModule
  ],
  declarations: [
    ProjectImportComponent
  ],
  exports: [
    ProjectImportComponent
  ]
})
export class ProjectImportModule { }
