import { Component, OnInit } from '@angular/core';
import { SignInData } from '../../shared/dataTypes';
import { UserService } from '../../shared/services/user.service';

@Component({
    selector: 'sign-in',
    templateUrl: 'sign-in.component.html'
})
export class SignInComponent {

    public signInData: SignInData = <SignInData>{};
    public output: any;

    constructor(private userService: UserService) {
        // This is intentional
     }

    // Submit Data to Backend
    onSubmit() {

        this.output = null;

        this.userService.login_signup(this.signInData, 'login').subscribe(
            res => {
                this.signInData    = <SignInData>{};
                this.output        = res;
            }, error => {
                this.signInData    = <SignInData>{};
                this.output        = error;
            }
        );
    }
}
