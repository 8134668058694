import * as _ from 'lodash';
import {
  DataManipulationOptions,
  EVENT_TYPES,
  ProjectData,
  STORY_STATE,
  IFilter,
  PROJECT_COLUMN_DATA,
  DevicePlatformData, StudioRole, PrimaryManagers, BugReasons
} from '../dataTypes';
import { Story } from './story';
import { User } from './user';
import { Membership } from './membership';
import { Epic } from './epic';
import { GUID } from './guid';
import { BaseModel } from './baseModel';
import {map} from 'lodash';
import moment from 'moment';

export class Project extends BaseModel implements ProjectData {
  graph_options?: any;
  chore_click?: boolean;
  bug_click?: boolean;
  supports_bb?: boolean;
  sideeffect_click?: boolean;
  favorite?: boolean;
  graph_details?: any;
  recorded_time?: string;
  selectedStories?: Array<Story> = [];
  stories_count?: number;
  story_type?: any;
  stories_status?: any;
  id?: number;
  name?: string;
  is_dragg?: boolean;
  last_changeset_id?: number;
  point_values?: Array<number>;
  start_date?: string;
  memberships_count?: number;
  membership_data?: Array<any>;
  project_brief?: String;
  squad_name?: string;
  project_brief_attachments?: Array<any>;
  landing_url?: String;
  internal_mattermost_channel_url?: String;
  builder_now_instant_URL?: String;
  builder_now_custom_URL?: String;
  branding_id?: number;
  branding_now_pid?: number;
  branding_project_id?: number;
  branding_created_at?: String;
  branding_updated_at?: String;
  branding_logo_attachments?: Array<any> = [];
  branding_font_attachments?: Array<any> = [];
  branding_colour_attachments?: Array<any> = [];
  branding_icon_attachments?: Array<any> = [];
  branding_illustration_attachments?: Array<any> = [];
  branding_other_attachments?: Array<any> = [];
  phases?: Array<String> = [];
  tracker_phases?: Array<any> = [];
  project_phases?: Array<any> = [];
  filters?: IFilter = {rating:[], statuses: [], ship_release_date_range: [], phase: [], feature: [], label: [], story_type: [], member: [], priority: [],states: [],sub_type: [],bb_status: [],
                        created_from: [], platform: [], code_commit: [], created_on_date_range: [],studio_roles: [],owner: [],reviewer: [],requester: [],dev_type: [],state_changed_at_date_range: [],sprints: [],bb_design: [], swimlane: []};
  internal?: boolean;
  selectedDate: { startDate: moment.Moment, endDate: moment.Moment };
  selectedUpdateDate: { startDate: moment.Moment, endDate: moment.Moment };
  dashboard_project_id?: number;
  build_card_id?: number;
  platform_names?: String;
  cpe_name?: string;
  cpe_initials?: string;
  cte_name?: string;
  cte_initials?: string;
  total_tracked_time?: string;
  total_approved_time?: string;
  invested_hours?: string;
  creator_tracked_time_data?: any;
  stories: Array<Story> = [];
  users: Array<User> = [];
  memberships: Array<Membership> = [];
  epics: Array<Epic> = [];
  labels: Array<any> = [];
  formatted_platforms?: Array<any>;
  status?: string;
  projectDevicePlatformsData?: Array<DevicePlatformData>;
  // progress?: ProgressPercentageData;
  progress?: any;
  progress_available?: boolean;
  studio_roles?: Array<StudioRole> = [];
  primary_managers?: Array<PrimaryManagers> = [];
  config?: any;
  bug_reasons?:Array<BugReasons> = [];
  checkIFProjectExternal: boolean = false;
  mainBugReasons: Array<BugReasons> = [];
  detailReasons: Array<BugReasons> = [];
  developer_working_schedules?:Array<any>;
  selected_role_for_bulk_action?: any;
  alias_name?:string;
  swimlane_count?: number;
  swimlanes: { name: string; group: number }[] = [];
  static className: string = 'Project';

  static getProject(data: ProjectData) {
    const project = GUID.instance.findProjectByID(data.id);
    if (project) {
      return project;
    } else {
      return new Project(data);
    }
  }

  constructor(data: ProjectData) {
    super(data);
    this.graph_options = data.graph_options;
    this.id = data.id;
    this.supports_bb = data.supports_bb;
    this.is_dragg = data.is_dragg;
    this.name = data.name;
    this.favorite = data.favorite;
    this.graph_details = data.graph_details;
    this.recorded_time = data.recorded_time;
    this.stories_count = data.stories_count;
    this.story_type = data.story_type;
    this.stories_status = data.stories_status;
    this.bug_click = data.bug_click;
    this.chore_click = data.chore_click;
    this.sideeffect_click = data.sideeffect_click;
    this.last_changeset_id = data.last_changeset_id;
    this.point_values = data.point_values;
    this.start_date = data.start_date;
    this.memberships_count = data.memberships_count;
    this.internal = data.internal;
    this.cpe_name = data.cpe_name;
    this.cpe_initials = data.cpe_initials;
    this.cte_name = data.cte_name;
    this.cte_initials = data.cte_initials;
    this.total_tracked_time = data.total_tracked_time;
    this.total_approved_time = data.total_approved_time;
    this.invested_hours = data.invested_hours;
    this.formatted_platforms = data.formatted_platforms;
    this.status = data.status;
    this.progress = data.progress;
    this.progress_available = data.progress_available;
    this.config = data.config;
    this.bug_reasons = data.bug_reasons;
    this.detailReasons = [];
    this.project_phases = data.phases;
    this.alias_name = data.alias_name;
    this.swimlane_count = data.swimlane_count;
    if (this.swimlane_count >= 0) {
      this.swimlanes = []; // Initialize the array here if needed
      for (let i = 0; i <= this.swimlane_count; i++) {
        this.swimlanes.push({ name: `Swimlane ${i + 1}`, group: i });
      }
    }

    if (data.bug_reasons) {
      const mainBugs = _.filter(data.bug_reasons, ['parent_id', null]);
      this.mainBugReasons = mainBugs;
    }

    if (data.primary_managers) {
      data.primary_managers.forEach(primary_manager => this.primary_managers.push(primary_manager));
    } else {
      this.primary_managers = [];
    }

    if (data.studio_roles) {
      data.studio_roles.forEach(st_role => this.studio_roles.push(st_role));
    } else {
      this.studio_roles = [];
    }

    if (data.formatted_platforms) {
      this.setDevicePlatformsData(data.formatted_platforms);
    } else {
      this.projectDevicePlatformsData = [];
    }

    if (data) {
      this.dashboard_project_id = data.dashboard_project_id;
      this.build_card_id = data.build_card_id;
      this.platform_names = data.platform_names;
    }

    if (this.dashboard_project_id) {
      this.checkIFProjectExternal = true;
    } else{
      this.checkIFProjectExternal = false;
    }

    if (data.membership_data) {
      data.membership_data.forEach(membership_content => {
        const membership = Membership.getMembership(membership_content.membership);
        this.addMember(membership);
      });
    }
    this.selected_role_for_bulk_action = null;
  }
  
  detailedBugReason(id: number) {
    return _.filter(this.bug_reasons, ['parent_id', id]);
  }

  addStory(story: Story) {
    if (story.testCase()) { return; }
    
    const index = this.stories.findIndex(candidate => candidate.id === story.id);
    story.prev_state = story.state;
    if (index === -1) {
      this.stories.push(story);
    } else {
      this.stories.splice(index, 1, story);
      if (story && story.list_id !== this.getArchivedColumnId()) {
        const selStoriesIds = map(this.selectedStories, 'id');
        const isStorySelected = _.includes(selStoriesIds, story.id);
        story.isSelected = isStorySelected;
      } else if (story && story.list_id === this.getArchivedColumnId()) {
        const ind = this.selectedStories.findIndex(item => item.id === story.id);
        if (ind >= 0) {
          this.selectedStories.splice(ind, 1);
          story.isSelected = false;
        }
      }
    }
  }

  getArchivedColumnId(): number {
    const ind = PROJECT_COLUMN_DATA.findIndex(col => col && col.list_code === 'archived');
    return ind >= 0 ? PROJECT_COLUMN_DATA[ind].list_id : -1;
  }

  getSelectedStoriesId() {
   return map(this.selectedStories, 'id');
  }

  findStoryByID(storyId: number) {
    return this.stories.filter(s => s.id === storyId)[0];
  }

  getStoriesId() {
    return map(this.stories, 'id');
   }

  removeStory(story: Story, options: DataManipulationOptions) {
    const index = this.stories.findIndex(candidate => candidate.id == story.id);
    if (index !== -1) {
      const payload = {
        data: {
          story: story
        }
      };
      this.stories.splice(index, 1);
      const storyIndex = this.findStoryIndex(story);
      if(storyIndex >= 0) {
        this.spliceStory(storyIndex);
      }
      options.notificationService.broadcast(EVENT_TYPES.STORY.REMOVED, payload);
      if (this.selectedStories.length === 1) {
        const selStory: Story = this.selectedStories[0];
        if (selStory) {
          selStory.isSelected = true;
          selStory.showDropDown = true;
          selStory.showQuickAct = true;
        }
      }
    }
  }

  findQuickActionStory() {
   return this.selectedStories.filter((story) => {
      return story.showQuickAct;
    })[0];
  }

  spliceStory(storyIndex) {
    this.selectedStories.splice(storyIndex, 1);
  }

  findStoryIndex(story) {
    return this.selectedStories.findIndex((sStory) => {
      return sStory.id === story.id;
    });
  }

  getLatestStoryPosition(): number {
    const story: Story = _.maxBy(this.stories, st => st.position);

    return story ? story.position : 0.0;
  }

  addEpics(epics: Array<Epic>) {
    epics.forEach(epic => this.addEpic(epic));
  }

  addEpic(epic: Epic) {
    const index = this.epics.findIndex(candidate => candidate.id === epic.id);

    if (index === -1) {
      this.epics.push(epic);
    } else {
      this.epics.splice(index, 1, epic);
    }

    if (!this.dashboard_project_id) {
      this.sortEpics();
    }
  }

  sortEpics() {
    this.epics = this.epics.sort((a, b) => {
      if (a.title.toLowerCase() < b.title.toLowerCase()) {
        return -1;
      }
      if (a.title.toLowerCase() > b.title.toLowerCase()) {
        return 1;
      }
      return 0;
    });
  }

  removeEpic(epic: Epic) {
    const index = this.epics.findIndex(candidate => candidate === epic);

    if (index !== -1) {
      this.epics.splice(index, 1);

      this.stories.forEach(story => story.removeEpic(epic));
    }
  }

  hasMember(membership: Membership) {
    const index = this.memberships.findIndex(candidate => candidate === membership);
    return index !== -1;
  }

  addMembers(memberships: Array<Membership>) {
    memberships.forEach(membership => this.addMember(membership));
    this.sortMembers();
    this.sortDeallocatedMembers();
  }

  addMember(membership: Membership) {
    if (!this.hasMember(membership)) {
      this.memberships.push(membership);
      this.addUser(membership.user);
    }
    this.sortMembers();
    this.sortDeallocatedMembers();
  }


  sortMembers(){
    this.memberships = this.memberships.sort((a, b) => {
      let valueA, valueB;
      valueA = a.user.name ? a.user.name : '',
      valueB = b.user.name ? b.user.name : '';
      valueA = valueA.toLowerCase();
      valueB = valueB.toLowerCase();
      if (valueA > valueB) {
        return 1;
      } else if (valueA < valueB) {
        return -1;
      } else {
        return 0;
      }
    });
  }

  sortDeallocatedMembers(){
    this.memberships = this.memberships.sort((a, b) => {
      let valueA, valueB;
      valueA = a,
      valueB = b;
      if ((valueA.user.deleted_at || !valueA.active) && (valueB.active && !valueB.user.deleted_at)) {
        return 1;
      } else if ((!valueA.user.deleted_at && valueA.active) && (!valueB.active || valueB.user.deleted_at)) {
        return -1;
      } else {
        return 0;
      }
    });
  }

  removeMember(membership: Membership) {
    const index = this.memberships.findIndex(candidate => candidate === membership);

    if (index !== -1) {
      this.memberships.splice(index, 1);
      this.removeUser(membership.user);
    }
  }

  membershipForUser(userId): Membership {
    const index = this.memberships.findIndex(item => item.user_id == userId);
    if (index >= 0) {
      return this.memberships[index];
    } else {
      return null;
    }
  }

  hasUser(user: User) {
    const index = this.users.findIndex(candidate => candidate.id === user.id);
    return index !== -1;
  }

  addUser(user: User) {
    if (!this.hasUser(user)) {
      this.users.push(user);
    }
  }

  removeUser(user: User) {
    const index = this.users.findIndex(candidate => candidate === user);

    if (index !== -1) {
      this.users.splice(index, 1);
    }
  }

  isUserActive(user: User) {
    // if user soft deleted
    if (user.deleted_at) { return false; }

    const ind = this.memberships.findIndex(member => member.user.id === user.id);
    return (ind !== -1) ? this.memberships[ind].active : false;
  }

  userInactiveText(user: User) {
    return user.deleted_at ? 'Deactivated' : 'Deallocated';
  }

  clearFilter() {
    this.filters.phase = [];
    this.filters.label = [];
    this.filters.feature = [];
    this.filters.member = [];
    this.filters.story_type = [];
    this.filters.states = [];
    this.filters.bb_status = [];
    this.filters.sub_type = [];
    this.filters.priority = [];
    this.filters.created_from = [];
    this.filters.platform = [];
    this.filters.code_commit = [];
    this.filters.created_on_date_range = [];
    this.filters.studio_roles = [];
    this.filters.owner = [];
    this.filters.requester = [];
    this.filters.reviewer = [];
    this.filters.dev_type = [];
    this.filters.state_changed_at_date_range = [];
    this.filters.sprints = [];
    this.filters.bb_design = [];
    this.filters.statuses = [];
    this.filters.ship_release_date_range = [];
    this.filters.rating = [];
    this.filters.swimlane = [];
  }

  addFilterParam(key, value) {
    if (this.filters[key]) {
      if (key === 'ship_release_date_range' || key === 'created_on_date_range' || key === 'state_changed_at_date_range') {
        this.filters[key] = [value];
      } else {
        if (!this.filters[key].find(ftr => ftr == value)) {
          this.filters[key].push(value);  
        }
      }
    }
  }

  findFilterIndex(key, value) {
    if (this.filters[key].length > 0) {
      if (key === 'ship_release_date_range' || key === 'created_on_date_range' || key === 'state_changed_at_date_range') {
        return 1;
      } else {
        return this.filters[key].indexOf(value);
      }
    } else {
      return -1;
    }
  }

  removeFilterParam(key, index) {
    if (this.filters[key]) {
      if (key === 'ship_release_date_range' || key === 'created_on_date_range' || key === 'state_changed_at_date_range') {
        this.filters[key] = [];
      } else {
        return this.filters[key].splice(index, 1);
      }
    }
  }

  get filterCount() {
    return this.filters.feature.length + this.filters.label.length + this.filters.member.length
            + this.filters.phase.length + this.filters.story_type.length + this.filters.priority.length
            + this.filters.created_from.length + this.filters.platform.length + this.filters.code_commit.length + this.filters.created_on_date_range.length
            + this.filters.states.length + this.filters.sub_type.length + this.filters.bb_status.length + this.filters.studio_roles.length 
            + this.filters.owner.length + this.filters.reviewer.length + this.filters.requester.length + this.filters.dev_type.length + this.filters.state_changed_at_date_range.length
            + this.filters.sprints.length + this.filters.bb_design.length + this.filters.swimlane.length + this.filters.statuses.length + this.filters.ship_release_date_range.length + this.filters.rating.length;
  }

  getClassName() {
    return Project.className;
  }

  isExternalProject(): boolean {
    return !!this.dashboard_project_id;
  }

  external(): boolean {
    return !!this.dashboard_project_id;
  }

  hasProjectPlatforms() {
    return this.dashboard_project_id && this.formatted_platforms;
  }

  setDevicePlatformsData(formatted_platforms) {
    this.projectDevicePlatformsData = [];
    if (formatted_platforms) {
      for (const device in formatted_platforms) {
        const devicePlatformData: DevicePlatformData = { device: device, platforms: []};
        if (formatted_platforms[device] && formatted_platforms[device].length > 0) {
          formatted_platforms[device].forEach(platform => devicePlatformData.platforms.push(platform));
        }
        this.projectDevicePlatformsData.push(devicePlatformData);
      }
    }
  }

  designPhaseAvailable(){
    return  (this.project_phases && (this.project_phases.find(phase => phase === 'Design' || phase === 'Prototype')));
  }

  liteCertified(epic){
    return epic.certification_type === "LITE";
  }

}
