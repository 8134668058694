import {
  Component,
  OnInit,
  Input,
  ElementRef,
  ChangeDetectorRef,
  Renderer2, Output, EventEmitter, ViewChild
} from '@angular/core';

import {
  Epic,
} from '../../shared/models/epic';

import {
  Project,
} from '../../shared/models/project';
import { trigger, transition, style, animate } from '@angular/animations';

import moment from 'moment';
import { EpicData, EVENT_TYPES,BB_STATUS_OPTIONS } from '../../shared/dataTypes';
import { QueryParamsService } from '../../shared/services/queryParams.service';
import { PolicyService } from '../../shared/services/policy.service';
import { EpicService } from '../../shared/services/epic.service';
import { UserService } from '../../shared/services/user.service';
import { ErrorService } from '../../shared/errorFunction';
import { NotificationService } from '../../shared/services/notification.service';
import { ProjectService } from '../../shared/services/project.service';
import { SharedService } from '../../shared/services/shared.service';
import {CustomToastService} from '../../shared/services/custom-toast.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { FilterStoryService } from '../../shared/services/filter-story.service';

@Component({
    selector: 'project-epic, [project-epic]',
    templateUrl: 'epic.component.html',
    styleUrls: ['epic.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({
          opacity:'0',
          transform: 'translateX(25px)'
        }),
        animate(200, style({
          opacity:'1',
          transform: 'translateX(0)'
        }))
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate(200, style({
          opacity:'0',
          transform: 'translateX(25px)'
        }))
      ])
    ]),
    trigger('fadeInOutVertical', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({
          opacity: '0',
          transform: 'translateY(10px)'
        }),
        animate(100, style({
          opacity: '1',
          transform: 'translateY(0)'
        }))
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate(100, style({
          opacity: '0',
          transform: 'translateY(10px)'
        }))
      ])
    ]),
  ],
  host: {
    '(document:click)': 'onClick($event)'
  }
})
export class EpicComponent implements OnInit {
  @Input() epic: Epic;
  @Input() project: Project;
  BBStatusOptions: Array<any> = BB_STATUS_OPTIONS
  editMode: boolean = false;
  @Input() showEpicArrow: boolean = true;
  epic_url: string;
  openNoteDeletePopup = false;
  delnote: any;
  waitForLoader = false;
  mouseOvered:boolean = false;
  openAuthor: boolean = false;
  openItemDoc: string = '';
  slideConfig = {
    'slidesToShow': 3,
    'slidesToScroll': 1,
    'infinite': false,
    'variableWidth': true
  };
  accessTypeOfAttachment = ['png', 'jpg', 'jpeg', 'gif'];
  detailViewMode: boolean = false;
  startLoaderOnEpicDetailView: boolean = false;
  featureDetailsData: any;
  estMouseOvered:boolean = false;
  @ViewChild('getEstimateBarCordinate')
  private getEstimateBarCordinate;
  @ViewChild('getEstimateInfoCordinate')
  private getEstimateInfoCordinate;
  showVideo:boolean = false;
  vURL = '';
  @Output('applyFeatureFilter') applyFeatureFilter = new EventEmitter();
  // setFeatureFilter: boolean = true;
  updateEpic:boolean = false;
  updatedFeatureTitle: String = '';
  updatedFeatureDescription: String = '';
  disableUpdateFeature: boolean = false;
  mapFeatureProgress;
  view_editor_options = {
    allowedContent: true,
    extraPlugins: 'divarea',
    forcePasteAsPlainText: true,
    removePlugins: 'exportpdf'
  };
  editorConfig: AngularEditorConfig = {
    editable: false,
    enableToolbar: false,
    showToolbar: false,
    sanitize: true,
    height: 'auto'
  };
  showFeatureStories:boolean = false;
  feature_stories: Array<any> = [];
  storiesLoader:boolean = false;
  updateFeature_Loader:boolean = false;
  current_story_completion;
  meta: any;
  isPending:boolean = false;
  storyBugMenuVisible:boolean = false;
  @ViewChild("progressTooltip", { static: false })
  private progressTooltip;
  @ViewChild("showProgressHover", { static: false })
  private showProgressHover;

  constructor(private notificationService: NotificationService,
              public userService: UserService,
              private errorService: ErrorService,
              public projectService: ProjectService,
              public epicService: EpicService,
              private sharedService: SharedService,
              private queryParamsService: QueryParamsService,
              public policyService: PolicyService,
              private element: ElementRef,
              private cdr: ChangeDetectorRef,
              private customToast: CustomToastService,
              private filterStoryService: FilterStoryService,
              private renderer: Renderer2) {

  }

  ngOnInit() {
    this.detailViewMode = false;
  }

  onClick(event) {
    const element = event.target;
    if (!element.classList.contains('iconb-plusIcon')) {
      this.storyBugMenuVisible = false;
    }
  }

  /* feature description will be shown in pop up. Var 'showFeatureDetail' is true by default but it sets to be false in case
     user clicks on feature story count => feature needs to be set as filter in the current project and feature description
     should not be shown */
  toggleDetailViewMode(type?) {
    this.projectService.hide_StoryDetails();
    if (this.detailViewMode) {
      this.detailViewMode = false;
      this.featureDetailsData = undefined;
    } else {
      this.projectService.setFeatureFilter = false;
      if (type && type === '360_feature') {
        this.detailViewMode = true;
        this.featureDetailsData = this.epic.featureDetailsData;
        if (this.featureDetailsData.video_urls) {
          this.featureDetailsData.video_urls = this.sortByDate(this.featureDetailsData.video_urls);
        }
      } else if (type && type === 'clickup_feature') {
        this.detailViewMode = true;
        this.featureDetailsData = {
          name : this.epic.title,
          description : this.epic.description
        };
      }
    }
    if (this.detailViewMode) {
      this.renderer.addClass(document.body, 'epicPopupView');
    } else {
      this.renderer.removeClass(document.body, 'epicPopupView');
    }

    this.featureDetailsData.description = this.featureDetailsData.description.replace(/(?:\r\n|\r|\n)/g, '<br>');
  }

  openAuthorPopup(url) {
    if (this.sharedService.checkType(url, true) == 'image') {
      this.openItemDoc = url;
      this.openAuthor = true;
    }
  }

  closeAuthorPopup() {
    this.openAuthor = false;
  }

  openEstimateInfo() {
    this.estMouseOvered = true;
    this.featureEstimateInfo();
  }
  closeEstimateInfo() {
    this.estMouseOvered = false;
  }

  featureEstimateInfo() {
    setTimeout(() => {
      if (this.getEstimateBarCordinate && this.getEstimateInfoCordinate) {
        const windHeight = window.innerHeight;
        const windWidth = window.innerWidth;
        const x = this.getEstimateBarCordinate.nativeElement.getBoundingClientRect().x;
        const y = this.getEstimateBarCordinate.nativeElement.getBoundingClientRect().y;
        const showpopWidth = this.getEstimateInfoCordinate.nativeElement.getBoundingClientRect().width;
        const calcWidth = showpopWidth + x + 45;
        const newdhf = x;
        const showpopHeight = this.getEstimateInfoCordinate.nativeElement.getBoundingClientRect().height;
        const elementPositionDiff = windHeight - y;
        this.renderer.setStyle(this.getEstimateInfoCordinate.nativeElement, 'left', newdhf + 'px');

        if (elementPositionDiff < showpopHeight) {
          const updatedPopPositon = y - showpopHeight;
          if (y < showpopHeight) {
            this.renderer.setStyle(this.getEstimateInfoCordinate.nativeElement, 'top', '220px');
          } else {
            this.renderer.setStyle(this.getEstimateInfoCordinate.nativeElement, 'top', updatedPopPositon - 5 + 'px');
          }
        } else {
          this.renderer.setStyle(this.getEstimateInfoCordinate.nativeElement, 'top', y + 10 + 'px');
        }
      }
    }, 0);
  }

  openVideoPopup(item, isURL?) {
    this.showVideo = true;
    this.vURL = isURL ? item : item.url;
  }

  closeVideoPopup() {
    this.showVideo = false;
    this.vURL = '';
  }

  getVideoName(url: string) {
    return url.substring(url.lastIndexOf('/') + 1);
  }

  formatTime(time) {
    return moment(time).format('lll');
  }

  sortByDate(videos) {
    videos.sort((a, b) => {
      return (+new Date(b[1])) - (+new Date(a[1]));
    });
    return videos;
  }

  /* Var 'setFeatureFilter' is true by default but it sets to false in case user wants to add new story in the feature or
     user wants to see the description/summary of the feature. In this case, feature filter should not be set */
  applyFilterOnStoryCountClick(key, value) {
    this.projectService.hide_StoryDetails();
    if (this.projectService.setFeatureFilter) {
      this.filterStoryService.applyFilter(this.project, null, key, value);
    } else {
      this.projectService.setFeatureFilter = true;
    }
  }

  getSelectedEpic(id) {
    return this.project && this.project.filters.feature.includes(id);
  }

  openUpdateEpicPopup() {
    this.projectService.setFeatureFilter = false;
    this.updateEpic = true;
    this.updatedFeatureTitle = this.epic.title;
    this.updatedFeatureDescription = this.epic.description;
    this.renderer.addClass(document.body, 'epicPopupView');
  }

  closeUpdateEpicPopup() {
    this.updateEpic = false;
    this.renderer.removeClass(document.body, 'epicPopupView');
  }

  updateFeature() {
    if (!this.disableUpdateFeature) {
      this.disableUpdateFeature = true;
      const data = {
        epic : {
          title : this.updatedFeatureTitle.trim() ? this.updatedFeatureTitle.trim() : this.epic.title,
          description : this.updatedFeatureDescription.trim() ? this.updatedFeatureDescription.trim() : this.epic.description,
          story_completion: this.epic.story_completion
        }
      };
      this.epicService.updateEpic(this.project.id, this.epic.id, data).subscribe(res => {
        this.disableUpdateFeature = false;
        this.customToast.messages.push({
          id: 'new_feature_creation_success',
          type: 'success',
          class: 'story_new',
          title: res.title,
          message: res.message
        });
        if (res && res.epic) {
          this.project.addEpic(new Epic(res.epic));
        }
        this.closeloadStoryCreatedForFeature();
        this.closeUpdateEpicPopup();
      }, err => {
        this.errorService.errorFunction(err);
        this.epic.story_completion = this.current_story_completion;
        this.disableUpdateFeature = false;
        this.closeloadStoryCreatedForFeature();
      });
    }
  }

  loadStoryCreatedForFeature(epicId) {
    if(!this.epic.story_completion && (this.epic.draft_stories_count === 0)) {
      this.storiesLoader = true;
      this.projectService.hide_StoryDetails();
      this.projectService.setFeatureFilter = false;
      this.showFeatureStories = true;
      this.renderer.addClass(document.body, 'epicPopupView');
      this.getStoryCreatedForFeature(epicId);
    }
  }

  getStoryCreatedForFeature(epicId, nextPage?) {
    let pageNo = nextPage ? nextPage : 1;
    this.epicService.storiesCreatedForFeature(this.project.id, epicId, pageNo).subscribe(res => {
      this.meta = res.meta;
      res.stories.forEach(story => {
        this.feature_stories.push(story);
      });
      this.storiesLoader = false;
      this.isPending = false;
    }, err => {
      this.storiesLoader = false;
      this.isPending = false;
      this.errorService.errorFunction(err);
    });
  }
  
  closeloadStoryCreatedForFeature() {
    this.feature_stories = [];
    this.showFeatureStories = false;
    this.renderer.removeClass(document.body, 'epicPopupView');
  }

  allStoriesCreatedOfFeature() {
    if (this.feature_stories.length === 0) {
      this.policyService.readOnlyWarning();
    } else {
      this.current_story_completion = this.epic.story_completion;
      this.epic.story_completion = true;
      this.updateFeature();
    }
  }

  isScrollFetchAvailable(): boolean {
    return !this.isPending && this.meta && this.meta.total_count !== this.feature_stories.length;
  }

  getMoreParentStories() {
    if (this.meta.total_count !== this.feature_stories.length) {
      this.isPending = true;
      this.getStoryCreatedForFeature(this.epic.id, this.meta.current_page + 1);
    }
  }
  goToStory(storyId) {
    window.open(`${window.location.origin}/#/projects/${this.project.id}` + `?storyId=${storyId}`, '_blank');
  }

  getBBDesignClass(status){
    return this.BBStatusOptions.find(st => st.type === status).type;
  }
  getFormattedBBStatus(status){
    return this.BBStatusOptions.find(st => st.type === status).title;
  }
  storyBugMenuToggle($event) {
    this.storyBugMenuVisible = !this.storyBugMenuVisible;
    $event.preventDefault();
    $event.stopPropagation();
  }

  bbStatusPlatform(device){
    return this.epic.bb_design_infos.find(design => design.device === device);
  }

  getIconClass(device){
    switch (device) {
      case 'Smartphone':
        return 'iconb-mobile';
      case 'Desktop':
        return 'iconb-desktop';
      case 'Watch':
        return 'iconb-watch';
      default:
        return;
    }
  }

  getphaseClass(phase){
    switch (phase) {
      case 'Full Build':
        return 'full-build';
      case 'Design':
        return 'design';
      case 'Custom Phase':
        return 'custom-phase';
      case 'Prototype':
        return 'prototype';
      case 'MVP':
        return 'mvp';
      default:
        return '';
    }
  }

  designPhase(phase){
    return phase.name === 'Prototype' || phase.name === 'Product Roadmap' || phase.name === 'Design';
  }

  progressPercentageHover() {
    const hoverTextBottom = this.showProgressHover.nativeElement.getBoundingClientRect().bottom;
    const tooltipTop = hoverTextBottom + 10;
    if (this.progressTooltip.nativeElement) {
      this.progressTooltip.nativeElement.style.top = `${tooltipTop}px`;
    }
  }
}
