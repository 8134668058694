import { EventEmitter } from '@angular/core';
import { clamp, range } from 'lodash';
import { CommonBase } from './base.class';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

export class CommonList extends CommonBase {
  meta: any;
  params = {
    q: '',
    sort_column: 'id',
    sort_order: 'asc',
    page: 1
  };
  pages: Array<number>;
  getRecordsThrottle: Subject<void> = new Subject<void>();

  constructor() {
    super();
    this
      .getRecordsThrottle
      .pipe(debounceTime(300))
      .subscribe(() => this.getRecords());
  }

  generatePages() {
    const minPage = 1;
    const maxPage = this.meta.total_pages;
    const curPage = this.params.page;
    const pageSideCount = 4;
    const pageTotalCount = pageSideCount * 2;
    let startPage, endPage;
    const constrainPage = (page) => clamp(page, minPage, maxPage);

    // Start from right side because it has less buffer
    if (curPage - minPage > maxPage - curPage) {
      endPage = constrainPage(curPage + pageSideCount);
      startPage = constrainPage(endPage - pageTotalCount);
    } else {
      startPage = constrainPage(curPage - pageSideCount);
      endPage = constrainPage(startPage + pageTotalCount);
    }
    this.pages = range(startPage, endPage + 1);
  }

  goToPage(page: number) {
    const notSamePage = page != this.params.page;
    const isInRange = page >= 1 && page <= this.meta.total_pages;
    if (notSamePage && isInRange) {
      this.params.page = page;
      this.getRecords();
    }
  }

  nextPage() {
    if (this.nextPageAvailable()) {
      this.params.page++;
      this.getRecords();
    }
  }

  nextPageAvailable(): boolean {
    return this.params.page < this.meta.total_pages;
  }

  previousPage() {
    if (this.previousPageAvailable()) {
      this.params.page--;
      this.getRecords();
    }
  }

  previousPageAvailable(): boolean {
    return this.params.page > 1;
  }

  columnIsActive(column: string) {
    return this.params.sort_column === column;
  }

  getSortStyling(column: string) {
    const isActive = this.columnIsActive(column);
    const isAscending = this.params.sort_order === 'asc';
    return {
      'glyphicon': true,
      'glyphicon-sort': !isActive,
      'glyphicon-sort-by-attributes': isActive && isAscending,
      'glyphicon-sort-by-attributes-alt': isActive && !isAscending,
    };
  }

  toggleSorting(column: string) {
    const toggle = {asc: 'desc', desc: 'asc'};
    if (this.params.sort_column === column) {
      this.params.sort_order = toggle[this.params.sort_order];
    } else {
      this.params.sort_column = column;
      this.params.sort_order = 'asc';
    }
    this.getRecordsThrottle.next();
  }

  getRecords() {
    console.warn('getRecords is not overriden');
  }
}
