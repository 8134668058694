import { Component, OnInit } from '@angular/core';
import { HttpRequests } from '../../shared/services/http-requests.service';
import { DataService } from '../../shared/services/data.service';
import { ErrorService } from '../../shared/errorFunction';

const DASHBOARD_ROUTES = {
  INDEX: 'admin/dashboard'
};

@Component({
  selector: 'admin-dashboard',
  templateUrl: 'dashboard.component.html',
  styleUrls: [
    '../admin.common.scss',
    'dashboard.component.scss'
  ]
})
export class AdminDashboardComponent implements OnInit {
  isPending: boolean = false;
  users: Array<any>;
  projects: Array<any>;
  details: any;
  startLoader:boolean = false;

  constructor(private httpRequest: HttpRequests,
              private dataService: DataService,
              private errorService: ErrorService) {}

  ngOnInit() {
    this.startLoader = true;
    this.getRecentUsersAndProjects();
  }

  getRecentUsersAndProjects() {
    this.isPending = true;
    const API = this.dataService.apiUrl + DASHBOARD_ROUTES.INDEX;
    this
      .httpRequest
      .get(API, this.dataService.getRequestOptionArgs())
      .subscribe(response => {
        let json = this.parseResponse(response);
        this.users = json.recent_users;
        this.projects = json.recent_projects;
        this.details = json.details;
        this.isPending = false;
        this.startLoader = false;
      },
    (error) => {
      this.errorService.errorFunction(error);
    });
  }

  userPath(user) {
    return `/#/admin/users/${user.id}`;
  }

  projectPath(project) {
    return `/#/admin/projects/${project.id}`;
  }

  public parseResponse(res: any): any {
    return res;
  }
}
