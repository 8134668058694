import { BaseModel } from './baseModel';
import { StoryReviewUsersData } from '../dataTypes';
export class StoryReviewUsers extends BaseModel implements StoryReviewUsersData {
    id?: number;
    name?: string;
    initials?: string;
    email?: string;
    isSelected?: boolean;

  constructor(data: StoryReviewUsersData) {
    super(data);
    this.id = data.id;
    this.name = data.name;
    this.initials = data.initials;
    this.email = data.email;
    this.isSelected = false;
  }

  getClassName() {
    return "StoryReviewUsers";
  }
} 