<div class="storyBoardPanel-Loader" *ngIf="startLoader">
  <img src="../../assets/images/storyBoardLoader.gif" alt="" />
</div>

<div class="projectsDashboard-wrapper">
  <div class="topSection">
    <div class="topHeading"> Story Approvals </div>
    <div class="fliterButton-wapper" >
      <div class="filterButton for_story_approval" [class.buttonSelected]="showfilterList" (click)="toggelefilter()">
        <em class="iconb-filter"></em>
        <span class="filterbutton-title"><b style="font-weight: 500" class="filterbutton-title">{{storyApprovalService.totalfiltersApplied()}}</b> Filter</span>
      </div>
      <div class="filterDD" [@slideOut] [ngClass]="[showfilterList ? 'filterDD-open':'']">
        <app-approvallist-filter (resetFilter)="resetFilter()" (filterApplied)="applyFilter($event.event ,$event.key, $event.value)"></app-approvallist-filter>
      </div>
    </div>
  </div>
  <div class="allProjectList-wrqapper">
    <div class="allProjectList-topHead">
      <div class="nameBlock">Feature Name</div>
      <div class="storiesBlock">Skill Type</div>
      <div class="membersBlock">Requestor</div>
      <div class="createdBlock">Requested On</div>
      <div class="requestTypeBlock">Request Type</div>
      <div class="statusBlock">Status</div>
      <div class="statusBlock">Design Review</div>
      <div class="membersBlock">Reviewer</div>
      <div class="reviewedOnBlock">Reviewed On</div>
    </div>
    <div class="noMemberFound-message" *ngIf="!storyReviewList?.length">
      <img src="../../../../assets/images/no-such-projects.png" alt="no project">
      <span class="notFoundText">No Story for approval found.</span>
    </div>
    <div 
    class="allProjectList-content" 
    infiniteScroll 
    [scrollWindow]="false" 
    [infiniteScrollDisabled]="!isScrollFetchAvailable()" 
    (scrolled)="getMoreStories()">
      <div class="allProjectList-listing" (click)="openStoryCompare(list)" *ngFor="let list of storyReviewList">
        <div class="nameBlock">{{list.epic?.title}} </div>
        <div class="storiesBlock">{{list.tech_stack? list.tech_stack : '-'}}</div>
        <div class="membersBlock">
          <span class="userInfo rquester-icon">{{list.requester?.initials ? list.requester?.initials?.slice(0,1) :
            list.requester?.name?.slice(0,1)}}
            <span class="elementHover">{{list.requester?.email}}</span>
          </span>
        </div>
        <div class="createdBlock">{{sharedService.timeFormat(list.created_at)}}</div>
        <div class="requestTypeBlock">{{list.request_type ? (list.request_type | replaceUnderscore) : '-'}}</div>
        <div class="statusBlock">
          <span class="status-chip"
            [ngClass]="{'accepted': list.status == 'accepted', 'rejected': list.status == 'rejected'}">{{list.status ?
            list.status : '-'}}</span>
        </div>
        <div class="statusBlock">
          <span class="status-chip" *ngIf="list.request_type === 'request_to_promote'"
            [ngClass]="{'accepted': list.designer_status == 'accepted', 'rejected': list.designer_status == 'rejected'}">{{list?.designer_status}}</span>
            <span *ngIf="list.request_type !==  'request_to_promote'">-</span>
        </div>
        <div class="membersBlock">
          {{list.reviewer ? '': '-'}}
          <span class="userInfo reviewer-icon" *ngIf="list.reviewer">{{list.reviewer?.intials ?
            list.reviewer?.intials?.slice(0,1) : list.reviewer?.name?.slice(0,1)}}
            <span class="elementHover">{{list.reviewer?.email}}</span>
          </span>
        </div>
        <div class="reviewedOnBlock">{{list.reviewed_on ? sharedService.timeFormat(list.updated_at) : '-'}}</div>
      </div>
    </div>
  </div>
</div>

<app-story-compare-popup *ngIf="showCompare" [isUpdateAvailable]="isUpdateAvailable" [selectedStory]="selectedStory" (showCompareChange)="onShowCompareChange($event)"></app-story-compare-popup>
