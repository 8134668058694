import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AdminUsersService } from '../users.service';
import { ErrorService } from '../../../shared/errorFunction';
import {Subscription} from 'rxjs';
import moment from 'moment';
import { SharedService } from '../../../shared/services/shared.service';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'admin-users-show',
  templateUrl: 'show.component.html',
  styleUrls: [
    '../../admin.common.scss',
    'show.component.scss'
  ]
})
export class AdminUsersShowComponent implements OnInit {
  userId: number;
  user: any;
  userProjects: any;
  waitForLoader:boolean = false;
  accessTypeOfattachment = ['png', 'jpg', 'jpeg', 'gif'];
  totalCount: any = {};
  load_more_projects = false;
  startLoader: boolean = true;
  searchProject: string = '';
  projectSubscription: Subscription;
  userDetailLoader:boolean = false;

  constructor(private usersService: AdminUsersService,
              private router: Router,
              public sharedService: SharedService,
              private activatedRoute: ActivatedRoute,
              private errorService: ErrorService,
              private domSanitizer: DomSanitizer) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.userDetailLoader = true;
      this.userId = +params['id'];
      this.getUser();
      this.loadUserProjects();
    });
  }

  getUser() {
    this.usersService.getUser(this.userId).subscribe(response => {
      this.user = this.parseResponse(response).user;
      this.userDetailLoader = false;
    }, err => {
      this.errorService.errorFunction(err);
      this.userDetailLoader = false;
    });
  }

  loadUserProjects(all_load?) {
    this.startLoader = true;
    if (this.projectSubscription) {
      this.projectSubscription.unsubscribe();
    }
    this.projectSubscription = this.usersService.getUserProjects(this.userId, this.searchProject, all_load).subscribe(res => {
      this.userProjects = [];
      res.projects.forEach(item => this.userProjects.push(item.project));
      this.startLoader = false;
      if (res.meta) {
        this.totalCount['current_count'] = res.meta['current_count'];
        this.totalCount['total_count'] = res.meta['total_count'];
        this.load_more_projects = this.totalCount['total_count'] > this.totalCount['current_count'] && !all_load;
      }
      this.userDetailLoader = false;
    }, err => {
      this.errorService.errorFunction(err);
      this.startLoader = false;
      this.userDetailLoader = false;
    });
  }

  public parseResponse(res: any): any {
    return res;
  }

  toggleViewmode(activity) {
    activity.toggleHistory = !activity.toggleHistory;
  }

  checkType(doc) {
    if (doc && doc.length > 0) {
      const lastIndexofDot = doc.lastIndexOf('.');
      const type = doc.substr(lastIndexofDot + 1);
      if (this.accessTypeOfattachment.indexOf(type.toLowerCase()) >= 0) {
        return true;
      }
      return false;
    }
  }

  loadMore() {
    this.load_more_projects = false;
    this.loadUserProjects(true);
  }

  secsToDuration(secs: number) {
    const duration = moment.duration(secs, 'seconds');
    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    const pad = (number) => { return number < 10 ? '0' + number : number }
    return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
  }

  bypassSanitization(html: string) {
    return this.domSanitizer.bypassSecurityTrustHtml(html);
  }
}
