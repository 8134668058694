import moment from 'moment';

import { BaseModel } from './baseModel';

import {
  NoteData,
  ReplyData
} from '../dataTypes';

import {
  Story
} from '../models/story';

export class Note extends BaseModel implements NoteData {
  created_at?: Date;
  errors?: {[key: string]: any};
  id?: number;
  metadata?: {[key: string]: any};
  formatted_replies?: ReplyData[];
  note?: string;
  display_note?: string;
  display_note_html?: string;
  open_edit?: boolean;
  show_mention?: boolean;
  note_type?: string;
  story_id?: number;
  updated_at?: Date;
  user_id?: number;
  user_name?: string;
  user_email?: string;
  display_reply?: string;
  user_initials?: string;
  maintain_mentions?: any;
  story?: Story;
  documents?: Array<any>;
  isReadOnly?: boolean = false;

  // adding variables for local use (done while optimizing note component)
  editCommentuploadProcessStarted?: boolean;
  uploaderArr?: Array<any> = [];
  attachmentPreviewUrls?: Array<any> = [];
  removeDocuments?: Array<any> = [];
  prevNoteText?: string;
  prevNoteDocuments?: Array<any> = [];
  replyNote: string = '';
  currentReply?: ReplyData;
  showMentionReply?: boolean = false;
  replyMaintainMentions = [];
  showReplyInput?: boolean = false;
  openCommentReply?: boolean = false;
  prevOpenCommentReplyStatus?: boolean;
  disableReplyButton?: boolean = false;
  user_type?: string;
  release_id?: number;

  constructor(data: NoteData, story?: Story) {
    super(data);
    this.release_id = data.release_id;
    this.created_at = data.created_at;
    this.errors = data.errors;
    this.open_edit = data.open_edit;
    this.id = data.id;
    this.metadata = data.metadata;
    if (data.user_type === 'client') {
      this.note = 'Comment received from client : ' + data.note;
    } else {
      this.note = data.note;
    }
    // this.note = data.note;
    this.note_type = data.note_type;
    this.story_id = data.story_id;
    this.updated_at = data.updated_at;
    this.user_id = data.user_id;
    this.user_name = data.user_name;
    this.user_email = data.user_email;
    this.user_initials = data.user_initials;
    this.story = story;
    this.documents = data.documents;
    this.formatted_replies = data.formatted_replies;
    if (data.formatted_replies && data.formatted_replies.length > 0) {
      this.refreshDisplayReply();
    }

    this.setReadonly();
    this.displayNote();
    this.openCommentReply = this.prevOpenCommentReplyStatus;
    this.user_type = data.user_type;
  }

  private refreshDisplayReply() {
    this.setDisplayReply();
    this.formatted_replies.forEach(reply => reply.openEditReply = false);
  }

  setReadonly() {
    if (this.story) {
      this.isReadOnly = this.story.isReadOnly;
    }
  }

  setDisplayReply() {
    this.formatted_replies.forEach((reply) => {
      reply.display_reply = reply.note;
      if(reply.display_reply) {
        reply.maintain_mentions = [];
        reply.display_reply = this.maintainMentions(reply.display_reply, true, reply);
        reply.display_reply_html = this.addToolTip(reply.display_reply, reply.maintain_mentions)
      }
    })
  }

  displayNote() {
    this.display_note = this.note;
    this.maintain_mentions = [];
    if(this.display_note) {
      this.display_note = this.maintainMentions(this.display_note, false);
      this.display_note_html = this.addToolTip(this.display_note, this.maintain_mentions)
    }
  }

  maintainMentions(note, reply, replystr?) {
    let isLoop = true;
    let startIndex = 0;
    while(isLoop) {
      let a = note.indexOf('{"name', startIndex);
      let b = note.indexOf('"}', a);
      startIndex = a + 5;
      if( a >= 0 && b  >= 0  && note && note.substring(a, b + 2) && note.substring(a, b + 2).trim()) {
        let obj;
        try {
          obj = JSON.parse(note.substring(a, b + 2));
        } catch (e) {
          obj = ''; // error in the above string (in this case, yes)!
        }
        if(typeof obj === 'object') {
          note = note.substring(0, a) + '@' + obj.name + note.substring(b + 2);
          let maintain_obj = {start: a-1, value: obj, replace: '@' + obj.name, end: a + obj.name.length}
          if(reply && replystr) {
            replystr.maintain_mentions.push(maintain_obj);
          } else {
            this.maintain_mentions.push(maintain_obj);
          }
        }
      }
      if(note.indexOf('{"name', startIndex) >= 0) {
        isLoop = true;
      } else {
        isLoop = false;
      }
    }
    return note
  }

  addToolTip(note, maintain_mentions) {
    if(maintain_mentions.length > 0) {
      let fullText = note;
      if(fullText) {
        maintain_mentions.slice().reverse().forEach((mention) => {
          fullText =  fullText.slice(0,  mention.end + 1) + '<span class = "elementHover">' +  mention.value.email  + '</span></span>' +  fullText.slice(mention.end + 1);
          fullText =  fullText.slice(0,  mention.start + 1) + '<span class = "mentionTitle">' +  fullText.slice(mention.start + 1)
        })
      }
      return fullText;
    }
    return note;
  }

  get createdAt() {
    return moment(this.created_at).format('lll');
  }

  getClassName() {
    return "Note";
  }

  addReply(reply: ReplyData) {
    this.updateReply(reply);
  }

  updateReply(reply: ReplyData) {
    if (!this.formatted_replies) { return; }

    const index = this.formatted_replies.findIndex(n => n.id === reply.id);
    if (index === -1) {
      this.formatted_replies.push(reply);
    } else {
      this.formatted_replies.splice(index, 1, reply);
    }
    this.refreshDisplayReply();
  }

  removeReply(reply: ReplyData) {
    if (!this.formatted_replies) { return; }
    
    const index = this.formatted_replies.findIndex(n => n.id == reply.id);
    if (index !== -1) {
      this.formatted_replies.splice(index, 1);
    }
  }
}
