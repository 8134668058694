<div class="st-Loader adminTimingLoader" *ngIf="startLoader">
  <div class="in-Loader"><img src="../../assets/images/builder_black.png" alt="loader"/></div>
  <div class="loadingOverlay"></div>
</div>

<div [class.pending]="isPending">
  <div class="row">
    <div class="col-sm-12">
      <h3>Report</h3>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-6">
        <label>Name of user</label>
        <select name="user"
                class="form-control"
                [(ngModel)]="user"
                [disabled]="isPending"
                (change)="getProjects()">
          <option *ngFor="let user of users" [ngValue]="user">
            {{user.name_or_email}}
          </option>
        </select>
      </div>

    <app-admin-timing-date-range-switcher
      [isPending]="isPending"
      [dateRange]="dateRange"
      (onDateRangeChanged)="onDateRangeChanged($event)">
    </app-admin-timing-date-range-switcher>
  </div>

  <div class="row">
    <div class="col-sm-12">
      <hr>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12">
      <div class="table-wrapper table-timing">
        <table role="presentation" class="table table-hover table-striped">
          <thead>
            <tr>
              <td>List of projects</td>
              <td>Total time</td>
            </tr>
          </thead>
          <tbody>
            <tr *ngIf="!projects.length" class="nothing-yet">
              <td colspan="3"><em>There is no data yet</em></td>
            </tr>
            <tr *ngFor="let project of projects" (click)="goToProject(project.id)">
              <td>{{project.name}}</td>
              <td>{{project.totalTime || '00:00:00'}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div *ngIf="projects.length" class="row">
    <div class="col-sm-12">
      <app-admin-timing-pagination
        [isPending]="isPending"
        [currentPage]="currentProjectsPage"
        [totalPages]="totalProjectsPages"
        (onNewPageSelected)="onProjectsPageChanged($event)">
      </app-admin-timing-pagination>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-6 total-hours">
      <div class="section">
        <div class="row">
          <div class="col-sm-7">
            <div>Total time on all the projects</div>
          </div>
          <div class="col-sm-5 col-left-border">
            <div class="heading">{{totalTime || '00:00:00'}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
