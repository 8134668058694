import { Component, OnInit, Input } from '@angular/core';
import { CustomToastService } from '../../services/custom-toast.service';

@Component({
  selector: 'app-toastdisplay',
  templateUrl: './toastdisplay.component.html',
  styleUrls: ['./toastdisplay.component.scss']
})
export class ToastdisplayComponent implements OnInit {
  constructor(private customToast: CustomToastService) { }
  @Input() data: any;
  ngOnInit() {
    const time = this.data && this.data.time || 10000
      // this.customToast.messages.filter((mes) => {
        setTimeout(() => {
          this.closeToaster(this.data.id);
        }, time)
      // })
  }

  closeToaster(id) {
  this.customToast.messages =   this.customToast.messages.filter((message) => {
      return message.id != id;
    })
  }

  get class() {
    return 'custom-toasterWrapper' + ' ' + this.data.class;
  }
}
