import { Component, OnInit } from '@angular/core';
import { UserService } from '../../shared/services/user.service';

@Component({
    selector: 'sign-out',
    templateUrl: 'sign-out.component.html'
})
export class SignOutComponent {

    public output: any;

    constructor(private userService: UserService) { }

    // Submit Data to Backend
    onSubmit() {
        this.output = null;
        this.userService.signOutUser().subscribe(
            res => this.output     = res,
            error => this.output   = error
        );
    }
}
