import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { clone, isEqual, uniq, each } from 'lodash';
import { AccountService } from '../../../shared/services/account.service';
import moment from 'moment-timezone';
import { Router } from '@angular/router';
import { CopiedComponent } from '../../../project';

@Component({
    selector: 'app-account-edit',
    templateUrl: 'edit.component.html',
    styleUrls: ['edit.component.scss']
})
export class AccountEditComponent {
  private errors: any;
  private account: any;
  public accountForm: any;
  private isPending: boolean = false;
  copied: any;
  offsetTmz = [];
  showLoginPassword = false;

  constructor(private formBuilder: FormBuilder,
              private accountService: AccountService, private router: Router) {
                this.copied = CopiedComponent;
              }

  ngOnInit() {
    this.isPending = true;
    this
      .accountService
      .get()
      .subscribe(this.onSuccessInit.bind(this), this.onError.bind(this));
  }


  showPassword($event) {
    this.showLoginPassword = !this.showLoginPassword;
    $event.preventDefault();
    $event.stopPropagation();
  }


  onPasswordChange(event) {
    let fieldName: string = event.srcElement.name;
    if (this.accountForm[fieldName] &&
        this.accountForm[fieldName].length == 0) {
      delete this.accountForm[fieldName];
    }
  }

  onSubmit() {
    this.isPending = true;
    this
      .accountService
      .update(this.accountForm)
      .subscribe(this.onSuccess.bind(this), this.onError.bind(this));
  }

  revert() {
    this.errors = null;
    this.accountForm = clone(this.account);
    for (const i in moment.tz.names()) {
      if (i) {
        this.offsetTmz.push({'id': moment.tz.names()[i], 'value': moment.tz.names()[i] + ' (GMT' + moment.tz(moment.tz.names()[i]).format('Z') + ')'});
      }
    }
    this.accountForm.time_zone = this.accountForm.time_zone ? this.accountForm.time_zone : 'US/Pacific';
  }

  onSuccess(response) {
    this.account = response.account;
    this.revert();
    this.isPending = false;
    this.router.navigate(['/account'])
  }

  onSuccessInit(response) {
    this.account = response.account;
    this.revert();
    this.isPending = false;
  }

  onError(response) {
    this.errors = {};
    each(response, (value, key) => {
      this.errors[key] = uniq(value);
    });
    this.isPending = false;
  }

  hasError(field) {
    return this.errors && this.errors.hasOwnProperty(field);
  }

  get isPristine(): boolean {
    return isEqual(this.account, this.accountForm);
  }
}
