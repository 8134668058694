import {Component, NgZone, ViewEncapsulation, HostListener, Optional, AfterViewInit, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {environment} from '../environments/environment';
import {EVENT_TYPES, HEADER_CONTEXT_TYPES, HeaderContextType} from './shared/dataTypes';
import {CustomToastService} from './shared/services/custom-toast.service';
import { DataService } from './shared/services/data.service';
import { ErrorService } from './shared/errorFunction';
import { NotificationService } from './shared/services/notification.service';
import { UserService } from './shared/services/user.service';
import { PolicyService } from './shared/services/policy.service';
import { ProjectService } from './shared/services/project.service';
import { SharedService } from './shared/services/shared.service';
import { API_ROUTES } from './shared/apiRoutes';
import { SwUpdate } from '@angular/service-worker';
import { trigger, transition, style, animate } from '@angular/animations';
import { Project  } from './shared/models/project';
import { fromEvent, of, Subscription, Observable, merge } from 'rxjs';
import { mapTo } from 'rxjs/operators';

@Component({
  selector: 'app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('makeSlide', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({
          opacity: '0',
          transform: 'translateX(-70px)'
        }),
        animate(100, style({
          opacity: '1',
          transform: 'translateX(0)'
        }))
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate(100, style({
          opacity: '0',
          transform: 'translateX(-70px)'
        }))
      ])
    ])
  ],
  host: {
    '(document:click)': 'onClick($event)'
  }
})
export class AppComponent implements OnInit {
  showCurrentUserList:boolean = false;
  contextType: HeaderContextType = HEADER_CONTEXT_TYPES.project;
  versionUpdate = false;
  showFavInBreadCrumb:boolean = false;
  online$: Observable<boolean>;
  subscriptions: Subscription[] = [];
  canDisplayNetOfflineModal: string = 'default';
  
  constructor(
              private policyService: PolicyService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              public projectService: ProjectService,
              public sharedService: SharedService,
              private location: Location,
              public userService: UserService,
              private customToast: CustomToastService,
              private notificationService: NotificationService,
              private errorService: ErrorService,
              private dataService: DataService,
              private zone: NgZone,
              @Optional() private swUpdate: SwUpdate
  ) {
    this.dataService.setDomain();
    if(!this.userService.getCookie(this.dataService.user_cookie)) {
      localStorage.removeItem('access_token');
      localStorage.removeItem('client');
      localStorage.removeItem('uid');
      this.userService.currentUser = null;
      this.userService.clearCurrentUser();
    }
    if (this.userService.userSignedIn()) {
      if(this.userService.getDesignerLogin()) {
          if(this.location.path().indexOf('confirmation_token') < 0) {
            window.location.href = environment.designerUrl;
          }
      }
      const qParamsObj  = this.sharedService.getQueryParam(window.location.href);
      if(qParamsObj && qParamsObj.key == 'redirect_to_uiw' && qParamsObj.value == 'true' && this.userService.getAdminLogin()) {
      const projectId = this.sharedService.parseProjectId(this.location.path())
        if(projectId) {
          window.location.href = API_ROUTES.UIW_URL(environment.designerUrl, projectId);
        } else {
          window.location.href = environment.designerUrl;
        }
      }
      this.userService.signOutDeveloperAfterSetTime();
      this.policyService.refresh();
      this.userService.getPartnerCode().subscribe(partner => {
        this.userService.setPartner(partner);
        this.loadProjects();
      }, err => this.errorService.errorFunction(err));
    } else {
      if (window.location.href.indexOf('register') >= 0 || window.location.href.indexOf('projects') >= 0) {
        let queryIndex = window.location.href.indexOf('?');
        let queryUrl = window.location.href.substr(queryIndex + 1);
        let registerQueryParam = queryUrl.substr(0, queryUrl.indexOf('='));
        let registerQueryParamValue = queryUrl.substr(queryUrl.indexOf('=') + 1);
        this.userService.loginUrl = this.location.path();
        if (registerQueryParam == 'confirmation_token') {
          this.policyService.initialPolicies();
          if (window.location.href.indexOf('register') >= 0) {
            const queryParams = {'confirmation_token': registerQueryParamValue};
            this.router.navigate(['register'], {queryParams: queryParams});
          } else {
            const queryParams = {'confirmation_token': registerQueryParamValue};
            // this.projectService.acceptProjectInvitation(queryParams).subscribe((resp) => {
            // })
          }


        } else {
          this.router.navigate(['login']);
        }

      } else {
        const path = this.location.path();
        const skipRoutes = ['/forgot-password', '/change-password'];
        // Skip redirect for password routes
        if (!skipRoutes.some(route => path.startsWith(route))) {
          this.router.navigate(['/noSession']);
        }
      }

    }

    this.online$ = merge(
      of(navigator.onLine),
      fromEvent(window, 'online').pipe(mapTo(true)),
      fromEvent(window, 'offline').pipe(mapTo(false))
    );

    this.networkStatus();
  }

  networkStatus() {
    this.online$.subscribe(online => {
      if (online) {
        if (this.canDisplayNetOfflineModal === 'offline') {
          this.canDisplayNetOfflineModal = 'online';
          window.location.reload();
        }
      } else {
        this.canDisplayNetOfflineModal = 'offline';
      }
    });
  }

  reloadPage() {
    this.canDisplayNetOfflineModal = 'online';
    window.location.reload();
  }

  //version upload
  versionReload() {
    window.location.reload();
    this.versionUpdate = false;
    return true;
  }

  private handleSoftwareUpdate() {
    this.swUpdate.available.subscribe(event => {
      this.versionUpdate = true;
        this.swUpdate.activateUpdate().then(() => {
        });
    });
    this.swUpdate.checkForUpdate();
  }

  ngOnInit() {
    this.handleSoftwareUpdate();
    this.subscribeForMaintenance();
    this.notificationService.subscribeEvent(EVENT_TYPES.SESSION.LOGGED_IN_NEW, data => {
      this.loadProjects();
    });
  }

  loadProjects() {
    this.zone.runOutsideAngular(() => {
      this.projectService.projects
        .subscribe(
          projects => {
            let projectsArr = []
            if(projects.projects.length) {
              projects.projects.forEach(pr => {
                projectsArr.push( Project.getProject(pr.project))
              })
            }
            if(projects.meta) {
              this.projectService.meta = projects.meta;
            }
            this.parseProjectData(projectsArr);
          },
          (error => {
            this.errorService.errorFunction(error);
          })
        );
    });
  }

  private parseProjectData(projects) {
    this.zone.run(() => {
      this.projectService.all_projects = projects;
      this.projectService.notifyProjectsList(this.projectService.all_projects, this.projectService.meta);
      this.projectService.clearMessage();
    });
  }

  get isAdmin(): boolean {
    return this.policyService.isAdmin;
  }

  openUserlist() {
    this.showCurrentUserList = !this.showCurrentUserList;
    if (this.showCurrentUserList) {
      this.userService.trackSegmentEvent('Member List Selected',
        this.projectService.getCommonPropsForStoryBoardEvents(this.projectService.currentProject));
    }
  }

  isProjectContext() {
    return this.router.url.substring(0, 9) == '/projects';
  }

  isProjectImportContext() {
    return this.router.url.indexOf('import') > 0;
  }

  isProjectBriefContext() {
    return this.router.url.indexOf('brief') > 0;
  }

  isProjectDashboardContext() {
    return this.router.url.indexOf('dashboard') > 0;
  }
  isProjectSettingContext() {
    return this.router.url.indexOf('settings') > 0;
  }

  addMember() {
    this.sharedService.showUserForm = true;
    this.projectService.isNewMemberAdded = true;
    this.userService.trackSegmentEvent('Add Member Clicked',
                  this.projectService.getCommonPropsForStoryBoardEvents(this.projectService.currentProject));
  }

  onClick(event) {
    const element = event.target;
    if (!element.classList.contains('usersInitials')) {
      this.showCurrentUserList = false;
    }
  }

  toggle_favourite(project) {
    project.favorite = !project.favorite;
    if (project.favorite) {
      this.projectService.addToFavorite(project.id).subscribe((res) => {
      }, error => {
        project.favorite = !project.favorite;
        this.errorService.errorFunction(error);
      });
    } else {
      this.projectService.deleteFromFavorite(project.id)
        .subscribe((res) => {
        }, error => {
          project.favorite = !project.favorite;
          this.errorService.errorFunction(error);
        });
    }
  }

  checkWindowUrlForNavigation(type) {
    if (type === 'trackerNav' || type === 'topNavigation') {
      return (this.router.url !== '/login' && this.router.url !== '/register'
        && window.location.href.indexOf('/register?confirmation_token=') < 0
        && this.router.url !== '/forgot-password' && this.router.url !== '/change-password'
        && window.location.href.indexOf('/change-password?reset_password_token=') < 0
        && window.location.href.indexOf('confirmation_token=') < 0)
        || (window.location.href.indexOf('confirmation_token=') > 0 &&
            this.userService.userSignedIn());
    } else if (type === 'trackerContent') {
      return this.router.url === '/login' || this.router.url === '/register'
        || window.location.href.indexOf('/register?confirmation_token=') >= 0
        || this.router.url === '/forgot-password' || this.router.url === '/change-password'
        || window.location.href.indexOf('/change-password?reset_password_token=') >= 0
        || (window.location.href.indexOf('confirmation_token=') >= 0 && !this.userService.userSignedIn());
    }
    return false;
  }

  subscribeForMaintenance() {
    this.userService.checkMaintenance();
    // setInterval(() => this.userService.checkMaintenance(), this.randomIntFromInterval(100000, 250000));
  }

  randomIntFromInterval(min, max) { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min)
  }

  onDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
