<div class="hs_story history_story" *ngIf="activities.length > 0">
  <div class="hs_storyListing" [ngClass]="{commentHistory: activity.details.length == 0}" *ngFor="let activity of activities">
    <div class="hs_story-header">
      <div class="hs_userInitials">{{activity.user_initials.slice(0,1)}}</div>
      <div class="hs_storyIconWrapper" *ngIf="activity.details.length > 0">
        <em class="iconb-dd-down hs_story-expandIcon" [ngClass]="{'expanded':activity.toggleHistory}" (click)="toggleViewmode(activity)"></em>
      </div>
      <div class="hs_storyTitleWrapper">
        <div class="hs_requestor">
          <span class="hs_requesterInfo">{{ activity.user_name }}</span>
          <span class="hs_eventType">{{ activity.action_name }}
            <span *ngIf="activity.commit_id"><a [href]="activity.commit_url" target="_blank" class="commitLink">{{activity.commit_id}}</a></span>
            {{ activity.created_on ? sharedService.formattTimeWithSeconds(activity.created_on) : ''}}
          </span>{{ activity.action_type }} <span *ngIf="(activity.moved_subtask | json) != '{}'"> from "<a [href]="activity.moved_subtask?.from_url" target="_blank" class="commitLink">{{activity.moved_subtask?.from_name}}</a>" to "<a [href]="activity.moved_subtask?.to_url" target="_blank" class="commitLink">{{activity.moved_subtask?.to_name}}</a>"</span> 
          <span class="hs_attachments" *ngIf="activity.attachment_url">
            <span class="uploading-listing">
              <div class="uploading-listing-thumbnail not_maximized">
                <img *ngIf="checkType(activity.attachment_url)" src="{{ activity.attachment_url }}" alt="loader"/>
                <img *ngIf="!checkType(activity.attachment_url)" class="default-attachment" src="/assets/images/defaullt-download.png" alt="loader"/>
              </div>
              <div class="uploading-file-description">
                <span class="docuName">{{ activity.action_type.substr(1, activity.action_type.length - 2) }}</span>
              </div>
            </span>
          </span>
        </div>
      </div>
    </div>
    <div class="hs_historyInfo" *ngIf="activity.toggleHistory &&  activity.details.length > 0">
      <div class="hs_eventTypeList" *ngFor="let detail of activity.details">
        <span class="hs_eventTypeName" *ngIf="detail.field_name == 'description to'"><strong>{{ detail.action }}</strong>{{ ' ' + detail.field_name + ' '}}<span [innerHtml]="bypassSanitization(detail.field_value)"></span></span>
        <span class="hs_eventTypeName" *ngIf="detail.field_name != 'description to'"><strong>{{ detail.action }}</strong>{{ ' ' + detail.field_name + ' "'}}<span [innerHtml]="bypassSanitization(detail.field_value)"></span>"</span>
        <span class="hs_attachments" *ngIf="detail.attachment_url">
          <span class="uploading-listing">
            <div class="uploading-listing-thumbnail not_maximized">
              <img *ngIf="checkType(detail.attachment_url)" src="{{ detail.attachment_url }}" alt="loader"/>
              <img *ngIf="!checkType(detail.attachment_url)" class="default-attachment" src="/assets/images/defaullt-download.png" alt="loader"/>
            </div>
            <div class="uploading-file-description">
              <span class="docuName">{{ detail.field_value }}</span>
            </div>
          </span>
        </span>
      </div>
    </div>
    <div class="hs_date"><span>{{ activity.added_on }}</span></div>
  </div>
</div>
<div class="show-more-stories" *ngIf="show_activity" (click)="showMore()">
  Show all <em class="iconb-dd-down"></em>
</div>
