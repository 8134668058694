import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SharedModule } from './../shared/shared.module';
import { ProjectBriefComponent } from './project-brief.component';
import {SlickCarouselModule} from 'ngx-slick-carousel';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    SlickCarouselModule
  ],
  declarations: [
    ProjectBriefComponent
  ],
  exports: [
    ProjectBriefComponent
  ]
})
export class ProjectBriefModule { }
