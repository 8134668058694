import {map} from 'rxjs/operators';
import { Injectable, Inject, forwardRef } from '@angular/core';
import {
  PolicyData,
  ADMIN_ROLES,
  PROJECT_UPDATE_ROLES
} from '../dataTypes';
import { API_ROUTES } from '../apiRoutes';
import { Project } from '../models/project';
import { NotificationService } from './notification.service';
import { DataService } from './data.service';
import { HttpRequests } from './http-requests.service';
import {ErrorService} from '../errorFunction';
import {CustomToastService} from "./custom-toast.service";

@Injectable()
export class PolicyService {
  private policies: PolicyData;
  policiesActive: boolean = false;

  constructor(
              private httpRequest: HttpRequests,
              private errorService: ErrorService,
              private dataService: DataService,
              private customToast: CustomToastService,
              @Inject(forwardRef(() => NotificationService)) private notificationService: NotificationService) {
  }

  refresh() {
    this.initialPolicies();
  }

  get isAdmin() {
    return this.policies && ADMIN_ROLES.indexOf(this.policies['role']) !== -1;
  }

  get isSuperAdmin() {
    return this.policies && this.policies['role'] === 'super_admin';
  }

  projectPolicy(project: Project) {
    if (project && this.policies && this.policies['policies'] && this.policies['policies']['projects']) {
      return this.policies['policies']['projects'][project.id];
    }
  }

  get isUserProjectAdmin() {
    return this.policies && (this.policies['project_admin']);
  }

  canEditProject(project: Project) {
    if (this.isAdmin) return this.isAdmin;

    const policy = this.projectPolicy(project);
    return policy && policy.edit;
  }

  canUpdateProject(project: Project) {
    if (this.isAdmin) return this.isAdmin;

    const policy = this.projectPolicy(project);
    return policy && policy.update;
  }

  // Use who have memership on this project with admin role
  // Or have Global admin
  isProjectAdmin(project: Project) {
    if (this.isSuperAdmin) return true;
    
    const policy = this.projectPolicy(project);
    if (policy && policy.role) {
      return ADMIN_ROLES.indexOf(policy.role) !== -1;
    } else {
      return this.isAdmin;
    }
  }

  // Use who have memership on this project with admin role
  projectAdmin(project: Project) {
    if (this.isSuperAdmin) return true;
     
    const policy = this.projectPolicy(project);
    return policy && ADMIN_ROLES.indexOf(policy.role) !== -1;
  }

  // if user has membership on this project
  hasMembership(project: Project) {
    if (this.isSuperAdmin) return true;

    const policy = this.projectPolicy(project);
    return policy && policy.role;
  }

  // Use who have memership on this project with user role
  // Or not have global admin
  isProjectUser(project: Project) {
    if (this.isSuperAdmin) return false;

    const policy = this.projectPolicy(project);
    return !policy || ADMIN_ROLES.indexOf(policy.role) === -1;
  }

  expertAsQA(project, checkAdmin = true) {
    return (checkAdmin && this.projectAdmin(project) || this.isQAExpert(project));
  }

  isQAExpert(project): boolean {
    const policy = this.projectPolicy(project);
    if (policy && policy.skill) {
      return policy.skill === 'Manual QA' || policy.skill === 'Automation QA';
    } else if(this.policies) {
      return this.policies.skill === 'Manual QA' || this.policies.skill === 'Automation QA';
    }
  }

  isProjectReadonly(project: Project) {
    const readpolicy = this.projectPolicy(project);
    return ((readpolicy && (readpolicy.role === 'read_only')) || (project && project.status === 'blocked') || (project && project.status === 'stopped'));
  }

  readOnlyWarning() {
    this.customToast.messages.push({
      id: 'read_only_warning',
      type: 'warning',
      class: 'generic_alert',
      title: 'Alert',
      message: 'Not authorized to perform this action.'
    });
  }

  inDraftMode() {
    this.customToast.messages.push({
      id: 'read_only_warning',
      type: 'warning',
      class: 'generic_alert',
      title: 'Alert',
      message: 'This story is in Draft mode. Please contact your delivery manager.'
    });
  }
  
  expertReadOnlyWarning() {
    this.customToast.messages.push({
      id: 'read_only_warning',
      type: 'warning',
      class: 'generic_alert',
      title: 'Alert',
      message: 'You are not authorized to perform this action. Please contact your delivery manager.'
    });
  }

  canEditProjectEntities(project: Project) {
    const policy = this.projectPolicy(project);

    return policy && PROJECT_UPDATE_ROLES.indexOf(policy['role']) !== -1;
  }

  canManageEpic(project: Project) {
    return this.canUpdateProject(project) || this.canEditProjectEntities(project);
  }

  canManageStory(project: Project) {
    return this.canUpdateProject(project) || this.canEditProjectEntities(project);
  }

  canDeleteProject(project: Project) {
    if (this.isAdmin) return this.isAdmin;

    const policy = this.projectPolicy(project);
    return policy && policy.delete;
  }

  canCreateMembership(project: Project) {
    if (this.isAdmin) return this.isAdmin;

    const projectPolicy = this.projectPolicy(project);
    return projectPolicy && projectPolicy.memberships.create;
  }

  canUpdateMembership(project: Project) {
    if (this.isAdmin) return this.isAdmin;

    const projectPolicy = this.projectPolicy(project);
    return projectPolicy && projectPolicy.memberships.update;
  }

  canDeleteMembership(project: Project) {
    if (this.isAdmin) return this.isAdmin;
    
    const projectPolicy = this.projectPolicy(project);
    return projectPolicy && projectPolicy.memberships.destroy;
  }

  initialPolicies() {
    const API = this.dataService.apiUrl + API_ROUTES.POLICIES;
    const observer = this.httpRequest.get(API, this.dataService.getRequestOptionArgs('application/json')).pipe(
      map(res => this.parseResponse(res)));

    observer.subscribe(
        res => {
          this.updatePolicies(res as PolicyData);
        },
        error => {
          this.errorService.errorFunction(error);
        }
      );

    return observer;
  }

  updatePolicies(policies: PolicyData) {
    this.policies = policies;
    this.policiesActive = true;

    const payload = {
      data: {
        policies: this.policies
      }
    };

    // this.notificationService.broadcast(EVENT_TYPES.POLICIES.UPDATED, payload);
  }

  public parseResponse(res: any): any {
    return res;
  }

}
