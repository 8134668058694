import { Component, OnInit, Input } from '@angular/core';
import {EVENT_TYPES} from '../../shared/dataTypes';
import {Project} from '../../shared/models/project';
import { NotificationService } from '../../shared/services/notification.service';
import { ProjectService } from '../../shared/services/project.service';
import { ActivityService } from '../../shared/services/activity.service';
import {ErrorService} from '../../shared/errorFunction';
import { ActivityTimeline } from '../../shared/models/activity-timeline';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-activity-timeline',
  templateUrl: './activity-timeline.component.html',
  styleUrls: ['./activity-timeline.component.scss']
})
export class ActivityTimelineComponent implements OnInit {
  @Input() project: Project;
  @Input() subject_id;
  @Input() subject_type;
  activities: any;
  activityDataExpanded = false;
  activity_loader:boolean =false;
  show_activity:boolean =false;

  constructor(private notificationService: NotificationService,
    private activityService: ActivityService,
    private domSanitizer: DomSanitizer,
    private errorService: ErrorService){}

  ngOnInit() {
    this.activity_loader = true;
    this.loadActivities(false);
    this.notificationService.subscribeEvent(EVENT_TYPES.STORY.STORY_HISTORY, data => {
      data && this.loadActivities(false);
    });
  }

  loadActivities(load_all_activities) {
    this.activityService.common_activities(this.project.id, this.subject_id, this.subject_type, load_all_activities).subscribe((res) => {
      if(res){
        const activities_data = [];
        res.data.forEach(data => {
          activities_data.push(new ActivityTimeline(data));
        });
        this.activities = this.groupData(activities_data);
        this.show_activity = !!res.meta.next_page
      }
     this.activity_loader = false;
    }, error => {
      this.errorService.errorFunction(error);
    });
  }

  toggleActivityData(activity) {
    activity.expand = !activity.expand;
  }

  groupData(data) {
    const result = {};
    data.forEach(item => {
      const createdOn = item.created_on;
      if (!result[createdOn]) {
        result[createdOn] = [];
      }
      result[createdOn].push(item);
    });
    return result;
  }
  
  showAllActivities() {
    this.show_activity = false;
    this.loadActivities(true);
  }

  bypassSanitization(html: string) {
    return this.domSanitizer.bypassSecurityTrustHtml(html);
  }

}
