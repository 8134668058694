import {Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, HostListener, Renderer2, ViewChildren} from '@angular/core';

import {
  Note
} from '../../shared/models/note';

import {
  User
} from '../../shared/models/user';

import {
  Project
} from '../../shared/models/project';

import {
  Story
} from '../../shared/models/story';

import {
  DataManipulationOptions
} from '../../shared/dataTypes';

import { NotificationService } from '../../shared/services/notification.service';
import { PolicyService } from '../../shared/services/policy.service';
import { NoteService } from '../../shared/services/note.service';
import { DocumentService } from '../../shared/services/document.service';
import { UserService } from '../../shared/services/user.service';
import { ErrorService } from '../../shared/errorFunction';
import {CustomToastService} from '../../shared/services/custom-toast.service';
import {LinkifyPipe} from '../../shared/pipes/linkify';
import moment from 'moment';
import { SharedService } from '../../shared/services/shared.service';

@Component({
  selector: 'story-note',
  templateUrl: 'note.component.html',
  styleUrls: ['note.component.scss']
})

export class StoryNoteComponent implements OnInit {
  comment_sort_order: String = 'New to Old';
  note;
  @Input('story') story: Story;
  @Input('release_comments') release_comments;
  @Input('project') project: Project;
  linkifyPipe = new LinkifyPipe();
  currentUser: User;
  @ViewChild('editComment') editComment: ElementRef;
  @ViewChild('replyNoteEl') replyNoteEl: ElementRef;
  @ViewChild('replyEditNote') replyEditNote: ElementRef;
  mentionUsers = [];
  selectedMemberIndex = 0;
  membersListElements = [];
  @ViewChildren('editMentionsList') editMentionsList: any;
  @ViewChildren('replyMentionsList') replyMentionsList: any;
  @ViewChildren('editReplyMentionsList') editReplyMentionsList: any;
  openAuthor: boolean = false;
  openItemDoc: string = '';
  showVideo: boolean = false;
  vURL = '';
  charCount = 0;
  atTheIndex;
  searchText = '';
  oldText;
  type = '';
  openNoteDeletePopup = false;
  delnote: any;
  waitForLoader = false;
  noteDocRemove:boolean = false;
  noteObj = {
    project_id: 0,
    subject_id: 0,
    subject_type: '',
  }

  constructor(private noteService: NoteService,
              private notificationService: NotificationService,
              public userService: UserService,
              public sharedService: SharedService,
              private errorService: ErrorService,
              private documentService: DocumentService,
              private customToast: CustomToastService,
              private renderer2: Renderer2,
              private policyService: PolicyService) {

  }

  get isProjectReadonly(): boolean {
    return this.policyService.isProjectReadonly(this.project);
  }

  ngOnInit() {
    this.currentUser = this.userService.getUser();
    this.noteObj = {
      project_id: this.project.id,
      subject_id: 0,
      subject_type: this.story ? 'story' : 'release',
    }
  }

  removeNoteDocument(note, file) {
    const index = note.uploaderArr.findIndex(item => item === file);
    if (index !== -1) {
      note.uploaderArr.splice(index, 1);
      note.attachmentPreviewUrls.splice(index, 1);
    }
  }

  deleteNote(note) {
    this.waitForLoader = true;
    this.openNoteDeletePopup = true;
    const options: DataManipulationOptions = {
      currentUser: this.userService.getUser(),
      notificationService: this.notificationService
    };
    this.noteObj.subject_id = this.story ? note.story_id : note.release_id;
    this.noteService.deleteNote(this.noteObj, note).subscribe((nt: Note) => {
      if(this.noteObj.subject_type === 'story'){
        this.trackCommentUpdateSegmentEvent('comment deleted', 'comment_deleted', nt);
        nt.story.removeNote(nt);
      } 
      this.waitForLoader = false;
      this.openNoteDeletePopup = false;
      this.customToast.messages.push({
        id: 'note_deleted',
        type: 'success',
        class: 'user_delete_info',
        title: 'Comment Deleted',
        message: 'The comment is deleted successfully.'
      });
      this.renderer2.removeClass(document.body, 'makePopupAttop');
    }, error => {
      this.errorService.errorFunction(error);
      this.waitForLoader = false;
      this.openNoteDeletePopup = false;
      this.renderer2.removeClass(document.body, 'makePopupAttop');
    });
  }

  openNotePopup(note, data, type) {
    if (this.story && this.story.deleted_at) {
      this.policyService.readOnlyWarning();
    } else {
      if (type === 'removeDocument') {
        this.noteDocRemove = true;
      } else {
        this.noteDocRemove = false;
      }
      this.renderer2.addClass(document.body, 'makePopupAttop');
      this.note = note;
      this.openNoteDeletePopup = true;
      this.delnote = data;
      this.type = type;
    }
  }

  closeNotePopup() {
    this.renderer2.removeClass(document.body, 'makePopupAttop');
    this.openNoteDeletePopup = false;
  }

  deleteElement(data) {
    if (this.type === 'deleteNote') {
      this.deleteNote(this.note);
    } else if (this.type === 'removeDocument') {
      this.removeDocument(this.note, data);
    } else if (this.type === 'deleteNoteReply') {
      this.deleteReply(this.note);
    }
  }

  removeRenderedClass() {
    this.renderer2.removeClass(document.body, 'makePopupAttop');
  }

  removeDocument(note, document) {
    this.waitForLoader = true;
    this.openNoteDeletePopup = true;
    const options: DataManipulationOptions = {
      currentUser: this.userService.getUser(),
      notificationService: this.notificationService
    };
    if(note.release_id){this.story = null;}
    this.noteObj.subject_id = this.story ? note.story_id : note.release_id;
    if (note.documents.length === 1 && ((!note.note) || (!note.note.trim()) )) {
      this.noteService.deleteNote(this.noteObj, note).subscribe((nt: Note) => {
        if (this.story) {
          nt.story.removeNote(nt);
        } 
        this.waitForLoader = false;
        this.openNoteDeletePopup = false;
        this.removeRenderedClass();
      }, error => {
        this.errorService.errorFunction(error);
        this.waitForLoader = false;
        this.openNoteDeletePopup = false;
        this.removeRenderedClass();
      });
    } else {
      this.noteService.updateNote(note.story, note, document, this.noteObj)
      .subscribe((nt: Note) => {
        if (this.story) {
          nt.story.removeNoteDoc(nt, document);
        }
        this.waitForLoader = false;
        this.openNoteDeletePopup = false;
        this.removeRenderedClass();
      }, error => {
        this.errorService.errorFunction(error);
        this.waitForLoader = false;
        this.openNoteDeletePopup = false;
        this.removeRenderedClass();
      });
    }
  }


  editCurrentNote(note) {
    if (!note.editCommentuploadProcessStarted) {
      note.editCommentuploadProcessStarted = true;
      this.noteObj.subject_id = this.story ? note.story_id : note.release_id;
      if (note.uploaderArr.length === 0 && note.note.trim() === '' && note.documents.length === 0) {
        this.deleteNote(note);
      } else {
        let text = note.display_note;
        if (note.maintain_mentions.length > 0) {
          text = this.sharedService.saveParseString(text, note.maintain_mentions);
        }
        note.note = text;
        this.noteService.editUpdateNote(note.story, note, note.uploaderArr, note.removeDocuments, this.noteObj).subscribe((nt: Note) => {
          if(this.noteObj.subject_type === 'story'){
            this.trackCommentUpdateSegmentEvent('comment updated', 'comment_updated', nt);
          }
          note.removeDocuments = [];
          note.uploaderArr = [];
          note.open_edit = false;
          note.attachmentPreviewUrls = [];
          note.editCommentuploadProcessStarted = false;
        }, err => {
          this.errorService.errorFunction(err);
          note.editCommentuploadProcessStarted = false;
        });
      }
    }
  }

  openAuthorPopup(note, item, key, index?) {
    this.renderer2.addClass(document.body, 'makePopupAttop');
    if (item && key === 'name') {
      if (this.sharedService.checkType(item.name, true) === 'image') {
        this.openItemDoc = note.attachmentPreviewUrls[index];
        this.openAuthor = true;
      }
    } else if (item && key === 'remote_url') {
      if (this.sharedService.checkType(item.remote_url, true) === 'image') {
        this.openItemDoc = item.remote_url;
        this.openAuthor = true;
      }
    }
  }

  closeAuthorPopup() {
    this.renderer2.removeClass(document.body, 'makePopupAttop');
    this.openAuthor = false;
  }

  attachFilesInComment(event, note) {
    Array.from(event.target.files).forEach(file => {
      note.uploaderArr.push(file);
      this.preview(file, note);
    });
  }

  preview(file, note) {
    if (this.sharedService.checkType(file.name, true) === 'image') {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (_event) => {
        note.attachmentPreviewUrls.push(reader.result);
      };
    } else {
      note.attachmentPreviewUrls.push('/assets/images/defaullt-download.png');
    }
  }

  editNote(note) {
    if (this.story && this.story.deleted_at) {
      this.policyService.readOnlyWarning();
    } else {
      note.open_edit = true;
      note.prevNoteText = note.display_note;
      note.prevNoteDocuments = Object.assign([], note.documents);
    }
  }

  canceleditNote(note) {
    note.display_note = note.prevNoteText;
    note.open_edit = false;
    note.documents =  Object.assign([], note.prevNoteDocuments);
    note.uploaderArr = [];
    note.attachmentPreviewUrls = [];
    note.removeDocuments = [];
  }

  showNoteContent(content) {
    content = this.linkifyPipe.transform(content);
    const a =  content.replace(new RegExp('\n', 'g'), '<br />');
    return a;
  }

  removeEditDoc(note, document) {
    if ((this.story && !this.story.isNew) || this.noteObj.subject_type === 'release') {
      const doc = {
        'id': document.document.id,
        '_destroy': true,
        'name' : document.document.name,
        'size' : document.document.size,
        'content_type' : document.document.content_type,
        'remote_url' : document.document.remote_url
      };
      note.removeDocuments.push(doc);
    }
    if(this.story){note.story.removeNoteDoc(note, document);}
    if(this.noteObj.subject_type === 'release'){this.removeReleaseDetailDocWhileEditing(note, document);}
  }

  removeReleaseDetailDocWhileEditing(note: Note, document: Document) {
    this.release_comments.forEach((candidate, index) => {
      if (candidate == note) {
        const  noteIndex = this.release_comments[index].documents.findIndex(doc => doc == document);
        if (noteIndex !== -1) {
          this.release_comments[index].documents.splice(noteIndex, 1);
        }
      }
    });
  }

  get isProjectAdmin(): boolean {
    return this.policyService.isProjectAdmin(this.story.project);
  }

  trackCommentUpdateSegmentEvent(eventName, propName, note) {
    this.userService.trackSegmentEvent(this.story.storyOrTask() + ' ' + eventName, this.getPropsOnCommmentUpdation(propName, note));
  }

  getPropsOnCommmentUpdation(propName, note) {
    if (this.project && this.story) {
      const props = {
        'build_card_id': this.project.build_card_id,
        '360_project_id': this.project.dashboard_project_id,
        'platform_name': this.project.platform_names,
        'tracker_id': this.project.id,
        'updated_by': this.userService.getUser().email,
        'phase': this.story.phase,
        'user_email': this.userService.getUser().email,
        'user_type': this.userService.getIsUserInternalOrCp(),
        'project_name': this.project.name,
        'project_type': this.project.dashboard_project_id ? 'client' : 'internal'
      };

      if (this.story.parent_id) {
        props['story_id'] = this.story.parent_id;
        props['task_id'] = this.story.id;
      } else {
        props['story_id'] = this.story.id;
      }

      props[propName] = this.getCommentData(note);
      return props;
    }
  }

  getCommentData(note) {
    const urls: Array<any> = [];
    note.documents.forEach(doc => urls.push(doc.document.remote_url));

    const com = {
      'id': note.id,
      'comment': note.note,
      'attachments': urls
    };

    return com;
  }

  openReplyField(note) {
    if (this.isProjectReadonly || (this.story && this.story.deleted_at)) {
      this.policyService.readOnlyWarning();
    } else {
      note.showReplyInput = !note.showReplyInput;
      note.replyNote = '';
    }
  }

  closeReplyField(note) {
    note.showReplyInput = false;
    note.replyNote = '';
  }

  openReply(note) {
    note.openCommentReply = !note.openCommentReply;
    note.prevOpenCommentReplyStatus = note.openCommentReply;
  }

  openEditReply(reply, note) {
    if (this.isProjectReadonly || (this.story && this.story.deleted_at)) {
      this.policyService.readOnlyWarning();
    } else {
      note.currentReply = reply;
      reply.openEditReply = true;
      this.searchText = '';
      note.replyMaintainMentions = [];
      reply.prevReplyText = reply.display_reply;
      reply.disableEditReplyButton = false;
    }
  }

  cancelEditReply(reply, note) {
    reply.openEditReply = false;
    note.replyMaintainMentions = [];
    this.searchText = '';
    reply.display_reply = reply.prevReplyText;
    reply.disableEditReplyButton = false;
  }

  replyOnNote(note) {
    if (!note.disableReplyButton) {
      note.disableReplyButton = true;
      let text = note.replyNote;
      this.noteObj.subject_id = this.story ? note.story_id : note.release_id;
      if (note.replyMaintainMentions.length > 0) {
        text = this.sharedService.saveParseString(text, note.replyMaintainMentions);
      }
      const replyNote = {
        note: text.trim(),
        parent_id: note.id
      };
      this.noteService.createNoteReply(this.story, replyNote, this.noteObj).subscribe((nt: Note) => {
        note.addReply(nt);
        note.replyMaintainMentions = [];
        this.searchText = '';
        this.closeReplyField(note);
        note.disableReplyButton = false;
      }, (error) => {
        this.errorService.errorFunction(error);
        note.disableReplyButton = false;
      });
    }
  }

  editReply(reply, note) {
    this.noteObj.subject_id = this.story ? note.story_id : note.release_id;
    if (!reply.disableEditReplyButton) {
      reply.disableEditReplyButton = true;

      if (reply.display_reply.trim().length === 0 || reply.display_reply.trim() === reply.prevReplyText) {
        this.cancelEditReply(reply, note);
        return false;
      }
      let text = note.currentReply.display_reply;
      if (note.currentReply.maintain_mentions.length > 0) {
        text = this.sharedService.saveParseString(text, note.currentReply.maintain_mentions);
      }
      const replyNote = {
        id: reply.id,
        note: text.trim(),
      };
      this.noteService.editNoteReply(note.story, replyNote, this.noteObj).subscribe((nt: Note) => {
        note.updateReply(nt);
        reply.disableEditReplyButton = false;
        reply.openEditReply = false;
      }, err => {
        this.errorService.errorFunction(err);
        reply.disableEditReplyButton = false;
      });
    }
  }

  deleteReply(note) {
    this.noteObj.subject_id = this.story ? note.story_id : note.release_id;
    this.noteService.deleteNoteReply(this.noteObj, this.delnote.id).subscribe(() => {
      note.removeReply({ id: this.delnote.id });
      this.waitForLoader = false;
      this.openNoteDeletePopup = false;
      this.renderer2.removeClass(document.body, 'makePopupAttop');
    }, err => {
      this.errorService.errorFunction(err);
      this.waitForLoader = false;
      this.openNoteDeletePopup = false;
      this.renderer2.removeClass(document.body, 'makePopupAttop');
    });
  }

  formatTime(time) {
    return moment(time).format('lll');
  }

  hideAllMentionPoupForReply(note) {
    if (note && note.formatted_replies) {
      note.formatted_replies.forEach((reply) => reply.show_mention = false);
    }
  }

  selectMention(user, type, note) {
    this.mentionUsers = [];
    let mentionObj;
    if (type === 'edit') {
      mentionObj =   this.sharedService.prepareMentionStr(user, note.display_note, this.atTheIndex);
      note.display_note = mentionObj.searchText;
      const obj = {start: this.atTheIndex - 1, value: mentionObj.userObj, replace: '@' +  mentionObj.userObj.name,
                    end: this.atTheIndex + mentionObj.userObj.name.length};
      note.display_note = note.display_note.substring(0, mentionObj.commentStr.length) +
                            note.display_note.substring(mentionObj.commentStr.length + this.charCount);
      note.maintain_mentions.push(obj);
    } else if (type === 'reply') {
      mentionObj =   this.sharedService.prepareMentionStr(user, note.replyNote, this.atTheIndex);
      note.replyNote = mentionObj.searchText;
      const obj = {start: this.atTheIndex - 1, value: mentionObj.userObj, replace: '@' +  mentionObj.userObj.name,
                    end: this.atTheIndex + mentionObj.userObj.name.length};
      note.replyNote = note.replyNote.substring(0, mentionObj.commentStr.length) +
                        note.replyNote.substring(mentionObj.commentStr.length + this.charCount);
      note.replyMaintainMentions.push(obj);
    } else if (type === 'replyedit') {
      mentionObj =   this.sharedService.prepareMentionStr(user, note.currentReply.display_reply, this.atTheIndex);
      note.currentReply.display_reply = mentionObj.searchText;
      const obj = {start: this.atTheIndex - 1, value: mentionObj.userObj, replace: '@' +  mentionObj.userObj.name,
                    end: this.atTheIndex + mentionObj.userObj.name.length};
      note.currentReply.display_reply = note.currentReply.display_reply.substring(0, mentionObj.commentStr.length) +
                                          note.currentReply.display_reply.substring(mentionObj.commentStr.length + this.charCount);
      note.currentReply.maintain_mentions.push(obj);
    }
    this.typeMentionShow(false, type, note);
    this.atTheIndex = null;
    this.charCount = 0;
    this.searchText = '';
    setTimeout(() => {
      if (type === 'edit') {
        this.editComment.nativeElement.focus();
        this.editComment.nativeElement.selectionEnd = mentionObj.commentStr.length;
      } else if ('reply') {
        this.replyNoteEl.nativeElement.focus();
        this.replyNoteEl.nativeElement.selectionEnd =  mentionObj.commentStr.length;
      } else {
        this.replyEditNote.nativeElement.focus();
        this.replyEditNote.nativeElement.selectionEnd =  mentionObj.commentStr.length;
      }
    }, 0);
  }

  typeMentionShow(showHide, type, note) {
    if (type === 'edit') {
      this.hideAllMentionPoupForReply(note);
      note.show_mention = showHide;
    } else if (type === 'reply') {
      note.show_mention = false;
      note.showMentionReply = showHide;
    } else {
      note.show_mention = false;
      this.hideAllMentionPoupForReply(note);
      note.currentReply.show_mention = showHide;
    }
  }

  typeShowMention(type, note) {
    if (type === 'edit') {
      return note.show_mention;
    } else if (type === 'reply') {
      return note.showMentionReply;
    } else {
      return note.currentReply.show_mention;
    }
  }

  searchMentionUser(event, type, note) {
    this.note = note;
    if(this.story) {
      this.story.notes.forEach(nt => {
        nt.show_mention = false;
        nt.showMentionReply = false;
        if (nt.currentReply) {
          nt.currentReply.show_mention = false;
        }
      });
    }

    if (type === 'edit') {
      note.show_mention = true;
      this.searchText = note.display_note;
    } else if (type === 'reply') {
      note.showMentionReply = true;
      this.searchText = note.replyNote;
    } else {
      note.currentReply.show_mention = true;
      this.searchText = note.currentReply.display_reply;
    }

    if (event && (event.keyCode === 38 || event.keyCode === 40 || event.keyCode === 9 || event.keyCode === 13)) {
      event.preventDefault();
      return false;
    }

    if (this.searchText[event.target.selectionStart - 1] === '@') {
      this.atTheIndex = event.target.selectionStart - 1;
      this.mentionUsers = this.sharedService.searchMentionUser(this.searchText, this.atTheIndex, this.project.users);
      this.charCount = 0;
      this.typeMentionShow(true, type, note);
      if (this.searchText[event.target.selectionStart - 1] === '@' || event.keyCode === 39) {
        this.setMembersList(note);
      }
    } else if (this.typeShowMention(type, note) && this.searchText[this.atTheIndex] === '@' &&
                (this.atTheIndex < (event.target.selectionStart - 1))) {
      this.charCount++;
      this.mentionUsers = this.sharedService.searchMentionUser(this.searchText, this.atTheIndex, this.project.users, this.charCount);
      this.setMembersList(note);
    } else {
      this.typeMentionShow(false, type, note);
      this.mentionUsers = [],
      this.charCount = 0;
      this.atTheIndex = null;
    }
    if (type === 'edit') {
      if (this.oldText && (this.oldText.length > this.searchText.length)) {
        const decrementCount = this.oldText.length - this.searchText.length;
        note.maintain_mentions = this.sharedService.decrementMentionIndex(note.maintain_mentions, event, decrementCount);
      }
      if (this.oldText && (this.oldText.length < this.searchText.length)) {
        const incrementCount = this.searchText.length - this.oldText.length;
        note.maintain_mentions = this.sharedService.incrementMentionIndex(note.maintain_mentions, event, incrementCount);
      }
    } else if (type === 'reply') {
      if (this.oldText &&  (this.oldText.length > this.searchText.length)) {
        const decrementCount = this.oldText.length - this.searchText.length;
        note.replyMaintainMentions = this.sharedService.decrementMentionIndex(note.replyMaintainMentions, event, decrementCount);
      }
      if (this.oldText &&  (this.oldText.length < this.searchText.length)) {
        const incrementCount = this.searchText.length - this.oldText.length;
        note.replyMaintainMentions = this.sharedService.incrementMentionIndex(note.replyMaintainMentions, event, incrementCount);
      }
    } else if (type === 'replyedit') {
      if (this.oldText &&  (this.oldText.length > this.searchText.length)) {
        const decrementCount = this.oldText.length - this.searchText.length;
        note.currentReply.maintain_mentions =
                      this.sharedService.decrementMentionIndex(note.currentReply.maintain_mentions, event, decrementCount);
      }
      if (this.oldText &&  (this.oldText.length < this.searchText.length)) {
        const incrementCount = this.searchText.length - this.oldText.length;
        note.currentReply.maintain_mentions =
                      this.sharedService.incrementMentionIndex(note.currentReply.maintain_mentions, event, incrementCount);
      }
    }

    this.oldText = this.searchText;
    if (!this.searchText) {
      note.replyMaintainMentions = [];
      this.oldText = '';
    }
  }

  setMembersList(note) {
    setTimeout(() => {
      this.selectedMemberIndex = 0;
      this.membersListElements = [];

      if (note && note.show_mention) {
        this.membersListElements = this.editMentionsList.map(member => {
          this.renderer2.setStyle(member.nativeElement, 'backgroundColor', 'white');
          return member.nativeElement;
        });
      } else if (note.showMentionReply) {
        this.membersListElements = this.replyMentionsList.map(member => {
          this.renderer2.setStyle(member.nativeElement, 'backgroundColor', 'white');
          return member.nativeElement;
        });
      } else if (note.currentReply && note.currentReply.show_mention) {
        this.membersListElements = this.editReplyMentionsList.map(member => {
          this.renderer2.setStyle(member.nativeElement, 'backgroundColor', 'white');
          return member.nativeElement;
        });
      }

      if (this.membersListElements && this.membersListElements[this.selectedMemberIndex]) {
        this.renderer2.setStyle(this.membersListElements[this.selectedMemberIndex], 'backgroundColor', '#F2F4F4');
        this.membersListElements[this.selectedMemberIndex].scrollIntoViewIfNeeded();
      }
    }, 0);
  }

  setSelectedIndex(index) {
    if (this.membersListElements && this.membersListElements[this.selectedMemberIndex]) {
      this.renderer2.setStyle(this.membersListElements[this.selectedMemberIndex], 'backgroundColor', 'white');
      this.selectedMemberIndex = index;
      this.renderer2.setStyle(this.membersListElements[this.selectedMemberIndex], 'backgroundColor', '#F2F4F4');
      this.membersListElements[this.selectedMemberIndex].scrollIntoViewIfNeeded();
    }
  }

  @HostListener('keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (this.note && ( this.note.show_mention || this.note.showMentionReply ||
                        (this.note.currentReply && this.note.currentReply.show_mention))) {
      if (event.keyCode === 38) {
        if (this.selectedMemberIndex > 0) {
          this.setSelectedIndex(this.selectedMemberIndex - 1);
        }
        event.preventDefault();
      } else if (event.keyCode === 40) {
        if (this.membersListElements && (this.selectedMemberIndex < (this.membersListElements.length - 1))) {
          this.setSelectedIndex(this.selectedMemberIndex + 1);
        }
        event.preventDefault();
      } else if (event.keyCode === 9 || event.keyCode === 13) {
        this.setSelectedMember(this.note);
      }
    }
  }

  setSelectedMember(note) {
    if (note && note.show_mention) {
      this.selectMention(this.mentionUsers[this.selectedMemberIndex], 'edit', note);
    } else if (note.showMentionReply) {
      this.selectMention(this.mentionUsers[this.selectedMemberIndex], 'reply', note);
    } else if (note.currentReply && note.currentReply.show_mention) {
      this.selectMention(this.mentionUsers[this.selectedMemberIndex], 'replyedit', note);
    }
  }

  openVideoPopup(item, isUrl?) {
    this.renderer2.addClass(document.body, 'makePopupAttop');
    this.showVideo = true;
    this.vURL = item;
  }

  closeVideoPopup() {
    this.renderer2.removeClass(document.body, 'makePopupAttop');
    this.showVideo = false;
    this.vURL = '';
  }
}
