import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SharedModule } from './../shared/shared.module';
import { ProjectModule } from './../project';
// import { BsDropdownModule } from 'ngx-bootstrap';
// import { StoryTimerModule } from '../shared';
import { NotificationsModule } from '../notifications';
import { HeaderComponent } from './header.component';
import { FormsModule } from '@angular/forms';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AdminModule } from '../admin/admin.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    ProjectModule,
    AdminModule,
    // BsDropdownModule,
    // StoryTimerModule,
    NotificationsModule,
    InfiniteScrollModule,
    FormsModule
  ],
  declarations: [
    HeaderComponent
  ],
  exports: [
    HeaderComponent
  ]
})
export class HeaderModule { }
