<div class="storyBoardPanel-Loader" *ngIf="startLoader">
  <img src="../../assets/images/storyBoardLoader.gif" alt=""/>
</div>

<div class="projectDetail-wrapper" *ngIf="project">
  <div class="topInfo-section">
    <div class="leftSide">
      <div class="aboutProject">
        <span class="aboutProjec-name">{{project?.name}}</span>
        <span class="aboutProject-edit" title="Edit Project" (click)="sharedService.showprojectAdminForm = true"><em class="iconb-edit"></em></span>
        <span class="aboutProject-delete" title="Delete Project" (click)="openProjectDeletePopup()"><em class="iconb-delete-icon"></em></span>
      </div>
      <div class="projectStartDate" [class.text-faded]="valueIsBlank(project.formatted_start_date)">Start Date: {{valueToString(project.formatted_start_date)}}</div>
    </div>
    <div class="rightSide">
      <span class="velocity-section">Default Velocity: {{project.default_velocity}}</span>
      <div class="totalStories">
        <div class="totalStories-count">Total Stories ({{project.stories_count}}) :</div>
        <div class="totalStories-types">
          <div class="storyTypeBlock">
          <span class="ss-type">
            <em class="iconb-task taskIcons"></em>
            <span class="typeCounter">{{ project.story_type?.task?project.story_type.task:'0' }}</span>
          </span>
            <span class="ss-type">
            <em class="iconb-bug bugIcons"></em>
            <span class="typeCounter">{{ project.story_type?.bug?project.story_type.bug:'0' }}</span>
          </span>
            <span class="ss-type">
            <em class="iconb-side-effect sideeffectIcons"></em>
            <span class="typeCounter">{{ project.story_type?.side_effect?project.story_type.side_effect:'0' }}</span>
          </span>
            <span class="ss-type">
            <em class="iconb-chore choreIcons"></em>
            <span class="typeCounter">{{ project.story_type?.chore?project.story_type.chore:'0' }}</span>
          </span>
            <span class="ss-type">
            <em class="iconb-release releaseIcons"></em>
            <span class="typeCounter">{{ project.story_type?.release?project.story_type.release:'0' }}</span>
          </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <common-memberships class="makeRelative" *ngIf="projectId" [projectId]="projectId"></common-memberships>
</div>
<div class="addNewProject-popupWrapper" *ngIf="sharedService.showprojectAdminForm">
  <div class="addNewProject-popUpBackdrop" (click)="sharedService.showprojectAdminForm = false"></div>
  <div class="addNewProject-content">
    <div class="addNewProject-top">Edit Project</div>
    <admin-project-edit></admin-project-edit>
  </div>
</div>


<app-custom-popup   [texts] = "{ptitle: 'Delete', ptext: 'Are you sure you want to delete?', psuccess: 'Delete', pcancel: 'Cancel'}" (closeCustomPopUp) = "closeProjectDeletePopUp()" (deleteElement) = "deleteProject()" [delement] = "elem" [OpenPopUp] = "OpenPopUp" [waitForLoader] = "waitForLoader"></app-custom-popup>
