import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common';
import { SharedModule } from './../shared/shared.module';
// import { PopoverModule } from 'ngx-bootstrap';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NotificationsComponent } from './notifications.component';
import { MemberModule } from '../member/member.module';
import {AdminModule} from '../admin/admin.module';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        InfiniteScrollModule,
        MemberModule,
        AdminModule
    ],
  declarations: [
    NotificationsComponent
  ],
  exports: [
    NotificationsComponent
  ]
})
export class NotificationsModule { }
