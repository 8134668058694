import { Moment } from 'moment';
import moment from 'moment';

export class DateRange {
  from: Moment;
  to: Moment;
  dateFormat = 'DD/MM/YYYY';

  constructor(from: Moment, to: Moment) {
    this.from = from;
    this.to = to;
  }

  get formatedFrom() {
    return this.from.format(this.dateFormat);
  }

  get formatedTo() {
    return this.to.format(this.dateFormat);
  }
}
