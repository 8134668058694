import { Component, OnInit, ViewChild, Output } from '@angular/core';
import { Router } from '@angular/router';
import { each, uniq } from 'lodash';
import { CommonEdit } from '../../common/classes';
import { AdminProjectsService, ROUTES } from '../projects.service';
import { PolicyService } from '../../../shared/services/policy.service';
import { NotificationService } from '../../../shared/services/notification.service';
import { EVENT_TYPES } from '../../../shared/dataTypes';
import { CustomToastService } from '../../../shared/services/custom-toast.service';
import { AdminProjectsFormComponent } from '..';
import { SharedService } from '../../../shared/services/shared.service';
import {ErrorService} from '../../../shared/errorFunction';

@Component({
  selector: 'admin-projects-new',
  templateUrl: 'new.component.html',
  styleUrls: [
    '../../admin.common.scss',
    'new.component.scss'
  ]
})
export class AdminProjectsNewComponent implements OnInit {
  form: any = {
    start_date: new Date()
  };

  errors: any;
  isPending: boolean = false;
  addAnother = false;

  constructor(private projectsService: AdminProjectsService,
              public sharedService: SharedService,
              private router: Router,
              private notificationService: NotificationService,
              private policyService: PolicyService,
              private errorService: ErrorService,
              private customToast: CustomToastService) {
  }

  ngOnInit() {
    // This is intentional
  }

  onSubmit(addAnother?) {
    this.addAnother = addAnother ? addAnother: false;
    if (this.form.name && this.form.start_date) {
      this.isPending = true;
      this
        .projectsService
        .createProject({ project: this.form })
        .subscribe(this.onCreateSuccess.bind(this), this.onError.bind(this));
    }
  }

  onCreateSuccess(response) {
    const payload = {
      data: {
        status: true,
        mode:'add'
      }
    };
    this.policyService.initialPolicies();

    this.isPending = false;
    this.notificationService.broadcast(EVENT_TYPES.SESSION.LOGGED_IN_NEW, payload);
    // this.notificationService.broadcast(EVENT_TYPES.PROJECT.NEW, payload);
    this.onSuccess(response, 'Project');
    // this.toastyRecordCreated('Project');
  }

  onSuccess(response, recordName) {
    //this.toastr.success('New project was created successfully');
    this.customToast.messages.push({id:"newprojectcreated", type:"success", class: "new_project",  title: 'New Project', message: 'New project created successfully'});
    let projectId = response.project.id;
    if(this.addAnother) {
      this.form = {
        start_date: new Date(),
      };
      this.sharedService.showprojectAdminForm = true;
      // this.router.navigate(['admin/projects/new']);
    } else {
      // this.router.navigate([ROUTES.PROJECT(projectId)]);
      this.sharedService.showprojectAdminForm = false;
    }

  }

  onError(response) {
    this.errors = {};
    each(response, (value, key) => {
      this.errors[key] = uniq(value);
    });
    this.isPending = false;
    this.sharedService.showprojectAdminForm = false;
    this.errorService.errorFunction(response);
  }
}
