import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ISessionDialogOptions } from '../../interfaces/session-dialog-options.interface';
import { Project } from '../../models/project';
import { Story } from '../../models/story';

@Component({
  selector: 'app-admin-timing-user-detail-project',
  templateUrl: 'user-detail-project.component.html',
  styleUrls: [
    '../../../admin.common.scss',
    'user-detail-project.component.scss'
  ]
})
export class TimingUserDetailProjectComponent {

  @Input() project: Project;
  @Output() onSessionDialogOpened: EventEmitter<ISessionDialogOptions> = new EventEmitter();

  constructor() {
    // This is intentional
  }

  onCellClicked(story: Story, daysOffet: number): void {
    const eventParams: ISessionDialogOptions = {
      project: this.project,
      story: story,
      daysOffet: daysOffet
    };
    this.onSessionDialogOpened.emit(eventParams);
  }
}
