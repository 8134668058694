<div class="storyCollapseExpand" *ngIf="!projectService.start_storyDetailLoader" (click)="projectService.expandStoryDetailSize()">
  <span *ngIf="!this.projectService.storySizeExpand" class="iconb-open-story">
    <span class="elementHover-extra">Expand</span>
  </span>
  <span *ngIf="this.projectService.storySizeExpand" class="iconb-close-story">
    <span class="elementHover-extra">Collapse</span>
  </span>
</div>

<div class="storyNext iconb-left-2" *ngIf="(story && (projectService.initiate_from === 'from project') && story?.previous?.id && !projectService.start_storyDetailLoader)" (click)="gotoStory(story?.previous?.id)">
  <span class="elementHover-extra">Previous {{story.parent_id ? 'Subtask' : 'Task'}}</span>
</div>
<div class="storyPrevious iconb-right" *ngIf="(story && (projectService.initiate_from === 'from project') && story?.next?.id && !projectService.start_storyDetailLoader)" (click)="gotoStory(story?.next?.id)">
  <span class="elementHover-extra">Next {{story.parent_id ? 'Subtask' : 'Task'}}</span>
</div>

<button type="button" class="close-storyPopup" (click)="cancel()" *ngIf="!projectService.start_storyDetailLoader">
  <em class="iconb-close"></em>
</button>
<div class="storyDetailsWrapper">
  <div class="detailStory-Loader" *ngIf="projectService.start_storyDetailLoader">
    <img src="../../assets/images/storyBoardLoader.gif" alt="loader"/>
  </div>
  <div class="makeFullLoader" *ngIf="storyService.disableStoryChatGPT || disable_story_board"></div>
  <div class="storyTop-block">
    <div class="storyStatus-Info">
      <div class="actionBlock">
        <div class="user-storyStateWrapper singleStoryStatus">
          <div class="user-storyStateDD-selected" *ngIf = "story">
            <div class="pointerNotAllowed" *ngIf="disableStoryStatusChange() && !story.testCase() && (story.state !== 'rejected')"></div>
            <div class="ifReadOnly" *ngIf="story && (story.inDraft() || disable_story_board || isProjectReadonly || story?.inactive || story?.deleted_at || checkBugRelatedAction() || checkForBBStatus())"></div>
            <div class="selected-state" (click)="story.testCase() ? testSuiteFocusDropdown('parent_state') : focusDropDown('parent_state')">
              <span [class]='storyStatusIconSelectedClass(story.state)'></span>
              <span class="stateSelected-state">{{getState(story)}}</span>
              <em class="iconb-dd-down"></em>
            </div>
            <div class="stateSelection-DD" *ngIf="showDropDown && dropDownType==='parent_state'" [ngClass]="{'openStateSelection-DD': showDropDown && dropDownType==='parent_state'}">
              <div class="stateSelection-list" *ngFor="let storyState of getStoryStatesOptions(story)" (click)='changeStoryState(storyState.type)'>
                <div [ngClass] = "{storyFaded: addStoryClass(storyState.type) || (story.checkForMR() && storyState.type==='delivered')}">
                  <span [class]='storyState.icon'></span>
                  <span class="selectedState-name">{{ storyState?.title }}</span>
                </div>
                <ng-container *ngIf="!story.testCase()">
                  <span class="elementHover-horizontal" *ngIf="addStoryClass(storyState.type) && !(story.state === 'unstarted' || story.state === 'unscheduled') && !policyService.isProjectAdmin(project) && (story.timer_elapsed_total > 0)">You are not authorized to perform this action.<br/> Please contact your delivery manager.</span>
                  <span class="elementHover-horizontal" *ngIf="addStoryClass(storyState.type) && (storyState.type==='started' || storyState.type==='finished' || storyState.type==='delivered')">You are not authorized to perform this action as only<br/> owner can change the status from unstarted to delivered.</span>
                  <span class="elementHover-horizontal" *ngIf="addStoryClass(storyState.type) && (storyState.type==='accepted' || storyState.type==='rejected') && !(story.epics[0]?.bb_status === 'ready_for_certification' || story.epics[0]?.bb_status === 'bb_under_development')">You are not authorized to perform this action as <br/> only reviewer can accept or reject the story.</span>
                  <span class="elementHover-horizontal" *ngIf="addStoryClass(storyState.type) && story.created_from !== 'delivery' && (storyState.type==='accepted' || storyState.type==='rejected') && (story.dev_type === 'FE' || story.dev_type === 'BE') && (story.epics[0]?.bb_status === 'ready_for_certification' || story.epics[0]?.bb_status === 'bb_under_development')">Story cannot be accepted and rejected as story is<br/> ready for certification.</span>
                  <span class="elementHover-horizontal" *ngIf="(story.state !== 'delivered' && story.state !== 'accepted' && story.state !== 'rejected') && (storyState.type==='accepted' || storyState.type==='rejected')">A story can transition to accepted or rejected state only<br/> after it's delivered.</span>
                  <span class="elementHover-horizontal" *ngIf="(loggedInUser.id === story.owned_by_id) && story.checkForMR() && (storyState.type==='delivered')">Story cannot be delivered as valid<br/> MR is not attached. </span>
                </ng-container>
                <span class="elementHover-horizontal" *ngIf="story && story.testCase() && loggedInUser.id !== story.owned_by_id">You are not authorized to perform this action as only<br/> executor can change the status.</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="is_ready_or_draft" *ngIf="story && story.story_type !== 'bug' && story.status && project.dashboard_project_id && !story.testCase()">
        {{ story.status === 'ready' ? 'Ready' : 'Draft' }}
        <div class="alert-forCore">
          <img src="../../../assets/images/info-icon.png" alt="info" />
          <div class="elementHover">{{ story.status === 'ready' ? 'Story is ready to start' :
            (!story.parent_id ? 'Story is in Draft mode because Acceptance Criteria or Description is missing.' :
            'Subtask is in Draft mode because its parent is in Draft mode.') }}</div>
        </div>
      </div>
      <div class="storyStatusInfo-label" *ngIf="story && !story.testCase()">
        <!--for developer:- info icon for Freeze status dropdown and add info icon- Starts  -->
        <div class="statusInfo" *ngIf="story && (disableStoryStatusChange() || !checkCoreStoryAcceptance() || checkBugRelatedAction() || checkForBBStatus())">
          <!--<em class="iconb-info-extra infoHoverIcon" (click)="showStatusInfo()"></em>-->
          <span class="infoHoverIcon"><img src="../../../assets/images/info-icon.png" alt="info" /></span>
          <span class="infoHover" *ngIf="checkBugRelatedAction()">{{ story.bugReasonTooltip() }}</span>
          <span class="infoHover" *ngIf="disableStoryStatusChange()">Story status cannot be changed as BB is in the {{ sharedService.formatBBStatus(story.getEpicInStory()?.bb_status) }} state for the feature ({{ story.getEpicInStory()?.title }})</span>
          <span class="infoHover" *ngIf="checkForBBStatus()">Custom stories for a feature cannot be started unless all core stories are archived or accepted.</span>
          <span class="infoHover" *ngIf="!checkCoreStoryAcceptance()">Story cannot be accepted as BB is in the {{ sharedService.formatBBStatus(story.getEpicInStory()?.bb_status) }} state for the feature ({{ story.getEpicInStory()?.title }})</span>
        </div>
        <!--for developer:- info icon for Freeze status dropdown and add info icon- Ends  -->
        <div class="iconb-uiwdesign uiw-desginIcon" [ngClass]="[story.design_status]"  *ngIf="project && project.dashboard_project_id && story && story.phase && story.phase === 'Design'">
          <span class="elementHover">Design Status : {{ story.design_status ? story.design_status.charAt(0).toUpperCase() + story.design_status.slice(1) : 'Awaited' }}</span>
        </div>
        <div class="iconb-uiwsketch uiw-sketchIcon" [ngClass]="[story.sketch_status ? story.sketch_status : '']" *ngIf="project && project.dashboard_project_id && story && story.phase && story.phase === 'Design'">
          <span class="elementHover">Sketch Status : {{ getSketchStatus() }}</span>
        </div>
      </div>
    </div>
    <div class="storyActionButtonWrapper" *ngIf = "story">
      <div class="ifInactive-text" *ngIf="story?.inactive">Inactive</div>
      <div class="ifSync-active" *ngIf="story?.sync_available && policyService.projectAdmin(project) && !checkRestriction()" (click)="openStoryCompare()">
        Preview
        <div class="alert-forSync">
          <img src="../../../assets/images/info-icon.png" alt="info" />
          <div class="elementHover">A new update is available for the story.<br/> Kindly review and synchronize.</div>
        </div>
      </div>
      <div class="isCustomOrCore" *ngIf="story && project && project.dashboard_project_id  && !story.testCase()">
        {{ story.core ? 'Core' : 'Custom' }}
        <div class="alert-forCore" *ngIf="story.core">
          <img src="../../../assets/images/info-icon.png" alt="info" />
          <div class="elementHover">Core constitutes the reusable component in a feature. <br/>So ensure the description of this user story represent the <br/>building blocks of the feature that will be part <br/>of every application.</div>
        </div>
        <div class="alert-forCore" *ngIf="!story.core">
          <img src="../../../assets/images/info-icon.png" alt="info" />
          <div class="elementHover">Custom constitutes the specific requirements for a <br/>feature for the application its being built for. This could <br/>include placeholder texts, theme, colour, font, error messages,<br/> branding information etc.</div>
        </div>
      </div>
      <button class="forCopyLink popover-activate" (click)="copyStoryLink()" placement="bottom" [clip]="story?.title + ' - ' + storyUrl">
        <em class="iconb-copyid"></em>
        <span class="elementHover"> {{showCopiedLinked ? 'Copied':'Copy Link'}}</span>
      </button>

      <div class="moreAction">
        <em class="iconb-more-vert toOpen" (click)="openMoreAction()"></em>
        <span class="elementHover" *ngIf="!showExtraOptions">More Actions</span>
        <div class="moreAction-wrapper" [@DDowner] *ngIf="showExtraOptions">
          <button type="button" class="moreAction-list forCopy" *ngIf = "!story.testCase()" (click)="openCloneConfirmation()" [disabled]="story?.deleted_at || story?.inactive || (story.wireframe && story.core) || (story.phase === 'Product Roadmap' && story.core)">
            <em class="iconb-duplicate-outline"></em>
            <span class="list-title">Duplicate Story</span>
          </button>
          <button class="moreAction-list forCreate-subTask" (click)="toggle_comment_history('history')" [disabled]="story && story?.deleted_at">
            <img src="../../../assets/images/cursor-double-click.svg" alt="loader"/>
            <span class="list-title">Show Activity</span>
          </button>
          <button class="moreAction-list forSketch-download" *ngIf="project?.dashboard_project_id && (story.phase !== 'Product Roadmap') && (story.phase !== 'Design') && (story.phase !== 'Prototype') && !story.testCase()" (click)="downloadSketchDoc()" [disabled]="story && (isProjectReadonly || story?.epics.length === 0 || story?.inactive || story?.deleted_at )">
            <em class="iconb-download-sketch"></em>
            <span class="list-title">Download Sketch</span>
          </button>
          <button class="moreAction-list forRequest" [disabled]="!story?.reviewed_enabled_at || !policyService.projectAdmin(project)" (click)="storyReviewConfirmationPopup()">
            <em class="iconb-brief"></em>
            <span class="list-title">Request for Review</span>
            <div class="statusInfo" *ngIf="story?.request_for_review_count > 0">
              <span class="infoHoverIcon"><img src="../../../assets/images/info-icon.png" alt="info" /></span>
              <span class="infoHover" >{{(story?.request_for_review_count)}} {{(story.request_for_review_count === 1) ?  'time':'times'}} request is already raised from this project for this story.</span>
            </div>
          </button>
          <button class="moreAction-list forSketch-download" *ngIf="project?.dashboard_project_id && (story.phase === 'Design') && !story.isTestCaseOrBug() && ['CPE','SCPE','Associate Productologist', 'Tribe Lead - Productologist'].includes(projectService.current_user_pod_role)" (click)="triggerDesignThreshold()" [disabled]="story && (isProjectReadonly || story?.epics.length === 0 || story?.inactive || story?.deleted_at || story?.sketch_status !== 'sketch_accepted' || story?.state === 'accepted' || story?.state === 'rejected')">
            <em class="iconb-edit-tick-pointer"></em>
            <span class="list-title">Design Threshold Met</span>
          </button>
          <ng-container *ngIf="story && !story.deleted_at && story.checkTestCaseMemberPermission(loggedInUser) && (!story.checkE2EStory() || projectService.checkBBStatusSkipped(story))">
            <button type="button" class="moreAction-list forDelete" (click)="destroy()" [disabled]="story?.inactive || (story.wireframe && story.parent_id === null) || (story.core && story.phase === 'Product Roadmap' && story.parent_id === null)">
              <em class="iconb-bin"></em>
              <span class="list-title">{{story.testCase() ? 'Delete' : 'Archive'}}</span>
            </button>
          </ng-container>
          <button type="button" class="moreAction-list forDelete" (click)="showUnarchivePopup()" *ngIf="story && story.deleted_at && !story?.inactive">
            <em class="iconb-unarchive"></em>
            <span class="list-title">Unarchive</span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div id="storyDIVScroll" #checkScroll (scroll)="onScroll($event)" class="storyDetails-container" *ngIf = "story">
    <div class="topContent-wrapper">
      <div class="totalTimeAndprogress-wrapper" [ngClass]="{'no-timer': story.testCase()}">
        <div class="inputBlocks">
          <div class="actionBlock">
            <ng-container *ngIf="!story.testCase()">
            <div class="time-title">Total Time</div>
            <div class="totalEllapsedTime">
              <div class="timerProgressBar" *ngIf = "story">
                <span class="progressMade" [style.width.%]="story.time_completed" [ngStyle]="story.time_completed === 101 && {'background-color': '#FF4D4F'}"></span>
              </div>
              <story-timer *ngIf = "story" [project] = "project" class="storyEllapsedTime-timer" [type] = "'total'" [story]="story" [validateTimer]="false"></story-timer>
            </div>
            <span class="hrs-text2">hrs remaining</span>
          </ng-container>
            <span class="storyCreatedON">Created on : {{getTimeFormat(story?.created_at)}}</span>
          </div>
        </div>
      </div>
      <div class="storyTitle-block" *ngIf="story && projectService.render_storyDetails" [ngClass]="{'pleaseSelect2': story && !story.title}">
        <div class="storyTitle-wrapper" *ngIf = "story">
          <div class="storyLocation" *ngIf="story && story.parent_id && story.list_id">
            <a (click)="goToParent()" [ngStyle]="{'cursor': 'pointer'}">{{this.parent_story?.title}}</a> 
            <span *ngIf="story && story.story_type !== 'test_case' && story.storyColumnName(story.list_id)"> / in {{ story.storyColumnName(story.list_id) }}</span>
          </div>
          <textarea #titleDim maxlength="200" autosize [disabled] = "checkRestriction() || validateStoryPermission(null, 'title') || (story.wireframe && story.parent_id === null)" type="text" placeholder="Enter Story Title" class="storyTitle-input" [ngClass]="{'addScroll': addScroll}"
                    (focusout)="updateTitle()" [(ngModel)]="story.title" #titleInput="ngModel"  (input)="getDimension()"></textarea>
          <div *ngIf="titleInput.errors && (titleInput.dirty)" class="title-error">
            <div [hidden]="!titleInput.errors.required">Enter story title</div>
          </div>
          <span class="elementHover-table" *ngIf="validateStoryPermission(null, 'title')">You are not authorized to perform this action.<br/> Please contact your delivery manager.</span>
          <!--<div class="storyLocation" *ngIf="story && !story.parent_id && story.list_id">in <span style="font-weight: 500;">{{ story.storyColumnName(story?.list_id) }}</span></div>-->
        </div>
      </div>

      <div class="contentWrapper addMargin" *ngIf="projectService.render_storyDetails">

        <div class="write-with-ai" *ngIf="(!story.parent_id && (story.story_type === 'task') && !(checkRestriction() || validateStoryPermission()))">
          <div class="ai-header">
            <span class="ai-title">Use AI Co-pilot</span>
            <div class="btns-wrapper">
              <ng-container *ngIf="!aiInputBlock">
                <div class="ai-btn lg-btn" (click)="aiInputBlock = true">
                  <em class="btn-icon natasha-icon">
                    <img src="../../../assets/images/natasha-icon.svg" alt="">
                  </em>
                  Write with Natasha
                </div>
              </ng-container>
              <ng-container *ngIf="aiInputBlock">
                <button class="ai-btn" [disabled]="(aiInput.length === 0) || (aiInput.length !== 0 && storyService.canDisableSaveFlag)" (click)="checkReasonAndSave()">Save</button>
                <button class="ai-btn" [disabled]="(aiInput.length === 0) || (aiInput.length !== 0 && storyService.canDisableResetFlag)" (click)="resetStory()">Reset</button>
              </ng-container>
            </div>
          </div>
          <div class="ai-input-wrapper" *ngIf="aiInputBlock">
            <input type="text" class="ai-input" [disabled]="storyService.chatGPTLoader" placeholder="Please write a user story for" [(ngModel)]="aiInput" (keyup.enter)="getChatGptResponse()">
            <button [ngClass]="{'iconb-send-thick':!storyService.chatGPTLoader,'send-btn':!storyService.chatGPTLoader,'loader':storyService.chatGPTLoader} " [disabled]="aiInput.length === 0" (click)="getChatGptResponse()" title="Send"></button>
          </div>
        </div>

        <div class="labelText forDes">{{story.story_type === 'bug' ? 'Steps to reproduce' : 'Description'}}
          <div class="alert-subtask-info" *ngIf="story && story.subTask() && story.story_type !== 'bug'">
            <span class="infoHoverIcon"><img src="../../../assets/images/info-icon.png" alt="info" /></span>
            <span class="infoHover">Description and Acceptance Criteria can be edited on the Parent story level i.e. User story level.</span>
          </div>
        </div>
        <div class="description-format-wrapper" *ngIf="story && story.story_type !== 'bug' && !story.testCase()">
          <div class="format-type-list">
            <div class="format-type-title">As a</div>
            <div class="format-type-description">
              <div class="format-inout if-no-as-a" *ngIf="story.parent_id">{{story.description_as_a}}</div>
              <textarea *ngIf="!story.parent_id" 
              class="format-inout"
              name="as_a" 
              (input)="keepEditingAlive('description_format_editing', story.description_format_edited)" 
              (focusout)="updateStoryFormat(story.description_as_a, story.old_story_as_a)" 
              [(ngModel)]="story.description_as_a" 
              [disabled]="checkRestriction() || validateStoryPermission() || (story.wireframe && story.parent_id === null)" 
              autosize 
              placeholder="<type of user>"></textarea>
              <span class="elementHover-table" *ngIf="validateStoryPermission()">You are not authorized to perform this action.<br/> Please contact your delivery manager.</span>
            </div>
          </div>
           <div class="format-type-list">
            <div class="format-type-title">I want</div>
            <div class="format-type-description">
              <div class="format-inout if-no-i-want" *ngIf="story.parent_id">{{story.description_i_want}}</div>
              <textarea *ngIf="!story.parent_id"
              class="format-inout" 
              name="i_want" 
              (input)="keepEditingAlive('description_format_editing', story.description_format_edited)" 
              (focusout)="updateStoryFormat(story.description_i_want, story.old_story_i_want)" 
              [(ngModel)]="story.description_i_want" 
              [disabled]="checkRestriction() || validateStoryPermission() || (story.wireframe && story.parent_id === null)"  
              autosize 
              placeholder='<perform some task>'></textarea>
              <span class="elementHover-table" *ngIf="validateStoryPermission()">You are not authorized to perform this action.<br/> Please contact your delivery manager.</span>
            </div>
          </div>
           <div class="format-type-list">
            <div class="format-type-title">So that</div>
            <div class="format-type-description">
              <div class="format-inout if-no-so-that" *ngIf="story.parent_id">{{story.description_so_that}}</div>
              <textarea *ngIf="!story.parent_id"
              class="format-inout" 
              name="so_that" 
              (input)="keepEditingAlive('description_format_editing', story.description_format_edited)" 
              (focusout)="updateStoryFormat(story.description_so_that, story.old_story_so_that)" 
              [(ngModel)]="story.description_so_that" 
              [disabled]="checkRestriction() || validateStoryPermission() || (story.wireframe && story.parent_id === null)"  
              autosize 
              placeholder='<achieve some goal>'></textarea>
              <span class="elementHover-table" *ngIf="validateStoryPermission()">You are not authorized to perform this action.<br/> Please contact your delivery manager.</span>
            </div>
          </div>
        </div>
        <span class="labelText addtional-note" *ngIf="story.story_type !== 'bug' && !story.testCase()">{{story.parent_id ? 'Task Description':'Additional Notes'}}</span>
        <div *ngIf="descriptionInEditMode" class="descriptionInput">
          <ckeditor [config]="editor_options" [editor]="editDescriptionEditor" [(ngModel)]="story.description"  (ready)="onEdit($event)"
                    [disabled] = "checkRestriction() || validateStoryPermission(null, 'description') || (story.wireframe && story.parent_id === null)"></ckeditor>
        </div>
        <div *ngIf="!descriptionInEditMode && !isUrlClickedInDesc" class="descriptionView" [ngClass]="{'coreRestriction': story && story.core}">
          <ckeditor [config] = "view_editor_options" [editor]="viewDescriptionEditor" [(ngModel)]="story.description" (ready)="onView($event)"
                    (click)="startDescriptionEditing($event)" [disabled]="story && (checkRestriction() || validateStoryPermission(null, 'description') || (story.wireframe && story.parent_id === null))"></ckeditor>
        </div>
      </div>
      <div class="rightSideWrapper">
        <div class="story-detail-form">
          <div class="inputBlocks2">
            <div class="fullSize-label">ID</div>
            <div class="actionBlock">
              <div class="storyTypeDD-selected">
                <div class="selected-type" style="cursor: initial;">
                  <span class="storyId-i">{{story.id}}</span>
                  <em class="iconb-copyid" (click)="copyStoryID()" [clip]="story.id">
                    <span class="elementHover">{{showCopiedID ? 'Copied' : 'Copy ID'}}</span>
                  </em>
                </div>
              </div>
            </div>
          </div>
          <div class="inputBlocks2" *ngIf="story.isTestCaseOrBug() && story.test_case_id">
            <div class="fullSize-label">Test case ID</div>
            <div class="actionBlock">
              <div class="storyTypeDD-selected">
                <div class="selected-type" style="cursor: initial;">
                  <span class="storyId-i">{{story.story_type === 'bug' ? story.test_case_id : story.id}}</span>
                  <em class="iconb-copyid" (click)="copyStoryID()" [clip]="story.story_type === 'bug' ? story.test_case_id : story.id">
                    <span class="elementHover">{{showCopiedID ? 'Copied' : 'Copy ID'}}</span>
                  </em>
                </div>
              </div>
            </div>
          </div>
          <div class="inputBlocks2" *ngIf="story.isTestCaseOrBug() && story.parent_id">
            <div class="fullSize-label">Parent Story ID</div>
            <div class="actionBlock">
              <div class="storyTypeDD-selected">
                <div class="selected-type" style="cursor: initial;">
                  <span class="storyId-i">{{story.parent_id}}</span>
                  <em class="iconb-copyid" (click)="copyStoryID()" [clip]="story.parent_id">
                    <span class="elementHover">{{showCopiedID ? 'Copied' : 'Copy ID'}}</span>
                  </em>
                </div>
              </div>
            </div>
          </div>
          <div class="inputBlocks2">
            <div class="fullSize-label">Type
              <div class="statusInfo" *ngIf="story && story.core && story.phase == 'Product Roadmap' && (story.wireframe && story.parent_id === null)">
                <span class="infoHoverIcon"><img src="../../../assets/images/info-icon.png" alt="info" /></span>
                <span class="infoHover">This field cannot be updated for this story</span>
              </div>
            </div>
            <div class="actionBlock">
              <div class="storyTypeDD-selected">
                <div class="ifReadOnly" *ngIf="story && (checkRestriction() || story.testCase() || policyService.isProjectUser(project) || story.core || (story.wireframe && story.parent_id === null))"></div>
                <span class="selected-type" (click)="focusDropDown('story_type')">
                  <em class="iconb-dd-down"  *ngIf="!story.testCase()"></em>
                  <em [class]="storyTypeIcon(story?.story_type, story?.parent_id !== null)" *ngIf="!story.testCase()"></em>
                  <span class="selectedType-name">{{(story?.story_type === 'task' && story?.parent_id)? 'Sub Task' : (story?.story_type === 'task'? 'Story': story.testCase() ? 'Test Case': titleCase(story?.story_type)) }}</span>
                </span>
                <span class="elementHover-table" *ngIf="policyService.isProjectUser(project)">You are not authorized to perform this action.<br/> Please contact your delivery manager.</span>
                <div class="storySelection-DD" [ngClass]="{'openDD': showDropDown && dropDownType==='story_type'}">
                  <div class="storySelection-list" *ngFor="let storyType of storyTypeOptions" (click)='changeStoryType(storyType.type)'>
                    <em [ngClass]="[storyTypeIcon(storyType.type, story?.parent_id !== null) , 'make-left']"></em>
                    <span class="selectedType-name">{{(storyType?.title === 'Story' && story?.parent_id)? 'Sub Task' : storyType?.title }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="inputBlocks2" *ngIf="story?.story_type === 'bug'">
            <div class="fullSize-label">Bug Reason</div>
            <div class="actionBlock">
              <div class="storyTypeDD-selected">
                <div class="ifReadOnly" *ngIf="story && (checkRestriction() || validateStoryPermission() || (story.core && story.phase == 'Product Roadmap') || (story.wireframe && story.parent_id === null))"></div>
                <span class="selected-type" (click)="focusDropDown('bug_reason_id')">
                  <em class="iconb-dd-down"></em>
                  <span class="selectedType-name priorityName">{{ story.bugReason() }}</span>
                </span>
                <span class="elementHover-table" *ngIf="validateStoryPermission()">You are not authorized to perform this action.<br/> Please contact your delivery manager.</span>
                <div class="storySelection-DD" [ngClass]="{'openDD': showDropDown && dropDownType==='bug_reason_id'}">
                  <div class="storySelection-list" *ngFor="let bugReason of project?.mainBugReasons" (click)="changeBugReason(bugReason?.id, 'bug_reason')">
                    <span class="selectedType-name">{{ bugReason.title }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="inputBlocks2" *ngIf="story?.story_type === 'bug'">
            <div class="fullSize-label">Bug Reason Detail</div>
            <div class="actionBlock">
              <div class="storyTypeDD-selected">
                <div class="ifReadOnly" *ngIf="story && (checkRestriction() || validateStoryPermission() || (story.core && story.phase == 'Product Roadmap') || (story.wireframe && story.parent_id === null))"></div>
                <span class="selected-type" (click)="focusDropDown('detail_bug_reason_id')">
                  <em class="iconb-dd-down"></em>
                  <span class="selectedType-name priorityName">{{ story.bugDetailReason() }}</span>
                </span>
                <span class="elementHover-table" *ngIf="validateStoryPermission()">You are not authorized to perform this action.<br/> Please contact your delivery manager.</span>
                <div *ngIf="story.bug_reason_id" class="storySelection-DD" [ngClass]="{'openDD': showDropDown && dropDownType === 'detail_bug_reason_id'}">
                  <div class="storySelection-list" *ngFor="let detailbugReason of project?.detailedBugReason(story.bug_reason_id)" (click)="changeBugReason(detailbugReason?.id, 'detail_reason')">
                    <span class="selectedType-name">{{ detailbugReason.title }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="inputBlocks2">
            <div class="fullSize-label">Priority
              <div class="statusInfo" *ngIf="story && story.core && story.phase == 'Product Roadmap' && (story.wireframe && story.parent_id === null)">
                <span class="infoHoverIcon"><img src="../../../assets/images/info-icon.png" alt="info" /></span>
                <span class="infoHover">This field cannot be updated for this story</span>
              </div>
            </div>
            <div class="actionBlock">
              <div class="storyTypeDD-selected">
                <div class="ifReadOnly" *ngIf="story && (checkRestriction() || validateStoryPermission() || (story.core && story.phase == 'Product Roadmap') || (story.wireframe && story.parent_id === null))"></div>
                <span class="selected-type" (click)="focusDropDown('priority')">
                  <em class="iconb-dd-down"></em>
                  <span class="selectedType-name priorityName">{{ story.priority }}</span>
                </span>
                <span class="elementHover-table" *ngIf="validateStoryPermission()">You are not authorized to perform this action.<br/> Please contact your delivery manager.</span>
                <div class="storySelection-DD" [ngClass]="{'openDD': showDropDown && dropDownType==='priority'}">
                  <div class="storySelection-list" *ngFor="let priority of storyPriorityOptions" (click)='changeStoryPriority(priority.type)'>
                    <span class="selectedType-name">{{ priority.type }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="inputBlocks2" *ngIf="story  && project.studio_roles && (project.studio_roles.length > 0) && !(story.parent_core && story.studio_role_id === null) && !(story.core && story.created_from === 'catalog' && story.phase !== 'Design') && story.phase !== 'Product Roadmap'">
            <div class="fullSize-label">Role</div>
            <div class="actionBlock">
              <div class="storyTypeDD-selected" *ngIf="story && (story.parent_id || story.testCase())">
                <div class="ifReadOnly" *ngIf="story && (checkRestriction() || validateStoryPermission() || (story.core && story.phase === 'Design') )"></div>
                <span class="selected-type" (click)="focusDropDown('studio_role')" style="cursor: auto">
                  <span class="selectedType-name" style="padding-left: 3px">{{story?.studio_role_id ? story.studioRoleMap() : 'Please select role from parent story'}}</span>
                </span>
              </div>
              <div class="storyTypeDD-selected" *ngIf="story && !story.parent_id && !story.testCase()">
                <div class="ifReadOnly" *ngIf="story && (checkRestriction() || validateStoryPermission() || (story.core && story.phase === 'Design' && story?.studio_role_id))"></div>
                <span class="selected-type" (click)="focusDropDown('studio_role')">
                  <em class="iconb-dd-down"></em>
                  <span class="selectedType-name" style="padding-left: 3px">{{story?.studio_role_id ? story.studioRoleMap() : 'Select Role'}}</span>
                </span>
                <span class="elementHover-table" *ngIf="validateStoryPermission()">You are not authorized to perform this action.<br/> Please contact your delivery manager.</span>
                <div class="storySelection-DD" [ngClass]="{'openDD': showDropDown && dropDownType==='studio_role'}">
                  <div class="storySelection-list" *ngFor="let st_role of project.studio_roles" (click)='changeStoryStudioRole(st_role.studio_role.id)'>
                    <span class="selectedType-name">{{ st_role?.studio_role.name }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="inputBlocks2" *ngIf="story && checkAvailailtyOfBBReadyCheck() && !story.testCase()">
            <div class="fullSize-label">Ready for Certification
              <div class="statusInfo">
                <span class="infoHoverIcon"><img src="../../../assets/images/info-icon.png" alt="info" /></span>
                <span class="infoHover">Sensei can send story for certification only when both the story itself and its parent end-to-end user story have been marked as delivered.</span>
              </div>
            </div>
            <div class="actionBlock">
              <div class="bb-readyForCertificateCheck">
                <span class="iconb-checklist veiw-checklist" (click)="checkBBReady(null, true)">
                  <span class="elementHover">View Checklist</span>
                </span>
                <label class="customCheckForStorySelection">
                  <input type="checkbox" [disabled] = "checkDisaleForBBReady() || story.ready_for_certification" class="ifNotSelected"  [(ngModel)] = "story.ready_for_certification" (change)="checkBBReady($event, false)" [ngClass]="{'story-selected-checkbox': story.ready_for_certification}" hidden/>
                  <span class="check-toggle"></span>
                  <span class="elementHover-table" *ngIf="checkAllSubtaskDelivered()">Story cannot be sent for certification until all <br/> the subtasks are delivered.</span>
                  <span class="elementHover-table" *ngIf="!story?.demo_video_url">Story certification requires a demo video. Please add a demo video <br/> before proceeding with the certification process. </span>
                </label>
              </div>
            </div>
          </div>

          <div class="inputBlocks2" *ngIf="story && !story.parent_id && !story.testCase()">
            <div class="fullSize-label">Phase
              <div class="statusInfo" *ngIf="story && story.core && story.phase == 'Product Roadmap'">
                <span class="infoHoverIcon"><img src="../../../assets/images/info-icon.png" alt="info" /></span>
                <span class="infoHover">This field cannot be updated for this story</span>
              </div>
            </div>
            <div class="actionBlock">
              <div class="storyReqDD-selected">
                <div class="ifReadOnly" *ngIf="story && (checkRestriction() || validateStoryPermission() || story.core || story.wireframe)" (click)="showRestrictionError()"></div>
                <span class="selected-name"  [ngStyle]="{'cursor': 'pointer'}" (click)="focusDropDown('phases')">
                  <em [ngClass]="['iconb-' + story.storyPhaseIcon(story.phase)]" *ngIf="story.phase"></em>
                  <span class="selectedReq-name">{{(story.phase ? story.phase : 'Select phase') }}</span>
                  <em class="iconb-dd-down"></em>
                </span>
                <span class="elementHover-table" *ngIf="validateStoryPermission()">You are not authorized to perform this action.<br/> Please contact your delivery manager.</span>
                <div class="reqSelection-DD" *ngIf="story && !story.core" [ngClass]="{'openReqDD': showDropDown && dropDownType==='phases'}">
                  <div class="storyReqSelection-list" *ngFor="let phase of phases" (click)='changeStoryPhase(phase)'>
                    <em [ngClass]="['iconb-' + story.storyPhaseIcon(phase), 'make-left']"></em>
                    <span class="selectedType-name">{{ phase }}</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="wireframeLabel-wrapper" *ngIf="story.wireframe">
              <div class="wireframeLabel">
                <span class="iconb-flow2"></span>
                Wireframe
                <span class="alert-wireframeInfo" >
              <img src="../../../assets/images/info-icon.png" alt="info" />
                <span class="elementHover">This is a wireframe story.</span>
              </span>
              </div>
            </div>
          </div>

          <div class="inputBlocks3" *ngIf="story && story.parent_id && !story.testCase()">
            <div class="fullSize-label">Sub Phase</div>
            <div class="actionBlock">
              <div class="storyReqDD-selected">
                <div class="ifReadOnly" *ngIf="story && (checkRestriction() || validateStoryPermission() || story.wireframe)"></div>
                <span class="selected-name" [ngStyle]="{'cursor': 'pointer'}" (click)="focusDropDown('tracker_phases')">
                  <em [ngClass]="['iconb-' + story.storyPhaseIcon(story.task)]" *ngIf="story.task"></em>
                  <span class="selectedReq-name">{{(story.task ? story.task : 'Select Sub-phase')}}</span>
                  <em class="iconb-dd-down"></em>
                </span>
                <span class="elementHover-table" *ngIf="validateStoryPermission()">You are not authorized to perform this action.<br/> Please contact your delivery manager.</span>
                <div class="reqSelection-DD" [ngClass]="{'openReqDD': showDropDown && dropDownType==='tracker_phases'}">
                  <div class="storyReqSelection-list" *ngFor="let phase of filtered_tracker_phases" (click)='changeStoryTracker_Phase(phase)'>
                    <em [ngClass]="['iconb-' + story.storyPhaseIcon(phase.tracker_phase_name), 'make-left']"></em>
                    <span class="selectedType-name">{{ phase.tracker_phase_name }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="wireframeLabel-wrapper" *ngIf="story.wireframe">
              <div class="wireframeLabel">
                <span class="iconb-flow2"></span>
                Wireframe
                <span class="alert-wireframeInfo" >
              <img src="../../../assets/images/info-icon.png" alt="info" />
                <span class="elementHover">This is a wireframe story.</span>
              </span>
              </div>
            </div>
          </div>

          <!-- For Developers:- Devices and Plateform selection block Starts   -->
          <div class="inputBlocks3" *ngIf="story && story.project && story.project.hasProjectPlatforms()" style="border-bottom: none;">
            <div class="fullSize-label">Devices and Platforms
              <span class="alert-taskExecution" *ngIf="story.alertForPlatformAndDevice()">
              <img src="../../../assets/images/info-icon.png" alt="info" />
                <span class="elementHover">{{story.parent_id ? 'Devices and platform can only be edited on parent story only.' : 'Maximum one device can be chosen in a design/prototype phase.'}}</span>
              </span>
            </div>
            <ng-container *ngIf="story.story_type !== 'test_case'">
              <div class="actionBlock deviceAndPlatform" *ngIf="!(story.parent_id && parent_story)">
                <div class="storyReqDD-selected" [ngClass]="{'makeLayerUp': showDropDown && dropDownType ==='deviceAndPlatform'}">
                  <div class="ifReadOnly" *ngIf="story && (story.checkDevicePlatformRestriction() || checkRestriction() || validateStoryPermission() || story.wireframe)"></div>
                  <span class="selected-name"  [ngStyle]="{'cursor': 'pointer'}" (click)="focusDropDown('deviceAndPlatform')">
                    <em [ngClass]="['iconb-devices-platefrom']"></em>
                    <span class="selectedDevicePlatform-name">{{story?.selectedDevicePlatform}}</span>
                    <em class="iconb-dd-down"></em>
                  </span>
                  <span class="elementHover-table" *ngIf="validateStoryPermission()">You are not authorized to perform this action.<br/> Please contact your delivery manager.</span>
                  <div class="reqSelection-DD forDevicePlatform" [@DDpAni] *ngIf="showDropDown && dropDownType==='deviceAndPlatform'" [ngClass]="{'openReqDD': showDropDown && dropDownType==='deviceAndPlatform'}">
                    <div class="selectDeviceList-wrapper" *ngFor="let devicePlatformData of story.project.projectDevicePlatformsData; index as i;">
                      <div class="deviceType">
                        <div class="moveSelection-check" *ngIf="!story.isCreatedFromDelivery(story.created_from)">
                          <label class="customCheckFor-moveSelection" for="{{'deviceSelect_' + i}}">
                            <input type="radio" class="move-selected-checkbox" id="{{'deviceSelect_' + i}}" (change)="toggleDeviceSelection($event,devicePlatformData)" name="selectDevice" [checked]="story.isAllPlatformChecked(devicePlatformData)" hidden/>
                            <span class="check-toggleMoveSelection at-device-block" name="device-check"></span>
                          </label>
                        </div>
                        <em [ngClass]="[devicePlatformData.device ? 'iconb-' + devicePlatformData.device : 'iconb-mobile']"></em> 
                        {{devicePlatformData.device}}
                      </div>
                      <div class="storyReqSelection-list" *ngFor="let platform of devicePlatformData.platforms">
                        <div class="moveSelection-check">
                          <label class="customCheckFor-moveSelection" [ngClass]="{'disabled':(story.isPlatformDetached(platform) || story.freezePlatform(devicePlatformData))}">
                            <input type="checkbox" class="move-selected-checkbox" [disabled]="story.isPlatformDetached(platform) || story.freezePlatform(devicePlatformData)" (change)="toggleDevicePlatform($event, platform?.id, devicePlatformData)" [checked]="isPlatformChecked(platform?.id)" hidden/>
                            <span class="check-toggleMoveSelection"></span>
                          </label>
                          <ng-container *ngIf="story.isPlatformDetached(platform) || story.checkForPlatformMessage(platform)">
                            <span class="elementHover">
                              {{ story.isPlatformDetached(platform) ? platform?.display_name + ' platform cannot be tagged to this story again.' : 'Do you want to create a separate parent story for the ' + platform?.display_name + ' platform?' }}
                            </span>
                          </ng-container>
                        </div>
                        <span class="selectedType-name">{{ platform?.display_name }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
            
            <div class="actionBlock deviceAndPlatform" *ngIf="story && !story.hasStoryPlatforms && story.parent_id && (parent_story || story.testCase())">
              <div class="storyReqDD-selected">
                <span class="selected-name">No platform is selected</span>
              </div>
            </div>
          </div>
          <!-- For Developers:- Devices and Plateform selection block Ends -->

          <div class="inputBlocks3" [ngClass]="{'goUp' : (this.story.parent_id && (this.parent_story || story.testCase()))}"  *ngIf="story && story.hasStoryPlatforms" style="border-bottom: none;">
            <div class="selectedDeviceList-wrapper">
              <div class="selectedDeviceList" *ngFor="let devicePlatformData of story.storyDevicePlatformsData">
                <div class="ifReadOnly" *ngIf="story && (checkRestriction() || validateStoryPermission() || story.wireframe)"></div>
                <div class="deviceType">{{devicePlatformData.device}}</div>
                <div class="selectedDevicePlatform-type" *ngFor="let platform of devicePlatformData.platforms">
                  <em class="iconb-flow2"></em>
                  {{ platform?.display_name }}
                </div>
              </div>
            </div>
          </div>
          
          <!-- For Developers:- Devices and Plateform selected Options block Ends -->

          <!--devtype block Starts -->
          <div class="inputBlocks3" style="border-top: 1px solid rgba(0,0,0,.1);" *ngIf = "!story.testCase() && story.story_type !== 'bug' && !story.isDesignStory() && project.external()">
            <div class="fullSize-label">Skill Type</div>
            <div class="actionBlock">
              <div class="storyTypeDD-selected">
                <div class="ifReadOnly" *ngIf="story && (story.checkStoryDevTypeRestriction() || checkRestriction() || policyService.isProjectUser(project))"></div>
                <span class="selected-type" (click)="focusDropDown('dev_type')">
                  <em class="iconb-dd-down"></em>
                  <span class="selectedType-name">{{story.getDevType()}}</span>
                </span>
                <span class="elementHover-table make_left" *ngIf="!story.phase">Please assign phase.</span>
                <span class="elementHover-table" *ngIf="policyService.isProjectUser(project)">You are not authorized to perform this action.<br/> Please contact your delivery manager.</span>
                <div class="storySelection-DD" [ngClass]="{'openDD': showDropDown && dropDownType==='dev_type'}">
                  <div class="storySelection-list" *ngFor="let devType of devTypeOptions | devOptions:story" (click)='changeDevType(devType.type)'>
                    <span class="selectedType-name">{{devType.title}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- devtype block Ends -->

          <div class="inputBlocks3" style="border-top: 1px solid rgba(0,0,0,.1);">
            <div class="fullSize-label">Requester</div>
            <div class="actionBlock">
              <div class="requesterInfo-wrapper" *ngIf="story && story.created_from === 'builder_live' && story.client_name">
                <div class="requester-avatar">{{story?.client_name | slice:0:1}}</div>
                <div class="requester-name">{{ story?.client_name }}</div>
              </div>
              <div class="requesterInfo-wrapper" *ngIf="story && story.created_from !== 'builder_live' && !story.client_name">
                <div class="requester-avatar">{{story?.requested_by_initials | slice:0:1}}</div>
                <div class="requester-name">{{ story?.requested_by_name }}</div>
              </div>
            </div>
          </div>

          <!-- owner -->
          <div class="inputBlocks3">
            <div class="fullSize-label">{{story.testCase() ? 'Executor' : 'Owner'}}</div>
            <div class="actionBlock">
              <div class="userDD-selected" [ngClass]="{'full-width': story.testCase()}">
                <div class="ifReadOnly" *ngIf="story && !story.testCase() && (checkRestriction() || validateStoryPermission() || !story.phase || (disableStoryStatusChange() && (story.owner_email === 'tracker_systemadmin@builder.ai') && story.state === 'accepted'))"></div>
                <div class="ifReadOnly" *ngIf="story && story.testCase() && validateStoryPermission()"></div>
                <ng-container *ngIf="!story.testCase()">
                  <span class="elementHover" *ngIf="!story.phase">Please assign phase</span>
                  <span class="elementHover" *ngIf="(disableStoryStatusChange() && (story.owner_email === 'tracker_systemadmin@builder.ai') && story.state === 'accepted')">Owner cannot be changed as BB <br/> exists and the story is accepted</span>
                </ng-container>
                <span class="selectedUser-wrapper" [class.makeFocus]="dropDownType==='owned_by'" [ngClass]="{'selectedUser': story?.owned_by_name}" (click)="story.testCase() ? testSuiteFocusDropdown('owned_by') : focusDropDown('owned_by')">
                  <span class="ownerIcons">{{story?.owned_by_initials | slice: 0:1}}</span>
                  <span class="selectedUser-name">{{story?.owned_by_name ? story?.owned_by_name: 'Owner'}}</span>
                  <em class="iconb-dd-down"></em>
                </span>
                <span class="elementHover-table" *ngIf="validateStoryPermission()">You are not authorized to perform this action.<br/> Please contact your delivery manager.</span>
                <div class="userSelection-DD" [ngClass]="{'openUserDD': showDropDown && dropDownType==='owned_by'}">
                  <div class="userSearchWrapper">
                    <input #focusOwnerSearch type="text" class="userSearchFilter" (input)="setMembersList()" [(ngModel)] = "searchUser" placeholder="Search by name or email">{{ (showDropDown && dropDownType==='owned_by') ? focusOwnerSearch.focus() : '' }}
                  </div>
                  <div class="listingWrapper">
                    <ng-container  *ngFor = "let user of sortedUsers | membersearch: searchUser: 'requesteduser' | squadMemberSearch: story; index as i;">
                      <div #ownersList [attr.email]="user.email" class="userSelection-list" [ngClass]="{'userNotActive': !project.isUserActive(user) || commonStoryService.checkSameUser(user,'owner', story, project)}"
                          (mouseenter)="setSelectedIndex(i)">
                        <div class="disableClick" *ngIf="!project.isUserActive(user) || commonStoryService.checkSameUser(user,'owner', story, project)"></div>
                        <div class="clickContainer" (click)="setUser(user, 'Owner')">
                          <span class="selectUser-initial">{{user?.initials | slice:0:1}}</span>
                          <span class="selectUser-descriptionWrapper">
                            <span class="ifUserDeallocated" *ngIf="!project.isUserActive(user)">{{ project.userInactiveText(user) }}</span>
                            <span class="ifUserDeallocated sameUser" *ngIf="story && commonStoryService.checkSameUser(user,'owner', story, project)">Owner and Reviewer cannot be the same.</span>
                            <span class="selectUser-name">{{user?.name}} <span *ngIf = "project && project.checkIFProjectExternal && user.skill">({{user.skill}})</span></span>
                            <span class="selectUser-email">{{user?.email}}</span>
                          </span>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>

              <div class="timerAction-wrapper" *ngIf="!story.testCase()">
                <div class="ifReadOnly" *ngIf="story && checkRestriction()"></div>
                <div class="userEllapsedTime">
                  <story-timer *ngIf = "story" [project] = "project" [type] = "'owner'" class="storyEllapsedTime-timer"  [story]="story"></story-timer>
                </div>
                <div class="storyEstimate-wrapper">
                  <div class="ifReadOnly" *ngIf="story && !policyService.projectAdmin(project)"></div>
                  <div class="timer_begins" *ngIf="checkforTimeInput(story) && !story_update_reason_option" (click)="askReasonForTimeInput('owner_time')"></div>
                  <label for="ownerTime" class="iconb-glasstime"></label>
                  <div class="dynamicWidth-forInput">
                    <span class="inputDynamic">{{input_estimate}}</span>
                    <input #ownerTimeInput id="ownerTime" type="text" maxlength="4" autocomplete="off" 
                    [disabled] = "story && (checkRestriction() || !story.owned_by_name || !policyService.projectAdmin(project))"
                    class="estimateHours" 
                    (keypress) = "sharedService.restrictNumeric($event)" 
                    (focusin)="openOwnerQuickTimeEstimate()"  
                    (focusout)="updateEstimate('estimate')"  
                    [(ngModel)]="input_estimate" placeholder="0"/>
                  </div>
                  <label for="ownerTime" class="hrs-text">{{ story?.estimate == 1? 'hr' : 'hrs'}}</label>
                  <span class="elementHover" *ngIf="!story?.owned_by_name && policyService.projectAdmin(project)">Please assign owner</span>
                  <span class="elementHover-extra" *ngIf="!policyService.projectAdmin(project)">You are not authorized to perform this action. Please contact your Delivery Manager for effort estimation.</span>
                  <div class="quickTimeAction-backdrop" *ngIf="insertOwnerTime" (click)="closeOwnerQuickTimeEstimate()"></div>
                  <div class="quickTimeAction" *ngIf="story?.owned_by_name" [ngClass]="{'openTimeAction': insertOwnerTime}">
                    <!--<em class="iconb-close" (click)="closeOwnerQuickTimeEstimate()"></em>-->
                    <span class="timeIncButton" (click)="increaseOwnerEstimate(1)">+ 1h</span>
                    <span class="timeIncButton" (click)="increaseOwnerEstimate(2)">+ 2h</span>
                    <span class="timeIncButton" (click)="increaseOwnerEstimate(4)">+ 4h</span>
                    <span class="timeIncButton" (click)="increaseOwnerEstimate(8)">+ 8h</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Reviewer -->
          <div class="inputBlocks3" *ngIf="!story.testCase()">
            <div class="fullSize-label">Reviewer</div>
            <div class="actionBlock">
              <div class="userDD-selected">
                <div class="ifReadOnly" *ngIf="story && (checkRestriction() || validateStoryPermission() || !story.phase || (disableStoryStatusChange() && (story.owner_email === 'tracker_systemadmin@builder.ai') && story.state === 'accepted'))"></div>
                <span class="elementHover" *ngIf="!story.phase">Please assign phase</span>
                <span class="elementHover" *ngIf="(disableStoryStatusChange() && (story.owner_email === 'tracker_systemadmin@builder.ai') && story.state === 'accepted')">Owner cannot be changed as BB <br/> exists and the story is accepted</span>
                <span class="selectedUser-wrapper" [class.makeFocus]="dropDownType==='reviewer_by'" [ngClass]="{'selectedUser': story?.reviewer_name}" (click)="focusDropDown('reviewer_by')">
                  <span class="reviewerIcons">{{story?.reviewer_initials | slice: 0:1}}</span>
                  <span class="selectedUser-name">{{story?.reviewer_name ? story?.reviewer_name: 'Reviewer'}}</span>
                  <em class="iconb-dd-down"></em>
                </span>
                <span class="elementHover-table" *ngIf="validateStoryPermission()">You are not authorized to perform this action.<br/> Please contact your delivery manager.</span>
                <div class="userSelection-DD" [ngClass]="{'openUserDD': showDropDown && dropDownType==='reviewer_by'}">
                  <div class="userSearchWrapper">
                    <input #focusReviewerSearch type="text" class="userSearchFilter" (input)="setMembersList()" [(ngModel)] = "searchUser" placeholder="Search by name or email">{{ (showDropDown && dropDownType==='reviewer_by') ? focusReviewerSearch.focus() : '' }}
                  </div>
                  <div class="listingWrapper">
                    <ng-container *ngFor="let user of sortedUsers | membersearch: searchUser: 'requesteduser' | squadMemberSearch: story; index as i;">
                      <div #reviewersList [attr.email]="user.email" class="userSelection-list" [ngClass]="{'userNotActive': !project.isUserActive(user) || commonStoryService.checkSameUser(user,'reviewer', story, project)}"
                          (mouseenter)="setSelectedIndex(i)">
                        <div class="disableClick" *ngIf="!project.isUserActive(user) || commonStoryService.checkSameUser(user,'reviewer', story, project)"></div>
                        <div class="clickContainer" (click)="setUser(user, 'Reviewer')">
                          <span class="selectUser-initial">{{user?.initials | slice:0:1}}</span>
                          <span class="selectUser-descriptionWrapper">
                            <span class="ifUserDeallocated" *ngIf="!project.isUserActive(user)">{{ project.userInactiveText(user) }}</span>
                            <span class="ifUserDeallocated sameUser" *ngIf="commonStoryService.checkSameUser(user,'reviewer', story, project)">Owner and Reviewer cannot be the same.</span>
                            <span class="selectUser-name">{{user?.name}}</span>
                            <span class="selectUser-email">{{user?.email}}</span>
                          </span>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>

              <div class="timerAction-wrapper" *ngIf="!story.testCase()">
                <div class="ifReadOnly" *ngIf="story && checkRestriction()"></div>
                <div class="userEllapsedTime" *ngIf = "story">
                  <story-timer class="storyEllapsedTime-timer" [project] = "project"  [type] = "'reviewer'" [story]="story"></story-timer>
                </div>
                <div class="storyEstimate-wrapper">
                  <div class="ifReadOnly" *ngIf="story && !policyService.projectAdmin(project)"></div>
                  <div class="timer_begins" *ngIf="checkforTimeInput(story,'reviewer') && !story_update_reason_option" (click)="askReasonForTimeInput('reviewer_time')"></div>
                  <label for="reviewerTime" class="iconb-glasstime"></label>
                  <div class="dynamicWidth-forInput">
                    <span class="inputDynamic">{{story?.reviewer_estimate}}</span>
                    <input 
                    #reviewerTimeInput
                    id="reviewerTime" 
                    type="text" 
                    maxlength="3" 
                    autocomplete="off" 
                    class="estimateHours" 
                    [disabled] = "story && (checkRestriction() || !story.reviewer_name || !policyService.projectAdmin(project))"
                    (keypress) = "sharedService.restrictNumeric($event)" 
                    (focusin)="openReviewerQuickTimeEstimate()"  
                    (focusout)="updateEstimate('reviewer_estimate')" 
                    [(ngModel)] = "input_reviewer_estimate" placeholder="0"/>
                  </div>
                  <label for="reviewerTime" class="hrs-text">{{ story?.reviewer_estimate == 1? 'hr' : 'hrs'}}</label>
                  <span class="elementHover" *ngIf="!story?.reviewer_name && policyService.projectAdmin(project)">Please assign reviewer</span>
                  <span class="elementHover-extra" *ngIf="!policyService.projectAdmin(project)">You are not authorized to perform this action. Please contact your Delivery Manager for effort estimation.</span>
                  <div class="quickTimeAction-backdrop" *ngIf="insertReviewerTime" (click)="closeReviewerQuickTimeEstimate()"></div>
                  <div class="quickTimeAction" *ngIf="story?.reviewer_name" [ngClass]="{'openTimeAction': insertReviewerTime}">
                    <!-- <em class="iconb-close" (click)="closeReviewerQuickTimeEstimate()"></em> -->
                    <span class="timeIncButton" (click)="increaseReviewerEstimate(1)">+ 1h</span>
                    <span class="timeIncButton" (click)="increaseReviewerEstimate(2)">+ 2h</span>
                    <span class="timeIncButton" (click)="increaseReviewerEstimate(4)">+ 4h</span>
                    <span class="timeIncButton" (click)="increaseReviewerEstimate(8)">+ 8h</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="leftSideWrapper is-testsuite-details" *ngIf = "story">

        <div class="inputBlocks4" *ngIf="story && !story.testCase() && !(story.parent_id && (story.phase === 'Design' || story.phase === 'Prototype')) && !(parent_story && (parent_story.phase === 'Design' || parent_story.phase === 'Prototype'))">
          <div class="contentWrapper">
            <div class="labelText forSubtask" *ngIf = "story">
              {{(story?.total_tasks_count > 1) ? 'Subtasks':'Subtask'}} ({{story?.done_tasks_count}}/{{story?.total_tasks_count}})
              <span class="elementHover-extraDe" [ngClass]="{'moveRight': (story.sub_tasks.length <= 1)}" *ngIf="!story?.phase">Phase is not selected for this story</span>
              <div class="subtaskSorting-wrapper is-subtask-block-btn" *ngIf="(!story?.inactive && story?.sub_tasks && (story?.sub_tasks.length > 1))">
                <em class="iconb-filter makeFilterClick" (click)="openSubtaskFilter()"></em>
                <div class="sortDD-wrapper" [@DDSort] *ngIf="showSubtaskFilter">
                  <span class="sortDD-list" [ngClass]="{'selected': subtaskSortOrder === 'title'}" (click)="subtaskSortOrder = 'title'">A-Z (1-9)</span>
                  <span class="sortDD-list" [ngClass]="{'selected': subtaskSortOrder === 'created_at'}" (click)="subtaskSortOrder = 'created_at'">Date/Time Created</span>
                  <span class="sortDD-list" [ngClass]="{'selected': subtaskSortOrder === 'updated_at'}" (click)="subtaskSortOrder = 'updated_at'">Recent Activity</span>
                  <span class="sortDD-list" [ngClass]="{'selected': subtaskSortOrder === 'state'}" (click)="subtaskSortOrder = 'state'">Status</span>
                </div>
              </div>
              <span class="subtask-button" [ngClass]="{'is-subtask-block-btn': story?.sub_tasks.length > 0}" (click)="createSubTask(true)" *ngIf="!story?.inactive && policyService.expertAsQA(project)">+</span>
              <div class="disable-create-subtask" [ngClass]="{'is-subtask-block-btn': story?.sub_tasks.length > 0}" *ngIf="story.disableCreateSubtask(create_SubTask)">
                <span class="elementHover-extra" *ngIf="story && (story?.story_type === 'bug' && !story?.bug_reason_id)">Please select the bug reason for the parent story</span>
                <span class="elementHover-extra" *ngIf="story && story?.state === 'accepted'">Subtasks cannot be created after the story is accepted.</span>
              </div>
              <div class="subtak-action-wrapper" *ngIf="story?.sub_tasks.length > 0" [ngClass]="{'open': subTaskMenuVisible}">
                <em class="iconb-more-vert" (click)="toggleSubTaskMenu()"></em>
                <div class="subtak-buttons-dropdown" [@fadeInOut] *ngIf="subTaskMenuVisible">
                  <button class="subtask-btn" *ngIf="(story?.sub_tasks.length > 0) && !story?.inactive" [disabled]="selectedSubtasks?.length === 0" (click)="openConvertSubtaskPopup()"><em class="iconb-logout"></em>Promote to Task</button>
                  <button class="subtask-btn" *ngIf="(story?.sub_tasks.length > 0) && !story?.inactive" [disabled]="((selectedSubtasks?.length === 0) || !(story?.phase))" (click)="showMoveSubtaskPopup()"><em class="iconb-logout"></em>Move</button>
                  <button class="subtask-btn" *ngIf="(story?.sub_tasks.length > 0) && !story?.inactive" [disabled]="selectedSubtasks?.length === 0" (click)="openAcceptRejectConfirmation('accept')"><em class="iconb-bulk-accept"></em>{{selectedSubtasks?.length === 1 ? 'Accept':'Bulk Accept'}}</button>
                  <button class="subtask-btn" *ngIf="(story?.sub_tasks.length > 0) && !story?.inactive" [disabled]="selectedSubtasks?.length === 0" (click)="openAcceptRejectConfirmation('reject')"><em class="iconb-bulk-accept"></em>{{selectedSubtasks?.length === 1 ? 'Reject':'Bulk Reject'}}</button>
                </div>
              </div>
            </div>
            <div class="enterSubtask-wrapper" *ngIf="create_SubTask">
              <div class="storyTypeDD-selected" [ngClass]="{'shiftDiv' : policyService.isProjectUser(project)}">
                <span class="selected-type" (click)="focusDropDown('story_type_task')">
                  <em [class]="storyTypeIcon(new_subTask?.story_type, new_subTask?.parent_id !== null)"></em>
                  <em class="iconb-dd-down" *ngIf="policyService.projectAdmin(project)"></em>
                </span>
                <div class="storySelection-DD" [ngClass]="{'openDD': showDropDown && dropDownType==='story_type_task'}">
                  <div class="storySelection-list" *ngFor="let storyType of storyTypeOptions" (click)='changeNewSubTaskType(storyType.type)'>
                    <em [class]='storyType.icon'></em>
                    <span class="selectedType-name">{{(storyType?.title === 'Story')? 'Sub Task' : storyType?.title }}</span>
                  </div>
                </div>
              </div>
              <div class="enterSubtask-input" *ngIf = "new_subTask">
                <input #focusNewSubTask type="text" class="subtask-inputbox" placeholder="enter title" (keyup.enter)="addSubTask()" [(ngModel)]="new_subTask.title"/>{{ focus_create_SubTask ? focusNewSubTask.focus() : '' }}
              </div>
            </div>
            <div class="enterSubtask-actionButtons" *ngIf="create_SubTask">
              <button type="button" class="cancel" (click)="createSubTask(false)">Cancel</button>
              <button type="button" class="create" (click)="addSubTask()" [disabled]="disable_story_board || ((new_subTask) && (!new_subTask.title || new_subTask.title.trim().length < 1))">Create</button>
            </div>

            <div class="subtaskList-wrapperEmpty" *ngIf="story && story?.sub_tasks.length === 0 && !new_subTask">
              <span class="wrapperEmpty-title">No Subtask</span> 
            </div>
            <div class="subtaskList-wrapper" *ngIf="story && story.sub_tasks && story.sub_tasks.length>0">
              <div class="subTaskAction-Loader" *ngIf="updatingSubtaskList">
                <img src="../../assets/images/storyBoardLoader.gif" alt="loader"/>
              </div>
              <div #subTaskOwnerTimeInputList id="{{subTask.id}}"  class="subtaskList" *ngFor="let subTask of (story.sub_tasks | subTaskListSort:subtaskSortOrder:'title'); index as i" [ngClass]="{'is-edit-mode': subTask.subTaskTitleEdit}">
                <div class="subTaskmoveSelection-check">
                  <label class="customCheckFor-moveSelection">
                    <div class="ifReadOnly"  *ngIf="checkRestriction()"></div>
                    <input type="checkbox" class="move-selected-checkbox" (change)="selectSubtask($event, subTask)" [disabled]="checkRestriction()" [checked]="isSelectedSubtask(subTask)" hidden/>
                    <span class="check-toggleMoveSelection"></span>
                  </label>
                </div>
                <div class="subtaskList-leftSide">
                  <em [class]='storyTypeIcon(subTask?.story_type,true)' (click)="goToSubTask(subTask)"></em>
                  <div class="subtask-storyName"  *ngIf="!subTask?.subTaskTitleEdit" (click)="goToSubTask(subTask)">{{ subTask?.title }}</div>
                  <div class="edit-subtask-storyName" *ngIf="subTask?.subTaskTitleEdit">
                    <input type="text" #subTaskInputTitle maxlength="200" class="blocker-input" [(ngModel)]="subTaskTitle" (focusout)="updateSubtaskTitle(subTask)" (keyup.enter)="updateSubtaskTitle(subTask)">
                  </div>
                </div>
               
                <div class="subtaskList-rightSide">
                  <em class="iconb-edit-2 editTitle" *ngIf="validateEditPermission(subTask)" (click)="toggleSubTaskEditMode(subTask)"></em>
                  <div class="ownerBadge" [ngClass]="{'ifemptyOwner' : !subTask?.owned_by_id }">
                    <div class="ifReadOnly" *ngIf="story && (checkRestriction() || validateStoryPermission(subTask?.story_type) || story?.state === 'rejected')"></div>
                    <div class="owner-wrapper" (click)="selectSubTaskOwner(subTask?.id)">
                      <span class="ifOwner-exist" *ngIf="subTask?.owned_by_id">{{ subTask?.owned_by_initials?.substr(0,1) }}</span>
                      <span class="iconb-add-member" *ngIf="!subTask?.owned_by_id"></span>
                      <span class="elementHover" *ngIf="subTask?.id !== subTaskID">{{subTask.owned_by_name ? subTask.owned_by_name : 'Select Owner'}}</span>
                    </div>
                    <div class="userSelection-DD openUserDD" [@DDowner] *ngIf="subTask?.id === subTaskID">
                      <div class="userSearchWrapper">
                        <input #focusOwnerSearch type="text" class="userSearchFilter" (input)="setMembersList()" [(ngModel)] = "searchUser" placeholder="Search by name or email">{{ (showDropDown && dropDownType==='owned_by') ? focusOwnerSearch.focus() : '' }}
                      </div>
                      <div class="listingWrapper">
                        <ng-container  *ngFor = "let user of sortedUsers | membersearch: searchUser: 'requesteduser'; index as i;">
                          <div #ownersList [attr.email]="user.email" class="userSelection-list" [ngClass]="{'userNotActive': !project.isUserActive(user)}"
                              (mouseenter)="setSelectedIndex(i)">
                            <div class="disableClick" *ngIf="!project.isUserActive(user)"></div>
                            <div class="clickContainer" (click)="setSubTaskUser(user, subTask)">
                              <span class="selectUser-initial">{{user?.initials | slice:0:1}}</span>
                              <span class="selectUser-descriptionWrapper">
                                <span class="ifUserDeallocated" *ngIf="!project.isUserActive(user)">{{ project.userInactiveText(user) }}</span>
                                <span class="selectUser-name">{{user?.name}} <span *ngIf = "project && project.checkIFProjectExternal && user.skill">({{user.skill}})</span></span>
                                <span class="selectUser-email">{{user?.email}}</span>
                              </span>
                            </div>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </div>

                  <div class="timerAction-wrapper">
                    <div class="storyEstimate-wrapper">
                      <div class="ifReadOnly" *ngIf="story && (checkRestriction() || !subTask.owned_by_id || (!policyService.projectAdmin(project)))"></div>
                      <div class="timer_begins" *ngIf="checkforTimeInput(subTask) && !story_update_reason_option" (click)="askReasonForTimeInput('subtask_owner_time', subTask.id)"></div>
                      <label for="{{'subtaskownerTime_' + subTask?.id}}" class="iconb-glasstime"></label>
                      <div class="dynamicWidth-forInput">
                        <span class="inputDynamic">{{subTask?.estimate}}</span>
                        <input 
                        #subTaskOwnerTimeInput
                        id="{{'subtaskownerTime_' + subTask?.id}}" 
                        type="text" 
                        maxlength="3" 
                        autocomplete="off" 
                        class="estimateHours" 
                        (keypress) = "sharedService.restrictNumeric($event)" 
                        (focusin)='openSubtaskOwnerQuickTimeEstimate(subTask?.id, false)'  
                        (change)="setSubTaskOwnerTime(subTask)" 
                        [(ngModel)]="subTask.estimate" 
                        placeholder="0" 
                        [disabled] = "story && (checkRestriction() || !subTask.owned_by_id || (!policyService.projectAdmin(project)))" />
                      </div>
                      <label for="{{'subtaskownerTime_' + subTask?.id}}" class="hrs-text">{{ subTask?.estimate == 1? 'hr' : 'hrs'}}</label>
                      <span class="elementHover" *ngIf="!subTask?.owned_by_id && policyService.projectAdmin(project)">Please assign owner</span>
                      <span class="elementHover-extra" *ngIf="!policyService.projectAdmin(project)">You are not authorized to perform this action. Please contact your Delivery Manager for effort estimation.</span>
                      <div class="quickTimeAction-backdrop" *ngIf="subTask?.id === idForTimerPopup" (click)="closeSubtaskOwnerQuickTimeEstimate()"></div>
                      <div class="quickTimeAction openTimeAction" *ngIf="subTask?.id === idForTimerPopup" >
                        <em class="iconb-close" (click)="closeSubtaskOwnerQuickTimeEstimate()"></em>
                        <span class="timeIncButton" (click)="increaseSubTaskOwnerEstimate(1, subTask)">+ 1h</span>
                        <span class="timeIncButton" (click)="increaseSubTaskOwnerEstimate(2, subTask)">+ 2h</span>
                        <span class="timeIncButton" (click)="increaseSubTaskOwnerEstimate(4, subTask)">+ 4h</span>
                        <span class="timeIncButton" (click)="increaseSubTaskOwnerEstimate(8, subTask)">+ 8h</span>
                      </div>
                    </div>
                  </div>
                  <div class="subT-status">
                    <div [class]='storyStatusIconSelectedClass(subTask.state)'></div>
                    <span class="elementHover-extra">{{subTask.state}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> 

        <div class="inputBlocks4" *ngIf="story && !story?.parent_id && !story.isTestCaseOrBug()">
          <div class="contentWrapper">
            <div class="labelText forSubtask" *ngIf = "story">
              {{(story?.test_cases.length > 1) ? 'Test Cases':'Test Case'}} ({{story?.test_cases.length}})
            </div>
            <div class="subtaskList-wrapperEmpty" *ngIf="story && story?.test_cases.length === 0">
              <span class="wrapperEmpty-title">No Test Case</span> 
            </div>
            <div class="subtaskList-wrapper" *ngIf="story && story?.test_cases && story?.test_cases.length>0">
              <div #testCaseList id="{{testCase.test_case.id}}"  class="subtaskList" *ngFor="let testCase of (story?.test_cases); index as i" >
                <div class="subtaskList-leftSide pl-0">         
                  <!-- <em [class]='storyTypeIcon(testCase?.test_case.story_type)' (click)="goToTestCase(testCase)"></em> -->
                  <div class="subtask-storyName" (click)="goToTestCase(testCase)">{{ testCase?.test_case.title }}</div>
                </div>
               
                <div class="subtaskList-rightSide">
                  <div class="ownerBadge" [ngClass]="{'ifemptyOwner' : !testCase?.test_case?.owned_by_id }">
                    <div class="ifReadOnly" *ngIf="story && (checkRestriction() || validateStoryPermission(testCase?.test_case.story_type) || story?.state === 'rejected')"></div>
                    <div class="owner-wrapper" (click)="selectSubTaskOwner(testCase?.test_case.id)">
                      <span class="ifOwner-exist" *ngIf="testCase?.test_case.owned_by_id">{{ testCase?.test_case.owned_by_initials?.substr(0,1) }}</span>
                      <span class="iconb-add-member" *ngIf="!testCase?.test_case.owned_by_id"></span>
                      <span class="elementHover" *ngIf="testCase?.test_case.id !== subTaskID">{{testCase?.test_case.owned_by_name ? testCase?.test_case.owned_by_name : 'Select Owner'}}</span>
                    </div>
                    <div class="userSelection-DD openUserDD" [@DDowner] *ngIf="testCase?.test_case.id === subTaskID">
                      <div class="userSearchWrapper">
                        <input #focusOwnerSearch type="text" class="userSearchFilter" (input)="setMembersList()" [(ngModel)] = "searchUser" placeholder="Search by name or email">{{ (showDropDown && dropDownType==='owned_by') ? focusOwnerSearch.focus() : '' }}
                      </div>
                      <div class="listingWrapper">
                        <ng-container  *ngFor = "let user of sortedUsers | membersearch: searchUser: 'requesteduser'; index as i;">
                          <div #ownersList [attr.email]="user.email" class="userSelection-list" [ngClass]="{'userNotActive': !project.isUserActive(user)}"
                              (mouseenter)="setSelectedIndex(i)">
                            <div class="disableClick" *ngIf="!project.isUserActive(user)"></div>
                            <div class="clickContainer" (click)="setSubTaskUser(user, testCase?.test_case)">
                              <span class="selectUser-initial">{{user?.initials | slice:0:1}}</span>
                              <span class="selectUser-descriptionWrapper">
                                <span class="ifUserDeallocated" *ngIf="!project.isUserActive(user)">{{ project.userInactiveText(user) }}</span>
                                <span class="selectUser-name">{{user?.name}} <span *ngIf = "project && project.checkIFProjectExternal && user.skill">({{user.skill}})</span></span>
                                <span class="selectUser-email">{{user?.email}}</span>
                              </span>
                            </div>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                  <div class="subT-status">
                    <div [class]='storyStatusIconSelectedClass(testCase?.test_case.state)'></div>
                    <span class="elementHover-extra">{{getTestCaseState(testCase)}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="inputBlocks4" *ngIf="story?.epics[0]?.bb_status !== 'unassigned' && story?.core && !(story?.isDesignStory())">
          <div class="contentWrapper">
            <div class="labelText">Demo Video
              <label class="add-attach" *ngIf="((!story?.inactive && (!story?.deleted_at) && !disable_story_board) && (story?.epics[0]?.bb_status === 'assigned' || story?.epics[0]?.bb_status === 'bb_under_development' || story?.accepted_demo_video_url === null))" >
                +
                <input #attachmentInput
                       type="file"
                       class="forFiletype"
                       (click)="attachmentInput.value = null" 
                       value=""
                       accept="video/*"
                       (change)="attachDemoVideoOnly($event)" />
              </label>
            </div>
            <div class="subtaskList-wrapperEmpty" *ngIf="!story?.demo_video_url && !uploadVideoLoader">
              <span class="wrapperEmpty-title">No Demo Video</span> 
            </div>
            <div class="demoVideo-Loader" *ngIf="uploadVideoLoader">
              <em class="icon"><img src="../../assets/images/loader.svg" alt="loader"/></em>
              {{story?.demo_video_url ? 'Updating Video' : 'Uploading Video'}}
            </div>
            <div class="demoVideo-list" *ngIf="story?.demo_video_url && (!uploadVideoLoader)">
              <div class="demoVideo-row">
                <div class="video-name">
                  <em class="iconb-attach"></em>
                  <span class="name">{{story?.accepted_demo_video_url ? getFileName(story?.accepted_demo_video_url) :getFileName(story?.demo_video_url)}}</span>
                </div>
                <div class="file-display">
                  <a [href]="story?.demo_video_url" target="_blank" class="download-file" *ngIf="story && !story.deleted_at">
                    <em class="iconb-down-2-1"></em>
                    <span class="elementHover">Download</span>
                  </a>
                  <span class="file-name" (click)="openVideoPopup(story?.demo_video_url)">{{story?.accepted_demo_video_url ?
                    getFileName(story?.accepted_demo_video_url,true) : getFileName(story?.demo_video_url,true)}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="inputBlocks4">
          <div class="contentWrapper">
            <div class="labelText">Attachments ({{story.attachments.length}})
              <label class="add-attach" *ngIf="(!story?.inactive && (!story?.deleted_at) && !disable_story_board )">
                +
                <input type="file"
                                 #attachmentInput
                                 id="fileOnly"
                                 class="forFiletype"
                                 [disabled]="isProjectReadonly || story?.inactive || story?.deleted_at"
                                 (click)="attachmentInput.value = null" value=""
                                 (change)="attachFilesOnly($event)"
                                 multiple />
              </label>
              <button class="saveAddedFiles" *ngIf="attachOnlyArr.length > 0"
                [disabled]="storyService.attachmentActionLoader || storyService.attachmentActionLoader2"
                (click)="addAttachmentToStory()">Upload added Files</button>
            </div>
            <div class="attachmentAction-Loader" *ngIf="storyService.attachmentActionLoader || storyService.attachmentActionLoader2">
              <img src="../../assets/images/storyBoardLoader.gif" alt="loader"/>
            </div>
            <div class="storyAttachmentOnly" *ngIf="attachOnlyArr.length > 0">
              <div class="toBeUploaded-list" *ngFor="let item of attachOnlyArr; let i = index">
                <img *ngIf = "sharedService.checkType(item.name, true) == 'image'" [src]="attachmentPreviewUrls[i]" alt="loader"/>
                <span class="removeDocument" (click)="removeStoryAttachment(item)">
                  <em class="iconb-bin"></em>
                </span>
              </div>
            </div>
            <div class="subtaskList-wrapperEmpty" *ngIf="story && story?.attachments?.length === 0">
              <span class="wrapperEmpty-title">No Attachment</span> 
            </div>
            <div class="allAttachmentsList-wrapper" *ngIf="story && story?.attachments && story?.attachments?.length>0">
                <div *ngFor="let item of story.attachments" class="storyDetailImage">
                  <span *ngIf="((loggedInUser.id === item.user_id) && story && (!story.deleted_at))" class="deleteAttachDocument" (click)="removeAttachmentDocument(item)">
                    <em class="iconb-bin"></em>
                    <span class="elementHover">Delete</span>
                  </span>
                  <a [href]="item.remote_url" target="_blank" class="download-file" *ngIf="story && !story.deleted_at">
                    <em class="iconb-down-2-1"></em>
                    <span class="elementHover">Download</span>
                  </a>
                  <div class="requester-avatar" *ngIf="!item.description">{{item.user_initials ? (item.user_initials) : (item.user_name | slice:0:1)}}</div>
                  <span (click)="openAuthorPopup(item,'remote_url')">
                      <img *ngIf = "item.remote_url && sharedService.checkType(item.remote_url, true) == 'image'" [src]="item.remote_url" alt="loader"/>
                      <a *ngIf = "item.remote_url && (sharedService.checkType(item.remote_url, true) == 'doc' || sharedService.checkType(item.remote_url, true) == '')" class="gotoImage" [href]="item.remote_url" target="_blank">
                          <img class="default-attachment" src="/assets/images/defaullt-download.png" alt="loader"/>
                      </a>
                      <div *ngIf = "item.remote_url && sharedService.checkType(item.remote_url, true) == 'video'" class="videoContainer" (click)="openVideoPopup(item.remote_url)">
                          <em class="iconb-play-circle"></em>
                      </div>
                  </span>
                </div>
            </div>
          </div>
        </div>

        <!-- Features start -->
        <div class="inputBlocks4">
          <div class="contentWrapper" *ngIf = "story">
            <span class="labelText">Features ({{ story.epics ? story.epics.length : 0 }})</span>
            <div class="featureAndLabelWrapper">
              <div class="list-wrapperEmpty" *ngIf="story && (story?.epics.length === 0) && story?.deleted_at">
                <span class="wrapperEmpty-title">No Feature</span> 
              </div>
              <div class="list-wrapperEmpty list-wrapperEmpty2" *ngIf="story && (story?.epics.length === 0) && (story?.parent_id || story.testCase()) && !story?.deleted_at">
                <span class="wrapperEmpty-des2">Please select feature from parent story.</span>  
              </div>
              <div class="featureAndLabelWrapper-list">
                <span class="infoBox ifFeature" *ngFor="let epic of story?.epics">
                  <span class="enteredText">{{epic?.title}}</span>
                  <span class="forRemove" *ngIf = "(story?.epics.length > 1) && !(story?.core && story?.phase == 'Product Roadmap') && !story.wireframe">
                    <em class="iconb-bin" (click)='onEpicRemoved(epic)'></em>
                  </span>
                </span>
                <div class="inputfeature-label-name"  *ngIf="!checkRestriction() && ((policyService.isQAExpert(this.project) && story.story_type === 'bug') || policyService.projectAdmin(project)) && checkAddedFeature()">
                  <span class="addFeature" (click)='canAddFeature = !canAddFeature'>Add Feature</span>
                </div>
              </div>
              <div class="selectFeatureName" [ngClass]="{'open-selectFeatureName': canAddFeature && !story?.parent_id && (showEpics().length > 0) && !story?.core && !story?.wireframe}">
                <ng-container *ngFor="let epic of showEpics()">
                  <div class="selectFeatureName-list">
                    <div class="if_featureInactive" *ngIf="story?.inactive"></div>
                    <span class="epic-class" (click)='openAddFeaturePopUp(epic)'>{{epic?.title}}</span>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
        
        <!-- Sprint start -->
        <div class="inputBlocks4" *ngIf="showSprint()">
          <div class="contentWrapper">
            <span class="labelText">Sprint</span>
            <div class="sprint-selection-block">
              <div class="blocker-block" *ngIf="isSprintEmpty() && !changeStorySprint()">
                <div class="wrapperEmpty-title">No sprint added</div>
              </div>
              <div class="sprint-selection-wrapper" *ngIf="isSprintEmpty() && changeStorySprint()" [ngClass]="{'open': addSprintDropdown}">
                <div class="sprint-trigger" (click)="addSprintToggle()">Add Sprint <em class="iconb-dd-down"></em></div>
                <div class="sprint-list-dropdown" [ngClass]="{hideSearch: (sprints_in_story.length < 1 && projectService.sprintQueryParams.q === '')}"
                infiniteScroll
                [scrollWindow]="false"
                [infiniteScrollDisabled]="!isScrollFetchAvailable()"
                (scrolled)="getOlder()"
                [@fadeInOut] *ngIf="addSprintDropdown">
                  <div class="search-sprint">
                    <input class="search-sprint-input" (input) = "getSearchSprintStories()" placeholder="Search Sprint" [(ngModel)]="projectService.sprintQueryParams.q" (keyup)="getSprints(true)"/>
                  </div>
                  <ng-container *ngIf="sprints_in_story.length < 1">
                    <div class="sprint-name no-sprint">
                      <span class="sprint-label">No sprint available!</span>
                    </div>
                 </ng-container>
                  <ng-container *ngIf="sprints_in_story.length > 0">
                    <div class="sprint-name" *ngFor="let sprint of sprints_in_story" (click)="addSprint(sprint.id)" >
                      <span class="sprint-label">{{sprint.name}}</span>
                      <div class="status" [ngClass]="sprint.status"><span class="elementHover">{{sprint.status}}</span></div>
                    </div>
                 </ng-container>
                </div>
              </div>

              <div class="sprint-selected-row" *ngIf="!isSprintEmpty()">
                <div class="sprint-selected">{{story.sprint.name}}<span *ngIf="changeStorySprint()" (click)="removeSprint()" class="remove iconb-closeSmall"></span></div>
              </div>
            </div>
          </div>
        </div>
        <!-- Labels start -->
        <div class="inputBlocks4">
          <div class="contentWrapper" *ngIf = "story">
            <span class="labelText">Labels ({{story.labels ? story.labels.length : 0}})</span>
            <div class="featureAndLabelWrapper">
              <div class="list-wrapperEmpty" *ngIf="story && story?.deleted_at && story?.labels.length === 0">
                <span class="wrapperEmpty-title">No Label</span> 
              </div>
              <div class="featureAndLabelWrapper-list">
                <span class="infoBox ifLabel" *ngFor="let label of story?.labels">
                 <span class="enteredText">{{label.display}}</span>
                  <span class="forRemove">
                    <em class="iconb-bin" (click)='onLabelRemove(label)'></em>
                  </span>
                </span>
                <div class="inputfeature-label-name" *ngIf="(!story?.inactive && !story?.deleted_at)">
                  <input type="text" class="enterFeatureLabel-name" placeholder="Add Label" (focus)='canAddFeatureLabel = true;'
                        (focusout)='canAddFeatureLabel = false;' [disabled]="story && checkRestriction()" (keyup.enter)="addNewLabel(searchEpic)" [(ngModel)]="searchEpic">
                </div>
              </div>
              <div class="selectFeatureName" [ngClass]="{'open-selectFeatureName': canAddFeatureLabel && !story.parent_id && (showEpics().length>0 || showLabels().length>0) && !story.core && !story.wireframe}">
                <div class="selectFeatureName-list" *ngFor="let label of showLabels()" (click)='addNewLabel(label)'>
                  <span class="label-class">{{label}}</span>
                </div>
              </div>
              <div class="selectFeatureName" [ngClass]="{'open-selectFeatureName': canAddFeatureLabel && story.parent_id && showLabels().length>0}">
                <div class="selectFeatureName-list" *ngFor="let label of showLabels()" (click)='addNewLabel(label)'>
                  <span class="label-class">{{label}}</span>
                </div>
              </div>
              <div class="selectFeatureName" [ngClass]="{'open-selectFeatureName': canAddFeatureLabel && !story.parent_id && (showLabels().length>0) && story.core}">
                <div class="selectFeatureName-list" *ngFor="let label of showLabels()" (click)='addNewLabel(label)'>
                  <span class="label-class">{{label}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>


        <!-- Test Per Condition -->
        <div class="inputBlocks4 blocker-block" *ngIf="story.isTestCaseOrBug()">
          <div class="contentWrapper">
            <div class="contentWrapper-inner">
              <div class="labelText">Pre-conditions</div>
              <div class="scrollableContentWrapper" (click)="toggleTestCaseTextFields('Pre-conditions')" *ngIf="!isPreConditionFieldActive">
                <p>{{story.pre_conditions}}</p>
              </div>
              <div class="blockerInputWrapper">
                <textarea [mention] = "projectUsernames" *ngIf="isPreConditionFieldActive" #preCondition [(ngModel)]="story.pre_conditions"  autosize [disabled] = "checkRestriction() || validateStoryPermission() || (story.wireframe && story.parent_id === null)" type="text" placeholder="Add Pre-condition" class="test-case-data-input" [ngClass]="{'addScroll': addScroll}"
                    (focusout)="!disable_story_board && preCondition?.value && updateTestCase('pre_conditions',story.pre_conditions)" (input)="getDimension()"></textarea>
              </div>
            </div>
          </div>
        </div>
        
        <!-- Test Post Condition -->
        <div class="inputBlocks4 blocker-block" *ngIf="story.isTestCaseOrBug()">
          <div class="contentWrapper">
            <div class="contentWrapper-inner">
              <div class="labelText">Post-conditions</div>
              <div class="scrollableContentWrapper" (click)="toggleTestCaseTextFields('post-conditions')" *ngIf="!isPostConditionFieldActive">
                <p>{{story.post_conditions}}</p>
              </div>
              <div class="blockerInputWrapper">
                <textarea [mention] = "projectUsernames" *ngIf="isPostConditionFieldActive" #postCondition [(ngModel)]="story.post_conditions" autosize [disabled] = "checkRestriction() || validateStoryPermission() || (story.wireframe && story.parent_id === null)" type="text" placeholder="Add Post-condition" class="test-case-data-input" [ngClass]="{'addScroll': addScroll}"
                (focusout)="!disable_story_board && postCondition?.value && updateTestCase('post_conditions',story.post_conditions)" (input)="getDimension()"></textarea>
              </div> 
            </div> 
          </div>
        </div>
        
        <!-- Test  Steps-->
        <div class="inputBlocks4 blocker-block" *ngIf="story.testCase()">
          <div class="contentWrapper">
            <div class="contentWrapper-inner">
              <div class="labelText">Test Steps</div>
              <div class="scrollableContentWrapper" (click)="toggleTestCaseTextFields('testSteps')" *ngIf="!isTestStepsFieldActive">
                <p>{{story.test_steps}}</p>
              </div>
              <div class="blockerInputWrapper">
                <textarea [mention] = "projectUsernames" *ngIf="isTestStepsFieldActive" #testSteps [(ngModel)]="story.test_steps" autosize [disabled] = "checkRestriction() || validateStoryPermission() || (story.wireframe && story.parent_id === null)" type="text" placeholder="Add Test Steps" class="test-case-data-input" [ngClass]="{'addScroll': addScroll}"
                (focusout)="!disable_story_board && testSteps?.value && updateTestCase('test_steps',story.test_steps)" (input)="getDimension()"></textarea>
              </div> 
            </div> 
          </div>
        </div>
  
        <!-- Test Data -->
        <div class="inputBlocks4 blocker-block" *ngIf="story.isTestCaseOrBug()">
          <div class="contentWrapper">
            <div class="contentWrapper-inner">
              <div class="labelText">Test Data</div>
              <div class="scrollableContentWrapper" (click)="toggleTestCaseTextFields('testData')" *ngIf="!isTestDataFieldActive">
                <p>{{story.test_data}}</p>
              </div>
              <div class="blockerInputWrapper">
                <textarea [mention] = "projectUsernames" *ngIf="isTestDataFieldActive" #testData [(ngModel)]="story.test_data" autosize [disabled] = "checkRestriction() || validateStoryPermission() || (story.wireframe && story.parent_id === null)" type="text" placeholder="Add Test Data" class="test-case-data-input" [ngClass]="{'addScroll': addScroll}"
                (focusout)="!disable_story_board && testData?.value && updateTestCase('test_data',story.test_data)" (input)="getDimension()"></textarea>
              </div>
            </div>
          </div>
        </div>

        <div class="inputBlocks4 blocker-block" *ngIf="story && story.isTestCaseOrBug()">
          <div class="contentWrapper">
            <div class="contentWrapper-inner">
              <div class="labelText">Expected Result</div>
              <label class="add-attach" *ngIf="!expResultInputField" (click)="toggleExpResultInputField()">+
              </label>
              <div class="blockerInputWrapper" *ngIf="expResultInputField">
                <input [mention] = "projectUsernames" type="text" [(ngModel)]="expectedResultText" #expectedResult="ngModel" class="blocker-input"
                placeholder="Add Expected Result" [disabled] = "story && checkRestriction()" (keyup.enter)="!disable_story_board && expectedResult?.value && createExpectedResult(expectedResultText)" (keyup)="enableCreateExpResultButton(expectedResultText)">
                <div class="enterSubtask-actionButtons">
                  <button type="button" class="cancel" (click)="toggleExpResultInputField()">Cancel</button>
                  <button type="button" class="create" (click)="createExpectedResult(expectedResultText)" [disabled]="!enableCreateExpResult">Create</button>
                </div>
              </div>
              <div class="wrapperEmpty-title" *ngIf="emptyExpectedResult(story) && !expResultInputField">No Expected Result</div>
              <div class="scrollableContentWrapper hover-transparent" *ngIf="story.expected_results.length > 0">
                <div class="custom-checkbox-wrapper">
                  <div class="custom-checkbox-row" *ngFor="let result of story.expectedResults()">
                    <div class="custom-checkbox-outer has-action-btns" [ngClass] = "{'editMode':result.editMode  === true}">
                      <div class="ifReadOnly"  *ngIf="!story.checkTestCaseMemberPermission(loggedInUser)"></div>
                      <input *ngIf="!result.editMode" type="checkbox" [checked]="result.status === 'pass'" (click)="UpdateExpectedResult(result)" class="hidden-input">
                      <span *ngIf="!result.editMode" class="checkbox-text">{{result.note}}</span>
                      <div class="blockerInputWrapper" *ngIf="result.editMode" >
                        <input [mention] = "projectUsernames" type="text" [(ngModel)]="expectedResultText"  #expectedResult="ngModel" class="blocker-input resultsInput"
                         placeholder="Add Expected Result" [disabled] = "story && checkRestriction()" (keyup.enter)="!disable_story_board && expectedResult?.value && updateExpResultTitle(result,expectedResultText)"  (focusout)="!disable_story_board && expectedResult?.value && updateExpResultTitle(result,expectedResultText)">
                      </div>
                      <div class="action-btns" *ngIf="story.checkTestCaseMemberPermission(loggedInUser)">
                        <span class="edit-btn" (click)="ToggleExpResultEditMode(result)"><em _ngcontent-mqc-c10="" class="iconb-edit"></em></span>
                        <span class="deletebtn" (click)="deleteExpectedResult(result)"><em _ngcontent-mqc-c10="" class="iconb-bin"></em></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <div class="inputBlocks4 blocker-block" *ngIf="story.isTestCaseOrBug()">
          <div class="contentWrapper">
            <div class="contentWrapper-inner">
              <div class="labelText">Actual Result</div>
              <label class="add-attach" *ngIf="!actualResultInputField" (click)="toggleExpResultInputField(true)">+
              </label>
              <div class="blockerInputWrapper" *ngIf="actualResultInputField">
                <input [mention] = "projectUsernames" type="text" [(ngModel)]="expectedResultText" #actualResult="ngModel" class="blocker-input"
                placeholder="Add Actual Result" [disabled] = "story && checkRestriction()" (keyup.enter)="!disable_story_board && actualResult?.value && createExpectedResult(expectedResultText,true)" (keyup)="enableCreateExpResultButton(expectedResultText)">
                <div class="enterSubtask-actionButtons">
                  <button type="button" class="cancel" (click)="toggleExpResultInputField(true)">Cancel</button>
                  <button type="button" class="create" (click)="createExpectedResult(expectedResultText,true)" [disabled]="!enableCreateExpResult">Create</button>
                </div>
              </div>
              <div class="wrapperEmpty-title" *ngIf="isActualResultEmpty(story) && !actualResultInputField">No Actual Result</div>
              <div class="scrollableContentWrapper hover-transparent" *ngIf="story.expected_results.length > 0">
                <div class="custom-checkbox-wrapper">
                  <div class="custom-checkbox-row" *ngFor="let result of story.actualResults()">
                    <div class="custom-checkbox-outer has-action-btns" [ngClass] = "{'editMode':result.editMode  === true && result.status === 'actual'}">
                      <div class="ifReadOnly"  *ngIf="!story.checkTestCaseMemberPermission(loggedInUser)"></div>
                      <input type="checkbox" *ngIf="result.status === 'pass'" checked (click)="UpdateExpectedResult(result)" class="hidden-input">
                      <span *ngIf="!result.editMode || result.status === 'pass'" [ngClass]="{actualResultIcon: result.status === 'actual'}" class="checkbox-text">{{result.note}}</span>
                      <div class="blockerInputWrapper" *ngIf="result.editMode && result.status === 'actual'" >
                        <input [mention] = "projectUsernames" type="text" [(ngModel)]="expectedResultText"  #actualResult="ngModel" class="blocker-input resultsInput"
                         placeholder="Add Actual Result" [disabled] = "story && checkRestriction()" (keyup.enter)="!disable_story_board && actualResult?.value && updateExpResultTitle(result,expectedResultText)"  (focusout)="!disable_story_board && actualResult?.value && updateExpResultTitle(result,expectedResultText)">
                      </div>
                      <div class="action-btns" *ngIf="story.checkTestCaseMemberPermission(loggedInUser) && result.status === 'actual'">
                        <span class="edit-btn" (click)="ToggleExpResultEditMode(result)"><em _ngcontent-mqc-c10="" class="iconb-edit"></em></span>
                        <span class="deletebtn" (click)="deleteExpectedResult(result)"><em _ngcontent-mqc-c10="" class="iconb-bin"></em></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Related to feature starts -->
        <div class="inputBlocks4" *ngIf="story && story.story_type === 'bug'">
          <div class="contentWrapper">
            <span class="labelText">Related To
              <div class="infoHoverIcon related_to_hover">
                <img src="../../../assets/images/info-icon.png" alt="info" />
                <span class="elementHover">This section means that this bug "Related to" a user story which caused this bug. To change the user story in this section just search for the story and click enter and story will be replaced as for now only 1 story can be added in this section.</span>
              </div>
            </span>
            <div class="relatedToWrapper">
              <div class="addRelated-to">
                <input type="search" class="addRelated-input" (focus)='enterRelatedStory = true;' [(ngModel)] = "searchRelatedStory" (input) = "getrelatedStories()" placeholder="Add the task story# that caused this bug"/>

                <div class="relatedto-storyList-wrapper" *ngIf="enterRelatedStory">
                  <div class="relatedto-storyList" *ngFor="let relateStory of relateStoryList" (click)="selectRelatedStory(relateStory)">
                    <span class="relatedTo-storyId">[#{{relateStory.id}}]</span> {{relateStory.title}}
                  </div>
                  <div class="parentList-Loader" *ngIf="loaderForRelateStoryList">
                    <img src="../../assets/images/storyBoardLoader.gif" alt="loader"/>
                  </div>
                  <div class="no-story-available" *ngIf = "((relateStoryList.length === 0) && searchRelatedStory)">No story is available.</div>
                </div>
              </div>
              <div class="relatedTo-list with-link" *ngIf = "story && story?.bug_related_to_story" (click) = "gotoStory(story?.bug_related_to_story?.id)">
                <span class="relatedTo-storyId">[#{{story?.bug_related_to_story?.id}}]</span> {{story?.bug_related_to_story?.title}}
              </div>
            </div>
          </div>
        </div>
         <!-- Related to feature ends -->
        <!-- Blockers -->
        <div class="inputBlocks4 blocker-block" *ngIf="!story.testCase()">
          <div class="contentWrapper">
            <span class="labelText">{{getBlockerCount() === 1 ? 'Blocker':'Blockers'}} ({{getBlockerCount()}})</span>
            <div class="blockerInputWrapper">
              <input [mention] = "projectUsernames" type="text" ngModel #blocker="ngModel" class="blocker-input"  (focusout)="!disable_story_board && blocker?.value && addBlocker(blocker)"
                    placeholder="Blocker reason (e.g. story # or @user)" [disabled] = "story && checkRestriction()" (keyup.enter)="!disable_story_board && blocker?.value && addBlocker(blocker)">
            </div>
            <ng-container *ngIf="showBlokerlist.length !== 0">
              <div class="blockerListWrapper">
                <div class="cst-row" *ngFor="let blocker of showBlokerlist let i = index">
                  <div class="cst-block" [ngClass] = "{'blocker-done': blocker.done === true}">
                    <div class="blocker-detailWrapper">
                      <div class="blockerDetail set-icons" *ngIf="!blocker.isText">
                      <span class="st-blockerIcons">
                        <span class="ifBlocker">
                            <em class="iconb-blocker"></em>
                        </span>
                      </span>
                        <span class="id-displayed" (click)="listBlocker(blocker)" *ngIf="!blocker.isText">
                        {{blocker.text}}
                      </span>
                      </div>
                    </div>
                    <div class="blockerInputWrapper" *ngIf="blocker.isShow">
                      <div class="blockerInputWrapper-box">
                        <span class="blockerEdit-icon">
                          <em class="iconb-blocker"></em>
                        </span>
                        <input type="text" [(ngModel)]="blocker.name" [disabled] = "story && checkRestriction()"  class="blocker-input editBlockerInput" [mention] = "projectUsernames">
                        <div class="blockerActionBtn">
                          <button class="add-btn" [disabled]='blocker.name?.length === 0' (click)="addBlocker(blocker, '', i)">Update</button>
                          <button class="cancel-btn" (click)="canclelistBlocker(blocker)">Cancel</button>
                        </div>
                      </div>
                    </div>
                    <div class="blocker-actionWrapper" *ngIf="(story && !blocker.isShow && !story.deleted_at)">
                      <div class="restrict-blockerAction" *ngIf="story?.inactive"></div>
                      <div class="blockerStatus" *ngIf="!blocker.isText">
                        <button class="setBlockerStatus" [disabled] = "isProjectReadonly || story?.inactive" (click)="addBlocker(blocker, true, i)"></button>
                      </div>
                      <div class="blockerAction" (click)="openBlockerStoryPopup(blocker, 'deleteBlocker')">
                        <em class="iconb-bin"></em>
                        <span class="blockerAction-title">Delete</span>
                      </div>
                      <div class="blockerAction" *ngIf="!blocker.done" (click)="listBlocker(blocker)">
                        <em class="iconb-edit"></em>
                        <span class="blockerAction-title">Edit</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="story?.related_stories.length > 0">
              <div class="relatedTo-list with-link make-margin" *ngFor="let r_story of story.related_stories"  (click) = "gotoStory(r_story.id)">
                <span class="relatedTo-storyId">[#{{r_story.id}}]</span> {{r_story.title}}
              </div>
            </ng-container>
          </div>
        </div>
        <!-- Blockers -->
        <!-- Blocked Stories -->
        <div class="inputBlocks4 blocker-block blocked-block" *ngIf = "story?.blocked_stories && story?.blocked_stories.length > 0 && !story.testCase()">
          <div class="contentWrapper">
            <span class="labelText" *ngIf = "story?.blocked_stories && story?.blocked_stories.length > 0" >Blocks ({{story?.blocked_stories.length}}) </span>
            <ng-container *ngIf="story?.blocked_stories && story?.blocked_stories.length > 0">
              <div class="blockerListWrapper">
                <div class="cst-row" *ngFor="let block of story?.blocked_stories">
                  <div class="blockerDetail set-icons">
                  <span class="st-blockerIcons">
                      <span class="ifBlockedBy">
                        <em class="iconb-blocker"></em>
                      </span>
                  </span>
                    <span class="id-displayed">
                    {{block}}
                  </span>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
        <!-- Blocked stories -->
        <!---MR block starts-->
        <div class="inputBlocks4 mr-block" *ngIf = "!story.isDesignStory() && !story.testCase() && !story.checkE2EStory()">
          <div class="contentWrapper" *ngIf = "story">
            <span class="labelText">{{story.merge_requests.length == 1 ? 'Merge request' : 'Merge requests'}} ({{story.merge_requests.length}})</span>
            <div class="mr-block-InputWrapper">
              <input type="text" ngModel #mergeRequest="ngModel" class="mr-block-input"  (focusout)="!disable_story_board && mergeRequest?.value && addMergeRequest(mergeRequest)"
                placeholder="Enter Merge Request URL"   [disabled] = "story && checkRestriction()" (keyup.enter)="!disable_story_board && mergeRequest?.value && addMergeRequest(mergeRequest)">
            </div>
            <ng-container *ngIf="story.merge_requests.length !== 0">
              <div class="mr-block-ListWrapper">
                <div class="mr-list" *ngFor="let mergeRequest of story.merge_requests let i = index">
                  <a target="_blank" rel="noopener" class="mr-info" href="{{mergeRequest.commit.url}}">{{mergeRequest.commit.url}}</a>
                  <em class="iconb-bin delete-mr" *ngIf="(story && !story.deleted_at && !story?.inactive)" (click)="deleteMergeRequest(mergeRequest)"></em>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
        <!---MR block ends-->
        <div class="inputBlocks4"  *ngIf = "!story.isDesignStory() && !story.testCase() && !story.checkE2EStory()">
          <div class="contentWrapper" *ngIf = "story">
            <span class="labelText">Code ({{(story.commits && story.commits.length ? story.commits.length : 0)}}) <span class="learnMore" (click)="openComPopup()">Learn more!</span></span>
            <div class="commentfilter" *ngIf = "story && story.commits && story.commits.length > 0">
              <div class="commentFilter-selected">
                <span class="commentFilter-text">Sort by:</span>
                <span class="commentFilter-selectedOption" (click)="focusDropDown('sort_commits')">{{commit_sort_order}}</span>
                <em class="iconb-dd-down" (click)="focusDropDown('sort_commits')"></em>
              </div>
              <div class="commentFilter-DD" [ngClass]="{'commentFilter-DDopen': showDropDown && dropDownType==='sort_commits'}">
                <span class="commentFilter-optionList" (click)="commit_sort_order = 'New to Old'">New to Old</span>
                <span class="commentFilter-optionList" (click)="commit_sort_order = 'Old to New'">Old to New</span>
              </div>
            </div>
            <div class="commitListWrapper">
              <ng-container *ngIf = "(story && story.commits && story.commits.length > 0); else nocontent">
                <div class="commitlist" *ngFor = "let commit of (story.commits | notesSort:commit_sort_order:'committed_on')">
                  <div class="commitInfo">
                    <div class="commit_meta">
                      <span class="commitUser-initials">{{commit?.initials | slice: 0:1}}</span>
                      <span class="commitUser-name">{{commit?.author}}</span>
                      <div class="comnitActionButton">
                        <span class="iconb-more-vert" (click) = "commitOption(commit)"></span>
                        <div class="commitActionDD-backdrop" *ngIf="commit?.show_copy_commit" (click) = "commitOption(commit)"></div>
                        <div class="commitActionDD" [ngClass] = "{showDD: commit?.show_copy_commit}">
                          <span class="commitActionList copylink" (click) = "copyCommitLink(commit)">Copy Link</span>
                        </div>
                      </div>
                      <span class="commitUser-time">{{getCommitCreatedAt(commit?.committed_on)}}</span>
                    </div>
                    <div class="showCommit-error" *ngIf="commit.error_message">
                  <span class="showCommit-errorId">
                    <a [href]="commit.url" target="_blank" class="commitId"><em class="iconb-alert"></em> {{commit?.commit_id}}</a>
                  </span>
                      <span class="commitDesText">We could not change the story status as it is un-estimated or owner is not assigned.</span>
                    </div>
                    <div class="commitDes">
                      <a [href]="commit.url" target="_blank" class="commitId" *ngIf="!commit.error_message">{{commit?.commit_id}}</a>
                      <span class="commitDesText">
                    {{commit?.message}}
                  </span>
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-template #nocontent><div class="noCommit-message">No commits yet!</div></ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="acceptance-comment-Activity-wrapper" *ngIf="story">
      <ng-container *ngIf="story && (!story.parent_id || (story.parent_id && story.acceptance_criteria.length > 0))">
        <div class="inputBlocks4" *ngIf="story && story.story_type !== 'bug' && this.projectService.storySizeExpand && !story.isTestCaseOrBug()">
          <div class="contentWrapper">
            <div class="labelText">Acceptance Criteria 
              <div class="alert-subtask-info" *ngIf="story && story.subTask()">
                <span class="infoHoverIcon"><img src="../../../assets/images/info-icon.png" alt="info" /></span>
                <span class="infoHover">Description and Acceptance Criteria can be edited on the Parent story level i.e. User story level.</span>
              </div>
              <button type="button" class="add-criteria" [disabled]="story.show_create_criteria" (click)="openAddCriteria()" *ngIf= "!story.parent_id && !checkRestriction() && !validateStoryPermission() && storyService.allowStoryUpdateFlag && story.acceptance_criteria.length > 0 ">+</button>
            </div>
            <div class="criteria-list-wrapper">
              <div class="criteria-list" *ngIf="story && !story.parent_id && story.show_create_criteria && !(checkRestriction() || validateStoryPermission())">
                <div class="criteria-title">
                  <input 
                  type="text" 
                  class="criteria-title-input" 
                  (focusout)="checkCreateCriteria(add_criteria_title)" 
                  [(ngModel)]="add_criteria_title" 
                  [disabled]="checkRestriction() || validateStoryPermission() || create_criteria_loader"
                  placeholder="Enter criteria name" />
                  <span class="elementHover-table" *ngIf="validateStoryPermission()">You are not authorized to perform this action.<br/> Please contact your delivery manager.</span>
                  <div class="delete-criteria-button" *ngIf="!checkRestriction() && storyService.handleAcceptanceCriteriaDeleteForGPT && !validateStoryPermission()  && story.acceptance_criteria.length > 0">
                    <button type="button" class="iconb-bin delete-criteria" (click)="closeAddCriteria()"></button>
                  </div>
                </div>
                <div class="description-format-wrapper">
                  <div class="common-Loader commonPopup-Loader" *ngIf="create_criteria_loader">
                    <img src="../../assets/images/storyBoardLoader.gif" alt="loader"/>
                  </div>
                  <div class="format-type-list">
                    <div class="format-type-title">Given</div>
                    <div class="format-type-description">
                      <textarea 
                      class="format-inout" 
                      name="add_criteria_given" 
                      (focusout)="checkCreateCriteria(add_criteria_given)" 
                      [disabled]="checkRestriction() || validateStoryPermission() || create_criteria_loader"
                      [(ngModel)]="add_criteria_given"  
                      autosize 
                      placeholder='<Conditions>'></textarea>
                      <span class="elementHover-table" *ngIf="validateStoryPermission()">You are not authorized to perform this action.<br/> Please contact your delivery manager.</span>
                    </div>
                  </div>
                  <div class="format-type-list">
                    <div class="format-type-title">When</div>
                    <div class="format-type-description">
                      <textarea 
                      class="format-inout" 
                      name="add_criteria_when" 
                      (focusout)="checkCreateCriteria(add_criteria_when)" 
                      [disabled]="checkRestriction() || validateStoryPermission() || create_criteria_loader"
                      [(ngModel)]="add_criteria_when" 
                      autosize 
                      placeholder='<Action performed>'></textarea>
                      <span class="elementHover-table" *ngIf="validateStoryPermission()">You are not authorized to perform this action.<br/> Please contact your delivery manager.</span>
                    </div>
                  </div>
                  <div class="format-type-list">
                    <div class="format-type-title">Then</div>
                    <div class="format-type-description">
                      <textarea 
                      class="format-inout" 
                      name="add_criteria_then" 
                      (focusout)="checkCreateCriteria(add_criteria_then)" 
                      [disabled]="checkRestriction() || validateStoryPermission() || create_criteria_loader"
                      [(ngModel)]="add_criteria_then" 
                      autosize 
                      placeholder='<Expected results>'></textarea>
                      <span class="elementHover-table" *ngIf="validateStoryPermission()">You are not authorized to perform this action.<br/> Please contact your delivery manager.</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="criteria-list" *ngFor="let criteria of story.acceptance_criteria">
                <div class="common-Loader commonPopup-Loader" *ngIf="criteria.criteria_loader">
                  <img src="../../assets/images/storyBoardLoader.gif" alt="loader"/>
                </div>
                <div class="criteria-title">
                  <div class="criteria-title-input" *ngIf="story && story.parent_id">{{criteria.title}}</div>
                  <input *ngIf="!story.parent_id" 
                  type="text" 
                  class="criteria-title-input" 
                  (input)="keepEditingAlive('criteria_editing', criteria.criteria_description_edited, criteria)" 
                  (focusout)="checkAcceptanceCriteriaEdited(criteria, 'title')" 
                  [disabled] = "checkRestriction() || validateStoryPermission()" 
                  [(ngModel)]="criteria.title"/>
                  <span class="elementHover-table" *ngIf="validateStoryPermission()">You are not authorized to perform this action.<br/> Please contact your delivery manager.</span>
                  <div class="criteria-action-buttons">
                    <div class="delete-criteria-button is-copy-button" (click)="copyAcceptanceCriteriaId()" [clip]="criteria.id">
                      <button type="button" class="iconb-copyid delete-criteria is-copy-id"></button>
                      <span class="delete-criteria-hover">{{criteriaLinkCopied ? 'Copied':'Copy Acceptance Criteria id'}}</span>
                    </div>
                    <div class="delete-criteria-button" *ngIf="!checkRestriction() && storyService.handleAcceptanceCriteriaDeleteForGPT && !validateStoryPermission() &&  !story.parent_id">
                      <button type="button" class="iconb-bin delete-criteria" (click)="openDelteAcceptanceCriteria(criteria, true)" [disabled]="story.acceptance_criteria.length <= 1"></button>
                      <span class="delete-criteria-hover" *ngIf="story.acceptance_criteria.length <= 1">Atleast 1 acceptance criteria should always be there.</span>
                    </div>
                  </div>
                </div>
                <div class="description-format-wrapper">
                  <div class="format-type-list">
                    <div class="format-type-title">Given</div>
                    <div class="format-type-description">
                      <div class="format-inout if-no-given" *ngIf="story && story.parent_id">{{criteria.description_for_given}}</div>
                      <textarea *ngIf="story && !story.parent_id" 
                        class="format-inout if-empty" 
                        (input)="keepEditingAlive('criteria_editing', criteria.criteria_description_edited, criteria)" 
                        (focusout)="checkAcceptanceCriteriaEdited(criteria, 'description_for_given')" 
                        name="description_for_given" 
                        [disabled] = "checkRestriction() || validateStoryPermission()"  
                        autosize 
                        [(ngModel)]="criteria.description_for_given"
                        placeholder='<Conditions>'></textarea>
                      <span class="elementHover-table" *ngIf="validateStoryPermission()">You are not authorized to perform this action.<br/> Please contact your delivery manager.</span>
                    </div>
                  </div>
                  <div class="format-type-list">
                    <div class="format-type-title">When</div>
                    <div class="format-type-description">
                      <div class="format-inout if-no-when" *ngIf="story && story.parent_id">{{criteria.description_for_when}}</div>
                      <textarea *ngIf="story && !story.parent_id" 
                        class="format-inout if-empty" 
                        (input)="keepEditingAlive('criteria_editing', criteria.criteria_description_edited, criteria)" 
                        (focusout)="checkAcceptanceCriteriaEdited(criteria, 'description_for_when')" 
                        name="description_for_when" 
                        [disabled] = "checkRestriction() || validateStoryPermission()"  
                        autosize 
                        [(ngModel)]="criteria.description_for_when"
                        placeholder='<Action performed>'></textarea>
                      <span class="elementHover-table" *ngIf="validateStoryPermission()">You are not authorized to perform this action.<br/> Please contact your delivery manager.</span>
                    </div>
                  </div>
                  <div class="format-type-list">
                    <div class="format-type-title">Then</div>
                    <div class="format-type-description">
                      <div class="format-inout if-no-then" *ngIf="story && story.parent_id">{{criteria.description_for_then}}</div>
                      <textarea *ngIf="story && !story.parent_id" 
                        class="format-inout if-empty" 
                        (input)="keepEditingAlive('criteria_editing', criteria.criteria_description_edited, criteria)" 
                        (focusout)="checkAcceptanceCriteriaEdited(criteria, 'description_for_then')" 
                        name="description_for_then" 
                        [disabled] = "checkRestriction() || validateStoryPermission()"  
                        autosize 
                        [(ngModel)]="criteria.description_for_then"
                        placeholder='<Expected results>'></textarea>
                      <span class="elementHover-table" *ngIf="validateStoryPermission()">You are not authorized to perform this action.<br/> Please contact your delivery manager.</span>  
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <div class="inputBlocks4">
        <div class="contentWrapper">
          <span class="labelText" *ngIf="!projectService.show_Story_history">
            {{story?.notes.length==1?'Comment':'Comments'}} ({{story?.notes.length}})
            <button type="button" class="showActivity" (click)="toggle_comment_history('history')">Show Activity</button>
          </span>
          <span class="labelText" *ngIf="projectService.show_Story_history">
            Activity
            <button type="button" class="showActivity" (click)="toggle_comment_history('comments')">Show Comments</button>
          </span>
          <div class="commentListWrapper-common">
            <div id="activityDIV" class="notelist">
              <div class="note_form">
                <div class="attachments" [@docUploader] *ngIf="uploaderArr.length > 0">
                  <div class="attachment" *ngFor="let item of uploaderArr; let i = index">
                    <span class="uploading-listing" (click)="openAuthorPopup(item,'name',i)">
                      <div class="uploading-listing-thumbnail not_maximized">
                        <img *ngIf = "sharedService.checkType(item.name, true) == 'image'" [src]="attachmentPreviewUrls[i]" alt="loader"/>
                        <a *ngIf = "sharedService.checkType(item.name, true) == 'doc' || sharedService.checkType(item.name, true) == ''" class="gotoImage" [href]="attachmentPreviewUrls[i]" target="_blank" rel="noopener">
                            <img class="default-attachment" src="/assets/images/defaullt-download.png" alt="loader"/>
                        </a>
                        <div *ngIf = "sharedService.checkType(item.name, true) == 'video'" class="videoContainer" (click)="openVideoPopup(attachmentPreviewUrls[i], true)">
                            <em class="iconb-play-circle"></em>
                        </div>
                      </div>
                      <div class="uploading-file-description">
                        <span class="docuName">{{ item?.name }}</span>
                        <span class="docuSize">{{ sharedService.getFileSizeText(item?.size) }}</span>
                      </div>
                    </span>
                    <span class="removeDocument" (click)="removeNoteDocument(item)">
                      <em class="iconb-bin"></em>
                    </span>
                  </div>
                </div>
                <div class="flex-wrapper">
                  <div class="commentAreaBtn">
                    <div class="noteActionWrapper">
                      <div class="add-note">
                        <button type="button" [disabled]="story && (commentNoteUploading || checkRestriction())" class="postComment" (click)="addNewNote(note)">Comment</button>
                      </div>
                      <div class="attach-file">
                        <label class="labelForFileType">
                          <em class="iconb-attach" [ngClass]="{'iconDisabled': isProjectReadonly || story?.inactive || story?.deleted_at}"></em>
                          <input type="file"
                                  #attachmentInput
                                  id="file"
                                  class="forFiletype"
                                  [disabled]="isProjectReadonly || story?.inactive || story?.deleted_at"
                                  (click)="attachmentInput.value = null" value=""
                                  (change)="attachFilesInComment($event)"
                                  multiple />
                        </label>
                      </div>
                    </div>
                  </div>
                  <textarea
                  class="textAreaForComment"
                  #maintainHeight
                  appDragDropFile
                  (files)="attachDroppedFilesInComment($event)"
                  (keyup) = "searchMentionUser($event)"
                  #note="ngModel"
                  name="note" 
                  [disabled] = "story && checkRestriction()"  
                  autosize 
                  [(ngModel)]="searchText"
                  placeholder='Add a comment...'>
                </textarea>
              </div>
              <div class = "mentiondropdown" *ngIf = "showMention && mentionUsers.length > 0">
                <div #mentionUsersList class="mentionSelection-list" *ngFor = "let user of mentionUsers; index as i;"
                      (click) = "selectMention(user)" (mouseenter)="setSelectedIndex(i)">
                  <div class="mentionUser-initial">{{user.initials | slice:0:1}}</div>
                  <div class="mentionUser-descriptionWrapper">
                    <span class="mentionUser-name">{{user.name | titlecase}}</span>
                    <span class="mentionUser-email">{{user.email}}</span>
                  </div>
                </div>
              </div>
              </div>
            </div>
          </div>
          <div *ngIf="!projectService.show_Story_history && projectService.render_storyDetails">
            <story-note class="story-note-com" [story] = "story" [project]="project"></story-note>
          </div>
          <app-activity-timeline *ngIf="projectService.show_Story_history  && projectService.render_storyDetails" [project]="project" [subject_id]="story.id" [subject_type]="'story'"></app-activity-timeline>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="showConvertSubtaskPopup" class="commonPopUp">
  <div class="commonPopUp-backdrop" (click)="closeConvertSubtaskPopup()"></div>
  <div class="commonPopUp-wrapper">
    <div class="common-Loader commonPopup-Loader" *ngIf="waitForLoader">
      <img src="../../assets/images/storyBoardLoader.gif" alt="loader"/>
    </div>
    <div class="commonPopUp-title">Convert Subtasks to Parent Task</div>
    <div class="commonPopUp-text">{{promotePopupMessage()}}
      <span *ngIf="validSelectedSubtasks(false).length > 0"> {{ validSelectedSubtasks(false).length }} subtask(s) will not be promoted because:
        <ul>
          <li>It is a core subtask (i.e. Created by CMT or Tracker).</li>
          <li>It is a design subtask and a feature can only 1 design story per device/platform per role.</li>
        </ul>
      </span>
    </div>
    <div class="list-title" *ngIf="validSelectedSubtasks(false).length > 0">Subtasks which are not promoted:</div>
    <div class="selectedStoriesList-wrapper" *ngIf="validSelectedSubtasks(false).length > 0">
      <div class="selectedStoriesList" *ngFor = "let task of validSelectedSubtasks(false)">
        <div class="selectedStoriesList-left">
          <div class="story-typeIcon">
            <em class="iconb-task featureIcon" *ngIf = "task.story_type == 'task'"></em>
            <em class="iconb-bug bugIcon" *ngIf = "task.story_type == 'bug'"></em>
          </div>
          <div class="story-NameBox">
            <span class="selected-storyName">{{task.title}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="commonPopUp-actionBtn" style="margin-top: 10px;">
      <span class="commonPopUp-submit-s" *ngIf="validSelectedSubtasks(true).length > 0" (click)="moveSubtasksTotask('convertSubtask')">Yes</span>
      <span class="commonPopUp-cancel-s" [ngClass]="{'no-margin' : validSelectedSubtasks(true).length === 0}" (click)="closeConvertSubtaskPopup()">Cancel</span>
    </div>
  </div>
</div>

<div *ngIf="openAuthor" class="authorBioWrapper" [ngClass]="{'openAuthorBio' : openAuthor}">
  <div class="backdropClose" (click)="closeAuthorPopup()"></div>
  <em class="closeFun iconb-close" (click)="closeAuthorPopup()">close</em>
  <div class="authorContent">
    <img src="{{openItemDoc}}" alt="loader"/>
  </div>
</div>

<!-- selection of bug reasons lists popup -->
<div class="warningPopUp" *ngIf = "openBugReasonsPopup && project?.bug_reasons.length">
  <div class="warningPopUp-backdrop" (click)="closeBugReasonPopup()"></div>
  <div class="warningPopUp-wrapper bugSelection-wrapper">
    <em class="closeFun iconb-close" (click)="closeBugReasonPopup()"></em>
    <div class="warningPopUp-title"> Bug Reason:</div>
    <div class="warningPopUp-subtext" *ngIf="checkDescriptionFormatHasData() && !create_SubTask" style="margin-bottom: 12px;">You are trying to change this task to a bug, due to this you will lose the data in Description and Acceptance Criteria. Are you sure you want to convert this task to bug?</div>
    <div class="bugReason-listWrapper">
      <div class="bugreason-selectBlock">
        <div class="selected-bugreason" (click)="openReasonsLists()">{{ bugReason() }} <em class="iconb-dd-down" [ngClass]="{'makeDown' : showReasonList }"></em></div>
        <div class="dd-wrapper" *ngIf="showReasonList">
          <div class="bugReason-list" *ngFor="let bugReason of project?.mainBugReasons" (click)="selectBugReason(bugReason)">{{bugReason?.title}}</div>
        </div>
      </div>
      <div class="bugreason-selectBlock">
        <div class="ifReadOnly" *ngIf="!bug_reason_id"></div>
        <div class="selected-bugreason" (click)="openDetailReasonsLists()">{{ bugDetailReason() }} <em class="iconb-dd-down" [ngClass]="{'makeDown' : showDetailReasonList }"></em></div>
        <div class="dd-wrapper" *ngIf="showDetailReasonList">
          <div class="bugReason-list" *ngFor="let detailbugReason of project?.detailedBugReason(bug_reason_id)" (click)="selectBugReason(detailbugReason, 'detailedReason')">{{detailbugReason?.title}}</div>
        </div>
      </div>
      <div class="relatedToWrapper inPopup" *ngIf = "(currentStoryTypeChange && !story.bug_related_to_story?.id) || !currentStoryTypeChange">
        <div class="addRelated-to">
          <input type="search" class="addRelated-input" (focus)='enterRelatedStory = true;' [(ngModel)] = "searchRelatedStory" (input) = "getrelatedStories()" placeholder="Add the task story# that caused this bug"/>
          <div class="relatedto-storyList-wrapper" *ngIf="enterRelatedStory">
            <div class="relatedto-storyList" *ngFor="let relateStory of relateStoryList" (click)="selectRelatedStory(relateStory, true)">
              <span class="relatedTo-storyId">[#{{relateStory.id}}]</span> {{relateStory.title}}
            </div>
            <div class="parentList-Loader" *ngIf="loaderForRelateStoryList">
              <img src="../../assets/images/storyBoardLoader.gif" alt="loader"/>
            </div>
            <div class="no-story-available" *ngIf = "((relateStoryList.length === 0) && searchRelatedStory)">No story is available.</div>
          </div>
        </div>
        <div class="relatedTo-list" *ngIf = "bug_related_to_story?.id">
          <span class="relatedTo-storyId">[#{{bug_related_to_story?.id}}]</span> {{bug_related_to_story?.title}}
          <em class="iconb-close reset-related" (click)="resetRelatedTo()"></em>
        </div>
      </div>
    <div class="logReason-actionBt">
      <button type="button" class="warningPopUp-submit" [disabled]="!bug_reason_detail_id || !bug_related_to_story?.id" (click)="submitBugReasonPopup()">Submit</button>
      <span class="warningPopUp-cancel" (click) = "closeBugReasonPopup()" >Cancel</span>
    </div>
  </div>
</div>
</div>

<div class="commitPopup-wrapper" *ngIf="openCommitPopup">
  <div class="commitPopup-contentBlock">
    <em class="iconb-close commitClose-icon" (click)="closeCommitPopup()"></em>
    <div class="commitPopup-heading">GitLab Integration</div>
    <div class="commitPopup-contentWrapper">
      <span class="commitPopup-subHeading">Admins of a Buildcard can create a GitLab integration so that commits and merge requests in GitLab automatically attach to the appropriate stories.</span>
      <div class="commitPopup-listing">
        <span class="ls-text">Using the GitLab integration: Commits</span>
        <span class="commitPopup-text">If your Buildcard has a GitLab integration and webhook setup, you can also attach commits to a story or task's code stream by using a commit message
  tagged with the story/task id. The syntax for your commit message is pretty simple. Add the Builder Tracker story/task id with a preceding hash symbol
  within square brackets at the end of your commit message. Optionally, you can include a state change for the Builder Tracker story within the brackets.
  Currently, there are 3 state changes supported: Started, Finished and Delivered. For example, including "Start" will change the specified story to start
  state, “Finishes” or “Fixes” in your commit message will change the specified story to the Finished state, while “Deliver” will change the specified story
  to Delivered state.</span>
        <span class="finished-boxes">[(start|started|finish|finishes|finished|deliver|delivered|fix|fixes|fixed) #TRACKER_STORY_ID]</span>
        <span class="commitPopup-text">The commit message in the example below adds a commit to story 123123 and changes story state to “Finished” with the “finishes” keyword.</span>
        <span class="finished-boxes">git commit -m "[finishes #123123] Updated settings for holograph projector"</span>
        <span class="commitPopup-text">You can place each story id in its own set of square brackets, and separate each set of brackets with a comma or space. This will prevent story
  ids from showing up in non-related stories.</span>
        <span class="commitPopup-text">
          <span class="finished-boxes">[story_id] Your commit message here</span><br>
          <span class="finished-boxes">Your commit message here [story_id]</span><br>
          <span class="finished-boxes">[#story_id] Your commit message here</span><br>
          <span class="finished-boxes">Your commit message here [#story_id]</span><br>
          <span class="finished-boxes">[finished #story_id] Your commit message here</span><br>
          <span class="finished-boxes">[#story_id Finished] Your commit message here</span><br>
          <span class="finished-boxes">[story_id story_id Delivered] Your commit message here</span><br>
          <span class="finished-boxes">[Delivered #story_id #story_id #story_id] Your commit message here</span><br>
          <span class="finished-boxes">[#story_id Delivered #story_id #story_id] Your commit message here</span><br>
          <span class="finished-boxes">[#story_id story_id Finished] [story_id] [story_id Delivered] Your commit message here</span><br>
        </span>
      </div>
    </div>
    <div class="actionBlock">
      <span class="commitPopup-close" (click)="closeCommitPopup()">Close</span>
    </div>
  </div>
</div>

<app-custom-popup  
[texts] = "{ptitle: 'Delete', ptext: 'Are you sure you want to delete Acceptance Criteria?', psuccess: 'Confirm', pcancel: 'Cancel'}" 
(closeCustomPopUp) = "openDelteAcceptanceCriteria(selected_criteria, false)" 
(deleteElement) = "deleteAcceptanceCriteria(selected_criteria)"  
[OpenPopUp] = "openCriteriaDeleteConfirmation" 
[waitForLoader] = "waitForLoader"></app-custom-popup>

<app-custom-popup  [texts] = "{ptitle: 'Delete', ptext: 'Are you sure you want to delete?', psuccess: 'Delete', pcancel: 'Cancel'}" (closeCustomPopUp) = "closePopUp()" (deleteElement) = "deleteElement($event)" [delement] = "elem" [OpenPopUp] = "OpenBlockerPopUp" [waitForLoader] = "waitForLoader"></app-custom-popup>

<app-custom-popup
  [texts]="{ptitle: this.selected_story_state === 'accepted' ? 'Accept' : (this.selected_story_state === 'rejected') ? 'Reject' : '',
            ptext: 'Are you sure you want to ' + (this.selected_story_state === 'accepted' ? 'accept' : (this.selected_story_state === 'rejected') ? 'reject' : '') + ' ?',
            psuccess: this.selected_story_state === 'accepted' ? 'Accept' : (this.selected_story_state === 'rejected') ? 'Reject' : '', pcancel: 'Cancel'}"
  (closeCustomPopUp)="closeAcceptRejectPopUp()" (deleteElement)="finalStoryState()"[OpenPopUp]="OpenAcceptRejectPopup" [waitForLoader]="waitForLoader"></app-custom-popup>

<app-custom-popup *ngIf="story"
  [texts]="{ptitle: this.selected_story_state === 'accepted' ? 'Accept' : (this.selected_story_state === 'rejected') ? 'Reject' : '',
            ptext: 'There' + (story?.calcPendingTestCasesAndSubtasks() == 1? ' is ' : ' are ') + (story?.calcPendingTestCasesAndSubtasks()) + ' incomplete Task/Test Case' + ' within this story. Please action them before accepting this story.',
            psuccess: 'Okay', pcancel: ''}"
  (closeCustomPopUp)="closeAcceptAlertPopUp()" (deleteElement)="closeAcceptAlertPopUp()" [OpenPopUp]="OpenAcceptAlertPopup" [waitForLoader]="waitForLoader"></app-custom-popup>

<app-custom-popup
  [texts]="{ptitle: 'Warning',
            ptext: 'A story can have only one feature, do you want to select <b>' + addEpic?.title + '</b> ?',
            psuccess: 'Confirm', pcancel: 'Cancel'}"
  (closeCustomPopUp)="closeAddFeaturePopUp()" (deleteElement)="addStoryToEpic(addEpic, story)" [OpenPopUp]="openAddFeaturePopup" [waitForLoader]="waitForLoader"></app-custom-popup>

<app-custom-popup  
*ngIf="story" 
[texts] = "{ptitle: 'Request for Review', ptext: 'Are you sure you want to send this story content for review?', psuccess: 'Confirm', pcancel: 'Cancel'}" 
(closeCustomPopUp) = "storyReviewConfirmation = false" 
(deleteElement) = "requestForReview(story.id, 'request_for_review')" 
[OpenPopUp] = "storyReviewConfirmation" 
[waitForLoader] = "waitForLoader"></app-custom-popup>

<div class="videoPopup-wrapper" *ngIf="showVideo">
    <div class="videoPopup-backdrop"></div>
    <em class="iconb-close closeVideoPopup" (click)="closeVideoPopup()"></em>
    <div class="videoPopup">
      <video width="640px" controls autoplay>
        <source src="{{vURL}}" type='video/mp4' />
        <source src="{{vURL}}" type='video/mov' />
      </video>
    </div>
  </div>

<div class="commitPopup-wrapper" *ngIf="showCommitPopupOnStoryStarted">
  <div class="commitPopup-contentBlock">
    <div class="commitPopup-heading2">Message structure when commiting to Gitlab</div>
    <div class="commitPopup-contentWrapper">
      <div class="commitPopup-listing">
        <span class="commitPopup-text">The structure will be as follows: `[story number] commit message.` To enforce this there
          will be several things that you will need to do on your end. On your local machine, for your Buildcard, please replace
          the file `commit-message.sample` with the file attached in the email. `commit-msg.sample` is found in every Git project
          under the hidden folder `.git`. Inside `.git`, there will be another folder named `hooks`. That is where
          `commit-msg.sample` is located (.git/hooks).</span>
        <span class="commitPopup-text2">`commit-msg` contains the following script:</span>
        <span class="commitPopup-text3">
          <pre>
            #!/bin/bash
            MSG_FILE=$1
            FILE_CONTENT="$(cat $MSG_FILE)"
            # Initialize constants here
            export REGEX='^[[][a-zA-Z0-9]+[]][[:space:]][a-zA-Z0-9].{{"{1,50}"}}$'
            export ERROR_MSG="Commit message format must match regex \"${{"{REGEX}"}}\""
            if [[ $FILE_CONTENT =~ $REGEX ]]; then
            echo "Commit message is valid."
            else
            echo "Bad commit \"$FILE_CONTENT\""
            echo "Please use the following format: [storyNumber] {{"{commitMessage}"}}."
            echo $ERROR_MSG
            exit 1
            fi
            exit 0</pre>
        </span>
        <span class="commitPopup-text">If you are working on a Buildcard that is using Builder's IDE, then please do the same in the virtual machine</span>
      </div>
    </div>
    <div class="actionBlock">
      <span class="commitMessage-button" (click)="closeCommitPopupOnStoryStarted()">Go Back</span>
      <span class="commitMessage-button" (click)="clickedOnIUnderstand()">I Understand</span>
    </div>
  </div>
</div>

<app-custom-popup
  [texts]="{ptitle: 'Warning',
            ptext: 'No commit was found for this story on GitLab.<b> Learn More!</b> for more details.',
            psuccess: 'OK', pcancel: 'Cancel'}"
  (closeCustomPopUp)="closeCommitWarningPopup()" (deleteElement)="openComPopup()" [OpenPopUp]="showCommitWarningPopup" [waitForLoader]="waitForLoader">
</app-custom-popup>

<div class="warningPopUp" *ngIf = "showMatchingPopup">
  <div class="warningPopUp-backdrop" (click)="closeArchivePopUp()"></div>
  <div class="warningPopUp-wrapper">
    <div class="warningPopUp-title"><em class="iconb-alert"></em> Warning</div>
    <div class="warningPopUp-text">"A similar core story already exists on this board: <span class = "similar-story-link" (click) = "goToStory(story.matching_story?.id)">{{story.matching_story?.id}}"</span>.</div>
    <div class="warningPopUp-text">Do you still want to continue writing this custom story?</div>
    <div class="warningPopUp-actionBtn">
      <span class="warningPopUp-cancel" (click) = "showMatching('No')" >No</span>
      <span class="warningPopUp-submit"  (click) = "showMatching('Yes')" >Yes</span>
    </div>
  </div>
</div>

<div *ngIf="OpencommonPopUp" class="commonPopUp">
  <div class="commonPopUp-backdrop" (click)="closecommonPopUp()"></div>
  <div class="commonPopUp-wrapper">
    <div class="common-Loader commonPopup-Loader" *ngIf="waitForLoader">
      <img src="../../assets/images/storyBoardLoader.gif" alt="loader"/>
    </div>
    <div class="commonPopUp-title">{{story.testCase() ? 'Delete' : 'Archive'}}</div>
    <div class="commonPopUp-text" *ngIf="!story.testCase()">The status of the story is {{ story.state }} and time tracked is {{ sharedService.totalTimeTracked(story) }}, are you sure you want to archive it?</div>
    <div class="commonPopUp-text" *ngIf="story.testCase()">Are you sure you want to delete this test case. Please confirm as it cannot be retrieved.</div>
    <div class="commonPopUp-actionBtn">
      <span class="commonPopUp-submit-s" (click)="archive()">{{story.testCase() ? 'Delete' : 'Archive'}}</span>
      <span class="commonPopUp-cancel-s" (click)="closecommonPopUp()">Cancel</span>
    </div>
  </div>
</div>

<div class="bulkaction-popUp" *ngIf="detachment_options">
  <div class="bulkaction-popUpBackdrop" (click)="closeDevicePlateformpopup()"></div>
  <div class="bulkaction-popUp-wrapper">
    <div class="bulkaction-popUp-title">Platform Story Options</div>
    <span class="bulkaction-popUp-Text2">Are you sure you want to create a separate parent story for the platform {{story.detachedPlatformData(story.detached_platform_id)}}? </span>
    <span class="bulkaction-popUp-Text3"> Please note: If this action is performed once, platforms cannot be tagged to this story.</span>
    <div class="bulkaction-popUp-epicListing no-min-height">
      <div class="moveSelectionlist-wrapper">
        <div class="moveSelection-list device-platform">
          <div class="moveSelection-radio">
            <label class="customRadioFor-moveSelection">
              <input type="radio" id="option_1" class="move-selected-radio" (change)="selectedOptionForPlatformDetach($event, 'duplicate_parent_only')" name="selectToCreate" hidden/>
              <span class="radio-toggleMoveSelection"></span>
            </label>
          </div>
          <label for="option_1" class="moveSelection-epicName">
            <span class="moveName">Duplicate user story for platform {{story.detachedPlatformData(story.detached_platform_id)}}.</span>
          </label>
        </div>
        <div class="moveSelection-list device-platform">
          <div class="moveSelection-radio">
            <label class="customRadioFor-moveSelection">
              <input type="radio" id="option_2" class="move-selected-radio" (change)="selectedOptionForPlatformDetach($event, 'duplicate_sub_tasks')" name="selectToCreate" hidden/>
              <span class="radio-toggleMoveSelection"></span>
            </label>
          </div>
          <label for="option_2" class="moveSelection-epicName">
            <span class="moveName">Duplicate user story with subtasks.</span>
          </label>
        </div>
        <div class="moveSelection-list device-platform">
          <div class="moveSelection-radio">
            <label class="customRadioFor-moveSelection">
              <input type="radio" id="option_3" class="move-selected-radio" (change)="selectedOptionForPlatformDetach($event, 'do_nothing')" name="selectToCreate" hidden/>
              <span class="radio-toggleMoveSelection"></span>
            </label>
          </div>
          <label for="option_3" class="moveSelection-epicName">
            <span class="moveName">Do not create anything</span>
          </label>
        </div>
      </div>
    </div>
    <div class="bulkaction-popUp-actionBtn">
      <button class="bulkaction-popUp-submit" type="button" [disabled] = "!story.platform_detach_story_option" (click)="checkStoryCreationForPlatform()">Confirm</button>
      <button class="bulkaction-popUp-cancel" type="button" (click)="closeDevicePlateformpopup()">Cancel</button>
    </div>
  </div>
</div>

<div class="bulkaction-popUp" *ngIf="openMoveSubtask">
  <div class="bulkaction-popUpBackdrop" (click)="closeMoveSubtaskPopup()"></div>
  <div class="bulkaction-popUp-wrapper">
    <div class="bulkaction-popUp-title">Move subtasks to different task</div>
    <span class="bulkaction-popUp-Text1">
      <p *ngIf="validateSubtasksForMove().length > 0 && !ifNoParentTask">Are you certain about moving this subtask to another task? Please bear in mind that if this subtask has any of its own subtasks, they will also be detached from the main story.</p><br><br>
      You have selected {{selectedSubtasks.length}} {{selectedSubtasks.length > 1 ? 'Subtasks': 'Subtask'}} for moving to another task. <span *ngIf="validateSubtasksForMove(false).length > 0 || ifNoParentTask">{{validateSubtasksForMove(false).length}} {{validateSubtasksForMove(false).length > 1 ? 'Subtasks': 'Subtask'}} will not 
      be moved because:
        <ul>
          <li *ngIf="project.external()">- It is a core subtask (i.e. Created by CMT or Tracker).</li>
          <li>- All the stories are either accepted or in a different phase.</li>
        </ul></span>
    </span>
    <div class="search-box" *ngIf="validateSubtasksForMove().length > 0 && !ifNoParentTask">
      <input type="search" (input) = "getSearchParentStories()" [(ngModel)] = "searchParentStories"  class="bulkaction-epicSearch" placeholder="Search for task name or ID" />
      <button type="button" class="bulkaction-epicSearchButton">
        <em class="iconb-search"></em>
      </button>
    </div>
    <div class="bulkaction-popUp-epicListing"
    *ngIf="validateSubtasksForMove().length > 0 && !ifNoParentTask"
    infiniteScroll
    [scrollWindow]="false"
    [infiniteScrollDisabled]="!isScrollFetchAvailable()"
    (scrolled)="getMoreParentStories()">
      <div class="moveSelectionlist-wrapper">
        <div class="moveSelection-list" *ngFor = "let task of parentTasksList; index as i;">
          <div class="moveSelection-radio">
            <label class="customRadioFor-moveSelection">
              <input type="radio" id="{{'move_checkSelect_1_' + i}}" class="move-selected-radio" (change)="selectStoryForMove($event, task)" name="selectToMove" hidden/>
              <span class="radio-toggleMoveSelection"></span>
            </label>
          </div>
          <label class="moveSelection-epicName" for="{{'move_checkSelect_1_' + i}}">
            <div class="subT-status">
              <div [class]='storyStatusIconSelectedClass(task.state)'></div>
              <span class="elementHover-extra">task status</span>
            </div>
            <span class="moveName">{{task?.title}}</span>
          </label>
          <div class="userInitials-block">
            <div class="usersInitials">
              {{ task?.requested_by_initials?.substr(0,1) }}
              <span class="elementHover2">{{task?.requested_by_name}}</span>
            </div>
            <div class="usersInitials" *ngIf="task?.owned_by_name">
              {{ task?.owned_by_initials?.substr(0,1) }}
              <span class="elementHover2">{{task?.owned_by_name}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="parentList-Loader" *ngIf="loaderForTaskList || loaderForTaskListSearch">
        <img src="../../assets/images/storyBoardLoader.gif" alt="loader"/>
      </div>
      <!-- <div class="parentList-Loader" *ngIf="loaderForTaskListSearch">
        <img src="../../assets/images/storyBoardLoader.gif" alt="loader"/>
      </div> -->
      <div class="no-notifications" *ngIf="ifNoParentTask">No story is available. All the stories are either accepted or in a different phase.</div>
    </div>
    <div class="bulkaction-popUp-actionBtn">
      <button class="bulkaction-popUp-submit" type="button" *ngIf="validateSubtasksForMove().length > 0 && !ifNoParentTask" (click) = "moveSubtasksTotask('moveSubtask')" [disabled] = "!moveToStory">Move</button>
      <button class="bulkaction-popUp-cancel" type="button" (click)="closeMoveSubtaskPopup()">Cancel</button>
    </div>
  </div>
</div>

<div class="commonPopUp" *ngIf="storyService.story_start_confirmation">
  <div class="commonPopUp-backdrop" (click)="closeStoryStartConfirmation()"></div>
  <div class="commonPopUp-wrapper">
    <div class="bulkAction-Loader" *ngIf="loaderForConvertSubtask">
      <img src="../../assets/images/storyBoardLoader.gif" alt="loader"/>
    </div>
    <div class="storyStartConfirmation-wrapper">
      <div class="storyStartConfirmation-title">You are ready to start this user story <em class="iconb-total-activeuser"></em> </div>
      <div class="storyStartConfirmation-text">Before you start, we want to ensure you're ready. If there are any concerns with below, please reach out to your productologist.</div>
      <div class="option-title">Please confirm-</div>
      <div class="confirmOption-wrapper">
        <div class="confirmOption-list" *ngFor="let list of storyService.confirm_option_list">
          <span class="check-icon"></span> 
          <span class="confirmOption-text">{{list.option}}</span>
        </div>
      </div>
    </div>
    <div class="commonPopUp-actionBtn">
      <span class="commonPopUp-submit-s" (click)="storyService.readyToWork()">Yes, proceed</span>
      <span class="commonPopUp-cancel-s" (click)="closeStoryStartConfirmation()">Cancel</span>
    </div>
  </div>
</div>

<div *ngIf="openUnarchivePopUp" class="commonPopUp">
  <div class="commonPopUp-backdrop" (click)="closeUnarchivePopUp()"></div>
  <div class="commonPopUp-wrapper">
    <div class="bulkAction-Loader" *ngIf="archived_story_detail_loader">
      <img src="../../assets/images/storyBoardLoader.gif" alt="loader"/>
    </div>
    <div class="commonPopUp-title">Unarchive</div>
    <div class="commonPopUp-text" *ngIf="projectService.bbExists(story)">
      Story cannot be unarchived as this feature is in Ready for certification or BB exists.
    </div>
    <div class="commonPopUp-text" *ngIf="!story?.parent_id && !projectService.bbExists(story)">
      {{ checkParentArchiveDetail() ? 'Are you sure you want to unarchive this story?' : 'This story is a parent story with ' + (archived_story_detail?.archived_subtask_count) + ' subtasks. Do you want to unarchive all subtasks?' }} 
    </div>
    <div class="commonPopUp-text" *ngIf="story?.parent_id && !projectService.bbExists(story)">
      {{ archived_story_detail.parent_deleted_at ?  'This story is a subtask of a parent story which is in an archived state. Do you still want to unarchive it as it will lose its connection with its parent story?' : 'Are you sure you want to unarchive this story?' }}
    </div>
    <div class="commonPopUp-actionBtn">
      <span class="commonPopUp-submit-s" *ngIf="!projectService.bbExists(story)" (click)="unarchive()">Confirm</span>
      <span class="commonPopUp-cancel-s" (click)="closeUnarchivePopUp()">Cancel</span>
    </div>
  </div>
</div>

<!-- selection of Story Update reasons lists popup -->
<div class="warningPopUp" *ngIf = "openTimerReasonsPopup">
  <div class="warningPopUp-backdrop" ></div>
  <div class="warningPopUp-wrapper storyUpdate-reason">
    <div class="warningPopUp-title">{{(descriptionInEditMode || story.description_format_edited || selected_criteria?.criteria_description_edited) ? "Can you please spare a moment and let us know why you're editing this user story:" : "Can you please spare a moment to let us know why you're updating hours?"}} </div>
    <div class="bugReason-listWrapper">
      <div class="bugreason-selectBlock">
        <div class="selected-bugreason" (click)="openStoryUpdateReasonsLists()">{{ story_update_reason_option ?  story_update_reason_option : 'Select reason'}} <em class="iconb-dd-down" ></em></div>
        <div class="dd-wrapper" *ngIf="showStoryupdateReasonList">
          <div class="bugReason-list" *ngFor="let updateReason of story_update_reason" (click)="selectStoryupdateReason(updateReason)">{{updateReason?.reason}}</div>
        </div>
      </div>
      <div class="logReason-actionBt">
        <button type="button" class="warningPopUp-submit" [disabled]="!story_update_reason_option" (click)="updateReason('proceed')">Proceed</button>
        <span class="warningPopUp-cancel" (click) = "updateReason('cancel')" >Cancel</span>
      </div>
    </div>
  </div>
</div>

<div class="commonPopUp" *ngIf="readyForCertificationPopUp">
  <div class="commonPopUp-backdrop" (click)="readyForCertificationAction('No')"></div>
  <div class="commonPopUp-wrapper">
    <div class="bulkAction-Loader" *ngIf="loaderForConvertSubtask">
      <img src="../../assets/images/storyBoardLoader.gif" alt="loader"/>
    </div>
    <div class="certificationConfirmation-wrapper">
      <div class="storyStartConfirmation-title" *ngIf="!read_only_checklist">You are ready to send this Story for certification.</div>
      <div class="storyStartConfirmation-text" *ngIf="!read_only_checklist">Before you send this story for certification, please ensure the pre-certification checks listed in the doc below have been adhered to.</div>
      <div class="option-title">{{read_only_checklist ? 'Can we please ensure to follow this checklist before sending a story for certification:' : 'Please confirm-'}}</div>
      <div class="confirmOption-wrapper">
        <div class="confirmOption-list" *ngFor="let checklist of checklist_certification_options; index as i;">
          <label class="customCheckFor-certificationList" *ngIf="!read_only_checklist">
            <input type="checkbox" class="certificationList-checkbox" (change)="toggleCheckCertificationList($event, i)" [checked]="checklist.isChecked" hidden/>
            <span class="check-certificationList"></span>
          </label>
          <span class="confirmOption-text" [ngClass]="{'read_only':read_only_checklist}" [innerHtml]="checklist.list"></span>
        </div>
      </div>
    </div>
    <div class="commonPopUp-actionBtn">
      <button type="button" class="commonPopUp-submit-s" *ngIf="!read_only_checklist" [disabled]="!checkCertificationOptionsSelected()" (click)="readyForCertificationAction('Yes')">Yes</button>
      <span class="commonPopUp-cancel-s" (click)="!read_only_checklist ? readyForCertificationAction('No') : closereadyForCertificationAction()">Go Back</span>
    </div>
  </div>
</div>

<div *ngIf="failedTestCasePopup" class="commonPopUp">
  <div class="commonPopUp-backdrop" (click)="closeFailedTestCasePopup()"></div>
  <div class="commonPopUp-wrapper">
    <div class="commonPopUp-text" >This test case is marked as failed, please create bug for the same to log the issue.</div>
    <div class="commonPopUp-actionBtn flex-btns">
      <span class="commonPopUp-submit-s" (click)="createBug()">Create Bug</span>
      <span class="commonPopUp-cancel-s" (click)="closeFailedTestCasePopup()">Cancel</span>
    </div>
  </div>
</div>

<div class="warningPopUp" *ngIf = "openCreateBugPopup">
  <div class="warningPopUp-backdrop" (click)="closeCreateBugPopup()"></div>
    <div class="warningPopUp-wrapper bugSelection-wrapper">
      <em class="closeFun iconb-close" (click)="closeCreateBugPopup()"></em>
      <div class="warningPopUp-title">Create a Bug</div>
      <div class="bugReason-listWrapper">
        <div class="relatedToWrapper form-group">
          <input #testcaseBugTitle type="text" class="addRelated-input"  [(ngModel)]="testCaseBugTitle" placeholder="Add Bug title">
        </div>
        <div class="bugreason-selectBlock form-group">
          <div class="selected-bugreason" (click)="openReasonsLists()">{{ bugReason() }}<em class="iconb-dd-down" [ngClass]="{'makeDown' : showReasonList }"></em></div>
          <div class="dd-wrapper" *ngIf="showReasonList">
            <div class="bugReason-list" *ngFor="let bugReason of project?.mainBugReasons" (click)="selectBugReason(bugReason)">{{bugReason?.title}}</div>
          </div>
        </div>
        <div class="bugreason-selectBlock form-group">
          <div class="ifReadOnly" *ngIf="!bug_reason_id"></div>
          <div class="selected-bugreason" (click)="openDetailReasonsLists()">{{ bugDetailReason() }}<em class="iconb-dd-down" [ngClass]="{'makeDown' : showDetailReasonList }"></em></div>
          <div class="dd-wrapper" *ngIf="showDetailReasonList">
            <div class="bugReason-list" *ngFor="let detailbugReason of project?.detailedBugReason(bug_reason_id)" (click)="selectBugReason(detailbugReason, 'detailedReason')">{{detailbugReason?.title}}</div>
          </div>
        </div>
        <div class="relatedToWrapper" *ngIf = "(currentStoryTypeChange && !story.bug_related_to_story?.id) || !currentStoryTypeChange">
          <div class="relatedTo-list" *ngIf = "bug_related_to_story?.id">
            <span class="relatedTo-storyId">[#{{bug_related_to_story?.id}}]</span> {{bug_related_to_story?.title}}
          </div>
        </div>
      <div class="logReason-actionBt">
        <button type="button" class="warningPopUp-submit" [disabled]="!bug_reason_detail_id || testCaseBugTitle.length < 1 || !bug_related_to_story?.id" (click)="createTestCaseBug()">Submit</button>
        <span class="warningPopUp-cancel" (click) = "closeCreateBugPopup()" >Cancel</span>
      </div>
    </div>
  </div>
</div>

<div class="commonPopUp" *ngIf="story?.openDocValidationPopup">
  <div class="commonPopUp-backdrop" (click)="closeDocValidationPopup()"></div>
  <div class="commonPopUp-wrapper dev_checklist">
    <div class="certificationConfirmation-wrapper">
      <div class="storyStartConfirmation-title">{{story.dev_type === 'FE' ? 'Frontend' : 'Backend'}} Block Certification Checklist</div>
      <div class="storyStartConfirmation-text">Before delivering your story, kindly ensure you followed the checklist below:</div>
      <iframe class="certificationDoc-container" [src]="docLinkUrl" frameborder="0" width="100%" height="100%"></iframe>
      <div class="confirmOption-wrapper">
        <div class="confirmOption-list">
          <label class="customCheckFor-certificationList">
            <input type="checkbox" class="certificationList-checkbox" (change)="toggleCertificationDocRead($event)" [checked]="story?.certification_doc_checked" hidden/>
            <span class="check-certificationList"></span>
          </label>
          <span class="confirmOption-text">Please confirm that you have followed the above checklist for building the Block.</span>
        </div>
      </div>
    </div>
    <div class="commonPopUp-actionBtn">
      <button type="button" class="commonPopUp-submit-s" [disabled]="!story?.certification_doc_checked" (click)="changeStoryState('delivered')">Confirm</button>
      <span class="commonPopUp-cancel-s" (click)="closeDocValidationPopup()">Cancel</span>
    </div>
  </div>
</div>

<app-story-compare-popup *ngIf="showCompare" [has_story_data]="story" (syncStory)="syncCMTstory($event)" (showCompareChange)="onShowCompareChange($event)"></app-story-compare-popup>