// import { GUID } from './guid';
import { BaseModel } from './baseModel';
import { UpdatedStoryData, AcceptanceCriteriaApprovalsData } from '../dataTypes';

export class UpdatedStoryModel extends BaseModel implements UpdatedStoryData {
  catalog_story_id?: number;
  created_at?: Date;
  epic?: {builder_feature_id?: number, title?: string};
  estimate?: number;
  id?: number;
  parent_id?: number;
  project_id?: number;
  request_type?: string;
  requester?: {id?: number, name?: string, initials?: string, email?: string};
  reviewer?: {id?: number, name?: string, initials?: string, email?: string};
  status?: string;
  tech_stack?: string;
  title?: string;
  updated_at?: Date;
  reviewed_on?: Date;
  description?: string;
  description_as_a?: string;
  description_i_want?: string;
  description_so_that?: string;
  acceptance_criteria_approvals?: Array<AcceptanceCriteriaApprovalsData> = [];
  designer?: {id?: number, name?: string, initials?: string, email?: string};
  designer_status?: string
  feedbacks?: Array<any>
  figma_image_url?: string

  constructor(data: UpdatedStoryData) {
    super(data);
    this.description = data.description;
    this.catalog_story_id = data.catalog_story_id;
    this.created_at = data.created_at;
    this.epic = data.epic ? data.epic : null;
    this.id = data.id;
    this.parent_id = data.parent_id;
    this.project_id = data.project_id;
    this.estimate = data.estimate;
    this.request_type = data.request_type;
    this.requester = data.requester ? data.requester : null;
    this.reviewer = data.reviewer ? data.reviewer : null;
    this.status = data.status;
    this.tech_stack = data.tech_stack;
    this.title = data.title;
    this.updated_at = data.updated_at;
    this.reviewed_on = data.reviewed_on;
    this.description_as_a = data.description_as_a ? data.description_as_a : '';
    this.description_i_want = data.description_i_want ? data.description_i_want : '';
    this.description_so_that = data.description_so_that ? data.description_so_that : '';
    this.designer_status = data.designer_status
    this.designer = data.designer
    this.feedbacks = data.feedbacks 
    this.figma_image_url = data.figma_image_url

    // this.acceptance_criteria_approvals = data.acceptance_criteria_approvals ? data.acceptance_criteria_approvals : [];
    if(data.acceptance_criteria_approvals) {
      this.acceptance_criteria_approvals = [];
      data.acceptance_criteria_approvals.forEach((aca) => {
        this.acceptance_criteria_approvals.push({
          id: aca.id,
          title: aca.title,
          description_for_given: aca.description_for_given ? aca.description_for_given : '',
          description_for_when: aca.description_for_when ? aca.description_for_when : '',
          description_for_then: aca.description_for_then ? aca.description_for_then : '',
          created_at: aca.created_at,
          updated_at: aca.updated_at,
          cmt_acceptance_criteria_id: aca.cmt_acceptance_criteria_id,
          story_approval_id: aca.story_approval_id,
        });
      });
    }
  }

  updateComparePopup(changes) {
    this.designer_status = changes.designer_status ? changes.designer_status : this.designer_status
    this.status = changes.status
    this.reviewed_on = changes.reviewed_on;
    this.reviewer = changes.reviewer;
    this.updated_at = changes.updated_at;
  }

  updateDiffStoryFields(titleDiff, as_aDiff, i_wantDiff, so_thatDiff, descriptionDiff) {
    this.title = titleDiff.modifiedHtml;
    this.description_as_a = as_aDiff.modifiedHtml;
    this.description_i_want = i_wantDiff.modifiedHtml;
    this.description_so_that = so_thatDiff.modifiedHtml;
    this.description = descriptionDiff.modifiedHtml;
  }

  getClassName() {
    return "UpdatedStoryModel";
  }
} 