import moment from 'moment';
import { DateRange } from '../models/date-range';

export class BaseComponent {
  public dateRange: DateRange;
  public isPending = true;
  public currentProjectsPage = 1;
  public currentUsersPage = 1;
  public totalProjectsPages = 1;
  public totalUsersPages = 1;

  constructor() {
    const from = moment().startOf('isoWeek');
    const to = moment(from).day(+7);
    this.dateRange = new DateRange(from, to);
  }
}
