import {
  MembershipData,
  ProjectData,
  ProjectRole,
  PROJECT_ROLES
} from '../dataTypes';
import { Project } from './project';
import { User } from './user';
import { GUID } from './guid';
import { BaseModel } from './baseModel';

export class Membership extends BaseModel implements MembershipData {
  id?: number;
  role: string;
  user?: User;
  // project?: Project;
  editable?: boolean;
  accepted_at?: String;
  created_at: String;
  project_id?: number;
  openSelectDD?: boolean;
  user_id?: number;
  added_on?: String;
  last_time_active?: String;
  member_card?: any;
  recent_activities?: Array<any>;
  invoice_activity?: any;
  active?: boolean;
  developer_working_schedules?:Array<any>;
  pod_role?: string;
  
  static defaultRole: ProjectRole = PROJECT_ROLES.user;
  static getMembership(data: MembershipData): Membership {
    const membership = GUID.instance.findMembershipByID(data.id);

    if(membership) {
      return membership;
    } else {
      return new Membership(data);
    }
  }

  constructor(data: MembershipData) {
    super(data);
    this.id = data.id;
    this.role = data.role;
    this.pod_role = data.pod_role;
    if (data.user) {
      data.user['pod_role'] = this.pod_role;
      this.user = new User(data.user);
    }
    this.editable = data.editable;
    this.accepted_at = data.accepted_at;
    this.created_at = data.created_at;
    this.project_id = data.project_id;
    this.openSelectDD = false;
    this.user_id = data.user_id;
    this.added_on = data.added_on;
    this.last_time_active = data.last_time_active;
    this.member_card = data.member_card;
    this.recent_activities = data.recent_activities;
    this.invoice_activity = data.invoice_activity;
    this.active = data.active;
  }

  static className: string = "Membership";

  getClassName() {
    return Membership.className;
  }

}
