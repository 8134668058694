import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BaseComponent } from '../base.component';
import { TimingUsersService } from '../../services/users.service';
import { User } from '../../models/user';

@Component({
  selector: 'app-admin-timing-users',
  templateUrl: 'users.component.html',
  styleUrls: [
    '../../../admin.common.scss',
    'users.component.scss'
  ]
})
export class TimingUsersComponent extends BaseComponent implements OnInit {

  public users: User[] = [];
  public startLoader:boolean = false;

  constructor(private usersService: TimingUsersService,
              private router: Router) {
    super();
  }

  ngOnInit(): void {
    this.startLoader = true;
    this.getUsers();
  }

  onUsersPageChanged(page: number): void {
    this.currentUsersPage = page;
    this.getUsers();
  }

  getUsers(): void {
    this.isPending = true;
    const filter = {
      page: this.currentUsersPage
    };
    this.usersService
      .getUsers(filter)
      .subscribe(
        data => {
          this.users = data.users;
          this.totalUsersPages = data.pages;
          this.isPending = false;
          this.startLoader = false;
        });
  }

  goToUser(id: number): void {
    this.router.navigate([`admin/timing/users/${id}`]);
  }
}
