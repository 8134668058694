import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminComponent } from './admin.component';
import { AdminLeftMenuComponent } from './header/leftMenu.component';
import { AdminDashboardComponent } from './dashboard';
import {
  AdminUsersListComponent,
  AdminUsersShowComponent
} from './users';
import {
  AdminProjectsListComponent,
  AdminProjectsShowComponent,
} from './projects';

import { timingRoutes } from './timing/timing-routing.module';

const routes: Routes = [
  {
    path: 'admin',
    component: AdminComponent,
    children: [
      {
        path: 'dashboard',
        component: AdminDashboardComponent
      },
      {
        path: 'users',
        component: AdminUsersListComponent
      },
      {
        path: 'users/:id',
        component: AdminUsersShowComponent
      },
      {
        path: 'projectadmin',
        component: AdminProjectsListComponent
      },
      {
        path: 'projects/:id',
        component: AdminProjectsShowComponent
      },
      {
        path: 'timing',
        children: [...timingRoutes]
      }
    ],
    data: {
      header: {
        leftMenu: AdminLeftMenuComponent,
        context: {
          type: 'admin'
        }
      }
    }
  }
];

@NgModule({
  exports: [ RouterModule ],
  imports: [ RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }) ]
})
export class AdminRoutingModule {}
