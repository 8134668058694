<div class="topTitle">Export</div>
<div class="projectExport-wrapper">
  <div class="selectStatus-wrapper">
    <span class="status-label">Select Status</span>
    <div class="selected-status" (click)="focusDropDown('status')">
      <span class="selected-statusText">{{selected_states}}</span>
      <em class="iconb-dd-down" [ngClass]="{'makeUp': showDropDown && dropDownType==='status'}"></em>
    </div>
    <div class="statusSelection-DD" [ngClass]="{'openStatusSelection-DD': showDropDown && dropDownType==='status'}">
      <span class="statusSelection-list" *ngFor="let storyState of storyStateOptions">
        <label class="customCheckForStorySelection">
          <input type="checkbox" class="custom-selected-checkbox" (click)="toggleOption($event, storyState, 'state')"  [checked]="storyState?.checked" hidden/>
          <span class="check-toggle"></span>
          {{ storyState?.title }}
        </label>
      </span>
    </div>
  </div>
  
  <div class="selectStatus-wrapper">
    <span class="status-label">Select Phase</span>
    <div class="selected-status" (click)="focusDropDown('phase')">
      <span class="selected-statusText">{{selected_phases}}</span>
      <em class="iconb-dd-down" [ngClass]="{'makeUp': showDropDown && dropDownType==='phase'}"></em>
    </div>
    <div class="statusSelection-DD" [ngClass]="{'openStatusSelection-DD': showDropDown && dropDownType==='phase'}">
      <span class="statusSelection-list" *ngFor="let storyPhase of storyPhaseOptions">
        <label class="customCheckForStorySelection">
          <input type="checkbox" class="custom-selected-checkbox" (click)="toggleOption($event, storyPhase, 'phase')"  [checked]="storyPhase?.checked" hidden/>
          <span class="check-toggle"></span>
          {{ storyPhase?.title }}
        </label>
      </span>
    </div>
  </div>

  <div class="selectDate-wrapper">
    <span class="status-label">Select Date</span>
    <input type="text"
           ngxDaterangepickerMd
           class="dateRagne-input"
           [(ngModel)]="selected"
           [alwaysShowCalendars]="true"
           [ranges]="ranges"
           [showDropdowns]="true"
           [linkedCalendars]="false"
           placeholder="Select please..."/>
    <em class="iconb-calendar dateIcon"></em>
    <button type="button" *ngIf="selected && selected.startDate" class="dateClearButton" (click)="clear($event)">
      <em class="iconb-close"></em>
    </button>
  </div>
  <div class="selectStatus-wrapper">
    <span class="status-label">Select Fields</span>
    <div class="selected-status" (click)="focusDropDown('fields')">
      <span class="selected-statusText">{{selected_fields}}</span>
      <em class="iconb-dd-down" [ngClass]="{'makeUp': showDropDown && dropDownType==='fields'}"></em>
    </div>
    <div class="statusSelection-DD" [ngClass]="{'openStatusSelection-DD': showDropDown && dropDownType==='fields'}">
      <span class="statusSelection-list"  *ngFor="let exportField of exportFieldOptions">
        <label class="customCheckForStorySelection">
          <input type="checkbox" class="custom-selected-checkbox" (click)="toggleExportField($event, exportField)"  [checked]="exportField?.checked" hidden/>
          <span class="check-toggle"></span>
          {{ exportField?.title }}
        </label>
      </span>
    </div>
  </div>
  <button type="button" [disabled]="disabledExportButton" class="exportButton" (click)="exportReport()">Export</button>
  <div class="bulkAction-Loader" *ngIf="waitForLoader">
    <img src="../../assets/images/storyBoardLoader.gif" alt="loader"/>
  </div>
</div>
