import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { IMyDate, IMyOptions, AngularMyDatePickerDirective } from 'angular-mydatepicker';
import { Moment } from 'moment';
import moment from 'moment';
import { DateRange } from '../../models/date-range';

@Component({
  selector: 'app-admin-timing-date-range-switcher',
  templateUrl: 'date-range-switcher.component.html',
  styleUrls: ['date-range-switcher.component.scss']
})
export class TimingDateRangeSwitcherComponent {

  public dateFrom = { jsdate: moment().toDate() };
  public dateTo = { jsdate: moment().toDate() };
  public datePickerOptions: IMyOptions = { dateFormat: 'dd/mm/yyyy' };

  @Input() isDashboard: boolean = false;
  @Input()
  set dateRange(range: DateRange) {
    this.dateFrom.jsdate = range.from.toDate();
    this.dateTo.jsdate = range.to.toDate();
    this.updateDisabledDates(range.from, range.to);
  };
  @Input() isPending: boolean;
  @Output() onDateRangeChanged: EventEmitter<DateRange> = new EventEmitter();
  @ViewChild('datePickerFrom', {static: true}) datePickerFrom: AngularMyDatePickerDirective;
  @ViewChild('datePickerTo', {static: true}) datePickerTo: AngularMyDatePickerDirective;

  constructor() { 
    // This is intentional
  }

  onDateFromChanged(event): void {
    const range = new DateRange(moment(event.jsdate), moment(this.dateTo.jsdate));
    this.onDateRangeChanged.emit(range);
  }

  onDateToChanged(event): void {
    const range = new DateRange(moment(this.dateFrom.jsdate), moment(event.jsdate));
    this.onDateRangeChanged.emit(range);
  }

  private updateDisabledDates(from: Moment, to: Moment): void {
    const disableSince = this.dateToIMyDate(moment(to).add(1, 'day'));
    const disableUntil = this.dateToIMyDate(moment(from).subtract(1, 'day'));
    if(this.isDashboard){
      let i;
      const disable_dates = [];
      for (i = 0;i<=31;i++){
        disable_dates.push({ year: 0, month: 0, day: i });
      }
      this.datePickerFrom['opts'].disableDates = disable_dates;
      this.datePickerTo['opts'].disableDates = disable_dates;
      this.datePickerFrom['opts'].enableDates = [{ year: 0, month: 0, day: 15 }];
      this.datePickerTo['opts'].enableDates = [{ year: 0, month: 0, day: 14 }];
    }
    this.datePickerFrom['opts'].disableSince = disableSince;
    this.datePickerTo['opts'].disableUntil = disableUntil;
  }

  private dateToIMyDate(date: Moment): IMyDate {
    return { year: date.year(), month: date.month() + 1, day: date.date() };
  }
}
