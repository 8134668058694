<div class="release-commonPopUp">
    <div class="release-commonPopUp-backdrop" (click)="closeFeaturelist()"></div>
    <div class="close-release-planner-popup" (click)="closeFeaturelist()">
      <em class="iconb-close"></em>
    </div>
    <div class="release-commonPopUp-wrapper">
      <div class="bulkAction-Loader" *ngIf="waitForLoader">
        <img src="../../assets/images/storyBoardLoader.gif" alt=""/>
      </div>
      <div class="release-commonPopUp-title-block">
        <div class="title">{{selected_release.name}}</div>
      </div>
      <div class="commonPopUp-featurecontent">
        <div class="featureListTitle">Feature List <span class="count">({{featureList.length}})</span></div>
        <div class="featuresList" *ngFor="let feature of featureList">{{feature}}</div>
      </div>
    </div>
  </div>