<div class="st-Loader adminTimingLoader" *ngIf="startLoader">
  <div class="in-Loader"><img src="../../assets/images/builder_black.png" alt="loader"/></div>
  <div class="loadingOverlay"></div>
</div>

<div [class.pending]="isPending">
  <div class="row">
    <div class="col-sm-12">
      <h3>Project</h3>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-6">
      <label>Name of project</label>
      <select name="project"
              class="form-control"
              [(ngModel)]="project"
              [disabled]="isPending"
              (change)="getUsers()">
        <option *ngFor="let project of projects" [ngValue]="project">
          {{project.name}}
        </option>
      </select>
    </div>

    <app-admin-timing-date-range-switcher
      [isPending]="isPending"
      [dateRange]="dateRange"
      (onDateRangeChanged)="onDateRangeChanged($event)">
    </app-admin-timing-date-range-switcher>
  </div>

  <div class="row">
    <div class="col-sm-12">
      <hr>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-2">
      <div class="section">
        <div class="heading">{{totalUsers || 0}}</div>
        <div>total users</div>
      </div>
    </div>
    <div class="col-sm-2">
      <div class="section">
        <div class="heading">{{totalTime || '00:00:00'}}</div>
        <div>total time</div>
      </div>
    </div>
  </div>

  <div class="row pt5">
    <div class="col-sm-12">
      <div class="table-wrapper table-timing">
        <table role="presentation" class="table table-hover table-striped">
          <thead>
            <tr>
              <td>Name</td>
              <td>Position</td>
              <td>Total time</td>
            </tr>
          </thead>
          <tbody>
            <tr *ngIf="!users.length" class="nothing-yet">
              <td colspan="3"><em>There is no data yet</em></td>
            </tr>
            <tr *ngFor="let user of users" (click)="goToUser(user.id)">
              <td>{{user.name_or_email}}</td>
              <td>{{user.role}}</td>
              <td>{{user.totalTime || '00:00:00'}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div *ngIf="users.length" class="row">
    <div class="col-sm-12">
      <app-admin-timing-pagination
        [isPending]="isPending"
        [currentPage]="currentUsersPage"
        [totalPages]="totalUsersPages"
        (onNewPageSelected)="onUsersPageChanged($event)">
      </app-admin-timing-pagination>
    </div>
  </div>
</div>
