import { HttpParams, HttpClient } from "@angular/common/http";

export class UploadAdapter {
    constructor(
        private loader,
        public url: string,
        private http: HttpClient
    ) {
    }
    //the uploadFile method use to upload image to your server
    uploadFile(file, url?: string, user?: string) {
        let name = '';
        let formData: FormData = new FormData();
        let headers = new Headers();
        name = file.name;
        formData.append('file', file, name);
        headers.append('Accept', 'application/json');
        let params = new HttpParams();
        const options = {
            params: params,
            reportProgress: true,
        };
        //http post return an observer
        //so I need to convert to Promise
        return this.http.post(url, formData, options);
    }
    //implement the upload 
    upload() {
        let upload = new Promise((resolve, reject) => {
            this.loader['file'].then(
                (data) => {
                    this.uploadFile(data, this.url, 'test')
                        .subscribe(
                            (result) => {
                                resolve({ default: result['file_url'] })
                            },
                            (error) => {
                                reject(data.msg);
                            }
                        );
                }
            );
        });
        return upload;
    }
    abort() {
        console.log("abort")
    }
}