import { NgModule, ErrorHandler } from '@angular/core'
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { OutputComponent } from './output';
import { NoContentComponent } from './noContent.component';
import { AccountService } from './services/account.service';
import { ProjectService } from './services/project.service';
import { PolicyService } from './services/policy.service';
import { UserService } from './services/user.service';
import { NotificationService } from './services/notification.service';
import { NotificationsService } from './services/notifications.service';
import { StoryService } from './services/story.service';
import { NoteService } from './services/note.service';
import { EpicService } from './services/epic.service';
import { DocumentService } from './services/document.service';
import { SocketsService } from './services/sockets.service';
import { ProjectSyncService } from './services/projectSync.service';
import { QueryParamsService } from './services/queryParams.service';
import { ErrorService } from './errorFunction';
import { TruncatePipe } from './pipes/truncate';
import { AutosizeDirective } from './directives';
import { OrderPipe } from './pipes/order';
import { AuthGuardService } from './services/auth-guard.service';
import { ToastdisplayComponent } from './components/toastdisplay/toastdisplay.component';
import { CustomPopupComponent } from './components/custom-popup/custom-popup.component';
import { FilterPipe } from './pipes/filter';
import { SearchPipe } from './pipes/search';
import {MemberSearchPipe} from './pipes/member.search';
import {LinkifyPipe} from './pipes/linkify';
import { SharedService } from './services/shared.service';
import { FilterUserPipe } from './pipes/filter-user';
import {NotesSortPipe} from './pipes/notes.sort';
import { ActivityService } from './services/activity.service';
import { SentryErrorHandler } from './services/sentry.service';
import { HttpRequests } from './services/http-requests.service';
import { DataService } from './services/data.service';
import { StoryProgressComponent } from './components/story-progress/story-progress.component';
import { CommentParsePipe } from './pipes/comment.parse.pipe';
import { MyOffClickDirective } from './directives/off-click.directive';
import {FilterDataSortPipe} from './pipes/filterData.sort';
import {DragDropFileDirective} from './directives/dragdropfile.directive';
import {CommonStoryService} from "./services/common-story.service";
import { SafePipe } from './pipes/safe.pipe';
import { SubTaskListSort } from './pipes/subTask-list.sort';
import { ReplaceUnderscorePipe } from './pipes/replace-underscore';
import { SortByDevice } from './pipes/sortByDevice.pipe';
import { FilterStoryService } from './services/filter-story.service';
import { SprintService } from './services/sprint.service';
import { KeyValuePipe } from './pipes/keyvalue.pipe';
import { SquadMemberSearchPipe } from './pipes/squadMemberSearch.pipe';
import { DevTypeOptionsPipe } from './pipes/dev-type-options.pipe';
import { DateAndTimeService } from './services/dateAndTime.service';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { ReleasePlannerService } from './services/release-planner.service';
import { EpicSearchPipe } from './pipes/feature.pipe';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    BrowserModule,
  ],
  declarations: [
    OutputComponent,
    NoContentComponent,
    TruncatePipe,
    SearchPipe,
    MemberSearchPipe,
    LinkifyPipe,
    NotesSortPipe,
    FilterDataSortPipe,
    SafePipe,
    OrderPipe,
    FilterUserPipe,
    CommentParsePipe,
    FilterPipe,
    AutosizeDirective,
    MyOffClickDirective,
    ToastdisplayComponent,
    CustomPopupComponent,
    StoryProgressComponent,
    DragDropFileDirective,
    SubTaskListSort,
    ReplaceUnderscorePipe,
    SortByDevice,
    KeyValuePipe,
    SquadMemberSearchPipe,
    DevTypeOptionsPipe,
    ClickOutsideDirective,
    EpicSearchPipe
  ],
  providers: [
    DataService,
    NotificationService,
    NotificationsService,
    AccountService,
    ProjectService,
    HttpRequests,
    PolicyService,
    UserService,
    NoteService,
    StoryService,
    ActivityService,
    EpicService,
    ErrorService,
    DocumentService,
    SocketsService,
    ProjectSyncService,
    CommonStoryService,
    QueryParamsService,
    AuthGuardService,
    SharedService,
    FilterStoryService,
    SprintService,
    DateAndTimeService,
    ReleasePlannerService,
    { provide: ErrorHandler, useClass: SentryErrorHandler }
    
  ],
  exports: [
    OutputComponent,
    StoryProgressComponent,
    NoContentComponent,
    TruncatePipe,
    OrderPipe,
    FilterUserPipe,
    CommentParsePipe,
    FilterPipe,
    SearchPipe,
    AutosizeDirective,
    MyOffClickDirective,
    ToastdisplayComponent,
    CustomPopupComponent,
    MemberSearchPipe,
    SafePipe,
    LinkifyPipe,
    NotesSortPipe,
    FilterDataSortPipe,
    DragDropFileDirective,
    SubTaskListSort,
    ReplaceUnderscorePipe,
    SortByDevice,
    KeyValuePipe,
    SquadMemberSearchPipe,
    DevTypeOptionsPipe,
    ClickOutsideDirective,
    EpicSearchPipe
  ]
})
export class SharedModule { }
