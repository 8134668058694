<div class="sidebar">
  <app-admin-timing-sidebar></app-admin-timing-sidebar>
</div>
<div class="projectsDashboard-wrapper">
    <div class="row">
      <div class="col-sm-12">
        <router-outlet></router-outlet>
      </div>
  </div>
</div>
