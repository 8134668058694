import { forwardRef, Inject, Injectable } from '@angular/core';
import { API_ROUTES } from '../apiRoutes';
import {map} from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DataService } from './data.service';
import { HttpRequests } from './http-requests.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import * as _ from 'lodash';
import moment from 'moment';
import { ErrorService } from '../errorFunction';
import { CustomToastService } from './custom-toast.service';
import { ProjectService } from './project.service';
import { UserService } from './user.service';
import { NotificationService } from './notification.service';
import { EVENT_TYPES } from '../dataTypes';
import { EpicService } from './epic.service';
import { Epic } from '../models/epic';
import { Observable, Observer } from 'rxjs';

@Injectable()
export class ReleasePlannerService {
  add_release_detail_for_release: boolean = false;
  constructor(
    private dataService: DataService,
    private httpRequest: HttpRequests,
    private errorService: ErrorService,
    private customToast: CustomToastService,
    private projectService: ProjectService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private notificationService: NotificationService,
    private epicService: EpicService,
    public http: HttpClient) {}

    updateParamsData(filters?, data?) {
      if (filters['statuses[]'].length > 0) { data['statuses[]'] = filters['statuses[]']; }
      if (filters['epic_ids[]'].length > 0) { data['epic_ids[]'] = filters['epic_ids[]']; }
      if (filters['ship_release_date_range[]'].length > 0) { data['shipped_release_date'] = filters['ship_release_date_range[]']; }
      if (filters['rating[]'].length > 0) { data['rating[]'] = filters['rating[]']; }
    }     

// create release
  createRelease(project, release) {
    const API = this.dataService.apiUrl + API_ROUTES.RELEASES(project.id);
    return this.httpRequest
      .post(API, release, this.dataService.getRequestOptionArgs()).pipe(
      map(res => this.parseResponse(res)));
  }

// get list of release
  getReleases(project, pageno, search, env, filters) {
    const data = [];
    data['q'] = search;
    data['page'] = pageno;
    data['environment'] = env;
    if (filters) {
      this.updateParamsData(filters, data);
    }
    const API = this.dataService.apiUrl + API_ROUTES.RELEASES(project.id);
    return this.httpRequest
      .get(API, {params : data}, this.dataService.getRequestOptionArgs()).pipe(
      map(res => this.parseResponse(res)));
  }

  // get list of features
  getFeatures(project, release_id) {
    const API = this.dataService.apiUrl + API_ROUTES.RELEASE_FEATURES(project.id, release_id);
    return this.httpRequest
      .get(API, this.dataService.getRequestOptionArgs()).pipe(
      map(res => this.parseResponse(res)));
  }

  common_activities(projectId: number) {
    const subject_id_queryStr = '?subject_id=' + 1;
    const subject_type_queryStr = '&subject_type=' + 'release';
    const API = this.dataService.apiUrl + API_ROUTES.ACTIVITIES(projectId) + subject_id_queryStr + subject_type_queryStr + '&all=' + false;
    return this.httpRequest
      .get(API, this.dataService.getRequestOptionArgs()).pipe(
      map(res => {
        return this.parseResponse(res);
      }));
  }

  // get comments for release
  getReleaseComments(project, release_id) {
    const API = this.dataService.apiUrl + API_ROUTES.COMMENTS_FOR_RELEASE(project.id, release_id);
    return this.httpRequest
      .get(API, this.dataService.getRequestOptionArgs()).pipe(
      map(res => this.parseResponse(res)));
  }
  
  // Create comment for release
  createReleaseComments(project, release_id, note, onCreate) {
    const formData: FormData = new FormData();
    formData.append('note[note]', note.note);
    // formData.append('note[on_create]', JSON.stringify (onCreate));
    if(note) {
      note.documents.forEach(file => {
        formData.append('note[documents_attributes][][name]', file.name);
        formData.append('note[documents_attributes][][attachment]', file);
        formData.append('note[documents_attributes][][content_type]', file.type);
        formData.append('note[documents_attributes][][size]', file.size);
      });
    }
    

    const API = this.dataService.apiUrl + API_ROUTES.COMMENTS_FOR_RELEASE(project.id, release_id);
    return this.httpRequest
      .post(API, formData, this.dataService.getRequestOptionArgs()).pipe(
      map(res => this.parseResponse(res)));
  }

  // get release details
  getRelease(projectId, release_id) {
    const API = this.dataService.apiUrl + API_ROUTES.SINGLE_RELEASE(projectId, release_id);
    return this.httpRequest
      .get(API, this.dataService.getRequestOptionArgs()).pipe(
      map(res => this.parseResponse(res)));
  }

  // get release note details
  getReleaseDetail(project, release_id) {
    const API = this.dataService.apiUrl + API_ROUTES.release_detail(project.id, release_id);
    return this.httpRequest
      .get(API, this.dataService.getRequestOptionArgs()).pipe(
      map(res => this.parseResponse(res)));
  }

  // Move release
  moveRelease(projectId, data) {
    const payload = {
      releases : data
    }
    const API = this.dataService.apiUrl + API_ROUTES.MOVE_RELEASE(projectId);
    return this.httpRequest
      .put(API,payload, this.dataService.getRequestOptionArgs()).pipe(
      map(res => this.parseResponse(res)));
  }

  // Move release to Production
  moveReleaseToProd(projectId, data) {
    const payload = {
      ids : JSON.stringify(data)
    }
    const API = this.dataService.apiUrl + API_ROUTES.MOVE_RELEASE_T0_PROD(projectId);
    return this.httpRequest
      .post(API,payload, this.dataService.getRequestOptionArgs()).pipe(
      map(res => this.parseResponse(res)));
  }

  // get release details
  removeRelease(projectId, releaseId, deleted_info) {
    const data = {
      release: {
        deleted_at: deleted_info
      }
    }
    const API = this.dataService.apiUrl + API_ROUTES.SINGLE_RELEASE(projectId, releaseId);
    return this.httpRequest
      .patch(API, data, this.dataService.getRequestOptionArgs()).pipe(
      map(res => this.parseResponse(res)));
  }

  // update release
  updateRelease(project, release, formData?) {
    const API = this.dataService.apiUrl + API_ROUTES.SINGLE_RELEASE(project.id, release.id);
    return this.httpRequest
      .patch(API, formData, this.dataService.getRequestOptionArgs()).pipe(
      map(res => this.parseResponse(res)));
  }

  updateReleaseDetail( project, release_id, release_detail, note_id) {
    const API = this.dataService.apiUrl + API_ROUTES.UPDATE_RELEASE_DETAILS(project.id, release_id, note_id);
    return this.httpRequest
      .put(API, release_detail, this.dataService.getRequestOptionArgs()).pipe(
      map(res => this.parseResponse(res)));
  }

  getStoriesForRelease(project, searchStory, pagno, release_id?) {
    const project_id = project ? project.id : null;
    const data = {
      q: searchStory,
      page: pagno,
      release_id: release_id
     };
    const API = this.dataService.apiUrl + API_ROUTES.STORIES_FOR_RELEASE(project_id);
    return this.httpRequest
      .get(API, {params : data}, this.dataService.getRequestOptionArgs()).pipe(
      map(res => this.parseResponse(res)));
  }

  getSprintsForRelease(project, searchSprint, pagno) {
    const data = {
      q: searchSprint,
      page: pagno
     };
    const API = this.dataService.apiUrl + API_ROUTES.SPRINTS_FOR_RELEASE(project.id);
    return this.httpRequest
      .get(API, {params : data}, this.dataService.getRequestOptionArgs()).pipe(
      map(res => this.parseResponse(res)));
  }

  addStoryInRelease(project,release_id, stories) {
    const API = this.dataService.apiUrl + API_ROUTES.ADD_STORIES_IN_RELEASE(project.id, release_id);
    const formData = new FormData();
    formData.append('story_ids', JSON.stringify(stories));
    return this.httpRequest
      .put(API, formData, this.dataService.getRequestOptionArgs()).pipe(
      map(response => this.parseResponse(response)));
  }

  deleteStoriesFromRelease(project,release_id, stories) {
    const API = this.dataService.apiUrl + API_ROUTES.REMOVE_STORIES_FROM_RELEASE(project.id, release_id);
    const formData = new FormData();
    formData.append('story_ids', JSON.stringify(stories));
    return this.httpRequest
      .put(API, formData, this.dataService.getRequestOptionArgs()).pipe(
      map(response => this.parseResponse(response)));
  }
  
  public parseResponse(res: any): any {
    return res;
  }

  isScrollFetchAvailable(meta): boolean {
    return meta && meta.next_page != null;
  }

  timeFormat(time) {
    return moment(time).format('DD MMM YYYY | HH:mm:ss');
  }

  // update release status
  changeReleaseStatus(status, release, project) {
    release.status_loader = true;
    const temp_status = release.status;
    release.status = status.value;
    release.statusDropdownVisible = false;
    const formData = new FormData();
    formData.append('release[status]', release.status);
    this.updateRelease(project, release, formData).subscribe(res => {
      this.customToast.messages.push({
        id: 'release_updated',
        type: 'success',
        class: 'stories_moved',
        title: 'Release',
        message: 'Release Status updated successfully'
      });
      release.status_loader = false;
    }, (error) => {
      release.status_loader = false;
      release.status = temp_status;
      this.errorService.errorFunction(error);
    });
  }

  // Create or Edit Release 
  prepareFormData(release: any, release_detail_data: any, attachOnlyArr: any[], action_for?, previous_data?, delete_Attachment?): FormData {
    const hasChanged = (key, newValue, oldValue) => {
      if (key === 'phase' || key === 'phase_deliverables') {
        return !_.isEqual(newValue, oldValue.value); 
      }
      return !_.isEqual(newValue, oldValue);
    };

    const formData: FormData = new FormData();
    // Append release properties
    const releaseProperties = {
      'name': release.name,
      'version': release.version,
      'phase_deliverables': release.phase_deliverables.value,
      'phase': release.phase.value,
      'estimated_end_date': release.estimated_end_date,
      'build_url': release.build_url,
      'sprint_ids': JSON.stringify(release.sprint_ids),
      'story_ids': JSON.stringify(release.story_ids)
    };
    
    Object.keys(releaseProperties).forEach(key => {
      if ((key === 'story_ids' || key === 'sprint_ids') && action_for !== 'update') {
        formData.append(key, releaseProperties[key]);
      } else if (key !== 'story_ids' && key !== 'sprint_ids') {
        if (action_for === 'update' && hasChanged(key, releaseProperties[key], previous_data[key])) {
          formData.append('release[' + key + ']', releaseProperties[key]);
        }
        if (action_for === 'create') {
          formData.append('release[' + key + ']', releaseProperties[key]);
        }
      }
    });

    if (release.platforms && release.platforms.length > 0) {
      release.platforms.forEach( platform => {
        formData.append('release[platform_associations_attributes[][platform_id]', platform.platform_id);
      })
    }

    // Function to append files
    const appendFiles = (files, prefix) => {
      files.forEach(file => {
        formData.append(prefix + '[name]', file.name);
        formData.append(prefix + '[attachment]', file);
        formData.append(prefix + '[content_type]', file.type);
        formData.append(prefix + '[size]', file.size);
      });
    };
      
    // Append the attachments
    if(delete_Attachment && delete_Attachment.length > 0) {
      delete_Attachment.forEach(file => {
        formData.append('release[documents_attributes][][name]', file.name);
        formData.append('release[documents_attributes][][id]', file.id);
        formData.append('release[documents_attributes][][_destroy]', 'true');
      });
    } 

    if(attachOnlyArr && attachOnlyArr.length > 0) {
      appendFiles(attachOnlyArr, 'release[documents_attributes][]');
    }
    
    // Check if release_detail_data has data or not and update the release note
    if (release_detail_data) {
      const releaseDetailProperties = {
        'body': release_detail_data.body,
        'known_issues': release_detail_data.known_issues,
        'credentials': release_detail_data.credentials,
        'coming_soon': release_detail_data.coming_soon
      };
      
      Object.keys(releaseDetailProperties).forEach(key => {
        formData.append('release[release_detail_attributes][' + key + ']', releaseDetailProperties[key]);
      });

      if(action_for === 'update') {
        formData.append('release[release_detail_attributes][id]', release_detail_data.id);
      }

      if ((release_detail_data.documents_attributes.length > 0) && !('id' in release_detail_data.documents_attributes[0])) {
        appendFiles(release_detail_data.documents_attributes, 'release[release_detail_attributes][documents_attributes][]');
      }

      // if release_detail_data.feature_notes exists then append the feature notes
      if(release_detail_data.feature_notes && release_detail_data.feature_notes.length > 0) {
        release_detail_data.feature_notes.forEach( feature_note => {
          formData.append('release[release_detail_attributes][feature_notes_attributes][][id]', JSON.stringify(feature_note.id));
          formData.append('release[release_detail_attributes][feature_notes_attributes][][note_type]', feature_note.note_type);
          formData.append('release[release_detail_attributes][feature_notes_attributes][][body]', feature_note.body);
          formData.append('release[release_detail_attributes][feature_notes_attributes][][release_detail_id]', JSON.stringify(feature_note.release_detail_id));
          if (feature_note.documents_attributes) {
            appendFiles(feature_note.documents_attributes, 'release[release_detail_attributes][feature_notes_attributes][][documents_attributes][]');
          }
        })
      }
    }

    return formData;
  }

 // Edit Release Note
  prepareReleaseDetailUpdateFormData(release: any, release_detail_data: any, attachVideoOnly: any[]): FormData {
    const formData: FormData = new FormData();
    const releaseDetailProperties = {
      'body': release_detail_data.body,
      'known_issues': release_detail_data.known_issues,
      'credentials': release_detail_data.credentials,
      'coming_soon': release_detail_data.coming_soon
    };
    
    Object.keys(releaseDetailProperties).forEach(key => {
      formData.append('release_detail[' + key + ']', releaseDetailProperties[key]);
    });

    if(release_detail_data.feature_notes && release_detail_data.feature_notes.length > 0) {
      release_detail_data.feature_notes.forEach( feature_note => {
        formData.append('release_detail[feature_notes_attributes][][id]', feature_note.id);
        formData.append('release_detail[feature_notes_attributes][][note_type]', feature_note.note_type);
        formData.append('release_detail[feature_notes_attributes][][body]', feature_note.body);
        if (feature_note.documents_attributes.length > 0) {
          feature_note.documents_attributes.forEach( document => {
            formData.append('release_detail[feature_notes_attributes][][documents_attributes][][name]', document.name);
            formData.append('release_detail[feature_notes_attributes][][documents_attributes][][attachment]', document);
            formData.append('release_detail[feature_notes_attributes][][documents_attributes][][content_type]', document.type);
            formData.append('release_detail[feature_notes_attributes][][documents_attributes][][size]', document.size);
          });
        }
        if (feature_note.for_removal.length > 0) {
          feature_note.for_removal.forEach( document => {
            formData.append('release_detail[feature_notes_attributes][][documents_attributes][][id]', document.id);
            formData.append('release_detail[feature_notes_attributes][][documents_attributes][][name]', document.name);
            formData.append('release_detail[feature_notes_attributes][][documents_attributes][][_destroy]', 'true');
          });
        }
      })      
    }

    // Function to append files
    let appendFiles = (files, prefix) => {
      files.forEach(file => {
        formData.append(prefix + '[name]', file.name);
        formData.append(prefix + '[attachment]', file);
        formData.append(prefix + '[content_type]', file.type);
        formData.append(prefix + '[size]', file.size);
      });
    };

    // check if video is edited or not
    if ((release_detail_data.documents_attributes.length > 0) && !('id' in release_detail_data.documents_attributes[0])) {
      appendFiles(release_detail_data.documents_attributes, 'release_detail[documents_attributes][]');
    }

    return formData;
  }


  // Load Project Details
  loadProjectDetails(initiateFrom: string, projectId: number): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      this.projectService.initiate_from = initiateFrom;
      this.projectService.initialLoader = true;
      const queryIndex = window.location.href.indexOf('?');
      const queryUrl = window.location.href.substr(queryIndex + 1);
      const registerQueryParam = queryUrl.substr(0, queryUrl.indexOf('='));
      const registerQueryParamValue = queryUrl.substr(queryUrl.indexOf('=') + 1);
      const currentUser = this.userService.getUser();
      this.projectService.project(projectId, true).subscribe(project => {
        const project_data = project;
        if (registerQueryParam === 'storyId') {
          this.projectService.show_StoryDetails(project_data, '', registerQueryParamValue, false, this.projectService.initiate_from);
        }
        this.notificationService.broadcast(EVENT_TYPES.PROJECT.SHOW, {
          data: {
            project: project_data
          }
        });
        project_data.memberships = [];
        if (this.projectService.current_user_role === 'read_only') {
          this.router.navigate(['/projects/' + project_data.id]);
          return;
        }
        this.projectService.loadUsers(project_data);
        this.epicService.projectEpics(project_data)
        .subscribe((epics: Array<Epic>) => {
          project_data.addEpics(epics);
          }, (error) => {
            this.errorService.errorFunction(error);
        });
  
        observer.next({
          project: project_data,
          current_user: currentUser
        });
        observer.complete();
  
      }, err => {
        this.errorService.errorFunction(err);
        observer.error(err);
      });
    });
  }

}