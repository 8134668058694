<div #proSt
     [ngClass]="['story-mincard', 'story', this.story.story_type, 'story-' + this.story.id, (this.story.parent_id) ? 'is-subtask' : '', this.story.inactive ? 'inactive' : '', (this.projectService.show_storyDetails && ((this.projectService.activeStoryId == this.projectService.storyId ) || (this.projectService.activeStoryId == this.story.id))) ? 'active' : '', (this.story.isSelected) ? 'checkbox-is-checked' : '']"
     [class.childStory]="story.parent_id"
     [attr.data-story-uid]="story?.guid"
     [class.featureFilterSelected]="getSelectedStory(story.id)">

  <div class="sprintInitial-Loader" *ngIf="mincardUpdateLoader">
      <img alt="">
  </div>
  
  <div class="story-options">
    <label class="customCheckForStorySelection min-card-selection" *ngIf="!story?.inactive"
      [class.storyIsChecked]="story.isSelected"
      [class.showCheckBox]="mouseOvered"
      #getQuickActioncord>
      <span class="ifDisabled" *ngIf="projectService.checkStoryarchived(story)"></span>
      <input type="checkbox" *ngIf="!isProjectReadonly" class="story-selected-checkbox" [disabled]="projectService.checkStoryarchived(story)" hidden [checked]="story.isSelected" (change)="toggleStorySelection($event,story)"/>
      <span class="check-toggle"></span>
      <span class="elementHover-checkbox" >{{projectService.archived_story_selected ? ('This story cannot be selected as you have selected archived ' + [[(this.project?.selectedStories.length === 1) ? 'story.' : 'stories.']]) : ('This story cannot be selected as you  have selected unarchived ' + [[(this.project?.selectedStories.length === 1) ? 'story.' : 'stories.']])}}</span>
    </label>
  </div>
  
  <div  class="quickAction-wrapper" [@fadeInOut] [options] = "{isApply: story.showQuickAct, key: 'showQuickAct'}" myOffClick (offClick) = "onClick($event)"  #showQuickActioncord *ngIf = "story.showQuickAct" [ngClass]="{'quickAction-show':story.showQuickAct}">
    <ng-container *ngIf="!story.deleted_at">
      <div class="quickAction-listBlock">
        <div class="quickAction-list" *ngIf="acceptValidationForQuickAction()" (click)="openCustomPopup('accept', $event)">
          <em class="iconb-done"></em>
          <span class="storyStatus-quickActionsText acceptedColor">Accept</span>
        </div>
        <div class="quickAction-list" *ngIf="rejectValidationForQuickAction()" (click)="openCustomPopup('reject', $event)">
          <em class="iconb-close"></em>
          <span class="storyStatus-quickActionsText rejectedColor">Reject</span>
        </div>
        <div class="quickAction-list" (click)="editFeaturesLabels()">
          <em class="iconb-label"></em>
          <span class="storyStatus-quickActionsText">Edit{{ !story?.parent_id ? ' Features &' : ''}} Labels</span>
        </div>
        <div class="quickAction-list" *ngIf="!story.owned_by_id" [ngClass]="{'disableAction': !story.phase}"  (click)="setOwner()">
          <em class="iconb-changemember"></em>
          <span class="storyStatus-quickActionsText">Set Owner</span>
          <span class="elementHover" *ngIf="!story.phase">Please select phase</span>
        </div>
        <div class="quickAction-list" *ngIf="!story.reviewer_id" [ngClass]="{'disableAction': !story.phase}" (click)="setReviewer()">
          <em class="iconb-changemember"></em>
          <span class="storyStatus-quickActionsText">Set Reviewer</span>
          <span class="elementHover" *ngIf="!story.phase">Please select phase</span>
        </div>
        <!-- <div class="quickAction-list" *ngIf="!story.estimate && projectService.current_user_role !== 'user'" (click)="setEstimate()">
          <em class="iconb-glasstime"></em>
          <span class="storyStatus-quickActionsText">Set Owner Estimate</span>
        </div> -->
        <div class="quickAction-list" *ngIf="story.subTask() && story.created_from === 'delivery'" (click)="openConvertSubtaskPopup()">
          <em class="iconb-task"></em>
          <span class="storyStatus-quickActionsText">Promote to Task</span>
        </div>
        <div class="quickAction-list" (click)="openCloneConfirmation()" style="margin-top: -1px;">
          <em class="iconb-duplicate"></em>
          <span class="storyStatus-quickActionsText">Duplicate Story</span>
        </div>
        <div class="quickAction-list" *ngIf="!story.checkE2EStory() || projectService.checkBBStatusSkipped(story)" (click)="destroy()" style="margin-top: -2px;">
          <em class="iconb-bin"></em>
          <span class="storyStatus-quickActionsText">Archive</span>
        </div>
        <div class="quickAction-list" (click)="moveStory()" style="margin-top: -2px;">
          <em class="iconb-move"></em>
          <span class="storyStatus-quickActionsText">Move</span>
        </div>
      </div>
      <div class="quickAction-listBlock" *ngIf="story && story.subTask()">
        <div class="quickAction-typeList">
          <span class="listBlock-label">Sub Phase</span>
          <div class="actionBlock">
            <div class="storyTypeDD-selected">
              <div class="ifReadOnly2" *ngIf="story.wireframe"></div>
                <span class="selected-type" (click)="focusDropDown()">
                  <em [ngClass]="['iconb-' + story.storyPhaseIcon(story.task)]" *ngIf="story.task"></em>
                  <span class="selectedType-name">{{(story.task ? story.task : 'Select Sub-phase')}}</span>
                  <em class="iconb-dd-down"></em>
                </span>
            </div>
            <div class="storySelection-DD" [ngClass]="{'openDD': story.showDropDown}">
              <div class="storySelection-list" *ngFor="let phase of subPhases()" (click)='changeStoryTracker_Phase(phase)'>
                <em [ngClass]="['iconb-' + story.storyPhaseIcon(phase.tracker_phase_name)]"></em>
                <span class="selectedType-name">{{ phase.tracker_phase_name }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="quickAction-listBlock" *ngIf="story && !story.parent_id">
        <div class="quickAction-typeList">
          <span class="listBlock-label">Phase</span>
          <div class="actionBlock">
            <div class="storyTypeDD-selected">
              <div class="ifReadOnly2" *ngIf="(story.core && story.phase == 'Product Roadmap') || (story.core && story.wireframe) "></div>
                <span class="selected-type" (click)="focusDropDown()">
                  <em [ngClass]="['iconb-' + story.storyPhaseIcon(story.phase)]" *ngIf="story.phase"></em>
                  <span class="selectedType-name">{{(story.phase ? story.phase : 'Select phase') }}</span>
                  <em class="iconb-dd-down"></em>
                </span>
            </div>
            <div class="storySelection-DD" [ngClass]="{'openDD': story.showDropDown}">
              <div class="storySelection-list" *ngFor="let phase of phases()" (click)='changeStoryPhase(phase)'>
                <em [ngClass]="['iconb-' + story.storyPhaseIcon(phase)]"></em>
                <span class="selectedType-name">{{ phase }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="story.deleted_at">
      <div class="quickAction-listBlock">
        <div class="quickAction-list singleList" (click)="showUnarchivePopup()" style="margin-bottom: -5px;">
          <em class="iconb-bin"></em>
          <span class="storyStatus-quickActionsText">Unarchive</span>
        </div>
      </div>
    </ng-container>
  </div>
  
  <div (click)="toggleEditMode(story?.id)">
    <div class="inactive-row" *ngIf="story?.inactive">
      <span class="inactive-tag">Inactive</span>
    </div>
    <!-- <div class="tag-block"  *ngIf="story.epics && story.epics.length > 0">
      <div class="epic-link" *ngFor="let epic of story?.epics;" (click)="applyFilterOnIconClick('feature', epic?.id)">
        <span class="epicName" *ngIf = "epic">{{epic.title}}</span>
      </div>
      <div class="forMore" *ngIf="story.epics.length > 2">+{{story.epics.length -2}}</div>
    </div> -->
    <div class="mincard-top-row">
      <div class="top-left">
        <!-- <div class="feature-tag-wrapper">
          <span class="feature-tag" *ngIf="story?.epics.length > 0" (click)="applyFilterOnIconClick('feature', story?.epics[0]?.id)">{{startCase(story?.epics[0]?.title)}}</span>
          <span class="extra-tags" *ngIf="story?.epics.length > 1">+{{story?.epics.length-1}}</span>
        </div> -->
        <div class="feature-tag-wrapper">
          <div class="ifReadOnly" *ngIf="disableFeatureChange(story,project)">
            <span class="elementHover left-align">This field cannot be updated for this story.</span>
          </div>
          <div class="dropdown-trigger" *ngIf="story?.epics.length > 0" (click)="focusDropdown($event,'feature'+story.id)">
            <span class="feature-tag">{{startCase(story?.epics[0]?.title)}}
              <span class="iconb-dd-down dropdown-arrow" [ngClass]="{'expanded': projectService.dropDownType === 'feature'+story.id}"></span>
            </span>
            <span class="extra-tags" *ngIf="story?.epics.length > 1">+{{story?.epics.length-1}}</span>
          </div>
          <div class="mincard-common-dropdown full-width" [ngClass]="{'expanded': projectService.dropDownType === 'feature'+story.id}">
            <div class="feature-listing-wrapper">
              <div class="feature-list-search" (click)="$event.stopPropagation()">
                <input class="feature-search" [(ngModel)]="featureSearchText" placeholder="Search Feature">
                <em class="iconb-search"></em>
              </div>
              <div class="feature-listing">
                <ng-container *ngFor="let epic of projectFeatures | epicSearch: featureSearchText">
                  <div class="inner-feature" (click)="addStoryToEpic($event, epic, story)">{{epic?.title}}</div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
        <span class="story-type">
          <!-- <em class="iconb-bug"></em> -->
          <em [class]="story.storyTypeIcon_minCard(story.story_type, story?.parent_id !== null)"></em>
          <span class="elementHover text-capitalize">{{(story.parent_id && story.story_type === 'task') ? 'Sub Task' : story.storyTypeName()}}</span>
        </span>
      </div>
      <div class="top-right">
        <div class="user-list">
          <!-- <div class="user-initials owner" *ngIf="story && story.owned_by_name" (click)="applyFilterOnIconClick('owner', story.owned_by_id)">
            <span class="text-uppercase"> {{story?.owned_by_initials?.substr(0,1)}}</span>
            <span class="elementHover right-align">{{story?.owned_by_name}}</span>
          </div>
          <div class="user-initials reviewer" *ngIf="story && story.reviewer_name" (click)="applyFilterOnIconClick('reviewer', story.reviewer_id)">
            <span class="text-uppercase">{{story?.reviewer_initials?.substr(0,1)}}</span>
            <span class="elementHover right-align">{{story?.reviewer_name}}</span>
          </div> -->
          <div class="dropdown-trigger" (click)="focusDropdown($event,'user'+story.id)">
            <div class="user-initials owner" *ngIf="story && story.owned_by_name">
              <span class="text-uppercase">{{story?.owned_by_initials?.substr(0,1)}}</span>
              <span class="elementHover right-align">{{story?.owned_by_name}}</span>
            </div>
            <div class="user-initials reviewer" *ngIf="story && story.reviewer_name">
              <span class="text-uppercase">{{story?.reviewer_initials?.substr(0,1)}}</span>
              <span class="elementHover right-align">{{story?.reviewer_name}}</span>
            </div>
          </div>
          <div class="mincard-common-dropdown lg" [ngClass]="{'expanded': projectService.dropDownType === 'user'+story.id}">
            <div class="close-user-selection">
              <em class="iconb-close"  (click)="focusDropdown($event,'user'+story.id)"></em>
            </div>
            <div class="users-wrapper">
              <div class="user-row" *ngIf="story && story.owned_by_name">
                <div class="ifReadOnly" *ngIf="story && !story.testCase() && (projectService.checkRestriction(story,project) || projectService.validateStoryPermission(story,project) || !story.phase || (disableStoryStatusChange() && (story.owner_email === 'tracker_systemadmin@builder.ai') && story.state === 'accepted'))">
                  <span class="elementHover left-align">You are not authorized to perform this action.</span>
                </div>
                <div class="ifReadOnly" *ngIf="story && story.testCase() && validateStoryPermission(story,project)">
                  <span class="elementHover left-align">You are not authorized to perform this action.</span>
                </div>
                <div class="username" (click)="focusUserDropdown($event,'owner')">
                  <span class="initial">{{story?.owned_by_initials?.substr(0,1)}}</span>
                  <span class="name">{{story?.owned_by_name}}</span>
                </div>
                <div class="userSelection-DD" [ngClass]="{'openUserDD': userDropDownType === 'owner'}">
                  <div class="userSearchWrapper">
                    <input type="text" class="userSearchFilter" (click)="$event.stopPropagation()" [(ngModel)] = "searchUser" placeholder="Search by name or email">
                  </div>
                  <div class="listingWrapper">
                    <ng-container  *ngFor = "let user of sortedUsers | membersearch: searchUser: 'requesteduser' | squadMemberSearch: story; index as i;">
                      <div class="userSelection-list" [ngClass]="{'userNotActive': !project.isUserActive(user) || commonStoryService.checkSameUser(user,'owner', story, project)}">
                        <div class="disableClick" *ngIf="!project.isUserActive(user) || commonStoryService.checkSameUser(user,'owner', story, project)"></div>
                        <div class="clickContainer" (click)="setUser($event,user,'Owner')">
                          <span class="selectUser-initial">{{user?.initials | slice:0:1}}</span>
                          <span class="selectUser-descriptionWrapper">
                            <span class="ifUserDeallocated" *ngIf="!project.isUserActive(user)">{{ project.userInactiveText(user) }}</span>
                            <span class="ifUserDeallocated sameUser" *ngIf="story && commonStoryService.checkSameUser(user,'owner', story, project)">Owner and Reviewer cannot be the same.</span>
                            <span class="selectUser-name">{{user?.name}} <span *ngIf = "project && project.checkIFProjectExternal && user.skill">({{user.skill}})</span></span>
                            <span class="selectUser-email">{{user?.email}}</span>
                          </span>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
                <div class="time-role">
                  <div class="inner-col">
                    <div class="title">Allocated Time</div>

                    <div class="value is-time">
                      <div class="storyEstimate-wrapper">
                        <div class="timer_begins" *ngIf="checkforTimeInput(story) && !story_update_reason_option" (click)="askReasonForTimeInput('owner_time',$event)"></div>
                        <label for="ownerTime1" class="iconb-clock1"></label>
                        <div class="dynamicWidth-forInput">
                          <span class="inputDynamic">{{input_estimate}}</span>
                          <input #ownerTimeInput id="ownerTime1" type="text" maxlength="3" autocomplete="off" 
                            [disabled] = "story && (projectService.checkRestriction(story,project) || !story.owned_by_name || !policyService.projectAdmin(project))"
                            class="estimateHours" 
                            (keypress) = "sharedService.restrictNumeric($event)" 
                            (focusin)="openOwnerQuickTimeEstimate($event)"  
                            (focusout)="updateEstimate('estimate')"  
                            [(ngModel)]="input_estimate" placeholder="0"/>
                        </div>
                        <label for="ownerTime1" class="hrs-text">{{ story?.estimate == 1? 'hr' : 'hrs'}}</label>
                        <em class="iconb-edit-2" [ngClass]="{'disabled': !policyService.projectAdmin(project)}" (click)="openOwnerQuickTimeEstimate($event)"></em>
                        <span class="elementHover"  *ngIf="!story?.owned_by_name && policyService.projectAdmin(project)">Please assign owner</span>
                        <span class="elementHover-extra" *ngIf="!policyService.projectAdmin(project)">You are not authorized to perform this action.</span>
                        <div class="quickTimeAction-backdrop" *ngIf="insertOwnerTime" (click)="closeOwnerQuickTimeEstimate($event)"></div>
                        <div class="quickTimeAction" [ngClass]="{'openTimeAction': insertOwnerTime,'pointer-none' : mincardUpdateLoader}">
                          <span class="timeIncButton" (click)="increaseOwnerEstimate(1,$event)">+ 1h</span>
                          <span class="timeIncButton" (click)="increaseOwnerEstimate(2,$event)">+ 2h</span>
                          <span class="timeIncButton" (click)="increaseOwnerEstimate(4,$event)">+ 4h</span>
                          <span class="timeIncButton" (click)="increaseOwnerEstimate(8,$event)">+ 8h</span>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div class="inner-col">
                    <div class="title">Role</div>
                    <div class="value">Owner</div>
                  </div>
                </div>
              </div>
              <div class="user-row" *ngIf="story && story.reviewer_name">
                <div class="ifReadOnly" *ngIf="story && (projectService.checkRestriction(story,project) || projectService.validateStoryPermission(story,project) || !story.phase || (disableStoryStatusChange() && (story.owner_email === 'tracker_systemadmin@builder.ai') && story.state === 'accepted'))">
                  <span class="elementHover left-align">You are not authorized to perform this action.</span>
                </div>
                <div class="username" (click)="focusUserDropdown($event,'reviewer')">
                  <span class="initial is-blue">{{story?.reviewer_initials?.substr(0,1)}}</span>
                  <span class="name">{{story?.reviewer_name}}</span>
                </div>
                <div class="userSelection-DD" [ngClass]="{'openUserDD': userDropDownType === 'reviewer'}">
                  <div class="userSearchWrapper">
                    <input type="text" class="userSearchFilter" (click)="$event.stopPropagation()" [(ngModel)] = "searchUser" placeholder="Search by name or email">
                  </div>
                  <div class="listingWrapper">
                    <ng-container  *ngFor = "let user of sortedUsers | membersearch: searchUser: 'requesteduser' | squadMemberSearch: story; index as i;">
                      <div class="userSelection-list" [ngClass]="{'userNotActive': !project.isUserActive(user) || commonStoryService.checkSameUser(user,'reviewer', story, project)}">
                        <div class="disableClick" *ngIf="!project.isUserActive(user) || commonStoryService.checkSameUser(user,'reviewer', story, project)"></div>
                        <div class="clickContainer" (click)="setUser($event,user,'Reviewer')">
                          <span class="selectUser-initial">{{user?.initials | slice:0:1}}</span>
                          <span class="selectUser-descriptionWrapper">
                            <span class="ifUserDeallocated" *ngIf="!project.isUserActive(user)">{{ project.userInactiveText(user) }}</span>
                            <span class="ifUserDeallocated sameUser" *ngIf="story && commonStoryService.checkSameUser(user,'reviewer', story, project)">Owner and Reviewer cannot be the same.</span>
                            <span class="selectUser-name">{{user?.name}} <span *ngIf = "project && project.checkIFProjectExternal && user.skill">({{user.skill}})</span></span>
                            <span class="selectUser-email">{{user?.email}}</span>
                          </span>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
                <div class="time-role">
                  <div class="inner-col">
                    <div class="title">Allocated Time</div>

                    <div class="value is-time">
                      <div class="storyEstimate-wrapper">
                        <div class="timer_begins" *ngIf="checkforTimeInput(story,'reviewer') && !story_update_reason_option" (click)="askReasonForTimeInput('reviewer_time',$event)"></div>
                        <label for="reviewerTime1" class="iconb-clock1"></label>
                        <div class="dynamicWidth-forInput">
                          <span class="inputDynamic">{{input_reviewer_estimate}}</span>
                          <input 
                          #reviewerTimeInput
                          id="reviewerTime1" 
                          type="text" 
                          maxlength="3" 
                          autocomplete="off" 
                          class="estimateHours" 
                          [disabled] = "story && (projectService.checkRestriction(story,project) || !story.reviewer_name || !policyService.projectAdmin(project))"
                          (keypress) = "sharedService.restrictNumeric($event)" 
                          (focusin)="openReviewerQuickTimeEstimate($event)"  
                          (focusout)="updateEstimate('reviewer_estimate')" 
                          [(ngModel)] = "input_reviewer_estimate" placeholder="0"/>
                        </div>
                        <label for="reviewerTime1" class="hrs-text">{{ story?.reviewer_estimate == 1? 'hr' : 'hrs'}}</label>
                        <em class="iconb-edit-2" [ngClass]="{'disabled': !policyService.projectAdmin(project)}" (click)="openReviewerQuickTimeEstimate($event)"></em>
                        <span class="elementHover" *ngIf="!story?.reviewer_name && policyService.projectAdmin(project)">Please assign reviewer</span>
                        <span class="elementHover-extra" *ngIf="!policyService.projectAdmin(project)">You are not authorized to perform this action.</span>
                        <div class="quickTimeAction-backdrop" *ngIf="insertReviewerTime" (click)="closeReviewerQuickTimeEstimate($event)"></div>
                        <div class="quickTimeAction" *ngIf="story?.reviewer_name" [ngClass]="{'openTimeAction': insertReviewerTime,'pointer-none' : mincardUpdateLoader}">
                          <!-- <em class="iconb-close" (click)="closeReviewerQuickTimeEstimate()"></em> -->
                          <span class="timeIncButton" (click)="increaseReviewerEstimate(1,$event)">+ 1h</span>
                          <span class="timeIncButton" (click)="increaseReviewerEstimate(2,$event)">+ 2h</span>
                          <span class="timeIncButton" (click)="increaseReviewerEstimate(4,$event)">+ 4h</span>
                          <span class="timeIncButton" (click)="increaseReviewerEstimate(8,$event)">+ 8h</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="inner-col">
                    <div class="title">Role</div>
                    <div class="value">Reviewer</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="archive" *ngIf="story?.deleted_at">
          <em class="iconb-archive-new"></em>
          <span class="elementHover right-align">Archived</span>
        </div>
      </div>
    </div>

    <div class="status-roles">
      <div class="status"><span class="status-bg" [ngClass]="[story.state]"></span>{{story.state}}</div>
      <div class="role" *ngIf="story && project && project.dashboard_project_id && story.role_name">
        <div class="ifReadOnly" *ngIf="story && (projectService.checkRestriction(story,project) || projectService.validateStoryPermission(story,project) || (story.core && story.phase === 'Design') )">
          <span class="elementHover right-align">You are not authorized to perform this action. <br> Please contact your delivery manager.</span>
        </div>
        <div class="dropdown-trigger" (click)="focusDropdown($event,'role'+story.id)">
          <em class="iconb-user-group"></em>{{ story.role_name}}
          <span class="iconb-dd-down dropdown-arrow" [ngClass]="{'expanded': projectService.dropDownType === 'role'+story.id}"></span>
          <span class="elementHover right-align">{{ story.role_name}}</span>
        </div>
        <div class="mincard-common-dropdown" [ngClass]="{'expanded': projectService.dropDownType === 'role'+story.id}">
          <div class="roles-list">
            <ng-container  *ngFor="let st_role of studioRoles">
              <div class="role-inner" (click)="changeStoryStudioRole($event,st_role.studio_role.id)">
                <em class="iconb-user-group"></em>
                <span class="role-text" >{{st_role?.studio_role.name}}</span>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>

    <div class="mincard-title" [ngClass]="{'is-subtask': story.parent_id}">
      <em class="subtask-icon iconb-subtask-new" *ngIf="story.parent_id"></em>{{story.title}}
    </div>

    <div class="mincard-icons-row">
      <div class="left-icons">
        <div class="inner-icon" *ngIf="story && !story.parent_id && (story.total_tasks_count && story.total_tasks_count > 0)">
          <em class="icon iconb-subtask-new"></em>
          <span class="icon-label">{{story.done_tasks_count}}/{{story.total_tasks_count}}</span>
          <span class="elementHover">Sub Tasks</span>
        </div>
        <div class="inner-icon" *ngIf="story.notes_count > 0">
          <em class="icon iconb-comment-new"></em>
          <span class="icon-label">{{story.notes_count}}</span>
          <span class="elementHover">Comments</span>
        </div>
        <div class="inner-icon is-static" *ngIf="story && story.hasStoryPlatforms">
          <em [ngClass]="[getIconClass(story.storyDevicePlatformsData)]"></em>
          <div class="devicePlatform-dd">
            <div class="deviceName-container" *ngFor="let devicePlatformData of story.storyDevicePlatformsData">
              <div class="deviceName-title">{{devicePlatformData.device}}</div>
              <div class="deviceName-list" *ngFor="let platform of devicePlatformData.platforms">
                <span class="deviceName-info">- {{ platform?.display_name }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="inner-icon" *ngIf="story.phase">
          <em *ngIf="!story.task" [ngClass]="['iconb-' + story.storyPhaseIcon(story.phase), 'icon']"></em>
          <em *ngIf="story.task" [ngClass]="['iconb-' + story.storyPhaseIcon(story.task), 'icon']"></em>
          <span class="elementHover">{{ story?.parent_id ? story.task : story.phase }}</span>
        </div>
      </div>
      <div class="right-icons">
        <div class="inner-icon has-dropdown" [ngClass]="getPriorityClass(story.priority)" *ngIf="story && story.priority">
          <div class="ifReadOnly" *ngIf="story && (projectService.checkRestriction(story,project) || projectService.validateStoryPermission(story,project) || (story.core && story.phase == 'Product Roadmap') || (story.wireframe && story.parent_id === null))">
            <span class="elementHover right-align">You are not authorized to perform this action. <br> Please contact your delivery manager.</span>
          </div>
          <div class="dropdown-trigger" (click)="focusDropdown($event,'priority'+story.id)">
            <em class="icon iconb-priority-flag"></em>
            <span class="icon-label">{{ getPriority() }}</span>
            <span class="iconb-dd-down dropdown-arrow" [ngClass]="{'expanded': projectService.dropDownType === 'priority'+story.id}"></span>
            <span class="elementHover right-align">Priority : {{story.priority}}</span>
          </div>
          <div class="mincard-common-dropdown" [ngClass]="{'expanded': projectService.dropDownType === 'priority'+story.id}">
            <div class="priority-list" *ngFor="let priority of storyPriorityOptions">
              <div class="priority-inner" [ngClass]="getPriorityClass(priority.type)" (click)="changeStoryPriority($event,priority.type)" ><em class="icon iconb-priority-flag"></em>{{priority.type.split(" -")[0]}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <div class="mincard-timer">
      <div class="timings">
        <div class="clock-icon">
          <em class="iconb-timings"></em>
          <div class="timerHover">
            <span class="timer_type">Owner Allocated Time:</span><span class="timer_value">{{secsToDuration(story.estimate*3600)}}</span>
            <span class="timer_type">Reviewer Allocated Time:</span><span class="timer_value">{{secsToDuration(story.reviewer_estimate*3600)}}</span>
          </div>
        </div>
        
        <div class="timing-detail">
          {{ total_elapsed_time() }}
          <span class="elementHover">Total Elapsed Time</span>
        </div>
      </div>
      <div class="timer-progress">
        <div class="progressbar">
          <span class="actual-progress" [style.width.%]="story.time_completed"></span>
        </div>
        <div class="time" *ngIf="!(story.current_reviewer_timer_activity || story.current_timer_activity)">
          <span class="timer"><span>{{elapsedTime}}</span></span>
        </div >
        <story-timer [project] = "project" class="time" [type] = "'total'" [story]="story" *ngIf="story.current_reviewer_timer_activity || story.current_timer_activity"></story-timer>
      </div>
    </div>

    <div class="mincard-bottom" *ngIf="story.labels && story.labels.length > 0">
      <div class="bottom-left">
        <span class="state-label is-grey" *ngFor="let label of story.labels;" (click)="applyFilterOnIconClick('label', label?.value)">{{label?.value}}</span>
      </div>
    </div>

    <div class="mincard-bottom">
      <div class="bottom-icons" [ngClass]="{'d-flex': project && !project.external()}">
        <div class="icon" (click)="copyStoryLink()" [clip]="story?.url">
          <em class=""><img src="../../../assets/images/link.svg" alt=""></em>
          <div class="elementHover left-align">{{!showCopiedLink ? 'Copy Story Link' : 'Story Link Copied'}}</div>
        </div>
      </div>
      <div class="bottom-left states">
        <span class="state-label" *ngIf="story && project && project.external()">{{ story.core ? 'Core' : 'Custom' }}</span>
        <span class="state-label" *ngIf="story && story.status && project && project.external() && !story.isTestCaseOrBug()">{{ story.status === 'ready' ? 'Ready' : 'Draft' }}</span>
      </div>

      <div class="bottom-right">
        <div class="commits" *ngIf="story.commits_count > 0">
          <em class="iconb-commits"></em>
          <span class="count">{{story.commits_count}}</span>
          <span class="elementHover">Code Commits</span>
        </div>
        <div class="story-id" (click)="copyStoryID()" [clip]="story?.id">
          <span>ID: {{story?.id}}</span>
          <em class="iconb-copy-new"></em>
          <span class="elementHover">{{!showCopiedID ? 'Copy Story ID' : 'Story ID Copied'}}</span>
        </div>
      </div>
    </div>
  </div>
</div>


<div *ngIf="showConvertSubtaskPopup" class="commonPopUp">
  <div class="commonPopUp-backdrop" (click)="closeConvertSubtaskPopup()"></div>
  <div class="commonPopUp-wrapper">
    <div class="common-Loader commonPopup-Loader" *ngIf="waitForLoader">
      <img src="../../assets/images/storyBoardLoader.gif" alt=""/>
    </div>
    <div class="commonPopUp-title">Convert Subtasks to Parent Task</div>
    <div class="commonPopUp-text" *ngIf="story.phase !== 'Design'">Are you certain about promoting this subtask as the parent? Please bear in mind that if this subtask has any of its own subtasks, they will also be detached from the main story.</div>
    <div class="commonPopUp-text" *ngIf="story.phase === 'Design'">Sub-task with design phase cannot be promoted to Parent story as a feature can have a maximum of one parent story per platform per role.</div>
    <div class="commonPopUp-actionBtn">
      <span class="commonPopUp-submit-s" *ngIf="story.phase !== 'Design'" (click)="convertSubTaskToTask(story)">Yes</span>
      <span class="commonPopUp-cancel-s" (click)="closeConvertSubtaskPopup()">Cancel</span>
    </div>
  </div>
</div>

<ng-container>
  <div *ngIf="openCommonPopUp" class="commonPopUp">
    <div class="commonPopUp-backdrop" (click)="closeCommonPopUp()"></div>
    <div class="commonPopUp-wrapper">
      <div class="bulkAction-Loader" *ngIf="loaderForConvertSubtask">
        <img src="../../assets/images/storyBoardLoader.gif" alt=""/>
      </div>
      <div class="commonPopUp-title">Archive</div>
      <div class="commonPopUp-text">The status of the story is {{ story.state }} and time tracked is {{ sharedService.totalTimeTracked(story) }}, are you sure you want to archive it?</div>
      <div class="commonPopUp-actionBtn">
        <span class="commonPopUp-submit-s" (click)="archive()">Archive</span>
        <span class="commonPopUp-cancel-s" (click)="closeCommonPopUp()">Cancel</span>
      </div>
    </div>
  </div>
</ng-container>

<app-custom-popup
  [texts]="{ptitle: stateActionTextTitle, ptext: 'Are you sure you want to ' + stateActionText + '?', psuccess: stateActionTextTitle, pcancel: 'Cancel'}"
  (closeCustomPopUp)="closePopUp()" (deleteElement)="stateActionChanged($event)" [delement]="stateAction"
  [OpenPopUp]="OpenAcceptPopup" [waitForLoader]="waitForLoader"></app-custom-popup>

<app-custom-popup
  [texts]="{ptitle: stateActionTextTitle, ptext: 'There' + (story?.calcPendingTestCasesAndSubtasks() == 1? ' is ' : ' are ') + (story?.calcPendingTestCasesAndSubtasks()) + ' incomplete Task/Test Case' + ' within this story. Please action them before accepting this story.', psuccess: 'Okay', pcancel: ''}"
  (closeCustomPopUp)="closeAlertPopUp()" (deleteElement)="closeAlertPopUp()" [OpenPopUp]="OpenAcceptAlertPopup" [waitForLoader]="waitForLoader"></app-custom-popup>

<app-custom-popup
  [texts]="{ptitle: 'Warning',
            ptext: 'No commit was found for this story on GitLab.<b> Learn More!</b> for more details.',
            psuccess: 'OK', pcancel: ''}"
  (closeCustomPopUp)="closeCommitWarningPopup()" (deleteElement)="closeCommitWarningPopup()" [OpenPopUp]="showCommitWarningPopup" [waitForLoader]="waitForLoader"></app-custom-popup>
 
<div *ngIf="openUnarchivePopUp" class="commonPopUp">
  <div class="commonPopUp-backdrop" (click)="closeUnarchivePopUp()"></div>
  <div class="commonPopUp-wrapper">
    <div class="bulkAction-Loader" *ngIf="archived_story_detail_loader">
      <img src="../../assets/images/storyBoardLoader.gif" alt=""/>
    </div>
    <div class="commonPopUp-title">Unarchive</div>
    <div class="commonPopUp-text" *ngIf="projectService.bbExists(story)">
      Story cannot be unarchived as this feature is in Ready for certification or BB exists. 
    </div>
    <div class="commonPopUp-text" *ngIf="!story?.parent_id && !projectService.bbExists(story)">
      {{ checkParentArchiveDetail() ? 'Are you sure you want to unarchive this story?' : 'This story is a parent story with ' + (archived_story_detail?.archived_subtask_count) + ' subtasks. Do you want to unarchive all subtasks?' }} 
    </div>
    <div class="commonPopUp-text" *ngIf="story?.parent_id && !projectService.bbExists(story)">
      {{ archived_story_detail.parent_deleted_at ?  'This story is a subtask of a parent story which is in an archived state. Do you still want to unarchive it as it will lose its connection with its parent story?' : 'Are you sure you want to unarchive this story?' }}
      </div>
    <div class="commonPopUp-actionBtn">
      <span class="commonPopUp-submit-s" *ngIf="!projectService.bbExists(story)" (click)="unarchive()">Confirm</span>
      <span class="commonPopUp-cancel-s" (click)="closeUnarchivePopUp()">Cancel</span>
    </div>
  </div>
</div>

<div class="warningPopUp" *ngIf = "openTimerReasonsPopup">
  <div class="warningPopUp-backdrop" ></div>
  <div class="warningPopUp-wrapper storyUpdate-reason" (click)="$event.stopPropagation()">
    <div class="warningPopUp-title">{{"Can you please spare a moment to let us know why you're updating hours?"}} </div>
    <div class="bugReason-listWrapper">
      <div class="bugreason-selectBlock">
        <div class="selected-bugreason" (click)="openStoryUpdateReasonsLists()">{{ story_update_reason_option ?  story_update_reason_option : 'Select reason'}} <em class="iconb-dd-down" ></em></div>
        <div class="dd-wrapper" *ngIf="showStoryupdateReasonList">
          <div class="bugReason-list" *ngFor="let updateReason of story_update_reason" (click)="selectStoryupdateReason(updateReason)">{{updateReason?.reason}}</div>
        </div>
      </div>
      <div class="logReason-actionBt">
        <button type="button" class="warningPopUp-submit" [disabled]="!story_update_reason_option" (click)="updateReason('proceed',$event)">Proceed</button>
        <span class="warningPopUp-cancel" (click) = "updateReason('cancel',$event)" >Cancel</span>
      </div>
    </div>
  </div>
</div>