import { Component, OnInit, Input } from '@angular/core';
import { Sprint } from '../../shared/models/sprint';
import { SprintService } from '../../shared/services/sprint.service';
import moment from 'moment-timezone';

@Component({
  selector: 'app-sprint-popup',
  templateUrl: './sprint-popup.component.html',
  styleUrls: ['./sprint-popup.component.scss']
})
export class SprintPopupComponent implements OnInit {
  @Input() sprint_Details: Sprint;
  @Input() projectId;
  @Input() popupFor;
  startDt: any;
  endDt: any;
  startLoader:boolean = false;
  createSprint: any = {
    name: "",
    description: "",
    projected_start_date: "",
    projected_end_date: "",
    id: ''
  };
  public mytime: Date = new Date();
  currentYear: any = this.mytime.getUTCFullYear();
  currentDate: any = this.mytime.getUTCDate() - 1;
  currentMonth: any = this.mytime.getUTCMonth() + 1;
  datePickerOptionsStart = {
    dateFormat: 'dd/mm/yyyy',
    openSelectorTopOfInput: true,
    showSelectorArrow: false,
    alignSelectorRight: true
  };
  setDisableUntil = {
    year: this.currentYear, month: this.currentMonth, day: this.currentDate
  };
  datePickerOptionsEnd:any;
  showStartDateError:boolean = false;
  enableUpdateButton:boolean = false;
  disableStartDate:boolean = false;
  disableEndDate:boolean = false;

  constructor(public sprintService: SprintService) { }

  ngOnInit() {
    if(this.popupFor === 'add_sprint') {
      this.sprint_Details = this.createSprint;
    } else{
      if (this.sprint_Details.projected_start_date) {
        this.startDt = {isRange:false, singleDate: {jsDate: new Date(this.sprint_Details.projected_start_date)}};
        this.endDt = {isRange:false, singleDate: {jsDate: new Date(this.sprint_Details.projected_end_date)}}; 
      }
      this.setSprintData(this.sprint_Details);
    }
  }

  setSprintData(sprint_data?) {
    this.disableStartDate = false;
    this.disableEndDate = false;
    this.enableUpdateButton = false;
    sprint_data.showMoreAction = false;
    if (sprint_data) {
      this.enableUpdateButton = true;
      if (sprint_data.status !== 'draft') {
        this.disableStartDate = true;
        this.initiateCalendarOptions();
      }
      if (sprint_data.status === 'completed') {
        this.disableEndDate = true;
      }
      if (sprint_data.status === 'draft') {
        this.initiateCalendarOptions();
      }
    }
  }

  initiateCalendarOptions() {
    this.datePickerOptionsEnd = {
      dateFormat: 'dd/mm/yyyy',
      openSelectorTopOfInput: true,
      showSelectorArrow: false,
      alignSelectorRight: true,
      disableUntil: this.setDisable()
    };
  }

  setDisable() {
    let getdates;
    if(this.sprint_Details && this.sprint_Details.projected_start_date){
      getdates = new Date(this.sprint_Details.projected_start_date);
    }
    return {year: getdates ? getdates.getFullYear() : this.currentYear, month: getdates ? (getdates.getMonth() + 1) : this.currentMonth, day: getdates ? (getdates.getDate()) : this.currentDate};
  }

  onStartDateChanged(event) {
    this.sprint_Details.projected_start_date = event.singleDate.formatted;
    this.setDisableUntil = event.singleDate.date;
    this.datePickerOptionsEnd = {
      dateFormat: 'dd/mm/yyyy',
      openSelectorTopOfInput: true,
      showSelectorArrow: false,
      alignSelectorRight: true,
      disableUntil: this.setDisableUntil
    };
    this.setEndDate(event.singleDate.formatted)
    this.showStartDateError = false;
    if (this.endDt && (event.singleDate.jsDate.getTime() > this.endDt.singleDate.jsDate.getTime())) {
      this.endDt = null;
    }
  }

  setEndDate(startDate){
    const date = moment(startDate, 'DD/MM/YYYY').toDate();
    let getWorkingDaysCount = 13
    const getDay = date.getDay()
    switch(getDay){
    case 0:
      getWorkingDaysCount = 12
      break;
    case 1:
      getWorkingDaysCount = 11
      break;
    default:
      getWorkingDaysCount = 13
    }
    date.setDate(date.getDate() + getWorkingDaysCount);
    this.sprint_Details.projected_end_date = `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`;;
    this.endDt = {isRange:false, singleDate: {jsDate: date}};

}
  onEndDateChanged(event) {
    this.sprint_Details.projected_end_date = event.singleDate.formatted;
  }

  closeAddSprintPopup() {
    this.sprintService.editSprint = false;
    this.disableStartDate = false;
    this.sprint_Details.projected_start_date = this.sprint_Details.clone_projected_start_date;
    this.sprint_Details.projected_end_date = this.sprint_Details.clone_projected_end_date;
    this.sprint_Details.name = this.sprint_Details.clone_name;
    this.sprint_Details.description = this.sprint_Details.clone_description;
  }

  showSelectStartDate() {
    this.showStartDateError = true;
  }

}
