import * as _ from 'lodash';
import {
  Component,
  ElementRef,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  Renderer2
} from '@angular/core';

import {
  Epic,
} from '../../shared/models/epic';

import {
  Project,
} from '../../shared/models/project';

import {
  Story
} from '../../shared/models/story';
import { trigger, transition, style, animate } from '@angular/animations';

import { ProjectColumnData, EVENT_TYPES, PROJECT_COLUMN_DATA, PROJECT_COLUMN_IDENTIFIER } from '../../shared/dataTypes';
import { StoryService } from '../../shared/services/story.service';
import { PolicyService } from '../../shared/services/policy.service';
import { EpicService } from '../../shared/services/epic.service';
import { UserService } from '../../shared/services/user.service';
import { ErrorService } from '../../shared/errorFunction';
import { NotificationService } from '../../shared/services/notification.service';
import { ProjectService } from '../../shared/services/project.service';
import { SharedService } from '../../shared/services/shared.service';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import {CustomToastService} from '../../shared/services/custom-toast.service';
import { ProjectSyncService } from '../../shared/services/projectSync.service';
import { Subscription } from 'rxjs';

@Component({
  selector: '[project-column]',
  templateUrl: 'projectColumn.component.html',
  host: {
    '(document:click)': 'onClick($event)',
  },
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({
          opacity:'0',
          top: '40px'
        }),
        animate(100, style({
          opacity:'1',
          top: '22px'
        }))
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate(100, style({
          opacity:'0',
          top: '40px'
        }))
      ])
    ]),
    trigger('storyLoad', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({
          opacity:'0',
          top: '20px'
        }),
        animate(200, style({
          opacity:'1',
          top: '0'
        }))
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate(200, style({
          opacity:'1',
          top: '0'
        }))
      ])
    ])
  ]
})
export class ProjectColumnComponent implements OnInit {
  @Input('column') projectColumn: ProjectColumnData;
  @Input() project: Project;
  columnData: Array<Story> = [];
  disbaleRenameList = false;
  searchQuery: string;
  epics: Array<Epic> = [];
  searchStory = false;
  showHtml = false;
  can_update = false;
  is_search_view_open = false;
  @ViewChild('storycolumn', { read: ElementRef }) storyColumn: ElementRef;
  startLoader: boolean = false;
  createNewTask: boolean = false;
  newStory_title: string = '';
  addEpic_inNewStory: Epic = undefined;
  moreAvailable: boolean = true;
  isPending: boolean = false;
  isNewAdd: boolean = false;
  isRemoved: boolean = false;
  isCreateNewStoryDisable: boolean = false;
  isStoryLoading: boolean = false;
  @Output('applyFilterLabelNext') applyFilterLabelNext = new EventEmitter();
  columnActionToggle:boolean = false;
  editColumnName:boolean = false;
  OpencolumnNamePopup:boolean = false;
  waitForLoader = false;
  columnName: string = '';
  @ViewChild('focusNewStoryTitle') public focusNewStoryTitle: ElementRef;
  newStoryCreationReason = '';
  private notificationSubscriptions: Subscription[] = [];

  constructor(private notificationService: NotificationService,
              public userService: UserService,
              public policyService: PolicyService,
              private storyService: StoryService,
              private cdr: ChangeDetectorRef,
              private location: Location,
              private router: Router,
              private errorService: ErrorService,
              private sharedService: SharedService,
              private activatedRoute: ActivatedRoute,
              public projectService: ProjectService,
              private epicService: EpicService,
              private customToast: CustomToastService,
              private projectSyncService: ProjectSyncService,
              private renderer: Renderer2) {

              }


  ngOnInit() {
    this.setColumnData();

    const storyAddedEvent = this.notificationService.subscribeEvent(EVENT_TYPES.STORY.ADDED, res => {
      if (res.payload.data?.story?.list_id === this.projectColumn.list_id) {
        this.addStory(res.payload.data.story);
        this.setColumnData();

        /*  isNewAdd : true => story was not present in this column and now it has been added in this column
            isNewAdd : false => story was already present in this column and now it has been updated in this column */
        if (this.isNewAdd) {
          if(!(this.storyService.skipStoryAdding(res.payload.data.story))){
            this.projectColumn.total_count++;
          }
          /* need to update total_count locally on based on 'isNewAdd' variable's value */
        }
      }
    });
    this.notificationSubscriptions.push(storyAddedEvent);

    const storyRemovedEvent = this.notificationService.subscribeEvent(EVENT_TYPES.STORY.REMOVED, res => {
      if (res.payload.data.column && res.payload.data.column === this.projectColumn.list_id) {
        /* this shows that if column is present in payload data & is same as this column name then story needs to be removed
            from this column. This case is required when column changes due to state change i.e. removing from this column
            and will be added in another. */
        this.removeStory(res.payload.data.story);
        this.setColumnData();

        /*  isRemoved : true => story was present in this column and now it has been removed from this column
            isRemoved : false => story was not present in this column so no action performed related to remove story  */
        if (this.isRemoved) {
          /* need to update total_count locally on based on 'isRemoved' variable's value */
          this.projectColumn.total_count--;
        }
      } else if (res.payload.data?.story?.list_id === this.projectColumn.list_id) {
        /* this shows that if column is not present in payload data and if this column name is same as story's column name then
           story needs to be removed from this column. This case is required when story needs to be removed from the story board. */
        this.removeStory(res.payload.data.story);
        this.setColumnData();

        /*  isRemoved : true => story was present in this column and now it has been removed from this column
            isRemoved : false => story was not present in this column so no action performed related to remove story  */
        if (this.isRemoved) {
          /* need to update total_count locally on based on 'isRemoved' variable's value */
          this.projectColumn.total_count--;
        }
      }

      /* after removing the story from the column, it checks if the column has less no of stories as per per_page_count or
         total_count then it gets more stories in the column if available */
      if (this.projectColumn.stories.length < this.projectColumn.per_page_count &&
        this.projectColumn.stories.length < this.projectColumn.total_count) {
        this.getMoreStories();
      }
    });
    this.notificationSubscriptions.push(storyRemovedEvent);

    const storyUpdatedEvent = this.notificationService.subscribeEvent(EVENT_TYPES.STORY.UPDATED, res => {
      if (res.payload.data?.story?.list_id === this.projectColumn.list_id) {
        this.setColumnData();
      }
    });
    this.notificationSubscriptions.push(storyUpdatedEvent);

    /* As new story creation is only possible in TO_DO column so no need to subscribe in other available columns */
    if (this.projectColumn.columnIdentifier === PROJECT_COLUMN_IDENTIFIER.to_do) {
      const createNewStoryEvent = this.notificationService.subscribeEvent(EVENT_TYPES.EPIC.CREATE_NEW_STORY, res => {
        this.projectColumn.visible = true;    // make column visible if in case it is not
        if (res.payload.data.epic) {
          /* it will be present in case creating a story from feature */
          this.addNewtask();
          this.addEpic_inNewStory = res.payload.data.epic;    // shows which feature needs to be added in story while creating
        }
      });
      this.notificationSubscriptions.push(createNewStoryEvent);
    }

    const epicUpdatedEvent = this.notificationService.subscribeEvent(EVENT_TYPES.EPIC.UPDATED, res => {
      if(res.payload.data.epics && res.payload.data.epics.length > 0) {
         this.project.epics = res.payload.data.epics
         if(this.columnData.length > 0) {
           this.columnData.forEach((st => {
             this.project.epics.forEach((epic => {
               if(epic.story_ids.indexOf(st.id) >= 0) {
                 st.addEpic(epic);
               }
             }))
           }))
         }
      }
    });
    this.notificationSubscriptions.push(epicUpdatedEvent);
  }

  ngOnDestroy() {
    this.notificationSubscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngOnChanges (changes: SimpleChanges) {
    if (changes['project']) {
      this.setColumnData();
      this.cdr.detectChanges();
      if (this.projectColumn.per_page_count != this.projectColumn.stories.length && this.projectColumn.total_count > this.projectColumn.stories.length) {
        setTimeout(() => {
          this.getMoreStories();
        }, 100);
      }
    }
  }

  setColumnData() {
    if (this.project) {
      this.columnData = (_.sortBy(this.projectColumn.stories, 'position') as Array<Story>);
      if (this.projectColumn.total_count > this.projectColumn.stories.length) {
        this.moreAvailable = true;
      }
      this.projectColumn.selectedStoryCount = this.getSelectedStoriesCount();
      this.projectColumn.allSelected = (this.projectColumn.stories.length !== this.projectColumn.selectedStoryCount) ? false : true;
      this.canUpdate();
    }
  }

  getSelectedStoriesCount() {
    return this.columnData.filter(st => st.isSelected).length;
  }

  get policiesActive(): boolean {
    return this.policyService.policiesActive && !!this.project;
  }

  canUpdate() {
    return this.can_update = this.policiesActive && this.policyService.canManageStory(this.project);
  }

  toggleStorySelection(event) {
    setTimeout(() => {
      this.projectService.hide_StoryDetails();
    }, 110);
    if (event.target.checked) {
      this.projectColumn.list_name === 'Archived' ? (this.projectService.archived_story_selected = true) : (this.projectService.normal_story_selected = true)
      this.columnData.forEach(story => {
        if ((!story.isSelected) && (((story.epics.length === 0) || story.epics[0].status !== 'inactive'))) {
          story.isSelected = true;
          this.project.selectedStories.push(story);
        }
      });
      this.projectColumn.allSelected = true;
      // this.projectColumn.selectedStoryCount = this.columnData.length;
      this.projectColumn.selectedStoryCount = this.project.selectedStories.length;
      if (this.project.selectedStories.length === 1) {
        this.sharedService.showQuickAction(this.project);
      } else {
        const qucikStory: Story = this.project.findQuickActionStory();
        if(qucikStory) {
          qucikStory.storyQuickActionClose();
        }
      }
    } else {
      this.columnData.forEach(story => {
        if (story.isSelected) {
          const storyIndex = this.project.findStoryIndex(story);
          if (storyIndex >= 0) {
            this.project.spliceStory(storyIndex);
          }
          story.isSelected = false;
          story.storyQuickActionClose();
        }
      });
      this.projectColumn.allSelected = false;
      this.projectColumn.selectedStoryCount = 0;
      if (this.project.selectedStories.length === 1) {
        this.sharedService.showQuickAction(this.project);
      } else {
        const qucikStory: Story = this.project.findQuickActionStory();
        if(qucikStory) {
          qucikStory.storyQuickActionClose();
        }
      }
      if(this.projectService.archived_story_selected) {
        this.project.selectedStories.length === 0 ? this.projectService.archived_story_selected = false : this.projectService.archived_story_selected = true;
      }
      if(this.projectService.normal_story_selected) {
        this.project.selectedStories.length === 0 ? this.projectService.normal_story_selected = false : this.projectService.normal_story_selected = true;
      }
    }
  }

  checkColSelected(list_name){
   return (this.projectService.archived_story_selected && list_name !== 'Archived') || (this.projectService.normal_story_selected && list_name === 'Archived')
  }

  addNewtask() {
    if(this.policyService.isProjectReadonly(this.project)) {
      this.policyService.readOnlyWarning();
    } else {
      this.createNewTask = true;
      this.projectService.expandCollpaseColumn(this.projectColumn,this.project)
      setTimeout(() => {
        if (this.focusNewStoryTitle) {
          this.focusNewStoryTitle.nativeElement.focus();
        }
      }, 0);
    }
  }
  closeAddNewtask() {
    this.createNewTask = false;
    this.newStory_title = '';
    this.projectService.creationReasonsSelected = '';
  }

  addNewStory() {
    if (!this.isCreateNewStoryDisable) {
      this.isCreateNewStoryDisable = true;
      if (this.newStory_title.trim().length === 0) {
        this.isCreateNewStoryDisable = false;
        this.newStory_title = '';
        return false;
      }
      const newStory = Story.createDefaultStory(this.project, this.userService.getUser());
      newStory.title = this.newStory_title.trim();
      newStory.reason_for_creation = this.projectService.creationReasonsSelected ? this.projectService.creationReasonsSelected : '';
      if (newStory && this.addEpic_inNewStory) {
        newStory.epics.push(this.addEpic_inNewStory);
        newStory.is_epic_added = true;
      } else {
        newStory.is_epic_added = false;
      }
      newStory.list_id = this.projectColumn.list_id;
      let newStoryClone = JSON.parse(JSON.stringify(newStory));
      if(this.addEpic_inNewStory) {
        newStory.epics.forEach(epic => {
          newStory.epic_id = epic.id;
        });
      }
      if (this.projectService.createBug) {
        newStory.story_type = 'bug';
      }
      if(this.project.filters.phase.length === 1 && this.project.external()) {
        newStory.phase = this.project.filters.phase[0];
      }
      this.storyService.createStory(newStory).subscribe((story: Story) => {
        this.projectService.creationReasonsSelected = '';
        this.projectService.createBug = '';
        story.project = this.project;
        this.projectSyncService.updateLocalStory(story, null, null);
        if (!this.addEpic_inNewStory) {
          this.trackStoryCreateSegmentEvent(story);
          if(story.list_id == this.projectColumn.list_id) {
            this.addStoryInColumn(story, false);
          }
        }
        newStory.id = story.id;
        if(story) {
          if(story.epics.length > 0) {
            story.epics.forEach((epic) => {
              this.trackStoryCreateSegmentEvent(story);
              if(this.projectColumn.list_id == story.list_id) {
                this.addStoryInColumn(story, true, epic);
              }
            })
          }
        }
        this.closeAddNewtask();
        this.customToast.messages.push({
          id: 'story_new',
          type: 'success',
          class: 'story_new',
          title: 'Story Added',
          message: story && story.parent_id ? 'Task added successfully' : 'Story added successfully'
        });
        this.addEpic_inNewStory = undefined;
        this.isCreateNewStoryDisable = false;
      }, (error) => {
        this.errorService.errorFunction(error);
        this.addEpic_inNewStory = undefined;
        this.isCreateNewStoryDisable = false;
      });
    }
  }

  applyFilterLabel(data) {
    this.applyFilterLabelNext.emit(data);
  }

  addStoryInColumn(story, isFeatureAdded, epic?) {
    if (this.project.filterCount === 0 || (isFeatureAdded && this.isOnlyFeatureFilterApplied(epic))) {
      const st = new Story(story);
      this.project.addStory(st);
      this.addStory(st);
      this.setColumnData();
      if (this.isNewAdd) {
        if(!(this.storyService.skipStoryAdding(story))){
          this.projectColumn.total_count++;
        }
      }
      if (isFeatureAdded) {
        this.updateEpicData(epic, st);
      }
    }
  }

  isOnlyFeatureFilterApplied(epic) {
    if(this.project) {
      const check = this.project.filterCount === this.project.filters.feature.length;
      if (check) {
        return (this.project.findFilterIndex('feature', epic.id) !== -1);
      }
      return false;
    }
  }

  updateEpicData(epic, story: Story) {
    if ((epic as Epic) && (epic as Epic).project) {
      this.epicService.epic(epic as Epic).subscribe(ep => {
        if (ep) {
          this.project.addEpic(ep);
          if (story) {
            story.refreshEpics();
          }
        }
      });
    }
  }

  isScrollFetchAvailable(): boolean {
    return !this.isPending && this.moreAvailable;
  }

  getMoreStories() {
    if (this.projectColumn.total_count === this.projectColumn.stories.length) {
      this.moreAvailable = false;
    }
    if (this.moreAvailable) {
      const params = {
        list_id: this.projectColumn.list_id,
        page: Math.floor(this.projectColumn.stories.length / this.projectColumn.per_page_count) + 1
      };
      this.getStories(params);
    }
  }

  getStories(params) {
    this.isPending = true;
    this.isStoryLoading = true;
    this.storyService.stories(this.project, null, params, this.projectService.getFiltersForAPICall(this.project)).subscribe(col => {
      const index = this.sharedService.findIndexOfColumn(this.projectColumn.list_id);
      let column = PROJECT_COLUMN_DATA[index];
      if (column && col.stories) {
        column.appendStories(col.stories, this.project);
      }
      if (column) { column.setMetaData(col.meta); }
      this.setColumnData();
      this.isPending = false;
      this.isStoryLoading = false;
    });
  }

  addStory(story: Story) {
    if (story.testCase()) { return; }
    if(this.storyService.skipStoryAdding(story)){
      return;
    }
    this.isNewAdd = false;
    const index = this.projectColumn.stories.findIndex(st => st.id === story.id);
    if (index === -1) {
      this.projectColumn.stories.unshift(story);
      this.isNewAdd = true;
    } else {
      this.projectColumn.stories.splice(index, 1, story);
    }
  }

  removeStory(story: Story) {
    this.isRemoved = false;
    const index = this.projectColumn.stories.findIndex(st => st.id === story.id);
    if (index !== -1) {
      this.projectColumn.stories.splice(index, 1);
      this.isRemoved = true;
    }
  }

  trackStoryCreateSegmentEvent(story) {
    this.userService.trackSegmentEvent('Story created', this.projectService.getPropsOnStoryTaskCreation(this.project, story));
  }
  openColumnActionDD() {
    this.columnActionToggle = true;
  }
  onClick(event) {
    const element = event.target;
    if (!(element.classList.contains('columnActionIcon'))) {
      this.columnActionToggle = false;
    }
  }
  openEditColumnName() {
    if(this.policyService.isProjectReadonly(this.project)) {
      this.policyService.readOnlyWarning();
    } else {
      this.columnName = this.projectColumn && this.projectColumn.list_name ? this.projectColumn.list_name : '';
      this.editColumnName = true;
    }
  }
  canceleditColumn() {
    this.editColumnName = false;
  }

  renameColumnName() {
    if (this.columnName.trim() && this.columnName.length > 15) {
      this.customToast.messages.push({
        id: 'newprojectcreationFail',
        type: 'fail',
        class: 'generic_alert',
        title: 'Alert',
        message: 'Column name can not be more than 15 characters'
      });
    } else if(this.columnName.trim() && this.columnName != this.projectColumn.list_name) {
      this.disbaleRenameList = true;
      this.projectService.renameColumn(this.project.id, this.projectColumn.list_id, this.columnName.trim(), 'rename').subscribe((resp) => {
        const isColumn = this.sharedService.getColumnById(resp.list.id);
        this.canceleditColumn();
        this.disbaleRenameList = false;
        if(isColumn) {
          isColumn.list_name = resp.list.name;
            this.customToast.messages.push({
              id: 'story_new',
              type: 'success',
              class: 'story_new',
              title: 'Custom Column Renamed',
              message: resp.message
            });
        }
      },
    (error) => {
      this.disbaleRenameList = false;
      this.errorService.errorFunction(error);
    });
    }
  }
  deleteColumn() {
    if(this.policyService.isProjectReadonly(this.project)) {
      this.policyService.readOnlyWarning();
    } else {
      this.renderer.addClass(document.body, 'deletePopupView');
      this.OpencolumnNamePopup = true;
    }
  }
  closedeleteColumn() {
    this.OpencolumnNamePopup = false;
    this.renderer.removeClass(document.body, 'deletePopupView');
  }
  stateActionChanged() {
    this.waitForLoader = false;
    this.OpencolumnNamePopup = false;
    this.renderer.removeClass(document.body, 'deletePopupView');
    this.projectService.deleteColumn(this.project.id, this.projectColumn.list_id).subscribe((resp) => {
      const columnIndex = this.sharedService.findIndexOfColumn(resp.list.id);
      if(columnIndex >= 0) {
        PROJECT_COLUMN_DATA.splice(columnIndex, 1);
        let index = this.sharedService.visibleIds.indexOf(resp.list.id);
        if (index >= 0) {
          this.sharedService.visibleIds.splice(index, 1);
        }
      }
      this.closedeleteColumn();
      this.customToast.messages.push({
          id: 'bulk_delete',
          type: 'success',
          class: 'bulk_delete',
          title: 'Custom Column',
          message: resp.message
      });
    })
  }
}
