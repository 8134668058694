<div class="commonDashboard-wrapper" *ngIf="accountForm">
  <div class="account-title">My Account
    <a routerLink="/account" class="back-to-view-page">Back to view <em class="iconb-close"></em></a>
  </div>
  <form class="form-horizontal" #f="ngForm" (ngSubmit)="onSubmit()" autocomplete="off">
      <div class="genralBlock">
        <div class="genralBlock-title">General Information</div>
        <div class="small-block" [class.has-error]="hasError('name')">
          <label for="accountName" class="account-label">
            Full name *
          </label>
          <input id="accountName"
                   type="text"
                   class="account-field"
                   placeholder="Full name"
                   name="name"
                   #name="ngModel"
                   required
                   [(ngModel)]="accountForm.name">
        <div *ngIf="name && name.invalid && (name.touched || name.dirty)" class="error-block">
            <div *ngIf="name.errors.required" class="new-error-meg">Full Name is required</div>
        </div>
        <div class="error-block">{{ errors?.name }}</div>
        </div>
        <div class="small-block" [class.has-error]="hasError('initials')">
          <label for="accountInitials" class="account-label">
            Initials *
          </label>
          <input id="accountInitials"
                   type="text"
                   class="account-field"
                   placeholder="Initials"
                   required
                   #initials="ngModel"
                   name="initials"
                   [(ngModel)]="accountForm.initials">
            <div *ngIf="initials && initials.invalid && (initials.touched || initials.dirty)" class="error-block">
              <div *ngIf="initials.errors.required" class="new-error-meg">Initials is required</div>
            </div>
          <div class="error-block">
            {{ errors?.initials }}
          </div>
        </div>
        <div class="small-block" [class.has-error]="hasError('email')">
          <label for="accountEmail" class="account-label">
            Email
          </label>
          <input id="accountEmail"
                   type="text"
                   class="account-field"
                   placeholder="Email"
                   name="email"
                   disabled
                   [(ngModel)]="accountForm.email">
          <div class="error-block">
            {{ errors?.email }}
          </div>
        </div>
        <div class="small-block" [ngClass]="{'show-password': !showLoginPassword}" [class.has-error]="hasError('password')">
          <label for="accountPassword" class="account-label">
            Password
          </label>
          <input id="accountPassword"
                type="{{showLoginPassword ? 'text':'password'}}"
                   class="account-field padRight"
                   placeholder="Password"
                   name="password"
                   [(ngModel)]="accountForm.password"
                   (keyup)="onPasswordChange($event)">
          <span class="forEyeOption" (click) = "showPassword($event)"></span>
          <div class="error-block">
            {{ errors?.password }}
          </div>
        </div>
        <div class="small-block" [class.has-error]="hasError('password_confirmation')">
          <label for="accountPasswordConfirmation" class="account-label">
            Repeat Password
          </label>
          <input id="accountPasswordConfirmation"
                   type="password"
                   class="account-field"
                   placeholder="Repeat Password"
                   name="password_confirmation"
                   [(ngModel)]="accountForm.password_confirmation"
                   (keyup)="onPasswordChange($event)">
          <div class="error-block">
            {{ errors?.password_confirmation }}
          </div>
        </div>
        <!-- <div class="small-block" [class.has-error]="hasError('password_confirmation')">
          <label for="accountUserName" class="account-label">Username</label>
          <input id="accountUserName"
                 type="text"
                 class="account-field"
                 placeholder="username"
                 name="username"
                 [(ngModel)]="accountForm.username">
          <p class="error-block">
            {{ errors?.username }}
          </p>
        </div> -->
        <div class="small-block" [class.has-error]="hasError('password_confirmation')">
          <label class="account-label">Time Zone</label>
          <!-- <input type="text"
                 class="account-field"
                 placeholder="(GMT-08:00) Alaska"
                 name="timezone"> -->
                 <select id="timezone"
                 class="account-field"
                 name="time_zone"
                 [(ngModel)]="accountForm.time_zone"
                  style="height: 42px;">
                 <option *ngFor="let timeZone of offsetTmz" [ngValue]="timeZone.id">{{timeZone?.value}}</option>
         </select>
         <div class="error-block">
          {{ errors?.time_zone }}
        </div>
        </div>
      </div>
      <div class="genralBlock2">
        <div class="genralBlock-title">Notifications</div>
        <div class="genralBlock-subtitle">Notify when my stories are</div>
        <div class="genralNotification-block">
          <div class="statusBlock">
            <input type="checkbox" name="notify_on_delivered" class="customCheck" id="notifyondeliver" [(ngModel)]="accountForm.notify_on_delivered">
            <label for="notifyondeliver" class="statusIcon">Delivered</label>
          </div>
          <div class="statusBlock">
            <input type="checkbox" name="notify_on_accepted" class="customCheck" id="notifyonaccepted" [(ngModel)]="accountForm.notify_on_accepted">
            <label for="notifyonaccepted" class="statusIcon">Accepted</label>
          </div>
          <div class="statusBlock">
            <input type="checkbox" name="notify_on_rejected" class="customCheck" id="notifyonrejected" [(ngModel)]="accountForm.notify_on_rejected">
            <label for="notifyonrejected" class="statusIcon">Rejected</label>
          </div>
        </div>
      </div>

      <div class="genralBlock">
        <div class="genralBlock-title">Access Token</div>
        <div class="large-block">
          <div class="account-field2">
            <span class="tokenField">{{ accountForm.access_token }}</span>
            <span class="tokenField-action popover-activate"  placement="bottom" [clip]="account.access_token">
            <em class="iconb-task"></em>
            <span class="tokenField-actionText">Copy</span>
          </span>
          </div>
        </div>
      </div>
      <div class="actionBt-wrapper">
        <button *ngIf="!isPristine"
                type="revert"
                class="actionButtonRevert"
                (click)="revert()">
          Revert
        </button>
        <button type="submit"
                class="actionButton"
                [disabled]="isPristine || isPending || (!f.valid)">
          <em class="fa fa-spinner fa-spin" [class.hide]="!isPending"></em>
          <span>Update</span>
        </button>
      </div>

    </form>

</div>
