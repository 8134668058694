<div class="filterWrapper">
    <div class="resetFilter-block">
      <div class="filterCounter">{{storyApprovalService.totalfiltersApplied()}} Filter selected</div>
      <button type="button" class="resetFilter-button" [disabled]="storyApprovalService.totalfiltersApplied() === 0" (click)="clearAllFilters($event, 'clear_all')">Clear all</button>
    </div>
    <div class="filterOptions-type">
      <div class="filterOptions-typeListing" [ngClass]="{'selected': showSelectedFilterType === list.filter_key}" *ngFor="let list of filterList" (click)="showFilterOptions(list.filter_key)">
        <span class="typeList-name">{{list.filter_name}}</span>
        <span class="typeList-counter">{{storyApprovalService.filtersApplied(list.filter_key)}}</span>
      </div>
    </div>

    <!-------------this is for Feature name block starts--------------->
    <div class="filterOptions-typeBock" *ngIf = "showSelectedFilterType === 'epics'">
      <span class="filterSearchWrapper">
        <input type="text"  class="filterSearch" placeholder="Search Feature" [(ngModel)]="searchFeature" (keyup)="filterData(showSelectedFilterType)">
        <button type="button" class="filterSearchButton"><em class="iconb-search"></em></button>
      </span>
      <div class="noMemberFound-message" *ngIf="!featureList?.length && !filter_loader">
        <span class="notFoundText">No Results.</span>
      </div>
      <div class="approvalfilter-Loader" *ngIf="filter_loader">
        <img src="../../assets/images/storyBoardLoader.gif" alt=""/>
      </div>
      <div class="typeBock-listing" *ngFor="let feature of featureList">
        <span class="typeBock-check">
          <label class="customCheckForStorySelection">
            <input type="checkbox" class="story-selected-checkbox" [disabled]="" (change)="filterOption($event ,feature ,'epics')" [checked]="feature.isSelected" hidden/>
            <span class="check-toggle"></span>
          </label>
        </span>
        <span class="typeBock-name">{{feature.title | titlecase}}</span>
      </div>
    </div>
    <!-------------this is for Feature name block Ends--------------->

    <!-------------this is for Requested By block starts--------------->
    <div class="filterOptions-typeBock" *ngIf = "showSelectedFilterType === 'requestors'">
      <span class="filterSearchWrapper">
        <input type="text"  class="filterSearch" placeholder="Search Member" [(ngModel)]="searchRequestor" (keyup)="filterData(showSelectedFilterType)">
        <button type="button" class="filterSearchButton"><em class="iconb-search"></em></button>
      </span>
      <div class="noMemberFound-message" *ngIf="!requestorList?.length && !filter_loader">
         <span class="notFoundText">No Results.</span>
      </div>
      <div class="approvalfilter-Loader" *ngIf="filter_loader">
        <img src="../../assets/images/storyBoardLoader.gif" alt=""/>
      </div>
      <div class="typeBock-listing" *ngFor="let requestor of requestorList">
        <span class="typeBock-check">
          <label class="customCheckForStorySelection">
            <input type="checkbox" class="story-selected-checkbox" [disabled]="" (change)="filterOption($event ,requestor ,'requestors')" [checked]="requestor.isSelected" hidden/>
            <span class="check-toggle"></span>
          </label>
        </span>
        <span class="typeBock-name">{{requestor.name | titlecase}}</span>
      </div>
    </div>
    <!-------------this is for Requested By block Ends--------------->

    <!-------------this is for Reviewed By block starts--------------->
    <div class="filterOptions-typeBock" *ngIf = "showSelectedFilterType === 'reviewers'">
      <span class="filterSearchWrapper">
        <input type="text"  class="filterSearch" placeholder="Search Member" [(ngModel)]="searchReviewer" (keyup)="filterData(showSelectedFilterType)">
        <button type="button" class="filterSearchButton"><em class="iconb-search"></em></button>
      </span>
      <div class="noMemberFound-message" *ngIf="!reviewerList?.length && !filter_loader">
        <span class="notFoundText">No Results.</span>
      </div>
      <div class="approvalfilter-Loader" *ngIf="filter_loader">
        <img src="../../assets/images/storyBoardLoader.gif" alt=""/>
      </div>
      <div class="typeBock-listing" *ngFor="let reviewer of reviewerList">
        <span class="typeBock-check">
          <label class="customCheckForStorySelection">
            <input type="checkbox" class="story-selected-checkbox" [disabled]="" (change)="filterOption($event ,reviewer ,'reviewers')" [checked]="reviewer.isSelected" hidden/>
            <span class="check-toggle"></span>
          </label>
        </span>
        <span class="typeBock-name">{{reviewer.name | titlecase}}</span>
      </div>
    </div>
    <!-------------this is for Reviewed By block Ends--------------->

      <!-------------this is for Requested On block starts--------------->
    <div class="filterOptions-typeBock" *ngIf = "showSelectedFilterType === 'requested_on'">
      <div class="typeBock-listing">
        <div class="selectDate-wrapper filterCal">
          <span class="status-label">Select Date</span>
          <input type="text"
                ngxDaterangepickerMd
                class="dateRagne-input" 
                [autoApply]="false"
                [(ngModel)]="requested_on_date"
                [alwaysShowCalendars]="true"
                [ranges]="ranges"
                [showDropdowns]="true"
                (datesUpdated)="datesFilter($event,'requested_on')"
                [linkedCalendars]="false"
                placeholder="Select please..."/>
          <em class="iconb-calendar dateIcon"></em>
          <button type="button" *ngIf="requested_on_date && requested_on_date?.startDate" class="dateClearButton" (click)="clearDate('remove','requested_on')">
            <em class="iconb-close"></em>
          </button>
        </div>
      </div>
    </div>
    <!-------------this is for Requested On block Ends--------------->

    <!-------------this is for Reviewed on block starts--------------->
    <div class="filterOptions-typeBock" *ngIf = "showSelectedFilterType === 'reviewed_on'">
      <div class="typeBock-listing">
        <div class="selectDate-wrapper filterCal">
          <span class="status-label">Select Date</span>
          <input type="text"
                ngxDaterangepickerMd
                class="dateRagne-input" 
                [autoApply]="false"
                [(ngModel)]="reviewed_on_date"
                [alwaysShowCalendars]="true"
                [ranges]="ranges"
                [showDropdowns]="true"
                (datesUpdated)="datesFilter($event,'reviewed_on')"
                [linkedCalendars]="false"
                placeholder="Select please..."/>
          <em class="iconb-calendar dateIcon"></em>
          <button type="button" *ngIf="reviewed_on_date && reviewed_on_date?.startDate" class="dateClearButton" (click)="clearDate('remove','reviewed_on')">
            <em class="iconb-close"></em>
          </button>
        </div>
      </div>
    </div>
    <!-------------this is for Reviewed on block Ends--------------->

    <!-------------this is for "Status" block starts--------------->
    <div class="filterOptions-typeBock" *ngIf = "showSelectedFilterType === 'status'">
      <div class="typeBock-listing" *ngFor="let status_chceklist of statusList">
        <span class="typeBock-check">
          <label class="customCheckForStorySelection">
            <input type="checkbox" class="story-selected-checkbox"  (change)="filterOption($event, status_chceklist, 'status')" [checked]="status_chceklist.isSelected"  hidden/>
            <span class="check-toggle"></span>
          </label>
        </span>
        <span class="typeBock-name">{{status_chceklist.name}}</span>
      </div>
    </div>
    <!-------------this is for "Status" block Ends--------------->

    <!-------------this is for Request type block starts--------------->
    <!-- <div class="filterOptions-typeBock" *ngIf = "showSelectedFilterType === 'request_type'">
      <div class="typeBock-listing">
        <span class="typeBock-check">
          <label class="customCheckForStorySelection">
            <input type="checkbox" class="story-selected-checkbox" [disabled]="" (change)="applyFilter($event, 'request_type', 'request_for_review')" [checked]="" hidden/>
            <span class="check-toggle"></span>
          </label>
        </span>
        <span class="typeBock-name">Request for Review</span>
      </div>
    </div> -->
    <!-------------this is for Request type block Ends--------------->

  </div>