import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonEdit } from '../../common/classes';
import { AdminUsersService } from '../users.service';
import {CustomToastService} from '../../../shared/services/custom-toast.service';
import { UserService } from '../../../shared/services/user.service';
import {ErrorService} from '../../../shared/errorFunction';


@Component({
  selector: 'admin-users-new',
  templateUrl: 'new.component.html',
  styleUrls: [
    '../../admin.common.scss',
    'new.component.scss'
  ]
})
export class AdminUsersNewComponent extends CommonEdit implements OnInit {
  form: any = {role: '', email: ''};
  isPending: boolean = false;
  add: String = 'add';
  outputErr: any;

  constructor(private usersService: AdminUsersService,
              private customToast: CustomToastService,
              private sharedUserService: UserService,
              private errroService: ErrorService,
              private router: Router) {
    super();
  }

  ngOnInit() {
    // This is intentional
  }

  onSubmit(isAdd?) {
    if (!this.form.role) {
      this.form.role = 'user';
    }
    this.usersService.createUser(this.form, this.fullEmail).subscribe((member: any) => {
      this.customToast.messages.push({
        id: 'UserAdded', type: 'success',
        class: 'new_member_added',
        title: 'User Created',
        message: 'New user created successfully'
      });
      if (isAdd) {
        this.router.navigate(['/admin/users/new']);
      } else {
        this.router.navigate([`admin/users`]);
      }
      delete this.form.email;
      delete this.form.name;
      delete this.form.initials;
      this.form.role = 'user';
      this.form.time_zone = 'US/Pacific';
    }, (error) => {
      this.errroService.errorFunction(error);
      const err = JSON.parse(error._body);
      this.outputErr = err;
    });
  }

  get fullEmail() {
    return this.form.email + '@' + this.form.selected_user_domain;
  }

  checkEmail(email) {
    let re = /^(?=.{1,256})([a-zA-Z0-9][a-zA-Z0-9._%+-]*@[a-zA-Z0-9][a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$)/;
    return re.test(email);
  }
}
