import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { SharedModule } from './../shared/shared.module';
import { ProjectDashboardComponent } from './projectDashboard.component';
import { FormsModule } from '@angular/forms';
import { MemberModule } from '../member/member.module';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {AdminModule} from '../admin/admin.module';
import { ClipModule } from 'ng2-clip';


@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        SharedModule,
        NgSelectModule,
        MemberModule,
        FormsModule,
        InfiniteScrollModule,
        AdminModule,
        ClipModule
    ],
  declarations: [
    ProjectDashboardComponent
  ],
  exports: [
    ProjectDashboardComponent
  ]
})
export class ProjectDashboardModule { }
