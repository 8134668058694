<div class="projectRelease-wrapper">
  <div class="topInfo-section">
    <div class="topTitle">
      <div class="topLeft">
        <div class="maintitle">Releases</div>
      </div>
      <div class="topRight">
        <!-- <ul class="start-end-date">
          <li>Est. Start date : 05 May 2023</li>
          <li>Est. End date : 10 Aug 2023</li>
         </ul> -->
      </div>
    </div>
    <div class="bottomTitle">
      <div class="bottomLeft">
        <ul class="releaseTypeMenu">
          <li><a href="javascript:void(0)" [class.active]="environment === 'staging'" (click)="changeEnv('staging')">Staging<span class="no">{{releasesMeta?.total_staging_count}}</span></a></li>
          <li><a href="javascript:void(0)" [class.active]="environment === 'pre_prod'" (click)="changeEnv('pre_prod')">UAT<span class="no">{{releasesMeta?.total_pre_prod_count}}</span></a></li>
          <li><a href="javascript:void(0)" [class.active]="environment === 'production'" (click)="changeEnv('production')">Prod<span class="no">{{releasesMeta?.total_production_count}}</span></a></li>
        </ul>
      </div>
      <div class="bottomRight">
        <div class="fliterButton-wapper" >
          <div class="filterButton" [class.buttonSelected]="showfilterList" (click)="toggelefilter()">
            <em class="iconb-filter"></em>
            <span class="filterbutton-title release-filter"><strong style="font-weight: 500" >{{project ? project.filterCount : 0}}</strong> Filter</span>
          </div>
          <div class="filterBackdrop" *ngIf="showfilterList" (click)="toggelefilter()"></div>
          <div class="filterDD" [@slideOut] *ngIf="showfilterList" [ngClass]="[showfilterList ? 'filterDD-open':'']">
            <app-story-filter [project]="project" [currentUser]="currentUser"></app-story-filter>
          </div>
        </div>
        <div class="integrationSection-search">
          <input type="text" class="integrationSection-searchInput" name="searchRelease" (input)="searchRelease()" [(ngModel)]="search_release_text" placeholder="Search" />
          <button type="button" class="integrationSection-searchButton">
            <em class="iconb-search"></em>
          </button>
        </div>
        <ng-container *ngIf="bulk_selection.length > 0">
          <button type="button" class="addNewProject-release" *ngIf="policyService.projectAdmin(project) && environment === 'staging'" (click)="openMoveReleasePopup('pre_prod', 'deployed_to_pre_prod')">Move to UAT</button>
          <button type="button" class="addNewProject-release" *ngIf="policyService.projectAdmin(project) && environment === 'pre_prod'" (click)="openMoveReleasePopup('staging', 'deployed_to_staging')">Move to Staging</button>
          <button type="button" class="addNewProject-release" *ngIf="policyService.projectAdmin(project) && environment === 'pre_prod'" (click)="openMoveReleasePopup('production', 'deployed_to_production')">{{bulk_selection.length > 1 ? 'Merge & ' : ''}}Copy to Prod</button>
        </ng-container>
        
        <button type="button" class="addNewProject-release" *ngIf="policyService.projectAdmin(project)" (click)="versionAction()">+ Create Release</button>
      </div>
    </div>
  </div>
  <div class="releaseVersion-table releaseTable">
    <div class="releaseVersion-tableHead">
      <div class="tableHead-col1" style="width: calc(14% + 30px);">Release Name (version no.)</div>
      <div class="tableHead-col2">Status</div>
      <div class="tableHead-col3">Features</div>
      <div class="tableHead-col4">Est. Release date</div>
      <div class="tableHead-col5">Updated on</div>
      <div class="tableHead-col6">Release Notes</div>
      <div class="tableHead-col7">
        <div class="ratingHead">
          <em class="icon iconb-rating"></em>Rating
        </div>
      </div>
      <div class="tableHead-col8">Action</div>
    </div>
    <div 
    infiniteScroll 
    [scrollWindow]="false" 
    [infiniteScrollDisabled]="!releasePlannerService.isScrollFetchAvailable(releasesMeta)" 
    (scrolled)="loadReleases()" 
    class="releaseVersion-tableContent">
      <div class="bulkAction-Loader" *ngIf="releaseListLoader">
        <img src="../../assets/images/storyBoardLoader.gif" alt=""/>
      </div>
      <div class="empty-search-block" *ngIf="releases_list.length === 0 && !releaseListLoader">
        <em class="icon">
          <img src="../../assets/images/empty-search-icon.svg" alt="">
        </em>
        <p>No Release detail found</p>
      </div>
      <div class="releaseVersion-tableRow" *ngFor="let release of releases_list" (click)="gotoReleaseDetail($event, release)">
        <div class="tableHead-col21" *ngIf="environment !== 'production'">
          <div class="rowCheckBox">
            <label class="customCheckForRowSelection">
              <input type="checkbox" [disabled]="release.parent_id || release.stories_count === 0 || release.stories_count_by_status.accepted !== release.stories_count" [checked]="release.check_selection" (change)="storySelection($event, release)" class="story-selected-checkbox" hidden/>
              <span class="check-toggle"></span>
              <span class="elementHover-horizontal" *ngIf="release.parent_id">This Release is been copied/merged and moved to Production.</span>
              <span class="elementHover-horizontal" *ngIf="release.stories_count === 0 || release.stories_count_by_status.accepted !== release.stories_count">{{release.stories_count === 0 ? 'Please add story in release.' : 'All the stories must be accepted.'}}</span>
            </label>
          </div>
        </div>
        <div class="tableHead-col1" [ngClass]="{'isProdEnv': environment === 'production'}">
          <div class="release-name-wrapper">
            <div class="release-name">{{release.name ? release.name : 'Edit Release for Name'}}</div>
            <div class="version-no" *ngIf="release.version">{{release.version}}
              <span class="elementHover">Version</span>
            </div>
            <div class="release-phase" *ngIf="release.phase">Phase: {{release.phase | replaceUnderscore}}</div>
            <div class="story-progress">
              <div class="stories-status-values" *ngIf="release.stories_count === 0">No stories added</div>
              <ng-container *ngIf="release.stories_count > 0">
                <div class="progresscount">
                  <ng-container *ngFor="let state of release.stories_count_by_status | keyvalue">
                    <span *ngIf="state.value > 0"  class="status-type {{state.key}}" [ngStyle]="{'width': storyProgress(state.value, release?.stories_count) + '%'}"></span>
                  </ng-container>
                </div>
              </ng-container>
              <div class="story-breakup" *ngIf="release.stories_count > 0">
                <div class="story-breakup-title">{{release.stories_count}} {{release.stories_count > 1 ? 'Stories' : 'Story'}}</div>
                <ng-container *ngFor="let state of release.stories_count_by_status | keyvalue">
                  <div class="story-breakup-list" *ngIf="state.value > 0">
                    <span class="story-status-color {{state.key}}"></span>
                    <span class="story-status-name">{{state.key | titlecase}}</span>
                    <span class="story-count">{{state.value}}</span>
                  </div>
                </ng-container>
              </div>
            </div>
            <div class="stories-status-values" *ngIf="release.stories_count > 0">{{release.stories_count_by_status.accepted}}/{{release.stories_count}} Accepted</div>
          </div>
        </div>
        <div class="tableHead-col2">
          <div class="versionStatus">
            <div class="update-loader" *ngIf="release.status_loader"><img src="../../assets/images/dot-loader.gif" alt=""></div>
            <div class="selected-status" *ngIf="release.parent_id || !policyService.projectAdmin(project)">
              <span class="status-icon {{release.status}}"></span> 
              {{release.status | replaceUnderscore | titlecase}}
            </div>
            <div class="selected-status" *ngIf="!release.parent_id && policyService.projectAdmin(project)" (click)="release.statusDropdownVisible = true">
              <span class="status-icon {{release.status}}"></span> 
              {{release.status | replaceUnderscore | titlecase}}
              <em class="iconb-dd-down d-icon"></em>
            </div>
            <div class="status-list" [@fadeInOut] *ngIf="release.statusDropdownVisible" clickOutside (clickOutside)="toggleStatusDropdown($event, release)">
              <button type="button" class="status-list-item" *ngFor="let status of status_selection" [disabled]="!status.canChangeStatus" (click)="releasePlannerService.changeReleaseStatus(status, release, project)">
                <span class="status-icon {{status.iconColor}}"></span> {{status.name}}
              </button>
            </div>
          </div>
        </div>
        <div class="tableHead-col3">
          <div class="noOfFeatures">
            <ng-container *ngIf="release.features.length === 0"> - </ng-container>
            <span class="featureNames" *ngFor="let feature of release.features">{{feature}}</span>
            <span class="featureCounts" *ngIf="release.total_feature_count > 3" (click)="openFeaturelist(release)">+ {{release.total_feature_count - 3}} more</span>
          </div>
        </div>
        <div class="tableHead-col4">
          <div class="releaseDate">{{release.estimated_end_date ? release.estimated_end_date : '-'}}</div>
        </div>
        <div class="tableHead-col5">
          <div class="updatedOn">{{release.updated_at ? release.updated_at : '-'}}</div>
        </div>
        <div class="tableHead-col6">
          <div class="releaseDetails" *ngIf="!release.release_detail_text"><div class="notes" ><span class="readMore" (click)="showReleaseDetail(release)">Add Release notes</span> </div></div>
          <div class="releaseDetails" *ngIf="release.release_detail_text">
            <em class="iconb-check-circle"></em>
            <div class="notes">
              <span class="release_detail_text">{{release.release_detail_text}}</span><span class="readMore" (click)="showReleaseDetail(release)">View Release Note</span> 
            </div>
          </div>
        </div>
        <div class="tableHead-col7">
          <div class="ratingBlock">
            <div class="rating">{{release.rating ? release.rating : '-'}}</div>
            <span class="feedback-link" *ngIf="release.rating">View Feedback</span>
          </div>
        </div>
        <div class="tableHead-col8">
          <div class="actionWrapper">
            <em class="iconb-more-vert actionTrigger" [ngClass]="{'active': release.show_release_actions}" 
              (click)="release.show_release_actions = true"></em>
            <div class="actionDropdown" [@fadeInOut] *ngIf="release.show_release_actions" clickOutside (clickOutside)="openActionMenu($event, release)">
              <div class="actionLink" *ngIf="policyService.projectAdmin(project) && environment === 'staging'" (click)="showAddStoryPopup(release, 'add_story')"><em class="iconb-plus action_icon"></em>Add Story</div>
              <div class="actionLink" *ngIf="environment === 'staging' && release.stories_count > 0 && policyService.projectAdmin(project)" (click)="showAddStoryPopup(release, 'remove_story')"><em class="iconb-minus action_icon"></em>Remove Story</div>
              <div class="actionLink" *ngIf="!release.parent_id && policyService.projectAdmin(project)" (click)="versionAction(release)"><em class="iconb-edit action_icon"></em>Edit Release</div>
              <div class="actionLink" *ngIf="release.environment === 'staging' && policyService.projectAdmin(project)"  (click)="openDeleteReleaseConfirmation(release)"><em class="iconb-bin action_icon"></em>Remove Release</div>
              <div class="actionLink" (click)="showActivityPopup(release, 'activity')"><em class="iconb-activity action_icon"></em>View Activity</div>
              <div class="actionLink" (click)="showActivityPopup(release, 'comment')"><em class="iconb-chat-bubble action_icon"></em>Add Comments</div>
              <div class="actionLink" *ngIf="!release.parent_id && release.stories_count > 0 && release.environment!== 'production'">
                <em class="iconb-move-left"></em>Move to <em class="iconb-caret-left submenu-icon"></em>
                <div class="submenu-dropdown">
                  <div class="submenu-link" *ngIf="environment !== 'staging'" (click)="SingleReleaseMove(release, 'staging')">Staging</div>
                  <div class="submenu-link" *ngIf="environment === 'staging'" (click)="SingleReleaseMove(release, 'pre_prod')">UAT</div>
                  <div class="submenu-link" *ngIf="environment === 'pre_prod'" (click)="SingleReleaseMove(release, 'prod')">Copy to Prod</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-add-remove-story *ngIf="openAddStoriesPopUp" [action_on_story]="action_on_story" [project]="project" [selected_release]="selected_release" (closeAddStoryModal)="closeAddStoryPopup($event)"></app-add-remove-story>
  <app-create-edit-release *ngIf="openAddVersion"  [project]="project" [selected_release]="selected_release" (closeReleasePopup)="openAddVersion = false"></app-create-edit-release>
  <app-release-note *ngIf="openReleaseDetailPopUp" [project]="project" [release]="selected_release" [release_detail_for]="release_detail_for"  (closeReleaseDetailPopup)="closeReleaseDetail($event)"></app-release-note>
  <app-comment-activity *ngIf="viewActivityPopUp" [project]="project" [selected_release]="selected_release" (closeActivityPopup)="closeCommentActivityPopup($event)"></app-comment-activity>
  <app-feature-list *ngIf="openFeatureListPopUp" [project]="project" [selected_release]="selected_release" (closeFeaturelistPopup)="closeFeaturelist($event)"></app-feature-list>
</div>

<app-custom-popup  [texts] = "{ptitle: 'Remove', ptext: 'Are you sure you want to remove?', psuccess: 'Delete', pcancel: 'Cancel'}" (closeCustomPopUp) = "closeDeleteReleasePopUp()" (deleteElement) = "deleteRelease($event)" [delement] = "" [OpenPopUp] = "close_delete_release_popup" [waitForLoader] = "waitForLoader"></app-custom-popup>


<div *ngIf="show_move_release_Popup" class="commonPopUp">
  <div class="commonPopUp-backdrop" (click)="closeMoveReleasePopup()"></div>
  <div class="commonPopUp-wrapper">
    <!-- <div class="common-Loader commonPopup-Loader" *ngIf="waitForLoader">
      <img src="../../assets/images/storyBoardLoader.gif" alt="loader"/>
    </div> -->
    <div class="commonPopUp-title">{{moveable_env === 'production' ? 'Merge' : "Move"}} Release</div>
    <div class="commonPopUp-text"> 
      <ng-container *ngIf="release_cannot_move.length > 0">
        You have selected {{bulk_selection.length}} releases. 
        {{bulk_selection.length}} out of {{release_cannot_move.length}} releases cannot be {{moveable_env === 'production' ? 'merged' : "move"}}  due to absense of required status.
      </ng-container>
      <ng-container *ngIf="release_cannot_move.length === 0">Are you sure you want to {{moveable_env === 'production' ? 'merge' : "move"}}  the selected releases?</ng-container>
    </div>
    <ng-container *ngIf="release_cannot_move.length > 0">
      <div class="list-title">Releases which cannot be {{moveable_env === 'production' ? 'merged' : "move"}} :</div>
      <div class="selected-release-list-wrapper">
        <div class="selected-release-list" *ngFor = "let ummoveable_release of release_cannot_move">
          <div class="selected-release-version">{{ummoveable_release.version}}</div>
          <div class="selected-release-name">{{ummoveable_release.name}}</div>
        </div>
      </div>
    </ng-container>
    <div class="commonPopUp-actionBtn" style="margin-top: 10px;">
      <button class="commonPopUp-submit-s" [disabled]="release_cannot_move.length === bulk_selection.length" (click)="bulkReleaseMove(moveable_env, moveable_status)">Yes</button>
      <button class="commonPopUp-cancel-s" (click)="closeMoveReleasePopup()">Cancel</button>
    </div>
  </div>
</div>