import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccountViewComponent } from './pages/view/view.component';
import { AccountEditComponent } from './pages/edit/edit.component';

const routes: Routes = [
  {
    path: 'account',
    component: AccountViewComponent
  },
  {
    path: 'account/edit',
    component: AccountEditComponent
  }
];

@NgModule({
  exports: [ RouterModule ],
  imports: [ RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }) ]
})
export class AccountRoutingModule {}
