import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
// import { TextMaskModule } from 'angular2-text-mask';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import { SharedModule } from './../../shared/shared.module';

import { TimingComponent } from './timing.component';
import { TimingSidebarComponent } from './components/sidebar/sidebar.component';
import { TimingUserDetailProjectComponent } from './components/user-detail-project/user-detail-project.component';
import { TimingUserDetailSessionDialogComponent } from './components/user-detail-session-dialog/user-detail-session-dialog.component';
import { TimingDateRangeSwitcherComponent } from './components/date-range-switcher/date-range-switcher.component';
import { TimingWeeksSwitcherComponent } from './components/weeks-switcher/weeks-switcher.component';
import { TimingPaginationComponent } from './components/pagination/pagination.component';

import { TimingUsersService } from './services/users.service';
import { TimingProjectsService } from './services/projects.service';
import { TimingProjectsDetailsService } from './services/projects_details.service';
import { TimingSessionsService } from './services/sessions.service';

import { TimingDashboardComponent } from './pages/dashboard/dashboard.component';
import { TimingReportComponent } from './pages/report/report.component';
import { TimingProjectsComponent } from './pages/projects/projects.component';
import { TimingUsersComponent } from './pages/users/users.component';
import { TimingUserDetailComponent } from './pages/user-detail/user-detail.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    // TextMaskModule,
    RouterModule,
    AngularMyDatePickerModule,
    SharedModule,
  ],
  declarations: [
    TimingComponent,
    TimingDashboardComponent,
    TimingReportComponent,
    TimingProjectsComponent,
    TimingSidebarComponent,
    TimingUsersComponent,
    TimingUserDetailComponent,
    TimingUserDetailProjectComponent,
    TimingUserDetailSessionDialogComponent,
    TimingWeeksSwitcherComponent,
    TimingDateRangeSwitcherComponent,
    TimingPaginationComponent
  ],
  providers: [
    TimingUsersService,
    TimingProjectsService,
    TimingProjectsDetailsService,
    TimingSessionsService
  ]
})
export class AdminTimingModule { }
