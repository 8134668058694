<form  #adminUserForm="ngForm" class="form-horizontal" novalidate autocomplete="off">
  <div class="inputBlock">
    <label for="userFullName" class="forAddMember">Full Name</label>
    <input id="userFullName"
                type="text"
                class="addMember-input"
                placeholder="Full Name"
                name="name"
                #name="ngModel"
                (input) = "removeError('name')"
                [(ngModel)]="model.name">
                <div *ngIf="outputErr && outputErr.name" class="validation-color">{{outputErr.name[0]}}</div>
  </div>

  <div class="inputBlock" [class.has-error]="hasError('email')">
    <label for="userEmail" class="forAddMember">Email *</label>
      <input id="userEmail"
             type="text"
             #email = "ngModel"
             (input) = "removeError('email')"
             class="addMember-input typeEmail"
             placeholder="Email"
             name="email"
             [(ngModel)]="model.email"
             [ngClass]="{ 'is-invalid': adminUserForm.submitted && email.invalid }" required>
    <!--<span class="domainName">@{{userService.partner?.allowed_user_domain}}</span>-->
    <div class="domainDD">
      <div class="selectedDomain" (click)="openDomainSelection()">@{{model.selected_user_domain}}<em class="iconb-dd-down"></em> </div>
      <div class="selectDomain" [ngClass]="{'openSelectDomain': selectDomainDD}">
        <span class="domainList" *ngFor="let user_domain of userService.partner?.allowed_user_domain" (click)="closeDomainSelection(user_domain)">@{{user_domain}}</span>
      </div>
    </div>
    <div *ngIf="email && email.invalid && (email.touched || email.dirty)" class="invalid-feedback">
      <div *ngIf="email && email.value == '' && email.errors.required && (email.touched || email.dirty)" class="validation-color">Email is required</div>
    </div>
    <div *ngIf="email && checkEmail(model.email) && (email.touched || email.dirty)" class="validation-color">Email must be a valid email address</div>
    <div *ngIf="outputErr && outputErr.email" class="validation-color">{{outputErr.email[0]}}</div>
  </div>

  <div class="inputBlock">
    <label for="userInitials" class="forAddMember">Initials</label>
    <input id="userInitials"
             type="text"
             class="addMember-input"
             placeholder="Initials"
             (input) = "removeError('Initials')"
             name="initials"
             #initials="ngModel"
             [(ngModel)]="model.initials">
             <div *ngIf="outputErr && outputErr.initials" class="validation-color">{{outputErr.initials[0]}}</div>
  </div>
  <div class="inputBlock" [class.has-error]="hasError('role')">
    <label for="userRole" class="forAddMember">Role</label>
    <select id="userRole"
              class="addMember-input"
              name="role"
              #role = "ngModel"
              [(ngModel)]="model.role"
              (change) = "removeError('role')"
              required>
        <option *ngFor="let role of roleData"
                [ngValue]="role.id"
                >
          {{role?.value}}
        </option>
      </select>
    <div *ngIf="outputErr && outputErr.role" class="validation-color">{{outputErr.role[0]}}</div>
  </div>

  <div class="inputBlock">
      <label for="timezone" class="forAddMember">Time Zone</label>
      <select id="timezone"
                class="addMember-input"
                name="time_zone"
                (change) = "removeError('time_zone')"
                [(ngModel)]="model.time_zone">
                <option *ngFor="let timeZone of offsetTmz" [ngValue]="timeZone.id">{{timeZone?.value}}</option>
        </select>
        <div *ngIf="outputErr && outputErr.time_zone" class="validation-color">{{outputErr.time_zone[0]}}</div>
    </div>
  <ng-content></ng-content>
</form>
