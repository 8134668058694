<div class="release-commonPopUp">
    <div class="release-commonPopUp-backdrop" (click)="closeAddStoryPopup()"></div>
    <div class="close-release-planner-popup" (click)="closeAddStoryPopup()">
      <em class="iconb-close"></em>
    </div>
    <div class="release-commonPopUp-wrapper forAddStory">
      <div class="release-commonPopUp-title-block">
        <div class="title">{{selected_release?.name}}</div>
      </div>
      <div class="commonPopUp-storyListWrapper">
        <div class="addStory-search" [ngClass]="{'in-release-version' : initiate_from === 'from_release_detail'}">
          <div class="search-input">
            <input type="text" class="addStory-searchInput" name="searchStories" (input)="searchStories()" [(ngModel)]="searchStoryData" placeholder="Search" />
            <button type="button" class="addStory-searchButton" (click)="searchStories()">
              <em class="iconb-search"></em>
            </button>
          </div>
          
          <div class="bulk-selection" *ngIf="bulk_selection">
            <div class="bulk-left">
              <div class="story-selected">
                <span class="count">{{storiesSelected.length}}</span>{{storiesSelected.length === 1 ? 'Story' : 'Stories'}} Selected
              </div>
              <div class="deselect">
                <span class="customCheckFor-moveSelection" (click)="resetallSelections()">
                  Deselect All
                </span>
              </div>
              <div class="infoHoverIcon" *ngIf="initiate_from === 'from_release_detail'">
                <em class="iconb-info-extra"></em> User is restricted to edit story while selection.
              </div>
            </div>
            <button class="story-addStory" [disabled]="add_remove_loader"  (click)="action_on_story === 'add_story' ? addStoriesInRelease() : removeStoriesFromRelease()">
              <em class="iconb-{{action_on_story === 'add_story' ? 'plus' : 'bin'}}"></em>{{action_on_story === 'add_story' ? 'Add' : 'Remove'}} {{storiesSelected.length === 1 ? 'Story' : 'Stories'}}
            </button>
          </div>
        </div>
        <div class="releaseVersion-table addStory-table" [ngClass]="{'projectRelease-detail' : initiate_from === 'from_release_detail'}">
          <div class="releaseVersion-tableHead">
            <div class="tableHead-col6">
              <label class="customCheckFor-moveSelection" *ngIf="selected_release?.environment === 'staging' && policyService.projectAdmin(project)">
                <input type="checkbox" class="move-selected-checkbox" (change)="addAllAvailableStories(stories, 'all_stories', $event)" [checked]="all_stories_selected" hidden />
                <span class="check-toggleMoveSelection"></span>
              </label>Status
            </div>
            <div class="tableHead-col7">P</div>
            <div class="tableHead-col8">T</div>
            <div class="tableHead-col9">ID</div>
            <div class="tableHead-col10">Summary</div>
            <div class="tableHead-col11">Owner</div>
          </div>
          <div 
          infiniteScroll 
          [scrollWindow]="false" 
          [infiniteScrollDisabled]="!releasePlannerService.isScrollFetchAvailable(storiesMeta)" 
          (scrolled)="loadStories()" 
          class="releaseVersion-tableContent">
            <div class="common-Loader commonPopup-Loader" *ngIf="add_remove_loader">
              <img src="../../assets/images/storyBoardLoader.gif" alt=""/>
            </div>
            <div class="bulkAction-Loader" *ngIf="storyListLoader">
              <img src="../../assets/images/storyBoardLoader.gif" alt=""/>
            </div>
            <div class="empty-search-block" (click)="addStoryTableData()" *ngIf="stories.length === 0 && !storyListLoader">
              <em class="icon">
                <img src="../../assets/images/empty-search-icon.svg" alt="">
              </em>
              <p>No Story found.</p>
            </div>
            <div class="releaseVersion-tableRow" *ngFor="let story of stories" (click)="toggleEditMode($event, story, project)">
              <div class="tableHead-col6">
                <div class="story-statusWrapper">
                  <label class="customCheckFor-moveSelection" *ngIf="selected_release?.environment === 'staging' && policyService.projectAdmin(project)">
                    <input type="checkbox" class="move-selected-checkbox" (change)="selectStories(story, 'bulk_stories', $event)" [checked]="story.selected && bulk_selection" hidden />
                    <span class="check-toggleMoveSelection"></span>
                  </label>
                  <span [ngClass]='getStoryStateClasses(story.state)'>
                    <span class="elementHover">{{story.state}}</span>
                  </span>
                </div>
              </div>
              <div class="tableHead-col7">
                <div class="story-priority">
                  <em [class]="'priority-icon ' + story.storyPriorityType_icon(story.priority)"></em>
                  <span class="elementHover">{{story?.priority}}</span>
                </div>
              </div>
              <div class="tableHead-col8">
                <div class="story-type"><em [ngClass]="story.storyTypeIcon_minCard(story.story_type)"></em>
                  <span class="elementHover">{{(story.parent_id && story.story_type === 'task') ? 'Sub Task' : story.storyTypeName()}}</span>
                </div>
              </div>
              <div class="tableHead-col9">
                <div class="story-id">{{story.id}}</div>
              </div>
              <div class="tableHead-col10">
                <div class="story-summary"><span>{{story.title}}</span></div>
              </div>
              <div class="tableHead-col11">
                <div class="name-btn-wrapper">
                  <div class="nameBlock">
                    <div class="userInitials" *ngIf="story.owned_by_id">{{(story.owned_by_initials ? story.owned_by_initials : story.owned_by_name) | slice:0:1}}</div>
                    <div class="nameWithInitials">{{story.owned_by_id ? story.owned_by_name : '-'}}</div>
                  </div>
                  <button type="button" *ngIf="selected_release?.environment === 'staging' && !bulk_selection && policyService.projectAdmin(project)" class="story-addStory" (click)="story.release_id ? selectStories(story, 'single_story', $event, 'remove') : selectStories(story, 'single_story', $event, 'add')">
                    <em class="iconb-{{story.release_id ? 'minus':'plus'}}"></em>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

<div id="storyDIV" class="revStoryDes-popup"  [hidden] ="!projectService.show_storyDetails && !projectService.start_storyDetailLoader">
  <div class="revStoryDes-backdrop" (click) = "projectService.hide_StoryDetails()"></div>
  <div class="revStoryDes-popupWrapper">
    <story-detail [hidden] ="!projectService.show_storyDetails && !projectService.start_storyDetailLoader">
    </story-detail>
  </div>
</div>