import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { SharedModule } from './../shared/shared.module';
import { FormsModule } from '@angular/forms';
import { MemberModule } from '../member/member.module';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import { FeedbackloopDashboardComponent } from './feedbackloop-dashboard.component';
import { StoryApprovalService } from '../shared/services/story-approval.service';
import { ApprovallistFilterComponent } from './approvallist-filter/approvallist-filter.component';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { StoryCompareModule } from '../shared/storyCompare.module';
@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        SharedModule,
        NgSelectModule,
        MemberModule,
        FormsModule,
        InfiniteScrollModule,
        FormsModule,
        StoryCompareModule,
        AngularMyDatePickerModule,
        NgxDaterangepickerMd.forRoot({
          separator: ' To ',
          format: 'DD/MM/YYYY'
        })
    ],
  declarations: [
    FeedbackloopDashboardComponent,
    ApprovallistFilterComponent,
  ],
  exports: [
    FeedbackloopDashboardComponent,
    ApprovallistFilterComponent,
  ],
  providers: [
    StoryApprovalService
  ],
})
export class FeedbackloopModule { }