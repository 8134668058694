import { GUID } from './guid';
import { BaseModel } from './baseModel';
import { ReviewStoryEpicsData } from '../dataTypes';
export class StoryReviewEpic extends BaseModel implements ReviewStoryEpicsData {
  id?: number;
  title?: string;
  isSelected?: boolean;

  constructor(data: ReviewStoryEpicsData) {
    super(data);
    this.id = data.id;
    this.title = data.title;
    this.isSelected = false;
  }

  getClassName() {
    return "StoryReviewEpic";
  }
} 