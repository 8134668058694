<div class="projectRelease-wrapper">
  <div class="storyBoardPanel-Loader" *ngIf="startLoader">
    <img src="../../assets/images/storyBoardLoader.gif" alt="loader"/>
  </div>
  <div class="topInfo-section" *ngIf="release">
    <div class="topTitle">
      <div class="left">
        <div class="maintitle">
          {{release.name}} 
          <span class="version-no">{{release.version}} </span> 
          <div class="action-on-release" *ngIf="!release.parent_id && policyService.projectAdmin(project)">
            <span class="iconb-edit" (click)="releaseEdit()">
              <span class="elementHover">Edit Release</span>
            </span>
            <span *ngIf="release.environment === 'staging'" class="iconb-bin" (click)="close_delete_release_popup = true">
              <span class="elementHover">Delete Release</span>
            </span>
          </div>
        </div>
        <div class="releaseVersion-filter">
          <div class="versionStatus">
            <div class="update-loader" *ngIf="release.status_loader"><img src="../../assets/images/dot-loader.gif" alt=""></div>
            <div class="selected-status" *ngIf="release.parent_id || !policyService.projectAdmin(project)">
              <span class="status-icon {{release.status}}"></span> 
              {{release.status | replaceUnderscore | titlecase}}
            </div>
            <div class="selected-status" *ngIf="!release.parent_id && policyService.projectAdmin(project)" (click)="release.statusDropdownVisible = true">
              <span class="status-icon {{release.status}}"></span> 
              {{release.status | replaceUnderscore | titlecase}}
              <em class="iconb-dd-down d-icon"></em>
            </div>
            <div class="status-list" [@fadeInOut] *ngIf="release.statusDropdownVisible" clickOutside (clickOutside)="toggleStatusDropdown($event, release)">
              <button type="button" class="status-list-item" [disabled]="!status.canChangeStatus" *ngFor="let status of status_selection" (click)="releasePlannerService.changeReleaseStatus(status, release, project)">
                <span class="status-icon {{status.iconColor}}"></span> {{status.name}}
              </button>
            </div>
          </div>
          <span class="release-type">{{release.environment | replaceUnderscore | titlecase}}</span>
          <span class="selectedFilter" *ngIf="release.environment === 'production'">Shipped on : 05 Nov 2021</span>
          <span class="releaseDetail" (click)="showHideReleaseDetail()">Release Notes</span>
        </div>
      </div>
      <div class="right">
        <button type="button" *ngIf="sync_story_list" class="addNewProject-release" (click)="showAddStoryPopup(release, 'remove_story')">
          Sync Story
        </button>
        <button type="button" *ngIf="release.environment === 'staging' && policyService.projectAdmin(project)" class="addNewProject-release" (click)="action_on_story === 'add_story' ? showAddStoryPopup(release, 'remove_story') : showAddStoryPopup(release, 'add_story')">
          <em class="iconb-plus" *ngIf="action_on_story === 'remove_story'"></em>
          {{action_on_story === 'add_story' ? "Added Story List" : 'Add Story'}}
        </button>
        <button type="button" class="addNewProject-release" (click)="showActivityPopup(release, 'activity')">
          <em class="iconb-activity"></em> Comments & Activity
        </button>
      </div>
    </div>
    <div class="story-progress">
      <ng-container *ngIf="release.stories_count === 0">
        <span class="status-type" style="width: 100%; border: 1px solid #ccc; border-radius: 20px;background-color: #8fa5a5;"></span>
      </ng-container>
      <ng-container *ngFor="let state of release.stories_count_by_status | keyvalue">
        <span *ngIf="state.value > 0"  class="status-type {{state.key}}" [ngStyle]="{'width': storyProgress(state.value, release?.stories_count) + '%'}"></span>
      </ng-container>
    </div>

    <div class="storyStatus-wrapper">
      <div class="storyStatus-cell selected">
        <span class="story-count">{{release.stories_count}}</span>
        <span class="story-status-name">{{release.stories_count > 1 ? 'Stories' : 'Story'}}</span>
      </div>
      <ng-container *ngIf="release.stories_count > 0">
        <ng-container *ngFor="let state of release.stories_count_by_status | keyvalue">
          <div class="storyStatus-cell" *ngIf="state.value > 0">
            <span class="story-status-color {{state.key}}"></span>
            <span class="story-count">{{state.value}}</span>
            <span class="story-status-name">{{state.key | titlecase}}</span>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
  <div class="empty-search-block" style="margin-top: 50px;" *ngIf="release && release.stories_count === 0 && action_on_story === 'remove_story'">
    <em class="icon">
      <img src="../../assets/images/empty-search-icon.svg" alt="">
    </em>
    <p>No Story found.</p>
  </div>

  <app-add-remove-story class="changeLayout" *ngIf="project && release && action_on_story === 'remove_story' && release.stories_count !== 0" [action_on_story]="action_on_story" [project]="project" [selected_release]="release" (closeAddStoryModal)="closeAddStoryPopup($event)"></app-add-remove-story>
  <app-add-remove-story class="changeLayout" *ngIf="project && release && action_on_story === 'add_story'" [action_on_story]="action_on_story" [project]="project" [selected_release]="release" (closeAddStoryModal)="closeAddStoryPopup($event)"></app-add-remove-story>
  <app-custom-popup  [texts] = "{ptitle: 'Remove', ptext: 'Are you sure you want to archive the release?', psuccess: 'Delete', pcancel: 'Cancel'}" (closeCustomPopUp) = "closeDeleteReleasePopUp()" (deleteElement) = "deleteRelease($event)" [delement] = "" [OpenPopUp] = "close_delete_release_popup" [waitForLoader] = "waitForLoader"></app-custom-popup>
  <app-create-edit-release *ngIf="open_edit_release"  [project]="project" [selected_release]="release" (closeReleasePopup)="open_edit_release = false"></app-create-edit-release>
  <app-release-note *ngIf="openReleaseDetailPopUp" [project]="project" [release]="release" [release_detail_for]="release_detail_for" (closeReleaseDetailPopup)="showHideReleaseDetail()"></app-release-note>
  <app-comment-activity *ngIf="viewActivityPopUp" [project]="project" [selected_release]="release" (closeActivityPopup)="closeCommentActivityPopup($event)"></app-comment-activity>
</div>
