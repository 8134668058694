// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

export const environment = {
  production: true,
  show_sprint: true,
  developerLogoutTime: 4,
  apiBase: 'https://develop.api.tracker.engineer.ai',
  apiPath: '/api/v1/',
  websocketUrl: 'wss://staging.centrifugo.tracker.engineer.ai/connection/websocket',
  apiPMBase: 'https://api-staging-pmdashboard.engineer.ai/',
  secretKey: 'UheeeUKUKrwLzKZuKjux',
  enableSentry: true,
  maintenance: false,
  USER_COOKIE: 'tracker_staging_beta',
  designerUrl: 'https://uiw-staging.engineer.ai',
  apiHiveBase: 'https://api-staging-partner.engineer.ai/',
  hive_username: 'admin@engineer.ai',
  hive_password: '1;l7BuZB;Z!5W|62D9zWu|8N%2pAKy',
  INTERCOM_APP_ID : 'ob3az46u',
  white_board_base_url: 'https://www.whiteboard.team',
  whiteBoardClientId: 'de407599b5e9da86506556a7a958184d',
  whiteBoardClientSecret: '5b636e6f0ab506cbbb36707c45f8e9de',
  nowUrl: 'https://staging-now.engineer.ai',
  apiNowUIWBase: 'https://staging.api-now-uiw.builder.ai',
  uiw_now_token: 'irLgelJClLXrAQfqnWgY',
  cmtAPIBase: 'https://cmt-staging.builder.ai/api/v2/',
  cmtAPIKey: 'tracker_staging',
  cmtSecretKey: 'bf0342c0-46eb-4e05-a158-8ed0b4cb325c',
  whiteboard_url:'https://staging-whiteboard.builder.ai',
  uiwAPIBase: 'https://staging.api-tracker-uiw.builder.ai/'
};
