import { Component, Inject, OnInit } from '@angular/core';
import { ISessionDialogOptions } from '../../interfaces/session-dialog-options.interface';
import { TimingSessionsService } from '../../services/sessions.service';
import { User } from '../../models/user';
import { Project } from '../../models/project';
import { Story } from '../../models/story';
import { Session } from '../../models/session';

@Component({
  selector: 'app-admin-timing-user-detail-session-dialog',
  templateUrl: 'user-detail-session-dialog.component.html',
  styleUrls: [
    '../../../admin.common.scss',
    'user-detail-session-dialog.component.scss'
  ]
})
export class TimingUserDetailSessionDialogComponent implements OnInit {

  public options: ISessionDialogOptions;
  public user: User;
  public projects: Project[];
  public sessions: Session[];
  public selectedProject: Project;
  public selectedStory: Story;
  public mask = [/[0-9]/, /[0-9]/, ':', /[0-5]/, /[0-9]/, ':', /[0-5]/, /[0-9]/];
  public isPending = true;

  constructor(private sessionsService: TimingSessionsService,
              // private dialogRef: MatDialogRef<TimingUserDetailSessionDialogComponent>,
              // @Inject(MAT_DIALOG_DATA) private data
  ) {
    // this.options = data;
  }

  ngOnInit(): void {
    this.user = this.options.user;
    this.projects = this.options.projects;
    this.selectedProject = this.options.project;
    this.selectedStory = this.options.story;
    this.getSessions();
  }

  onChangeProject(): void {
    if (this.selectedStory !== this.selectedProject.stories[0]) {
      this.selectedStory = this.selectedProject.stories[0];
      this.getSessions();
    }
  }

  onChangeStory(): void {
    this.getSessions();
  }

  getSessions(): void {
    this.isPending = true;
    this.sessionsService
      .getSessions(this.user.id, this.selectedStory.id, this.options.date)
      .subscribe(sessions => {
        this.sessions = sessions;
        this.isPending = false;
      });
  }

  updateSessions(): void {
    this.isPending = true;
    const data = {};
    this.sessions.forEach(s => data[s.id.toString()] = s.elapsed);

    this.sessionsService
      .updateSessions(this.user.id, data)
      .subscribe(sessions => {
        this.sessions = sessions;
        this.isPending = false;
        if (!this.sessions.filter(x => x.status === 'broken').length) {
          this.closeDialog('updated');
        }
      });
   }

  closeDialog(result: string): void {
    // this.dialogRef.close(result);
  }
}
