import { Component, OnInit } from '@angular/core';
import { ProjectService } from '../../shared/services/project.service';
import { ErrorService } from '../../shared/errorFunction';
import { PolicyService } from '../../shared/services/policy.service';
import { CustomToastService } from '../../shared/services/custom-toast.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import { ProjectSyncService } from '../../shared/services/projectSync.service';
import { SprintService } from '../../shared/services/sprint.service';
import {Project} from "../../shared/models/project";
import {User} from "../../shared/models/user";
import {EVENT_TYPES, RELEASE_STATUS_LIST} from '../../shared/dataTypes';
import { ReleasePlannerService } from '../../shared/services/release-planner.service';
import { ReleasePlannerModal } from '../../shared/models/releasePlannerModal';
import moment from 'moment';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'app-release-version',
  templateUrl: './release-version.component.html',
  styleUrls: ['./release-version.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({
          opacity: '0',
          top: '40px'
        }),
        animate(200, style({
          opacity: '1',
          top: '20px'
        }))
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate(200, style({
          opacity: '0',
          top: '40px'
        }))
      ])
    ])
  ]
})
export class ReleaseVersionComponent implements OnInit {
  project: Project;
  projectId?: number;
  release_id?: number;
  release: ReleasePlannerModal;
  releases_list: Array<ReleasePlannerModal> = [];
  selected_release:ReleasePlannerModal;
  currentUser: User;
  viewActivityPopUp: boolean = false;
  openAddStoriesPopUp: boolean = false;
  addStoryTableDataVisible: boolean = false;
  statusDropdownVisible: boolean = false;
  startLoader: boolean = false;
  action_on_story:string = '';
  openReleaseDetailPopUp: boolean = false;
  open_edit_release: boolean = false;
  close_delete_release_popup: boolean = false;
  waitForLoader: boolean = false;
  status_selection: any;
  release_detail_for = '';
  sync_story_list:boolean = false;

  constructor(public projectService: ProjectService,
    private errorService: ErrorService,
    public policyService: PolicyService,
    public customToast: CustomToastService,
    private projectSyncService: ProjectSyncService,
    private activatedRoute: ActivatedRoute,
    private releasePlannerService: ReleasePlannerService,
    public sprintService: SprintService) { }

  ngOnInit() {
    this.startLoader = true;
    this.action_on_story = 'remove_story';
    this.sync_story_list = false;
    this.activatedRoute.params.subscribe((params: Params) => {
      this.projectId = +params['id'];
      this.release_id = +params['rid'];
    });
    this.releasePlannerService.loadProjectDetails('from_release_detail', this.projectId).subscribe(
      data => {
        this.project = data.project;
        this.currentUser = data.current_user;
        this.loadRelease();
        this.projectSyncService.subscribe(this);
      },
      err => {
        this.errorService.errorFunction(err)
      }
    );
  }

  ngOnDestroy() {
    this.projectService.initiate_from = '';
  }

  loadRelease() {
    this.releasePlannerService.getRelease(this.project.id, this.release_id).subscribe(res => {
      if(res) {
        this.release = new ReleasePlannerModal(res.data);
        this.startLoader = false;
        this.projectService.releaseId = this.release.id;
        this.status_selection = RELEASE_STATUS_LIST[this.release.environment];
      }
    }, (error) => {
      this.startLoader = false;
      this.errorService.errorFunction(error);
    });
  }

  storyProgress(no_stories, total_count) {
    const individual_p = (no_stories * (100/total_count));
    return individual_p;
  }

  showActivityPopup(release, type) {
    release.show_comment = type === 'comment';
    release.show_activity = type !== 'comment';
    this.viewActivityPopUp = true;
  }

  closeCommentActivityPopup() {
    this.viewActivityPopUp = false;
  }

  showAddStoryPopup(release, action_type) {
    this.sync_story_list = false;
    this.action_on_story = action_type;
    this.openAddStoriesPopUp = true;
    this.selected_release = release;
  }

  closeAddStoryPopup() {
    this.openAddStoriesPopUp = false;
  }

  showHideReleaseDetail() {
    this.openReleaseDetailPopUp = !this.openReleaseDetailPopUp;
    this.release_detail_for = this.release.release_detail_text === '' ? 'edit' : 'show';
  }

  releaseEdit() {
    this.open_edit_release = !this.open_edit_release;
  }

  closeDeleteReleasePopUp() {
    this.close_delete_release_popup = false
  }

  // for soft deleting release call api from service file
  deleteRelease(event) {
    this.release.deleted_at = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
    const deleted_at = this.release.deleted_at;
    this.releasePlannerService.removeRelease(this.project.id, this.release.id, deleted_at).subscribe(res => {
      this.customToast.messages.push({
        id: 'release_created',
        type: 'success',
        class: 'stories_moved',
        title: 'Release',
        message: 'Release removed successfully'
      });
      this.close_delete_release_popup = false;
    }, (error) => {
      this.errorService.errorFunction(error);
    });
  }

  toggleStatusDropdown(event, release) {
    release.statusDropdownVisible = !release.statusDropdownVisible;
  }
  
}
