<div class="navCollapseExpand" (click)="collapseNav()">
  <span *ngIf="!toggleNavigation" class="iconb-close-story">
    <span class="elementHover-extra">Collapse</span>
  </span>
  <span *ngIf="toggleNavigation" class="iconb-open-story">
    <span class="elementHover-extra">Expand</span>
  </span>
</div>
<div class="trackerNavigation-wrapper">
  <div class="trackerLogo" [routerLink]="['/']" (click)="projectService.closeProjectDD()">
    <img *ngIf="!toggleNavigation" src="assets/images/trackerLogo.png" alt="eTracker">
    <img *ngIf="toggleNavigation" class="small-logo" src="assets/images/trackerLogo-small.png" alt="eTracker">
  </div>
  <div class="navigationBlock">
    <div class="projectSelection-DD" *ngIf="isProjectContext()">
      <div class="projectSelection-selected" (click)="projectService.openProjectDD(); adjustZLayer()">
        <div class="projectIcon">
          <img src="../../assets/images/projectIcon-default.png" alt=""/>
        </div>
        <div class="projectSelected-info" *ngIf="!toggleNavigation">
          <span class="p-name">{{(projectName ? 'Buildcard' : 'Buildcards')}}</span>
          <span class="p-type">{{(projectName ? projectName : 'Select Buildcard')}}</span>
        </div>
        <em class="iconb-dd-down" [ngClass]="{'openedDD-icon': projectService.openProDD}" *ngIf="!toggleNavigation"></em>
        <span class="menuListHover">{{(projectName ? projectName : 'Select Buildcard')}}</span>
      </div>
      <div class="projectProgress-wrapper" *ngIf="isProjectExternal && isProgressAvailable"> 
        <div class="overall-progress">
          <span class="progress-line" [style.width.%]="projectProgress.project"></span>
        </div>
        <div class="progressText">
          <span class="progressText-color">
            Buildcard Progress 
          </span>
          <span class="progressCount-text">{{projectProgress.project}}%</span>
        </div>
        <div class="phaseProgressInfo-wrapper">
          <span class="w-Title">
            Buildcard progress% is a weighted average from all Buildcard phases. <br/> (
            <span *ngIf="projectProgress.phase_weightage?.pr">Roadmap: {{projectProgress.phase_weightage?.pr}}%, </span>
            <span *ngIf="projectProgress.phase_weightage?.design"> Design: {{projectProgress.phase_weightage?.design}}%, </span>
            <span *ngIf="projectProgress.phase_weightage?.fb"> MVP/Custom Phase/Full Build: {{projectProgress.phase_weightage?.fb}}%</span>
              )
          </span>
          <div class="phaseProgressInfo-list" *ngFor="let listProgress of projectProgress.phases">
            <div class="progressColor"></div>
            <div class="progressName">{{listProgress.name}}</div>
            <div class="progressCount">{{listProgress.progress}}%</div>
            <span class="progress-line2" [style.width.%]="listProgress.progress"></span>
          </div>
        </div>
      </div>
    </div>
    <project-navbar *ngIf = "(currentUrl && currentUrl.substring(0, 9) == '/projects') || (locationPath && locationPath.substring(0, 9) == '/projects')"></project-navbar>
    <div class="navigationBlock-links"  *ngIf="checkRoute()" [routerLink]="['/']" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
      <em class="iconb-projectfolder"></em>
      <span class="linkText" *ngIf="!toggleNavigation">Buildcards</span>
      <span class="menuListHover">Buildcards</span>
    </div>
    <div *ngIf="isProjectsShow && checkRoute()" [routerLink]="['/admin/users']" (click)="projectService.closeProjectDD()"  routerLinkActive="active" class="navigationBlock-links">
      <em class="iconb-members"></em>
      <span class="linkText" *ngIf="!toggleNavigation">Members</span>
      <span class="menuListHover">Members</span>
    </div>
    <div *ngIf="ifUserIsReviewer && checkRoute()" [routerLink]="['/story-approvals']" (click)="projectService.closeProjectDD()"  routerLinkActive="active" class="navigationBlock-links">
      <em class="iconb-activity"></em>
      <span class="linkText" *ngIf="!toggleNavigation">Story Approvals</span>
      <span class="menuListHover">Story Approvals</span>
      <!-- <span class="story-approval-count">4</span> -->
    </div>
    <admin-left-menu *ngIf = "(currentUrl && currentUrl.substring(0, 6)) == '/admin'"></admin-left-menu>
    <a [routerLink]="['/help-support']" (click)="projectService.closeProjectDD()"  routerLinkActive="active" class="navigationBlock-links forHelp">
      <em class="iconb-help"></em>
      <span class="linkText" *ngIf="!toggleNavigation">Help</span>
      <span class="menuListHover">Help</span>
    </a>
  </div>

  <div class="trackerUserInfo-wrapper" [ngClass]="{'makeSmall':toggleNavigation}" (click)="userOptionsToggle()">
      <div class="trackerUserInfo">
        <div class="trackerUserInfo-initials">
          {{currentUser?.initials.slice(0,1)}}
          <span class="menuListHover">{{currentUser?.name}}</span>
        </div>
        <div *ngIf="!toggleNavigation" class="trackerUserInfo-name">
          <span class="user-name">{{currentUser?.name}}</span>
          <span class="user-email">{{currentUser?.email}}</span>
        </div>
        <span *ngIf="!toggleNavigation" class="upDown-arrow">
          <em class="iconb-dd-down up"></em>
          <em class="iconb-dd-down"></em>
        </span>
      </div>

      <div class="trackerUserInfo-optionsDD" [ngClass]="{'openOptionDD': userOptionsDD}">
        <span class="optionsList" routerLink="account" (click)="projectService.closeProjectDD() && userOptionsClose()">
          <em class="iconb-changemember"></em> My Account
        </span>
        <span class="optionsList" *ngIf="isSignedIn" (click)="signOut($event); projectService.closeProjectDD()">
          <em class="iconb-logout"></em> Log out
        </span>
      </div>
    </div>
</div>

<div class="projectSelection-DD-backdrop" *ngIf="projectService.openProDD" (click)="projectService.closeProjectDD(); adjustZLayer()"></div>
<div class="c-dd-menu" [ngClass]="{'c-dd-menu-active': projectService.openProDD}">
  <div class="c-dd-top">
    <div  class="searchProject">
      <input type="text" (input) = "getSearchProjects()" [(ngModel)] = "searchText" class="searchInputBox" placeholder="Search Buildcard" />
    </div>
  </div>
  <div class="projectslinkWrapper"
       infiniteScroll
       [scrollWindow]="false"
       [infiniteScrollDisabled]="!isScrollFetchAvailable()"
       (scrolled)="getOlderProject()">
    <div class="projectslink" *ngFor="let project of projects">
      <a class="projectListing" [routerLink]="['/projects', project.id]" (click)="projectService.closeProjectDD(true)">
        {{project.name}}
        <!--<span class="makeFavorite" [ngClass]="{'iconb-star-outline': !project.favorite , 'iconb-star-fill': project.favorite}"></span>-->
      </a>
    </div>
    <div class="noSuchProject" *ngIf = "(projects).length == 0">
      <div class="noSuchProjectInner">
        <img src="../../assets/images/no_Project_icon.png" alt=""/>
        <p>No such Buildcard.</p>
      </div>
    </div>
    <div class="mainProjectList-Loader" *ngIf="startLoader && moreAvailable">
      <img src="../../assets/images/storyBoardLoader.gif" alt=""/>
    </div>
    <div class="no-notifications" *ngIf = "!moreAvailable">No more Buildcards.</div>
  </div>
</div>
