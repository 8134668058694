import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TimingComponent } from './timing.component';
import { TimingDashboardComponent } from './pages/dashboard/dashboard.component';
import { TimingReportComponent } from './pages/report/report.component';
import { TimingProjectsComponent } from './pages/projects/projects.component';
import { TimingUsersComponent } from './pages/users/users.component';
import { TimingUserDetailComponent } from './pages/user-detail/user-detail.component';

export const timingRoutes: Routes = [
  {
    path: '',
    component: TimingComponent,
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        component: TimingDashboardComponent
      },
      {
        path: 'report',
        component: TimingReportComponent
      },
      {
        path: 'projects',
        component: TimingProjectsComponent
      },
      {
        path: 'users',
        component: TimingUsersComponent,
      },
      {
        path: 'users/:id',
        component: TimingUserDetailComponent,
      }
    ]
  }
];

export const timingRouting = RouterModule.forChild(timingRoutes);
