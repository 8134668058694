<div class="loader" *ngIf="isPending">
  <span class="fa fa-spin fa-refresh"></span>
</div>

<div *ngIf="user" [class.pending]="isPending">
  <div class="row">
    <div class="col-sm-12">
      <h3>{{user.name}}</h3>
    </div>
  </div>

  <app-admin-timing-weeks-switcher
    [isPending]="isPending"
    [dateRange]="dateRange"
    (onDateRangeChanged)="onDateRangeChanged($event)">
  </app-admin-timing-weeks-switcher>

  <div class="row">
    <div class="col-sm-12">
      <hr>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12">
      <div class="table-wrapper table-timing">
        <table role="presentation">
          <thead>
            <tr>
              <td>Projests</td>
              <td *ngFor="let day of weekDays">{{day}}</td>
            </tr>
          </thead>
        </table>
      </div>
      <div class="report-project" *ngFor="let project of projects">
        <app-admin-timing-user-detail-project
          [project]="project"
          (onSessionDialogOpened)="onSessionDialogOpened($event)">
        </app-admin-timing-user-detail-project>
      </div>
    </div>
  </div>
</div >
