<div class="authMainContainer">

  <div class="rightSection">
    <div class="logo"><img src="../../../assets/images/trackerLogo2.png" alt="eTracker" class="head-logo"></div>
    <div class="sign-box-center">
      <ng-container *ngIf="State.Initial === state">
        <div class="auth-title2">Change password</div>
        <div class="notifyText2">Please enter your new password. Minimum length is 8 characters,
          password and confirmation must match.</div>
        <div class="authForm">
          <form (ngSubmit)="onSubmit()">
            <div class="authForm-block" [class.has-error]="hasError('password')">
              <span class="inputLabel">New Password</span>
              <input id="password"
                     type="{{showLoginPassword ? 'text':'password'}}"
                     class="authInput"
                     name="password"
                     placeholder="New Password"
                     [(ngModel)]="form.password">
              <span class="reveal-concealChangePass" [ngClass]="{'showText': showLoginPassword}" (click) = "showPassword($event)">
                <em class="iconb-view-tasks"></em>
              </span>
              <span class="wrong-password">{{ errors?.password }}</span>
            </div>
            <div class="authForm-block" [class.has-error]="hasError('password_confirmation')">
              <span class="inputLabel">Password Confirmation</span>
              <input id="password_confirmation"
                     type="password"
                     class="authInput"
                     name="password_confirmation"
                     placeholder="Password Confirmation"
                     [(ngModel)]="form.password_confirmation">
              <span class="wrong-password">{{ errors?.password_confirmation }}</span>
            </div>
            <div class="changePasswordButton-wrapper">
              <button type="submit" class="changePasswordButton" [disabled]="!canSubmit()">Change Password</button>
              <button type="cancel" class="changePasswordButton backToLogin" routerLink="/login">Cancel</button>
            </div>
          </form>
        </div>
      </ng-container>
      <ng-container *ngIf="State.PasswordChanged === state">
        <div class="auth-title2">Password Changed</div>
        <div class="notifyText2">Password was successfully changed. You can login using your new credentials now.</div>
        <div class="changePasswordButton-wrapper">
          <button class="changePasswordButton" routerLink="/login">
            Go to login page
          </button>
        </div>
      </ng-container>

      <ng-container *ngIf="State.TokenInvalid === state">
        <div class="auth-title2">Token Invalid</div>
        <div class="notifyText2">Link is either expired or incorrect. Please try again.</div>
        <div class="changePasswordButton-wrapper">
          <button class="changePasswordButton" routerLink="/forgot-password">
            Go to reset password page
          </button>
          <button class="changePasswordButton" routerLink="/login">
            Go to login page
          </button>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="leftSection">
    <!--<img src="../../../assets/images/builderTracker_W_T.png" alt="eTracker" class="head-logo">-->
  </div>
</div>
