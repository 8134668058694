import { Pipe, PipeTransform } from '@angular/core';
import {IFilter} from '../dataTypes';
@Pipe({
  name: 'filterDataSort',
  pure: false
})

export class FilterDataSortPipe implements PipeTransform {

  transform(items: any[], filterType: string, projectFiltersApplied: IFilter): any[] {
    if (!items) {
      return [];
    }
    if (!filterType) {
      return items;
    }

    switch (filterType) {
      case 'phase':
      case 'created_from':
      case 'label':
        return items.sort((a: any, b: any) => {
          if (this.isFilterChecked(projectFiltersApplied, filterType, a) === this.isFilterChecked(projectFiltersApplied, filterType, b)) {
            return 0;
          }
          if (this.isFilterChecked(projectFiltersApplied, filterType, a)) {
            return -1;
          }
          return 1;
        });
      case 'states':
      case 'statuses':
      case 'bb_status':
      case 'bb_design':
      case 'dev_type':
      case 'sub_type':
      case 'story_type':
      case 'code_commit':
      case 'studio_roles':
      case 'owner':
      case 'reviewer':
      case 'requester':
      case 'state_changed_at_date_range':
      case 'rating':
      case 'ship_release_date_range':
      case 'created_on_date_range':
      case 'swimlane':
      case 'priority':
        return items.sort((a: any, b: any) => {
          // tslint:disable-next-line:max-line-length
          if (this.isFilterChecked(projectFiltersApplied, filterType, a.type) === this.isFilterChecked(projectFiltersApplied, filterType, b.type)) {
            return 0;
          }
          if (this.isFilterChecked(projectFiltersApplied, filterType, b.type)) {
            return -1;
          }
          return 1;
        });

      case 'feature':
        return items.sort((a: any, b: any) => {
          // tslint:disable-next-line:max-line-length
          if (this.isFilterChecked(projectFiltersApplied, 'feature', a.id) === this.isFilterChecked(projectFiltersApplied, 'feature', b.id)) {
            return 0;
          }
          if (this.isFilterChecked(projectFiltersApplied, 'feature', a.id)) {
            return -1;
          }
          return 1;
        });

      case 'member':
        return items.sort((a: any, b: any) => {
          // tslint:disable-next-line:max-line-length
          if (this.isFilterChecked(projectFiltersApplied, 'member', a.user.id) === this.isFilterChecked(projectFiltersApplied, 'member', b.user.id)) {
            return 0;
          }
          if (this.isFilterChecked(projectFiltersApplied, 'member', a.user.id)) {
            return -1;
          }
          return 1;
        });

      default:
        return items;
    }
  }

  isFilterChecked(arr, key, value) {
    if (arr[key].length > 0) {
      return arr[key].indexOf(value) >= 0;
    }
    return false;
  }
}
