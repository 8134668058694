import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
name: 'epicSearch'
})
export class EpicSearchPipe implements PipeTransform {
transform(epics: any[], searchText: string): any[] {
if (!searchText) {
return epics;
}
searchText = searchText.toLowerCase();
return epics.filter(epic => epic.title.toLowerCase().includes(searchText));
}
}
