import { Component } from '@angular/core';

@Component({
  selector: 'app-timing',
  templateUrl: 'timing.component.html',
  styleUrls: ['timing.component.scss'],
})
export class TimingComponent {

  constructor() {
    // This is intentional
   }
}
