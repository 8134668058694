<div class="helpSupport-wrapper">
  <div class="topInfo-section">
    <div class="topTitle">
      <div class="left">
        <div class="maintitle">Help</div>
      </div>
    </div>
  </div>
  <div class="helpSupport-section">
    <div class="subTitle">Tutorials for Builder Tracker</div>
    <div class="helpAttachment-wrapper">
      <div class="helpAttachment-list">
        <div class="assetsContainer">
          <a class="asst-container" href="https://builderbuckets.blob.core.windows.net/trackerimages/uploads/document/44043/Squad_members.pdf" target="_blank" rel="noopener">
            <span class="gotoImage">
                <img class="default-attachment" src="/assets/images/pdf-icon.png" alt="loader"/>
            </span>
          </a>
        </div>
        <div class="assetsInfo">
          <div class="assetsInfo-wrapper">
            <span class="assetsName">Delivery</span>
            <span class="assetsDes">How to create and allocate stories</span>
          </div>
          <a  href="https://builderbuckets.blob.core.windows.net/trackerimages/uploads/document/44043/Squad_members.pdf" class="gotoImage" target="_blank" rel="noopener"> <em class="iconb-download"></em></a>
        </div>
      </div>
      <div class="helpAttachment-list">
        <div class="assetsContainer">
          <a class="asst-container" href="https://builderbuckets.blob.core.windows.net/trackerimages/uploads/document/44041/Creators.pdf" target="_blank" rel="noopener">
            <span class="gotoImage">
                <img class="default-attachment" src="/assets/images/pdf-icon.png" alt="loader"/>
            </span>
          </a>
        </div>
        <div class="assetsInfo">
          <div class="assetsInfo-wrapper">
            <span class="assetsName">Creators - Developers</span>
            <span class="assetsDes">Guide for Developers</span>
          </div>
          <a  href="https://builderbuckets.blob.core.windows.net/trackerimages/uploads/document/44041/Creators.pdf" class="gotoImage" target="_blank" rel="noopener"> <em class="iconb-download"></em></a>
        </div>
      </div>
      <div class="helpAttachment-list">
        <div class="assetsContainer">
          <a class="asst-container" href="https://builderbuckets.blob.core.windows.net/trackerimages/uploads/document/44042/Reviewers.pdf" target="_blank" rel="noopener">
            <span class="gotoImage">
              <img class="default-attachment" src="/assets/images/pdf-icon.png" alt="loader"/>
            </span>
          </a>
        </div>
        <div class="assetsInfo">
          <div class="assetsInfo-wrapper">
            <span class="assetsName">Creators - Reviewer/QA</span>
            <span class="assetsDes">Guide for Reviewer/QA </span>
          </div>
          <a  href="https://builderbuckets.blob.core.windows.net/trackerimages/uploads/document/44042/Reviewers.pdf" class="gotoImage" target="_blank" rel="noopener"> <em class="iconb-download"></em></a>
        </div>
      </div>
      <div class="helpAttachment-list">
        <div class="assetsContainer">
          <a class="asst-container" href="https://builderbuckets.blob.core.windows.net/trackerimages/uploads/document/46025/Building_Blocks_Training.pdf" target="_blank" rel="noopener">
            <span class="gotoImage">
                <img class="default-attachment" src="/assets/images/pdf-icon.png" alt="loader"/>
            </span>
          </a>
        </div>
        <div class="assetsInfo">
          <div class="assetsInfo-wrapper">
            <span class="assetsName">Building Blocks</span>
            <span class="assetsDes">Building Blocks Training Guide</span>
          </div>
          <a  href="https://builderbuckets.blob.core.windows.net/trackerimages/uploads/document/46025/Building_Blocks_Training.pdf" class="gotoImage" target="_blank" rel="noopener"> <em class="iconb-download"></em></a>
        </div>
      </div>

      <div class="helpAttachment-list">
        <div class="assetsContainer">
          <a class="asst-container" href="https://builderbuckets.blob.core.windows.net/trackerimages/uploads/document/474363/Tracker_onboarding_course.pdf" target="_blank" rel="noopener">
            <span class="gotoImage">
                <img class="default-attachment" src="/assets/images/pdf-icon.png" alt="loader"/>
            </span>
          </a>
        </div>
        <div class="assetsInfo">
          <div class="assetsInfo-wrapper">
            <span class="assetsName">Tracker Onboarding</span>
            <span class="assetsDes">Tracker Onboarding Course</span>
          </div>
          <a  href="https://builderbuckets.blob.core.windows.net/trackerimages/uploads/document/474363/Tracker_onboarding_course.pdf" class="gotoImage" target="_blank" rel="noopener"> <em class="iconb-download"></em></a>
        </div>
      </div>

      <div class="helpAttachment-list">
        <div class="assetsContainer">
          <a class="asst-container" href="https://builderbuckets.blob.core.windows.net/trackerimages/uploads/document/1387423/UI-Engine_20Generated_20Code_20Explained_for_tracker.pdf" target="_blank" rel="noopener">
            <span class="gotoImage">
                <img class="default-attachment" src="/assets/images/pdf-icon.png" alt="loader"/>
            </span>
          </a>
        </div>
        <div class="assetsInfo">
          <div class="assetsInfo-wrapper">
            <span class="assetsName">UI-Engine Generated Code</span>
            <span class="assetsDes">UI-Engine Generated Code Explained</span>
          </div>
          <a  href="https://builderbuckets.blob.core.windows.net/trackerimages/uploads/document/1387423/UI-Engine_20Generated_20Code_20Explained_for_tracker.pdf" class="gotoImage" target="_blank" rel="noopener"> <em class="iconb-download"></em></a>
        </div>
      </div>

      <div class="helpAttachment-list">
        <div class="assetsContainer">
          <a class="asst-container" href="https://builderbuckets.blob.core.windows.net/trackerimages/uploads/document/1489506/Builder_20IDE_20-_20Get_20Started_20Guide.pdf" target="_blank" rel="noopener">
            <span class="gotoImage">
                <img class="default-attachment" src="/assets/images/pdf-icon.png" alt="loader"/>
            </span>
          </a>
        </div>
        <div class="assetsInfo">
          <div class="assetsInfo-wrapper">
            <span class="assetsName">IDE- Getting Started</span>
            <span class="assetsDes">Builder IDE- Getting Started Guide</span>
          </div>
          <a  href="https://builderbuckets.blob.core.windows.net/trackerimages/uploads/document/1489506/Builder_20IDE_20-_20Get_20Started_20Guide.pdf" class="gotoImage" target="_blank" rel="noopener"> <em class="iconb-download"></em></a>
        </div>
      </div>

    </div>

    <div class="subTitle2">Video tutorials for Builder Tracker</div>
    <div class="helpAttachment-wrapper">
      <div class="helpAttachment-list videoList" *ngFor="let videoTut of videoTuts">
        <div class="assetsContainer" (click)="openVideoPopup(videoTut)">
          <span class="asst-container">
            <span class="gotoImage">
                <img class="default-attachment" src="/assets/images/help-video.png" alt="loader"/>
            </span>
          </span>
        </div>
        <div class="assetsInfo">
          <div class="assetsInfo-wrapper">
            <span class="assetsName">{{videoTut.videoName}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="videoPopup-wrapper" *ngIf="showVideo">
  <div class="videoPopup-backdrop"></div>
  <em class="iconb-close closeVideoPopup" (click)="closeVideoPopup()"></em>
  <div class="videoPopup">
    <video width="640px" controls autoplay>
      <source src="{{vURL}}" type='video/mp4' />
    </video>
  </div>
</div>
