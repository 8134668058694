import { Component, OnInit } from '@angular/core';
import { AdminProjectsService } from '../projects.service';
import { range } from 'lodash';
import { CommonList } from '../../common/classes';
import {CustomToastService} from '../../../shared/services/custom-toast.service';
import { SharedService } from '../../../shared/services/shared.service';
import { ErrorService } from '../../../shared/errorFunction';
import { Router, ActivatedRoute, Params, NavigationEnd } from '@angular/router';

@Component({
  selector: 'admin-projects-list',
  templateUrl: 'list.component.html',
  styleUrls: [
    '../../admin.common.scss',
    'list.component.scss'
  ]
})
export class AdminProjectsListComponent extends CommonList implements OnInit {
  projects: Array<any>;
  meta: any;
  pages: Array<number>;
  params = {
    q: '',
    sort_column: 'id',
    sort_order: 'asc',
    page: 1
  };
  startLoader:boolean = false;
  is_exporting: boolean = false;

  constructor(private projectsService: AdminProjectsService,
              private errroService: ErrorService,
              public sharedService: SharedService,
              private customToast: CustomToastService,
              private router: Router,) {
    super();
  }

  ngOnInit() {
    this.startLoader = true;
    this.getRecords();
  }

  getRecords() {
    this.projectsService.getProjects(this.params).subscribe(json => {
      this.projects = json.projects;
      this.meta = json.meta;
      this.generatePages();
      this.startLoader = false;
    },
      (error) => {
        this.errroService.errorFunction(error);
        this.router.navigateByUrl('/');
      })
  }

  getSearchRecords() {
    this.params.page = 1;
    this.getRecords();
  }

  export() {
    this.is_exporting = true;
    this.customToast.messages.push({
      id: 'ProjectsList',
      type: 'warning',
      class: 'story_edited',
      title: 'Export Projects',
      message: 'Projects.csv is being downloaded.'
    });
    this.projectsService.exportFile().subscribe((res: any) => {
      const parsedResponse = res;
      const blob = new Blob([parsedResponse], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);

      if (navigator.msSaveOrOpenBlob) {
        navigator.msSaveBlob(blob, 'projects.csv');
      } else {
        const a = document.createElement('a');
        a.href = url;
        a.download = 'projects.csv';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
      window.URL.revokeObjectURL(url);

      this.is_exporting = false;
      this.customToast.messages.push({
        id: 'ProjectsList',
        type: 'success',
        class: 'new_member_added',
        title: 'Export Projects',
        message: 'Projects.csv is successfully downloaded.'
      });
    }, (error) => {
        this.is_exporting = false;
        this.customToast.messages.push({
        id: 'Downloadfail',
        type: 'fail',
        class: 'import_fail',
        title: 'Export Projects',
        message: 'Export projects.csv failed.'
      });
      this.errroService.errorFunction(error);
    });
  }

  storiesDetailedCount(detailed_count) {
    const text = [];
    let x;
    for (x in detailed_count) {
      text.push((x.length == 0 ? 'Untagged' : x) + ": " + detailed_count[x]);
    }
    return text.join(", ");
  }
}
