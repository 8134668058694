
import {debounceTime} from 'rxjs/operators';
import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';
import { UserData, EVENT_TYPES, RegisterData } from '../../shared/dataTypes';
import { QueryParamsService } from '../../shared/services/queryParams.service';
import { PolicyService } from '../../shared/services/policy.service';
import { UserService } from '../../shared/services/user.service';
import { ErrorService } from '../../shared/errorFunction';
import { NotificationService } from '../../shared/services/notification.service';
import { ProjectService } from '../../shared/services/project.service';
import { filter } from 'rxjs/operators';
import { HttpRequests } from '../../shared/services/http-requests.service';
import { DataService } from '../../shared/services/data.service';
import { environment } from '../../../environments/environment';
import {SharedService} from "../../shared/services/shared.service";

@Component({
  selector: 'register',
  templateUrl: 'register.component.html',
  styleUrls: ['register.component.scss']
})
export class RegisterComponent {
  public registerData: RegisterData = <RegisterData>{};
  public output: any;
  showHtml = false;
  showLoginPassword = false;

  constructor(
    private httpRequest: HttpRequests,
    private dataService: DataService,
    private errorService: ErrorService,
    private activatedRoute: ActivatedRoute,
    private queryParamsService: QueryParamsService,
    private router: Router,
    private userService: UserService,
    private location: Location,
    private renderer: Renderer2,
    private projectService: ProjectService,
    private notificationService: NotificationService,
    private policyService: PolicyService,
    private sharedService: SharedService) {

  }

  ngOnInit() {
    this.renderer.addClass(document.body, 'auth-screen');
    if (this.userService.getUser()) {
      this.router.navigate(['/']);
    } else {
        this.activatedRoute.queryParams.pipe(
        debounceTime(500),
        filter((queryParams: any) => {
          if(!queryParams.confirmation_token) {
            this.router.navigate(['/login']);
          }
          return queryParams.confirmation_token;
        }))
          .subscribe(params => {
              const confirmationToken = params['confirmation_token'];
              if (confirmationToken) {
                this.userService.getConfirmationTokenFromRegister(confirmationToken).subscribe((res) => {
                  this.registerData.email = res.user.email;
                  this.registerData.name = res.user.name;
                  this.registerData.initials = res.user.initials;
                  this.registerData.id = res.user.id;
                  this.showHtml = true;
                },
                  (error) => {
                    this.errorService.errorFunction(error);
                    this.router.navigate(['/login']);
                  })
              }
          });
    }
  }

  // Submit Data to Backend
  onSubmit() {
    this.output = null;
    this.registerData.confirm_success_url = window.location.origin + '/#/' + 'login';
    this.userService.login_signup(this.registerData, 'register').subscribe(
      res => {
        this.registerData = <RegisterData>{};
        this.output = res;
        const data = res.body['data'];
        const adminLogin = (data.user.role == 'admin' || (data.project_admin));
        const designerLogin =  data.user.skill == 'UI/UX';
        this.userService.setCurrentUser(data.user as UserData, res.headers, designerLogin, adminLogin);
        if(!designerLogin) {
          const payload = {
            data: {
              currentUser: this.userService.getUser()
            }
          };
          this.notificationService.broadcast(EVENT_TYPES.SESSION.LOGGED_IN, payload);
          this.notificationService.broadcast(EVENT_TYPES.SESSION.LOGGED_IN_NEW, payload);
          this.policyService.refresh();
          // this.router.navigate(['/']);
          this.loginRedirect();
        } else {
          window.location.href = environment.designerUrl;
        }

      }, (error) => {
        this.output = error.errors;
      }
    );
  }

  showPassword($event) {
    this.showLoginPassword = !this.showLoginPassword;
    $event.preventDefault();
    $event.stopPropagation();
  }

  ngOnDestroy() {
    this.renderer.addClass(document.body, 'auth-screen');
  }
  loginRedirect() {
    const currentUser = this.userService.getUser();
    const payload = {
      data: {
        currentUser: currentUser,
      }
    };
    this.userService.getPartnerCode().subscribe(partner => {
      this.userService.setPartner(partner);
    }, err => this.errorService.errorFunction(err));
    this.notificationService.broadcast(EVENT_TYPES.SESSION.LOGGED_IN_NEW, payload);
    this.notificationService.broadcast(EVENT_TYPES.SESSION.LOGGED_IN, payload);
    this.policyService.refresh();
    let redirectUrl = '';
    let queryParams = {};
    if(this.userService.loginUrl != '') {
      this.userService.redirectToStoryBoard = true;
      if(this.userService.loginUrl.indexOf('?') >= 0) {
        this.router.navigate(["/projects/" + this.userService.pro_id_for_reg]);
      }
      else {
        redirectUrl = this.userService.loginUrl;
        if(redirectUrl.trim() === '/projects') {
          redirectUrl = '/';
        }
        this.router.navigate([redirectUrl]);
      }

    } else {
      redirectUrl = '/';
      this.router.navigate(['/']);
    }

    if (currentUser) {
      this.userService.trackSegmentEvent('Login successful', this.sharedService.getCommonPropsForSegmentEvents());
    }
  }
  getueryString(querystring) {
    // remove any preceding url and split
    querystring = querystring.substring(querystring.indexOf('?')+1).split('&');
    let params = {}, pair, d = decodeURIComponent;
    // march and parse
    for (let i = querystring.length - 1; i >= 0; i--) {
      pair = querystring[i].split('=');
      params[d(pair[0])] = d(pair[1] || '');
    }

    return params;
  }
}
