import * as _ from 'lodash';
import { DraggableData, STORY_STATE, PROJECT_COLUMN_DATA, PROJECT_COLUMN, PROJECT_COLUMN_IDENTIFIER } from './dataTypes';
import { CustomToastService } from './services/custom-toast.service';
import { Project } from './models/project';
import { GUID } from './models/guid';

export class Draggable {
  static dragMode = false;
  static permittedColumns = _.filter(PROJECT_COLUMN_DATA, column => column.canDrag).map(column => column.list_code);
  static permittedDropContainers = _.filter(PROJECT_COLUMN_DATA, column => column.isDroppableContainer).map(column => column.list_code);
  static currentTarget: any;
  static currentTargetElement: any;
  static initialSiblingId: any;

  static isContainer(el) {
    this.permittedColumns = _.filter(PROJECT_COLUMN_DATA, column => column.canDrag).map(column => column.list_code);
    this.permittedDropContainers = _.filter(PROJECT_COLUMN_DATA, column => column.isDroppableContainer).map(column => column.list_code);
    if (this.dragMode) {
      return this.isColumnContainer(el) ||
             this.isEpicsContainer(el) ||
             this.isEpicDetailContainer(el);
    } else {
      return this.isColumnContainer(el);
    }
  }

  static isEpicsContainer(el) {
    return el && el.classList.contains('epic');
  }

  static isEpicDetailContainer(el) {
    return el && el.classList.contains('epicdetailcolumn');
  }

  static isColumnContainer(el) {
    return el && el.classList.contains("storycolumn");
  }

  static invalid(el, handle) {
    if (this.columnIdentifier(el) === PROJECT_COLUMN_IDENTIFIER.archived || el.classList.contains("newStory-wrapper")) {
      return true;
    }
    return false;

  }

  // 1. done is unmovable doesn't accept stories
  // 2. search is unmovable doesn't accept stories
  static draggableColumn(el) {
    const identifier = this.columnIdentifier(el);

    if(identifier) {
      return _.some(this.permittedColumns, column => column ===identifier);
    } else {
      return false;
    }
  }


  static isChildColumn(el) {
    return el && el.classList.contains("story_task_detail");
  }

  // 3. chilly accepts stories in column others accept only inside iteration
  static accepts(el, target, source, sibling): boolean {
    return target && target.classList.contains('epic') ? false: true;
  }

  static acceptForColumn(el) {
    return _.some(this.permittedDropContainers, column => column === el.id);
  }

  static columnIdentifier(el) {
    const attributeName = "project-column";
    let startElement = el;

    while ((el = el.parentElement) && !el.attributes[attributeName]);

    if(el) {
      const header = el.querySelector('.column_header');
      return header.attributes['data-column-name'].value;
    } else {
      return null;
    }
  }

  static withinStory(el) {
    const attributeName = "project-story";
    let startElement = el;
    while ((el = el.parentElement) && !el.attributes[attributeName]);

    if(!el) {
      return startElement.attributes &&
             !!startElement.attributes[attributeName];
    }

    return !!el;
  }

  static extractDropData(dropData, project: Project): DraggableData {
    const response: DraggableData = {
      columnIdentifier: dropData[0]
    };

    // extract element story
    const element = dropData[1];
    if (element) {
      response.elementStory = this.getStoryFromElement(element);
    }

    const target = dropData[2];
    if (target) {
      if (target.classList.contains('storycolumn')) {
        response.columnIdentifier = target.id;
      }
    }

    const source = dropData[3];

    // extract sibling story
    const sibling = dropData[4];
    if (sibling && sibling.querySelector('.story')) {
      response.siblingStory = this.getStoryFromElement(sibling);
    }

    return response;
  }

  static getStoryId(el) {
    const story = el.querySelector('.story');
    if(story && story.attributes && story.attributes['data-story-uid']) {
      return story.attributes['data-story-uid'].value;
    } else {
      return null;
    }
  }

  static getStoryFromElement(el) {
    const storyUID = this.getStoryId(el);

    return GUID.instance.findStoryByGUID(storyUID);
  }

  static getEpicId(el) {
    if(el.classList.contains('epic') ||
       el.classList.contains('epicdetailcolumn')) {
      return el.attributes['data-epic-uid'].value;
    } else {
      return el.querySelector('.epic').attributes['data-epic-uid'].value;
    }
  }

  static getEpicFromElement(el) {
    const epicUID = this.getEpicId(el);

    return GUID.instance.findEpicByGUID(epicUID);
  }

  static getColumnIdentifier(target) {
    if(!target.classList.contains('storycolumn')) {
      while(!target.classList.contains('storycolumn')) {
        target = target.parentElement;
      }
    }

    return target ? target.id : null;
  }

  static moveIntoTarget(dropData) {
    const target = dropData[2];

    if(target) {
      this.currentTargetElement = target;

      // Set current epic target
      if(target.classList.contains('epic')) {
        this.currentTargetElement.classList.add("hover-target");
        this.currentTarget = this.getEpicFromElement(target);
      }
    }
  }

  static leaveFromTarget(dropData) {
    if(this.currentTargetElement) {
      this.currentTargetElement.classList.remove("hover-target");
      this.currentTargetElement = null;
    }

    this.currentTarget = null;
    const target = dropData[2];

    if(target) {

    }
  }

  // Set containers to be drop zone only not accepting elements to append
  static isDropZone(item, target) {
    if(target) {
      if(target.classList.contains('epic') ||
         target.classList.contains('epicdetailcolumn')) {
        return true;
      }
    }
    return false;
  }

  static setInitialStoryData(item) {
    this.initialSiblingId = null;
    let story = this.getStoryFromElement(item);
    let sibling = this.nextStorySibling(item.getAttribute('storyid'), item);
    if (sibling) {
      this.initialSiblingId = sibling.getAttribute('storyid');
    }
  }

  static nextStorySibling(prevStoryId, sibling) {
    while (sibling &&
            (
              (
                !sibling.classList ||
                !sibling.classList.contains('projectStory') ||
                sibling.classList.contains('gu-unselectable') ||
                (prevStoryId == sibling.getAttribute('storyid'))
              )
            )
          ) {
      sibling = sibling.nextElementSibling;
    };
    return sibling;
  }

  static prevStorySibling(prevStoryId, sibling) {
    while (sibling &&
            (
              (
                !sibling.classList ||
                !sibling.classList.contains('projectStory') ||
                sibling.classList.contains('gu-unselectable') ||
                (prevStoryId == sibling.getAttribute('storyid'))
              )
            )
          ) {
      if (sibling.previousElementSibling) {
        sibling = sibling.previousElementSibling;
      } else {
        break;
      }
    };
    let nextSibling = sibling.nextElementSibling;
    if (nextSibling.getAttribute('storyid') == prevStoryId) {
      return nextSibling.nextElementSibling;
    } else {
      return sibling.nextElementSibling;
    }
  }

  static isNotDroppable(item, source, target) {
    const story = this.getStoryFromElement(item);
    const target_column = target.id;
    if (target_column == PROJECT_COLUMN_IDENTIFIER.to_do || target_column == PROJECT_COLUMN_IDENTIFIER.in_progress) {
      return false;
    } else if (story && (!story.estimate || !story.owned_by_id || !story.phase || story.epics.length == 0)) {
      return 'Owner, estimates, features and phase are required to ' + this.storyState(target_column) + ' the story.';
    } else if (story && story.project && story.project.dashboard_project_id && !story.story_acceptable &&
                (target_column === PROJECT_COLUMN_IDENTIFIER.qa || target_column === PROJECT_COLUMN_IDENTIFIER.accepted)) {
      return 'Sketch needs to be accepted to ' + this.storyState(target_column) + ' the story.';
    } else if (story && story.project && story.project.dashboard_project_id &&
                (target_column === PROJECT_COLUMN_IDENTIFIER.qa || target_column === PROJECT_COLUMN_IDENTIFIER.accepted) &&
                (story.phase === 'Full Build' || story.phase === 'MVP') && (story.dev_type !== 'QA') && story.commits && story.dev_type !=='E2E' && story.commits.length < 1) {
      return 'No commit was found for this story on GitLab.';
    }
    return false;
  }

  static storyState(columnIdentifier) {
    switch (columnIdentifier) {
      case PROJECT_COLUMN_IDENTIFIER.to_do:
         return 'unstart';
      case PROJECT_COLUMN_IDENTIFIER.in_progress:
        return 'start';
      case PROJECT_COLUMN_IDENTIFIER.qa:
        return 'deliver';
      case  PROJECT_COLUMN_IDENTIFIER.rejected:
        return 'reject';
      case PROJECT_COLUMN_IDENTIFIER.accepted:
        return 'accept';
      default:
        return null;
    }
  }

  static newReference(item, sibling) {
    if (sibling) {
        return this.prevStorySibling(item.getAttribute('storyid'), sibling.previousElementSibling);
    } else {
      return false;
    }
  }

  static isDropPlaceholder(item, target) {
    return target && target.classList.contains('epicdetailcolumn');
  }

  static extractDropZoneData(value) {
    const data = [];

    const target = value[2];
    if(target && target.classList.contains('epic')) {
      data['epic'] = this.getEpicFromElement(target);
    } else if(target && target.classList.contains('epicdetailcolumn')) {
      data['epic'] = this.getEpicFromElement(target);
    }

    const item = value[1];
    if(item) {
      data['story'] = this.getStoryFromElement(item);
    }

    return data;
  }

}
