export class Story {
  id: number;
  name: string;
  hours: string[];

  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    this.hours = this.prepareHours(data.hours || []);
  }

  private prepareHours(hours: string[]): string[] {
    hours.length = 7;
    for (let i = 0; i < hours.length; i++) {
      hours[i] = hours[i] || '-';
    }
    return hours;
  }
}
