<div class="storyProgressStatus-popup">
  <div class="storyProgressStatus-popupWrapper">
    <div class="topSection">
      <div class="titleSection">{{storyProgress?.title}}</div>
      <span class="storyState-icon" [ngClass]="[story.state]"></span>
      <em [ngClass]="['iconb-' + story.story_type === 'task' && story.parent !== null ? 'subtask' : story.story_type,'storyTypeIcon']"></em>
      <em class="iconb-summary" *ngIf="story.description"></em>
      <div class="timeInfo"><em class="iconb-clock"></em> Last time updated: {{sharedService.formattTime(story.updated_at)}}</div>
    </div>
    <div class="progressSection">
      <div class="slideSection">
        <div class="slideProgressLine">
          <span class="slideLineDefault"></span>
          <span class="slideLineprog" [style.width.%] = "storyProgress.completedProgress"><span *ngIf="(storyProgress.completedProgress > 9) && (storyProgress.completedProgress < 92)" class="percentCounter">{{(storyProgress.completedProgress)}}%</span></span>
        </div>
        <div class="slideInterval-wrapper">
          <div class="slideInterval" [ngClass] = "{covered: checkIndex(i)}" (click) = "progressStrip(prg, i)" *ngFor = "let prg of sharedService.sliderProgress; let i = index"></div>
        </div>
      </div>
    </div>
    <div class="statusAction">
      <button type="button" class="statusButton" (click)="SaveProgress()">{{(storyProgress.completedProgress == '100' ? 'Finish': 'Save')}}</button>
    </div>
  </div>
</div>
