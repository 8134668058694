import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserService } from './user.service';

@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(public userService: UserService, public auth: UserService, public router: Router) {}

  canActivate(next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    if (!this.auth.getUser()) {
      this.router.navigate(['login']);
      return false;
    }

    if (state.url === '/story-approvals' && this.auth.getUser().reviewer === false) {
      this.router.navigate(['']);
      return false;
    }

    return true;
  }
}
