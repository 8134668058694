<div class="storyBoard-navigation">
  <div class="navigation-list" *ngIf="project">
    <a class="navList-item" *ngIf="projectService.currentProject && projectService.currentProject.dashboard_project_id" (click)="projectService.closeProjectDD()" role="presentation" [routerLink]="['/projects', project.id, 'brief']" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
      <em class="iconb-brief"></em>
      <span class="navigation-listName" [@fadeInOut]>Brief & Documents</span>
      <span class="menuListHover">Brief & Documents</span>
    </a>
    <a class="navList-item" (click)="projectService.closeProjectDD()" role="presentation" [routerLink]="['/projects', project.id]" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" >
      <em class="iconb-fade"></em>
      <span class="navigation-listName" [@fadeInOut]>Stories</span>
      <span class="menuListHover">Buildcard Stories</span>
    </a>
    <a class="navList-item" *ngIf="projectService.currentProject && projectService.currentProject.dashboard_project_id" (click)="projectService.closeProjectDD()" role="presentation" [routerLink]="['/projects', project.id, 'whiteboard']" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
      <em class="iconb-whiteboard"></em>
      <span class="navigation-listName" [@fadeInOut]>Whiteboard</span>
      <span class="menuListHover">Whiteboard</span>
    </a>
    
    <ng-container *ngIf="isProjectAdmin">
      <a class="navList-item" (click)="projectService.closeProjectDD()" role="presentation" [routerLink]="['/projects', project.id, 'dashboard']" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
        <em class="iconb-project-dashboard"></em>
        <span class="navigation-listName" [@fadeInOut]>Members</span>
        <span class="menuListHover">Members</span>
      </a>
    </ng-container>

    <div class="navList-item" (click)="openPlanningDD()">
      <em class="iconb-activity"></em>
      <em class="iconb-dd-down ddarw" [ngClass]="{'notOpen': isPlanningDDon}"></em>
      <span class="navigation-listName" [@fadeInOut]>Planning</span>
      <span class="menuListHover">Planning</span>
    </div>

    <ng-container *ngIf="isPlanningDDon">
      <div class="navList-item planningDD" (click)="projectService.closeProjectDD()" role="presentation" [routerLink]="['/projects', project.id, 'sprint']" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: false }">
        <em class="iconb-sprint-planning"></em>
        <span class="navigation-listName" [@fadeInOut]>Sprint Planning</span>
        <span class="menuListHover">Sprint Planning</span>
      </div>
    </ng-container>
    <ng-container *ngIf="isPlanningDDon">
      <div class="navList-item planningDD" (click)="projectService.closeProjectDD()" role="presentation" [routerLink]="['/projects', project.id, 'releases']" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
        <em class="iconb-release-planning"></em>
        <span class="navigation-listName" [@fadeInOut]>Release Planning</span>
        <span class="menuListHover">Release Planning</span>
      </div>
    </ng-container>
      <a class="navList-item" (click)="projectService.closeProjectDD()" role="presentation" [routerLink]="['/projects', project.id, 'test-suite']" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
        <em class="iconb-bug"></em>
        <span class="navigation-listName">Test Suite</span>
        <span class="menuListHover">Test Suite</span>
      </a>
      <ng-container *ngIf="projectService.currentProject.isExternalProject()">
        <a class="navList-item" (click)="projectService.closeProjectDD()" role="presentation" [routerLink]="['/projects', project.id, 'expert-schedule']" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
          <em class="iconb-calendar"></em>
          <span class="navigation-listName" [@fadeInOut]>Expert Schedule 
            <!-- <em class="count">10</em> -->
          </span>
          <span class="menuListHover">Expert Schedule</span>
        </a>
      </ng-container>
    <a class="navList-item" *ngIf="!isProjectReadonly && isProjectAdmin" (click)="projectService.closeProjectDD()" role="presentation" [routerLink]="['/projects', project.id, 'export']" routerLinkActive="active">
      <em class="iconb-reports"></em>
      <span class="navigation-listName" [@fadeInOut]>Export</span>
      <span class="menuListHover">Buildcard Export</span>
    </a>
    <ng-container *ngIf="isProjectAdmin || policyService.expertAsQA(project)">
      <a class="navList-item" (click)="projectService.closeProjectDD()" role="presentation" [routerLink]="['/projects', project.id, 'import']" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
        <em class="iconb-hotspost"></em>
        <span class="navigation-listName" [@fadeInOut]>Import</span>
        <span class="menuListHover">Buildcard Import</span>
      </a>
    </ng-container>
    <ng-container *ngIf="projectService.currentProject && projectService.currentProject.isExternalProject() && project.designPhaseAvailable()">
      <a class="navList-item" href={{getUIWUrl()}} target="_blank" >
        <em class="iconb-uiw"></em>
        <span class="navigation-listName">Builder UIW</span>
        <span class="menuListHover defaultTooltip">Click here to see design workflows and prototype.</span>
        <span class="menuListHover">Builder UIW</span>
      </a>
    </ng-container>
    <ng-container *ngIf="isProjectAdmin">
      <a class="navList-item" (click)="projectService.closeProjectDD()" role="presentation" [routerLink]="['/projects', project.id, 'settings']" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
        <em class="iconb-settings"></em>
        <span class="navigation-listName" [@fadeInOut]>Settings</span>
        <span class="menuListHover">Buildcard Settings</span>
      </a>
    </ng-container>   
  </div>
</div>
