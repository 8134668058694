import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common';
// import { PopoverModule, TooltipModule } from 'ngx-bootstrap';
import { FormsModule } from '@angular/forms';
import { StoryTimerComponent } from './story-timer.component';
import { SharedModule } from '../../shared.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    // PopoverModule.forRoot(),
    // TooltipModule.forRoot(),
    SharedModule
  ],
  declarations: [
    StoryTimerComponent
  ],
  exports: [
    StoryTimerComponent
  ]
})
export class StoryTimerModule { }
