import { CommonBase } from './base.class';
import { isEqual, clone, each, uniq } from 'lodash';

export class CommonEdit extends CommonBase {
  // private toastr: ToastrService;
  formOriginal: any;
  form: any;
  errors: any;
  isPending: boolean = false;

  revert() {
    this.errors = null;
    this.form = clone(this.formOriginal);
  }

  onError(response) {
    this.errors = {};
    each(response, (value, key) => {
      this.errors[key] = uniq(value);
    });
    this.isPending = false;
  }

  get isPristine(): boolean {
    return isEqual(this.formOriginal, this.form);
  }

  // toastyRecordCreated(recordName: string) {
  //   this.toastr.success('${recordName} created, New ${recordName.toLowerCase() was created successfully');
  // }
  //
  // toastyChangesSaved() {
  //   this.toastr.success('Changes updated successfully');
  // }
}
