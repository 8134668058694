import {
  ActivityData, ActivityDetail
} from '../dataTypes';

export class Activity implements ActivityData {
story_id: number;
commit_id?: string;
commit_url?: string;
story_name: string;
user_initials: string;
added_on: string;
user_name: string;
action_name: string;
action_type: string;
content_type: string;
attachment_url: string;
details: ActivityDetail[];
created_on?: string;
toggleHistory: Boolean;
moved_subtask?: {
  from_name?: string,
  from_url?: string,
  to_name?: string,
  to_url?: string
};

  constructor(data: ActivityData) {
    this.story_id = data.story_id;
    this.commit_id = data.commit_id;
    this.commit_url = data.commit_url;
    this.story_name = data.story_name;
    this.added_on = data.added_on;
    this.user_name = data.user_name;
    this.user_initials = data.user_initials;
    this.action_name = data.action_name;
    this.action_type = data.action_type;
    this.content_type = data.content_type;
    this.attachment_url = data.attachment_url;
    this.details = data.details;
    this.created_on = data.created_on;
    this.toggleHistory = false;
    this.moved_subtask = data.moved_subtask;
  }
}
