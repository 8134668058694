import { RouterModule, Routes } from '@angular/router';
import { ExampleComponent } from './example';
import { RestrictedComponent } from './restricted';
import {
  LoginComponent,
  RegisterComponent,
  ForgotPasswordComponent,
  ChangePasswordComponent
} from './authentication';
import { DashboardComponent } from './dashboard';
import { HeaderComponent } from './header/header.component';
import {
  NoContentComponent
} from './shared/noContent.component';
import {
  ProjectComponent
} from './project';
import { ProjectDashboardComponent } from './projectDashboard';
import { ProjectImportComponent } from './projectImport';
import { MemberComponent } from './member/member.component';
import { AuthGuardService } from './shared/services/auth-guard.service';
import {ProjectBriefComponent} from './project-brief/project-brief.component';
import {ProjectSettingComponent} from './project-setting/project-setting.component';
import {HelpSupportComponent} from './help-support/help-support.component';
import {ProjectExportComponent} from './project-export/project-export.component';
import {WhiteBoardComponent} from './white-board/white-board.component';
import {ProjectReleasesComponent} from "./project-releases/project-releases.component";
import {ReleaseVersionComponent} from "./project-releases/release-version/release-version.component";
import { FeedbackloopDashboardComponent } from './feedbackloop-dashboard/feedbackloop-dashboard.component';
import { TestSuiteComponent } from './test-suite/test-suite.component';
import { SprintComponent } from "./project/sprint/sprint.component";
import { SprintDetailComponent } from "./project/sprint-detail/sprint-detail.component";
import { LeavePlannerComponent } from './leave-planner/leave-planner.component';

const routerConfig: Routes = [
  {
    path: '',
    component: DashboardComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'projects/:id',
    component: ProjectComponent
  },
  {
    path: 'projects/:id/brief',
    component: ProjectBriefComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'projects/:id/settings',
    component: ProjectSettingComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'projects/:id/test-suite',
    component: TestSuiteComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'projects/:id/expert-schedule',
    component: LeavePlannerComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'projects/:id/dashboard',
    component: ProjectDashboardComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'projects/:id/whiteboard',
    component: WhiteBoardComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'projects/:id/import',
    component: ProjectImportComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'projects/:id/releases',
    component: ProjectReleasesComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'projects/:id/releases/:rid',
    component: ReleaseVersionComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'projects/:id/export',
    component: ProjectExportComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'example',
    component: ExampleComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'restricted',
    component: RestrictedComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'register',
    component: RegisterComponent
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent
  },
  {
    path: 'change-password',
    component: ChangePasswordComponent
  },
  {
    path: 'noSession',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: '',
    component: NoContentComponent,
    outlet: 'navbar'
  },
  {
    path: '',
    component: HeaderComponent,
    outlet: 'header'
  },
  {
    path: 'noContent',
    component: NoContentComponent,
    outlet: 'header'
  },
  {
    path: '**',
    component: HeaderComponent,
    outlet: 'header'
  },
  {
    path: 'member',
    component: MemberComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'help-support',
    component: HelpSupportComponent
  },
  {
    path: 'story-approvals',
    component: FeedbackloopDashboardComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'projects/:id/sprint',
    component: SprintComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'projects/:id/sprint/:sprint_id',
    component: SprintDetailComponent,
    canActivate: [AuthGuardService],
  }

];

export const routes = RouterModule.forRoot(routerConfig, {
    useHash: true,
    relativeLinkResolution: 'legacy'
});
