import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'filter'
})
export class FilterPipe implements PipeTransform {
    transform(items: any[], searchText: string): any[] {
        if (!items) return [];
        if (!searchText) return items;
        if(searchText == 'isSelected') {
            let a = items.filter(it => {
                return it[searchText] == true;
         });
         return a;
        } else {
            searchText = searchText.toLowerCase();
            return items.filter(it => {
                return (it.title.includes(searchText)); 
            });
        }

    }
}