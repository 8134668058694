<div class="authMainContainer" *ngIf="!showFaceAuth">

  <div class="rightSection">
    <div class="logo"><img src="../../../assets/images/trackerLogo2.png" alt="eTracker" class="head-logo"></div>
    <div class="sign-box-center">

       <div class="auth-title3">Sign in to Builder Tracker</div>
      <!-- <div class="authOption-wrapper">
        <button type="button" class="select-authOption" [ngClass]="{'selected': enableDeveloperForm || enableLoginWithPassword}" (click)="showDeveloperForm()">Partner</button>
        <button type="button" class="select-authOption" [ngClass]="{'selected': enableInternalForm && !enableLoginWithPassword}" (click)="showInternalForm()">Internal user</button>
      </div> -->
      <div class="authForm">
        <form *ngIf="enableInternalForm" #signInForm="ngForm" (ngSubmit)="onSubmit()">
          <div class="authForm-block">
            <span class="inputLabel">Email address</span>
            <input type="text" class="authInput" placeholder="Email" required [(ngModel)]="signInData.email" name="email">
          </div>
          <div class="authForm-block">
            <span class="inputLabel">Password</span>
            <input type="{{showLoginPassword ? 'text':'password'}}"  class="authInput" placeholder="Password" required [(ngModel)]="signInData.password"
                   name="password">
            <span class="reveal-conceal" [ngClass]="{'showText': showLoginPassword}" (click) = "showPassword($event)">
              <em class="iconb-view-tasks"></em>
            </span>
          </div>

          <div class="fgtBlock"><span  class="forgotPassword-link" (click)="forgotPassword()">Forgot password?</span></div>
          <button type="submit" class="submitAuth" [disabled]="!signInForm.form.valid">Login</button>
        </form>
        <div class="faceLogin-step1" *ngIf="enableDeveloperForm">
          <div class="authForm-block">
            <span class="inputLabel">Email address</span>
            <input type="text" class="authInput" placeholder="Email" required [(ngModel)]="signInData.email" name="email" (keyup)="showResendButton()" (mouseleave)="showResendButton()">
            <button type="button" class="submitAuth" [disabled]="disableNextBtton" (click)="checkPartnerAndDeveloper()">Next</button>
            <button type="submit" class="submitAuth resend" *ngIf="userService.isShowResendButton" (click)="resendEmail()">Resend<em class="iconb-info"><span class="elementHover">This resend invitation email on<br> your email address again.</span></em></button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="leftSection">
    <!--<img src="../../../assets/images/loginBG.jpg" alt="eTracker" class="head-logo">-->
  </div>

</div>

<div class="faceAuthContainer" *ngIf="showFaceAuth">
  <div class="topLogo"><img src="../../../assets/images/trackerLogo.png" alt="eTracker" class="head-logo"></div>
  <div class="faceAuth-progress">
    <span class="goBackToNormalLogin-button" (click)="!cameraAccessible && closeFaceAuth()"><em class="iconb-tag"></em></span>
    <div class="progress-wrapper">
      <div class="progress-step" [ngClass]="{'active': cameraAccessible}">
        <span class="progress-infoTitle">Detecting {{user.name}}’s face</span>
        <span class="progress-Status">1</span>
      </div>
      <div class="progress-step" [ngClass]="{'active': !showStream && cameraAccessible}">
        <span class="progress-infoTitle">Face Found</span>
        <span class="progress-Status">2</span>
      </div>
      <div class="progress-step" [ngClass]="{'active': isLoginComplete}">
        <span class="progress-infoTitle">Complete</span>
        <span class="progress-Status">3</span>
      </div>
    </div>
  </div>
  <div class="faceAuth-wrapper">
    <div class="faceAuth-block">
      <div class="loader-verification" *ngIf="showLoader">
        <span class="loading">Verifying</span>
      </div>
      <div class="counterFace" *ngIf="counter > 0">{{counter}}</div>
      <div class="faceCapture" [hidden]="!cameraAccessible">
        <video #video id="video" [hidden]="!showStream" width="618" height="463" style="border-radius: 8px;" autoplay></video>
        <canvas #canvas id="canvas" [hidden]="showStream" width="618" height="463" style="border-radius: 8px;"></canvas>
      </div>

      <!--For Developers: If user fails face auth, then show below options-->
      <div class="faceAuth-alternateOptions" *ngIf="!cameraAccessible">
        <div class="otherOptionTitle">Camera permission not given</div>
        <!--<button type="button" class="forPermission">Give permission</button>-->
        <button type="button" class="forloginWithPassword" (click)="loginWithPassword(true)">Login with password</button>
      </div>
    </div>
  </div>
</div>



