export class User {
  id: number;
  name: string;
  role: string;
  totalTime: string;
  name_or_email?: string;

  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    this.role = data.role;
    this.totalTime = data.total_time;
    if(data.name_or_email) {
      this.name_or_email = data.name_or_email
    }
    
  }
}
