<div class="col-md-6">
	<div class="card card-block">
		<h4 class="card-title">Sign In with Github</h4>

		<form #accessResourceForm="ngForm" (ngSubmit)="onSubmit()">

			<button
				type="submit"
				class="btn btn-primary">
				Sign In OAuth
			</button>

		</form>

	</div>
</div>
<div class="col-md-6">
	<output [data]="output"></output>
</div>