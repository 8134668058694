import { NgModule} from '@angular/core'
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { StoryComparePopupComponent } from './components/story-compare-popup/story-compare-popup.component';
import { ClipModule } from 'ng2-clip';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { SharedModule } from './shared.module';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    BrowserModule,
    ClipModule,
    CKEditorModule,
    SharedModule
  ],
  declarations: [
    StoryComparePopupComponent,
  ],
  providers: [],
  exports: [
    StoryComparePopupComponent,
  ]
})
export class StoryCompareModule { }
