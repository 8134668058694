import { Injectable } from '@angular/core';
import { Session } from '../models/session';
import { Observable, Observer } from 'rxjs';
import { HttpRequests } from '../../../shared/services/http-requests.service';
import { DataService } from '../../../shared/services/data.service';

@Injectable()
export class TimingSessionsService {

  private apiUrl = 'admin/timing/sessions';

  constructor(
    private httpRequest: HttpRequests,
    private dataService: DataService) { }

  getSessions(userId: number, storyId: number, date: string): Observable<Session[]> {
    const url = `${this.apiUrl}?user=${userId}&story=${storyId}&date=${date}`;

    const API = this.dataService.apiUrl + url;
    return Observable.create((observer: Observer<Session[]>) => {
      this.httpRequest
        .get(API, this.dataService.getRequestOptionArgs())
        .subscribe(
          response => {
            const sessions = this.parseResponse(response).sessions.map(x => new Session(x));
            observer.next(sessions);
            observer.complete();
          }
        );
      }
    );
  }

  updateSessions(userId: number, data: any): Observable<Session[]> {
    const url = `${this.apiUrl}?user=${userId}`;

    const API = this.dataService.apiUrl + url;
    return Observable.create((observer: Observer<Session[]>) => {
      this.httpRequest
        .put(API, { sessions: data }, this.dataService.getRequestOptionArgs())
        .subscribe(
          response => {
            const sessions = this.parseResponse(response).sessions.map(x => new Session(x));
            observer.next(sessions);
            observer.complete();
          }
        );
      }
    );
  }

  public parseResponse(res: any): any {
    return res;
  }
}
