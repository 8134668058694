import { ChangeDetectorRef, Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import moment from 'moment';
import { trigger, transition, style, animate } from '@angular/animations';
import { DateAndTimeService } from '../shared/services/dateAndTime.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectService } from '../shared/services/project.service';
import { Project } from '../shared/models/project';
import { NotificationService } from '../shared/services/notification.service';
import { EVENT_TYPES, ICurrentYearAndMonth, IDays, ILeaveType, IMessageSource, IMonthList, IResponse, ISortTypes, currentlyAllocatedDataType, INudgeParams } from '../shared/dataTypes';
import { ErrorService } from '../shared/errorFunction';
import { isEmpty, startCase } from 'lodash';
import { DomSanitizer } from '@angular/platform-browser';
import { UserService } from '../shared/services/user.service';
import { CustomToastService } from '../shared/services/custom-toast.service';
import { User } from '../shared/models/user';
import { PolicyService } from '../shared/services/policy.service';

@Component({
  selector: 'app-leave-planner',
  templateUrl: './leave-planner.component.html',
  styleUrls: ['./leave-planner.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({
          opacity: '0',
          transform: 'translateY(-20px)'
        }),
        animate(100, style({
          opacity: '1',
          transform: 'translateY(0)'
        }))
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate(100, style({
          opacity: '0',
          transform: 'translateY(-20px)'
        }))
      ])
    ]),
  ],
})
export class LeavePlannerComponent implements OnInit, OnDestroy {

  public startCase = startCase
  public accordionExpanded: boolean = false;
  public filterVisible: boolean = false;
  public featureDescPopupVisible: boolean = false;
  public allocationDetailVisible: boolean = false;
  public projectSubscriber: Subscription;
  public leavePlannerSubscriber: Subscription;
  public paramsSubscriber: Subscription;
  public selectedFilterCounter: number = 0
  public monthsList: Array<IMonthList> = [];
  public days: Array<IDays> = []
  public skills: Array<string> = []
  public isLoading: boolean = true
  public currentPingSelected: number = -1
  public nudgeResponseIsLoading: boolean = false
  public slideConfig = {
    "slidesToShow": 7,
    "slidesToScroll": 2,
    "initialSlide": 2 * 6,
    "infinite": false,
    "draggable": false,
    "touchMove": false,
  };
  public currentYearAndMonth: ICurrentYearAndMonth = {
    currentYear: parseInt(moment().format('YYYY')),
    currentMonth: parseInt(moment().format('M')),
    currentDate: this.getCurrentDate(),
    yearRange: 1
  }
  public currentlyAllocatedData: currentlyAllocatedDataType = {}
  public queryParams: IMessageSource = {
    year: parseInt(moment().format('YYYY')),
    month: parseInt(moment().format('M')),
    project_id: 0,
    type: 'month',
    search: '',
    sort_by: '',
    per_page: 15,
    page_number: 1,
    skill_filter: [],
    leave_filter: []
  }
  private project: Project;
  private lastQueryString: string = ''
  public sortTypes: Array<ISortTypes> = [{ key: 'alphabetical', label: "A-Z", value: 'alphabetical', sortOrder: 'asc', isChecked: false }]
  public leaveTypes: Array<ILeaveType> = [{ key: 'approved', label: "Approved", value: true, isChecked: false },
  { key: 'pending', label: 'Pending', value: false, isChecked: false }]
  public skillType: Array<number> = []
  private lastSelectedMonth: any = {}
  private searchDecouncer$: Subject<string> = new Subject();
  public response: IResponse = {
    "success": true,
    "message": "",
    "data": [],
    "meta": {},
    "errors": []
  }

  constructor(private dateAndTimeService: DateAndTimeService, private route: ActivatedRoute, private projectService: ProjectService,
    private notificationService: NotificationService, private router: Router, private errorService: ErrorService, private domSanitizer: DomSanitizer, private renderer: Renderer2, private userService: UserService, private customToast: CustomToastService, public policyService: PolicyService  ) {

    let sessionData: string = sessionStorage.getItem('leavePlannerParams')
    const parsedParams: any = sessionData ? JSON.parse(sessionData) : null
    this.queryParams = parsedParams ? parsedParams : this.queryParams
    this.currentYearAndMonth = {
      currentYear: this.queryParams.year,
      currentMonth: this.queryParams.month,
      currentDate: this.getCurrentDate(),
      yearRange: 1
    }
    this.monthsList = this.dateAndTimeService.getMonthsList(this.currentYearAndMonth)
    this.slideConfig.initialSlide += this.currentYearAndMonth.currentMonth - 1;
    this.lastSelectedMonth = this.monthsList[this.currentYearAndMonth.yearRange * 12 + this.currentYearAndMonth.currentMonth - 1]
    this.days = this.dateAndTimeService.getDays(this.currentYearAndMonth.currentYear, this.currentYearAndMonth.currentMonth)
  }

  ngOnInit() {
    this.filterStateOnRefresh()
    this.setupSearchDebouncer();
    this.route.params.subscribe(params => {
      // Access the query parameters
      this.queryParams.page_number = 1
      this.projectSubscriber = this.projectService.project(params['id'], true).subscribe(project => {
        this.project = project;
        this.notificationService.broadcast(EVENT_TYPES.PROJECT.SHOW, {
          data: {
            project: this.project
          }
        });
        this.queryParams.project_id = this.project.dashboard_project_id
        this.dateAndTimeService.changeQueryParams(this.queryParams)
        this.paramsSubscriber = this.dateAndTimeService.queryParams.subscribe(message => {
          let santizedQuery = this.dateAndTimeService.sanitizeQuery(message)
          if (santizedQuery !== this.lastQueryString) {
            this.isLoading = this.queryParams.page_number > 1 ? false : true
            sessionStorage.setItem('leavePlannerParams', JSON.stringify(this.queryParams))
            this.lastQueryString = santizedQuery;
            this.response.data = this.queryParams.page_number === 1 ? [] : this.response.data
            this.leavePlannerSubscriber = this.dateAndTimeService.getDevelopersData(this.lastQueryString).subscribe((res) => {
              if (this.queryParams.page_number === 1) {
                this.response = res
                this.skills = Object.keys(this.response.meta.skills)
              } else {
                this.response.data = this.response.data.concat(res.data)
              }
              this.isLoading = false
            });
          }
        });
        if (this.project.dashboard_project_id == null) {
          this.router.navigate(['/projects/' + this.project.id]);
          return;
        }
      }, error => {
        this.isLoading = false
        this.errorService.errorFunction(error);
      });
    });
  }

  filterStateOnRefresh() {
    this.skillType = this.queryParams.skill_filter
    this.selectedFilterCounter += this.skillType.length
    this.sortTypes.forEach((item: any) => {
      if (item.value === this.queryParams.sort_by) {
        item.isChecked = true
        this.selectedFilterCounter += 1
      }
    })
    this.leaveTypes.forEach((item: ILeaveType) => {
      if (this.queryParams.leave_filter.includes(item.value)) {
        item.isChecked = true
        this.selectedFilterCounter += 1
      }
    })
  }

  loopRows(i: number) {
    return new Array(i);
  }

  getCurrentDate() {
    let today = new Date();
    return today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
  }

  public onSearchInputChange(term: string): void {
    this.searchDecouncer$.next(term);
  }

  private setupSearchDebouncer(): void {
    // Subscribe to `searchDecouncer$` values,
    // but pipe through `debounceTime` and `distinctUntilChanged`
    this.searchDecouncer$.pipe(
      debounceTime(400),
      distinctUntilChanged(),
    ).subscribe((term: string) => {
      this.queryParams.search = term
      this.queryParams.page_number = 1
      this.dateAndTimeService.changeQueryParams(this.queryParams)
    });
  }

  bypassSanitization(html: string) {
    return this.domSanitizer.bypassSecurityTrustHtml(html);
  }

  updateMonthAndYear(month) {
    if (!isEmpty(this.lastSelectedMonth)) {
      this.lastSelectedMonth.isActive = false
    }
    month.isActive = true
    this.lastSelectedMonth = month
    this.days = this.dateAndTimeService.getDays(month.year, month.value)
    this.queryParams.month = month.value
    this.queryParams.year = month.year
    this.queryParams.page_number = 1
    this.dateAndTimeService.changeQueryParams(this.queryParams)
  }

  getMoreData() {
    if ((this.response.meta.total_count / this.response.meta.per_page) > this.queryParams.page_number) {
      this.queryParams.page_number += 1
      this.dateAndTimeService.changeQueryParams(this.queryParams)
    }
  }

  openModal(allocationDate) {
    this.currentlyAllocatedData = allocationDate
    this.renderer.addClass(document.body, 'allocation-detail-visible');
    this.allocationDetailVisible = !this.allocationDetailVisible;
  }

  getCorrectDate(date: string) {
    let dateData: Array<any> = date.split('-')
    dateData[1] -= 1
    return dateData
  }

  getDurationDate(startDate, endDate) {
    let splitStartDate = this.getCorrectDate(startDate)
    let splitEndDate = this.getCorrectDate(endDate)
    return `${moment(splitStartDate).format("DD MMM YYYY")} - ${moment(splitEndDate).format("DD MMM YYYY")}`
  }

  startDateCal(item) {
    return moment(this.getCorrectDate(item.start_date)).date() - 1
  }

  endDateCal(item) {
    return moment(this.getCorrectDate(item.end_date)).date() - (moment(this.getCorrectDate(item.start_date)).date() - 1)
  }

  accordionToggle() {
    this.accordionExpanded = !this.accordionExpanded;
  }

  filterToggleClose() {
    if (this.filterVisible) {
      this.filterVisible = false;
    }
  }

  getSortType() {
    return this.sortTypes[0].isChecked ? this.sortTypes[0].value : ''
  }

  getLeaveType() {
    let leaveParam = []
    this.leaveTypes.forEach((item: any) => {
      item.isChecked && leaveParam.push(item.value)
    })
    return leaveParam
  }

  resetFilter() {
    this.sortTypes.forEach((item) => {
      item.isChecked = false
    })
    this.leaveTypes.forEach((item: any) => {
      item.isChecked = false
    })
    this.skillType = []
    this.selectedFilterCounter = 0
    this.queryParams.sort_by = this.getSortType()
    this.queryParams.leave_filter = this.getLeaveType()
    this.queryParams.skill_filter = this.skillType
    this.queryParams.page_number = 1
    this.dateAndTimeService.changeQueryParams(this.queryParams)
  }

  toggleAllocationDetail() {
    this.renderer.removeClass(document.body, 'allocation-detail-visible');
    this.allocationDetailVisible = !this.allocationDetailVisible;
  }

  toggleFeatureDescPopup() {
    this.renderer.addClass(document.body, 'epicPopupView');
    this.featureDescPopupVisible = !this.featureDescPopupVisible;

    this.renderer.removeClass(document.body, 'allocation-detail-visible');
    this.allocationDetailVisible = false;
  }

  toggleFeatureDescPopupClose() {
    this.renderer.removeClass(document.body, 'epicPopupView');
    this.featureDescPopupVisible = !this.featureDescPopupVisible;

    this.renderer.addClass(document.body, 'allocation-detail-visible');
    this.allocationDetailVisible = true;
  }

  toggleCheck(filterType, val?: any) {
    switch (filterType) {
      case 'sort_by':
        this.selectedFilterCounter = val.isChecked ? this.selectedFilterCounter - 1 : this.selectedFilterCounter + 1
        val.isChecked = !val.isChecked
        this.queryParams.sort_by = this.sortTypes[0].isChecked ? this.sortTypes[0].value : ''
        break;
      case 'leave_filter':
        this.selectedFilterCounter = val.isChecked ? this.selectedFilterCounter - 1 : this.selectedFilterCounter + 1
        val.isChecked = !val.isChecked
        this.queryParams.leave_filter = this.getLeaveType()
        break;
      case 'skill_filter':
        let index = this.skillType.indexOf(val)
        if (index >= 0) {
          this.selectedFilterCounter -= 1
          this.skillType.splice(index, 1)
        } else {
          this.selectedFilterCounter += 1
          this.skillType.push(val)
        }
        this.queryParams.skill_filter = this.skillType
        break;
    }
    this.queryParams.page_number = 1
    this.dateAndTimeService.changeQueryParams(this.queryParams)
  }

  getWidth(timeline) {
    return `calc(${(100 / this.days.length) * this.endDateCal(timeline) + '%'} - 2px)`
  }

  getAllocationClasses(timeline, userTimelineCount, currentIndex) {
    return {
      'deallocated': timeline.allocation_status === 'deallocated',
      'allocated': timeline.allocation_status === 'accepted',
      'pending': timeline.allocation_status === 'pending',
      'top-pos': userTimelineCount > 1 && currentIndex === 0,
      'mini-strip': this.endDateCal(timeline) <= 2
    }
  }

  pingAPICall(developer) {
    this.currentPingSelected = developer.id
    let currentUser: User = this.userService.getUser();
    this.nudgeResponseIsLoading = true
    let nudgeData: INudgeParams = {
      developer_email: developer.email,
      project_id: this.project.id,
      nudged_by_user_id: currentUser.id,
      nudged_by_user_email: currentUser.email,
      nudged_by_username: currentUser.name,
      nudged_from: "Tracker",
      // urgent: false
    }
    this.dateAndTimeService.postNudgeExpert(nudgeData).subscribe((res) => {
      this.nudgeResponseIsLoading = false
      this.currentPingSelected = -1
      this.customToast.messages.push({
        id: 'story_new',
        type: 'success',
        class: 'story_new',
        title: 'Nudge Sent',
        message: res.message
      });
    }, error => {
      this.errorService.errorFunction(error.error)
      this.currentPingSelected = -1
      this.nudgeResponseIsLoading = false
    });
  }

  pingAccess(){
    return (this.projectService && this.projectService.current_user_pod_role && this.policyService.projectAdmin(this.project))
  }

  ngOnDestroy(): void {
    this.projectSubscriber.unsubscribe()
    this.leavePlannerSubscriber.unsubscribe()
    this.paramsSubscriber.unsubscribe()
  }
}
