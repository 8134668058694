import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Notification } from '../models/notification';
import { API_ROUTES } from '../apiRoutes';
import { DataService } from './data.service';
import { HttpRequests } from './http-requests.service';

@Injectable()
export class NotificationsService {
  constructor(
    private httpRequest: HttpRequests,
    private dataService: DataService) {}

  getNotifications(query = {}) {
    const API = this.dataService.apiUrl + API_ROUTES.NOTIFICATIONS;
    const url = this.dataService.parseQueryParams(API, query);

    return this.httpRequest
      .get(url, this.dataService.getRequestOptionArgs()).pipe(
        map(response => {
          const data: any = this.parseResponse(response);
          data.notifications = data.notifications.map(notificationData => {
            return new Notification(notificationData);
        });
        return data;
      }));
  }

  markAsRead(query = {}) {
    const API = this.dataService.apiUrl + API_ROUTES.NOTIFICATIONS_MARK_AS_READ;
    return this.httpRequest
      .put(API, query, this.dataService.getRequestOptionArgs()).pipe(
      map(response => this.parseResponse(response)));
  }

  public parseResponse(res: any): any {
    return res;
  }
}
