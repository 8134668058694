import {Component, OnInit} from '@angular/core';
import { Project } from '../shared/models/project';
import { PolicyService } from '../shared/services/policy.service';
import { ProjectService } from '../shared/services/project.service';
import { NotificationService } from '../shared/services/notification.service';
import { ErrorService } from '../shared/errorFunction';
import { EVENT_TYPES} from '../shared/dataTypes';
import {ActivatedRoute, Params, Router} from '@angular/router';
import { SharedService } from '../shared/services/shared.service';
import {DomSanitizer} from '@angular/platform-browser';
import { Projectdocument } from '../shared/models/projectdocument';
import moment from 'moment';

@Component({
  selector: 'app-project-brief',
  templateUrl: './project-brief.component.html',
  styleUrls: ['./project-brief.component.scss']
})
export class ProjectBriefComponent implements OnInit {
  projectId;
  project: Project;
  briefHeading: string = '';
  slideConfig = {
    'slidesToShow': 4,
    'slidesToScroll': 1,
    'infinite': false,
    'variableWidth': true
  };
  openBrandGuidlines:boolean = false;
  briefLoader:boolean = false;
  openAuthor: boolean = false;
  openItemDoc: string = '';
  showVideo:boolean = false;
  vURL = '';
  
  projectDocumentFile = [];
  projectDocuments: Array<Projectdocument> = [];
  selectedFolderName: any = '';

  constructor(private projectService: ProjectService,
              private errorService: ErrorService,
              public sharedService: SharedService,
              private policyService: PolicyService,
              private notificationService: NotificationService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private domSanitizer: DomSanitizer) { }

  ngOnInit() {
    this.briefLoader = true;
    this.activatedRoute.params.subscribe((params: Params) => {
      this.projectId = +params['id'];
    });

    this.projectService.project(this.projectId, true).subscribe(project => {
      this.project = project;
      this.notificationService.broadcast(EVENT_TYPES.PROJECT.SHOW, {
        data: {
          project: this.project
        }
      });
      if (this.project.dashboard_project_id == null) {
        this.router.navigate(['/projects/' + this.project.id]);
        return;
      }
      this.projectService.projectBriefDocument(this.project).subscribe(projectDoc => {
        projectDoc.folders.forEach( item => {
          this.projectDocuments.push(item);
        });
        this.projectDocumentFile = this.projectDocuments[0].folder_files;
        // const topFolderName = this.projectDocuments[0].name;
        this.selectedFolderName = this.projectDocuments[0].name;
      }, err => this.onErrorBrief(err));

      this.projectService.projectPhasesNBrief(this.project)
        .subscribe(res => this.onSuccessBrief(res), err => this.onErrorBrief(err));
    }, error => this.onErrorGetProject(error));
  }

  onSuccessBrief(res) {
    this.project.project_brief = res.data.project_brief;
    this.project.project_brief_attachments = res.data.project_brief_attachments;
    this.project.landing_url = res.data.landing_url;
    if (res.data.builder_now_url) {
      this.project.builder_now_instant_URL = res.data.builder_now_url.instant_url;
      this.project.builder_now_custom_URL = res.data.builder_now_url.custom_url;
    }
    if (res.data.branding) {
      this.project.branding_id = res.data.branding.id;
      this.project.branding_logo_attachments = res.data.branding.logo_attachments;
      this.project.branding_font_attachments = res.data.branding.font_attachments;
      this.project.branding_colour_attachments = res.data.branding.colour_attachments;
      this.project.branding_icon_attachments = res.data.branding.icon_attachments;
      this.project.branding_illustration_attachments = res.data.branding.illustration_attachments;
      this.project.branding_other_attachments = res.data.branding.other_attachments;
      this.project.branding_now_pid = res.data.branding.branding_now_pid;
      this.project.branding_project_id = res.data.branding.project_id;
      this.project.branding_created_at = res.data.branding.created_at;
      this.project.branding_updated_at = res.data.branding.updated_at;
    }

    const isProjectBriefAvailable = (this.project.landing_url &&
      (this.project.project_brief || this.project.project_brief_attachments.length > 0)) ? true : false;
    const isDesignBriefAvailable = this.project.branding_id ? true : false;

    if (isProjectBriefAvailable && isDesignBriefAvailable) {
      this.briefHeading = 'Buildcard & Design Brief';
    } else if (isProjectBriefAvailable && !isDesignBriefAvailable) {
      this.briefHeading = 'Buildcard Brief';
    } else if (!isProjectBriefAvailable && isDesignBriefAvailable) {
      this.briefHeading = 'Design Brief';
    } else {
      this.briefHeading = 'Brief is not available';
    }
    this.briefLoader = false;
  }

  onErrorBrief(err) {
    this.project.landing_url = null;
    this.project.builder_now_instant_URL = null;
    this.project.builder_now_custom_URL = null;
    this.briefHeading = 'Brief is not available';
    this.briefLoader = false;
  }

  onErrorGetProject(err) {
    this.errorService.errorFunction(err);
    this.briefHeading = 'Brief is not available';
    this.briefLoader = false;
  }

  get isProjectAdmin(): boolean {
    return this.policyService.isProjectAdmin(this.project);
  }

  openAuthorPopup(url) {
    if (this.sharedService.checkType(url, true) == 'image') {
      this.openItemDoc = url;
      this.openAuthor = true;
    }
  }

  closeAuthorPopup() {
    this.openAuthor = false;
  }

  showBrandGuidline() {
    this.openBrandGuidlines = true;
  }

  closeBrandGuidline() {
    this.openBrandGuidlines = false;
  }

  openVideoPopup(slide, isBranding?) {
    this.showVideo = true;
    this.vURL = isBranding ? slide : slide.url;
  }

  closeVideoPopup() {
    this.showVideo = false;
    this.vURL = '';
  }
  formatDate(date: any) {
    return moment(date).format('DD-MMM-YYYY');
  }
  selectFolder(pdoc) {
    this.selectedFolderName = '';
    this.projectDocumentFile = [];
    pdoc.card_selected = !pdoc.card_selected;
    this.projectDocumentFile = pdoc.folder_files
    this.selectedFolderName = pdoc.name;
  }
  openDocument(doc) {
    if (doc) {
      window.open(doc, '_blank');
    }
  }
}
