import {Component, Input, HostBinding, ChangeDetectorRef, OnInit, OnDestroy, SimpleChanges, OnChanges} from '@angular/core';
import {
  StoryService,
} from '../../services/story.service';
import { Epic } from '../../models/epic';
import { QueryParamsService } from '../../services/queryParams.service';
import { PolicyService } from '../../services/policy.service';
import { UserService } from '../../services/user.service';
import { NotificationService } from '../../services/notification.service';
import { ProjectService } from '../../services/project.service';
import {Story} from '../../models/story';
import {Subscription} from 'rxjs';
import moment from 'moment';
import { timer as timerFunction} from 'rxjs';
import { EVENT_TYPES, DataManipulationOptions,BB_STATUS } from '../../dataTypes';
import { SharedService } from '../../services/shared.service';
import {ErrorService} from '../../errorFunction';

@Component({
  selector: 'story-timer',
  templateUrl: 'story-timer.component.html',
  styleUrls: ['story-timer.component.scss']

})
export class StoryTimerComponent implements OnInit, OnDestroy, OnChanges {
  @Input('story') story: Story;
  @Input('type') type: string;
  @Input('validateTimer') validateTimer: boolean = true;
  storyProgress = {title: '', completedProgress: 0};
  stopPreviousStage: boolean;
  startPreviousStage: boolean;
  is_child_story_active = false;
  timer: any;
  reviewer_timer: any;
  timerForm: any;
  total_timer: any;
  ownerElapsed: string;
  reviewerElapsed: string;
  reviewer_subscription: Subscription;
  owner_subscription: Subscription;
  total_subscription: Subscription;
  total_elapsed_timer: String;
  total_story_time;
  owner_runningTime_subscription: Subscription;
  @Input('project') project;
  enterStoryProgress:boolean = false;
  timerLoader:boolean = false;
  automaticStopTimer = false;
  private notificationSubscriptions: Subscription[] = [];

  constructor(private projectService: ProjectService,
              private notificationService: NotificationService,
              private userService: UserService,
              public sharedService: SharedService,
              private errorService: ErrorService,
              private cdr: ChangeDetectorRef,
              private storyService: StoryService,
              private queryParams: QueryParamsService,
              private policyService: PolicyService) {
  }

  ngOnInit() {
    this.resetForm();
    this.initialProgress(this.story);
    if (this.type === 'owner') {
      this.startOwner();
    } else if (this.type === 'reviewer') {
      this.startReviewer();
    } else if (this.type === 'total') {
      this.startTotal();
    }

    const timerStart = this.notificationService.subscribeEvent(EVENT_TYPES.SESSION.TIMER_START, res => {
      if (res && res.payload && res.payload.data && res.payload.data.story && this.story) {
        if (res.payload.data.story.id === this.story.id || (res.payload.data.story.changes && res.payload.data.story.changes.id === this.story.id)) {
          // If changes present, please update the story object
          const response = res.payload.data.story;
          if (response.changes && Object.keys(response.changes).length !== 0) {
            for (let key in response.changes) {
              this.story[key] = response.changes[key];
            }
          } else {
            this.story = response;
          }

          // Check timers to start again or stop the timer
          if (this.type === 'owner') {
            this.initialProgress(this.story);
            this.startOwner();
          } else if (this.type === 'reviewer') {
            this.startReviewer();
          } else if (this.type === 'total') {
            this.startTotal();
          }
        }
      }
    });
    this.notificationSubscriptions.push(timerStart);

    const closeExpand = this.notificationService.subscribeEvent(EVENT_TYPES.STORY.CLOSE_EXPAND, res => {
      if (res && res.payload && res.payload.data && res.payload.data.story && this.story &&
        res.payload.data.story.id === this.story.id) {
        if (this.type === 'owner') {
          this.unsubscribeOwnerSubscription();
        } else if (this.type === 'reviewer') {
          this.unsubscribeReviewerSubscription();
        } else if (this.type === 'total' && !this.validateTimer) {
          this.unsubscribeTotalSubscription();
        }
      }
    });
    this.notificationSubscriptions.push(closeExpand);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['story'] && this.projectService.show_Story) {
      if (this.type === 'owner') {
        this.startOwner();
      } else if (this.type === 'reviewer') {
        this.startReviewer();
      } else if (this.type === 'total') {
        this.startTotal();
      }
    }
  }

  timerExceeded(type: string): boolean {
    let exceeded = false;
    if (type === 'owner' && this.story.timer_elapsed_total) {
      exceeded = this.story.timer_elapsed_total >= this.story.estimate * 3600; 
    } else if (type === 'reviewer' && this.story.reviewer_elapsed_time_total) {
      exceeded = this.story.reviewer_elapsed_time_total >= this.story.reviewer_estimate * 3600;
    }
    return exceeded;
  }

  initialProgress(story) {
    if (story) {
      this.storyProgress.title = story.title;
      this.storyProgress.completedProgress = story.progress;
    }
  }

  unsubscribeOwnerSubscription() {
    if (this.owner_subscription) {
      this.owner_subscription.unsubscribe();
    }
  }

  startOwner() {
    this.automaticStopTimer = false;
    this.unsubscribeOwnerSubscription();
    if ((this.story.timer_elapsed_total) && !(this.story.current_timer_activity)) {
      this.ownerElapsed = this.storyTrackedTime(this.trackedStory);
    }
    if (this.story.current_timer_activity) {
      let timer = timerFunction(1000, 1000);
      this.owner_subscription = timer.subscribe(t => {
        if (this.trackedStory) {
          this.timer = this.storyTrackedTime(this.trackedStory);
          this.validateTimerFor('owner');
        }
      });
    }
  }

  unsubscribeReviewerSubscription() {
    if (this.reviewer_subscription) {
      this.reviewer_subscription.unsubscribe();
    }
  }

  startReviewer() {
    this.automaticStopTimer = false;
    this.unsubscribeReviewerSubscription();
    if ((this.story.reviewer_elapsed_time_total) && !(this.story.current_reviewer_timer_activity)) {
      this.reviewerElapsed = this.storyReviewerTrackedTime(this.trackedStory);
    }
    if (this.story.current_reviewer_timer_activity) {
      let atimer = timerFunction(1000, 1000);
      this.reviewer_subscription = atimer.subscribe(t => {
        if (this.trackedStory) {
          this.reviewer_timer = this.storyReviewerTrackedTime(this.trackedStory);
          this.validateTimerFor('reviewer');
        }
      });
    }
  }

  unsubscribeTotalSubscription() {
    if (this.total_subscription) {
      this.total_subscription.unsubscribe();
    }
  }

  startTotal() {
    this.automaticStopTimer = false;
    this.unsubscribeTotalSubscription();
    this.total_story_time = this.secsToDuration(this.calculate_total_time(this.trackedStory));
    if (this.story.current_reviewer_timer_activity || this.story.current_timer_activity) {
      let btimer = timerFunction(1000, 1000);
      this.total_subscription = btimer.subscribe(t => {
        if (this.trackedStory) {
          this.total_timer = this.totalStoryTrackedTime(this.trackedStory);
          if (this.validateTimer) {
            if (this.story.current_timer_activity) {
              this.validateTimerFor('owner');
            } else if (this.story.current_reviewer_timer_activity) {
              this.validateTimerFor('reviewer');
            }
          }
        }
      });
    }
    if ((this.story.timer_elapsed_total || this.story.reviewer_elapsed_time_total) &&
        !(this.story.current_timer_activity || this.story.current_reviewer_timer_activity)) {
      this.total_elapsed_timer = this.totalStoryTrackedTime(this.trackedStory);
    }
  }

  validateTimerFor(type: string) {
    if (type === 'owner') {
      if (!this.automaticStopTimer && this.storyTrackedNumberTime(this.trackedStory) >= (this.trackedStory.estimate * 3600)) {
        this.automaticStopTimer = true;
        if (this.type === 'owner') {
          this.unsubscribeOwnerSubscription();
        } else if (this.type === 'total') {
          this.unsubscribeTotalSubscription();
        }
        if (this.story.current_timer_activity) {
          if (this.story.owned_by_id === this.userService.getUser().id || this.canTrackStory()) {
            if (this.type === 'owner' || (!this.projectService.show_Story || this.projectService.show_Story.id !== this.trackedStory.id)) {
              this.automaticStopTime('owner');
            }
          }
        }
      }
    } else if (type === 'reviewer') {
      if (!this.automaticStopTimer && this.storyReviewTrackedTimeNumber(this.trackedStory) >= (this.trackedStory.reviewer_estimate * 3600)) {
        this.automaticStopTimer = true;
        if (this.type === 'reviewer') {
          this.unsubscribeReviewerSubscription();
        } else if (this.type === 'total') {
          this.unsubscribeTotalSubscription();
        }
        if (this.story.current_reviewer_timer_activity) {
          if (this.story.reviewer_id === this.userService.getUser().id || this.canTrackStory()) {
            if (this.type === 'reviewer' || (!this.projectService.show_Story || this.projectService.show_Story.id !== this.trackedStory.id)) {
              this.automaticStopTime('reviewer');
            }
          }
        }
      }
    }
  }

  automaticStopTime(type: string) {
    if (type === 'owner') {
      this.timerLoader = true;
      let trackedStory: Story = this.currentActiveStory ? this.currentActiveStory : this.trackedStory;
      this.storyService.stopTimer(this.project, trackedStory, true).subscribe((story) => {
        this.notifyTimer(story);
        this.timerLoader = false;
        this.automaticStopTimer = false;
      }, error => {
        this.timerLoader = false;
        this.errorService.errorFunction(error);
      });
    } else if (type === 'reviewer') {
      this.timerLoader = true;
      this.storyService.stopReviewerTimer(this.project, this.reviewertrackedStory, true).subscribe((story) => {
          this.notifyTimer(story);
          this.timerLoader = false;
          this.automaticStopTimer = false;
        }, error => {
          this.timerLoader = false;
          this.errorService.errorFunction(error);
        });
    }
  }

  canTrackStory() {
    return this.policyService.isProjectAdmin(this.project);
  }

  resetForm() {
    this.timerForm = { story_id: 0, description: '' };
    this.automaticStopTimer = false;
  }

  // Timer is available only when current project
  // and current user is present
  @HostBinding('class.available')
  get isTimerAvailable(): boolean {
    return !!(this.projectService.currentProject
      && this.userService.getUser());
  }

  // Component story or selected story
  get selectedStory(): Story {
    if (this.story) {
      return this.story;
    }
  }

  // Story worked on by current user
  get activeStory(): Story {
    return this.projectService.currentProject.stories.find(story => {
      return !!(story.owned_by_id === this.userService.getUser().id
        && story.current_timer_activity);
    });
  }

  // Story worked on by current user
  get activeReviewerStory(): Story {
    return this.projectService.currentProject.stories.find(story => {
      return !!(story.reviewer_id === this.userService.getUser().id
        && story.current_reviewer_timer_activity);
    });
  }

  // Story to display timer for
  get trackedStory(): Story {
    return this.story || this.activeStory;
  }

  // Story to display timer for
  get reviewertrackedStory(): Story {
    return this.story || this.activeReviewerStory;
  }
  // Convert seconds to time duration
  secsToDuration(secs: number) {
    const duration = moment.duration(secs, 'seconds');
    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    const pad = (number) => { return number < 10 ? '0' + number : number }
    return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
  }

  // Format time spent on story including active timer
  storyTrackedTime(story: Story) {
    return this.secsToDuration(this.storyTrackedNumberTime(story));
  }

  storyTrackedNumberTime(story: Story) {
    let elapsedTotal = 0;
    let currentElapsed = 0;
    if (story.timer_elapsed_total) {
      elapsedTotal = story.timer_elapsed_total;
    }
    if (story.current_timer_activity) {
      const startedAt = story.current_timer_activity.started_at;
      currentElapsed = moment().diff(moment(startedAt).local(), 'seconds');
    }
    return Math.ceil(elapsedTotal + currentElapsed);
  }

  calculate_total_time(story: Story) {
    let total_time = 0;
    if (story.estimate) {
      total_time = total_time + story.estimate;
    }
    if (story.reviewer_estimate) {
      total_time = total_time + story.reviewer_estimate;
    }
    return total_time * 3600;
  }

  totalStoryTrackedTime(story: Story) {
    const total_tracked_time = this.storyTrackedNumberTime(story) + this.storyReviewTrackedTimeNumber(story);
    const total = this.calculate_total_time(story);
    if (total - total_tracked_time < 0) {
      story.time_completed = 101;
      return '-' + this.secsToDuration(total_tracked_time - total);
    } else {
      story.time_completed = total_tracked_time / total * 100;
      return this.secsToDuration(total - total_tracked_time);
    }
  }

  storyReviewerTrackedTime(story: Story) {
    return this.secsToDuration(this.storyReviewTrackedTimeNumber(story));
  }

  storyReviewTrackedTimeNumber(story) {
    let elapsedTotal = 0;
    let currentElapsed = 0;
    if (story.reviewer_elapsed_time_total) {
      elapsedTotal = story.reviewer_elapsed_time_total;
    }
    if (story.current_reviewer_timer_activity) {
      const startedAt = story.current_reviewer_timer_activity.started_at;
      currentElapsed = moment().diff(moment(startedAt).local(), 'seconds');
    }
    return Math.ceil(elapsedTotal + currentElapsed);
  }

  isTrackAvailable(): boolean {
    if (this.story.owned_by_change === true || this.story.state_change === true || this.story.type_change === true) {
      return this.startPreviousStage;
    }
    if (this.story) {
      // No timer running and owned by current user
      // w.r.t. owned user array
      let temp = false;
      temp = this.canTrackStory() || (this.story.owned_by_id === this.userService.getUser().id);
      let isNoUserAssign = this.story.owned_by_id && this.story.requested_by_id ? true : false;
      this.startPreviousStage = !this.story.current_timer_activity && temp && !this.story.isReadOnly && this.story.state == 'started' && isNoUserAssign;
      return !this.story.current_timer_activity && temp && !this.story.isReadOnly && this.story.state == 'started' && isNoUserAssign;
    } else {
      return !this.activeStory;
    }
  }

  isTrackReviewerAvailable(): boolean {
    if (this.story) {
      // No timer running and owned by current user
      // w.r.t. owned user array
      let temp = false;
      temp = this.canTrackStory() || (this.story.reviewer_id === this.userService.getUser().id);
      let isNoUserAssign = this.story.reviewer_id && this.story.requested_by_id ? true : false;
      this.startPreviousStage = !this.story.current_timer_activity && temp && !this.story.isReadOnly && isNoUserAssign;
      return !this.story.current_reviewer_timer_activity && temp && !this.story.isReadOnly && isNoUserAssign && !!this.story.reviewer_estimate;
    } else {
      return !this.activeReviewerStory;
    }
  }

  isStopAvailable(): boolean {
    if (this.story.owned_by_change === true || this.story.state_change === true || this.story.type_change === true) {
      return this.stopPreviousStage;
    }
    let temp = false;
    temp = (this.canTrackStory()) || ((this.story.owned_by_id === this.userService.getUser().id));
    this.stopPreviousStage = !!(this.trackedStory && temp && this.trackedStory.current_timer_activity && this.story.state == 'started');
    return !!(this.trackedStory && temp && this.trackedStory.current_timer_activity  && this.story.state == 'started');
  }

  isReviewerStopAvailable(): boolean {
    let temp = false;
    temp = (this.canTrackStory()) || ((this.story.reviewer_id === this.userService.getUser().id));
    this.stopPreviousStage = !!(this.reviewertrackedStory && temp && this.reviewertrackedStory.current_timer_activity);
    return !!(this.reviewertrackedStory && temp && this.reviewertrackedStory.current_reviewer_timer_activity);
  }

  startTracking() {
    this.timerLoader = true;
    this
      .storyService
      .startTimer(
        this.project,
        this.selectedStory
      )
      .subscribe(story => {
        this.notifyTimer(story);
        this.timerLoader = false;
      }, error => {
        this.timerLoader = false;
        this.errorService.errorFunction(error);
      });
  }

  startReviewerTracking() {
    this.timerLoader = true;
    this
      .storyService
      .startReviewerTimer(
        this.project,
        this.selectedStory
      )
      .subscribe(story => {
        this.notifyTimer(story);
        this.timerLoader = false;
      }, error => {
        this.timerLoader = false;
        this.errorService.errorFunction(error);
      });
  }

  isStoryOwnerDifferent(type){
    if(type === 'owner') {
      return this.userService.currentUser.id !== this.story.owned_by_id;
    } else {
      return this.userService.getUser().id !== this.story.reviewer_id;
    }
  }

  currentActiveStory: Story;

  checkForBBStatus(){
    const storyEpic: Epic = this.story.getEpicInStory();
    if (storyEpic && !(this.story.isDesignStory() || this.story.core || this.story.parent_core || this.story.dev_type === 'QA' || !this.project.external())) {
      if(storyEpic.bb_status === BB_STATUS.assigned || storyEpic.bb_status === BB_STATUS.ready_for_certification || storyEpic.bb_status === BB_STATUS.bb_under_development){
        return true;
      }
    }
    return false;
  }

  checkStartForOtherStory(type) {
    if (this.story && (this.story.inDraft() || this.story.deleted_at || this.isStoryOwnerDifferent(type) || this.checkForBBStatus())) {
      this.policyService.readOnlyWarning();
    } else {
      if(type === 'owner' && !this.story.confirmed && (this.story.confirmed_by_id !== this.story.owned_by_id) ) {
        this.storyService.confirmOptions( this.story.story_type, this.story.state, this.policyService.expertAsQA(this.project, false));
        return;
      } else {
        let story: Story;
        if (type === 'owner' ) {
          story = this.sharedService.getActiveStory(this.trackedStory.owned_by_id, this.trackedStory.id);
        } else {
          story = this.sharedService.getActiveStory(this.trackedStory.reviewer_id);
        }
        if (!story) {
          if (type === 'owner') {
            this.startTracking();
          } else {
            this.startReviewerTracking();
          }
        } else {
          this.stopTracking(story);
          this.currentActiveStory = story;
          this.initialProgress(story);
        }
      }
      
    }
  }

  stopTracking(story) {
    this.timerLoader = true;
    let trackedStory: Story = this.currentActiveStory ? this.currentActiveStory : this.trackedStory;
    this.storyService.stopTimer(this.project, trackedStory).subscribe((story) => {
      this.unsubscribeOwnerSubscription();
      this.unsubscribeTotalSubscription();
      if (this.currentActiveStory) {
        if (this.type === 'owner') {
          this.startTracking();
        } else {
          this.startReviewerTracking();
        }
      }
      this.currentActiveStory = null;
      this.notifyTimer(story);
      this.timerLoader = false;
    }, error => {
      this.timerLoader = false;
      this.errorService.errorFunction(error);
    });
  }

  // storyProgressFromStory(trackedStory) {
  //   if(trackedStory) {
  //     this.storyProgress.completedProgress = trackedStory.progress;
  //     // this.storyProgress.completeIndex = trackedStory.progress / 10;
  //     this.storyProgress.title = trackedStory.title;
  //   }
  // }

  getStory(storyId) {
    return this.project.stories.filter((story) => {
      return story.id === storyId;
    })[0];
  }

  updateStory(trackedStory: Story) {
    this.storyService.updateStory(trackedStory, true).subscribe((data) => {
      const payload = {
        data: {
         story: trackedStory
        }
      };
      this.notificationService.broadcast(EVENT_TYPES.STORY.PROGRESS, payload);
    });
  }

  stopReviewerTracking() {
    this.timerLoader = true;
    this
      .storyService
      .stopReviewerTimer(this.project, this.reviewertrackedStory)
      .subscribe((story) => {
        this.unsubscribeReviewerSubscription();
        this.unsubscribeTotalSubscription();
        this.notifyTimer(story);
        this.timerLoader = false;
      }, error => {
        this.timerLoader = false;
        this.errorService.errorFunction(error);
      });
  }

  notifyTimer(story) {
    const payload = {
      data: {
        story: story
      }
    };
    this.notificationService.broadcast(EVENT_TYPES.SESSION.TIMER_START, payload);
  }

  ngOnDestroy() {
    this.story = null;
    this.unsubscribeOwnerSubscription();
    this.unsubscribeReviewerSubscription();
    this.unsubscribeTotalSubscription();
    this.notificationSubscriptions.forEach(subscription => subscription.unsubscribe());
  }
  closeStoryProgressPopup() {
    this.enterStoryProgress = false;
  }


  get dataManipulationOptions(): DataManipulationOptions {
    return {
      notificationService: this.notificationService,
      currentUser: this.userService.getUser()
    };
  }
}
