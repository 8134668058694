import { BaseModel } from './baseModel';
import { ReleaseFeatureNotesData, ReleaseDetailsData} from '../dataTypes';
import moment from 'moment';

export class ReleaseDetailModel extends BaseModel implements ReleaseDetailsData{
  id?: number;
  release_id?: number;
  user_id?: number;
  body?: string;
  known_issues?: string;
  credentials?: string;
  coming_soon?: string;
  documents_attributes?: Array<any>;
  attachments?: [{
    id?: number,
    name?: string,
    remote_url?: string,
    content_type?: string,
  }];
  feature_notes?: Array<ReleaseFeatureNotesData>;

  constructor(data: ReleaseDetailsData) {
    super(data);
    this.id = data.id ? data.id : null;
    this.release_id = data.release_id;
    this.user_id = data.user_id;
    this.body = data.body ? data.body : '';
    this.known_issues = data.known_issues;
    this.credentials = data.credentials;
    this.coming_soon = data.coming_soon;
    this.attachments = data.attachments;
    if(data.feature_notes && data.feature_notes.length > 0) {
      this.feature_notes = [];
      data.feature_notes.forEach((feature_note) => {
        feature_note.documents_attributes = [];
        feature_note.for_removal = [];
        this.feature_notes.push(feature_note);
      })
    } else {
      this.feature_notes = [];
    }
    if(this.id) {
      this.documents_attributes = data.attachments && data.attachments.length > 0 ? data.attachments : [];
    } else {
      this.documents_attributes = data.documents_attributes && data.documents_attributes.length > 0 ? data.documents_attributes : [];
    }
    
  }

  getClassName() {
    return "ReleaseDetail";
  }
} 