import { Component, OnInit, OnChanges, Input, ViewChild, ElementRef, HostListener, AfterViewInit } from '@angular/core';
import { Project } from '../../shared/models/project';
import {
  EVENT_TYPES,
  RELEASE_STATUS_LIST,
  DraggableData,
  PROJECT_COLUMN_DATA,
  PROJECT_COLUMN_IDENTIFIER,
  PROJECT_COLUMN,
  STORY_STATE_OPTIONS,
  BB_STATUS_OPTIONS,
  STORY_STATE, STORY_TYPE_OPTIONS,STORY_SUB_TYPE_OPTIONS,DEV_TYPE_OPTIONS, STORY_TYPE, NOT_APPLIED_REVIEWER, STORY_PRIORITY_OPTIONS, BB_STATUS, RELEASE_RATE_LIST
} from '../../shared/dataTypes';
import { User } from '../../shared/models/user';
import { Story } from '../../shared/models/story';
import { Epic } from '../../shared/models/epic';
import { API_ROUTES } from '../../shared/apiRoutes';
import { ProjectColumn } from '../../shared/models/column';
import { map } from 'lodash';
import 'rxjs/add/operator/first';
import 'rxjs/add/operator/pairwise';
import * as _ from 'lodash';
import { filter } from 'rxjs/operators';
import moment from 'moment';
import { ProjectService } from '../../shared/services/project.service';
import { ErrorService } from '../../shared/errorFunction';
import { UserService } from '../../shared/services/user.service';
import { SharedService } from '../../shared/services/shared.service';
import { NotificationService } from '../../shared/services/notification.service';
import { FilterStoryService } from '../../shared/services/filter-story.service';
import {Sprint} from "../../shared/models/sprint";
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-story-filter',
  templateUrl: './story-filter.component.html',
  styleUrls: ['./story-filter.component.scss']
})
export class StoryFilterComponent implements OnInit {
  @Input() project: Project;
  @Input() currentUser;
  statusOptionList = [];
  projectColumns: Array<ProjectColumn> = PROJECT_COLUMN_DATA;
  storyTypeOptions: Array<any> = STORY_TYPE_OPTIONS;
  storySubTypeOptions: Array<any> = STORY_SUB_TYPE_OPTIONS;
  storyPriorityOptions: Array<any> = STORY_PRIORITY_OPTIONS;
  storyStateOptions: Array<any> = STORY_STATE_OPTIONS;
  BBStatusOptions: Array<any> = BB_STATUS_OPTIONS;
  devTypeOptions: Array<any> = DEV_TYPE_OPTIONS;
  startApplyFilterLoader: boolean = false;
  filtersCount:number = 0;
  showSelectedFilterType: String = '';
  searchLabelForFilter: String = '';
  searchFeatureForFilter: String = '';
  searchMemberForFilter: String = '';
  sprintsMeta: {
    current_page: number,
    next_page: number,
    per_page: number,
    total_count: number
  };
  sprintSubscription:  Subscription;
  sprints: Array<Sprint> = [];
  params = {
    q: '',
    page: 1,
    filter: true,
    per_page: 200
  };
  paramsString: String = JSON.stringify({
    q: '',
    page: 1,
    filter: true,
    per_page: 200
  })
  alwaysShowCalendars: boolean;
  ranges: any = {
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'Last 3 Month': [moment().subtract(3, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'Last 6 Month': [moment().subtract(6, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'Last 12 Month': [moment().subtract(12, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  };
  private notificationSubscriptions: Subscription[] = [];
  release_rating_list: Array<any> = [];

  constructor(
    public projectService: ProjectService,
    public filterStoryService: FilterStoryService,
    private errorService: ErrorService,
    private userService: UserService,
    private sharedService: SharedService,
    private notificationService: NotificationService) { }

  ngOnInit() {
    this.sprints = this.filterStoryService.sprintFilterList
    this.filtersCount = (this.project && this.project.filterCount) ? this.project.filterCount : 0;
    // below is for to show the default opened filter section
    this.showSelectedFilterType = this.projectService.initiate_from === 'from_release' ? 'statuses' : 'phase';
    if(this.projectService.initiate_from === 'from_release') {
      this.release_rating_list = RELEASE_RATE_LIST;
      this.statusFilterOptions();
    }
    const sprintAddedEvent = this.notificationService.subscribeEvent(EVENT_TYPES.SPRINT.SPRINT_CREATED, res => {
      this.sprints.unshift(new Sprint(res.payload.sprint));
    });
    this.notificationSubscriptions.push(sprintAddedEvent);

    const sprintDeletedEvent = this.notificationService.subscribeEvent(EVENT_TYPES.SPRINT.SPRINT_DELETED, res => {
      if(res) {
        const sprint_Index = this.sprints.findIndex(st_sprint_index => st_sprint_index.id === res.payload.sprint.id);
        if(sprint_Index !== -1) {
          this.sprints.splice(sprint_Index, 1);
        }
      }
    });
    this.notificationSubscriptions.push(sprintDeletedEvent);
    
    const sprintUpdatedEvent = this.notificationService.subscribeEvent(EVENT_TYPES.SPRINT.SPRINT_UPDATED, res => {
      const sprint_Index = this.sprints.findIndex(st_sprint_index => st_sprint_index.id === res.payload.sprint.id);
      this.sprints[sprint_Index] = new Sprint(res.payload.sprint);
    }); 
    this.notificationSubscriptions.push(sprintUpdatedEvent);
  }

  statusFilterOptions() {
    this.statusOptionList = [];
    // Flatten the nested arrays using Object.keys() and map()
    const flattenedList = [].concat(...Object.keys(RELEASE_STATUS_LIST).map(key => RELEASE_STATUS_LIST[key]));
    // Filter out duplicates based on the 'value' attribute
    const seenValues = new Set();
    this.statusOptionList = flattenedList.filter(item => {
      if (!seenValues.has(item.value)) {
        seenValues.add(item.value);
        return true;
      }
      return false;
    });
  }

  ngOnDestroy() {
    this.notificationSubscriptions.forEach(subscription => subscription.unsubscribe());
    this.clearSprintSubscription();
  }

  clearSprintSubscription(){
    if (this.sprintSubscription) {
      this.sprintSubscription.unsubscribe();      
    }
  }

  getEpics() {
    return Object.assign([], this.project.epics);
  }

  isFilterChecked(key, value) {
    return this.project.findFilterIndex(key, value) >= 0 ? true : false;
  }

  clearDataForApplyFilter() {
    this.projectService.showHtml = false;
    if (this.project) {
      this.project.stories = [];
    }
    PROJECT_COLUMN_DATA.forEach(col => {
      col.stories = [];
      col.allSelected = false;
      col.selectedStoryCount = 0;
      col.current_page = 0;
      col.per_page_count = 0;
      col.total_count = 0;
      col.total_pages = 0;
    });
  }

  public datesUpdated(event,type) {
    if (event.startDate && event.endDate) {
      const start_date = moment(event.startDate).format('DD/MM/YYYY');
      const end_date = moment(event.endDate).format('DD/MM/YYYY');
      if (type === 'status_updated') {
        this.filterStoryService.applyFilter(this.project, null, 'state_changed_at_date_range', start_date + ',' + end_date); 
      } else if (type === 'date_created') {
        this.filterStoryService.applyFilter(this.project, null, 'created_on_date_range', start_date + ',' + end_date);
      } else if (type === 'ship_release_date_range') {
        this.filterStoryService.applyFilter(this.project, null, 'ship_release_date_range', start_date + ',' + end_date);
      }
    }
  }

  clearDate(e,type) {
    if (type === 'status_updated') {
      if (this.project.filters.state_changed_at_date_range.length > 0) {
        this.filterStoryService.applyFilter(this.project,null, 'state_changed_at_date_range', null);
      }
      this.project.selectedUpdateDate = null;
    } else if (type === 'date_created') {
      if (this.project.filters.created_on_date_range.length > 0) {
        this.filterStoryService.applyFilter(this.project,null, 'created_on_date_range', null);
      }
      this.project.selectedDate = null; 
    } else if (type === 'ship_release_date_range') {
      if (this.project.filters.ship_release_date_range.length > 0) {
        this.filterStoryService.applyFilter(this.project,null, 'ship_release_date_range', null);
      }
      this.project.selectedUpdateDate = null;
    }
  }

  getSprints(replace?:boolean){
    this.showSelectedFilterType='sprints';
    this.clearSprintSubscription();
    if ((this.paramsString === JSON.stringify(this.params)) && !_.isEmpty(this.sprints)) {
      return
    }
    this.sprintSubscription = this.projectService.getProjectSprints(this.project.id, this.params).subscribe(res => {
        if (replace) {
          this.sprints = [];
          this.params.page = 1;
        }
        res.data.forEach((sprintData) => {
          if (!this.sprints.find(sprint => sprint.id === sprintData.id)) {
            this.sprints.push(new Sprint(sprintData));
          }
        });
        this.sprintsMeta = res.meta;
    }, err => this.errorService.errorFunction(err));
  }
  
  getOlder() {
    if (this.sprintsMeta && this.sprintsMeta.next_page) {
      this.params.page = this.sprintsMeta.next_page;
      this.getSprints();
    }
  }

  isScrollFetchAvailable(): boolean {
    return this.sprintsMeta && this.sprintsMeta.next_page != null;
  }

  getSelectedFilterTypeW(type) {
    if (this.project.filters.created_on_date_range.length === 0) {
      this.project.selectedDate = null;
    }

    if (this.project.filters.state_changed_at_date_range.length === 0) {
      this.project.selectedDate = null;
    }

    if (this.project.filters.ship_release_date_range.length === 0) {
      this.project.selectedUpdateDate = null;
    }
    this.showSelectedFilterType = type;
  }

  applyFilterFeature(res) {
    this.filterStoryService.applyFilter(this.project, null, res.data.key, res.data.value);
  }

  disableBBstatusTitle(bbstatus){
    if (this.project.epics) {
      const index = this.project.epics.findIndex(ftr => ftr.bb_status === bbstatus)
        if (index == -1) {
          return true;
        }
    }
  }

  clearAllFilters() {
    if (!this.projectService.startApplyFilterLoader) {
      this.projectService.startApplyFilterLoader = true;
      this.projectService.closeSearch();
      this.project.clearFilter();
      this.filtersCount = 0;
      this.clearDataForApplyFilter();
      this.project.selectedDate = null; 
      this.project.selectedUpdateDate = null;
      if(this.projectService.initiate_from === 'from_sprint') {
        this.notificationService.broadcast(EVENT_TYPES.SPRINT.SPRINT_FILTER, { data: { filter_at: 'sprint_dashboard'} });
      } else if(this.projectService.sprintId) {
        this.notificationService.broadcast(EVENT_TYPES.SPRINT.SPRINT_FILTER, { data: { filter_at: 'sprint_detail'} });
      } else if(this.projectService.initiate_from === 'from_test_suite') {
        this.notificationService.broadcast(EVENT_TYPES.TEST_SUITE.TEST_SUITE_STORY__FILTER, { data: { filter_at: 'test_suit'} });
      } else if(this.projectService.initiate_from === 'from_release') {
        this.notificationService.broadcast(EVENT_TYPES.RELEASE.RELEASE_FILTER, { data: { filter_at: 'release_filter'} });
      } else {
        this.projectService.loadStories(this.project, false).subscribe((stories) => {
          this.projectColumns = PROJECT_COLUMN_DATA;
          this.projectService.getExpandCollapse(this.project);
          this.onlyVisibleColumns();
          this.projectService.showHtml = true;
          this.projectService.startApplyFilterLoader = false;
          // this.ngAfterViewInit();
          this.userService.trackSegmentEvent('Clear All Filter', this.projectService.getCommonPropsForStoryBoardEvents(this.project));
        }, err => {
          this.errorService.errorFunction(err);
          this.projectService.startApplyFilterLoader = false;
        });
      }
    }
  }

  onlyVisibleColumns() {
    PROJECT_COLUMN_DATA.forEach((col) => {
      if (this.sharedService.visibleIds.indexOf(col.list_id) < 0) {
        col.visible = false;
      }
    })
  }

}
