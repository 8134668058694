import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BaseComponent } from '../base.component';
import { TimingProjectsDetailsService, IProjectsList } from './../../services/projects_details.service';
import { TimingUsersService, IUsersList } from './../../services/users.service';
import { Moment } from 'moment';
import moment from 'moment';
import { DateRange } from '../../models/date-range';
import { Project } from '../../models/project';
import { User } from '../../models/user';

@Component({
  selector: 'app-admin-timing-dashboard',
  templateUrl: 'dashboard.component.html',
  styleUrls: [
    '../../../admin.common.scss',
    'dashboard.component.scss'
  ]
})
export class TimingDashboardComponent extends BaseComponent implements OnInit {

  public projects: Project[] = [];
  public users: User[] = [];
  public totalUsers: number;
  public totalTime: string;
  public inProcess: number;
  startLoader:boolean = false;
  searchPro: String = '';

  constructor(private projectsService: TimingProjectsDetailsService,
              private usersService: TimingUsersService,
              private router: Router) {
    super();
  }

  ngOnInit(): void {
    this.startLoader = true;
    const from = moment().startOf('month').subtract(1, 'month').add(14, 'days');
    const to = moment().startOf('month').add(13, 'days');
    this.dateRange = new DateRange(from, to);
    this.searchPro = '';
    this.getProjects();
    this.getUsers();
  }

  onDateRangeChanged(dateRange: DateRange): void {
    this.currentProjectsPage = 1;
    this.dateRange = dateRange;
    this.getProjects();
    this.getUsers();
  }

  onProjectsPageChanged(page: number): void {
    this.currentProjectsPage = page;
    this.getProjects();
  }

  onUsersPageChanged(page: number): void {
    this.currentUsersPage = page;
    this.getUsers();
  }

  getProjects(): void {
    this.isPending = true;
    const filter = {
      page: this.currentProjectsPage,
      from: this.dateRange.formatedFrom,
      to: this.dateRange.formatedTo,
      hours: true,
      project_name: this.searchPro
    };
    this.projectsService
      .getProjects(filter)
      .subscribe(data => {
        this.projects = data.projects;
        this.totalProjectsPages = data.pages;
        this.isPending = false;
        this.startLoader = false;
      });
  }

  getUsers(): void {
    this.isPending = true;
    const filter = {
      page: this.currentUsersPage,
      from: this.dateRange.formatedFrom,
      to: this.dateRange.formatedTo,
      hours: true
    };
    this.usersService
      .getUsers(filter)
      .subscribe(
        data => {
          this.users = data.users;
          this.totalUsers = data.totalUsers;
          this.totalUsersPages = data.pages;
          this.totalTime = data.totalTime;
          this.isPending = false;
        });
  }

  // Convert seconds to time duration
  secsToDuration(secs: number) {
    const duration = moment.duration(secs, 'seconds');
    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    const pad = (number) => { return number < 10 ? '0' + number : number };
    return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
  }

  goToProject(id: number): void {
    this.router.navigate([`admin/timing/projects`, { id: id }]);
  }

  goToUser(id: number): void {
    this.router.navigate([`admin/timing/users/${id}`]);
  }

  getTotalAdjustment(total_approved_time, total_recorded_time) {
    if(total_approved_time || total_recorded_time) {
        if(total_approved_time >= total_recorded_time) {
            return this.secsToDuration(total_approved_time - total_recorded_time)
        } else {
            return `-${this.secsToDuration(total_recorded_time - total_approved_time)}`
        }
    }

    return '00:00:00';
  }

  searchProject() {
    this.currentProjectsPage = 1;
    this.getProjects();
  }
}
