import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-help-support',
  templateUrl: './help-support.component.html',
  styleUrls: ['./help-support.component.scss']
})
export class HelpSupportComponent implements OnInit {
  showVideo:boolean = false;
  vURL = '';
  videoTuts = [
    {videoT: "https://buildertrackerimages.blob.core.windows.net/base-sketch/bulk_actions.mp4", videoName: "Bulk actions"},
    {videoT: "https://buildertrackerimages.blob.core.windows.net/base-sketch/comments.mp4", videoName: "Comments"},
    {videoT: "https://buildertrackerimages.blob.core.windows.net/base-sketch/description_update.mp4", videoName: "Description update"},
    {videoT: "https://buildertrackerimages.blob.core.windows.net/base-sketch/filters.mp4", videoName: "Filters"},
    {videoT: "https://buildertrackerimages.blob.core.windows.net/base-sketch/label_and_features.mp4", videoName: "Labels & Features"},
    {videoT: "https://buildertrackerimages.blob.core.windows.net/base-sketch/login.mp4", videoName: "Login"},
    {videoT: "https://buildertrackerimages.blob.core.windows.net/base-sketch/new_story_creation.mp4", videoName: "New story creation"},
    {videoT: "https://buildertrackerimages.blob.core.windows.net/base-sketch/overall_structure.mp4", videoName: "Overall structure"},
    {videoT: "https://buildertrackerimages.blob.core.windows.net/base-sketch/owner.mp4", videoName: "Owner"},
    {videoT: "https://buildertrackerimages.blob.core.windows.net/base-sketch/project_board_structure.mp4", videoName: "Project board structure"},
    {videoT: "https://buildertrackerimages.blob.core.windows.net/base-sketch/story_type_phase_and_status.mp4", videoName: "Story type, phase and status"},
    {videoT: "https://buildertrackerimages.blob.core.windows.net/base-sketch/update_story.mp4", videoName: "Update story"}
  ];
  constructor() {
    // This is intentional
   }

  ngOnInit() {
    // This is intentional
  }

  openVideoPopup(videoTut) {
    this.showVideo = true;
    this.vURL = videoTut.videoT;
  }
  closeVideoPopup() {
    this.showVideo = false;
    this.vURL = '';
  }
}
