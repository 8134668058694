import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { find, pull, some, sortBy } from 'lodash';
import { ProjectComponent } from '../../project/project.component';
import { EVENT_TYPES, PROJECT_COLUMN_DATA, PROJECT_COLUMN } from '../../shared/dataTypes';
import { GUID } from '../models/guid';
import { Epic } from '../models/epic';
import { Story } from '../models/story';

import { NotificationService } from './notification.service';
import { SocketsService } from './sockets.service';
import { StoryService } from './story.service';
import { UserService } from './user.service';
import { EpicService } from './epic.service';
import { ProjectService } from './project.service';
import { ActivityService } from './activity.service';
import {map} from 'lodash';
import { ProjectColumn } from '../models/column';
import { SharedService } from './shared.service';
import {SprintComponent} from "../../project/sprint/sprint.component";
import { Note } from '../models/note';
import { CustomToastService } from './custom-toast.service';
import { ReleasePlannerModal } from '../models/releasePlannerModal';
import { Router } from '@angular/router';
import moment from 'moment';

const delay: number = 1500;

@Injectable()
export class ProjectSyncService {
  public _context: any;
  private _delayIdentifiers: Array<any> = [];
  private _subscription: any;
  public acceptanceCriteriaArray:any = []

  constructor(private notificationService: NotificationService,
              private socketsService: SocketsService,
              private storyService: StoryService,
              private sharedService: SharedService,
              private userService: UserService,
              private epicService: EpicService,
              private customToast: CustomToastService,
              private activityService: ActivityService,
              private projectService: ProjectService,
              private router: Router
              ) {}

  public subscribe(context) {
    this._context = context;
    let channelName: string = `$project-${this._context.projectId}`;
    let centrifuge = this.socketsService.centrifuge;
    this.unsubscribe();
    this._subscription = centrifuge.subscribe(channelName, (eventContext) => {
        let event = new Event(eventContext.data);
        this.processEvent(event);
      });
  }

  public unsubscribe() {
    if (this._subscription) {
      this._subscription.unsubscribe();
      this._subscription.removeAllListeners();
      this._subscription = null;
    }
  }

  private delay(delayIdentifier: any, callback: Function) {
    if (!find(this._delayIdentifiers, delayIdentifier)) {
      this._delayIdentifiers.push(delayIdentifier);
      setTimeout(() => {
        callback();
        pull(this._delayIdentifiers, delayIdentifier);
      }, delay);
    }
  }

  private processEvent(event) {
    console.log(event);
    if (event.user_id) {
      if (event.action && event.class && event.is('AcceptanceCriteria:bulk_create')) {
        this.createAcceptanceCriteriaBulk(event);
        this.updateStoryHistory(event.story_id);
        if (this.userService.currentUser.id === event.user_id) {
          this.storyService.allowStoryUpdateFlag = true
          this.storyService.handleAcceptanceCriteriaDeleteForGPT = true
          this.storyService.disableStoryChatGPT = false
          this.customToast.messages.push({
            id: 'ResetSuccess',
            type: 'success',
            class: 'new_member_added',
            title: 'Story Updated Successfully',
            message: 'Story details have been saved successfully.'
          });
        }
      } else if (this.userService.currentUser.id === event.user_id) {
        this.storyService.allowStoryUpdateFlag = false
        this.storyService.disableStoryUpdateFlag = false
        this.storyService.chatGPTLoader = false
        if (event.status === 'failure') {
          this.customToast.messages.push({
            id: 'Responsefail',
            type: 'fail',
            class: 'import_fail',
            title: 'AI Failure',
            message: event.message
          });
        } else {
          if (this.storyService.neglectOnClosure) {
            this.storyService.canDisableResetFlag = false
            this.storyService.canDisableSaveFlag = false
            this.updateStoryFromChatGPT(event.story)
            this.createAcceptanceCriteriaFromGPT(event.acceptance_criterias, event.story_id)
          }
        }
      }
    } else if (event.is('Story:update') && !this.storyService.disableStoryUpdateFlag) {
      this.storyService.allowStoryUpdateFlag && this.onStoryUpdation(event);
    } else if (event.is('Story:create')) {
      if (!event.story_ids) {
        event.story_ids = [];
        event.story_ids.push(event.id);
      }
      this.onTestCaseBugCreation(event);
      this.onBulkStoryCreate(event);
    }else if (event.is('TestCase:create')) {
      this.onTestCaseCreation(event);
    }else if (event.is('Story:update')) {
      this.onTestCaseUpdation(event);
      this.onStoryUpdation(event);
      this.updateStoryHistory(event.story_id);
      // this.loadEpics();
    }else if (event.is('Story:bulk_sync_available')) {
      this.enableSyncWithCMT(event);
    }else if (event.is('Story:bulk_update_role')) {
      this.bulkRoleUpdate(event);
    }else if (event.is('ExpectedResult:*')) {
      if (this.projectService.show_Story && this.projectService.show_Story.id == event.story_id) {
        this.notificationService.broadcast(EVENT_TYPES.STORY.EXPECTED_RESULT, event);
      }   
      this.updateStoryHistory(event.story_id);
    } else if (event.is('Story:destroy')) {
      this.onStoryDestroy(event.id);
      this.loadEpics();
    } else if (event.is('AcceptanceCriteria:create')) {
      this.createAcceptanceCriteria(event);
      this.updateStoryHistory(event.story_id);
    } else if (event.is('AcceptanceCriteria:destroy')) {
      this.deleteAcceptanceCriteria(event);
      this.updateStoryHistory(event.story_id);
    } else if (event.is('AcceptanceCriteria:update')) {
      this.updateAcceptanceCriteria(event);
      this.updateStoryHistory(event.story_id);
    } else if (event.is('Story:bulk_create')) {
      this.onBulkStoryCreate(event);
    } else if (event.is('Story:bulk_assign_owner')) {
      this.onBulkActions(event, 'bulk_assign_owner');
      event.story_ids.forEach(storyId => this.updateStoryHistory(storyId));
    } else if (event.is('Story:bulk_assign_reviewer')) {
      this.onBulkActions(event, 'bulk_assign_reviewer');
      event.story_ids.forEach(storyId => this.updateStoryHistory(storyId));
    } else if (event.is('Story:bulk_assign_platforms')) {
      this.onBulkActions(event, 'bulk_assign_platforms');
      event.story_ids.forEach(storyId => this.updateStoryHistory(storyId));
    } else if (event.is('Story:bulk_archive')) {
      this.onBulkArchive(event);
      event.story_ids.forEach(storyId => this.updateStoryHistory(storyId));
      this.loadEpics();
    } else if (event.is('Story:bulk_unarchive')) {
      this.onBulkArchive(event);
      event.story_ids.forEach(storyId => this.updateStoryHistory(storyId));
      this.loadEpics();
    } else if (event.is('Story:bulk_update_state')) {
      this.onBulkArchive(event);
      event.story_ids.forEach(storyId => this.updateStoryHistory(storyId));
      this.loadEpics();
    } else if (event.is('Story:bulk_destroy')) {
      event.story_ids.forEach(storyId => this.onStoryDestroy(storyId));
      this.loadEpics();
    } else if (event.is('Epic:bulk_epics')) {
      this.onBulkActions(event, 'bulk_epic_add');
      event.story_ids.forEach(storyId => this.updateStoryHistory(storyId));
    } else if (event.is('Commit:*')) {
      let st = this.getStory(event.story_id);
      if (this.updatedLocalobject(st, event)) {
        this.refreshCommits(st, event);
        this.updateStoryHistory(event.story_id);
      } else {
        this.delay(this.onStoryUpdate, () => {
          this.onStoryUpdate(event);
          this.updateStoryHistory(event.story_id);
        });
      }
    } else if (event.is('Note:*')) {
      let st = this.getStory(event.story_id);
      if (this.updatedLocalobject(st, event)) {
        this.refreshNotes(st, event);
        this.updateStoryHistory(event.story_id);
      } else {
        this.delay(this.onStoryUpdate, () => {
          this.onStoryUpdate(event);
          this.updateStoryHistory(event.story_id);
        });
      }
    } else if (event.is('Task:*')) {
      this.delay(this.onStoryUpdate, () => {
        this.onStoryUpdate(event);
        this.updateStoryHistory(event.story_id);
      });
    } else if (event.is('Epic:destroy')) {
      this.onEpicDestroy(event);
    } else if (event.is('Epic:*')) {
      if(this._context.testSuiteStories) {
        this.onTestCaseEpicUpdation(event);
      }
      this.onEpicCreateUpdate(event);
      this.updateStoryHistory(event.story_id);
    } else if (event.is('Project:update')) {
      if (this.updatedLocalobject(this._context.project, event)) {
        for (let key in event.changes) {
          this._context.project[key] = event.changes[key];
        }
      } else {
        this.onProjectUpdate(event);
      }
    } else if (event.is('Blocker:*')) {
      this.onStoryUpdate(event);
      this.updateStoryHistory(event.story_id);
    } else if (event.is('List:list_created')) {
      const isColumn = this.sharedService.getColumnById(event.list_id);
      if(!isColumn) {
        const column = new ProjectColumn({list_id: event.list_id, list_code: event.list_code, list_type: event.list_type, list_name: event.list_name})
        PROJECT_COLUMN_DATA.push(column);
      }
    } else if (event.is('List:list_updated')) {
      if(event.position_changed) {
        const isColumn = this.sharedService.getColumnById(event.list_id);
        if(isColumn) {
          isColumn.list_position = Number(event.position);
          PROJECT_COLUMN_DATA.sort((a,b) => (a.list_position - b.list_position));
        }
      } else {
        const columnIndex = this.sharedService.findIndexOfColumn(event.list_id);
        if(columnIndex >= 0) {
          PROJECT_COLUMN_DATA[columnIndex].list_name = event.list_name;
        }
      }
    } else if (event.is('List:list_deleted')) {
      const columnIndex = this.sharedService.findIndexOfColumn(event.list_id);
      if(columnIndex >= 0) {
        PROJECT_COLUMN_DATA.splice(columnIndex, 1);
        let index = this.sharedService.visibleIds.indexOf(event.list_id);
        if (index >= 0) {
          this.sharedService.visibleIds.splice(index, 1);
        }
      }
    } else if(event.is('Project:progress_updated')) {
      this._context.project.progress = event.progress.project_progress;
      const payload = {
        data: {
          project: this._context.project
        }
      };
      this.notificationService.broadcast(EVENT_TYPES.PROJECT_PERCENTAGE.PROGRESS_UPDATED, payload);
      if (event.progress.features_progress && event.progress.features_progress.length > 0) {
        event.progress.features_progress.forEach(item => {
          let index = this._context.project.epics.findIndex(epic_id => epic_id.id === item.id);
          if ( index !== -1){
            this._context.project.epics[index].progress = item.progress;
          }
        });
        const updatepayload = {
          data: {
            project: this._context.project
          }
        };
        this.notificationService.broadcast(EVENT_TYPES.EPIC_PROGRESS_UPDATE.EPIC_PROGRESS_UPDATED, updatepayload);
      }
    } else if (event.is('Sprint:addstory')) {
      this.notificationService.broadcast(EVENT_TYPES.SPRINT.BACKLOG_STORY_REMOVED, event);
    } else if (event.is('Sprint:removestory')) {
      this.notificationService.broadcast(EVENT_TYPES.SPRINT.BACKLOG_STORY_ADDED, event);
    } else if (event.is('Sprint:sprint_created')) {
      this.notificationService.broadcast(EVENT_TYPES.SPRINT.SPRINT_CREATED, event);
    } else if (event.is('Sprint:sprint_updated')) {
      this.notificationService.broadcast(EVENT_TYPES.SPRINT.SPRINT_UPDATED, event);
    } else if (event.is('Sprint:sprint_story_updated')) {
      this.notificationService.broadcast(EVENT_TYPES.SPRINT.SPRINT_STORY_UPDATED, event);
    } else if (event.is('Sprint:sprint_deleted')) {
      this.notificationService.broadcast(EVENT_TYPES.SPRINT.SPRINT_DELETED, event);
    } else if (event.is('Sprint:story_added')) {
      this.notificationService.broadcast(EVENT_TYPES.SPRINT.SPRINT_STORY_ADDED, event);
    } else if (event.is('Sprint:story_removed')) {
      this.notificationService.broadcast(EVENT_TYPES.SPRINT.SPRINT_STORY_REMOVED, event);
    } else if(event.is('Project:job_processed')) {
      const payload = {
        data: {
          acting_user_id: event.acting_user_id,
          job_processed_message: event.message
        }
      };
      if(event.acting_user_id === this.userService.currentUser.id) {
        this.customToast.messages.push({
          id: 'bulk_delete',
          type: 'success',
          class: 'bulk_delete',
          title: 'Bulk Archive',
          message: event.message
        });
      }
    } else if (event.is('ReleaseDetail:update')) {
      this.notificationService.broadcast(EVENT_TYPES.release_detail.release_detail_UPDATE, event);
      const release_id = event.release_id;
      if(this._context.projectService.initiate_from === 'from_release') {
        const existing_release_list = this._context.releases_list;
        const release_index = existing_release_list.findIndex(release => release.id === release_id);
        Object.keys(event.changes).forEach(key => {
          if(key === 'body') {
            existing_release_list[release_index]['release_detail_text'] = event.changes[key];
          }
        });
      }
    } else if (event.is('Release:create')) {
      const existing_release_list = this._context.releases_list;
      const release_meta = this._context.releasesMeta;
      const release_env = this._context.environment;
      if(event.changes.environment === 'staging') {
        release_meta.total_staging_count += 1;
      } else {
        release_meta.total_production_count += 1;
      }
      if(release_env === 'staging' && event.changes.environment === 'staging') {
        existing_release_list.unshift(new ReleasePlannerModal(event.changes));
      } else if(release_env === 'production' && event.changes.environment === 'production') {
        existing_release_list.unshift(new ReleasePlannerModal(event.changes));
      } 
    } else if (event.is('Release:move')) {
      const release_env = this._context.environment;
      const release_meta = this._context.releasesMeta;
      const existing_release_list = this._context.releases_list;
      event.releases.forEach(release => {
        const release_index = existing_release_list.findIndex(item => item.id === release.changes.id);
        if(this._context.projectService.initiate_from === 'from_release_detail') {
          this._context.release.environment = release.changes.environment;
        } else {
          if(release.changes.environment === 'staging') {
            release_meta.total_staging_count += 1;
            release_meta.total_pre_prod_count -= 1;
          } else if(release.changes.environment === 'pre_prod') {
            release_meta.total_staging_count -= 1;
            release_meta.total_pre_prod_count += 1;
          }
          if(release_index !== -1 && (release_env === 'staging' || release_env === 'pre_prod')) {
            existing_release_list.splice(release_index, 1);
          } else if(release_index && (release_env === 'staging' || release_env === 'pre_prod')) {
            existing_release_list.unshift(new ReleasePlannerModal(release.changes));
          }
        }
      });
    } else if (event.is('Release:addstory') || event.is('Release:removestory') || event.is('Release:update')) {
      const release_id = event.id;
      const release_env = this._context.environment;
      if(this._context.projectService.initiate_from === 'from_release_detail') {
        const existing_release = this._context.release;
        if((event.is('Release:addstory') || event.is('Release:removestory')) && (event.acting_user_id !== this._context.currentUser.id)) {
          this._context.sync_story_list = true;
        }
        Object.keys(event.changes).forEach(key => {
          existing_release[key] = event.changes[key];
        });
        if(event.changes.deleted_at) {
          const URL = `/projects/${this._context.project.id}/releases`;
          this.router.navigateByUrl(URL);
        }
      } else {
        const existing_release_list = this._context.releases_list;
        const release_index = this._context.releases_list.findIndex(release => release.id === release_id);
        Object.keys(event.changes).forEach(key => {
          if(key === 'estimated_end_date' || key === 'updated_at') {
            event.changes[key] = moment(event.changes[key]).format('DD MMM YYYY | HH:mm:ss');
          } 
          existing_release_list[release_index][key] = event.changes[key];
        });
        //check if release is deleted
        if(event.changes.deleted_at) {
          existing_release_list.splice(release_index, 1);
        }
      }
    } else if (event.is('Comment:*')) {
      this.notificationService.broadcast(EVENT_TYPES.RELEASE_COMMENT.COMMENT_ACTION, event);
    }
  }

  bulkRoleUpdate(event) {
    event.story_ids.forEach(story_id => {
      const fetched_story = this.getStory(story_id);
      if (fetched_story) {
        fetched_story.studio_role_id = event.studio_role_id;
        fetched_story.role_name = fetched_story.studioRoleMap();
      }
    });
  };

  createAcceptanceCriteria(event) {
    if (this.projectService.show_Story && (event.story_id ===  this.projectService.show_Story.id || event.story_id ===  this.projectService.show_Story.parent_id)) {
      this.projectService.show_Story.addCriteria(event.changes);
      this.projectService.show_Story.show_create_criteria = false;
    }
  }
  createAcceptanceCriteriaBulk(event) {
    if (this.projectService.show_Story && (event.story_id === this.projectService.show_Story.id || event.story_id === this.projectService.show_Story.parent_id)) {
      this.projectService.show_Story.addCriteriaInBulk(event.changes);
      this.projectService.show_Story.show_create_criteria = false;
    }
  }
  createAcceptanceCriteriaFromGPT(event, story_id) {
    if (this.projectService.show_Story && (story_id === this.projectService.show_Story.id || story_id === this.projectService.show_Story.parent_id)) {
      this.projectService.show_Story.acceptance_criteria = []
      event.forEach((val: any,index:number) => {
        val.attributes.title = val.attributes.title ? val.attributes.title : `Acceptance Criteria ${index+1}`
        this.acceptanceCriteriaArray.push(val.attributes)
        this.projectService.show_Story.addCriteria(val.attributes , false);
      })
      this.projectService.show_Story.show_create_criteria = false;
    }
  }

  deleteAcceptanceCriteria(event) {
    if (this.projectService.show_Story && (event.story_id ===  this.projectService.show_Story.id || event.story_id ===  this.projectService.show_Story.parent_id)) {
      this.projectService.show_Story.removeCriteria(event);
    }
  }

  updateAcceptanceCriteria(event) {
    if (this.projectService.show_Story && (event.story_id ===  this.projectService.show_Story.id || event.story_id ===  this.projectService.show_Story.parent_id)) {
      this.projectService.show_Story.addCriteria(event);
    }
  }

  updateStoryHistory(storyId) {
    if (this.projectService.show_Story && this.projectService.show_Story_history && (storyId ===  this.projectService.show_Story.id)) {
      this.notificationService.broadcast(EVENT_TYPES.STORY.STORY_HISTORY, {});
    }
  }

  hideStoryDetail_onDelete(storyId) {
    if (this.projectService.show_storyDetails && this.projectService.show_Story && (storyId === this.projectService.show_Story.id)) {
      this.projectService.hide_StoryDetails();
    }
  }

  /* this function will reload all the epics data in project and if stories array is present
     then refresh the epics for all the stories in array */
  loadEpics(stories?: Array<Story>) {
        if (this._context && this._context.project) {
          if (stories) {
            stories.forEach(story => story.refreshEpics());
          }
          this.epicService.projectEpics(this._context.project)
            .subscribe((epics: Array<Epic>) => {
              this._context.project.addEpics(epics);
              if (stories) {
                stories.forEach(story => story.refreshEpics());
              }
            });
        }
  }

  refreshEpics(stories?: Array<Story>) {
      if (this._context && this._context.project) {
        if (stories) {
          stories.forEach(story => {
            if (story.epics.length !== 0) {
              let epic = story.epics[0];
              if (epic instanceof Epic) {
                this._context.project.addEpic(epic);
              } else {
                this._context.project.addEpic(new Epic(epic));
              }
            }
            story.refreshEpics()
          });
        }
      }
    }

  /* this function is common for 1 story creation or creating more than 1 story */
  private onBulkStoryCreate(event) {
    const stories: Array<Story> = [];
    this
      .storyService
      .stories(this._context.project, event.story_ids, null, this.projectService.getFiltersForAPICall(this._context.project))
      .subscribe(res => {
        if(res && res.length > 0) {
          res.forEach(item => {
            item.story.project = this._context.project;
            const story = new Story(item.story);
            this._context.project.addStory(story);
            const payload = {
              data: {
                story: story
              }
            };
            this.notificationService.broadcast(EVENT_TYPES.STORY.ADDED, payload);
            if (this._context && (this.projectService.initiate_from === 'from_sprint' || this.projectService.initiate_from === 'from_sprint_detail')) {
              this.notificationService.broadcast(EVENT_TYPES.STORY.SPRINT_STORY_CLONED, payload);
            }
            stories.push(story);
          });
        }
        
        if (typeof event.is === 'function') {
          if (event.is('Story:create')) {
            this.refreshEpics(stories)
          } else {
            this.loadEpics(stories);
          }
        }
      });
  }

  /* this function is common for bulk actions : assign owner, assign reviewer, bulk epics add */
  private onBulkActions(event, event_type) {
    if (this._context.project.filterCount > 0) {
      const stories = [];
      /* sending filters applied on stories board & story_ids which have been updated by performing bulk action ==>
          returns result which shows which all stories should be present on stories page */
      this.storyService
        .stories(this._context.project, event.story_ids, null, this.projectService.getFiltersForAPICall(this._context.project))
        .subscribe(res => {
          res.forEach(item => stories.push(item.story));
          this.processStoriesForBulkActionsIfFiltersApplied(stories, event, event_type);
        });
    } else {
      /* perform action on stories in normal scenario when no filter is applied on story board */
      switch (event_type) {
        case 'bulk_epic_add':
          this.onBulkEpicAdd(event);
          break;
        case 'bulk_assign_owner':
          this.onBulkStoryAssignOwner(event);
          break;
        case 'bulk_assign_reviewer':
          this.onBulkStoryAssignReviewer(event);
          break;
        case 'bulk_assign_platforms':
          this.onBulkStoryAssignPlatforms(event);
          break;
      }
    }
  }

  /* this func is checking for bulk stories either story needs to be created, updated on story board
    or deleted from it and performing the required functionality */
  private processStoriesForBulkActionsIfFiltersApplied(stories: Array<any>, event, event_type) {
    const stories_add: Array<number> = [];            // stories which needs to be added on story board
    const stories_update: Array<number> = [];         // stories which needs to be updated on story board
    const stories_delete: Array<number> = [];         // stories which needs to be deleted from story board

    /* pushing stories in the required category of array */
    event.story_ids.forEach(story_id => {
      const index = stories.findIndex(item => item.id === story_id);
      if (index !== -1) {
        const ind = this._context.project.stories.findIndex(item => item.id === story_id);
        (ind !== -1) ? stories_update.push(story_id) : stories_add.push(story_id);
      } else {
        const ind = this._context.project.stories.findIndex(item => item.id === story_id);
        if (ind !== -1) {
          stories_delete.push(story_id);
        }
      }
    });

    /* deleting stories which needs to be deleted from the story board & loadEpics() is not called
       in case of bulk_epic_add as in updating epics loadEpics() is already being called */
    if (stories_delete.length > 0) {
      stories_delete.forEach(story_id => this.onStoryDestroy(story_id));
      if (event_type === 'bulk_assign_owner' || event_type === 'bulk_assign_reviewer') {
        this.loadEpics();
      }
    }

    /* updating stories which needs to be updated on the story board */
    if (stories_update.length > 0) {
      switch (event_type) {
        case 'bulk_epic_add':
          this.onBulkEpicAdd({'story_ids': stories_update});
          break;
        case 'bulk_assign_owner':
          this.onBulkStoryAssignOwner({'story_ids': stories_update});
          break;
        case 'bulk_assign_reviewer':
          this.onBulkStoryAssignReviewer({'story_ids': stories_update});
          break;
        case 'bulk_assign_platforms':
          this.onBulkStoryAssignPlatforms({'story_ids': stories_update});
          break;
      }
    }

    /* adding stories which needs to be added on the story board */
    if (stories_add.length > 0) {
      this.onBulkStoryCreate({'story_ids': stories_add});
    }
  }

  private onBulkStoryAssignOwner(event) {
    this
      .storyService
      .stories(this._context.project, event.story_ids)
      .subscribe(res => {
        res.forEach(item => {
          const story: Story = GUID.instance.findStoryByID(item.story.id);
          story.owned_by_name = item.story.owned_by_name;
          story.owned_by_id = item.story.owned_by_id;
          story.owned_by_initials = item.story.owned_by_initials;
          story.owner_email = item.story.owner_email;
        });
      });
  }

  private onBulkStoryAssignReviewer(event) {
    this
      .storyService
      .stories(this._context.project, event.story_ids)
      .subscribe(res => {
        res.forEach(item => {
          const story: Story = GUID.instance.findStoryByID(item.story.id);
          story.reviewer_name = item.story.reviewer_name;
          story.reviewer_id = item.story.reviewer_id;
          story.reviewer_initials = item.story.reviewer_initials;
          story.reviewer_email = item.story.reviewer_email;
        });
      });
  }

  private onBulkStoryAssignPlatforms(event) {
    this.storyService.stories(this._context.project, event.story_ids).subscribe(res => {
      res.forEach(item => {
        const story: Story = GUID.instance.findStoryByID(item.story.id);
        story.platform_ids = item.story.platform_ids;
        story.formatted_platforms = item.story.formatted_platforms;
        story.setHasStoryPlatforms();
        story.setDevicePlatformsData(item.story.formatted_platforms);
      });
    });
  }

  /* this function will call loadEpics() and passes stories whose epics needs to be refreshed */
  private onBulkEpicAdd(event) {
    const stories: Array<Story> = [];
    event.story_ids.forEach(story_id => {
      const story: Story = GUID.instance.findStoryByID(story_id);
      if (story) {
        stories.push(story);
      }
    });
    this.loadEpics(stories);
  }

  onTestCaseUpdation(event){
    this.notificationService.broadcast(EVENT_TYPES.TESTCASE.DATA_UPDATED, event);
  }

  onTestCaseEpicUpdation(event) {
    this.notificationService.broadcast(EVENT_TYPES.TESTCASE.EPIC_UPDATED, event);
  }

  onTestCaseCreation(event){
    this.notificationService.broadcast(EVENT_TYPES.TESTCASE.CREATE_NEW, event);
  }

  onTestCaseBugCreation(event){
    if (event.test_case_id) {
      this.notificationService.broadcast(EVENT_TYPES.TESTCASE.CREATE_NEW_BUG, event); 
    }
  }
  
  updateStoryFromChatGPT(event) {
    event.changes = event.attributes
    if (this.projectService.show_Story && (event.story_id === this.projectService.show_Story.id || event.story_id === this.projectService.show_Story.parent_id) && event.attributes && Object.keys(event.attributes).length !== 0) {
      this.notificationService.broadcast(EVENT_TYPES.STORY.UPDATE_OPENED_SUBTASK, event);
    }
  }

  private onStoryUpdation(event) {
    // if parent story opened, update sub stories data
    if (this.projectService.show_Story && this.projectService.show_Story.id == event.parent_id && event.changes && Object.keys(event.changes).length !== 0) {
      this.notificationService.broadcast(EVENT_TYPES.STORY.DATA_UPDATED, event);
    }

    if (this.projectService.show_Story && (event.story_id ===  this.projectService.show_Story.id || event.story_id ===  this.projectService.show_Story.parent_id) && event.changes && Object.keys(event.changes).length !== 0) {
      this.notificationService.broadcast(EVENT_TYPES.STORY.UPDATE_OPENED_SUBTASK, event);
    }

    if (this._context && (this.projectService.initiate_from === 'from_sprint' || this.projectService.initiate_from === 'from_sprint_detail')) {
      this.notificationService.broadcast(EVENT_TYPES.STORY.SPRINT_STORY_UPDATED, event);
    }

    if (event.is('Story:update') && (this.projectService.initiate_from === 'from_sprint' || this.projectService.initiate_from === 'from_sprint_detail')) {
      this.notificationService.broadcast(EVENT_TYPES.STORY.UPDATED, event);
    }

    if (this._context.project.filterCount > 0 && this.projectService.initiate_from === 'from project') {
      /* in case of single story event will not be having story_ids so need it as a parameter to pass as an array of story ids */
      if (!event.story_ids) {
        event.story_ids = [];
        event.story_ids.push(event.id);
      }

      const stories: Array<any> = [];
      /* sending filters applied on stories board & story_id of story which has been updated ==>
          returns result which shows which either story should be present or not on stories page */
      this.storyService
        .stories(this._context.project, event.story_ids, null, this.projectService.getFiltersForAPICall(this._context.project))
        .subscribe(res => {
          res.forEach(item => stories.push(item.story));
          this.processStoryIfFiltersApplied(stories, event);
        });
    } else {
      // update story in normal scenario when no filter is applied on story board
      this.updateStory(event);
    }
  }

  /* this func is for checking either story needs to be created, updated on story board or deleted from it
     and performing the required functionality */
  private processStoryIfFiltersApplied(stories: Array<any>, event) {
    if (stories && event) {
      const index = stories.findIndex(item => item.id === event.story_id);
      if (index !== -1) {
        /* (index !== -1) ==> represents that this story should be on story board */
        const ind = this._context.project.stories.findIndex(item => item.id === event.story_id);
        if (ind !== -1) {
          /* (ind !== -1) ==> represents that this story is already present on story board and only needs to be updated */
          (event && event.event_type === 'epic_update') ? this.refreshStoryEpics(event.story_id) : this.updateStory(event);
        } else {
          /* this represents that this story is not present on story board but it should be there so adding it on story board */
          this.onBulkStoryCreate(event);
        }
      } else {
        /* (index === -1) ==> represents that this story should not be on story board if present then delete it */
        const ind = this._context.project.stories.findIndex(item => item.id === event.story_id);
        if (ind !== -1) {
          /* (ind !== -1) ==> represents that this story is present on story so deleting it from the story board */
          this.onStoryDestroy(event.story_id);
        }
      }
    }
  }

  enableSyncWithCMT(event) {
    if (this.projectService.show_Story) {
      event.story_ids.forEach(story_id => {
        if (this.projectService.show_Story.id === story_id) {
          this.projectService.show_Story.sync_available = true;
        }
      });
    }
  }

  private getStory(story_id: number) {
    let st = this._context.project.findStoryByID(story_id);
    if (!st) {
      // if story already opened
      if (this.projectService.show_Story && this.projectService.show_Story.id === story_id) {
        st = this.projectService.show_Story;
      }
    }
    return st;
  }

  // check if local object is updated or out of sync with backend server
  private updatedLocalobject(object: any, event: any): boolean {
    if(!object) { return false; }

    if (event.changes && Object.keys(event.changes).length !== 0) {
      if (event.changes['last_updated_at'] && 'updated_at' in object) {
        // Get last updated time for this object
        let last_updated_at = event.changes['last_updated_at'];
        delete event.changes['last_updated_at'];

        // if local object is updated, do not fetch data from backend server
        return last_updated_at === object.updated_at;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  private updateStory(event) {
    if (this._context && this._context.project) {
      const st = this.getStory(event.story_id);

      let prev_col, prev_state;
      if (st) {
        prev_col = st.list_id;
        prev_state = st.prev_state;
      }

      if (this.sharedService.initialCoulmn) {
        prev_col = this.sharedService.initialCoulmn.list_id;
      }

      // Refresh the parent story if sub tasks are moved or promoted
      let updateThroughWebsocket = false;
      if (this.updatedLocalobject(st, event)) {
        if ('total_tasks_count' in event.changes) {
          if (this.projectService.show_Story && this.projectService.show_Story.id === st.id) {
            updateThroughWebsocket = (event.changes.total_tasks_count == st.total_tasks_count) && (event.changes.test_cases_count == st.test_cases_count);
          } else {
            updateThroughWebsocket = true;
          }
        } else {
          updateThroughWebsocket = true;
        }
      }

      // if changes exist, update story immediatly
      if (updateThroughWebsocket) {
        for (let key in event.changes) {
          st[key] = event.changes[key];
        }


        if(!st.parent_id && event.changes.parent_id === null){
          this.projectService.updateFilteredList(this._context.project);
        }

        st.setReadonly();

        // on title change, refresh title textarea height on UI
        if ('title' in event.changes) {
          this.notificationService.broadcast(EVENT_TYPES.STORY.TITLE_UPDATED, {});
        }

        if ('bug_related_to_story' in event.changes) {
          const payload = {
            data: {
              story_id: event.story_id,
              bug_related_to_story: event.changes.bug_related_to_story
            }
          };
          this.notificationService.broadcast(EVENT_TYPES.STORY.BUG_RELATED_TO_UPDATED, payload);
        }

        if ('status' in event.changes) { 
          this._context.project.stories.forEach(as => {
            if(as.parent_id === event.story_id) {
              as.status = event.changes.status;
            }
          })
        }

        // on estimate change, refresh estimate on UI
        if ('estimate' in event.changes || 'reviewer_estimate' in event.changes) {
          this.notificationService.broadcast(EVENT_TYPES.STORY.ESTIMATE_UPDATED, event);
        } else if ('deleted_at' in event.changes) {
          if (event.changes['deleted_at']) {
            st.current_timer_activity = null;
            st.current_reviewer_timer_activity = null;
          }
        }

        // on labels change, refresh labels on UI
        if ('labels' in event.changes) {
          st.refreshLabels({ labels: st.labels });
        }

        // on platform_ids change, refresh devices on UI
        if ('platform_ids' in event.changes) {
          st.refreshDevices({ formatted_platforms: st.formatted_platforms });
        }

        // Update dev release to use it as date
        if ('dev_release_at' in event.changes) {
          st.setDevReleaseAt(event.changes);
        }
        
        this.updateLocalStory(st, prev_col, prev_state);
      } else {
        if (this.projectService.initiate_from !== 'from_sprint' && this.projectService.initiate_from !== 'from_sprint_detail') {
          this.fetchStoryFromServer(event, prev_col, prev_state);
        }
        
      }
    }
  }

  fetchStoryFromServer(event, prev_col, prev_state) {
    const isStorySelected = this.projectService.show_Story && this.projectService.show_Story.id == event.id;
    this.storyService.story(this._context.project, {id: event.id} as Story, null, isStorySelected).subscribe(story => {
      this.updateLocalStory(story, prev_col, prev_state);
    });
  }

  changesPresent(object: any) {
    return object.changes && Object.keys(object.changes).length !== 0;
  }

  /* Update the local story variable and shift to next list if state is changed */
  updateAndShiftStory(story: Story, response: any) {
    // Support blank response
    if (!response || Object.keys(response).length === 0) { return; }

    // Collect previous story state and list id, which helps to move to respective list
    const prev_col = story.list_id;
    const prev_state = story.state;

    // Update the existing local story variable from story update api response
    if (this.changesPresent(response)) {
      for (let key in response.changes) {
        story[key] = response.changes[key];
      }
    } else if (!story.testCase()) {
      response.project = story.project;
      story = new Story(response);
    }

    // Update local story variable
    if (!story.testCase()) {
      this.updateLocalStory(story, prev_col, prev_state);
    }
  }

  /* On state change, shift story to respective list if list is not correct */
  shiftStory(story: Story, prev_col: number) {
    if (prev_col && prev_col !== story.list_id) {
      const payload = {
        data: {
          story: story,
          column: prev_col
        }
      };
      this.notificationService.broadcast(EVENT_TYPES.STORY.REMOVED, payload); // removes story from prev column
      this.notificationService.broadcast(EVENT_TYPES.STORY.ADDED, payload);   // will add story in new column
    }
  }

  /* Update local story as per previous state and previous list*/
  updateLocalStory(story: Story, prev_col: number, prev_state: string) {
    /* as the story object has been replaced so it is marking the story as selected if it was */
    const selStoriesIds = map(this._context.project.selectedStories, 'id');
    const isStorySelected = _.includes(selStoriesIds, story.id);
    story.isSelected = isStorySelected;

    if (prev_col && prev_col !== story.list_id) {
      /* it shows that story column has been changed due to state change */

      this.sharedService.initialCoulmn = null;
      if (this._context.project.selectedStories.length == 1) {
        setTimeout(() => {
          this.sharedService.showQuickAction(this._context.project);
        });
      }

      this._context.project.addStory(story);
      this.shiftStory(story, prev_col);
    } else {
      /* in case of simple update , story just needs to be replaced */
      this.sharedService.initialCoulmn = null;
      this._context.project.addStory(story);
      const payload = {
        data: {
          story: story
        }
      };
      this.notificationService.broadcast(EVENT_TYPES.STORY.UPDATED, payload);
    }
    this.refreshEpics([story]);

    this.notifyTimer(story);

    /* in case of update story, epics needs to be reloaded only in case if prev_state or curr_state is accepted  */
    if (prev_state !== story.state &&  (prev_state === 'accepted' || story.state === 'accepted')) {
      // this.loadEpics(); : now calling after this func call

      /* update parent story in case child state has been changed to or changed from accepted. */
      // Update done tasks count as per story current state
      if (story && story.parent_id) {
        this.updateParentStory(story, prev_state);
      }
    }
  }

  /* Update the parent story sub tasks list with proper state */
  private updateParentStory(story: Story, prev_state: String) {
    const parentStory = this.getStory(story.parent_id);
    if (parentStory) {
      if (parentStory.sub_tasks.length > 0) {
        const subTask = parentStory.findSubStoryByID(story.id);
        if (subTask) { subTask.state = story.state; }
        parentStory.refreshTaskList(parentStory); 
      } else {
        if (prev_state === 'accepted') {
          parentStory.done_tasks_count--;
        } else if (story.state === 'accepted') {
          parentStory.done_tasks_count++;
        }
      }
    }
  }

  private refreshNotes(st: Story, event: any) {
    if (this._context && this._context.project) {
      if ('notes_count' in event) {
        st.notes_count = event.notes_count;
      }
      if (!this.projectService.show_Story) { return; }

      // if story opened, update the notes
      if (this.projectService.show_Story.id === event.story_id) {
        let note;
        const newNote = new Note(event.changes.note, st);
        if (event.parent_id) {
          note = st.notes.filter(note => note.id === event.parent_id)[0];
        }
        if (event.action == 'create') {
          if (event.parent_id) {
            note.addReply(newNote);
          } else {
            st.addNote(newNote);
          }
        } else if (event.action == 'update') {
          if (event.parent_id) {
            note.updateReply(newNote);
          } else {
            st.updateNote(newNote);
          }
        } else if (event.action == 'destroy') {
          if (event.parent_id) {
            note.removeReply(newNote);
          } else {
            st.removeNote(newNote);
          }
        }
      } else if (this.projectService.show_Story.id === st.parent_id) {
        if (!event.parent_id) {
          // this.notificationService.broadcast(EVENT_TYPES.STORY.DATA_UPDATED, event);
        }
      }
    }
  }

  private refreshCommits(st: Story, event: any) {
    if (this._context && this._context.project) {
      if (!this.projectService.show_Story) { return; }

      // if story opened, update the notes
      if (this.projectService.show_Story.id === event.story_id) {
        const commit = event.changes.commit;
        if (event.action == 'create') {
          st.addCommit(commit);
        } else if (event.action == 'update') {
          st.updateCommit(commit);
        } else if (event.action == 'destroy') {
          st.removeCommit(commit);
        }
      }
    }
  }

  private onBulkArchive(event) {
    if (event.bulk_changes && Object.keys(event.bulk_changes).length !== 0 && (event.is('Story:bulk_archive') || event.is('Story:bulk_unarchive'))) {
      if (this._context && (this.projectService.initiate_from === 'from_sprint' || this.projectService.initiate_from === 'from_sprint_detail')) {
        this.notificationService.broadcast(EVENT_TYPES.STORY.SPRINT_STORY_REMOVED, event);
      }

      event.bulk_changes.forEach(story_data => {
        const st = this.getStory(story_data.id);
        if (st) {
          const prev_col = st.list_id;
          const prev_state = st.state;

          // If parent story's detail page is open, refresh the details page.
          if (!story_data.parent_id && this.projectService.show_Story && this.projectService.show_Story.id == story_data.id) {
            this.fetchStoryFromServer(story_data, prev_col, prev_state);
          } else {
            for (let key in story_data) {
              st[key] = story_data[key];
            }
            this.shiftStory(st, prev_col);
          }
        }
      });
    } else if (this._context.project.filterCount > 0) {
      const stories: Array<any> = [];
      /* sending filters applied on stories board & story_id of story which has been archived ==>
          returns result which shows which either story should be present or not on stories page */
      this.storyService
        .stories(this._context.project, event.story_ids, null, this.projectService.getFiltersForAPICall(this._context.project))
        .subscribe(res => {
          res.forEach(item => stories.push(item.story));
          if (event && event.story_ids && event.story_ids.length > 0) {
            event.story_ids.forEach(storyId => {
              const storyEvent = {
                story_id: storyId,
                id: storyId
              };
              this.processStoryIfFiltersApplied(stories, storyEvent);
            });
          }
        });
    } else {
      // archive each story in normal scenario when no filter is applied on story board
      if (event && event.story_ids && event.story_ids.length > 0) {
        event.story_ids.forEach(storyId => {
          const storyEvent = {
            story_id: storyId,
            id: storyId
          };
          this.updateStory(storyEvent);
        });
      }
    }
  }

  notifyTimer(story) {
    const payload = {
      data: {
        story: story
      }
    };
    this.notificationService.broadcast(EVENT_TYPES.SESSION.TIMER_START, payload);
  }

  closeQuickAction(story) {
    const storyIndex = this._context.project.findStoryIndex(story);
    if (storyIndex !== -1) {
      this._context.project.spliceStory(storyIndex);
      story.showDropDown = false;
      story.showQuickAct = false;
      story.isQuickActionPerformed = false;
    }
  }

  /* this function is to update story in case commits, blockers, comments added/updated in story as no need to check filters
      in these cases. If story presents on stories board then it just simply needs to be updated */
  private onStoryUpdate(event) {
    this.storyService.attachmentActionLoader2 = true;
    if (this && this.storyService && event) {
      const isStorySelected = this.projectService.show_Story && this.projectService.show_Story.id == event.story_id;
      this.storyService.story(this._context.project, {id: event.story_id} as Story, null, isStorySelected).subscribe(story => {
        const index = this._context.project.stories.findIndex(candidate => candidate.id === story.id);
        /* this will only update the story in case the story is present on stories board*/
        if (index !== -1) {
          this._context.project.stories.splice(index, 1, story);

          /* as the story object has been replaced so it is marking the story as selected if it was */
          const selStoriesIds = map(this._context.project.selectedStories, 'id');
          const isStorySelected = _.includes(selStoriesIds, story.id);
          story.isSelected = isStorySelected;

          // setting up the local variable for comparing the current state of story with the previous case
          story.prev_state = story.state;

          if (typeof event.is === 'function' && event.is('Blocker:*')) {
            this.updateBlockersOnStoryDetail(story);
          }
        }
        this.storyService.attachmentActionLoader2 = false;
      });
    }
  }

  /* story is opened in story detailed mode then needs to update blockers explicitly */
  private updateBlockersOnStoryDetail(story) {
    if (this.projectService.show_storyDetails && story.id === this.projectService.show_Story.id) {
      story.updateBlockers(story);
      const payload = {
        data: {
          story: story
        }
      };
      this.notificationService.broadcast(EVENT_TYPES.STORY.BLOCKERS_UPDATED, payload);
    }
  }

  private onStoryDestroy(storyId) {
    const story: Story = GUID.instance.findStoryByID(storyId);
    if (story) {
      this
        ._context
        .project
        .removeStory(story, {
          notificationService: this.notificationService,
          currentUser: this.userService.getUser()
        });
       if(story.deleted_at)  {
        this.hideStoryDetail_onDelete(storyId);
       }
    }
  }

  private onEpicCreateUpdate(event) {
    const epic = {project: {id: this._context.projectId}, id: event.id};
    this.fetchEpic(epic, event);
  }

  fetchEpic(epic, event) {
    this.epicService.epic(epic as Epic).subscribe(ep => {
      this._context.project.addEpic(ep);
      if (event.story_id && this._context.project.filterCount === 0) {
        this.refreshStoryEpics(event.story_id);
      } else if (event.story_id && this._context.project.filterCount > 0) {
        this.onStoryUpdation({'story_id': event.story_id, 'id': event.story_id, 'event_type': 'epic_update'});
      }
    });
  }

  private refreshStoryEpics(story_id) {
    const story = GUID.instance.findStoryByID(story_id);
    if (story) {
      story.refreshEpics();
    }
  }

  private onEpicDestroy(event) {
    const epic: Epic = GUID.instance.findEpicByID(event.id);
    if (epic) {
      this._context.project.removeEpic(epic);
    }
  }

  private onProjectUpdate(event) {
    this
      .projectService
      .get(this._context.project.id)
      .subscribe(data => {
        this.projectService.loadData(this._context.project);
      });
  }

  public parseResponse(res: any): any {
    return res;
  }
}

class Event {
  public id: number;
  public class: string;
  public action: string;
  public story_id: number;

  constructor(eventData) {
    Object.assign(this, eventData);
  }

  public is(query: string): boolean {
    let queries: Array<string> = query.split(',').map((query) => query.trim());
    return some(queries, this.doesMatchQuery.bind(this));
  }

  private doesMatchQuery(query: string): boolean {
    let params: Array<string> = query.split(':').map((param) => param.trim());
    if (params.length != 2) {
      throw new Error(`'${query} is not a valid query'`);
    }
    return this.stringMatches(this.class, params[0])
        && this.stringMatches(this.action, params[1]);
  }

  private stringMatches(str: string, query: string): boolean {
    str = str.toLowerCase();
    query = query.toLowerCase();
    return query == '*' ? true : query == str;
  }
}
