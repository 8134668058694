// import { GUID } from './guid';
import { BaseModel } from './baseModel';
import { CMTStoryReviewData, CMTAcceptanceCriteriaApprovalsData } from '../dataTypes';

export class CmtStoryModel extends BaseModel implements CMTStoryReviewData {
  id?: number;
  title?: string;
  phase?: string;
  description?: string;
  story_type?: string;
  assignee?: string;
  dev_type?: string;
  priority?: string;
  role?: string;
  goal?: string;
  reason?: string;
  acceptance_criteria?: Array<CMTAcceptanceCriteriaApprovalsData>;
  estimate_hrs?: number;
  feature?: {
      name?: string;
      complexity?: string;
      version?: string;
      obsolete?: boolean;
      assemblerize_name?: string;
      bb_exists?: boolean;
      certification_type?: string;
  };
  subtasks?: Array<any>;
  as_a?: string;
  i_want?: string;
  so_that?: string;

  constructor(data: CMTStoryReviewData) {
    super(data);
    this.id = data.id;
    this.title = data.title;
    this.phase = data.phase;
    this.description = data.description;
    this.story_type = data.story_type;
    this.assignee = data.assignee;
    this.dev_type = data.dev_type;
    this.priority = data.priority;
    this.role = data.role;
    this.goal = data.goal;
    this.reason = data.reason;
    // this.acceptance_criteria = data.acceptance_criteria ? data.acceptance_criteria : [] ;
    if(data.acceptance_criteria) {
      this.acceptance_criteria = [];
      data.acceptance_criteria.forEach((ac) => {
        this.acceptance_criteria.push({
          id: ac.id,
          title: ac.title,
          given: ac.given ? ac.given : '',
          when: ac.when ? ac.when : '',
          then: ac.then ? ac.then : '',
          created_at: ac.created_at,
          updated_at: ac.updated_at,
          cmt_acceptance_criteria_id: ac.cmt_acceptance_criteria_id,
          story_approval_id: ac.story_approval_id,
        });
      });
    }
    this.estimate_hrs = data.estimate_hrs;
    this.feature = data.feature ? data.feature : null;
    this.subtasks = data.subtasks ? data.subtasks : null;
    this.as_a = data.as_a;
    this.i_want = data.i_want;
    this.so_that = data.so_that;
  }

  updateDiffCMTStoryFields(titleDiff, as_aDiff, i_wantDiff, so_thatDiff, descriptionDiff) {
    this.title = titleDiff.originalHtml;
    this.as_a = as_aDiff.originalHtml;
    this.i_want = i_wantDiff.originalHtml;
    this.so_that = so_thatDiff.originalHtml;
    this.description = descriptionDiff.originalHtml;
  }

  getClassName() {
    return "CmtStoryModel";
  }
}
