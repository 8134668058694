import { Component, OnInit, Input, EventEmitter, Output, ViewChild, ViewChildren, ElementRef, Renderer2} from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { ReleasePlannerService } from '../../shared/services/release-planner.service';
import { ErrorService } from '../../shared/errorFunction';
import { Note } from '../../shared/models/note';
import { PolicyService } from '../../shared/services/policy.service';
import {SharedService} from '../../shared/services/shared.service';
import { NotificationService } from '../../shared/services/notification.service';
import { EVENT_TYPES } from '../../shared/dataTypes';

@Component({
  selector: 'app-comment-activity',
  templateUrl: './comment-activity.component.html',
  styleUrls: ['./comment-activity.component.scss'],
  animations: [
    trigger('docUploader', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({
          bottom: '0',
          opacity: '0'
        }),
        animate(400, style({
          bottom: '54px',
        }))
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate(400, style({
          bottom: '0',
        }))
      ])
    ]),
  ]
})

export class CommentActivityComponent implements OnInit {
  @Input() project;
  @Input() selected_release;
  @Output() closeActivityPopup = new EventEmitter<boolean>();
  loadData:boolean = false;
  release_comments = [];
  note_text = '';
  vURL = '';
  openItemDoc: string = '';
  openAuthor:boolean = false;
  showVideo:boolean = false;
  // searchText: string = '';
  mentionUsers = [];
  atTheIndex;
  showMention = false;
  maintainMentions = [];
  charCount = 0;
  @ViewChild('maintainHeight', {static: false}) maintainHeight: ElementRef;
  @ViewChildren('mentionUsersList') mentionUsersList: any;
  oldText = '';
  commentNoteUploading:boolean = false;

  constructor( public releasePlannerService: ReleasePlannerService,
    public sharedService: SharedService,
    public policyService: PolicyService,
    private renderer: Renderer2,
    private notificationService: NotificationService,
    private errorService: ErrorService) { }

  ngOnInit() {
    this.loadData = true;
    this.loadComments();
    this.notificationService.subscribeEvent(EVENT_TYPES.RELEASE_COMMENT.COMMENT_ACTION, res => {
      if (res && res.payload.changes.comment) {
        // const newNote = new Note(res.payload.changes.comment);
        if(res.payload.action === "create") { 
          if(!res.payload.parent_id) {
            this.release_comments.push(new Note(res.payload.changes.comment));
          } else {
            //find the parent comment and push the new comment to its replies
            const parentComment = this.release_comments.find(x => x.id === res.payload.parent_id);
            if(parentComment) {
              parentComment.addReply(res.payload.changes.comment);
            }
          }
        } else if(res.payload.action === "update") { 
          if(res.payload.parent_id) {
            //find the comment and update reply
            const parentComment = this.release_comments.find(x => x.id === res.payload.parent_id);
            if(parentComment) {
              parentComment.updateReply(res.payload.changes.comment);
            }
          } else {
            //find the comment and update it
            const parentComment = this.release_comments.find(x => x.id === res.payload.id);
            if(parentComment) {
              this.updateNote(res.payload.changes.comment);
            }
          }
        } 
         else if(res.payload.action === "destroy") {
          //find the index of the comment and remove it from the array
          const index = this.release_comments.findIndex(x => x.id === res.payload.changes.comment.id);
          if(index > -1) {
            this.release_comments.splice(index, 1);
          }
        }
      }
    });
  }

  updateNote(note: Note) {
    if (note.note_type !== 'attachment') {
      const index = this.release_comments.findIndex(n => n.id === note.id);
      if (index !== -1) {
        this.release_comments[index] = new Note(note);
      }
    }
  }

  loadComments() {
    this.releasePlannerService.getReleaseComments(this.project, this.selected_release.id).subscribe(res => {
      if(res) {
        res.data.forEach(element => {
          this.release_comments.push(new Note(element));
        });
        this.loadData = false;
      }
    }, (error) => {
      this.loadData = false;
      this.errorService.errorFunction(error);
    });
  }

  get isProjectReadonly(): boolean {
    return ((this.policyService.isProjectReadonly(this.project)) );
  }

  openAuthorPopup(item, key, index?) {
    if (item && key === 'name') {
      if (this.sharedService.checkType(item.name, true) === 'image') {
        this.openItemDoc = this.sharedService.attachmentPreviewUrls[index];
        this.openAuthor = true;
      }
    } else if (item && key === 'remote_url') {
      if (this.sharedService.checkType(item.remote_url, true) === 'image') {
        this.openItemDoc = item.remote_url;
        this.openAuthor = true;
      }
    }
  }

  openVideoPopup(item, isUrl?) {
    this.showVideo = true;
    this.vURL = item;
  }

  addNewNote(model) {
    if(this.maintainMentions.length === 0 && this.note_text === '') {return}
    this.commentNoteUploading = true;
    const note = new Note({
      note: this.maintainMentions.length > 0 ? this.sharedService.saveParseString(this.note_text, this.maintainMentions) : this.note_text,
      documents: this.sharedService.uploaderArr
    });
    this.releasePlannerService.createReleaseComments(this.project, this.selected_release.id, note , false).subscribe((nt: Note) => {
      model.reset();
      this.note_text = '';
      this.renderer.setStyle(this.maintainHeight.nativeElement, 'height', '');
      this.sharedService.uploaderArr = [];
      this.sharedService.attachmentPreviewUrls = [];
      this.commentNoteUploading = false;
    }, (error) => {
      this.errorService.errorFunction(error);
      this.commentNoteUploading = false;
    });
  }

  selectMention(user) {
    this.mentionUsers = [];
    const mentionObj =   this.sharedService.prepareMentionStr(user, this.note_text, this.atTheIndex);
    this.note_text = mentionObj.searchText;
    const obj = {start: this.atTheIndex - 1, value: mentionObj.userObj, replace: '@' +  mentionObj.userObj.name, end: this.atTheIndex + mentionObj.userObj.name.length};
    this.maintainMentions.push(obj);
    this.showMention = false;
    this.atTheIndex = null;
    this.note_text = this.note_text.substring(0, mentionObj.commentStr.length) + this.note_text.substring(mentionObj.commentStr.length + this.charCount);
    setTimeout(() => {
      this.maintainHeight.nativeElement.focus();
      this.maintainHeight.nativeElement.selectionEnd = mentionObj.commentStr.length;
      this.charCount = 0;
    }, 0);
  }

  searchMentionUser(event) {
    if (event && (event.keyCode === 38 || event.keyCode === 40 || event.keyCode === 9 || event.keyCode === 16 || event.keyCode === 13)) {
      event.preventDefault();
      return false;
    }
    if (this.note_text[event.target.selectionStart - 1] == '@') {
      this.atTheIndex = event.target.selectionStart - 1;
      this.mentionUsers = this.sharedService.searchMentionUser(this.note_text, this.atTheIndex, this.project.users);
      this.charCount = 0;
      this.showMention = true;
    } else if (this.showMention && this.note_text[this.atTheIndex] == '@' && (this.atTheIndex < (event.target.selectionStart - 1))) {
      this.charCount++;
      this.mentionUsers = this.sharedService.searchMentionUser(this.note_text, this.atTheIndex, this.project.users, this.charCount);
    } else {
      this.showMention = false;
      this.mentionUsers = [];
      this.charCount = 0;
      this.atTheIndex = null;
    }
  }

  closeActivity() {
    this.closeActivityPopup.emit(false);
  }

  toggleCommentOrActivity() {
    if(this.selected_release.show_activity) {
      this.selected_release.show_activity = false;
      this.selected_release.show_comment = true;
    } else {
      this.selected_release.show_activity = true;
      this.selected_release.show_comment = false;
    }
  }
}
