<div class="release-commonPopUp">
    <div class="release-commonPopUp-backdrop" (click)="closeActivity()"></div>
    <div class="close-release-planner-popup" (click)="closeActivity()">
      <em class="iconb-close"></em>
    </div>
    <div class="release-commonPopUp-wrapper">
      <div class="bulkAction-Loader" *ngIf="loadData">
        <img src="../../assets/images/storyBoardLoader.gif" alt=""/>
      </div>
      <div class="release-commonPopUp-title-block">
        <div class="title">{{selected_release.name}}</div>
        <div class="action-on-release-note" (click)="toggleCommentOrActivity()">
          {{selected_release.show_comment ? 'Show Activity Log' : 'Show Comments'}}
        </div>
      </div>
      <div class="commonPopUp-featurecontent activity-logs">
        <div class="featureListTitle">
          <span>{{selected_release.show_comment ? 'Comment' : 'Activity Log'}}</span>
        </div>
        <story-note class="story-note-com" *ngIf="selected_release.show_comment" [release_comments]="release_comments" [project]="project"></story-note>
        <app-activity-timeline 
        *ngIf="selected_release.show_activity" 
        [project]="project" 
        [subject_id]="selected_release.id" 
        [subject_type]="'release'"></app-activity-timeline>
      </div>

      <div class="commentListWrapper-common" *ngIf="selected_release.show_comment">
        <div id="activityDIV" class="notelist">
          <div class="note_form">
            <div class="attachments" [@docUploader] *ngIf="sharedService.uploaderArr.length > 0">
              <div class="attachment" *ngFor="let item of sharedService.uploaderArr; let i = index">
                <span class="uploading-listing" (click)="openAuthorPopup(item,'name',i)">
                  <div class="uploading-listing-thumbnail not_maximized">
                    <img *ngIf = "sharedService.checkType(item.name, true) == 'image'" [src]="sharedService.attachmentPreviewUrls[i]" alt="loader"/>
                    <a *ngIf = "sharedService.checkType(item.name, true) == 'doc' || sharedService.checkType(item.name, true) == ''" class="gotoImage" [href]="sharedService.attachmentPreviewUrls[i]" target="_blank" rel="noopener">
                        <img class="default-attachment" src="/assets/images/defaullt-download.png" alt="loader"/>
                    </a>
                    <div *ngIf = "sharedService.checkType(item.name, true) == 'video'" class="videoContainer" (click)="openVideoPopup(sharedService.attachmentPreviewUrls[i], true)">
                        <em class="iconb-play-circle"></em>
                    </div>
                  </div>
                  <div class="uploading-file-description">
                    <span class="docuName">{{ item?.name }}</span>
                    <span class="docuSize">{{ sharedService.getFileSizeText(item?.size) }}</span>
                  </div>
                </span>
                <span class="removeDocument" (click)="sharedService.removeNoteDocument(item)">
                  <em class="iconb-bin"></em>
                </span>
              </div>
            </div>
            <div class="flex-wrapper">
              <div class="commentAreaBtn">
                <div class="noteActionWrapper">
                  <div class="add-note">
                    <button type="button" class="postComment" [disabled]="isProjectReadonly || commentNoteUploading" (click)="addNewNote(note)">Comment</button>
                  </div>
                  <div class="attach-file">
                    <label class="labelForFileType">
                      <em class="iconb-attach" [ngClass]="{'iconDisabled': isProjectReadonly}"></em>
                      <input type="file"
                        #attachmentInput
                        id="file"
                        class="forFiletype"
                        [disabled]="isProjectReadonly"
                        (click)="attachmentInput.value = null" value=""
                        (change)="sharedService.attachFilesInComment($event)"
                        multiple />
                    </label>
                  </div>
                </div>
              </div>
              <textarea
              class="textAreaForComment"
              #maintainHeight
              (files)="attachDroppedFilesInComment($event)"
              #note="ngModel"
              name="note" 
              (keyup) = "searchMentionUser($event)"  
              autosize 
              [(ngModel)]="note_text"
              placeholder='Add a comment...'>
            </textarea>
          </div>
          <div class = "mentiondropdown" *ngIf = "showMention && mentionUsers.length > 0">
            <div #mentionUsersList class="mentionSelection-list" *ngFor = "let user of mentionUsers; index as i;"
                  (click) = "selectMention(user)">
              <div class="mentionUser-initial">{{user.initials | slice:0:1}}</div>
              <div class="mentionUser-descriptionWrapper">
                <span class="mentionUser-name">{{user.name | titlecase}}</span>
                <span class="mentionUser-email">{{user.email}}</span>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
</div> 