import { Directive, ElementRef, Output, EventEmitter, HostListener, Input } from '@angular/core';

@Directive({
    selector: '[clickOutside]'
})
export class ClickOutsideDirective {
    @Output() clickOutside: EventEmitter<any> = new EventEmitter();
    public isFirst = 0
    
    constructor(private elementRef: ElementRef) { }

    @HostListener('document:click', ['$event.target'])
    public onClick(targetElement: any): void {
        const clickedInside = this.elementRef.nativeElement.contains(targetElement);
        if (!clickedInside && this.isFirst) {
            this.clickOutside.emit(null);
        }
        this.isFirst = 1;
    }
}