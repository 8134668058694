import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { CommonForm } from '../../common/classes';

@Component({
  selector: 'admin-projects-form',
  templateUrl: 'form.component.html',
  styleUrls: [
    '../../admin.common.scss',
    'form.component.scss'
  ]
})
export class AdminProjectsFormComponent extends CommonForm implements OnInit, OnChanges {
  @Input('model') model: any;
  @Input('errors') errors: any;
  @Input('isPending') isPending: boolean = false;
  error = '';
  startDate: any;
  endDate: any;
  datePickerOptions = {
    dateFormat: 'yyyy/mm/dd',
    openSelectorTopOfInput: true,
    showSelectorArrow: false
  };

  ngOnInit() {
    this.setStartDate();
  }

  ngOnChanges() {
    this.setStartDate();
    // if(!this.model.default_velocity) {
    //   this.model.default_velocity = 10;
    // }
  }

  setStartDate() {
    if (this.model && this.valueIsPresent(this.model.start_date)) {
      this.startDate = {isRange: false , singleDate: { jsDate: new Date(this.model.start_date)}};
    } else {
      this.startDate = null;
    }
  }

  onStartDateChanged($event) {
    this.model.start_date = $event.formatted;
  }

  // inputVelocity() {
  //   if(this.model.default_velocity && this.model.default_velocity < 0) {
  //     this.error = "Velocity can't be negative";
  //   } else {
  //     this.error = '';
  //   }
  // }

}
