import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminUsersService } from '../users.service';
import { CommonEdit } from '../../common/classes';
import { CustomToastService } from '../../../shared/services/custom-toast.service';
import {SharedService} from '../../../shared/services/shared.service';
import { UserService } from '../../../shared/services/user.service';
import { NotificationService } from '../../../shared/services/notification.service';
import {ErrorService} from '../../../shared/errorFunction';
import {EVENT_TYPES} from '../../../shared/dataTypes'

@Component({
  selector: 'admin-users-edit',
  templateUrl: 'edit.component.html',
  styleUrls: [
    '../../admin.common.scss',
    'edit.component.scss'
  ]
})
export class AdminUsersEditComponent extends CommonEdit implements OnInit {
  userId: number;
  formOriginal: any;
  form: any;
  outputErr: any;
  isPending = false;
  initialUser: any;
  edit: String = 'edit';

  constructor(private usersService: AdminUsersService,
              private activatedRoute: ActivatedRoute,
              private notificationService: NotificationService,
              public sharedService: SharedService,
              private customToast: CustomToastService,
              private userService: UserService,
              public router: Router,
              public errorService: ErrorService) {
    super();
  }

  ngOnInit() {
    this.userId = this.sharedService.userIdForEditFormInAdmin;
    this.getUser();
  }

  getUser() {
    this.isPending = true;
    this
      .usersService
      .getUser(this.userId)
      .subscribe(this.onInItSuccess.bind(this), err => {
        this.errorService.errorFunction(err);
        this.sharedService.showUserEditFormInAdmin = false;
      });
  }

  onInItSuccess(response) {
    this.onSuccess(response, 'onIt');
  }

  // postData() {
  //   const formFields = ['email', 'name', 'time_zone', 'initials', 'role'];
  //   let user: any = pick(this.form, formFields);
  //   return {user: user};
  // }

  onSubmit() {
    this.isPending = true;
    if (!this.form.role) {
      this.form.role = 'user';
    }
    this
      .usersService
      .updateUser(this.userId, this.form, this.fullEmail)
      .subscribe((member) => {
        this.notificationService.broadcast(EVENT_TYPES.USER.ON_EDIT_SUCCESS, {});
        this.onSuccess(member);
        if (this.userId === this.userService.getUser().id && this.formOriginal.role != this.initialUser.role) {
              this.router.navigate(['/']);
        }
        this.sharedService.showUserEditFormInAdmin = false;
        this.sharedService.isGetUserSuccessForEditInAdmin = false;
        this.customToast.messages.push({
          id: 'useredited',
          type: 'success',
          class: 'user_edited',
          title: 'User Updated',
          message: 'Changes updated successfully'});
      },
      (error) => {
        this.errorService.errorFunction(error);
        const err = JSON.parse(error._body);
        this.outputErr = err;
        this.sharedService.showUserEditFormInAdmin = false;
        this.sharedService.isGetUserSuccessForEditInAdmin = false;
      });
  }

  onSuccess(response, onIt?) {
    this.formOriginal = response.user;
    this.formOriginal.selected_user_domain = response.user.email.split('@')[1];
    if (onIt) {
      this.initialUser = response.user;
      const userEmail = response.user.email.split('@')[0];
      this.formOriginal.email = userEmail;
      this.sharedService.isGetUserSuccessForEditInAdmin = true;
    }
    this.revert();
    this.isPending = false;
  }

  checkEmail(email) {
    let re = /^(?=.{1,256})([a-zA-Z0-9][a-zA-Z0-9._%+-]*@[a-zA-Z0-9][a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$)/;
    return re.test(email);
  }

  get fullEmail() {
    return this.form.email + '@' + this.form.selected_user_domain;
  }

  cancel() {
    this.sharedService.showUserEditFormInAdmin = false;
    this.sharedService.isGetUserSuccessForEditInAdmin = false;
  }
}
