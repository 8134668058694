<nav class="navigation">
  <ul class="pagination">
    <li class="page-item"
      [class.disabled]="firstPage || isPending"
      (click)="!isPending && !firstPage && goToPage(currentPage - 1)">
      <span class="page-link">Prev</span>
    </li>
    <li *ngFor="let page of pages"
        class="page-item"
        (click)="!isPending && goToPage(page)"
        [class.active]="page == currentPage">
      <span class="page-link">{{page}}</span>
    </li>
    <li class="page-item"
      [class.disabled]="lastPage || isPending"
      (click)="!isPending && !lastPage && goToPage(currentPage + 1)">
      <span class="page-link">Next</span>
    </li>
  </ul>
</nav>
