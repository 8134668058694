import {filter} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable ,  Subject } from 'rxjs';
import {
  NotificationEvent,
  EventType,
  NotificationPayload,
  NotificationSuccessCallback,
  NotificationErrorCallback,
  NotificationCompletedCallback
} from '../dataTypes';
import { Subscription } from 'rxjs';

@Injectable()
export class NotificationService {
  private subject: Subject<NotificationEvent> = new Subject<NotificationEvent>();

  constructor() {
// This is intentional
  }

  broadcast(eventName: EventType,
            payload: NotificationPayload,
            message?: string) {
    const event: NotificationEvent = {
      event: eventName,
      payload: payload,
      message: message
    };

    this.subject.next(event);
  }

  subscribeEvent(eventName: EventType,
                 successCallback: NotificationSuccessCallback,
                 errorCallback?: NotificationErrorCallback,
                 completedCallback?: NotificationCompletedCallback): Subscription  {

    const subject = this.subject.pipe(filter(notification => notification.event == eventName));

    return this._subscribe(subject,
                    successCallback,
                    errorCallback,
                    completedCallback);
  }

  subscribe(successCallback: NotificationSuccessCallback,
            errorCallback?: NotificationErrorCallback,
            completedCallback?: NotificationCompletedCallback) {
    this._subscribe(this.subject,
                    successCallback,
                    errorCallback,
                    completedCallback);
  }

  _subscribe(subject: Observable<NotificationEvent>,
             successCallback: NotificationSuccessCallback,
             errorCallback?: NotificationErrorCallback,
             completedCallback?: NotificationCompletedCallback): Subscription {
    return subject.subscribe(successCallback,
                      errorCallback,
                      completedCallback);
  }
}
