import { Injectable } from '@angular/core';
import { ProjectService } from './project.service';
import { UserService } from './user.service';
import { NotificationService } from './notification.service';
import moment from 'moment';
import {PROJECT_COLUMN_DATA, EVENT_TYPES, PlatformData} from '../dataTypes';
import { Story } from '../models/story';
import { Project } from '../models/project';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API_ROUTES } from '../apiRoutes';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import {map} from 'rxjs/operators';

@Injectable()
export class SharedService {
  showprojectAdminForm = false;
  showUserForm = false;
  showUserEditFormInAdmin = false;
  userIdForEditFormInAdmin = undefined;
  isGetUserSuccessForEditInAdmin = false;
  initialCoulmn;
  mentionUsers = [];
  visibleIds = [];
  accessTypeOfImageAttachment = ['png', 'jpg', 'jpeg', 'gif'];
  accessTypeOfDocAttachment = ['pdf', 'doc', 'docx', 'xls', 'csv'];
  accessTypeOfVideoAttachment = ['mp4', 'mov', 'webm', 'mkv','ogg', 'wmv'];
  newPosition: number;
  public sliderProgress = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
  uploaderArr: Array<any> = [];
  attachOnlyArr: Array<any> = [];
  attachmentPreviewUrls: Array<any> = [];

  constructor(private projectService: ProjectService,
    public notificationService: NotificationService,
     private userService: UserService,
     public http: HttpClient) {
  }

  getCMTstory(cmtStoryId) {
    const httpOptions = {  headers:  new HttpHeaders()};
    httpOptions.headers =  httpOptions.headers.set('Authorization', 'Basic ' + btoa(environment.cmtAPIKey + ':' + environment.cmtSecretKey));
    const API = environment.cmtAPIBase + API_ROUTES.GET_CMT_STORY(cmtStoryId);
    return this.http.get(API, httpOptions).pipe(
      map(res => this.parseResponse(res)));
  }
  public parseResponse(res: any): any {
    return res;
  }

  restrictNumeric(e) {
    let input;
    if (e.metaKey || e.ctrlKey) {
      return true;
    }
    if (e.which === 32) {
      return false;
    }
    if (e.which === 0) {
      return true;
    }
    if (e.which < 33) {
      return true;
    }
    input = String.fromCharCode(e.which);
    return !!/[\d\s]/.test(input);
  }

  parseQueryParams(url, filter) {
    let apiUrl = '';
    for(let key in filter) {
      let i = Object.keys(filter).indexOf(key);
      if(i === 0) {
        apiUrl = apiUrl + '?';
        apiUrl = apiUrl + key + '=' + filter[key];
      } else {
        apiUrl = apiUrl + '&' + key + '=' + filter[key];
      }
    }
    return url + apiUrl;
  }

  getStoryById(storyId, stories) {
    return stories.filter((story) => story.id == storyId)[0];
  }

  closeQuickAction(project: Project) {
    const selStory: Story = project.selectedStories[0];
    if (selStory) {
      selStory.isSelected = false;
      selStory.isQuickActionPerformed = false;
      selStory.showDropDown = false;
      selStory.showQuickAct = false;

      const column = this.getColumn(selStory.list_id);
      if (column) {
        column.allSelected = false;
        column.selectedStoryCount = 0;
      }
    }
    project.selectedStories = [];
  }

  getColumn(list_id) {
    return PROJECT_COLUMN_DATA.filter((col) => {
      return col.list_id === list_id;
    })[0];
  }

  findIndexOfColumn(list_id) {
    return PROJECT_COLUMN_DATA.findIndex((column) => {
      return list_id == column.list_id;
    })
  }

  getColumnById(list_id) {
    return PROJECT_COLUMN_DATA.filter((col) => {
      return col.list_id === list_id;
    })[0];
  }

  getCustomColumns() {
    return PROJECT_COLUMN_DATA.filter((col) => {
      return col.list_type === 'custom';
    });
  }


  showQuickAction(project: Project) {
    const selStory: Story = project.selectedStories[0];

    if (selStory) {
      selStory.isSelected = true;
      selStory.showDropDown = false;
      selStory.showQuickAct = true;
      const payload = {
        data: {
          selStory: selStory
        }
      };
      this.notificationService.broadcast(EVENT_TYPES.STORY.SHOW_QUICK_ACTIONS, payload);
    }
  }

  getColumnByColumnIdentifier(columnIdentifier) {
    return PROJECT_COLUMN_DATA.filter((col) => {
      return col.columnIdentifier === columnIdentifier;
    })[0];
  }

  getActiveStory(owned_by_id, storyId?) {
    return this.projectService.currentProject.stories.filter(story => {
      if(storyId) {
        return !!(story.owned_by_id === owned_by_id && story.id != storyId
          && story.current_timer_activity);
      } else {
        return !!(story.owned_by_id === owned_by_id
          && story.current_timer_activity);
      }

    })[0];
  }

  formattTime(time) {
    return time ? 'On ' + moment(time).format('MMM') + ' ' + moment(time).format('DD') + ', ' + moment(time).format('YYYY') + ' at ' + moment(time).format('h:mm A') : '';
  }

  formattTimeWithSeconds(time) {
    return time ? 'on ' + moment(time).format('MMM') + ' ' + moment(time).format('DD') + ', ' + moment(time).format('YYYY') + ' at ' + moment(time).format('h:mm:ss A') : '';
  }

  searchMentionUser(searchText, lastIndex, users, charCount = 0) {
      if(charCount == 0) {
        this.mentionUsers =  this.filterUsers('', users)
      } else if (charCount > 0) {
        let str = searchText.substr(lastIndex + 1, charCount);
        while (str.charCodeAt(str.length - 1) === 10) {
          str = str.substr(0, str.length - 1);
        }
        this.mentionUsers =  this.filterUsers(str, users);
      }
    return this.mentionUsers;
  }

  filterUsers(searchText, users) {
    if(!searchText) {
      return  users;
    }
   return users.filter((user) => {
      return user.name.toLowerCase().includes(searchText.toLowerCase()) || user.email.toLowerCase().includes(searchText.toLowerCase())
    })
  }

  decrementMentionIndex(mentions, event, decrementCount) {
    mentions.forEach((o) => {
      if(o.start >=  event.target.selectionStart) {
        o.start = o.start - decrementCount;
        o.end = o.end - decrementCount;
      }
    })
    return mentions;
  }

  incrementMentionIndex(mentions, event, incrementCount) {
    mentions.forEach((o) => {
      if(o.start >=  event.target.selectionStart) {
        o.start = o.start + incrementCount;
        o.end = o.end + incrementCount;
      }
    })
    return mentions;
  }

  saveParseString(searchText, maintainMentions) {
    maintainMentions.slice().reverse().forEach((o) => {
      if(searchText.substring(o.start + 1, o.end + 1) == o.replace) {
          searchText = searchText.substring(0, o.start + 1) + JSON.stringify(o.value) + searchText.substring(o.end + 1)
      }
    })
    return searchText;
  }

  prepareMentionStr(user, searchText, atTheIndex) {
    const selectedName = user.name.split(' ')[0];
    const userObj = {name: selectedName, email: user.email}
    const commentStr =  searchText.substring(0, atTheIndex)  + '@' +  selectedName + ' ';
    searchText =  commentStr +  searchText.substring(atTheIndex + 1);
    return {searchText: searchText, commentStr: commentStr, userObj: userObj};
  }

  getQueryParam(url) {
    if(url) {
      let queryIndex = url.indexOf('?');
      let queryUrl = url.substr(queryIndex + 1);
      let registerQueryParam = queryUrl.substr(0, queryUrl.indexOf('='));
      return {key: registerQueryParam.trim(), value: queryUrl.substr(queryUrl.indexOf('=') + 1).trim()};
    }
     else {
       return {key: '', value: ''};
     }
  }

  parseProjectId(url) {
    const projectIndex =  url.indexOf('projects/')
    if(url.indexOf('/admin') < 0 && projectIndex > 0) {
      const querySignIndex = url.indexOf('?');
      if(querySignIndex > 0) {
        return url.substring(projectIndex + 9, querySignIndex);
      }
    }
    return '';
  }

  getFileSizeText(data) {
    if (data) {
      let size = data / 1024;
      if (size < 1) {
        return `${data.toFixed(2)}B`;
      }
      if (size < 1024) {
        return `${size.toFixed(2)} KB`;
      } else {
        size = size / 1024;
        return `${size.toFixed(2)} MB`;
      }
    }
  }

  getCommonPropsForSegmentEvents(email?, type?) {
    const props = {
      'user_email': email ? email : ((this.userService.getUser() !== null) ? this.userService.getUser().email : ''),
      'user_type': type ? type : this.userService.getIsUserInternalOrCp()
    };
    return props;
  }

  checkType(item, isUrl?) {
    let doc;
    if(!isUrl) {
      doc = item.document ? item.document.remote_url : item.remote_url;
    } else {
      doc = item;
    }
    if (doc && doc.length > 0) {
      let lastIndexofDot = doc.lastIndexOf('.');
      let type = doc.substr(lastIndexofDot + 1);
      if (this.accessTypeOfImageAttachment.indexOf(type.toLowerCase()) >= 0) {
        return 'image';
      } else if (this.accessTypeOfDocAttachment.indexOf(type.toLowerCase()) >= 0) {
        return 'doc';
      } else if (this.accessTypeOfVideoAttachment.indexOf(type.toLowerCase()) >= 0) {
        return 'video';
      }
      return '';
    }
  }

  /* replace underscore with space */
  formatBBStatus(status): String {
    if (status) {
      return status.replace(/_/g, ' ');
    }
    return '';
  }

  /* Calculate total time tracked on story */
  totalTimeTracked(story) {
    const total_tracked_time = this.storyTrackedNumberTime(story) + this.storyReviewTrackedTimeNumber(story);
    return this.secsToDuration(total_tracked_time);
  }

  /* Calculate owner time tracked on story */
  storyTrackedNumberTime(story) {
    let elapsedTotal = 0;
    let currentElapsed = 0;
    if (story.timer_elapsed_total) {
      elapsedTotal = story.timer_elapsed_total;
    }
    if (story.current_timer_activity) {
      const startedAt = story.current_timer_activity.started_at;
      currentElapsed = moment().diff(startedAt, 'seconds');
    }
    return elapsedTotal + currentElapsed;
  }

  /* Calculate reviewer time tracked on story */
  storyReviewTrackedTimeNumber(story) {
    let elapsedTotal = 0;
    let currentElapsed = 0;
    if (story.reviewer_elapsed_time_total) {
      elapsedTotal = story.reviewer_elapsed_time_total;
    }
    if (story.current_reviewer_timer_activity) {
      const startedAt = story.current_reviewer_timer_activity.started_at;
      currentElapsed = moment().diff(startedAt, 'seconds');
    }
    return elapsedTotal + currentElapsed;
  }

  /* Convert seconds to HH:MM:SS format */
  secsToDuration(secs: number) {
    const duration = moment.duration(secs, 'seconds');
    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    const pad = (number) => number < 10 ? '0' + number : number;
    return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
  }

  getArchivedColumnId(): number {
    const ind = PROJECT_COLUMN_DATA.findIndex(col => col && col.list_code === 'archived');
    return ind >= 0 ? PROJECT_COLUMN_DATA[ind].list_id : -1;
  }

  countArchivedAndHiddenColStories(): number {
    let length = 0;
    PROJECT_COLUMN_DATA.forEach(col => {
      if (col && (!col.visible || col.list_code === 'archived')) {
        length = length + col.stories.length;
      }
    });
    return length;
  }

  timeFormat(time) {
    return moment(time).format('DD MMM YYYY | HH:mm:ss');
  }

  attachFilesInComment(event) {
    Array.from(event.target.files).forEach(file => {
      this.uploaderArr.push(file);
      this.preview(file);
    });
  }

  removeNoteDocument(file) {
    const index = this.uploaderArr.findIndex(item => item === file);
    if (index !== -1) {
      this.uploaderArr.splice(index, 1);
      this.attachmentPreviewUrls.splice(index, 1);
    }
  }

  preview(file) {
    if (this.checkType(file.name, true) === 'image') {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (_event) => {
        this.attachmentPreviewUrls.push(reader.result);
      };
    } else {
      this.attachmentPreviewUrls.push('/assets/images/defaullt-download.png');
    }
  }
}
