import { Component, OnInit,Input, Renderer2 } from '@angular/core';
import { Project } from '../../models/project';
import { ProjectService } from '../../services/project.service';
import { ErrorService } from '../../errorFunction';
import { CustomToastService } from '../../services/custom-toast.service';
import { Story } from '../../models/story';
import { StoryService } from '../../services/story.service';
import { ProjectColumn } from '../../models/column';
import {
  PROJECT_COLUMN_DATA
} from '../../dataTypes';

@Component({
  selector: 'app-accept-reject-popup',
  templateUrl: './accept-reject-popup.component.html',
  styleUrls: ['./accept-reject-popup.component.scss']
})
export class AcceptRejectPopupComponent implements OnInit {
  projectColumns: Array<ProjectColumn> = PROJECT_COLUMN_DATA;
  acceptSelectionPopup: boolean = false;
  @Input('project') project: Project;
  @Input() public popupType: string;
  story: Story;
  waitForLoader = false;

  constructor(
    private errorService: ErrorService,
    public projectService: ProjectService,
    private customToast: CustomToastService,
    private storyService: StoryService,
    private renderer2: Renderer2
  ) { }

  ngOnInit() {
  }

  get selStories() {
    if (this.project.stories.length > 0) {
      return this.project.stories.filter((story) => {
        return story.isSelected === true;
      });
    }
  }

  closeAcceptRejectConfirmation() {
    this.projectService.acceptRejectPopup = false;
    this.projectService.storiesCanBeAccepted = [];
    this.projectService.storiesCannotBeAccepted = [];
    this.projectService.storiesCanBeRejected = [];
    this.projectService.storiesCannotBeRejected = [];
    this.renderer2.removeClass(document.body, 'duplicateStoryPopup-open'); 
  }

  toggleAcceptRejectSelection(event, story: Story) {
    if (this.popupType === 'accept') {
      if (event.target.checked) {
        if (this.projectService.togglestoryAccept.indexOf(story.id) < 0) {
          this.projectService.togglestoryAccept.push(story.id);
        }
      } else {
        for (let i = 0; i < this.projectService.togglestoryAccept.length; i++) {
          if (this.projectService.togglestoryAccept[i] === story.id) {
            this.projectService.togglestoryAccept.splice(i, 1);
          }
        }
      }
    }else{
      if (event.target.checked) {
        if (this.projectService.togglestoryReject.indexOf(story.id) < 0) {
          this.projectService.togglestoryReject.push(story.id);
        }
      } else {
        for (let i = 0; i < this.projectService.togglestoryReject.length; i++) {
          if (this.projectService.togglestoryReject[i] === story.id) {
            this.projectService.togglestoryReject.splice(i, 1);
          }
        }
      }
    }
  }

  getFullEpic(story) {
    let fullEpicTitle = '';
    if (story) {
      story.epics.forEach((epic, index) => {
        if (index != 0) {
          fullEpicTitle = fullEpicTitle + ', ' + epic.title;
        } else {
          fullEpicTitle = epic.title;
        }
      });
    }
    return fullEpicTitle;
  }

  bulkAcceptReject(type) {
    this.waitForLoader = true;
    const data = {
      story_ids : type === 'accepted' ? this.projectService.togglestoryAccept : this.projectService.togglestoryReject,
      state : type,
    };
    this.storyService.bulkAcceptRejectStories(data, this.project.id).subscribe(res => {
      this.closeAcceptRejectConfirmation();
      this.waitForLoader = false;
      this.deSelectAll();
      this.customToast.messages.push({
        id: 'bulk_accept',
        type: 'success',
        class: 'stories_moved',
        title: type === 'accepted' ? 'Bulk Accept' : 'Bulk Reject',
        message: type === 'accepted' ? 'Stories accepted successfully' : 'Stories rejected successfully'
      });
    }, (error) => {
      this.waitForLoader = false;
      this.errorService.errorFunction(error);
    });
  }

  deSelectAll() {
    this.project.selectedStories.forEach((story) => {
      story.isSelected = false;
      story.storyQuickActionClose();
    })
    this.projectService.archived_story_selected = false;
    this.projectService.normal_story_selected = false;
    this.projectColumns.forEach(col => {
      col.allSelected = false;
      col.selectedStoryCount = 0;
    });
    this.project.selectedStories = [];
  }

  SelectedStories(){
    return this.projectService.subtaskSelected ? this.projectService.acceptRejectSubtasks : this.selStories;
  }

}
