import {
    TimelineActivityData
  } from '../dataTypes'; 

  export class ActivityTimeline implements TimelineActivityData {
    title?: string;
    created_on?: string;
    details?: Array<any>;
    expand?:boolean;
  
    constructor(data: TimelineActivityData) {
        this.title = data.title;
        this.created_on = data.created_on;
        this.details = data.details;
        this.expand = false;
    }

    getClassName() {
        return "ActivityTimeline";
    }
  }
  