import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { pick, each, uniq, clone } from 'lodash';
import { CommonEdit } from '../../common/classes';
import { AdminProjectsService } from '../projects.service';
import { NotificationService } from '../../../shared/services/notification.service';
import {ErrorService} from '../../../shared/errorFunction';
import {EVENT_TYPES} from '../../../shared/dataTypes'
import { CustomToastService } from '../../../shared/services/custom-toast.service';
import {SharedService} from '../../../shared/services/shared.service';
import { GUID } from '../../../shared/models/guid';

@Component({
  selector: 'admin-project-edit',
  templateUrl: 'edit.component.html',
  styleUrls: [
    '../../admin.common.scss',
    'edit.component.scss'
  ]
})
export class AdminProjectsEditComponent extends CommonEdit implements OnInit {
  projectId: number;
  formOriginal: any;
  form = {
    name: '',
    start_date: ''
  };
  errors: any;
  isPending: boolean = false;

  constructor(private projectsService: AdminProjectsService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private errorService: ErrorService,
              private notificationService: NotificationService,
              public sharedService: SharedService,
              private customToast: CustomToastService) {
    super();
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.projectId = +params['id'];
      this.getProject();
    });
  }

  getProject() {
    this.isPending = true;
    this
      .projectsService
      .getProject(this.projectId)
      .subscribe(this.onSuccess.bind(this),
      (error) => {
        this.errorService.errorFunction(error);
      });
  }

  postData() {
    const formFields = [
      'name', 'start_date'
    ];
    return {project: pick(this.form, formFields)};
  }

  onSubmit() {
    this.isPending = true;
    this
      .projectsService
      .updateProject(this.projectId, this.postData())
      .subscribe(this.onUpdateSuccess.bind(this), this.onError.bind(this)),
      (error) => {
        this.errorService.errorFunction(error);
      };
  }

  onUpdateSuccess(response) {
    const payload = {
      data: {
        mode: 'update',
        project: this.postData(),
        id: this.projectId
      }
    };
    this.isPending = false;
    GUID.instance.unsetProjectById(this.projectId);
    this.notificationService.broadcast(EVENT_TYPES.SESSION.LOGGED_IN_NEW, payload);
    if (this.router.url == ('/projects/' + this.projectId + '/dashboard')) {
      this.notificationService.broadcast(EVENT_TYPES.PROJECT.EDIT, { data: { project: response.project } });
    }
    this.onSuccess(response);
    this.customToast.messages.push({
      id: 'projectedited',
      type: 'success',
      class: 'edit_project',
      title: 'Changes saved',
      message: 'Changes updated successfully'
    });
    this.sharedService.showprojectAdminForm = false;
  }

  onSuccess(response) {
    this.formOriginal = response.project;
    this.revert();
    this.isPending = false;
  }

  onError(response) {
    this.errors = {};
    each(response, (value, key) => {
      this.errors[key] = uniq(value);
    });
    this.isPending = false;
  }

  revert() {
    this.errors = null;
    this.form = clone(this.formOriginal);
  }
}
