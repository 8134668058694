import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SharedModule } from './../shared/shared.module';
import { ProjectExportComponent } from './project-export.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    FormsModule,
    NgxDaterangepickerMd.forRoot({
      separator: ' To ',
      format: 'DD/MM/YYYY'
    })
  ],
  declarations: [
    ProjectExportComponent
  ],
  exports: [
    ProjectExportComponent
  ]
})
export class ProjectExportModule { }
