import { PartnerData } from '../dataTypes';
import { GUID } from './guid';
import { BaseModel } from './baseModel';

export class Partner extends BaseModel implements PartnerData {
  id: number;
  company_name: string;
  name: string;
  website_link: string;
  frontend_url: string;
  code: string;
  logo_url: string;
  allowed_user_domain: Array<string>;

  static getPartner(data: PartnerData): Partner {
    const partner = GUID.instance.findPartnerByID(data.id);

    if(partner) {
      return partner;
    } else {
      return new Partner(data);
    }
  }

  constructor(data: PartnerData) {
    super(data);

    this.id = data.id;
    this.company_name = data.company_name;
    this.allowed_user_domain = data.allowed_user_domain;
    this.name = data.name;
    this.website_link = data.website_link;
    this.frontend_url = data.frontend_url;
    this.code = data.code;
    this.logo_url = data.logo_url;
  }

  toJSON(): PartnerData {
    return {
      id: this.id,
      company_name: this.company_name,
      code: this.code,
      name: this.name,
      frontend_url: this.frontend_url,
      website_link: this.website_link,
      allowed_user_domain: this.allowed_user_domain,
      logo_url: this.logo_url
    };
  }

  getClassName() {
    return "Partner";
  }

}
