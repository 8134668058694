<div [ngClass]="['epic', 'epic-' + epic?.id]" [attr.data-epic-uid]="epic?.guid" [class.epicHover]="mouseOvered" (mouseenter)="mouseOvered=true" (mouseleave)="mouseOvered=false">
  
  <div class="epic-content" [class.featureFilterSelected]="getSelectedEpic(epic.id)" (click)="applyFilterOnStoryCountClick('feature', epic.id)" [class.cursorPointer]="project && project.dashboard_project_id != null && epic.builder_feature_id != null">
    <div class="ifInactive" *ngIf="epic.status === 'inactive'"></div>
    <div class="ifInactive-text" *ngIf="epic.status === 'inactive'">Inactive</div>
    <div class="minCard-top" [ngClass]="['feature-' + epic.bb_status]" *ngIf="project && project.external() && epic && epic.isSystemFeature() && epic.bb_status">
    <div class="progress-info" *ngIf="project.progress_available" #showProgressHover (mouseover)="progressPercentageHover()">
        <span class="progressPercentageLabel">Progress</span>
        <span class="progressPercentage">{{epic?.progress.total}}%</span>
        
        <div class="progress-info-tooltip" #progressTooltip>
          <p>Buildcard progress (%) is a weighted average from all Buildcard phases.</p>
          <p *ngIf="epic?.progress.phase_weightage">
            ( <span *ngIf="epic?.progress.phase_weightage.pr">Roadmap: {{epic?.progress.phase_weightage.pr}}%, </span>
             <span *ngIf="epic?.progress.phase_weightage.design">Design: {{epic?.progress.phase_weightage.design}}%, </span>
             <span *ngIf="epic?.progress.phase_weightage.fb">MVP/Custom Phase/Full Build: {{epic?.progress.phase_weightage.fb}}%</span> )
          </p>
          <div class="progress-blocks" *ngIf="epic?.progress && epic.progress.phases.length > 0">
            <div *ngFor="let phase of epic?.progress.phases">
              <div class="progress-outer" [ngClass]="[getphaseClass(phase.name)]">
                <div class="progress-detail">
                  <div class="left-info">
                    <em class="dot"></em>{{phase.name}}
                  </div>
                  <div class="right-info">{{phase.progress}}%</div>
                </div>
                <div class="feature-progressbar has-tags">
                  <div class="actual-progress" *ngIf="designPhase(phase)" [style.width.%] = "phase.progress"></div>
                  <div *ngIf="!designPhase(phase)">
                    <div class="actual-progress" [ngClass]="{'no-core': !phase.core || phase.core < 20}" [style.width.%] = "phase.core">
                      <span class="progress-tag">Core({{phase.core}}%)</span>
                    </div>
                    <div class="actual-progress secondary-progress" [ngClass]="{'no-custom': phase.custom === 0}" [ngStyle]="{'left': 'calc(' + phase.core + '% - 2px)', 'width': 'calc(' + phase.custom + '% + 2px)'}">
                      <span class="progress-tag">Custom({{phase.custom}}%)</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="certification-tag" *ngIf="project.liteCertified(epic)">
        <em class="iconb-certified-ribbon"></em>
        <div class="elementHover">Lite Certified</div>
      </div>
    </div>
    
    <div class="minCard-top" *ngIf="project && project.external() && !epic.bb_status && project.progress_available">
      <div class="progress-info" #showProgressHover (mouseover)="progressPercentageHover()">
        <span class="progressPercentageLabel">Progress</span>
        <span class="progressPercentage">{{epic?.progress.total}}%</span>
        
        <div class="progress-info-tooltip" #progressTooltip>
          <p>Buildcard progress (%) is a weighted average from all Buildcard phases.</p>
          <p *ngIf="epic?.progress.phase_weightage">
            ( <span *ngIf="epic?.progress.phase_weightage.pr">Roadmap: {{epic?.progress.phase_weightage.pr}}%, </span>
             <span *ngIf="epic?.progress.phase_weightage.design">Design: {{epic?.progress.phase_weightage.design}}%, </span>
             <span *ngIf="epic?.progress.phase_weightage.fb">MVP/Custom Phase/Full Build: {{epic?.progress.phase_weightage.fb}}%</span> )
          </p>
          <div class="progress-blocks" *ngIf="epic?.progress && epic?.progress.phases.length > 0">
            <div *ngFor="let phase of epic?.progress.phases">
              <div class="progress-outer" [ngClass]="[getphaseClass(phase.name)]">
                <div class="progress-detail">
                  <div class="left-info">
                    <em class="dot"></em>{{phase.name}}
                  </div>
                  <div class="right-info">{{phase.progress}}%</div>
                </div>
                <div class="feature-progressbar has-tags">
                  <div class="actual-progress" *ngIf="designPhase(phase)" [style.width.%] = "phase.progress"></div>
                  <div *ngIf="!designPhase(phase)">
                    <div class="actual-progress" [ngClass]="{'no-core': !phase.core || phase.core < 20}" [style.width.%] = "phase.core">
                      <span class="progress-tag">Core({{phase.core}}%)</span>
                    </div>
                    <div class="actual-progress secondary-progress" [ngClass]="{'no-custom': phase.custom === 0}" [ngStyle]="{'left': 'calc(' + phase.core + '% - 2px)', 'width': 'calc(' + phase.custom + '% + 2px)'}">
                      <span class="progress-tag">Custom({{phase.custom}}%)</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="certification-tag" *ngIf="project.liteCertified(epic)">
        <em class="iconb-certified-ribbon"></em>
        <div class="elementHover">Lite Certified</div>
      </div>
    </div>

    <span class="feature-addNewStory showToClick" *ngIf="epic?.status === 'active' && policyService.expertAsQA(project) && !policyService.projectAdmin(project)">
      <em class="iconb-plusIcon" (click)="projectService.checkCreateConfirmation('new_story', epic)"></em> 
      <span class="epicMinCardHover">Add user story</span>
    </span>

    <span class="feature-addNewStory showToClick" *ngIf="epic?.status === 'active' && policyService.expertAsQA(project) && policyService.projectAdmin(project)">
      <em class="iconb-plusIcon" [ngClass]="{'menu-visible': storyBugMenuVisible}" (click)="storyBugMenuToggle($event)"></em> 
      <div class="type-selection-menu" [@fadeInOutVertical] *ngIf="storyBugMenuVisible">
        <span class="type" (click)="projectService.checkCreateConfirmation('new_story', epic)"><em class="iconb-story"></em>Add Story</span>
        <span class="type" (click)="projectService.checkCreateConfirmation('new_bug', epic)"><em class="iconb-bug"></em>Add Bug</span>
      </div>
    </span>

    <div class="customCheckForStorySelection" [ngClass]="{'story_writting_done':(epic.story_completion || (epic.draft_stories_count > 0))}" *ngIf="project && this.projectService.current_user_pod_role && project.external()">
      <span class="check-toggle" [ngClass]="{'checked': epic.story_completion}" (click)="loadStoryCreatedForFeature(epic.id)"></span>
      <span class="epicMinCardHover" *ngIf="(epic.draft_stories_count > 0) && !epic.story_completion">
        You cannot mark this feature as done writing<br/> user stories because it has 1 or more stories <br/>that are not written in a proper format.
      </span>
      <span class="epicMinCardHover" *ngIf="epic.story_completion">
        Squad members have confirmed all user <br/> stories linked to this feature are complete.
      </span>
      <span class="epicMinCardHover" *ngIf="(epic.draft_stories_count === 0) && !epic.story_completion">
        Please tick this box to confirm all user <br/> stories for this feature are complete, <br/> unlocking expert allocation.
      </span>
    </div>

    <div class="epic-details">
      <div class="title">{{epic?.title}}
        <span *ngIf="epic?.studio_feature_count > 0"> ({{epic?.studio_feature_count}})</span>
      </div>
      <div class="epic-briefDescription" *ngIf="project && project.dashboard_project_id != null && epic.builder_feature_id != null && epic.featureDetailsData && (epic.featureDetailsData.attachments || epic.featureDetailsData.description?.length > 0 || epic.featureDetailsData.notes?.length > 0 || epic.featureDetailsData.video_urls?.length > 0)">
        <div class="featuer-notes-label" (click)="toggleDetailViewMode('360_feature')">
          <em class="iconb-feature-notes"></em>Feature Notes 
        </div>
      </div>
      <div class="epic-briefDescription" *ngIf="project && project.dashboard_project_id == null && epic.internal">
        <div class="featuer-notes-label" (click)="openUpdateEpicPopup()">
          <em class="iconb-feature-notes"></em>Feature Notes 
        </div>
      </div>
      <div class="epic-briefDescription" *ngIf="project && project.dashboard_project_id == null && !epic.internal && epic.description?.length > 0">
        <div class="featuer-notes-label" (click)="toggleDetailViewMode('clickup_feature')">
          <em class="iconb-feature-notes"></em>Feature Notes 
        </div>
      </div>
      <div class="allStories-progress" *ngIf = "((epic.stories_count > 0) && (epicService.epicEstimateStatus == false))">
        <div class="allStoriesProgressBar">
          <span class="allStories-progressMade" [style.width.%]="epic.accepted_stories_count*100/epic.stories_count"></span>
        </div>
        <div class="allStories-counterbox">{{epic.accepted_stories_count}}/{{epic.stories_count}}
          <span class="epicMinCardHover">This metric displays the overall count of accepted tickets, including subtasks, in relation to the total number of tickets created for this feature.</span>
        </div>
      </div>
      <div class="allStories-progress" #getEstimateBarCordinate *ngIf = "project && project.dashboard_project_id != null && epic.builder_feature_id != null && epicService.epicEstimateStatus" (mouseenter)="openEstimateInfo()" (mouseleave)="closeEstimateInfo()">
        <div class="allStoriesProgressBar" >
          <!--for developers:: please add calculated width and background according to story 1) for green "#52C41A", 2) for blue "#1890FF", and 3) For red "#FF4D4F"-->
          <span class="allStories-progressMade" *ngIf="epic.total_estimate < epic.trends?.time_low"
                [style.width.%]="epic.total_estimate*100/epic.trends?.time_high" style="background-color: #52C41A"></span>
          <span class="allStories-progressMade" *ngIf="epic.total_estimate >= epic.trends?.time_low && epic.total_estimate < epic.trends?.time_mid"
                [style.width.%]="epic.total_estimate*100/epic.trends?.time_high" style="background-color: #1890FF"></span>
          <span class="allStories-progressMade" *ngIf="epic.total_estimate >= epic.trends?.time_mid && epic.total_estimate < epic.trends?.time_high"
                [style.width.%]="epic.total_estimate*100/epic.trends?.time_high" style="background-color: #FF4D4F"></span>
          <span class="allStories-progressMade" *ngIf="epic.total_estimate >= epic.trends?.time_high"
                [style.width.%]="100" style="background-color: #FF4D4F"></span>
        </div>
        <div class="allStories-counterbox"><em class="iconb-clock"></em> {{epic.total_estimate}}/{{epic?.trends?.time_high ? epic?.trends?.time_high : 0}}</div>
        <div #getEstimateInfoCordinate class="estimateProgressInfo" [@fadeInOut] *ngIf="estMouseOvered">
          <div class="est-progressBarTitle">Time Limit</div>
          <div class="est-progressBar">
            <div class="est-subTitle">Min</div>
            <div class="est-progressBarWrapper">
              <div class="est-allStoriesProgressBar">
                <span class="est-allStories-progressMade" [style.width.%]="epic.trends?.time_low*100/epic.trends?.time_high" style="background-color: #52C41A"></span>
              </div>
              <div class="est-allStories-counterbox"><em class="iconb-clock"></em> {{epic.trends?.time_low ? epic.trends?.time_low : 0}} hr</div>
              <div class="est-allStories-counterbox showTrend showPositive" *ngIf="epic.trends && epic.trends.current_time_low != null && epic.trends.current_time_low < epic.trends?.time_low">
                <em class="iconb-dd-down-fill showUp"></em> {{epic.trends.current_time_low}} hr
              </div>
              <div class="est-allStories-counterbox showTrend showNegative" *ngIf="epic.trends && epic.trends.current_time_low != null && epic.trends.current_time_low > epic.trends?.time_low">
                <em class="iconb-dd-down-fill showDown"></em> {{epic.trends.current_time_low}} hr
              </div>
            </div>
          </div>
          <div class="est-progressBar">
            <div class="est-subTitle">Mid</div>
            <div class="est-progressBarWrapper">
              <div class="est-allStoriesProgressBar">
                <span class="est-allStories-progressMade" [style.width.%]="epic.trends?.time_mid*100/epic.trends?.time_high" style="background-color: #1890FF"></span>
              </div>
              <div class="est-allStories-counterbox"><em class="iconb-clock"></em> {{epic.trends?.time_mid ? epic.trends?.time_mid : 0}} hr</div>
              <div class="est-allStories-counterbox showTrend showPositive" *ngIf="epic.trends && epic.trends.current_time_mid != null && epic.trends.current_time_mid < epic.trends?.time_mid"> <em class="iconb-dd-down-fill showUp"></em> {{epic.trends.current_time_mid}} hr</div>
              <div class="est-allStories-counterbox showTrend showNegative" *ngIf="epic.trends && epic.trends.current_time_mid != null && epic.trends.current_time_mid > epic.trends?.time_mid"> <em class="iconb-dd-down-fill showDown"></em> {{epic.trends.current_time_mid}} hr</div>
            </div>
          </div>
          <div class="est-progressBar">
            <div class="est-subTitle">Max</div>
            <div class="est-progressBarWrapper">
              <div class="est-allStoriesProgressBar">
                <span class="est-allStories-progressMade" [style.width.%]="100"  style="background-color: #FF4D4F"></span>
              </div>
              <div class="est-allStories-counterbox"><em class="iconb-clock"></em> {{epic.trends?.time_high ? epic.trends?.time_high : 0}} hr</div>
              <div class="est-allStories-counterbox showTrend showPositive" *ngIf="epic.trends && epic.trends.current_time_high != null && epic.trends.current_time_high < epic.trends?.time_high"><em class="iconb-dd-down-fill showUp"></em> {{epic.trends.current_time_high}} hr</div>
              <div class="est-allStories-counterbox showTrend showNegative" *ngIf="epic.trends && epic.trends.current_time_high != null && epic.trends.current_time_high > epic.trends?.time_high"><em class="iconb-dd-down-fill showDown"></em> {{epic.trends.current_time_high}} hr</div>
            </div>
          </div>
        </div>
      </div>
      <div class="bb-info-wrapper" *ngIf="project && project.external() && epic && epic.isSystemFeature()">
        <div class="bb-row" *ngIf="epic.bb_design_infos?.length > 0">
          <div class="bb-label">BB Design</div>
          <div class="bb-right-info">
            <div class="info-tag"  *ngFor="let design of epic.bb_design_infos | sortByDevice" [ngClass]="[getBBDesignClass(bbStatusPlatform(design.device).bb_status)]"><em [ngClass]="[getIconClass(design.device)]"></em><span class="info-label">{{ getFormattedBBStatus(bbStatusPlatform(design.device).bb_status) }}</span></div>
          </div>
        </div>
        <div class="bb-row" *ngIf="epic.bb_status">
          <div class="bb-label">BB Code</div>
          <div class="bb-right-info">
            <div class="info-tag is-expanded" [ngClass]="[getBBDesignClass(epic.bb_status)]"><span class="info-label">{{ getFormattedBBStatus(epic.bb_status) }}</span></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="epicStoryDes-popup" *ngIf="detailViewMode && featureDetailsData">
  <div class="epicStoryDes-backdrop" (click)="toggleDetailViewMode()"></div>
  <div class="epicStoryDes-popupWrapper">
    <em class="iconb-close closeEpicStoryPopup" (click)="toggleDetailViewMode()"></em>
    <div class="bulkAction-Loader" *ngIf="startLoaderOnEpicDetailView">
      <img src="../../assets/images/storyBoardLoader.gif" alt="loader"/>
    </div>
    <div class="epicTitle">{{featureDetailsData?.name}}</div>
    <div class="epicContent-wrapper">
      <div class="contentTitle" *ngIf="featureDetailsData?.description">Description</div>
      <div class="contentDescription" *ngIf="featureDetailsData?.description">
        <angular-editor [config]="editorConfig" [(ngModel)]="featureDetailsData.description"></angular-editor>
      </div>
      <div class="contentTitle2" *ngIf="featureDetailsData?.notes && featureDetailsData.notes.length > 0">Notes</div>
      <div class="contentDescription" *ngIf="featureDetailsData?.notes && featureDetailsData.notes.length > 0">
        <div *ngFor="let note of featureDetailsData.notes">
          <angular-editor [config]="editorConfig" [(ngModel)]="note.description"></angular-editor>
        </div>
      </div>
      <div class="contentTitle2" *ngIf="featureDetailsData && featureDetailsData?.video_urls && featureDetailsData.video_urls.length > 0">Video Library for {{epic.title}}</div>
      <div class="epicAttachment-wrapper" *ngIf="featureDetailsData && featureDetailsData?.video_urls && featureDetailsData.video_urls.length > 0">
        <ngx-slick-carousel class="carousel"
                            #slickModal="slick-carousel"
                            [config]="slideConfig">
          <div ngxSlickItem *ngFor="let item of featureDetailsData.video_urls" class="slide epicAttachment-list">
            <div class="videoContainer" *ngIf = "item[0]" (click)="openVideoPopup(item[0], true)">
              <div class="att-type">Video</div>
              <em class="iconb-play-circle"></em>
              <span class="elementHover">{{formatTime(item[1])}}</span>
            </div>
            <div class="assetsInfo" *ngIf = "item[0]">
              <span class="videoName">{{getVideoName(item[0])}}</span>
            </div>
          </div>
        </ngx-slick-carousel>
      </div>
      <div class="contentTitle2" *ngIf="featureDetailsData && featureDetailsData?.attachments && featureDetailsData.attachments.length > 0">Attachments</div>
      <div class="epicAttachment-wrapper" *ngIf="featureDetailsData && featureDetailsData?.attachments && featureDetailsData.attachments.length > 0">
        <ngx-slick-carousel class="carousel"
                            #slickModal="slick-carousel"
                            [config]="slideConfig">
          <div ngxSlickItem *ngFor="let attachment of featureDetailsData?.attachments" class="slide epicAttachment-list">
            <div class="assetsContainer">
              <div class="att-type">{{sharedService.checkType(attachment?.url, true) == 'image' ? 'Asset' : 'Document'}}</div>
              <span class="asst-container" (click)="openAuthorPopup(attachment?.url)">
              <img *ngIf="sharedService.checkType(attachment?.url, true) == 'image'" src="{{ attachment?.url }}" alt="loader"/>
              <a *ngIf = "sharedService.checkType(attachment?.url, true) == 'doc' || sharedService.checkType(attachment?.url, true) == ''" class="gotoImage" href="{{attachment?.url}}" target="_blank" rel="noopener">
                  <img class="default-attachment" src="/assets/images/defaullt-download.png" alt="loader"/>
              </a>
              <div *ngIf = "sharedService.checkType(attachment?.url, true) == 'video'" class="videoContainer" (click)="openVideoPopup(attachment)">
                  <div class="att-type">Video</div>
                  <em class="iconb-play-circle"></em>
                </div>
            </span>
            </div>
            <div class="assetsInfo">
              <span class="assetsName">{{attachment?.name}}</span>
              <a href="{{attachment?.url}}" class="gotoImage" target="_blank" rel="noopener"><em class="iconb-download"></em></a>
            </div>
          </div>
        </ngx-slick-carousel>
      </div>
    </div>
  </div>
</div>

<div class="videoPopup-wrapper" *ngIf="showVideo">
  <div class="videoPopup-backdrop"></div>
  <em class="iconb-close closeVideoPopup" (click)="closeVideoPopup()"></em>
  <div class="videoPopup">
    <video width="640px" controls autoplay>
      <source src="{{vURL}}" type='video/mp4' />
    </video>
  </div>
</div>

<div *ngIf="openAuthor" class="authorBioWrapper" [ngClass]="{'openAuthorBio' : openAuthor}">
    <div class="backdropClose" (click)="closeAuthorPopup()"></div>
    <em class="closeFun iconb-close" (click)="closeAuthorPopup()">close</em>
    <div class="authorContent">
      <img src="{{openItemDoc}}" alt="loader"/>
    </div>
  </div>

<!--For developers: Add Feature popup-->
<div class="addNewEpic-popup" *ngIf = "updateEpic">
  <div class="addNewEpic-backdrop" (click) = "closeUpdateEpicPopup()"></div>
  <div class="addNewEpic-popupWrapper">
    <div class="topSection">
      <div class="topTitle">Update feature</div>
      <span class="addEpic-label">Title</span>
      <input type="text" class="addEpic-inputTitle" [(ngModel)]="updatedFeatureTitle" placeholder="Enter title"/>
    </div>
    <div class="addEpic-description">
      <span class="addEpic-label">Description</span>
      <div class="editor-container">
        <div class="editor"></div>
      </div>
    </div>
    <div class="actionButtons">
      <button type="button" class="actionButton submit" (click)="updateFeature()"
              [disabled]="epic?.status === 'inactive' || updatedFeatureTitle?.trim()?.length == 0 || disableUpdateFeature || (updatedFeatureTitle.trim() == epic.title.trim() && updatedFeatureDescription.trim() == epic.description.trim())">Update</button>
      <button type="button" class="actionButton cancel" (click) = "closeUpdateEpicPopup()">Cancel</button>
    </div>
  </div>
</div>

<!-- pop up for checking if CPE done story writting for Feature -->
<div *ngIf="showFeatureStories" class="commonPopUp">
  <div class="commonPopUp-backdrop" (click)="closeloadStoryCreatedForFeature()"></div>
  <div class="commonPopUp-wrapper toListStories">
    <div class="common-Loader commonPopup-Loader" *ngIf="disableUpdateFeature">
      <img src="../../assets/images/storyBoardLoader.gif" alt="loader"/>
    </div>
    <div class="commonPopUp-title">Feature Stories</div>
    <div class="commonPopUp-text4">Please confirm all user stories for <span class="epic_title">{{epic?.title}}</span> have been created. 
      This will unlock expert allocation for this feature. Once confirmed, 
      you will have to let us know why you're adding more user stories.</div>
      <div class="storiesList-wrapper" 
      infiniteScroll
      [scrollWindow]="false"
      [infiniteScrollDisabled]="!isScrollFetchAvailable()"
      (scrolled)="getMoreParentStories()">
        <div class="storiesList-Loader" *ngIf="storiesLoader">
          <img src="../../assets/images/storyBoardLoader.gif" alt="loader"/>
        </div>
        <div class="if_no_content" *ngIf="feature_stories.length === 0 && !storiesLoader">No story available</div>
        <div class="storiesList" (click)="goToStory(feature_story.id)" *ngFor="let feature_story of feature_stories">
          [#{{feature_story.id}}] {{feature_story.title}}
          <span class="forPhase" *ngIf="feature_story.phase">{{feature_story.phase}}</span>
        </div>
        <div class="featureStoryList-Loader" *ngIf="isPending">
          <img src="../../assets/images/storyBoardLoader.gif" alt="loader"/>
        </div>
      </div>
    <div class="commonPopUp-actionBtn">
      <button type="button" class="commonPopUp-submit-s" (click)="allStoriesCreatedOfFeature()" [disabled]="storiesLoader || (feature_stories.length === 0)">Yes, I confirm</button>
      <span class="commonPopUp-cancel-s" (click)="closeloadStoryCreatedForFeature()">Cancel</span>
    </div>
  </div>
</div>