import { BaseModel } from './baseModel';
import { Project } from './project';
import { Story } from './story';
import { GUID } from './guid';
import { EpicData, DataManipulationOptions } from '../dataTypes';

export class Epic extends BaseModel implements EpicData {
  id: number;
  title: String;
  description?: String;
  project?: Project;
  story_ids?: Array<number> = [];
  notes?: Array<any>;
  updated_at?: Date;
  created_at?: Date;
  isSelected?: boolean;
  internal?: boolean;
  builder_feature_id?: number;
  min_estimate?: number;
  mid_estimate?: number;
  max_estimate?: number;
  estimate?: number;
  tracked_time?: number;
  accepted_stories_count?: number;
  stories_count?: number;
  studio_feature_count = 0;
  trends?: any;
  total_estimate?: number;
  featureDetailsData?: any; // used to save feature detailed data for external projects
  bb_status?: String;
  progress?: any;
  status?: string;
  story_completion?: boolean;
  task_done_count?: number;
  draft_stories_count?: number;
  bb_design_infos?: Array<any>;
  certification_type?: string;
  group_no?: number;

  constructor(data: EpicData) {
    super(data);

    this.id = data.id;
    this.builder_feature_id = data.builder_feature_id;
    this.title = data.title;
    this.internal = data.internal;
    this.featureDetailsData = data.featureDetailsData || '';
    this.status = data.status;
    this.draft_stories_count = data.draft_stories_count;
    if (data.description) {
      this.description = data.description;
    } else if (data.internal) {
      this.description = '**AS A** < User>\n' + '**I WANT TO** < Feature description>\n' +
        '**SO THAT** < Reason description>\n' + '\n' + '**GIVEN** < Conditions>\n' +
        '**WHEN** < Action performed>\n' + '**THEN** <Expected result>';
    }

    if (data.isSelected) {
      this.isSelected = data.isSelected;
    }
    if (data.project) {
      this.project = data.project;
    } else if (data.project_id) {
      this.project = Project.getProject({
        id: data.project_id
      });
    }
    if (data.max_estimate) {
      this.max_estimate = data.max_estimate;
    }
    if (data.mid_estimate) {
      this.mid_estimate = data.mid_estimate;
    }
    if (data.min_estimate) {
      this.min_estimate = data.min_estimate;
    }
    this.estimate = data.estimate;
    this.tracked_time = data.tracked_time;
    this.updated_at = data.updated_at;
    this.created_at = data.created_at;
    this.accepted_stories_count = data.accepted_stories_count;
    this.stories_count = data.stories_count;
    this.story_ids = data.story_ids;
    this.trends = data.trends;
    this.total_estimate = data.total_estimate;
    this.bb_status = data.bb_status;
    this.progress = data.progress;
    if (data.studio_feature_count) {
      this.studio_feature_count = data.studio_feature_count;
    };
    this.story_completion = data.story_completion;
    this.task_done_count = data.task_done_count;
    this.bb_design_infos = data && data.bb_design_infos && data.bb_design_infos.length > 0 ? data.bb_design_infos : data.default_bb_design_infos;
    this.certification_type = data.certification_type;
    this.group_no = data.group_no;
  }

  update(data: EpicData, options: DataManipulationOptions) {
    if (data.id) {
      this.id = data.id;
      GUID.setUIDForObject(this);
    }
    if (data.title && data.title !== this.title) {
      this.title = data.title;
    }
    if (data.description && data.description !== this.description) {
      this.description = data.description;
    }
    if (data.updated_at && data.updated_at !== this.updated_at) {
      this.updated_at = data.updated_at;
    }
    if (data.created_at && data.created_at !== this.created_at) {
      this.created_at = data.created_at;
    }
    if (data.builder_feature_id) {
      this.builder_feature_id = data.builder_feature_id;
    }

    if (data.project) {
      this.project = data.project;
    } else if (data.project_id) {
      this.project = Project.getProject({
        id: data.project_id
      });
    }
  }

  hasStory(story: Story) {
    const index = this.story_ids.findIndex(candidate => candidate == story.id);

    return index !== -1;
  }

  getClassName() {
    return 'Epic';
  }

  get rootColumns(): EpicData {
    let data: EpicData = {
      title: this.title,
      description: this.description
    };

    return data;
  }

  set rootColumns(data: EpicData) {
    this.title = data.title;
    this.description = data.description;
  }

  toJSON(): EpicData {
    return {
      id: this.id,
      title: this.title,
      description: this.description,
      project_id: this.project.id,
      updated_at: this.updated_at,
      created_at: this.created_at
    };
  }

  isSystemFeature(): boolean {
    return !!this.builder_feature_id;
  }
}
