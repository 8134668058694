import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';
// import { MatDialog, MatDialogRef } from '@angular/material';
import { BaseComponent } from '../base.component';
import { TimingUsersService } from '../../services/users.service';
import { TimingUserDetailSessionDialogComponent } from '../../components/user-detail-session-dialog/user-detail-session-dialog.component';
import { ISessionDialogOptions } from '../../interfaces/session-dialog-options.interface';
import { User } from '../../models/user';
import { Project } from '../../models/project';
import { DateRange } from '../../models/date-range';

@Component({
  selector: 'app-admin-timing-user-detail',
  templateUrl: 'user-detail.component.html',
  styleUrls: [
    '../../../admin.common.scss',
    'user-detail.component.scss'
  ]
})
export class TimingUserDetailComponent extends BaseComponent implements OnInit {

  private id: number;
  public weekDays = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];
  public user: User;
  public projects: Project[] = [];
  public dateRange: DateRange;
  public isPending = false;
  // private sessionDialogRef: MatDialogRef<TimingUserDetailSessionDialogComponent>;

  constructor(private usersService: TimingUsersService,
              private route: ActivatedRoute
              // private dialog: MatDialog
  ) {
    super();
  }

  ngOnInit(): void {
    this.id = +this.route.snapshot.paramMap.get('id');
    const from = moment().startOf('isoWeek');
    const to = moment(from).day(+7);
    this.dateRange = new DateRange(from, to);
    this.getUserInfo();
    this.getProjects();
  }

  onDateRangeChanged(dateRange: DateRange): void {
    this.dateRange = dateRange;
    this.getProjects();
  }

  // onSessionDialogOpened(sessionDialogOptions: ISessionDialogOptions, projectId: number): void {
  //   sessionDialogOptions.date = moment(this.dateRange.from)
  //     .add(sessionDialogOptions.daysOffet, 'days').format('DD/MM/YYYY');
  //
  //   sessionDialogOptions.user = this.user;
  //   sessionDialogOptions.projects = this.projects;
  //   this.sessionDialogRef = this.dialog.open(TimingUserDetailSessionDialogComponent, {
  //     data: sessionDialogOptions
  //   });
  //   this.sessionDialogRef
  //     .afterClosed()
  //     .subscribe(data => {
  //       if (data === 'updated') {
  //         this.getProjects();
  //       }
  //     });
  // }

  getUserInfo(): void {
    this.usersService
      .getUser(this.id)
      .subscribe(user => this.user = user);
  }

  getProjects(): void {
    this.isPending = true;
    const filter = {
      project: this.route.snapshot.queryParams.project,
      from: this.dateRange.formatedFrom,
      to: this.dateRange.formatedTo
    };
    this.usersService
      .getProjects(this.id, filter)
      .subscribe(
        projects => {
          this.projects = projects;
          this.isPending = false;
        });
  }
}
