import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AdminProjectsService } from '../projects.service';
import { CommonBase } from '../../common/classes';
import { CustomToastService } from '../../../shared/services/custom-toast.service';
import { ErrorService } from '../../../shared/errorFunction';
import { EVENT_TYPES } from '../../../shared/dataTypes';
import { NotificationService } from '../../../shared/services/notification.service'
import {SharedService} from '../../../shared/services/shared.service';
import { GUID } from '../../../shared/models/guid';

@Component({
  selector: 'admin-projects-show',
  templateUrl: 'show.component.html',
  styleUrls: [
    '../../admin.common.scss',
    'show.component.scss'
  ]
})
export class AdminProjectsShowComponent extends CommonBase implements OnInit {
  projectId: number;
  project: any;
  OpenPopUp:boolean = false;
  waitForLoader:boolean = false;
  elem: any;
  projectName: '';
  ifDataIsLoading:boolean = false;
  startLoader = false;

  constructor(private projectsService: AdminProjectsService,
              private router: Router,
              private customToast: CustomToastService,
              private errorService: ErrorService,
              private notificationService: NotificationService,
              public sharedService: SharedService,
              private activatedRoute: ActivatedRoute) {
    super();
  }

  ngOnInit() {
    this.ifDataIsLoading = true;
    this.activatedRoute.params.subscribe(params => {
      this.projectId = +params['id'];
      this.getProject();
    })
  }

  openProjectDeletePopup(data, type) {
    this.OpenPopUp = true;
    this.elem = data;
  }

  closeProjectDeletePopUp() {
    this.OpenPopUp = false;
  }


  getProject() {
    this
      .projectsService
      .getProject(this.projectId)
      .subscribe(response => {
        this.project = this.parseResponse(response).project;
        this.projectName = this.project.name;
        this.ifDataIsLoading = false;
      });
  }

  deleteProject() {
    this.waitForLoader = true;
    this.OpenPopUp = true;
    this.projectsService.destroyProject(this.projectId).subscribe(() => {
      GUID.instance.unsetProjectById(this.projectId);
      this.notificationService.broadcast(EVENT_TYPES.SESSION.LOGGED_IN_NEW, {});
      this.router.navigate(['/admin/projects']);
      this.waitForLoader = false;
      this.OpenPopUp = false;

      this.customToast.messages.push({
        id: 'project_deleted',
        type: 'success',
        class: 'user_delete_info',
        title: 'Project Deleted',
        message: this.projectName + ' Project is deleted successfully'
      });
    }, err => {
      this.errorService.errorFunction(err);
      this.waitForLoader = false;
      this.OpenPopUp = false;
    });
  }

  public parseResponse(res: any): any {
    return res;
  }
}

