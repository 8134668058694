<span class="notif-icon" (click)="openNotiDD();$event.stopPropagation()" [ngClass]="{'focus': openNotificationDD}">
  <em class="iconb-notification"></em>
  <span *ngIf="unreadCount" class="notifications-badge">{{ unreadCount }}</span>
</span>
<div class="notifications-backdrop" *ngIf="openNotificationDD" (click)="closeNotiDD()"></div>
<div class="notifications-container-main" (click)="doNothing = true" [ngClass]="{'show': openNotificationDD}">
  <div class="notificationHeading">
    <span class="headingAndCount">Notifications ({{totalCount?totalCount:0}})</span>
    <div class="checkAllStories" title="Mark all as read" (click)="markAllAsRead()"><em class="iconb-double-check"></em></div>
    <div class="filterDD">
      <span class="selectedOption" (click)="openOptionFilter()">{{ filter_value }}<em class="iconb-dd-down"></em>
      </span>
      <div class="filterDD-backdrop" *ngIf="filterOption" (click)="closeOptionFilter()"></div>
      <div class="selectOptionDD" [ngClass]="{'openFilterOption': filterOption}">
        <span class="selectOptionList" (click)="getNotifications('all','All')">All</span>
        <span class="selectOptionList" (click)="getNotifications('unread','Unread')">Unread</span>
        <span class="selectOptionList" (click)="getNotifications('mentions','Mentions')">Mentions</span>
      </div>
    </div>
  </div>
  <div class="notifications-container"
        infiniteScroll
        [scrollWindow]="false"
        [infiniteScrollDisabled]="!isScrollFetchAvailable()"
        (scrolled)="getOlderNotifications()">
      <div *ngIf="notifications">
        <div class="notification" (click)="markAsRead(notification)" [class.unread]="!notification.read_at" *ngFor="let notification of notifications">
          <span class="notification-icon">
            <span [class]="notificationIconClass(notification)"></span>
          </span>

          <div class="notification-header">
            <span class="project-name" [title]="notification.project?.name">
              {{ notification.project?.name }}
            </span>
            <span class="timestamp" [title]="shortDateTime(notification.created_at)">
              {{ shortDate(notification.created_at) }}
            </span>
          </div>
          <a class="story-name" (click)="goToStory(notification)">
            {{ notification.story.title }}
          </a>
          <div class="notification-body"
              [class.expanded]="notification.expanded"
              (click)="notification.expanded = !notification.expanded">

            <ng-container [ngSwitch]="notification.kind">

              <!-- Notification: new note added to story -->
              <ng-container *ngSwitchCase="'new_note'">
                <span>New comment added to a story by </span>
                <span class="mention">@{{notification.emitter?.name}}</span>.
                <ng-container *ngIf="notification.subject?.note?.note">
                  <span class="caret"></span>
                  <div class="citation" [innerHtml] = "notification.subject?.note.note">
                  </div>
                </ng-container>
              </ng-container>

              <!-- Notification: user was mentioned -->
              <ng-container *ngSwitchCase="'mention'">
                <ng-container *ngIf="!notification.subject">
                  <span>You were mentioned in story by </span>
                  <span class="mention">@{{notification.emitter?.name}}</span>.
                  <ng-container *ngIf="notification.story?.description">
                    <span class="caret"></span>
                    <div class="citation" [innerHtml] = "notification.story.description">
                    </div>
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="notification.subject">
                  <span>You were mentioned in note by </span>
                  <span class="mention">@{{notification.emitter?.name}}</span>.
                  <ng-container *ngIf="notification.subject?.note?.note">
                    <span class="caret"></span>
                    <div class="citation" [innerHtml] = "notification.subject?.note.note">
                    </div>
                  </ng-container>
                </ng-container>
              </ng-container>

              <!-- Notification: story was assigned to user -->
              <ng-container *ngSwitchCase="'story_assign'">
                <span>You were assigned to a story by </span>
                <span class="mention">@{{notification.emitter?.name}}</span>.
                <ng-container *ngIf="notification.story?.description">
                  <span class="caret"></span>
                  <div class="citation" [innerHtml] = "notification.story.description">
                  </div>
                </ng-container>
              </ng-container>

              <!-- Notification: story details was changed -->
              <ng-container *ngSwitchCase="'story_change'">
                <span>Story details was modified by </span>
                <span class="mention">@{{notification.emitter?.name}}</span>
                <ng-container *ngIf="notification.changes && notification.changes.length > 0">
                  <span class="caret"></span>
                  <div class="citation" *ngIf = "notification.changes">
                    <div *ngFor="let change of notification.changes">
                      <ng-container [ngSwitch]="change.field">

                        <ng-container *ngSwitchCase="'position'">
                          <span class="field-name">priority </span>
                          <span>was </span>
                          <span *ngIf="change.valueWas < change.valueNow"
                                class="old-value">
                            decreased
                          </span>
                          <span *ngIf="change.valueWas > change.valueNow"
                                class="new-value">
                            increased
                          </span>
                        </ng-container>

                        <ng-container *ngSwitchDefault>

                          <ng-container [ngSwitch]="change.type">

                            <!-- field was set from null to value -->
                            <ng-container *ngSwitchCase="'set'">
                              <span class="field-name">{{change.field}}</span>
                              <span> set to </span>
                              <span class="new-value" [innerHtml] = "change.valueNow"></span>
                            </ng-container>

                            <!-- field was set from value to null -->
                            <ng-container *ngSwitchCase="'unset'">
                              <span class="field-name">{{change.field}}</span>
                              <span> unset from </span>
                              <span class="old-value" [innerHtml] = "change.valueWas"></span>
                            </ng-container>

                            <!-- field was changed -->
                            <ng-container *ngSwitchCase="'change'">
                              <span class="field-name">{{change.field}}</span>
                              <span> changed from </span>
                              <span class="old-value" [innerHtml] = "change.valueWas"></span>
                              <span> to </span>
                              <span class="new-value" [innerHtml] = "change.valueNow"></span>
                            </ng-container>

                          </ng-container>

                        </ng-container>

                      </ng-container>
                    </div>
                  </div>
                </ng-container>
              </ng-container>

              <!-- Notification: story added to epic -->
              <ng-container *ngSwitchCase="'add_to_epic'">
                <span>Story was added to </span>
                <a [href]="epicPath(notification.subject)">{{notification.subject?.title}}</a>
                <span> feature by </span>
                <span class="mention">@{{notification.emitter?.name}}</span>.
              </ng-container>

              <!-- Notification: story removed from epic -->
              <ng-container *ngSwitchCase="'remove_from_epic'">
                <span>Story was removed from </span>
                <a [href]="epicPath(notification.subject)">{{notification.subject?.title}}</a>
                <span> feature by </span>
                <span class="mention">@{{notification.emitter?.name}}</span>.
              </ng-container>

            </ng-container>

          </div>
        </div>
        <div class="mainProjectList-Loader" *ngIf="isPending">
          <img src="../../assets/images/storyBoardLoader.gif" alt=""/>
        </div>
        <div class="no-notifications" *ngIf="!moreAvailable && notifications.length">
          No more notifications.
        </div>
      </div>
      <div class="no-notifications" *ngIf="!isPending && !notifications?.length">
        <div class="noNotificationsInner">
            <!-- <span class="glyphicon glyphicon-bell"></span> -->
            <img src="../../assets/images/no_notification_icon.png" alt="">
            <p>All caught up!</p>
        </div>
      </div>
    </div>
</div>


<div class="addNewProject-popupWrapper addMember" *ngIf="sharedService.showUserForm">
    <div class="addNewProject-popUpBackdrop" (click)="sharedService.showUserForm = false"></div>
    <div class="addNewProject-content">
        <div class="addNewProject-top">Add New Member</div>
        <app-member></app-member>
    </div>
  </div>

<div class="addNewProject-popupWrapper addMember" *ngIf="sharedService.showUserEditFormInAdmin" [hidden]="!sharedService.isGetUserSuccessForEditInAdmin">
  <div class="addNewProject-popUpBackdrop" (click)="closeUserEditFormInAdmin()"></div>
  <div class="addNewProject-content">
    <div class="addNewProject-top">Edit Member</div>
    <admin-users-edit></admin-users-edit>
  </div>
</div>
