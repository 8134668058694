<div class="importDash-Wrapper">
  <div class="col-lg-12">
    <div class="importWrapper">
      <div class="genralBlock">
        <div class="genralBlock-title">Import CSV</div>
        <div class="importContentBlock">
          <div class="importContentBlock-left">
            <p>Please specify a CSV file to import from.</p>
          </div>
          <div class="importContentBlock-right putTextRight">
            <!--<button type="button" class="help-button">-->
              <!--<em class="material-icons">help</em> Help-->
            <!--</button>-->
            <a class="viewSampleText" (click) = "downloadSample()" href="javascript:void(0)">View Sample CSV</a>
          </div>
          <!--<div class="importContentBlock-full putTextRight">-->
            <!--<a class="viewSampleText" (click) = "downloadSample()" href="javascript:void(0)">View Sample CSV</a>-->
          <!--</div>-->

          <div class="importContentBlock-full">
            <div appDragDropFile *ngIf = "!importedFile"
                 [ngClass]="{'nv-file-over': hasBaseDropZoneOver}"
                 (files)="draggedFileToImport($event)"
                 class="importDropZone">
              <span class="uploaderIcon">
                <img src="../../assets/images/import-uploadIcon.png" alt="upload"/>
              </span>
              <span class="importDropZone-text">Drag and drop files here</span>
              <span class="importDropZone-divider">or</span>
              <label class="importButton">
                Browse files
                <input type="file" #importAttachmentInput id="file" class="hidden-file" (click)="importAttachmentInput.value = null" value="" (change)="fileToImport($event)" />
              </label>
              <div *ngIf="importuploadProcessStarted" class="import-loadingWrapper">
                <div class="import-loading"></div>
                <span class="loading-text">Loading...</span>
              </div>
            </div>

            <div class="importDropedInfo" *ngIf = "importedFile">
              <div class="importUpload-fileName" *ngIf="filenameClose">
                <span class="file-name">{{fileToUpload.name}}</span>
                <button *ngIf = "!processingImport" (click)=removeFile() class="remove-button"><em class="iconb-close"></em></button>
              </div>
              <div *ngIf="processingImport" class="import-processingWrapper">
                <div class="import-processing"></div>
                <span class="loading-text">Processing...</span>
              </div>
            </div>
            <div class="importUpload-actionWrapper">              
              <button (click)="uploadImport()" class="import-button" [disabled]="fileToUpload === null">Import</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
