import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ProjectService } from '../../services/project.service';
@Component({
  selector: 'app-invalid-stories',
  templateUrl: './invalid-stories.component.html',
  styleUrls: ['./invalid-stories.component.scss']
})
export class InvalidStoriesComponent implements OnInit {
  @Input() selStories:Array<any>;

  constructor(
    public projectService: ProjectService
  ) { }

  ngOnInit() {
  }

}
