<div class="projectSprintDetail-wrapper">
  <div class="sprintInitial-Loader" style="height: 100%;" *ngIf="!project && sprintDetailinitialLoader  && !sprint_Details">
    <img src="../../assets/images/storyBoardLoader.gif" alt=""/>
  </div>
  <div class="sprinttopInfo-section" *ngIf="project && sprint_Details">
    <div class="topTitle">
      <span class="sprintTitle">{{sprint_Details?.name}} </span> 
      <span [ngClass]="['sprintStatus', sprint_Details.status]">
        <span class="elementHover">{{sprint_Details.sprintStatus()}}</span>
      </span>
      <span *ngIf="policyService.projectAdmin(project)" class="iconb-edit-2" (click)="editSprintDetails(sprint_Details)">
        <span class="elementHover">Edit Sprint</span>
      </span>
      <span *ngIf="policyService.projectAdmin(project)" class="iconb-bin" (click)="showSprintDelete()">
        <span class="elementHover">Delete Sprint</span>
      </span>
    </div>
    <div class="sprintTime-info">
      <div class="sprint-duration" *ngIf="sprint_Details?.projected_start_date"><em class="iconb-calendar duration-cal"></em> Duration: {{ commonStoryService.getDateFormat(sprint_Details?.projected_start_date) }} - {{ commonStoryService.getDateFormat(sprint_Details?.projected_end_date) }}</div>
      <div class="sprint-duration" *ngIf="!sprint_Details?.projected_start_date"><em class="iconb-calendar duration-cal"></em> Duration: <span class="cursor-pointer" (click)="openSprintEdit()">Select Date</span>
      </div>
      <div class="sprintDemoDate" *ngIf="policyService.projectAdmin(project) && sprint_Details">Internal Demo: 
        <input 
          type="text"
          class="demoDate-input"
          placeholder="Select Date"
          name="internalDemoDate"
          angular-mydatepicker
          (dateChanged)="updateDemoDate($event, 'internal_demo_scheduled_at')"
          #demoDateInternal="angular-mydatepicker"
          [options]="demoDatePickerOptions"
          [(ngModel)]="internalDemoDate" />
        <div class="demoDate-cal-btn cursor-pointer" (click)="sprint_Details?.projected_start_date ? demoDateInternal.toggleCalendar() : openDateAlertpopup()">
          <span class="calIcon">
            <em class="iconb-calendar" *ngIf="sprint_Details && !sprint_Details.internal_demo_scheduled_at"></em>
            <em class="iconb-edit-2" *ngIf="sprint_Details && sprint_Details.internal_demo_scheduled_at"></em>
          </span>
        </div>
      </div>
      <div class="sprintDemoDate" *ngIf="policyService.projectAdmin(project) && sprint_Details">External Demo: 
        <input 
          type="text"
          class="demoDate-input"
          placeholder="Select Date"
          name="externalDemoDate"
          angular-mydatepicker
          (dateChanged)="updateDemoDate($event, 'external_demo_scheduled_at')"
          #demoDateExternal="angular-mydatepicker"
          [options]="demoDatePickerOptions"
          [(ngModel)]="externalDemoDate" />
          <div class="demoDate-cal-btn cursor-pointer" (click)="sprint_Details?.projected_start_date ? demoDateExternal.toggleCalendar() : openDateAlertpopup()">
            <span class="calIcon">
              <em class="iconb-calendar" *ngIf="sprint_Details && !sprint_Details.external_demo_scheduled_at"></em>
              <em class="iconb-edit-2" *ngIf="sprint_Details && sprint_Details.external_demo_scheduled_at"></em>
            </span>
          </div>
      </div>
    </div>
    <div class="sprintAction-wrapper">
      <div class="sprintInfo-action">
        <span class="sprintGoal">{{sprint_Details?.description}}</span>
      </div>
      <div class="sprintInfo-startSprint active" (click)="toggleActivityLogSidebar()">Activity Log</div>
      <div class="sprintInfo-startSprint" [ngClass]="{'active':sprintStories.length > 0}" *ngIf="policyService.projectAdmin(project) && (sprint_Details?.status === 'draft' || sprint_Details?.status === 'paused')" (click)="openStartSprintConfirmation('start_sprint')">{{(sprint_Details.status === 'paused') ? 'Resume Sprint' : 'Start Sprint'}}</div>
      <div class="sprintInfo-startSprint active" *ngIf="policyService.projectAdmin(project) && sprint_Details?.status !== 'draft' && sprint_Details?.status !== 'paused' && sprint_Details?.status !== 'completed'" (click)="openPauseSprintConfirmation('paused')">Pause Sprint</div>
      <div class="sprintInfo-startSprint active" *ngIf="checkForCpmplete()" (click)="openCompleteSprintConfirmation('completed')">Complete Sprint</div>
      
    </div>
  </div>

  <div class="holding-wrapper">
    
    <div class="sprintBacklogStories-block" [ngClass]="{'collapseBacklog' : projectService.show_hide_backlog}" *ngIf="(policyService.projectAdmin(project) || this.policyService.isQAExpert(project)) && sprint_Details?.status !== 'completed'">
      <app-backlog-stories *ngIf="project" [project]="project" [currentUser]="currentUser"></app-backlog-stories>
    </div>

    <div class="sprintStories-block" [ngClass]="{'expandMore' : projectService.show_hide_backlog, 'fullexpand' : sprint_Details?.status === 'completed' || (!policyService.projectAdmin(project) && !this.policyService.isQAExpert(project))}">
      <div class="sprint-wrapper">
        <div class="topInfo-section">
          <div class="topActionButtons">
            <div class="tableTitle" *ngIf="projectService.sprintDetailPageTitle">Sprint ( {{inSprintStoryMeta?.total_count}} )</div>
            <div class="bulk-action-block extra-space" *ngIf="selected_stories.length > 0">
              <button *ngIf="selected_stories.length > 0" [disabled]="loadmoreStories" class="bulk-add-stories" type="button" (click)="bulkStoriesRemove()">Remove Stories</button>
              <button *ngIf="selected_stories.length > 0" [disabled]="loadmoreStories" class="bulk-add-stories" type="button" (click)="resetStorySelection()">Deselect Stories</button>
            </div>
            
            <div class="sprint-stories-sorting">
              <span class="selectedSprintFilter" [ngClass]="{'buttActive':showSort}" (click)="openSort()"><em class="iconb-sorting"></em> Sort </span>
              <div class="sprint-story-sorting-options" [@fadeInOut3] *ngIf="showSort">
                <div class="sprint-sorting-option" [ngClass]="{'active': sort_by === 'updated_at'}" (click)="sortStoryList('updated_at')"><em class="iconb-calendar"></em> Date Updated</div>
                <div class="sprint-sorting-option" [ngClass]="{'active': sort_by === 'created_at'}" (click)="sortStoryList('created_at')"><em class="iconb-calendar"></em> Date Created</div>
                <div class="sprint-sorting-option" [ngClass]="{'active': sort_by === 'priority'}" (click)="sortStoryList('priority')"><em class="iconb-three-arrow-nav"></em> Priority</div>
                <div class="sprint-sorting-option" [ngClass]="{'active': sort_by === 'status'}" (click)="sortStoryList('status')"><em class="iconb-three-arrow-nav"></em> Status</div>
                <div class="sprint-sorting-option" [ngClass]="{'active': sort_by === 'story_type'}" (click)="sortStoryList('story_type')"><em class="iconb-three-arrow-nav"></em> Type</div>
              </div>
            </div>
          </div>
        </div>
        <div class="releaseVersion-table"
          #sprintStoryscrollableDiv
          infiniteScroll 
          [scrollWindow]="false"
          [infiniteScrollDisabled]="!isScrollFetchAvailable()"
          (scrolled)="getMoreAddedStories()">
          <div class="hscroll">
            <div class="sprintInitial-Loader" *ngIf="projectService.storyAddedListLoader || loadmoreStories">
              <img src="../../assets/images/storyBoardLoader.gif" alt=""/>
            </div>
            <div class="sprintInitial-Loader" *ngIf="filterLoader">
              <img src="../../assets/images/storyBoardLoader.gif" alt=""/>
            </div>
            <div class="releaseVersion-tableHead" *ngIf="sprintStories.length > 0">
              <div class="tableHead-col1">
                <div class="rowCheckBox">
                  <label class="customCheckForRowSelection">
                    <input type="checkbox" [checked]="select_all_stories" (change)="allStorySelection($event)" class="story-selected-checkbox" hidden/>
                    <span class="check-toggle"></span>
                  </label>
                </div>
              </div>
              <div class="tableHead-col2">Status</div>
              <div class="tableHead-col2">
                <div class="topHead-label">P
                  <span class="elementHover">Priority</span>
                </div>
              </div>
              <div class="tableHead-col2">
                <div class="topHead-label">T
                  <span class="elementHover">Ticket Type</span>
                </div>
              </div>
              <div class="tableHead-col8">Story ID</div>
              <div class="tableHead-col9">Title</div>
              <div class="tableHead-col6">Story Type</div>
              <div class="tableHead-col8" style="text-align: center;">Dev Release</div>
              <div class="tableHead-col10">Owner</div>
              <div class="tableHead-col10">Reviewer</div>
              <div class="tableHead-col3"></div>
            </div>
            <div *ngIf="project" class="releaseVersion-tableContent"  [ngClass]="{'noBacklog': ((!policyService.projectAdmin(project) && !this.policyService.isQAExpert(project)) || sprint_Details?.status === 'completed')}" 
                [id]="'todoList'"
                #todoList="cdkDropList"
                cdkDropList
                [cdkDropListData]="sprintStories"
                [cdkDropListConnectedTo]="'doneList'"
                (cdkDropListDropped)="drop($event)"  >
              <div class="sprintStories-empty" *ngIf="sprintStories.length === 0">
                <span class="iconb-sprint-planning dropIcon" *ngIf="sprint_Details?.status !== 'completed'"></span>
                <div class="dropMessage" *ngIf="sprint_Details?.status !== 'completed'">
                  <span class="dropMessage-text1">Plan your sprint</span>
                  <span class="dropMessage-text2">Plan a sprint by dragging stories from backlog here.</span>
                </div>
                <div class="dropMessage" *ngIf="sprint_Details?.status === 'completed'">
                  <span class="dropMessage-text1">All the stories from this Sprint have been archived or marked inactive.</span>
                </div>
              </div>
              <div class="releaseVersion-tableRow" [ngClass]="{'bulk-selection-on': selected_stories.length > 0,'sub-task': story?.parent_id}"  [cdkDragDisabled]="checkStoryAndSprint()" cdkDrag [cdkDragData]="story" [cdkDragDisabled]="selected_stories.length > 0" *ngFor="let story of sprintStories" (click)="commonStoryService.toggleEditMode($event, story, project)">
                <div class="tableHead-col1">
                  <div class="rowCheckBox">
                    <label class="customCheckForRowSelection">
                      <input type="checkbox" [checked]="story.isSelected" (change)="toggleStorySelection($event,story)" class="story-selected-checkbox" hidden/>
                      <span class="check-toggle"></span>
                    </label>
                  </div>
                </div>
                <div class="tableHead-col2">
                  <div class="tableCell-block">
                    <div class="story-statusWrapper">
                      <span class="story-statusIcon" [ngClass]="[story?.state]"></span>
                      <span class="elementHover">{{story?.state}}</span>
                    </div>
                  </div>
                </div>
                <div class="tableHead-col2">
                  <div class="story-priority" (click)="commonStoryService.openDDOptions('priority',story)">
                    <em [class]="'priority-icon ' + story.storyPriorityType_icon(story.priority)"></em>
                    <span class="elementHover" *ngIf="!story?.showPriority">{{story?.priority}}</span>
                  </div>
                  <div class="storyPriority-wrapper" [@fadeInOut2] *ngIf="story?.showPriority && commonStoryService.dd_type === 'priority'">
                    <div class="storyPriority-option" *ngFor="let priority of storyPriorityOptions" (click)='commonStoryService.changeStoryPriority(priority.type, story)'><em [class]="priority.icon"></em> {{ priority.type }}</div>
                  </div>
                </div>
                <div class="tableHead-col2">
                  <div class="story-type">
                    <em [class]="story?.story_type_icon"></em>
                    <span class="elementHover">{{(story && !story.parent_id) ? story?.story_type : 'Sub Task'}}</span>
                  </div>
                </div>
                <div class="tableHead-col8">
                  <div class="story-id" (click)="commonStoryService.copyStoryID(story)" [clip]="story?.title + ' - ' + getStoryURL(story.id)">
                    {{story?.id}} <span class="iconb-duplicate copy-bt"></span>
                    <div *ngIf="story?.show_copy_tooltip" class="elementHover">Copied</div>
                  </div>
                </div>
                <div class="tableHead-col9">
                  <div class="story-summary" *ngIf="!story?.showStoryTitleInput">{{story?.title}}<em *ngIf="policyService.projectAdmin(project)" class="iconb-edit-2 editTitle" (click)="showEditStoryTitleInput(story)"></em> </div>
                  <div class="story-summary" *ngIf="story?.showStoryTitleInput">
                    <input type="text" id="sprintStoryfocusTitle" maxlength="200" class="editName" (focusout)="commonStoryService.updateStoryTitle(story)" #makeFocusTitle [(ngModel)]="story.title">
                  </div>
                </div>
                <div class="tableHead-col6">
                  <div class="story-summary">{{story?.core? 'Core' : 'Custom'}}</div>
                </div>
                <div class="tableHead-col8">
                  <ng-container *ngIf="!policyService.projectAdmin(project)">
                    {{(story && story.dev_release_at_date) ? commonStoryService.getDateFormat(story.dev_release_at_date) : '-'}} 
                  </ng-container>
                  <ng-container *ngIf="policyService.projectAdmin(project)">
                    <input    
                      type="text"
                      class="dev-release-date-input"
                      placeholder="Select Date"
                      name="devReleaseDate"
                      angular-mydatepicker 
                      (dateChanged)="setDevReleaseDate($event, story)"
                      #devReleaseDate="angular-mydatepicker"
                      [options]="devReleaseDateOptions" 
                      [(ngModel)]="story.dev_release_at" />
                    <div class="demoDate-cal-btn" (click)="sprint_Details?.projected_start_date ? (story.dev_release_at_cal = true) && devReleaseDate.toggleCalendar() : openDateAlertpopup();$event.stopPropagation()">
                      <span class="calIcon">
                        <em class="iconb-calendar" *ngIf="story && !story.dev_release_at"></em>
                        <em class="iconb-edit-2" *ngIf="story && story.dev_release_at"></em>
                      </span>
                    </div>
                  </ng-container>
                  
                </div>
                <div class="tableHead-col10">
                  <div class="nameBlock">
                    <div class="userInitials noMember" *ngIf="!story?.owned_by_initials" (click)="commonStoryService.openDDOptions('owner',story)"><em class="userInitials-icon iconb-add-member"></em></div>
                    <div class="userInitials" (click)="commonStoryService.openDDOptions('owner',story)" *ngIf="story?.owned_by_initials">{{story?.owned_by_initials?.substr(0,1)}}</div>
                    <span class="elementHover" *ngIf="!story?.showOwnerList && story?.owned_by_name">{{story?.owned_by_name}}</span>
                  </div>
                  <div class="storyUserList-wrapper" [@fadeInOut2] *ngIf="story?.showOwnerList && commonStoryService.dd_type === 'owner'">
                    <div class="storyUser-search">
                      <input type="text" class="storyUser-searchInput" [(ngModel)]="searchOwner" placeholder="Search"/>
                      <button type="button" class="storyUser-searchButton">
                        <em class="storyUser-searchButtonIcon iconb-search"></em>
                      </button>
                    </div>
                    <div class="list-optionWrapper">
                      <div class="storyUserList-option" *ngFor = "let user of project.users  | search: searchOwner: 'name' | squadMemberSearch: story; index as i;">
                        <div class="ifClickOutside nameBlock" [ngClass]="{'userNotActive': !project.isUserActive(user) || commonStoryService.checkSameUser(user,'owner',story, project)}" (click)="commonStoryService.setUser(user, 'Owner', story, project)">
                          <div class="ifClickOutside userInitials">{{user.initials.substr(0,1)}}</div>
                          <div class="ifClickOutside nameWithInitials">
                            <span class="ifClickOutside ifUserDeallocated" *ngIf="!project.isUserActive(user)">{{ project.userInactiveText(user) }}</span>
                            <span class="ifClickOutside ifUserDeallocated sameUser" *ngIf="commonStoryService.checkSameUser(user,'owner',story, project)">Owner and Reviewer cannot be the same.</span>
                            <span class="ifClickOutside selectUser-name">{{user?.name}}</span>
                            <span class="ifClickOutside selectUser-email">{{user?.email}}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tableHead-col10">
                  <div class="nameBlock">
                    <div class="userInitials noMember" *ngIf="!story?.reviewer_initials" (click)="commonStoryService.openDDOptions('reviewer',story)"><em class="userInitials-icon iconb-add-member"></em></div>
                    <div class="userInitials" *ngIf="story?.reviewer_initials" (click)="commonStoryService.openDDOptions('reviewer',story)">{{story?.reviewer_initials?.substr(0,1)}}</div>
                    <span class="elementHover" *ngIf="!story?.showReviewerList && story?.reviewer_name">{{story?.reviewer_name}}</span>
                  </div>
                  <div class="storyUserList-wrapper" [@fadeInOut2] *ngIf="story?.showReviewerList && commonStoryService.dd_type === 'reviewer'">
                    <div class="storyUser-search">
                      <input type="text" class="storyUser-searchInput" [(ngModel)]="searchReviewer" placeholder="Search"/>
                      <button type="button" class="storyUser-searchButton">
                        <em class="storyUser-searchButtonIcon iconb-search"></em>
                      </button>
                    </div>
                    <div class="list-optionWrapper">
                      <div class="storyUserList-option" *ngFor = "let user of project.users  | search: searchReviewer: 'name' | squadMemberSearch: story; index as i;">
                        <div class="ifClickOutside nameBlock" [ngClass]="{'userNotActive': !project.isUserActive(user) || commonStoryService.checkSameUser(user,'reviewer',story, project)}"  (click)="commonStoryService.setUser(user, 'Reviewer', story, project)">
                          <div class="ifClickOutside userInitials">{{user.initials.substr(0,1)}}</div>
                          <div class="ifClickOutside nameWithInitials">
                            <span class="ifClickOutside ifUserDeallocated" *ngIf="!project.isUserActive(user)">{{ project.userInactiveText(user) }}</span>
                            <span class="ifClickOutside ifUserDeallocated sameUser" *ngIf="commonStoryService.checkSameUser(user,'reviewer',story, project)">Owner and Reviewer cannot be the same.</span>
                            <span class="ifClickOutside selectUser-name">{{user?.name}}</span>
                            <span class="ifClickOutside selectUser-email">{{user?.email}}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tableHead-col3">
                  <div class="moreActions" *ngIf="sprint_Details?.status !== 'completed'">
                    <em class="iconb-more moreAction-bt" [ngClass]="{'makeActive':(story?.showMoreStoryAction && commonStoryService.dd_type==='more_action')}"  (click)="commonStoryService.openDDOptions('more_action',story,project)"></em>
                    <div class="storyaction-wrapper" [@fadeInOut2] *ngIf="story?.showMoreStoryAction && commonStoryService.dd_type==='more_action'">
                      <div class="storyaction-option" (click)="commonStoryService.storyEditMode(story.id, project)"><em class="iconb-edit-2"></em> Edit Story</div>
                      <div class="storyaction-option" (click)="makeClone(story, true)"><em class="iconb-duplicate"></em> Duplicate</div>
                      <div class="storyaction-option" (click)="commonStoryService.openArchivePopup(story, 'sprint_story')"><em class="iconb-archive"></em> Archive</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mainProjectList-Loader" *ngIf="isPending">
                <img src="../../assets/images/storyBoardLoader.gif" alt=""/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>

<div *ngIf="commonStoryService.OpenConfirmPopUp && commonStoryService.story_belongs_to === 'sprint_story'" class="commonPopUp">
  <div class="commonPopUp-backdrop" (click)="commonStoryService.closeArchivePopup()"></div>
  <div class="commonPopUp-wrapper">
    <div class="common-Loader commonPopup-Loader" *ngIf="commonStoryService.waitForLoader">
      <img src="../../assets/images/storyBoardLoader.gif" alt=""/>
    </div>
    <div class="commonPopUp-title">Archive</div>
    <div class="commonPopUp-text">
      The status of the story is {{ commonStoryService.archiveStory?.state }} and time tracked is {{ sharedService.totalTimeTracked(commonStoryService.archiveStory) }}, are you sure you want to archive it?
    </div>
    <div class="commonPopUp-actionBtn">
      <span class="commonPopUp-submit-s" (click)="commonStoryService.archive('sprint_story')">Archive</span>
      <span class="commonPopUp-cancel-s" (click)="commonStoryService.closeArchivePopup()">Cancel</span>
    </div>
  </div>
</div>

<div id="storyDIV" class="revStoryDes-popup"  [hidden] ="!projectService.show_storyDetails && !projectService.start_storyDetailLoader">
  <div class="revStoryDes-backdrop" (click) = "projectService.hide_StoryDetails()"></div>
  <div class="revStoryDes-popupWrapper">
    <story-detail [hidden] ="!projectService.show_storyDetails && !projectService.start_storyDetailLoader">
    </story-detail>
  </div>
</div>

<div class="addSprintPopup" *ngIf="sprintService.editSprint">
  <app-sprint-popup [sprint_Details]="sprint_Details" [popupFor]="popupFor" [projectId]="projectId"></app-sprint-popup>
</div>

<div *ngIf="sprintDeletePopUp" class="commonPopUp">
  <div class="commonPopUp-backdrop" (click)="closeSprintDelete()"></div>
  <div class="commonPopUp-wrapper">
    <div class="common-Loader commonPopup-Loader" *ngIf="waitForLoader">
      <img src="../../assets/images/storyBoardLoader.gif" alt=""/>
    </div>
    <div class="commonPopUp-title">Delete</div>
    <div class="commonPopUp-text">Are you sure you want to delete the sprint?</div>
    <div class="commonPopUp-actionBtn">
      <span class="commonPopUp-submit-s" (click)="deleteSprint()">Delete</span>
      <span class="commonPopUp-cancel-s" (click)="closeSprintDelete()">Cancel</span>
    </div>
  </div>
</div>

<div *ngIf="sprintStartActionPopUp" class="commonPopUp">
  <div class="commonPopUp-backdrop" (click)="closeStartSprintConfirmation()"></div>
  <div class="commonPopUp-wrapper">
    <div class="common-Loader commonPopup-Loader" *ngIf="waitForLoader">
      <img src="../../assets/images/storyBoardLoader.gif" alt=""/>
    </div>
    <div class="commonPopUp-title">{{(sprint_Details.status === 'paused') ? 'Resume Sprint' : 'Start Sprint'}}</div>
    <div class="commonPopUp-text" *ngIf="canStartSprint && sprint_Details.status === 'paused'">Are you sure you want to resume the sprint?</div>
    <div class="commonPopUp-text" *ngIf="canStartSprint && sprint_Details.status === 'draft'">{{sprint_Details.checkSprintStartingDate()}}</div>
    <div class="commonPopUp-text" *ngIf="!canStartSprint">Please add stories into the sprint.</div>
    <div class="commonPopUp-actionBtn" *ngIf="canStartSprint">
      <span class="commonPopUp-submit-s" (click)="actionSprint(sprintActionType)">{{(sprint_Details.status === 'paused') ? 'Resume' : 'Start'}}</span>
      <span class="commonPopUp-cancel-s" (click)="closeStartSprintConfirmation()">Cancel</span>
    </div>
    <div class="commonPopUp-actionBtn" *ngIf="!canStartSprint">
      <span class="commonPopUp-cancel-s" (click)="closeStartSprintConfirmation()">Ok</span>
    </div>
  </div>
</div>

<div *ngIf="sprintCompletetActionPopUp" class="commonPopUp">
  <div class="commonPopUp-backdrop" (click)="closeCompleteSprintConfirmation()"></div>
  <div class="commonPopUp-wrapper">
    <div class="common-Loader commonPopup-Loader" *ngIf="waitForLoader">
      <img src="../../assets/images/storyBoardLoader.gif" alt=""/>
    </div>
    <div class="commonPopUp-title">Complete Sprint</div>
    <div class="commonPopUp-text" *ngIf="canCompleteSprint && !sprint_Details.futureEndDate(sprint_Details.projected_end_date)">Are you sure you want to complete the sprint?</div>
    <div class="commonPopUp-text" *ngIf="canCompleteSprint && sprint_Details.futureEndDate(sprint_Details.projected_end_date)">You are in the process of closing the sprint, but it appears that an end date is in the future. As per your request, the system will proceed to close the sprint; however, it will automatically adjust the end date to today's date for accuracy.</div>
    <div class="commonPopUp-text" *ngIf="!canCompleteSprint">Oops. Sprint cannot be completed because some stories are still not accepted.</div>
    <div class="commonPopUp-actionBtn" *ngIf="canCompleteSprint">
      <span class="commonPopUp-submit-s" (click)="actionSprint(sprintActionType)">{{sprint_Details.futureEndDate(sprint_Details.projected_end_date) ? 'Confirm':'Complete'}}</span>
      <span class="commonPopUp-cancel-s" (click)="closeCompleteSprintConfirmation()">Cancel</span>
    </div>
    <div class="commonPopUp-actionBtn" *ngIf="!canCompleteSprint">
      <span class="commonPopUp-cancel-s" (click)="closeCompleteSprintConfirmation()">Ok</span>
    </div>
  </div>
</div>

<div *ngIf="sprintPausePopUp" class="commonPopUp">
  <div class="commonPopUp-backdrop" (click)="closePauseSprintConfirmation()"></div>
  <div class="commonPopUp-wrapper">
    <div class="common-Loader commonPopup-Loader" *ngIf="waitForLoader">
      <img src="../../assets/images/storyBoardLoader.gif" alt=""/>
    </div>
    <div class="commonPopUp-title">Pause Sprint</div>
    <div class="commonPopUp-text">Are you sure you want to pause the sprint?</div>
    <div class="commonPopUp-actionBtn">
      <span class="commonPopUp-submit-s" (click)="actionSprint(sprintActionType)">Pause</span>
      <span class="commonPopUp-cancel-s" (click)="closePauseSprintConfirmation()">Cancel</span>
    </div>
  </div>
</div>
<app-story-clone-popup *ngIf="projectService.cloneConfirmPopup && sprint_Details?.status === 'completed'" [project]="project" [fromBacklog]= true></app-story-clone-popup>

<div class="activity-slidebar-overlay" (click)="toggleActivityLogSidebar()"></div>
<div class="activity-log-sidebar-wrapper" *ngIf="activitySidebarOpen">
  <div class="log-header">
    <h2>Activity Log</h2>
    <div class="close-btn" (click)="toggleActivityLogSidebar()"><img src="../../../assets/images/ic-close.svg" alt=""></div>
  </div>
  <div class="log-body">
    <app-activity-timeline *ngIf="activitySidebarOpen" [project]="project" [subject_id]="sprint_Details.id" [subject_type]="'Sprint'"></app-activity-timeline>
  </div>
</div>


<div class="commonPopUp commonSecondary" *ngIf="moveStoriesToSprint">
  <div class="commonPopUp-backdrop" (click)="closeMoveStoriesToSprint()"></div>
  <div class="commonPopUp-wrapper">
    <span class="commonPopUp-close iconb-close" (click)="closeMoveStoriesToSprint()"></span>
    <div class="commonPopUp-title">Move Stories to close Sprint</div>
    <div class="commonPopUp-body spill-over-content">
      <p class="info-text">There are still <strong>{{spillOverStoryCount}} open {{spillOverStoryCount > 1 ? 'stories' : 'story'}}</strong> in this sprint. Please move them to next sprint. <span class="red-text">*</span></p>
      <div class="story-creation-reason-wrapper forBulk_duplicate full-width">
        <div class="selected-reason" (click)="sprintselection()">{{selectedSprintForMove.name ? selectedSprintForMove.name : 'Select Sprint'}}<em class="iconb-dd-down" [ngClass]="{'makeDown': sprintselectionDropdown}"></em></div>
        <div class="selected-reason-list-wrapper scrollable" *ngIf="sprintselectionDropdown">
          <ng-container *ngFor="let sprint of sprintMoveOptions">
            <div class="select-reason" (click)=selectSprintForMove(sprint)>{{sprint.name}}</div>
          </ng-container>
        </div>
       </div>
    </div>
    <div class="commonPopUp-actionBtn">
      <button class="commonPopUp-cancel-s is-black" (click)="closeMoveStoriesToSprint()">Cancel</button>
      <button class="commonPopUp-submit-s" [disabled]="!selectedSprintForMove.name" (click)="moveStoriesAndCompleteSprint(sprint_Details,project.id,selectedSprintForMove.id)">Confirm</button>
    </div>
  </div>
</div>

<div *ngIf="sprintService.enterDateAlertPopup" class="commonPopUp">
  <div class="commonPopUp-backdrop" (click)="closeDateAlertPopup()"></div>
  <div class="commonPopUp-wrapper">
    <div class="common-Loader commonPopup-Loader" *ngIf="waitForLoader">
      <img src="../../assets/images/storyBoardLoader.gif" alt=""/>
    </div>
    <div class="commonPopUp-title">Start Sprint</div>
    <div class="commonPopUp-text">Please enter sprint start/end date first.</div>
    <div class="commonPopUp-actionBtn">
      <span class="commonPopUp-submit-s" (click)="openSprintEdit()">Select date</span>
      <span class="commonPopUp-cancel-s" (click)="sprintService.closeDateAlertPopup()">Cancel</span>
    </div>
  </div>
</div>