
    <admin-users-form *ngIf="form"
                      [model]="form"
                      [errors]="outputErr"
                      [isPending]="isPending"
    >

    </admin-users-form>
    <div class="actionBt-wrapper" *ngIf="sharedService.isGetUserSuccessForEditInAdmin">
      <button class="actionButton cancel-addMember" (click)="cancel()">Cancel</button>
      <button type="revert" class="actionButton add" *ngIf="!isPristine" (click)="revert()">Revert Changes</button>
      <button class="actionButton add" [disabled]="(form.email == '') ||  (checkEmail(form.email) && checkEmail(fullEmail))" (click) = "onSubmit()">Save</button>
    </div>
