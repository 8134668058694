import { Injectable } from '@angular/core';
import { API_ROUTES } from '../apiRoutes';
import { DataService } from './data.service';
import { HttpRequests } from './http-requests.service';

@Injectable()
export class AccountService {
  constructor(
    private httpRequest: HttpRequests,
    private dataService: DataService
  ) {}

  public get() {
    const API = this.dataService.apiUrl + API_ROUTES.ACCOUNT;
    return this.httpRequest.get(API, this.dataService.getRequestOptionArgs());
  }

  public update(account) {
    const API = this.dataService.apiUrl + API_ROUTES.ACCOUNT;
    return this.httpRequest.put(
      API,
      { account: account },
      this.dataService.getRequestOptionArgs()
    );
  }
}
