<admin-projects-form [model]="form"
                     [errors]="errors"
                     [isPending]="isPending"
                     >
  <div class="actionBt-wrapper">
      <button class="actionButton cancel-addMember" (click) = "sharedService.showprojectAdminForm = false">Cancel</button>
    <button class="actionButton add" (click) = "onSubmit(true)" [disabled] = "(!form.name)  || (!form.start_date)">Save & Add New</button>
      <button class="actionButton add" (click) = "onSubmit()" [disabled]="isPending || (!form.name) || (!form.start_date)">
        <span class="fa fa-spin fa-spinner" *ngIf="isPending"></span>
        Add & Close
      </button>
  </div>
</admin-projects-form>