<!--Instruction For Developers: please add "open-BulkSection" class in below div if any checkbox is selected
 like this <div class="bulkSelection-wrapper open-BulkSection"> -->

  <div *ngIf = "projectService.showHtml && project?.selectedStories.length > 1" class="bulkSelection-wrapper" [ngClass]="{'open-BulkSection': project?.selectedStories.length > 1}">
    <div class="bulkSection-block">
      <div class="bulkAction-info">
        <span class="counter-info">{{ project?.selectedStories.length }}</span>
        <span class="text-info">{{(project?.selectedStories.length > 1 ? 'Stories': 'Story')}} Selected</span>
      </div>
      <div class="bulkAction-info  bulkAction-hover" [ngClass] = "{'changeSign' : (project?.selectedStories.length + sharedService.countArchivedAndHiddenColStories()) == project?.stories.length}"  (click)="manageSelectDeselectAll()">
        <div class="ifReadOnly" *ngIf="projectService.archived_story_selected"></div>
        <span *ngIf="(project?.selectedStories.length + sharedService.countArchivedAndHiddenColStories()) != project?.stories.length " class="info-action selectall"></span>
        <span class="text-info">{{((project?.selectedStories.length + sharedService.countArchivedAndHiddenColStories()) != project?.stories.length ? 'Select All': 'Deselect All')}}</span>
        <span class="elementHover" *ngIf="(project?.selectedStories.length + sharedService.countArchivedAndHiddenColStories()) != project?.stories.length">{{ projectService.archived_story_selected ?  'You cannot perform this action as you have selected archived stories.' : 'This action will not select archived stories.'}}</span>
      </div>
      <div class="bulkAction-info" (click)="deSelectAll()" *ngIf="project?.selectedStories.length >0 && (project?.selectedStories.length + sharedService.countArchivedAndHiddenColStories()) != project?.stories.length" > 
        <span class="text-info">Deselect All</span>
      </div>
      <div class="bulkAction-wrapper" *ngIf="projectService.archived_story_selected">
        <div class="bulkAction-button" (click)="openDeleteSelectionPopup('unarchive')">
          <span class="bulkAction-buttonIcon"><em class="iconb-unarchive deleteIcon"></em> </span>
          <span class="bulkAction-buttonText">Unarchive</span>
        </div>
      </div>
      <div class="bulkAction-wrapper" *ngIf="projectService.normal_story_selected">
        <div class="bulkAction-button bulkAction-buttonWrapper">
          <div class="bulkAction-ddButton" (click)="openMemberDD()">
            <span class="bulkAction-buttonIcon"><em class="iconb-members memberIcon"></em> </span>
            <span class="bulkAction-buttonText">Members</span>
            <span class="iconb-dd-down memDD-icon"></span>
          </div>
          <div class="bulkAction-ddwrapper" *ngIf="selectMemberDD" [ngClass]="{'openbulkAction-ddwrapper': selectMemberDD}">
            <div class="bulkAction-ddList" (click)="openOwnerPopup()">
              <span class="bulkAction-ddListIcon"><em class="iconb-owner"></em></span>
              <span class="bulkAction-ddListName">Replace Owner</span>
            </div>
            <div class="bulkAction-ddList" (click)="openReviewerPopup()">
              <span class="bulkAction-ddListIcon"><em class="iconb-review"></em></span>
              <span class="bulkAction-ddListName">Replace Reviewer</span>
            </div>
          </div>
        </div>

        <div class="bulkAction-button bulkAction-buttonWrapper" *ngIf="project.studio_roles.length > 0">
          <div class="bulkAction-ddButton" (click)="openRoleDD()">
            <span class="bulkAction-buttonIcon"><em class="iconb-subtask roleIcon"></em> </span>
            <span class="bulkAction-buttonText">Assign Role</span>
            <span class="iconb-dd-down memDD-icon"></span>
          </div>
          <div class="bulkAction-ddwrapper" *ngIf="selectRoleDD" [ngClass]="{'openbulkAction-ddwrapper': selectRoleDD}">
            <div class="bulkAction-ddList" (click)="openRoleChange(role)" *ngFor = "let role of project.studio_roles; index as i;">
              <span class="bulkAction-ddListName">{{role.studio_role.name | titlecase}}</span>
            </div>
          </div>
        </div>

        <div class="bulkAction-button bulkAction-buttonWrapper">
          <div class="bulkAction-ddButton" (click)="openMoveDD()">
            <span class="bulkAction-buttonIcon"><em class="iconb-move"></em> </span>
            <span class="bulkAction-buttonText">Move</span>
            <span class="iconb-dd-down memDD-icon"></span>
          </div>
          <div class="bulkAction-ddwrapper forMove"  *ngIf="selectMoveDD" [ngClass]="{'openbulkAction-ddwrapper': selectMoveDD }">
            <div class="bulkAction-ddList" (click)="openMoveSelectedPopup()">
              <span class="bulkAction-ddListName">Move to Buildcard</span>
            </div>
            <div class="bulkAction-ddList" *ngIf="project && project.hasProjectPlatforms()" (click)="openMoveDeviceAndPlatformPopup()">
              <span class="bulkAction-ddListName">Move to Devices & Platforms</span>
            </div>
          </div>
        </div>
      
        <div class="bulkAction-button bulkAction-buttonWrapper">
          <div class="bulkAction-ddButton otherOptions_button" (click)="selectionBulkOptionsDD()">
            <span class="bulkAction-buttonIcon no_margin"><em class="iconb-more-vert"></em> </span>
          </div>
          <div class="bulkAction-ddwrapper otherOptions"  *ngIf="showBulkOptions" [ngClass]="{'openbulkAction-ddwrapper': showBulkOptions }">
            <div class="bulkAction-ddList" (click)="openAcceptRejectConfirmation('accept')">
              <span class="bulkAction-ddListIcon"><em class="iconb-bulk-accept otherOptions_icons"></em></span>
              <span class="bulkAction-ddListName">Accept Stories</span>
            </div>
            <div class="bulkAction-ddList" (click)="openAcceptRejectConfirmation('reject')">
              <span class="bulkAction-ddListIcon"><em class="iconb-bulk-accept otherOptions_icons"></em></span>
              <span class="bulkAction-ddListName">Reject Stories</span>
            </div>
            <div class="bulkAction-ddList" (click)="openCloneConfirmation()">
              <span class="bulkAction-ddListIcon"><em class="iconb-duplicate otherOptions_icons"></em></span>
              <span class="bulkAction-ddListName">Duplicate</span>
            </div>
            <div class="bulkAction-ddList" (click)="openFeatureLabelPopup()">
              <span class="bulkAction-ddListIcon"><em class="iconb-label otherOptions_icons"></em></span>
              <span class="bulkAction-ddListName">{{ canUpdateFeature() ? 'Features & ' : '' }}Labels</span>
            </div>
            <div class="bulkAction-ddList" (click)="openDeleteSelectionPopup('archive')">
              <span class="bulkAction-ddListIcon"><em class="iconb-delete-icon otherOptions_icons"></em></span>
              <span class="bulkAction-ddListName">Archive</span>
            </div>
            <!-- <div class="bulkAction-ddList" (click)="openDeleteSelectionPopup('unarchive')">
              <span class="bulkAction-ddListIcon"><em class="iconb-unarchive otherOptions_icons"></em></span>
              <span class="bulkAction-ddListName">Unrchive</span>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--Unarchiving Stories popup Starts-->
  <div class="bulkaction-popUp" *ngIf="unArchiveSelectionPopup">
    <div class="bulkaction-popUpBackdrop" (click)="closeDeleteSelectionPopup()"></div>
    <div class="bulkaction-popUp-wrapper deleteStories" [ngClass]="{'without_list': !archive_meta?.valid_to_archive}">
      <div class="bulkAction-Loader" *ngIf="waitForLoader">
        <img src="../../assets/images/storyBoardLoader.gif" alt="loader"/>
      </div>
      <div class="bulkaction-popUp-title">Bulk Unarchive</div>
      <span class="bulkaction-popUp-Text1">You have selected {{archive_meta?.total_selected}} stories to unarchive them.
        <span *ngIf="archive_meta && invalidToUnarchive()">
          {{invalidToUnarchive()}} stories will not be unarchived because:
          <ol>
            <li>Their parent story is archived.</li>
            <li>It is a design story and 1 feature can have only 1 design story per role per platform.</li>
            <li>It is a core story and core stories cannot be unarchived after BB exists for the
              associated feature.</li>
          </ol>
        </span>
      </span>
      <span class="bulkaction-popUp-title2" *ngIf="archive_meta && archive_meta.valid_to_archive">Stories that will be unarchived:</span>
      <div class="selectedStoriesList-wrapper" *ngIf="storiesToArchiveOrUnarchive.length > 0">
        <div class="selectedStoriesList" *ngFor = "let story of storiesToArchiveOrUnarchive">
          <div class="selectedStoriesList-left">
            <div class="story-typeIcon">
              <em class="iconb-task featureIcon" *ngIf = "story.story_type == 'task'"></em>
              <em class="iconb-bug bugIcon" *ngIf = "story.story_type == 'bug'"></em>
            </div>
            <div class="story-NameBox">
              <span class="selected-storyName">{{story.title}}</span>
              <span class="selected-storyEpicName">{{story.epic_title}}</span>
            </div>
          </div>
          <div class="selectedStoriesList-right">
            <div class="deleteSelection-check">
              <label class="customCheckFor-deleteSelection">
                <input type="checkbox" checked class="delete-selected-checkbox" (change) = "toggleUnarchiveSelection($event, story)" hidden/>
                <span class="check-toggleDeleteSelection"></span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="bulkaction-popUp-actionBtn">
        <button class="bulkaction-popUp-submit" [disabled]="togglestoryDel.length === 0" type="button" (click)="bulkUnarchive()">Confirm</button>
        <button class="bulkaction-popUp-cancel" type="button" (click)="closeDeleteSelectionPopup()">Cancel</button>
      </div>
    </div>
  </div>
  <!--Unarchiving Stories popup Ends-->

  <!-- Set Roles for stories -->
  <div class="bulkaction-popUp" *ngIf="bulk_role_update">
    <div class="bulkaction-popUpBackdrop" (click)="closeRoleChange()"></div>
    <div class="bulkaction-popUp-wrapper deleteStories">
      <div class="bulkAction-Loader" *ngIf="waitForLoader">
        <img src="../../assets/images/storyBoardLoader.gif" alt="loader"/>
      </div>
      <div class="bulkaction-popUp-title">Update Role</div>
      <span class="bulkaction-popUp-Text1">
        You have selected {{selStories.length}} {{selStories.length > 1 ? 'Stories': 'Story'}} for changing role in Bulk. 
        <span *ngIf="roleUpdateList_meta?.not_valid_count > 0">
          {{roleUpdateList_meta?.not_valid_count}} 
          stories role cannot be changed due to below reasons:
          <ul>
            <li>- It is a design parent story and a story already exists for the selected role as design stories can only have 1 parent story per role per platform.</li>
            <li>- It is a core story and role cannot be changed from core stories.</li>
          </ul>
        </span>
      </span>
      <div class="selectedStoriesList-wrapper" *ngIf="roleUpdateList.length > 0">
      <span class="bulkaction-popUp-Text1">Role can be updated for the below {{roleUpdateList.length > 1 ? 'stories': 'story'}}:</span>
        <div class="selectedStoriesList" *ngFor = "let story of roleUpdateList">
          <div class="selectedStoriesList-left">
            <div class="story-typeIcon">
              <em class="iconb-task featureIcon" *ngIf = "story.story_type === 'task'"></em>
              <em class="iconb-bug bugIcon" *ngIf = "story.story_type == 'bug'"></em>
            </div>
            <div class="story-NameBox">
              <span class="selected-storyName">{{story.title}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="bulkaction-popUp-actionBtn">
        <button class="bulkaction-popUp-submit" type="button" (click) = "bulkUpdateStoriesRole()" [disabled]="roleUpdateList.length === 0">Accept</button>
        <button class="bulkaction-popUp-cancel" type="button" (click)="closeRoleChange()">Cancel</button>
      </div>
    </div>
  </div>
  <!-- Set Roles for stories ends-->

  <!--clone Confirmantion Popup Starts-->
  <div class="bulkaction-popUp" *ngIf="cloneConfirmPopup">
    <div class="bulkaction-popUpBackdrop" (click)="closecloneconfirmation()"></div>
    <div class="bulkaction-popUp-wrapper duplicateStories">
      <div class="bulkAction-Loader" *ngIf="waitForLoader">
        <img src="../../assets/images/storyBoardLoader.gif" alt="loader"/>
      </div>
      <div class="bulkaction-popUp-title">Duplicate Stories</div>
      <div class="bulkaction-popUp-text">Are you sure you want to duplicate <strong>{{(project?.selectedStories.length > 1) ? (project?.selectedStories.length + ' stories') : (this.project.selectedStories.length + ' story')}} </strong>  in <strong>{{project.name}}</strong>?</div>
      <div class="bulkaction-indicateInfo">
        <span class="indicateInfo-icon-box">
          <span class="iconb-info indicateInfo-typeIcon"></span>
        </span>
        <span class="bulkaction-indicateInfoText">The new stories will be created at the top of the To Do column.</span>
      </div>
      <div class="selectedStoriesList-wrapper">
        <div class="selectedStoriesList" *ngFor = "let story of selStories">
          <div class="selectedStoriesList-left">
            <div class="story-typeIcon">
              <em class="iconb-task featureIcon" *ngIf = "story.story_type == 'task'"></em>
              <em class="iconb-bug bugIcon" *ngIf = "story.story_type == 'bug'"></em>
            </div>
            <div class="story-NameBox">
              <span class="selected-storyName">{{story.title}}</span>
            </div>
            <div class="story-creation-reason-wrapper forBulk_duplicate" *ngIf="(story?.epics[0]?.task_done_count > 0) && story.story_type === 'task' && !story.parent_id">
              <div class="selected-reason" (click)="openBulkDuplicateReasonList(story)">{{story.bulk_story_selected_reason ? story.bulk_story_selected_reason : 'Select Reason'}}<em class="iconb-dd-down" [ngClass]="{'makeDown':projectService.story_creation_reason}"></em></div>
              <div class="selected-reason-list-wrapper" *ngIf="story.show_reason_dd">
                <div class="select-reason" (click)="projectService.selectCreationReason(creationReason.reason, story)" *ngFor="let creationReason of projectService.creationReasons">{{creationReason.reason}}</div>
              </div>
              <textarea *ngIf="story.show_other_reason_input" class="otherReason-input" (input)="projectService.getOtherReasonData(story)" placeholder="Please provide additional information" [(ngModel)]="story.other_reason_input"></textarea>
             </div>
          </div>
          <div class="selectedStoriesList-right" *ngIf="story?.epics.length > 0">
            <span class="selected-storyEpicName">{{story?.epics[0]?.title}}</span>
          </div>
        </div>
      </div>
      <div class="bulkaction-popUp-actionBtn">
        <div class="conditionForChild" *ngIf="hasParentSubtasks()">
          <div class="forChildSelection-check">
            <label class="customCheckFor-deleteSelection">
              <input type="checkbox" checked class="delete-selected-checkbox" (change)="toggleApplyOnSubTasks()" [checked]="apply_on_sub_tasks" hidden/>
              <span class="check-toggleDeleteSelection"></span>
            </label>
          </div>
          <div class="conditionForChild-title">Duplicate stories with the sub tasks</div>
        </div>
        <button type="button" class="bulkaction-popUp-submit" [disabled]="checkStoriesReason()" (click) = "bulkClone()">Confirm</button>
        <button type="button" class="bulkaction-popUp-cancel" (click)="closecloneconfirmation()">Cancel</button>
      </div>
    </div>
  </div>
  <!--clone Confirmantion Popup Ends-->

  <!--Features Labels Selection and confirmation popup starts-->
  <div class="bulkaction-popUp" *ngIf="featurelabelSelectionPopup">
    <div class="bulkaction-popUpBackdrop" (click)="closefeaturelabelPopup()"></div>
    <div class="bulkaction-popUp-wrapper">
      <div class="bulkAction-Loader" *ngIf="waitForLoader">
        <img src="../../assets/images/storyBoardLoader.gif" alt="loader"/>
      </div>
      <div class="bulkaction-popUp-title">Add or Remove{{ canUpdateFeature() ? ' Features and' : '' }} Labels</div>
      <span class="bulkaction-popUp-Text1">This action will update the{{ canUpdateFeature() ? ' features and' : '' }} labels for selected {{project?.selectedStories.length === 1 ? 'story' : 'stories'}}.</span>
      <div class="selectedEpic-box" *ngIf = "project">
        <span class="selectedEpic-list featureList"  [ngClass] = "{'noList-selected': !epic.isSelected}" *ngFor = "let epic of project.epics">
          <ng-container *ngIf = "epic.isSelected">
          <span class="selectedEpic-name">{{epic.title}}</span>
          <em class="iconb-close removeEpic-name" (click) = "removeEpic(epic)"></em>
          </ng-container>
        </span>
        <span class="selectedEpic-list labelList"  [ngClass] = "{'noList-selected': !isLabelSelected(label)}" *ngFor = "let label of project.labels">
          <ng-container *ngIf = "isLabelSelected(label)">
            <span class="selectedEpic-name">{{label}}</span>
            <em class="iconb-close removeEpic-name" (click) = "removeLabel(label)"></em>
          </ng-container>
        </span>
      </div>
      <div class="search-box">
        <input type="search" [(ngModel)] = "searchFeatureLabel" class="bulkaction-epicSearch" placeholder="Search{{ canUpdateFeature() ? ' Features and' : '' }} Labels" />
        <button type="button" class="bulkaction-epicSearchButton">
          <em class="iconb-search"></em>
        </button>
      </div>
      <ng-container *ngIf = "project">
      <div class="ifNoLabelFound" *ngIf="(project.epics | search: searchFeatureLabel.trim(): 'title').length + (project.labels | search: searchFeatureLabel.trim()).length == 0">
        <button class="forAdding-label" (click)="addNewLabel(searchFeatureLabel.trim())">
          Add "{{searchFeatureLabel.trim()}}" as a new label
        </button>
      </div>
      </ng-container>
      <div class="bulkaction-popUp-epicListing" *ngIf = "project">
        <div class="epicSelectionlist-wrapper"
             *ngIf="canUpdateFeature() && (project.epics | search: searchFeatureLabel.trim(): 'title').length + (project.labels | search: searchFeatureLabel.trim()).length > 0">
          <div class="epicSelection-list" [ngClass]="{'itSelected': epic.isSelected}" *ngFor = "let epic of project.epics  | search: searchFeatureLabel.trim(): 'title'; index as i;">
            <div class="highlight-inactiveEpic" *ngIf = "epic?.status === 'inactive'"><span class="inactive-text">Feature is inactive</span></div>
            <div class="epicSelection-radio">
              <label class="customRadioFor-epicSelection">
                <input type="radio" id="{{'radioSelect_' + i}}" class="epic-selected-radio" (change)="toggleEpicSelection($event,epic)" name="selectToEpic" hidden/>
                <span class="radio-toggleEpicSelection"></span>
              </label>
            </div>
            <label for="{{'radioSelect_' + i}}" class="epicSelection-epicName">
              <em class="iconb-feature-name starIcon"></em>
              <span class="epicName featureList">{{epic.title}}</span>
            </label>
          </div>
          <div class="epicSelection-list" [ngClass]="{'itSelected': isLabelSelected(label)}" *ngFor = "let label of project.labels | search: searchFeatureLabel.trim(); index as i;">
            <div class="epicSelection-check">
              <label class="customCheckFor-epicSelection">
                <input type="checkbox" id="{{'checkSelect_' + i}}" class="epic-selected-checkbox" (change)="toggleLabelSelection($event,label)" [checked]="isLabelSelected(label)" hidden/>
                <span class="check-toggleEpicSelection"></span>
              </label>
            </div>
            <label for="{{'checkSelect_' + i}}" class="epicSelection-epicName">
              <em class="iconb-label starIcon"></em>
              <span class="epicName labelList">{{label}}</span>
            </label>
          </div>
        </div>
        <div class="epicSelectionlist-wrapper"
             *ngIf="!canUpdateFeature() && (project.labels | search: searchFeatureLabel.trim()).length > 0">
          <div class="epicSelection-list" [ngClass]="{'itSelected': isLabelSelected(label)}" *ngFor = "let label of project.labels | search: searchFeatureLabel.trim(); index as i;">
            <div class="epicSelection-check">
              <label class="customCheckFor-epicSelection">
                <input type="checkbox" id="{{'checkSelect_2_' + i}}" class="epic-selected-checkbox" (change)="toggleLabelSelection($event,label)" [checked]="isLabelSelected(label)" hidden/>
                <span class="check-toggleEpicSelection"></span>
              </label>
            </div>
            <label for="{{'checkSelect_2_' + i}}" class="epicSelection-epicName">
              <em class="iconb-label starIcon"></em>
              <span class="epicName labelList">{{label}}</span>
            </label>
          </div>
        </div>
      </div>
      <div class="bulkaction-popUp-actionBtn">
        <button class="bulkaction-popUp-submit" (click) = "bulkFeatureLabelAdd()" type="button" [disabled] = "(SelectedEpics.length == 0 && selectedLabels.length == 0)">Add</button>
        <button class="bulkaction-popUp-cancel" type="button" (click)="closefeaturelabelPopup()">Cancel</button>
      </div>
    </div>
  </div>
  <!--Features Labels Selection and confirmation popup Ends-->

  <!--Move Stories from current Board to another board popup starts-->
  <div class="bulkaction-popUp" *ngIf="moveSelectionPopup">
    <div class="bulkaction-popUpBackdrop" (click)="closeMoveSelectedPopup()"></div>
    <div class="bulkaction-popUp-wrapper">
      <div class="bulkAction-Loader" *ngIf="waitForLoader">
        <img src="../../assets/images/storyBoardLoader.gif" alt="loader"/>
      </div>
      <div class="bulkaction-popUp-title">Move {{ selStories && selStories.length === 1 ? 'Story' : 'Stories' }}</div>
      <span class="bulkaction-popUp-Text1">Select the Buildcard to move the {{ selStories && selStories.length === 1 ? 'story' : 'stories' }}</span>
      <div class="search-box">
        <input type="search" (input) = "getSearchProjects()" [(ngModel)] = "searchProject"  class="bulkaction-epicSearch" placeholder="Search Buildcard" />
        <button type="button" class="bulkaction-epicSearchButton">
          <em class="iconb-search"></em>
        </button>
      </div>
      <div class="bulkaction-popUp-epicListing"
      infiniteScroll
      [scrollWindow]="false"
      [infiniteScrollDisabled]="!isScrollFetchAvailable()"
      (scrolled)="getOlderProject()">
        <div class="moveSelectionlist-wrapper">
          <div class="moveSelection-list" *ngFor = "let prjct of boardProjects; index as i;">
            <div class="moveSelection-radio">
              <label class="customRadioFor-moveSelection">
                <input type="radio" id="{{'move_checkSelect_1_' + i}}" class="move-selected-radio" (change)="selectProjectForMove($event, prjct)" name="selectToMove" hidden/>
                <span class="radio-toggleMoveSelection"></span>
              </label>
            </div>
            <label for="{{'move_checkSelect_1_' + i}}" class="moveSelection-epicName">
              <span class="project-icon"><img src="../../assets/images/projectIcon-default.png" alt="loader"> </span>
              <span class="moveName">{{prjct.name}}</span>
            </label>
          </div>
        </div>
        <div class="mainProjectList-Loader" *ngIf="startProjectScrollLoader && moreAvailable">
          <img src="../../assets/images/storyBoardLoader.gif" alt="loader"/>
        </div>
        <div class="bulkAction-Loader set-height" *ngIf="openStartProjectScrollLoader">
          <img src="../../assets/images/storyBoardLoader.gif" alt="loader"/>
        </div>
        <div class="no-notifications" *ngIf = "!moreAvailable">No more Buildcards.</div>
      </div>
      <div class="bulkaction-popUp-actionBtn">
        <div class="conditionForChild" *ngIf="hasParentSubtasks()">
          <div class="forChildSelection-check">
            <label class="customCheckFor-deleteSelection">
              <input type="checkbox" checked class="delete-selected-checkbox" (change)="toggleApplyOnSubTasks()" [checked]="apply_on_sub_tasks" hidden/>
              <span class="check-toggleDeleteSelection"></span>
            </label>
          </div>
          <div class="conditionForChild-title">Move stories with the sub tasks</div>
        </div>
        <button class="bulkaction-popUp-submit" type="button" (click) = "moveStoriesToProject()" [disabled] = "!selectedProjectId">Move</button>
        <button class="bulkaction-popUp-cancel" type="button" (click)="closeMoveSelectedPopup()">Cancel</button>
      </div>
    </div>
  </div>
  <!--Move Stories from current Board to another board popup Ends-->

  <!--Move Stories for different Devices and Platform popup starts-->
  <div class="bulkaction-popUp" *ngIf="moveDeviceAndPlatformPopup">
    <div class="bulkaction-popUpBackdrop" (click)="closeMoveDeviceAndPlatformPopup()"></div>
    <div class="bulkaction-popUp-wrapper">
      <div class="bulkAction-Loader" *ngIf="waitForLoader">
        <img src="../../assets/images/storyBoardLoader.gif" alt="loader" />
      </div>
      <div class="bulkaction-popUp-title">Move to Devices & Platforms</div>
      <span class="bulkaction-popUp-Text1">Select devices and platforms</span>
      <div class="bulkaction-popUp-epicListing">
        <div class="moveSelectionlist-wrapper">
          <div class="moveDeviceList-wrapper" *ngFor="let devicePlatformData of project.projectDevicePlatformsData; index as k;">
            <div class="deviceType"><em [ngClass]="[devicePlatformData.device ? 'iconb-' + devicePlatformData.device : 'iconb-mobile']"></em> {{devicePlatformData.device}}</div>
            <div class="moveSelection-list" *ngFor="let platform of devicePlatformData.platforms; index as i;">
              <div class="moveSelection-check">
                <label class="customCheckFor-moveSelection">
                  <input type="checkbox"  id="{{'move_checkSelect_2_' + i + k}}"  class="move-selected-checkbox" (change)="togglePlatformSelection($event,platform?.id)" [checked]="isPlatformSelected(platform?.id)" hidden/>
                  <span class="check-toggleMoveSelection"></span>
                </label>
              </div>
              <label for="{{'move_checkSelect_2_' + i + k}}" class="moveSelection-epicName">
                <span class="moveName2">{{ platform?.display_name }}</span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="bulkaction-popUp-actionBtn">
        <button class="bulkaction-popUp-submit" type="button" (click) = "moveBulkStoriesToPlatforms()" [disabled]="selectedPlatformsArr.length==0">Move</button>
        <button class="bulkaction-popUp-cancel" type="button" (click)="closeMoveDeviceAndPlatformPopup()">Cancel</button>
      </div>
    </div>
  </div>
  <!--Move Stories for different Devices and Platform popup Ends-->

  <!--Delete Stories popup Starts-->
  <div class="bulkaction-popUp" *ngIf="deleteSelectionPopup">
    <div class="bulkaction-popUpBackdrop" (click)="closeDeleteSelectionPopup()"></div>
    <div class="bulkaction-popUp-wrapper deleteStories" [ngClass]="{'without_list': !archive_meta?.invalid_to_archive}">
      <div class="bulkAction-Loader" *ngIf="waitForLoader">
        <img src="../../assets/images/storyBoardLoader.gif" alt="loader"/>
      </div>
      <div class="bulkaction-popUp-title">Bulk Archive</div>
      <span class="bulkaction-popUp-Text1">You are archiving {{archive_meta?.total_selected}} user stories.
        <span *ngIf="archive_meta && archive_meta.invalid_to_archive">
          {{archive_meta?.invalid_to_archive}} user stories will not be archived as per core user story rules wherein a feature is expected to have atleast one 1 core user story or you are not authorized to perform this action as experts can't archive stories.
        </span>
      </span>
      <span class="bulkaction-popUp-title2" *ngIf="archive_meta && archive_meta.invalid_to_archive">Stories which will not be archived:</span>
      <div class="selectedStoriesList-wrapper" *ngIf="storiesToArchiveOrUnarchive.length > 0">
        <div class="selectedStoriesList" *ngFor = "let story of storiesToArchiveOrUnarchive">
          <div class="selectedStoriesList-left">
            <div class="story-typeIcon">
              <em class="iconb-task featureIcon" *ngIf = "story.story_type == 'task'"></em>
              <em class="iconb-bug bugIcon" *ngIf = "story.story_type == 'bug'"></em>
            </div>
            <div class="story-NameBox">
              <span class="selected-storyName">{{story.title}}</span>
              <span class="selected-storyEpicName">{{story.epic_title}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="bulkaction-popUp-actionBtn">
        <button class="bulkaction-popUp-submit" (click)="bulkDelete()" *ngIf="!storiesUnableToArchive()" type="button">Confirm</button>
        <button class="bulkaction-popUp-cancel" type="button" (click)="closeDeleteSelectionPopup()">Cancel</button>
      </div>
    </div>
  </div>
  <!--Delete Stories popup Ends-->


  <!--Set owners popup Starts-->
  <div class="bulkaction-popUp" *ngIf="setOwnerPopup">
    <div class="bulkaction-popUpBackdrop" (click)="closeOwnerPopup()"></div>
    <div class="bulkaction-popUp-wrapper">
      <div class="bulkAction-Loader" *ngIf="waitForLoader">
        <img src="../../assets/images/storyBoardLoader.gif" alt="loader"/>
      </div>
      <div class="bulkaction-popUp-title">Set Story Owner</div>
      <span class="bulkaction-popUp-Text1">This action will set/replace the owner for selected stories.</span>
      <div class="search-box">
        <input type="search" [(ngModel)]="searchOwner" class="bulkaction-epicSearch" placeholder="Search by name" />
        <button type="button" class="bulkaction-epicSearchButton">
          <em class="iconb-search"></em>
        </button>
      </div>

      
      
      <div class="bulkaction-popUp-epicListing">
        <div class="moveSelectionlist-wrapper" *ngFor = "let user of project.users  | search: searchOwner: 'name' | squadMemberSearch: checkStorySelection(); index as i;">
          <div class="moveSelection-list" [ngClass]="{'userNotActive': !project.isUserActive(user) || checkSameUser(user,'owner')}">
            <div class="moveSelection-radio">
              <label class="customRadioFor-moveSelection" *ngIf="project.isUserActive(user) && !checkSameUser(user,'owner')">
                <input type="radio" id="{{'owner_checkSelect_1_' + i}}" class="move-selected-radio" [disabled]="!project.isUserActive(user)" (change)="selectOwnerForAllocation($event, user)" name="selectToMove" hidden/>
                <span class="radio-toggleMoveSelection"></span>
              </label>
            </div>
            <label for="{{'owner_checkSelect_1_' + i}}" class="moveSelection-epicName">
              <span class="moveName-initials">{{user.initials.substr(0,1)}}</span>
              <span class="moveName"><span class="nameTitle">{{user.name | titlecase}}</span>
                                     <span class="ifUserDeallocated" *ngIf="!project.isUserActive(user)">{{ project.userInactiveText(user) }}</span>
                                     <span class="ifUserDeallocated" *ngIf="checkSameUser(user,'owner')">Owner and reviewer cannot be same.</span>
              </span> 
            </label>
            <app-invalid-stories *ngIf="selectedOwnerId === user.id && projectService.storiesInvalidToAssignUser.length >0" [selStories]="selStories"></app-invalid-stories>
          </div>
        </div>
      </div>
      <div class="bulkaction-popUp-actionBtn">
        <button class="bulkaction-popUp-submit" type="button" (click) = "allocateStoriesToMember('owner')" [disabled] = "!selectedOwnerId || !checkEligibleStories('owner')">Allocate</button>
        <button class="bulkaction-popUp-cancel" type="button" (click)="closeOwnerPopup()">Cancel</button>
      </div>
    </div>
  </div>
  <!--Set Owners popup Ends-->

  <!--Set Reviewer popup Starts-->
  <div class="bulkaction-popUp" *ngIf="setReviewerPopup">
    <div class="bulkaction-popUpBackdrop" (click)="closeReviewerPopup()"></div>
    <div class="bulkaction-popUp-wrapper">
      <div class="bulkAction-Loader" *ngIf="waitForLoader">
        <img src="../../assets/images/storyBoardLoader.gif" alt="loader"/>
      </div>
      <div class="bulkaction-popUp-title">Set Story Reviewer</div>
      <span class="bulkaction-popUp-Text1">This action will set/replace the reviewer for selected stories.</span>
      <div class="search-box">
        <input type="search" class="bulkaction-epicSearch" [(ngModel)]="searchReviewer" placeholder="Search by name" />
        <button type="button" class="bulkaction-epicSearchButton">
          <em class="iconb-search"></em>
        </button>
      </div>
      
      <div class="bulkaction-popUp-epicListing">
        
        <div class="moveSelectionlist-wrapper" *ngFor = "let user of project.users  | search: searchReviewer: 'name' | squadMemberSearch: checkStorySelection(); index as i;">
          <div class="moveSelection-list" [ngClass]="{'userNotActive': !project.isUserActive(user) || checkSameUser(user,'reviewer')}">
            <div class="moveSelection-radio">
              <label class="customRadioFor-moveSelection" *ngIf="project.isUserActive(user) && !checkSameUser(user,'reviewer')">
                <input type="radio" id="{{'reviewer_checkSelect_1_' + i}}" class="move-selected-radio" (change)="selectReviewerForAllocation($event, user)" name="selectToMove" hidden/>
                <span class="radio-toggleMoveSelection"></span>
              </label>
            </div>
            <label for="{{'reviewer_checkSelect_1_' + i}}" class="moveSelection-epicName">
              <span class="moveName-initials">{{user.initials.substr(0,1)}}</span>
              <span class="moveName"><span class="nameTitle">{{user.name | titlecase}}</span> 
                                    <span class="ifUserDeallocated" *ngIf="!project.isUserActive(user)">{{ project.userInactiveText(user) }}</span>
                                    <span class="ifUserDeallocated" *ngIf="checkSameUser(user,'reviewer')">Owner and reviewer cannot be same.</span>
              </span>
            </label>
            <app-invalid-stories *ngIf="selectedReviewerId === user.id  && projectService.storiesInvalidToAssignUser.length > 0" [selStories]="selStories"></app-invalid-stories>
          </div>
        </div>
      </div>
      <div class="bulkaction-popUp-actionBtn">
        <button class="bulkaction-popUp-submit" type="button" (click) = "allocateStoriesToMember('reviewer')" [disabled] = "!selectedReviewerId || !checkEligibleStories('reviewer')">Allocate</button>
        <button class="bulkaction-popUp-cancel" type="button" (click)="closeReviewerPopup()">Cancel</button>
      </div>
    </div>
  </div>

  <!--Ckeck Owner confirmation Starts-->
  <div *ngIf="isDragForConfirmationAndOwner" class="quickAction-popUp open-quickActionPopup">
      <div class="quickAction-popUpBackdrop"></div>
      <div class="quickAction-popUp-wrapper">
        <div class="drag-Loader" *ngIf="showDragLoader" [class.drag-height2] = "forBothEstimateAndOwner == 'single'">
          <img src="../../assets/images/storyBoardLoader.gif" alt="loader"/>
          </div>
        <ng-container *ngIf="!showDragLoader">
          <div class="topWrapper">
            <span class="story-title">{{story.title}}</span>
            <div class="aboutStory">
              <span class="stateSelected-icon unscheduled"></span>
              <em [class]="story.storyTypeIcon_minCard(story.story_type, story?.parent_id !== null)"></em>
            </div>
            <div class="featureInfo">in <strong style="font-weight: 500">To do</strong></div>
          </div>
          <div class="diffrent_user_drag" *ngIf="!story.owned_by_id">
            Story cannot be started as No Owner is selected.
          </div>
          <div class="diffrent_user_drag" *ngIf="isUserAndOwnerSame === 'not_same_user'">
            You are not authorized to start the story as only Owner can start a story.
          </div>
          <div class="storyStartConfirmation-wrapper" *ngIf="checkStoryOwnerAndLoggedUser() && quickPopupType !== 'reviewer'">
            <div class="storyStartConfirmation-title">You are ready to start this user story <em class="iconb-total-activeuser"></em> </div>
            <div class="storyStartConfirmation-text">Before you start, we want to ensure you're ready. If there are any concerns with below, please reach out to your productologist.</div>
            <div class="option-title">Please confirm-</div>
            <div class="confirmOption-wrapper">
              <div class="confirmOption-list" *ngFor="let list of storyService.confirm_option_list">
                <span class="check-icon"></span> 
                <span class="confirmOption-text">{{list.option}}</span>
              </div>
            </div>
          </div>
          <div class="actionWrapper2">
            <button type="button" class="cancel" (click)="closeConfirmDragPopupOnIcon()">{{checkStoryOwnerAndLoggedUser() ? 'Cancel' : 'Ok'}}</button>
            <button type="button" *ngIf="checkStoryOwnerAndLoggedUser()" class="apply" (click)="confirmStory()">Yes, proceed</button>
          </div>
        </ng-container>
      </div>
    </div>

  <!--Bulk section ends here-->

  <!--For Developres: for initialising alert please add class "openAllocationAlert". and by default its set to 7 days before.
   For last 2 days please one more class "twoDaysLeft" : Starts-->
  <div class="allocationAlert" *ngIf="projectService.allocation_end_date"
       [ngClass]="{'openAllocationAlert': allocationAlert, 'twoDaysLeft': allocationRedAlert}" >
    <em class="iconb-close" (click)="closeAllocationAlert()"></em>
    <em class="iconb-alert"></em>
    <span class="allocationAlert-message">Your allocation {{ freezePanel ? 'ended' : 'ends' }} on {{ allocation_end_date }}. Please ask Productologist to extend, if needed.</span>
  </div>
  <!--Ends-  Allocation end alert for creator  -Ends-->

  <!--For freezing panel if User  time is over on particular project : Starts-->
  <div class="freezePanel" *ngIf="freezePanel"></div>
  <!--For freezing panel if User  time is over on particular project : Ends-->

  <div class="storyBoard-wrapper">
    <div class="storyBoardPanel-Loader" *ngIf="startLoader">
      <img src="../../assets/images/storyBoardLoader.gif" alt="loader"/>
    </div>
    <div class="storyBoard-topBlock">
      <div class="storyBoard-title">Stories</div>

      <div class="storyBoardSearch-wrapper">
        <div class="searchArea-block" (click)="projectService.doSearch()"><em class="iconb-search"></em></div>
        <div class="searchWrapper-backdrop" *ngIf="projectService.openSearchBlock" (click)="projectService.closeSearch()"></div>
        <div class="searchWrapper" [@fadeInOut] *ngIf="projectService.openSearchBlock" [ngClass]="{'searchWrapper-open': projectService.openSearchBlock}">
          <div class="searchInput-wrapper">
            <input #focusSearchStory type="text" class="searchinput" placeholder="Search stories and labels"
                   (input)="clearSearchStoriesData()" [(ngModel)]="searchStr" (keyup.enter)="searchStories(); searchInEpic()"/>{{ projectService.openSearchBlock ? focusSearchStory.focus() : '' }}
            <span class="searchInput-info">Enter to search <em class="iconb-search"></em></span>
          </div>

          <div class="resultToggle-wrapper">
            <button type="button" class="resultStatus-title" [class.activeToggle]="resultStoriesStatus" (click)="showRelatedStories()">Related Stories ({{searchResult.stories && searchResult.stories.length > 0 ? searchResult.stories.length : 0}})</button>
            <div class="resultStatus" [class.resultStoriesActive]="resultStoriesStatus" [class.resultLabelsActive]="resultLabelsStatus">
              <span class="toggleCircle"></span>
            </div>
            <button type="button" class="resultStatus-title" [class.activeToggle]="resultLabelsStatus" (click)="showRelatedLabels()">Related Features/Labels ({{totalCountSearch}})</button>
          </div>
          <div class="searhResultWrapper"
          infiniteScroll
          [scrollWindow]="false"
          [infiniteScrollDisabled]="!moreSearchStoryAvailale"
          (scrolled)="searchStories(true)">
            <div class="blank_search" *ngIf="!searchStr">
              <div class="noSuchProjectInner">
                <div class="blank_searchLoader" *ngIf="searchGoingLoader"><img src="../../assets/images/small-spinner.gif" alt="loader"></div>
                <img src="../../assets/images/no_Project_icon.png" alt="loader"/>
                <p>please enter keywords to search!</p>
              </div>
            </div>
            <div class="resultBlock" *ngIf="resultStoriesStatus && searchStr">
              <!--<div class="result-title">Related stories ({{searchResult.stories && searchResult.stories.length > 0 ? searchResult.stories.length : 0}}) <span *ngIf="searchGoingLoader"><img src="../../assets/images/small-spinner.gif"></span></div>-->
              <div class="blank_search" *ngIf="searchResult.stories && searchResult.stories.length === 0">
                <div class="noSuchProjectInner">
                  <div class="blank_searchLoader" *ngIf="searchGoingLoader"><img src="../../assets/images/small-spinner.gif" alt="loader"></div>
                  <img src="../../assets/images/no_Project_icon.png" alt="loader"/>
                  <p>No result!!</p>
                </div>
              </div>
              <ng-container *ngIf="searchResult.stories && searchResult.stories.length > 0">
                <div
                   class="resultListing" *ngFor="let searchStory of searchResult.stories" (click)="goToStoryDetail(searchStory?.id)">
                  <div class="storyName">{{searchStory.title}}</div>
                  <span class="storyState-icon" [ngClass]="[searchStory.state]"></span>
                  <em [class]="storyTypeIcon(searchStory.story_type, searchStory?.parent_id !== null)"></em>
                  <div class="storyDescription" *ngIf="!isSearchById && !searchStory.title.toLowerCase().includes(oldSearchStr.trim().toLowerCase())">
                    ... <strong>{{this.oldSearchStr?.trim()}}</strong> ...
                  </div>
                </div>
              </ng-container>
              <div class="mainProjectList-Loader" *ngIf="startSearchLoader">
                <img src="../../assets/images/storyBoardLoader.gif" alt="loader"/>
              </div>
            </div>
            <div class="resultBlock" *ngIf="resultLabelsStatus && searchStr">
              <!--<div class="result-title">Related Labels ({{searchResult.labels && searchResult.labels.length > 0 ? searchResult.labels.length : 0}}) <span *ngIf="searchGoingLoader"><img src="../../assets/images/small-spinner.gif"></span></div>-->
              <div class="blank_search" *ngIf="totalCountSearch === 0">
                <div class="noSuchProjectInner">
                  <div class="blank_searchLoader" *ngIf="searchGoingLoader"><img src="../../assets/images/small-spinner.gif" alt="loader"></div>
                  <img src="../../assets/images/no_Project_icon.png" alt="loader"/>
                  <p>No result!!</p>
                </div>
              </div>
              
              <div class="resultListing-labelFeatureWrapper" *ngIf="searchFeatures && searchFeatures.length > 0">
                <div class="featureName" *ngFor="let searchF of searchFeatures" (click)="applyFilter('feature', searchF?.id)">{{searchF.title}}</div>
              </div>
              <div class="resultListing-labelFeatureWrapper" *ngIf="searchResult.labels && searchResult.labels.length > 0">
                <div class="labelName" *ngFor="let searchLabel of searchResult.labels" (click)="applyFilter('label', searchLabel)">{{searchLabel}}</div>
              </div>
              
            </div>
          </div>
        </div>
      </div>

      <!-- Filters related code starts -->
      <div class="fliterButton-wapper" >
        <div class="filterButton" [class.buttonSelected]="showfilterList" (click)="toggelefilter()">
          <em class="iconb-filter"></em>
          <span class="filterbutton-title"><b style="font-weight: 500" class="filterbutton-title">{{project ? project.filterCount : 0}}</b> Filter</span>
        </div>
        <div class="filterDD" [@slideOut] *ngIf="project && showfilterList" [ngClass]="[showfilterList ? 'filterDD-open':'', project.dashboard_project_id ? '':'forInternal']">
          <app-story-filter [project]="project" [currentUser]="currentUser"></app-story-filter>
        </div>
      </div>
      <!-- Filters related code ends -->

      <!-- Mine Filter -->
      <div class="me-filter-btn" [ngClass]="{'selected': filterStoryService.meFilterSelected(project, currentUser?.id), 'disabled': projectService.meFilterLoader}" (click)="toggleMeFilter($event, project, currentUser?.id, true)">
        <em class="iconb-user"></em>Me
        <span class="iconb-close" *ngIf="filterStoryService.meFilterSelected(project, currentUser?.id)" (click)="toggleMeFilter($event, project, currentUser?.id, false)"></span>
      </div>
    <!-- Current Sprint Filter -->
    <div class="viewButton-wapper is-secondary">
      <button class="viewButton" [disabled]="filterStoryService.currentRunningSprintList.length === 0"
        (click)="filterStoryService.currentSprintSelection(project)"
        [ngClass]="{'buttonSelected': sprintDropdown, 'is-selected': filterStoryService.sprintSelected && filterStoryService.currentRunningSprintList.length !== 0 && project?.filters?.sprints.length !== 0}">
        <em class="iconb-sprint-planning sprint-icon"></em>
        <span class="viewbutton-title">Active Sprint{{filterStoryService.currentRunningSprintList.length > 1 ? 's' : ''}}</span>
        <em class="iconb-dd-down dropdown-arrow" (click)="sprintListShow($event)"></em>
      </button>
      <div class="selectColumnDD sprint-dropdown" [@fadeInOut] *ngIf="sprintDropdown"
        [ngClass]="{'selectColumnDD-open': sprintDropdown}">
        <div class="selectColumn-listing" *ngFor="let sprint of filterStoryService.currentRunningSprintList">
          <span class="selectColumn-check">
            <label class="customCheckForColumnSelection">
              <input type="checkbox" id="{{sprint.id}}"
                (change)="filterSelectionCheck(project,$event, 'sprints', sprint.id)"
                class="column-selected-checkbox" [checked]="isFilterChecked('sprints', sprint.id)" hidden />
              <span class="column-check-toggle"></span>
            </label>
          </span>
          <label for="{{sprint.id}}" class="selectColumn-name">{{sprint.name}}</label>
          <span class="iconb-info tooltip-icon" *ngIf="filterStoryService.deletedSprintData && filterStoryService.deletedSprintData?.id === sprint.id">
            <span class="elementHover">The active sprint you are using as a filter has been deleted. Please select another sprint from your filter settings or Refresh to remove the filter.</span>
          </span>
        </div>
      </div>
    </div>

      <div class="viewButton-wapper">
        <div class="viewButton" (click)="toggeleColumnView()" [class.buttonSelected]="showcolumnList">
          <em class="iconb-flow-view"></em>
          <span class="viewbutton-title"><strong style="font-weight: 500" class="viewbutton-title">{{sharedService.visibleIds.length}}</strong> Views</span>
        </div>
        <div class="selectColumnDD" [@fadeInOut] *ngIf="showcolumnList" [ngClass]="{'selectColumnDD-open': showcolumnList}">
          <div class="selectColumn-listing" *ngFor="let col of projectColumns">
            <span class="selectColumn-check">
              <label class="customCheckForColumnSelection">
                <input type="checkbox" id="{{'view'+col.list_id}}" class="column-selected-checkbox" [checked]="col.visible" (change)="toggleColumnVisibility($event,col)" hidden/>
                <span class="column-check-toggle"></span>
              </label>
            </span>
            <label for="{{'view'+col.list_id}}" class="selectColumn-name">{{col?.list_name}}</label>
          </div>
        </div>
      </div>

      <div class="show-hide-task-wrapper" [ngClass]="{'open': showHideTaskDropdown}">
        <div class="btn-trigger" (click)="toggleTaskDropdown()">
          <em class="iconb-eye"></em>Show
        </div>
        <div class="dropdown-wrapper" [@fadeInOut] *ngIf="showHideTaskDropdown">
          <div class="option" *ngFor="let filter of storyTypeFilter" [ngClass]="{active: storyService.parentOrSubtaskStories === filter.type}" (click) = "applyStoryOrSubtaskFilter(filter.type)">{{filter.title}}</div>
        </div>
      </div>
      
      <div class="trackedHour-info" *ngIf="this.project && this.project.creator_tracked_time_data && this.project.creator_tracked_time_data.current_cycle_data">
        <span class="trackedHour-detail">{{ this.project.creator_tracked_time_data.current_cycle_data.tracked_time }}</span>
        <span class="iconb-info">
          <span class="elementHover">Time tracked for cycle {{ this.project.creator_tracked_time_data.current_cycle_data.start_date }} - {{ this.project.creator_tracked_time_data.current_cycle_data.end_date }}</span>
        </span>
      </div>

      <div class="trackedHour-info has-margin" *ngIf="this.project && this.project.creator_tracked_time_data && this.project.creator_tracked_time_data.total_tracked_time">
        <span class="trackedHour-detail">{{ this.project.creator_tracked_time_data.total_tracked_time }}</span>
        <span class="iconb-info">
          <span class="elementHover">Time tracked for {{ project.name }}</span>
        </span>
      </div>


      <a href="{{projectService.docker_ides[0].url}}" *ngIf="projectService.docker_ides && projectService.docker_ides.length === 1" class="ideButton" target="_blank" rel="noopener">
        <em class="iconb-stories"></em>
        Go to Buildcard IDE
        <em class="iconb-copyid" (click)="copyIdeLink($event,projectService.docker_ides[0])" [clip]="projectService.docker_ides[0].url">
          <span class="elementHover">{{projectService.docker_ides[0].linkCopied ? 'IDE link copied' : 'Copy IDE link'}}</span>
        </em>
      </a>
      <div class="ideButton-wrapper" *ngIf="projectService.docker_ides && projectService.docker_ides.length > 1">
        <div class="ideButton" (click)="openIDE()">
          Go to Buildcard IDE <em class="iconb-dd-down"></em>
        </div>
        <div class="ide-DD" [@fadeInOut] *ngIf="showIDEList">
          <div class="ideButtons" *ngFor="let docker_id of projectService.docker_ides" (click)="goToIdeUrl(docker_id.url)">
            <em class="iconb-url-link"></em>
            {{docker_id.repo_name ? docker_id.repo_name.charAt(0).toUpperCase() + docker_id.repo_name.slice(1) : ''}} URL
            <em class="iconb-copyid" (click)="copyIdeLink($event,docker_id)" [clip]="docker_id.url">
              <span class="elementHover" >{{docker_id.linkCopied ? 'IDE link copied' : 'Copy IDE link'}}</span>
            </em>
          </div>
        </div>
      </div>
    </div>

    <div class="storyBoard-block">
      <!--<router-outlet></router-outlet>-->
      <div class="storyBoard-columnWrapper"  [hidden] = "!project">
        <div class="storyBoardPanel-filterLoader" *ngIf="projectService.startApplyFilterLoader">
          <img src="../../assets/images/storyBoardLoader.gif" alt="loader"/>
        </div>
        <!-- [cdkDragDisabled]="column.list_type == 'fixed'" -->
        <div
        #projectStoriesTableContainer
        cdkDropList
        cdkDropListOrientation="horizontal"
        [cdkDropListData]="projectColumns"
        (cdkDropListDropped)="drop($event)"
        [hidden] = "projectColumns.length == 0"
           class = "project-column-list storyBoard">
           <div cdkDropListGroup cdkDragLockAxis="x" [class.make-hidden] = "!column.visible" [cdkDragDisabled]="column.list_type == 'fixed'" *ngFor="let column of projectColumns" [cdkDragData]="column" (cdkDragStarted)="cdkDragStarted($event)" class="storyBoard-column column-list" cdkDrag [ngClass]="{'shrinked': column.expandable,'customColumnStories': customColumnStories(column)}">
           <ng-container *ngIf = "column.visible">
            <div  class="drag_column_header" [ngClass]="{'noStory': columnEmpty(column)}"  [class.moveDisable]="column.list_type == 'fixed'" cdkDragHandle>
              
              <div class="feature-search-wrapper" *ngIf="column.column_type == 'is_feature_column' && column.visible && featureSearchVisible">
                <div class="feature-search-outer">
                  <em class="iconb-search"></em>
                  <input type="text" [(ngModel)]="projectService.featureSearchText" class="search-input" placeholder="Search Feature" />
                  <span class="clear-input iconb-close" (click)="clearFeatureSearch()"></span> 
                </div>
              </div>

              <span class="toggle-title">
                <span class="col-title" *ngIf="(column.column_type === 'is_feature_column' && !projectService.featureSearchText) && column.visible">{{column?.list_name}}
                  <em class="iconb-search" *ngIf="column.column_type == 'is_feature_column' && column.visible" (click)="showFeatureSearch()"></em>
                </span>
                <span class="col-title" *ngIf="column.column_type !== 'is_feature_column' && column.visible">{{column?.list_name}}
                </span>
                <div class="feature-search-string" *ngIf="column.column_type == 'is_feature_column' && column.visible && projectService.featureSearchText && !featureSearchVisible">
                  <span class="string">{{projectService.featureSearchText}}</span>
                  <div class="clear-search" (click)="clearFeatureSearch()">
                    <em class="iconb-close"></em> 
                    <span class="elementHover">Clear Search</span>
                  </div>
                </div>
                <span class="showTotalCount" *ngIf="column.column_type !== 'is_feature_column'">({{column.total_count}})</span>
              </span>

              <div class="featureStatus-toggle" *ngIf="column.column_type == 'is_feature_column' && project && project.dashboard_project_id != null">
                <button type="button" class="epicStatus-title" [class.activeToggle]="epicStatus" (click)="changeEpicViewToStatus()">Status</button>
                <div class="epicStatus" [class.epicStatusActive]="epicStatus" [class.epicEstimateActive]="epicEstimate" (click)="toggleEpicStatusEstimate()">
                  <span class="toggleCircle"></span>
                </div>
                <button type="button" class="epicStatus-title" [class.activeToggle]="epicEstimate" (click)="changeEpicViewToEstimate()">Estimate</button>
              </div>

              <div class="add-feature" *ngIf="column.column_type == 'is_feature_column' && project && project.dashboard_project_id === null" (click)="openAddEpicPopup()">+ Add Feature</div>

              <div class="expand-collapse-btn" (click)="expandCollpaseColumn(column)">
                <em class="iconb-left-arrow"></em>
                <div class="elementHover-extra"> {{column.expandable? 'Expand': 'Collapse'}}</div>
              </div>
            </div>

            <ng-container *ngIf="projectService.showHtml && column.visible && column.column_type == 'is_project_column'">
              <div project-column [column]="column"
              (applyFilterLabelNext) = "applyFilterLabel($event)"
                  [project]="project"
                >
              </div>
            </ng-container>
            <ng-container *ngIf="column.column_type == 'is_feature_column' && column.visible">
              <div epic-column [column]="column"
               (applyFeatureFilterNext) = "applyFilterFeature($event)"
                  [project]="project"
                  >
              </div>
            </ng-container>
            </ng-container>
          </div>
          <div class="storyBoard-column" [ngClass]="{'makeFullLenght':showCutomColumn}" *ngIf = "((sharedService.getCustomColumns().length < 5) && checkProjectAdmin)">
            <div class="column_headerforNewStory" *ngIf = "!showCutomColumn">
            <span class="add-newColumn" (click)="addNewColumn()">
              <span class="plusIcon">+</span>
              <span class="plusIcon-text">Add New List </span>
            </span>
            </div>

            <div class="newList-wrapper" *ngIf = "showCutomColumn">
              <textarea autosize class="newStory-title" [disabled]="disableAddColumn" (keyup.enter)="addColumn()" [(ngModel)] = "newColumnName" placeholder="List name... " maxlength="120"></textarea>
              <div class="newStory-actions">
                <button type="button" (click) = "addColumn()" class="addStory" [disabled]="newColumnName?.trim()?.length == 0 || disableAddColumn">Add List</button>
                <button type="button" (click) = "cancelAddColumn()" class="newStoryForm-close">Cancel</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <a href="" [href]="project.internal_mattermost_channel_url" target="_blank" rel="noopener" class="forChatIcon" *ngIf=" project && project.internal_mattermost_channel_url">
      <img src="../../assets/images/chat-icon.png" alt="take help"/>
    </a>
  </div>

  <div id="storyDIV" class="revStoryDes-popup" #expand [hidden] ="!projectService.show_storyDetails && !projectService.start_storyDetailLoader">
    <div class="revStoryDes-popupWrapper">
      <story-detail [hidden] ="!projectService.show_storyDetails && !projectService.start_storyDetailLoader">
      </story-detail>
    </div>
  </div>

<!--For developers: Add Feature popup-->
<div class="addNewEpic-popup" *ngIf = "addNewEpic">
  <div class="addNewEpic-backdrop" (click) = "closeAddEpicPopup()"></div>
  <div class="addNewEpic-popupWrapper">
    <div class="topSection">
      <div class="topTitle">Add feature</div>
      <span class="addEpic-label">Title</span>
      <input type="text" class="addEpic-inputTitle" [(ngModel)]="newFeatureTitle" placeholder="Enter title"/>
    </div>
    <div class="addEpic-description">
      <span class="addEpic-label">Description</span>
      <textarea maxlength="200" class="addEpic-inputDescription" [(ngModel)]="newFeatureDescription" placeholder="Enter description"></textarea>
    </div>
    <div class="actionButtons">
      <button type="button" class="actionButton submit" [disabled]="newFeatureTitle?.trim()?.length == 0 || disableAddNewFeature" (click)="addNewFeature()">Submit</button>
      <button type="button" class="actionButton cancel" (click) = "closeAddEpicPopup()">Cancel</button>
    </div>
  </div>
</div>

<div *ngIf="openArchivePopUp" class="commonPopUp">
  <div class="commonPopUp-backdrop" (click)="closeArchivePopUp()"></div>
  <div class="commonPopUp-wrapper">
    <div class="common-Loader commonPopup-Loader" *ngIf="waitForLoader">
      <img src="../../assets/images/storyBoardLoader.gif" alt="loader"/>
    </div>
    <div class="commonPopUp-title">Archive</div>
    <div class="commonPopUp-text">The status of the story is {{ story?.state }} and time tracked is {{ sharedService.totalTimeTracked(story) }}, are you sure you want to archive it?</div>
    <div class="commonPopUp-actionBtn">
      <span class="commonPopUp-submit-s" [ngClass]="{'disabled': disableArchive}" (click)="archiveByDrag()">Archive</span>
      <span class="commonPopUp-cancel-s" (click)="closeArchivePopUp()">Cancel</span>
    </div>
  </div>
</div>

<div class="commonPopUp shiftUp" *ngIf="projectService.showCreationReasonDialog">
  <div class="commonPopUp-backdrop" (click)="projectService.closeCreationReasonDialog('reset_all_values')"></div>
  <div class="commonPopUp-wrapper">
    <div class="common-Loader commonPopup-Loader" *ngIf="waitForLoader">
      <img src="../../assets/images/storyBoardLoader.gif" alt=""/>
    </div>
    <div class="commonPopUp-text">It's quite odd that a user story is being created that late. Can you spare a moment and let us know the reason.</div>
    <div class="story-creation-reason-wrapper">
      <div class="selected-reason" (click)="projectService.showReasonList()">{{projectService.selected_reason_option ? projectService.selected_reason_option : 'Select Reason'}}<em class="iconb-dd-down" [ngClass]="{'makeDown':projectService.story_creation_reason}"></em></div>
      <div class="selected-reason-list-wrapper" *ngIf="projectService.story_creation_reason">
        <div class="select-reason" (click)="projectService.selectCreationReason(creationReason.reason)" *ngFor="let creationReason of projectService.creationReasons">{{creationReason.reason}}</div>
      </div>
      <textarea *ngIf="projectService.other_reason" class="otherReason-input" (input)="projectService.getOtherReasonData()" placeholder="Please provide additional information" [(ngModel)]="projectService.otherReason"></textarea>
    </div>
    <div class="commonPopUp-actionBtn">
      <button type="button" class="commonPopUp-submit-s" [disabled]="!projectService.creationReasonsSelected" (click)="projectService.confirmedToCreateStory()">Submit</button>
      <span class="commonPopUp-cancel-s" (click)="projectService.closeCreationReasonDialog('reset_all_values')">Cancel</span>
    </div>
  </div>
</div>

<app-story-clone-popup *ngIf="projectService.cloneConfirmPopup" [project]="project"></app-story-clone-popup>

<app-accept-reject-popup *ngIf="projectService.acceptRejectPopup" [popupType]="projectService.acceptRejectPopupType" [project]="project"></app-accept-reject-popup>

