<div *ngIf="OpenPopUp" class="commonPopUp">
  <div class="commonPopUp-backdrop" (click)="closePopUp()"></div>
  <div class="commonPopUp-wrapper">
    <div class="common-Loader commonPopup-Loader" *ngIf="waitForLoader">
      <img src="../../assets/images/storyBoardLoader.gif" alt=""/>
    </div>
    <div class="commonPopUp-title">{{texts.ptitle}}</div>
    <div class="commonPopUp-text" [innerHtml]="texts.ptext"></div>
    <div class="commonPopUp-actionBtn">
      <span class="commonPopUp-submit" *ngIf='texts.psuccess.length > 0' (click)="deleteEl(delement)">{{texts.psuccess}}</span>
      <span class="commonPopUp-cancel" *ngIf='texts.pcancel.length > 0' (click)="closePopUp()">{{texts.pcancel}}</span>
    </div>
  </div>
</div>
