import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SharedModule } from './../shared/shared.module';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { LeavePlannerComponent } from './leave-planner.component';
import { FormsModule } from '@angular/forms';



@NgModule({
  declarations: [LeavePlannerComponent],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    SharedModule,
    SlickCarouselModule,
    InfiniteScrollModule
  ],
  providers:[],
  exports: [
    LeavePlannerComponent
  ],
})
export class LeavePlannerModule { }
