<div class="storyBoardPanel-Loader" *ngIf="startLoader">
  <img src="../../assets/images/storyBoardLoader.gif" alt=""/>
</div>
<div class="projectsDashboard-wrapper" *ngIf="projects">
  <div class="topSection">
    <div class="topHeading"> Buildcards ({{meta?.total_count}})</div>
    <div class="searchMember">
      <input type="search"
             class="searchMember-input"
             placeholder="Search"
             name="search"
             autocomplete="off"
             [(ngModel)]="params.q"
             (input)="getSearchRecords()">
             <!--(ngModelChange)="getRecordsThrottle.next()">-->
      <button class="searchMember-button"><em class="iconb-search"></em></button>
    </div>
    <span class="exportIcon" (click)="!is_exporting && export()" title="Export">
      <img src="../../../../assets/images/export-file.svg" alt=""/>
    </span>
    <!--<span class="createNewProject" (click) = "sharedService.showprojectAdminForm = true">+ New Project</span>-->
  </div>

  <div class="noMemberFound-message" *ngIf="this.params.q && !this.projects?.length">
    <img src="../../../../assets/images/no-such-projects.png" alt="no project">
    <span class="notFoundText"><strong> No such Buildcard </strong></span>
  </div>

  <div class="allProjectList-wrqapper" *ngIf="this.projects && this.projects.length">
    <div class="allProjectList-topHead">
      <div class="nameBlock">Buildcard Name</div>
      <div class="createdBlock">Created On</div>
      <!--<div class="velocityBlock">Default Velocity (hrs)</div>-->
      <div class="membersBlock"># of Members</div>
      <div class="featureBlock"># of Features</div>
      <div class="storiesBlock"># of Stories</div>
      <div class="storyTypeBlock">Story Type</div>
      <div class="phaseBlock">Phase Wise</div>
      <div class="btHoursBlock">Total BT Recorded Hours</div>
      <div class="btHoursBlock new_x">Total Productologist Approved Hours</div>
      <div class="btHoursBlock">Total Invested Hours</div>
    </div>
    <div class="allProjectList-content">
      <div class="allProjectList-listing" *ngFor="let project of projects">
        <div class="nameBlock">{{ project.name }}</div>
        <div class="createdBlock">{{ project.created_on }}</div>
        <!--<div class="velocityBlock">{{ project.default_velocity }}</div>-->
        <div class="membersBlock">{{ project.memberships_count }}</div>
        <div class="featureBlock">{{ project.features_count }}</div>
        <div class="storiesBlock">{{ project.stories_count }}</div>
        <div class="storyTypeBlock">
          <span class="ss-type">
            <em class="iconb-task taskIcons"></em>
            <span class="typeCounter">{{ project.story_type?.task?project.story_type.task:'0' }}</span>
          </span>
          <span class="ss-type">
            <em class="iconb-bug bugIcons"></em>
            <span class="typeCounter">{{ project.story_type?.bug?project.story_type.bug:'0' }}</span>
          </span>
          <span class="ss-type">
            <em class="iconb-rework reworkIcons"></em>
            <span class="typeCounter">{{ project.story_type?.rework?project.story_type.rework:'0' }}</span>
          </span>
        </div>
        <div class="phaseBlock">{{storiesDetailedCount(project.stories_detailed_count)}}</div>
        <div class="btHoursBlock">{{ project.recorded_time }}</div>
        <div class="btHoursBlock new_x">{{ project.total_approved_time }}</div>
        <div class="btHoursBlock">{{ project.invested_hours }}</div>
      </div>
    </div>
  </div>
  <div class="paginationWrapper" *ngIf="this.projects.length">
    <ul class="pagination">
      <li [class.disabled]="!previousPageAvailable()">
        <span (click)="previousPage()">&laquo;</span>
      </li>
      <li *ngFor="let page of pages" [class.active]="page == params.page">
        <span (click)="goToPage(page)">{{page}}</span>
      </li>
      <li [class.disabled]="!nextPageAvailable()">
        <span (click)="nextPage()">&raquo;</span>
      </li>
    </ul>
  </div>
</div>
