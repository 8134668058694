<div class="projectSprint-wrapper">
  <div class="topMainInfo-section">
    <div class="topTitle">Sprint Planning</div>
    <div class="tabSection">
      <div class="tabOptions" [ngClass]="{'active':showSprint}" (click)="showSprintOptions()">Sprints</div>
      <div class="tabOptions" *ngIf="policyService.projectAdmin(project) || this.policyService.isQAExpert(project)" [ngClass]="{'active':showBacklog}" (click)="showBacklogOptions()">Backlog</div>
    </div>
  </div>
  <div class="sprintInitial-Loader" *ngIf="projectService.initialLoader">
    <img src="../../assets/images/storyBoardLoader.gif" alt=""/>
  </div>
  <div class="tabBlock" *ngIf="showBacklog">
    <app-backlog-stories [project]="project" [currentUser]="currentUser"></app-backlog-stories>
  </div>

  <div class="sprintBlock" *ngIf="showSprint">
    <div class="sprint-wrapper">
      <div class="topInfo-section">
        <div class="topActionButtons">
          <button type="button" class="addNewProject-release" *ngIf="policyService.projectAdmin(project)" (click)="addSprintDetails()">+ Add Sprint</button>
          <div class="integrationSection-search">
            <input type="text" class="integrationSection-searchInput" placeholder="Search Sprints" [(ngModel)]="params.q" (keyup.enter)="getSprints(true)" (input)="checkifSearchInputEmpty()"/>
            <button type="button" class="integrationSection-searchButton">
              <em class="iconb-search"></em>
            </button>
          </div>

          <div class="sprint-sorting">
            <span class="selectedFilter" [ngClass]="{'buttActive':showSortBy}" (click)="openSortBy()">
              <em class="iconb-sorting"></em>  Sort
            </span>
            <div class="sprint-sorting-options" [@fadeInOut] *ngIf="showSortBy">
              <div class="sprint-sorting-option" [ngClass]="{'active': sort_by === 'based_on_status'}" (click)="sortStoryList('based_on_status')"><em class="iconb-calendar"></em> Status</div>
              <div class="sprint-sorting-option" [ngClass]="{'active': sort_by === 'name'}" (click)="sortStoryList('name')"><em class="iconb-calendar"></em> A-Z</div>
              <div class="sprint-sorting-option" [ngClass]="{'active': sort_by === 'number'}" (click)="sortStoryList('number')"><em class="iconb-calendar"></em> Sprint Number</div>
              <div class="sprint-sorting-option" [ngClass]="{'active': sort_by === 'recently_created'}" (click)="sortStoryList('recently_created')"><em class="iconb-calendar"></em> Recently Created</div>
              <div class="sprint-sorting-option" [ngClass]="{'active': sort_by === 'nearest_start_date'}" (click)="sortStoryList('nearest_start_date')"><em class="iconb-calendar"></em> Nearest Start Date</div>
              <div class="sprint-sorting-option" [ngClass]="{'active': sort_by === 'farthest_start_date'}" (click)="sortStoryList('farthest_start_date')"><em class="iconb-calendar"></em> Farthest Start Date</div>
              <div class="sprint-sorting-option" [ngClass]="{'active': sort_by === 'nearest_end_date'}" (click)="sortStoryList('nearest_end_date')"><em class="iconb-calendar"></em> Nearest End Date</div>
              <div class="sprint-sorting-option" [ngClass]="{'active': sort_by === 'farthest_end_date'}" (click)="sortStoryList('farthest_end_date')"><em class="iconb-calendar"></em> Farthest End Date</div>
            </div>
          </div>

        </div>
      </div>
      <div class="ifNoSprint-exits" *ngIf="sprints.length === 0">
        <span class="ifNoSprint-text">No Sprints available!!</span>
        <span class="ifNoSprint-text2">Please create new sprint.</span>
        <button type="button" class="addNewProject-release" *ngIf="policyService.projectAdmin(project)" (click)="addSprintDetails()">+ Add Sprint</button>
      </div>
      <div class="releaseVersion-table" *ngIf="sprints.length > 0" 
        infiniteScroll
        [scrollWindow]="false"
        [infiniteScrollDisabled]="!isScrollFetchAvailable()"
        (scrolled)="getOlder()">
        <div class="releaseVersion-tableHead">
          <div class="tableHead-col2">No</div>
          <div class="tableHead-col4">Name</div>
          <div class="tableHead-col11">Goal</div>
          <div class="tableHead-col5">Status</div>
          <div class="tableHead-col10">Duration</div>
          <div class="tableHead-col10">Started On</div>
          <div class="tableHead-col10">Updated On</div>
          <div class="tableHead-col7">Started By</div>
          <div class="tableHead-col7">Updated By</div>
          <div class="tableHead-col3"></div>
        </div>
        <div class="releaseVersion-tableContent">
          <div class="releaseVersion-tableRow" *ngFor="let sprintData of sprints;let i = index" (click)="gotoSprintDetail($event, sprintData)">
            <div class="tableHead-col2">
              <div class="sprint-counter">{{sprintData?.number? sprintData?.number : '-'}}</div>
            </div>
            <div class="tableHead-col4">
              <div class="sprint-name">
                <span class="name-text" *ngIf="!sprintData.showInput">{{sprintData.name}}</span>
                <span class="iconb-edit-2 edit-bt" *ngIf="!sprintData.showInput && policyService.projectAdmin(project)" (click)="showEditInput(sprintData)"></span>
                <input *ngIf="sprintData.showInput" type="text" class="editName" (focusout)="updateSprintName(sprintData)" #makeFocusName [(ngModel)]="sprintData.name">
              </div>
              <div class="story-progress" *ngIf="sprintData?.stories_count > 0">
                <div class="progress-wrapper">
                  <span *ngIf="sprintData?.stories_status?.unscheduled > 0" class="status-type unscheduled" [ngStyle]="{'width': storyProgress(sprintData?.stories_status?.unscheduled, sprintData?.stories_count) + '%'}"></span>
                  <span *ngIf="sprintData?.stories_status?.unstarted > 0" class="status-type unstarted" [ngStyle]="{'width': storyProgress(sprintData?.stories_status?.unstarted, sprintData?.stories_count) + '%'}"></span>
                  <span *ngIf="sprintData?.stories_status?.started > 0" class="status-type started" [ngStyle]="{'width': storyProgress(sprintData?.stories_status?.started, sprintData?.stories_count) + '%'}"></span>
                  <span *ngIf="sprintData?.stories_status?.finished > 0" class="status-type finished" [ngStyle]="{'width': storyProgress(sprintData?.stories_status?.finished, sprintData?.stories_count) + '%'}"></span>
                  <span *ngIf="sprintData?.stories_status?.delivered > 0" class="status-type delivered" [ngStyle]="{'width': storyProgress(sprintData?.stories_status?.delivered, sprintData?.stories_count) + '%'}"></span>
                  <span *ngIf="sprintData?.stories_status?.accepted > 0" class="status-type accepted" [ngStyle]="{'width': storyProgress(sprintData?.stories_status?.accepted, sprintData?.stories_count) + '%'}"></span>
                  <span *ngIf="sprintData?.stories_status?.rejected > 0" class="status-type rejected" [ngStyle]="{'width': storyProgress(sprintData?.stories_status?.rejected, sprintData?.stories_count) + '%'}"></span>
                </div>
              
                <div class="story-breakup" *ngIf="sprintData?.stories_count > 0" [ngStyle]="{'top':(sprints.length > 3 && i >= sprints.length - 1) ? ('-' + (sprintData.no_of_states*27 + 64) + 'px') : (23 + 'px')}">
                  <div class="story-breakup-title">{{sprintData.stories_count}} {{sprintData.stories_count > 1 ? 'Stories' : 'Story'}}</div>
                  <ng-container *ngFor="let state of sprintData.stories_status | keyvalue">
                    <div class="story-breakup-list" *ngIf="state.value > 0">
                      <span class="story-status-color {{state.key}}"></span>
                      <span class="story-status-name">{{state.key | titlecase}}</span>
                      <span class="story-count">{{state.value}}</span>
                    </div>
                  </ng-container>
                </div>
  
              </div>
              <div class="stories-status-values" *ngIf="sprintData?.stories_count > 0">{{sprintData?.stories_status?.accepted}}/{{sprintData?.stories_count}} Accepted</div>
              <div class="stories-status-values" *ngIf="sprintData?.stories_count === 0">No stories added</div>
            </div>
            <div class="tableHead-col11">
              <div class="story-summary">
                <span class="story-summary-text" *ngIf="!sprintData.showDescInput">{{sprintData?.description}}</span>
                <em class="iconb-edit-2 editTitle" *ngIf="!sprintData.showDescInput && policyService.projectAdmin(project)" (click)="showEditDescritption(sprintData)"></em>
                <input *ngIf="sprintData.showDescInput" type="text" class="editDescription" (focusout)="updateSprintGoal(sprintData)" #makeFocusDesc [(ngModel)]="sprintData.description">
              </div>
            </div>
            <div class="tableHead-col5">
              <div class="sprint-status" [ngClass]="[sprintData.status]">{{sprintData?.status}}</div>
            </div>
            <div class="tableHead-col10">
              <span *ngIf="sprintData?.projected_start_date">{{getDateFormat(sprintData?.projected_start_date)}} - {{getDateFormat(sprintData?.projected_end_date)}}</span>
              <span *ngIf="!sprintData?.projected_start_date">-</span>
              <span class="no_of_working_days" *ngIf="sprintData.projected_start_date"><em class="iconb-calendar"></em> {{sprintData.numberOfWorkdays}} days</span>
            </div>
            <div class="tableHead-col10">
              <div class="sprint-date">{{sprintData?.started_at? getDateFormat(sprintData?.started_at) : '-'}}</div>
            </div>
            <div class="tableHead-col10">
              <div class="sprint-date">{{sprintData?.updated_at? getDateFormat(sprintData?.updated_at) : '-'}}</div>
            </div>
            <div class="tableHead-col7">
              <div class="nameBlock">
                <div class="userInitials noMember" *ngIf="!sprintData?.started_by"><em class="iconb-add-member"></em></div>
                <div class="userInitials" *ngIf="sprintData?.started_by">{{sprintData?.started_by?.initials.substr(0,1)}}</div>
                <span class="elementHover" *ngIf="sprintData?.started_by?.name">{{sprintData?.started_by?.name}}</span>
              </div>
            </div>
            <div class="tableHead-col7">
              <div class="nameBlock">
                <div class="userInitials" *ngIf="!sprintData?.updated_by"><em class="iconb-add-member"></em></div>
                <div class="userInitials" *ngIf="sprintData?.updated_by">{{sprintData?.updated_by?.initials.substr(0,1)}}</div>
                <span class="elementHover" *ngIf="sprintData?.updated_by?.name">{{sprintData?.updated_by?.name}}</span>
              </div>
            </div>
            <div class="tableHead-col3">
              <div class="moreActions" *ngIf="sprintData.status !== 'completed' && policyService.projectAdmin(project)">
                <em class="iconb-more moreAction-bt" [ngClass]="{'makeActive':sprintData.showMoreAction}" (click)="openMoreAction($event, sprintData)"></em>
                <div class="storyaction-wrapper" [@fadeInOut2] *ngIf="sprintData.showMoreAction">
                  <div class="storyaction-option" *ngIf="sprintData.status === 'draft' || sprintData.status === 'paused'" (click)="openStartSprintConfirmation(sprintData , 'start_sprint')"><em class="iconb-start-sprint"></em> {{(sprintData.status === 'paused') ? 'Resume Sprint' : 'Start Sprint'}} </div>
                  <div class="storyaction-option" *ngIf="sprintData.status !== 'draft' && sprintData.status !== 'paused' && sprintData.status !== 'completed'" (click)="openCompleteSprintConfirmation(sprintData , 'completed')"><em class="iconb-check-circle"></em> Complete Sprint</div>
                  <div class="storyaction-option" *ngIf="sprintData.status !== 'draft' && sprintData.status !== 'paused' && sprintData.status !== 'completed'" (click)="openPauseSprintConfirmation(sprintData , 'paused')"><em class="iconb-pause-small"></em> Pause Sprint</div>
                  <div class="storyaction-option" *ngIf="sprintData.status !== 'completed'"  (click)="policyService.projectAdmin(project) ? editSprintDetails(sprintData) : gotoSprintDetail($event, sprintData)"><em class="iconb-edit-2"></em>Edit Sprint Details</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="sprintInitial-Loader" *ngIf="storyListLoader">
      <img src="../../assets/images/storyBoardLoader.gif" alt=""/>
    </div>
  </div>
</div>

<div class="addSprintPopup" *ngIf="sprintService.editSprint">
  <app-sprint-popup [sprint_Details]="createOrUpdateSprint" [popupFor]="popupFor" [projectId]="projectId"></app-sprint-popup>
</div>

<div *ngIf="sprintStartActionPopUp" class="commonPopUp">
  <div class="commonPopUp-backdrop" (click)="closeStartSprintConfirmation()"></div>
  <div class="commonPopUp-wrapper">
    <div class="common-Loader commonPopup-Loader" *ngIf="waitForLoader">
      <img src="../../assets/images/storyBoardLoader.gif" alt=""/>
    </div>
    <div class="commonPopUp-title">{{(sprint_start_data.status === 'paused') ? 'Resume Sprint' : 'Start Sprint'}}</div>
    <div class="commonPopUp-text" *ngIf="canStartSprint && sprint_start_data.status === 'paused'">Are you sure you want to resume the sprint?</div>
    <div class="commonPopUp-text" *ngIf="canStartSprint && sprint_start_data.status === 'draft'">{{sprint_start_data.checkSprintStartingDate()}}</div>
    <div class="commonPopUp-text" *ngIf="!canStartSprint">Please add stories into the sprint.</div>
    <div class="commonPopUp-actionBtn" *ngIf="canStartSprint">
      <span class="commonPopUp-submit-s" (click)="actionSprint(sprint_start_data, sprintActionType)">{{(sprint_start_data.status === 'paused') ? 'Resume' : 'Start'}}</span>
      <span class="commonPopUp-cancel-s" (click)="closeStartSprintConfirmation()">Cancel</span>
    </div>
    <div class="commonPopUp-actionBtn" *ngIf="!canStartSprint">
      <span class="commonPopUp-cancel-s" (click)="closeStartSprintConfirmation()">Ok</span>
    </div>
  </div>
</div>

<div *ngIf="sprintCompletetActionPopUp" class="commonPopUp">
  <div class="commonPopUp-backdrop" (click)="closeCompleteSprintConfirmation()"></div>
  <div class="commonPopUp-wrapper">
    <div class="common-Loader commonPopup-Loader" *ngIf="waitForLoader">
      <img src="../../assets/images/storyBoardLoader.gif" alt=""/>
    </div>
    <div class="commonPopUp-title">Complete Sprint</div>
    <div class="commonPopUp-text" *ngIf="canCompleteSprint && !sprint_start_data.futureEndDate(sprint_start_data.projected_end_date)">Are you sure you want to complete the sprint?</div>
    <div class="commonPopUp-text" *ngIf="canCompleteSprint && sprint_start_data.futureEndDate(sprint_start_data.projected_end_date)">You are in the process of closing the sprint, but it appears that an end date is in the future. As per your request, the system will proceed to close the sprint; however, it will automatically adjust the end date to today's date for accuracy.</div>
    <div class="commonPopUp-text" *ngIf="!canCompleteSprint">Oops. Sprint cannot be completed because some stories are still not accepted.</div>
    <div class="commonPopUp-actionBtn" *ngIf="canCompleteSprint">
      <span class="commonPopUp-submit-s" (click)="actionSprint(sprint_start_data, sprintActionType)">{{sprint_start_data.futureEndDate(sprint_start_data.projected_end_date) ? 'Confirm' : 'Complete'}}</span>
      <span class="commonPopUp-cancel-s" (click)="closeCompleteSprintConfirmation()">Cancel</span>
    </div>
    <div class="commonPopUp-actionBtn" *ngIf="!canCompleteSprint">
      <span class="commonPopUp-cancel-s" (click)="closeCompleteSprintConfirmation()">Ok</span>
    </div>
  </div>
</div>

<div *ngIf="sprintPausePopUp" class="commonPopUp">
  <div class="commonPopUp-backdrop" (click)="closePauseSprintConfirmation()"></div>
  <div class="commonPopUp-wrapper">
    <div class="common-Loader commonPopup-Loader" *ngIf="waitForLoader">
      <img src="../../assets/images/storyBoardLoader.gif" alt=""/>
    </div>
    <div class="commonPopUp-title">Pause Sprint</div>
    <div class="commonPopUp-text">Are you sure you want to pause the sprint?</div>
    <div class="commonPopUp-actionBtn">
      <span class="commonPopUp-submit-s" (click)="actionSprint(sprint_start_data, sprintActionType)">Pause</span>
      <span class="commonPopUp-cancel-s" (click)="closePauseSprintConfirmation()">Cancel</span>
    </div>
  </div>
</div>

<div id="storyDIV" #expand class="revStoryDes-popup"  [hidden] ="!projectService.show_storyDetails && !projectService.start_storyDetailLoader">
  <div class="revStoryDes-backdrop" (click) = "projectService.hide_StoryDetails()"></div>
  <div class="revStoryDes-popupWrapper">
    <story-detail [hidden] ="!projectService.show_storyDetails && !projectService.start_storyDetailLoader">
    </story-detail>
  </div>
</div>

<div class="commonPopUp commonSecondary" *ngIf="moveStoriesToSprint">
  <div class="commonPopUp-backdrop" (click)="closeMoveStoriesToSprint()"></div>
  <div class="commonPopUp-wrapper">
    <span class="commonPopUp-close iconb-close" (click)="closeMoveStoriesToSprint()"></span>
    <div class="commonPopUp-title">Move Stories to close Sprint</div>
    <div class="commonPopUp-body minHeight">
      <p class="info-text">There are still <strong>{{spillOverStoryCount}} open {{spillOverStoryCount > 1 ? 'stories' : 'story'}}</strong> in this sprint. Please move them to next sprint. <span class="red-text">*</span></p>
      <div class="story-creation-reason-wrapper forBulk_duplicate full-width">
        <div class="selected-reason" (click)="sprintselection()">{{selectedSprintForMove.name ? selectedSprintForMove.name : 'Select Sprint'}}<em class="iconb-dd-down" [ngClass]="{'makeDown': sprintselectionDropdown}"></em></div>
        <div class="selected-reason-list-wrapper scrollable" *ngIf="sprintselectionDropdown">
          <ng-container *ngFor="let sprint of sprintMoveOptions">
            <div class="select-reason" (click)=selectSprintForMove(sprint)>{{sprint.name}}</div>
          </ng-container>
        </div>
       </div>
    </div>
    <div class="commonPopUp-actionBtn">
      <button class="commonPopUp-cancel-s is-black" (click)="closeMoveStoriesToSprint()">Cancel</button>
      <button class="commonPopUp-submit-s" [disabled]="!selectedSprintForMove.name" (click)="moveStoriesAndCompleteSprint(sprint_start_data,project.id,selectedSprintForMove.id)">Confirm</button>
    </div>
  </div>
</div>


<div *ngIf="sprintService.enterDateAlertPopup" class="commonPopUp">
  <div class="commonPopUp-backdrop" (click)="sprintService.closeDateAlertPopup()"></div>
  <div class="commonPopUp-wrapper">
    <div class="common-Loader commonPopup-Loader" *ngIf="waitForLoader">
      <img src="../../assets/images/storyBoardLoader.gif" alt=""/>
    </div>
    <div class="commonPopUp-title">Start Sprint</div>
    <div class="commonPopUp-text">Please enter sprint start/end date first.</div>
    <div class="commonPopUp-actionBtn">
      <span class="commonPopUp-submit-s" (click)="openSprintEdit()">Select date</span>
      <span class="commonPopUp-cancel-s" (click)="sprintService.closeDateAlertPopup()">Cancel</span>
    </div>
  </div>
</div>
