import {map} from 'rxjs/operators';
import { Injectable, Inject, forwardRef } from '@angular/core';
import {
  FileItem
} from '../../../lib';

import { Document } from '../models/document';
import { API_ROUTES } from '../apiRoutes';
import { NotificationService } from './notification.service';
import { DataService } from './data.service';
import { HttpRequests } from './http-requests.service';

@Injectable()
export class DocumentService {
  constructor(
    private httpRequest: HttpRequests,
    private dataService: DataService,
    @Inject(forwardRef(() => NotificationService)) private notificationService: NotificationService) {
  }

  uploadAuthentication() {
    const API = this.dataService.apiUrl + API_ROUTES.UPLOAD_AUTHORIZATION;
    return this.httpRequest.get(API, this.dataService.getRequestOptionArgs()).pipe(
             map(res => this.parseResponse(res)));
  }

  parseS3Response(response) {
    const data: any = {};
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(response,"text/xml");

    data.url = xmlDoc.getElementsByTagName('Location')[0].childNodes[0].nodeValue;
    data.filepath = xmlDoc.getElementsByTagName('Key')[0].childNodes[0].nodeValue;

    return data;
  }

  createDocument(item: FileItem) {
    const data = {
      name: item.file.name,
      progress: 1
    };

    return data;
  }

  updateDocumentOnSuccess(item: FileItem, doc: Document) {
    doc['remote_url'] = item.metadata.url.replace(/%2F/g, '/');
    doc['key'] = item.metadata.filepath;
    doc['path'] = item.metadata.filepath;
    doc['content_type'] = item.file.type;
    doc['size'] = item.file.size;
    doc['progress'] = item.progress;

    return doc;
  }

  /*buildDocumentMetadata(item: FileItem, story: Story) {
    const data: any = {};
    const file = item.file;

    data['remote_url'] = item.metadata.url.replace(/%2F/g, "/");
    data['key'] = item.metadata.filepath;
    data['path'] = item.metadata.filepath;
    data['name'] = file.name;
    data['size'] = file.size;
    data['content_type'] = file.type;

    return data;
  }*/


  buildImportDocumentMetadata(item: FileItem) {
    const data: any = {};
    const file = item.file;

    data['remote_url'] = item.metadata.url.replace(/%2F/g, "/");
    // data['key'] = item.metadata.filepath;
    // data['path'] = item.metadata.filepath;
    data['name'] = file.name;
    data['size'] = file.size;
    data['content_type'] = file.type;

    return data;
  }

  public parseResponse(res: any): any {
    return res;
  }
}
