import { Component, OnInit, Input, EventEmitter, Output, HostListener, ElementRef } from '@angular/core';
import { ReleasePlannerService } from '../../shared/services/release-planner.service';
import { ErrorService } from '../../shared/errorFunction';
import { Story } from '../../shared/models/story';
import { Subscription } from 'rxjs';
import { SharedService } from '../../shared/services/shared.service';
import { Sprint } from '../../shared/models/sprint';
import { CustomToastService } from '../../shared/services/custom-toast.service';
import { ReleasePlannerModal } from '../../shared/models/releasePlannerModal';
import * as _ from 'lodash';
import { ALLOWED_CLASS_IN_DD, PHASES_AND_DELIVERABLES } from '../../shared/dataTypes';
import moment from 'moment';
import { IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';

@Component({
  selector: 'app-create-edit-release',
  templateUrl: './create-edit-release.component.html',
  styleUrls: ['./create-edit-release.component.scss']
})
export class CreateEditReleaseComponent implements OnInit {
  @Input() project;
  @Input() selected_release;
  @Output() closeReleasePopup = new EventEmitter<boolean>();
  @Output() openReleaseDetailPopup = new EventEmitter<boolean>();
  stories: Array<Story> = [];
  sprints: Array<Sprint> = [];
  phases: Array<any> = [];
  showDevicePlatformDD: boolean = false;
  addStoryVisible: boolean = true;
  addSprintVisible: boolean = false;
  showDropDown: boolean = false;
  dropDownType: string = '';
  searchStoryData:string = '';
  searchSprintData:string = '';
  endDt: any;
  public mytime: Date = new Date();
  currentYear: any = this.mytime.getUTCFullYear();
  currentDate: any = this.mytime.getUTCDate() - 1;
  currentMonth: any = this.mytime.getUTCMonth() + 1;
  datePickerOptionsEnd: IAngularMyDpOptions = {
    dateFormat: 'dd/mm/yyyy',
    openSelectorTopOfInput: true,
    showSelectorArrow: false,
    alignSelectorRight: true,
    disableUntil:{year: this.currentYear, month: this.currentMonth, day: this.currentDate}
  };
  listSubscriptions: Subscription;
  listSprintSubscriptions: Subscription;
  storiesMeta: {
    current_page: number,
    next_page: number,
    per_page: number,
    total_count: number
  };
  sprintsMeta: {
    current_page: number,
    next_page: number,
    per_page: number,
    total_count: number
  };
  storiesSelected = [];
  sprintsSelected = [];
  storyListLoader: boolean = false;
  sprintListLoader: boolean = false;
  attachOnlyArr: Array<any> = [];
  attachmentPreviewUrls: Array<any> = [];
  waitLoader: boolean = false;
  release = {
    name: '',
    version: null,
    estimated_end_date: null,
    build_url: '',
    release_details: '',
    phase_deliverables: { title: '', value: '' },
    phase: { name: '', value: '' },
    story_ids: [],
    sprint_ids: [],
    platforms:[]
  };
  phases_list = [];
  phase_deliverables_list = [];
  selectedDevicePlatform = '';
  allowedClassNames = ALLOWED_CLASS_IN_DD;
  waitForLoader: boolean = false;
  editAttachment = [];
  deleted_attachment = [];
  openReleaseDetailPopUp: boolean = false;
  release_detail_for = '';
  release_detail_data:any;
  isFormValid: boolean = false;
  disableEndDate:boolean = false;
  temp_release_data:any;
  available_platforms = [];
  
  constructor(
    private releasePlannerService: ReleasePlannerService,
    private errorService: ErrorService,
    public sharedService: SharedService,
    private customToast: CustomToastService,
    private elementRef: ElementRef
  ) { }

  set_release_detail_data(data) {
    this.release_detail_data = data;
    if(this.selected_release) {
      this.selected_release.release_detail_text = data.body;
    }
    this.areFieldsValid();
  }

   ngOnInit() {
    const release_exist = this.selected_release && this.selected_release.id ? true : false;
    this.phases_list = PHASES_AND_DELIVERABLES.map(phase => ({
      name: phase.name,
      value: phase.value
    }));
    this.storyListLoader = true;
    release_exist ? this.renderReleaseData() : this.loadStories();
    if(!release_exist) {
      this.setPlatformOptions(false);
    }
  }

  platformMapping() {
    if(this.selected_release?.platforms?.length > 0) {
      this.selected_release.platforms.forEach(platform => {
        this.selectedDevicePlatform += ' ' + platform.display_name + ',';
        this.release.platforms.push({platform_id: platform.id});
      });
      this.project.projectDevicePlatformsData.forEach(data => {
        data.platforms.forEach(platform => {
          if(this.selected_release && this.selected_release.platforms.findIndex(item => item.id === platform.id) > -1) {
            platform['selected'] = true;
          }
        });
        this.available_platforms.push(data);
      });
    } else {  
      this.selectedDevicePlatform = '';
      this.setPlatformOptions(false);
    }
    this.areFieldsValid();
  }

  setPlatformOptions(action: boolean = false) {
    if(action) {
      this.platformMapping();
    } else {
      this.release.platforms = [];
      this.selectedDevicePlatform = '';
      this.project.projectDevicePlatformsData.forEach(data => {
        data.platforms.forEach(platform => {
          platform['selected'] = false;
        });
        this.available_platforms.push(data);
      });
    }
    this.areFieldsValid();
  }

  preparePhaseDeliverablesLIst(phase) {
    const phase_options = PHASES_AND_DELIVERABLES.find(item => item.value === phase.value);
    if(phase_options) {
      this.phase_deliverables_list = [];
      this.release.phase_deliverables = { title: '', value: '' };
      phase_options.options.forEach(item => {
        this.phase_deliverables_list.push(item);
      });
    }
  }

  renderReleaseData() {
    this.release.name = this.selected_release.name;
    this.release.version = this.selected_release.version;
    if (this.selected_release.estimated_end_date) {
      const formattedDate = this.selected_release.estimated_end_date;
      const momentDate = moment(formattedDate, 'DD MMM YYYY | HH:mm:ss');
      const jsDate = momentDate.toDate(); 
      this.endDt = {
        isRange: false,
        singleDate: {
          date: {
            year: jsDate.getFullYear(),
            month: jsDate.getMonth() + 1,
            day: jsDate.getDate()
          },
          jsDate: jsDate
        }
      };
      this.release.estimated_end_date = moment(this.endDt.singleDate.jsDate).format('DD/MM/YYYY');
    }
    this.release.build_url = this.selected_release.build_url;
    this.release.phase = {name: this.selected_release.phase, value: this.selected_release.phase};
    this.preparePhaseDeliverablesLIst(this.release.phase);
    this.release.phase_deliverables = {title:this.selected_release.phase_deliverables, value:this.selected_release.phase_deliverables};
    this.release.build_url = this.selected_release.build_url;
    if(this.selected_release.attachments.length > 0){
      this.selected_release.attachments.forEach(attachment => {
        this.editAttachment.push(attachment);
      });
    }
    this.setPlatformOptions(true);
    this.temp_release_data = _.cloneDeep(this.release);
    this.areFieldsValid();
  }

  areFieldsValid() {
    this.isFormValid = this.release.name &&
           this.release.version &&
           this.release.phase &&
           this.release.phase_deliverables &&
           this.release.platforms &&
           this.release.platforms.length > 0 &&
           (this.endDt || this.release.estimated_end_date) && 
           ((this.release_detail_data && this.release_detail_data.body) || (this.selected_release && this.selected_release.release_detail_text));
  }

  updateRelease() {
    this.waitLoader = true;
    // Collect the data for updating the release
    this.release.story_ids = this.storiesSelected;
    this.release.sprint_ids = this.sprintsSelected;

    // prepare payload
    const formData = this.releasePlannerService.prepareFormData(this.release, this.release_detail_data, this.attachOnlyArr, 'update', this.temp_release_data, this.deleted_attachment);
    
    //Call the service to update the release
    this.releasePlannerService.updateRelease(this.project, this.selected_release, formData).subscribe(res => {
      this.customToast.messages.push({
        id: 'release_updated',
        type: 'success',
        class: 'stories_moved',
        title: 'Release',
        message: 'Release updated successfully'
      });
      this.closeReleasePopup.emit(false);
      this.release_detail_for = '';
      this.waitLoader = false;
    }, (error) => {
      this.waitLoader = false;
      this.errorService.errorFunction(error);
    });
  }

  createRelease() {
    this.waitLoader = true;

    //collect the data for creating release
    this.release.story_ids = this.storiesSelected;
    this.release.sprint_ids = this.sprintsSelected;

    // prepare payload
    const formData = this.releasePlannerService.prepareFormData(this.release, this.release_detail_data, this.attachOnlyArr, 'create');

    //call the service to create release
    this.createReleaseCall(formData);
  }

  createReleaseCall(formData) {
    this.releasePlannerService.createRelease(this.project, formData).subscribe(res => {
      this.customToast.messages.push({
        id: 'release_created',
        type: 'success',
        class: 'stories_moved',
        title: 'Release',
        message: 'Release created successfully'
      });
      this.closeReleasePopup.emit(false);
      this.release_detail_for = '';
      this.waitLoader = false;
    }, (error) => {
      this.waitLoader = false;
      this.errorService.errorFunction(error);
    });
  }

  toggleDevicePlatform(event, platform) {
    const hasValidInputs = event && event.target && platform.id;
    if (!hasValidInputs) {
      return;
    }
    
    if (event.target.checked) {
      if (!_.includes(this.release.platforms, platform.id)) {
        const data = {
          platform_id : platform.id
        };
        this.release.platforms.push(data);
        this.selectedDevicePlatform += ' ' + platform.display_name + ',';
      }
    } else {
      const ind = this.release.platforms.findIndex(item => item.platform_id === platform.id);
      if (ind >= 0) {
        this.release.platforms.splice(ind, 1);
        this.selectedDevicePlatform = this.selectedDevicePlatform.replace((platform.display_name + ','), '').trim();
      }
    }
    this.areFieldsValid();
  }

  onEndDateChanged(event) {
    this.release.estimated_end_date = event.singleDate.formatted;
    this.areFieldsValid();
  }

  selectPhaseAndDeliverables(phase, type) {
    if(type === 'phase') {
      this.preparePhaseDeliverablesLIst(phase);
    }
    type === 'phase' ? this.release.phase = phase : this.release.phase_deliverables = phase;
    this.dropDownType = '';
    this.showDropDown === false;
    this.areFieldsValid();
  }

  ngOnDestroy() {
    this.clearCallSubscription();   
    this.clearSprintCallSubscription();
    // reset the this.release object
    this.release = {
      name: '',
      version: null,
      estimated_end_date: null,
      build_url: '',
      release_details: '',
      phase_deliverables: { title: '', value: '' },
      phase: { name: '', value: '' },
      story_ids: [],
      sprint_ids: [],
      platforms:[]
    }
  }

  clearCallSubscription() {
    if (this.listSubscriptions) {
      this.listSubscriptions.unsubscribe();      
    }
  }

  clearSprintCallSubscription() {
    if (this.listSprintSubscriptions) {
      this.listSprintSubscriptions.unsubscribe();      
    }
  }

  searchStories() {
    this.storyListLoader = true;
    this.stories = [];
    this.clearCallSubscription();
    this.loadStories();
  }

  loadStories() {
    const pageno = this.storiesMeta && this.storiesMeta.next_page ? this.storiesMeta.next_page : 1;
    this.listSubscriptions = this.releasePlannerService.getStoriesForRelease(this.project, this.searchStoryData, pageno,'').subscribe(res => {
      if(res.data && res.data.length === 0) {
        this.stories = [];
      }
      if(res) {
        this.storiesMeta = res.meta;
        if(this.storiesMeta.current_page === 1) {this.stories = [];}
        res.data.forEach(story => {
          story['selected'] = this.storiesSelected.indexOf(story.id) > -1 ? true : false;
          this.stories.push(new Story(story));
        });
      }
      this.storyListLoader = false;
    }, (error) => {
      this.errorService.errorFunction(error);
      this.storyListLoader = false;
    });
  }

  focusDropDown(dropDownType: string) {
    if (this.showDropDown === false) {
      this.dropDownType = dropDownType;
      this.showDropDown = true;
      } else if (this.dropDownType === dropDownType) {
      this.showDropDown = false;
      this.dropDownType = '';
      } else {
      this.dropDownType = dropDownType;
    }
  }

  closeReleaseVersionPopup() {
    this.closeReleasePopup.emit(false);
  }

  devicePlatformSelection() {
    this.showDevicePlatformDD = !this.showDevicePlatformDD;
  }

  addStoriesToggle() {
    this.addStoryVisible = true;
    this.addSprintVisible = false;
  }

  addStories(story) {
    story.selected = true;
    this.storiesSelected.push(story.id);
  }

  removeStories(story) {
    story.selected = false;
    this.storiesSelected.splice(this.storiesSelected.indexOf(story.id), 1);
  }

  addSprints(sprint) {
    sprint.selected = true;
    this.sprintsSelected.push(sprint.id);
  }

  removeSprints(sprint) {
    sprint.selected = false;
    this.sprintsSelected.splice(this.sprintsSelected.indexOf(sprint.id), 1);
  }

  addSprintsToggle() {
    this.sprintListLoader = true;
    this.addSprintVisible = true;
    this.addStoryVisible = false;
    this.loadSprints();
  }

  searchSprints() {
    this.sprintListLoader = true;
    this.sprints = [];
    this.clearSprintCallSubscription();
    this.loadSprints();
  }

  loadSprints() {
    const pageno = this.sprintsMeta && this.sprintsMeta.next_page ? this.sprintsMeta.next_page : 1;
    this.listSprintSubscriptions = this.releasePlannerService.getSprintsForRelease(this.project, this.searchSprintData, pageno).subscribe(res => {
      if(res.data && res.data.length === 0) {
        this.sprints = [];
      }
      if(res) {
        this.sprintsMeta = res.meta;
        if(this.sprintsMeta.current_page === 1) {this.sprints = [];}
        res.data.forEach(sprint => {
          sprint['selected'] = this.storiesSelected.indexOf(sprint.id) > -1 ? true : false;
          this.sprints.push(new Sprint(sprint));
        });
      }
      this.sprintListLoader = false;
    }, (error) => {
      this.errorService.errorFunction(error);
      this.sprintListLoader = false;
    });
  }

  getStateClasses(state) {
    return ['searched-storyStatus ' + state]
  }

  attachFilesOnly(event) {
    Array.from(event.target.files).forEach(file => {
      this.attachOnlyArr.push(file);
      this.preview(file);
    });
  }

  preview(file) {
    if (this.sharedService.checkType(file.name, true) === 'image') {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (_event) => {
        this.attachmentPreviewUrls.push(reader.result);
      };
    } else {
      this.attachmentPreviewUrls.push('/assets/images/defaullt-download.png');
    }
  }

  removeStoryAttachment(file, alreadyUploaded = false) {
    if (alreadyUploaded) {
      this.deleted_attachment.push(file);
      const index = this.editAttachment.findIndex(item => item === file);
      if (index !== -1) {
        this.editAttachment.splice(index, 1);
      }
    } else {
      const index = this.attachOnlyArr.findIndex(item => item === file);
      if (index !== -1) {
        this.attachOnlyArr.splice(index, 1);
        this.attachmentPreviewUrls.splice(index, 1);
      }
    }
  }

  @HostListener('document:click', ['$event'])
  onClick(event) {
    const element = event.target;
    if (this.allowedClassNames.some(className => element.classList.contains(className))) {
      return;
    } else {
      this.showDropDown = false;
      this.dropDownType = '';
    }
  }

  showHideReleaseDetail() {
    this.release_detail_for = this.selected_release && this.selected_release.id ? 'release_edit' : 'create';
    this.openReleaseDetailPopUp = !this.openReleaseDetailPopUp;
  }

}
