import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { API_ROUTES } from '../apiRoutes';
import { DataService } from './data.service';
import { HttpRequests } from './http-requests.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Epic } from '../models/epic';
import { Project } from '../models/project';
import { Story } from '../models/story';

@Injectable()
export class EpicService {
  epicEstimateStatus: boolean = false;

  constructor(
    private httpRequest: HttpRequests,
    private dataService: DataService,
    public http: HttpClient
  ) {}

  projectEpics(project: Project) {
    const API = this.dataService.apiUrl + API_ROUTES.EPICS(project.id);
    return this.httpRequest
      .get(API, this.dataService.getRequestOptionArgs())
      .pipe(
        map((res) => this.parseResponse(res)),
        map((res) => res.map((data) => new Epic(data.epic)))
      );
  }

  epic(epic: Epic) {
    if (epic && epic.project) {
      const API =
        this.dataService.apiUrl + API_ROUTES.EPIC(epic.project.id, epic.id);
      return this.httpRequest
        .get(API, this.dataService.getRequestOptionArgs())
        .pipe(
          map((res) => this.parseResponse(res)),
          map((res) => new Epic(res.epic))
        );
    }
  }

  createEpic(projectId, epicData) {
    const API = this.dataService.apiUrl + API_ROUTES.EPICS(projectId);

    return this.httpRequest
      .post(API, epicData, this.dataService.getRequestOptionArgs())
      .pipe(map((res) => this.parseResponse(res)));
  }

  updateEpic(projectId, epicId, epicData) {
    const API = this.dataService.apiUrl + API_ROUTES.EPIC(projectId, epicId);

    return this.httpRequest
      .patch(API, epicData, this.dataService.getRequestOptionArgs())
      .pipe(map((res) => this.parseResponse(res)));
  }

  deleteEpic(projectId, epicId) {
    const API = this.dataService.apiUrl + API_ROUTES.EPIC(projectId, epicId);
    return this.httpRequest
      .delete(API, this.dataService.getRequestOptionArgs())
      .pipe(map((res) => this.parseResponse(res)));
  }

  addStory(epic: Epic, story: Story, param?) {
    const API =
      this.dataService.apiUrl +
      API_ROUTES.EPIC_ADD_STORY(story.project.id, epic.id, story.id);
    return this.httpRequest
      .post(API, param, this.dataService.getRequestOptionArgs())
      .pipe(map((res) => this.parseResponse(res)));
  }

  removeStory(epic: Epic, story: Story) {
    const API =
      this.dataService.apiUrl +
      API_ROUTES.EPIC_REMOVE_STORY(epic.project.id, epic.id, story.id);
    return this.httpRequest
      .post(API, {}, this.dataService.getRequestOptionArgs())
      .pipe(map((res) => this.parseResponse(res)));
  }

  allFeatureDetails(project: Project) {
    const httpOptions = { headers: new HttpHeaders() };
    httpOptions.headers = httpOptions.headers.set(
      'secret-key',
      environment.secretKey
    );
    const API =
      environment.apiPMBase +
      API_ROUTES.ALL_FEATURE_DETAILS(project.dashboard_project_id);
    return this.http
      .get(API, httpOptions)
      .pipe(map((res) => this.parseResponse(res)));
  }

  storiesCreatedForFeature(projectId, epicId, nextPage) {
    const API =
      this.dataService.apiUrl +
      API_ROUTES.STORIES_CREATED_IN_FEATURE(projectId, epicId) +
      '?page=' +
      encodeURIComponent(nextPage);
    return this.httpRequest
      .get(API, this.dataService.getRequestOptionArgs())
      .pipe(map((res) => this.parseResponse(res)));
  }

  public parseResponse(res: any): any {
    return res;
  }
}
