<div class="commonDashboard-wrapper" *ngIf="account">

    <div class="row">
        <h2 class="account-title">My Account</h2>
    </div>
    <div class="genralBlock">
      <div class="genralBlock-title">General Information</div>
      <div class="small-block">
        <div class="account-label">Full Name</div>
        <div class="account-field">{{ account.name }}</div>
      </div>
      <div class="small-block">
        <div class="account-label">Initials</div>
        <div class="account-field">{{ account.initials }}</div>
      </div>
      <div class="small-block">
        <div class="account-label">Email</div>
        <div class="account-field">{{ account.email }}</div>
      </div>
      <!-- <div class="small-block">
        <div class="account-label">Username</div>
        <div class="account-field">{{ account.username }}</div>
      </div> -->
      <div class="small-block">
        <div class="account-label">Time Zone</div>
        <div class="account-field">{{account?.display_time_zone}}</div>
      </div>
      <div class="small-block">
        <div class="account-label">Password  <span class="forChangePassword" (click)="openChangePasswordPopup()">Change password</span></div>
        <div class="account-field">************</div>
      </div>
    </div>

    <div class="genralBlock2">
      <div class="genralBlock-title">Notifications</div>
      <div class="genralBlock-subtitle">Notify when my stories are</div>
      <div class="statusBlock">
        <span class="statusIcon" [ngClass]="toIcon(account.notify_on_delivered)"></span>
        <span class="statusText">Delivered</span>
      </div>
      <div class="statusBlock">
        <span class="statusIcon" [ngClass]="toIcon(account.notify_on_accepted)"></span>
        <span class="statusText">Accepted</span>
      </div>
      <div class="statusBlock">
        <span class="statusIcon" [ngClass]="toIcon(account.notify_on_rejected)"></span>
        <span class="statusText">Rejected</span>
      </div>
    </div>
    <!--<div class="row">-->
      <!--<div class="col-sm-3 account-label">Notify me by</div>-->
      <!--<div class="col-sm-9 account-field">-->
        <!--<ng-container *ngFor="let provider of providers">-->
            <!--<app-account-integration [provider]="provider"-->
                                     <!--[accountId]="account.id"-->
                                     <!--[state]="account.slack_notifications">-->
            <!--</app-account-integration>-->
          <!--</ng-container>-->
      <!--</div>-->
    <!--</div>-->

    <div class="genralBlock">
      <div class="genralBlock-title">Access Token</div>
      <div class="large-block">
        <div class="account-field2">
          <span class="tokenField">{{ account.access_token }}</span>
          <span class="tokenField-action popover-activate"  placement="bottom" [clip]="account.access_token">
            <em class="iconb-task"></em>
            <span class="tokenField-actionText">Copy</span>
          </span>
        </div>
      </div>
    </div>
    <div class="actionBt-wrapper">
      <a class="actionButton" routerLink="edit">Edit</a>
    </div>

</div>

<!--  Change Password Popup Starts  -->
<div class="changePasswordPopUp" *ngIf="openChangePassword">
  <div class="changePasswordPopUp-backdrop" (click)="closeChangePasswordPopup()"></div>
  <div class="changePasswordPopUp-wrapper">
    <!--<div class="st-Loader inPopup" *ngIf="waitForLoader">-->
      <!--<div class="in-Loader"><img src="../../assets/images/builder_black.png"/></div>-->
      <!--<div class="loadingOverlay"></div>-->
    <!--</div>-->
    <div class="commonPopUp-title">Change Password</div>
    <form class="cp-form" #f="ngForm"  (ngSubmit)="onSubmit()" >
      <div class="changePass-inputblock" [ngClass]="{'show-password': !showLoginPassword}" [class.has-error]="hasError('password')">
        <div class="changePass-label">New Password</div>
        <input type="{{showLoginPassword ? 'text':'password'}}" (focus) = "removeErrors()" #password="ngModel" required  minlength="8" (keyup)="onPasswordChange($event)" [(ngModel)]="form.password" name="password" placeholder="Password" class="cp-field"/>
        <span class="forEyeOption" (click) = "showPassword($event)"></span>
        <div *ngIf="password && password.invalid && (password.touched || password.dirty)" class="chPassword-error">
          <div *ngIf="password.errors.required" class="new-error-meg">Password is required</div>
          <div *ngIf="password.errors.minlength" class="new-error-meg">Password should have atleast 8 characters</div>
        </div>
        <div *ngIf = "errors?.password_confirmation" class="chPassword-error">
            {{ errors?.password }}
        </div>
      </div>
      <div class="changePass-inputblock" [class.has-error]="hasError('password_confirmation')">
        <div class="changePass-label">Repeat Password</div>
        <input type="password" (focus) = "removeErrors()"  #password_confirmation="ngModel" required minlength="8" (keyup)="onPasswordChange($event)" [(ngModel)]="form.password_confirmation" name="password_confirmation" placeholder="Repeat password" class="cp-field"/>
      <div *ngIf="password_confirmation && password_confirmation.invalid && (password_confirmation.touched || password_confirmation.dirty)" class="chPassword-error">
        <div *ngIf="password_confirmation.errors.required" class="new-error-meg">Password confirmation is required</div>
        <div *ngIf="password_confirmation.errors.minlength" class="new-error-meg">Password Confirmation should have atleast 8 characters</div>
      </div>
      <div *ngIf = "errors?.password_confirmation" class="chPassword-error">
          {{ errors?.password_confirmation }}
      </div>

      </div>
      <div class="changePass-actionBtn">
        <span class="changePass-cancel" (click)="closeChangePasswordPopup()">Cancel</span>
        <button type = "submit" class="changePass-submit" [disabled] = "!f.valid">Update</button>
      </div>
    </form>
  </div>
</div>
<!--  Change Password Popup Ends  -->
