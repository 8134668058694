import { Component, OnInit, ElementRef, Renderer2,ViewChild,HostListener } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { ProjectService } from '../shared/services/project.service';
import { Project } from '../shared/models/project';
import { NotificationService } from '../shared/services/notification.service';
import {Story} from '../shared/models/story';
import {PolicyService} from "../shared/services/policy.service";
import {CustomToastService} from '../shared/services/custom-toast.service';
import moment from 'moment';
import {
  ActivatedRoute,
  Params
} from '@angular/router';
import { ErrorService } from '../shared/errorFunction';
import {
  PROJECT_ROLES,
  EVENT_TYPES,
  APPROVE_HOUR_REASONS, MEMBER_DASHBOARD_FILTER_DATA,STORY_TYPE,TEST_CASE_STATUS
} from '../shared/dataTypes';
import { StoryService } from '../shared/services/story.service';
import { Subscription } from 'rxjs';
import { ProjectSyncService } from '../shared/services/projectSync.service';
import { RelatedStory } from '../shared/models/related_story';
import { UserService } from '../shared/services/user.service';
import { EpicService } from '../shared/services/epic.service';
import {Epic} from "../shared/models/epic";
import { CommonStoryService } from '../shared/services/common-story.service';

@Component({
  selector: 'app-test-suite',
  templateUrl: './test-suite.component.html',
  styleUrls: ['./test-suite.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({
          opacity: '0',
          transform: 'translateY(-20px)'
        }),
        animate(100, style({
          opacity: '1',
          transform: 'translateY(0)'
        }))
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate(100, style({
          opacity: '0',
          transform: 'translateY(-20px)'
        }))
      ])
    ]),
    trigger('slideOut', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({
          opacity:'0',
          top: '0',
          right: '-190px'
        }),
        animate(100, style({
          opacity:'1',
          top: '0',
          right: '0'
        }))
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate(100, style({
          opacity:'0',
          top: '0',
          right: '-190px'
        }))
      ])
    ]),
  ],
  host: {
    '(document:click)': 'onClick($event)'
  }
})
export class TestSuiteComponent implements OnInit {
  project: Project;
  story: Story;
  testCases = [];
  selectedStory: any;
  projectId?: number;
  initial_loader:boolean = false;
  testCaseTitle = '';
  selectedTestCase :any;
  projectName:string = '';
  testSuiteStories = [];
  openCreateTestCasePopup:boolean;
  searchSubscription: Subscription = undefined;
  @ViewChild('expand') expand: ElementRef;
  openCreateBugPopup: boolean = false;
  searchResult = { stories: [], testCase: [] };
  testCaseStatus: Array<any> = TEST_CASE_STATUS;
  oldSearchStr: String = '';
  totalCountSearch = 0;
  searchStr: string = '';
  OpencommonPopUp: boolean = false;
  testSuiteLoader:boolean = false;
  waitForLoader: boolean = false;
  showCopiedID: boolean = false;
  showTestCopiedID: boolean = false;
  searchFeatures = [];
  openSearchBlock: boolean = false;
  resultStoriesStatus: boolean = true;
  resultLabelsStatus: boolean = false;
  moreSearchStoryAvailale = true;
  searchGoingLoader: boolean = false;
  isSearchById: boolean = false;
  startSearchLoader = false;
  searchStoriesParam = 1;
  showfilterList: boolean = false;
  isEmptyStoriesVisible = true;
  isTestCasesVisible = false;
  bug_reason_id: number;
  showDetailReasonList:boolean = false;
  showReasonList:boolean = false;
  bug_reason_detail_id: number;
  testCaseBugTitle = '';
  isBugListMenuOpen:boolean = false;
  enterRelatedStory:boolean = false;
  searchRelatedStory = '';
  relateStoryList: Array<RelatedStory> = [];
  loaderForRelateStoryList:boolean = false;
  bug_related_to_story: {id?: number, title?: string};
  relateStorySub: Subscription;
  failedTestCasePopup:boolean = false;
  testCaseRejected:boolean = false;
  statusChangeLoader:boolean = false;
  storyListMeta: {
    current_page: number;
    next_page: number;
    per_page: number;
    total_count: number;
  };
  testCaseListMeta: {
    current_page: number;
    next_page: number;
    per_page: number;
    total_count: number;
  };
  isPending: boolean = false;
  loggedInUser: any;
  showSort:boolean = false;
  sort_by = 'created_at';
  private notificationSubscriptions: Subscription[] = [];

  constructor(
    public projectSyncService : ProjectSyncService,
    public customToast: CustomToastService,
    public projectService: ProjectService,
    public notificationService: NotificationService,
    public activatedRoute: ActivatedRoute,
    public errorService: ErrorService,
    public storyService: StoryService,
    public policyService: PolicyService,
    public renderer2: Renderer2,
    public userService: UserService,
    public epicService: EpicService,
    private commonStoryService: CommonStoryService
  ) {}

  ngOnInit() {
    this.loggedInUser = this.userService.getUser();
    this.isPending = false;
    this.projectService.initiate_from = 'from_test_suite';
    this.testSuiteLoader = true;
    this.isEmptyStoriesVisible = true;
    this.activatedRoute.params.subscribe((params: Params) => {
      this.clearData();
      this.projectId = +params['id'];
      localStorage.setItem('projectId', JSON.stringify(this.projectId));
      this.loadData();
    }); 
    this.projectSyncService.subscribe(this);
    
    const testSuiteFilter = this.notificationService.subscribeEvent(EVENT_TYPES.TEST_SUITE.TEST_SUITE_STORY__FILTER, res => {
      if (res && res.payload.data.filter_at === 'test_suit') {
        this.projectService.getTestSuiteStories(this.project).subscribe(res=>{
          if (res.data.length > 0) {
            this.isEmptyStoriesVisible = false;
          }
          this.testSuiteStories = res.data;
          this.testSuiteLoader = false;
          this.projectService.startApplyFilterLoader = false;
        });
      }
    });
    this.notificationSubscriptions.push(testSuiteFilter);

    const testCaseCreated = this.notificationService.subscribeEvent(EVENT_TYPES.TESTCASE.CREATE_NEW, res => {
      if (this.selectedStory && res.payload.parent_id === this.selectedStory.id) {
        this.testCases.push(res.payload.test_case);
      }
    });
    this.notificationSubscriptions.push(testCaseCreated);

    const testCaseEpicUpdated = this.notificationService.subscribeEvent(EVENT_TYPES.TESTCASE.EPIC_UPDATED, res => {
      const event = res.payload
      // update the Epic in test suite story
      const story = this.testSuiteStories.find(st => st.id === event.story_id);
      const epic = this.project.epics.find(epicdata => epicdata.id === event.id);
      if (story && epic && event.action === 'addstory') {
        story.feature_title = epic.title;
        story.feature_id = epic.id;
        this.testCases.forEach(es => {
          if(es.parent_id === story.id) {
            es.feature_tags = epic.title;
          }
        })
      }
    });
    this.notificationSubscriptions.push(testCaseEpicUpdated);

    const testCaseUpdated = this.notificationService.subscribeEvent(EVENT_TYPES.TESTCASE.DATA_UPDATED, res => {
      const event = res.payload;

      // Update the test suites from web sockets
      if (this.selectedStory && res.payload.parent_id === this.selectedStory.id) {
        this.commonStoryService.updateStories(this.testCases, res.payload);
      }
      
      // Update the parent stories for test suites count
      const story = this.testSuiteStories.find(st => st.id === event.story_id)
      if (story && event.changes && 'test_cases_count' in event.changes) {
        story.test_cases_count = event.changes.test_cases_count;
      }
      if (story && event.changes && 'title' in event.changes) {
        story.title = event.changes.title;
      }
    });
    this.notificationSubscriptions.push(testCaseUpdated);

    const testCaseBugCreated = this.notificationService.subscribeEvent(EVENT_TYPES.TESTCASE.CREATE_NEW_BUG, res => {
      const index = this.testCases.findIndex(test => test.id === res.payload.test_case_id)
      this.testCases[index].bug_ids.push(res.payload.story_id);
    });
    this.notificationSubscriptions.push(testCaseBugCreated);

    const testSuitFilterApplied = this.notificationService.subscribeEvent(EVENT_TYPES.STORY.UPDATED, res => {
      this.projectService.getTestSuiteStories(this.project).subscribe(res=>{
        if (res.data.length > 0) {
          this.isEmptyStoriesVisible = false;
        }
        this.testSuiteStories = res.data;
        this.testSuiteLoader = false;
        this.projectService.startApplyFilterLoader = false;
      });
    });
    this.notificationSubscriptions.push(testSuitFilterApplied);
  }

  onClick(event) {
    const element = event.target;
    if (!(element.classList.contains('actions-col'))) {
      this.testCases.forEach(test => {
        test.openActionDD = false;
      });
    }
    if (!(element.classList.contains('status-col'))) {
      this.testCases.forEach(test => {
        test.openStatusDD = false;
      });
    }
    if (!(element.classList.contains('bug-id-col'))) {
      this.isBugListMenuOpen = false;
    }
    if (!(element.classList.contains('bug-id-col'))) {
      this.testCases.forEach(test => {
        test.openBugListMenu = false;
      });
    }
    if (!(element.classList.contains('addRelated-input') || element.classList.contains('relatedto-storyList'))) {
      this.enterRelatedStory = false;
      this.searchRelatedStory = '';
      this.relateStoryList = [];
    }
    if (!(element.classList.contains('sprint-sorting-options') || element.classList.contains('selectedFilter') || element.classList.contains('iconb-sorting'))) {
      this.showSort = false;
    }
   }

  ngOnDestroy(){
    this.projectSyncService.unsubscribe();
    this.notificationSubscriptions.forEach(subscription => subscription.unsubscribe());
    this.projectService.initiate_from = '';
    if(this.projectService.show_storyDetails) {
      this.projectService.hide_StoryDetails_if_it_is_open();
    }
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.projectService.hide_StoryDetails();
  }

  doSearch() {
    this.openSearchBlock = true;
  }

  loadData(){
    this.projectService.project(this.projectId, true).subscribe(project => {
      this.project = project;
      this.projectName = this.project.name;
      const queryIndex = window.location.href.indexOf('?');
      const queryUrl = window.location.href.substr(queryIndex + 1);
      const registerQueryParam = queryUrl.substr(0, queryUrl.indexOf('='));
      const registerQueryParamValue = queryUrl.substr(queryUrl.indexOf('=') + 1);
      if (registerQueryParam === 'storyId') {
        this.projectService.show_StoryDetails(this.project, '', registerQueryParamValue, false);
      }
      this.notificationService.broadcast(EVENT_TYPES.PROJECT.SHOW, {
        data: {
          project: this.project
        }
      });
      this.projectService.loadUsers(this.project);
      this.project.phases = [];
      this.project.tracker_phases = [];
      if (this.project && !this.project.dashboard_project_id) {
        this.project.phases = ['Product Roadmap', 'Design', 'Prototype', 'MVP', 'Full Build'];
        this.project.phases.forEach(item => {
          const tracker_phase = {
            'tracker_phase_name': item,
            'phase_name': item
          };
          this.project.tracker_phases.push(tracker_phase);
        });
      } else {
        this.projectService.projectPhasesNBrief(this.project).subscribe(res => {
          this.project.internal_mattermost_channel_url = res.data.internal_mattermost_channel_url;
  
          for (let i = 0; i < res.data.trackerphasesettings.length; i++) {
            this.project.phases.push(res.data.trackerphasesettings[i].phase_name);
  
            for (let j = 0; j < res.data.trackerphasesettings[i].tracker_phases.length; j++) {
              const tracker_phase = {
                'tracker_phase_name': res.data.trackerphasesettings[i].tracker_phases[j],
                'phase_name': res.data.trackerphasesettings[i].phase_name
              };
              this.project.tracker_phases.push(tracker_phase);
            }
          }
        }, (error) => {
          this.project.internal_mattermost_channel_url = null;
        });
      }

      this.loadEpics();
      this.projectService.project_labels(this.project).subscribe(data => {
        this.project.labels = data;
      }, (error) => {
        this.errorService.errorFunction(error);
      });
  
      this.projectService.getTestSuiteStories(this.project).subscribe(res=>{
        if (res.data.length > 0) {
          this.isEmptyStoriesVisible = false;
        }
        this.testSuiteStories = res.data;
        this.storyListMeta = res.meta;
        if (registerQueryParam && !this.selectedStory) {
          const story = this.testSuiteStories.find(st => st.id === parseInt(registerQueryParamValue));
          if (story) {
            this.selectedStory = story;
            this.showTestCases(this.selectedStory);
          }
        }
        this.testSuiteLoader = false;
      });
    }, error => {
      this.testSuiteLoader = false;
      this.errorService.errorFunction(error);
    });
    // this.notificationService.subscribeEvent(EVENT_TYPES.STORY.ACTION_EXPAND, res => {
    //   this.expandStoryDetailSize();
    // });
  }

  showTestCases(story) {
    this.initial_loader = true;
    this.testCases = [];
    this.selectedStory = story;
    this.projectService.getTestSuites(this.project,story,this.sort_by).subscribe(res=>{
      if (res.data.length > 0) {
        this.testCases = res.data;
        this.testCaseListMeta = res.meta;
        this.isTestCasesVisible = true;
      }
      this.initial_loader = false;
    }, error => {
      this.initial_loader = false;
      this.errorService.errorFunction(error);
    });
  }

  copyStoryID($event,type) {
    $event.stopPropagation();
    if(type === 'story'){
      this.showCopiedID = true;
    }else  this.showTestCopiedID = true;
    setTimeout(() => {
      this.showTestCopiedID = false;
      this.showCopiedID = false;
    }, 6000);
  }


  showStoryDetail(storyId,story?){
    this.closeSearch();
    if (story && ( !this.selectedStory || story.id !== this.selectedStory.id)) {
      this.selectedStory = story;
      this.showTestCases(this.selectedStory);
    }
    this.projectService.show_StoryDetails(this.project, '', storyId,false,true);    
  }

  clearData() {
    this.project = undefined;
    this.projectName = '';
    this.openCreateBugPopup = false;
    this.openCreateTestCasePopup = false;
    this.bug_reason_id = null;
    this.bug_reason_detail_id = null;
    this.searchRelatedStory = '';
    this.relateStoryList = [];
}

  toggleCreateTestCasePopup(story?){
    if(!this.project.isUserActive(this.loggedInUser)) {
      this.customToast.messages.push({
        id: 'read_only_warning',
        type: 'warning',
        class: 'generic_alert',
        title: 'Alert',
        message: 'Not authorized to perform this action.'
      });
      return;
    }
    if(this.projectService.show_storyDetails) {
      this.projectService.hide_StoryDetails_if_it_is_open();
    }
    if (story  && ( !this.selectedStory || story.id !== this.selectedStory.id)) {
      this.selectedStory = story;
      this.showTestCases(this.selectedStory);
    }
    this.testCaseTitle = '';
    this.openCreateTestCasePopup = !this.openCreateTestCasePopup;
  }

  createTestCase(){
    this.waitForLoader = true;
    const testCaseData = {
    "parent_id": this.selectedStory.id,
    "title": this.testCaseTitle,
    "story_type": "test_case"};
    this.storyService.createTestCase(testCaseData,this.project).subscribe(res=>{
      this.testCaseTitle = '';
      this.openCreateTestCasePopup = false;
      this.waitForLoader = false;
    }, error => {
      this.waitForLoader = false;
      this.errorService.errorFunction(error);
    });
  }

  showCreateBugPopup(test){
    this.selectedTestCase = test;
    this.openCreateBugPopup = true;
    this.testCaseBugTitle = '';
    this.searchRelatedStory = '';
    this.relateStoryList = [];
    if(test.parent_id) {
      this.bug_related_to_story = {
        id : test.parent_id,
        title : this.selectedStory.title
      }
    }
  }

  closeCreateBugPopup(){
    this.openCreateBugPopup = false;
    this.showReasonList = false;
    this.showDetailReasonList = false;
    this.bug_reason_id = null;
    this.bug_reason_detail_id = null;
    this.bug_related_to_story = {};
    this.testCaseBugTitle = '';
    this.searchRelatedStory = '';
    this.relateStoryList = [];
    this.testCaseRejected = false;
  }

  closeFailedTestCasePopup(){
    this.failedTestCasePopup = false;
  }

  createBug(){
    this.failedTestCasePopup = false;
    this.showCreateBugPopup(this.selectedTestCase);
    this.testCaseRejected = true;
  }

  createTestCaseBug(){
    this.waitForLoader = true;
    this.openCreateBugPopup = false;
    const testCaseData = {
    "test_case_id": this.selectedTestCase.id,
    "title": this.testCaseBugTitle,
    "story_type": "bug",
    "bug_reason_id": this.bug_reason_id,
    "bug_reason_detail_id": this.bug_reason_detail_id,
    "related_to_story_id" : this.bug_related_to_story.id,
    "parent_id": this.selectedTestCase.parent_id
  };
    this.storyService.createTestCase(testCaseData,this.project).subscribe(res=>{
      if (this.testCaseRejected) {
        let testCaseStatusData = {}
        testCaseStatusData['state'] = 'rejected';
        this.storyService.updateTestCase(testCaseStatusData,this.selectedTestCase.id,this.project).subscribe(res=>{
          this.testCases.forEach(test => {
            test.openStatusDD = false;
          });
          this.testCaseRejected = false;
        }, (error) => {
          this.testCaseRejected = false;
          this.errorService.errorFunction(error);
        });  
      }
        this.testCaseBugTitle = '';
        this.closeCreateBugPopup();
        this.waitForLoader = false;
    }, error => {
      this.waitForLoader = false;
      this.errorService.errorFunction(error);
    });
  }

  openReasonsLists() {
    this.showDetailReasonList = false;
    this.showReasonList = !this.showReasonList;
  } 

  bugReason() {
    if (this.bug_reason_id) {
      return this.project.bug_reasons.filter(b => b.id === this.bug_reason_id)[0].title;
    } else {
      return 'Select a Reason';
    }
  }


  bugDetailReason() {
    if (this.bug_reason_detail_id) {
      return this.project.bug_reasons.filter(b => b.id === this.bug_reason_detail_id)[0].title;
    } else {
      return 'Select Reason Detail';
    }
  }

  selectBugReason(bugReason, type?) {
    if (type === 'detailedReason') {
      this.bug_reason_detail_id = Number(bugReason.id);
      this.showDetailReasonList = false;
    } else {
      this.bug_reason_id = Number(bugReason.id);
      this.bug_reason_detail_id = null;
      this.showReasonList = false;
    }
  }

  openDetailReasonsLists() {
    this.showReasonList = false;
    this.showDetailReasonList = !this.showDetailReasonList;
  }

  getrelatedStories() {
    if (this.searchRelatedStory.length >= 3) {
      this.loadStoriesToRealtebug();
    } else {
      this.relateStoryList = [];
    }
  }

  loadStoriesToRealtebug() {
    this.loaderForRelateStoryList = true;
    if (this.relateStorySub) {
      this.relateStorySub.unsubscribe();
    }
    this.relateStoryList = [];
    this.relateStorySub = this.storyService.relateStories(this.project.id, this.selectedTestCase.id, this.searchRelatedStory).subscribe(res => {
      res.forEach(item => {
        this.relateStoryList.push(item);
      });
      this.loaderForRelateStoryList = false;
    }, err => {
      this.relateStoryList = [];
      this.loaderForRelateStoryList = false;
      this.errorService.errorFunction(err);
    });
  }

  selectRelatedStory(relateStory) {
    this.enterRelatedStory = false;
    this.searchRelatedStory = '';
    this.relateStoryList = [];
    this.bug_related_to_story = { id: relateStory.id, title: relateStory.title };
  }

  get isProjectReadonly(): boolean {
    return ((this.policyService.isProjectReadonly(this.project)) );
  }
  
  closecommonPopUp() {
    this.waitForLoader = false;
    this.OpencommonPopUp = false;
  }

  archiveTestCase(test) {
    this.selectedTestCase = test;
    if(this.isProjectReadonly || (test && test.deleted_at)) {
      this.policyService.readOnlyWarning();
    } else{
      this.OpencommonPopUp = true;
    }
  }

 archive() {
  this.selectedTestCase.deleted_at = moment();
  this.waitForLoader = true;
  this.storyService.archiveTestCase(this.project.id,this.selectedTestCase.id,this.selectedTestCase, true).subscribe((st: Story) => {
      this.closecommonPopUp();
      this.customToast.messages.push({
        id: 'story_deleted',
        type: 'success',
        class: 'story_delete',
        title: 'Test Case Deleted',
        message: 'You have deleted test case successfully'
      });
  }, error => {
    this.errorService.errorFunction(error);
  });
  }

  closeSearch() {
    this.openSearchBlock = false;
  }

  clearSearchStoriesData() {
    if (this.searchSubscription) {
      this.searchSubscription = undefined;
    }
    this.searchResult = { stories: [], testCase: [] };
    this.searchFeatures = [];
    this.oldSearchStr = '';
    this.totalCountSearch = 0;
  }

  searchInEpic() {
    const searchFeature:string = this.searchStr.toLowerCase();
    this.searchFeatures = this.project.epics.filter((element) => {
      return element.title.toLowerCase().includes(searchFeature)
    });
  }

  searchStories(scroll?) {
    if (this.searchStr.trim().length === 0) {
      return false;
    }
    this.moreSearchStoryAvailale = this.resultStoriesStatus;
    if(!scroll) {
      this.moreSearchStoryAvailale = true;
      this.searchStoriesParam = 1;
    } else if(scroll && this.resultStoriesStatus) {
      this.searchStoriesParam++;
      if(this.searchStoriesParam > 5) {
        this.searchStoriesParam = 5;
        this.moreSearchStoryAvailale = false;
        return;
      }
      this.startSearchLoader = true;
    }

      this.searchGoingLoader = true;
      if(!scroll) {
        this.clearSearchStoriesData();
      }

      this.isSearchById = !isNaN(parseInt(this.searchStr.trim(), 10));
      this.searchSubscription = this.storyService.searchstories(this.project, this.searchStr.trim(), this.searchStoriesParam,true).subscribe(res => {
        this.oldSearchStr = this.searchStr;
        if(res.stories.length == 0) {
          this.moreSearchStoryAvailale = false;
        }
        this.startSearchLoader = false;
        this.searchResult.stories.push(...res.stories);
        res.test_cases.forEach(item => this.searchResult.testCase.push(item));
        this.searchGoingLoader = false;
        this.totalCountSearch = this.searchResult.testCase.length;
      }, err => {
        this.errorService.errorFunction(err);
        this.searchGoingLoader = false;
        this.startSearchLoader = false;
      });
  }

  updateTestCase(status,test){
    if (test.state === status) {
      return;
    }
    this.selectedTestCase = test;
    if (status === 'rejected' && test.bug_ids.length === 0) {
      this.failedTestCasePopup = true;
      return;
    }
    this.statusChangeLoader = true;
    let testCaseData = {};
    testCaseData['state'] = status;
    this.storyService.updateTestCase(testCaseData,test.id,this.project).subscribe(res=>{
      this.statusChangeLoader = false;
      this.testCases.forEach(test => {
        test.openStatusDD = false;
      });
    }, (error) => {
      this.statusChangeLoader = false;
      this.errorService.errorFunction(error);
    });
  }

  showRelatedStories() {
    this.resultStoriesStatus = true;
    this.resultLabelsStatus = false;
  }

  showRelatedLabels() {
    this.resultStoriesStatus = false;
    this.resultLabelsStatus = true;
  }

  storyTypeIcon(story_type , subtask) {
    switch (story_type) {
      case STORY_TYPE.task:
        return  subtask? 'iconb-subtask taskIcons' :'iconb-task taskIcons';
      case STORY_TYPE.chore:
        return 'iconb-chore choreIcons';
      case STORY_TYPE.bug:
        return 'iconb-bug bugIcons';
      case STORY_TYPE.release:
        return 'iconb-release releaseIcons';
      case STORY_TYPE.side_effect:
        return 'iconb-side-effect sideeffectIcons';
      default:
        return null;
    }
  }

  getStatus(test){
    if (test.state === 'started') {
      return 'in-progress';
    } else if(test.state === 'accepted'){
      return 'pass';
    }else if (test.state === 'rejected') {
      return 'fail';
    } else {
      return 'Todo';
    }
  }
  
  getStatusText(test) {
    const statusMessage = this.getStatus(test);
    const formattedStatusMessage = statusMessage.replace(/-/g, ' ');
    return formattedStatusMessage.charAt(0).toUpperCase() + formattedStatusMessage.slice(1);
  }


  actionMenuToggle(test) {
    this.testCases.forEach(test => {
      test.openActionDD = false;
    });
    test.openActionDD = !test.openActionDD;
  }

  checkUser(test) {
    return test.owned_by_id !== this.loggedInUser.id ? 'disabled' : '';
  }

  statusMenuToggle(test) {
    if (test.owned_by_id === this.loggedInUser.id) {
      this.testCases.forEach(test => {
        test.openStatusDD = false;
      });
      test.openStatusDD = !test.openStatusDD;
    }
  }

  bugListToggle(test){
    this.testCases.forEach(test => {
      test.openBugListMenu = false;
    });
    test.openBugListMenu = !test.openBugListMenu;
  }

  getBugIds(test){
    const bugIds = test.bug_ids;
    return bugIds.filter((id, index) => index != 0);
  }

  cardLoop(i: number) {
    return new Array(i);
  }

  toggelefilter() {
    this.showfilterList = !this.showfilterList;
    if (this.showfilterList) {
      this.userService.trackSegmentEvent('Filter Open', this.projectService.getCommonPropsForStoryBoardEvents(this.project));
    }
  }

  closeFilter() {
    this.showfilterList = false;
  }

  loadEpics() {
    this.epicService.projectEpics(this.project)
      .subscribe((epics: Array<Epic>) => {
        this.project.addEpics(epics);
        if (this.project && this.project.dashboard_project_id != null) {
          this.loadAllFeaturedetails();
        }
        const payload = {
          data: {
            epics: this.project.epics
          }
        };
        this.notificationService.broadcast(EVENT_TYPES.EPIC.UPDATED, payload);
      }, (error) => {
        this.errorService.errorFunction(error);
      });
  }

  loadAllFeaturedetails() {
    this.epicService.allFeatureDetails(this.project).subscribe(res => {
      if (res && res.data && res.data.length > 0) {
        res.data.forEach(item => {
          const ind = this.project.epics.findIndex(obj => obj.builder_feature_id === item.builder_feature_id);
          if (ind >= 0) {
            this.project.epics[ind].featureDetailsData = item;
          }
        });
      }
    });
  }

  getMoreParentStories() {
    if (this.storyListMeta && this.storyListMeta.next_page) {
      this.isPending = true;
      this.projectService.getTestSuiteStories(this.project,this.storyListMeta.next_page).subscribe(res=>{
        this.isPending = false;
        this.storyListMeta = res.meta;
        if (res ) {
          res.data.forEach((storyData) => {
            this.testSuiteStories.push(storyData);
          });
        }
      }, error => {
        this.errorService.errorFunction(error);
      });
    }
  }

  getMoreTestCases() {
    if (this.testCaseListMeta && this.testCaseListMeta.next_page) {
      this.isPending = true;
      this.projectService.getTestSuites(this.project,this.selectedStory,this.sort_by,this.testCaseListMeta.next_page).subscribe(res=>{
        this.isPending = false;
        this.testCaseListMeta = res.meta;
        if (res ) {
          res.data.forEach((storyData) => {
            this.testCases.push(storyData);
          });
        }
      }, error => {
        this.errorService.errorFunction(error);
      });
    }   
  }
  
  isStoryScrollFetchAvailable(): boolean {
    return !this.isPending && this.storyListMeta && this.storyListMeta.next_page != null;
  }
  
  istestCaseScrollFetchAvailable(): boolean {
    return !this.isPending && this.testCaseListMeta && this.testCaseListMeta.next_page != null;
  }

  openSort() {
    this.showSort = !this.showSort;
  }

  sortTestCaseList(value) {
    if (this.selectedStory) {
      this.sort_by = value;
      this.showTestCases(this.selectedStory); 
    }
  }

}
