<div class="membersDashboard-wrapper">
  <div class="common-Loader" *ngIf="userDetailLoader">
    <img src="../../assets/images/storyBoardLoader.gif" alt="loader"/>
  </div>
  <div class="topInfo-section">
    <div class="leftSide">
      <div class="aboutUser">
        <span class="userInitial">{{user?.initials | slice: 0:1}}</span>
        <div class="aboutUser-detail">
          <span class="aboutUser-name">{{user?.name}}</span>
          <span class="aboutUser-emailBox">
            <em class="iconb-email"></em>
            <span class="sideTitle">{{user?.email}}</span>
          </span>
          <span class="aboutUser-infoBox">
            <em class="iconb-clock"></em>
            <span class="sideTitle">Last time active: {{sharedService.formattTime(user.last_time_active)}}</span>
          </span>
          <span class="aboutUser-infoBox">
            <em class="iconb-edit"></em>
            <span class="sideTitle">Created on: {{user?.created_on}}</span>
          </span>
        </div>
      </div>
    </div>
    <div class="rightSide">
      <div class="totalStories">
        <div class="totalStories-count">Total Stories ({{user?.stories_count}})</div>
        <div class="totalStories-types">
          <div class="typeList-block">
            <span class="stateSelection-icon unscheduled"></span>
            <span class="makeCount">{{user.stories_status?.unscheduled ? user.stories_status.unscheduled : '0'}}</span>
            <span class="elementHover">Unscheduled</span>
          </div>
          <div class="typeList-block">
            <span class="stateSelection-icon unstarted"></span>
            <span class="makeCount">{{user.stories_status?.unstarted ? user.stories_status.unstarted : '0'}}</span>
            <span class="elementHover">Unstarted</span>
          </div>
          <div class="typeList-block">
            <span class="stateSelection-icon started"></span>
            <span class="makeCount">{{user.stories_status?.started ? user.stories_status.started : '0'}}</span>
            <span class="elementHover">Started</span>
          </div>
          <div class="typeList-block">
            <span class="stateSelection-icon finished"></span>
            <span class="makeCount">{{user.stories_status?.finished ? user.stories_status.finished : '0'}}</span>
            <span class="elementHover">Finished</span>
          </div>
          <div class="typeList-block">
            <span class="stateSelection-icon delivered"></span>
            <span class="makeCount">{{user.stories_status?.delivered ? user.stories_status.delivered : '0'}}</span>
            <span class="elementHover">Delivered</span>
          </div>
          <div class="typeList-block">
            <span class="stateSelection-icon accepted"></span>
            <span class="makeCount">{{user.stories_status?.accepted ? user.stories_status.accepted : '0'}}</span>
            <span class="elementHover">Accepted</span>
          </div>
          <div class="typeList-block">
            <span class="stateSelection-icon rejected"></span>
            <span class="makeCount">{{user.stories_status?.rejected ? user.stories_status.rejected : '0'}}</span>
            <span class="elementHover">Rejected</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="userProfile-top">
    <div class="userProfile-leftSide">
      <div class="maintitle">Buildcards ({{totalCount['total_count']}})</div>
    </div>
    <div class="userProfile-rightSide">
      <div class="searchProjectWrapper">
        <input type="text" (input)="loadUserProjects()" class="searchProjectWrapper-input" placeholder="Search Buildcards" [(ngModel)]= "searchProject"/>
        <button type="button" class="searchProjectWrapper-searchButton">
          <em class="iconb-search"></em>
        </button>
      </div>
    </div>
  </div>

  <div class="noSearchResult-project" *ngIf="this.userProjects.length == 0">
    <img src="../../../../assets/images/no-such-projects.png" alt="no member">
    <span class="notFoundText"> No Buildcard found </span>
  </div>

  <div class="projectMember-table" *ngIf="this.userProjects.length > 0">
    <div class="tableBlocks-top">
      <div class="tableBlocks-projecName">Buildcard Name</div>
      <div class="tableBlocks-stories">Stories</div>
      <div class="tableBlocks-estimatedTime">Total Estimated Time</div>
      <div class="tableBlocks-loggedTime">Total Logged  Time</div>
      <div class="tableBlocks-ApprovedTime">Total Approved Time</div>
    </div>
    <div class="tableBlocks-listingWrapper" *ngIf="this.userProjects.length > 0">
      <div class="tableBlocks-listing" *ngFor="let project of userProjects">
        <div class="tableBlocks-projecName">
          <span class="project-icon"><img src="../../assets/images/projectIcon-default.png" alt="loader"> </span>
          <span class="projectName">{{project.name}}</span>
        </div>
        <div class="tableBlocks-stories">{{ project.stories_count }} {{ project.stories_count >= 2 ? 'stories' : 'story' }}
          <div class="iconb-info-extra">
            <div class="storyStatusHover-wrapper">
              <div class="storyStatus-list">
                <span class="stateSelection-icon unscheduled"></span>
                <span class="makeCount-2">{{project.stories_status?.unscheduled ? project.stories_status.unscheduled : '0'}}</span>
              </div>
              <div class="storyStatus-list">
                <span class="stateSelection-icon unstarted"></span>
                <span class="makeCount-2">{{project.stories_status?.unstarted ? project.stories_status.unstarted : '0'}}</span>
              </div>
              <div class="storyStatus-list">
                <span class="stateSelection-icon started"></span>
                <span class="makeCount-2">{{project.stories_status?.started ? project.stories_status.started : '0'}}</span>
              </div>
              <div class="storyStatus-list">
                <span class="stateSelection-icon finished"></span>
                <span class="makeCount-2">{{project.stories_status?.finished ? project.stories_status.finished : '0'}}</span>
              </div>
              <div class="storyStatus-list">
                <span class="stateSelection-icon delivered"></span>
                <span class="makeCount-2">{{project.stories_status?.delivered ? project.stories_status.delivered : '0'}}</span>
              </div>
              <div class="storyStatus-list">
                <span class="stateSelection-icon accepted"></span>
                <span class="makeCount-2">{{project.stories_status?.accepted ? project.stories_status.accepted : '0'}}</span>
              </div>
              <div class="storyStatus-list">
                <span class="stateSelection-icon rejected"></span>
                <span class="makeCount-2">{{project.stories_status?.rejected ? project.stories_status.rejected : '0'}}</span>
              </div>
            </div>
        </div>
        </div>
        <div class="tableBlocks-estimatedTime">
          <em class="iconb-clock"></em> {{secsToDuration(project.total_estimated_time * 3600)}}
        </div>
        <div class="tableBlocks-loggedTime">
          <em class="iconb-clock"></em> {{project.total_tracked_time}}
        </div>
        <div class="tableBlocks-ApprovedTime">
          <em class="iconb-clock"></em> {{project.total_approved_time}}
        </div>
      </div>
    </div>
    <div class="load-more-stories" *ngIf="load_more_projects" (click)="loadMore()">View All Buildcards</div>
  </div>

  <div class="userProfile-top" *ngIf="user && user.most_recent_activities && user.most_recent_activities.length > 0">
    <div class="userProfile-leftSide">
      <div class="maintitle">Activity</div>
    </div>
  </div>
  <div class="useraActivityWrapper" *ngIf="user && user.most_recent_activities && user.most_recent_activities.length > 0">
    <div class="activityBlock">
      <div class="hs_story history_story">
        <div class="hs_storyListing" *ngFor="let activity of user.most_recent_activities">
          <div class="hs_story-header">
            <div class="hs_userInitials">{{user.initials.slice(0,1)}}</div>
            <div class="hs_storyIconWrapper" *ngIf="activity.details.length > 0">
              <em class="iconb-dd-down hs_story-expandIcon" [ngClass]="{'expanded':activity.toggleHistory}" (click)="toggleViewmode(activity)"></em>
            </div>
            <div class="hs_storyTitleWrapper">
              <div class="hs_requestor">
                <span class="hs_requesterInfo">{{ activity.user_name }}</span>
                <span class="hs_eventType">{{ activity.action_name }}
                  <span *ngIf="activity.commit_id"><a [href]="activity.commit_url" target="_blank" class="commitLink">{{activity.commit_id}}</a></span>
                  {{ activity.created_on ? sharedService.formattTimeWithSeconds(activity.created_on) : ''}}
                </span>
                {{ activity.action_type }} {{activity.details.length > 0 ? '' : ' on story '}} <span class="sp-em">{{activity.story_name}}</span>
                <span class="hs_attachments" *ngIf="activity.attachment_url">
                  <span class="uploading-listing">
                    <div class="uploading-listing-thumbnail not_maximized">
                      <img *ngIf="checkType(activity.attachment_url)" src="{{ activity.attachment_url }}" alt="loader"/>
                      <img *ngIf="!checkType(activity.attachment_url)" class="default-attachment" src="/assets/images/defaullt-download.png" alt="loader"/>
                    </div>
                    <div class="uploading-file-description">
                      <span class="docuName">{{ activity.action_type.substr(1, activity.action_type.length - 2) }}</span>
                    </div>
                  </span>
                </span>
              </div>
            </div>
          </div>
          <div class="hs_historyInfo" *ngIf="activity.toggleHistory &&  activity.details.length > 0">
            <div class="hs_eventTypeList" *ngFor="let detail of activity.details">
              <span class="hs_eventTypeName" *ngIf="detail.field_name == 'description to'"><strong>{{ detail.action }}</strong>{{ ' ' + detail.field_name + ' '}}<span [innerHtml]="bypassSanitization(detail.field_value)"></span></span>
              <span class="hs_eventTypeName" *ngIf="detail.field_name != 'description to'"><strong>{{ detail.action }}</strong>{{ ' ' + detail.field_name + ' "'}}<span [innerHtml]="bypassSanitization(detail.field_value)"></span>"</span>
              <span class="hs_attachments" *ngIf="detail.attachment_url">
                <span class="uploading-listing">
                  <div class="uploading-listing-thumbnail not_maximized">
                    <img *ngIf="checkType(detail.attachment_url)" src="{{ detail.attachment_url }}" alt="loader"/>
                    <img *ngIf="!checkType(detail.attachment_url)" class="default-attachment" src="/assets/images/defaullt-download.png" alt="loader"/>
                  </div>
                  <div class="uploading-file-description">
                    <span class="docuName">{{ detail.field_value }}</span>
                  </div>
                </span>
              </span>
            </div>
          </div>
          <div class="hs_date"><span>{{ activity.added_on }} - on Buildcard <strong style="font-weight: 500">{{activity.project_name}}</strong></span></div>
        </div>
      </div>
    </div>
  </div>
  <div class="userProfile-top">
    <div class="userProfile-leftSide">
      <div class="maintitle">Timesheet last week </div>
    </div>
  </div>
  <div class="useraActivityWrapper">
    <div class="timeSheetBlock">
      <div class="durationTitle">Week {{user.last_week_tracked_time.from}} - {{user.last_week_tracked_time.to}} </div>
      <div class="durationTable">
        <div class="durationtable-top">
          <div class="dayWrapper">
            <span class="duatrionDay" *ngFor="let trackTime of user.last_week_tracked_time?.tracked_times">{{trackTime.day}}</span>
          </div>
          <div class="dateWrapper">
            <span class="duatrionDay" *ngFor="let trackTime of user.last_week_tracked_time?.tracked_times">{{trackTime.date}}</span>
          </div>
        </div>
        <div class="durationtable-content">
          <span class="duatrionDay" *ngFor="let trackTime of user.last_week_tracked_time?.tracked_times">{{trackTime.time}} hrs</span>
        </div>
      </div>
    </div>
  </div>
</div>
