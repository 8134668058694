import { environment } from '../../environments/environment';

export const API_ROUTES = {
  ACCOUNT: 'account',
  PROJECTS: 'projects.json',
  PROJECT_STATS: (id: number) => `projects/${id}/stories_stats`,
  PROJECT: (id: number) => `projects/${id}`,
  REGISTER: 'register',
  CONFIRM_MEMBERSHIP: 'confirm_membership',
  PROJECT_ACTIVITIES: 'projects/activities',
  UIW_URL: (url: string, projectId: number) => `${url}/uiworkspace/${projectId}/flowchart`,
  STORIES: (projectId: number) => `projects/${projectId}/stories.json`,
  INTEGRATIONS_GET: (projectId: number) => `projects/${projectId}/integrations.json`,
  INTEGRATION_ADD: (projectId: number, kind: string) => `projects/${projectId}/${kind}_integrations.json`,
  INTEGRATION_EDIT: (projectId: number, integrationId: number, kind: string) =>
    `projects/${projectId}/${kind}_integrations/${integrationId}`,
  INTEGRATION_DELETE: (projectId: number, integrationId: number, kind: string) =>
    `projects/${projectId}/${kind}_integrations/${integrationId}`,
  ACTIVITIES_STORIES: (projectId: number) => `projects/${projectId}/activities/story_activities.json`,
  ACTIVITIES_PROJECT: (projectId: number) => `projects/${projectId}/activities.json`,
  PROJECT_FAVORITE: (projectId: number) => `projects/${projectId}/favorite`,
  SEARCH_STORIES: (projectId: number) => `projects/${projectId}/search_all`,
  UPDATE_COMMIT_FORMAT_CONFIRMATION: (projectId: number) => `projects/${projectId}/update_commit_format_confirmation`,
  LABEL_UPDATE: (projectId: number, storyId: number) => `projects/${projectId}/stories/${storyId}/update_labels`,
  STORY: (projectId: number, storyId: number) => `projects/${projectId}/stories/${storyId}`,
  STORY_COPY: (projectId: number, storyId: number) => `projects/${projectId}/stories/${storyId}/clone`,
  BULK_STORY_COPY: (projectId: number) => `projects/${projectId}/stories/bulk_clone.json`,
  BULK_FEATURE_LABEL: (projectId: number) => `projects/${projectId}/epics/bulk_add`,
  BULK_MOVE: (projectId: number) => `projects/${projectId}/stories/move`,
  BULK_DELETE: (projectId: number) => `projects/${projectId}/stories/bulk_delete`,
  BULK_ASSIGN_MEMBER: (projectId: number) => `projects/${projectId}/stories/bulk_assign_member`,
  BULK_ASSIGN_PLATFORMS: (projectId: number) => `projects/${projectId}/stories/bulk_assign_platforms`,
  POLICIES: 'policies',
  QUERY_USERS: (projectId: number, query: string) => `projects/${projectId}/users/query?query=${query}`,
  PROJECT_USERS: (projectId: number) => `projects/${projectId}/users`,
  PROJECT_LABELS: (projectId: number) => `projects/${projectId}/labels`,
  RESEND_INVITE: (projectId: number) => `projects/${projectId}/users/resend_invitation`,
  PROJECT_USER: (projectId: number, userId: number) => `projects/${projectId}/users/${userId}`,
  PROJECT_PERSIST_VELOCITY: (projectId: number) => `projects/${projectId}/update_overridden_velocity`,
  STORY_NOTES: (projectId: number, storyId: number) => `projects/${projectId}/stories/${storyId}/notes`,
  STORY_NOTE: (projectId: number, storyId: number, noteId: number) => `projects/${projectId}/stories/${storyId}/notes/${noteId}`,
  STORY_TASKS: (projectId: number, storyId: number) => `projects/${projectId}/stories/${storyId}/tasks`,
  STORY_TASK: (projectId: number, storyId: number, taskId: number) => `projects/${projectId}/stories/${storyId}/tasks/${taskId}`,
  STORY_START_TIMER: (projectId: number, storyId: number) => `projects/${projectId}/stories/${storyId}/start_timer`,
  DEMO_VIDEO: (projectId: number, storyId: number) => `projects/${projectId}/stories/${storyId}/upload_demo_video`,
  STORY_STOP_TIMER: (projectId: number, storyId: number) => `projects/${projectId}/stories/${storyId}/stop_timer`,
  REVIEWER_START_TIMER: (projectId: number, storyId: number) => `projects/${projectId}/stories/${storyId}/start_reviewer_timer`,
  REVIEWER_STOP_TIMER: (projectId: number, storyId: number) => `projects/${projectId}/stories/${storyId}/stop_reviewer_timer`,
  STORY_PHASE: (projectId: number) => `api/v1/tracker/projects/${projectId}/get_phases`,
  PROJECT_DOCUMENT: (projectId: number) => `api/v1/tracker/projects/${projectId}/folder_and_files`,
  GET_WHITE_BOARD: (studio_build_card_id: number) => `/api/v1/uiw/whiteboards?build_card_id=${studio_build_card_id}`,
  SAVE_WHITE_BOARD: (studio_build_card_id: number) => `/api/v1/uiw/whiteboards?build_card_id=${studio_build_card_id}`,
  FEATURE_DETAILS: (projectId: number, builderFeatureId: number) =>
    `api/v1/tracker/projects/${projectId}/feature_details/${builderFeatureId}`,
  ALL_FEATURE_DETAILS: (projectId: number) => `api/v1/tracker/projects/${projectId}/all_feature_details`,
  PROJECT_DETAILS: (projectId: number) => `api/v1/tracker/projects/${projectId}/project_details`,
  TEST_SUITE_STORIES: (projectId: number) => `projects/${projectId}/test_cases/stories`,
  GET_TEST_SUITES:(projectId: number,storyId: number) => `projects/${projectId}/test_cases?story_id=${storyId}`,
  EPICS: (projectId: number) => `projects/${projectId}/epics`,
  PROJECT_TIMING_ACTIVITY: (projectId: number) => `projects/${projectId}/invoice_activities`,
  USER_TRACKED_TIME: (projectId: number) => `projects/${projectId}/users/tracked_hours`,
  PROJECT_DASHBOARD_DATA: (projectId: number) => `projects/${projectId}/invoice_activities/details`,
  ADD_NEW_COLUMN: (projectId: number) => `projects/${projectId}/lists`,
  RENAME_LIST: (projectId: number, listId: number) => `projects/${projectId}/lists/${listId}`,
  PROJECT_TIMING_APPROVED_HOURS: (projectId: number, activityId: number) =>
    `projects/${projectId}/invoice_activities/${activityId}/approve`,
  EPIC: (projectId: number, epicId: number) => `projects/${projectId}/epics/${epicId}`,
  EPIC_ADD_STORY: (projectId: number, epicId: number, storyId: number) =>
    `projects/${projectId}/epics/${epicId}/add_story?story_id=${storyId}`,
  EPIC_REMOVE_STORY: (projectId: number, epicId: number, storyId: number) =>
    `projects/${projectId}/epics/${epicId}/remove_story?story_id=${storyId}`,
  GET_EXPECTED_RESULTS:(projectId: number, storyId: number) => `projects/${projectId}/stories/${storyId}/expected_results`,
  SET_EXPECTED_RESULTS:(projectId: number, storyId: number) => `projects/${projectId}/stories/${storyId}/expected_results`,
  UPDATE_EXPECTED_RESULTS:(projectId: number, storyId: number,resultId: number) => `projects/${projectId}/stories/${storyId}/expected_results/${resultId}`,
  DOCUMENTS: 'documents',
  DOCUMENT: (documentId: number, storyId: number) => `documents/${documentId}?story_id=${storyId}`,
  UPLOAD_AUTHORIZATION: 'documents/upload_authorization.json',
  SOCKETS_AUTH: 'sockets/auth',
  NOTIFICATIONS: 'notifications',
  GET_PARTNER: 'get_partner',
  GET_USER: 'users/find_by_email',
  SIGN_IN: 'auth/sign_in',
  RESEND_INVITATION:  'users/resend_invitation',
  SIGN_UP: 'auth',
  SIGN_OUT: 'auth/sign_out',
  FORGOT_PASSWORD: 'auth/password',
  VALIDATE_TOKEN: 'auth/validate_token',
  NOTIFICATIONS_MARK_AS_READ: 'notifications/mark_as_read',
  FACE_AUTHENTICATION: `api/tracker/developers/facial_authentication`,
  OMNIAUTH: (provider: string, userId: number, redirect_url: string) => {
    const host = environment.apiBase;
    const callback = `${host}/api/v1/omniauth/${provider}/callback`;
    const queryParams =
      `auth_origin_url=${encodeURIComponent(callback)}&user_id=${userId}&redirect_url=${encodeURIComponent(redirect_url)}`;
    return `${host}/api/v1/auth/${provider}?${queryParams}`;
  },
  MEMBER: 'users',
  MEMBER_ROLE: (roles: string) => `users/preferences?type=${roles}`,
  MEMBER_LOCALE: (locales: string) => `users/preferences?type=${locales}`,
  Blocker_CREATE: (storyId: number) => `stories/${storyId}/blockers`,
  Blocker_UPDATE: (storyId: number, blockerId: number) => `stories/${storyId}/blockers/${blockerId}`,
  Blocker_DELETE: (storyId: number, blockerId: number) => `stories/${storyId}/blockers/${blockerId}`,
  ADD_MERGE_REQUEST: (projectId: number,storyId: number) => `projects/${projectId}/stories/${storyId}/merge_requests`,
  DELETE_MERGE_REQUEST: (projectId: number,storyId: number,commitId:number) => `projects/${projectId}/stories/${storyId}/merge_requests/${commitId}`,
  Export_STORIES: (projectId: number) => `projects/${projectId}/stories.csv`,
  Import_STORIES: (projectId: number) => `projects/${projectId}/import_upload`,
  PROJECT_SPRINTS: (projectId: number) => `projects/${projectId}/sprints`,
  CREATE_SPRINT: (projectId: number) => `projects/${projectId}/sprints.json`,
  UPDATE_SPRINT: (projectId: number, sprintId) => `projects/${projectId}/sprints/${sprintId}`,
  DELETE_SPRINT: (projectId: number, sprintId) => `projects/${projectId}/sprints/${sprintId}`,
  SPRINTS_BACKLOG_STORIES: (projectId: number) => `projects/${projectId}/sprints/stories`,
  SPRINT_DETAIL: (projectId: number, sprintId) => `projects/${projectId}/sprints/${sprintId}`,
  SPRINTS_DETAIL_BACKLOG_STORIES: (projectId: number, sprintId) => `projects/${projectId}/sprints/stories?backlog_type=sprint&sprint_id=${sprintId}`,
  SPRINTS_DETAIL_ADDED_BACKLOG_STORIES: (projectId: number, sprintId) => `projects/${projectId}/sprints/stories?sprint_id=${sprintId}`,
  ADD_STORY_TO_SPRINT: (projectId: number, sprintId) => `projects/${projectId}/sprints/${sprintId}/add_story`,
  REMOVE_STORY_FROM_SPRINT: (projectId: number, sprintId) => `projects/${projectId}/sprints/${sprintId}/remove_story`,
  DOWNLOAD_SKETCH: (projectId: number, epicId: number) => `projects/${projectId}/epics/${epicId}/download_sketch_files`,
  USER_ACTIVITIES: (projectId: number, userId: number) => `projects/${projectId}/memberships/${userId}/recent_activities.json`,
  USER_CARD: (projectId: number, userId: number) => `projects/${projectId}/memberships/${userId}/member_card.json`,
  STORY_ATTACHMENTS: (projectId: number, storyId: number) => `projects/${projectId}/stories/${storyId}/notes`,
  RESEND_IDE_INVITE: (projectId: number, userId: number) => `projects/${projectId}/memberships/${userId}/resend_docker_ides`,
  STORIES_WITH_SAME_FEATURE_PHASE: (projectId: number) => `projects/${projectId}/parent_stories`,
  MOVE_SUBTASKS_TO_ANOTHER_TASK: (projectId: number) => `projects/${projectId}/stories/move_subtasks.json`,
  STORY_APPROVAL: () => `story_approvals`,
  STORY_APPROVAL_FILTER_DATA: (scope) => `story_approvals/filters/${scope}`,
  STORY_COMPARE: (projectId: number, storyID: number) => `projects/${projectId}/story_approvals/${storyID}`,
  REQUEST_STORY_COMPARE: (projectId: number) => `projects/${projectId}/story_approvals`,
  ACTION_ON_COMPARE_REQUEST: (projectId: number, listId:number) => `projects/${projectId}/story_approvals/${listId}`,
  GET_CMT_STORY: (cmtStoryId: number) => `features/user_stories?user_story_id=${cmtStoryId}`,
  BULK_ACCEPT_REJECT: (projectId: number) => `projects/${projectId}/stories/bulk_update_state`,
  STORIES_CREATED_IN_FEATURE: (projectId: number, epicId: number) => `projects/${projectId}/epics/${epicId}/dev_stories`,
  STORIES_TO_RELATE: (projectId: number) => `projects/${projectId}/search_stories`,
  VERIFY_BULK_DELETE: (projectId: number) => `projects/${projectId}/stories/validate_archiving_stories`,
  VERIFY_BULK_UNARCHIVE: (projectId: number) => `projects/${projectId}/stories/validate_unarchiving_stories`,
  BULK_UNARCHIVE: (projectId: number) => `projects/${projectId}/stories/bulk_unarchive`,
  CREATE_ACCEPTANCE_CRITERIA: (story_id: number) => `stories/${story_id}/acceptance_criteria`,
  BULK_CREATE_ACCEPTANCE_CRITERIA: (projectId: number, story_id: number) => `projects/${projectId}/stories/${story_id}/bulk_create_acceptance_criterias`,
  UPDATE_ACCEPTANCE_CRITERIA: (story_id: number, criteria_id) => `stories/${story_id}/acceptance_criteria/${criteria_id}`,
  WORKING_HOURS: `api/v2/developer_working_schedules`,
  ACTIVITIES: (projectId: number) => `projects/${projectId}/activities/activities.json`,
  VERIFY_CHANGE_ROLE: (projectId: number) => `projects/${projectId}/stories/validate_bulk_updating_role`,
  BULK_CHANGE_ROLE: (projectId: number) => `projects/${projectId}/stories/bulk_update_role`,
  SCHEDULE_MAINTENANCE: (platform: string) => `maintenance?platform=${platform}`,
  DEVELOPERS_LEAVE: 'api/v1/developers_leave_planner',
  SYNC_STORY: (projectId: number, storyId: number) => `projects/${projectId}/stories/${storyId}/sync_story`,
  RELEASES: (projectId: number) => `projects/${projectId}/releases`,
  RELEASE_FEATURES: (projectId: number, release_id) => `projects/${projectId}/releases/${release_id}/features`,
  SINGLE_RELEASE: (projectId: number, release_id:number) => `projects/${projectId}/releases/${release_id}`,
  STORIES_FOR_RELEASE: (projectId: number) => `projects/${projectId}/releases/stories`,
  SPRINTS_FOR_RELEASE: (projectId: number) => `projects/${projectId}/releases/sprints`,
  ADD_STORIES_IN_RELEASE: (projectId: number, release_id:number) => `projects/${projectId}/releases/${release_id}/add_story`,
  REMOVE_STORIES_FROM_RELEASE: (projectId: number, release_id:number) => `projects/${projectId}/releases/${release_id}/remove_story`,
  COMMENTS_FOR_RELEASE: (projectId: number, release_id:number) => `projects/${projectId}/releases/${release_id}/comments`,
  UPDATE_COMMENTS_FOR_RELEASE: (projectId: number, release_id:number, commentId:number) => `projects/${projectId}/releases/${release_id}/comments/${commentId}`,
  DELETE_COMMENTS_FOR_RELEASE: (projectId: number, release_id:number, commentId:number) => `projects/${projectId}/releases/${release_id}/comments/${commentId}`,
  release_detail: (projectId: number, release_id:number) => `projects/${projectId}/releases/${release_id}/release_detail`,
  MOVE_RELEASE: (projectId: number) => `projects/${projectId}/releases/move`,
  MOVE_RELEASE_T0_PROD: (projectId: number) => `projects/${projectId}/releases/merge`,
  UPDATE_RELEASE_DETAILS: (projectId: number, release_id:number, note_id:number) => `projects/${projectId}/releases/${release_id}/release_details/${note_id}`,
  NUDGE_EXPERT: '/api/tracker/nudge_expert',
  GENERATE_USER_STORY: (projectId: number, storyId: number) => `projects/${projectId}/stories/${storyId}/generate_user_story`,
  GENERATE_UIW_DESIGN_THRESHOLD: (projectId: number, storyId: number) => `projects/${projectId}/stories/${storyId}/update_design_threshold`
};
