import { Component, OnInit } from '@angular/core';
import moment from 'moment';
import { Project } from '../shared/models/project';
import { PolicyService } from '../shared/services/policy.service';
import { ProjectService } from '../shared/services/project.service';
import { NotificationService } from '../shared/services/notification.service';
import { ErrorService } from '../shared/errorFunction';
import {EVENT_TYPES, EXPORT_FIELD_OPTIONS, EXPORT_STATE_OPTIONS, EXPORT_PHASE_OPTIONS} from '../shared/dataTypes';
import {ActivatedRoute, Params} from '@angular/router';
import { SharedService } from '../shared/services/shared.service';

@Component({
  selector: 'app-project-export',
  templateUrl: './project-export.component.html',
  styleUrls: ['./project-export.component.scss'],
  host: {
    '(document:click)': 'onClick($event)'
  }
})
export class ProjectExportComponent implements OnInit {
  projectId;
  project: Project;
  selected: {startDate: moment.Moment, endDate: moment.Moment};
  alwaysShowCalendars: boolean;
  storyStateOptions: Array<any> = EXPORT_STATE_OPTIONS;
  storyPhaseOptions: Array<any> = EXPORT_PHASE_OPTIONS;
  exportFieldOptions: Array<any> = EXPORT_FIELD_OPTIONS;
  disabledExportButton: boolean = false;
  selected_states: String = 'All';
  selected_phases: String = 'All';
  selected_fields: String = 'All';
  dropDownType: String = '';
  showDropDown: boolean = false;
  waitForLoader: boolean = false;
  ranges: any = {
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'Last 3 Month': [moment().subtract(3, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'Last 6 Month': [moment().subtract(6, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'Last 12 Month': [moment().subtract(12, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  };

  constructor(private projectService: ProjectService,
              private errorService: ErrorService,
              public sharedService: SharedService,
              private policyService: PolicyService,
              private notificationService: NotificationService,
              private activatedRoute: ActivatedRoute) {
    this.alwaysShowCalendars = true;
  }

  ngOnInit() {
    this.storyStateOptions.forEach(item => item.checked = true);
    this.exportFieldOptions.forEach(item => item.checked = true);
    this.activatedRoute.params.subscribe((params: Params) => {
      this.projectId = +params['id'];
    });

    this.projectService.project(this.projectId, true).subscribe(project => {
      this.project = project;
      this.notificationService.broadcast(EVENT_TYPES.PROJECT.SHOW, {
        data: {
          project: this.project
        }
      });
    }, error => this.errorService.errorFunction(error));
  }

  clear(e) {
    // this.picker.clear(); // we can do
    this.selected = null; // now we can do
  }

  focusDropDown(dropDownType: String) {
    if (this.showDropDown === false) {
      this.dropDownType = dropDownType;
      this.showDropDown = true;
    } else if (this.dropDownType === dropDownType) {
      this.showDropDown = false;
      this.dropDownType = '';
    } else {
      this.dropDownType = dropDownType;
    }
  }

  exportReport() {
    this.waitForLoader = true;
    if (!this.disabledExportButton) {
      this.disabledExportButton = true;

      const start_date = this.selected.startDate ? moment(this.selected.startDate).format('DD/MM/YYYY') : '';
      const end_date = this.selected.endDate ? moment(this.selected.endDate).format('DD/MM/YYYY') : '';
      const status = this.getSelectedOptions(this.storyStateOptions, 'all');
      const export_columns = this.getSelectedFields();
      const phases = this.getSelectedOptions(this.storyPhaseOptions, 'all');

      this.projectService.exportFile(this.project.id, status, phases, export_columns, start_date, end_date).subscribe((res: any) => {
        this.waitForLoader = false;
        const data = res;
        const parsedResponse = data;
        const blob = new Blob([parsedResponse], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);

        if (navigator.msSaveOrOpenBlob) {
          navigator.msSaveBlob(blob, this.project.name + '.csv');
        } else {
          const a = document.createElement('a');
          a.href = url;
          a.download = this.project.name + '.csv';
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
        window.URL.revokeObjectURL(url);
        this.disabledExportButton = false;
      },  error => {
        this.errorService.errorFunction(error);
        this.waitForLoader = false;
        this.disabledExportButton = false;
      });
    }
  }

  toggleOption(event, option, type) {
    const options = type === 'state' ? this.storyStateOptions : this.storyPhaseOptions;
    if (option.type === 'all') {
      const checked = event.target.checked;
      options.forEach(item => item.checked = checked);
      this['selected_' + type + 's'] = checked ? 'All' : 'None';
    } 
    else {
      option.checked = event.target.checked;
      const ind = options.findIndex(item => item.type === 'all');
      if (ind >= 0) {        
        options[ind].checked = false;
      }  
      
      let selected_options_count = options.filter(item => item.checked).length;
  
      if (event.target.checked && ind >= 0 && selected_options_count === options.length - 1) {        
        options[ind].checked = true;
        this['selected_' + type + 's'] = 'All';
      } 
      else if (ind >= 0 && !(options[ind].checked) && selected_options_count !== 0) {        
          this['selected_' + type + 's'] = '';
          options.forEach(item => this['selected_' + type + 's'] += item.checked ? item.title + ', ' : '');
          this['selected_' + type + 's'] = this['selected_' + type + 's'].substr(0, this['selected_' + type + 's'].length - 2);
      } else if (selected_options_count === 0) {
        this['selected_' + type + 's'] = 'None';
      }
    }
    this.updateDisableExportButton();
  }

  getSelectedOptions(options, allType) {
    const ind = options.findIndex(item => item.type === allType);
    if (ind >= 0 && options[ind].checked) {
      return 'all';
    } else {
      const selectedOptions = options.filter(item => item.checked).map(item => item.type);
      return selectedOptions;
    }
  }  

  toggleExportField(event, field) {
    if (field.type === 'all') {
      const checked = event.target.checked;
      this.exportFieldOptions.forEach(item => item.checked = checked);
      this.selected_fields = checked ? 'All' : 'None';
    } else {
      field.checked = event.target.checked;
      const ind = this.exportFieldOptions.findIndex(item => item.type === 'all');
      if (ind >= 0) {
        this.exportFieldOptions[ind].checked = false;
      }

      let selected_fields_count = 0;
      this.exportFieldOptions.forEach(item => item.checked ? selected_fields_count++ : '');

      if (event.target.checked && ind >= 0 && selected_fields_count === this.exportFieldOptions.length - 1) {
        this.exportFieldOptions[ind].checked = true;
        this.selected_fields = 'All';
      } else if (ind >= 0 && !this.exportFieldOptions[ind].checked && selected_fields_count !== 0) {
        this.selected_fields = '';
        this.exportFieldOptions.forEach(item => this.selected_fields += item.checked ? item.title + ', ' : '');
        this.selected_fields = this.selected_fields.substr(0, this.selected_fields.length - 2);
      } else if (selected_fields_count === 0) {
        this.selected_fields = 'None';
      }
    }
    this.updateDisableExportButton();
  }

  getSelectedFields() {
    const export_columns: Array<String> = [];
    this.exportFieldOptions.forEach(item => (item.type !== 'all' && item.checked) ? export_columns.push(item.type) : '');
    return export_columns;
  }

  updateDisableExportButton() {
    const isStatusChecked = this.storyStateOptions.findIndex(item => item.checked) >= 0;
    const isFieldChecked = this.exportFieldOptions.findIndex(item => item.checked) >= 0;
    this.disabledExportButton = !(isStatusChecked && isFieldChecked);
  }

  onClick(event) {
    const element = event.target;
    if (!(element.classList.contains('statusSelection-DD') || element.classList.contains('statusSelection-list')
          || element.classList.contains('selected-status') || element.classList.contains('iconb-dd-down')
          || element.classList.contains('selected-statusText') || element.classList.contains('customCheckForStorySelection')
          || element.classList.contains('checkbox') || element.classList.contains('custom-selected-checkbox')
          || element.classList.contains('check-toggle') || element.classList.contains('makeUp'))) {
      this.showDropDown = false;
      this.dropDownType = '';
    }
  }
}
