<div class="release-commonPopUp">
    <div class="releaseDetail-backdrop" (click)="closeReleaseDetail()"></div>
    <div class="release-commonPopUp-wrapper">
      <div class="bulkAction-Loader  create-release-Loader" *ngIf="waitForLoader">
        <img src="../../assets/images/storyBoardLoader.gif" alt=""/>
      </div>
      <div class="close-release-planner-popup" (click)="closeReleaseDetail()">
        <em *ngIf="release_detail_for !== 'create' && release_detail_for !== 'release_edit'" class="iconb-close"></em>
        {{(release_detail_for === 'create' || release_detail_for === 'release_edit') ? 'Back' : ''}}
      </div>
      <ng-container *ngIf="release_detail">
        <div class="release-commonPopUp-title-block">
          <div class="title for-release-note">
            {{release && release.name ? release.name : 'Release Note'}}
          </div>
          <div class="action-on-release-note" *ngIf="release_detail_for === 'show' && policyService.projectAdmin(project) && !release.parent_id" (click)="releaseEdit()">
            <span class="iconb-edit"></span> Edit Release Note
          </div>
        </div>
        <div class="commonPopUp-featurecontent releaseDetailsContent">
          <div class="releaseDetailBlock">
            <div class="releaseTitle">Release Note <sup>*</sup></div>
            <div class="releaseDetail-content" *ngIf="release_detail_for === 'show'" [innerHTML]="bypassSanitization(release_detail.body)"></div>
            <textarea *ngIf="release_detail_for !== 'show'" autosize class="releaseDetail-content lg" [(ngModel)]="release_detail.body"></textarea>
          </div>
          <div class="releaseDetailBlock">
            <div class="releaseTitle">Release Video 
              <span class="add-btn" [ngClass]="{'hide-it': attachVideoOnly}" *ngIf="release_detail_for !== 'show'"><em class="iconb-plus"></em>
                <input 
                type="file"  
                id="videofileOnly" 
                class="hidden-input" 
                (change)="attachVideosOnly($event)"
                accept="video/*"/>
              </span>
            </div>
            <div class="empty-block" *ngIf="!attachVideoOnly">
              <p>No Video Attached.</p>
            </div>
            <ng-container *ngIf="attachVideoOnly">
              <div class="video-link">
                <span (click)="openVideoPopup(attachVideoOnly)">{{attachVideoOnly.name}}</span>
                <a *ngIf="attachVideoOnly.remote_url && release_detail_for === 'show'" target="_blank" rel="noopener" class="downloadAttachment" [href]="attachVideoOnly.remote_url">
                  <em class="iconb-download"></em>
                  <span class="elementHover">Download</span>
                </a>
                <label *ngIf="release_detail_for !== 'show'" for="videofileOnly" class="replaceAttachment">
                  <em class="iconb-edit"></em>
                  <span class="elementHover">Change/Replace Video</span>
                </label>
              </div>
            </ng-container>
          </div>
          <div class="releaseDetailBlock">
            <div class="releaseTitle">Credentials</div>
            <div class="empty-block-feature" *ngIf="release_detail_for === 'show' && !release_detail.credentials"><p>No Credentials.</p></div>
            <div class="releaseDetail-content" *ngIf="release_detail_for === 'show' && release_detail.credentials" [innerHTML]="bypassSanitization(release_detail.credentials)"></div>
            <textarea class="releaseDetail-content" autosize *ngIf="release_detail_for !== 'show'" [(ngModel)]="release_detail.credentials"></textarea>
          </div>
          <div class="releaseDetailBlock" *ngIf="release_detail_for !== 'create'">
            <div class="releaseTitle">Featurewise Release Note</div>
            <div class="empty-block" *ngIf="release_detail && release_detail.feature_notes.length === 0"><p>No Features Details.</p></div>
            <ng-container *ngIf="release_detail && release_detail.feature_notes.length > 0">
              <div class="release-feature-outer" *ngFor="let feature_note of release_detail.feature_notes; let i = index">
                <div class="feature-list">
                  <span class="feature-chip">{{feature_note.feature_name}}</span>
                </div>
                <div class="empty-block-feature" *ngIf="release_detail_for === 'show' && !feature_note.body && feature_note.attachments.length === 0"><p>No Data Available.</p></div>
                <ng-container *ngIf="release_detail_for !== 'show' || feature_note.body || feature_note.attachments.length > 0">
                  <div class="releaseDetail-content" [ngClass]="{'no-data': !feature_note.body}" *ngIf="release_detail_for === 'show'" [innerHTML]="bypassSanitization(feature_note.body)"></div>
                  <textarea class="releaseDetail-content" autosize *ngIf="release_detail_for !== 'show'" [(ngModel)]="feature_note.body"></textarea>
                  <div class="relase-video-block">
                    <div class="left-title" *ngIf="release_detail_for !== 'show'">Add Attachments <em class="iconb-attach"></em>
                      <input 
                      type="file" 
                      id="fileOnly_{{i}}" 
                      class="hidden-input"
                      value="" 
                      (change)="attachFilesForFeatureNote($event, i)" 
                      multiple />
                    </div>
                    <!-- <div class="video-listing">
                      <span class="video-chip">file.mov</span>
                      <span class="video-chip">file.pdf</span>
                    </div> -->
                  </div>
                  <div class="video-link for_feature_note" *ngFor="let attachment of feature_note.documents_attributes;let doc_pos = index;">
                    <span (click)="openAttachmentPopup(attachment)">{{attachment.name}}</span>
                    <span target="_blank" rel="noopener" class="deleteDocument" (click)="removeNewAddedAttachment(i , doc_pos)">
                      <em class="iconb-bin"></em>
                      <span class="elementHover">Delete</span>
                    </span>
                  </div>
                  <div class="video-link for_feature_note" *ngFor="let attachment of feature_note.attachments;let attach_pos = index;">
                    <span (click)="openAttachmentPopup(attachment)">{{attachment.name}}</span>
                    <a *ngIf="release_detail_for === 'show'" [href]="attachment?.remote_url" target="_blank" rel="noopener" class="downloadAttachment for-feat-note">
                      <em class="iconb-download"></em>
                      <span class="elementHover">Download</span>
                    </a>
                    <a *ngIf="release_detail_for !== 'show'" target="_blank" rel="noopener" class="deleteDocument" (click)="removeSavedAttachment(i, attach_pos, attachment)">
                      <em class="iconb-bin"></em>
                      <span class="elementHover">Delete</span>
                    </a>
                  </div>
                </ng-container>
              </div>
            </ng-container>
            
          </div>
          <div class="releaseDetailBlock">
            <div class="releaseTitle">Known Issues</div>
            <div class="empty-block-feature" *ngIf="release_detail_for === 'show' && !release_detail.known_issues"><p>No Credentials data.</p></div>
            <div class="releaseDetail-content" *ngIf="release_detail_for === 'show' && release_detail.known_issues" [innerHTML]="bypassSanitization(release_detail.known_issues)"></div>
            <textarea class="releaseDetail-content" autosize *ngIf="release_detail_for !== 'show'"  [(ngModel)]="release_detail.known_issues"></textarea>
          </div>
          <div class="releaseDetailBlock">
            <div class="releaseTitle">Coming Soon</div>
            <div class="empty-block-feature" *ngIf="release_detail_for === 'show' && !release_detail.coming_soon"><p>No Coming Soon data.</p></div>
            <div class="releaseDetail-content" *ngIf="release_detail_for === 'show' && release_detail.coming_soon" [innerHTML]="bypassSanitization(release_detail.coming_soon)"></div>
            <textarea class="releaseDetail-content" autosize *ngIf="release_detail_for !== 'show'"  [(ngModel)]="release_detail.coming_soon"></textarea>
          </div>
          <div class="release-commonPopUp-actionBtn" *ngIf="release_detail_for !== 'show'">
            <button type="button" class="release-commonPopUp-cancel" *ngIf="release_detail_for === 'release_detail_edit'" (click)="closeReleaseDetail()">Cancel</button>
            <span class="release-commonPopUp-submit" (click)="(release_detail_for === 'create' || release_detail_for === 'release_edit') ? addNote() : updateReleaseDetail()">{{(release_detail_for === 'create' || release_detail_for === 'release_edit') ? 'Add' : 'Update'}}</span>
          </div>
        </div>
    </ng-container>
    </div>
</div>

<div class="videoPopup-wrapper" *ngIf="showVideo">
  <div class="videoPopup-backdrop"></div>
  <em class="iconb-close closeVideoPopup" (click)="closeVideoPopup()"></em>
  <div class="videoPopup">
    <video width="640px" controls autoplay>
      <source src="{{vURL}}" type='video/mp4' />
      <source src="{{vURL}}" type='video/mov' />
    </video>
  </div>
</div>

<div *ngIf="showImageModal" class="authorBioWrapper" [ngClass]="{'openAuthorBio' : showImageModal}">
  <div class="backdropClose" (click)="closeImagePopup()"></div>
  <em class="closeFun iconb-close" (click)="closeImagePopup()">close</em>
  <div class="authorContent">
    <img src="{{openImage_url}}" alt="loader"/>
  </div>
</div>