import { Component, HostListener, OnInit, Renderer2 } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { ErrorService } from '../shared/errorFunction';
import { StoryReview } from '../shared/models/storyReview';
import { StoryApprovalService } from '../shared/services/story-approval.service';
import moment from 'moment';
import { SharedService } from '../shared/services/shared.service';
import { SocketsService } from '../shared/services/sockets.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-feedbackloop-dashboard',
  templateUrl: './feedbackloop-dashboard.component.html',
  styleUrls: ['./feedbackloop-dashboard.component.scss'],
  animations: [
    trigger('slideOut', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({
          opacity:'0',
          top: '0',
          right: '-190px'
        }),
        animate(100, style({
          opacity:'1',
          top: '0',
          right: '0'
        }))
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate(100, style({
          opacity:'0',
          top: '0',
          right: '-190px'
        }))
      ])
    ])
  ],
  host: {
    '(document:click)': 'onClick($event)',
  }
})
export class FeedbackloopDashboardComponent implements OnInit {
  storyReviewList: Array<StoryReview> = [];
  showCompare:boolean = false;
  startLoader:boolean = false;
  isPending:boolean = false; 
  moreAvailable: boolean = true;
  meta: {
    total_count?: number,
    current_page?: number,
    next_page?: number,
  };
  reviewActionConfirmation:boolean = false;
  showfilterList:boolean = false;
  _subscription: any;
  selectedStory:any;
  isUpdateAvailable:any;

  constructor(
    public storyApprovalService: StoryApprovalService,
    private errorService: ErrorService,
    public sharedService: SharedService,
    public renderer: Renderer2,
    private socketsService: SocketsService
    ) { }

  ngOnInit() {
    this.startLoader = true;
    this.loadCompareList();
    this.eventUnsubscribe();
    this._subscription = this.socketsService.centrifuge.subscribe('story-approvals', (eventContext) => {
      let eventData = eventContext.data;
      this.processEvent(eventData);
    });
  }

  eventUnsubscribe() {
    if (this._subscription) {
      this._subscription.unsubscribe();
      this._subscription.removeAllListeners();
      this._subscription = null;
    }
  }

  loadCompareList(actionOnFilter?:boolean) {
    const pageNo = this.meta && this.meta.next_page && !actionOnFilter ? this.meta.next_page : 1;
    this.storyApprovalService.storyApprovalList(pageNo).subscribe(res => {
      if(actionOnFilter && res.data && res.data.length === 0) {
        this.storyReviewList = [];
      }
      if(res.data && res.data.length > 0) {
        this.meta = res.meta;
        if(this.meta.current_page === 1) {this.storyReviewList = [];}
        res.data.forEach((rstory) => {
          this.storyReviewList.push(new StoryReview(rstory));
        });
      }
      this.startLoader = false;
    }, error => {
      this.startLoader = false;
      this.errorService.errorFunction(error)
    });
  }

  openStoryCompare(storyData) {
    this.showCompare = true;
    this.selectedStory = storyData;
  }

  // close story approval detail popup
  onShowCompareChange(showCompare: boolean) {
    this.showCompare = showCompare;
    this.selectedStory = null;
  }

  processEvent(event) {
    console.log(event);
    if (event.class === 'StoryApproval' && event.action === 'create') {
      this.storyReviewList.unshift(new StoryReview(event.changes));
    } else if(event.class === 'StoryApproval' && event.action === 'update') {
      const index = this.storyReviewList.findIndex(storyIndex => event.id === storyIndex.id);
      if(index > -1) {
        this.storyReviewList[index].updateList(event.changes);
        if(this.showCompare && this.selectedStory && this.selectedStory.id === event.id) {
          this.isUpdateAvailable = event.changes;
        }
      }
    }
  }

  isScrollFetchAvailable(): boolean {
    return this.meta && this.meta.next_page != null;
  }

  getMoreStories() {
    this.loadCompareList();
  }

  onClick(event) {
    const element = event.target;
    if (!(element.classList.contains('filterbutton-title') || element.classList.contains('iconb-filter')
      || element.classList.contains('filterButton') || element.classList.contains('filterOptions-typeListing')
      || element.classList.contains('typeList-name') || element.classList.contains('typeList-counter')
      || element.classList.contains('filterOptions-typeBock') || element.classList.contains('typeBock-listing')
      || element.classList.contains('check-toggle') || element.classList.contains('story-selected-checkbox')
      || element.classList.contains('typeBock-name') || element.classList.contains('filterSearch')
      || element.classList.contains('iconb-search') || element.classList.contains('ownerListWrapper')
      || element.classList.contains('filterCounter') || element.classList.contains('resetFilter-button')
      || element.classList.contains('resetFilter-block') || element.classList.contains('filterCal')
      || element.classList.contains('dateRagne-input') || element.classList.contains('dateClearButton') 
      || element.classList.contains('iconb-close') || element.classList.contains('status-label')
      || element.classList.contains('filterbyDate-button'))) {
      this.showfilterList = false;
      this.renderer.removeClass(document.body, 'adjustLayer');
    }
  }

  toggelefilter() {
    this.renderer.addClass(document.body, 'adjustLayer');
    this.showfilterList = !this.showfilterList;
  }

  applyFilter(event, key, value) {
    const actionOnFilter = true;
    this.storyApprovalService.addRemoveFilter(event, key, value);
    this.loadCompareList(actionOnFilter);
  }

  resetFilter() {
    const actionOnFilter = true;
    this.storyApprovalService.resetFilter();
    this.loadCompareList(actionOnFilter);
  }
  
}
