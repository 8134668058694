import {
  Component,
  OnInit,
  Input,
  SimpleChanges, Output, EventEmitter
} from '@angular/core';

import {
  Epic,
} from '../../shared/models/epic';

import {
  Project,
} from '../../shared/models/project';

import {
  EVENT_TYPES
} from '../../shared/dataTypes';

import { UserService } from '../../shared/services/user.service';
import { ErrorService } from '../../shared/errorFunction';
import { NotificationService } from '../../shared/services/notification.service';
import { PolicyService } from '../../shared/services/policy.service';
import { ProjectColumn } from '../../shared/models/column';
import { ProjectService } from '../../shared/services/project.service';

@Component({
    selector: 'epic-column, [epic-column]',
    templateUrl: 'epicColumn.component.html'
})
export class EpicColumnComponent implements OnInit {
  @Input('column') projectColumn: ProjectColumn;
  @Input() project: Project;
  epics: Array<Epic> = [];
  startLoader:boolean = false;
  enableEstimates:boolean = false;
  enableStatus:boolean = false;
  @Output('applyFeatureFilterNext') applyFeatureFilterNext = new EventEmitter();

  constructor(private notificationService: NotificationService,
              private userService: UserService,
              private errorService: ErrorService,
              private policyService: PolicyService,
              public projectService: ProjectService) {

  }

  ngOnInit() {
    this.enableEstimates = true;
    this.notificationService.subscribeEvent(EVENT_TYPES.EPIC.UPDATED, res => {
      if(res.payload.data.epics && res.payload.data.epics.length > 0) {
         this.project.epics = res.payload.data.epics;
         this.setEpics()
      }
    });
    this.notificationService.subscribeEvent(EVENT_TYPES.EPIC_PROGRESS_UPDATE.EPIC_PROGRESS_UPDATED, data => {
      if(data.payload.data.epics && data.payload.data.epics.length > 0) {
        this.project.epics = data.payload.data.epics;
        this.setEpics();
      }
    });
    this.setEpics();
  }
  enableEstimateOpt() {
    this.enableStatus = false;
    this.enableEstimates = true;
  }
  enableStatusOpt() {
    this.enableStatus = true;
    this.enableEstimates = false;
  }

  ngOnChanges (changes: SimpleChanges) {
    if (changes['project']) {
      this.setEpics();
    }
  }

  setEpics() {
    this.startLoader = true;
    if (this.project) {
      let epics = Object.assign([], this.project.epics);
      this.epics = epics;
      this.startLoader = false;
    }
  }

  get isProjectAdmin(): boolean {
    return this.policyService.isProjectAdmin(this.project);
  }

  // send data to filter
  applyFeatureFilter(data) {
    this.applyFeatureFilterNext.emit(data);
  }

  getEpics(){
    if ( this.project.filters.feature.length > 0) {
      return this.epics.filter(epic => this.project.filters.feature.find(feature => feature === epic.id))
    }else return this.epics;
  }
}
