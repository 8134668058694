import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import {API_ROUTES} from '../../shared/apiRoutes';
import { DataService } from '../../shared/services/data.service';
import { HttpClient } from '@angular/common/http';

export const ROUTES = {
  USERS: 'admin/users.json',
  USER: (id: number) => `admin/users/${id}`,
  USER_PROJECTS: (id: number) => `admin/users/${id}/projects.json`,
  Export_USERS: 'admin/users.csv'
};

@Injectable()
export class AdminUsersService {
  isNewUserAdded: boolean = false;

  constructor(
    private http: HttpClient,
    private dataService: DataService
    ) {}

  getUsers(queryParams) {
    const API = this.dataService.apiUrl + ROUTES.USERS;
    const url = this.dataService.parseQueryParams(API, queryParams);

    return this
      .http
      .get(url, this.dataService.getRequestOptionArgs())
      .pipe(map(response => this.parseResponse(response)));
  }

  getUser(id: number) {
    const API = this.dataService.apiUrl + ROUTES.USER(id);
    return this.http.get(API, this.dataService.getRequestOptionArgs());
  }

  getUserProjects(id: number, searchQuery, all_load?: boolean) {
    let queryStr = '?q=' + searchQuery;
    if (all_load) {
      queryStr = queryStr + '&all=' + true;
    }
    const API = this.dataService.apiUrl + ROUTES.USER_PROJECTS(id) + queryStr;
    return this.http.get(API, this.dataService.getRequestOptionArgs()).pipe(
                            map(response => this.parseResponse(response)));
  }

  createUser(user, email?) {
    let addUser = Object.assign({}, user);
    if (email) {
      addUser.email = email;
    }

    const API = this.dataService.apiUrl + ROUTES.USERS;
    return this.http.post(API, addUser, this.dataService.getRequestOptionArgs());
  }

  updateUser(id: number, user: any, email?) {
    let editUser = Object.assign({}, user);
    if (email) {
      editUser.email = email;
    }

    const API = this.dataService.apiUrl + ROUTES.USER(id);
    return this.http.put(API, editUser, this.dataService.getRequestOptionArgs());
  }

  destroyUser(id: number) {
    const API = this.dataService.apiUrl + ROUTES.USER(id);
    return this.http.delete(API, this.dataService.getRequestOptionArgs());
  }

  exportFile() {
    const API = this.dataService.apiUrl + ROUTES.Export_USERS;
    return this.http.get(API, this.dataService.getRequestOptionArgs('', false, '', '', '', true));
  }

  getallRoles(roles: string) {
    const API = this.dataService.apiUrl + API_ROUTES.MEMBER_ROLE(roles);
    return this.http.get(API, this.dataService.getRequestOptionArgs()).pipe(
                            map(response => this.parseResponse(response)));
  }

  getallLocale(locales: string) {
    const API = this.dataService.apiUrl + API_ROUTES.MEMBER_LOCALE(locales);
    return this.http.get(API, this.dataService.getRequestOptionArgs()).pipe(
                            map(response => this.parseResponse(response)));
  }

  public parseResponse(res: any): any {
    return res;
  }
}
