// import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// import { PopoverModule, BsModalService, ModalModule, BsModalRef } from 'ngx-bootstrap';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
// import { MatIconModule, MatSelectModule, MatFormFieldModule } from '@angular/material';
// import { MarkdownModule } from 'angular2-markdown';
import { ClipModule } from 'ng2-clip';
// import { MentionModule } from 'angular-mentions';
import { DragulaService } from '../../lib';
import { FileUploadModule } from '../../lib';
import { SharedModule } from './../shared/shared.module';
import { ProjectComponent } from './project.component';
import { ProjectColumnComponent } from './column';
import { ProjectStoryComponent } from './story';
import {  StoryDetailComponent, StoryHistoryComponent } from './story';
import { StoryNoteComponent } from './note';
import {
  ProjectNavbarComponent
} from './header';
import { EpicComponent } from './epic';
import { EpicColumnComponent } from './epicColumn';
import { HttpClientModule } from '@angular/common/http';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { StoryTimerModule } from '../shared/components/story-timer';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SprintComponent } from './sprint/sprint.component';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import { SprintDetailComponent } from './sprint-detail/sprint-detail.component';
import { BacklogStoriesComponent } from './backlog-stories/backlog-stories.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { StoryFilterComponent } from './story-filter/story-filter.component';
import { SprintPopupComponent } from './sprint-popup/sprint-popup.component';
import { ActivityTimelineComponent } from './activity-timeline/activity-timeline.component';
import { InvalidStoriesComponent } from '../shared/components/invalid-stories/invalid-stories.component';
import { StoryClonePopupComponent } from '../shared/components/story-clone-popup/story-clone-popup.component';
import { AcceptRejectPopupComponent } from '../shared/components/accept-reject-popup/accept-reject-popup.component';
import { StoryCompareModule } from '../shared/storyCompare.module';
@NgModule({
  imports: [
    // NgbModule.forRoot(),
    CommonModule,
    RouterModule,
    SharedModule,
    FormsModule,
    // MatIconModule,
    // PopoverModule,
    BrowserModule,
    DragDropModule,
    // MarkdownModule.forRoot(),
    ClipModule,
    // MentionModule,
    StoryTimerModule,
    FileUploadModule,
    // MatFormFieldModule,
    InfiniteScrollModule,
    // MatSelectModule,
    // ModalModule.forRoot(),
      HttpClientModule,
    SlickCarouselModule,
    CKEditorModule,
    AngularMyDatePickerModule,
    AngularEditorModule,
    NgxDaterangepickerMd.forRoot({
      separator: ' To ',
      format: 'DD/MM/YYYY'
    }),
    StoryCompareModule
  ],
  declarations: [
    ProjectComponent,
    ProjectColumnComponent,
    ProjectStoryComponent,
    StoryNoteComponent,
    StoryDetailComponent,
    StoryHistoryComponent,
    ProjectNavbarComponent,
    EpicComponent,
    EpicColumnComponent,
    SprintComponent,
    SprintDetailComponent,
    BacklogStoriesComponent,
    StoryFilterComponent,
    SprintPopupComponent,
    ActivityTimelineComponent,
    InvalidStoriesComponent,
    StoryClonePopupComponent,
    AcceptRejectPopupComponent
  ],
  providers: [
    DragulaService,
    // BsModalService,
    // BsModalRef
  ],
  exports: [
    StoryDetailComponent,
    ProjectComponent,
    ProjectNavbarComponent,
    ProjectStoryComponent,
    StoryFilterComponent,
    InvalidStoriesComponent,
    StoryClonePopupComponent,
    AcceptRejectPopupComponent,
    StoryNoteComponent,
    ActivityTimelineComponent,
  ],
  entryComponents: [
    AcceptRejectPopupComponent
  ]
})
export class ProjectModule { }
