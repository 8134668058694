import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { API_ROUTES } from '../apiRoutes';
import { HttpRequests } from './http-requests.service';
import { DataService } from './data.service';

@Injectable()
export class ActivityService {

  constructor(
    private httpRequest: HttpRequests,
    private dataService: DataService) {
  }

  activities(projectId: number, storyId: number, all_load?: boolean) {
    let queryStr = '?story_id=' + storyId;
    if (all_load) {
      queryStr = queryStr + '&all=' + true;
    }
    const API = this.dataService.apiUrl + API_ROUTES.ACTIVITIES_STORIES(projectId) + queryStr;
    return this.httpRequest
      .get(API, this.dataService.getRequestOptionArgs()).pipe(
      map(res => {
        return this.parseResponse(res);
      }));
  }

  common_activities(projectId: number, subject_id: number, subject_type:string, load_all_activities) {
    const subject_id_queryStr = '?subject_id=' + subject_id;
    const subject_type_queryStr = '&subject_type=' + subject_type;
    const API = this.dataService.apiUrl + API_ROUTES.ACTIVITIES(projectId) + subject_id_queryStr + subject_type_queryStr + '&all=' + load_all_activities;
    return this.httpRequest
      .get(API, this.dataService.getRequestOptionArgs()).pipe(
      map(res => {
        return this.parseResponse(res);
      }));
  }

  public parseResponse(res: any): any {
    return res;
  }
}
