import {
  AfterViewInit,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
  Renderer2
} from '@angular/core';
import {Location} from '@angular/common';

import {ActivatedRoute, NavigationEnd, NavigationStart, Router} from '@angular/router';
import * as _ from 'lodash';
import moment from 'moment';

import {
  EVENT_TYPES,
  FIRST_VISIBLE_COLUMN,
  HeaderComponentData,
  PROJECT_COLUMN_DATA,
} from './../shared/dataTypes';

import { NoContentComponent } from '../shared/noContent.component';
import { Project } from '../shared/models/project';
import { User } from '../shared/models/user';

import { ErrorService } from '../shared/errorFunction';
import { NotificationService } from '../shared/services/notification.service';
import { PolicyService } from '../shared/services/policy.service';
import { ProjectService } from '../shared/services/project.service';
import { UserService } from '../shared/services/user.service';
import {filter} from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { SharedService } from '../shared/services/shared.service';

@Component({
  selector: 'header-bar',
  templateUrl: 'header.component.html',
  styleUrls: ['header.component.scss'],
  host: {
    '(document:click)': 'onClick($event)'
  }
})
export class HeaderComponent implements OnInit, OnDestroy {
  currentUser: User;
  isSignedIn: boolean;
  projects: Array<Project> = [];
  searchText: string = '';
  startLoader: boolean = false;
  currentUrl = null;
  projectSubscription: Subscription;
  isPending: boolean = false;
  params = {
    q: '',
    page: 1,
    timezone: '',
    min_search: true
  };
  meta: any;
  moreAvailable: boolean = true;
  projectName = '';
  userOptionsDD: boolean = false;
  toggleNavigation: boolean = false;
  locationPath = null;
  isProjectExternal: boolean = false;
  projectProgress: any;
  isProgressAvailable: boolean = false;
  overAllPhasesProgress: number ;

  constructor(
              private router: Router,
              private policyService: PolicyService,
              public userService: UserService,
              private location: Location,
              private sharedService: SharedService,
              private notificationService: NotificationService,
              public projectService: ProjectService,
              private activatedRoute: ActivatedRoute,
              private errorService: ErrorService,
              private resolver: ComponentFactoryResolver,
              private renderer: Renderer2) {
    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = this.router.url;
        if (this.currentUrl.indexOf('admin') >= 0) {
          this.projectName = '';

        }
        this.locationPath = null;
      }
    });
  }

  ngOnInit() {
    this.notificationService.subscribeEvent(EVENT_TYPES.PROJECT_PERCENTAGE.PROGRESS_UPDATED, data => {
      this.collectProjectProgress(data);
    });

    this.notificationService.subscribeEvent(EVENT_TYPES.PROJECT.SHOW, data => {
      this.projectName =  data.payload.data.project.name;
      this.isProjectExternal = data.payload.data.project.checkIFProjectExternal;
      this.collectProjectProgress(data);
      
      this.projects = [];
      this.params.page = 1;
      this.getProjects();
      const ind = this.projects.findIndex(pro => pro.id === data.payload.data.project.id);
      if (ind !== -1) {
        this.projects[ind].name = this.projectName;
      }
    });
    
    this.locationPath = this.location.path();
    this.searchText = '';
    this.toggleNavigation = localStorage.getItem('toggleNavigation') === 'collapsed';
    if (this.toggleNavigation){
      this.renderer.addClass(document.body, 'collapseNav');
    }
    this.loadData();
  }

  collectProjectProgress(data: any) {
    this.isProgressAvailable = data.payload.data.project.progress_available;
    this.overAllPhasesProgress = 0;

    // Show progress percentage when progress available
    if (this.isProgressAvailable) {
      this.projectProgress = data.payload.data.project.progress;
      this.projectProgress.phases.forEach(item => {
        this.overAllPhasesProgress += item.progress;
      });
    } else {
      this.projectProgress = {};
    }
  }


  ngOnDestroy() {
    // This is intentional
  }

  get isAdmin(): boolean {
    return this.policyService.isAdmin;
  }

  get isNewProjectAdmin() {
    return this.policyService.isUserProjectAdmin
  }

  get isProjectsShow() {
    return (this.policyService.isUserProjectAdmin || this.policyService.isAdmin);
  }

  get ifUserIsReviewer() {
    return this.policyService.isAdmin && this.currentUser.reviewer;
  }

  loadData() {
    this.isSignedIn = this.userService.userSignedIn();
    if (this.isSignedIn) {
      this.currentUser = this.userService.getUser();
    }
  }

  getOlderProject() {
    this.startLoader = true;
    if (this.meta.total_count == this.projects.length) {
      this.moreAvailable = false;
    }
    if (this.moreAvailable) {
      this.params.page++;
      this.getProjects();
    }
  }

  isScrollFetchAvailable(): boolean {
    return !this.isPending && this.moreAvailable;
  }

  getProjects(search?) {
    this.params.q = this.searchText;
    this.params.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.isPending = true;
    if (this.projectSubscription) {
      this.projectSubscription.unsubscribe();
    }
    this.projectSubscription = this.projectService.getProjects(this.params).
    subscribe(res => {
      this.isPending = false;
      this.meta = res.meta;
      if (search) {
        this.projects = [];
      }
      res.projects.forEach(data => this.projects.push(data.project));
      this.startLoader = false;
    },
  (error) => {
    this.errorService.errorFunction(error);
  });
  }

  getSearchProjects() {
    this.params.page = 1;
    this.getProjects(true);
  }

  signOut(event) {
    localStorage.setItem('isUserInternalOrCp', '');
    this.userService.signOut();
  }

  userOptionsToggle() {
    this.userOptionsDD = !this.userOptionsDD;
  }

  userOptionsClose() {
    return this.userOptionsDD = false;
  }

  collapseNav() {
    this.toggleNavigation = !this.toggleNavigation;
    if (this.toggleNavigation) {
      localStorage.setItem('toggleNavigation', 'collapsed');
      this.renderer.addClass(document.body, 'collapseNav');
    } else {
      localStorage.setItem('toggleNavigation', '');
      this.renderer.removeClass(document.body, 'collapseNav');
    }
  }

  adjustZLayer() {
    this.projectService.openProDD ? this.renderer.addClass(document.body, 'makeLayerDown') : this.renderer.removeClass(document.body, 'makeLayerDown');
  }

  onClick(event) {
    const element = event.target;
    if (!(element.classList.contains('iconb-changemember') || element.classList.contains('iconb-logout')
          || element.classList.contains('optionsList') || element.classList.contains('trackerUserInfo-wrapper')
          || element.classList.contains('user-email') || element.classList.contains('trackerUserInfo-initials')
          || element.classList.contains('user-name') || element.classList.contains('iconb-dd-down'))) {
      this.userOptionsClose();
    }
  }

  isProjectContext() {
    return (this.router.url && this.router.url.substring(0, 9) == '/projects')
   }

  isAdminMemberContext() {
    return (this.router.url && this.router.url.indexOf('/admin/users') >= 0) ? true: false;
   }

   isAdminMemberLocationContext() {
    return this.locationPath && this.locationPath.indexOf('/admin/users') >= 0 ? true: false;
   }

   isAccountContext() {
    return (this.router.url && this.router.url.indexOf('/account')) >= 0 ? true: false;
   }

   isAccountLocationContext() {
    return this.locationPath && this.locationPath.indexOf('/account') >= 0 ? true: false;
   }

   checkRoute() {
    return ((this.currentUrl == '/'  ||  this.currentUrl === '' ||  this.currentUrl === '/help-support' || this.currentUrl === '/story-approvals' || this.locationPath === '' || this.isAdminMemberContext() || this.isAccountContext()  || this.isAdminMemberLocationContext() || this.isAccountLocationContext()));
   }
}
