<div class="table-wrapper table-timing">
  <table role="presentation">
    <thead>
      <tr>
        <td>{{project.name}}</td>
        <td *ngFor="let hour of project.hours">{{hour}}</td>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let story of project.stories">
        <td>{{story.name}}</td>
        <td *ngFor="let hour of story.hours; index as i"
            (click)="hour !== '-' && onCellClicked(story, i)">
          {{hour}}
        </td>
      </tr>
    </tbody>
  </table>
</div>
