import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import {
  Router,
  ActivatedRoute,
  Params
} from '@angular/router';
import { EVENT_TYPES } from '../shared/dataTypes';
import { HttpClient } from '@angular/common/http';
import { CustomToastService } from '../shared/services/custom-toast.service';
import { Project } from '../shared/models/project';
import { User } from '../shared/models/user';
import { UserService } from '../shared/services/user.service';
import {  ProjectService } from '../shared/services/project.service';
import { ErrorService } from '../shared/errorFunction';
import { PolicyService } from '../shared/services/policy.service';
import { DocumentService } from '../shared/services/document.service';
import { SocketsService } from '../shared/services/sockets.service';
import { NotificationService } from '../shared/services/notification.service';
import {FileHandle} from '../shared/directives/dragdropfile.directive';

@Component({
    selector: 'project-import',
    templateUrl: 'projectImport.component.html',
    styleUrls: ['projectimport.component.scss']
})
export class ProjectImportComponent implements OnInit {
  project: Project;
  projectId?: number;
  currentUser: User;
  public importuploadProcessStarted: boolean = false;

  // Import file.
  // url = 'https://api.cloudinary.com/v1_1/' + this.cloudinary.config().cloud_name + '/auto/upload';
  fileToUpload: File = null;
  channelName = '';
  filenameClose: boolean = false;
  importFile: boolean = true;
  @ViewChild('myInput')
  myInputVariable: ElementRef;
  message = '';
  private _subscription: any;
  public hasBaseDropZoneOver: boolean = false;
  processingImport:boolean = false;
  importPTStories: boolean = false;

  constructor(
              private router: Router,
              private notificationService: NotificationService,
              private userService: UserService,
              private activatedRoute: ActivatedRoute,
              private projectService: ProjectService,
              private errorService: ErrorService,
              private policyService: PolicyService,
              //private cloudinary: Cloudinary,
              private httpClient: HttpClient,
              private customToast: CustomToastService,
              private documentService: DocumentService,
              private socketsService: SocketsService) {}

  ngOnInit() {
    this.importPTStories = false;
    this.activatedRoute.params.subscribe((params: Params) => {
      this.projectId = +params['id'];
      this.loadData();
    });
  }

  get importedFile() {
    return this.fileToUpload !== null;
  }

  loadData() {
    this.currentUser = this.userService.getUser();
    this.projectService.project(this.projectId, true)
      .subscribe(
        project => {
          this.project = project;
          this.notificationService.broadcast(EVENT_TYPES.PROJECT.SHOW, { data: { project: this.project } });
          if ((this.projectService.current_user_role === 'read_only') || (this.projectService.current_user_role === 'user' && !this.policyService.isQAExpert(this.project))) {
            this.router.navigate(['/projects/' + this.project.id]);
            return;
          }
          this.projectService.loadUsers(this.project);
        }, err => this.errorService.errorFunction(err)
      );
  }

  subscribeForUpdates() {
    let centrifuge = this.socketsService.centrifuge;
    this._subscription = centrifuge.subscribe(this.channelName, (eventContext) => {
      let event = eventContext.data;
      if (event.action == 'import') {
        this.customToast.messages =  this.customToast.messages.filter( rmh =>{
           return rmh.id != "importProcessing";
          }
        );
        if (event.status == 'success') {
          this.customToast.messages.push({id: "importSuccess", type:  "success", class: "import_success",  title: 'Import Success', message: event.message});
          this.fileToUpload = null;
          this.processingImport = false;
        } else {
          this.customToast.messages.push({id:"importfail", type:  "fail", class: "import_fail",  title: 'Import Fail', message: event.message});
          this.fileToUpload = null;
          this.processingImport = false;
        }
      }
    })
  }

  public unsubscribeSubscrpt() {
    if (this._subscription) {
      this._subscription.unsubscribe();
      this._subscription = undefined;
    }
  }

  // For file uploaded in servcie.
  uploadImport() {
    this.unsubscribeSubscrpt();
    this.processingImport = true;
    let uniqKey = Math.floor(Math.random() * 10000);
    this.channelName = `import-user-${this.userService.getUser().id}` + '-' + uniqKey;
    this.subscribeForUpdates();
    this.projectService.importUpload(this.fileToUpload, this.projectId, this.channelName, this.importPTStories).subscribe(res => {
      if (this.parseResponse(res).status === 200) {
        if (this.parseResponse(res).message) {
          this.customToast.messages.push({id: "importProcessing", type:  "info", class: "import_processing",  title: 'Import Processing', message: this.parseResponse(res).message});
        } else {
          this.customToast.messages.push({id:"importfail", type:  "fail", class: "import_fail",  title: 'Import Fail', message: this.parseResponse(res).error});
          this.fileToUpload = null;
          this.processingImport = false;
        }
      }
    });
  }

  // Remove file if not import the file.
  removeFile() {
    this.filenameClose = false;
      this.importFile = true;
      this.fileToUpload = null;
  }

  downloadSample() {
    const link = document.createElement('a');
    link.download = 'sample import.csv';
    link.href = 'assets/download-sample/Fulcrum_Import_Template.csv';
    link.click();
  }

  public parseResponse(res: any): any {
    return res;
  }

  toggleImportPTStories() {
    this.importPTStories = !this.importPTStories;
  }

  fileToImport(event) {
    this.fileToUpload = event.target.files[0];
    this.filenameClose = true;
  }

  draggedFileToImport(files: FileHandle[]) {
    this.fileToUpload = files[0].file;
    this.filenameClose = true;
  }
}
