<form class="form-horizontal" novalidate autocomplete="off">
  <div class="inputBlock" [class.has-error]="hasError('name')">
    <label for="projectName" class="forProjectName">Name</label>
      <input id="projectName"
             type="text"
             class="addProject-input"
             placeholder="Name"
             name="name"
             [(ngModel)]="model.name">
    <p class="new-error-meg">
      {{ errors?.name }}
    </p>
  </div>
  <!-- <div class="inputBlock" [class.has-error]="hasError('default_velocity')">
    <label for="projectDefaultVelocity" class="forProjectName">Default Velocity</label>
      <input id="projectDefaultVelocity"
             type="number"
             min = "0"
             (input) = "inputVelocity()"
             class="addProject-input"
             placeholder="Default Velocity"
             name="default_velocity"
             [(ngModel)]="model.default_velocity">
    <p class="new-error-meg">
      {{ errors?.default_velocity ? errors?.default_velocity : error }}
    </p>
  </div> -->
  <div class="inputBlock" [class.has-error]="hasError('start_date')">
    <label for="projectStartDate" class="forProjectName">Start Date</label>
    <div class="cal-warpper">
    <input id="projectStartDate"
               type="text"
               class="addProject-input"
               placeholder="Start Date"
               name="start_date"
               angular-mydatepicker
               (dateChanged)="onStartDateChanged($event)"
               #dp="angular-mydatepicker"
               [options]="datePickerOptions"
               [(ngModel)]="startDate"
               >

      <div class="cal-btn">
        <span class="calIcon" (click)="dp.toggleCalendar()">
          <em class="iconb-calendar"></em>
        </span>
      </div>
    </div>

    <p class="new-error-meg">
      {{ errors?.start_date }}
    </p>
  </div>
  <ng-content></ng-content>
</form>
