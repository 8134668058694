import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import moment from 'moment';
import { API_ROUTES } from '../apiRoutes';
import { HttpParams } from '@angular/common/http';
import { isEmpty } from 'lodash';
import { HttpRequests } from './http-requests.service';
import { map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs'
import { DataService } from './data.service';
import { INudgeParams } from '../dataTypes';
interface IMessageSource {
  year: number,
  month: number,
  project_id: number,
  type: string,
  search: string,
  sort_by: string,
  per_page: number,
  page_number: number,
  skill_filter: Array<number>,
  leave_filter: Array<boolean>
}

@Injectable({ providedIn: 'root' })
export class DateAndTimeService {
  public messageSource = new BehaviorSubject<IMessageSource>({
    year: parseInt(moment().format('YYYY')),
    month: parseInt(moment().format('M')),
    project_id: 0,
    type: 'month',
    search: '',
    sort_by: '',
    per_page: 15,
    page_number: 1,
    skill_filter: [],
    leave_filter: []
  });
  public queryParams = this.messageSource.asObservable();

  constructor(private httpRequest: HttpRequests, private dataService: DataService,
  ) {
  }
  changeQueryParams(message: IMessageSource) {
    this.messageSource.next(message)
  }

  getMonthsList(currentYearAndMonth) {
    let { currentYear, currentMonth, yearRange } = currentYearAndMonth
    let monthsList: any = []
    for (let year = currentYear - yearRange; year <= currentYear + yearRange; year++) {
      for (let month = 0; month < 12; month++) {
        monthsList.push({ label: moment([year, month, 1]).format('MMM'), value: month + 1, year: year, isActive: year === currentYear && (month + 1) === currentMonth ? true : false })
      }
    }
    return monthsList
  }

  sanitizeQuery(qparams) {
    let params = new HttpParams()
    Object.keys(qparams).forEach((param) => {
      if (Array.isArray(qparams[param])) {
        !isEmpty(qparams[param]) && qparams[param].forEach((item) => {
          params = params.append(`${param}[]`, item)
        })
      } else if (qparams[param]) {
        params = params.append(param, qparams[param])
      }
    })
    return decodeURIComponent(params.toString())
  }

  getDevelopersData(qparams) {
    const API = environment.apiHiveBase + API_ROUTES.DEVELOPERS_LEAVE;
    return this.httpRequest
      .get(`${API}?${qparams}`, '', this.dataService.getRequestOptionArgs('', true)).pipe(
        map(res => res));
  }

  postNudgeExpert(qparams: INudgeParams) {
    const API = environment.apiHiveBase + API_ROUTES.NUDGE_EXPERT;
    return this.httpRequest
      .post(API, qparams, this.dataService.getRequestOptionArgs('', true)).pipe(
        map(res => res));
  }

  getDays(year: number, month: number): any[] {
    let numberOfDays = moment(`${year}-${month}`, "YYYY-MM").daysInMonth();
    let days = [];
    for (let i = 1; i <= numberOfDays; i++) {
      let initialDay = moment(`${year}-${month}-${i}`, 'YYYY-MM-DD').format('dd')
      days.push({
        dayNumber: moment(`${year}-${month}-${i}`, 'YYYY-MM-DD').format('D'),
        initial: initialDay,
        date: `${year}-${month}-${i}`
      });
    }
    return days;
  }

}
