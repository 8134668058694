import {Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { PolicyService } from '../../shared/services/policy.service';
import { ProjectService } from '../../shared/services/project.service';
import { NotificationService } from '../../shared/services/notification.service';
import { ErrorService } from '../../shared/errorFunction';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {CustomToastService} from '../../shared/services/custom-toast.service';
import {Project} from "../../shared/models/project";
import {DevicePlatformData, EVENT_TYPES} from '../../shared/dataTypes';
import {Sprint} from "../../shared/models/sprint";
import moment from 'moment';
import {ProjectSyncService} from "../../shared/services/projectSync.service";
import {Story} from "../../shared/models/story";
import {User} from "../../shared/models/user";
import {UserService} from "../../shared/services/user.service";
import {Membership} from "../../shared/models/membership";
import {Epic} from "../../shared/models/epic";
import { SprintService } from '../../shared/services/sprint.service';
import {EpicService} from "../../shared/services/epic.service";
import {StoryService} from "../../shared/services/story.service";
import {SharedService} from "../../shared/services/shared.service";
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-sprint',
  templateUrl: './sprint.component.html',
  styleUrls: ['./sprint.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({
          opacity:'0',
          top: '50px'
        }),
        animate(200, style({
          opacity:'1',
          top: '40px'
        }))
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate(200, style({
          opacity:'0',
          top: '50px'
        }))
      ])
    ]),
    trigger('fadeInOut2', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({
          opacity:'0',
          top: '30px'
        }),
        animate(100, style({
          opacity:'1',
          top: '21px'
        }))
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate(100, style({
          opacity:'0',
          top: '30px'
        }))
      ])
    ])
  ],
  host: {
    '(document:click)': 'onClick($event)'
  }
})
export class SprintComponent implements OnInit {
  @ViewChild('makeFocusName') makeFocusName: ElementRef;
  @ViewChild('makeFocusDesc') makeFocusDesc: ElementRef;
  @ViewChild('makeFocusTitle') makeFocusTitle: ElementRef;
  project: Project;
  projectId?: number;
  currentUser: User;
  sprints: Array<Sprint> = [];
  openedMoreActionSprint: Sprint;
  sprint_start_data: Sprint;
  sprintActionType = '';
  toastMessage = '';
  showSort:boolean = false;
  openFeatureListPopUp: boolean = false;
  showfilterList:boolean = false;
  showSelectedFilterType: String = 'phase';
  showBacklog:boolean = false;
  showSprint:boolean = true;
  showAddSprint:boolean = false;
  createOrUpdateSprint: Sprint;
  startLoader:boolean = false;
  temp_name = '';
  temp_description = '';
  enableUpdateButton:boolean = false;
  disableStartDate:boolean = false;
  disableEndDate:boolean = false;
  editingSprint:boolean = false;
  story: Story;
  membersListElements: any;
  waitForLoader:boolean = false;
  archivrStory: Story;
  whereFrom = 'from_sprint';
  memberships: Array<Membership> = [];
  epics: Array<Epic> = [];
  labels: Array<any> = [];
  formatted_platforms?: Array<any>;
  status?: string;
  projectDevicePlatformsData?: Array<DevicePlatformData>;
  searchMemberForFilter: String = '';
  isPending: boolean;
  sprintsMeta: {
    current_page: number,
    next_page: number,
    per_page: number,
    total_count: number
  };
  params = {
    q: '',
    page: 1,
    timezone: '',
  };
  sort_by = '';
  searchStoryData = '';
  sprintStartActionPopUp:boolean = false;
  canStartSprint:boolean = false;
  canCompleteSprint:boolean = false;
  sprintCompletetActionPopUp:boolean = false;
  sprintPausePopUp:boolean = false;
  @ViewChild('expand') expand: ElementRef;
  initial_page_no = 1;
  popupFor:string = '';
  showSortBy:boolean = false;
  sortByOpen = false;
  storyListLoader: boolean = false;
  private notificationSubscriptions: Subscription[] = [];
  sprintselectionDropdown = false;
  moveStoriesToSprint = false;
  spillOverStoryCount: number;
  sprintMoveOptions = [];
  selectedSprintForMove = [];

  constructor(public projectService: ProjectService,
              private errorService: ErrorService,
              public policyService: PolicyService,
              private epicService:EpicService,
              private notificationService: NotificationService,
              private customToast: CustomToastService,
              private router: Router,
              private userService: UserService,
              private projectSyncService: ProjectSyncService,
              private storyService: StoryService,
              private sharedService: SharedService,
              private activatedRoute: ActivatedRoute,
              public sprintService: SprintService) { }


  ngOnInit() {
    this.sort_by = this.getSortBy();
    const queryIndex = window.location.href.indexOf('?');
    const queryUrl = window.location.href.substr(queryIndex + 1);
    const registerQueryParam = queryUrl.substr(0, queryUrl.indexOf('='));
    const registerQueryParamValue = queryUrl.substr(queryUrl.indexOf('=') + 1);
    if (registerQueryParam === 'storyId') {
      this.showBacklog = true;
      this.showSprint = false;
    }

    this.projectService.initiate_from = 'from_sprint';
    this.projectService.initialLoader = true;
    this.activatedRoute.params.subscribe((params: Params) => {
      this.projectId = +params['id'];
    });
    this.projectSyncService.subscribe(this);
    this.currentUser = this.userService.getUser();
    this.projectService.isRefresh = true;
    this.projectService.project(this.projectId, true).subscribe(project => {
      this.project = project;
      if (registerQueryParam === 'storyId') {
        this.projectService.show_StoryDetails(this.project, '', registerQueryParamValue, false, this.whereFrom);
      }
      this.notificationService.broadcast(EVENT_TYPES.PROJECT.SHOW, {
        data: {
          project: this.project
        }
      });
      this.project.memberships = [];
      if (this.projectService.current_user_role === 'read_only') {
        this.router.navigate(['/projects/' + this.project.id]);
        return;
      }
      this.projectService.loadUsers(this.project);
      this.project.phases = [];
      this.project.tracker_phases = [];
      if (this.project && !this.project.dashboard_project_id) {
        this.project.phases = ['Product Roadmap', 'Design', 'Prototype', 'MVP', 'Full Build'];
        this.project.phases.forEach(item => {
          const tracker_phase = {
            'tracker_phase_name': item,
            'phase_name': item
          };
          this.project.tracker_phases.push(tracker_phase);
        });
      } else {
        this.projectService.projectPhasesNBrief(this.project).subscribe(res => {
          this.project.internal_mattermost_channel_url = res.data.internal_mattermost_channel_url;

          for (let i = 0; i < res.data.trackerphasesettings.length; i++) {
            this.project.phases.push(res.data.trackerphasesettings[i].phase_name);

            for (let j = 0; j < res.data.trackerphasesettings[i].tracker_phases.length; j++) {
              const tracker_phase = {
                'tracker_phase_name': res.data.trackerphasesettings[i].tracker_phases[j],
                'phase_name': res.data.trackerphasesettings[i].phase_name
              };
              this.project.tracker_phases.push(tracker_phase);
            }
          }
        }, (error) => {
          this.project.internal_mattermost_channel_url = null;
        });
      }
      this.loadEpics();
      this.projectService.project_labels(this.project).subscribe(data => {
        this.project.labels = data;
      }, (error) => {
        this.errorService.errorFunction(error);
      });

    }, err => this.errorService.errorFunction(err));
    this.getSprints();
    const sprintAddedEvent = this.notificationService.subscribeEvent(EVENT_TYPES.SPRINT.SPRINT_CREATED, res => {
      this.sprints.unshift(new Sprint(res.payload.sprint));
    });
    this.notificationSubscriptions.push(sprintAddedEvent);

    const sprintDeletedEvent = this.notificationService.subscribeEvent(EVENT_TYPES.SPRINT.SPRINT_DELETED, res => {
      if(res) {
        const sprint_Index = this.sprints.findIndex(st_sprint_index => st_sprint_index.id === res.payload.sprint.id);
        if(sprint_Index !== -1) {
          this.sprints.splice(sprint_Index, 1);
        }
      }
    });
    this.notificationSubscriptions.push(sprintDeletedEvent);

    const sprintUpdatedEvent = this.notificationService.subscribeEvent(EVENT_TYPES.SPRINT.SPRINT_UPDATED, res => {
      this.updateSprintWithWebsocket(res);
      if (res.payload.sprint.status === 'completed') {
        const index = this.sprintMoveOptions.findIndex(sprint => sprint.id === res.payload.sprint.id)
        if (index !== -1 ) {
          this.sprintMoveOptions.splice(index,1)
        }
      }
    });
    this.notificationSubscriptions.push(sprintUpdatedEvent);

    this.notificationService.subscribeEvent(EVENT_TYPES.SPRINT.SPRINT_STORY_ADDED, res => {
      this.updateSprintWithWebsocket(res);
    });

    this.notificationService.subscribeEvent(EVENT_TYPES.SPRINT.SPRINT_STORY_UPDATED, res => {
      this.updateSprintWithWebsocket(res, true);
    });

    this.notificationService.subscribeEvent(EVENT_TYPES.SPRINT.SPRINT_STORY_REMOVED, res => {
      this.updateSprintWithWebsocket(res);
    });
  }

  updateSprintWithWebsocket(res, sprint_story_updated?) {
    const sprint_Index = this.sprints.findIndex(st_sprint_index => st_sprint_index.id === res.payload.sprint.id);
    if (sprint_story_updated) {
      this.sprints[sprint_Index].stories_count = res.payload.sprint.stories_count;
      this.sprints[sprint_Index].stories_status = res.payload.sprint.stories_status;
    } else {
      this.sprints[sprint_Index] = new Sprint(res.payload.sprint);
    }
  }

  getOlder() {
    this.isPending = true;
    if (this.sprintsMeta && this.sprintsMeta.next_page) {
      this.params.page = this.sprintsMeta.next_page;
      this.getSprints();
    }
  }

  onClick(event) {
    const element = event.target;
    if (!(element.classList.contains('storyaction-wrapper') || element.classList.contains('storyaction-option'))) {
      if (this.openedMoreActionSprint) {
        this.openedMoreActionSprint.showMoreAction = false;
        this.openedMoreActionSprint = null;
      }
    }
    if (!(element.classList.contains('sprint-sorting-options')) && !(element.classList.contains('selectedFilter')) && !(element.classList.contains('iconb-sorting'))){
      this.showSortBy = false;
    }
    
  }

  getSprints(replace?: boolean) {
    if (replace) {
      this.params['page'] = 1;
    }
    if (this.sort_by) {
      this.params['sort_by'] = this.sort_by;
    }
    this.projectService.getProjectSprints(this.projectId, this.params).subscribe(res => {
      if (res ) {
        this.isPending = false;
        if (replace) {
          this.sprints = [];
        }
        res.data.forEach((sprintData) => {
          this.sprints.push(new Sprint(sprintData));
        });
        this.storyListLoader = false;
        this.sprintsMeta = res.meta;
        this.projectService.initialLoader = false;
      }
    }, err => this.errorService.errorFunction(err));
  }

  checkifSearchInputEmpty() {
    if (this.params.q === '') {
      this.getSprints(true);
    }
  }

  isScrollFetchAvailable(): boolean {
    return !this.isPending && this.sprintsMeta && this.sprintsMeta.next_page != null;
  }

  ngOnDestroy() {
    this.projectSyncService.unsubscribe();
    this.projectService.initiate_from = '';
    this.projectService.sprintFilter = false;
    this.notificationSubscriptions.forEach(subscription => subscription.unsubscribe());
  }

  showSprintOptions() {
    this.showBacklog = false;
    this.showSprint = true;
  }

  showBacklogOptions() {
    setTimeout(() => {
      this.showBacklog = true;
      this.showSprint = false;
    }, 0);
  }

  openSort() {
    this.showSort = !this.showSort;
  }

  openFeaturelist() {
    this.openFeatureListPopUp = true;
  }

  toggelefilter() {
    this.showfilterList = !this.showfilterList;
    if (this.showfilterList) {
      this.userService.trackSegmentEvent('Filter Open', this.projectService.getCommonPropsForStoryBoardEvents(this.project));
    }
  }

  openMoreStoryAction(story) {
    story.showMoreStoryAction = !story.showMoreStoryAction;
  }

  openMoreAction(event, sp_data) {
    event.stopPropagation();
    sp_data.showMoreAction = !sp_data.showMoreAction;
    if (!sp_data.showMoreAction && this.openedMoreActionSprint) {
      this.openedMoreActionSprint.showMoreAction = false;
      this.openedMoreActionSprint = null;
    }
    this.openedMoreActionSprint = sp_data;
  }

  gotoSprintDetail(event, sp_data) {
    if (sp_data.showInput || sp_data.showDescInput || sp_data.showMoreAction || this.sprintService.editSprint) {
      event.stopPropagation();
    } else {
      this.router.navigate(['/projects/' + this.project.id + '/sprint/' + sp_data.id]);
    }
  }

  addSprintDetails() {
    this.popupFor = 'add_sprint';
    this.sprintService.editSprint = true;
  }

  editSprintDetails(sprint) {
    this.popupFor = 'edit_sprint';
    this.createOrUpdateSprint = new Sprint(sprint);
    this.sprintService.editSprint = true;
  }

  getDateFormat(date_generic) {
    return moment(date_generic).format('DD/MM/YYYY');
  }

  showEditInput(sp_data) {
    sp_data.showInput = true;
    this.temp_name = sp_data.name;
    setTimeout(() => {
      this.makeFocusName.nativeElement.focus();
    }, 0);
  }

  showEditDescritption(sp_data) {
    sp_data.showDescInput = true;
    this.temp_description = sp_data.description;
    setTimeout(() => {
      this.makeFocusDesc.nativeElement.focus();
    }, 0);
  }
  
  updateSprint(sp_data) {
    sp_data.showDescInput = false;
    sp_data.showInput = false;
    this.projectService.updateSprint(sp_data, this.projectId).subscribe((res: any) => {
      this.customToast.messages.push({
        id: 'sprintUpdate', type: 'success',
        class: 'new_member_added',
        title: 'Sprint Update',
        message: sp_data.showInput ? 'Sprint name updated successfully' : 'Sprint goal updated successfully'
      });    
    }, (error) => {
      sp_data.name = this.temp_name;
      sp_data.desription = this.temp_name;
      this.errorService.errorFunction(error);
    });
  }

  updateSprintName(sp_data){
    if (this.temp_name === sp_data.name) {
      sp_data.name = this.temp_name;
      sp_data.showInput = false;
      return;
    }
    this.updateSprint(sp_data)
  }

  updateSprintGoal(sp_data){
    if (this.temp_description === sp_data.description) {
      sp_data.desription = this.temp_name;
      sp_data.showDescInput = false;
      return;
    }
    this.updateSprint(sp_data)
  }
  
  openStartSprintConfirmation(sp_data, actionType) {
    this.sprint_start_data = sp_data;
    if (!this.sprint_start_data.projected_start_date) {
      this.sprintService.enterDateAlertPopup = true;
      return;
    }
    this.sprintActionType = actionType;
    this.sprintStartActionPopUp = true;
    this.canStartSprint = (actionType === 'start_sprint' && this.sprint_start_data.stories_count > 0);
  }

  closeStartSprintConfirmation() {
    this.sprintStartActionPopUp = false;
    this.canCompleteSprint = false;
    this.sprint_start_data = null;
    this.sprintActionType = '';
  }

  openCompleteSprintConfirmation(sp_data, actionType) {
    this.sprintActionType = actionType;
    this.sprint_start_data = sp_data;
    const  params = {
      sprint_id: sp_data.id
    };
    this.projectService.getProjectSprints(this.projectId,params).subscribe(res => {
      if (res ) {
        this.spillOverStoryCount = res.meta.remainig_story_count
        this.sprintMoveOptions = res.data;
        this.spillOverStoryCount > 0 && res.data.length ? this.moveStoriesToSprint = true : this.sprintCompletetActionPopUp = true;
        this.selectedSprintForMove = [];
        this.canCompleteSprint = (actionType === 'completed' && (sp_data.stories_count === sp_data.stories_status.accepted)) ? true : false;
      }
    }, err => {
      this.errorService.errorFunction(err)
      this.closeMoveStoriesToSprint();
    });
  }

  closeCompleteSprintConfirmation() {
    this.sprintCompletetActionPopUp = false;
    this.canCompleteSprint = false;
    this.sprint_start_data = null;
    this.sprintActionType = '';
  }

  openPauseSprintConfirmation(sp_data, actionType) {
    this.sprint_start_data = sp_data;
    this.sprintActionType = actionType;
    this.sprintPausePopUp = true;
  }

  closePauseSprintConfirmation() {
    this.sprintPausePopUp = false;
    this.sprintActionType = '';
    this.sprint_start_data = null;
  }

  actionSprint(sp_data, actionType: String) {
    this.waitForLoader = true;
    sp_data.showMoreAction = false;
    this.openedMoreActionSprint = null;
    let sprintIndex = this.sprints.findIndex(st_sprint_index => st_sprint_index.id === sp_data.id);
    if (actionType === 'start_sprint') {
      const startDate: Date = new Date(this.sprint_start_data.projected_start_date);
      const endDate: Date = new Date(this.sprint_start_data.projected_end_date);
      const currentDate: Date = new Date;
      if (startDate > currentDate) {
        this.sprint_start_data.projected_start_date = this.sprint_start_data.getDateFormat(moment()); 
      }
      this.sprints[sprintIndex].status = 'running';
      this.toastMessage = 'Sprint started successfully';
    }
    if (actionType === 'paused') {
      this.sprints[sprintIndex].status = 'paused';
      this.toastMessage = 'Sprint paused successfully';
      this.sprintPausePopUp = false;
    }
    if (actionType === 'completed') {
      if (this.sprint_start_data.futureEndDate(this.sprint_start_data.projected_end_date)) {
        this.sprint_start_data.projected_end_date = this.sprint_start_data.getDateFormat(moment());
      }
      this.sprints[sprintIndex].status = 'completed';
      this.toastMessage = 'Sprint completed successfully';
    }
    this.projectService.updateSprint(this.sprints[sprintIndex], this.projectId).subscribe((res: any) => {
      this.sprintStartActionPopUp = false;
      this.sprintCompletetActionPopUp = false;
      this.waitForLoader = false;
      this.sprint_start_data = null;
      this.customToast.messages.push({
        id: 'sprintUpdate', type: 'success',
        class: 'new_member_added',
        title: 'Sprint Update',
        message: this.toastMessage
      });
      this.sprintActionType = '';
      this.toastMessage = '';
    }, (error) => {
      this.sprintActionType = '';
      this.sprintStartActionPopUp = false;
      this.sprintCompletetActionPopUp = false;
      this.waitForLoader = false;
      this.sprint_start_data = null;
      this.sprintActionType = '';
      this.errorService.errorFunction(error);
      const err = JSON.parse(error._body);
    });
  }

  storyProgress(no_stories, total_count) {
    const individual_p = (no_stories * (100/total_count));
    return individual_p;
  }

  loadEpics() {
    this.epicService.projectEpics(this.project)
      .subscribe((epics: Array<Epic>) => {
        this.project.addEpics(epics);
        if (this.project && this.project.dashboard_project_id != null) {
          this.loadAllFeaturedetails();
        }
        const payload = {
          data: {
            epics: this.project.epics
          }
        };
        this.notificationService.broadcast(EVENT_TYPES.EPIC.UPDATED, payload);
      }, (error) => {
        this.errorService.errorFunction(error);
      });
  }

  loadAllFeaturedetails() {
    this.epicService.allFeatureDetails(this.project).subscribe(res => {
      if (res && res.data && res.data.length > 0) {
        res.data.forEach(item => {
          const ind = this.project.epics.findIndex(obj => obj.builder_feature_id === item.builder_feature_id);
          if (ind >= 0) {
            this.project.epics[ind].featureDetailsData = item;
          }
        });
      }
    });
  }

  getSortBy() {
    return localStorage.getItem('sort_by') ? localStorage.getItem('sort_by') : 'based_on_status';
  }

  openSortBy() {
    this.showSortBy = !this.showSortBy;
  }

  sortStoryList(value) {
    this.sortByOpen = false;
    this.storyListLoader = true;
    this.sort_by = value;
    localStorage.setItem('sort_by', value);
    this.isPending = true;
    this.storyService.searchStoriesVal = this.searchStoryData;
    this.getSprints(true);
  }

  closeMoveStoriesToSprint() {
    this.moveStoriesToSprint = false;
    this.sprintselectionDropdown = false; 
  }

  sprintselection() {
    this.sprintselectionDropdown = !this.sprintselectionDropdown; 
  }

  selectSprintForMove(sprint){
    this.selectedSprintForMove = sprint;
    this.sprintselectionDropdown = false;
  }

  moveStoriesAndCompleteSprint(sprint,projectID,nextSprintId){
    if (this.selectedSprintForMove.length === 0) {
      return;
    }
    if (this.sprint_start_data.futureEndDate(this.sprint_start_data.projected_end_date)) {
      this.sprint_start_data.projected_end_date = this.sprint_start_data.getDateFormat(moment());
    }
    sprint.status = 'completed';
    this.projectService.moveStoriesAndCompleteSprint(sprint,projectID,nextSprintId).subscribe(res => {
      this.closeMoveStoriesToSprint();
      this.customToast.messages.push({
        id: 'sprintUpdate',
        type: 'success',
        class: 'new_member_added',
        title: 'Sprint Update',
        message: res.message
      });
    }, err => this.errorService.errorFunction(err));
  }

  openSprintEdit(){
    this.sprintService.enterDateAlertPopup = false;
    this.editSprintDetails(this.sprint_start_data);
  }

}
