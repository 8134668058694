import {
    Component
} from '@angular/core';


@Component({
    selector: 'copied',
    template: `
    <h2 class="popover-title">Copied</h2>
    `
})
export class CopiedComponent {
}