import { Component, OnInit, Input } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import {
  EVENT_TYPES,
  HEADER_CONTEXT_TYPES,
  HeaderContextType
} from '../../shared/dataTypes';

import {
  Project
} from '../../shared/models/project';

import { PolicyService } from '../../shared/services/policy.service';
import { NotificationService } from '../../shared/services/notification.service';
import { ProjectService } from '../../shared/services/project.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import { User } from '../../shared/models/user';
import { UserService } from '../../shared/services/user.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'project-navbar',
  templateUrl: 'navbar.component.html',
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({
          opacity:'0',
          transform: 'translateX(30px)'
        }),
        animate(200, style({
          opacity:'1',
          transform: 'translateX(0px)'
        }))
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate(200, style({
          opacity:'0',
          transform: 'translateX(30px)'
        }))
      ])
    ])
  ],
})
export class ProjectNavbarComponent implements OnInit {
  project: Project;
  isOpened: boolean = true;
  contextType: HeaderContextType;
  isPlanningDDon:boolean = false;
  sprintPage:boolean = false;
  projectId?: number;
  sprintId?: number;
  mouseOvered:boolean = false;
  currentUser: User;

  constructor(private notificationService: NotificationService,
      private projectService: ProjectService,
      private activatedRoute: ActivatedRoute,
      private router: Router,
      private userService: UserService,
      private policyService: PolicyService) {}  

  get policiesActive(): boolean {
    return this.policyService.policiesActive && !!this.project;
  }

  get isAdmin(): boolean {
    return this.policyService.isAdmin;
  }

  get isProjectAdmin(): boolean {
    return this.policyService.isProjectAdmin(this.project);
  }
  get isProjectReadonly(): boolean {
    return this.policyService.isProjectReadonly(this.project);
  }

  ngOnInit() {
    const queryIndex = window.location.href.indexOf('/sprint');
    const queryIndex_release = window.location.href.indexOf('/releases');
    if (queryIndex > -1 || queryIndex_release > -1) {
      this.isPlanningDDon = true;
    }

    this.project = this.projectService.currentProject;
    this.currentUser = this.userService.getUser();
    
    if (this.project) {
      this.isOpened = true;
    }

    this.notificationService.subscribeEvent(EVENT_TYPES.PROJECT.NEW_CURRENT, data => {
      this.project = this.projectService.currentProject;
    });
  }

  canUpdate() {
    return this.policyService.canUpdateProject(this.project);
  }

  openPlanningDD() {
    this.isPlanningDDon = !this.isPlanningDDon;
  }

  getUIWUrl(){
    return environment.designerUrl + '/uiworkspace/flowchart';
  }

}
