import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class QueryParamsService {

  constructor(private router: Router) {}

  set(params) {
    this.router.navigate([], {queryParams: params, replaceUrl: true});
  }
}
