<div class="trackerContent-wrapper" *ngIf = "!userService.maintenance">
  <div class="trackerNav" *ngIf = "checkWindowUrlForNavigation('trackerNav')">
    <header-bar class="trackerNav-block"></header-bar>
    <router-outlet name="navbar"></router-outlet>
  </div>
  <div class="trackerContent" [ngClass]="{'makeFullSize': checkWindowUrlForNavigation('trackerContent')}">
    <div class="topNavigation" *ngIf = "checkWindowUrlForNavigation('topNavigation')">
      <div class="topLeftNavigation">
        <div class="breadAndCrumbs"  *ngIf="projectService.currentProject && isProjectContext()">
          <span class="breadAndCrumbs-link" routerLink="/">Buildcards</span>
          <span class="breadAndCrumbs-link ifProjectFav" [routerLink]="['/projects', projectService.currentProject.id]" (click)="this.projectService.hide_StoryDetails_if_it_is_open()">{{projectService.currentProject.name}}</span>
          <span class="breadAndCrumbs-link" *ngIf = "isProjectImportContext()" [routerLink]="['/projects', projectService.currentProject.id, '/import']"><span class="innerText">Import</span></span>
          <span class="breadAndCrumbs-link" *ngIf = "isProjectBriefContext()" [routerLink]="['/projects', projectService.currentProject.id, '/brief']"><span class="innerText">Brief</span></span>
          <span class="breadAndCrumbs-link" *ngIf = "isProjectDashboardContext()" [routerLink]="['/projects', projectService.currentProject.id, '/dashboard']"><span class="innerText">Members</span></span>
          <span class="breadAndCrumbs-link" *ngIf = "isProjectSettingContext()" [routerLink]="['/projects', projectService.currentProject.id, '/settings']"><span class="innerText">Settings</span></span>
          <span class="breadAndCrumbs-link" *ngIf = "isProjectSettingContext()" [routerLink]="['/projects', projectService.currentProject.id, '/releases']"><span class="innerText">Releases</span></span>
          <span class="makeFavorite" [ngClass]="{'iconb-star-outline': !projectService.currentProject.favorite , 'iconb-star-fill': projectService.currentProject.favorite}" (click)="toggle_favourite(projectService.currentProject)">
            <span class="elementHover" *ngIf="!projectService.currentProject.favorite">Add to favorites</span>
            <span class="elementHover" *ngIf="projectService.currentProject.favorite">Remove from favorites</span>
          </span>
        </div>
        <div class="breadAndCrumbs"  *ngIf="router.url === '/help-support'">
          <span class="breadAndCrumbs-link" routerLink="/">Dashboard</span>
          <span class="breadAndCrumbs-link">Help</span>
        </div>
        <div class="project-alias-info-wrapper" *ngIf="projectService.currentProject?.alias_name && isProjectContext()">
          <span class="alias-tag">Alias</span>
          <p class="alias-text">{{projectService.currentProject?.alias_name}}</p>
        </div>
      </div>
      <div class="breadAndCrumbs"  *ngIf="router.url === '/story-approval'">
        <span class="breadAndCrumbs-link" routerLink="/">Dashboard</span>
        <span class="breadAndCrumbs-link">Story Approvals</span>
      </div>
      <notifications-dropdown class="userNotification"></notifications-dropdown>
      <span class="addNewuser" (click)="addMember()"
            *ngIf="isAdmin && projectService.currentProject && router.url==='/projects/'+this.projectService.currentProject.id">
        <em class="iconb-add-member"></em>
        <span class="elementHover">Add Member</span>
      </span>
      <div class="usersInitals-wrapper" (click)="openUserlist()" *ngIf="projectService.show_users && projectService.currentProject && router.url==='/projects/'+this.projectService.currentProject.id">
        <div class="userInitials-block">
          <div class="usersInitials" *ngFor="let member of projectService.currentProject.memberships">
            {{ member.user.initials?.substr(0,1) }}
            <span class="elementHover">{{member.user.name ? (member.user.name | titlecase) : member.user.email}}</span>
          </div>
          <div class="usersInitials" *ngIf="projectService.currentProject.memberships.length > 3">+{{projectService.currentProject.memberships.length - 3}}</div>
        </div>
        <div class="userListDD" [ngClass]="{'userListDD-open': showCurrentUserList}">
          <div class="userList-infoWrapper" [ngClass] = "{'userNotActive': !member.active || member.user.deleted_at}" *ngFor="let member of projectService.currentProject.memberships">
            <span class="userList-initials">{{ member.user.initials?.substr(0,1) }}</span>
            <span class="userList-name">{{member.user.name ? (member.user.name | titlecase) : member.user.email}}</span>
            <span class="ifUserDeallocated" *ngIf="!member.active || member.user.deleted_at">{{ member.user.deleted_at ? 'Deactivated' : 'Deallocated' }}</span>
          </div>
        </div>
      </div>
    </div>
    <router-outlet></router-outlet>
  </div>
  <span defaultOverlayTarget></span>
  <div class="custom-toaster" [ngClass]="{'visiblecustom-toaster': customToast.messages.length > 0}">
    <app-toastdisplay class="showToastDisplay" [ngClass]="{'showCustomToaster': customToast.messages.length > 0}"  *ngFor = "let data of customToast.messages" [data] = "data"></app-toastdisplay>
  </div>
</div>
<div *ngIf = "userService.maintenance" class="maintenancePage">
  <span class="maintenanceIcon">
    <img src="../assets/images/maintenanceIcon.svg" alt="Maintenace" />
  </span>
  <h1>We're under maintenance!!</h1>
  <p>We’re constantly working towards making this a better product. Sincere apologies for the inconvenience. We’ll be back online soon.</p>
  <p class="mb-3" *ngIf="userService.maintenanceData">{{ userService.maintenanceData?.description }}</p>
  <p>Feel free to reach out to us at <a href="mailto:support@builder.ai" style="color: #fff; text-decoration: underline;">support@builder.ai</a></p>
</div>

<div class="global-notification-strip" *ngIf="userService.maintenanceData?.notify && userService.showDowntimeNotification">
  <button type="button" class="close-btn pull-right" (click)="userService.showDowntimeNotification = false">
    <em class="iconb-closeSmall"></em>
  </button>
  <em class="info-icon icon-info-circle"></em>
  <p>{{ userService.maintenanceData?.description }}</p>
</div>

<div [@makeSlide] *ngIf = "versionUpdate" class="version-toast">
  <em class="iconb-info2"></em>
  <span class="versionText">A new version of Tracker is available! Please </span>
  <button (click) = "versionReload()" class="vesion-update-button" type="button"> Refresh </button>
</div>
<div class="commonPopUp" *ngIf="canDisplayNetOfflineModal === 'offline'">
  <div class="commonPopUp-backdrop"></div>
  <div class="commonPopUp-wrapper">
    <div class="commonPopUp-img"><em class="iconb-offline"></em></div>
    <div class="commonPopUp-text2">You're disconnected from the internet</div>
    <div class="commonPopUp-text3">Check your network connection and try again.</div>
    <div class="commonPopUp-actionBtn">
      <span class="commonPopUp-reload" *ngIf="canDisplayNetOfflineModal === 'online'" (click)="reloadPage()">Refresh</span>
    </div>
  </div>
</div>

