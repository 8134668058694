import { Pipe, PipeTransform } from "@angular/core";
import { orderBy } from "lodash";
@Pipe({
  name: "sortByDevice",
  pure: false,
})
export class SortByDevice implements PipeTransform {
  transform(items: any[], type?: string): any[] {
    if (!items) return [];
    return items.sort((a: any, b: any) => {
      if (a.device < b.device) {
        return -1;
      }
      if (a.device > b.device) {
        return 1;
      }
      return 0;
    });
  }
}
