import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { StoryApprovalService } from '../../services/story-approval.service';
import { CustomToastService } from '../../services/custom-toast.service';
import { ErrorService } from '../../errorFunction';
import { UpdatedStoryModel } from '../../models/updatedStoryModel';
import { CmtStoryModel } from '../../models/cmtStoryModel';
import { SharedService } from '../../services/shared.service';
import * as DecoupledEditor from '../../../../assets/js/ck-editor/ckeditor.js';
import { Observable, forkJoin, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { isEmpty } from 'lodash';
@Component({
  selector: 'app-story-compare-popup',
  templateUrl: './story-compare-popup.component.html',
  styleUrls: ['./story-compare-popup.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({
          opacity: '0',
          transform: 'translateY(-20px)'
        }),
        animate(100, style({
          opacity: '1',
          transform: 'translateY(0)'
        }))
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate(100, style({
          opacity: '0',
          transform: 'translateY(-20px)'
        }))
      ])
    ])
  ],
  host: {
    '(document:click)': 'onClick($event)',
  }
})
export class StoryComparePopupComponent implements OnInit {
  @Input() selectedStory;
  @Output() showCompareChange = new EventEmitter<boolean>();
  @Output() syncStory = new EventEmitter<boolean>();
  @Input() isUpdateAvailable;
  @Input() has_story_data;
  currentUserDesignSquad: boolean = false;
  storyCompare: UpdatedStoryModel;
  cmtStory: CmtStoryModel;
  showCopiedID:boolean = false;
  waitForLoader:boolean = false;
  reviewActionConfirmation:boolean = false;
  action_on_story: any;
  statusDropdown: boolean = false;
  comparePopupStartLoader:boolean = false;
  showCompare:boolean = false;
  syncActionConfirmation:boolean = false;
  reasonForRejection: string = ''
  public viewDescriptionEditor = DecoupledEditor;
  // Editor option for view mode.
  view_editor_options = {
    image: {
      styles: [
        // This option is equal to a situation where no style is applied.
        'full',

        // This represents an image aligned to the left.
        'alignLeft',

        // This represents an image aligned to the right.
        'alignRight'
      ]
    },
    placeholder: 'Enter Additional Description',
  };
  text_added = 0;
  text_removed = 0;
  deviceActionsVisible:boolean = false;
  devicePreviewVisible:boolean = false;
  openRejectionReasonPopup:boolean = false;
  isEmpty = isEmpty

  constructor(
    public  storyApprovalService: StoryApprovalService,
    private errorService: ErrorService,
    public sharedService: SharedService,
    private customToast: CustomToastService) { }

  ngOnInit() {
    this.comparePopupStartLoader = true;
    if(this.has_story_data) {
      this.has_story_data['epic'] = this.has_story_data.epics[0]; 
      this.has_story_data['acceptance_criteria_approvals']= this.has_story_data.acceptance_criteria;
      this.storyCompare = new UpdatedStoryModel(this.has_story_data);
      this.comparePopupStartLoader = false;
      this.getCMTstory(this.has_story_data).subscribe(
        () => {
          this.storyDiff();
        },
        error => {
          this.errorService.errorFunction(error);
        }
      );
    } else{ 
      forkJoin([
        this.getTrackerStory(this.selectedStory),
        this.getCMTstory(this.selectedStory)
      ]).subscribe(
        ([trackerStory, cmtStory]) => {
          this.storyDiff();
          this.comparePopupStartLoader = false;
        },
        error => {
          this.errorService.errorFunction(error);
          this.comparePopupStartLoader = false;
        }
      );
    }
  }

  ngOnChanges() {
    if(this.storyCompare && this.isUpdateAvailable) {
      this.storyCompare.updateComparePopup(this.isUpdateAvailable);
    }
  }

  compareStoryFields() {
    const titleDiff = this.storyApprovalService.calculateWordDiff(this.cmtStory.title, this.storyCompare.title);
    const descriptionDiff = this.storyApprovalService.calculateWordDiff(this.cmtStory.description, this.storyCompare.description);
    const initialDiff = { additions: 0, deletions: 0, modifiedHtml: '', originalHtml: '' };
    let as_aDiff, i_wantDiff, so_thatDiff;
    if (!this.storyCompare.parent_id) {
      as_aDiff = this.storyApprovalService.calculateWordDiff(this.cmtStory.as_a, this.storyCompare.description_as_a);
      i_wantDiff = this.storyApprovalService.calculateWordDiff(this.cmtStory.i_want, this.storyCompare.description_i_want);
      so_thatDiff = this.storyApprovalService.calculateWordDiff(this.cmtStory.so_that, this.storyCompare.description_so_that);
    } else {
      as_aDiff = { ...initialDiff };
      i_wantDiff = { ...initialDiff };
      so_thatDiff = { ...initialDiff };
    }
    this.text_added = titleDiff.additions + as_aDiff.additions + i_wantDiff.additions + so_thatDiff.additions + descriptionDiff.additions;
    this.text_removed = titleDiff.deletions + as_aDiff.deletions + i_wantDiff.deletions + so_thatDiff.deletions + descriptionDiff.deletions;
    this.storyCompare.updateDiffStoryFields(titleDiff, as_aDiff, i_wantDiff, so_thatDiff, descriptionDiff);
    this.cmtStory.updateDiffCMTStoryFields(titleDiff, as_aDiff, i_wantDiff, so_thatDiff, descriptionDiff);
  }

  acceptanceCriteriaDiff(acceptance_criteria_list, action_type) {
    let text_added_count = 0;
    let text_removed_count = 0;
    let acceptance_titleDiff;
    let acceptance_given;
    let acceptance_when;
    let acceptance_then;
    
    acceptance_criteria_list.forEach((tracker_criteria) => {
      let matchedCMTData = this.cmtStory.acceptance_criteria.find((cmt_criteria) => cmt_criteria.id === tracker_criteria.cmt_acceptance_criteria_id);
      if(action_type === 'delete_criteria_list') {
        acceptance_titleDiff = this.storyApprovalService.calculateWordDiff(tracker_criteria.title, '');
        acceptance_given = this.storyApprovalService.calculateWordDiff(tracker_criteria.given, '');
        acceptance_when = this.storyApprovalService.calculateWordDiff(tracker_criteria.when, '');
        acceptance_then = this.storyApprovalService.calculateWordDiff(tracker_criteria.then, '');
      } else {
        acceptance_titleDiff = this.storyApprovalService.calculateWordDiff(matchedCMTData ? matchedCMTData.title : '', tracker_criteria.title);
        acceptance_given = this.storyApprovalService.calculateWordDiff(matchedCMTData ? matchedCMTData.given : '', tracker_criteria.description_for_given);
        acceptance_when = this.storyApprovalService.calculateWordDiff(matchedCMTData ? matchedCMTData.when : '', tracker_criteria.description_for_when);
        acceptance_then = this.storyApprovalService.calculateWordDiff(matchedCMTData ? matchedCMTData.then : '', tracker_criteria.description_for_then);
      }
      text_added_count = acceptance_titleDiff.additions + acceptance_given.additions + acceptance_when.additions + acceptance_then.additions;
      text_removed_count = acceptance_titleDiff.deletions + acceptance_given.deletions + acceptance_when.deletions + acceptance_then.deletions;
      
      if(action_type !== 'delete_criteria_list') {
        tracker_criteria.title = acceptance_titleDiff.modifiedHtml;
        tracker_criteria.description_for_given = acceptance_given.modifiedHtml;
        tracker_criteria.description_for_when = acceptance_when.modifiedHtml;
        tracker_criteria.description_for_then = acceptance_then.modifiedHtml;
      } else if(action_type === 'delete_criteria_list') {
        tracker_criteria.title = acceptance_titleDiff.originalHtml;
        tracker_criteria.given = acceptance_given.originalHtml;
        tracker_criteria.when = acceptance_when.originalHtml;
        tracker_criteria.then = acceptance_then.originalHtml;
      }
      
      if(matchedCMTData && action_type !== 'delete_criteria_list') {
        matchedCMTData.title = acceptance_titleDiff.originalHtml;
        matchedCMTData.given = acceptance_given.originalHtml;
        matchedCMTData.when = acceptance_when.originalHtml;
        matchedCMTData.then = acceptance_then.originalHtml;
      }
      this.text_added += text_added_count;
      this.text_removed += text_removed_count;
    });
  }

  
  compareAcceptanceCriteriaFields() {
    if(!this.storyCompare.parent_id && this.storyCompare.acceptance_criteria_approvals.length > 0 && this.cmtStory.acceptance_criteria.length > 0) {
      const common_criteria = this.storyCompare.acceptance_criteria_approvals.filter((tracker_criteria) => this.cmtStory.acceptance_criteria.some(((cmt_criteria) => cmt_criteria.id === tracker_criteria.cmt_acceptance_criteria_id)));
      this.acceptanceCriteriaDiff(common_criteria, 'diff_criteria_list');
      const newly_added_criteria = this.storyCompare.acceptance_criteria_approvals.filter((tracker_criteria) => !this.cmtStory.acceptance_criteria.some(((cmt_criteria) => cmt_criteria.id === tracker_criteria.cmt_acceptance_criteria_id)));
      this.acceptanceCriteriaDiff(newly_added_criteria, 'new_criteria_list');
      const criteria_deleted = this.cmtStory.acceptance_criteria.filter((cmt_criteria) => !this.storyCompare.acceptance_criteria_approvals.some(((tracker_criteria) => cmt_criteria.id === tracker_criteria.cmt_acceptance_criteria_id)));
      this.acceptanceCriteriaDiff(criteria_deleted, 'delete_criteria_list');
    }
  }
  
  storyDiff() {
    this.text_added = 0;
    this.text_removed = 0;
    this.compareStoryFields();
    this.compareAcceptanceCriteriaFields();
  }

  // Fetch the story detail from tracker
  getTrackerStory(storyData): Observable<any> {
    if(storyData) {
      return this.storyApprovalService.storyCompare(storyData).pipe(
        map(res => {
          if (res.data) {
            this.storyCompare = new UpdatedStoryModel(res.data);
          }
          // if(res.meta){
          //   this.currentUserDesignSquad = res.meta.squads.includes('design')
          // }
          return res;
        }),
        catchError(error => {
          this.errorService.errorFunction(error);
          return throwError(error);
        })
      );
    }
  }

  // Fetch the story detail from CMT
  getCMTstory(storyData): Observable<any> {
    if (storyData && storyData.catalog_story_id) {
      return this.sharedService.getCMTstory(storyData.catalog_story_id).pipe(
        map(res => {
          if (res) {
            this.cmtStory = new CmtStoryModel(res.user_story);
          }
          return res;
        }),
        catchError(error => {
          this.errorService.errorFunction(error);
          return throwError(error);
        })
      );
    } else {
      return of(null);
    }
  }

  statusDropdownToggle(status,designer_status) {
    if(status === 'waiting' || (status === 'accepted' && designer_status === 'waiting')) {
      this.statusDropdown = !this.statusDropdown;
    } else {
      this.statusDropdown = false;
    }
  }

  copyStoryID() {
    this.showCopiedID = true;
    setTimeout(() => {
      this.showCopiedID = false;
    }, 10000);
  }

  reviewAction(storyCompare, type) {
    this.reviewActionConfirmation = type === 'accepted'|| storyCompare.request_type !== 'request_to_promote'? true : false;
    this.openRejectionReasonPopup = type === 'rejected' && storyCompare.request_type === 'request_to_promote'? true : false
    this.action_on_story = {
      requestIs: type,
      message : {
        title : type === 'accepted' ? 'Accept Changes' : 'Reject Changes',
        text : 'Are you sure you want to ' + (type === 'accepted' ? 'accept' : 'reject') + ' this request?'
      }
    }
  }

  storyReviewAction(compareData, actionType, reasonForRejection) {
    this.waitForLoader = true;
    let statusKey = compareData.status === 'accepted' ? 'designer_status' : 'status'
    
    this.storyApprovalService.actionOnStory(compareData.project_id, compareData.id, actionType,reasonForRejection,statusKey).subscribe(res => {
      this.waitForLoader = false;
      this.reviewActionConfirmation = false
      this.openRejectionReasonPopup = false
      this.action_on_story = {};
      if(actionType === 'accepted') {
        this.customToast.messages.push({
          id: 'story_new',
          type: 'success',
          class: 'story_new',
          title: 'Alert',
          message: 'Story reviewed and accepted.'
        });
      } else {
        this.customToast.messages.push({
          id: 'story_new',
          type: 'success',
          class: 'story_new',
          title: 'Alert',
          message: 'Story reviewed and rejected.'
        });
      }
      this.reasonForRejection = ''
    }, err => {
      this.waitForLoader = false;
      this.reasonForRejection = ''
      this.reviewActionConfirmation = false
      this.openRejectionReasonPopup = false
      this.errorService.errorFunction(err);
    });
  }

  closeStoryCompare() {
    this.showCompare = false;
    this.showCompareChange.emit(this.showCompare);
    this.storyCompare = null;
  }

  syncAction() {
    this.syncStory.emit(this.syncActionConfirmation);
    this.syncActionConfirmation = false;
    this.closeStoryCompare();
  }

  onClick(event) {
    const element = event.target;
    if (!(element.classList.contains('status-dropdown') ||
      element.classList.contains('status') || 
      element.classList.contains('status-trigger'))) {
        this.statusDropdown = false;
    }
  }

  deviceActions() {
    this.deviceActionsVisible = !this.deviceActionsVisible;
  }
  showDevicePreview() {
    this.devicePreviewVisible = true;
  }
  hideDevicePreview() {
    this.devicePreviewVisible = false;
  }
  openRejectionPopup() {
    this.openRejectionReasonPopup = true;
    this.reasonForRejection = isEmpty(this.storyCompare.feedbacks) ? '' : this.storyCompare.feedbacks[0].note 
  }
  closeRejectionPopup() {
    this.openRejectionReasonPopup = false;
  }
}