import { Directive, Output, HostListener, EventEmitter, ElementRef, Input } from "@angular/core";

@Directive({
    selector: '[myOffClick]'
  })
  export class MyOffClickDirective {
  
    @Output() offClick = new EventEmitter();
    @Input() options;
  
    constructor(private _elementRef: ElementRef) {
    }
  
    @HostListener('document:click', ['$event'])
    public onGlobalClick(event: any) {
      if(this.options && this.options.isApply && this.options.key == 'showQuickAct') {
        const element = event.target;
        if(this.options.key == 'showQuickAct') {
          if ((element.classList.contains('check-toggle') || element.classList.contains('story-selected-checkbox'))) {
                return ;
            }
        }
        const targetElementPath = event.path;
        if (targetElementPath) {
          let elementRefInPath = targetElementPath.find(e => e === this._elementRef && this._elementRef.nativeElement);
          if (!elementRefInPath) {
            this.offClick.emit(null);
          }
        }
      }
    }
  }