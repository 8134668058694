<div class="addNewEpic-backdrop" (click) = "closeAddSprintPopup()"></div>
  <div class="addSprintPopup-wrapper">
    <div class="create-sprintLoader" *ngIf="sprintService.startLoader">
      <img src="../../assets/images/storyBoardLoader.gif" alt=""/>
    </div>
    <em class="iconb-close closePopup" (click) = "closeAddSprintPopup()"></em>
    <div class="addSprintPopup-content">
      <div class="mainTitle">{{(popupFor === 'add_sprint') ? 'Add Sprint':'Edit Sprint'}}</div>
      <form class="addSprintForm" >
        <div class="addSprintForm-block">
          <label class="inputTitle">Sprint Name<sup>*</sup></label>
          <input type="text" class="addSprint-input" name="name" placeholder="Add Sprint Name" required [(ngModel)]="sprint_Details.name"/>
        </div>
        <div class="addSprintForm-block">
          <label class="inputTitle">Sprint Goal<sup>*</sup></label>
          <textarea class="addSprint-description" name="description" placeholder="Add Sprint Goal" [(ngModel)]="sprint_Details.description"></textarea>
        </div>
        <div class="addSprintForm-block">
          <label class="inputTitle">Start Date</label>
          <input id="sprintStartDate"
                 type="text"
                 class="addSprint-input"
                 placeholder="Select Date"
                 name="startDate"
                 angular-mydatepicker
                 (dateChanged)="onStartDateChanged($event)"
                 #dp="angular-mydatepicker"
                 [options]="datePickerOptionsStart"
                 [(ngModel)]="startDt"
          >
          <div class="cal-btn" [ngClass]="{'calendarDisabled': disableStartDate}" (click)="!disableStartDate? dp.toggleCalendar() : ''">
            <span class="calIcon">
              <em class="iconb-calendar"></em>
            </span>
          </div>
          <span class="error-1" *ngIf="showStartDateError">please select start date first</span>
        </div>
        <div class="addSprintForm-block">
          <label class="inputTitle">End Date</label>
          <input id="sprintEndDate"
                 type="text"
                 class="addSprint-input"
                 placeholder="Select Date"
                 name="endDate"
                 angular-mydatepicker
                 (dateChanged)="onEndDateChanged($event)"
                 #dpp="angular-mydatepicker"
                 [options]="datePickerOptionsEnd"
                 [(ngModel)]="endDt">
          <div class="disableCal" *ngIf="disableEndDate"></div>
          <div class="cal-btn" (click)="sprint_Details.projected_start_date ? dpp.toggleCalendar() : showSelectStartDate()">
            <span class="calIcon">
              <em class="iconb-calendar"></em>
            </span>
          </div>
        </div>
        <div class="actionWrapper">
          <button type="button" class="actionButton submit" [disabled]="!sprint_Details.name || !sprint_Details.description" (click) = "(popupFor === 'add_sprint') ? sprintService.addSprint(sprint_Details, projectId) : sprintService.updateSprintDetails(sprint_Details, projectId)">{{(popupFor === 'add_sprint') ? 'Submit':'Update'}}</button>
          <button type="button" class="actionButton cancel" (click) = "closeAddSprintPopup()">Cancel</button>
        </div>
      </form>
    </div>
  </div>