import { Component, OnInit, Renderer2 } from '@angular/core';
import { HttpRequests } from '../../shared/services/http-requests.service';
import { DataService } from '../../shared/services/data.service';
import { UserService } from '../../shared/services/user.service';
import { Router } from '@angular/router';


enum State { Initial, EmailSent }

@Component({
  selector: 'forgot-password',
  templateUrl: 'forgot-password.component.html',
  styleUrls: ['forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  State = State;
  state: State = State.Initial;
  resetPasswordData = {email: '', redirect_url: ''};
  isPending: boolean = false;
  message: string;
  errors: any;

  constructor(
    private httpRequest: HttpRequests,
    private router: Router,
    private userService: UserService,
    private dataService: DataService, 
    private renderer: Renderer2) {}

  ngOnInit() {
    this.renderer.addClass(document.body, 'auth-screen');
    if(this.userService.getUser()) {
      this.router.navigate(['/']);
    }
  }

  canSubmit() {
    return !this.isPending
         && this.resetPasswordData.email.trim().length > 0;
  }

  onSubmit() {
    this.isPending = true;
    this.resetPasswordData.redirect_url = window.location.origin + '/#/' + 'change-password' 
    this.userService
      .login_signup(this.resetPasswordData, 'forgot')
      .subscribe(this.onSuccess.bind(this), this.onError.bind(this))
  }

  onSuccess(response) {
    const json = response;
    this.message = json.message;
    this.state = State.EmailSent;
    this.isPending = false;
  }

  onError(response) {
    const json = response;
    this.errors = json.errors;
    this.isPending = false;
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'auth-screen');
  }
}
