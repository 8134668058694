
import {finalize} from 'rxjs/operators';
import { Component, OnInit, Input, ViewChild, ChangeDetectorRef, ViewChildren } from '@angular/core';
import { CommonMembershipsService } from '../../services';
import { CommonList } from '../../classes';
import { CustomToastService } from '../../../../shared/services/custom-toast.service';
import { IMyOptions } from 'angular-mydatepicker';
import moment from 'moment';
import { ErrorService } from '../../../../shared/errorFunction';

@Component({
  selector: 'common-memberships',
  templateUrl: 'memberships.component.html',
  styleUrls: [
    '../../../admin.common.scss',
    'memberships.component.scss'
  ]
})
export class CommonMembershipsComponent extends CommonList implements OnInit {
  @Input('userId') userId: number;
  @Input('projectId') projectId: number;
  @Input('isEditable') isEditable: boolean;
  // @ViewChild('popover', {static: false}) popover: PopoverDirective;
  roles: Array<string> = ['admin', 'user'];
  memberships: Array<any>;
  projects: Array<any>;
  users: Array<any>;
  isUser: boolean = false;
  isProject: boolean = false;
  indexIsPending: boolean = false;
  createIsPending: boolean = false;
  projectsIsPending: boolean = false;
  usersIsPending: boolean = false;
  itemPendingId: number;
  createForm: any;
  params: any = {
    page: 1
  };
  elem: any;
  OpenPopUp = false;
  waitForLoader = false;
  openSelectDD?: boolean;
  is_exporting: boolean = false;

  myOptionsStartDate: IMyOptions = {
    // other options...
    dateFormat: 'dd.mm.yyyy',
    focusInputOnDateSelect: false,
    closeSelectorOnDateSelect: false,
    closeSelectorOnDocumentClick: false
  };

  myOptionsEndDate: IMyOptions = {
    // other options...
    dateFormat: 'dd.mm.yyyy',
    focusInputOnDateSelect: false,
    closeSelectorOnDateSelect: false,
    closeSelectorOnDocumentClick: false,
    // disableUntil: {year: 9999, month: 12, day: 31}
  };
  // Initialized to specific date (09.10.2018)
  startDate: any;
  endDate: any;
  openDateFilter:boolean = false;

  constructor(private membershipsService: CommonMembershipsService,
              private cdr: ChangeDetectorRef,
              private customToast: CustomToastService,
              private errorService: ErrorService) {
    super();
  }

  ngOnInit() {
    this.initializeParams();
    this.getRecords();
    this.resetForm();
  }

  openFilterDate() {
    this.openDateFilter = !this.openDateFilter;
  }
  closeFilterDate() {
    this.openDateFilter = false;
  }

  initializeParams() {
    if (this.userId) {
      this.params.user_id = this.userId;
      this.isUser = true;
    }
    if (this.projectId) {
      this.params.project_id = this.projectId;
      this.isProject = true;
    }
  }
  openSelectRoleDD(membership) {
    for(let i=0; i<this.memberships.length; i++) {
      if(this.memberships[i] != membership) {
        this.memberships[i].openSelectDD = false;
      }
    }
    membership.openSelectDD = !membership.openSelectDD;
  }


  resetForm() {
    this.createForm = {
      project_id: this.projectId || 0,
      user_id: this.userId || 0,
      role: 'admin'
    }
  }

  onPopoverShown() {
    this.getAvailableProjects();
    this.getAvailableUsers();
  }

  onPopoverHidden() {
    this.resetForm();
    this.projects = null;
    this.users = null;
  }

  getAvailableProjects() {
    if (this.userId) {
      this.projectsIsPending = true;
      this.membershipsService
        .availableProjects(this.userId)
        .subscribe(response => {
          this.projects = this.parseResponse(response).projects;
          this.projectsIsPending = false;
        },
        (error) => {
          this.errorService.errorFunction(error);
        });
    }
  }

  getAvailableUsers() {
    if (this.projectId) {
      this.usersIsPending = true;
      this.membershipsService
        .availableUsers(this.projectId)
        .subscribe(response => {
          this.users = this.parseResponse(response).users;
          this.usersIsPending = false;
        },
        (error) => {
          this.errorService.errorFunction(error);
        });
    }
  }

  getRecords() {
    this.indexIsPending = true;
    this
      .membershipsService
      .getMemberships(this.params)
      .subscribe(response => {
        let json = this.parseResponse(response);
        this.memberships = json.memberships;
        this.meta = json.meta;
        this.generatePages();
        this.indexIsPending = false;
      },
      (error) => {
        this.errorService.errorFunction(error);
      });
  }

  canSubmit(): boolean {
    return this.valueIsPresent(this.createForm.project_id)
        && this.valueIsPresent(this.createForm.user_id)
        && this.valueIsPresent(this.createForm.role);
  }

  createMembership() {
    this.createIsPending = true;
    this
      .membershipsService
      .createMembership({membership: this.createForm})
      .subscribe(response => {
        this.customToast.messages.push({
          id: 'MemberAdded', type: 'success',
          class: 'new_member_added',
          title: 'Membership Info',
          message: 'New member added successfully'
        });
        this.createIsPending = false;
        // this.popover.hide();
        this.resetForm();
        this.getRecords();
      }, err => this.errorService.errorFunction(err));
  }

  updateMembership(role, membership) {
    membership.openSelectDD = false;
    membership.role = role;
    const data = {membership: {role: membership.role}};
    this.itemPendingId = membership.id;
    this
      .membershipsService
      .updateMembership(membership.id, data).pipe(
      finalize(() => {
        this.itemPendingId = null;
      }))
      .subscribe(response => {
        membership.role = response.role;
        this.itemPendingId = null;

          this.customToast.messages.push({
            id: 'role_updated',
            type: 'success',
            class: 'new_member_added',
            title: 'Role Updated',
            message: 'Role updated successfully'
          });
      },
      (error) => {
        this.memberships.forEach((mem, index) => {
          if(mem.id === this.itemPendingId) {
            this.memberships[index].role = 'admin';
          }
        });
        this.errorService.errorFunction(error);
      }
   )
  }

  openTaskPopup(data, type) {
    this.OpenPopUp = true;
    this.elem = data;
  }

  closeTaskPopUp() {
    this.OpenPopUp = false;
  }

  deleteMembership(membership) {
    this.waitForLoader = true;
    this.itemPendingId = membership.id;
    this
      .membershipsService
      .deleteMembership(membership.id).pipe(
      finalize(() => {
        this.itemPendingId = null;
      }))
      .subscribe(() => {
          this.waitForLoader = false;
          this.OpenPopUp = false;
        if (this.previousPageAvailable() && this.memberships.length == 1) {
          this.previousPage();
        } else {
          this.getRecords();
        }
        this.itemPendingId = null;
          this.customToast.messages.push({id:"deletinguser", type:"success", class: "user_delete_info",  title: 'Membership Info', message: membership.user.name + ' is deleted from this project.'});
      },
        (error) => {
          this.waitForLoader = false;
          this.OpenPopUp = false;
          this.errorService.errorFunction(error);
        }
      )
  }

  exportUser() {
    this.is_exporting = true;
    this.membershipsService.exportFileMember(this.projectId).subscribe((res: any) => {
      const parsedResponse = res;
      const blob = new Blob([parsedResponse], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);

      if (navigator.msSaveOrOpenBlob) {
        navigator.msSaveBlob(blob, 'members.csv');
      } else {
        const a = document.createElement('a');
        a.href = url;
        a.download = 'members.csv';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
      window.URL.revokeObjectURL(url);

      this.is_exporting = false;
      this.customToast.messages.push({
        id: 'MembersList',
        type: 'success',
        class: 'new_member_added',
        title: 'Export Members',
        message: 'Members.csv is successfully downloaded.'
      });
    }, (error) => {
      this.is_exporting = false;
      this.customToast.messages.push({
        id: 'Downloadfail',
        type: 'fail',
        class: 'import_fail',
        title: 'Export Members',
        message: 'Export members.csv failed.'
      });
      this.errorService.errorFunction(error);
    });

  }

  resendInvite(membership) {
    this.membershipsService
      .resendInviteToUser(membership, this.projectId)
      .subscribe(res => {
        this.customToast.messages.push({id:"memberReInvited", type:  "success", class: "membership_added",  title: 'Member Reinvited', message: res.message});
      }, err => this.errorService.errorFunction(err));
  }

  getSearchRecords() {
    this.params.page = 1;
    this.getRecords();
  }

  resetCalender(dpStart, dpEnd) {
    dpStart.clearDate();
    dpEnd.clearDate();
    dpStart.openCalendar();
    dpEnd.openCalendar();
  }

  onDateChanged(event, type, dpEnd) {
  }

  filterMemberShipData(dpEnd) {
    if(this.startDate && this.endDate) {
      const start = moment(this.startDate.jsdate, 'YYYY/MM/DD');
      const end = moment(this.endDate.jsdate, 'YYYY/MM/DD');
      if(end.diff(start, 'days') >= 0) {
        let startDate = this.startDate.date.year + '/' + this.startDate.date.month + '/' + this.startDate.date.day;
        let endDate = this.endDate.date.year + '/' + this.endDate.date.month + '/' + this.endDate.date.day;
        this.getMembership(startDate, endDate)
      } else {
        dpEnd.clearDate();
        dpEnd.openCalendar();
        this.customToast.messages.push({
          id: 'datewarning', type: 'warning',
          class: 'bulk_delete',
          title: 'Date Warning',
          message: 'End Date Should be greater then Start Date'
        });
      }
    }
  }

  getMembership(startDate, endDate) {
    this.params['start_date'] = startDate;
    this.params['end_date'] = endDate;
    this.getRecords();
    this.openFilterDate();
  }

  setStartEndDate(time) {
    this.endDate = { jsdate: new Date(moment().format("YYYY-MM-DDTHH:mm:ssZ")) };
    this.startDate = { jsdate: new Date(moment().subtract(time, 'months').format("YYYY-MM-DDTHH:mm:ssZ")) };
    let startDate = this.startDate.jsdate.getFullYear() + '/' + (this.startDate.jsdate.getMonth() + 1) + '/' + this.startDate.jsdate.getDate();
    let endDate = this.endDate.jsdate.getFullYear() + '/' + (this.endDate.jsdate.getMonth() + 1) + '/' + this.endDate.jsdate.getDate();
    this.getMembership(startDate, endDate);
  }

  public parseResponse(res: any): any {
    return res;
  }
}
