import { Component, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { each, uniq } from 'lodash';
import { HttpClient } from '@angular/common/http';
import { HttpRequests } from '../../shared/services/http-requests.service';
import { DataService } from '../../shared/services/data.service';
import { UserService } from '../../shared/services/user.service';
import {ErrorService} from "../../shared/errorFunction";
import {NotificationService} from "../../shared/services/notification.service";
import {PolicyService} from "../../shared/services/policy.service";
import {SharedService} from "../../shared/services/shared.service";
import {
  UserData,
  EVENT_TYPES,
  FIRST_VISIBLE_COLUMN,
  PROJECT_COLUMN_DATA, SignInData
} from './../../shared/dataTypes';
import {ProjectService} from "../../shared/services/project.service";
import {SocketsService} from "../../shared/services/sockets.service";
import {environment} from "../../../environments/environment";

const CHANGE_PASSWORD_ROUTE = 'auth/password';

enum State { Initial, PasswordChanged, TokenInvalid }

@Component({
  selector: 'change-password',
  templateUrl: 'change-password.component.html',
  styleUrls: ['change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  State = State;
  isPending: boolean = false;
  state: State = State.Initial;
  errors: any;
  form = {password: '', password_confirmation: '', reset_password_token: ''};
  showLoginPassword:boolean = false;
  private signInData: SignInData = <SignInData>{};
  private output: any;

  constructor(
              private httpRequest: HttpRequests,
              private dataService: DataService,
              private route: ActivatedRoute,
              private router: Router,
              private userService: UserService,
              private http: HttpClient,
              private renderer: Renderer2,
              private errorService: ErrorService,
              private notificationService: NotificationService,
              private policyService: PolicyService,
              private sharedService: SharedService,
              private projectService: ProjectService,
              private socketService: SocketsService) {}

  ngOnInit() {
    this.renderer.addClass(document.body, 'auth-screen');
    if (this.userService.getUser()) {
      this.router.navigate(['/']);
    }
    this.route.queryParamMap.subscribe(params => {
      this.form.reset_password_token = params.get('reset_password_token');
      if (!this.form.reset_password_token) {
        this.state = State.TokenInvalid;
      }
    })
  }
  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'auth-screen');
  }

  canSubmit(): boolean {
    return !this.isPending
         && this.form.password.trim().length > 0
         && this.form.password_confirmation.trim().length > 0
         && this.form.reset_password_token.trim().length  > 0;
  }

  onSubmit() {
    this.isPending = true;
    this.httpRequest
      .put(this.dataService.apiUrl + CHANGE_PASSWORD_ROUTE, this.form, this.dataService.getRequestOptionArgs('application/json', false, '', '', 'login'))
      .subscribe(this.onSuccess.bind(this), this.onError.bind(this))
  }

  onSuccess(res) {
    this.renderer.removeClass(document.body, 'collapseNav');
    this.signInData    = <SignInData>{};
    this.output        = res;
    const data = res.body['data'];
    const adminLogin = (data.user.role == 'admin' || (data.project_admin));
    const designerLogin =  data.user.skill == 'UI/UX';
    this.userService.setCurrentUser(data.user as UserData, res.headers, designerLogin, adminLogin);
    this.socketService.centrifugeConfig();
    if ((!designerLogin)) {
      this.loginRedirect();
    } else {
      window.location.href = environment.designerUrl;
    }
  }

  onError(response) {
    this.errors = {};
    each(response, (value, key) => {
      this.errors[key] = uniq(value);
    });
    if (this.errors.reset_password_token) {
      this.state = State.TokenInvalid;
    }
    this.isPending = false;
  }

  hasError(field: string): boolean {
    return this.errors && this.errors.hasOwnProperty(field);
  }
  showPassword($event) {
    this.showLoginPassword = !this.showLoginPassword;
    $event.preventDefault();
    $event.stopPropagation();
  }


  getueryString(querystring) {
    // remove any preceding url and split
    querystring = querystring.substring(querystring.indexOf('?')+1).split('&');
    let params = {}, pair, d = decodeURIComponent;
    // march and parse
    for (let i = querystring.length - 1; i >= 0; i--) {
      pair = querystring[i].split('=');
      params[d(pair[0])] = d(pair[1] || '');
    }

    return params;
  }

  loginRedirect() {
    const currentUser = this.userService.getUser();
    const payload = {
      data: {
        currentUser: currentUser,
      }
    };
    this.userService.getPartnerCode().subscribe(partner => {
      this.userService.setPartner(partner);
    }, err => this.errorService.errorFunction(err));
    this.notificationService.broadcast(EVENT_TYPES.SESSION.LOGGED_IN_NEW, payload);
    this.notificationService.broadcast(EVENT_TYPES.SESSION.LOGGED_IN, payload);
    this.policyService.refresh();
    let redirectUrl = '';
    let queryParams = {};
    if(this.userService.loginUrl != '') {
      if(this.userService.loginUrl.indexOf('?') >= 0) {
        queryParams = this.getueryString(this.userService.loginUrl);
        if(queryParams['storyId']) {
          this.projectService.storyId = queryParams['storyId'];
        }
        redirectUrl = this.userService.loginUrl.substr(0, this.userService.loginUrl.indexOf('?'));
        this.router.navigate([redirectUrl], { queryParams: queryParams });
      }
      else {
        redirectUrl = this.userService.loginUrl;
        if(redirectUrl.trim() == '/projects') redirectUrl = '/'
        this.router.navigate([redirectUrl]);
      }

    } else {
      redirectUrl = '/';
      this.router.navigate(['/']);
    }

    if (currentUser) {
      this.userService.trackSegmentEvent('Login successful', this.sharedService.getCommonPropsForSegmentEvents());
    }
  }
}
