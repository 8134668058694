import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { OutletContext } from '@angular/router';


@Component({
  selector: 'app-custom-popup',
  templateUrl: './custom-popup.component.html',
  styleUrls: ['./custom-popup.component.css']
})
export class CustomPopupComponent  {

  constructor() { 
    // This is intentional
  }
  @Input() waitForLoader;
  @Input() OpenPopUp;
  @Input() delement;
  @Input() texts;
  @Output() closeCustomPopUp = new EventEmitter();
  @Output() deleteElement = new EventEmitter();

  ngOnInit() {
    // This is intentional
  }

  closePopUp() {
     this.closeCustomPopUp.emit('yes');
  }

  deleteEl(element) {
     this.deleteElement.emit(element);
  }
}
