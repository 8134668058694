<ul class="nav nav-pills nav-stacked">
  <li routerLinkActive="active">
    <a routerLink="dashboard">
      Dashboard
    </a>
  </li>
  <li routerLinkActive="active">
    <a routerLink="projects">
      Projects
    </a>
  </li>
  <li routerLinkActive='active'>
    <a routerLink='/admin/timing/users'>Users</a>
  </li>
  <li routerLinkActive="active">
    <a routerLink="report">
      Report
    </a>
  </li>
</ul>
