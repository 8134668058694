import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SharedModule } from './../shared/shared.module';
import { FormsModule } from '@angular/forms';
import { MemberModule } from '../member/member.module';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {AdminModule} from '../admin/admin.module';
import {ProjectReleasesComponent} from "./project-releases.component";
import { ReleaseVersionComponent } from './release-version/release-version.component';
import { CreateEditReleaseComponent } from './create-edit-release/create-edit-release.component';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import { ReleaseDetailComponent } from './release-note/release-note.component';
import { AddRemoveStoryComponent } from './add-remove-story/add-remove-story.component';
import { CommentActivityComponent } from './comment-activity/comment-activity.component';
import { ProjectModule } from '../project/project.module';
import { FeatureListComponent } from './feature-list/feature-list.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    FormsModule,
    InfiniteScrollModule,
    AngularMyDatePickerModule,
    ProjectModule
  ],
  declarations: [
    ProjectReleasesComponent,
    ReleaseVersionComponent,
    CreateEditReleaseComponent,
    ReleaseDetailComponent,
    AddRemoveStoryComponent,
    CommentActivityComponent,
    FeatureListComponent
  ],
  exports: [
    ProjectReleasesComponent,
    CreateEditReleaseComponent,
    AddRemoveStoryComponent,
    CommentActivityComponent
  ]
})
export class ProjectReleasesModule { }
