import {throwError as observableThrowError,  Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable()
export class HttpRequests {
  constructor(public http: HttpClient, private router: Router) {
  }

  public get(url: string, Headers?, requestOptions?): Observable<any> {
    if (requestOptions && Headers.params) {
      return this.http.get(url, {params: Headers.params, headers: requestOptions.headers})
        .map(res => this.parseResponse(res)).catch((error: HttpErrorResponse) => {
          return observableThrowError(error.error);
      });
    } else if (requestOptions) {
      return this.http.get(url, requestOptions)
        .map(res => this.parseResponse(res)).catch((error: HttpErrorResponse) => {
          return observableThrowError(error.error);
      });
    } else if (Headers) {
      return this.http.get(url,  Headers )
        .map(res => this.parseResponse(res)).catch((error: HttpErrorResponse) => {
          return observableThrowError(error.error);
      });
    } else {
      return this.http.get(url)
        .map(res => this.parseResponse(res)).catch((error: HttpErrorResponse) => {
          return observableThrowError(error.error);
      });
    }
  }


  public post(url: string, data: any, Headers): Observable<any> {
    if (Headers) {
      return this.http.post(url, data,  Headers )
        .map(res => this.parseResponse(res)).catch((error: HttpErrorResponse) => {
          return observableThrowError(error.error);
      });
    } else {
      return this.http.post(url, data)
        .map(res => this.parseResponse(res)).catch((error: HttpErrorResponse) => {
          return observableThrowError(error.error);
      });
    }
  }

  public put(url: string, data: any, Header): Observable<any> {
    if (Header) {
      return this.http.put(url, data,  Header)
        .map(res => this.parseResponse(res)).catch((error: HttpErrorResponse) => {
          return observableThrowError(error.error);
      });
    } else {
      return this.http.put(url, data)
        .map(res => this.parseResponse(res)).catch((error: HttpErrorResponse) => {
          return observableThrowError(error.error);
      });
    }
  }

  public delete(url: string, Header): Observable<any> {
    if (Header) {
      return this.http.delete(url, Header)
        .map(res => this.parseResponse(res)).catch((error: HttpErrorResponse) => {
          return observableThrowError(error.error);
      });
    } else {
      return this.http.delete(url)
        .map(res => this.parseResponse(res)).catch((error: HttpErrorResponse) => {
          return observableThrowError(error.error);
      });
    }
  }

  public patch(url: string, data: any, Header): Observable<any> {
    if (Header) {
      return this.http.patch(url, data, Header)
        .map(res => this.parseResponse(res)).catch((error: HttpErrorResponse) => {
          return observableThrowError(error.error);
      });
    } else {
      return this.http.patch(url, data)
        .map(res => this.parseResponse(res)).catch((error: HttpErrorResponse) => {
          return observableThrowError(error.error);
      });
    }
  }

  public parseResponse(res: any): any {
    return res;
  }

  public handleError(error: any) {
    if (error.status === 401) {
      this.router.navigate(['login']);
    }
    return observableThrowError(error.error);
  }
}
