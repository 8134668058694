<div class="forBreadCrumb">
  <a class="backLink" routerLink="../../dashboard"><em class="iconb-home"></em></a>
  <a class="backLink" routerLink=".."><span class="innerText">Members</span></a>
  <span class="activePage">New Member</span>
</div>


<div class="outerWrapper">
  <div class="genralBlock">
    <div class="genralBlock-title">Add new member</div>
    <admin-users-form #adminUsersFormComponent
    *ngIf = "form"
    [model]="form"
    [errors]="outputErr"
    [isPending]="isPending">
    </admin-users-form>
    <div class="actionBt-wrapper">
      <button class="actionButton cancel-addMember" [routerLink]="['/admin/users']">Cancel</button>
      <button class="actionButton add" [disabled]="(form.email == '') ||  (checkEmail(form.email) && checkEmail(fullEmail))" (click) = "onSubmit(true)" >Save & Add Another</button>
      <button class="actionButton add" [disabled]="(form.email == '') || (checkEmail(form.email) && checkEmail(fullEmail))" (click) = "onSubmit()">Add & Close</button>
    </div>
  </div>
</div>
