export * from './../shared/shared.module';
// export * from './register';
export * from './sign-in';
export * from './sign-in-oauth';
// export * from './change-password';
export * from './access-resource';
export * from './validate-token';
export * from './sign-out';

export * from './example.component';
export * from './example.module';
