import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpRequests } from '../../shared/services/http-requests.service';
import { DataService } from '../../shared/services/data.service';
import { HttpClient } from '@angular/common/http';

export const ROUTES = {
  PROJECTS: 'admin/projects.json',
  PROJECT: (id: number) => `admin/projects/${id}`,
  Export_PROJECTS: 'admin/projects.csv'
};

@Injectable()
export class AdminProjectsService {
  constructor(
    private httpRequest: HttpRequests,
    private http: HttpClient,
    private dataService: DataService) {}

  getProjects(queryParams) {
    const API = this.dataService.apiUrl + ROUTES.PROJECTS;
    const url = this.dataService.parseQueryParams(API, queryParams);

    return this
      .httpRequest
      .get(url, this.dataService.getRequestOptionArgs())
      .pipe(map(response => this.parseResponse(response)));
  }

  getProject(id: number) {
    const API = this.dataService.apiUrl + ROUTES.PROJECT(id);
    return this
      .httpRequest
      .get(API, this.dataService.getRequestOptionArgs());
  }

  createProject(project) {
    const API = this.dataService.apiUrl + ROUTES.PROJECTS;
    return this
      .httpRequest
      .post(API, project, this.dataService.getRequestOptionArgs());
  }

  updateProject(id: number, project: any) {
    const API = this.dataService.apiUrl + ROUTES.PROJECT(id);
    return this
      .httpRequest
      .put(API, project, this.dataService.getRequestOptionArgs());
  }

  destroyProject(id: number) {
    const API = this.dataService.apiUrl + ROUTES.PROJECT(id);
    return this
      .httpRequest
      .delete(API, this.dataService.getRequestOptionArgs());
  }

  exportFile() {
    const API = this.dataService.apiUrl + ROUTES.Export_PROJECTS;
    return this.http.get(API, this.dataService.getRequestOptionArgs('', false, '', '', '', true));
  }

  public parseResponse(res: any): any {
    return res;
  }
}
