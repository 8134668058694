import {IntegrationData} from '../dataTypes';

export class Integration implements IntegrationData {
  id: number;
  kind?: string;
  created_at?: string;
  resource_url?: string;
  insertURL?: boolean;
  inputURL?: string;
  isSaveDisable?: boolean;

  constructor(data: IntegrationData) {
    this.id = data.id;
    this.kind = data.kind;
    this.created_at = data.created_at;
    this.resource_url = data.resource_url;
    this.insertURL = false;
    this.inputURL = '';
    this.isSaveDisable = false;
  }
}
