import { Directive, ElementRef, HostListener, OnInit } from '@angular/core';

@Directive({
  selector: 'textarea[autosize]'
})
export class AutosizeDirective implements OnInit {

  constructor(public element: ElementRef) { }

  @HostListener('input', ['$event.target'])
  public onInput() {
    this.resize();
  }

  ngOnInit(): void {
    setTimeout(() => this.resize(), 0);
  }

  public resize(): void {
    const style = this.element.nativeElement.style;
    style.overflow = 'hidden';
    style.height = '36px';

    const height = this.element.nativeElement.scrollHeight;
    style.height = `${height}px`;
  }
}
