import {debounceTime, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import { API_ROUTES } from '../apiRoutes';
import { NotificationService } from './notification.service';
import { Observable ,  Subject } from 'rxjs';
import { HttpRequests } from './http-requests.service';
import { DataService } from './data.service';
import { Project } from '../models/project';
import { Story } from '../models/story';
import {EVENT_TYPES, START_STORY_CONFIRM_OPTIONS} from '../dataTypes';
import { environment } from '../../../environments/environment';

@Injectable()
export class StoryService {
  getstories = new Subject<any>();
  getblocker = new Subject<any>();
  searchStoriesVal = '';
  attachmentActionLoader:boolean = false;
  attachmentActionLoader2:boolean = false;
  confirm_option_list = [];
  story_start_confirmation:boolean = false;
  storyState = '';
  parentOrSubtaskStories = 'both';
  allowStoryUpdateFlag:boolean = true
  disableStoryUpdateFlag:boolean = true
  neglectOnClosure:boolean = false
  chatGPTLoader:boolean = false
  canDisableSaveFlag = true
  canDisableResetFlag = true
  disableStoryChatGPT = false
  handleAcceptanceCriteriaDeleteForGPT = true
  constructor(private notificationService: NotificationService,
              private httpRequest: HttpRequests,
              private dataService: DataService) {
  }
 
  updateParamsData(filters?, data?, getApi = true) {
    const additionalParamKey = getApi ? '[]' : '';
    if (filters['sub_type[]'] == 'core') {
      data['core'] = true 
      data['story_types' + additionalParamKey] = 'task'; 
    }
    if (filters['sub_type[]'] == 'custom') {
      data['core'] = false
      data['story_types' + additionalParamKey] = 'task'; 
    }
    if (filters['states[]'].length > 0) { data['states' + additionalParamKey] = filters['states[]']; }
    if (filters['story_types[]'].length > 0) { data['story_types' + additionalParamKey] = filters['story_types[]']; }
    if (filters['phases[]'].length > 0) { data['phases' + additionalParamKey] = filters['phases[]']; }
    // if (filters['bb_status[]'].length > 0) { data['bb_status' + additionalParamKey] = filters['bb_status[]']; }
    if (filters['epic_ids[]'].length > 0) { data['epic_ids' + additionalParamKey] = filters['epic_ids[]']; }
    if (filters['labels[]'].length > 0) { data['labels' + additionalParamKey] = filters['labels[]']; }
    if (filters['priorities[]'].length > 0) { data['priorities' + additionalParamKey] = filters['priorities[]']; }
    if (filters['created_from[]'].length > 0) { data['created_from' + additionalParamKey] = filters['created_from[]']; }
    if (filters['platform_ids[]'].length > 0) { data['platform_ids' + additionalParamKey] = filters['platform_ids[]']; }
    if (filters['code_commit[]'].length > 0) { data['code_commit' + additionalParamKey] = filters['code_commit[]']; }
    if (filters['created_on_date_range[]'].length > 0) { data['created_on_date_range' + additionalParamKey] = filters['created_on_date_range[]']; }
    if (filters['studio_role_ids[]'].length > 0) { data['studio_role_ids' + additionalParamKey] = filters['studio_role_ids[]']; }
    if (filters['owner_ids[]'].length > 0) { data['owner_ids' + additionalParamKey] = filters['owner_ids[]']; }
    if (filters['requestor_ids[]'].length > 0) { data['requestor_ids' + additionalParamKey] = filters['requestor_ids[]']; }
    if (filters['reviewer_ids[]'].length > 0) { data['reviewer_ids' + additionalParamKey] = filters['reviewer_ids[]']; }
    if (filters['dev_type[]'].length > 0) { data['dev_type' + additionalParamKey] = filters['dev_type[]']; }
    if (filters['state_changed_at_date_range[]'].length > 0) { data['state_changed_at_date_range' + additionalParamKey] = filters['state_changed_at_date_range[]']; }
    if (filters['sprint_ids[]'].length > 0) { data['sprint_ids' + additionalParamKey] = filters['sprint_ids[]']; }
    if (filters['bb_design[]'].length > 0) { data['bb_design' + additionalParamKey] = filters['bb_design[]']; }
  } 

  stories(project: Project, stories?, queryParams?, filters?, apply_default_filter?, sortBy?, page_no?) {
    const data = [];
    if (stories) {
      data['story_ids[]'] = stories;
    }

    // Map the filters into data parameter
    if (filters) {
      this.updateParamsData(filters, data);
    }

    if (this.parentOrSubtaskStories && this.parentOrSubtaskStories !== 'both') {
      data[this.parentOrSubtaskStories] = true;
    }
   
    // if stories ids, load minimal data
    if (stories) {
      data['min'] = true;
    } else {
      // For lists, load minimal data
      data['per_page'] = Math.ceil((window.screen.availHeight - 225) / 110);
    }

    if (queryParams) {
      data['list_id'] = queryParams.list_id;
      data['page'] = queryParams.page;
    }
    if (apply_default_filter) {
      data['apply_default_filter'] = apply_default_filter;
    }
    const API = this.dataService.apiUrl + API_ROUTES.STORIES(project.id);
    return this.httpRequest
      .get(API, {params : data}, this.dataService.getRequestOptionArgs()).pipe(
        map(res => this.parseResponse(res)),
      );
  }

  searchstories(project: Project, searchQuery, page?,testSuite?) {
    let api = this.dataService.apiUrl + API_ROUTES.SEARCH_STORIES(project.id) + '?search=' + encodeURIComponent(searchQuery);
    if(page) {
      api += '&page=' + page
    }
    if (testSuite) {
      api += '&test_suite=' + testSuite
    }
    return this.httpRequest
            .get(api, this.dataService.getRequestOptionArgs()).pipe(
            map(res => this.parseResponse(res)));
  }

  public parseResponse(res: any): any {
    return res;
  }

  public parseStoryResponse(res: any): any {
    if (res && res.changes && Object.keys(res.changes).length !== 0) { return res; }
    if (res && res.story) { return res.story; }
    return {};
  }

  story(project: Project, story: Story | any, id?, isStorySelected?: boolean) {
    const storyId = id ? id : story.id;
    let API = this.dataService.apiUrl + API_ROUTES.STORY(project.id, storyId);
    if (isStorySelected) { API += '?selected=true' }
    return this.httpRequest
            .get(API, this.dataService.getRequestOptionArgs()).pipe(
            map(res => this.parseResponse(res)),
            map(res => {
              res.story.project = project;
              const res_story = new Story(res.story);
              const payload = {
                data: {
                  story: res_story
                }
              };
              this.notificationService.broadcast(EVENT_TYPES.STORY.DATA_UPDATED, payload);
              return res_story;
            }));
  }

  createStory(story: Story) {
    const API = this.dataService.apiUrl + API_ROUTES.STORIES(story.project.id);
    return this.httpRequest
      .post(API, { story: story.toJSON() }, this.dataService.getRequestOptionArgs()).pipe(
      map(response => {
        const storyData = this.parseResponse(response).story;
        storyData.isSimulatedObject = true;
        return new Story(storyData);
      }));
  }

  createTestCase(testCaseData,project){
    const API = this.dataService.apiUrl + API_ROUTES.STORIES(project.id);
    return this.httpRequest
      .post(API, { story: testCaseData }, this.dataService.getRequestOptionArgs()).pipe(
      map(response => {
      }));
  }

  copyStory(story: Story, apply_on_sub_tasks = false) {
    const API = this.dataService.apiUrl + API_ROUTES.STORY_COPY(story.project.id, story.id);
    return this.httpRequest
      .post(API, {position: story.position, reason_for_creation: story.reason_for_creation, apply_on_sub_tasks: apply_on_sub_tasks}, this.dataService.getRequestOptionArgs()).pipe(
      map(response => this.parseResponse(response)));
  }

  bulkCopyStory(projectId, stories, apply_on_sub_tasks) {
    const API = this.dataService.apiUrl + API_ROUTES.BULK_STORY_COPY(projectId);
    return this.httpRequest
      .post(API, {stories: stories, apply_on_sub_tasks: apply_on_sub_tasks}, this.dataService.getRequestOptionArgs()).pipe(
      map(res => this.parseResponse(res)));
  }

  bulkFeaturesLabels(selectedStoriesIds, selectedEpicsIds, projectId, selectedLabels) {
    const API = this.dataService.apiUrl + API_ROUTES.BULK_FEATURE_LABEL(projectId);
    return this.httpRequest
      .post(API, {story_ids: selectedStoriesIds, epic_ids: selectedEpicsIds, labels: selectedLabels}, this.dataService.getRequestOptionArgs()).pipe(
        map(res => this.parseResponse(res)));
  }

  bulkMove(selectedStoriesIds, selectedProjectId, projectId, apply_on_sub_tasks) {
    const API = this.dataService.apiUrl + API_ROUTES.BULK_MOVE(projectId);
    return this.httpRequest
      .post(API, {story_ids: selectedStoriesIds, new_project_id: selectedProjectId, apply_on_sub_tasks: apply_on_sub_tasks}, this.dataService.getRequestOptionArgs()).pipe(
      map(res => this.parseResponse(res)));
  }

  bulkDelete(projectId, archive_data , invalid_stories) {
    const data = {
      'story_ids' : archive_data.selected_stories,
      'list_ids' : archive_data.list_id,
      'invalid_story_ids': invalid_stories
    };
    this.updateParamsData(archive_data.filters, data, false);
    const API = this.dataService.apiUrl + API_ROUTES.BULK_DELETE(projectId);
    return this.httpRequest
      .post(API, data, this.dataService.getRequestOptionArgs()).pipe(
      map(res => this.parseResponse(res)));
  }

  bulkDeleteCheck(projectId, archive_data) {
    const data = [];
    data['story_ids[]'] = archive_data.selected_stories;
    data['list_ids[]'] = archive_data.list_id;
    this.updateParamsData(archive_data.filters, data);
    const API = this.dataService.apiUrl + API_ROUTES.VERIFY_BULK_DELETE(projectId);
    return this.httpRequest
      .get(API, {params : data} , this.dataService.getRequestOptionArgs()).pipe(
      map(res => this.parseResponse(res)));
  }

  bulkUnarchiveCheck(projectId, archive_data) {
    const API = this.dataService.apiUrl + API_ROUTES.VERIFY_BULK_UNARCHIVE(projectId);
    return this.httpRequest
      .get(API, {params : {'story_ids[]': archive_data}} , this.dataService.getRequestOptionArgs()).pipe(
      map(res => this.parseResponse(res)));
  }

  bulkAcceptRejectStories(data, projectId) {
    const API = this.dataService.apiUrl + API_ROUTES.BULK_ACCEPT_REJECT(projectId);
    return this.httpRequest
      .post(API, data , this.dataService.getRequestOptionArgs()).pipe(
      map(res => this.parseResponse(res)));
  }

  createAcceptanceCriteria(story_id, data) {
    const API = this.dataService.apiUrl + API_ROUTES.CREATE_ACCEPTANCE_CRITERIA(story_id);
    return this.httpRequest
      .post(API, {acceptance_criteria :data} , this.dataService.getRequestOptionArgs()).pipe(
      map(res => this.parseResponse(res)));
  }
  createAcceptanceCriteriaBulk(projectId, story_id, data) {
    const API = this.dataService.apiUrl + API_ROUTES.BULK_CREATE_ACCEPTANCE_CRITERIA(projectId, story_id);
    return this.httpRequest
      .post(API, { acceptance_criterias: data }, this.dataService.getRequestOptionArgs()).pipe(
        map(res => this.parseResponse(res)));
  }
  updateAcceptanceCriteria(story_id, data, criteria_id) {
    const API = this.dataService.apiUrl + API_ROUTES.UPDATE_ACCEPTANCE_CRITERIA(story_id, criteria_id);
    return this.httpRequest
      .put(API, {acceptance_criteria :data} , this.dataService.getRequestOptionArgs()).pipe(
      map(res => this.parseResponse(res)));
  }
  deleteAcceptanceCriteria(story_id, criteria_id) {
    const API = this.dataService.apiUrl + API_ROUTES.UPDATE_ACCEPTANCE_CRITERIA(story_id, criteria_id);
    return this.httpRequest
      .delete(API, this.dataService.getRequestOptionArgs()).pipe(
      map(res => this.parseResponse(res)));
  }

  bulkAssignMember(projectId, data) {
    const API = this.dataService.apiUrl + API_ROUTES.BULK_ASSIGN_MEMBER(projectId);
    return this.httpRequest
      .post(API, data, this.dataService.getRequestOptionArgs()).pipe(
      map(res => this.parseResponse(res)));
  }

  bulkAssignPlatforms(selectedStoriesIds, selectedPlatformIds, projectId) {
    const API = this.dataService.apiUrl + API_ROUTES.BULK_ASSIGN_PLATFORMS(projectId);
    return this.httpRequest
      .post(API, {story_ids: selectedStoriesIds, platform_ids: selectedPlatformIds}, this.dataService.getRequestOptionArgs()).pipe(
        map(res => this.parseResponse(res)));
  }

  getStoryDetail(projectId: number, storyId: number){
    const API = this.dataService.apiUrl + API_ROUTES.STORY(projectId, storyId) + '?story_detail=true'
    return this.httpRequest
      .get(API, {} , this.dataService.getRequestOptionArgs()).pipe(
      map(response => this.parseResponse(response)));
  }

  getMinRequiredKeys(story: Story, appendKeys: Array<string>) {
    let baseKeys = ['project_id', 'id']
    baseKeys = baseKeys.concat(appendKeys)
    let newObj = {}
    baseKeys.forEach((key: string) => {
      newObj[key] = story[key]
    })
    return newObj
  }

  updateStoryGeneric(storyMinObject, minimal = false, propName?) {
    this.story_start_confirmation = false;
    const API = this.dataService.apiUrl + API_ROUTES.STORY(storyMinObject.project_id, storyMinObject.id);
    const data = {
      story: storyMinObject,
      minimal: minimal
    }
    return this.httpRequest
      .put(API, data, this.dataService.getRequestOptionArgs()).pipe(
        map(response => this.parseStoryResponse(response)));
  }

  updateStory(story: Story, minimal = false, propName? ) {
    this.story_start_confirmation = false;
    const API = this.dataService.apiUrl + API_ROUTES.STORY(story.project_id, story.id);
    const data = { 
      story: propName === 'devicePlatformDetach' ? story.toJSONforPlatformDetach() : !this.disableStoryUpdateFlag? story.toJSONWithoutDescription() : story.toJSON(),
      minimal: minimal 
    };
    if (propName === 'devicePlatformDetach') {
      data['duplicate_sub_tasks'] = story.duplicate_sub_tasks;
      data['duplicate_parent_only'] = story.duplicate_parent_only;
      data['detached_platform_id'] = story.detached_platform_id;
    }
    return this.httpRequest
      .put(API, data, this.dataService.getRequestOptionArgs()).pipe(
      map(response => this.parseStoryResponse(response)));
  }

  syncCMTStory (story: Story) {
    const API = this.dataService.apiUrl + API_ROUTES.SYNC_STORY(story.project.id, story.id);
    const data = { story: story.toJSON()};
    return this.httpRequest
      .put(API, data, this.dataService.getRequestOptionArgs()).pipe(
      map(response => this.parseStoryResponse(response)));
  }

  archiveTestCase(projectId,storyId,testcase,minimal = false){
    const API = this.dataService.apiUrl + API_ROUTES.STORY(projectId, storyId);
    const data = { story: testcase, minimal: minimal };
    return this.httpRequest
      .put(API, data, this.dataService.getRequestOptionArgs()).pipe(
      map(response => this.parseStoryResponse(response)));
  }

  updateTestCase(testCaseData,storyID,project) {
    const API = this.dataService.apiUrl + API_ROUTES.STORY(project.id, storyID);
    const data = { story: testCaseData};
    return this.httpRequest
      .put(API, data, this.dataService.getRequestOptionArgs()).pipe(
      map(response => this.parseStoryResponse(response)));
  }

  getexpectedResult(storyID,project){
    const API = this.dataService.apiUrl + API_ROUTES.GET_EXPECTED_RESULTS(project.id, storyID);
    return this.httpRequest
      .get(API, {} , this.dataService.getRequestOptionArgs()).pipe(
      map(response => this.parseResponse(response)));
  }

  setexpectedResult(storyID,project,param){
    const API = this.dataService.apiUrl + API_ROUTES.SET_EXPECTED_RESULTS(project.id, storyID);
    return this.httpRequest
    .post(API, param, this.dataService.getRequestOptionArgs()).pipe(
    map(response => this.parseResponse(response)));
  }

  updateExpectedResult(storyID,project,data,resultID){
    const API = this.dataService.apiUrl + API_ROUTES.UPDATE_EXPECTED_RESULTS(project.id, storyID,resultID);
    return this.httpRequest
    .put(API, data, this.dataService.getRequestOptionArgs()).pipe(
    map(response => this.parseStoryResponse(response)));
  }

  deleteExpectedResult(storyID,project,resultID){
    const API = this.dataService.apiUrl + API_ROUTES.UPDATE_EXPECTED_RESULTS(project.id, storyID,resultID);
    return this.httpRequest
    .delete(API, this.dataService.getRequestOptionArgs()).pipe(map(response => this.parseResponse(response)));
  }


  updateStorySubtaskList(projectId, updateSubtaskListData, subtaskId, minimal = false) {
    const API = this.dataService.apiUrl + API_ROUTES.STORY(projectId, subtaskId);
    const data = { story: updateSubtaskListData, minimal: minimal };
    return this.httpRequest
      .put(API, data, this.dataService.getRequestOptionArgs()).pipe(
      map(response => this.parseStoryResponse(response)));
  }

  updateSprintStory(story: Story) {
    const API = this.dataService.apiUrl + API_ROUTES.STORY(story.project_id, story.id);
    const data = {
      story: story.toJSONForSprint()
    };
    return this.httpRequest
      .put(API, data, this.dataService.getRequestOptionArgs()).pipe(
        map(response => this.parseResponse(response).story));
  }
  
  cloneSprintStory(story: Story, apply_on_sub_tasks = false) {
    const API = this.dataService.apiUrl + API_ROUTES.STORY_COPY(story.project_id, story.id);
    return this.httpRequest
      .post(API, {position: story.position, apply_on_sub_tasks: apply_on_sub_tasks}, this.dataService.getRequestOptionArgs()).pipe(
        map(response => this.parseResponse(response)));
  }

  deleteStory(story: Story) {
    const API = this.dataService.apiUrl + API_ROUTES.STORY(story.project.id, story.id);
    return this.httpRequest
             .delete(API, this.dataService.getRequestOptionArgs()).pipe(
             map(res => story));
  }

  updateLabels(story: Story) {
    const API = this.dataService.apiUrl + API_ROUTES.LABEL_UPDATE(story.project.id, story.id);
    const data = {
      story: story.toJSONforLabels()
    };
    return this.httpRequest
      .put(API, data, this.dataService.getRequestOptionArgs()).pipe(
      map(response => this.parseResponse(response).story));
  }

  startTimer(project: Project, story: Story) {
    const API = this.dataService.apiUrl + API_ROUTES.STORY_START_TIMER(project.id, story.id);
    return this
      .httpRequest
      .put(
        API,
        { minimal: true },
        this.dataService.getRequestOptionArgs()
       ).pipe(
      map(response => {
        if (response.changes && Object.keys(response.changes).length !== 0) { return response; }
        let data = this.parseResponse(response).story;
        data.project = project;
        return new Story(data);
      }));
  }

  startReviewerTimer(project: Project, story: Story) {
    const API = this.dataService.apiUrl +  API_ROUTES.REVIEWER_START_TIMER(project.id, story.id);
    return this
      .httpRequest
      .put(
        API,
        { minimal: true },
        this.dataService.getRequestOptionArgs()
       ).pipe(
      map(response => {
        if (response.changes && Object.keys(response.changes).length !== 0) { return response; }
        let data = this.parseResponse(response).story;
        data.project = project;
        return new Story(data);
      }));
  }

  stopTimer(project: Project, story: Story, automatic = false) {
    const API = this.dataService.apiUrl +  API_ROUTES.STORY_STOP_TIMER(project.id, story.id);
    return this
      .httpRequest
      .put(API, { automatic: automatic, minimal: true }, this.dataService.getRequestOptionArgs()).pipe(
      map(response => {
        if (response.changes && Object.keys(response.changes).length !== 0) { return response; }
        let data = this.parseResponse(response).story;
        data.project = project;
        return new Story(data);
      }));
  }

  stopReviewerTimer(project: Project, story: Story, automatic = false) {
    const API = this.dataService.apiUrl +  API_ROUTES.REVIEWER_STOP_TIMER(project.id, story.id);
    return this
      .httpRequest
      .put(API, { automatic: automatic, minimal: true }, this.dataService.getRequestOptionArgs()).pipe(
      map(response => {
        if (response.changes && Object.keys(response.changes).length !== 0) { return response; }
        let data = this.parseResponse(response).story;
        data.project = project;
        return new Story(data);
      }));
  }

  // Clone Story.
  watchStories() {
    return this.getstories.asObservable();
  }

  // Blocker Story
  createBlocker(storyid, param) {
    const API = this.dataService.apiUrl +  API_ROUTES.Blocker_CREATE(storyid);
    return this.httpRequest
    .post(API, param, this.dataService.getRequestOptionArgs()).pipe(
    map(response => this.parseResponse(response)));
  }

  updateBlocker(storyid, blockerId, param) {
    const API = this.dataService.apiUrl +  API_ROUTES.Blocker_UPDATE(storyid, blockerId);
    return this.httpRequest
    .post(API, param, this.dataService.getRequestOptionArgs()).pipe(
    map(response => this.parseResponse(response)));
  }

  getBlocker(): Observable<any> {
    return this.getblocker.asObservable();
  }

  deleteBlocker(storyid, blockerId) {
    const API = this.dataService.apiUrl +  API_ROUTES.Blocker_DELETE(storyid, blockerId);
    return this.httpRequest.delete(API, this.dataService.getRequestOptionArgs()).pipe(map(response => this.parseResponse(response)));
  }

  addMergeRequest(projectId,storyId,mergeRequst){
    const API = this.dataService.apiUrl + API_ROUTES.ADD_MERGE_REQUEST(projectId,storyId);
    return this.httpRequest
    .post(API, {merge_request: mergeRequst}, this.dataService.getRequestOptionArgs()).pipe(
    map(res => this.parseResponse(res)));
  }

  deleteMergeRequest(projectId,storyId,commitId){
    const API = this.dataService.apiUrl + API_ROUTES.DELETE_MERGE_REQUEST(projectId,storyId,commitId);
    return this.httpRequest
    .delete(API, this.dataService.getRequestOptionArgs()).pipe(
    map(res => this.parseResponse(res)));
  }


  getSketchFiles(projectId, epicId) {
    const API = this.dataService.apiUrl + API_ROUTES.DOWNLOAD_SKETCH(projectId, epicId);
    return this.httpRequest
      .get(API, this.dataService.getRequestOptionArgs('', false, '', '', '', false, '', true));
  }

  sameFeaturePhaseStories(projectId, storyId, phase, nextPage?, searchStory?) {
    let api = this.dataService.apiUrl + API_ROUTES.STORIES_WITH_SAME_FEATURE_PHASE(projectId) + '?phases[]=' + encodeURIComponent(phase) + '&q=' + encodeURIComponent(searchStory) + '&page=' + encodeURIComponent(nextPage) + '&parent_story_id=' + encodeURIComponent(storyId);
    return this.httpRequest
            .get(api, this.dataService.getRequestOptionArgs()).pipe(
            map(res => this.parseResponse(res)));
  }
  moveSubtaskToAnotherTask (projectId, moveStoryData) {
    const API = this.dataService.apiUrl +  API_ROUTES.MOVE_SUBTASKS_TO_ANOTHER_TASK(projectId);
    return this.httpRequest
    .post(API, moveStoryData, this.dataService.getRequestOptionArgs()).pipe(
    map(response => this.parseResponse(response)));
  }

  requestTOReview (projectId, storyId, requestType) {
    const data = {
      story_approval: {
        story_id: storyId,
        request_type: requestType
    }
      
    };
    const API = this.dataService.apiUrl +  API_ROUTES.REQUEST_STORY_COMPARE(projectId);
    return this.httpRequest
    .post(API, data, this.dataService.getRequestOptionArgs()).pipe(
    map(response => this.parseResponse(response)));
  }

  relateStories(projectId, storyId, searchStory?) {
    let api = this.dataService.apiUrl + API_ROUTES.STORIES_TO_RELATE(projectId) + '?q=' + encodeURIComponent(searchStory);
    return this.httpRequest
            .get(api, this.dataService.getRequestOptionArgs()).pipe(
            map(res => this.parseResponse(res)));
  }


  readyToWork() {
    const payload = {
      data: {
        user_agreed: true,
        story_state: this.storyState
      }
    };
    this.notificationService.broadcast(EVENT_TYPES.STORY_CONFIRMATION.USER_CONFIRMED_STORY, payload);
    this.story_start_confirmation = false;
  }

  confirmOptions(story_type, storyState, expertAsQA) {
    this.storyState = storyState;
    if (story_type === 'task') {
      if(expertAsQA) {
        this.confirm_option_list = START_STORY_CONFIRM_OPTIONS['task_and_qa'];
      } else {
        this.confirm_option_list = START_STORY_CONFIRM_OPTIONS['task_and_not_qa'];
      }
    } else {
      if(expertAsQA) {
        this.confirm_option_list = START_STORY_CONFIRM_OPTIONS['bug_and_qa'];
      } else {
        this.confirm_option_list = START_STORY_CONFIRM_OPTIONS['bug_and_not_qa'];
      }
    }
    this.story_start_confirmation = true;
  }

  bulkUnarchive(projectId, selected_stories){
    const data = {
      'story_ids' : selected_stories,
    };
    const API = this.dataService.apiUrl + API_ROUTES.BULK_UNARCHIVE(projectId);
    return this.httpRequest
      .post(API, data, this.dataService.getRequestOptionArgs()).pipe(
      map(res => this.parseResponse(res)));
  }

  bulkChangeRoleCheck(projectId, selected_stories, role) {
    const data = {};
    data['story_ids[]'] = selected_stories;
    data['studio_role_id'] = role.studio_role.id;
    const API = this.dataService.apiUrl + API_ROUTES.VERIFY_CHANGE_ROLE(projectId);
    return this.httpRequest
      .get(API, {params : data} , this.dataService.getRequestOptionArgs()).pipe(
      map(res => this.parseResponse(res)));
  }

  bulkChangeRole(projectId, stories_selected, role) {
    const data = {};
    data['story_ids'] = stories_selected;
    data['studio_role_id'] = role.studio_role.id;
    const API = this.dataService.apiUrl + API_ROUTES.BULK_CHANGE_ROLE(projectId);
    return this.httpRequest
      .post(API, data , this.dataService.getRequestOptionArgs()).pipe(
      map(res => this.parseResponse(res)));
  }

  skipStoryAdding(story){
    return ((this.parentOrSubtaskStories === 'sub_tasks' && !story.parent_id) || (this.parentOrSubtaskStories === 'parent_stories' && story.parent_id));
  }

  addDemoVideo(project: Project, story: Story, params) {
    const API = this.dataService.apiUrl + API_ROUTES.DEMO_VIDEO(project.id, story.id);
    return this
      .httpRequest
      .post(
        API,
        params,
        this.dataService.getRequestOptionArgs()
      ).pipe(
        map(response => {
          return response;
        }));
  }

  generateChatGptResponse(project: Project, story: Story, description,notes) {
    const data = {};
    data['feature_description'] = description;
    data['feature_notes'] = notes;
    const API = this.dataService.apiUrl + API_ROUTES.GENERATE_USER_STORY(project.id, story.id);
    return this.httpRequest
      .get(API, {params : data} , this.dataService.getRequestOptionArgs()).pipe(
      map(res => this.parseResponse(res)));
  }

  triggerUIWDesignThresholdAPI(project: Project, story: Story) {
    const data = {};
    const API = this.dataService.apiUrl + API_ROUTES.GENERATE_UIW_DESIGN_THRESHOLD(project.id, story.id);
    return this.httpRequest
      .post(API, {forced:true} , this.dataService.getRequestOptionArgs()).pipe(
      map(res => this.parseResponse(res)));
  }
}
