import { BaseModel } from './baseModel';

export interface NotificationData {
  id?: number;
  kind?: string;
  emitter?: any;
  project?: any;
  story?: any;
  changes?: Array<any>;
  subject?: any;
  created_at?: string;
  expanded?: boolean;
  read_at?: any;
}

export class Notification extends BaseModel implements NotificationData {
  id: number;
  kind: string;
  emitter: any;
  project: any;
  changes?: Array<any>;
  story: any;
  subject: any;
  created_at: string;
  expanded: boolean = false;
  read_at?: any;

  constructor(data: any) {
    super(data);
    this.id = data.id;
    this.kind = data.kind;
    this.emitter = data.emitter;
    this.project = data.project;
    this.story = data.story;
    this.subject = data.subject;
    this.created_at = data.created_at;
    this.read_at = data.read_at;
  }

  getClassName(): string {
    return 'Notification';
  }
}

