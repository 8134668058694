import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SharedModule } from './../shared/shared.module';
import { DashboardComponent } from './dashboard.component';
import {FormsModule} from '@angular/forms';
// import { HighchartsChartModule } from 'highcharts-angular';
import { AdminModule } from '../admin/admin.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    FormsModule,
    // HighchartsChartModule,
    AdminModule,
    InfiniteScrollModule   
  ],
  declarations: [
    DashboardComponent
  ],
  exports: [
    DashboardComponent
  ]
})
export class DashboardModule { }
