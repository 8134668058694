import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { Project } from '../shared/models/project';
import { PolicyService } from '../shared/services/policy.service';
import { ProjectService } from '../shared/services/project.service';
import { NotificationService } from '../shared/services/notification.service';
import { ErrorService } from '../shared/errorFunction';
import {EVENT_TYPES, IntegrationKindData} from '../shared/dataTypes';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {CustomToastService} from '../shared/services/custom-toast.service';
import {Integration} from '../shared/models/integration';

@Component({
  selector: 'app-project-setting',
  templateUrl: './project-setting.component.html',
  styleUrls: ['./project-setting.component.scss'],
  host: {
    '(document:click)': 'onClick($event)',
  }
})
export class ProjectSettingComponent implements OnInit {
  openConfirmPopUp:boolean = false;
  waitForLoader:boolean = false;
  project: Project;
  projectId?: number;
  integration_kinds: Array<IntegrationKindData> = [];
  selectedIntegration: Integration;
  @ViewChild('focusURLInput') public focusURLInput: ElementRef;

  constructor(private projectService: ProjectService,
              private errorService: ErrorService,
              private policyService: PolicyService,
              private notificationService: NotificationService,
              private customToast: CustomToastService,
              private router: Router,
              private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.projectId = +params['id'];
    });

    this.projectService.project(this.projectId, true).subscribe(project => {
      this.project = project;
      this.notificationService.broadcast(EVENT_TYPES.PROJECT.SHOW, {
        data: {
          project: this.project
        }
      });
      if (this.projectService.current_user_role === 'read_only' || this.projectService.current_user_role === 'user') {
        this.router.navigate(['/projects/' + this.project.id]);
        return;
      }
    }, err => this.errorService.errorFunction(err));

    this.projectService.getProjectIntegrations(this.projectId).subscribe(res => {
      this.integration_kinds = [];
      for (const kind in res) {
        if (kind) {
          const data: IntegrationKindData = {
            'kind' : kind,
            'integrations' : []
          };
          this.integration_kinds.push(data);
          const index = this.integration_kinds.findIndex(item => item.kind === kind);
          if (index !== -1) {
            res[kind].forEach(item => this.integration_kinds[index].integrations.push(new Integration(item.integration)));
          }
        }
      }
    }, err => this.errorService.errorFunction(err));
  }

  insertIntegrationURL(integration) {
    integration.insertURL = true;
    integration.inputURL = '';
    setTimeout(() => {
      if (this.focusURLInput) {
        this.focusURLInput.nativeElement.focus();
      }
    }, 0);
  }

  addIntegration(integration) {
    if (!integration.isSaveDisable) {
      integration.isSaveDisable = true;
      this.projectService.addProjectIntegration(this.projectId, integration.inputURL.trim(), integration.kind).subscribe(res => {
        if (res && res.integration) {
          const index = this.integration_kinds.findIndex(item => item.kind === res.integration.kind);
          if (index !== -1) {
            const ind = this.integration_kinds[index].integrations.findIndex(item => item.resource_url === null);
            if (ind >= 0) {
              this.integration_kinds[index].integrations.splice(ind, 1, new Integration(res.integration));
            }
          }
        }
        integration.insertURL = false;
        integration.inputURL = '';
        this.customToast.messages.push({
          id: 'story_new',
          type: 'success',
          class: 'story_new',
          title: 'Integration Enabled',
          message: res.message
        });
        integration.isSaveDisable = false;
      }, err => {
        this.errorService.errorFunction(err);
        integration.isSaveDisable = false;
      });
    }
  }

  editIntegration(integration) {
    if (!integration.isSaveDisable) {
      integration.isSaveDisable = true;
      this.projectService.updateProjectIntegration(this.projectId, integration.id, integration.inputURL.trim(), integration.kind).subscribe(res => {
        if (res && res.integration) {
          const index = this.integration_kinds.findIndex(item => item.kind === res.integration.kind);
          if (index !== -1) {
            const ind = this.integration_kinds[index].integrations.findIndex(item => item.id === integration.id);
            if (ind >= 0) {
              this.integration_kinds[index].integrations.splice(ind, 1, new Integration(res.integration));
            }
          }
        }
        integration.insertURL = false;
        integration.inputURL = '';
        this.customToast.messages.push({
          id: 'story_new',
          type: 'success',
          class: 'story_new',
          title: 'Integration Updated',
          message: res.message
        });
        integration.isSaveDisable = false;
      }, err => {
        this.errorService.errorFunction(err);
        integration.isSaveDisable = false;
        integration.inputURL = integration.resource_url;
      });
    }
  }

  editURL(integration) {
    integration.insertURL = true;
    integration.inputURL = integration.resource_url;

    // removing locally added integration if any
    const index = this.integration_kinds.findIndex(item => item.kind === integration.kind);
    if (index !== -1) {
      if (this.integration_kinds[index].integrations.length > 1) {
        const ind = this.integration_kinds[index].integrations.findIndex(it => it.id === null);
        if (ind >= 0) {
          this.integration_kinds[index].integrations.splice(ind, 1);
        }
      }
    }
  }

  openDeletePopup(integration) {
    this.openConfirmPopUp = true;
    this.selectedIntegration = integration;
  }

  closeConfirmPopUp() {
    this.openConfirmPopUp = false;
    this.waitForLoader = false;
  }

  deleteIntegration() {
    this.waitForLoader = true;
    this.projectService.deleteProjectIntegration(this.projectId, this.selectedIntegration.id, this.selectedIntegration.kind).subscribe(res => {
      if (res && res.integration) {
        const index = this.integration_kinds.findIndex(item => item.kind === res.integration.kind);
        if (index !== -1) {
          if (this.integration_kinds[index].integrations.length === 1) {
            this.integration_kinds[index].integrations[0].id = null;
            this.integration_kinds[index].integrations[0].created_at = null;
            this.integration_kinds[index].integrations[0].resource_url = null;
          } else if (this.integration_kinds[index].integrations.length > 1) {
            const ind = this.integration_kinds[index].integrations.findIndex(item => item.id === this.selectedIntegration.id);
            if (ind >= 0) {
              this.integration_kinds[index].integrations.splice(ind, 1);
            }
          }
        }
      }
      this.closeConfirmPopUp();
      this.selectedIntegration.insertURL = false;
      this.selectedIntegration = undefined;
      this.customToast.messages.push({
        id: 'story_deleted',
        type: 'success',
        class: 'story_delete',
        title: 'Integration Deleted',
        message: res.message
      });
    }, err => {
      this.closeConfirmPopUp();
      this.errorService.errorFunction(err);
      this.selectedIntegration.insertURL = false;
    });
  }

  onClick(event) {
    const element = event.target;
    if (!(element.classList.contains('inputIntegration-link') || element.classList.contains('saveButton')
            || element.classList.contains('insertURL') || element.classList.contains('ifNotEnabled')
            || element.classList.contains('iconb-edit') || element.classList.contains('addMultipleUrl')
            || element.classList.contains('iconb-plus'))) {
      this.integration_kinds.forEach(item => {
        if (item.integrations.length === 1) {
          item.integrations[0].insertURL = false;
        } else if (item.integrations.length > 1) {
          const ind = item.integrations.findIndex(it => it.id === null);
          if (ind >= 0) {
            item.integrations.splice(ind, 1);
          }
          item.integrations.forEach(it => it.insertURL = false);
        }
      });
    }
  }

  checkForAddUrl(kind) {
    if (kind && kind === 'gitlab') {
      const index = this.integration_kinds.findIndex(item => item.kind === kind);
      if (index !== -1) {
        const ind = this.integration_kinds[index].integrations.findIndex(it => it.id === null);
        return (ind !== -1) ? false : true;
      }
      return false;
    } else {
      return false;
    }
  }

  addIntegrationLocally(kind) {
    const index = this.integration_kinds.findIndex(item => item.kind === kind);
    if (index !== -1) {
      this.integration_kinds[index].integrations.push(new Integration({
        'id' : null,
        'kind' : kind,
        'created_at' : null,
        'resource_url' : null
      }));
      this.insertIntegrationURL(this.integration_kinds[index].integrations[this.integration_kinds[index].integrations.length - 1]);
    }
  }
}
