import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'keyvalue'
  })

  export class KeyValuePipe implements PipeTransform {
    transform(value: any, args?: any[]): any {
      if (value == null) return null;
  
      const keys = Object.keys(value);
      const result = [];
  
      for (let i = 0; i < keys.length; i++) {
        result.push({ key: keys[i], value: value[keys[i]] });
      }
  
      return result;
    }
  }