import { Injectable, Inject, forwardRef, OnInit, Injector } from '@angular/core';
import { CustomToastService } from './services/custom-toast.service';
import { Router } from '@angular/router';
import { UserService } from './services/user.service';
import { map } from 'lodash';

@Injectable()
export class ErrorService implements OnInit {

  constructor(private customToast: CustomToastService,
              private injector:Injector,
              private userService: UserService,
            private router: Router) {

            }


  static count = 0;
  ngOnInit() {
    // this.toastyConfig.position = "bottom-right";
  }
  errorFunctionForBlob(blobError){
    const errorMessage = JSON.parse(blobError).errors[0];
    this.customToast.messages.push({id:"serverfail", type:"fail", class:"generic_alert",  title: 'Alert', message: errorMessage });
  }

  get errorFunction() {
    return (err) => {
      console.log('err', err);
      // err = err.json();
      let errorMessage = err ? (err.errors || err.error || err.messages || err.message || 'Oops something went wrong. Please try again!') : 'Oops something went wrong. Please try again!';

      errorMessage = String (errorMessage);
      if (errorMessage.trim().toLowerCase() !== 'session expired') {
        const messages = map(this.customToast.messages, 'message');
        if (messages.indexOf(errorMessage) < 0) {
         if (errorMessage.trim().toLowerCase().substring(0, 29) === 'a confirmation email was sent') {
            this.customToast.messages.push({id:"serverfail", type:"fail", class:"generic_alert",  title: 'Alert', message: errorMessage});
          }
          else if(errorMessage.trim().toLowerCase() != 'you need to sign in or sign up before continuing.') {
            this.customToast.messages.push({id:"serverfail", type:"fail", class:"import_fail",  title: 'Error', message: errorMessage});
          }
        }
      }

      if (errorMessage.trim().toLowerCase() === 'session expired' && ErrorService.count === 0) {
        this.customToast.messages.push({id:"serverfail", type:"fail", class:"generic_alert",  title: 'Alert', time: 5000, message: (errorMessage + ', you need to sign in before continuing.')});
          ErrorService.count++;
          this.userService.clearCurrentUser(false);
          this.userService.unSetCookie();
          this.userService.removeSignOutLocalStorage();
          this.router.navigate(['/noSession']);
        }
    };
  }
}
