import * as _ from 'lodash';
import { GUIDData, GUIDType } from '../dataTypes';
import { BaseModel } from './baseModel';
import { Story } from './story';
import { Project } from './project';
import { Epic } from './epic';
import { Membership } from './membership';

export class GUID {
  private data: Array<GUIDData> = [];

  static _instance: GUID;

  constructor() {
    if (!GUID._instance) {
      GUID._instance = this;
    }

    return GUID._instance;
  }

  static get instance() {
    return new GUID();
  }

  static setUIDForObject(object): BaseModel {
    return GUID.instance.setGUIDForObject(object);
  }

  setGUIDForObject(object): BaseModel {
    let [guid, selectedObject] = this.generateRandomGUID(object);
    const objectData: GUIDData = {
      type: object.getClassName(),
      object: selectedObject,
      guid: guid
    };

    if (!this.findByGUID(guid)) {
      this.data.push(objectData);
    }

    return selectedObject;
  }

  generateRandomGUID(object: BaseModel): [string, BaseModel] {
    let selectedObject = this.findExistingObject(object);

    if (selectedObject) {
      return [selectedObject.guid, selectedObject];
    } else {
      selectedObject = this.findByGUID(object.guid);

      if (selectedObject) {
        return [selectedObject.guid, selectedObject];
      } else {
        selectedObject = object;
        let c = "";
        let foundNewUID = false;

        while (!foundNewUID) {
          c = "";
          for (; c.length < 8;) c += Math.random().toString(36).substr(2, 1);
          if (!this.findByGUID(c)) {
            foundNewUID = true;
          }
        }

        selectedObject._guid = c;
        return [c, selectedObject];
      }
    }
  }

  findExistingObject(newObject) {
    if (!newObject.id) return null;

    let item = _.find(this.data, item => {
      return item.object.id === newObject.id &&
        item.type === newObject.getClassName();
    });

    return item ? item.object : null;
  }


  findDataByGUIID(uid: string): GUIDData {
    return _.find(this.data, item => {
      return item.guid === uid;
    });
  }

  findByGUID(uid: string): BaseModel {
    let data = this.findDataByGUIID(uid);

    return data ? data.object : null;
  }

  findStoryByGUID(uid: string): Story {
    let data = this.findDataByGUIID(uid);

    if (data && data.type === GUIDType.Story) {
      return data.object as Story;
    }

    return null;
  }

  findProjectByGUID(uid: string): Project {
    let data = this.findDataByGUIID(uid);

    if (data && data.type === GUIDType.Project) {
      return data.object as Project;
    }

    return null;
  }

  findEpicByGUID(uid: string): Epic {
    let data = this.findDataByGUIID(uid);

    if (data && data.type === GUIDType.Epic) {
      return data.object as Epic;
    }

    return null;
  }

  findEpicByID(id: number): Epic {
    let data = _.find(this.data, item => {
      return item.object.id === id && item.type === GUIDType.Epic;
    });

    if (data) {
      return data.object;
    }
  }

  findProjectByID(id: number): Project {
    let data = _.find(this.data, item => {
      return item.object.id === id && item.type === GUIDType.Project;
    });

    if (data) {
      return data.object;
    }
  }

  unsetProjectById(id: number) {
   this.data = this.data.filter((res) => {
      return res.object.id != id && res.type === GUIDType.Project;
    })
  }

  findStoryByID(id: number): Story {
    let data = _.find(this.data, item => {
      return item.object.id === id && item.type === GUIDType.Story;
    });

    if (data) {
      return data.object;
    }
  }

  findUserByID(id: any, from?: any) {
    //This is for user who owned the story, which is array
    // if (from && from == 'ownedBy') {
    //   let data = [];
    //   id.forEach(element => {
    //     data.push(_.find(this.data, item => {
    //       return item.object.id === element && item.type === GUIDType.User;
    //     }));
    //   });
    //   if (data) {
    //     return data;
    //   }
    // } else {
      let data = _.find(this.data, item => {
        return item.object.id === id && item.type === GUIDType.User;
      });

      if (data) {
        return data.object;
      }
    // }
  }

  findPartnerByID(id: any, from?: any) {
    //This is for user who owned the story, which is array
    // if (from && from == 'ownedBy') {
    //   let data = [];
    //   id.forEach(element => {
    //     data.push(_.find(this.data, item => {
    //       return item.object.id === element && item.type === GUIDType.User;
    //     }));
    //   });
    //   if (data) {
    //     return data;
    //   }
    // } else {
      let data = _.find(this.data, item => {
        return item.object.id === id && item.type === GUIDType.Partner;
      });

      if (data) {
        return data.object;
      }
    // }
  }

  findMembershipByID(id: number): Membership {
    let data = _.find(this.data, item => {
      return item.object.id === id && item.type === GUIDType.Membership;
    });

    if (data) {
      return data.object;
    }
  }

}
