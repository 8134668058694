import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SharedModule } from './../shared/shared.module';
import { TestSuiteComponent } from './test-suite.component';
import {FormsModule} from '@angular/forms';
import { ClipModule } from 'ng2-clip';
import { ProjectModule } from './../project';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';


@NgModule({
	declarations: [
		TestSuiteComponent
	],
	imports: [
		InfiniteScrollModule,
		CommonModule,
		RouterModule,
		SharedModule,
		FormsModule,
		ClipModule,
		ProjectModule
	],
	exports: [TestSuiteComponent],
})
export class TestSuiteModule { }
