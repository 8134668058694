import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ReleasePlannerService } from '../../shared/services/release-planner.service';
import { ReleasePlannerModal } from '../../shared/models/releasePlannerModal';
import { ErrorService } from '../../shared/errorFunction';
import { SharedService } from '../../shared/services/shared.service';
import { CustomToastService } from '../../shared/services/custom-toast.service';
import { ReleaseDetailModel } from '../../shared/models/releaseDetailModel';
import { DomSanitizer } from '@angular/platform-browser';
import { PolicyService } from '../../shared/services/policy.service';
import { NotificationService } from '../../shared/services/notification.service';
import { EVENT_TYPES } from '../../shared/dataTypes';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';

@Component({
  selector: 'app-release-note',
  templateUrl: './release-note.component.html',
  styleUrls: ['./release-note.component.scss']
})
export class ReleaseDetailComponent implements OnInit {
  @Output() closeReleaseDetailPopup = new EventEmitter<boolean>();
  @Output() release_detail_data = new EventEmitter<any>();
  @Input() project;
  @Input() release_detail_for;
  @Input() release_detail_added_data;
  @Input() release;
  action_for:boolean = false;
  waitForLoader: boolean = false;
  attachOnlyArr = [];
  attachVideoOnly: any;
  attachmentPreviewUrls = [];
  editAttachment = [];
  release_detail;
  create_release_detail = {
    id: null,
    body: '',
    credentials: '',
    known_issues: '',
    coming_soon: '',
    documents_attributes: [],
    feature_notes_attributes: [{
      note_type : '',
      body: '',
      documents_attributes: [{
        name: '',
        attachment: '',
        content_type: '',
      }]
    }]
  };
  temp_release_detail;
  showVideo:boolean = false;
  vURL = '';
  real_time_update: Subscription;
  showImageModal: boolean = false;
  openImage_url: string = '';

  constructor(
    public releasePlannerService: ReleasePlannerService,
    private errorService: ErrorService,
    public policyService: PolicyService,
    public sharedService: SharedService,
    private customToast: CustomToastService,
    private domSanitizer: DomSanitizer,
    private notificationService: NotificationService
  ) { }

  ngOnInit() {
    if(this.release_detail_for === 'create' && !this.release_detail_added_data) {
      this.release_detail = new ReleaseDetailModel(this.create_release_detail);
    }

    if((this.release_detail_for === 'release_edit' || this.release_detail_for === 'edit' || this.release_detail_for === 'show') && !this.release_detail_added_data) {
      this.loadReleaseDetail();
    }

    if(this.release_detail_added_data) {
      this.release_detail = new ReleaseDetailModel(this.release_detail_added_data);
      this.attachVideoOnly = this.release_detail.documents_attributes[0];
    }

    this.real_time_update = this.notificationService.subscribeEvent(EVENT_TYPES.release_detail.release_detail_UPDATE, res => {
      if (res && res.payload.changes && this.release_detail_for === 'show') {
        const data = res.payload.changes;
        const existing_release_detail = this.release_detail;
        Object.keys(data).forEach(key => {
          if(key === 'attachments' && key.length > 0) {
            data.attachments.forEach( item => {
              this.attachVideoOnly = item;
            })
          }
          existing_release_detail[key] = data[key];
        });
      }
    });
  } 

  ngOnDestroy() {
    this.real_time_update.unsubscribe();   
  }

  //create function to update release note
  updateReleaseDetail() {
    this.waitForLoader = true;
    if(this.attachVideoOnly){
      this.release_detail.documents_attributes = [];
      this.release_detail.documents_attributes.push(this.attachVideoOnly);
    }
    const formData = this.releasePlannerService.prepareReleaseDetailUpdateFormData(this.release, this.release_detail, this.attachVideoOnly);
    this.releasePlannerService.updateReleaseDetail(
      this.project, this.release.id, formData, this.release_detail.id
    ).subscribe(res => {
      if(res) {
        this.customToast.messages.push({
          id: 'release_updated',
          type: 'success',
          class: 'stories_moved',
          title: 'Release Note',
          message: 'Release Note updated successfully'
        });
        this.waitForLoader = false;
        this.closeReleaseDetailPopup.emit(false);
      }
    }, (error) => {
      this.waitForLoader = false;
      this.errorService.errorFunction(error);
    });
  }

  releaseEdit() {
    this.release_detail_for = 'release_detail_edit';
  }

  loadReleaseDetail() {
    this.waitForLoader = true;
    this.releasePlannerService.getReleaseDetail(this.project, this.release.id).subscribe(res => {
      if(res) {
        this.release_detail = new ReleaseDetailModel(res.data);
        this.waitForLoader = false;
        if(res.data.attachments.length > 0) {
          this.release_detail.attachments.forEach( item => {
            this.attachVideoOnly = item;
          })
        }
        this.temp_release_detail = _.cloneDeep(this.release_detail);
      }
    }, (error) => {
      this.waitForLoader = false;
      this.errorService.errorFunction(error);
    });
  }

  closeReleaseDetail() {
    this.release_detail_for = '';
    this.closeReleaseDetailPopup.emit(false);
  }

  addNote() {
    if(this.attachVideoOnly){
      this.release_detail.documents_attributes = [];
      this.release_detail.documents_attributes.push(this.attachVideoOnly);
    }
    this.release_detail_data.emit(this.release_detail);
    this.closeReleaseDetailPopup.emit(false);
  }

  attachVideosOnly(event) {
    Array.from(event.target.files).forEach(file => {
      this.attachVideoOnly = file;
    });
  }

  attachFilesOnly(event) {
    Array.from(event.target.files).forEach(file => {
      this.attachOnlyArr.push(file);
    });
  }

  attachFilesForFeatureNote(event, index) {
    Array.from(event.target.files).forEach(file => {
      this.release_detail.feature_notes[index].documents_attributes.push(file);
    });
  }

  removeSavedAttachment(index, attach_pos, attachment) {
    const pos = this.release_detail.feature_notes[index].attachments.findIndex(item => item === attachment);
    if (pos !== -1) {
      this.release_detail.feature_notes[index].attachments.splice(attach_pos, 1);
      this.release_detail.feature_notes[index].for_removal.push(attachment);
    }
  }

  removeNewAddedAttachment(index, doc_pos) {
    this.release_detail.feature_notes[index].documents_attributes.splice(doc_pos, 1);
  }

  bypassSanitization(html: string) {
    return this.domSanitizer.bypassSecurityTrustHtml(html);
  }

  openImagePopup(attachment) {
    this.showImageModal = true;
    this.openImage_url = attachment.remote_url;
  }

  closeImagePopup() {
    this.showImageModal = false;
    this.openImage_url = '';
  }

  openAttachmentPopup(attachment) {
    if (attachment && attachment.content_type) {
      const type = attachment.content_type.split('/')[0];
      if (type === 'image') {
        this.openImagePopup(attachment);
      } else if (type === 'video') {
        this.openVideoPopup(attachment);
      } else if (type === 'application/pdf') {
        window.open(attachment.remote_url, '_blank');
      }
    }
  }

  openVideoPopup(item) {
    if(item && item.remote_url) {
      this.showVideo = true;
      this.vURL = item.remote_url;
    }
  }
  closeVideoPopup() {
    this.showVideo = false;
    this.vURL = '';
  }

}