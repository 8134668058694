<div class="authMainContainer">

  <div class="rightSection-register">
    <div class="logo"><img src="../../../assets/images/trackerLogo2.png" alt="eTracker" class="head-logo"></div>
    <div class="sign-box-center">
      <div class="auth-title2">Sign Up</div>
      <div class="authForm">
          <form #registerForm="ngForm" (ngSubmit)="onSubmit()">
            <div class="authForm-block">
              <span class="inputLabel">Full name<span>*</span></span>
              <input #name = "ngModel" type="text" class="authInput" placeholder="Full Name" required [(ngModel)]="registerData.name" name="name" required>
              <span class="validation-msg" *ngIf = "name && name.errors && (name.touched || name.dirty)">Name is required.</span>
            </div>

            <div class="authForm-block">
              <span class="inputLabel">Initials<span>*</span></span>
              <input type="text" #initials = "ngModel" class="authInput" placeholder="Initials" required [(ngModel)]="registerData.initials" name="initials" required>
              <span class="validation-msg" *ngIf = "initials && initials.errors && (initials.touched || initials.dirty)">Initials is required.</span>
            </div>

            <div class="authForm-block">
              <span class="inputLabel">Email address<span>*</span></span>
              <input type="text" class="authInput" placeholder="Email" disabled [(ngModel)]="registerData.email" name="email">
              <span class="validation-msg" *ngIf = "output && output.email">{{output?.email[0]}}</span>
            </div>

            <div class="authForm-block">
              <span class="inputLabel">Password<span>*</span></span>
              <input type="{{showLoginPassword ? 'text':'password'}}" #password = "ngModel" class="authInput" placeholder="Password" required
                     [(ngModel)]="registerData.password" name="password" required>
              <span class="reveal-conceal" [ngClass]="{'showText': showLoginPassword}" (click) = "showPassword($event)">
                <em class="iconb-view-tasks"></em>
              </span>
              <span class="validation-msg" *ngIf = "password && password.errors && (password.touched || password.dirty)">Password is required.</span>
              <span class="validation-msg"  *ngIf = "output && output.password">{{output?.password[0]}}</span>
            </div>

            <div class="authForm-block">
              <span class="inputLabel">Password Confirmation<span>*</span></span>
              <input type="password"  #password_confirmation = "ngModel" class="authInput" placeholder="Password Confirmation" required [(ngModel)]="registerData.password_confirmation" name="password_confirmation" required>
              <span class="validation-msg" *ngIf = "password_confirmation && password_confirmation.errors && (password_confirmation.touched || password_confirmation.dirty)">Password Confirmation is required.</span>
              <span class="validation-msg" *ngIf = "output && output.password_confirmation">{{output?.password_confirmation[0]}}</span>
            </div>

            <button type="submit" class="submitAuth" [disabled]="!registerForm.form.valid">Signup</button>
            <div class="fgtBlock2">Already have an account? <a routerLink="/login" class="forgotPassword-link">Login</a></div>
          </form>
      </div>
    </div>
  </div>
  <div class="leftSection">
    <!--<img src="../../../assets/images/builderTracker_W_T.png" alt="eTracker" class="head-logo">-->
  </div>

</div>
