<div class="storyBoardPanel-Loader" *ngIf="startLoader">
  <img src="../../assets/images/storyBoardLoader.gif" alt=""/>
</div>

<div class="adminDashboard-wrapper">
  <div class="topSection">
    <div class="topCate-section" routerLink="../projects">
      <span class="topCate-icon"><em class="iconb-total-activeuser"></em></span>
      <span class="topCate-desc">
        <span class="topCate-descTitle">Total Projects</span>
        <span class="topCate-counter">{{details?.total_projects}}</span>
      </span>
    </div>
    <div class="topCate-section" routerLink="../users">
      <span class="topCate-icon"><em class="iconb-total-user"></em></span>
      <span class="topCate-desc">
        <span class="topCate-descTitle">Total Members</span>
        <span class="topCate-counter">{{details?.total_users}}</span>
      </span>
    </div>
  </div>

  <div class="recentProjectAnduser-wrapper">
    <div class="recentprojectsList-wrapper">
      <div class="topHeading">Recent Active Members </div>
      <div class="recentActivityList-wrapper">
        <div class="recentActivity-listing" *ngFor="let user of users">
          <span class="membersName">{{(user.name && user.name != '' ? user.name: user.email)}}</span>
          <a class="goTo-user" [href]="userPath(user)">view</a>
        </div>
      </div>
    </div>
    <div class="usersList-wrapper">
      <div class="topHeading">Recently Opened Projects</div>
      <div class="recentActivityList-wrapper">
        <div class="recentActivity-listing" *ngFor="let project of projects">
          <span class="membersName">{{project.name}}</span>
          <a class="goTo-user" [href]="projectPath(project)">view</a>
        </div>
      </div>
    </div>
  </div>
</div>
