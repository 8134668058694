<ng-container *ngIf="isTimerAvailable && type == 'owner'">
  <div class="timerLoader" *ngIf="timerLoader">
    <img src="../../../../assets/images/dot-loader.gif" alt="loader" />
  </div>
  <span *ngIf="trackedStory" class="timer">
    <a *ngIf="isTrackAvailable()" class="timerActionBtn">
      <div class="ifReadOnly" *ngIf="story.inDraft() || timerExceeded('owner') || isStoryOwnerDifferent('owner') || checkForBBStatus()"></div>
      <em class="iconb-play" (click)="checkStartForOtherStory('owner')"></em>
      <span class="elementHover-extra" *ngIf="timerExceeded('owner')">You have reached the allotted time limit. Please speak with the Delivery Manager to resume.</span>
      <span class="elementHover-extra" *ngIf="story.inDraft()">Story is in Draft as some information is missing. Please speak with the Delivery Manager to resume.</span>
    </a>
    <a *ngIf="isStopAvailable()" class="timerActionBtn" (click)="stopTracking(trackedStory)">
      <em class="iconb-pause-small"></em>
    </a>
    <span class="timerActionBtn-default"><em class="iconb-timer"></em></span>
    <span *ngIf = "trackedStory.current_timer_activity">{{ timer ? timer : '00:00:00' }}</span>
    <span *ngIf = "(trackedStory.timer_elapsed_total) && !(trackedStory.current_timer_activity)">{{ ownerElapsed ? ownerElapsed : timer }}</span>
    <span *ngIf = "(!trackedStory.timer_elapsed_total) && !(trackedStory.current_timer_activity)">00:00:00</span>
  </span>
</ng-container>

<ng-container *ngIf="isTimerAvailable && type == 'reviewer'">
  <div class="timerLoader" *ngIf="timerLoader">
    <img src="../../../../assets/images/dot-loader.gif" alt="loader" />
  </div>
  <span *ngIf="trackedStory" class="timer">
    <a *ngIf="isTrackReviewerAvailable()" class="timerActionBtn">
      <div class="ifReadOnly" *ngIf="story.inDraft() || timerExceeded('reviewer') || isStoryOwnerDifferent('reviewer') || checkForBBStatus()"></div>
      <em class="iconb-play" (click)="checkStartForOtherStory('reviewer')"></em>
      <span class="elementHover-extra" *ngIf="timerExceeded('reviewer')">You have reached the allotted time limit. Please speak with the Delivery Manager to resume.</span>
      <span class="elementHover-extra" *ngIf="story.inDraft()">Story is in Draft as some information is missing. Please speak with the Delivery Manager to resume.</span>
    </a>
    <a *ngIf="isReviewerStopAvailable()" class="timerActionBtn" (click)="stopReviewerTracking()">
      <em class="iconb-pause-small"></em>
    </a>
    <span class="timerActionBtn-default"><em class="iconb-timer"></em></span>
    <span *ngIf = "trackedStory.current_reviewer_timer_activity">{{ reviewer_timer ? reviewer_timer : '00:00:00' }}</span>
    <span *ngIf = "(trackedStory.reviewer_elapsed_time_total) && !(trackedStory.current_reviewer_timer_activity)">{{reviewerElapsed?reviewerElapsed:reviewer_timer}}</span>
    <span *ngIf = "(!trackedStory.reviewer_elapsed_time_total) && !(trackedStory.current_reviewer_timer_activity)">00:00:00</span>
  </span>
</ng-container>

<ng-container *ngIf="isTimerAvailable && type == 'total'">
  <span *ngIf="trackedStory" class="timer">
    <span *ngIf = "this.story.current_reviewer_timer_activity || this.story.current_timer_activity">{{ total_timer?total_timer:total_story_time }}</span>
    <span *ngIf = "(this.story.timer_elapsed_total || this.story.reviewer_elapsed_time_total) && !(this.story.current_reviewer_timer_activity || this.story.current_timer_activity)">{{ total_elapsed_timer?total_elapsed_timer:total_timer }}</span>
    <span *ngIf = "!(this.story.timer_elapsed_total || this.story.reviewer_elapsed_time_total) && !(this.story.current_reviewer_timer_activity || this.story.current_timer_activity)">{{ total_story_time }}</span>
  </span>
</ng-container>


<!--<app-story-progress  *ngIf = "enterStoryProgress" (progressEmit) = "stopTracking($event)" [story] = "story" [storyProgress] = "storyProgress" ></app-story-progress>-->
