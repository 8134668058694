<div class="filterWrapper" *ngIf="project">
    <div class="resetFilter-block">
      <div class="filterCounter">{{project ? project.filterCount : 0}} {{(project && project.filterCount !== 1) ? 'Filters' : 'Filter'}} selected</div>
      <button type="button" class="resetFilter-button" (click)="clearAllFilters()" [disabled]="project && ( project.filterCount === 0 || projectService.startApplyFilterLoader)">Clear all</button>
    </div>
    <div class="filterOptions-type" *ngIf="projectService.initiate_from !== 'from_release'">
      <div class="filterOptions-typeListing" [ngClass]="{'selected': showSelectedFilterType==='phase'}" (click)="showSelectedFilterType='phase'">
        <span class="typeList-name">Phase</span>
        <span class="typeList-counter">{{ project.filters?.phase && project.filters.phase.length > 0 ? project.filters?.phase.length : 0  }}</span>
      </div>
      <div class="filterOptions-typeListing" [ngClass]="{'selected': showSelectedFilterType==='story_type'}" (click)="showSelectedFilterType='story_type'">
        <span class="typeList-name">Story Type</span>
        <span class="typeList-counter">{{ project.filters?.story_type  && project.filters?.story_type.length >0 ? project.filters?.story_type.length : 0 }}</span>
      </div>
      <div class="filterOptions-typeListing" [ngClass]="{'selected': showSelectedFilterType==='states'}" (click)="showSelectedFilterType='states'">
        <span class="typeList-name">Status</span>
        <span class="typeList-counter">{{ project.filters?.states  && project.filters?.states.length >0 ? project.filters?.states.length : 0 }}</span>
      </div>
      <div class="filterOptions-typeListing" *ngIf="project?.dashboard_project_id && project?.supports_bb" [ngClass]="{'selected': showSelectedFilterType==='bb_status'}" (click)="showSelectedFilterType='bb_status'">
        <span class="typeList-name">BB Code</span>
        <span class="typeList-counter">{{ project.filters?.bb_status  && project.filters?.bb_status.length >0 ? project.filters?.bb_status.length : 0 }}</span>
      </div>
      <div class="filterOptions-typeListing" *ngIf="project?.dashboard_project_id && project?.supports_bb" [ngClass]="{'selected': showSelectedFilterType==='bb_design'}" (click)="showSelectedFilterType='bb_design'">
        <span class="typeList-name">BB Design</span>
        <span class="typeList-counter">{{ project.filters?.bb_design  && project.filters?.bb_design.length >0 ? project.filters?.bb_design.length : 0 }}</span>
      </div>
      <div class="filterOptions-typeListing" *ngIf="project?.dashboard_project_id && project.studio_roles.length >0" [ngClass]="{'selected': showSelectedFilterType==='studio_roles'}" (click)="showSelectedFilterType='studio_roles'">
        <span class="typeList-name">Roles</span>
        <span class="typeList-counter">{{ project.filters?.studio_roles  && project.filters?.studio_roles.length >0 ? project.filters?.studio_roles.length : 0 }}</span>
      </div>
      <div class="filterOptions-typeListing" *ngIf="project?.isExternalProject()" [ngClass]="{'selected': showSelectedFilterType==='dev_type'}" (click)="showSelectedFilterType='dev_type'">
        <span class="typeList-name">Skill Type</span>
        <span class="typeList-counter">{{ project.filters?.dev_type  && project.filters?.dev_type.length >0 ? project.filters?.dev_type.length : 0 }}</span>
      </div>
      <div class="filterOptions-typeListing" [ngClass]="{'selected': showSelectedFilterType==='member'}" (click)="showSelectedFilterType='member'">
        <span class="typeList-name">Member</span>
        <span class="typeList-counter">{{ project.filters?.member && project.filters?.member.length > 0 ? project.filters?.member.length : 0 }}</span>
      </div>
      <div class="filterOptions-typeListing" [ngClass]="{'selected': showSelectedFilterType==='owner'}" (click)="showSelectedFilterType='owner'">
        <span class="typeList-name">Owner</span>
        <span class="typeList-counter">{{ project.filters?.owner  && project.filters?.owner.length >0 ? project.filters?.owner.length : 0 }}</span>
      </div>
      <div class="filterOptions-typeListing" [ngClass]="{'selected': showSelectedFilterType==='reviewer'}" (click)="showSelectedFilterType='reviewer'">
        <span class="typeList-name">Reviewer</span>
        <span class="typeList-counter">{{ project.filters?.reviewer  && project.filters?.reviewer.length >0 ? project.filters?.reviewer.length : 0 }}</span>
      </div>
      <div class="filterOptions-typeListing" [ngClass]="{'selected': showSelectedFilterType==='requester'}" (click)="showSelectedFilterType='requester'">
        <span class="typeList-name">Requester</span>
        <span class="typeList-counter">{{ project.filters?.requester  && project.filters?.requester.length >0 ? project.filters?.requester.length : 0 }}</span>
      </div>
      <div class="filterOptions-typeListing" [ngClass]="{'selected': showSelectedFilterType==='label'}" (click)="showSelectedFilterType='label'">
        <span class="typeList-name">Label</span>
        <span class="typeList-counter">{{ project.filters?.label && project.filters?.label.length > 0 ? project.filters?.label.length : 0}}</span>
      </div>
      <div class="filterOptions-typeListing" [ngClass]="{'selected': showSelectedFilterType==='sprints'}" (click)="getSprints()">
        <span class="typeList-name">Sprints </span>
        <span class="typeList-counter">{{ project.filters?.sprints && project.filters?.sprints.length > 0 ? project.filters?.sprints.length : 0}}</span>
      </div>
      <div class="filterOptions-typeListing" *ngIf="project?.isExternalProject() && project.swimlane_count >= 0" [ngClass]="{'selected': showSelectedFilterType==='swimlane'}" (click)="showSelectedFilterType='swimlane'">
        <span class="typeList-name">Swimlane</span>
        <span class="typeList-counter">{{ project.filters?.swimlane && project.filters?.swimlane.length > 0 ? project.filters?.swimlane.length : 0 }}</span>
      </div>
      <div class="filterOptions-typeListing" [ngClass]="{'selected': showSelectedFilterType==='feature'}" (click)="showSelectedFilterType='feature'">
        <span class="typeList-name">Feature</span>
        <span class="typeList-counter">{{ project.filters?.feature && project.filters?.feature.length > 0 ? project.filters?.feature.length : 0 }}</span>
      </div>
      <div class="filterOptions-typeListing" *ngIf="project && project.hasProjectPlatforms()" [ngClass]="{'selected': showSelectedFilterType==='deviceAndPlatform'}" (click)="showSelectedFilterType='deviceAndPlatform'">
        <span class="typeList-name">Devices & Platforms</span>
        <span class="typeList-counter">{{ project.filters?.platform && project.filters.platform.length > 0 ? project.filters?.platform.length : 0  }}</span>
      </div>
      <div class="filterOptions-typeListing" [ngClass]="{'selected': showSelectedFilterType==='priority'}" (click)="showSelectedFilterType='priority'">
        <span class="typeList-name">Priority</span>
        <span class="typeList-counter">{{ project.filters?.priority && project.filters.priority.length > 0 ? project.filters?.priority.length : 0  }}</span>
      </div>
      <div class="filterOptions-typeListing" *ngIf="project?.dashboard_project_id" [ngClass]="{'selected': showSelectedFilterType==='created_from'}" (click)="showSelectedFilterType='created_from'">
        <span class="typeList-name">Created From</span>
        <span class="typeList-counter">{{ project.filters?.created_from && project.filters?.created_from.length > 0 ? project.filters?.created_from.length : 0  }}</span>
      </div>
      <div class="filterOptions-typeListing" *ngIf="project?.dashboard_project_id" [ngClass]="{'selected': showSelectedFilterType==='code_commit'}" (click)="showSelectedFilterType='code_commit'">
        <span class="typeList-name">Code Commit</span>
        <span class="typeList-counter">{{ project.filters?.code_commit && project.filters?.code_commit.length > 0 ? project.filters?.code_commit.length : 0  }}</span>
      </div>
      <div class="filterOptions-typeListing" [ngClass]="{'selected': showSelectedFilterType==='date_created'}" (click)="getSelectedFilterTypeW('date_created')">
        <span class="typeList-name">Date Created</span>
        <span class="typeList-counter">{{ project.filters?.created_on_date_range && project.filters?.created_on_date_range.length > 0 ? project.filters?.created_on_date_range.length : 0  }}</span>
      </div>
      <div class="filterOptions-typeListing" [ngClass]="{'selected': showSelectedFilterType==='status_updated'}" (click)="getSelectedFilterTypeW('status_updated')">
        <span class="typeList-name">Status Updated</span>
        <span class="typeList-counter">{{ project.filters?.state_changed_at_date_range && project.filters?.state_changed_at_date_range.length > 0 ? project.filters?.state_changed_at_date_range.length : 0  }}</span>
      </div>
    </div>

    <div class="filterOptions-type" *ngIf="projectService.initiate_from === 'from_release'">
      <div class="filterOptions-typeListing" [ngClass]="{'selected': showSelectedFilterType==='statuses'}" (click)="showSelectedFilterType='statuses'">
        <span class="typeList-name">Status</span>
        <span class="typeList-counter">{{ project.filters?.statuses && project.filters.statuses.length > 0 ? project.filters?.statuses.length : 0  }}</span>
      </div>
      <div class="filterOptions-typeListing" [ngClass]="{'selected': showSelectedFilterType==='rating'}" (click)="showSelectedFilterType='rating'">
        <span class="typeList-name">Rating</span>
        <span class="typeList-counter">{{ project.filters?.rating && project.filters.rating.length > 0 ? project.filters?.rating.length : 0  }}</span>
      </div>
      <div class="filterOptions-typeListing" [ngClass]="{'selected': showSelectedFilterType==='release_features'}" (click)="showSelectedFilterType='release_features'">
        <span class="typeList-name">Features</span>
        <span class="typeList-counter">{{ project.filters?.feature && project.filters.feature.length > 0 ? project.filters?.feature.length : 0  }}</span>
      </div>
      <div class="filterOptions-typeListing" [ngClass]="{'selected': showSelectedFilterType==='ship_date'}" (click)="getSelectedFilterTypeW('ship_date')">
        <span class="typeList-name">Ship Date</span>
        <span class="typeList-counter">{{ project.filters?.ship_release_date_range && project.filters.ship_release_date_range.length > 0 ? project.filters?.ship_release_date_range.length : 0  }}</span>
      </div>
    </div>

    <!-------------this is for release rating list block starts--------------->
    <div class="filterOptions-typeBock" *ngIf = "showSelectedFilterType === 'rating'">
      <div class="typeBock-listing" *ngFor="let rating of release_rating_list">
        <span class="typeBock-check">
          <label class="customCheckForStorySelection">
            <input id="'rating_' + {{rating.name}}" type="checkbox" class="story-selected-checkbox" [disabled]="projectService.startApplyFilterLoader" (change)="filterStoryService.applyFilter(project, $event, 'rating', rating.value)" [checked]="isFilterChecked('rating', rating.value)" hidden/>
            <span class="check-toggle"></span>
          </label>
        </span>
        <label for="'rating_' + {{rating.name}}" class="typeBock-name typeBock-label">{{rating.name}}</label>
      </div>
    </div>
    <!-------------this is for release rating block Ends--------------->

    <!-------------this is for release status block starts--------------->
    <div class="filterOptions-typeBock" *ngIf = "showSelectedFilterType === 'statuses'">
      <div class="typeBock-listing" *ngFor="let status of statusOptionList">
        <span class="typeBock-check">
          <label class="customCheckForStorySelection">
            <input id="{{status.value}}" type="checkbox" class="story-selected-checkbox" [disabled]="projectService.startApplyFilterLoader" (change)="filterStoryService.applyFilter(project, $event, 'statuses', status.value)" [checked]="isFilterChecked('statuses', status.value)" hidden/>
            <span class="check-toggle"></span>
          </label>
        </span>
        <label for="{{status.value}}" class="typeBock-name typeBock-label">{{status.name}}</label>
      </div>
    </div>
    <!-------------this is for release status block Ends--------------->


    <!-------------this is for Ship release date block starts--------------->
    <div class="filterOptions-typeBock" *ngIf = "showSelectedFilterType === 'ship_date'">
      <div class="typeBock-listing">
        <div class="selectDate-wrapper filterCal">
          <span class="status-label">Select Date</span>
          <input type="text"
                ngxDaterangepickerMd
                class="dateRagne-input" 
                [autoApply]="false"
                [(ngModel)]="project.selectedUpdateDate"
                [alwaysShowCalendars]="true"
                [ranges]="ranges"
                [showDropdowns]="true"
                (datesUpdated)="datesUpdated($event,'ship_release_date_range')"
                [linkedCalendars]="false"
                placeholder="Select please..."/>
          <em class="iconb-calendar dateIcon"></em>
          <button type="button" *ngIf="project.selectedUpdateDate && project.selectedUpdateDate?.startDate" class="dateClearButton" (click)="clearDate($event,'ship_release_date_range')">
            <em class="iconb-close"></em>
          </button>
        </div>
      </div>
    </div>
    <!-------------this is for Ship release date block Ends--------------->

    <!-------------this is for date updated block starts--------------->
    <div class="filterOptions-typeBock" *ngIf = "showSelectedFilterType === 'status_updated'">
      <div class="typeBock-listing">
        <div class="selectDate-wrapper filterCal">
          <span class="status-label">Select Date</span>
          <input type="text"
                ngxDaterangepickerMd
                class="dateRagne-input" 
                [autoApply]="false"
                [(ngModel)]="project.selectedUpdateDate"
                [alwaysShowCalendars]="true"
                [ranges]="ranges"
                [showDropdowns]="true"
                (datesUpdated)="datesUpdated($event,'status_updated')"
                [linkedCalendars]="false"
                placeholder="Select please..."/>
          <em class="iconb-calendar dateIcon"></em>
          <button type="button" *ngIf="project.selectedUpdateDate && project.selectedUpdateDate?.startDate" class="dateClearButton" (click)="clearDate($event,'status_updated')">
            <em class="iconb-close"></em>
          </button>
        </div>
      </div>
    </div>
    <!-------------this is for date updated block Ends--------------->
    
    <!-------------this is for Date Created block starts--------------->
    <div class="filterOptions-typeBock" *ngIf = "showSelectedFilterType === 'date_created'">
      <div class="typeBock-listing">
        <div class="selectDate-wrapper filterCal">
          <span class="status-label">Select Date</span>
          <input type="text"
                ngxDaterangepickerMd
                class="dateRagne-input" 
                [autoApply]="false"
                [(ngModel)]="project.selectedDate"
                [alwaysShowCalendars]="true"
                [ranges]="ranges"
                [showDropdowns]="true"
                (datesUpdated)="datesUpdated($event,'date_created')"
                [linkedCalendars]="false"
                placeholder="Select please..."/>
          <em class="iconb-calendar dateIcon"></em>
          <button type="button" *ngIf="project.selectedDate && project.selectedDate?.startDate" class="dateClearButton" (click)="clearDate($event,'date_created')">
            <em class="iconb-close"></em>
          </button>
        </div>
      </div>
    </div>
    <!-------------this is for Date Created block Ends--------------->

    <!-------------this is for phase block starts--------------->
    <div class="filterOptions-typeBock" *ngIf = "showSelectedFilterType === 'phase'">
      <div class="typeBock-listing">
        <span class="typeBock-check">
          <label class="customCheckForStorySelection">
            <input id="phaseUnassigned" type="checkbox" class="story-selected-checkbox" [disabled]="projectService.startApplyFilterLoader" (change)="filterStoryService.applyFilter(project, $event, 'phase', null)" [checked]="isFilterChecked('phase', null)" hidden/>
            <span class="check-toggle"></span>
          </label>
        </span>
        <label for="phaseUnassigned" class="typeBock-name typeBock-label">Unassigned</label>
      </div>
      <div class="typeBock-listing" *ngFor="let phase of project.phases">
        <span class="typeBock-check">
          <label class="customCheckForStorySelection">
            <input id="{{phase}}" type="checkbox" class="story-selected-checkbox" [disabled]="projectService.startApplyFilterLoader" (change)="filterStoryService.applyFilter(project, $event, 'phase', phase)" [checked]="isFilterChecked('phase', phase)" hidden/>
            <span class="check-toggle"></span>
          </label>
        </span>
        <label for="{{phase}}" class="typeBock-name typeBock-label">{{phase}}</label>
      </div>
    </div>
    <!-------------this is for phase block Ends--------------->
     <!-------------this is for roles block starts--------------->
     <div class="filterOptions-typeBock" *ngIf = "showSelectedFilterType === 'studio_roles'">
      <div class="typeBock-listing" *ngFor="let studio_roles of project.studio_roles">
        <span class="typeBock-check">
          <label class="customCheckForStorySelection">
            <input id="{{studio_roles.studio_role.id}}" type="checkbox" class="story-selected-checkbox" [disabled]="projectService.startApplyFilterLoader" (change)="filterStoryService.applyFilter(project, $event, 'studio_roles', studio_roles.studio_role.id)" [checked]="isFilterChecked('studio_roles', studio_roles.studio_role.id)" hidden/>
            <span class="check-toggle"></span>
          </label>
        </span>
        <label for="{{studio_roles.studio_role.id}}" class="typeBock-name typeBock-label">{{studio_roles.studio_role.name}}</label>
      </div>
    </div>
    <!-------------this is for roles block Ends--------------->
    <!-------------this is for dev type block starts--------------->
    <div class="filterOptions-typeBock" *ngIf = "showSelectedFilterType === 'dev_type'">
      <div class="typeBock-listing">
        <span class="typeBock-check">
          <label class="customCheckForStorySelection">
            <input id="devTypeUnassigned" type="checkbox" class="story-selected-checkbox" [disabled]="projectService.startApplyFilterLoader" (change)="filterStoryService.applyFilter(project, $event, 'dev_type', null)" [checked]="isFilterChecked('dev_type', null)" hidden/>
            <span class="check-toggle"></span>
          </label>
        </span>
        <label for="devTypeUnassigned" class="typeBock-name typeBock-label">Unassigned</label>
      </div>
      <div class="typeBock-listing" *ngFor="let dev_type of devTypeOptions">
        <span class="typeBock-check">
          <label class="customCheckForStorySelection">
            <input id="{{dev_type.type}}" type="checkbox" class="story-selected-checkbox" [disabled]="projectService.startApplyFilterLoader" (change)="filterStoryService.applyFilter(project, $event, 'dev_type', dev_type.type)" [checked]="isFilterChecked('dev_type', dev_type.type)" hidden/>
            <span class="check-toggle"></span>
          </label>
        </span>
        <label for="{{dev_type.type}}" class="typeBock-name typeBock-label">{{dev_type.title}}</label>
      </div>
    </div>
    <!-------------this is for dev type block Ends--------------->
 <!-------------this is for owner block starts--------------->
 <div class="filterOptions-typeBock ownerBlock"  *ngIf = "showSelectedFilterType === 'owner'">
  <span class="filterSearchWrapper">
    <input type="text"  class="filterSearch" placeholder="Search Member" [(ngModel)]="searchMemberForFilter">
    <button type="button" class="filterSearchButton"><em class="iconb-search"></em></button>
  </span>
  <div class="ownerListWrapper">
    <div class="typeBock-listing">
      <span class="typeBock-check">
        <label class="customCheckForStorySelection">
          <input id="ownerUnassigned" type="checkbox" class="story-selected-checkbox" [disabled]="projectService.startApplyFilterLoader" (change)="filterStoryService.applyFilter(project,$event, 'owner', null)" [checked]="isFilterChecked('owner', null)" hidden/>
          <span class="check-toggle"></span>
        </label>
      </span>
      <label for="ownerUnassigned" class="typeBock-label">
        <span class="typeBock-ownerInitials">U</span>
      <span class="typeBock-name">Unassigned</span>
      </label>
    </div>
    <div class="typeBock-listing"  *ngFor="let member of ((project.memberships | membersearch: searchMemberForFilter.trim()) | filterDataSort:'member': project.filters)">
      <span class="typeBock-check">
        <label class="customCheckForStorySelection">
          <input id="{{member.user.id}}" type="checkbox" class="story-selected-checkbox" [disabled]="projectService.startApplyFilterLoader" (change)="filterStoryService.applyFilter(project,$event, 'owner', member.user.id)" [checked]="isFilterChecked('owner', member.user.id)" hidden/>
          <span class="check-toggle"></span>
        </label>
      </span>
      <label for="{{member.user.id}}" class="typeBock-label">
      <span class="typeBock-ownerInitials">{{member.user.initials?.substr(0,1)}}</span>
      <span class="typeBock-name">{{member.user.name ? (member.user.name | titlecase) : member.user.email}}</span>
      </label>
    </div>
  </div>
</div>    
        <!-------------this is for owner block Ends--------------->
 <!-------------this is for reviewer block starts--------------->
 <div class="filterOptions-typeBock ownerBlock"  *ngIf = "showSelectedFilterType === 'reviewer'">
  <span class="filterSearchWrapper">
    <input type="text"  class="filterSearch" placeholder="Search Member" [(ngModel)]="searchMemberForFilter">
    <button type="button" class="filterSearchButton"><em class="iconb-search"></em></button>
  </span>
  <div class="ownerListWrapper">
    <div class="typeBock-listing">
      <span class="typeBock-check">
        <label class="customCheckForStorySelection">
          <input id="reviewerUnassigned" type="checkbox" class="story-selected-checkbox" [disabled]="projectService.startApplyFilterLoader" (change)="filterStoryService.applyFilter(project,$event, 'reviewer', null)" [checked]="isFilterChecked('reviewer', null)" hidden/>
          <span class="check-toggle"></span>
        </label>
      </span>
      <label for="reviewerUnassigned" class="typeBock-label">
      <span class="typeBock-ownerInitials">U</span>
      <span class="typeBock-name">Unassigned</span>
      </label>
    </div>
    <div class="typeBock-listing"  *ngFor="let member of ((project.memberships | membersearch: searchMemberForFilter.trim()) | filterDataSort:'member': project.filters)">
      <span class="typeBock-check">
        <label class="customCheckForStorySelection">
          <input id="{{member.user.id}}" type="checkbox" class="story-selected-checkbox" [disabled]="projectService.startApplyFilterLoader" (change)="filterStoryService.applyFilter(project,$event, 'reviewer', member.user.id)" [checked]="isFilterChecked('reviewer', member.user.id)" hidden/>
          <span class="check-toggle"></span>
        </label>
      </span>
      <label for="{{member.user.id}}" class="typeBock-label">
      <span class="typeBock-ownerInitials">{{member.user.initials?.substr(0,1)}}</span>
      <span class="typeBock-name">{{member.user.name ? (member.user.name | titlecase) : member.user.email}}</span>
      </label>
    </div>
  </div>
</div>
<!-------------this is for reviewer block Ends--------------->
 <!-------------this is for requester block starts--------------->
 <div class="filterOptions-typeBock ownerBlock"  *ngIf = "showSelectedFilterType === 'requester'">
  <span class="filterSearchWrapper">
    <input type="text"  class="filterSearch" placeholder="Search Member" [(ngModel)]="searchMemberForFilter">
    <button type="button" class="filterSearchButton"><em class="iconb-search"></em></button>
  </span>
  <div class="ownerListWrapper">
    <div class="typeBock-listing">
      <span class="typeBock-check">
        <label class="customCheckForStorySelection">
          <input id="requestorUnassigned" type="checkbox" class="story-selected-checkbox" [disabled]="projectService.startApplyFilterLoader" (change)="filterStoryService.applyFilter(project,$event, 'requester', null)" [checked]="isFilterChecked('requester', null)" hidden/>
          <span class="check-toggle"></span>
        </label>
      </span>
      <label for="requestorUnassigned" class="typeBock-label">
      <span class="typeBock-ownerInitials">U</span>
      <span class="typeBock-name">Unassigned</span>
    </label>
    </div>
    <div class="typeBock-listing"  *ngFor="let member of ((project.memberships | membersearch: searchMemberForFilter.trim()) | filterDataSort:'member': project.filters)">
      <span class="typeBock-check">
        <label class="customCheckForStorySelection">
          <input id="{{member.user.id}}" type="checkbox" class="story-selected-checkbox" [disabled]="projectService.startApplyFilterLoader" (change)="filterStoryService.applyFilter(project,$event, 'requester', member.user.id)" [checked]="isFilterChecked('requester', member.user.id)" hidden/>
          <span class="check-toggle"></span>
        </label>
      </span>
      <label for="{{member.user.id}}" class="typeBock-label">
      <span class="typeBock-ownerInitials">{{member.user.initials?.substr(0,1)}}</span>
      <span class="typeBock-name">{{member.user.name ? (member.user.name | titlecase) : member.user.email}}</span>
    </label>
    </div>
  </div>
</div>
<!-------------this is for requester block Ends--------------->
<!-------------this is for Sprints block starts--------------->
<div 
infiniteScroll
[scrollWindow]="false"
[infiniteScrollDisabled]="!isScrollFetchAvailable()"
(scrolled)="getOlder()"
class="filterOptions-typeBock ownerBlock"  *ngIf = "showSelectedFilterType === 'sprints'">
  <span class="filterSearchWrapper">
    <input type="text"  class="filterSearch" placeholder="Search Sprint" [(ngModel)]="params.q" (keyup)="getSprints(true)">
    <button type="button" class="filterSearchButton" (click)="getSprints()"><em class="iconb-search"></em></button>
  </span>
  <div class="ownerListWrapper">
    <div class="typeBock-listing">
      <span class="typeBock-check">
        <label class="customCheckForStorySelection">
          <input id="sprintUnassigned" type="checkbox" class="story-selected-checkbox" [disabled]="projectService.startApplyFilterLoader" (change)="filterStoryService.applyFilter(project,$event, 'sprints', null)" [checked]="isFilterChecked('sprints', null)" hidden/>
          <span class="check-toggle"></span>
        </label>
      </span>
      <label for="sprintUnassigned" class="typeBock-name">Unassigned</label>
    </div>
    <div class="typeBock-listing"  *ngFor="let sprint of sprints">
      <span class="typeBock-check">
        <label class="customCheckForStorySelection">
          <input id="{{sprint.id}}" type="checkbox" class="story-selected-checkbox" [disabled]="projectService.startApplyFilterLoader" (change)="filterStoryService.applyFilter(project,$event, 'sprints', sprint.id)" [checked]="isFilterChecked('sprints', sprint.id)" hidden/>
          <span class="check-toggle"></span>
        </label>
      </span>
      <label for="{{sprint.id}}" class="typeBock-name">{{sprint.name}}</label>
      <span class="sprint-status" [ngClass]="sprint.status">
        <span class="sprint-status-tooltip">{{sprint.status}}</span>
      </span>
    </div>
  </div>
</div>    
    <!-------------this is for Sprints block Ends--------------->

    <!-------------this is for swimlane block starts--------------->
                    <div class="filterOptions-typeBock" *ngIf = "showSelectedFilterType === 'swimlane'">
                      <div class="typeBock-listing" *ngFor="let swimlane of project.swimlanes">
                        <span class="typeBock-check">
                          <label class="customCheckForStorySelection">
                            <input id="{{swimlane.group}}" type="checkbox" class="story-selected-checkbox" [disabled]="projectService.startApplyFilterLoader" (change)="filterStoryService.applyFilter(project,$event, 'swimlane', swimlane.group)" [checked]="isFilterChecked('swimlane', swimlane.group)" hidden/>
                            <span class="check-toggle"></span>
                          </label>
                        </span>
                        <label for="{{swimlane.group}}" class="typeBock-name typeBock-label">{{swimlane.name}}</label>
                      </div>
                    </div>
    <!-------------this is for swimlane block Ends--------------->
        
    <!-------------this is for bb code block starts--------------->
    <div class="filterOptions-typeBock" *ngIf = "showSelectedFilterType === 'bb_status'">
      <div class="typeBock-listing" *ngFor="let bb_status of BBStatusOptions">
        <span class="typeBock-check">
          <label class="customCheckForStorySelection">
            <input id="{{bb_status.type}}" type="checkbox" class="story-selected-checkbox" [disabled]="projectService.startApplyFilterLoader || disableBBstatusTitle(bb_status.type)" (change)="filterStoryService.applyFilter(project, $event, 'bb_status', bb_status.type)" [checked]="isFilterChecked('bb_status', bb_status.type)" hidden/>
            <span class="check-toggle"></span>
          </label>
        </span>
        <label for="{{bb_status.type}}" class="typeBock-name typeBock-label" [ngClass]="{disableFilter: disableBBstatusTitle(bb_status.type)}">{{bb_status.title}}</label>
        <span class="elementHover" *ngIf = "disableBBstatusTitle(bb_status.type)">No feature is available in this status</span>
      </div>
    </div>
    <!-------------this is for bb code block Ends--------------->
  <!-------------this is for bb design block starts--------------->
  <div class="filterOptions-typeBock" *ngIf = "showSelectedFilterType === 'bb_design'">
    <div class="typeBock-listing" *ngFor="let bb_design of project.projectDevicePlatformsData">
      <span class="typeBock-check">
        <label class="customCheckForStorySelection">
          <input id="{{bb_design.device}}" type="checkbox" class="story-selected-checkbox" [disabled]="projectService.startApplyFilterLoader" (change)="filterStoryService.applyFilter(project, $event, 'bb_design', bb_design.device)" [checked]="isFilterChecked('bb_design', bb_design.device)" hidden/>
          <span class="check-toggle"></span>
        </label>
      </span>
      <label for="{{bb_design.device}}" class="typeBock-name typeBock-label">{{bb_design.device}}</label>
    </div>
  </div>
  <!-------------this is for bb design block Ends--------------->
    <!-------------this is for Story type block starts--------------->
    <div class="filterOptions-typeBock" *ngIf = "showSelectedFilterType === 'story_type'">
      <div class="typeBock-listing">
        <span class="typeBock-check">
          <label class="customCheckForStorySelection">
            <input id="bug" type="checkbox" class="story-selected-checkbox" [disabled]="projectService.startApplyFilterLoader" (change)="filterStoryService.applyFilter(project, $event, 'story_type', 'bug')" [checked]="isFilterChecked('story_type', 'bug')" hidden/>
            <span class="check-toggle"></span>
          </label>
        </span>
        <label for="bug" class="typeBock-name typeBock-label">Bug</label>
      </div>
      <div>
        <div class="typeBock-listing">
          <span class="typeBock-check">
            <label class="customCheckForStorySelection">
              <input id="task" type="checkbox" class="story-selected-checkbox" [disabled]="projectService.startApplyFilterLoader" (change)="filterStoryService.applyFilter(project, $event, 'story_type', 'task')" [checked]="isFilterChecked('story_type', 'task') || isFilterChecked('sub_type', 'custom')|| isFilterChecked('sub_type', 'core')" hidden/>
              <span class="check-toggle"></span>
            </label>
          </span>
          <label for="task" class="typeBock-name typeBock-label">Story</label>
        </div>
        <div class="typeBock-listing-subtype" *ngFor="let storySubType of storySubTypeOptions">
          <span class="typeBock-check" *ngIf="project?.dashboard_project_id">
            <label class="customCheckForStorySelection">
              <input id="{{storySubType.type}}" type="checkbox" class="story-selected-checkbox" [disabled]="projectService.startApplyFilterLoader" (change)="filterStoryService.applyFilter(project, $event, 'sub_type', storySubType.type)" [checked]="isFilterChecked('sub_type', storySubType.type) && isFilterChecked('story_type', 'task')" hidden/>
              <span class="check-toggle"></span>
            </label>
          </span>
          <label for="{{storySubType.type}}" class="typeBock-name typeBock-label" *ngIf="project?.dashboard_project_id">{{storySubType.title}}</label>
        </div>
      </div>
    </div>
    <!-------------this is for Story type block Ends--------------->

    <!-------------this is for label block starts--------------->
    <div class="filterOptions-typeBock labelBlock" *ngIf = "showSelectedFilterType === 'label'">
      <span class="filterSearchWrapper">
        <input type="text"  class="filterSearch" placeholder="Search Label" [(ngModel)]="searchLabelForFilter">
        <button type="button" class="filterSearchButton"><em class="iconb-search"></em></button>
      </span>
      <div class="ownerListWrapper">
        <div class="typeBock-listing" *ngFor="let label of ((project.labels | search: searchLabelForFilter.trim()) | filterDataSort:'label': project.filters)">
          <span class="typeBock-check">
            <label class="customCheckForStorySelection">
              <input id="{{label}}" type="checkbox" class="story-selected-checkbox" [disabled]="projectService.startApplyFilterLoader" (change)="filterStoryService.applyFilter(project, $event, 'label', label)" [checked]="isFilterChecked('label', label)" hidden/>
              <span class="check-toggle"></span>
            </label>
          </span>
          <label for="{{label}}" class="typeBock-name typeBock-label">{{label}}</label>
        </div>
      </div>
    </div>
    <!-------------this is for label block Ends--------------->

    <!-------------this is for Feature of story OR Feature for release block starts--------------->
    <div class="filterOptions-typeBock featureBlock"  *ngIf = "showSelectedFilterType === 'feature' || showSelectedFilterType === 'release_features'">
      <span class="filterSearchWrapper">
        <input type="text"  class="filterSearch" placeholder="Search Feature" [(ngModel)]="searchFeatureForFilter">
        <button type="button" class="filterSearchButton"><em class="iconb-search"></em></button>
      </span>
      <div class="ownerListWrapper" *ngIf = "project">
        <div class="typeBock-listing">
          <span class="typeBock-check">
            <label class="customCheckForStorySelection">
              <input id="featrueUnassigned" type="checkbox" class="story-selected-checkbox" [disabled]="projectService.startApplyFilterLoader" (change)="filterStoryService.applyFilter(project, $event, 'feature', null)" [checked]="isFilterChecked('feature', null)" hidden/>
              <span class="check-toggle"></span>
            </label>
          </span>
          <label for="featrueUnassigned" class="typeBock-name typeBock-label">Unassigned</label>
        </div>
        <div class="typeBock-listing" *ngFor="let epic of ((getEpics() | search: searchFeatureForFilter.trim(): 'title') | filterDataSort:'feature': project.filters)">
        <span class="typeBock-check">
          <label class="customCheckForStorySelection">
            <input id="{{epic.id}}" type="checkbox" class="story-selected-checkbox" [disabled]="projectService.startApplyFilterLoader" (change)="filterStoryService.applyFilter(project, $event, 'feature', epic?.id)" [checked]="isFilterChecked('feature', epic?.id)" hidden/>
            <span class="check-toggle"></span>
          </label>
        </span>
        <label for="{{epic.id}}" class="typeBock-name typeBock-label">{{epic.title}}</label>
      </div>
      </div>
    </div>
    <!-------------this is for Feature of story OR Feature for releaseblock Ends--------------->

     <!-------------this is for states block starts--------------->
     <div class="filterOptions-typeBock" *ngIf = "showSelectedFilterType === 'states'">
      <div class="typeBock-listing" *ngFor="let states of storyStateOptions">
        <span class="typeBock-check">
          <label class="customCheckForStorySelection">
            <input id="{{states.type}}" type="checkbox" class="story-selected-checkbox" [disabled]="projectService.startApplyFilterLoader" (change)="filterStoryService.applyFilter(project, $event, 'states', states.type)" [checked]="isFilterChecked('states', states.type)" hidden/>
            <span class="check-toggle"></span>
          </label>
        </span>
        <label for="{{states.type}}" class="typeBock-name typeBock-label">{{states.title}}</label>
      </div>
    </div>
    <!-------------this is for states block Ends--------------->

    <!-------------this is for priority block starts--------------->
    <div class="filterOptions-typeBock" *ngIf = "showSelectedFilterType === 'priority'">
      <div class="typeBock-listing" *ngFor="let priority of storyPriorityOptions">
        <span class="typeBock-check">
          <label class="customCheckForStorySelection">
            <input id="{{priority.type}}" type="checkbox" class="story-selected-checkbox" [disabled]="projectService.startApplyFilterLoader" (change)="filterStoryService.applyFilter(project, $event, 'priority', priority.type)" [checked]="isFilterChecked('priority', priority.type)" hidden/>
            <span class="check-toggle"></span>
          </label>
        </span>
        <label for="{{priority.type}}" class="typeBock-name typeBock-label">{{priority.type}}</label>
      </div>
    </div>
    <!-------------this is for priority block Ends--------------->

    <!-------------this is for Member block starts--------------->
    <div class="filterOptions-typeBock ownerBlock"  *ngIf = "showSelectedFilterType === 'member'">
      <span class="filterSearchWrapper">
        <input type="text"  class="filterSearch" placeholder="Search Member" [(ngModel)]="searchMemberForFilter">
        <button type="button" class="filterSearchButton"><em class="iconb-search"></em></button>
      </span>
      <div class="ownerListWrapper">
        <div class="typeBock-listing">
          <span class="typeBock-check">
            <label class="customCheckForStorySelection">
              <input id="memberUnassigned" type="checkbox" class="story-selected-checkbox" [disabled]="projectService.startApplyFilterLoader" (change)="filterStoryService.applyFilter(project, $event, 'member', null)" [checked]="isFilterChecked('member', null)" hidden/>
              <span class="check-toggle"></span>
            </label>
          </span>
          <label for="memberUnassigned" class="typeBock-label">
          <span class="typeBock-ownerInitials">U</span>
          <span class="typeBock-name">Unassigned</span>
          </label>
        </div>
        <div class="typeBock-listing"  *ngFor="let member of ((project.memberships | membersearch: searchMemberForFilter.trim()) | filterDataSort:'member': project.filters)">
          <span class="typeBock-check">
            <label class="customCheckForStorySelection">
              <input id="{{member.user.id}}" type="checkbox" class="story-selected-checkbox" [disabled]="projectService.startApplyFilterLoader" (change)="filterStoryService.applyFilter(project, $event, 'member', member.user.id)" [checked]="isFilterChecked('member', member.user.id)" hidden/>
              <span class="check-toggle"></span>
            </label>
          </span>
          <label for="{{member.user.id}}" class="typeBock-label">
          <span class="typeBock-ownerInitials">{{member.user.initials?.substr(0,1)}}</span>
          <span class="typeBock-name">{{member.user.name ? (member.user.name | titlecase) : member.user.email}}</span>
          </label>
        </div>
      </div>
    </div>
    <!-------------this is for Owner block block Ends--------------->


    <!-------------this is for Devices and Platforms block starts--------------->
    <div class="filterOptions-typeBock ownerBlock"  *ngIf = "showSelectedFilterType === 'deviceAndPlatform'">
      <div class="devicePlatformList-wrapper">
        <div class="devicePlatformList">
          <div class="typeBock-listing">
            <span class="typeBock-check">
              <label class="customCheckForStorySelection">
                <input id="deviceUnassigned" type="checkbox" class="story-selected-checkbox" [disabled]="projectService.startApplyFilterLoader" (change)="filterStoryService.applyFilter(project, $event, 'platform', null)" [checked]="isFilterChecked('platform', null)" hidden/>
                <span class="check-toggle"></span>
              </label>
            </span>
            <label for="deviceUnassigned" class="typeBock-name typeBock-label">Unassigned</label>
          </div>
        </div>
      </div>
      <div class="devicePlatformList-wrapper">
        <div class="devicePlatformList" *ngFor="let devicePlatformData of project.projectDevicePlatformsData">
          <div class="deviceType"><em [ngClass]="[devicePlatformData.device ? 'iconb-' + devicePlatformData.device : 'iconb-mobile']"></em> {{devicePlatformData.device}}</div>
          <div class="typeBock-listing" *ngFor="let platform of devicePlatformData.platforms">
            <span class="typeBock-check">
              <label class="customCheckForStorySelection">
                <input id="{{platform.id}}" type="checkbox" class="story-selected-checkbox" [disabled]="projectService.startApplyFilterLoader" (change)="filterStoryService.applyFilter(project, $event, 'platform', platform.id)" [checked]="isFilterChecked('platform', platform.id)" hidden/>
                <span class="check-toggle"></span>
              </label>
            </span>
            <label for="{{platform.id}}" class="typeBock-name typeBock-label">{{ platform?.display_name }}</label>
          </div>
        </div>
      </div>
    </div>
    <!-------------this is for Devices and Platforms block Ends--------------->

    <!-------------this is for "Chage From" block starts--------------->
    <div class="filterOptions-typeBock" *ngIf = "showSelectedFilterType === 'created_from'">

      <div class="typeBock-listing">
        <span class="typeBock-check">
          <label class="customCheckForStorySelection">
            <input id="tracker" type="checkbox" class="story-selected-checkbox" [disabled]="projectService.startApplyFilterLoader" (change)="filterStoryService.applyFilter(project, $event, 'created_from', 'tracker')" [checked]="isFilterChecked('created_from', 'tracker')" hidden/>
            <span class="check-toggle"></span>
          </label>
        </span>
        <label for="tracker" class="typeBock-name typeBock-label">Tracker</label>
      </div>

      <div class="typeBock-listing">
        <span class="typeBock-check">
          <label class="customCheckForStorySelection">
            <input id="delivery" type="checkbox" class="story-selected-checkbox" [disabled]="projectService.startApplyFilterLoader" (change)="filterStoryService.applyFilter(project, $event, 'created_from', 'delivery')" [checked]="isFilterChecked('created_from', 'delivery')" hidden/>
            <span class="check-toggle"></span>
          </label>
        </span>
        <label for="delivery" class="typeBock-name typeBock-label">Delivery</label>
      </div>

      <div class="typeBock-listing">
        <span class="typeBock-check">
          <label class="customCheckForStorySelection">
            <input id="catalog" type="checkbox" class="story-selected-checkbox" [disabled]="projectService.startApplyFilterLoader" (change)="filterStoryService.applyFilter(project, $event, 'created_from', 'catalog')" [checked]="isFilterChecked('created_from', 'catalog')" hidden/>
            <span class="check-toggle"></span>
          </label>
        </span>
        <label for="catalog" class="typeBock-name typeBock-label">Catalog</label>
      </div>


      <div class="typeBock-listing">
        <span class="typeBock-check">
          <label class="customCheckForStorySelection">
            <input id="visual_qa" type="checkbox" class="story-selected-checkbox" [disabled]="projectService.startApplyFilterLoader" (change)="filterStoryService.applyFilter(project, $event, 'created_from', 'visual_qa')" [checked]="isFilterChecked('created_from', 'visual_qa')" hidden/>
            <span class="check-toggle"></span>
          </label>
        </span>
        <label for="visual_qa" class="typeBock-name typeBock-label">VisualQA</label>
      </div>


      <div class="typeBock-listing">
        <span class="typeBock-check">
          <label class="customCheckForStorySelection">
            <input id="builder_live" type="checkbox" class="story-selected-checkbox" [disabled]="projectService.startApplyFilterLoader" (change)="filterStoryService.applyFilter(project, $event, 'created_from', 'builder_live')" [checked]="isFilterChecked('created_from', 'builder_live')" hidden/>
            <span class="check-toggle"></span>
          </label>
        </span>
        <label for="builder_live" class="typeBock-name typeBock-label">BHome (Client)</label>
      </div>
      <!--<div class="typeBock-listing">-->
        <!--<span class="typeBock-check">-->
          <!--<label class="customCheckForStorySelection">-->
            <!--<input type="checkbox" class="story-selected-checkbox" [disabled]="startApplyFilterLoader" (change)="filterStoryService.applyFilter(project, $event, 'created_from', 'others')" [checked]="isFilterChecked('created_from', 'others')" hidden/>-->
            <!--<span class="check-toggle"></span>-->
          <!--</label>-->
        <!--</span>-->
        <!--<span class="typeBock-name">Others</span>-->
      <!--</div>-->
    </div>
    <!-------------this is for "Chage From" block Ends--------------->

    <!-------------this is for Story type block starts--------------->
    <div class="filterOptions-typeBock" *ngIf = "showSelectedFilterType === 'code_commit'">
      <div class="typeBock-listing">
        <span class="typeBock-check">
          <label class="customCheckForStorySelection">
            <input id="with_commit" type="checkbox" class="story-selected-checkbox" [disabled]="projectService.startApplyFilterLoader" (change)="filterStoryService.applyFilter(project, $event, 'code_commit', 'with_commit')" [checked]="isFilterChecked('code_commit', 'with_commit')" hidden/>
            <span class="check-toggle"></span>
          </label>
        </span>
        <label for="with_commit" class="typeBock-name typeBock-label">With Code Commit</label>
      </div>



      <div class="typeBock-listing">
        <span class="typeBock-check">
          <label class="customCheckForStorySelection">
            <input id="without_commit" type="checkbox" class="story-selected-checkbox" [disabled]="projectService.startApplyFilterLoader" (change)="filterStoryService.applyFilter(project, $event, 'code_commit', 'without_commit')" [checked]="isFilterChecked('code_commit', 'without_commit')" hidden/>
            <span class="check-toggle"></span>
          </label>
        </span>
        <label for="without_commit" class="typeBock-name typeBock-label">Without Code Commit</label>
      </div>
    </div>
    <!-------------this is for Story type block Ends--------------->

  </div>