import { Component, OnInit, Input, EventEmitter, Output} from '@angular/core';
import { ErrorService } from '../../shared/errorFunction';
import { ReleasePlannerService } from '../../shared/services/release-planner.service';

@Component({
  selector: 'app-feature-list',
  templateUrl: './feature-list.component.html',
  styleUrls: ['./feature-list.component.scss']
})
export class FeatureListComponent implements OnInit {
  @Input() selected_release;
  @Input() project;
  @Output() closeFeaturelistPopup = new EventEmitter<boolean>();
  featureList = [];
  waitForLoader = false;

  constructor(
    private releasePlannerService: ReleasePlannerService,
    private errorService: ErrorService,
  ) { }

  ngOnInit() {
    this.waitForLoader = true;
    this.loadFeatures();
  }

  // load the full list of feature for selected release from the database
  loadFeatures() {
    this.releasePlannerService.getFeatures(this.project, this.selected_release.id).subscribe(res => {
      if(res) {
        res.data.forEach(item => {
          this.featureList.push(item);
        });
      }
      this.waitForLoader = false;
    }, (error) => {
      this.waitForLoader = false;
      this.errorService.errorFunction(error);
    });
  } 
  

  closeFeaturelist() {
    this.closeFeaturelistPopup.emit(true);
  }

}
