import { mix } from './mixinBuilder';
import { GUID } from './guid';

export class BaseModel {
  id?: number;
  private _pguid?: string;
  isSimulatedObject?: boolean;

  constructor(data: any) {
    this.id = data['id'];

    if(data['isSimulatedObject']) {

    } else {
      if (data['id']) {
        return GUID.setUIDForObject(this);
      }
    }

  }

  get isNew() {
    return !this.id;
  }

  set _guid(value) {
    this._pguid = value;
  }

  get guid() {
    return this._pguid;
  }

  getClassName() {
    throw new Error("Override this method for this class");
  }

}