import { Injectable } from '@angular/core';
import Centrifuge from 'centrifuge';

import { environment } from '../../../environments/environment';
import { API_ROUTES } from '../apiRoutes';
import { HttpRequests } from './http-requests.service';
import { DataService } from './data.service';
import {ErrorService} from '../errorFunction';


class Subscription {
  channel: string;
  callback: Function;
}

@Injectable()
export class SocketsService {
  private _centrifuge: Centrifuge;

  constructor(
    private httpRequest: HttpRequests,
    private errorService: ErrorService,
    private dataService: DataService) {
      this.centrifugeConfig();
  }

  public get centrifuge(): Centrifuge {
    return this._centrifuge;
  }

  public centrifugeConfig() {
    this._centrifuge = new Centrifuge({
      url: environment.websocketUrl,
      onPrivateChannelAuth: this.onPrivateChannelAuth.bind(this),

      // Prevent error on initialization, configure params on ajax auth
      user: 'none', timestamp: 'none', token: 'none'
    });
    const url = this.dataService.apiUrl + API_ROUTES.SOCKETS_AUTH;
    this
      .httpRequest
      .get(url, this.dataService.getRequestOptionArgs())
      .subscribe(response => {
        let json: any = this.parseResponse(response);

        this._centrifuge.configure({
          user: json.user,
          timestamp: json.timestamp,
          token: json.token
        });

        this._centrifuge.connect();
      }, err => {
        this.errorService.errorFunction(err);
      });
  }

  private onPrivateChannelAuth(context, callback) {
    const url = this.dataService.apiUrl + API_ROUTES.SOCKETS_AUTH;
    this
      .httpRequest
      .post(url, context.data, this.dataService.getRequestOptionArgs())
      .subscribe(
        res => callback(false, this.parseResponse(res)),
        res => callback(true, this.parseResponse(res))
      ),
      (error => {
        this.errorService.errorFunction(error);
      });
  }

  public parseResponse(res: any): any {
    return res;
  }
}
