import { Injectable } from '@angular/core';
import { User } from '../models/user';
import { Project } from '../models/project';
import { Observable, Observer } from 'rxjs';
import { HttpRequests } from '../../../shared/services/http-requests.service';
import { DataService } from '../../../shared/services/data.service';
import { SharedService } from '../../../shared/services/shared.service';

export interface IUsersList {
  users: User[];
  totalUsers?: number;
  totalTime?: string;
  currentPage?: number;
  pages?: number;
}

@Injectable()
export class TimingUsersService {

  private apiUrl = 'admin/timing/users';

  constructor(
    private sharedService: SharedService,
    private httpRequest: HttpRequests,
    private dataService: DataService) { }

  getUsers(filter?: object): Observable<IUsersList> {
    let url = this.apiUrl;
    if (filter) {
      url = this.sharedService.parseQueryParams(this.apiUrl, filter);
    }
    const API = this.dataService.apiUrl + url;
    return Observable.create((observer: Observer<IUsersList>) => {
      this.httpRequest
        .get(API, this.dataService.getRequestOptionArgs())
        .subscribe(
          response => {
            const projectsList: IUsersList = {
              users: this.parseResponse(response).users.map(x => new User(x)),
              totalUsers: this.parseResponse(response).total_users,
              totalTime: this.parseResponse(response).total_time,
              currentPage: this.parseResponse(response).current_page,
              pages: this.parseResponse(response).pages
            };
            observer.next(projectsList);
            observer.complete();
          }
        );
      }
    );
  }

  getProjects(userId: number, filter?: Object): Observable<Project[]> {
    let url = `${this.apiUrl}/${userId}/projects`;
    if (filter) {
      url = this.sharedService.parseQueryParams(this.apiUrl, filter);
    }

    const API = this.dataService.apiUrl + url;
    return Observable.create((observer: Observer<Project[]>) => {
      this.httpRequest
        .get(API, this.dataService.getRequestOptionArgs())
        .subscribe(
          response => {
            const projects = this.parseResponse(response).projects.map(x => new Project(x));
            observer.next(projects);
            observer.complete();
          }
        );
      }
    );
  }

  getUser(id: number): Observable<User> {
    const url = `${this.apiUrl}/${id}`;

    const API = this.dataService.apiUrl + url;
    return Observable.create((observer: Observer<User>) => {
      this.httpRequest
        .get(API, this.dataService.getRequestOptionArgs())
        .subscribe(
          response => {
            observer.next(new User(this.parseResponse(response)));
            observer.complete();
          }
        );
      }
    );
  }

  public parseResponse(res: any): any {
    return res;
  }
}
