import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'membersearch',
    pure: false
})

export class MemberSearchPipe implements PipeTransform {
    transform(items: any[], searchText: string, type?: string): any[] {
        if (!items) return [];
        if (!searchText) return items;
        searchText = searchText.toLowerCase();
        return items.filter(it => {
            if (type && type == 'requesteduser') {
                return ((it.name && it.name.toLowerCase().includes(searchText)) ||
                (it.email && it.email.toLowerCase().includes(searchText)) ||
                (it.name_or_email && it.name_or_email.toLowerCase().includes(searchText)) ||
                (it.initials && it.initials.toLowerCase().includes(searchText)));
            } else {
                return ((it.user.name && it.user.name.toLowerCase().includes(searchText)) ||
                    (it.user.email && it.user.email.toLowerCase().includes(searchText)) ||
                    (it.user.name_or_email && it.user.name_or_email.toLowerCase().includes(searchText)) ||
                    (it.user.initials && it.user.initials.toLowerCase().includes(searchText)));
            }

        });
    }
}
