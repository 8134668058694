<div class="activity-timeline-wrapper">
  <div class="sprintInitial-Loader" style="height: 200px;" *ngIf="activity_loader">
    <img src="../../../assets/images/storyBoardLoader.gif" alt=""/>
  </div>
  <div class="activity-timeline-outer" *ngFor="let activity of activities | keyvalue">
    <h3 class="date">{{activity.key}}</h3>
    <div class="activity-timeline-inner-wrapper">
      <div class="activity-timeline" [ngClass]="{'expanded': item.expand}" *ngFor="let item of activity.value">
        <div class="at-title">
          {{item.title}}
          <em class="dropdown-icon" (click)="toggleActivityData(item)"><img src="../../../assets/images/timeline-down-arrow.svg" alt=""></em>
        </div>
        <div class="at-body">
          <div class="body-data-row" *ngFor="let detail of item.details">
            <span class="title">{{detail.field_name}} </span>
            <span *ngIf="detail.field_name === 'Description:'" [innerHtml]="bypassSanitization(detail.field_value)"></span>
            <span *ngIf="detail.field_name !== 'Description:'">{{detail.field_value}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="show-more-stories" *ngIf="show_activity" (click)="showAllActivities()">
    Show all <em class="iconb-dd-down"></em>
  </div>
</div>