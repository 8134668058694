import {
  Component,
  IterableDiffers,
  OnInit,
  AfterViewInit,
  ViewChild,
  ChangeDetectorRef,
  NgZone,
  HostListener,
  ElementRef, ViewChildren, Renderer2, QueryList
} from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';
import { DragulaService, dragula } from '../../lib';
import * as DomAutoscroller from 'dom-autoscroller';
import { map } from 'lodash';
import 'rxjs/add/operator/first';
import 'rxjs/add/operator/pairwise';
import * as _ from 'lodash';
import { debounceTime } from 'rxjs/operators';
import { filter } from 'rxjs/operators';
import {CdkDragDrop, moveItemInArray, CdkDrag, CdkDropList} from '@angular/cdk/drag-drop';
import {startWith  , switchMap , tap} from 'rxjs/operators';
import {merge , Subscription} from 'rxjs';
import {Draggable} from './../shared/draggable';
import { EpicService } from './../shared/services/epic.service';
import { ErrorService } from './../shared/errorFunction';
import { NotificationService } from './../shared/services/notification.service';
import { PolicyService } from './../shared/services/policy.service';
import { StoryService} from './../shared/services/story.service';
import { ProjectService } from './../shared/services/project.service';
import { ProjectSyncService } from './../shared/services/projectSync.service';
import { UserService } from './../shared/services/user.service';
import {
  EVENT_TYPES,
  DraggableData,
  PROJECT_COLUMN_DATA,
  PROJECT_COLUMN_IDENTIFIER,
  PROJECT_COLUMN,
  STORY_STATE_OPTIONS,
  BB_STATUS_OPTIONS,
  STORY_STATE, STORY_TYPE_OPTIONS,STORY_SUB_TYPE_OPTIONS,STORY_TYPE_FILTER, STORY_TYPE, NOT_APPLIED_REVIEWER, STORY_PRIORITY_OPTIONS, BB_STATUS
} from './../shared/dataTypes';
import { CustomToastService } from '../shared/services/custom-toast.service';
import moment from 'moment';
import { SharedService } from '../shared/services/shared.service';
import { environment } from '../../environments/environment';
import { trigger, transition, style, animate } from '@angular/animations';
import { User } from '../shared/models/user';
import { Project } from '../shared/models/project';
import { Story } from '../shared/models/story';
import { Epic } from '../shared/models/epic';
import { API_ROUTES } from '../shared/apiRoutes';
import { ProjectColumn } from '../shared/models/column';
import {Sprint} from "../shared/models/sprint";
import { FilterStoryService } from '../shared/services/filter-story.service';

const MONTH = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

@Component({
  selector: 'project-dashboard',
  templateUrl: 'project.component.html',
  styleUrls: ['project.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({
          opacity: '0',
          transform: 'translateY(-20px)'
        }),
        animate(100, style({
          opacity: '1',
          transform: 'translateY(0)'
        }))
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate(100, style({
          opacity: '0',
          transform: 'translateY(-20px)'
        }))
      ])
    ]),
    trigger('slideOut', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({
          opacity:'0',
          top: '0',
          right: '-190px'
        }),
        animate(100, style({
          opacity:'1',
          top: '0',
          right: '0'
        }))
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate(100, style({
          opacity:'0',
          top: '0',
          right: '-190px'
        }))
      ])
    ]),
  ],
  host: {
    '(document:click)': 'onClick($event)'
  }
})
export class ProjectComponent implements OnInit, AfterViewInit {
  currentUser: User;
  newColumnName = '';
  showDropDown = false;
  quickPopupType = '';
  searchUser = '';
  waitForLoader = false;
  estimate: number = 0;
  existingExpandData = [];
  meFilterData = [];
  project: Project;
  projectId?: number;
  user_by_id;
  user_by_name;
  user_by_initials;
  existingFilterData = [];
  projectColumns: Array<ProjectColumn> = PROJECT_COLUMN_DATA;
  storyTypeOptions: Array<any> = STORY_TYPE_OPTIONS;
  storyTypeFilter: Array<any> = STORY_TYPE_FILTER;
  storySubTypeOptions: Array<any> = STORY_SUB_TYPE_OPTIONS;
  storyPriorityOptions: Array<any> = STORY_PRIORITY_OPTIONS;
  storyStateOptions: Array<any> = STORY_STATE_OPTIONS;
  BBStatusOptions: Array<any> = BB_STATUS_OPTIONS
  isEpicZone = false;
  moreAvailable: boolean = true;
  startProjectScrollLoader: boolean = false;
  @ViewChild('projectStoriesTableContainer', { read: ElementRef, static: true }) projectStoriesTableContainer;
  @ViewChild('projectStoriesTable', { static: true }) projectStoriesTable;
  cloneConfirmPopup: boolean = false;
  featurelabelSelectionPopup: boolean = false;
  moveSelectionPopup: boolean = false;
  moveDeviceAndPlatformPopup:boolean = false;
  deleteSelectionPopup: boolean = false;
  showHtml = false;
  searchFeatureLabel = '';
  searchOwner = '';
  searchReviewer = '';
  dragDropEstimateObj: any;
  searchProject = '';
  selectedLabelsArr = [];
  selectedPlatformsArr = [];
  projects = [];
  selectedProjectId: number;
  selectedOwnerId: number;
  selectedReviewerId: number;
  startTime: any;
  currentUrl = '';
  previousUrl = '';
  paramsSubscription: Subscription;
  togglestoryDel = [];
  initialSelStories = [];
  togglestoryAccept = [];
  allocationAlert: boolean = false;
  allocationRedAlert: boolean = false;
  days_left: number;
  allocation_end_date: String = '';
  scroll;
  startLoader: boolean = false;
  freezePanel: boolean = false;
  isPending: boolean = false;
  selectMemberDD: boolean = false;
  setOwnerPopup: boolean = false;
  setReviewerPopup: boolean = false;
  forEstimate: boolean = false;
  story: Story;
  apply_on_sub_tasks: boolean = true;
  showcolumnList: boolean = false;
  showfilterList: boolean = false;
  changeToSelectAll: boolean = false;
  isDragForConfirmationAndOwner: boolean = false;
  openSearchBlock: boolean = false;
  showSelectedFilterType: String = 'phase';
  searchLabelForFilter: String = '';
  searchFeatureForFilter: String = '';
  searchMemberForFilter: String = '';
  storyProgress = { title: '', completedProgress: 0 };
  enterStoryProgress = false;
  progress: number;
  otherStartedStory: Story;
  searchStr: string = '';
  isSearchById: boolean = false;
  oldSearchStr: String = '';
  searchResult = { stories: [], labels: [] };
  searchFeatures = [];
  totalCountSearch = 0;
  searchSubscription: Subscription = undefined;
  searchGoingLoader: boolean = false;
  startApplyFilterLoader: boolean = false;
  filtersCount: number = 0;
  insertOwnerTime: boolean = false;
  insertTimeWhileDraging: boolean = false;
  showCutomColumn = false;
  visibleIds = [];
  disableAddColumn: boolean = false;
  dragStart = false;
  @ViewChildren('membersList') membersList: any;
  membersListElements: any;
  selectedMemberIndex = 0;
  showDragLoader = false;
  forBothEstimateAndOwner = 'both';
  showIDEList = false;
  selectMoveDD:boolean = false;
  epicStatus:boolean = true;
  epicEstimate:boolean = false;
  addNewEpic:boolean = false;
  newFeatureTitle: String = '';
  newFeatureDescription: String = '';
  disableAddNewFeature: boolean = false;
  openArchivePopUp: boolean = false;
  openStartProjectScrollLoader: boolean = false;
  params = {
    q: '',
    page: 1,
    timezone: '',
  };
  meta: any;
  projectSubscription: Subscription;
  boardProjects = [];
  resultStoriesStatus:boolean = true;
  resultLabelsStatus:boolean = false;
  moreSearchStoryAvailale = true;
  searchStoriesParam = 1;
  sprints: Array<Sprint> = [];
  @ViewChild('expand') expand: ElementRef;
  // storySizeExpand:boolean = false;
  acceptSelectionPopup: boolean = false;
  storiesCanBeAccepted = [];
  storiesCannotBeAccepted = [];
  alwaysShowCalendars: boolean;
  selectedDate: { startDate: moment.Moment, endDate: moment.Moment };
  ranges: any = {
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'Last 3 Month': [moment().subtract(3, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'Last 6 Month': [moment().subtract(6, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'Last 12 Month': [moment().subtract(12, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  };
  isUserAndOwnerSame = '';
  storiesToArchiveOrUnarchive = [];
  archive_meta: any;
  showBulkOptions:boolean = false;
  bulk_role_update:boolean = false;
  unArchiveSelectionPopup: boolean = false;
  showHideTaskDropdown: boolean = false;
  selectRoleDD:boolean = false;
  roleUpdateList = [];
  roleUpdateList_meta: any;
  storyColumnExpanded:boolean = false;
  featureSearchVisible:boolean = false;
  featureSearched:boolean = false;
  disableArchive:boolean = false;
  sprintDropdown:boolean = false;
  private notificationSubscriptions: Subscription[] = [];
  
  constructor(
    private cdr: ChangeDetectorRef,
    private ngZone: NgZone,
    public sharedService: SharedService,
    private router: Router,
    private customToast: CustomToastService,
    private differs: IterableDiffers,
    private userService: UserService,
    public projectService: ProjectService,
    private policyService: PolicyService,
    private errorService: ErrorService,
    private notificationService: NotificationService,
    private activatedRoute: ActivatedRoute,
    private dragulaService: DragulaService,
    private storyService: StoryService,
    private epicService: EpicService,
    private projectSyncService: ProjectSyncService,
    private renderer2: Renderer2,
    public filterStoryService: FilterStoryService,
    private location: Location) {
    this.setupDragula();
    this.alwaysShowCalendars = true;
  }

  get locationUrl() {
    const queryIndex = window.location.href.indexOf('?');
    const queryUrl = window.location.href.substr(queryIndex + 1);
    const registerQueryParam = queryUrl.substr(0, queryUrl.indexOf('='));
    const registerQueryParamValue = queryUrl.substr(queryUrl.indexOf('=') + 1);
    return { registerQueryParam: registerQueryParamValue };
  }

  get isProjectReadonly(): boolean {
    return this.policyService.isProjectReadonly(this.project);
  }

  ngOnInit() {
    this.startLoader = true;
    this.currentUrl = this.router.url;
    this.projectService.trackEventSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
        if (((this.currentUrl.indexOf('?') < 0 && this.previousUrl.indexOf('?')) < 0 ||
          this.userService.userSignedIn()) && this.project) {
          this.userService.trackSegmentEvent('Story page ended', this.getPropsOnEnd());
        }
      }
    });

    this.projectService.searchString = '';
    this.activatedRoute.params.subscribe((params: Params) => {
      this.userService.pro_id_for_reg = +params['id'];
      if (this.locationUrl.registerQueryParam == 'confirmation_token') {
        this.inviteConfirmation(params, this.locationUrl);
        this.unsubscribeQueryParams();
      } else {
        this.unsubscribeQueryParams();
        this.paramsSubscription = this.activatedRoute.queryParams.pipe(
          debounceTime(500),
          filter((queryParams: any) => {
            if (this.userService.loginUrl && this.userService.userSignedIn()) {
              const qParamsObj = this.sharedService.getQueryParam(this.userService.loginUrl);
              if (qParamsObj && qParamsObj.key == 'redirect_to_uiw' && qParamsObj.value == 'true' && this.userService.getAdminLogin()) {
                if (params && params.id) {
                  window.location.href = API_ROUTES.UIW_URL(environment.designerUrl, params.id);
                } else {
                  window.location.href = environment.designerUrl;
                }
              }
              if (this.userService.redirectToStoryBoard) {
                this.router.navigate(["/projects/" + this.userService.pro_id_for_reg])
              } else {
                this.router.navigateByUrl(this.userService.loginUrl);
              }
              this.projectService.isFormMail = true;
              this.userService.loginUrl = '';
              this.userService.redirectToStoryBoard = false;
            }
            if (!queryParams.confirmation_token) {
              this.startLoader = true;
              this.loadInitialData(params);
              if (queryParams.storyId) {
                this.projectService.storyId = queryParams.storyId;
                this.projectService.urlStoryId = +queryParams.storyId;
                this.projectService.is_rebuild = true;
              }
            }
            if (queryParams.confirmation_token) {
              this.inviteConfirmation(params, { 'confirmation_token': queryParams.confirmation_token });
            }

            this.unsubscribeQueryParams();
            return queryParams.confirmation_token;
          })).subscribe((qparams) => {
            if (qparams['confirmation_token']) {
              this.policyService.initialPolicies();
              this.inviteConfirmation(params, qparams);
            } else {
              this.loadInitialData(params);
            }
          });
      }
    });

    // Clone Story.
    this.subscribeStory();

    window.onbeforeunload = (ev) => {
      this.userService.trackSegmentEvent('Story page ended', this.getPropsOnEnd());
      return true;
    };

    this.notificationService.subscribeEvent(EVENT_TYPES.STORY.SET_OWNER, () => this.openOwnerPopup());
    this.notificationService.subscribeEvent(EVENT_TYPES.STORY.SET_REVIEWER, () => this.openReviewerPopup());
    this.notificationService.subscribeEvent(EVENT_TYPES.STORY.SET_ESTIMATE, res => {
      this.story = res.payload.data.story;
      this.openEstimatePopup();
    });
    this.notificationService.subscribeEvent(EVENT_TYPES.STORY.EDIT_FEATURES_LABELS, () => this.openFeatureLabelPopup());
    this.notificationService.subscribeEvent(EVENT_TYPES.STORY.MOVE, () => this.openMoveSelectedPopup());
    // this.projectService.get_native_element = this.expand.nativeElement;
    const sprintAddedEvent = this.notificationService.subscribeEvent(EVENT_TYPES.SPRINT.SPRINT_CREATED, res => {
      this.filterStoryService.sprintFilterList.unshift(new Sprint(res.payload.sprint));
    });
    this.notificationSubscriptions.push(sprintAddedEvent);
    const sprintDeletedEvent = this.notificationService.subscribeEvent(EVENT_TYPES.SPRINT.SPRINT_DELETED, res => {
      if (res) {
        const sprint_Index = this.filterStoryService.sprintFilterList.findIndex(st_sprint_index => st_sprint_index.id === res.payload.sprint.id);
        if (sprint_Index !== -1 && !this.isFilterChecked('sprints', res.payload.sprint.id)) {
          this.filterStoryService.sprintFilterList.splice(sprint_Index, 1);
          this.filterStoryService.currentRunningSprintList = this.filterStoryService.sprintFilterList.filter((sprint: any) => sprint.status === 'running')
        } else {
          const currentDeletedSprint = this.filterStoryService.currentRunningSprintList.find(st_sprint_index => st_sprint_index.id === res.payload.sprint.id)
          this.filterStoryService.deletedSprintData = _.isEmpty(currentDeletedSprint) ? null : currentDeletedSprint
        }
      }
    });
    this.notificationSubscriptions.push(sprintDeletedEvent);

    const sprintUpdatedEvent = this.notificationService.subscribeEvent(EVENT_TYPES.SPRINT.SPRINT_UPDATED, res => {
      const sprint_Index = this.filterStoryService.sprintFilterList.findIndex(st_sprint_index => st_sprint_index.id === res.payload.sprint.id);
      this.filterStoryService.sprintFilterList[sprint_Index] = new Sprint(res.payload.sprint);
      this.filterStoryService.currentRunningSprintList = this.filterStoryService.sprintFilterList.filter((sprint: any) => sprint.status === 'running')
    }); 
    this.notificationSubscriptions.push(sprintUpdatedEvent);
  }
  
  openCloneConfirmation() {
    this.cloneConfirmPopup = true;
  }

  closecloneconfirmation() {
    this.closecloneconfirmationPopup();
    this.selStories.forEach(story => {
      story.show_reason_dd = false;
      story.story_create_reason_for_bulk_duplicate = '';
      story.show_other_reason_input = false;
      story.bulk_story_selected_reason = '';
      story.other_reason_input = '';
    });
  }
  closecloneconfirmationPopup() {
    this.cloneConfirmPopup = false;
    this.projectService.selected_reason_option = '';
    this.renderer2.removeClass(document.body, 'duplicateStoryPopup-open'); 
  }

  closeAllocationAlert() {
    this.allocationAlert = false;
    this.allocationRedAlert = false;
  }

  bulkFeatureLabelAdd() {
    if (this.project) {
      const selectedStoriesIds = map(this.selStories, 'id');
      const selectedEpicsIds = map(this.SelectedEpics, 'id');
      this.waitForLoader = true;
      this.storyService.bulkFeaturesLabels(selectedStoriesIds, selectedEpicsIds, this.project.id, this.selectedLabelsArr).subscribe((response) => {
        this.closefeaturelabelPopup();
        this.waitForLoader = false;
        if (this.selStories.length > 1) {
          this.deSelectAll();
        }
        this.projectService.project_labels(this.project).subscribe(data => {
          this.project.labels = data;
        });
        if (selectedStoriesIds.length > 1) {
          this.customToast.messages.push({
            id: 'bulk_epic',
            type: 'success',
            class: 'bulk_epic',
            title: 'Bulk Features and Labels',
            message: response.message
          });
        }
        this.trackBulkStoryTaskUpdateSegmentEvent(response.stories, 'features');
        this.trackBulkStoryTaskUpdateSegmentEvent(response.stories, 'labels');

        // Update local story variable with response story data
        response.stories.forEach((story: any) => {
          this.projectSyncService.updateLocalStory(new Story(story), null, null);
        });
      }, (error) => {
        this.waitForLoader = false;
        this.errorService.errorFunction(error);
      });
    }
  }

  toggleDeleteSelection(event, story) {
    const uncheck_story = this.togglestoryDel.indexOf(story.id);
    event.target.checked ? this.togglestoryDel.push(story.id) : this.togglestoryDel.splice(uncheck_story, 1);
  }


  toggleEpicSelection(event, epic: Epic) {
    this.deselectEpics();
    if (event.target.checked) {
      epic.isSelected = true;
    } else {
      epic.isSelected = false;
    }
  }

  selectProjectForMove(event, project) {
    if (event.target.checked) {
      this.selectedProjectId = project.id;
    }
  }

  selectOwnerForAllocation(event, user) {
    this.projectService.storiesInvalidToAssignUser = [];
    if (event.target.checked) {
      this.selectedOwnerId = user.id;
      this.checkSameUserBulkAction(user,'owner')
    }
  }

  selectReviewerForAllocation(event, user) {
    this.projectService.storiesInvalidToAssignUser = [];
    if (event.target.checked) {
      this.selectedReviewerId = user.id;
      this.checkSameUserBulkAction(user,'reviewer')
    }
  }

  bulkDelete() {
    this.waitForLoader = true;
    const data = this.archiveRelatedData();
    const invalid_stories = map(this.storiesToArchiveOrUnarchive, 'id');
    this.storyService.bulkDelete(this.project.id, data, invalid_stories).subscribe(res => {
      this.closeDeleteSelectionPopup();
      this.waitForLoader = false;
      this.deSelectAll();
      if (res && res.message) {
        this.customToast.messages.push({
          id: 'bulk_delete',
          type: 'success',
          class: 'bulk_delete',
          title: 'Bulk Archive',
          message: res.message
        });
      }
    }, (error) => {
      this.waitForLoader = false;
      this.errorService.errorFunction(error);
    });
  }

  archiveRelatedData() {
    const stories_selected = map(this.selStories, 'id');
    const filters = this.projectService.getFiltersForAPICall(this.project);
    const col_selected = [];
    this.projectColumns.forEach(item => {
      if(item.allSelected && item.stories.length > 0) {
        col_selected.push(item.list_id)
      };
    });
    const data = {
      selected_stories: stories_selected,
      list_id: col_selected,
      filters: filters
    };
    return data;
  }

  invalidToUnarchive() {
    return (this.archive_meta.total_selected - this.archive_meta.valid_to_archive);
  }

  toggleUnarchiveSelection(event, story) {
    const uncheck_story = this.storiesToArchiveOrUnarchive.indexOf(story.id);
    event.target.checked ? this.togglestoryDel.push(story.id) : this.togglestoryDel.splice(uncheck_story, 1);
  }

  bulkUnarchive() {
    if(this.togglestoryDel.length > 0) {
      this.waitForLoader = true;
      this.storyService.bulkUnarchive(this.project.id, this.togglestoryDel).subscribe(res => {
        // this.projectSyncService.updateLocalStory(this.togglestoryDel, null, null);
        this.closeDeleteSelectionPopup();
        this.waitForLoader = false;
        this.deSelectAll();
        if (res && res.message) {
          this.customToast.messages.push({
            id: 'bulk_delete',
            type: 'success',
            class: 'bulk_delete',
            title: 'Bulk Archive',
            message: res.message
          });
        }
        
      }, (error) => {
        this.waitForLoader = false;
        this.errorService.errorFunction(error);
      });
    }
  }

  openDeleteSelectionPopup(type) {
    type === 'archive' ? this.deleteSelectionPopup = true : this.unArchiveSelectionPopup = true;
    this.waitForLoader = true;
    this.storiesToArchiveOrUnarchive = [];
    if (this.selStories.length > 0) {
      if(type === 'archive') {
        const data = this.archiveRelatedData();
        this.storyService.bulkDeleteCheck(this.project.id, data).subscribe(res => {
          this.archive_meta = res.meta;
          if(res && res.stories) {
            res.stories.forEach( story_data => {
              this.storiesToArchiveOrUnarchive.push(story_data);
            });
          }
          this.waitForLoader = false;
        }, (error) => {
          this.waitForLoader = false;
          this.errorService.errorFunction(error);
        });
      } else{
        const stories_selected = map(this.selStories, 'id');
        this.storyService.bulkUnarchiveCheck(this.project.id, stories_selected).subscribe(res => {
          this.archive_meta = res.meta;
          if(res && res.stories) {
            res.stories.forEach( story_data => {
              this.storiesToArchiveOrUnarchive.push(story_data);
            });
          }
          this.togglestoryDel = map(this.storiesToArchiveOrUnarchive, 'id');
          this.waitForLoader = false;
        }, (error) => {
          this.waitForLoader = false;
          this.errorService.errorFunction(error);
        });
      }
      
    } else {
      this.customToast.messages.push({
        id: 'bulk_story_delete',
        type: 'warning',
        class: 'story_new',
        title: 'bulk-epic-error',
        message: 'There is no selected stories to be deleted.'
      });
    }
  }

  storiesUnableToArchive() {
    return this.archive_meta && (this.archive_meta.total_selected === this.archive_meta.invalid_to_archive);
  }

  moveStoriesToProject() {
    const storiesId = [];
    if (this.hasParentSubtasks()) {
      this.selStories.forEach(story => {
        if (!story.parent_id) {
          storiesId.push(story.id);
        } else {
          if (!this.isParentSelected(story.parent_id)) {
            storiesId.push(story.id);
          }
        }
      });
    } else {
      this.selStories.forEach(story => storiesId.push(story.id));
    }

    this.waitForLoader = true;
    this.storyService.bulkMove(storiesId, this.selectedProjectId, this.project.id, this.apply_on_sub_tasks).subscribe((response) => {
      this.closeMoveSelectedPopup();
      this.waitForLoader = false;
      this.deSelectAll();
      if (storiesId.length > 1) {
        this.customToast.messages.push({
          id: 'bulk_move',
          type: 'success',
          class: 'stories_moved',
          title: 'Bulk Move',
          message: 'Stories moved successfully.'
        });
      } else if (storiesId.length === 1) {
        this.customToast.messages.push({
          id: 'single_move',
          type: 'success',
          class: 'stories_moved',
          title: 'Story Moved',
          message: 'Story has been moved successfully.'
        });
      }
      this.trackStoryBulkMoveSegmentEvent(response.stories, response.project.project);
      this.apply_on_sub_tasks = true;
      this.selectedProjectId = undefined;
    }, (error) => {
      this.waitForLoader = false;
      this.errorService.errorFunction(error);
      this.apply_on_sub_tasks = true;
      this.selectedProjectId = undefined;
    });
  }

  checkSameUser(user,type){
    if ((type=== 'owner') && (this.selStories.length === 1) && (user.id === this.selStories[0].reviewer_id) && (this.selStories[0].phase !== 'Product roadmap') && this.project.external()) {
      return true;
    }else if ((type=== 'reviewer') && (this.selStories.length === 1) && (user.id === this.selStories[0].owned_by_id) && (this.selStories[0].phase !== 'Product roadmap' && this.project.external())) {
      return true;
    }else{ 
     return false;
    }
  }

  checkSameUserBulkAction(user,type){
    this.selStories.forEach(story =>{
      if (type === 'owner' && story.reviewer_id === user.id && story.phase !== 'Product roadmap' && this.project.external()) {
        this.projectService.storiesInvalidToAssignUser.push(story);
      }else if (type === 'reviewer' && story.owned_by_id === user.id && story.phase !== 'Product roadmap' && this.project.external()) {
        this.projectService.storiesInvalidToAssignUser.push(story);
      }else if (story.dev_type === 'E2E' && !this.checkUserPodRole(user.pod_role)) {
        this.projectService.storiesInvalidToAssignUser.push(story);
      }else {
       return;
      }
    });
  }

  checkUserPodRole(pod_role) {
    const allowed_pod_role = ['CPE','CTE','SCTE','SCPE','CPM','SCPM'];
    return _.includes(allowed_pod_role, pod_role);
  }

  checkEligibleStories(type){
    const filteredList = this.selStories.filter(story =>{
        if (story.phase !== 'Product roadmap' && this.project.external()) {
          if (type === 'owner') {
            return (this.selectedOwnerId !== story.reviewer_id);
          }else{
            return (this.selectedReviewerId !== story.owned_by_id);
          }
        }else{
          return story;
        }
      })
      if (filteredList.length > 0) {
        return true;
      }else{
        return false;
      }
  }

  allocateStoriesToMember(type) {
    const selected_user_info = this.project.users.filter(ad => {
      return (type === 'owner') ? ad.id === this.selectedOwnerId : ad.id === this.selectedReviewerId;
    });
    const filteredList = this.selStories.filter(story =>{
      if (story.phase === 'Product roadmap' && this.project.external()) {
        return true;
      }
      if (type === 'owner') {
        return story.dev_type === 'E2E' ? this.selectedOwnerId !== story.reviewer_id && this.checkUserPodRole(selected_user_info[0].pod_role) : this.selectedOwnerId !== story.reviewer_id;
      } else {
        return story.dev_type === 'E2E' ? this.selectedReviewerId !== story.owned_by_id && this.checkUserPodRole(selected_user_info[0].pod_role) : this.selectedReviewerId !== story.owned_by_id;
      }
    });
    const selectedStoriesIds = map(filteredList, 'id');
    this.waitForLoader = true;
    let data: any;
    if (type === 'owner') {
      data = {
        story_ids: selectedStoriesIds,
        owned_by_id: this.selectedOwnerId
      };
    } else if (type === 'reviewer') {
      data = {
        story_ids: selectedStoriesIds,
        reviewer_id: this.selectedReviewerId
      };
    }
    this.storyService.bulkAssignMember(this.project.id, data).subscribe((response) => {
      if (type === 'owner') {
        this.closeOwnerPopup();
      } else if (type === 'reviewer') {
        this.closeReviewerPopup();
      }
      this.waitForLoader = false;
      if (this.selStories.length > 1) {
        this.deSelectAll();
      }
      this.trackBulkStoryTaskUpdateSegmentEvent(response, type);

      // Update local story variable with response story data
      response.forEach((story: any) => {
        const storyResponse = story.story;
        const st = this.project.findStoryByID(storyResponse.id);
        if (st) {
          if (type === 'owner') {
            st.owned_by_id = storyResponse.owned_by_id;
            st.owned_by_name = storyResponse.owned_by_name;
            st.owner_email = storyResponse.owner_email;
            st.owned_by_initials = storyResponse.owned_by_initials;
          } else if (type === 'reviewer') {
            st.reviewer_id = storyResponse.reviewer_id;
            st.reviewer_name = storyResponse.reviewer_name;
            st.reviewer_email = storyResponse.reviewer_email;
            st.reviewer_initials = storyResponse.reviewer_initials;
          }
        }
      });
    }, (error) => {
      this.waitForLoader = false;
      this.errorService.errorFunction(error);
    });
  }

  removeEpic(epic) {
    epic.isSelected = false;
  }

  get SelectedEpics() {
    if (this.project) {
      return this.project.epics.filter((epic) => {
        return epic.isSelected == true;
      });
    }
  }

  get selStories() {
    if (this.project.stories.length > 0) {
      return this.project.stories.filter((story) => {
        return story.isSelected === true;
      });
    }
  }

  openFeatureLabelPopup() {
    this.searchFeatureLabel = '';
    if (this.project && this.project.epics && this.project.epics.length > 0) {
      this.openfeaturelabelPopup();
      this.deselectEpics();
    }
    if (this.project && this.project.labels.length > 0) {
      this.openfeaturelabelPopup();
      this.selectedLabelsArr = [];
      this.selStories.forEach((story) => {
        if (story.labels) {
          story.labels.forEach((label) => {
            const isLabelAlreadyExists = _.includes(this.selectedLabelsArr, label.display);
            if (!isLabelAlreadyExists) {
              this.selectedLabelsArr.push(label.display);
            }
          });
        }
      });
    }
    if (this.project && this.project.labels.length === 0 && this.project.epics.length === 0) {
      this.customToast.messages.push({
        id: 'bulk_story_epic',
        type: 'warning',
        class: 'bulk-epic-error',
        title: 'Bulk Stories Features & Labels',
        message: 'There is no Feature or Label.'
      });
    }
  }

  deselectEpics() {
    this.project.epics.forEach((epic) => {
      epic.isSelected = false;
    });
  }

  loadProjects() {
    if (this.projectService.all_projects) {
      this.projects = this.projectService.all_projects;
    } else {
      this.projectService.getProjectsList().subscribe((data) => {
        if (data && data.projects) {
          this.meta = data.meta;
          this.projects = data.projects;
        }
      },
        (error) => {
          this.errorService.errorFunction(error);
        });
    }
  }


  openfeaturelabelPopup() {
    this.featurelabelSelectionPopup = true;
    this.projectService.focus_input = true;
  }

  closefeaturelabelPopup() {
    this.searchFeatureLabel = '';
    this.featurelabelSelectionPopup = false;
    this.projectService.focus_input = false;
    if (this.project.selectedStories.length === 1) {
      this.sharedService.closeQuickAction(this.project);
    }
  }

  isLabelSelected(label) {
    return _.includes(this.selectedLabelsArr, label);
  }

  removeLabel(label) {
    this.selectedLabelsArr = this.selectedLabelsArr.filter(lab => lab != label);
  }

  get selectedLabels() {
    return this.selectedLabelsArr;
  }

  toggleLabelSelection(event, label) {
    if (event.target.checked) {
      const isLabelAlreadyExists = _.includes(this.selectedLabelsArr, label.display);
      if (!isLabelAlreadyExists) {
        this.selectedLabelsArr.push(label);
      }
    } else {
      this.selectedLabelsArr = this.selectedLabelsArr.filter(lab => lab != label);
    }
  }

  addNewLabel(label) {
    this.selectedLabelsArr.push(label);
    this.project.labels.push(label);
  }



  getFiteredProject(search?, move?) {
    this.params.q = this.searchProject;
    this.params.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.startProjectScrollLoader = true;
    this.isPending = true;
    if(move) {
      this.openMoveSelectionPopup();
    }
    if (this.projectSubscription) {
      this.projectSubscription.unsubscribe();
    }
    this.projectSubscription = this.projectService.getProjects(this.params).
      subscribe(res => {
        this.meta = res.meta;
        if (search) {
          this.boardProjects = [];
        }
        res.projects.forEach(data => this.boardProjects.push(data.project));
        this.boardProjects = this.adminFilterdProject();
        // if (move) {
        //   this.showFilteredProject();
        // }
        this.isPending = false;
        this.startProjectScrollLoader = false;

      },
        (error) => {
          this.errorService.errorFunction(error);
        });
  }


  adminFilterdProject() {
    if (this.project) {
      return this.boardProjects.filter(project => {
        return project.id != this.project.id && this.policyService.isProjectAdmin(project);
      });
    } else {
      return [];
    }
  }

  adminSkipFilterdProject() {
    if (this.project) {
      return this.projects.filter(project => {
        return (project.id == this.project.id || !this.policyService.isProjectAdmin(project));
      });
    } else {
      return [];
    }
  }

  get checkProjectAdmin(): boolean {
    return this.policyService.isProjectAdmin(this.project);
  }

  isScrollFetchAvailable(): boolean {
    return !this.isPending && this.moreAvailable;
  }

  getOlderProject() {
    this.startProjectScrollLoader = true;
    if ((this.meta.total_count - this.adminSkipFilterdProject().length) == this.boardProjects.length) {
      this.moreAvailable = false;
    }
    if (this.moreAvailable) {
      this.params.page++;
      this.getFiteredProject();
    }
  }

  getSearchProjects() {
    this.params.page = 1;
    this.getFiteredProject(true);
  }

  openMoveSelectedPopup() {
    this.params.page = 1;
    this.boardProjects = [];
    this.searchProject = '';
    this.meta = this.projectService.meta;
    if(this.params.page == 1) {
      this.openMoveSelectionPopup();
      this.openStartProjectScrollLoader = true;
      this.projects.forEach(data => this.boardProjects.push(data));
      this.boardProjects = this.adminFilterdProject();
      // this.params.page++;
      this.openStartProjectScrollLoader = false;
    } else {
      this.getFiteredProject('', true);
    }

  }

  showFilteredProject() {
    if (this.boardProjects.length > 0) {
      this.openMoveSelectionPopup();
    } else {
      this.customToast.messages.push({
        id: 'bulk_story_move',
        type: 'warning',
        class: 'bulk-epic-error',
        title: 'Bulk Stories Move',
        message: 'There is no Project to move to stories.'
      });
    }
  }

  openMoveSelectionPopup() {
    this.moveSelectionPopup = true;
    this.projectService.focus_input = true;
  }

  closeMoveSelectedPopup() {
    this.searchProject = '';
    this.moveSelectionPopup = false;
    this.selectedProjectId = undefined;
    this.projectService.focus_input = false;
  }

  closeDeleteSelectionPopup() {
    this.deleteSelectionPopup = false;
    this.unArchiveSelectionPopup = false;
    this.archive_meta = null;
    this.storiesToArchiveOrUnarchive = [];
    this.togglestoryDel = [];
  }

  openRoleChange(role) {
    this.waitForLoader = true;
    this.bulk_role_update = true;
    const stories_selected = map(this.selStories, 'id');
    this.roleUpdateList = [];
    this.roleUpdateList_meta = null;
    this.project.selected_role_for_bulk_action = role;
    if (this.selStories.length > 0) {
      this.storyService.bulkChangeRoleCheck(this.project.id, stories_selected, this.project.selected_role_for_bulk_action).subscribe(res => {
        this.roleUpdateList_meta = res.meta;
        this.roleUpdateList_meta['not_valid_count'] = res.meta.total_selected - res.meta.valid_to_bulk_update; 
        if(res && res.stories) {
          res.stories.forEach( story_data => {
            this.roleUpdateList.push(story_data);
          });
        }
        this.waitForLoader = false;
      }, (error) => {
        this.waitForLoader = false;
        this.errorService.errorFunction(error);
      });
    }
  }

  bulkUpdateStoriesRole() {
    this.waitForLoader = true;
    const role_data = this.project.selected_role_for_bulk_action;
    const stories_selected = map(this.roleUpdateList, 'id');
    this.storyService.bulkChangeRole(this.project.id, stories_selected, role_data).subscribe(res => {
        this.selectRoleDD = false;
        this.deSelectAll();
        this.customToast.messages.push({
          id: 'bulk_role_change',
          type: 'success',
          class: 'stories_moved',
          title: 'Bulk Update Role',
          message: res.message
        });
        this.closeRoleChange();
        this.waitForLoader = false;
      }, (error) => {
        this.waitForLoader = false;
        this.errorService.errorFunction(error);
      });
  }

  closeRoleChange() {
    this.bulk_role_update = false;
    this.waitForLoader = false;
    this.project.selected_role_for_bulk_action = null;
  }

  closeRoleChangeDD() {
    this.selectRoleDD = false;
  }
 
  openAcceptRejectConfirmation(type) {
    this.renderer2.addClass(document.body, 'duplicateStoryPopup-open');
    this.projectService.subtaskSelected = false;
    this.projectService.acceptRejectPopupType = type;
    this.projectService.acceptRejectPopup = true;
    if (this.selStories.length > 0) {
      this.initialSelStories = Object.assign([], this.selStories);
      if (type === 'accept') {
        this.selStories.forEach(item => {
          if(item.storyAcceptableRejectable(this.userService.currentUser,true)) {
            this.projectService.storiesCanBeAccepted.push(item);
          } else {
              this.projectService.storiesCannotBeAccepted.push(item);
          }
        });
        this.projectService.togglestoryAccept = map(this.projectService.storiesCanBeAccepted, 'id');
      } else{
        this.selStories.forEach(item => {
          if(item.storyAcceptableRejectable(this.userService.currentUser,false)) {
            this.projectService.storiesCanBeRejected.push(item);
          } else {
            this.projectService.storiesCannotBeRejected.push(item);
          }
        });
        this.projectService.togglestoryReject = map(this.projectService.storiesCanBeRejected, 'id');
      }
    } 
  }

  inviteConfirmation(params, qparams) {
    this.policyService.initialPolicies();
    this.projectService.acceptProjectInvitation({ 'confirmation_token': qparams.confirmation_token }).subscribe((resp) => {
      if (resp.signup_confirmation_token) {
        this.userService.loginUrl = this.location.path();
        const queryParams = { 'confirmation_token': resp.signup_confirmation_token };
        this.router.navigate(['register'], { queryParams: queryParams });
        return;
      }
      if (!this.userService.userSignedIn()) {
        this.router.navigateByUrl('/login');
      } else {
        if (this.userService.getDesignerLogin()) {
          window.location.href = environment.designerUrl;
        }
        this.loadInitialData(params);
      }
      this.unsubscribeQueryParams();
    },
      (error) => {
        if (!this.userService.userSignedIn()) {
          this.getUrl();
          this.errorService.errorFunction(error);
          this.router.navigateByUrl('/login');
        } else {
          if (this.userService.getDesignerLogin()) {
            window.location.href = environment.designerUrl;
          }
          this.loadInitialData(params);
        }
        this.unsubscribeQueryParams();
        // this.router.navigate(['login'])
      });
  }

  unsubscribeQueryParams() {
    if (this.paramsSubscription) {
      this.paramsSubscription.unsubscribe();
    }
  }

  loadInitialData(params) {
    this.filterStoryService.sprintFilterList = []
    this.filterStoryService.isFirst = true
    this.filterStoryService.currentRunningSprintList = []
    this.filterStoryService.deletedSprintData = null
    this.clearData();
    this.projectId = +params['id'];
    this.loadData();
    this.loadProjects();
    this.projectSyncService.subscribe(this);
  }


  ngAfterViewInit() {
    setTimeout(() => {
      this.setupDomAutoscroller()
    }, 0);
  }

  ngOnDestroy() {
    this.filterStoryService.sprintFilterList = []
    this.filterStoryService.isFirst = true
    this.filterStoryService.currentRunningSprintList = []
    this.filterStoryService.deletedSprintData = null
    if(this.projectService.show_storyDetails) {
      this.projectService.hide_StoryDetails_if_it_is_open();
    }
    
    if (this.userService.userSignedIn() && this.project) {
      this.userService.trackSegmentEvent('Story page ended', this.getPropsOnEnd());
    }
    if (this.projectService.trackEventSubscription) {
      this.projectService.trackEventSubscription.unsubscribe();
    }
    if (this.paramsSubscription) {
      this.paramsSubscription.unsubscribe();
    }
    this.dragulaService.destroy('project');
    this.projectService.show_users = false;
    this.projectService.docker_ides = [];
    this.showSelectedFilterType = null;
    if (this.project) {
      this.project.clearFilter();
    }
    this.projectSyncService.unsubscribe();
    this.notificationSubscriptions.forEach(subscription => subscription.unsubscribe());
    this.changeEpicViewToStatus();
  }

  applyFilter(key, value) {
    this.filterStoryService.applyFilter(this.project, null, key, value);
  }

  isOutOfViewport(elem) {

    // Get element's bounding
    const bounding = elem.getBoundingClientRect();

    // Check if it's out of the viewport on each side
    const out: any = {};
    out.top = bounding.top < 0;
    out.left = bounding.left < 0;
    out.bottom = bounding.bottom > (window.innerHeight || document.documentElement.clientHeight);
    out.right = bounding.right > (window.innerWidth || document.documentElement.clientWidth);
    out.any = out.top || out.left || out.bottom || out.right;
    out.all = out.top && out.left && out.bottom && out.right;

    return out;

  };
  mouseMove(event) {
    if (this.dragStart) {
      let viewportX = event.clientX;
      let viewportY = event.clientY;
      if (viewportX >= 1250) {
        let el1 = this.projectStoriesTableContainer.nativeElement;
        el1.scrollTo({ left: (el1.scrollLeft + 400), behavior: 'smooth' });
      }
      if (viewportX <= 380) {
        let el1 = this.projectStoriesTableContainer.nativeElement;
        el1.scrollTo({ left: (el1.scrollLeft - 400), behavior: 'smooth' });
      }
    }
  }

  setupDragula() {
    this.dragulaService.setOptions('project', {

      isContainer: (el) => {
        return Draggable.isContainer(el); // only elements in drake.containers will be taken into account
      },
      accepts: (el, target, source, sibling) => {
        return Draggable.accepts(el, target, source, sibling);
      },
      invalid: (el, handle) => {
        return Draggable.invalid(el, handle);
      },
      isDropZone: (item, source, target) => {
        return Draggable.isDropZone(item, target);
      },
      isDropPlaceholder: (item, target) => {
        return Draggable.isDropPlaceholder(item, target);
      },
      isNotDroppable: (item, source, target, sibling) => {
        const story = Draggable.getStoryFromElement(item);
        const column = this.sharedService.getColumnByColumnIdentifier(target.id);

        if (this.isProjectReadonly) {
          this.policyService.readOnlyWarning();
          return true;
        }
        
        if (story.inDraft()) {
          this.policyService.inDraftMode();
          return true;
        }

        if (!story) {
          this.dragStart = false;
          return true;
        }

        if (source === target) {
          return false;
        }

        if (!column) {
          return true;
        }

        if (column.list_type == 'custom') {
          return false;
        }
        const sourceColumn2 = this.sharedService.getColumnByColumnIdentifier(source.id);

        if(((this.policyService.isProjectAdmin(this.project)) && 
        (this.userService.currentUser.id !== story.owned_by_id) && 
        (this.userService.currentUser.id !== story.reviewer_id) && 
        (target.id !== PROJECT_COLUMN_IDENTIFIER.to_do || sourceColumn2.list_type !== 'custom')
        )) {
          this.customToast.messages.push({
            id: 'normalUserWithoutEstimateDrag',
            type: 'fail',
            class: 'generic_alert',
            title: 'Alert',
            message: "You are not authorized to drag the story as only Owner/Reviewer can drag a story."
          });
          return true;
        }

        if (story.epics.length === 0) {
          this.customToast.messages.push({
            id: 'storyWithoutFeature',
            type: 'fail',
            class: 'generic_alert',
            title: 'Alert',
            message: "Please tag feature as no feature is tagged in this story."
          });
          this.dragStart = false;
          return true;
        }

        if (story.epics[0].status === 'inactive') {
          this.customToast.messages.push({
            id: 'inactiveFeatureDrag',
            type: 'fail',
            class: 'generic_alert',
            title: 'Alert',
            message: "Inactive feature's story cannot be dragged."
          });
          return true;
        }

        if (story.story_type === 'bug' && (!story.bug_reason_id || !story.related_to_story_id)) {
          this.customToast.messages.push({
            id: 'inactiveFeatureDrag',
            type: 'fail',
            class: 'generic_alert',
            title: 'Alert',
            message: story.bugReasonTooltip()
          });
          return true;
        }

        if((!story.phase && 
          (target.id !== PROJECT_COLUMN_IDENTIFIER.rejected && target.id !== PROJECT_COLUMN_IDENTIFIER.archived && target.id !== PROJECT_COLUMN_IDENTIFIER.to_do)
          )) {
          this.customToast.messages.push({
            id: 'withoutPhase',
            type: 'fail',
            class: 'generic_alert',
            title: 'Alert',
            message: "Phase is required to start the story."
          });
          return true;
        }

        if(( 
          (this.userService.currentUser.id !== story.owned_by_id) && 
          (source.id === PROJECT_COLUMN_IDENTIFIER.to_do || source.id === PROJECT_COLUMN_IDENTIFIER.rejected || 
            source.id === PROJECT_COLUMN_IDENTIFIER.accepted || 
            source.id === PROJECT_COLUMN_IDENTIFIER.in_progress || 
            sourceColumn2.list_type == 'custom') && 
            (target.id === PROJECT_COLUMN_IDENTIFIER.qa || target.id === PROJECT_COLUMN_IDENTIFIER.in_progress || target.id === PROJECT_COLUMN_IDENTIFIER.to_do)
          )) {
          this.customToast.messages.push({
            id: 'normalUserWithoutEstimateDrag',
            type: 'fail',
            class: 'generic_alert',
            title: 'Alert',
            message: "You are not authorized to drag the story as only Owner can start, finish and deliver a story."
          });
          return true;
        }

        if (target.id === PROJECT_COLUMN_IDENTIFIER.qa) {
          this.story = story;
          if (this.story.checkForMR() && story.dev_type !== 'E2E') {
            this.customToast.messages.push({
              id: 'serverfail',
              type: 'fail',
              class: 'generic_alert',
              title: 'Alert',
              message: 'Story cannot be delivered as valid MR is not attached.'
            });
            return true;
          }
        }

        if (target.id === PROJECT_COLUMN_IDENTIFIER.archived) {
          this.story = story;
          if (this.project && this.project.external() && story && story.core && story.checkE2EStory()) {
            this.customToast.messages.push({
              id: 'serverfail',
              type: 'fail',
              class: 'generic_alert',
              title: 'Alert',
              message: 'Core E2E story cannot be archived.'
            });
            return true;
          }
          if (this.project && this.project.external() && story && !this.policyService.isUserProjectAdmin && this.story.story_type !== 'bug' && this.policyService.isQAExpert(this.project)) {
            this.policyService.readOnlyWarning();
            return true;
          }
          this.getExtractData(item, target, source, sibling, false);
          this.openArchivePopUp = true;
          return true;
        }

        /* check for feature's BB status validations */
        const storyEpic: Epic = story.getEpicInStory();
        if (storyEpic && !(story.isDesignStory() || story.core || story.parent_core || story.dev_type === 'QA' || !this.project.external())) {
          if(storyEpic.bb_status === BB_STATUS.assigned || storyEpic.bb_status === BB_STATUS.ready_for_certification || storyEpic.bb_status === BB_STATUS.bb_under_development){
            this.customToast.messages.push({
              id: 'serverfail',
              type: 'fail',
              class: 'generic_alert',
              title: 'Alert',
              message: 'Custom stories for a feature cannot be started unless all core stories are archived or accepted.'
            });
            this.dragStart = false;
            return true;
          }
        }
        if (!story.isDesignStory() && !story.isQACore && this.project && this.project.external() && storyEpic
              && storyEpic.builder_feature_id && storyEpic.bb_status && storyEpic.bb_status !== BB_STATUS.skipped && 
              (story.created_from === 'tracker' || story.created_from === 'catalog')) {

          if (target.id !== PROJECT_COLUMN_IDENTIFIER.to_do &&
                (storyEpic.bb_status === BB_STATUS.unassigned || storyEpic.bb_status === BB_STATUS.waiting)) {
            this.customToast.messages.push({
              id: 'serverfail',
              type: 'fail',
              class: 'generic_alert',
              title: 'Alert',
              message: 'Story status cannot be changed as BB is in the ' + this.sharedService.formatBBStatus(storyEpic.bb_status) +
                ' state for the feature (' + storyEpic.title + ')'
            });
            this.dragStart = false;
            return true;
          }

          if ((story.core || story.parent_core) && (storyEpic.bb_status === BB_STATUS.ready_for_certification ||
                (target.id !== PROJECT_COLUMN_IDENTIFIER.accepted && (storyEpic.bb_status === BB_STATUS.ready_for_customisation ||
                    storyEpic.bb_status === BB_STATUS.ongoing_customisation || storyEpic.bb_status === BB_STATUS.finished_customisation ||
                    storyEpic.bb_status === BB_STATUS.finished_using_bb)))) {
            this.customToast.messages.push({
              id: 'serverfail',
              type: 'fail',
              class: 'generic_alert',
              title: 'Alert',
              message: 'Story status cannot be changed as BB is in the ' + this.sharedService.formatBBStatus(storyEpic.bb_status) +
                ' state for the feature (' + storyEpic.title + ')'
            });
            this.dragStart = false;
            return true;
          }

          if ((story.core || story.parent_core) && target.id === PROJECT_COLUMN_IDENTIFIER.accepted &&
                (storyEpic.bb_status === BB_STATUS.assigned || storyEpic.bb_status === BB_STATUS.bb_under_development)) {
            this.customToast.messages.push({
              id: 'serverfail',
              type: 'fail',
              class: 'generic_alert',
              title: 'Alert',
              message: 'Story cannot be accepted as BB is in the ' + this.sharedService.formatBBStatus(storyEpic.bb_status) +
                ' state for the feature (' + storyEpic.title + ')'
            });
            this.dragStart = false;
            return true;
          }
        }

        // check if we have show story progress only
        if (column.list_type == 'fixed' && story.owned_by_id && 
          (story.confirmed_by_id === story.owned_by_id) && 
           story.estimate && 
          ((column.columnIdentifier == PROJECT_COLUMN_IDENTIFIER.in_progress) || ((column.columnIdentifier == PROJECT_COLUMN_IDENTIFIER.to_do) && story.current_timer_activity))
        ) {
          if (column.columnIdentifier == PROJECT_COLUMN_IDENTIFIER.in_progress) {
            this.otherStartedStory = this.sharedService.getActiveStory(story.owned_by_id, story.id);
            if (!this.otherStartedStory) {
              return false;
            }
          }
          this.story = story;
          this.getExtractData(item, target, source, sibling, true);
          return true;
        }
        // check if dropping in accepted column && parent has subtasks pending
        if (target.id === PROJECT_COLUMN_IDENTIFIER.accepted && story && (!story.parent_id) && story.sub_tasks.length > 0) {
          let notDroppable = this.isParentDroppable(story);
          if (notDroppable) {
            this.dragStart = false;
          }
          return notDroppable;
        }
        let notDroppable = Draggable.isNotDroppable(item, source, target);
        // if not droppable, raise error
        if (!!notDroppable) {
          this.customToast.messages.push({ id: "serverfail", type: "fail", class: "generic_alert", title: 'Alert', message: notDroppable })
          this.dragStart = false;
        } else {
          const sourceColumn = this.sharedService.getColumnByColumnIdentifier(source.id);
          // take inputs for owner and estimate if not there
          if ((source.id == PROJECT_COLUMN_IDENTIFIER.to_do || source.id == PROJECT_COLUMN_IDENTIFIER.in_progress || source.id == PROJECT_COLUMN_IDENTIFIER.qa || sourceColumn.list_type == 'custom') && 
              (target.id == PROJECT_COLUMN_IDENTIFIER.in_progress || target.id == PROJECT_COLUMN_IDENTIFIER.qa)) {
            if (!(story && story.owned_by_id) || (!story.confirmed && (story.confirmed_by_id !== story.owned_by_id))) {
              this.checkStory('owner', item, target, source, sibling, story);
              return true;
            }
          }
          // take inputs for reviewer and estimate if not there
          else if (((source.id != PROJECT_COLUMN_IDENTIFIER.accepted) && (source.id != PROJECT_COLUMN_IDENTIFIER.rejected)) && story.acceptRejectCondition() && (story.dev_type !== 'QA') && (target.id == PROJECT_COLUMN_IDENTIFIER.accepted || target.id == PROJECT_COLUMN_IDENTIFIER.rejected)) {
            this.customToast.messages.push({
              id: 'normalUserWithoutEstimateDrag2',
              type: 'fail',
              class: 'generic_alert',
              title: 'Alert',
              message: "Story cannot be dragged. Please add Reviewer."
            });
            return true;
          }
          else if (((source.id != PROJECT_COLUMN_IDENTIFIER.accepted) && (source.id != PROJECT_COLUMN_IDENTIFIER.rejected)) && (story.reviewer_id !== this.userService.currentUser.id) && (story.dev_type !== 'QA') && (target.id == PROJECT_COLUMN_IDENTIFIER.accepted || target.id == PROJECT_COLUMN_IDENTIFIER.rejected)) {
            this.customToast.messages.push({
              id: 'normalUserWithoutEstimateDrag2',
              type: 'fail',
              class: 'generic_alert',
              title: 'Alert',
              message: "You are not authorized to drag the story as only Reviewer can accept and reject a story."
            });
            return true;
          }
        }
        return !!notDroppable;
      },
      newReference: (item, sibling) => {
        return Draggable.newReference(item, sibling);
      }
    });

    this.dragulaService.cancel.subscribe((va) => {
      this.dragStart = false;
    })

    this.dragulaService.drag.subscribe((value) => {
      this.dragStart = true;
      document.addEventListener('mousemove', this.mouseMove.bind(this), false);
      Draggable.setInitialStoryData(value[1]);
      this.isEpicZone = false;
      Draggable.dragMode = true;
    });

    this.dragulaService.dragend.subscribe((value) => {
      Draggable.dragMode = false;
    });

    this.dragulaService.drop.subscribe((value) => {
      this.dragStart = false;
      document.removeEventListener('mousemove', this.mouseMove.bind(this), false)
      if (this.project) {
        this.project.is_dragg = true;
      }
      if (Draggable.dragMode && !this.isEpicZone) {
        const data: DraggableData = Draggable.extractDropData(value, this.project);
        this.dropAndUpdateStory(data.elementStory, data)
      }
    });
  }

  checkStory(type, item, target, source, sibling, story) {
    this.getExtractData(item, target, source, sibling);
    this.quickPopupType = type;
    this.openCheckStoryPopup(story);
  }
  checkStoryOwnerAndLoggedUser() {
    return this.story.owned_by_id && (this.userService.currentUser.id === this.story.owned_by_id);
  }

  getExtractData(item, target, source, sibling, progress?) {
    this.dragStart = false;
    this.sharedService.initialCoulmn = null;
    this.dragDropEstimateObj = null;
    let value = ['project', item, target, source, sibling];
    const data: DraggableData = Draggable.extractDropData(value, this.project);
    let elementStory = data.elementStory;
    const initialColumn = this.sharedService.getColumn(elementStory.list_id);
    const targetColumn = this.getColumnNameByColumnIdentifier(data.columnIdentifier);
    if (elementStory) {
      const siblingStory = data.siblingStory;
      if (data.siblingStory) {
        elementStory.position = this.reorderStory(targetColumn.stories, elementStory, siblingStory);
      } else {
        if (targetColumn.stories.length == 0) {
          elementStory.position = 1;
        } else {
          const lastStory = targetColumn.stories[targetColumn.stories.length - 1];
          elementStory.position = lastStory.position + 0.1;
        }
      }
    }

    this.sharedService.initialCoulmn = initialColumn;
    this.dragDropEstimateObj = {
      targetColumn: targetColumn,
      position: elementStory.position,
    };
    if (progress) {
      this.progressOnDragStory(data.elementStory, data);
    }
  }

  isParentDroppable(story) {
    const notDroppable = story.allSubTasksAccepted();
    if (!notDroppable) {
      this.customToast.messages.push({ id: "serverfail", type: "fail", class: "generic_alert", title: 'Alert', message: 'All Sub Tasks and Test Cases should be accepted.' })
    }
    return !notDroppable;
  }


  dropAndUpdateStory(elementStory, data) {
    if (elementStory) {
      this.sharedService.initialCoulmn = null;
      const elInitialPosition = elementStory.position;
      const initialState = elementStory.state;
      const initialColumn = this.sharedService.getColumn(elementStory.list_id);
      const initialColumnName = initialColumn.list_name;
      const initialListId = initialColumn.list_id;
      const siblingStory = data.siblingStory;
      if (this.project) {
        this.project.is_dragg = data && data.elementStory && data.elementStory.parent_id ? false : true;
      }
      const elementColumn = this.sharedService.getColumn(data.elementStory.list_id);
      const targetColumn = this.getColumnNameByColumnIdentifier(data.columnIdentifier);
      if (data.siblingStory) {
        elementStory.position = this.reorderStory(targetColumn.stories, elementStory, siblingStory);
      } else {
        if (targetColumn.stories.length == 0) {
          elementStory.position = 1;
        } else {
          const lastStory = targetColumn.stories[targetColumn.stories.length - 1];
          elementStory.position = lastStory.position + 0.1;
        }
      }
      if (elementColumn && targetColumn && (elementColumn.list_name != targetColumn.list_name) && targetColumn.list_type == 'fixed') {
        elementStory.state = this.updateStoryState(data.columnIdentifier);
      }
      elementStory.list_id = targetColumn.list_id;
      this.sharedService.initialCoulmn = initialColumn;
      this.updateStory(elementStory, elInitialPosition, initialState, initialColumnName, initialListId, initialColumn);
    }
  }

  progressOnDragStory(elementStory, data) {
    if ((data.columnIdentifier === PROJECT_COLUMN_IDENTIFIER.to_do) && elementStory.current_timer_activity) {
      this.otherStartedStory = elementStory;
    } else {
      this.otherStartedStory = this.sharedService.getActiveStory(elementStory.owned_by_id, elementStory.id);
    }
    if (this.otherStartedStory) {
      this.updateProgressOnDrag();
    }
  }

  updateProgressOnDrag(storyProgress?) {
    if (this.dragDropEstimateObj) {
      this.projectService.forDragDropLoader = true;
      this.story.position = this.dragDropEstimateObj.position;
      this.story.state = this.updateStoryState(this.dragDropEstimateObj.targetColumn.columnIdentifier);
      this.story.list_id = this.dragDropEstimateObj.targetColumn.list_id;
      if (this.story.id !== this.otherStartedStory.id) {
        this.storyService.updateStory(this.story, true).subscribe((st: Story) => {
          this.projectSyncService.updateAndShiftStory(this.story, st);
          this.projectService.forDragDropLoader = false;
        }, error => this.errorService.errorFunction(error));
      } else {
        this.updateStoryProgress(this.story);
      }
    }
  }

  updateStoryProgress(story) {
    this.storyService.updateStory(story, true).subscribe((st: Story) => {
      const payload = {
        data: {
          story: story
        }
      };
      this.projectSyncService.updateAndShiftStory(this.story, st);
      setTimeout(() => {
        this.projectService.forDragDropLoader = false;
        this.storyProgress = { title: '', completedProgress: 0 };
        this.otherStartedStory = null;
      }, 500)
    });
  }

  setStoryProgress(trackedStory) {
    this.storyProgress.title = trackedStory.title;
    this.storyProgress.completedProgress = trackedStory.progress;
  }

  updateStory(elementStory, elInitialPosition, initialState, initialColumnName, initialListId, initialColumn) {
    this.storyService.updateStory(elementStory, true).subscribe((st: Story) => {
      this.projectSyncService.updateAndShiftStory(elementStory, st);
    }, (error) => {
      elementStory.position = elInitialPosition;
      elementStory.state = initialState;
      elementStory.list_name = initialColumnName;
      elementStory.list_id = initialListId;
      this.errorService.errorFunction(error);
    });
  }

  getColumnNameByColumnIdentifier(columnIdentifier) {
    return PROJECT_COLUMN_DATA.filter((col) => {
      return col.columnIdentifier == columnIdentifier;
    })[0];
  }

  updateStoryState(columnIdentifier) {
    switch (columnIdentifier) {
      case PROJECT_COLUMN_IDENTIFIER.to_do:
        return STORY_STATE.unstarted;
      case PROJECT_COLUMN_IDENTIFIER.in_progress:
        return STORY_STATE.started;
      case PROJECT_COLUMN_IDENTIFIER.qa:
        return STORY_STATE.delivered;
      case PROJECT_COLUMN_IDENTIFIER.rejected:
        return STORY_STATE.rejected;
      case PROJECT_COLUMN_IDENTIFIER.accepted:
        return STORY_STATE.accepted;
      default:
        return null;
    }
  }

  getProjectColumnByColumnName(list_name) {
    return PROJECT_COLUMN_DATA.filter((column) => {
      return column.list_name == list_name
    })[0];
  }

  reorderStory(storiess: Story[], story: Story, siblingStory: Story) {
    let stories = _.sortBy(storiess, 'position') as Array<Story>;
    const index = stories.findIndex(candidate => candidate == story);
    if (index !== -1)
      stories.splice(index, 1);
    if (siblingStory) {
      let siblingIndex = stories.findIndex(candidate => candidate == siblingStory);
      let previousStory = siblingIndex > 0 ? stories[siblingIndex - 1] : null;
      let position = 0;
      if (previousStory) {
        position = (siblingStory.position + previousStory.position) / 2;
      } else {
        position = siblingStory.position - 0.1;
      }
      return position;
    }
  }

  setupDomAutoscroller(): void {
    let columnNodes: NodeList = document.querySelectorAll('.storycolumn');
    let columns: Array<Node> = [];
    for (let i = 0; i < columnNodes.length; i++) {
      columns.push(columnNodes[i]);
    }
    this.scroll = DomAutoscroller(
      columns,
      {
        margin: 50,
        maxSpeed: 50,
        scrollWhenOutside: false,
        autoScroll: function () {
          return this.down && Draggable.dragMode;
        }
      }
    );
  }

  clearData() {
    if (this.project) {
      this.project.clearFilter();
    }
    this.project = undefined;
    this.showSelectedFilterType = 'phase';
    this.projectColumns.forEach(col => col.visible = true);
    // this.clearDataForApplyFilter();
    this.clearSearchStoriesData();
    this.searchStr = '';
    this.projectService.openSearchBlock = false;
    this.filtersCount = 0;
    this.projectService.focus_input = false;
    this.changeEpicViewToStatus();
  }

  getUrl() {
    return this.userService.loginUrl = this.location.path();
  }

  setOwnerEstimate() {
    if (this.story.estimate > 120) {
      this.story.estimate = 120;
      this.customToast.messages.push({
        id: 'serverfail',
        type: 'fail',
        class: 'generic_alert',
        title: 'Alert',
        message: 'Maximum estimate allowed is 120 hours, else break this story into parts.'
      });
    }
    this.storyService.updateStory(this.story, true).subscribe((st: Story) => {
      this.closeEstimatePopup();
      this.userService.trackSegmentEvent(this.storyOrTask(this.story) + ' owner estimate updated',
        this.projectService.getPropsOnStoryTaskUpdation(this.project, st, 'owner estimate'));
    }, error => this.errorService.errorFunction(error));
  }   

  confirmStory() {
    let user_id;
    let popupType = this.quickPopupType;
    if (popupType == 'owner') {
      this.story.confirmed = true;
    }
    this.otherStartedStory = this.sharedService.getActiveStory(this.story.owned_by_id, this.story.id);
    if (user_id && popupType == 'owner' && this.otherStartedStory) {
      this.closeDragEstimateAndOwnerPopup();
      if (this.otherStartedStory) {
        if (this.otherStartedStory) {
          this.updateProgressOnDrag();
        }
      }
    } else {
      this.showDragLoader = true;
      if (this.dragDropEstimateObj) {
        this.story.position = this.dragDropEstimateObj.position;
        this.story.state = this.updateStoryState(this.dragDropEstimateObj.targetColumn.columnIdentifier);
        this.story.list_id = this.dragDropEstimateObj.targetColumn.list_id;
      }
      this.updateDropStory(true, true)
    }

  }

  updateDropStory(close?, dragLoader?) {
    this.storyService.updateStory(this.story, true).subscribe((st: Story) => {
      if (close) {
        setTimeout(() => {
          this.closeDragEstimateAndOwnerPopup();
          this.showDragLoader = false;
        }, 500);
        this.projectSyncService.updateAndShiftStory(this.story, st);
      }
    }, (error) => {
      if (close) {
        this.closeDragEstimateAndOwnerPopup();
      }
      this.errorService.errorFunction(error)
    });
  }

  closeArchivePopUp() {
    this.openArchivePopUp = false;
  }

  archiveByDrag() {
    if (this.disableArchive) {
      return;
    }
    this.showDragLoader = true;
    this.disableArchive = true;
    this.story.deleted_at = moment();
    if (this.dragDropEstimateObj) {
      this.story.position = this.dragDropEstimateObj.position;
      this.story.list_id = this.dragDropEstimateObj.targetColumn.list_id;
    }

    const ind = this.project.selectedStories.findIndex(story => story.id === this.story.id);
    if (ind >= 0) {
      this.project.selectedStories.splice(ind, 1);
      this.story.isSelected = false;
    }

    this.storyService.updateStory(this.story, true).subscribe((story: Story) => {
      this.disableArchive = false;
      this.showDragLoader = false;
      this.closeArchivePopUp();
    }, (error) => {
      this.disableArchive = false;
      this.errorService.errorFunction(error);
      this.showDragLoader = false;
      this.closeArchivePopUp();
    });
  }

  openCheckStoryPopup(story) {
    this.isDragForConfirmationAndOwner = true;
    this.story = story;
    if(story.owned_by_id && this.userService.currentUser.id !== story.owned_by_id) {
      this.isUserAndOwnerSame = 'not_same_user';
    } else {
      if(this.quickPopupType == 'owner') {
        this.storyService.confirmOptions( story.story_type, story.state, this.policyService.expertAsQA(this.project, false));
        if((this.story.owned_by_id && !this.story.estimate) || (!this.story.owned_by_id && this.story.estimate)) {
          this.forBothEstimateAndOwner = 'single';
        }
      }
    }
  }

  loadData() {
    if (!this.userService.userSignedIn()) {
      this.getUrl();
    }
    this.currentUser = this.userService.getUser();
    this.projectService.isRefresh = true;
    this.projectService.project(this.projectId, true)
      .subscribe(
        project => {
          this.project = project;
          if (this.project) {
            this.notificationService.broadcast(EVENT_TYPES.PROJECT.SHOW, {
              data: {
                project: this.project
              }
            });

            this.project.memberships = [];
            this.projectService.loadCreatorTrackedTime(this.project.id).subscribe(res => {
              if (res && res.creator) {
                this.project.creator_tracked_time_data = res.creator;
              }
            }, error => this.errorService.errorFunction(error));
            this.projectService.getlocalStorageFilterData()
            this.projectService.loadUsers(this.project);
            _.isEmpty(this.filterStoryService.sprintFilterList) && this.filterStoryService.getSprints(this.project, this.projectId)
            this.projectService.loadStories(project, true).subscribe((stories) => {
              this.projectColumns = PROJECT_COLUMN_DATA;
              this.sharedService.visibleIds = map(PROJECT_COLUMN_DATA, 'list_id');
              this.projectService.getExpandCollapse(this.project);
              this.showCutomColumn = false;
              if (typeof stories == 'string' && stories == 'project-change') {
                if (this.project.stories.length > 0) {
                  this.projectService.showHtml = true;
                } else {
                  this.projectService.showHtml = false;
                }
              } else {
                this.ngAfterViewInit();
                if (this.projectService.isDefaultFilterApplied) {
                  this.showAppliedMemberFilter();
                }
                this.startLoader = false;
                this.projectService.showHtml = true;
                this.userService.trackSegmentEvent('Story page initiated', this.getPropsOnStart());
              }
            },
            (error => {
              this.errorService.errorFunction(error);
              this.startLoader = false;
            }));

            this.project.phases = [];
            this.project.tracker_phases = [];
            if (this.project && !this.project.external()) {
              this.project.phases = ['Product Roadmap', 'Design', 'Prototype', 'MVP', 'Full Build'];
              this.project.phases.forEach(item => {
                const tracker_phase = {
                  'tracker_phase_name': item,
                  'phase_name': item
                };
                this.project.tracker_phases.push(tracker_phase);
              });
            } else {
              this.projectService.projectPhasesNBrief(this.project).subscribe(res => {
                this.project.internal_mattermost_channel_url = res.data.internal_mattermost_channel_url;

                for (let i = 0; i < res.data.trackerphasesettings.length; i++) {
                  this.project.phases.push(res.data.trackerphasesettings[i].phase_name);

                  for (let j = 0; j < res.data.trackerphasesettings[i].tracker_phases.length; j++) {
                    const tracker_phase = {
                      'tracker_phase_name': res.data.trackerphasesettings[i].tracker_phases[j],
                      'phase_name': res.data.trackerphasesettings[i].phase_name
                    };
                    this.project.tracker_phases.push(tracker_phase);
                  }
                }
              }, (error) => {
                this.project.internal_mattermost_channel_url = null;
              });
            }

            this.loadEpics();
            this.projectService.project_labels(this.project).subscribe(data => {
              this.project.labels = data;
            }, (error) => {
              this.errorService.errorFunction(error);
            });
          }

          const allocation = moment(this.projectService.allocation_end_date, 'YYYY/MM/DD');
          const current_date = moment();

          this.days_left = allocation.diff(current_date, 'days');
          this.allocation_end_date = MONTH[allocation.month()] + ' ' + allocation.date() + ', ' + allocation.year();

          if (this.days_left < 2) {
            this.allocationAlert = true;
            this.allocationRedAlert = true;
            setTimeout(() => { this.closeAllocationAlert(); }, 15000);
          } else if (this.days_left < 7) {
            this.allocationAlert = true;
            setTimeout(() => { this.closeAllocationAlert(); }, 15000);
          }

          if (this.days_left < 0 || (this.projectService.allocation_end_date !== null && this.projectService.allocated === false)) {
            this.freezePanel = true;
          } else {
            this.freezePanel = false;
          }
        },
        (error) => {
          this.startLoader = false;
          this.errorService.errorFunction(error);
          let loginUrl = '';
          if (this.userService.userSignedIn()) {
            loginUrl = '/';
          } else {
            loginUrl = '/login';
          }

          this.router.navigateByUrl(loginUrl);

        }
      );
  }

  loadEpics() {
    this.epicService.projectEpics(this.project)
      .subscribe((epics: Array<Epic>) => {
        this.project.addEpics(epics);
        if (this.project && this.project.dashboard_project_id != null) {
          this.loadAllFeaturedetails();
        }
        const payload = {
          data: {
            epics: this.project.epics
          }
        };
        this.notificationService.broadcast(EVENT_TYPES.EPIC.UPDATED, payload);
        // this.startLoader = false;
      }, (error) => {
        this.errorService.errorFunction(error);
      });
  }

  loadAllFeaturedetails() {
    this.epicService.allFeatureDetails(this.project).subscribe(res => {
      if (res && res.data && res.data.length > 0) {
        res.data.forEach(item => {
          const ind = this.project.epics.findIndex(obj => obj.builder_feature_id === item.builder_feature_id);
          if (ind >= 0) {
            this.project.epics[ind].featureDetailsData = item;
          }
        });
      }
    });
  }

  scrollToColumn(column: ProjectColumn): void {
    if (column && column.visible && this.projectStoriesTableContainer && this.projectStoriesTable) {
      const parent = this.projectStoriesTableContainer.nativeElement;
      const containerWidth = this.projectStoriesTable.nativeElement.clientWidth;
      const visibleColumns = this.projectColumns.filter(item => item.visible);
      const columnWidth = containerWidth / visibleColumns.length;
      const columnIndex = visibleColumns.findIndex(item => item.list_code === column.list_code);
      const columnPosition = columnWidth * columnIndex;
      if (columnPosition < parent.scrollLeft) {
        parent.scrollLeft = columnPosition;
      } else if ((parent.scrollLeft + parent.offsetWidth) < (columnPosition + columnWidth)) {
        parent.scrollLeft = columnPosition - parent.offsetWidth + columnWidth;
      }
    }
  }

  // Clone Story
  subscribeStory() {
    this.storyService.watchStories().subscribe((story) => {
      if (story) {
        this.projectId = story.project.id;
        this.loadData();
      }
    },
      (error) => {
        this.errorService.errorFunction(error);
      });
  }

  manageSelectDeselectAll() {
    if(!this.projectService.archived_story_selected) {
      (this.project.selectedStories.length + this.sharedService.countArchivedAndHiddenColStories())
      !== this.project.stories.length ? this.selectAll() : this.deSelectAll();
    }
  }

  selectAll() {
    this.project.selectedStories = [];
    const archivedColId = this.sharedService.getArchivedColumnId();
    this.project.stories.forEach(story => {
      const col = this.sharedService.getColumn(story.list_id);
      if (story && story.list_id !== archivedColId && col.visible && (((story.epics.length === 0) || story.epics[0].status !== 'inactive'))) {
        story.isSelected = true;
        this.project.selectedStories.push(story);
      }
    });
    this.projectColumns.forEach(col => {
      if (col.visible && col.list_name !== 'Archived') {
        col.allSelected = true;
        col.selectedStoryCount = col.stories.length;
      }
    });
  }

  deSelectAll() {
    this.project.selectedStories.forEach((story) => {
      story.isSelected = false;
      story.storyQuickActionClose();
    })
    this.projectService.archived_story_selected = false;
    this.projectService.normal_story_selected = false;
    this.projectColumns.forEach(col => {
      col.allSelected = false;
      col.selectedStoryCount = 0;
    });
    this.project.selectedStories = [];
  }

  toggleApplyOnSubTasks() {
    this.apply_on_sub_tasks = !this.apply_on_sub_tasks;
  }

  disabledsetUserApply() {
    return this.quickPopupType == 'owner' ? this.disableSetOwnerApply() : this.disableSetReviewerApply()
  }

  disableSetOwnerApply() {
    if (!this.story.owned_by_id && !this.story.estimate) {
      return this.user_by_id && this.estimate ? false : true;
    }
    else if (this.story.estimate && !this.story.owned_by_id) {
      return this.user_by_id ? false : true;
    }
    else if (this.story.owned_by_id && !this.story.estimate) {
      return this.estimate ? false : true;
    }
  }

  disableSetReviewerApply() {
    if (!this.story.reviewer_id && !this.story.reviewer_estimate) {
      return this.user_by_id && this.estimate ? false : true;
    }
    else if (this.story.reviewer_estimate && !this.story.reviewer_id) {
      return this.user_by_id ? false : true;
    }
    else if (this.story.reviewer_id && !this.story.reviewer_estimate) {
      return this.estimate ? false : true;
    }
  }

  hasParentSubtasks() {
    if (this.selStories) {
      const ind = this.selStories.findIndex(story => story.subTaskAvailable());
      return (ind !== -1) ? true : false;
    }
  }

  isParentSelected(parentId) {
    const index = this.selStories.findIndex(st => st.id === parentId);
    if (index !== -1) {
      return true;
    }
    return false;
  }

  checkStoriesReason() {
    if (this.selStories) {
      const ind = this.selStories.findIndex(story => 
        !story.parent_id && story.story_type === 'task' && (story.story_create_reason_for_bulk_duplicate === '') && (story.epics && story.epics[0].task_done_count > 0)
      );
      return (ind !== -1) ? true : false;
    }
  }

  bulkClone() {
    this.waitForLoader = true;
    const stories_data = [];
    if (this.hasParentSubtasks()) {
      this.selStories.forEach(story => {
        const story_data = {
          story_id : story.id,
          reason_for_creation: ''
        }
        if (!story.parent_id) {
          stories_data.push(story_data);
        } else {
          if (!this.isParentSelected(story.parent_id)) {
            stories_data.push(story_data);
          }
        }
      });
    } else {
      if (this.selStories) {
        this.selStories.forEach(story => {
          const story_data = {
            story_id : story.id,
            reason_for_creation: story.story_create_reason_for_bulk_duplicate
          }
          stories_data.push(story_data)
        });
      }
    }
    this.storyService.bulkCopyStory(this.project.id, stories_data, this.apply_on_sub_tasks).subscribe((response) => {
      this.closecloneconfirmation();
      this.waitForLoader = false;
      this.deSelectAll();
      this.customToast.messages.push({
        id: 'bulk_story_clone',
        type: 'success',
        class: 'bulk-cloned',
        title: 'Bulk Stories duplicate',
        message: 'Bulk stories duplicated successfully.'
      });
      this.trackStoryBulkCloneSegmentEvent(response);
      this.apply_on_sub_tasks = true;
    }, (error) => {
      this.waitForLoader = false;
      this.closecloneconfirmation();
      this.errorService.errorFunction(error);
      this.apply_on_sub_tasks = true;
    });
  }

  getPropsOnStart() {
    if (this.project) {
      const props = {
        'project_name': this.project.name,
        'no_of_epics': this.project.epics ? this.project.epics.length : 0,
        'no_of_stories': this.project.stories ? this.project.stories.length : 0,
        'started_time': moment(),
        'user_name': this.currentUser && this.currentUser.name ? this.currentUser.name : '',
        'user_email': this.currentUser && this.currentUser.email ? this.currentUser.email : '',
        'user_type': this.userService.getIsUserInternalOrCp(),
        'project_type': this.project.external() ? 'client' : 'internal'
      };
      this.startTime = moment();
      return props;
    }
  }

  getPropsOnEnd() {
    if (this.project) {
      const props = {
        'project_name': this.project.name,
        'no_of_epics': this.project.epics ? this.project.epics.length : 0,
        'no_of_stories': this.project.stories ? this.project.stories.length : 0,
        'stopped_time': moment(),
        'user_name': this.currentUser.name,
        'user_email': this.currentUser.email,
        'user_type': this.userService.getIsUserInternalOrCp(),
        'project_type': this.project.external() ? 'client' : 'internal',
        'total_time_spent_by_user': moment().diff(this.startTime) / 1000 + ' seconds'
      };
      return props;
    }
  }

  getAdmins() {
    const all_admins: Array<any> = [];
    if (this.project) {
      for (let i = 0; i < this.project.memberships.length; i++) {
        if (this.project.memberships[i].role === 'admin') {
          const admin = {
            'admin_name': this.project.memberships[i].user.name,
            'admin_email': this.project.memberships[i].user.email
          };

          all_admins.push(admin);
        }
      }
    }
    return all_admins;
  }

  openMemberDD() {
    this.selectMoveDD = false;
    this.selectRoleDD = false;
    this.selectMemberDD = !this.selectMemberDD;
  }

  openRoleDD() {
    this.selectMoveDD = false;
    this.selectMemberDD = false;
    this.selectRoleDD = !this.selectRoleDD;
    // this.selectMemberDD = !this.selectMemberDD;
  }

  closeMemberDD() {
    this.selectMemberDD = false;
  }
  openMoveDD() {
    this.selectMemberDD = false;
    this.selectRoleDD = false;
    this.selectMoveDD = !this.selectMoveDD;
  }
  closeMoveDD() {
    this.selectMoveDD = false;
  }

  sortDeallocatedMembers(){
    this.project.users = this.project.users.sort((a, b) => {
      let valueA, valueB;
      valueA = a,
      valueB = b;
      const ind1 = this.project.memberships.findIndex(member => member.user.id === valueA.id)
      const ind2 = this.project.memberships.findIndex(member => member.user.id === valueB.id);
      if ((valueA.deleted_at || !this.project.memberships[ind1].active) && (!valueB.deleted_at && this.project.memberships[ind2].active)) {
        return 1;
      } else if ((!valueA.deleted_at && this.project.memberships[ind1].active) && (valueB.deleted_at || !this.project.memberships[ind2].active)) {
        return -1;
      } else {
        return 0;
      }
    });
  }

  sortMembers(){
    this.project.users = this.project.users.sort((a, b) => {
      let valueA, valueB;
      valueA = a.name ? a.name : '',
      valueB = b.name ? b.name : '';
      valueA = valueA.toLowerCase();
      valueB = valueB.toLowerCase();
      if (valueA > valueB) {
        return 1;
      } else if (valueA < valueB) {
        return -1;
      } else {
        return 0;
      }
    });
  }

  getSortedUsers() {
    return this.project.users.sort((a: User, b: User) => {
      const nameA = (a.name != null ? a.name.toLowerCase() : a.email.toLowerCase());
      const nameB = (b.name != null ? b.name.toLowerCase() : b.email.toLowerCase());
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  }

  setUser(user) {
    this.user_by_id = user.id;
    this.user_by_name = user.name;
    this.user_by_initials = user.initials;
    this.searchUser = '';
    this.showDropDown = false;
  }

  openOwnerPopup() {
    this.projectService.storiesInvalidToAssignUser = [];
    this.selectMemberDD = false;
    this.setOwnerPopup = true;
    this.projectService.focus_input = true;
    this.selectedOwnerId = null;
    this.sortMembers();
    this.sortDeallocatedMembers();
  }

  closeOwnerPopup() {
    this.searchOwner = '';
    this.setOwnerPopup = false;
    this.projectService.focus_input = false;
    if (this.project.selectedStories.length === 1) {
      this.sharedService.closeQuickAction(this.project);
    }
  }

  checkStorySelection() {
    return this.project.selectedStories.length === 1 && this.project.selectedStories[0];
  }

  openReviewerPopup() {
    this.projectService.storiesInvalidToAssignUser = [];
    this.selectMemberDD = false;
    this.setReviewerPopup = true;
    this.projectService.focus_input = true;
    this.selectedReviewerId = null;
    this.sortMembers();
    this.sortDeallocatedMembers();
  }

  openEstimatePopup() {
    this.forEstimate = true;
    this.projectService.focus_input = true;
  }

  closeEstimatePopup() {
    this.forEstimate = false;
    this.story.estimate = 0;
    this.projectService.focus_input = false;
    if (this.project.selectedStories.length === 1) {
      this.sharedService.closeQuickAction(this.project);
    }
  }

  closeConfirmDragPopupOnIcon() {
    this.dragDropEstimateObj = null;
    this.story = null;
    this.sharedService.initialCoulmn = null;
    this.storyService.story_start_confirmation = false;
    this.closeDragEstimateAndOwnerPopup();
  }

  closeDragEstimateAndOwnerPopup() {
    this.isDragForConfirmationAndOwner = false;
    this.user_by_id = null;
    this.user_by_name = null;
    this.user_by_initials = null;
    this.quickPopupType = '';
    this.estimate = 0;
    this.isUserAndOwnerSame = '';
  }

  closeReviewerPopup() {
    this.searchReviewer = '';
    this.setReviewerPopup = false;
    this.projectService.focus_input = false;
    if (this.project.selectedStories.length === 1) {
      this.sharedService.closeQuickAction(this.project);
    }
  }

  storyOrTask(story) {
    const a = story.parent_id ? 'Task' : 'Story';
    return a;
  }

  trackStoryBulkMoveSegmentEvent(stories, project) {
    if (stories && stories.length > 0 && project) {
      stories.forEach(item => {
        this.userService.trackSegmentEvent(this.storyOrTask(item.story) + ' created',
          this.projectService.getPropsOnStoryTaskCreation(project, item.story));
      });
    }
  }

  trackStoryBulkCloneSegmentEvent(stories) {
    stories.forEach(item => {
      this.userService.trackSegmentEvent(this.storyOrTask(item.story) + ' created',
        this.projectService.getPropsOnStoryTaskCreation(this.project, item.story));
    });
  }

  trackBulkStoryTaskUpdateSegmentEvent(stories, prop) {
    if (stories && stories.length > 0 && prop) {
      stories.forEach(item => {
        item.story ? this.userService.trackSegmentEvent(this.storyOrTask(item.story) + ' ' + prop + ' updated',
          this.projectService.getPropsOnStoryTaskUpdation(this.project, item.story, prop)) :
          this.userService.trackSegmentEvent(this.storyOrTask(item) + ' ' + prop + ' updated',
            this.projectService.getPropsOnStoryTaskUpdation(this.project, item, prop));
      });
    }
  }

  hidePopups(data, key) {
    if (key == 'showDropDown') {
      this.showDropDown = false;
    } else if (key == 'showcolumnList') {
      this.showcolumnList = false;
    } else if (key == 'showfilterList') {
      this.showfilterList = false;
    } else if (key == 'closeMemberDD') {
      this.closeMemberDD();
    }
  }

  hide_StoryDetails() {
    this.projectService.hide_StoryDetails();
  }

  onClick(event) {
    const element = event.target;
    if (!(element.classList.contains('userSearchFilter') || element.classList.contains('iconb-dd-down')
      || element.classList.contains('iconb-owner') || element.classList.contains('iconb-search')
      || element.classList.contains('stateSelected-icon') || element.classList.contains('selected-state')
      || element.classList.contains('selectedUser-name'))) {
      this.showDropDown = false;
    }
    if (!(element.classList.contains('bulkAction-buttonText') || element.classList.contains('bulkAction-ddButton') || element.classList.contains('memDD-icon')
      || element.classList.contains('iconb-dd-down') || element.classList.contains('material-icons')
      || element.classList.contains('memberIcon'))) {
      this.closeMemberDD();
      this.closeMoveDD();
      this.closeRoleChangeDD();
    }
    if (!(element.classList.contains('otherOptions_button') || element.classList.contains('otherOptions') || element.classList.contains('bulkAction-ddListIcon')
      || element.classList.contains('bulkAction-ddList') || element.classList.contains('bulkAction-ddListName') || element.classList.contains('iconb-more-vert')
      || element.classList.contains('otherOptions_icons'))) {
      this.closeBulkOptionsDD();
    }
    if (element.classList.contains('revStoryDes-backdrop') || element.classList.contains('revStoryDes-popup')) {
      this.projectService.hide_StoryDetails();
    }
    if (!(element.classList.contains('viewbutton-title') || element.classList.contains('selectColumn-name')
      || element.classList.contains('selectColumn-listing') || element.classList.contains('selectColumn-name')
      || element.classList.contains('column-check-toggle') || element.classList.contains('column-selected-checkbox')
      || element.classList.contains('customCheckForColumnSelection') || element.classList.contains('viewButton-wapper')
      || element.classList.contains('viewButton') || element.classList.contains('iconb-flow-view'))) {
      this.showcolumnList = false;
    }
    if (!(element.classList.contains('filterbutton-title') || element.classList.contains('iconb-filter')
      || element.classList.contains('filterButton') || element.classList.contains('filterOptions-typeListing')
      || element.classList.contains('typeList-name') || element.classList.contains('typeList-counter')
      || element.classList.contains('filterOptions-typeBock') || element.classList.contains('typeBock-listing')
      || element.classList.contains('check-toggle') || element.classList.contains('story-selected-checkbox')
      || element.classList.contains('typeBock-name') || element.classList.contains('filterSearch')
      || element.classList.contains('iconb-search') || element.classList.contains('ownerListWrapper')
      || element.classList.contains('filterCounter') || element.classList.contains('resetFilter-button')
      || element.classList.contains('resetFilter-block') || element.classList.contains('filterCal')
      || element.classList.contains('dateRagne-input') || element.classList.contains('dateClearButton') 
      || element.classList.contains('iconb-close') || element.classList.contains('status-label')
      || element.classList.contains('filterbyDate-button'))) {
      this.showfilterList = false;
      this.renderer2.removeClass(document.body, 'adjustLayer');
    }
    if (!(element.classList.contains('ideButtons') || element.classList.contains('ideButton')
      || element.classList.contains('iconb-dd-down'))) {
      this.showIDEList = false;
    }
    if (!element.classList.contains('btn-trigger')) {
      this.showHideTaskDropdown = false;
    }
    if (!element.classList.contains('search-input') && !element.classList.contains('iconb-search')) {
      this.featureSearchVisible = false;
    }
    if (!(element.classList.contains('customCheckForColumnSelection') || element.classList.contains('column-check-toggle') || element.classList.contains('lg')|| element.classList.contains('selectColumn-listing') || element.classList.contains('selectColumn-name') || element.classList.contains('column-selected-checkbox') || element.classList.contains('column-selected-checkbox'))) {
      this.sprintDropdown = false;
    }
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.projectService.hide_StoryDetails();
  }

  toggeleColumnView() {
    this.showcolumnList = !this.showcolumnList;
    if (this.showcolumnList) {
      this.userService.trackSegmentEvent('Views Open', this.projectService.getCommonPropsForStoryBoardEvents(this.project));
    }
  }

  toggleColumnVisibility(event, col) {
    if (event.target.checked) {
      col.visible = true;
      const index = this.sharedService.visibleIds.indexOf(col.list_id);
      if (index < 0) {
        this.sharedService.visibleIds.push(col.list_id);
        this.userService.trackSegmentEvent('View Applied', this.getPropsOnViewAppliedOrRemoved(col.list_name));
      }
    } else {
      col.visible = false;
      this.unselectStoriesOnHideColumn(col);
      const index = this.sharedService.visibleIds.indexOf(col.list_id);
      if (index >= 0) {
        this.sharedService.visibleIds.splice(index, 1);
        this.userService.trackSegmentEvent('View Removed', this.getPropsOnViewAppliedOrRemoved(col.list_name));
      }

    }
  }

  unselectStoriesOnHideColumn(col) {
    col.stories.forEach(story => {
      if (story.isSelected) {
        const storyIndex = this.project.findStoryIndex(story);
        if (storyIndex >= 0) {
          this.project.spliceStory(storyIndex);
        }
        story.isSelected = false;
        story.storyQuickActionClose();
      }
    });
    col.allSelected = false;
    col.selectedStoryCount = 0;
    if (this.project.selectedStories.length === 1) {
      this.sharedService.showQuickAction(this.project);
    } else {
      const qucikStory: Story = this.project.findQuickActionStory();
      if (qucikStory) {
        qucikStory.storyQuickActionClose();
      }
    }
  }

  toggelefilter() {
    this.renderer2.addClass(document.body, 'adjustLayer');
    this.showfilterList = !this.showfilterList;
    this.showSelectedFilterType = 'phase';
    this.searchFeatureForFilter = '';
    this.searchLabelForFilter = '';
    this.searchMemberForFilter = '';
    if (this.showfilterList) {
      this.userService.trackSegmentEvent('Filter Open', this.projectService.getCommonPropsForStoryBoardEvents(this.project));
    }
  }

  showAppliedMemberFilter() {
    const filterIndex = this.project.findFilterIndex('member', this.currentUser.id);
    if (filterIndex === -1) {
      this.project.addFilterParam('member', this.currentUser.id);
      this.project.addFilterParam('owner', this.currentUser.id);
      this.project.addFilterParam('reviewer', this.currentUser.id);
      this.project.addFilterParam('requester', this.currentUser.id);
    }
    this.filtersCount = this.project.filterCount;
  }

  clearSearchStoriesData() {
    if (this.searchSubscription) {
      this.searchSubscription = undefined;
    }
    this.searchResult = { stories: [], labels: [] };
    this.searchFeatures = [];
    this.oldSearchStr = '';
    this.totalCountSearch = 0;
  }

  startSearchLoader = false;

  searchInEpic() {
    const searchFeature:string = this.searchStr.toLowerCase();
    this.searchFeatures = this.project.epics.filter((element) => {
      return element.title.toLowerCase().includes(searchFeature)
    });
  }

  searchStories(scroll?) {
    if (this.searchStr.trim().length === 0) {
      return false;
    }
    if(this.resultStoriesStatus) {
      this.moreSearchStoryAvailale = true;
    } else {
      this.moreSearchStoryAvailale = false;
    }
    if(!scroll) {
      this.moreSearchStoryAvailale = true;
      this.searchStoriesParam = 1;
    } else if(scroll && this.resultStoriesStatus) {
      this.searchStoriesParam++;
      if(this.searchStoriesParam > 5) {
        this.searchStoriesParam = 5;
        this.moreSearchStoryAvailale = false;
        return;
      }
      this.startSearchLoader = true;
    }

      this.searchGoingLoader = true;
      if(!scroll) {
        this.clearSearchStoriesData();
      }

      this.isSearchById = !isNaN(parseInt(this.searchStr.trim(), 10));
      this.searchSubscription = this.storyService.searchstories(this.project, this.searchStr.trim(), this.searchStoriesParam).subscribe(res => {
        this.oldSearchStr = this.searchStr;
        if(res.stories.length == 0) {
          this.moreSearchStoryAvailale = false;
        }
        this.startSearchLoader = false;
        this.searchResult.stories.push(...res.stories);
        res.labels.forEach(item => this.searchResult.labels.push(item));
        this.searchGoingLoader = false;
        this.totalCountSearch = this.searchResult.labels.length + this.searchFeatures.length;
      }, err => {
        this.errorService.errorFunction(err);
        this.searchGoingLoader = false;
        this.startSearchLoader = false;
      });
  }

  progressStrip(progress, i) {
    this.storyProgress.completedProgress = progress;
  }

  checkIndex(index) {
    return ((this.storyProgress.completedProgress / 10) >= index);
  }

  goToStoryDetail(story_id) {
    this.projectService.closeSearch();
    this.projectService.show_StoryDetails(this.project, '', story_id);
  }

  storyTypeIcon(story_type, subtask = false) {
    switch (story_type) {
      case STORY_TYPE.task:
        return  subtask? 'iconb-subtask taskIcons' : 'iconb-task taskIcons';
      case STORY_TYPE.chore:
        return 'iconb-chore choreIcons';
      case STORY_TYPE.bug:
        return 'iconb-bug bugIcons';
      case STORY_TYPE.release:
        return 'iconb-release releaseIcons';
      case STORY_TYPE.side_effect:
        return 'iconb-side-effect sideeffectIcons';
      default:
        return null;
    }
  }

  focusDropDown() {
    this.searchUser = '';
    this.showDropDown = !this.showDropDown;
    if (this.showDropDown) {
      this.setMembersList();
    }
  }

  setMembersList() {
    setTimeout(() => {
      this.selectedMemberIndex = 0;
      this.membersListElements = [];

      this.membersListElements = this.membersList.map(member => {
        this.renderer2.setStyle(member.nativeElement, 'backgroundColor', 'white');
        return member.nativeElement;
      });

      if (this.membersListElements && this.membersListElements[this.selectedMemberIndex]) {
        this.renderer2.setStyle(this.membersListElements[this.selectedMemberIndex], 'backgroundColor', '#F2F4F4');
        this.membersListElements[this.selectedMemberIndex].scrollIntoViewIfNeeded();
      }
    }, 0);
  }

  setSelectedIndex(index) {
    if (this.membersListElements && this.membersListElements[this.selectedMemberIndex]) {
      this.renderer2.setStyle(this.membersListElements[this.selectedMemberIndex], 'backgroundColor', 'white');
      this.selectedMemberIndex = index;
      this.renderer2.setStyle(this.membersListElements[this.selectedMemberIndex], 'backgroundColor', '#F2F4F4');
      this.membersListElements[this.selectedMemberIndex].scrollIntoViewIfNeeded();
    }
  }

  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (this.showDropDown) {
      if (event.keyCode === 38) {
        if (this.selectedMemberIndex > 0) {
          this.setSelectedIndex(this.selectedMemberIndex - 1);
        }
        event.preventDefault();
      } else if (event.keyCode === 40) {
        if (this.membersListElements && (this.selectedMemberIndex < (this.membersListElements.length - 1))) {
          this.setSelectedIndex(this.selectedMemberIndex + 1);
        }
        event.preventDefault();
      } else if (event.keyCode === 9 || event.keyCode === 13) {
        const index = this.getSortedUsers().findIndex(user =>
          user.email === this.membersListElements[this.selectedMemberIndex].getAttribute('email'));
        if (index >= 0 && this.project.isUserActive(this.getSortedUsers()[index])) {
          this.setUser(this.getSortedUsers()[index]);
        }
      }
    }
  }

  openOwnerQuickTimeEstimate() {
    this.insertOwnerTime = true;
  }
  closeOwnerQuickTimeEstimate() {
    this.insertOwnerTime = false;
  }
  quickTimeEstimateWhileDraging() {
    this.insertTimeWhileDraging = true;
  }
  closeQuickTimeEstimate() {
    this.insertTimeWhileDraging = false;
  }
  increaseEstimate(hour: number) {
    this.story.estimate = Number(this.story.estimate) + Number(hour);
  }

  whileDragIncreaseEstimate(hour: number) {
    this.estimate = Number(this.estimate) + Number(hour);
  }

  addNewColumn() {
    if(this.policyService.isProjectReadonly(this.project)) {
      this.policyService.readOnlyWarning();
    } else {
      this.showCutomColumn = true;
    }
  }

  addColumn() {
    if (!this.disableAddColumn) {
      this.disableAddColumn = true;
      if (this.newColumnName.trim().length === 0) {
        this.disableAddColumn = false;
        return;
      } else if (this.newColumnName.trim().length <= 15) {
        const duplicateColumn = this.isAlreadyColumn(this.newColumnName.trim());
        if (duplicateColumn) {
          this.customToast.messages.push({
            id: 'newprojectcreationFail',
            type: 'fail',
            class: 'new_project_fail',
            title: 'Alert',
            message: 'List with name \'' + this.newColumnName.trim() + '\' already exists!'
          });
          this.disableAddColumn = false;
          return;
        }
        this.projectService.addNewColumn(this.project.id, this.newColumnName.trim()).subscribe((column) => {
          this.disableAddColumn = false;
          const isColumn = this.sharedService.getColumnById(column.list.id);
          this.newColumnName = '';
          this.showCutomColumn = false;
          if (!isColumn) {
            const col = new ProjectColumn({ list_id: column.list.id, list_name: column.list.name, list_type: column.list.list_type, list_code: column.list.code });
            PROJECT_COLUMN_DATA.push(col);
          }
          this.sharedService.visibleIds.push(column.list.id);
          this.customToast.messages.push({
            id: 'story_new',
            type: 'success',
            class: 'story_new',
            title: 'Custom Column Added',
            message: column.message
          });
        }, err => {
          this.errorService.errorFunction(err);
          this.disableAddColumn = false;
        });
      } else {
        this.disableAddColumn = false;
        this.customToast.messages.push({
          id: 'newprojectcreationFail',
          type: 'fail',
          class: 'new_project_fail',
          title: 'Alert',
          message: 'Column name can not be more than 15 characters'
        });
      }
    }
  }

  showUserContent() {
    if (this.quickPopupType == 'owner') {
      return !this.story.owned_by_id;
    } else if (this.quickPopupType == 'reviewer') {
      return !this.story.reviewer_id;
    }
  }

  showEstimateContent() {
    if (this.quickPopupType == 'owner') {
      return !this.story.estimate;
    } else if (this.quickPopupType == 'reviewer') {
      return !this.story.reviewer_estimate;
    }
  }


  isAlreadyColumn(columnName) {
    return PROJECT_COLUMN_DATA.filter((col) => {
      return col.list_name.trim() == columnName;
    })[0];
  }

  cancelAddColumn() {
    this.newColumnName = '';
    this.showCutomColumn = false;
  }

  onlyVisibleColumns() {
    PROJECT_COLUMN_DATA.forEach((col) => {
      if (this.sharedService.visibleIds.indexOf(col.list_id) < 0) {
        col.visible = false;
      }
    })
  }

  getPropsOnViewAppliedOrRemoved(viewName) {
    if (viewName && this.project) {
      const props = this.projectService.getCommonPropsForStoryBoardEvents(this.project);
      props['view_name'] = viewName;

      return props;
    }
  }

  drop(event) {
    this.dragStart = false;
    document.removeEventListener('mousemove', this.mouseMove.bind(this), false);
    if(this.policyService.isProjectReadonly(this.project)) {
      this.policyService.readOnlyWarning();
    } else {
      if (event.currentIndex == 0) {
        return // this will stop item from drop
      }
      if (event.previousIndex == event.currentIndex) {
        return;
      }
      let rightPosition: number;
      let leftPosition: number;
      let newPosition: number;
      moveItemInArray(this.projectColumns, event.previousIndex, event.currentIndex);
      let leftColumn = this.projectColumns[event.currentIndex - 1];
      if (leftColumn && leftColumn.column_type == 'is_project_column') {
        leftPosition = Number(this.projectColumns[event.currentIndex - 1].list_position);
      }
      let rightColumn = this.projectColumns[event.currentIndex + 1];
      if (rightColumn && rightColumn.column_type == 'is_project_column') {
        rightPosition = Number(this.projectColumns[event.currentIndex + 1].list_position);
      }
      if (leftPosition !== undefined && rightPosition !== undefined) {
        newPosition = Number((leftPosition + rightPosition) / 2);
      } else if (leftPosition !== undefined && rightPosition === undefined) {
        newPosition = leftPosition + 1;
      } else if (leftPosition === undefined && rightPosition !== undefined) {
        newPosition = rightPosition - 0.1;
      }
      if (newPosition !== undefined) {
        this.projectService.renameColumn(this.project.id, this.projectColumns[event.currentIndex].list_id, newPosition, 'reorder').subscribe((resp) => {
          const isColumn = this.sharedService.getColumnById(resp.list.id);
          if (isColumn) {
            isColumn.list_position = Number(resp.list.position);
          }
        })
      }
    }
  }


  cdkDragStarted(event) {
    this.dragStart = true;
    document.addEventListener('mousemove', this.mouseMove.bind(this), false);
  }

  openIDE() {
    this.showIDEList = !this.showIDEList;
  }

  goToIdeUrl(docker_url) {
    if (docker_url) {
      window.open(docker_url, '_blank');
    }
    this.showIDEList = false;
  }

  openMoveDeviceAndPlatformPopup() {
    this.moveDeviceAndPlatformPopup = true;
  }

  closeMoveDeviceAndPlatformPopup() {
    this.params.page = 1;
    this.selectedPlatformsArr = [];
    this.moveDeviceAndPlatformPopup = false;
  }

  changeEpicViewToStatus() {
    this.epicEstimate = false;
    this.epicStatus = true;
    this.epicService.epicEstimateStatus = false;
  }

  changeEpicViewToEstimate() {
    this.epicStatus = false;
    this.epicEstimate = true;
    this.epicService.epicEstimateStatus = true;
  }

  toggleEpicStatusEstimate() {
    this.epicStatus = !this.epicStatus;
    this.epicEstimate = !this.epicEstimate;
    this.epicService.epicEstimateStatus = !this.epicService.epicEstimateStatus;
  }

  openAddEpicPopup() {
    this.addNewEpic = true;
    this.newFeatureTitle = '';
    this.newFeatureDescription = '**AS A** < User>\n' + '**I WANT TO** < Feature description>\n' +
      '**SO THAT** < Reason description>\n' + '\n' + '**GIVEN** < Conditions>\n' +
      '**WHEN** < Action performed>\n' + '**THEN** <Expected result>';
  }

  closeAddEpicPopup() {
    this.addNewEpic = false;
  }

  addNewFeature() {
    if (!this.disableAddNewFeature) {
      this.disableAddNewFeature = true;

      const data = {
        epic : {
          title : this.newFeatureTitle.trim(),
          description : this.newFeatureDescription.trim()
        }
      };

      this.epicService.createEpic(this.project.id, data).subscribe(res => {
        this.disableAddNewFeature = false;
        if (res && res.epic) {
          this.project.addEpic(new Epic(res.epic));
          const payload = {
            data: {
              epics: this.project.epics
            }
          };
          this.notificationService.broadcast(EVENT_TYPES.EPIC.UPDATED, payload);
        }
        this.customToast.messages.push({
          id: 'new_feature_creation_success',
          type: 'success',
          class: 'story_new',
          title: res.title,
          message: res.message
        });
        this.closeAddEpicPopup();
      }, err => {
        this.errorService.errorFunction(err);
        this.disableAddNewFeature = false;
      });
    }
  }

  canUpdateFeature(): boolean {
    if (this.project && this.project.selectedStories && this.project.selectedStories.length === 1) {
      return !this.project.selectedStories[0].parent_id;
    } else if (this.project && this.project.selectedStories && this.project.selectedStories.length > 1) {
      return !this.areAllSubTasks();
    }
    return false;
  }

  areAllSubTasks(): boolean {
    const ind = this.project.selectedStories.findIndex(item => !item.parent_id);
    return ind < 0;
  }

  togglePlatformSelection(event, platformId) {
    if (event.target.checked) {
      const isPlatformAlreadyExists = _.includes(this.selectedPlatformsArr, platformId);
      if (!isPlatformAlreadyExists) {
        this.selectedPlatformsArr.push(platformId);
      }
    } else {
      this.selectedPlatformsArr = this.selectedPlatformsArr.filter(id => id !== platformId);
    }
  }

  isPlatformSelected(platformId) {
    return _.includes(this.selectedPlatformsArr, platformId);
  }

  moveBulkStoriesToPlatforms() {
    if (this.project) {
      const selectedStoriesIds = map(this.selStories, 'id');
      this.waitForLoader = true;
      this.storyService.bulkAssignPlatforms(selectedStoriesIds, this.selectedPlatformsArr, this.project.id).subscribe(res => {
        this.closeMoveDeviceAndPlatformPopup();
        this.waitForLoader = false;
        if (this.selStories.length > 1) {
          this.deSelectAll();
        }
        if (selectedStoriesIds.length > 1) {
          this.customToast.messages.push({
            id: 'bulk_platform',
            type: 'success',
            class: 'bulk_epic',
            title: 'Bulk Platforms Assignment',
            message: res.message
          });
        }
      }, (error) => {
        this.waitForLoader = false;
        this.errorService.errorFunction(error);
      });
    }
  }

  showRelatedStories() {
    this.resultStoriesStatus = true;
    this.resultLabelsStatus = false;
  }

  showRelatedLabels() {
    this.resultStoriesStatus = false;
    this.resultLabelsStatus = true;
  }
  
  getSelectedFilterTypeW() {
    if (this.project.filters.created_on_date_range.length === 0) {
      this.selectedDate = null;
    }
    this.showSelectedFilterType ='date_created';
  }

  openBulkDuplicateReasonList(story){
    story.show_reason_dd = !story.show_reason_dd;
  }

  selectionBulkOptionsDD() {
    this.showBulkOptions = !this.showBulkOptions;
  }
  closeBulkOptionsDD() {
    this.showBulkOptions = false;
  }
  
  toggleTaskDropdown() {
    this.showHideTaskDropdown = !this.showHideTaskDropdown;
  }

  setLocalStorageFilterData(){
    this.existingFilterData = [];
    const parentOrSubtaskFilter = localStorage.getItem('parentOrSubtaskFilter')
    if (parentOrSubtaskFilter) {
      this.existingFilterData = JSON.parse(parentOrSubtaskFilter); 
      const index = this.existingFilterData.findIndex(e => e.project_id === this.project.id);
      if (index !== -1) {
        this.existingFilterData.splice(index, 1);
      }
    }
    this.existingFilterData.push({ project_id: this.project.id, filter: this.storyService.parentOrSubtaskStories })
    localStorage.setItem('parentOrSubtaskFilter', JSON.stringify(this.existingFilterData));
  }

  applyStoryOrSubtaskFilter(filter){
    this.storyService.parentOrSubtaskStories = filter;
    this.showHideTaskDropdown = false
    this.projectService.startApplyFilterLoader = true;
    this.setLocalStorageFilterData();
      this.projectService.loadStories(this.project, false).subscribe((stories) => {
        if(this.project.selectedStories.length > 0) {
          this.deSelectAll();
        }
        this.projectColumns = PROJECT_COLUMN_DATA;
        this.onlyVisibleColumns();
        this.projectService.getExpandCollapse(this.project);
        if (typeof stories === 'string' && stories === 'project-change') {
          this.projectService.startApplyFilterLoader = false;
          if (this.project.stories.length > 0) {
            this.projectService.showHtml = true;
          } else {
            this.projectService.showHtml = false;
          }
        } else {
          this.projectService.showHtml = true;
          this.projectService.startApplyFilterLoader = false;
        }
      }, err => {
        this.errorService.errorFunction(err);
        this.projectService.startApplyFilterLoader = false;
      });
    }

  expandCollpaseColumn(column) {
    column.expandable = !column.expandable;
    this.existingExpandData = [];
    const expandableListOptions = localStorage.getItem('expandableListOptions')
    if (expandableListOptions) {
      this.existingExpandData = JSON.parse(expandableListOptions); 
      const index = this.existingExpandData.findIndex(e => e.list_id === column.list_id);
      if (index !== -1) {
        this.existingExpandData.splice(index, 1);
      }
    }
    this.existingExpandData.push({ project_id: this.project.id, list_id: column.list_id, expandable: column.expandable })
    localStorage.setItem('expandableListOptions', JSON.stringify(this.existingExpandData));
  }
  columnEmpty(column){
    return  column.list_type !== 'custom' && column.stories.length === 0;
  }

  customColumnStories(column){
    return  column.list_type === 'custom' && column.stories.length > 0;
  }

  copyIdeLink($event,docker){
    this.projectService.docker_ides.forEach(id => id.linkCopied = false);
    docker.linkCopied = true;
    setTimeout(() => {
      docker.linkCopied = false;
    }, 6000);
    $event.preventDefault();
    $event.stopPropagation();
  }

  toggleMeFilter($event,project,id,filterApplied){
    if (filterApplied && this.filterStoryService.meFilterSelected(project,id)) {
      return      
    }
    if (filterApplied) {
      this.clearMemberFilter(); 
    }
    this.projectService.meFilterLoader = true;
    localStorage.setItem('meFilter', JSON.stringify(filterApplied));
    this.filterStoryService.meFilter = filterApplied;
    this.filterStoryService.applyFilter(project, null, 'member', id);
    $event.preventDefault();
    $event.stopPropagation();
  }

  clearMemberFilter(){
    this.project.filters.owner = [];
    this.project.filters.requester = [];
    this.project.filters.reviewer = [];
    this.project.filters.member = [];
  }

  showFeatureSearch() {
    this.featureSearchVisible = true;
  }

  clearFeatureSearch() {
    this.projectService.featureSearchText = '';
  }

  sprintListShow(event) {
    if (this.filterStoryService.currentRunningSprintList.length === 0) {
      this.filterStoryService.currentRunningSprintList = this.filterStoryService.sprintFilterList.filter((sprint: any) => sprint.status === 'running')
    }
    if (this.filterStoryService.currentRunningSprintList.length > 0) {
      this.sprintDropdown = !this.sprintDropdown
    }
    event.stopPropagation()
  }

  isFilterChecked(key, value) {
    return this.project.findFilterIndex(key, value) >= 0 ? true : false;
  }
  
  filterSelectionCheck(project, event, label, id) {
    this.filterStoryService.deletedSprintFilterOnUpdation(id)
    this.filterStoryService.applyFilter(project, event, label, id)
    let index = this.filterStoryService.currentRunningSprintList.findIndex((val) => this.isFilterChecked('sprints', val.id))
    if (index > -1) {
      this.filterStoryService.sprintSelected = true
      localStorage.setItem('currentSprintSelection', 'true')
    } else {
      this.filterStoryService.sprintSelected = false
      localStorage.setItem('currentSprintSelection', 'false')
    }
  }
}
