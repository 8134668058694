import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
// import { TextMaskModule } from 'angular2-text-mask';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import { SharedModule } from './../shared/shared.module';
import { AdminComponent } from './admin.component';
import { AdminLeftMenuComponent } from './header/leftMenu.component';
import { AdminRoutingModule } from './admin-routing.module';

import { AdminDashboardComponent } from './dashboard';
import {
  AdminUsersListComponent,
  AdminUsersShowComponent,
  AdminUsersNewComponent,
  AdminUsersEditComponent,
  AdminUsersFormComponent,
  AdminUsersService
} from './users';
import {
  AdminProjectsListComponent,
  AdminProjectsShowComponent,
  AdminProjectsNewComponent,
  AdminProjectsEditComponent,
  AdminProjectsFormComponent,
  AdminProjectsService
} from './projects';
import {
  CommonMembershipsComponent,
  CommonMembershipsService
} from './common';
import { AdminTimingModule } from './timing/timing.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    // TextMaskModule,
    RouterModule,
    AngularMyDatePickerModule,
    SharedModule,
    AdminRoutingModule,
    AdminTimingModule
  ],
  declarations: [
    AdminComponent,
    AdminLeftMenuComponent,
    AdminDashboardComponent,

    // Users section
    AdminUsersListComponent,
    AdminUsersShowComponent,
    AdminUsersNewComponent,
    AdminUsersEditComponent,
    AdminUsersFormComponent,

    // Projects section
    AdminProjectsListComponent,
    AdminProjectsShowComponent,
    AdminProjectsNewComponent,
    AdminProjectsEditComponent,
    AdminProjectsFormComponent,

    // Common
    CommonMembershipsComponent
  ],
    exports: [
        AdminProjectsNewComponent,
        AdminUsersNewComponent,
        AdminProjectsEditComponent,
        AdminLeftMenuComponent,
        AdminUsersEditComponent
    ],
  providers: [
    AdminUsersService,
    AdminProjectsService,
    CommonMembershipsService
  ]
})
export class AdminModule { }
