import { Component, Input, OnInit } from '@angular/core';
import {
  EVENT_TYPES
} from '../../../shared/dataTypes';
import {
  Project
} from '../../../shared/models/project';
import { NotificationService } from '../../../shared/services/notification.service';
import { ProjectService } from '../../../shared/services/project.service';
import { ActivityService } from '../../../shared/services/activity.service';
import { Activity } from '../../../shared/models/activity';
import {DomSanitizer} from '@angular/platform-browser';
import { SharedService } from '../../../shared/services/shared.service';
import {ErrorService} from '../../../shared/errorFunction';

@Component({
  selector: 'story-history',
  templateUrl: 'story-history.component.html'
})

export class StoryHistoryComponent implements OnInit {
  @Input() project: Project;
  storyId: number;
  accessTypeOfattachment = ['png', 'jpg', 'jpeg', 'gif'];
  activities: Activity[] = [];
  totalCount: any = {};
  show_activity = false;
  startLoader: boolean = true;

  constructor(private notificationService: NotificationService,
              private projectService: ProjectService,
              private activityService: ActivityService,
              private domSanitizer: DomSanitizer,
              private errorService: ErrorService,
              private sharedService: SharedService) {}

  ngOnInit() {
    this.notificationService.subscribeEvent(EVENT_TYPES.STORY.STORY_HISTORY, data => {
      if (data && data.payload && data.payload.data && data.payload.data.scrollIntoView) {
        this.loadActivities(false, true);
      } else {
        this.loadActivities(false);
      }
    });
  }

  loadActivities(all_load, scroll_into_view?) {
    this.storyId = this.projectService.show_Story.id;
    this.activityService.activities(this.project.id, this.storyId, all_load).subscribe((result) => {
      this.startLoader = false;
      if (result.activities) {
        this.getActivities(result.activities);
        if (result.meta) {
          this.totalCount['current_count'] = result.meta['current_count'];
          this.totalCount['total_count'] = result.meta['total_count'];
          this.show_activity = this.totalCount['total_count'] > this.totalCount['current_count'] && !all_load;
        }
      }
    }, error => {
      this.errorService.errorFunction(error);
    });
  }

  getActivities(activities: Activity[]) {
    this.activities = [];
    activities.forEach((act) => {
      this.activities.push(new Activity(act));
    });
  }

  checkType(doc) {
    if (doc && doc.length > 0) {
      const lastIndexofDot = doc.lastIndexOf('.');
      const type = doc.substr(lastIndexofDot + 1);
      if (this.accessTypeOfattachment.indexOf(type.toLowerCase()) >= 0) {
        return true;
      }
      return false;
    }
  }

  toggleViewmode(activity) {
    activity.toggleHistory = !activity.toggleHistory;
  }

  showMore() {
    this.show_activity = false;
    this.loadActivities(true);
  }

  bypassSanitization(html: string) {
    return this.domSanitizer.bypassSecurityTrustHtml(html);
  }
}
