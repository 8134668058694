<admin-projects-form 
                     [model]="form"
                     [errors]="errors"
                     [isPending]="isPending"
                     (submit)="onSubmit()">
  <div class="actionBt-wrapper">
    <button class="actionButton cancel-addMember" (click)="sharedService.showprojectAdminForm = false">Cancel</button>
    <button type="revert" class="actionButton add" *ngIf="!isPristine" (click)="revert()">Revert Changes</button>
    <button class="actionButton add" [disabled]="isPending || isPristine">Save</button>
  </div>
</admin-projects-form>
