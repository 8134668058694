import { Pipe, PipeTransform } from '@angular/core';
import { Story } from '../models/story';
import * as _ from 'lodash';
@Pipe({
    name: 'squadMemberSearch',
    pure: false
})

export class SquadMemberSearchPipe implements PipeTransform {
    transform(items: any[], story: Story): any[] {
        const allowed_pod_role = ['CPE','CTE','SCTE','SCPE','CPM','SCPM'];
        if (!items) return [];
        if (!story || story.dev_type !== 'E2E') return items;
        return items.filter(it => {
            return it.pod_role && _.includes(allowed_pod_role, it.pod_role);
        });
    }
}
