import {Pipe, PipeTransform} from '@angular/core'
import { Project } from '../../admin/timing/models/project';

@Pipe({
  name: 'order'
})
export class OrderPipe implements PipeTransform {
  transform(items: Project[], searchText: string): Project[] {
    if (!items) return [];
    if (!searchText) return items;
    searchText = searchText.toLowerCase();
    return items.filter(it => {
            let name = it.name.toLowerCase();
            return (name.includes(searchText));        
    });
  }
}