import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BaseComponent } from '../base.component';
import { TimingProjectsService, IProjectsList } from './../../services/projects.service';
import { TimingUsersService, IUsersList } from './../../services/users.service';
import { Moment } from 'moment';
import moment from 'moment';
import { DateRange } from '../../models/date-range';
import { Project } from '../../models/project';
import { User } from '../../models/user';

@Component({
  selector: 'app-admin-timing-report',
  templateUrl: 'report.component.html',
  styleUrls: [
    '../../../admin.common.scss',
    'report.component.scss'
  ]
})
export class TimingReportComponent extends BaseComponent implements OnInit {

  public users: User[] = [];
  public user: User;
  public projects: Project[] = [];
  public dateRange: DateRange;
  public totalTime: string;
  public isPending = false;
  public currentProjectsPage = 1;
  public totalProjectsPages = 1;
  public startLoader:boolean = false;

  constructor(private projectsService: TimingProjectsService,
              private usersService: TimingUsersService,
              private router: Router) {
    super();
  }

  ngOnInit(): void {
    this.startLoader = true;
    const from = moment().startOf('isoWeek');
    const to = moment(from).day(+7);
    this.dateRange = new DateRange(from, to);
    this.getUsers();
  }

  onDateRangeChanged(dateRange: DateRange): void {
    this.dateRange = dateRange;
    this.getProjects();
  }

  onProjectsPageChanged(page: number): void {
    this.currentProjectsPage = page;
    this.getProjects();
  }

  getUsers(): void {
    this.isPending = true;
    this.usersService
      .getUsers()
      .subscribe(
        data => {
          this.users = data.users;
          this.user = this.users[0];
          this.getProjects();
        });
  }

  getProjects(): void {
    this.isPending = true;
    const filter = {
      user: this.user.id,
      page: this.currentProjectsPage,
      from: this.dateRange.formatedFrom,
      to: this.dateRange.formatedTo,
      hours: true
    };
    this.projectsService
      .getProjects(filter)
      .subscribe(data => {
        this.projects = data.projects;
        this.totalTime = data.totalTime;
        this.totalProjectsPages = data.pages;
        this.isPending = false;
        this.startLoader = false;
      });
  }

  goToProject(id: number): void {
    this.router.navigate([`admin/timing/projects`, { id: id }]);
  }
}
