import { Component, EventEmitter, Input, Output } from '@angular/core';
import { range } from 'lodash';

@Component({
  selector: 'app-admin-timing-pagination',
  templateUrl: 'pagination.component.html',
  styleUrls: ['pagination.component.scss']
})
export class TimingPaginationComponent {

  @Input() public isPending: boolean;
  @Input() public currentPage: number;
  @Input() private totalPages: number;
  @Output() onNewPageSelected: EventEmitter<number> = new EventEmitter();

  constructor() {
    // This is intentional 
  }

  get firstPage() {
    return this.currentPage === 1;
  }

  get lastPage() {
    return this.currentPage === this.totalPages;
  }

  get pages() {
    let min = this.currentPage - 1 || 1;
    const max = min + 2 > this.totalPages ? this.totalPages : min + 2;
    if (this.totalPages > 2 && this.lastPage) { min -= 1; }
    return range(min, max + 1);
  }

  goToPage(page: number): void {
    this.onNewPageSelected.emit(page);
  }
}
