import { BaseModel } from './baseModel';
import { ReleasePlannerData, ReleasePlannerPlatform} from '../dataTypes';
import moment from 'moment';

export class ReleasePlannerModal extends BaseModel implements ReleasePlannerData {
  id?: number;
  name?: string;
  version?: string;
  estimated_end_date?: any;
  attachments?: Array<any>;
  created_at?: Date;
  environment?: string;
  release_detail_text?: string;
  phase_deliverables?: string;
  platform_associations?: Array<any>;
  platforms?: Array<ReleasePlannerPlatform>;
  build_url?: string;
  status?: string;
  sprints?: Array<number>;
  rating?: any;
  start_date?: Date;
  started_by?: string;
  started_by_id?: number;
  updated_at?: any;
  updated_by?: string;
  updated_by_id?: number;
  comments?: Array<any>;
  show_release_actions: boolean = false;
  show_comment:boolean = false;
  show_activity:boolean = false;
  stories_count?: number;
  stories_count_by_status?: any;
  features?: Array<any>;
  total_feature_count?: number;
  statusDropdownVisible?: boolean;
  status_selection?: any;
  deleted_at?: any;
  status_loader?: boolean;
  phase?: string;
  check_selection?: boolean = false;
  check_move_validation?: boolean = false;
  parent_id?: number;

  constructor(data: ReleasePlannerData) {
    super(data);
    this.id = data.id;
    this.name = data.name;
    this.version = data.version;
    this.estimated_end_date = data.estimated_end_date ? this.timeFormat(data.estimated_end_date) : '';
    this.attachments = data.attachments;
    this.created_at = data.created_at;
    this.environment = data.environment;
    this.release_detail_text = data.release_detail_text;
    this.phase_deliverables = data.phase_deliverables;
    this.platform_associations = data.platform_associations;
    // this.platforms = data.platforms;
    if(data.platforms && data.platforms.length > 0) {
      this.platforms = [];
      data.platforms.forEach((item) => {
        item['selected'] = true;
        this.platforms.push(item);
      })
    }
    this.build_url = data.build_url;
    this.status = data.status;
    this.sprints = data.sprints;
    this.rating = data.rating;
    this.start_date = data.start_date;
    this.started_by = data.started_by;
    this.started_by_id = data.started_by_id;
    this.updated_at = data.updated_at ? this.timeFormat(data.updated_at) : '';
    this.updated_by = data.updated_by;
    this.updated_by_id = data.updated_by_id;
    this.comments = data.comments;
    this.show_release_actions = false;
    this.show_comment = false;
    this.show_activity = false;
    this.stories_count = data.stories_count;
    this.stories_count_by_status = data.stories_count_by_status;
    this.features = data.features ? data.features : [];
    this.total_feature_count = data.total_feature_count;
    this.statusDropdownVisible = false;
    this.deleted_at = data.deleted_at;
    this.status_loader = false;
    this.phase = data.phase;
    this.check_selection = false;
    this.check_move_validation = false;
    this.parent_id = data.parent_id;
  }

  timeFormat(time) {
    return moment(time).format('DD MMM YYYY | HH:mm:ss');
  }

  getClassName() {
    return "ReleasePlannerModal";
  }
} 