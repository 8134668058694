import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild, ViewChildren, QueryList, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { StoryDetailComponent } from './storyDetail.component';
import {
  Story
} from '../../shared/models/story';

import { Project } from '../../shared/models/project';

import { EVENT_TYPES, DataManipulationOptions, ProjectColumnData, BB_STATUS,STORY_PRIORITY_OPTIONS,UPDATE_STORY_REASONS, UPDATE_STORY_EVENT_KEYS} from '../../shared/dataTypes';
import { StoryService } from '../../shared/services/story.service';
import { UserService } from '../../shared/services/user.service';
import { ErrorService } from '../../shared/errorFunction';
import { NotificationService } from '../../shared/services/notification.service';
import { ProjectService } from '../../shared/services/project.service';
import moment from 'moment';
import {CustomToastService} from '../../shared/services/custom-toast.service';
import { SharedService } from '../../shared/services/shared.service';
import {drop, startCase} from 'lodash';
import { trigger, transition, style, animate } from '@angular/animations';
import {PolicyService} from "../../shared/services/policy.service";
import {Epic} from '../../shared/models/epic';
import { ProjectSyncService } from '../../shared/services/projectSync.service';
import { FilterStoryService } from '../../shared/services/filter-story.service';
import { Subscription } from 'rxjs';
import { EpicService } from '../../shared/services/epic.service';
import {User } from '../../shared/models/user';
import { CommonStoryService } from '../../shared/services/common-story.service';

@Component({
  selector: 'project-story, [project-story]',
  templateUrl: 'projectStory.component.html',
  styleUrls: ['projectStory.component.scss'],
  providers: [
    StoryDetailComponent
  ],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({
          opacity:'0',
          transform: 'translateX(25px) translateY(3px)'
        }),
        animate(200, style({
          opacity:'1',
          transform: 'translateX(40px) translateY(3px)'
        }))
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate(200, style({
          opacity:'0',
          transform: 'translateX(25px) translateY(3px)'
        }))
      ])
    ]),
    trigger('tooltipAni', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({
          opacity:'0',
          transform: 'translateX(-50%)'
        }),
        animate(200, style({
          opacity:'1',
          transform: 'translateX(-50%)'
        }))
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate(200, style({
          opacity:'0',
          transform: 'translateX(-50%)'
        }))
      ])
    ])
  ],
  host: {
    '(document:click)': 'onClick($event)'
  }
})
export class ProjectStoryComponent implements OnInit,OnChanges {
  @Input() project: Project;
  @Input() story: Story;
  @Input() column: ProjectColumnData;
  @ViewChild('getQuickActioncord')
  private getQuickActioncord;
  @ViewChild('showQuickActioncord')
  private showQuickActioncord;
  @ViewChildren("proSt") proSt: QueryList<ElementRef>;
  @Output('applyFilterLabel') applyFilterLabel = new EventEmitter();
  OpenAcceptPopup = false;
  OpenAcceptAlertPopup = false;
  showCommitWarningPopup = false;
  stateAction;
  waitForLoader = false;
  @ViewChild('proSt', {static: true}) projectStory: any;
  mouseOvered:boolean =false;
  openCommonPopUp: boolean = false;
  showStoryDetail: boolean = true;
  showCopiedID:boolean = false;
  showCopiedLink:boolean = false;
  whereFrom:string = 'from_project_story';
  moveToStory:number = null;
  selectedSubtasks = [];
  showConvertSubtaskPopup:boolean = false;
  loaderForConvertSubtask:boolean = false;
  parent_story: Story;
  openUnarchivePopUp: boolean = false;
  archived_story_detail: any;
  archived_story_detail_loader:boolean = false;
  elapsedTime;
  estimateBroadCast : Subscription
  startCase = startCase;
  userDropDownType: string;
  storyPriorityOptions: Array<any> = STORY_PRIORITY_OPTIONS;
  dropDownType: string;
  searchEpic: String = '';
  featureSearchText: string;
  public searchUser = '';
  public sortedUsers = [];
  insertOwnerTime:boolean = false;
  insertReviewerTime:boolean = false;
  input_estimate: number = 0;
  input_reviewer_estimate: number = 0;
  story_update_reason_option = '';
  @ViewChild('ownerTimeInput', {static: false}) ownerTimeInput: ElementRef;
  @ViewChild('reviewerTimeInput', {static: false}) reviewerTimeInput: ElementRef;
  openTimerReasonsPopup:boolean = false;
  input_for = '';
  showStoryupdateReasonList:boolean = false;
  story_update_reason = UPDATE_STORY_REASONS;
  mincardUpdateLoader: boolean;
  projectFeatures:Array<any>;
  studioRoles:Array<any>;
  

  constructor(private notificationService: NotificationService,
              private userService: UserService,
              private errorService: ErrorService,
              private storyService: StoryService,
              private renderer: Renderer2,
              private customToast: CustomToastService,
              private renderer2: Renderer2,
              public sharedService: SharedService,
              public projectService: ProjectService,
              public policyService: PolicyService,
              private filterStoryService: FilterStoryService,
              private epicService: EpicService,
              public commonStoryService: CommonStoryService,
              private projectSyncService: ProjectSyncService) {
  }

  get isProjectReadonly(): boolean {
    return this.policyService.isProjectReadonly(this.project);
  }

  ngOnInit() {
    this.projectService.initiate_from = 'from project';
    if (!this.project) {
      this.project = this.story.project;
    }
    /* when a story is open via direct URL or notifications panel; this.projectService.storyId has been set in project component */
    if (this.projectService.storyId) {
      // this.router.navigate(['/projects/' + this.project.id]);
      const StoryId = this.projectService.storyId;
      this.toggleEditMode(StoryId);
      this.projectService.storyId = '';
    }

    this.notificationService.subscribeEvent(EVENT_TYPES.STORY.SHOW_QUICK_ACTIONS, res => {
      if(this.story.id == res.payload.data.selStory.id) {
        this.storyQuickAction();
      }
    });
    
    this.notificationService.subscribeEvent(EVENT_TYPES.STORY.DUPLICATE_AFTER_CONFIRM, res => {
      if(this.story.id === res.payload.data.story.id && this.projectService.duplicate_with_filter && !this.projectService.show_storyDetails) {
        this.duplicate();
        this.projectService.duplicate_with_filter = false;
      }
    });

    this.estimateBroadCast =  this.notificationService.subscribeEvent(EVENT_TYPES.STORY.ESTIMATE_UPDATED, res=>{
      if(res.payload.id === this.story.id){
        this.elapsedTime = this.totalElapsedTime()
      } 
    });
    this.input_estimate = this.story && this.story.estimate ? this.story.estimate : 0;
    this.input_reviewer_estimate = this.story.reviewer_estimate ?  this.story.reviewer_estimate : 0;
    this.story_update_reason_option = '';
    this.openTimerReasonsPopup = false;
    this.input_for = '';
    this.showStoryupdateReasonList = false;
  }

  sortedUserList(){
    this.sortedUsers = this.project.users.sort((a: User, b: User) => {
      const nameA = (a.name != null ? a.name.toLowerCase() : a.email.toLowerCase());
      const nameB = (b.name != null ? b.name.toLowerCase() : b.email.toLowerCase());
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  }

  sortMembers(){
    this.sortedUsers = this.sortedUsers.sort((a, b) => {
      let valueA, valueB;
      valueA = a.name ? a.name : '',
      valueB = b.name ? b.name : '';
      valueA = valueA.toLowerCase();
      valueB = valueB.toLowerCase();
      if (valueA > valueB) {
        return 1;
      } else if (valueA < valueB) {
        return -1;
      } else {
        return 0;
      }
    });
  }

  sortDeallocatedMembers(){
    this.sortedUsers = this.sortedUsers.sort((a, b) => {
      let valueA, valueB;
      valueA = a,
      valueB = b;
      const ind1 = this.project.memberships.findIndex(member => member.user.id === valueA.id)
      const ind2 = this.project.memberships.findIndex(member => member.user.id === valueB.id);
      if ((valueA.deleted_at || !this.project.memberships[ind1].active) && (!valueB.deleted_at && this.project.memberships[ind2].active)) {
        return 1;
      } else if ((!valueA.deleted_at && this.project.memberships[ind1].active) && (valueB.deleted_at || !this.project.memberships[ind2].active)) {
        return -1;
      } else {
        return 0;
      }
    });
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    this.elapsedTime = this.totalElapsedTime() 
  }

  totalElapsedTime() {
    const total_tracked_time = this.storyTrackedNumberTime(this.story) + this.storyReviewTrackedTimeNumber(this.story);
    const total = this.elapsedTotal();
    if (total - total_tracked_time < 0) {
      this.story.time_completed = 101;
      return '-' + this.secsToDuration(total_tracked_time - total);
    } else {
      this.story.time_completed = total_tracked_time / total * 100;
      return this.secsToDuration(total - total_tracked_time);
    }
  }

  elapsedTotal() {
    let total_time = 0;
    if (this.story.estimate) {
      total_time = total_time + this.story.estimate;
    }
    if (this.story.reviewer_estimate) {
      total_time = total_time + this.story.reviewer_estimate;
    }
    return total_time * 3600;
  }

  storyTrackedNumberTime(story: Story) {
    let elapsedTotal = 0;
    if (story.timer_elapsed_total) {
      elapsedTotal = story.timer_elapsed_total;
    }
    
    return Math.ceil(elapsedTotal);
  }
  
  storyReviewTrackedTimeNumber(story) {
    let elapsedTotal = 0;
    if (story.reviewer_elapsed_time_total) {
      elapsedTotal = story.reviewer_elapsed_time_total;
    }
   
    return Math.ceil(elapsedTotal);
  }

  ngOnDestroy() {
    this.whereFrom = '';
    this.projectService.initiate_from = '';
    this.estimateBroadCast.unsubscribe()
  }

  /* story will be open in detail view mode. var 'showStoryDetail' is true by default but it sets to be false in case
     user clicks on any label/feature as that shows that label/feature needs to be set as filter in the current project and
     story should not open in detail view mode */
  toggleEditMode(storyId) {
    this.projectService.activeStoryId = storyId
    if (this.showStoryDetail) {
      this.projectService.show_StoryDetails(this.project, '', storyId,false, this.whereFrom);
    } else {
      this.showStoryDetail = true;
    }
  }

  get dataManipulationOptions(): DataManipulationOptions {
    return {
      notificationService: this.notificationService,
      currentUser: this.userService.getUser()
    };
  }

  openCustomPopup(stateAction: string, $event: MouseEvent) {
    if(this.userService.currentUser.id === this.story.reviewer_id || (this.story.dev_type === 'QA' && this.userService.currentUser.id === this.story.owned_by_id)) {
      this.userService.trackSegmentEvent('Quick Action Selected', this.getPropsOnQuickActionSelection(stateAction));
      this.renderer.addClass(document.body, 'deletePopupView');
      this.story.isQuickActionPerformed = true;
      if (this.project) {
        this.project.is_dragg = false;
      }
      $event.preventDefault();
      $event.stopPropagation();
      if (stateAction === 'accept' || stateAction === 'reject') {
        if (stateAction === 'accept') {
          if (!this.story.parent_id && ((this.story.total_tasks_count - this.story.done_tasks_count > 0) || (this.story.test_cases && this.story.checkTestCasesStatus()))) {
            this.OpenAcceptAlertPopup = true;
          } else if (this.project.dashboard_project_id && (this.story.phase === 'Full Build' || this.story.phase === 'MVP') && this.story.dev_type !== 'QA' && this.story.commits_count === 0) {
            this.showCommitWarningPopup = true
          } else {
            this.OpenAcceptPopup = true;
          }
          this.stateAction = stateAction;
        } else {
          this.OpenAcceptPopup = true;
          this.stateAction = stateAction;
        }
      } else {
          this.stateActionChanged(stateAction);
      }
    } else{
      this.customToast.messages.push({
        id: 'notAuthorizedUser',
        type: 'fail',
        class: 'generic_alert',
        title: 'Alert',
        message: 'You are not authorized to ' + stateAction + ' the story as only reviewer can ' + stateAction + ' a story'
      });
    }
    
  }

  closeCommitWarningPopup() {
    this.renderer.removeClass(document.body, 'deletePopupView');
    this.story.isQuickActionPerformed = false;
    this.showCommitWarningPopup = false;
  }

  closePopUp() {
    this.renderer.removeClass(document.body, 'deletePopupView');
    this.OpenAcceptPopup = false;
    this.toggleStorySelection(null, this.story);
  }

  closeAlertPopUp() {
    this.renderer.removeClass(document.body, 'deletePopupView');
    this.OpenAcceptAlertPopup = false;
    this.toggleStorySelection(null, this.story);
  }

  get stateActionText() {
    if (this.stateAction === 'accept') {
      return 'accept';
    } else if (this.stateAction === 'reject') {
      return 'reject';
    }
  }

  get stateActionTextTitle() {
    if (this.stateAction === 'accept') {
      return 'Accept';
    } else if (this.stateAction === 'reject') {
      return 'Reject';
    }
  }

  stateActionChanged(stateAction) {
    this.waitForLoader = true;
    this.story.stateActionChanged(stateAction, this.userService.getUser());
    this.closePopUp();
    this.storyService.updateStory(this.story, true)
      .subscribe((st: Story) => {
        this.trackStoryUpdateSegmentEvent('state updated', 'state');
        this.projectSyncService.updateAndShiftStory(this.story, st);
        this.waitForLoader = false;
      }, (error) => {
        this.waitForLoader = false;
        this.errorService.errorFunction(error);
      });
  }

  isSketchStatusAccepted() {
    if (this.project && this.project.dashboard_project_id && this.story &&
          this.story.phase === 'Design' && this.story.sketch_status !== 'sketch_accepted') {
      return false;
    }
    return true;
  }

  toggleStorySelection(event, story: Story) {
    this.projectService.hide_StoryDetails();
    if(story) {
      const column = this.sharedService.getColumn(story.list_id);
      if (event && event.target.checked) {
        story.deleted_at ? (this.projectService.archived_story_selected = true) : (this.projectService.normal_story_selected = true)
        story.isSelected = true;
        this.project.selectedStories.push(story);
        column.selectedStoryCount++;
        if (this.project.selectedStories.length === 2) {
          const qucikStory: Story = this.project.findQuickActionStory();
          if(qucikStory) {
            qucikStory.storyQuickActionClose();
          }
        } else if (this.project.selectedStories.length === 1) {
          this.storyQuickActionOpen();
        }
        this.userService.trackSegmentEvent(story.storyOrTask() + ' Selected',
          this.projectService.getCommonPropsForStoryBoardEvents(story.project, story));
      } else if (!event && story.isSelected) {
        story.isSelected = false;
        const storyIndex = this.project.findStoryIndex(story);
        if (storyIndex >= 0) {
          this.project.spliceStory(storyIndex);
        }
        column.selectedStoryCount--;
        story.storyQuickActionClose();
        this.story.isQuickActionPerformed = false;
        this.checkAllStoriesUnselected();
      } else {
        story.isSelected = false;
        const storyIndex = this.project.findStoryIndex(story);
        if (storyIndex >= 0) {
          this.project.spliceStory(storyIndex);
        }
        column.selectedStoryCount--;
        story.storyQuickActionClose();
        if (this.project.selectedStories.length === 1) {
          this.sharedService.showQuickAction(this.project);
        }
        this.checkAllStoriesUnselected();
      }

      column.allSelected = (column.stories.length === column.selectedStoryCount);
    }
  }

  checkAllStoriesUnselected() {
    if(this.projectService.archived_story_selected) {
      this.project.selectedStories.length === 0 ? this.projectService.archived_story_selected = false : this.projectService.archived_story_selected = true;
    }
    if(this.projectService.normal_story_selected) {
      this.project.selectedStories.length === 0 ? this.projectService.normal_story_selected = false : this.projectService.normal_story_selected = true;
    }
  }

  moveStory() {
    this.story.isQuickActionPerformed = true;
    this.notificationService.broadcast(EVENT_TYPES.STORY.MOVE, {});
    this.userService.trackSegmentEvent('Quick Action Selected', this.getPropsOnQuickActionSelection('Move Story'));
  }

  setOwner() {
    if (!this.story.phase) {
      return;
    }
    this.story.isQuickActionPerformed = true;
    this.notificationService.broadcast(EVENT_TYPES.STORY.SET_OWNER, {});
    this.userService.trackSegmentEvent('Quick Action Selected', this.getPropsOnQuickActionSelection('Set Owner'));
  }

  setReviewer() {
    if (!this.story.phase) {
      return;
    }
    this.story.isQuickActionPerformed = true;
    this.notificationService.broadcast(EVENT_TYPES.STORY.SET_REVIEWER, {});
    this.userService.trackSegmentEvent('Quick Action Selected', this.getPropsOnQuickActionSelection('Set Reviewer'));
  }

  setEstimate() {
    this.story.isQuickActionPerformed = true;
    const payload = {
      data: {
        story: this.story
      }
    };
    this.notificationService.broadcast(EVENT_TYPES.STORY.SET_ESTIMATE, payload);
    this.userService.trackSegmentEvent('Quick Action Selected', this.getPropsOnQuickActionSelection('Set Owner Estimate'));
  }

  editFeaturesLabels() {
    this.story.isQuickActionPerformed = true;
    this.notificationService.broadcast(EVENT_TYPES.STORY.EDIT_FEATURES_LABELS, {});
    this.userService.trackSegmentEvent('Quick Action Selected', this.getPropsOnQuickActionSelection('Edit Features & Labels'));
  }

  changeStoryPhase(phase) {
    this.story.isQuickActionPerformed = true;
    this.story.phase = phase;
    this.updateStory(this.story, 'phase updated', 'phase');
    this.story.showDropDown = false;
  }

  changeStoryTracker_Phase(phase) {
    this.story.isQuickActionPerformed = true;
    this.story.phase = phase.phase_name;
    this.story.task = phase.tracker_phase_name;
    this.updateStory(this.story, 'sub phase updated', 'task');
    this.story.showDropDown = false;
  }

  duplicate() {
    this.story.isQuickActionPerformed = true;
    const copy = Object.assign({}, this.story);
    copy.is_epic_added = true;
    this.project.is_dragg = false;
    this.userService.trackSegmentEvent('Quick Action Selected', this.getPropsOnQuickActionSelection('Duplicate Story'));
    copy.reason_for_creation = this.projectService.creationReasonsSelected ? this.projectService.creationReasonsSelected : '';
    this.storyService.copyStory(copy, this.projectService.apply_on_sub_tasks).subscribe(res => {
      const storyData = res.story.story;
      this.projectService.clonePopupLoader = false;
      this.projectService.closeCloneConfirmationPopup();
      this.renderer2.removeClass(document.body, 'duplicateStoryPopup-open'); 
      storyData.isSimulatedObject = true;
      storyData.project = this.story.project;
      const clonedStory = new Story(storyData);
      this.trackStoryTaskCreateSegmentEvent(clonedStory);
      this.projectService.addStoryOrSubTaskOnStoryBoard(this.project, clonedStory);
      if (res && res.message) {
        this.customToast.messages.push({
          id: 'story_new',
          type: 'success',
          class: 'story_new',
          title: 'Story Cloned',
          message: res.message
        });
      }
      this.toggleStorySelection(null, this.story);
      this.projectService.creationReasonsSelected = '';
    }, error => {
      this.errorService.errorFunction(error);
      this.projectService.clonePopupLoader = false;
      this.projectService.closeCloneConfirmationPopup();
      this.renderer2.removeClass(document.body, 'duplicateStoryPopup-open'); 
      this.toggleStorySelection(null, this.story);
    });
  }

  destroy() {
    if( !this.policyService.isUserProjectAdmin && this.story.story_type !== 'bug' && this.policyService.isQAExpert(this.project)) {
      this.policyService.readOnlyWarning();
      return;
    }
    this.story.isQuickActionPerformed = true;
    this.openCommonPopUp = true;
    this.renderer.addClass(document.body, 'deletePopupView');
    this.userService.trackSegmentEvent('Quick Action Selected', this.getPropsOnQuickActionSelection('Delete'));
  }

  /*deleteStory() {
    this.waitForLoader = true;
    this.storyService.deleteStory(this.story).subscribe((story: Story) => {
        this.projectService.hide_StoryDetails();
        this.project.removeStory(story, this.dataManipulationOptions);
        this.waitForLoader = false;
        this.renderer.removeClass(document.body, 'deletePopupView');
        this.customToast.messages.push({
          id: 'story_deleted',
          type: 'success',
          class: 'story_delete',
          title: 'Story Archived',
          message: story && story.parent_id ? 'You have archived 1 task successfully' : 'You have archived 1 story successfully'
        });
      },
      (error) => {
        this.errorService.errorFunction(error);
        this.waitForLoader = false;
        this.closeCommonPopUp();
      });
  }*/

  closeCommonPopUp() {
    this.openCommonPopUp = false;
    this.renderer.removeClass(document.body, 'deletePopupView');
    this.toggleStorySelection(null, this.story);
  }

  phases() {
    if (this.story && this.story.phase) {
      let phases = Object.assign([], this.project.phases);
      phases = phases.filter(phase => phase !== this.story.phase);
      return phases;
    }
    return this.project.phases;
  }

  subPhases() {
    let filtered_tracker_phases = this.project.tracker_phases;
    if (this.story && this.story.parent_id) {
      if (this.parent_story && this.parent_story.phase && this.parent_story.phase.trim().toLowerCase() !== 'design') {
        filtered_tracker_phases = this.project.tracker_phases.filter(item => item.phase_name.trim().toLowerCase() !== 'design');
      }
    }
    if (this.story && this.story.task) {
      filtered_tracker_phases = filtered_tracker_phases.filter(phase => phase.tracker_phase_name !== this.story.task);
    }
    return filtered_tracker_phases;
  }

  updateStory(story, event_Name, propName, archive?, unarchive?) {
    let arrStoryKeys = []
    this.mincardUpdateLoader = true;
    const story_data = story;
    const story_deleted_at_data = story.deleted_at;
    const story_parent_id_data = story.parent_id;
    if(unarchive) {
      story_data.deleted_at = null;
      if (this.story.parent_id && this.archived_story_detail.parent_deleted_at) {
        story_data.parent_id = null;
      }
      arrStoryKeys = UPDATE_STORY_EVENT_KEYS['unarchive']
    }
    if (archive) {
      arrStoryKeys = UPDATE_STORY_EVENT_KEYS['archive']
    }
    if (UPDATE_STORY_EVENT_KEYS[propName]) {
      arrStoryKeys = UPDATE_STORY_EVENT_KEYS[propName]
    }
    this.story_update_reason_option = '';
    this.project.is_dragg = false;
    this.storyService.updateStoryGeneric(this.storyService.getMinRequiredKeys(story,arrStoryKeys), true).subscribe((st: Story) => {
      if (event_Name && propName) {
        this.trackStoryUpdateSegmentEvent(event_Name, propName);
      }
      this.toggleStorySelection(null, story);
      if (archive) {
        this.projectService.showListLoader = false;
        this.customToast.messages.push({
          id: 'story_deleted',
          type: 'success',
          class: 'story_delete',
          title: 'Story Archived',
          message: story && story.parent_id ? 'You have archived 1 task successfully' : 'You have archived 1 story successfully'
        });
      } else if (unarchive) {
        this.projectService.showListLoader = false;
        this.customToast.messages.push({
          id: 'story_new',
          type: 'success',
          class: 'story_new',
          title: 'Story Unarchive',
          message: story && story.parent_id ? 'You have unarchived 1 task successfully' : 'You have unarchived 1 story successfully'
        });
      }
      this.projectSyncService.updateAndShiftStory(this.story, st);
      this.mincardUpdateLoader = false;
    }, (error) => {
      if(unarchive) {
        this.story.deleted_at = story_deleted_at_data;
        if (this.story.parent_id && this.archived_story_detail.parent_deleted_at) {
          this.story.parent_id = story_parent_id_data;
        }
      }
      this.mincardUpdateLoader = false;
      this.story_update_reason_option = '';
      this.projectService.showListLoader = false;
      this.openCommonPopUp = false;
      this.renderer.removeClass(document.body, 'deletePopupView');
      this.openUnarchivePopUp = false;
      this.errorService.errorFunction(error);
      this.toggleStorySelection(null, story);
    });
  }

  unarchive() {
    this.story.storyQuickActionClose();
    this.closeUnarchivePopUp();
    this.projectService.showListLoader = true;
    this.projectService.story_col_name = this.story.storyColumnName(this.story.list_id);
    this.updateStory(this.story, null, null, false, true);
    this.renderer.removeClass(document.body, 'deletePopupView');
  }

  showUnarchivePopup() {
    this.story.isQuickActionPerformed = true;
    this.openUnarchivePopUp = true;
    this.renderer.addClass(document.body, 'deletePopupView');
    
    // Load story details from server
    this.archived_story_detail_loader = true;
    this.storyService.getStoryDetail(this.project.id, this.story.id).subscribe(res => {
      this.archived_story_detail = res;
      this.archived_story_detail_loader = false;
    }, (error) => {
      this.archived_story_detail_loader = false;
      this.errorService.errorFunction(error);
    });
  }

  checkParentArchiveDetail() {
    if (this.archived_story_detail && !this.story.parent_id) {
      return !this.archived_story_detail.active_subtask_count && !this.archived_story_detail.archived_subtask_count;
    }
  }

  closeUnarchivePopUp() {
    this.openUnarchivePopUp = false;
    this.renderer.removeClass(document.body, 'deletePopupView');
    this.toggleStorySelection(null, this.story);
  }

  archive() {
    this.story.storyQuickActionClose();
    this.closeCommonPopUp();
    this.waitForLoader = false;
    this.projectService.showListLoader = true;
    this.projectService.story_col_name = this.story.storyColumnName(this.story.list_id);
    this.story.deleted_at = moment();
    this.updateStory(this.story, null, null, true);
  }

  total_elapsed_time() {
    let  time_elapsed = 0;
    time_elapsed = time_elapsed + (this.story.timer_elapsed_total ? this.story.timer_elapsed_total : 0);
    time_elapsed = time_elapsed + (this.story.reviewer_elapsed_time_total ? this.story.reviewer_elapsed_time_total : 0);
    return this.secsToDuration(time_elapsed);
  }

  secsToDuration(secs: number) {
    const duration = moment.duration(secs, 'seconds');
    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    const pad = (number) => { return number < 10 ? '0' + number : number }
    return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
  }

  trackStoryUpdateSegmentEvent(event_Name, propName) {
    this.userService.trackSegmentEvent(this.story.storyOrTask() + ' ' + event_Name,
                                        this.projectService.getPropsOnStoryTaskUpdation(this.project, this.story, propName));
  }

  trackStoryTaskCreateSegmentEvent(story) {
    this.userService.trackSegmentEvent(story.storyOrTask(story) + ' created',
                                        this.projectService.getPropsOnStoryTaskCreation(this.project, story));
  }

  // Show quick option for story
  storyQuickActionOpen() {
    if (this.story.parent_id) {
      // Search parent story from loaded stories
      const index = this.project.stories.findIndex(st => st.id === this.story.parent_id);
      if (index !== -1) {
        this.parent_story = this.project.stories[index];
      } else if (!this.parent_story) {
        // Fetch story data from BE server
        this.storyService.getStoryDetail(this.project.id, this.story.parent_id).subscribe(parent => this.parent_story = parent);
      }
    }
    this.story.showQuickAct = true;
    this.storyQuickAction();
  }

  storyQuickAction() {
    setTimeout(() => {
      if(this.getQuickActioncord && this.showQuickActioncord) {
        const windHeight = window.innerHeight;
        const windWidth = window.innerWidth;
        const x = this.getQuickActioncord.nativeElement.getBoundingClientRect().x;
        const y = this.getQuickActioncord.nativeElement.getBoundingClientRect().y;
        const showpopWidth = this.showQuickActioncord.nativeElement.getBoundingClientRect().width;
        const calcWidth = showpopWidth + x + 45;
        const newdhf = x;
        this.renderer.setStyle(this.showQuickActioncord.nativeElement, 'left', newdhf + 'px');
        this.renderer.setStyle(this.showQuickActioncord.nativeElement, 'margin-left', '0');
        if (calcWidth > windWidth) {
          this.renderer.setStyle(this.showQuickActioncord.nativeElement, 'margin-left', '-200px');
        }
        this.checkDDInStoryQuickAction(windHeight, y);
      }
    }, 0);
  }

  checkDDInStoryQuickAction(windHeight, y) {
    const showpopHeight = this.showQuickActioncord.nativeElement.getBoundingClientRect().height;
    const elementPositionDiff = windHeight - y;
    if (elementPositionDiff < showpopHeight) {
      const updatedPopPositon = y - showpopHeight;
      if (y < showpopHeight) {
        this.renderer.setStyle(this.showQuickActioncord.nativeElement, 'top', '100px');
      } else {
        this.renderer.setStyle(this.showQuickActioncord.nativeElement, 'top', updatedPopPositon + 'px');
      }
    } else {
      this.renderer.setStyle(this.showQuickActioncord.nativeElement, 'top', y + 'px');
    }
  }


  focusDropDown() {
    this.story.isQuickActionPerformed = true;
    this.story.showDropDown = !this.story.showDropDown;
    setTimeout(() => {
      this.storyQuickAction();
    }, 0);
    if (this.story.showDropDown && this.story) {
      this.userService.trackSegmentEvent('Quick Action Selected',
                    this.getPropsOnQuickActionSelection(this.story.parent_id ? 'Sub Phase' : 'Phase'));
    }
  }

  applyFilterOnIconClick(key, value) {
    this.projectService.hide_StoryDetails();
    this.showStoryDetail = false;
    this.filterStoryService.applyFilter(this.project, null, key, value);
  }

  copyStoryID() {
    this.showStoryDetail = false;
    this.showCopiedLink = false;
    this.showCopiedID = true;
    setTimeout(() => {
      this.showCopiedID = false;
    }, 2000);
  }

  copyStoryLink() {
    this.showStoryDetail = false;
    this.showCopiedID = false;
    this.showCopiedLink = true;
    setTimeout(() => {
      this.showCopiedLink = false;
    }, 2000);
  }

  onClick(event) {
    const element = event.target;
    // if (this.project.selectedStories.length === 1 && this.story.isSelected && !this.story.isQuickActionPerformed) {
    //   this.toggleStorySelection(null, this.story);
    // }
    if (!element.classList.contains('userSearchFilter') ||
        !element.classList.contains('userSearchFilter') ||
        !element.classList.contains('feature-search') ||
        !element.classList.contains('user-initials') ||
        !element.classList.contains('feature-tag') ||
        !element.classList.contains('iconb-priority-flag') ||
        !element.classList.contains('iconb-user-group') ) {
      this.projectService.dropDownType = '';
      this.insertOwnerTime = false;
      this.insertReviewerTime = false;
      this.featureSearchText = '';
      this.sortedUsers = [];
      this.projectFeatures = [];
      this.studioRoles = [];
    }
    if (!element.classList.contains('username')) {
      this.userDropDownType ='';
      this.searchUser = '';
    }
  }

  getPropsOnQuickActionSelection(actionPerformed) {
    if (actionPerformed && this.story && this.project) {
      const props = this.projectService.getCommonPropsForStoryBoardEvents(this.project, this.story);
      props['action_selected'] = actionPerformed;

      return props;
    }
  }

  /* Disable dropdown and change in story status depending on BB(building block) status of epic in story */
  disableStoryStatusChange(): boolean {
    const storyEpic: Epic = this.story.getEpicInStory();
    if (!this.story.isDesignStory() && !this.story.isQACore && this.project && this.project.external() && storyEpic
      && storyEpic.builder_feature_id && storyEpic.bb_status && storyEpic.bb_status !== BB_STATUS.skipped) {
      if (storyEpic.bb_status === BB_STATUS.unassigned || storyEpic.bb_status === BB_STATUS.waiting) {
        return true;
      }

      if ((this.story.core || this.story.parent_core) && (storyEpic.bb_status === BB_STATUS.ready_for_customisation ||
        storyEpic.bb_status === BB_STATUS.ongoing_customisation || storyEpic.bb_status === BB_STATUS.finished_customisation ||
        storyEpic.bb_status === BB_STATUS.finished_using_bb || storyEpic.bb_status === BB_STATUS.ready_for_certification)) {
        return true;
      }
    }
    return false;
  }

  /* Is accepting core non-design story depending on BB(building block) status of epic in story possible. */
  checkCoreStoryAcceptance(): boolean {
    const storyEpic: Epic = this.story.getEpicInStory();
    if (!this.story.isDesignStory() && !this.story.isQACore && this.project && this.project.external() && storyEpic
      && storyEpic.builder_feature_id && storyEpic.bb_status && storyEpic.bb_status !== BB_STATUS.skipped
      && (this.story.core || this.story.parent_core)) {
      if (storyEpic.bb_status === BB_STATUS.assigned || storyEpic.bb_status === BB_STATUS.bb_under_development) {
        return false;
      }
    }
    return true;
  }

  convertSubTaskToTask(story) {
    this.loaderForConvertSubtask = true;
    this.selectedSubtasks.push(story.id)
    const storyID = story.id;
    const moveStoryData = {
      story: {
        current_parent_id: story.parent_id,
        new_parent_id: this.moveToStory,
        sub_task_ids: this.selectedSubtasks
      }
    };
    
    this.storyService.moveSubtaskToAnotherTask(this.project.id, moveStoryData).subscribe(res => {
      /* Update the Subtask when Current parnet story when subtask converted to parent story  */
      this.story.parent_id = null;
      this.story.task = null;
      // Update parent object instantly
      const st = this.project.findStoryByID(story.parent_id);
      if (st) {
        if(this.story.state === 'accepted') {
          st.done_tasks_count--;
        }
        st.total_tasks_count--;
      }
      this.closeConvertSubtaskPopup();
      this.loaderForConvertSubtask = false;
    }, err => {
      this.closeConvertSubtaskPopup();
      this.loaderForConvertSubtask = false;
      this.errorService.errorFunction(err);
    });
  }

  openConvertSubtaskPopup() {
    this.showConvertSubtaskPopup = true;
    this.renderer.addClass(document.body, 'deletePopupView');
  }
  closeConvertSubtaskPopup() {
    this.showConvertSubtaskPopup = false;
    this.renderer.removeClass(document.body, 'deletePopupView');
  }

  getSelectedStory(id){
    return this.projectService.show_Story && (this.projectService.show_Story.id === id);
  }

  openCloneConfirmation() {
    if( !this.policyService.isUserProjectAdmin && this.story.story_type !== 'bug' && this.policyService.isQAExpert(this.project)) {
      this.policyService.readOnlyWarning();
      return;
    }
    this.projectService.cloneConfirmPopup = true;
    this.projectService.selectedStory = this.story;
    this.renderer.addClass(document.body, 'duplicateStoryPopup-open');
  }

  getIconClass(platformData){
    if (platformData.length > 1) {
      return 'iconb-devices-platefrom';
    } else if (platformData[0].device === 'Smartphone') {
      return 'iconb-mobile';
    } else if (platformData[0].device === 'Desktop') {
      return 'iconb-desktop';
    } else if(platformData[0].device === 'Watch') {
      return 'iconb-watch';
    }
  }

  acceptValidationForQuickAction() {
    return (this.story.state == 'delivered' && this.story.dev_type === 'QA' && (this.userService.currentUser.id === this.story.owned_by_id)) || (this.story.state == 'delivered' && this.story.status == 'ready' && !this.story.acceptRejectCondition() && this.isSketchStatusAccepted() && !this.disableStoryStatusChange() && this.checkCoreStoryAcceptance());
  }
  // (this.story.dev_type === 'QA' && this.userService.currentUser.id === this.story.owned_by_id)
  rejectValidationForQuickAction() {
    return (this.story.state == 'delivered' && this.story.dev_type === 'QA' && (this.userService.currentUser.id === this.story.owned_by_id)) || (this.story.state == 'delivered' && this.story.status == 'ready' && !this.story.acceptRejectCondition() && !this.disableStoryStatusChange());
  }
  
  getPriority(){
    const priority = this.story.priority.split(" -");
    return priority[0];
  }

  getPriorityClass(priority){
    const priorityCLass = {P1:'is-red',P2:'is-yellow',P3:'is-blue',P4:'is-green',P5:'is-green'} 
    const priorityKey = priority.split(" -")[0]
    return priorityCLass[priorityKey] ? priorityCLass[priorityKey] : 'is-green'
  }

  getMincardClasses() {
    return ['story-mincard', 'story', this.story.story_type, 'story-' + this.story.id, (this.story.parent_id) ? 'is-subtask' : '', this.story.inactive ? 'inactive' : '', (this.projectService.show_storyDetails && ((this.projectService.activeStoryId == this.projectService.storyId ) || (this.projectService.activeStoryId == this.story.id))) ? 'active' : '', (this.story.isSelected) ? 'checkbox-is-checked' : '']
  }


  loopRows(i: number) {
	   return new Array(i);
	}

  focusDropdown($event,dropdown) {
    if (this.projectService.dropDownType === dropdown) {
      this.projectService.dropDownType = '';
    }else{
      this.projectService.dropDownType = dropdown;
    }
    if (dropdown === 'feature'+ this.story.id) {
      this.projectFeatures = this.showEpics();
    } else if (dropdown === 'user'+ this.story.id) {
      this.sortedUserList();
    } else if (dropdown === 'role'+ this.story.id) {
      this.studioRoles = this.project.studio_roles; 
    }
    this.projectService.hide_StoryDetails();
    this.userDropDownType = '';
    this.featureSearchText = '';
    this.searchUser = '';
    this.insertOwnerTime = false;
    this.insertReviewerTime = false;
    this.story_update_reason_option = '';
    this.openTimerReasonsPopup = false;
    this.input_for = '';
    this.showStoryupdateReasonList = false;
    $event.preventDefault();
    $event.stopPropagation();
  }

  focusUserDropdown($event,dropdown) {
    if (this.userDropDownType === dropdown) {
      this.userDropDownType = '';
    }else{
      this.userDropDownType = dropdown;
    }
    this.sortMembers();
    this.sortDeallocatedMembers();
    this.projectService.hide_StoryDetails();
    this.searchUser = '';
    this.insertOwnerTime = false;
    this.insertReviewerTime = false;
    $event.preventDefault();
    $event.stopPropagation();
  }

  blockSameDropDownRequest() {
    this.projectService.dropDownType = '';
    this.userDropDownType = '';
    return ;
  }

  updateStoryAttribute($event, attribute, value, successMessage, dropDownType) {
    $event.preventDefault();
    $event.stopPropagation();
  
    if (this.story[attribute] === value) {
      return this.blockSameDropDownRequest();
    }
    this.projectService.dropDownType = '';
    this.story[attribute] = value;
    this.updateStory(this.story, successMessage, dropDownType);
  }

  changeStoryPriority($event, priority) {
    this.updateStoryAttribute($event, 'priority', priority, 'priority updated', 'priority');
  }
  
  changeStoryStudioRole($event, role) {
    this.updateStoryAttribute($event, 'studio_role_id', role, 'studio role updated', 'role');
  }

  setUser($event,user, type) {
    $event.preventDefault();
    $event.stopPropagation();
    if((type == 'Owner' && this.story.owned_by_id == user.id) || (type == 'Reviewer' && this.story.reviewer_id == user.id)) {
      this.searchUser = '';
      return this.blockSameDropDownRequest();
    }
    this.userDropDownType = '';
    this.searchUser = '';

    if (type == 'Owner') {
      this.setOwnedByID(user);
    } else if (type == 'Reviewer') {
      this.setReviewerByID(user);
    }
  }

  setReviewerByID(user) {
    this.story.reviewer_id = user.id;
    this.story.reviewer_name = user.name;
    this.story.reviewer_initials = user.initials;
    this.updateStory(this.story, 'reviewer updated', 'reviewer');
  }

  setOwnedByID(user) {
    this.story.owned_by_id = user.id;
    this.story.owned_by_name = user.name;
    this.story.owned_by_initials = user.initials;
    this.updateStory(this.story, 'owner updated', 'owner');
  }

  addStoryToEpic($event, epic: Epic, story: Story) {
    $event.preventDefault();
    $event.stopPropagation();
    this.mincardUpdateLoader = true;
    if (this.disableFeatureChange(story,this.project)) {
      return;
    }
    if (!epic.hasStory(story)) {
      this.waitForLoader = true;
      const param = {
        'on_create': false
      };
      this.epicService.addStory(epic, story, param).subscribe(response => {
        this.mincardUpdateLoader = false;
        this.story.epics = [];
        this.story.epics.push(response.epic);
        this.projectService.dropDownType = '';
        this.searchEpic = '';
        this.trackStoryUpdateSegmentEvent('features updated', 'features');
        if (this.story && this.story.phase && this.story.phase.toLowerCase() === 'design') {
          this.projectService.show_StoryDetails(this.project, '', this.story.id, true);
        }
      },
    (error) => {
      this.mincardUpdateLoader = false;
      this.projectService.dropDownType = '';
      this.errorService.errorFunction(error);
    });
    }
  }

  showEpics() {
    if(this.project && this.story) {
      const epics: Array<Epic> = this.project.epics.filter(epic => this.story.epics.findIndex(ep => ep.id === epic.id) === -1);
      const searchText = this.searchEpic.toLowerCase();

      return epics.filter(epic => epic.title.toLowerCase().includes(searchText));
    }
  }

  preventDefaultEvent($event){
    $event.preventDefault();
    $event.stopPropagation();
  }

  openOwnerQuickTimeEstimate($event?) {
    if ($event) {
      this.preventDefaultEvent($event); 
    }
    if (!this.policyService.projectAdmin(this.project)) {
      return;
    }
    this.insertOwnerTime = true;
    setTimeout(() => {
      this.ownerTimeInput.nativeElement.focus();
    }, 400);
  }

  openReviewerQuickTimeEstimate($event? ) {
    if ($event) {
      this.preventDefaultEvent($event); 
    }
    if (!this.policyService.projectAdmin(this.project)) {
      return;
    }
    this.insertReviewerTime = true;
    setTimeout(() => {
      this.reviewerTimeInput.nativeElement.focus();
    }, 400);
  }

  closeReviewerQuickTimeEstimate($event) {
    this.preventDefaultEvent($event);
    this.insertReviewerTime = false;
    if (this.input_reviewer_estimate != this.story.reviewer_estimate) {
      this.updateEstimate('reviewer_estimate');
    } else{
      this.story_update_reason_option = '';
    }
  }

  closeOwnerQuickTimeEstimate($event) {
    this.preventDefaultEvent($event);
    this.insertOwnerTime = false;
    if (this.story && (this.input_estimate != this.story.estimate)) {
      this.updateEstimate('estimate');
    } else{
      this.story_update_reason_option = '';
    }
  }

  increaseOwnerEstimate(hour,$event) {
    this.preventDefaultEvent($event);
    if (!this.policyService.projectAdmin(this.project)) {
      return this.policyService.expertReadOnlyWarning();
    }

    this.input_estimate = Number(this.input_estimate) + Number(hour);
    if (this.input_estimate) {
      this.updateEstimate('estimate');
    }
  }

  increaseReviewerEstimate(hour,$event) {
    this.preventDefaultEvent($event);
    if (!this.policyService.projectAdmin(this.project)) {
      return this.policyService.expertReadOnlyWarning();
    }

    this.input_reviewer_estimate = Number(this.input_reviewer_estimate) + Number(hour);
    if (this.input_reviewer_estimate) {
      this.updateEstimate('reviewer_estimate');
    }
  }

  updateEstimate(key) {
    if (this.projectService.current_user_role === 'user') { return; }

    if (key === 'estimate') {
      this.input_estimate = parseInt(String(this.input_estimate), 10);
      if (isNaN(this.input_estimate) || this.input_estimate == 0) {
        this.input_estimate = this.story && this.story.estimate ? this.story.estimate : 0;
        return false;
      } else if (this.story && this.story.estimate && this.story.estimate == this.input_estimate) {
        this.story_update_reason_option = '';
        return false;
      }
    } else if (key === 'reviewer_estimate') {
      this.input_reviewer_estimate = parseInt(String(this.input_reviewer_estimate), 10);
      if (isNaN(this.input_reviewer_estimate) || this.input_reviewer_estimate == 0) {
        this.input_reviewer_estimate = this.story.reviewer_estimate ? this.story.reviewer_estimate : 0;
        return false;
      } else if (this.story.reviewer_estimate && this.story.reviewer_estimate == this.input_reviewer_estimate) {
        this.story_update_reason_option = '';
        return false;
      }
    }

    this.showEstimatedWarn(key);
    if (key === 'estimate') {
      this.story.estimate = this.input_estimate;
      this.updateStory(this.story, 'owner estimate updated', 'owner_estimate');
    } else if (key === 'reviewer_estimate') {
      this.story.reviewer_estimate = this.input_reviewer_estimate;
      this.updateStory(this.story, 'reviewer estimate updated', 'reviewer_estimate');
    }
  }

  showEstimatedWarn(key) {
    if (this.input_estimate > 120 || this.input_reviewer_estimate > 120) {
      if (key == 'estimate') {
        this.input_estimate = 120;
      } else if (key == 'reviewer_estimate') {
        this.input_reviewer_estimate = 120;
      }
      this.customToast.messages.push({
        id: 'serverfail',
        type: 'fail',
        class: 'generic_alert',
        title: 'Alert',
        message: 'Maximum estimate allowed is 120 hours, else break this story into parts.'
      });
    }
  }

  disableFeatureChange(story,project){
    return this.projectService.checkRestriction(story,project) || !((this.policyService.isQAExpert(this.project) && story.story_type === 'bug') || this.policyService.projectAdmin(project)) || !this.projectService.checkAddedFeature(story) || story.parent_id || story.core || story.wireframe;
  }

  checkforTimeInput(story,type = 'owner') {
    if (!this.policyService.projectAdmin(this.project)) {
      return false;
    }
    if (type === 'reviewer') {
      return story.reviewer_estimate_changed || (story.reviewer_elapsed_time_total || story.current_reviewer_timer_activity);
    }
    return story.timer_elapsed_total || !(['unscheduled','unstarted'].indexOf(story.state) !== -1);
  }

  askReasonForTimeInput(input_for,$event) {
    this.preventDefaultEvent($event);
    this.renderer.addClass(document.body, 'epicPopupView');
    this.input_for = input_for;
    this.openTimerReasonsPopup = true;
  }

  openStoryUpdateReasonsLists() {
    this.showStoryupdateReasonList = !this.showStoryupdateReasonList;
  } 

  selectStoryupdateReason(update_reason) {
    this.story_update_reason_option = update_reason.reason;
    this.showStoryupdateReasonList = false;
  }

  updateReason(action_type,$event) {
      this.preventDefaultEvent($event);
      action_type === 'proceed' ? this.submitStoryTimeUpdateReason() : this.closeTimerReason();
  }


  submitStoryTimeUpdateReason() {
    this.story.reason_for_update = this.story_update_reason_option;
    this.showStoryupdateReasonList = false;
    this.openTimerReasonsPopup = false;
    this.renderer.removeClass(document.body, 'epicPopupView');
    if(this.input_for === 'owner_time') {
      this.openOwnerQuickTimeEstimate();
    } else{
      this.openReviewerQuickTimeEstimate();
    }
  }

  closeTimerReason() {
    this.renderer.removeClass(document.body, 'epicPopupView');
    this.showStoryupdateReasonList = false;
    this.openTimerReasonsPopup = false;
    this.story_update_reason_option = '';
  }

}
