<div class="col-sm-3">
  <label>Start time</label>
  <div class="input-group">
    <input type="text"
            readonly
            class="form-control"
            name="dateFrom"
            [disabled]="isPending"
            [ngModel]="dateFrom"
            angular-mydatepicker
            #datePickerFrom="angular-mydatepicker"
            [options]="datePickerOptions"
            (dateChanged)="onDateFromChanged($event)"
            (focus)="datePickerFrom.openCalendar()">
    <div class="input-group-btn">
      <button class="cal-btnBox"
              [disabled]="isPending"
              (click)="datePickerFrom.toggleCalendar()">
        <em class="iconb-calendar"></em>
      </button>
    </div>
  </div>
</div>
<div class="col-sm-3">
  <label>End time</label>
  <div class="input-group">
    <input type="text"
           readonly
           class="form-control"
           name="dateTo"
           [disabled]="isPending"
           [ngModel]="dateTo"
           angular-mydatepicker
           #datePickerTo="angular-mydatepicker"
           [options]="datePickerOptions"
           (dateChanged)="onDateToChanged($event)"
           (focus)="datePickerTo.openCalendar()">
    <div class="input-group-btn">
      <button class="cal-btnBox"
              [disabled]="isPending"
              (click)="datePickerTo.toggleCalendar()">
        <em class="iconb-calendar"></em>
      </button>
    </div>
  </div>
</div>
