import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import moment from 'moment';
import * as _ from 'lodash';
// import * as Highcharts from 'highcharts';


import { Project  } from '../shared/models/project';
import { PolicyService } from '../shared/services/policy.service';
import { ProjectService } from '../shared/services/project.service';
import { ErrorService } from '../shared/errorFunction';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { SharedService } from '../shared/services/shared.service';
import {EVENT_TYPES} from '../shared/dataTypes';
import {NotificationService} from '../shared/services/notification.service';

@Component({
  selector: 'dashboard',
  templateUrl: 'dashboard.component.html',
  styleUrls: ['dashboard.component.scss'],
  host: {
    '(document:click)': 'onClick($event)'
    }
})
export class DashboardComponent implements OnInit {
  projects: Array<Project> = [];
  /*activities: Array<any>;*/
  startLoader: boolean = false;
  /*toLoadActivities: boolean = false;*/
  meta = {
    total_count: 0,
    current_count: 0
  };
  pages: Array<number>;
  projectSubscription: Subscription;
  params = {
    q: '',
    page: 1,
    timezone: ''
  };

  // Highcharts: typeof Highcharts = Highcharts;
  // chartOptions: Highcharts.Options = {
  //   title: {
  //     text: null
  //   },
  //   yAxis: {
  //     visible: false
  //   },
  //   xAxis: {
  //     visible: false
  //   },
  //   series: [{
  //     showInLegend: false,
  //     type: 'line'
  //   }]
  // };
  showQuickAct:boolean = false;
  quickIndex;

  constructor(
    private projectService: ProjectService,
    public sharedService: SharedService,
    private router: Router,
    private policyService: PolicyService,
    private errorService: ErrorService,
    private notificationService: NotificationService,
    private domSanitizer: DomSanitizer) {

  }

  ngOnInit() {
    this.startLoader = true;
    /*this.toLoadActivities = true;*/
    this.projectService.isFormMail = false;
    /*if (this.projectService.all_projects) {
      this.projects = this.projectService.all_projects;
      this.startLoader = false;
    }*/
    /*this.loadProjects();*/
    this.getProjects();
  }

  getProjects(blank?) {
    this.params.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if(this.projectSubscription) {
       this.projectSubscription.unsubscribe();
    }
    this.projectSubscription = this.projectService.getProjects(this.params).
    subscribe(res => {
      this.meta = res.meta;
      this.projectService.meta = this.meta;
      if(blank) {
        this.projects = [];
      }
      res.projects.forEach(data => this.projects.push(data.project));
      this.startLoader = false;
      // this.projects.forEach((project => {
      //   let points = [];
      //   project.graph_details.forEach((detail) => {
      //     points.push(detail.hours_accepted);
      //   })
      //   project.graph_options = {
      //     title: {
      //       text: null
      //     },
      //     credits: {
      //       enabled: false
      //     },
      //     tooltip: {
      //       useHTML: true,
      //       formatter: function () {
      //         let tooltipHtml = '<span class="cToolTip"><span class="cToolTip-top">' + project.graph_details[this.x].date_range + '</span>';
      //         tooltipHtml += '<span class="cToolTip-storiesCreated">' + 'Stories Created: ' + project.graph_details[this.x].stories_created + '</span>';
      //         tooltipHtml += '<span class="cToolTip-storiesAccepted">' + 'Stories Accepted: ' + project.graph_details[this.x].stories_accepted + '</span>';
      //         tooltipHtml += '<span class="cToolTip-hoursAccepted">' + 'Hours Accepted: ' + project.graph_details[this.x].hours_accepted + '</span></span>';
      //         return tooltipHtml;
      //       },
      //       shared: true,
      //     },
      //     yAxis: {
      //       visible: false,
      //       min: (Math.max(...points) == 0 ? 0 : -1)
      //     },
      //     xAxis: {
      //       visible: false
      //     },
      //     series: [{
      //       showInLegend: false,
      //       type: 'line',
      //       data: points
      //     }]
      //   };
      // }))
    },
  (error) => {
    this.startLoader = false;
    this.errorService.errorFunction(error);
  });
  }

  getSearchProjects() {
    this.params.page = 1;
    this.getProjects(true);
  }

  showMore() {
    this.params.page++;
    this.getProjects();
  }

  get policiesActive(): boolean {
    return this.policyService.policiesActive;
  }
  get isAdmin(): boolean {
    return this.policyService.isAdmin;
  }

  /*loadActivities() {
    this.projectService.projectActivities()
      .subscribe(
        (activities) => {
          this.activities = _.reverse(activities);
          if (this.toLoadActivities) {
          }
          this.toLoadActivities = false;
        },
        this.errorService.errorFunction
      );
  }*/

  /*loadProjects() {
    this.projectService.getProjectsList().subscribe((data) => {
      if (data && data.projects) {
        this.projects = data.projects;
        this.startLoader = false;
      }
    });
    if (this.toLoadActivities) {
      this.loadActivities();
    }
  }*/

  /*loadProjects() {
    this.projectService.projects
      .subscribe(
        projects => {
          this.projects = projects;
          if(this.toLoadActivities) {
            this.loadActivities();
          }
        },
        this.errorService.errorFunction
      );
  }*/

  canUpdate(project: Project) {
    return this.policyService.canUpdateProject(project);
  }

  headerDateFor(date) {
    return moment(date).format('MMM Do');
  }

  headerTimeFor(time) {
    return moment(time).format('hh:mm');
  }

  bypassSanitization(html: string) {
    return this.domSanitizer.bypassSecurityTrustHtml(html);
  }

  toggle_favourite(project) {
    project.favorite = !project.favorite;
    if (project.favorite) {
      this.projectService.addToFavorite(project.id).subscribe((res) => {
      }, error => {
        project.favorite = !project.favorite;
        this.errorService.errorFunction(error);
      });
    } else {
      this.projectService.deleteFromFavorite(project.id)
        .subscribe((res) => {
        }, error => {
          project.favorite = !project.favorite;
          this.errorService.errorFunction(error);
        });
    }
  }

  isProjectAdmin(project): boolean {
    return this.policyService.isProjectAdmin(project);
  }

  openColumn(column_name, project) {
    if(column_name === 'bug') {
        project.bug_click = true;
    } else if(column_name === 'chore') {
        project.chore_click = true;
    } else if(column_name === 'side_effect') {
        project.sideeffect_click = true;
    }
    this.router.navigate(['/projects/' + project.id], { queryParams: {type: column_name}});
  }
  projectQuickActionClose() {
    this.showQuickAct = false;
  }

  projectQuickActionOpen(index) {
    this.showQuickAct = !this.showQuickAct;
    if( this.showQuickAct) {
      this.quickIndex = index;
    }
  }

  onClick(event) {
    const element = event.target;
    if (!(element.classList.contains('iconb-more'))) {
      this.quickIndex = null;
      this.showQuickAct = false;
    }
  }

  fetchStatsData(project, index) {
    if (!project?.hasProjectStats) {
      this.projectService.getProjectStatsData(project.id).subscribe((res) => {
        this.projects[index] = { ...project, ...res.project, hasProjectStats: true }
      }, err => this.errorService.errorFunction(err))
    }
  }

  userTrackBy(index, project) {
    return project.id;
  }

  isScrollFetchAvailable(): boolean {
    return this.meta && (this.meta.total_count > (this.meta.current_count * this.params.page));
  }
}
