import moment from 'moment';
import * as _ from 'lodash';
import { includes } from 'lodash';
import {
  StoryData,
  TimerActivityData,
  DataManipulationOptions,
  EVENT_TYPES,
  STORY_STATE,
  STORY_STATE_ACTIONS,
  START_STORY_CONFIRM_OPTIONS,
  STORY_TYPE, PROJECT_COLUMN, NOT_APPLIED_REVIEWER, PROJECT_COLUMN_DATA, PlatformData, DevicePlatformData, BB_STATUS, STORY_PRIORITY_LEVEL, CriteriaData
} from '../dataTypes';
import { Note } from './note';
import { User } from './user';
import { Epic } from './epic';
import { GUID } from './guid';
import { Project } from './project';
import { Document } from './document';
import { BaseModel } from './baseModel';
import { AcceptanceCriteria } from './acceptanceCriteria';
import { getLocaleTimeFormat } from '@angular/common';

export class Story extends BaseModel implements StoryData {
  reviewer_estimate_changed?: boolean;
  title?: string;
  testCases?:Array<any> = [];
  test_cases?:Array<any> = [];
  title_copy?: string;
  st_platform_names?: string
  is_epic_added?:boolean;
  column_name?: string;
  epic_id?:any;
  sprint?:any;
  list_id?: number;
  attachments?: Array<any>;
  state_events?: any;
  active_blockers?: any;
  total_estimate?: number;
  progress?: number;
  showQuickAct?:boolean = false;
  showDropDown?: boolean = false;
  isQuickActionPerformed?: boolean;
  parent_id?: number;
  accepted_at?: Date;
  owned_by_change?: boolean;
  state_change?: boolean;
  type_change?: boolean;
  created_at?: Date;
  updated_at?: Date;
  description?: string;
  project_id?: number;
  story_type?: string;
  // severity_icon?: string;
  story_type_icon?: string;
  priority?: string;
  story_priority_icon?: string;
  states?: string;
  // severity?: string;
  owned_by_name?: string;
  owned_by_id?: number;
  owned_by_initials?: string;
  owner_email?: string;
  reviewer_email?: string;
  reviewer_id?: number;
  reviewer_name?: string;
  reviewer_initials?: string;
  reviewer_estimate?: number;
  phase?: string;
  task?: string;
  task_execution?: string;
  requested_by_id?: number;
  requested_by_name?: string;
  requested_by_email?: string;
  requested_by_initials?: string;
  estimate?: number;
  state?: string;
  prev_state?: string;
  position?: number;
  id?: number;
  done_tasks_count?: number;
  total_tasks_count?: number;
  total_tasks_and_test_case?: number;
  timer_elapsed_total?: number;
  reviewer_elapsed_time_total?: number;
  current_timer_activity?: TimerActivityData;
  current_reviewer_timer_activity?: TimerActivityData;
  labels?: Array<any> = [];
  project?: Project;
  notes?: Array<any> = [];
  notes_count?: number;
  blockers?: Array<any> = [];
  merge_requests?: Array<any> = [];
  commits?: Array<any> = [];
  commits_count?: number;
  blocked_stories?: Array<any> = [];
  blockers_count?: number;
  epics: Array<Epic> = [];
  isReadOnly = false;
  isSelected = false;
  aha_reference_num?: number;
  aha_url?: string;
  sub_tasks?: Array<any> = [];
  task_format?: any;
  time_completed?: number;
  sketch_status?: string;
  design_status?: string;
  story_acceptable?: boolean;
  deleted_at?: any;
  platform_ids?: Array<number>;
  formatted_platforms?: Array<any>;
  selectedDevicePlatform?: string;
  core?: boolean;
  parent_core?: boolean;
  dev_type?: string;
  isQACore?: boolean;
  hasStoryPlatforms?: boolean;
  inactive?: boolean;
  storyDevicePlatformsData?: Array<DevicePlatformData>;
  wireframe?: boolean;
  client_name?: string;
  created_from?: string;
  // match_found?:boolean;
  matching_story?: {id: number, title: string, catalog_story_id: number, description: string, difference: string, similarity: string};
  
  showPriority:boolean;
  showOwnerList:boolean;
  showReviewerList:boolean;
  showMoreStoryAction:boolean;
  showStoryTitleInput:boolean;
  studio_role_id?: number;
  show_copy_tooltip?:boolean;
  bug_reason_id?: number;
  bug_reason_detail_id?: number;
  previous?: {id?: number, title?: string};
  next?: {id?: number, title?: string};
  request_for_review_count?: number;
  reviewed_enabled_at?: boolean;
  sync_available?: boolean;
  catalog_story_id?: number;
  bug_related_to_story?: {id?: number, title?: string};
  related_to_story_id?: number;
  reason_for_creation?: string;
  show_reason_dd?: boolean;
  story_create_reason_for_bulk_duplicate?: string;
  show_other_reason_input?: boolean;
  bulk_story_selected_reason?: string;
  other_reason_input?: string;
  confirmed_by_id?: number;
  confirmed?: boolean;
  ready_for_certification?:boolean;
  requested_deleted_at?: Date;
  reason_for_update?: string;
  pre_conditions?: string;
  post_conditions?: string;
  test_steps?: string;
  test_data?: string;
  expected_results?: Array<any> = [];
  acceptance_criteria?:  Array<any>;
  show_create_criteria: boolean;
  description_as_a?: string;
  description_i_want?: string;
  description_so_that?: string;
  old_story_as_a?: string;
  old_story_i_want?: string;
  old_story_so_that?: string; 
  description_format_edited?: boolean;
  status?: string;
  role_name?: string;
  criteria_creation_inprogress?: boolean;
  test_case_id?: number;
  test_cases_count?: number;
  dev_release_at?: any;
  dev_release_at_date?: any;
  dev_release_at_cal?: boolean;
  related_stories?: Array<any>;
  sprint_id?: number;
  enable_ready_for_certification?: boolean;
  done_test_cases_count?: number;
  certification_doc_checked?: boolean;
  openDocValidationPopup?: boolean;
  show_ready_for_certification_cta?: boolean;
  detached_platform_ids?: Array<any>;
  platform_detach_story_option?: string;
  duplicate_sub_tasks?:boolean;
  duplicate_parent_only?:boolean;
  detached_platform_id?: number;
  url?: string;
  demo_video_url?: string;
  accepted_demo_video_url?: string;
  selected?: boolean;
  release_id?: number;

  static get defaults(): StoryData {
    return {
      notes: [],
      epics: [],
      state: STORY_STATE.unscheduled,
      story_type: STORY_TYPE.task,
      priority: 'P3 - Major',
      task_execution: 'custom',
      done_tasks_count: 0,
      total_tasks_count: 0,
      total_tasks_and_test_case: 0,
      notes_count: 0,
      commits_count: 0,
      blockers_count: 0
    } as StoryData;
  }

  static getStory(data: StoryData) { 
    const story = GUID.instance.findStoryByID(data.id);

    if (story) {
      return story;
    } else {
      return new Story(data);
    }
  }

  constructor(data: StoryData) {
    super(data);
    this.title = data.title;
    this.title_copy = this.title;
    this.column_name = data.column_name;
    if(data.attachments) {
      this.attachments = data.attachments;
    }
    if (data.commits) {
      this.commits = data.commits;
      this.commits_count = data.commits.length;
    } else {
      this.commits_count = data.commits_count;
    }
    if (data.test_cases) {
      this.test_cases = data.test_cases;
    }
    this.accepted_at = data.accepted_at;
    this.created_at = data.created_at;
    this.updated_at = data.updated_at;
    data.description ? this.description = data.description : this.description = '';
    this.progress = data.progress
    this.reviewer_id = data.reviewer_id;
    this.reviewer_name = data.reviewer_name;
    this.reviewer_initials = data.reviewer_initials;
    this.reviewer_email = data.reviewer_email;
    this.project_id = data.project_id;
    this.story_type = data.story_type;
    this.reviewer_estimate_changed = data.reviewer_estimate_changed;
    this.priority = data.priority;
    this.phase = data.phase;
    this.task = data.task;;
    this.task_execution = data.task_execution;
    this.requested_by_id = data.requested_by_id;
    this.owned_by_id = data.owned_by_id;
    this.owned_by_name = data.owned_by_name;
    this.owner_email = data.owner_email;
    this.owned_by_initials = data.owned_by_initials;
    this.requested_by_name = data.requested_by_name;
    this.requested_by_email = data.requested_by_email;
    this.requested_by_initials = data.requested_by_initials;
    this.test_case_id = data.test_case_id;
    this.test_cases_count = data.test_cases_count;
    // Estimates for owner and reviewer
    this.total_estimate = data.total_estimate;
    this.estimate = data.estimate ? data.estimate : 0;
    this.reviewer_estimate = data.reviewer_estimate ? data.reviewer_estimate : 0;
    this.aha_reference_num = data.aha_reference_num;
    this.aha_url = data.aha_url;
    this.state = data.state;
    this.prev_state = data.state;
    this.inactive = data.inactive;
    if (this.inactive) {
      this.position = 1000000;
    } else {
      this.position = parseFloat(String(data.position));
    }
    this.id = data.id;
    this.parent_id = data.parent_id;
    this.timer_elapsed_total = parseInt(String(data.timer_elapsed_total));
    if(data.reviewer_elapsed_time_total) {
      this.reviewer_elapsed_time_total = parseInt(String(data.reviewer_elapsed_time_total));
    } else{ 
      this.reviewer_elapsed_time_total = 0;
    }
    if(data.sprint) {
      this.sprint = data.sprint;
    }else{
      this.sprint = {};
    }
    this.current_timer_activity = data.current_timer_activity;
    this.current_reviewer_timer_activity = data.current_reviewer_timer_activity;
    this.project = data.project;
    this.blockers = data.blockers;
    if (data.merge_requests) {
      this.merge_requests = data.merge_requests;
    }
    if (data.expected_results) {
      this.expected_results = data.expected_results;
    }
    this.list_id = data.list_id;
    this.task_format = data.task_format;
    this.sketch_status = data.sketch_status;
    this.design_status = data.design_status;
    this.story_acceptable = data.story_acceptable;
    this.deleted_at = data.deleted_at;
    this.platform_ids = data.platform_ids;
    this.formatted_platforms = data.formatted_platforms;
    this.core = data.core;
    this.parent_core = data.parent_core;
    this.dev_type = data.dev_type;
    this.wireframe = data.wireframe;
    this.client_name = data.client_name;
    this.created_from = data.created_from;
    this.showPriority = false;
    this.showOwnerList = false;
    this.showReviewerList = false;
    this.showMoreStoryAction = false;
    this.showStoryTitleInput = false;
    this.studio_role_id = data.studio_role_id;
    this.show_copy_tooltip = false;
    this.bug_reason_id = data.bug_reason_id;
    this.bug_reason_detail_id = data.bug_reason_detail_id;
    this.previous = data.previous;
    this.next = data.next;
    this.pre_conditions = data.pre_conditions;
    this.post_conditions = data.post_conditions;
    this.test_data = data.test_data;
    this.test_steps = data.test_steps;
    // this.match_found = data.match_found;
    // this.matching_story = data.matching_story;
    this.role_name = data.role_name;
    this.isQACore = (data.dev_type && data.dev_type.toLowerCase() === 'qa' && data.core);
    this.refreshDevices(data);
    this.refreshTaskList(data);
    if (data.blockers) {
      this.activeBlockers();
      this.blockers_count = this.active_blockers.length;
    } else {
      this.blockers_count = data.blockers_count;
    }
    this.blocked_stories = data.blocked_stories;
    if (data.epics) {
      this.epics = [];
      data.epics.forEach(item => this.epics.push(item.epic ? item.epic : item));
    } else if (this.project instanceof Project) {
      this.refreshEpics();
    }
    this.refreshLabels(data);
    if (data.story_type) {
      this.storyTypeIcon_minCard(this.story_type, data.parent_id !== null);
    }
    if (data.priority){
      this.storyPriorityType_icon(this.priority);
    }
    if (data.state) {
      this.events();
    }
    this.setReadonly();
    if (data.notes) {
      this.notes_count = data.notes.length;
      this.notes.length = 0;
      const notes = data.notes.map(note => new Note(note.note, this));
      this.notes.push.apply(this.notes, notes);
    } else {
      this.notes_count = data.notes_count;
    };
    this.request_for_review_count = data.request_for_review_count;
    this.reviewed_enabled_at = data.reviewed_enabled_at;
    this.sync_available = data.sync_available;
    this.catalog_story_id = data.catalog_story_id;
    this.related_to_story_id = data.related_to_story_id;
    this.bug_related_to_story = data.bug_related_to_story;
    this.reason_for_creation = data.reason_for_creation;
    this.show_reason_dd = false;
    this.story_create_reason_for_bulk_duplicate = '';
    this.show_other_reason_input = false;
    this.bulk_story_selected_reason = '';
    this.other_reason_input = '';
    this.confirmed_by_id = data.confirmed_by_id;
    this.confirmed = null;
    this.ready_for_certification = data.ready_for_certification;
    this.requested_deleted_at = data.requested_deleted_at;
    this.reason_for_update = data.reason_for_update;

    if (data.acceptance_criteria) {
      this.acceptance_criteria = [];
      if (data.acceptance_criteria && data.acceptance_criteria.length > 0) {
        data.acceptance_criteria.forEach((item) => {
          this.acceptance_criteria.push(new AcceptanceCriteria(item));
        });
        this.show_create_criteria = false
      } else {
        this.show_create_criteria = true;
      }
    }

    if(data.description_as_a) {
      this.description_as_a = data.description_as_a;
      this.old_story_as_a = data.description_as_a;
    }else{
      this.description_as_a = ''
    }
    if(data.description_i_want) {
      this.description_i_want = data.description_i_want;
      this.old_story_i_want = data.description_i_want;
    }else{
      this.description_i_want = ''
    }
    if(data.description_so_that) {
      this.description_so_that = data.description_so_that;
      this.old_story_so_that = data.description_so_that;
    }else{
      this.description_so_that = ''
    }
    this.description_format_edited = false;
    this.status = data.status;
    this.criteria_creation_inprogress = false;
    this.setDevReleaseAt(data);
    this.dev_release_at_cal = false;
    this.sprint_id = data.sprint_id;
    this.related_stories = data.related_stories ? [...data.related_stories] : [];
    this.enable_ready_for_certification = data.enable_ready_for_certification;
    this.done_test_cases_count = data.done_test_cases_count;
    this.total_tasks_and_test_case = this.total_tasks_count + this.test_cases_count;
    this.certification_doc_checked = false;
    this.openDocValidationPopup = false;
    this.show_ready_for_certification_cta = data.show_ready_for_certification_cta;
    this.detached_platform_ids = data.detached_platform_ids ? [...data.detached_platform_ids] : [];
    this.platform_detach_story_option = '';
    this.duplicate_sub_tasks = false;
    this.duplicate_parent_only = false;
    this.detached_platform_id = null;
    this.url = data.url
    this.selected = false;
    this.release_id = data.release_id;
    this.demo_video_url = data.demo_video_url || '';
    this.accepted_demo_video_url = data.accepted_demo_video_url || '';
  }

  checkE2EStory() {
    return this.core && !this.parent_id && this.dev_type === 'E2E';
  }

  checkTestCasesStatus() {
    const no_of_test_cases_pending = this.test_cases_count - this.done_test_cases_count;
    return no_of_test_cases_pending;
  }

  checkPendingSubtask() {
    return this.total_tasks_count - this.done_tasks_count;
  }

  calcPendingTestCasesAndSubtasks () {
    const test_cases_pending = this.checkTestCasesStatus();
    return (test_cases_pending + this.checkPendingSubtask());
  }

  checkTestCaseMemberPermission(loggedInUser) {
    if(this.testCase()) {
      return this.project.isUserActive(loggedInUser) && this.story_type === 'test_case';
    } else{
      return true;
    }
  }

  subTask(): boolean {
    return this.parent_id && this.story_type !== 'test_case';
  }

  testCase(): boolean {
    return this.parent_id && this.story_type === 'test_case';
  }
 
  isTestCaseOrBug(){
    return this.story_type === 'test_case' || this.story_type === 'bug';
  }

  expectedResults(){
    return this.expected_results.filter(result => result.status === 'draft' || result.status === 'pass')
  }

  actualResults(){
    return this.expected_results.filter(result => result.status === 'actual' || result.status === 'pass').sort((a, b) => a.status.localeCompare(b.status))
  }

  updateExpectedResult(expresult){
    const index = this.expected_results.findIndex(result => result.id === expresult.changes.expected_result.id)
    if (index === -1 && (expresult.action === 'create')) {
      this.expected_results.unshift(expresult.changes.expected_result);
    } else if (index !== -1 && (expresult.action === 'destroy')) {
      this.expected_results.splice(index,1);
    } else {
      this.expected_results[index] = expresult.changes.expected_result;
    }
  }

  // Dev release always required into jsdate
  // Otherwise date picker will break on sprint detail for dev release calendar
  setDevReleaseAt(data) {
    if (data.dev_release_at) {
      this.dev_release_at = {isRange: false, singleDate:{ jsDate: new Date(data.dev_release_at) }}
    } else {
      this.dev_release_at = null;
    }
    this.dev_release_at_date = data.dev_release_at;
  }

  inDraft() {
   return this.status === 'draft' && this.project.external() && !this.testCase();
  }

  // Add the criteria on the story
  addCriteria(criteria: any,checkFlag: boolean = true) {
    this.acceptance_criteria = checkFlag ? this.acceptance_criteria.filter((criteria)=> criteria.id) : this.acceptance_criteria
    this.updateCriteria(criteria);
  }

  addCriteriaInBulk(criterias: any){
    this.acceptance_criteria = []
    this.acceptance_criteria =  criterias.map((val:any)=> new AcceptanceCriteria(val))
  }

  // Update the criteria on the story
  updateCriteria(criteria: any) {
    if (criteria.changes) {
      const existingCriteria = this.acceptance_criteria.find(ac => ac.id === criteria.id);
      if (existingCriteria) {
        for (let key in criteria.changes) {
          existingCriteria[key] = criteria.changes[key];
          if (existingCriteria['old_' + key]) {
            existingCriteria['old_' + key] = criteria.changes[key];
          }
        }
      }
    } else {
      const acceptance_criteria = new AcceptanceCriteria(criteria);
      const index = this.acceptance_criteria.findIndex(ac => ac.id === criteria.id);
      if (index === -1 || !criteria.id) {
        this.acceptance_criteria.unshift(acceptance_criteria);
      } else {
        this.acceptance_criteria.splice(index, 1, acceptance_criteria);
      }
    }
  }

  removeCriteria(criteria: any) {
    const index = this.acceptance_criteria.findIndex(ac => ac.id === criteria.id);
    if (index !== -1) {
      this.acceptance_criteria.splice(index, 1);
    }
  }

  removeCriteriaWithoutId(criteria: any) {
    const index = this.acceptance_criteria.findIndex(ac => ac.title === criteria.title);
    if (index !== -1) {
      this.acceptance_criteria.splice(index, 1);
    }
  }

  resetOldDescription() {
    this.description_as_a = this.old_story_as_a;
    this.description_i_want = this.old_story_i_want;
    this.description_so_that = this.old_story_so_that;
  }

  checkStoryDescriptionIsEdited():boolean {
    return this.description_as_a != this.old_story_as_a || this.description_i_want != this.old_story_i_want || this.description_so_that != this.old_story_so_that;
  }

  storyTypeIcon_minCard(story_type, subtask) {
    switch (story_type) {
      case STORY_TYPE.task:
        return this.story_type_icon = subtask ? 'iconb-subtask storyTypeIcon-minCard' : 'iconb-task storyTypeIcon-minCard';
      case STORY_TYPE.bug:
        return this.story_type_icon = 'iconb-bug storyTypeIcon-minCard';
      case STORY_TYPE.rework:
        return this.story_type_icon = 'iconb-rework storyTypeIcon-minCard';
      default:
        return this.story_type_icon = null;
    }
  }
  storyPriorityType_icon(priority_level) {
    switch (priority_level) {
      case STORY_PRIORITY_LEVEL.blocker:
        return 'iconb-restrict';
      case STORY_PRIORITY_LEVEL.critical:
        return 'iconb-thick-arrow-up';
      case STORY_PRIORITY_LEVEL.major:
        return 'iconb-doublechevron-up';
      case STORY_PRIORITY_LEVEL.minor:
        return 'iconb-doublechevron-down';
      case STORY_PRIORITY_LEVEL.trivial:
        return 'iconb-circle-withdot';
      default:
        this.story_priority_icon = null;
        return;
    }
  }

  public refreshEpics() {
    if (this.project) {
      const storyEpic = this.project.epics.filter(epic => epic.hasStory(this));
      if (storyEpic.length > 0) {
        this.epics = storyEpic;
      }
    }
  }

  public refreshDevices(data: any) {
    this.setSelectedDevicePlatform();
    this.hasStoryPlatforms = this.setHasStoryPlatforms();
    if (data.formatted_platforms) {
      this.setDevicePlatformsData(data.formatted_platforms);
    } else {
      this.storyDevicePlatformsData = [];
    }
  }

  public refreshLabels(data: any) {
    if (data.labels) {
      if (typeof data.labels === 'string') {
        this.labelObjects = data.labels.split(',');
      } else {
        this.labelObjects = data.labels;
      }
    } else {
      this.labelObjects = [];
    }
  }

  public bugReason() {
    if (this.bug_reason_id) {
      return this.project.bug_reasons.filter(b => b.id === this.bug_reason_id)[0].title;
    } else {
      return 'Select a Reason';
    }
  }

  public bugDetailReason() {
    if (this.bug_reason_detail_id) {
      return this.project.bug_reasons.filter(b => b.id === this.bug_reason_detail_id)[0].title;
    } else {
      return 'Select a Reason';
    }
  }

  findSubStoryByID(storyId: number) {
    return this.sub_tasks.filter(s => s.id === storyId)[0];
  }
      
  // Refresh the sub tasks list 
  public refreshTaskList(data) {
    if (data.sub_tasks) {
      this.sub_tasks = data.sub_tasks;
      this.total_tasks_count = data.sub_tasks.length;
      this.done_tasks_count = data.sub_tasks.filter((task) => task.state === 'accepted').length;
    } else {
      this.done_tasks_count = data.done_tasks_count;
      this.total_tasks_count = data.total_tasks_count;
    }
  }

  static createDefaultStory(project: Project, creator: User) {
    const data: StoryData = Story.defaults;
    data.position = project.getLatestStoryPosition() + 1;
    data.project_id = project.id;
    data.project = project;
    data.requested_by_id = creator.id;
    data.requested_by_name = creator.name;
    data.requested_by_email = creator.email;
    data.requested_by_initials = creator.initials;
    const defaultStory = new Story(data);
    defaultStory['created_from'] = 'delivery';
    return defaultStory;
  }

  setReadonly() {
    this.isReadOnly = (this.state === STORY_STATE.accepted && this.accepted_at !== undefined);
  }

  events() {
    switch (this.state) {
      case 'started':
        return this.state_events = ['finish'];
      case 'finished':
        return this.state_events = ['deliver'];
      case 'delivered':
        return this.state_events = ['accept', 'reject'];
      case 'rejected':
        return this.state_events = ['restart'];
      case 'accepted':
        return this.state_events = [];
      default:
        return this.state_events = ['start'];
    }
  }

  start() {
    this.state = 'started';
  }

  finish() {
    this.state = 'finished';
  }

  deliver() {
    this.state = 'delivered';
  }

  accept() {
    this.state = 'accepted';
  }

  reject() {
    this.state = 'rejected';
  }

  restart() {
    this.state = 'started';
  }

  storyIcon() {
    switch (this.story_type) {
      case STORY_TYPE.task:
        return 'task';
      case STORY_TYPE.bug:
        return 'bug';
      case STORY_TYPE.rework:
        return 'rework';
      default:
        return null;
    }
  }

  storyIconStyle() {
    switch (this.story_type) {
      case STORY_TYPE.task:
        return 'task';
      case STORY_TYPE.bug:
        return 'bug';
      case STORY_TYPE.rework:
        return 'rework';
      default:
        return null;
    }
  }

  storyTypeName() {
    switch (this.story_type) {
      case STORY_TYPE.task:
        return 'Story';
      case STORY_TYPE.bug:
        return 'Bug';
      case STORY_TYPE.rework:
        return 'Rework';
      default:
        return null;
    }
  }

  storyPhaseIcon(phase) {
    switch (phase) {
      case 'Product Roadmap':
        return 'product-roadmap';
      case 'Design':
        return 'design';
      case 'Prototype':
        return 'prototype';
      case 'MVP':
        return 'mvp';
      case 'Full Build':
        return 'full-build';
      case 'Full Build - Front end':
        return 'full-build-frontend';
      case 'Full Build - Back end':
        return 'full-build-backend';
      case 'Full Build- QA':
        return 'full-build-qa';
      default:
        return 'custom-phase';
    }
  }

  get createdAt() {
    return moment(this.created_at).format('LLL');
  }

  set labelObjects(values) {
    this.labels = values.map(data => {
      return {
        value: data,
        display: data
      };
    });
  }

  // Add the note on the story
  addNote(note: Note) {
    note.story = this;
    this.updateNote(note);
  }

  // Update the note on the story
  updateNote(note: Note) {
    // Do not add attachment to notes
    if (note.note_type !== 'attachment') {
      const index = this.notes.findIndex(n => n.id === note.id);
      if (index === -1) {
        this.notes.push(note);
      } else {
        this.notes.splice(index, 1, note);
      }
    }

    if (note.documents) {
      // Remove document into attachments if already present
      const documentIds = note.documents.map(a => a['document']['id']);
      if (note.documents.length < this.attachments.filter(d => d.note_id === note.id).length) {
        this.attachments = this.attachments.filter((d) => {
          return d.note_id !== note.id || _.includes(documentIds, d.id)
        });
      }

      // Add document into attachments
      if (note.documents.length > 0) {
        note.documents.forEach((document, index) => {
          let newDoc = document['document'];
          newDoc['note_id'] = note.id;
          newDoc['user_id'] = note.user_id;
          newDoc['user_email'] = note.user_email;
          newDoc['user_name'] = note.user_name;
          newDoc['user_initials'] = note.user_initials;

          const aIndex = this.attachments.findIndex(d => d.id === newDoc.id);
          if (aIndex === -1) {
            this.attachments.push(newDoc);
          } else {
            this.attachments.splice(aIndex, 1, newDoc);
          }
        });
      }
    }
  }

  // Remove note from story
  removeNote(note: Note) {
    const index = this.notes.findIndex(n => n.id === note.id);
    if (index !== -1) {
      this.notes.splice(index, 1);
    }

    // Remove document from attachments
    this.attachments = this.attachments.filter(d => d.note_id !== note.id);
  }

  // Remove note document from story
  removeNoteDoc(note: Note, document: Document) {
    this.notes.forEach((candidate, index) => {
      if (candidate == note) {
        const  noteIndex = this.notes[index].documents.findIndex(doc => doc == document);
        if (noteIndex !== -1) {
          this.notes[index].documents.splice(noteIndex, 1);
        }
      }
    });
  }

  // Add the commit on the story
  addCommit(commit: any) {
    this.updateCommit(commit);
  }

  // Update the commit on the story
  updateCommit(commit: any) {
    if (commit.url.includes('merge_requests')) {
      const index = this.merge_requests.findIndex(c => c.commit.id === commit.id);
      if (index === -1) {
        this.merge_requests.push({ commit: commit });
      } else {
        this.merge_requests.splice(index, 1, { commit: commit });
      }
    } else {
      const index = this.commits.findIndex(c => c.id === commit.id);
      if (index === -1) {
        this.commits.push(commit);
      } else {
        this.commits.splice(index, 1, commit);
      }
      this.commits_count = this.commits.length;
    }
  }

  // Remove the commit from story
  removeCommit(commit: any) {
    if (commit.url.includes('merge_requests')) {
      const index = this.merge_requests.findIndex(c => c.commit.id === commit.id);
      if (index !== -1) {
        this.merge_requests.splice(index, 1);
      }
    } else {
      const index = this.commits.findIndex(c => c.id === commit.id);
      if (index !== -1) {
        this.commits.splice(index, 1);
      }
      this.commits_count = this.commits.length;
    }
  }

  stateActionChanged(stateAction: string, currentUser: User) {
    const stateOption = STORY_STATE_ACTIONS.find(option => option.action === stateAction);

    this.state = stateOption.state;

    const options: DataManipulationOptions = {
      currentUser: currentUser
    };
    this.stateChanged(options);
  }

  stateChanged(options: DataManipulationOptions) {
    if (options.currentUser &&
        this.state === STORY_STATE.started &&
        !this.owned_by_id) {
      this.owned_by_id = options.currentUser && options.currentUser.id ? options.currentUser.id : undefined;
      this.owned_by_name = options.currentUser && options.currentUser.name ? options.currentUser.name : '';
      this.owned_by_initials = options.currentUser && options.currentUser.initials ? options.currentUser.initials : '';
    }

    if (this.state === STORY_STATE.accepted && !this.accepted_at) {
      const today = new Date();
      today.setHours(0);
      today.setMinutes(0);
      today.setSeconds(0);
      today.setMilliseconds(0);
      this.accepted_at = today;
    }
  }

  hasEpic(epic: Epic) {
    const index = this.epics.findIndex(candidate => candidate.id === epic.id);

    return index !== -1;
  }

  addEpic(epic: Epic) {
    if (!this.hasEpic(epic)) {
      this.epics.push(epic);
    }
  }

  removeEpic(epic: Epic) {
    const index = this.epics.findIndex(candidate => candidate === epic);

    if (index !== -1) {
      this.epics.splice(index, 1);
    }
  }

  toJSON(): StoryData {
    return {
      title: this.title,
      epic_id: this.epic_id,
      accepted_at: this.accepted_at,
      list_id: this.list_id,
      isSelected: this.isSelected,
      parent_id: this.parent_id,
      done_tasks_count: this.done_tasks_count,
      total_tasks_count: this.total_tasks_count,
      created_at: this.created_at,
      updated_at: this.updated_at,
      description: this.description,
      project_id: this.project_id,
      story_type: this.story_type,
      priority: this.priority,
      // severity: this.severity,
      owned_by_name: this.owned_by_name,
      owned_by_id: this.owned_by_id,
      owner_email: this.owner_email,
      phase: this.phase,
      task: this.task,
      task_execution: this.task_execution,
      owned_by_initials: this.owned_by_initials,
      reviewer_id: this.reviewer_id,
      reviewer_name: this.reviewer_name,
      reviewer_initials: this.reviewer_initials,
      reviewer_email: this.reviewer_email,
      requested_by_id: this.requested_by_id,
      reviewer_estimate: this.reviewer_estimate,
      requested_by_name: this.requested_by_name,
      requested_by_initials: this.requested_by_initials,
      estimate: this.estimate,
      state: this.state,
      progress: this.progress,
      aha_reference_num: this.aha_reference_num,
      aha_url: this.aha_url,
      position: this.position,
      sketch_status: this.sketch_status,
      design_status: this.design_status,
      id: this.id,
      deleted_at: this.deleted_at,
      platform_ids: this.platform_ids,
      studio_role_id: this.studio_role_id,
      bug_reason_id: this.bug_reason_id,
      bug_reason_detail_id: this.bug_reason_detail_id,
      // labels: this.labels.map(label => label.value).join(','),
      related_to_story_id: this.related_to_story_id,
      reason_for_creation: this.reason_for_creation,
      confirmed: this.confirmed,
      reason_for_update: this.reason_for_update,
      description_as_a: this.description_as_a,
      description_i_want: this.description_i_want,
      description_so_that: this.description_so_that,
      ready_for_certification: this.ready_for_certification,
      dev_release_at: this.dev_release_at,
      dev_type: this.dev_type,
      sprint_id: this.sprint_id ? this.sprint_id : null,
      created_from: this.created_from,
    };
  }

  toJSONWithoutDescription(): StoryData {
    return {
      title: this.title,
      epic_id: this.epic_id,
      accepted_at: this.accepted_at,
      list_id: this.list_id,
      isSelected: this.isSelected,
      parent_id: this.parent_id,
      done_tasks_count: this.done_tasks_count,
      total_tasks_count: this.total_tasks_count,
      created_at: this.created_at,
      updated_at: this.updated_at,
      description: this.description,
      project_id: this.project_id,
      story_type: this.story_type,
      priority: this.priority,
      // severity: this.severity,
      owned_by_name: this.owned_by_name,
      owned_by_id: this.owned_by_id,
      owner_email: this.owner_email,
      phase: this.phase,
      task: this.task,
      task_execution: this.task_execution,
      owned_by_initials: this.owned_by_initials,
      reviewer_id: this.reviewer_id,
      reviewer_name: this.reviewer_name,
      reviewer_initials: this.reviewer_initials,
      reviewer_email: this.reviewer_email,
      requested_by_id: this.requested_by_id,
      reviewer_estimate: this.reviewer_estimate,
      requested_by_name: this.requested_by_name,
      requested_by_initials: this.requested_by_initials,
      estimate: this.estimate,
      state: this.state,
      progress: this.progress,
      aha_reference_num: this.aha_reference_num,
      aha_url: this.aha_url,
      position: this.position,
      sketch_status: this.sketch_status,
      design_status: this.design_status,
      id: this.id,
      deleted_at: this.deleted_at,
      platform_ids: this.platform_ids,
      studio_role_id: this.studio_role_id,
      bug_reason_id: this.bug_reason_id,
      bug_reason_detail_id: this.bug_reason_detail_id,
      // labels: this.labels.map(label => label.value).join(','),
      related_to_story_id: this.related_to_story_id,
      reason_for_creation: this.reason_for_creation,
      confirmed: this.confirmed,
      reason_for_update: this.reason_for_update,
      ready_for_certification: this.ready_for_certification,
      dev_release_at: this.dev_release_at,
      dev_type: this.dev_type,
      sprint_id: this.sprint_id ? this.sprint_id : null,
      created_from: this.created_from,
    };
  }

  toJSONforPlatformDetach() {
    return {
      platform_ids: this.platform_ids,
    };
  }

  toJSONForSprint(): StoryData {
    return {
      id: this.id,
      title: this.title,
      priority: this.priority,
      owned_by_id: this.owned_by_id,
      reviewer_id: this.reviewer_id,
      dev_release_at: this.dev_release_at,
      deleted_at: this.deleted_at,
    };
  }

  toJSONforLabels(): StoryData {
    return {
      labels: this.labels.map(label => label.value).join(',')
    };
  }

  getClassName() {
    return 'Story';
  }

  activeBlockers() {
    if (this.blockers) {
      return this.active_blockers = this.blockers.filter((blocker) => !blocker.blocker.done);
    }
    return this.active_blockers =  [];
  }

  updateBlockers(data: StoryData) {
    this.blockers = data.blockers;
    this.activeBlockers();
  }

  storyOrTask() {
    const a = this.parent_id ? 'Task' : 'Story';
    return a;
  }

  storyColumnName(list_id) {
    if (list_id) {
      const res = PROJECT_COLUMN_DATA.filter(col => col && list_id === col.list_id);
      return res && res.length > 0 && res[0].list_name ? res[0].list_name : '';
    }
    return '';
  }

  acceptRejectCondition() {
   return !this.phase ? true : ((NOT_APPLIED_REVIEWER.indexOf(this.phase.trim()) == -1) && !(this.reviewer_estimate && this.reviewer_id))
  }

  storyQuickActionClose() {
    this.showQuickAct = false;
    this.showDropDown = false;
  }

  allSubTasksAccepted() {
    return this.sub_tasks.every((task) => task.state === 'accepted') && this.test_cases.every((task) => task.state === 'accepted');
  }

  isDesignStory(): boolean {
    return this.phase && (this.phase.toLowerCase() === 'design' ||
              this.phase.toLowerCase() === 'prototype' || this.phase.toLowerCase() === 'product roadmap');
  }

  getEpicInStory(): Epic {
    if (this.epics && this.epics.length > 0) {
      return this.epics[0];
    }
    return null;
  }

  setHasStoryPlatforms() {
    return this.project && this.project.dashboard_project_id && this.platform_ids && this.platform_ids.length > 0;
  }

  setDevicePlatformsData(formatted_platforms) {
    this.storyDevicePlatformsData = [];
    if (formatted_platforms) {
      for (const device in formatted_platforms) {
        const devicePlatformData: DevicePlatformData = { device: device, platforms: []};
        if (formatted_platforms[device] && formatted_platforms[device].length > 0) {
          formatted_platforms[device].forEach(platform => devicePlatformData.platforms.push(platform));
        }
        this.storyDevicePlatformsData.push(devicePlatformData);
      }
    }
  }

  setSelectedDevicePlatform() {
    if (this && this.formatted_platforms && Object.keys(this.formatted_platforms).length > 0) {
      let str = '';

      for (const device in this.formatted_platforms) {
        if (this.formatted_platforms[device].length > 0) {
          str = str + ' ' + device + ',';
          this.formatted_platforms[device].forEach(platform => {
            if (platform && platform.display_name)
              str = str + ' ' + platform.display_name + ',';
          });
        }
      }

      str = str.trim();
      this.selectedDevicePlatform = str.length > 0 && str[str.length - 1] === ',' ? str.substring(0, str.length - 1) : str;
    } else {
      this.selectedDevicePlatform = 'Select Device & Platform';
    }
  }

  allSubTaskDone() {
    return this.parent_id ? true : this.total_tasks_count === this.done_tasks_count;
  }

  subTaskAvailable() {
    return this.total_tasks_count > 0;
  }

  bbStatusAssignedOrDev() {
    let storyEpic = this.getEpicInStory();
    return storyEpic && (storyEpic.bb_status === BB_STATUS.assigned || storyEpic.bb_status === BB_STATUS.bb_under_development);
  }

  isUserConfirmedDocValidation() {
    return !this.certification_doc_checked && this.project.external() && this.state === 'finished' && this.bbStatusAssignedOrDev() && this.created_from !== 'delivery' && (['FE', 'BE'].indexOf(this.dev_type) !== -1);
  }

  storyAcceptableRejectable(current_user,accept) {
    // if story has any features
    // if story is in delivered state
    // if story not archived
    // if story has all sub tasks done
    let storyEpic = this.getEpicInStory();
    if (this.state === 'delivered' && this.status === 'ready' && !this.inactive && !this.deleted_at && storyEpic) {
      if (this.dev_type === 'QA' && (current_user.id === this.owned_by_id || current_user.id === this.reviewer_id)) {
        return accept ? this.allSubTaskDone() : true;
      }
      
      if (current_user.id === this.reviewer_id) {
        // if story bb_status is true
        if (this.project.external() && this.project.supports_bb && storyEpic.bb_status && !this.isCreatedFromDelivery(this.created_from)) {
          if ((this.dev_type === 'BE' || this.dev_type === 'FE') && !((this.core && storyEpic.bb_status === BB_STATUS.skipped) || !this.core)) {
            return false;
          }
        }

        if (!(this.phase === 'Design' || this.phase === 'Prototype' || this.phase === 'Product Roadmap')) {
          if ((!this.project.external() || this.dev_type === 'E2E' || this.commits_count > 0) && this.reviewer_id) {
            return accept ? this.allSubTaskDone() : true;
          } else {
            return false;
          }
        } else {
          return accept ? this.allSubTaskDone() : true;
        }
      } else{
        return false;
      }
    } else {
      return false;
    }
  }



  checkForMR() {
    if (this.project.external() && this.core && !this.isDesignStory() && this.created_from !== 'delivery' && (this.dev_type === "FE" || this.dev_type === "BE") && this.merge_requests.length === 0) {
      return this.bbStatusAssignedOrDev();
    }
    return false;
  }

  bugReasonTooltip() {
    if (!this.bug_reason_id && !this.related_to_story_id) {
      return 'Story status cannot be changed as no bug reason or no related to story is selected. Please select the bug reason and also add the task story# that caused this bug.';
    } else if (!this.bug_reason_id && this.related_to_story_id) {
      return 'Story status cannot be changed as no bug reason is selected. Please select the bug reason.';
    } else if (this.bug_reason_id && !this.related_to_story_id) {
      return 'Story status cannot be changed as no story is added that cause the bug. Please add the task story# that caused this bug.';
    }
  }

  getDevType(){
    if (this.dev_type === 'FE') {
      return 'Frontend';
    }else if(this.dev_type === 'BE'){
      return 'Backend';
    }else if(this.dev_type === 'E2E' && !this.parent_id){
      return 'E2E';
    }else if(this.dev_type === 'QA'){
      return this.dev_type;
    }else if(this.dev_type === 'Design' && this.core){
      return this.dev_type;
    }else return 'Select Skill Type';
  }

  checkStoryDevTypeRestriction() {
    const checkStoryState = ['delivered', 'accepted', 'rejected'];
    return (!this.phase || includes(checkStoryState, this.state) || this.dev_type === 'E2E'|| (this.core && this.created_from !== 'delivery'));
  }

  checkDevicePlatformRestriction() {
    const checkCreatedFrom = ['catalog', 'tracker'];
    const checkPhases = ['Design', 'Product Roadmap', 'Prototype'];
    return this.core && !this.checkPlatfromCanDetach() && includes(checkCreatedFrom, this.created_from);
  }

  isCreatedFromDelivery(created_from) {
    return created_from !== 'delivery';
  }

  disableCreateSubtask(create_SubTask) {
    return this.state === 'accepted' || this.deleted_at || create_SubTask || (this.story_type === 'bug' && !this.bug_reason_id);
  }

  studioRoleMap() {
    let roleIndex = this.project.studio_roles.findIndex(st_role_index => st_role_index.studio_role.id === this.studio_role_id);
    return roleIndex !== -1 ? this.project.studio_roles[roleIndex].studio_role.name : '';
  }

  checkPlatfromCanDetach() {
    return this.designAndCore() && (this.platform_ids.length > 1);
  }

  isPlatformDetached(platform){
    return this.designAndCore() && includes(this.detached_platform_ids, platform.id);
  }

  isAllPlatformChecked(deviceData) {
    return (this.storyDevicePlatformsData && this.storyDevicePlatformsData.length > 0) && this.storyDevicePlatformsData[0].device === deviceData.device;
  }

  freezePlatform(deviceData) {
    return (this.storyDevicePlatformsData && this.storyDevicePlatformsData.length > 0) && this.designAndCore() && this.storyDevicePlatformsData[0].device !== deviceData.device;
  }

  designAndCore() {
    return this.phase === 'Design' && this.core && this.created_from !== 'delivery';
  }

  detachedPlatformData(platform_id: number) {
    const allPlatforms = Object.keys(this.formatted_platforms)
                                .map(key => this.formatted_platforms[key])
                                .reduce((acc, val) => acc.concat(val), []);
    const platform = allPlatforms.find(p => p.id === platform_id);
    return platform ? platform.display_name : null;
  }

  checkForPlatformMessage(platform) {
    return this.checkPlatfromCanDetach() && !this.isPlatformDetached(platform) && !this.parent_id && includes(this.platform_ids, platform.id)
  }

  alertForPlatformAndDevice() {
    return (this.phase === 'Design' || this.phase === 'Prototype') && !this.core;
  }

}