import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { SharedModule } from '../shared/shared.module';
import { ExampleComponent } from './example.component';
// import { RegisterComponent } from './register';
import { SignInComponent } from './sign-in';
import { SignInOAuthComponent } from './sign-in-oauth';
import { SignOutComponent } from './sign-out';
// import { ChangePasswordComponent } from './change-password';
import { AccessResourceComponent } from './access-resource';
import { ValidateTokenComponent } from './validate-token';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    SharedModule
  ],
  declarations: [
    ExampleComponent,

    // RegisterComponent,
    SignInComponent,
    SignInOAuthComponent,
    // ChangePasswordComponent,
    SignOutComponent,
    AccessResourceComponent,
    ValidateTokenComponent
  ],
  exports: [
    ExampleComponent
  ]
})
export class ExampleModule { }
