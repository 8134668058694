import { ProjectColumnData, PROJECT_COLUMN_NAME, PROJECT_COLUMN, PROJECT_COLUMN_DATA, PROJECT_COLUMN_CODE } from "../dataTypes";
import { Story } from './story';
import {map} from 'lodash';

export class ProjectColumn implements ProjectColumnData {
    list_name: string;
    list_id: number;
    list_type: String;
    list_position?: number;
    list_code?: string;
    column_name?: string;
    column_type?: string = 'is_project_column';
    columnIdentifier?: string;
    visible?: boolean = true;
    canDrag?: boolean = true;
    canCreate?: boolean = false;
    isDroppableContainer?: boolean = true;
    allSelected?: boolean;
    isDependsOnCurrentUser?: boolean = false;
    selectedStoryCount?: number;
    stories?: Array<Story>;
    current_page?: number;
    total_pages?: number;
    total_count?: number;
    per_page_count?: number;
    expandable?: boolean;
    meta?: {current_page: number, total_pages: number, total_count: number, per_page_count: number};

    constructor(data: ProjectColumnData) {
      this.list_name = data.list_name;
      this.list_id = data.list_id;
      this.column_name = this.setColumnName(this.list_name);
      this.list_type = data.list_type
      this.column_type = data.column_type ? data.column_type: 'is_project_column';
      this.list_code = data.list_code;
      this.canCreate = this.list_code ==  PROJECT_COLUMN_CODE.to_do || this.list_type === 'custom';
      this.columnIdentifier = this.setColumnIdentifier(this.list_code);
      this.visible = data.visible ? data.visible: true;
      if(data.meta) {
        this.current_page =  data.meta.current_page,
        this.total_pages =  data.meta.total_pages,
        this.total_count =  data.meta.total_count,
        this.per_page_count =  data.meta.per_page_count
      }
      this.canDrag = this.column_type === 'is_project_column' ? true : false;
      this.isDroppableContainer = this.column_type === 'is_project_column' ? true : false;
      this.isDependsOnCurrentUser = data.isDependsOnCurrentUser ? data.isDependsOnCurrentUser: true;
      this.allSelected = data.allSelected;
      this.selectedStoryCount = data.selectedStoryCount;
      this.stories = data.stories ? data.stories : [];
      this.list_position = data.list_position;
    }

    setStories(project) {
        let stories: Array<Story> = [];
        this.stories.forEach(st => {
            st['story'].project = project;
            const story = new Story(st['story']);
            stories.push(story);
            project.addStory(story);
          });
          this.stories = [];
          this.stories = stories;
    }

    setColumnName(list_name) {
        switch (list_name.trim()) {
            case PROJECT_COLUMN_NAME.to_do:
                return PROJECT_COLUMN.to_do;
            case PROJECT_COLUMN_NAME.in_progress:
                return PROJECT_COLUMN.in_progress;
            case PROJECT_COLUMN_NAME.qa:
                return PROJECT_COLUMN.qa;
            case PROJECT_COLUMN_NAME.accepted:
                return PROJECT_COLUMN.accepted;
            case PROJECT_COLUMN_NAME.rejected:
                return PROJECT_COLUMN.rejected;
            case PROJECT_COLUMN_NAME.archived:
                return PROJECT_COLUMN.archived;
            default:
                // this.story_type_icon = null;
                return;
        }
    }

    setColumnIdentifier(column_name) {
        return '#' + column_name;
    }

    appendStories(stories, project) {
        stories.forEach((st) => {
            if (!this.isStoryExist(st['story'].id)) {
                st['story'].project = project;
                const story = new Story(st['story']);
                this.stories.push(story);
                project.addStory(story);
            }
        })
    }

    isStoryExist(id) {
        let colStories = map(this.stories, 'id');
        return colStories.indexOf(id) >= 0;
    }

    setMetaData(meta) {
        this.current_page = meta.current_page;
        this.total_pages = meta.total_pages;
        this.total_count = meta.total_count;
        this.per_page_count = meta.per_page_count;
    }
}
