<div class="column_header" [attr.data-column-name]="projectColumn.columnIdentifier">
  <!-- <span class="toggle-title">{{projectColumn.list_name}}
  </span> -->
</div>

<!-- project check  -->
<div class="epiccolumn" *ngIf = "project" [id]="projectColumn.columnIdentifier">
  <!--<div class="storyBoardPanel-Loader" *ngIf="startLoader">-->
    <!--<img src="../../assets/images/storyBoardLoader.gif" alt=""/>-->
  <!--</div>-->  
  <project-epic class="projectEpic"
      [showEpicArrow] = "true"
      [epic]="epic"
      [project] = "project"
      (applyFeatureFilter) = "applyFeatureFilter($event)"
      *ngFor="let epic of getEpics() | filterDataSort:'feature': project.filters | epicSearch: projectService.featureSearchText" >
  </project-epic>
</div>
