import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {EVENT_TYPES} from '../shared/dataTypes';
import {ProjectService} from '../shared/services/project.service';
import {ErrorService} from '../shared/errorFunction';
import {NotificationService} from '../shared/services/notification.service';
import {Project} from '../shared/models/project';
import {environment} from '../../environments/environment';
import {User} from '../shared/models/user';
import {UserService} from '../shared/services/user.service';
import { SafeResourceUrl } from '@angular/platform-browser';


@Component({
  selector: 'app-white-board',
  templateUrl: './white-board.component.html',
  styleUrls: ['./white-board.component.scss']
})

export class WhiteBoardComponent implements OnInit {
  projectId;
  project: Project;
  currentUser: User;
  url :SafeResourceUrl ="";

  constructor(private activatedRoute: ActivatedRoute,
              private projectService: ProjectService,
              private errorService: ErrorService,
              public userService: UserService,
              private notificationService: NotificationService,
              private router: Router) {
  }

  ngOnInit() {
    this.currentUser = this.userService.getUser();
    this.activatedRoute.params.subscribe((params: Params) => {
      this.projectId = +params['id'];
      this.getProjectDetail();
    });
  }

  getProjectDetail() {
    this.projectService.project(this.projectId, true).subscribe(
      (project) => {
        this.project = project;
        this.notificationService.broadcast(EVENT_TYPES.PROJECT.SHOW, {
          data: {
            project: this.project,
          },
        });
        if (!this.project.build_card_id) {
          this.router.navigate(["/projects/" + this.project.id]);
          return;
        }
        this.url = `${environment.whiteboard_url}/${this.project.build_card_id}/whiteboard?email=${this.currentUser && this.currentUser.email}&name=${this.currentUser && this.currentUser.name}`;
      },
      (error) => {
        this.errorService.errorFunction(error);
      }
    );
  }
}