import { Component } from '@angular/core';
import {
  ProjectService
} from './../../shared/services/project.service';

@Component({
  selector: 'admin-left-menu',
  templateUrl: 'leftMenu.component.html',
  styleUrls: ['leftMenu.component.scss']
})
export class AdminLeftMenuComponent {

  constructor(public projectService: ProjectService) {

  }

}
