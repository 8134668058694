<div class="release-commonPopUp">
    <div class="release-commonPopUp-backdrop" (click)="closeReleaseVersionPopup()"></div>
    <div class="release-commonPopUp-wrapper">
      <div class="close-release-planner-popup" (click)="closeReleaseVersionPopup()">
        <em class="iconb-close"></em>
      </div>
      <div class="release-commonPopUp-title-block">
        <div class="title">{{selected_release ? 'Edit' : 'Create'}} Release</div>
      </div>
      <div class="common-Loader create-release-Loader" *ngIf="waitLoader">
          <img src="../../assets/images/storyBoardLoader.gif" alt=""/>
        </div>
      <div class="commonPopUp-createVersion">
        <div class="createVersion">
          <div class="formBlock">
            <span class="label-type1">Version Name <sup>*</sup></span>
            <input type="text" class="versionName" [disabled]="selected_release && selected_release.environment !== 'production'" [(ngModel)]="release.version" placeholder="0.1" />
          </div>
          <div class="formBlock">
            <span class="label-type1">Release name <sup>*</sup></span>
            <input type="text" class="versionName" [(ngModel)]="release.name" />
          </div>
          <div class="formBlock">
            <div class="attachments-add">
              <span class="label-type1">Add attachments</span>
              <span class="add-btn"><em class="iconb-plus"></em> Add
                <input 
                type="file" 
                id="fileOnly" 
                class="hidden-input"
                value="" 
                (change)="attachFilesOnly($event)" 
                multiple />
              </span>
            </div>
            <div class="releaseAttachmentOnly" *ngIf="attachOnlyArr.length > 0 || editAttachment.length > 0">
              <div class="toBeUploaded-list" *ngFor="let item of editAttachment; let i = index">
                <img *ngIf = "sharedService.checkType(item.name, true) == 'image'" [src]="item.remote_url" alt="loader"/>
                <span class="removeDocument" (click)="removeStoryAttachment(item, true)">
                  <em class="iconb-bin"></em>
                </span>
              </div>
              <div class="toBeUploaded-list" *ngFor="let item of attachOnlyArr; let i = index">
                <img *ngIf = "sharedService.checkType(item.name, true) == 'image'" [src]="attachmentPreviewUrls[i]" alt="loader"/>
                <span class="removeDocument" (click)="removeStoryAttachment(item, false)">
                  <em class="iconb-bin"></em>
                </span>
              </div>
            </div>
          </div>
          <div class="formBlock">
            <div class="attachments-add">
              <span class="label-type1">Release Notes <sup>*</sup></span>
              <span class="add-btn" (click)="showHideReleaseDetail()"><em *ngIf="!release_detail_data && !selected_release?.release_detail_text" class="iconb-plus"></em> {{(release_detail_data || selected_release?.release_detail_text) ? 'Update' : 'Add'}}</span>
            </div>
          </div>
          <div class="formBlock">
            <span class="label-type1">Platforms <sup>*</sup></span>
            <div class="actionBlock">
              <div class="storyReqDD-selected">
                <span class="selected-name" (click)="focusDropDown('deviceAndPlatform')">
                  <em [ngClass]="['iconb-devices-platefrom']"></em>
                  <span class="selectedDevicePlatform-name">{{selectedDevicePlatform ? selectedDevicePlatform : 'Select Platform'}}</span>
                  <em class="iconb-dd-down"></em>
                </span>
                <div class="reqSelection-DD forDevicePlatform" [ngClass]="{'openReqDD': showDropDown && dropDownType==='deviceAndPlatform'}" >
                  <div class="selectDeviceList-wrapper" *ngFor="let devicePlatformData of available_platforms; index as i;">
                    <div class="deviceType">
                      <em [ngClass]="[devicePlatformData.device ? 'iconb-' + devicePlatformData.device : 'iconb-mobile']"></em>
                      {{devicePlatformData.device}}
                    </div>
                    <div class="storyReqSelection-list" *ngFor="let platform of devicePlatformData.platforms">
                      <div class="moveSelection-check">
                        <label class="customCheckFor-moveSelection">
                            <input type="checkbox" class="move-selected-checkbox" [checked]="platform.selected" (change)="toggleDevicePlatform($event, platform)" hidden/>
                            <span class="check-toggleMoveSelection"></span>
                        </label>
                      </div>
                      <span class="selectedType-name">{{ platform?.display_name }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="formBlock">
            <span class="label-type1">Phase<sup>*</sup></span>
            <div class="phaseDeliveables-selected">
                <span class="selected-type" (click)="focusDropDown('phase')">
                  <em class="iconb-dd-down"></em>
                  <span class="selectedType-name priorityName">{{release?.phase?.name ? (release.phase.name | replaceUnderscore) : 'Select'}}</span>
                </span>
                <div class="storySelection-DD" [ngClass]="{'openDD': showDropDown && dropDownType==='phase'}">
                  <div class="storySelection-list" (click)="selectPhaseAndDeliverables(phase, 'phase')" *ngFor="let phase of phases_list">
                    <span class="selectedType-name">{{phase.name}}</span>
                  </div>
                </div>
              </div>
          </div>
          <div class="formBlock">
            <span class="label-type1">Phase deliverables <sup>*</sup></span>
            <div class="phaseDeliveables-selected">
              <span class="selected-type" (click)="focusDropDown('phase_deliverables')">
                <em class="iconb-dd-down"></em>
                <span class="selectedType-name priorityName">{{release?.phase_deliverables?.title ? (release.phase_deliverables.title | replaceUnderscore) : (release?.phase?.name ? 'Select' : 'Select Phase First')}} </span>
              </span>
              <div class="storySelection-DD" *ngIf="phase_deliverables_list.length > 0" [ngClass]="{'openDD': showDropDown && dropDownType==='phase_deliverables'}">
                <div class="storySelection-list" (click)="selectPhaseAndDeliverables(phase,'phase_deliverables')" *ngFor="let phase of phase_deliverables_list">
                  <span class="selectedType-name">{{phase.title}}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="formBlock">
            <span class="label-type1">Build URL </span>
            <input type="text" class="versionName" [(ngModel)]="release.build_url" placeholder="URL" />
          </div>
          <div class="formBlock">
            <span class="label-type1">Estimated Release Date <sup>*</sup></span>
            <div class="addSprintForm-block">
                <input type="text"
                       class="addEstimation-input"
                       placeholder="Select Date"
                       name="endDate"
                       angular-mydatepicker
                       (dateChanged)="onEndDateChanged($event)"
                       #dp="angular-mydatepicker"
                       [options]="datePickerOptionsEnd"
                       [(ngModel)]="endDt">
                <div class="disableCal" *ngIf="disableEndDate"></div>
                <div class="cal-btn" (click)="dp.toggleCalendar()">
                  <span class="calIcon">
                    <em class="iconb-calendar"></em>
                  </span>
                </div>
              </div>
          </div>
          
          <div class="add-sprint-story" *ngIf="!selected_release">
            <div class="links-list">
              <span class="link" [ngClass]="{'active': addStoryVisible}" (click)="addStoriesToggle()">Add Stories</span>
              <span class="link" [ngClass]="{'active': addSprintVisible}" (click)="addSprintsToggle()">Add Sprints</span>
            </div>
            <div class="formBlock" *ngIf="addStoryVisible">
              <div class="search-input">
                <em class="iconb-search"></em>
                <input type="text" class="versionName" name="searchStories" (input)="searchStories()" [(ngModel)]="searchStoryData" placeholder="Search for a story to add"/>
              </div>
              <div 
              infiniteScroll 
              [scrollWindow]="false" 
              [infiniteScrollDisabled]="!releasePlannerService.isScrollFetchAvailable(storiesMeta)" 
              (scrolled)="loadStories()" 
              class="formBlock2">
              <div class="bulkAction-Loader" *ngIf="storyListLoader">
                <img src="../../assets/images/storyBoardLoader.gif" alt=""/>
              </div>
              <div class="empty-search-block" *ngIf="stories.length === 0 && !storyListLoader">
                <em class="icon">
                  <img src="../../assets/images/empty-search-icon.svg" alt="">
                </em>
                <p>No Story found.</p>
              </div>
                <div class="searchedStoryList" *ngFor="let story of stories">
                  <span [ngClass]='getStateClasses(story.state)'></span>
                  <span class="searched-storyName"><span>{{story.title}}</span></span>
                  <button type="button" class="searched-addStory" (click)="story.selected ? removeStories(story) : addStories(story)">{{story.selected ? '-':'+'}}</button>
                </div>
              </div>
            </div>
            <div class="formBlock" *ngIf="addSprintVisible">
              <div class="search-input">
                <em class="iconb-search"></em>
                <input type="text" class="versionName" name="searchSprints" (input)="searchSprints()" [(ngModel)]="searchSprintData" placeholder="Search for a sprint to add"/>
              </div>
              <div 
              class="formBlock2" 
              infiniteScroll 
              [scrollWindow]="false" 
              [infiniteScrollDisabled]="!releasePlannerService.isScrollFetchAvailable(sprintsMeta)" 
              (scrolled)="loadSprints()">
              <div class="bulkAction-Loader" *ngIf="sprintListLoader">
                <img src="../../assets/images/storyBoardLoader.gif" alt=""/>
              </div>
              <div class="empty-search-block" *ngIf="sprints.length === 0 && !sprintListLoader">
                <em class="icon">
                  <img src="../../assets/images/empty-search-icon.svg" alt="">
                </em>
                <p>No Story found.</p>
              </div>
                <div class="searchedStoryList" *ngFor="let sprint of sprints">
                  <span [ngClass]='getStateClasses(sprint.status)'></span>
                  <span class="searched-storyName"><span>{{sprint.name}}</span></span>
                  <button type="button" class="searched-addStory" (click)="sprint.selected ? removeSprints(sprint) : addSprints(sprint)">{{sprint.selected ? '-':'+'}}</button>
                </div>
              </div>
            </div>
          </div>
  
          <div class="release-commonPopUp-actionBtn">
            <span class="validationMsg" *ngIf="!isFormValid"><sup>*</sup> Fill all the mandatory fields </span>
            <button type="button" class="release-commonPopUp-cancel" (click)="closeReleaseVersionPopup()">Cancel</button>
            <button type="button" class="release-commonPopUp-submit" [disabled]="!isFormValid" (click)="selected_release ? updateRelease() : createRelease()">{{selected_release ? 'Update' : 'Create'}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-release-note 
  *ngIf="openReleaseDetailPopUp" 
  [project]="project" 
  [release]="selected_release" 
  [release_detail_for]="release_detail_for" 
  [release_detail_added_data]="release_detail_data"
  (release_detail_data)="set_release_detail_data($event)" 
  (closeReleaseDetailPopup)="showHideReleaseDetail()"></app-release-note>