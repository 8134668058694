// Include common helpers and variables here
export class CommonBase {

  weekDayOptions: Array<string> = [
    'Monday', 'Tuesday', 'Thursday', 'Wednesday',
    'Friday', 'Saturday', 'Sunday'
  ];
  iterationLengthOptions: Array<string> = [
    '1 week', '2 weeks', '3 weeks', '4 weeks'
  ];

  valueIsPresent(value): boolean {
    if (typeof value == 'string') {
      return value.trim().length > 0;
    } else {
      return !!value;
    }
  }

  valueIsBlank(value): boolean {
    return !this.valueIsPresent(value);
  }

  valueToString(value): string {
    return this.valueIsPresent(value) ? value.toString() : 'not set';
  }

  numberToWeekday(value: number): string {
    return this.valueToString(this.weekDayOptions[value]);
  }

  numberToIterationLength(value: number): string {
    return this.valueToString(this.iterationLengthOptions[value - 1]);
  }
}
