import { UserData } from '../dataTypes';
import { GUID } from './guid';
import { BaseModel } from './baseModel';

export class User extends BaseModel implements UserData {
  id: number;
  provider?: string;
  uid?: string;
  skill?: string;
  name: string;
  nickname?: string;
  username: string;
  initials?: string;
  image?: any;
  email: string;
  role?: string;
  name_or_email?: string;
  most_recent_activities?: Array<any>;
  last_time_active?: String;
  tracked_times?: Array<any>;
  is_confirmed?: boolean;
  reviewer?: boolean;
  deleted_at?: Date;
  pod_role?: string;

  static getUser(data: UserData): User {
    const user = GUID.instance.findUserByID(data.id);

    if (user) {
      return user;
    } else {
      return new User(data);
    }
  }

  constructor(data: UserData) {
    super(data);

    this.id = data.id;
    this.provider = data.provider;
    this.skill = data.skill;
    this.uid = data.uid;
    this.name = data.name;
    this.nickname = data.nickname;
    this.username = data.username;
    this.initials = data.initials;
    this.image = data.image;
    this.email = data.email;
    this.name_or_email = data.name_or_email;
    this.most_recent_activities = data.most_recent_activities;
    this.last_time_active = data.last_time_active;
    this.tracked_times = data.tracked_times;
    this.is_confirmed = data.is_confirmed;
    this.reviewer = data.reviewer;
    this.deleted_at = data.deleted_at;
    if (data.role) {
      this.role = data.role;
    }
    this.pod_role = data.pod_role;
  }

  toJSON(): UserData {
    return {
      id: this.id,
      provider: this.provider,
      uid: this.uid,
      name: this.name,
      nickname: this.nickname,
      username: this.username,
      initials: this.initials,
      image: this.image,
      email: this.email,
      most_recent_activities: this.most_recent_activities,
      last_time_active: this.last_time_active,
      tracked_times: this.tracked_times,
      is_confirmed: this.is_confirmed,
      reviewer: this.reviewer,
      skill: this.skill,
      role: this.role
    };
  }

  getClassName() {
    return 'User';
  }
}
