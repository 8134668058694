import {
  Component,
  ViewChild,
  ElementRef
} from '@angular/core';
import * as _ from 'lodash';
import {
  Router,
} from '@angular/router';

import { Renderer2 } from '@angular/core';
import {
  UserData,
  EVENT_TYPES,
  FIRST_VISIBLE_COLUMN,
  PROJECT_COLUMN_DATA, SignInData
} from './../../shared/dataTypes';
import { User } from '../../shared/models/user';
import { PolicyService } from '../../shared/services/policy.service';
import { UserService } from '../../shared/services/user.service';
import { ErrorService } from '../../shared/errorFunction';
import { NotificationService } from '../../shared/services/notification.service';
import { ProjectService } from '../../shared/services/project.service';
import {environment} from '../../../environments/environment';
import { DataService } from '../../shared/services/data.service';
import { SharedService } from '../../shared/services/shared.service';
import { SocketsService } from '../../shared/services/sockets.service';
import { CustomToastService } from '../../shared/services/custom-toast.service';


@Component({
    selector: 'login',
    templateUrl: 'login.component.html',
  styleUrls: ['login.component.scss'],
})
export class LoginComponent {
  projectColumns = PROJECT_COLUMN_DATA;
  private signInData: SignInData = <SignInData>{};
  private output: any;
  showLoginPassword = false;
  browserMedia: any;
  videoStream: any;
  cameraAccessible = false;
  user: any;
  @ViewChild('video') public video: ElementRef;
  @ViewChild('canvas') public canvas: ElementRef;
  showStream = true;
  enableInternalForm:boolean = false;
  enableDeveloperForm:boolean = false;
  enableLoginWithPassword: boolean = false;
  showFaceAuth:boolean = false;
  disableNextBtton = false;
  isResend:boolean;
  counter: number = 3;
  emailEntered: any;
  interval;
  isLoginComplete: boolean = false;
  showLoader: boolean = false;

  constructor(
              private customToastService: CustomToastService,
              private dataService: DataService,
              private router: Router,
              private sharedService: SharedService,
              private policyService: PolicyService,
              private userService: UserService,
              private projectService: ProjectService,
              private socketService: SocketsService,
              private notificationService: NotificationService,
              private renderer: Renderer2,
              private errorService: ErrorService) {

  }

  ngOnInit() {

    this.enableDeveloperForm = true;
    this.renderer.addClass(document.body, 'auth-screen');
    // this.router.navigate([{
    //   outlets: {
    //     'header': ['noContent']
    //   }
    // }]);
    if(this.userService.getUser()) {
      this.router.navigate(['/']);
    } else {
      this.userService.removeSignOutLocalStorage();
      this.userService.unSetCookie()
    }
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'auth-screen');
    this.router.navigate([{
      outlets: {
        'header': ['']
      }
    }]);
  }

  showDeveloperForm() {
    this.user = null;
    // this.enableInternalForm = false;
    // this.enableDeveloperForm = true;
  }
  showInternalForm() {
    // this.user = null;
    this.enableLoginWithPassword = false;
    this.enableInternalForm = true;
    this.enableDeveloperForm = false;
  }

  showPassword($event) {
    this.showLoginPassword = !this.showLoginPassword;
    $event.preventDefault();
    $event.stopPropagation();
  }

  // Submit Data to Backend
  onSubmit() {
    this.emailEntered = '';
    this.projectService.searchString = '';
    this.output = null;
    let type = this.user ? {'X-Face-Guid': this.user.guid} : (this.enableLoginWithPassword ? {'X-User-Type': 'developer'} :  {'X-User-Type': 'internal_user'})
    if(('X-Face-Guid' in type) || type["X-User-Type"] == 'developer') {
      localStorage.setItem('isLoginWithDeveloper', JSON.stringify(true));
    }
    this.userService.login_signup(this.signInData, 'login', type)
    .subscribe((res:any) => {
      this.renderer.removeClass(document.body, 'collapseNav');
      this.signInData    = <SignInData>{};
      this.output        = res;
      const data = res.body['data'];
      const adminLogin = (data.user.role == 'admin' || (data.project_admin));
      const designerLogin =  data.user.skill == 'UI/UX';
      this.userService.setCurrentUser(data.user as UserData, res.headers, designerLogin, adminLogin);
      this.socketService.centrifugeConfig();
      if((!designerLogin)) {
        localStorage.setItem('isUserInternalOrCp', this.isUserInternalOrCp());
        this.loginRedirect();
      }
       else {
          window.location.href = environment.designerUrl;
      }
    }, error => {
      this.signInData = <SignInData>{};
      this.output = error;
      this.errorService.errorFunction(error);
      /*this.notificationService.broadcast(EVENT_TYPES.SESSION.LOGIN_FAILED, {});*/
    });
  }

showResendButton(){
  if (this.signInData.email && this.signInData.email === this.emailEntered && this.isResend) {
    this.userService.isShowResendButton = true;
  }else{
    this.userService.isShowResendButton = false;
  }
}
  
resendEmail(){
    this.disableNextBtton = true;
    this.userService.resend_invitation(this.signInData['email'])
    .subscribe((res:any) => {
      this.customToastService.messages.push({
        id: 'resendConfirmation', type: 'success',
        class: 'generic_alert',
        title: 'Alert',
        message: 'Invitation email is sent to your email address. Please check and accept it to log in.'
      });
      this.disableNextBtton = false;
    });
  }

checkPartnerAndDeveloper() {
  this.emailEntered = this.signInData['email'];
  this.userService.checkUserExistence(this.signInData['email'])
  .subscribe(res => {
    if(res) {
      this.isResend = false;
      this.user = res.user;
      if( this.user.internal) {
        this.user = null;
        this.showInternalForm()
      } else if(!this.user.internal) {
        this.showFaceAuth = true;
        this.openFaceAuth();
      }
    }
  }, (error) => {   	
    if (error.resend) {
      this.userService.isShowResendButton = true;
      this.isResend = true;
    }else{
      this.isResend = false;
      this.userService.isShowResendButton = false;
    }
    this.errorService.errorFunction(error)
  });
}

  loginRedirect() {
    const currentUser = this.userService.getUser();
    const payload = {
      data: {
        currentUser: currentUser,
      }
    };
    this.userService.getPartnerCode().subscribe(partner => {
      this.userService.setPartner(partner);
    }, err => this.errorService.errorFunction(err));
    this.notificationService.broadcast(EVENT_TYPES.SESSION.LOGGED_IN_NEW, payload);
    this.notificationService.broadcast(EVENT_TYPES.SESSION.LOGGED_IN, payload);
    this.policyService.refresh();
    let redirectUrl = '';
    let queryParams = {};
    if(this.userService.loginUrl != '') {
      if(this.userService.loginUrl.indexOf('?') >= 0) {
        queryParams = this.getueryString(this.userService.loginUrl);
        if(queryParams['storyId']) {
          this.projectService.storyId = queryParams['storyId'];
        }
        redirectUrl = this.userService.loginUrl.substr(0, this.userService.loginUrl.indexOf('?'));
        this.router.navigate([redirectUrl], { queryParams: queryParams });
      }
       else {
        redirectUrl = this.userService.loginUrl;
        if(redirectUrl.trim() == '/projects') redirectUrl = '/'
        this.router.navigate([redirectUrl]);
      }

    } else {
      redirectUrl = '/';
      this.router.navigate(['/']);
    }

    if (currentUser) {
      this.userService.trackSegmentEvent('Login successful', this.sharedService.getCommonPropsForSegmentEvents());
    }
  }

  getueryString(querystring) {
    // remove any preceding url and split
    querystring = querystring.substring(querystring.indexOf('?')+1).split('&');
    let params = {}, pair, d = decodeURIComponent;
    // march and parse
    for (let i = querystring.length - 1; i >= 0; i--) {
      pair = querystring[i].split('=');
      params[d(pair[0])] = d(pair[1] || '');
    }

    return params;
  }

  getCameraPermissions(): void {
    this.browserMedia = <any>navigator;
    this.browserMedia.getUserMedia = (this.browserMedia.getUserMedia ||
      this.browserMedia.webkitGetUserMedia ||
      this.browserMedia.mozGetUserMedia ||
      this.browserMedia.msGetUserMedia);
      if (this.browserMedia.mediaDevices && this.browserMedia.mediaDevices.getUserMedia) {
      this.browserMedia.mediaDevices.getUserMedia({ video: true })
        .then(stream => {
          this.cameraAccessible = true;
          this.showStream = true;
          this.videoStream = stream.getTracks()[0];
          this.video.nativeElement.srcObject = stream;
          this.video.nativeElement.play();
          this.matchInitialFace();
          this.userService.trackSegmentEvent('Camera permission allowed', this.sharedService.getCommonPropsForSegmentEvents(this.user.email, 'CP'));
          this.userService.trackSegmentEvent('Camera countdown starts', this.sharedService.getCommonPropsForSegmentEvents(this.user.email, 'CP'));
        })
        .catch(err => {
          this.cameraAccessible = false;
          this.userService.trackSegmentEvent('Camera permission blocked', this.sharedService.getCommonPropsForSegmentEvents(this.user.email, 'CP'));
        });
    }
  }

  stopCapturing() {
    if (this.videoStream) {
      this.videoStream.stop();
    }
  }

  startTimer() {
    this.counter = 3;
    this.interval = setInterval(() => {
      if (this.counter > 0) {
        this.counter--;
      } else {
        clearInterval(this.interval);
      }
    },700);
  }

  matchInitialFace(): void {
    if (this.cameraAccessible) {
      this.startTimer();
      setTimeout(() => {
        this.captureImageForInitialFace();
      }, 2110);
    }
  }

  captureImageForInitialFace(): void {
    if (this.video.nativeElement.srcObject && this.video.nativeElement.srcObject.active) {
      this.canvas.nativeElement.getContext('2d').drawImage(this.video.nativeElement, 0, 0, 618, 473);
      const imageDataUri = this.canvas.nativeElement.toDataURL('image/png');
      this.srcToFile(
        imageDataUri,
        `${Date.now()}.png`,
        'image/png'
      )
        .then(file => {
          this.showStream = false;
          this.signInData.password = 'builder';
          this.stopCapturing();
          this.matchImageForInitialFace(file);
        });
    }
  }

  srcToFile(src, fileName, mimeType) {
    return (fetch(src)
        .then(res => res.arrayBuffer())
        .then(buf => new File([buf], fileName, {type: mimeType}))
    );
  }

  matchImageForInitialFace(file: any): void {
    this.showLoader = true;
    this.userService.trackSegmentEvent('Face verifying', this.sharedService.getCommonPropsForSegmentEvents(this.user.email, 'CP'));
    this.userService.matchImageForInitialFace(file, this.signInData['email']).subscribe(res => {
      this.userService.trackSegmentEvent('Face verifying successful', this.sharedService.getCommonPropsForSegmentEvents(this.signInData['email'], 'CP'));
      this.isLoginComplete = true;
      this.showLoader = false;
      this.onSubmit();
      this.user = null;
      }, (err: any) => {
      const error = (err && err.errors && err.errors[0]) ? err.errors[0] : undefined;
      this.userService.trackSegmentEvent('Face verifying failed', this.getPropsOnFaceVerifyingFailed(error));
      this.user = null;
      this.errorService.errorFunction(err);
      this.showLoader = false;
      this.loginWithPassword();
    });
  }

  openFaceAuth() {
    this.getCameraPermissions();
    this.userService.trackSegmentEvent('Login Next clicked', this.sharedService.getCommonPropsForSegmentEvents(this.user.email, 'CP'));
    // this.userService.checkUserExistence(this.signInData['email'])
    // .subscribe(res => {
    //   this.showFaceAuth = true;
    //   this.user = res.user;
    //   this.getCameraPermissions();
    //   this.userService.trackSegmentEvent('Login Next clicked', this.sharedService.getCommonPropsForSegmentEvents(this.user.email, 'CP'));
    // }, error => this.errorService.errorFunction(error));
  }
  closeFaceAuth() {
    // this.signInData = <SignInData>{};
    this.showFaceAuth = false;
  }

  loginWithPassword(removeUser?) {
    if(removeUser) {
      this.user = null;
    }
    this.closeFaceAuth();
    this.showInternalForm();
    this.enableLoginWithPassword = true;
  }

  forgotPassword() {
    this.userService.trackSegmentEvent('Forgot Password clicked', this.sharedService.getCommonPropsForSegmentEvents(this.signInData['email'] ? this.signInData['email'] : '', this.isUserInternalOrCp()));
    this.router.navigate(['/forgot-password']);
  }

  getPropsOnFaceVerifyingFailed(error) {
    const props = this.sharedService.getCommonPropsForSegmentEvents(this.signInData['email'], 'CP');
    if (error) {
      props['error'] = error;
    }
    return props;
  }

  isUserInternalOrCp() {
    if (this.enableDeveloperForm || this.enableLoginWithPassword) {
      return 'CP';
    } else if (this.enableInternalForm && !this.enableLoginWithPassword) {
      return 'internal';
    }
  }
}
