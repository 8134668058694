import { Component, OnInit,Input,OnDestroy, Renderer2 } from '@angular/core';
import { Story } from '../../models/story';
import { StoryService} from '../../services/story.service';
import { ProjectService } from '../../services/project.service';
import { Project } from '../../models/project';
import { ErrorService } from '../../errorFunction';
import { CustomToastService } from '../../services/custom-toast.service';
import { ProjectColumn } from '../../models/column';
import { NotificationService } from '../../services/notification.service';
import { CommonStoryService } from '../../services/common-story.service';
import {
  EVENT_TYPES,
  DraggableData,
  PROJECT_COLUMN_DATA,
  PROJECT_COLUMN_IDENTIFIER,
  PROJECT_COLUMN,
  STORY_STATE_OPTIONS,
  BB_STATUS_OPTIONS,
  STORY_STATE, STORY_TYPE_OPTIONS,STORY_SUB_TYPE_OPTIONS, STORY_TYPE, NOT_APPLIED_REVIEWER, STORY_PRIORITY_OPTIONS, BB_STATUS
} from '../../dataTypes';

@Component({
  selector: 'app-story-clone-popup',
  templateUrl: './story-clone-popup.component.html',
  styleUrls: ['./story-clone-popup.component.scss']
})
export class StoryClonePopupComponent implements OnInit,OnDestroy {
  story: Story;
  @Input('project') project: Project;
  waitForLoader: boolean;
  @Input() fromBacklog:boolean;
  projectColumns: Array<ProjectColumn> = PROJECT_COLUMN_DATA;
  constructor(
    public projectService: ProjectService,
    private commonStoryService: CommonStoryService,
    private customToast: CustomToastService,
    private storyService: StoryService,
    private notificationService: NotificationService,
    private errorService: ErrorService,
    private renderer2: Renderer2,
  ) { }
  
  ngOnInit() {
    this.story = this.projectService.selectedStory;
  }

  ngOnDestroy() {
    this.projectService.selectedStory = null;
  }

  hasParentSubtasks() {
    return this.story.subTaskAvailable();
  }

  toggleApplyOnSubTasks() {
    this.projectService.apply_on_sub_tasks = !this.projectService.apply_on_sub_tasks;
  }

  onClick(event) {
    const element = event.target;
    if (!element.classList.contains('bulkaction-popUp')) {
      this.closeCloneConfirmationPopup();
    }
  }

  checkStoriesReason() {
        return (!this.story.parent_id && this.story.story_type === 'task' && (!this.projectService.creationReasonsSelected) && (this.story.epics && this.story.epics[0].task_done_count > 0)) ? true : false;
  }

  closeCloneConfirmationPopup() {
    this.projectService.closeCloneConfirmationPopup();
    this.renderer2.removeClass(document.body, 'duplicateStoryPopup-open'); 
  }

  cloneStory(){
    this.projectService.clonePopupLoader = true;
    if (this.fromBacklog) {
      this.commonStoryService.makeClone(this.projectService.selectedStory, this.project);
    }else{
      this.projectService.checkStoryCreateNeedConfirmation(this.projectService.selectedStory, this.project)
    }
  }

  showReasonList() {
    this.projectService.story_creation_reason = !this.projectService.story_creation_reason;
  }

}
