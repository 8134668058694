<div class="card card-block">
    <h4 class="card-title">Response</h4>
    <p>
        <strong>Status:</strong>
        <code>{{output?.status}}</code>
    </p>
    <p *ngIf="output?.data != null">
        <strong>Data:</strong>
        <code>{{output?.data | json}}</code>
    <p>
    <p *ngFor="let error of output?.errors; let i = index">
        <strong>Error {{i + 1}}:</strong>
        <code>{{error}}</code>
    </p>
</div>