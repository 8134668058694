<div class="selectedStory_list" *ngIf="selStories">
    <span class="bulkaction-popUp-Text1" >You have selected {{selStories.length}} stories to change Owner. For {{projectService.storiesInvalidToAssignUser.length}} stories action cannot be performed because of the following reasons:
      <span class="reason-list-block" *ngIf="projectService.storiesInvalidToAssignUser.length > 0">
        <ol>
          <li>Owner and Reviewer cannot be same.</li>
          <li>Experts cannot be owner or reviewer of the core parent user story.</li>
        </ol>
      </span> 
    </span>
    <span class="bulkaction-popUp-Text1" style="padding-bottom: 0" *ngIf="projectService.storiesInvalidToAssignUser.length > 0">Stories on which action cannot be performed:</span>
    <div class="selectedStoriesList-wrapper" >
      <div class="selectedStoriesList" *ngFor = "let story of projectService.storiesInvalidToAssignUser">
        <div class="selectedStoriesList-left">
          <div class="story-typeIcon">
            <em class="iconb-task featureIcon" *ngIf = "story.story_type == 'task'"></em>
            <em class="iconb-bug bugIcon" *ngIf = "story.story_type == 'bug'"></em>
          </div>
          <div class="story-NameBox">
            <span class="selected-storyName">{{story.title}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>