import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SharedModule } from './../shared/shared.module';
import { ProjectSettingComponent } from './project-setting.component';
import {FormsModule} from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    FormsModule
  ],
  declarations: [
    ProjectSettingComponent
  ],
  exports: [
    ProjectSettingComponent
  ]
})
export class ProjectSettingModule { }
