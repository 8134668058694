import { Component, OnInit, Input, EventEmitter, Output} from '@angular/core';
import { ReleasePlannerService } from '../../shared/services/release-planner.service';
import { ErrorService } from '../../shared/errorFunction';
import { PolicyService } from '../../shared/services/policy.service';
import {SharedService} from '../../shared/services/shared.service';
import { Subscription } from 'rxjs';
import { Story } from '../../shared/models/story';
import { CustomToastService } from '../../shared/services/custom-toast.service';
import { ProjectService } from '../../shared/services/project.service';
@Component({
  selector: 'app-add-remove-story',
  templateUrl: './add-remove-story.component.html',
  styleUrls: ['./add-remove-story.component.scss']
})
export class AddRemoveStoryComponent implements OnInit {
  @Input() project;
  @Input() selected_release;
  @Input() action_on_story;
  @Output() closeAddStoryModal = new EventEmitter<boolean>();
  stories = [];
  storiesMeta: {
    current_page: number,
    next_page: number,
    per_page: number,
    total_count: number
  };
  storiesSelected = [];
  storyListLoader: boolean = false;
  listSubscriptions: Subscription;
  searchStoryData:string = '';
  bulk_selection:boolean = false;
  all_stories_selected:boolean = false;
  initiate_from;
  add_remove_loader: boolean = false;

  constructor( public releasePlannerService: ReleasePlannerService,
    public sharedService: SharedService,
    private customToast: CustomToastService,
    public policyService: PolicyService,
    public projectService: ProjectService,
    private errorService: ErrorService) { }

  ngOnInit() {
    this.clearCallSubscription();
    this.storyListLoader = true;
    this.loadStories();
    this.initiate_from = this.projectService.initiate_from;
  }

  clearCallSubscription() {
    if (this.listSubscriptions) {
      this.listSubscriptions.unsubscribe();      
    }
  }

  searchStories() {
    this.storyListLoader = true;
    this.stories = [];
    this.clearCallSubscription();
    this.loadStories();
  }

  loadStories() {
    const release_id = this.action_on_story === 'remove_story' ? this.selected_release.id : '';
    const pageno = this.storiesMeta && this.storiesMeta.next_page ? this.storiesMeta.next_page : 1;
    this.listSubscriptions = this.releasePlannerService.getStoriesForRelease(this.project, this.searchStoryData, pageno, release_id).subscribe(res => {
      if(res.data && res.data.length === 0) {
        this.stories = [];
      }
      if(res) {
        this.storiesMeta = res.meta;
        if(this.storiesMeta.current_page === 1) {this.stories = [];}
        res.data.forEach(item => {
          let story = new Story(item)
          story.selected = this.all_stories_selected;
          this.stories.push(story);
          if(this.all_stories_selected) {
            this.storiesSelected.push(story.id);
          }
        });
      }
      this.storyListLoader = false;
    }, (error) => {
      this.errorService.errorFunction(error);
      this.storyListLoader = false;
    });
  }

  selectStories(story, bulk_or_single, event?, action_type?) {
    if(bulk_or_single === 'single_story') {
      story.selected = true;
      this.storiesSelected.push(story.id);
      action_type && action_type === 'add' ? this.addStoriesInRelease() : this.removeStoriesFromRelease();
      return;
    }
    if (event && event.target.checked) {
      this.bulk_selection = true;
      story.selected = true;
      this.storiesSelected.push(story.id);
      if(this.storiesSelected.length === this.stories.length) {
        this.all_stories_selected = true;
      }
    } else if(event && !event.target.checked){
      this.removeStoryFromSelectedList(story);
    }
  }

  resetallSelections() {
    this.storiesSelected = [];
    this.bulk_selection = false;
    this.all_stories_selected = false;
    this.stories.forEach(story => {
      story.selected = false;
    });
  }

  addAllAvailableStories(stories, bulk_or_single, event) {
    this.all_stories_selected = event.target.checked;
    this.bulk_selection = event.target.checked;
    stories.forEach(story => {
      if (event.target.checked) {
        story.selected = true;
        this.storiesSelected.push(story.id);
      } else{
        story.selected = false;
        this.storiesSelected.splice(this.storiesSelected.indexOf(story.id), 1);
      }
    });
  }

  handleStoriesInRelease(action: 'add' | 'remove') {
    this.add_remove_loader = true;
    const storyCount = this.storiesSelected.length;
    const actionTitle = action === 'add' ? 'Added' : 'removed';
    const actionMethod = (action === 'add' ? this.releasePlannerService.addStoryInRelease : this.releasePlannerService.deleteStoriesFromRelease).bind(this.releasePlannerService);
    actionMethod(this.project, this.selected_release.id, this.storiesSelected).subscribe(res => {
      const storyWord = storyCount > 1 ? 'Stories' : 'Story';
      this.customToast.messages.push({
        id: 'bulk_move',
        type: 'success',
        class: 'stories_moved',
        title: `${storyWord} ${actionTitle}`,
        message: `${storyWord} ${actionTitle.toLowerCase()} successfully.`
      });
      this.updateStoryList(this.storiesSelected);
      this.storiesSelected = [];
      this.bulk_selection = false;
  
      if (this.all_stories_selected) {
        this.all_stories_selected = false;
        this.stories.forEach(story => story.selected = false);
      }
  
      if (this.stories.length < 10 && this.releasePlannerService.isScrollFetchAvailable(this.storiesMeta) && this.storiesMeta && this.storiesMeta.next_page) {
        this.loadStories();
      }
  
      this.add_remove_loader = false;
    }, (error) => {
      this.add_remove_loader = false;
      this.storiesSelected = [];
      this.bulk_selection = false;
      if (this.all_stories_selected) {
        this.all_stories_selected = false;
        this.stories.forEach(story => story.selected = false);
      }
      this.errorService.errorFunction(error);
    });
  }
  
  addStoriesInRelease() {
    this.handleStoriesInRelease('add');
  }
  
  removeStoriesFromRelease() {
    this.handleStoriesInRelease('remove');
  }

  updateStoryList(storiesAdded) {
    // remove all the stories which are added from stories array
    this.stories = this.stories.filter(story => {
      return storiesAdded.indexOf(story.id) === -1;
    })
  }

  removeStoryFromSelectedList(story) {
    story.selected = false;
    this.storiesSelected.splice(this.storiesSelected.indexOf(story.id), 1);
    if(this.storiesSelected.length === 0) {
      this.bulk_selection = false;
    }
    if(this.storiesSelected.length !== this.stories.length) {
      this.all_stories_selected = false;
    }
  }

  closeAddStoryPopup() {
    this.closeAddStoryModal.emit(false);
  }

  getStoryStateClasses(state) {
    return ['searched-storyStatus ' + state]
  }

  toggleEditMode(event, story, project) {
    if(this.projectService.initiate_from === 'from_release_detail') {
      const element = event.target;
      if(this.storiesSelected.length > 0 || element.classList.contains('check-toggleMoveSelection') || 
        element.classList.contains('move-selected-checkbox') || 
        element.classList.contains('story-addStory') || 
        element.classList.contains('iconb-minus')) {
        event.stopPropagation();
        return;
      } else {
        this.projectService.show_StoryDetails(project, '', story.id, false);
      }
    }
  }

}
