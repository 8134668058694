<div class="outerWrapper">
  <div class="genralBlock">
    <div class="genralBlock-title">Add new member</div>
    <form name="form" #memberForm="ngForm" novalidate>
        <div class="inputBlock">
          <label for="name" class="forAddMember">Full Name </label>
          <input type="text" (input) = "removeError('name')" class="addMember-input" name="name" [(ngModel)]="model.name" #name="ngModel" />
          <div *ngIf="outputErr && outputErr.name" class="validation-color">{{outputErr.name[0]}}</div>
          <!-- <div *ngIf="memberForm.submitted && name.invalid" class="invalid-feedback">
            <div *ngIf="name.errors.required" class="validation-color">Full Name is required</div>
          </div> -->
        </div>
        <div class="inputBlock">
          <label for="email" class="forAddMember">Email *</label>
          <input type="text" (input) = "removeError('email')" class="addMember-input typeEmail" name="email" [(ngModel)]="model.email" #email="ngModel"
                 [ngClass]="{ 'is-invalid': memberForm.submitted && email.invalid }" required />
          <!--<span class="domainName">@{{userService.partner?.allowed_user_domain}}</span>-->
          <div class="domainDD">
            <div class="selectedDomain" (click)="openDomainSelection()">@{{model.selected_user_domain}}<em class="iconb-dd-down"></em> </div>
            <div class="selectDomain" [ngClass]="{'openSelectDomain': selectDomainDD}">
              <span class="domainList" *ngFor="let user_domain of userService.partner?.allowed_user_domain" (click)="closeDomainSelection(user_domain)">@{{user_domain}}</span>
            </div>
          </div>
          <div *ngIf="email && email.invalid && (email.touched || email.dirty)" class="invalid-feedback">
            <div *ngIf="email && email.value == '' && email.errors.required && (email.touched || email.dirty)" class="validation-color">Email is required</div>
          </div>
          <div *ngIf="email && checkEmail(model.email) && (email.touched || email.dirty)" class="validation-color">Email must be a valid email address</div>
          <div *ngIf="outputErr && outputErr.email" class="validation-color">{{outputErr.email[0]}}</div>
        </div>
        <div class="inputBlock">
          <label for="initials" class="forAddMember">Initials </label>
          <input type="text" (input) = "removeError('Initials')" class="addMember-input" name="initials" [(ngModel)]="model.initials" #initials="ngModel"/>
          <div *ngIf="outputErr && outputErr.initials" class="validation-color">{{outputErr.initials[0]}}</div>
        </div>

      <div class="inputBlock">
          <label for="role" class="forAddMember">Role *</label>
          <select class="addMember-input" (change) = "removeError('role')" name="role" [(ngModel)]="model.role">
            <option *ngFor="let roles of roleData" [ngValue] = "roles.id">{{roles?.value}}</option>
          </select>
          <div *ngIf="outputErr && outputErr.role" class="validation-color">{{outputErr.role[0]}}</div>
          <!-- <div *ngIf="role && role.invalid && (role.touched || role.dirty)" class="invalid-feedback">
              <div *ngIf="role && role.errors.required && (role.touched || role.dirty)" class="validation-color">Role is required</div>

            </div> -->
      </div>

        <div class="inputBlock">
              <label for="timeZone" class="forAddMember">Time Zone</label>
              <select class="addMember-input" (change) = "removeError('time_zone')" name="time_zone" [(ngModel)]="model.time_zone">
                <option value="">Select Time Zone</option>
                <option *ngFor="let timeZone of offsetTmz" [ngValue]="timeZone.id">{{timeZone?.value}}</option>
              </select>
              <div *ngIf="outputErr && outputErr.time_zone" class="validation-color">{{outputErr.time_zone[0]}}</div>
        </div>
        <div class="actionBt-wrapper">
          <button class="actionButton cancel-addMember" (click) = "sharedService.showUserForm = false && projectService.isNewMemberAdded = false">Cancel</button>
          <button class="actionButton add" (click) = "onSubmit(true)" [disabled]="(!memberForm.form.valid) || (checkEmail(model.email) && checkEmail(fullEmail))">Save & Add Another</button>
          <button class="actionButton add" (click) = "onSubmit()" [disabled]="(!memberForm.form.valid) || (checkEmail(model.email) && checkEmail(fullEmail))">Add & Close</button>
        </div>
      </form>
  </div>
</div>
