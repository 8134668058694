import { Component, OnInit } from '@angular/core';
import { AdminUsersService } from '../users.service';
import { CommonList } from '../../common/classes';
import {CustomToastService} from '../../../shared/services/custom-toast.service';
import { SharedService } from '../../../shared/services/shared.service';
import {ErrorService} from '../../../shared/errorFunction';
import {NotificationService} from '../../../shared/services/notification.service';
import {EVENT_TYPES} from '../../../shared/dataTypes';

@Component({
  selector: 'admin-users-list',
  templateUrl: 'list.component.html',
  styleUrls: [
    '../../admin.common.scss',
    'list.component.scss'
  ],
  host: {
    '(document:click)': 'onClick($event)',
  },
})
export class AdminUsersListComponent extends CommonList implements OnInit {
  isPending: boolean = false;
  users: Array<any>;
  meta: any;
  pages: Array<number>;
  params = {
    q: '',
    sort_column: 'id',
    sort_order: 'asc',
    page: 1
  };

  startLoader: boolean = false;
  is_exporting: boolean = false;
  OpenPopUp: boolean = false;
  waitForLoader: boolean = false;
  elem: any;

  constructor(public usersService: AdminUsersService,
              public sharedService: SharedService,
              private errorService: ErrorService,
              private notificationService: NotificationService,
              private customToast: CustomToastService) {
    super();
  }

  ngOnInit() {
    this.startLoader = true;
    this.getRecords();

    this.notificationService.subscribeEvent(EVENT_TYPES.USER.NEW_USER_ADDED, () => {
      this.getSearchRecords();
    });

    this.notificationService.subscribeEvent(EVENT_TYPES.USER.ON_EDIT_SUCCESS, () => {
      this.getSearchRecords();
    });
  }

  getRecords() {
    this.usersService.getUsers(this.params).subscribe(json => {
      this.users = json.users;
      this.users.forEach(user => user.openMemberAction = false);
      this.meta = json.meta;
      this.generatePages();
      this.startLoader = false;
    }, error => {
      this.startLoader = false;
      this.errorService.errorFunction(error);
    });
  }

  getSearchRecords() {
    this.params.page = 1;
    this.getRecords();
  }

  /*export() {
    this.is_exporting = true;
    this.customToast.messages.push({
      id: 'MembersList',
      type: 'warning',
      class: 'story_edited',
      title: 'Export Members',
      message: 'Members.csv is being downloaded.'
    });
    this.usersService.exportFile().subscribe((res: any) => {
      const parsedResponse = res;
      const blob = new Blob([parsedResponse], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);

      if (navigator.msSaveOrOpenBlob) {
        navigator.msSaveBlob(blob, 'members.csv');
      } else {
        const a = document.createElement('a');
        a.href = url;
        a.download = 'members.csv';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
      window.URL.revokeObjectURL(url);

      this.is_exporting = false;
      this.customToast.messages.push({
        id: 'MembersList',
        type: 'success',
        class: 'new_member_added',
        title: 'Export Members',
        message: 'Members.csv is successfully downloaded.'
      });
    }, (error) => {
      this.is_exporting = false;
      this.customToast.messages.push({
        id: 'Downloadfail',
        type: 'fail',
        class: 'import_fail',
        title: 'Export Members',
        message: 'Export members.csv failed.'
      });
    });
  }*/

  toggleMemberAction(user) {
    if (this.users) {
      this.users.forEach(item => {
        if (item !== user) {
          item.openMemberAction = false;
        }
      });
      user.openMemberAction = !user.openMemberAction;
    }
  }

  closeMemberAction() {
    if (this.users) {
      this.users.forEach(item => item.openMemberAction = false);
    }
  }

  onClick(event) {
    const element = event.target;
    if (!element.classList.contains('iconb-more')) {
      this.closeMemberAction();
    }
  }

  openUserDeletePopup(data) {
    this.OpenPopUp = true;
    this.elem = data;
    data.openMemberAction = false;
  }

  closeUserDeletePopUp() {
    this.OpenPopUp = false;
  }

  deleteUser() {
    this.waitForLoader = true;
    this.usersService.destroyUser(this.elem.id).subscribe(() => {
      this.getSearchRecords();
      this.OpenPopUp = false;
      this.waitForLoader = false;
    }, err => {
      this.errorService.errorFunction(err);
      this.OpenPopUp = false;
      this.waitForLoader = false;
    });
  }

  editMember(user) {
    this.sharedService.userIdForEditFormInAdmin = user.id;
    this.sharedService.showUserEditFormInAdmin = true;
  }
}
